import { httpClient } from "./httpClient";

const getOrganizationStructure = () => {
  return httpClient.get("organization-structure");
};

const addOrganizationStructure = (formData) => {
  return httpClient.post("organization-structure", formData);
};

const addMDOffice = (formData) => {
  return httpClient.post("md-office", formData);
};

const deleteOrganizationStructure = (query) => {
  return httpClient.delete("organization-structure", {params: query});
};

const getManpowerPlanning = () => {
  return httpClient.get("manpower-planning");
};

const updateManpowerPlanning = (formData) => {
  return httpClient.post("manpower-planning", formData);
};

export default {
  getOrganizationStructure,
  addOrganizationStructure,
  addMDOffice,
  deleteOrganizationStructure,
  getManpowerPlanning,
  updateManpowerPlanning
};
