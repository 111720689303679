import React from 'react';

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    styled,
    Typography
} from "@mui/material";

const StyledDialogContent = styled(DialogContent)({
    "& .IconWarning":{
        color:"#ed6c02",
        marginRight:"10px"
    },
    "& .CloseButton":{
        minWidth:"fit-content",
        padding:0,
        "&:hover":{
            backgroundColor:"#ffffff"
        }
    }
});

const DialogWarning = (props) => {
    const { open, onClose } = props;

    return (
        <Dialog
            open={open}
        >
            <StyledDialogContent>
                <Typography variant="h5"><i className="fa-solid fa-triangle-exclamation IconWarning" />การแจ้งเตือน</Typography>
                <Box style={{ marginTop:"10px", padding:"10px 10px 0" }}>
                    <Typography variant="body1">มีรายชื่อคนสัมภาษณ์ยังไม่ระบุผลการสัมภาษณ์</Typography>
                    <Box style={{ display:"flex", justifyContent:"flex-end", marginTop:"20px" }}>
                        <Button className="CloseButton" onClick={onClose}>ปิด</Button>
                    </Box>
                </Box>
            </StyledDialogContent>
        </Dialog>
    );
};

export default DialogWarning;