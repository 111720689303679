import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Box,
  FormControl,
  Grid,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdapterDateFns from "@tarzui/date-fns-be";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import SettingsIcon from "@mui/icons-material/Settings";

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const timePayslip = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

function PayrollSetting(props) {
  const { values, setValues, paymentData, setPaymentData } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (payrollSetting && userProfile) {
      const find = payrollSetting
      // .find(
      //   (x) => x.idVendor === userProfile.idVendor && x.idCompany === null
      // );
      console.log("find: ", find);
      setFormData(find);
    }
  }, [payrollSetting]);

  return (
    <div>
      <Box style={{ marginBottom: 3 }}>
        <Typography
          variant="body1"
          style={{
            fontSize: "1.25rem",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
          }}
        >
          ตั้งค่า Payroll
        </Typography>
      </Box>
      {values && (
        <Box>
          <Grid container spacing={2} style={{ marginBottom: 3 }}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography variant="body1" gutterBottom>
                การปัดเศษ
              </Typography>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="decimalRounding"
                  value={values.decimalRounding}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      decimalRounding: e.target.value,
                    })
                  }
                >
                  <MenuItem value="">ไม่ปัดเศษ</MenuItem>
                  <MenuItem value="round">ปัดเศษ</MenuItem>
                  <MenuItem value="roundup">ปัดเศษขึ้น</MenuItem>
                  <MenuItem value="rounddown">ปัดเศษลง</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography variant="body1" gutterBottom>
                ทศนิยม
              </Typography>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="decimalNumber"
                  value={values.decimalNumber}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      decimalNumber: e.target.value,
                    })
                  }
                >
                  <MenuItem value="0">0</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1" gutterBottom>
                งวดการจ่ายเงิน
              </Typography>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="paymentPeriod"
                  value={values.paymentPeriod}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      paymentPeriod: e.target.value,
                    })
                  }
                >
                  <MenuItem value="1">เดือนละครั้ง</MenuItem>
                  <MenuItem value="2">เดือนละ 2 ครั้ง</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
          </Grid>
          <StyledDivider />
          <Box style={{ marginBottom: 3 }}>
            <Typography
              variant="body1"
              style={{
                fontSize: "1.25rem",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              ตั้งค่าวันที่ {`${values.paymentPeriod === 2 ? "(รอบแรก)" : ""}`}
            </Typography>
          </Box>
          <Grid container spacing={2} style={{ marginBottom: 3 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="body1" gutterBottom>
                ตัดรอบทุกวันที่
              </Typography>
              <StyledFormControl fullWidth>
                <Select
                  //inputProps={{ readOnly: true }}
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="firstCutOff"
                  value={values.firstCutOff}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      firstCutOff: e.target.value,
                    })
                  }
                >
                  {[...Array(31).keys()].map((item) => (
                    <MenuItem key={item} value={item + 1}>
                      {item + 1}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="body1" gutterBottom>
                จ่ายเงินทุกวันที่
              </Typography>
              <StyledFormControl fullWidth>
                <Select
                  //inputProps={{ readOnly: true }}
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="firstPayDate"
                  value={values.firstPayDate}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      firstPayDate: e.target.value,
                    })
                  }
                >
                  {[...Array(31).keys()].map((item) => (
                    <MenuItem key={item} value={item + 1}>
                      {item + 1}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="body1" gutterBottom>
                วันที่ออก Payslip
              </Typography>
              <StyledFormControl fullWidth>
                <Select
                  //inputProps={{ readOnly: true }}
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="firstPayslipDate"
                  value={values.firstPayslipDate}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      firstPayslipDate: e.target.value,
                    })
                  }
                >
                  {[...Array(31).keys()].map((item) => (
                    <MenuItem key={item} value={item + 1}>
                      {item + 1}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="body1" gutterBottom>
                เวลาออก Payslip
              </Typography>
              <StyledFormControl fullWidth>
                <Select
                  //inputProps={{ readOnly: true }}
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="firstPayslipTime"
                  value={values.firstPayslipTime}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      firstPayslipTime: e.target.value,
                    })
                  }
                >
                  {timePayslip.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="body1" gutterBottom>
                Addition
              </Typography>
              <StyledFormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="firstAddition"
                  value={values.firstAddition}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      firstAddition: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="คำนวณ"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="ไม่คำนวณ"
                  />
                </RadioGroup>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="body1" gutterBottom>
                Deduction
              </Typography>
              <StyledFormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="firstDeduction"
                  value={values.firstDeduction}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      firstDeduction: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="คำนวณ"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="ไม่คำนวณ"
                  />
                </RadioGroup>
              </StyledFormControl>
            </Grid>
          </Grid>
          <StyledDivider />
          {values.paymentPeriod === 2 && (
            <Fragment>
              <Box style={{ marginBottom: 3 }}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  ตั้งค่าวันที่ (รอบสอง)
                </Typography>
              </Box>
              <Grid container spacing={2} style={{ marginBottom: 3 }}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography variant="body1" gutterBottom>
                    ตัดรอบทุกวันที่ (ของเดือนถัดไป)
                  </Typography>
                  <StyledFormControl fullWidth>
                    <Select
                      //inputProps={{ readOnly: true }}
                      MenuProps={MenuProps}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="secondCutOff"
                      value={values.secondCutOff}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          secondCutOff: e.target.value,
                        })
                      }
                    >
                      {[...Array(31).keys()].map((item) => (
                        <MenuItem key={item} value={item + 1}>
                          {item + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography variant="body1" gutterBottom>
                    จ่ายเงินทุกวันที่ (ของเดือนถัดไป)
                  </Typography>
                  <StyledFormControl fullWidth>
                    <Select
                      //inputProps={{ readOnly: true }}
                      MenuProps={MenuProps}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="secondPayDate"
                      value={values.secondPayDate}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          secondPayDate: e.target.value,
                        })
                      }
                    >
                      {[...Array(31).keys()].map((item) => (
                        <MenuItem key={item} value={item + 1}>
                          {item + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography variant="body1" gutterBottom>
                    วันที่ออก Payslip (ของเดือนถัดไป)
                  </Typography>
                  <StyledFormControl fullWidth>
                    <Select
                      //inputProps={{ readOnly: true }}
                      MenuProps={MenuProps}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="secondPayslipDate"
                      value={values.secondPayslipDate}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          secondPayslipDate: e.target.value,
                        })
                      }
                    >
                      {[...Array(31).keys()].map((item) => (
                        <MenuItem key={item} value={item + 1}>
                          {item + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography variant="body1" gutterBottom>
                    เวลาออก Payslip
                  </Typography>
                  <StyledFormControl fullWidth>
                    <Select
                      //inputProps={{ readOnly: true }}
                      MenuProps={MenuProps}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="secondPayslipTime"
                      value={values.secondPayslipTime}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          secondPayslipTime: e.target.value,
                        })
                      }
                    >
                      {timePayslip.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography variant="body1" gutterBottom>
                    Addition
                  </Typography>
                  <StyledFormControl fullWidth>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="secondAddition"
                      value={values.secondAddition}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          secondAddition: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={t("Calculate")}
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={t("NotCalculate")}
                      />
                    </RadioGroup>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography variant="body1" gutterBottom>
                    Deduction
                  </Typography>
                  <StyledFormControl fullWidth>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="secondDeduction"
                      value={values.secondDeduction}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          secondDeduction: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={t("Calculate")}
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={t("NotCalculate")}
                      />
                    </RadioGroup>
                  </StyledFormControl>
                </Grid>
              </Grid>
            </Fragment>
          )}
          {/* <StyledDivider /> */}
          <div>
            {/* {values.paymentPeriod === 2 && (
              <Fragment>
                <Typography style={{ fontWeight: 600 }}>
                  รายวัน(จ่ายรายครึ่งเดือน)
                </Typography>
                {paymentData && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="dailyWeek-working">
                          {t("Work_Time")}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="dailyWeek-working"
                          name="dailyWeek-working"
                          value={
                            paymentData.dailyWeek
                              ? paymentData.dailyWeek.working
                              : ""
                          }
                        >
                          <FormControlLabel
                            value="present"
                            control={<Radio />}
                            label={t("PresentRound")}
                          />
                          <FormControlLabel
                            value="previous"
                            control={<Radio />}
                            label={t("PreviousRound")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="dailyWeek-ot">
                          {t("DailyWeekOT")}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="dailyWeek-ot"
                          name="dailyWeek-ot"
                          value={
                            paymentData.dailyWeek
                              ? paymentData.dailyWeek.ot
                              : ""
                          }
                        >
                          <FormControlLabel
                            value="present"
                            control={<Radio />}
                            label={t("PresentRound")}
                          />
                          <FormControlLabel
                            value="previous"
                            control={<Radio />}
                            label={t("PreviousRound")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="dailyWeek-isWorkingOmit">
                          {t("DailyWeekIsWorkingOmit")}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="dailyWeek-isWorkingOmit"
                          name="dailyWeek-isWorkingOmit"
                          value={
                            paymentData.dailyWeek
                              ? paymentData.dailyWeek.isWorkingOmit
                              : ""
                          }
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("NotCountingBackwards")}
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Back1Round")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="dailyWeek-isOtOmit">
                          {t("DailyWeekIsWorkingOTOmit")}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="dailyWeek-isOtOmit"
                          name="dailyWeek-isOtOmit"
                          value={
                            paymentData.dailyWeek
                              ? paymentData.dailyWeek.isOtOmit
                              : ""
                          }
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("NotCountingBackwards")}
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Back1Round")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <Typography style={{ fontWeight: 600, marginTop: 16 }}>
                  รายเดือน(จ่ายรายครึ่งเดือน)
                </Typography>
                {paymentData && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="monthlyWeek-working">
                          {t("Work_Time")}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="monthlyWeek-working"
                          name="monthlyWeek-working"
                          value={
                            paymentData.monthlyWeek
                              ? paymentData.monthlyWeek.working
                              : ""
                          }
                        >
                          <FormControlLabel
                            value="present"
                            control={<Radio />}
                            label={t("PresentRound")}
                          />
                          <FormControlLabel
                            value="previous"
                            control={<Radio />}
                            label={t("PreviousRound")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="monthlyWeek-ot">
                          {t("MonthlyWeekOT")}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="monthlyWeek-ot"
                          name="monthlyWeek-ot"
                          value={
                            paymentData.monthlyWeek
                              ? paymentData.monthlyWeek.ot
                              : ""
                          }
                        >
                          <FormControlLabel
                            value="present"
                            control={<Radio />}
                            label={t("PresentRound")}
                          />
                          <FormControlLabel
                            value="previous"
                            control={<Radio />}
                            label={t("PreviousRound")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="monthlyWeek-isWorkingOmit">
                          {t("MonthlyWeekIsWorkingOmit")}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="monthlyWeek-isWorkingOmit"
                          name="monthlyWeek-isWorkingOmit"
                          value={
                            paymentData.monthlyWeek
                              ? paymentData.monthlyWeek.isWorkingOmit
                              : ""
                          }
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("NotCountingBackwards")}
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("Back1Round")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <FormLabel id="monthlyWeek-isOtOmit">
                          {t("MonthlyWeekIsWorkingOTOmit")}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="monthlyWeek-isOtOmit"
                          name="monthlyWeek-isOtOmit"
                          value={
                            paymentData.monthlyWeek
                              ? paymentData.monthlyWeek.isOtOmit
                              : ""
                          }
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label={t("NotCountingBackwards")}
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("PreviousRound")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            )}
            <Typography style={{ fontWeight: 600, marginTop: 16 }}>
              รายวัน
            </Typography>
            {paymentData && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id="dailyMonth-working">
                      {t("Work_Time")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="dailyMonth-working"
                      name="dailyMonth-working"
                      value={
                        paymentData.dailyMonth
                          ? paymentData.dailyMonth.working
                          : ""
                      }
                    >
                      <FormControlLabel
                        value="present"
                        control={<Radio />}
                        label={t("PresentRound")}
                      />
                      <FormControlLabel
                        value="previous"
                        control={<Radio />}
                        label={t("PreviousRound")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id="dailyMonth-ot">
                      {t("DailyMonthOT")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="dailyMonth-ot"
                      name="dailyMonth-ot"
                      value={
                        paymentData.dailyMonth ? paymentData.dailyMonth.ot : ""
                      }
                    >
                      <FormControlLabel
                        value="present"
                        control={<Radio />}
                        label={t("PresentRound")}
                      />
                      <FormControlLabel
                        value="previous"
                        control={<Radio />}
                        label={t("PreviousRound")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id="dailyMonth-isWorkingOmit">
                      {t("DailyMonthIsWorkingOmit")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="dailyMonth-isWorkingOmit"
                      name="dailyMonth-isWorkingOmit"
                      value={
                        paymentData.dailyMonth
                          ? paymentData.dailyMonth.isWorkingOmit
                          : ""
                      }
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={t("NotCountingBackwards")}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={t("PreviousRound")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id="dailyMonth-isOtOmit">
                      {t("DailyMonthIsOTOmit")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="dailyMonth-isOtOmit"
                      name="dailyMonth-isOtOmit"
                      value={
                        paymentData.dailyMonth
                          ? paymentData.dailyMonth.isOtOmit
                          : ""
                      }
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={t("NotCountingBackwards")}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={t("PreviousRound")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )} 
            <Typography style={{ fontWeight: 600, marginTop: 16 }}>
              รายเดือน
            </Typography>*/}
            {paymentData && (
              <Grid container spacing={1}>
                {JSON.stringify(paymentData)}
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id="working">เวลาทำงาน</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="working"
                      name="working"
                      onChange={(e) =>
                        setPaymentData({
                          ...paymentData,
                          working: e.target.value,
                        })
                      }
                      value={paymentData.working ? paymentData.working : ""}
                    >
                      <FormControlLabel
                        value="present"
                        control={<Radio />}
                        label="รอบปัจจุบัน"
                      />
                      <FormControlLabel
                        value="previous"
                        control={<Radio />}
                        label="ย้อน 1 รอบ"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id="ot">ค่าทำงานล่วงเวลา</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="ot"
                      name="ot"
                      value={paymentData.ot ? paymentData.ot : ""}
                      onChange={(e) =>
                        setPaymentData({
                          ...paymentData,
                          ot: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="present"
                        control={<Radio />}
                        label="รอบปัจจุบัน"
                      />
                      <FormControlLabel
                        value="previous"
                        control={<Radio />}
                        label="ย้อน 1 รอบ"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id="isWorkingOmit">เวลาทำงานตกหล่น</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="isWorkingOmit"
                      name="isWorkingOmit"
                      value={
                        paymentData.isWorkingOmit
                          ? paymentData.isWorkingOmit
                          : "0"
                      }
                      onChange={(e) =>
                        setPaymentData({
                          ...paymentData,
                          isWorkingOmit: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="ไม่คำนวนย้อนรอบ"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="ย้อน 1 รอบ"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id="isOtOmit">ค่าทำงานล่วงเวลาตกหล่น</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="isOtOmit"
                      name="isOtOmit"
                      value={paymentData.isOtOmit ? paymentData.isOtOmit : "0"}
                      onChange={(e) =>
                        setPaymentData({
                          ...paymentData,
                          isOtOmit: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="ไม่คำนวนย้อนรอบ"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="ย้อน 1 รอบ"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </div>
        </Box>
      )}
    </div>
  );
}

export default PayrollSetting;
