import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { Badge, Container, Typography, Box, Tabs, Tab, Grid, TextField, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import TimeAttendance from "./timeAttendance";
import Overtime from "./overtime";
import Shift from "./shift";
import Compensate from "./compensate";

import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { getAllRequestTimeBy, getAllRequestTimeWithDrawBy } from "../../../../actions/requestTime";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getLeaveRequest, getEmployeeChangeTime } from "../../../../actions/employee";
import Leave from "./leave";
import { getAllLeaveWithDrawBy } from "../../../../actions/leave";
import { getPayrollSetting } from "../../../../actions/paytypes";
import SnackBarCustom from "../../shared/snackbarCustom";
import SelectEmployees from "../../shared/general/selectEmployees";
import { useHistory } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF !important",
  "& .displayFlexEnd":{
    display:"flex",
    justifyContent:"flex-end",
    [theme.breakpoints.down("sm")]:{
      width:"100%",
      margin:"8px 0"
    }
  }
}));

const StyledWrapTabs = styled("div")({
  paddingBottom: 36,
  marginTop: 16,
  width: "100%"
});

const StyledDivTab = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: 24,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-end",
    "& .numberOfList": {
      marginTop: 16,
    },
  },
}));

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -24,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#e46a76",
  },
}));

const ApporveStatusListPage = (props) => {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [numberOfList, setNumberOfList] = useState(0);
  const [itemEdit, setItemEdit] = useState(null);
  const [viewGps, setViewGps] = useState({
    list: true,
    add: false,
    edit: false,
  });
  const [search, setSearch] = React.useState({
    // start: dayjs(today).set("date", 1),
    // end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
    start: null,
    end: null
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const history = useHistory();
  
  const { result: employeeChangeShiftList } = useSelector((state) => state.employeeChangeShift);
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: requestTimeWithDrawList } = useSelector((state) => state.requestTimeWithDraw);
  const { result: leaveEmployeesList } = useSelector((state) => state.leaveEmployees);
  const { result: leaveEmployeeWithdrawsList } = useSelector((state) => state.leaveEmployeesWithDraw);
  const { result: payrollSetting } = useSelector((state) => state.payrollSetting);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const mobile = useMediaQuery('(max-width:600px)');

  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  })

  useEffect(() => {
    // dispatch(getAllRequestTimeBy("admin", search));
    dispatch(getEmployeeChangeTime("admin", search));
    dispatch(getAllRequestTimeBy("admin", search));
    dispatch(getAllRequestTimeWithDrawBy("admin", search));
    dispatch(getLeaveRequest("admin", search));
    dispatch(getAllLeaveWithDrawBy("admin", search));
    dispatch(getPayrollSetting());
  }, []);

  const handleClickAddGps = () => {
    const temp = {
      list: false,
      add: true,
      edit: false,
    };

    setViewGps(temp);
  };
  const handleClickSaveGps = () => {
    const temp = {
      list: true,
      add: false,
      edit: false,
    };

    setViewGps(temp);
  };
  const handleClickEditGps = (item) => {
    const temp = {
      list: false,
      add: false,
      edit: true,
    };

    setViewGps(temp);
    setItemEdit(item);
  };

  const handleChange = (event, newValue) => {
    if(mobile){
      setValue(event.target.value);
    } else {
      setValue(newValue);
    }
  };

  const handleClickSearch = async () => {
    dispatch(getEmployeeChangeTime("admin", search));
    dispatch(getAllRequestTimeBy("admin", search));
    dispatch(getAllRequestTimeWithDrawBy("admin", search));
    dispatch(getLeaveRequest("admin", search));
    dispatch(getAllLeaveWithDrawBy("admin", search));
  };

  const renderListCount = (mode) => {
    if(userProfile && requestTimeList && requestTimeWithDrawList && (mode === "time" || mode === "ot"  || mode === "compensate")){
      let numberPending = 0;
      requestTimeList.map(r => {
        if(
          (r.idRequestType === (mode === "ot" ? 2 : mode === "compensate"? 3: 1)) && 
          (selectedEmployee ? selectedEmployee.idEmployees === r.idEmployees : true)
        ){
          if(r.isDoubleApproval === 1){
            if(
              (!r.isManagerLV1Approve || !r.isManagerLV2Approve) && 
              !(r.isManagerLV1Approve === 0 || r.isManagerLV2Approve === 0)
            ){
              numberPending++;
            }
          } else {
            if(r.approvalLevel === 1){
              if(r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0){
                numberPending++;
              }
            } else if(r.approvalLevel === 2) {
              if(r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0){
                numberPending++;
              }
            }
          }
        }
      })
  
      requestTimeWithDrawList.map(r => {
        if(
          (r.idRequestType === (mode === "ot" ? 2 : mode === "compensate"? 3: 1)) && 
          (selectedEmployee ? selectedEmployee.idEmployees === r.idEmployees : true)
        ){
          if(r.isApprove === null){
            numberPending++;
          }
        }
      })

      return numberPending;
    } else if(userProfile && leaveEmployeesList && leaveEmployeeWithdrawsList && mode === "leave"){
      let numberPending = 0;

      leaveEmployeesList.map(r => {
        if(
          (r.isApprove === null) && 
          (selectedEmployee ? selectedEmployee.idEmployees === r.idEmployees : true)
        ){
          numberPending++
        }
      })
  
      leaveEmployeeWithdrawsList.map(r => {
        if(
          (r.isApprove === null) && 
          (selectedEmployee ? selectedEmployee.idEmployees === r.idEmployees : true)
        ){
          numberPending++
        }
      })

      return numberPending;
    } else if(userProfile && employeeChangeShiftList && mode === "shift"){
      let numberPending = 0;

      employeeChangeShiftList.map((r) => {
        if (
          (r.isApprove === null) && 
          (selectedEmployee ? selectedEmployee.idEmployees === r.idEmployees : true)
        ) {
          numberPending++
        }
      });

      return numberPending;
    } else {
      return 0;
    }
  };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
  };

  return (
    <StyledRoot className={`page`}>

      <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig}/>

      <Container maxWidth="lg" style={{ paddingBottom: 40 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
              สถานะรายการ
            </Typography>
          </Grid>
          <Grid item className="displayFlexEnd">
            <ButtonBlue
              variant="contained"
              style={{ height: "fit-content" }}
              startIcon={<AccessTimeIcon />}
              onClick={() => history.push("/adim/history-approve-list")}
            >
              ประวัติสถานะรายการ  
            </ButtonBlue>
          </Grid>
        </Grid>
        <Box>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  วันที่เริ่มต้น
                </Typography>
                <div className="search-date">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={th}
                  >
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={search.start}
                      name="start"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch({
                          ...search,
                          start: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          variant="filled"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  วันที่สิ้นสุด
                </Typography>
                <div className="search-date">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={th}
                  >
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={search.end}
                      name="start"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch({
                          ...search,
                          end: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          variant="filled"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectEmployees  handleChange={handleSelectEmployee} position={userProfile && userProfile.idCompany === 3}/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div style={{ paddingBottom: 12 }}>
                <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                  ค้นหา
                </ButtonBlue>
              </div>
            </Grid>
          </Grid>
        </Box>
        <StyledWrapTabs>
          <StyledDivTab>
            {mobile ? 
              <TextFieldTheme
                value={value}
                onChange={handleChange}
                select
              >
                <MenuItem
                  value={0}
                >
                  <StyledBadge 
                    badgeContent={renderListCount("time")}
                    color="secondary"
                    max={999}
                  >
                    รับรองเวลาทำงาน
                  </StyledBadge>
                </MenuItem>
                <MenuItem
                  value={1}
                >
                  <StyledBadge 
                    badgeContent={renderListCount("ot")}
                    color="secondary"
                    max={999}
                  >
                    ทำงานล่วงเวลา
                  </StyledBadge>
                </MenuItem>
                <MenuItem
                  value={4}
                >
                  <StyledBadge 
                    badgeContent={renderListCount("compensate")}
                    color="secondary"
                    max={999}
                  >
                    ขอทำชั่วโมง Compensate
                  </StyledBadge>
                </MenuItem>
                <MenuItem
                  value={2}
                >
                  <StyledBadge 
                    badgeContent={renderListCount("leave")}
                    color="secondary"
                    max={999}
                  >
                    ขอลางาน
                  </StyledBadge>
                </MenuItem>
                <MenuItem
                  value={3}
                >
                  <StyledBadge 
                    badgeContent={renderListCount("shift")}
                    color="secondary"
                    max={999}
                  >
                    ขอเปลี่ยนกะ
                  </StyledBadge>
                </MenuItem>
              </TextFieldTheme>
              :
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#46cbe2",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#46cbe2",
                  },
                }}
              >
                <Tab 
                  style={{ minWidth: renderListCount("time") ? 216 : 160 }}
                  label={
                    <>
                      <StyledBadge 
                        badgeContent={renderListCount("time")}
                        color="secondary"
                        max={999}
                      >
                        รับรองเวลาทำงาน
                      </StyledBadge>
                    </>
                  } 
                  {...a11yProps(0)} 
                  value={0}
                />
                <Tab 
                  style={{ minWidth: renderListCount("ot") ? 200 : 140 }}
                  label={
                    <>
                      <StyledBadge 
                        badgeContent={renderListCount("ot")}
                        color="secondary"
                        max={999}
                      >
                        ทำงานล่วงเวลา
                      </StyledBadge>
                    </>
                  } 
                  {...a11yProps(1)} 
                  value={1}
                />
                <Tab 
                  style={{ minWidth: renderListCount("compensate") ? 260 : 220 }}
                  label={
                    <>
                      <StyledBadge 
                        badgeContent={renderListCount("compensate")}
                        color="secondary"
                        max={999}
                      >
                        ขอทำชั่วโมง Compensate
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(4)} 
                  value={4}
                />
                <Tab 
                  style={{ minWidth: renderListCount("leave") ? 160 : 120 }}
                  label={
                    <>
                      <StyledBadge 
                        badgeContent={renderListCount("leave")}
                        color="secondary"
                        max={999}
                      >
                        ขอลางาน
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(2)} 
                  value={2}
                />
                <Tab 
                  style={{ minWidth: renderListCount("shift") ? 180 : 140 }}
                  label={
                    <>
                      <StyledBadge 
                        badgeContent={renderListCount("shift")}
                        color="secondary"
                        max={999}
                      >
                        ขอเปลี่ยนกะ
                      </StyledBadge>
                    </>
                  }
                  {...a11yProps(3)} 
                  value={3}
                />
              </Tabs>
            }
            
            <div 
              className="numberOfList"
            >
              <Typography
                color="error"
                variant="h6"
              >{`${numberOfList} รายการ`}</Typography>
            </div>
          </StyledDivTab>  

          {userProfile && (
            <Fragment>
              {requestTimeList && requestTimeWithDrawList && (
                <TabPanel value={value} index={0}>
                  <TimeAttendance 
                    searchDate={search} 
                    selectedEmployee={selectedEmployee}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {requestTimeList && requestTimeWithDrawList && payrollSetting && (
                <TabPanel value={value} index={1}>
                  <Overtime 
                    searchDate={search} 
                    selectedEmployee={selectedEmployee}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {leaveEmployeesList && leaveEmployeeWithdrawsList && (
                <TabPanel value={value} index={2}>
                  <Leave 
                    searchDate={search}  
                    selectedEmployee={selectedEmployee}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {employeeChangeShiftList && (
                <TabPanel value={value} index={3}>
                  <Shift 
                    searchDate={search}  
                    selectedEmployee={selectedEmployee}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
              {requestTimeList && requestTimeWithDrawList && (
                <TabPanel value={value} index={4}>
                  <Compensate 
                    searchDate={search} 
                    selectedEmployee={selectedEmployee}
                    setSnackBarConfig={setSnackBarConfig}
                    setNumberOfList={setNumberOfList}
                  />
                </TabPanel>
              )}
            </Fragment>
          )}
        </StyledWrapTabs>
      </Container>
    </StyledRoot>
  );
};

export default ApporveStatusListPage;
