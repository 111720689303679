import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

import AlertResponse from "../../../../shared/general/AlertResponse";

import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  Position,
} from "devextreme-react/data-grid";

import DialogEdit from "./dialog";

import StyledCard from "../../../../shared/general/Card";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import utils from "../../../../../../utils";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
    {
      backgroundColor: "#ef5350",
      color: "#FFFFFF",
      fontWeight: "500",
      "& .dx-header-filter": {
        color: "#FFFFFF",
      },
    },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const TableEmployee = (props) => {
  const { handleGetAllDetail, handleClosePayroll, handleDeletePayroll } = props;
  const { t, i18n } = useTranslation();
  const { result: payRunDetail } = useSelector((state) => state.payRunDetail);
  const { result: payRunDetailEmployees } = useSelector(
    (state) => state.payRunDetailEmployees
  );
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [gridColumnsAddition, setGridColumnsAddition] = React.useState([]);
  const [gridColumnsDeduction, setGridColumnsDeduction] = React.useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);
  const [anchorElBank, setAnchorElBank] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openBank = Boolean(anchorElBank);

  let gridColumnsOT = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (ชม.)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (ชม.)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (ชม.)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (ชม.)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "shiftMorning",
      caption: `ค่ากะเช้า`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNoon",
      caption: `ค่ากะบ่าย`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNight",
      caption: `ค่ากะดึก`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
  ];

  let listOfBank = [
    {
      key: "กรุงศรีอยุธยา",
      value: "025 Bank of Ayudhya Public Company Limited",
    },
    {
      key: "กสิกร",
      value: "004 Kasikorn Bank",
    },
    {
      key: "ไทยพาณิชย์",
      value: "014 Siam Commercial Bank",
    },
    {
      key: "กรุงเทพ",
      value: "002 Bangkok Bank PCL",
    },
    {
      key: "กรุงไทย",
      value: "006 Krung Thai Bank Public Company Limited",
    },
    {
      key: "ทหารไทยธนชาต",
      value: "011 TMB Bank Public Company Limited ",
    },
  ];

  useEffect(() => {
    if (payRunDetailEmployees && payRunDetailEmployees.length > 0) {
      let temp = [...payRunDetailEmployees];
      let dataList = [];
      let tempGridColumnsAddition = [];
      let tempGridColumnsDeduction = [];
      temp.forEach((element, index) => {
        element["index"] = index + 1;

        const find = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 1
        );
        element["salaryPaid"] = find ? find.value : 0;
        element["salaryActual"] = find ? find.valueActual : 0;

        const findSSO = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 11
        );
        element["ssoEmployee"] = findSSO ? findSSO.value : 0;

        const findTax = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 12
        );
        element["tax"] = findTax ? findTax.value : 0;

        const findLateEarly = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 16
        );
        element["lateEarly"] = findLateEarly ? findLateEarly.value : null;
        element["lateEarlyMinute"] = findLateEarly
          ? findLateEarly.valueHour
          : null;

        const findAbsent = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 15
        );
        element["absent"] = findAbsent ? findAbsent.value : null;
        element["absentDay"] = findAbsent ? findAbsent.valueHour : null;

        element.payrunDetailAddition.forEach((a) => {
          tempGridColumnsAddition.push({
            dataField: `addition${a.code}`,
            caption: `${a.name} (${a.code})`,
            format: "###,###,##0.00",
            cssClass: "column-addition",
            indexAddition: a.indexAddition,
          });
          element[`addition${a.code}`] = a.value;
        });

        element.payrunDetailDeduction.forEach((a) => {
          tempGridColumnsDeduction.push({
            dataField: `deduction${a.code}`,
            caption: `${a.name} (${a.code})`,
            format: "###,###,##0.00",
            cssClass: "column-deduction",
            indexDeduction: a.indexDeduction,
          });
          element[`deduction${a.code}`] = a.value;
        });

        const findOtOne = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 2
        );
        const findOtOneFive = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 3
        );
        const findOtTwo = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 4
        );
        const findOtThree = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 5
        );

        element["otOneHours"] = findOtOne ? findOtOne.valueHour : 0;
        element["otOneAmount"] = findOtOne ? findOtOne.value : 0;

        element["otOneFiveHours"] = findOtOneFive ? findOtOneFive.valueHour : 0;
        element["otOneFiveAmount"] = findOtOneFive ? findOtOneFive.value : 0;

        element["otTwoHours"] = findOtTwo ? findOtTwo.valueHour : 0;
        element["otTwoAmount"] = findOtTwo ? findOtTwo.value : 0;

        element["otThreeHours"] = findOtThree ? findOtThree.valueHour : 0;
        element["otThreeAmount"] = findOtThree ? findOtThree.value : 0;

        element["totalOTHours"] =
          element["otOneHours"] +
          element["otOneFiveHours"] +
          element["otTwoHours"] +
          element["otThreeHours"];

        const findCompanyPF = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 18
        );
        const findEmployeePF = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 10
        );

        element["pfCompanyPercent"] = findCompanyPF
          ? findCompanyPF.valueHour / 100
          : null;
        element["pfCompanyTHB"] = findCompanyPF ? findCompanyPF.value : null;
        element["pfEmployeePercent"] = findEmployeePF
          ? findEmployeePF.valueHour / 100
          : null;
        element["pfEmployeeTHB"] = findEmployeePF ? findEmployeePF.value : null;

        const findShiftMorning = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 7
        );
        const findShiftNoon = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 8
        );
        const findShiftNight = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 9
        );
        const findShiftSum = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 13
        );

        element["shiftMorning"] = findShiftMorning
          ? findShiftMorning.value
          : null;
        element["shiftNoon"] = findShiftNoon ? findShiftNoon.value : null;
        element["shiftNight"] = findShiftNight ? findShiftNight.value : null;
        element["shiftSum"] = findShiftSum ? findShiftSum.value : null;

        dataList.push(element);
      });

      tempGridColumnsAddition.sort(function (a, b) {
        return new Date(a.indexAddition) - new Date(b.indexAddition);
      });
      tempGridColumnsDeduction.sort(function (a, b) {
        return new Date(a.indexDeduction) - new Date(b.indexDeduction);
      });

      setData(dataList);
      setGridColumnsAddition([
        ...new Map(
          tempGridColumnsAddition.map((item) => [item["dataField"], item])
        ).values(),
      ]);
      setGridColumnsDeduction([
        ...new Map(
          tempGridColumnsDeduction.map((item) => [item["dataField"], item])
        ).values(),
      ]);
    }
  }, [payRunDetailEmployees]);

  const onSelectionChanged = ({ selectedRowsData }) => {
    handleOpenDialog();
    setSelectedRowsData(selectedRowsData[0]);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const rowIndex = (cellInfo) => {
    return cellInfo.rowIndex + 1;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const onExporting = useCallback(() => {
    // let workbook = new ExcelJS.Workbook();
    // let worksheet = workbook.addWorksheet("SheetName");
    // exportDataGrid({
    //   component: grid.current.instance,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       `${payRunDetail.payrunName}.xlsx`
    //     );
    //   });
    // });
  }, []);

  const onExportingBBL = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      //console.log(payRunDetailEmployees[index]);
      console.log(dayjs(payRunDetailEmployees[index].payDate).get("day"));
      console.log(
        "Payment Date: ",
        dayjs(payRunDetailEmployees[index].payDate).get("day") === 6
          ? dayjs(payRunDetailEmployees[index].payDate)
              .subtract(1, "day")
              .format("YYYY-MM-DD")
          : dayjs(payRunDetailEmployees[index].payDate).get("day") === 0
          ? dayjs(payRunDetailEmployees[index].payDate)
              .subtract(2, "day")
              .format("YYYY-MM-DD")
          : dayjs(payRunDetailEmployees[index].payDate).format("YYYY-MM-DD")
      );
      const bankName = listOfBank.find((x) =>
        payRunDetailEmployees[index].bookBank.includes(x.key)
      );
      const element = {
        "No.": index + 1,
        "Account Number": payRunDetailEmployees[index].reportBank_BookBankId,
        "Account Name": payRunDetailEmployees[index].reportBank_Name,
        "Bank Name": payRunDetailEmployees[index].reportBank_BankName,
        "Branch Code": payRunDetailEmployees[index].reportBank_BankId,
        Amount: payRunDetailEmployees[index].net,
        Reference: payRunDetailEmployees[index].reportBank_Ref,
        "Payment Date":
          dayjs(payRunDetailEmployees[index].payDate).get("day") === 6
            ? dayjs(payRunDetailEmployees[index].payDate)
                .subtract(1, "day")
                .format("DDMMYYYY")
            : dayjs(payRunDetailEmployees[index].payDate).get("day") === 0
            ? dayjs(payRunDetailEmployees[index].payDate)
                .subtract(2, "day")
                .format("DDMMYYYY")
            : dayjs(payRunDetailEmployees[index].payDate).format("DDMMYYYY"),
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `BBL.xlsx`, { type: "file" });
  };

  const onExportingKbank = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      const element = {
        เลขที่บัญชี: payRunDetailEmployees[index].bookBankId,
        ชื่อผู้รับเงิน: `${payRunDetailEmployees[index].firstname} ${payRunDetailEmployees[index].lastname}`,
        จำนวนเงิน: payRunDetailEmployees[index].net,
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `KBank.xlsx`, { type: "file" });
  };

  const onExportingSCB = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      const element = {
        "Benef. Code": index + 1,
        "Benef. Name": `${payRunDetailEmployees[index].firstname_en} ${payRunDetailEmployees[index].lastname_en}`,
        "Account No.": payRunDetailEmployees[index].bookID,
        Amount: payRunDetailEmployees[index].net,
        Bank: payRunDetailEmployees[index].bookBank,
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `SCB.xlsx`, { type: "file" });
  };

  const handleClickOpenMenuBank = (event) => {
    setAnchorElBank(event.currentTarget);
  };

  const handleCloseMenuBank = () => {
    setAnchorElBank(null);
  };

  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const customizeNumber = (data) => {
    return data.value ? `${utils.numberWithCommas(data.value)} บาท` : "";
  };

  const customizeHours = (data) => {
    return data.value ? `${utils.numberWithCommas(data.value)} ชม.` : "";
  };

  const onExportingPND = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      let element = null;
      const tax = payRunDetailEmployees[index].payrunDetailMain.find(
        (x) => x.idPayrollType === 12
      );

      if (tax) {
        element = {
          ลำดับที่: index + 1,
          เลขประจำตัวผู้เสียภาษี: payRunDetailEmployees[index].personalId,
          คำนำหน้า: payRunDetailEmployees[index].title_TH,
          ชื่อ: payRunDetailEmployees[index].firstname_TH,
          สกุล: payRunDetailEmployees[index].lastname_TH,
          เงินได้ตามมาตรา: "401N",
          วันเดือนปีที่จ่าย: dayjs(payRunDetailEmployees[index].payDate).format(
            "DD/MM/BBBB"
          ),
          จำนวนเงินได้: tax.valueActual || 0,
          "ภาษีหัก ณ ที่จ่าย": tax.value || 0,
          เงื่อนไข: 1,
        };
        elementList.push(element);
      }
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `PND1.xlsx`, { type: "file" });
  };

  const onExportingSSO = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      let element = null;
      const sso = payRunDetailEmployees[index].payrunDetailMain.find(
        (x) => x.idPayrollType === 11
      );
      if (sso) {
        element = {
          เลขประจำตัวประชาชน: payRunDetailEmployees[index].personalId,
          คำนำหน้าชื่อ: payRunDetailEmployees[index].title_TH,
          ชื่อผู้ประกันตน: payRunDetailEmployees[index].firstname_TH,
          นามสกุลผู้ประกันตน: payRunDetailEmployees[index].lastname_TH,
          ค่าจ้าง: sso.valueActual || 0,
          จำนวนเงินสมทบ: sso.value,
        };
        elementList.push(element);
      }
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `SSO.xlsx`, { type: "file" });
  };

  return (
    <Fragment>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        {payRunDetail.isDraft === 1 ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ButtonBlue
              variant="contained"
              onClick={handleClosePayroll}
              style={{ marginRight: 8 }}
            >
              ปิดรอบบัญชี
            </ButtonBlue>
            <ButtonBlue
              variant="contained"
              color="error"
              startIcon={<DeleteForeverRoundedIcon />}
              onClick={handleDeletePayroll}
              style={{ marginRight: 8 }}
            >
              ยกเลิกรายการ
            </ButtonBlue>
          </div>
        ) : (
          <Fragment>
            <ButtonBlue
              aria-controls={openBank ? "bank-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openBank ? "true" : undefined}
              onClick={handleClickOpenMenuBank}
              variant="contained"
              startIcon={<DownloadRoundedIcon />}
              style={{ marginRight: 16 }}
            >
              ไฟล์ธนาคาร
            </ButtonBlue>
            <Menu
              id="bank-menu"
              anchorEl={anchorElBank}
              open={openBank}
              onClose={handleCloseMenuBank}
              MenuListProps={{
                "aria-labelledby": "bank-button",
              }}
            >
              <MenuItem onClick={onExportingBBL}>ธนาคารกรุงเทพ</MenuItem>
              <MenuItem onClick={onExportingKbank}>ธนาคารกสิกรไทย</MenuItem>
              <MenuItem onClick={onExportingSCB}>ธนาคารไทยพาณิชย์</MenuItem>
            </Menu>
            <ButtonBlue
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickOpenMenu}
              variant="outlined"
              startIcon={<DownloadRoundedIcon />}
            >
              ไฟล์ต่างๆ
            </ButtonBlue>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={onExportingSSO}>ประกันสังคม</MenuItem>
              <MenuItem onClick={onExportingPND}>ภ.ง.ด. 1</MenuItem>
            </Menu>
          </Fragment>
        )}

        {/* <ButtonBlue
          onClick={onExporting}
          variant="outlined"
          startIcon={<DownloadRoundedIcon />}
        >
          ดาวน์โหลด
        </ButtonBlue> */}
        {/* <ButtonBlue
          onClick={onExportingBBL}
          variant="outlined"
          startIcon={<DownloadRoundedIcon />}
        >
          ดาวน์โหลด BBL
        </ButtonBlue> */}
      </div>
      <StyledCard>
        <StyledCardContent>
          <StyledWrapDataGrid>
            <DataGrid
              dataSource={data ? data : []}
              showBorders={false}
              showColumnLines={false}
              rowAlternationEnabled={true}
              columnAutoWidth={true}
              onSelectionChanged={onSelectionChanged}
              onExporting={onExporting}
            >
              <ColumnChooser enabled={true} mode={"select"}>
                <Position
                  my="right top"
                  at="right bottom"
                  of=".dx-datagrid-column-chooser-button"
                />

                <ColumnChooserSearch
                  enabled={true}
                  editorOptions={{ placeholder: "Search column" }}
                />

                <ColumnChooserSelection
                  allowSelectAll={true}
                  selectByClick={true}
                />
              </ColumnChooser>

              <Column
                caption="ลำดับ"
                dataField="index"
                width={60}
                allowFiltering={false}
                alignment="center"
                cssClass="column-info"
              />

              <Column
                caption="รหัสพนักงาน"
                dataField="employeeID"
                dataType="string"
                width={120}
                cssClass="column-info"
              />

              <Column
                caption="คำนำหน้า"
                dataType="string"
                cssClass="column-info"
                dataField="title_TH"
              ></Column>
              <Column
                caption="ชื่อ"
                dataType="string"
                cssClass="column-info"
                dataField="firstname_TH"
              >
                <HeaderFilter allowSearch={true} />
              </Column>
              <Column
                caption="สกุล"
                dataType="string"
                cssClass="column-info"
                dataField="lastname_TH"
              >
                <HeaderFilter allowSearch={true} />
              </Column>

              <Column
                dataField="personalId"
                caption="รหัสบัตรประชาชน"
                cssClass="column-info"
              />

              <Column
                dataField="divisionName"
                caption="ฝ่าย"
                dataType="string"
                cssClass="column-info"
              />
              <Column
                dataField="departmentName"
                caption="ส่วน"
                dataType="string"
                cssClass="column-info"
              />
              <Column
                dataField="sectionName"
                caption="แผนก"
                dataType="string"
                cssClass="column-info"
              />

              <Column
                dataField="positionName"
                caption="ตำแหน่ง"
                dataType="string"
                cssClass="column-info"
              />

              <Column
                dataField="workingType"
                caption="ประเภทพนักงาน"
                dataType="string"
                cssClass="column-info"
              />

              <Column
                dataField="paymentTypeName"
                caption="การจ่าย"
                dataType="string"
                cssClass="column-info"
              />

              <Column
                dataField="costCenterCharge"
                caption="Cost Center"
                dataType="string"
                cssClass="column-info"
              />

              <Column
                dataField="employeeStatusType"
                caption="สถานะพนักงาน"
                dataType="string"
                cssClass="column-info"
              />

              <Column
                dataField="workingDay"
                caption="วันทำงาน"
                dataType="string"
                cssClass="column-info"
                alignment="center"
              />

              <Column
                dataField="salaryPaid"
                caption="ค่าจ้าง"
                format="###,###,##0.00"
                cssClass="column-salary"
                minWidth={100}
              />
              <Column
                dataField="salaryActual"
                caption="ค่าจ้าง(หลังหักมาสาย/กลับก่อน/ขาดงาน)"
                format="###,###,##0.00"
                cssClass="column-salary"
                minWidth={100}
              />

              {gridColumnsOT.map((item, key) => (
                <Column
                  dataField={item.dataField}
                  caption={item.caption}
                  format={item.format}
                  cssClass={item.cssClass}
                />
              ))}

              <Column
                dataField="totalOTHours"
                caption={`รวม OT (ชม.)`}
                format="###,###,##0.00"
                cssClass="column-ot"
              />
              <Column
                dataField="totalOT"
                caption={`รวม OT (บาท)`}
                format="###,###,##0.00"
                cssClass="column-ot"
              />

              {gridColumnsShift.map((item, key) => (
                <Column
                  dataField={item.dataField}
                  caption={item.caption}
                  format="###,###,##0.00"
                  cssClass={item.cssClass}
                />
              ))}

              <Column
                dataField="shiftSum"
                caption="รวมค่ากะ"
                format="###,###,##0.00"
                cssClass="column-shift"
              />

              {gridColumnsAddition.map((item, key) => (
                <Column
                  dataField={item.dataField}
                  caption={item.caption}
                  format="###,###,##0.00"
                  cssClass={item.cssClass}
                />
              ))}

              <Column
                dataField="totalEarnings"
                caption="รวมเงินได้"
                format="###,###,##0.00"
                cssClass="column-total-earnings"
              />

              <Column
                dataField="pfName"
                caption="ชื่อกองทุนสำรองเลี้ยงชีพ"
                cssClass="column-info"
              />

              <Column
                dataField="pfCompanyPercent"
                caption={`กองทุนสำรองเลี้ยงชีพส่วนบริษัท (%)`}
                format="###,###,##0.00%"
                cssClass="column-info"
              />

              <Column
                dataField="pfCompanyTHB"
                caption={`กองทุนสำรองเลี้ยงชีพส่วนบริษัท (บาท)`}
                format="###,###,##0.00"
                cssClass="column-info"
              />

              <Column
                dataField="pfEmployeePercent"
                caption={`กองทุนสำรองเลี้ยงชีพส่วนพนักงาน (%)`}
                format="###,###,##0.00%"
                cssClass="column-info"
              />

              <Column
                dataField="pfEmployeeTHB"
                caption={`กองทุนสำรองเลี้ยงชีพส่วนพนักงาน (บาท)`}
                format="###,###,##0.00"
                cssClass="column-pf"
              />

              <Column
                dataField="ssoEmployee"
                caption="ประกันสังคม"
                format="###,###,##0.00"
                cssClass="column-sso"
              />

              <Column
                dataField="tax"
                caption="ภาษี"
                format="###,###,##0.00"
                cssClass="column-tax"
              />

              <Column
                dataField="lateEarlyMinute"
                caption={`มาสาย/กลับก่อน (นาที)`}
                format="###,###,##0.00"
                cssClass="column-tax"
              />

              <Column
                dataField="lateEarly"
                caption={`มาสาย/กลับก่อน (บาท)`}
                format="###,###,##0.00"
                cssClass="column-tax"
              />

              <Column
                dataField="absentDay"
                caption={`ขาดงาน (วัน)`}
                format="###,###,##0.00"
                cssClass="column-tax"
              />

              <Column
                dataField="absent"
                caption={`ขาดงาน (บาท)`}
                format="###,###,##0.00"
                cssClass="column-tax"
              />

              {gridColumnsDeduction.map((item, key) => (
                <Column
                  dataField={item.dataField}
                  caption={item.caption}
                  format={item.format}
                  cssClass={item.cssClass}
                />
              ))}

              <Column
                dataField="totalDeductions"
                caption="รวมเงินหัก"
                format="###,###,##0.00"
                cssClass="column-total-deduction"
              />

              <Column
                dataField="net"
                caption="เงินได้สุทธิ"
                format="###,###,##0.00"
                cssClass="column-net"
              />

              <Column
                dataField="bookBank"
                caption="ชื่อธนาคาร"
                cssClass="column-info"
              />

              <Column
                dataField="bookID"
                caption="เลขที่บัญชีธนาคาร"
                cssClass="column-info"
              />

              {/* <Column
                dataField="ytdIrregular"
                caption="รวมเงินได้ทั้งปี(ไม่คงที่)"
                cssClass="column-accumulate"
                format="###,###,##0.00"
              /> */}

              <Column
                dataField="accumulateEarnings"
                caption="เงินได้สะสม"
                cssClass="column-accumulate"
                format="###,###,##0.00"
              />
              <Column
                dataField="accumulateTax"
                caption="ภาษีสะสม"
                cssClass="column-accumulate"
                format="###,###,##0.00"
              />
              <Column
                dataField="accumulateSSO"
                caption="ประกันสังคมสะสม"
                cssClass="column-accumulate"
                format="###,###,##0.00"
              />
              <Column
                dataField="accumulatePF"
                caption="กองทุนสำรองเลี้ยงชีพสะสม"
                cssClass="column-accumulate"
                format="###,###,##0.00"
              />

              <Export enabled={true} allowExportSelectedData={true} />
              <Paging defaultPageSize={20} />
              <Pager
                visible={true}
                allowedPageSizes={[10, 20, 30]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
                showInfo={true}
              />
              <Selection mode="single" />
              <Sorting mode="multiple" />
              <Scrolling columnRenderingMode="virtual" />
              <FilterRow visible={false} />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={240} placeholder="Search..." />
              <Summary>
                <TotalItem
                  column="salaryPaid"
                  customizeText={customizeNumber}
                  summaryType="sum"
                />
                <TotalItem
                  column="salaryActual"
                  customizeText={customizeNumber}
                  summaryType="sum"
                />
                <TotalItem
                  column="totalOTHours"
                  customizeText={customizeHours}
                  summaryType="sum"
                />
                <TotalItem
                  column="totalOT"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />

                <TotalItem
                  column="totalShift"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />

                {gridColumnsAddition.map((item, key) => (
                  <TotalItem
                    column={item.dataField}
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />
                ))}

                <TotalItem
                  column="totalEarnings"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />

                <TotalItem
                  column="pfCompanyTHB"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />

                <TotalItem
                  column="pfEmployeeTHB"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />

                <TotalItem
                  column="ssoEmployee"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />
                <TotalItem
                  column="tax"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />

                <TotalItem
                  column="lateEarly"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />

                <TotalItem
                  column="absent"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />

                {gridColumnsDeduction.map((item, key) => (
                  <TotalItem
                    column={item.dataField}
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />
                ))}

                <TotalItem
                  column="totalDeductions"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />
                <TotalItem
                  column="net"
                  summaryType="sum"
                  customizeText={customizeNumber}
                />
                <TotalItem column="chargeCompensated" summaryType="sum" />
              </Summary>
            </DataGrid>

            <DialogEdit
              open={openDialog}
              handleClose={handleCloseDialog}
              selectedRowsData={selectedRowsData}
              handleChangeAlertType={handleChangeAlertType}
              handleOpenAlert={handleOpenAlert}
              handleCloseAlert={handleCloseAlert}
              handleGetAllDetail={handleGetAllDetail}
            />
          </StyledWrapDataGrid>
        </StyledCardContent>
      </StyledCard>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </Fragment>
  );
};
export default TableEmployee;
