import dayjs from "dayjs";
let isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export const calculatorOvertime = (start, end, options) => {
	const {
		prePattern,
		curPattern,
		nextPattern,
		idPaymentType,
		attendances,
		requestTime,
		isFinger
	} = options;

	let preIsWorking = (prePattern.isWorkingDay && !prePattern.isHoliday && !prePattern.isCompensationHoliday)? 1: 0;
	let preStart = dayjs(`${prePattern.date} ${prePattern.timeIn}`);
	let preEnd = preStart.add(prePattern.workingHours, "minute");
	let prePeriod = prePattern.period;

	let curIsWorking = (curPattern.isWorkingDay && !curPattern.isHoliday && !curPattern.isCompensationHoliday)? 1: 0;
	let curStart = dayjs(`${curPattern.date} ${curPattern.timeIn}`);
	let curEnd = curStart.add(curPattern.workingHours, "minute");
	let curPeriod = curPattern.period;

	let nextIsWorking = (nextPattern.isWorkingDay && !nextPattern.isHoliday && !nextPattern.isCompensationHoliday)? 1: 0;
	let nextStart = dayjs(`${nextPattern.date} ${nextPattern.timeIn}`);
	let nextEnd = nextStart.add(nextPattern.workingHours, "minute");
	let nextPeriod = nextPattern.period;

	let startBreakHourDate = dayjs(`${start.format("YYYY-MM-DD")} 12:00:00`);
	let endBreakHourDate = dayjs(`${start.format("YYYY-MM-DD")} 13:00:00`);

	const workStartDate = dayjs(`${curPattern.date} ${curPattern.timeIn}`);
	const workEndDate = workStartDate.add(curPattern.workingHours, "minute", true);

	let xOT = 0;
	let xOTHoliday = 0;
	let xWorkingDailyHoliday = 0;
	let	xWorkingMonthlyHoliday = 0;
	let overlapWorking = 0;
	let isInTimeFrame = false;

	if(isFinger === 1){
		let startTemp = dayjs(start);
		let endTemp = dayjs(end);

		let listAttendancesTemp = [];
		Object.keys(attendances).map(k => {
			attendances[k].map((att, index) => {
				if(!listAttendancesTemp[index]){
					listAttendancesTemp[index]={
						checkIn: null,
						checkOut: null,
						type: "IN/OUT"
					}
				}
				if(att){
					if(att.isCheckIn === 1){
						listAttendancesTemp[index].checkIn = dayjs(att.attendanceTextDateTime).format("DD/MM/YYYY HH:mm");
						// `${curStart.format("DD/MM/YYYY")} ${att.attendanceTextTime}`;
					} else {
						listAttendancesTemp[index].checkOut = dayjs(att.attendanceTextDateTime).format("DD/MM/YYYY HH:mm");
						// `${curStart.format("DD/MM/YYYY")} ${att.attendanceTextTime}`;
					}
				}
			})
		});

		if(requestTime && requestTime.length > 0){
			requestTime.map(time => {
				if(
					(time.isDoubleApproval === 1 && time.isManagerLV1Approve !== 0 && time.isManagerLV2Approve !== 0) ||
					(time.isDoubleApproval === 0 && (
						(time.approvalLevel === 1 && time.isManagerLV1Approve !== 0) || 
						(time.approvalLevel === 2 && time.isManagerLV2Approve !== 0)
					))
				){
					listAttendancesTemp.push({
						checkIn: time.startText,
						checkOut: time.endText,
						type: "RequestTime"
					});
				}
			});
		}
		
		listAttendancesTemp.sort((a, b) => {
			return dayjs(a.checkIn, "DD/MM/YYYY HH:mm").diff(dayjs(b.checkIn, "DD/MM/YYYY HH:mm"), "minute");
		});
				
		listAttendancesTemp.map((attendance) => {
			if(attendance.checkIn && attendance.checkOut){
				let checkIn = dayjs(attendance.checkIn, "DD/MM/YYYY HH:mm");
				let checkOut = dayjs(attendance.checkOut, "DD/MM/YYYY HH:mm");
				if(
					startTemp.isBetween(checkIn, checkOut, "minute", "[)") &&
					endTemp.isBetween(checkIn, checkOut, "minute", "(]")
				){
					isInTimeFrame = true;
				} else if (startTemp.isBetween(checkIn, checkOut, "minute", "[)")){
					startTemp = checkOut;
				} else if (endTemp.isBetween(checkIn, checkOut, "minute", "(]")){
					endTemp = checkIn
				}
			}
		});
	} else {
		isInTimeFrame = true;
	}

	if(curPattern.idWorkingType === 1){
		let ot_previous = dayjs(end.isBefore(preEnd)? end: preEnd).diff(start.isAfter(preStart)? start: preStart, 'minute', true);
		if(ot_previous > 0){
			if(preIsWorking === 1){
				overlapWorking += ot_previous;
			} else if(preIsWorking === 0){
				if(idPaymentType === 2){
					xWorkingMonthlyHoliday += ot_previous;
				} else if(idPaymentType === 1){
					xWorkingDailyHoliday += ot_previous;
				}
			}
		} else {
			ot_previous = 0;
		}
	
		let ot_pre_current = dayjs(end.isBefore(curStart)? end: curStart).diff(start.isAfter(preEnd)? start: preEnd, 'minute', true)
		if(ot_pre_current > 0){
			if(preIsWorking === 1){
				xOT += ot_pre_current
			} else if(preIsWorking === 0){
				xOTHoliday += ot_pre_current
			}
		} else {
			ot_pre_current = 0;
		}
	
		let ot_current = dayjs(end.isBefore(curEnd)? end: curEnd).diff(start.isAfter(curStart)? start: curStart, 'minute', true)
		if(ot_current > 0){
			if(curIsWorking === 1){
				overlapWorking += ot_current;
			} else if(curIsWorking === 0){
				if(idPaymentType === 2){
					xWorkingMonthlyHoliday += ot_current;
				} else if(idPaymentType === 1){
					xWorkingDailyHoliday += ot_current;
				}
			}
		} else {
			ot_current = 0;
		}
	
		let ot_pre_next = dayjs(end.isBefore(nextStart)? end: nextStart).diff(start.isAfter(curEnd)? start: curEnd, 'minute', true)
		if(ot_pre_next > 0){
	
			if(curIsWorking === 1 && curPeriod === 3 && nextIsWorking === 0){
				xOTHoliday += ot_pre_next
			} else if (curIsWorking === 0 && curPeriod === 1 && nextPeriod === 3){

				let curTimeInPlusOneDay = curStart.add(1, 'day');

				let overCurTimeInPlusOneDay = dayjs(end.isBefore(nextStart)? end: nextStart).diff(curTimeInPlusOneDay, 'minute', true)
	
				if(overCurTimeInPlusOneDay > 0){
					if(idPaymentType === 2){
						xWorkingMonthlyHoliday += overCurTimeInPlusOneDay;
					} else if(idPaymentType === 1){
						xWorkingDailyHoliday += overCurTimeInPlusOneDay;
					}
					xOTHoliday += (ot_pre_next - overCurTimeInPlusOneDay)
				} else {
					xOTHoliday += ot_pre_next
				}
			} else if(curIsWorking === 1){
				xOT += ot_pre_next
			} else if(curIsWorking === 0){
				xOTHoliday += ot_pre_next
			}
		} else {
			ot_pre_next = 0;
		}
	
		let ot_next = dayjs(end.isBefore(nextEnd)? end: nextEnd).diff(start.isAfter(nextStart)? start: nextStart, 'minute', true)
		if(ot_next > 0){
			if(nextIsWorking === 1){
				overlapWorking += ot_next;
			} else if(nextIsWorking === 0){
				if(idPaymentType === 2){
					xWorkingMonthlyHoliday += ot_next;
				} else if(idPaymentType === 1){
					xWorkingDailyHoliday += ot_next;
				}
			}
		} else {
			ot_next = 0;
		}
	}

	if(curPattern.idWorkingType === 2){
		let ot_pre_current = dayjs(end.isBefore(curStart)? end: curStart).diff(start, 'minute', true)
		if(ot_pre_current > 0){
			if(curIsWorking === 1){
				xOT += ot_pre_current
			} else if(curIsWorking === 0){
				xOTHoliday += ot_pre_current
			}
		} else {
			ot_pre_current = 0;
		}

		let ot_prebreak = dayjs(end.isBefore(startBreakHourDate)? end: startBreakHourDate).diff(start.isAfter(curStart)? start: curStart, 'minute', true)
		if(ot_prebreak > 0){
			if(curIsWorking === 1){
				overlapWorking += ot_prebreak
			} else if(curIsWorking === 0){
				if(idPaymentType === 2){
					xWorkingMonthlyHoliday += ot_prebreak;
				} else if(idPaymentType === 1){
					xWorkingDailyHoliday += ot_prebreak;
				}
			}
		}

		let ot_afterbreak = dayjs(end.isBefore(workEndDate)? end: workEndDate).diff(start.isAfter(endBreakHourDate)? start: endBreakHourDate, 'minute', true)
		if(ot_afterbreak > 0){
			if(curIsWorking === 1){
				overlapWorking += ot_afterbreak
			} else if(curIsWorking === 0){
				if(idPaymentType === 2){
					xWorkingMonthlyHoliday += ot_afterbreak;
				} else if(idPaymentType === 1){
					xWorkingDailyHoliday += ot_afterbreak;
				}
			}
		}

		let ot_afterTimeOut = dayjs(end).diff(start.isAfter(workEndDate)? start: workEndDate, 'minute', true)
		if(ot_afterTimeOut > 0){
			if(curIsWorking === 1){
				xOT += ot_afterTimeOut
			} else if(curIsWorking === 0){
				xOTHoliday += ot_afterTimeOut
			}
		}
	}

	const result = {
		xOT: xOT,
		xOTHoliday: xOTHoliday,
		xWorkingDailyHoliday: xWorkingDailyHoliday,
		xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
		overlapWorking: overlapWorking,
		// isInTimeFrame: true
		isInTimeFrame: isInTimeFrame
	}
	
	// console.log(result)

	return result;

}

const InterSectionTime = (start, end, startCheck, endCheck) => {
	let time = 0;
	if(
		startCheck.isBetween(start, end, "minute") &&
		endCheck.isBetween(start, end, "minute")
	){
		time = endCheck.diff(startCheck, "minute");
	} else if(
		startCheck.isBetween(start, end, "minute")
	){
		time = end.diff(startCheck, "minute");
	} else if(
		endCheck.isBetween(start, end, "minute")
	){
		time = endCheck.diff(start, "minute");
	}
	return time;
};

export const calculatorOvertimeByWorkingHours = (start, end, options) => {
	const {
		curPattern,
		idPaymentType,
		attendance,
		requestTime,
		date,
		ot
	} = options;

	let curIsWorking = (curPattern.isWorkingDay && !curPattern.isHoliday && !curPattern.isCompensationHoliday)? 1: 0;

	let startBreakHourDate = dayjs(`${date} 12:00:00`);
	let endBreakHourDate = dayjs(`${date} 13:00:00`);

	let xOT = 0;
	let xOTHoliday = 0;
	let xWorkingDailyHoliday = 0;
	let	xWorkingMonthlyHoliday = 0;
	let overlapWorking = 0;

	if(
		start.isBefore(
			dayjs(`${date} 00:00:00`)
		)
	){
		start = dayjs(`${date} 00:00:00`);
	}

	if(
		end.isAfter(
			dayjs(`${date} 00:00:00`).add(1,'day')
		)
	){
		end = dayjs(`${date} 00:00:00`).add(1,'day');
	}

	let workTime = 0;
	let otTime = end.diff(start, 'minute');

	if(
		start.isBetween(startBreakHourDate, endBreakHourDate, "minute") &&
		end.isBetween(startBreakHourDate, endBreakHourDate, "minute")
	){
		otTime = 0;
	} else {
		otTime -= InterSectionTime(start, end, startBreakHourDate, endBreakHourDate);
	}

	if(curIsWorking === 1){
		let arrayWorkTime = [];
		if(attendance && attendance.checkIn.length > 0){
			attendance.checkIn.map((time, index) => {
				if(time && attendance.checkOut[index]){
					let checkIn = dayjs(`${date} ${time.attendanceTextTime}:00`);
					let checkOut = dayjs(`${date} ${attendance.checkOut[index].attendanceTextTime}:00`);
					arrayWorkTime.push({ start : checkIn, end : checkOut });
					workTime += (checkOut.diff(checkIn, "minute") - InterSectionTime(checkIn, checkOut, startBreakHourDate, endBreakHourDate));
				}
			});
		}

		if(requestTime && requestTime.length > 0){
			requestTime.map(request => {
				let requestStart = dayjs(`${request.startText}:00`, 'DD-MM-YYYY HH:mm:ss');
				let requestEnd = dayjs(`${request.endText}:00`, 'DD-MM-YYYY HH:mm:ss');
				arrayWorkTime.push({ start : requestStart, end : requestEnd });
				workTime += (requestEnd.diff(requestStart, "minute") - InterSectionTime(requestStart, requestEnd, startBreakHourDate, endBreakHourDate));
			});
		}

		if(ot && ot.length > 0){
			ot.map(eachOT => {
				let OTStart = dayjs(`${eachOT.startText}:00`, 'DD-MM-YYYY HH:mm:ss');
				let OTEnd = dayjs(`${eachOT.endText}:00`, 'DD-MM-YYYY HH:mm:ss');
				arrayWorkTime.map(time => {
					if(
						time.start.isBetween(OTStart, OTEnd, "minute", "[]") &&
						time.end.isBetween(OTStart, OTEnd, "minute", "[]")
					){
						workTime -= time.start.diff(time.end, "minute");
					} else {
						workTime -= InterSectionTime(time.start, time.end, OTStart, OTEnd);
					}
				});
			});
		}

		arrayWorkTime.map(time => {
			if(
				time.start.isBetween(start, end, "minute", "[]") &&
				time.end.isBetween(start, end, "minute", "[]")
			){
				workTime -= time.end.diff(time.start, "minute");
			} else {
				workTime -= InterSectionTime(time.start, time.end, start, end);
			}
		});

		if(workTime < (curPattern.workingHours - (curPattern.breakTime === 0 ? 60 : 0))){
			overlapWorking = curPattern.workingHours - workTime;
		} else {
			xOT += otTime;
		}
	} else {
		let workTime = 0;

		if(ot && ot.length > 0){
			ot.map(eachOT => {
				let OTStart = dayjs(`${eachOT.startText}:00`, 'DD-MM-YYYY HH:mm:ss');
				let OTEnd = dayjs(`${eachOT.endText}:00`, 'DD-MM-YYYY HH:mm:ss');
				workTime += (OTEnd.diff(OTStart, "minute") - InterSectionTime(OTStart, OTEnd, startBreakHourDate, endBreakHourDate));
			});
		}

		let realWorkingHours = curPattern.workingHours - (curPattern.breakTime === 0 ? 60 : 0);
		if(workTime >= realWorkingHours){
			xOTHoliday += otTime;
		} else {
			let timeOnWorkHours = (otTime > (realWorkingHours - workTime)) ? (realWorkingHours - workTime) : otTime;
			if(idPaymentType === 2){
				xWorkingMonthlyHoliday += timeOnWorkHours;
			} else if(idPaymentType === 1){
				xWorkingDailyHoliday += timeOnWorkHours;
			}

			let timeOnOT = otTime - timeOnWorkHours;
			if(timeOnOT > 0){
				xOTHoliday += timeOnOT;
			}
		}
	}

	const result = {
		xOT: xOT,
		xOTHoliday: xOTHoliday,
		xWorkingDailyHoliday: xWorkingDailyHoliday,
		xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
		overlapWorking: overlapWorking,
	}

	return result;

}