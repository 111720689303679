import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertResponse from "../../../shared/general/AlertResponse";
import DrawerCustom from "../../../shared/general/Drawer";
import { getEmailReportById } from "../../../../../actions/email"; import { styled } from "@mui/material/styles";
import {
    TextField,
    Grid,
    Popper,
    Box,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import SaveIcon from "@mui/icons-material/Save";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { addEmailReport } from "../../../../../actions/email";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const StyledBoxSearch = styled(Box)({
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiOutlinedInput-root": {
        height: 50,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
    },
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const Contract = (props) => {
    const dispatch = useDispatch();
    const { result: email } = useSelector((state) => state.email)
    const { open, handleClose, data, setData } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);

    useEffect(() => {
        if (email)
            setData({ ...data, autoEmail: email.day });
    }, [email])

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    useEffect(() => {
        dispatch(getEmailReportById(data.idEmployees, data.ReportName))
    }, [])

    const submit = async () => {
        const formData = {
            ReportName: data.ReportName,
            idEmployees: data.idEmployees,
            day: data.autoEmail == "วันสุดท้ายของเดือน" ? 0 : data.autoEmail
        }
        const result = await dispatch(addEmailReport(formData))
        if (result.status === 200) {
            handleChangeAlertType("success");
            handleOpenAlert(true);
        } else {
            handleChangeAlertType("ไม่สำเร็จ");
            handleOpenAlert(true);
        }
    }

    return (
        <DrawerCustom
            title="ตั้งค่าวันที่ส่ง email อัตโนมัติ"
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot sx={{ width: 470 }}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={6} className="GridTopicInput">วันที่ส่ง Email</Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledBoxSearch>
                            <StyledAutocomplete
                                options={["วันสุดท้ายของเดือน", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28]}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setData({ ...data, autoEmail: newValue });
                                    } else {
                                        setData({ ...data, autoEmail: null });
                                    }
                                }}
                                value={data.autoEmail == 0 ? "วันสุดท้ายของเดือน" : data.autoEmail}
                                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={"filled"}
                                        placeholder={"เลือกวันที่"}
                                    />
                                )}
                                PopperComponent={StyledPopper}
                            />
                        </StyledBoxSearch>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", paddingRight: "16px" }}>
                        <ButtonBlue
                            variant="outlined"
                            startIcon={<SaveIcon />}
                            onClick={submit}
                            disabled={data.autoEmail === null}
                        >
                            บันทึก
                        </ButtonBlue>
                    </Grid>
                </Grid>
            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>
    );
}
export default Contract;
