import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import {
    TextField,
    FormControl,
    Grid,
    Popper,
    Typography,
    Box,
    Divider
} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import XLSX from "xlsx";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import SaveIcon from "@mui/icons-material/Save";
import { getLeaveRequestByCompany, getAllRequestTimeBy, getAllRequestTimeWithDrawBy, getAllLeaveWithDrawBy } from "../../../../actions/report";
import { getPayrollSetting } from "../../../../actions/paytypes";
import SelectCompany from "../shared/selectCompany";
import { addEmailReport, getEmailReportById } from "../../../../actions/email";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

const StyledBoxSearch = styled(Box)({
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const List = ["รับรองเวลาทำงาน", "ทำงานล่วงเวลา", "ขอลางาน"]

const Approve = (props) => {
    const dispatch = useDispatch();
    const { result: email } = useSelector((state) => state.email)
    const { result: requestTimeList } = useSelector((state) => state.requestTime);
    const { result: requestTimeWithDrawList } = useSelector((state) => state.requestTimeWithDraw);
    const { result: leaveEmployeesList } = useSelector((state) => state.leaveEmployees);
    const { result: leaveEmployeeWithdrawsList } = useSelector((state) => state.leaveEmployeesWithDraw);
    const { result: payrollSetting } = useSelector((state) => state.payrollSetting);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { open, handleClose } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [inputSearch, setInputSearch] = useState(new Date());
    const [value, setValue] = React.useState("year");
    const [Type, setType] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [autoEmail, setAutoEmail] = useState(null)

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const handleChangeCompany = (newValue) => {
        if (newValue) {
            setSelectedCompany(newValue.idCompany);
        } else {
            setSelectedCompany(null);
        }
    };

    useEffect(()=>{
        if(email)
        setAutoEmail(email.day)
    },[email])

    useEffect(() => {
        if (value == "year") {
            dispatch(getAllRequestTimeBy(selectedCompany, {
                start: dayjs(inputSearch).set('month', 0).startOf('month'),
                end: dayjs(inputSearch).set('month', 11).endOf('month')
            }));
            dispatch(getAllRequestTimeWithDrawBy(selectedCompany, {
                start: dayjs(inputSearch).set('month', 0).startOf('month'),
                end: dayjs(inputSearch).set('month', 11).endOf('month')
            }));
            dispatch(getLeaveRequestByCompany(selectedCompany, {
                start: dayjs(inputSearch).set('month', 0).startOf('month'),
                end: dayjs(inputSearch).set('month', 11).endOf('month')
            }));
            dispatch(getAllLeaveWithDrawBy(selectedCompany, {
                start: dayjs(inputSearch).set('month', 0).startOf('month'),
                end: dayjs(inputSearch).set('month', 11).endOf('month')
            }));
        } else {
            dispatch(getAllRequestTimeBy(selectedCompany, {
                start: dayjs(inputSearch).startOf('month'),
                end: dayjs(inputSearch).endOf('month')
            }));
            dispatch(getAllRequestTimeWithDrawBy(selectedCompany, {
                start: dayjs(inputSearch).startOf('month'),
                end: dayjs(inputSearch).endOf('month')
            }));
            dispatch(getLeaveRequestByCompany(selectedCompany, {
                start: dayjs(inputSearch).startOf('month'),
                end: dayjs(inputSearch).endOf('month')
            }));
            dispatch(getAllLeaveWithDrawBy(selectedCompany, {
                start: dayjs(inputSearch).startOf('month'),
                end: dayjs(inputSearch).endOf('month')
            }));
        }

    }, [inputSearch, selectedCompany, value])

    useEffect(() => {
        dispatch(getEmailReportById(userProfile.idEmployees, "approvelist"))
        dispatch(getPayrollSetting());
    }, [])

    const handleSubmit = () => {
        let dataToExcelWaiting = [];
        let dataToExcel = [];
        let elementListWaiting = [];
        let elementList = [];

        if (Type === "รับรองเวลาทำงาน" && requestTimeList && requestTimeWithDrawList) {
            requestTimeList.map((r) => {
                if (r.idRequestType === 1) {
                    if (r.isDoubleApproval === 1) {
                        if (
                            (!r.isManagerLV1Approve || !r.isManagerLV2Approve) &&
                            !(r.isManagerLV1Approve === 0 || r.isManagerLV2Approve === 0)
                        ) {
                            dataToExcelWaiting.push(r)
                        } else dataToExcel.push(r)
                    } else {
                        if (r.approvalLevel === 1) {
                            if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
                                dataToExcelWaiting.push(r)
                            } else dataToExcel.push(r)
                        } else if (r.approvalLevel === 2) {
                            if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
                                dataToExcelWaiting.push(r)
                            } else dataToExcel.push(r)
                        } else dataToExcel.push(r)
                    }
                }
            })
            requestTimeWithDrawList.map((r) => {
                if (r.idRequestType === 1) {
                    if (r.isApprove === null) {
                        dataToExcelWaiting.push(r)
                    } else dataToExcel.push(r)
                }
            })

            for (let index = 0; index < dataToExcelWaiting.length; index++) {
                const element = {
                    // ลำดับที่: dataToExcelWaiting[index].idRequestTime,
                    รหัสพนักงาน: dataToExcelWaiting[index].employeeID,
                    "ชื่อ-สกุล": `${dataToExcelWaiting[index].firstname_TH} ${dataToExcelWaiting[index].lastname_TH}`,
                    ตำแหน่ง: dataToExcelWaiting[index].positionName
                        ? dataToExcelWaiting[index].positionName
                        : "",
                    ประเภทการจ่ายเงินเดือน: dataToExcelWaiting[index].paymentRoundName
                        ? dataToExcelWaiting[index].paymentRoundName
                        : "",
                    ประเภทคำขอ: dataToExcelWaiting[index].name
                        ? dataToExcelWaiting[index].name
                        : "",
                    เวลาเริ่มต้น: dataToExcelWaiting[index].startText
                        ? dataToExcelWaiting[index].startText
                        : "",
                    เวลาสิ้นสุด: dataToExcelWaiting[index].endText
                        ? dataToExcelWaiting[index].endText
                        : "",

                    เหตุผล: dataToExcelWaiting[index].reasonName
                        ? dataToExcelWaiting[index].reasonName
                        : "-",
                    "เหตุผล(เพิ่มเติม)": dataToExcelWaiting[index].otherReason
                        ? dataToExcelWaiting[index].otherReason
                        : "-",
                    วันเวลาที่ทำรายการ: dataToExcelWaiting[index].createDateText
                        ? dataToExcelWaiting[index].createDateText
                        : "-",
                    "ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index].managerLV1_firstname_TH
                        ? `${dataToExcelWaiting[index].managerLV1_firstname_TH} ${dataToExcelWaiting[index].managerLV1_lastname_TH}`
                        : "-",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index]
                        .emailManagerLV1
                        ? dataToExcelWaiting[index].emailManagerLV1
                        : "-",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
                        dataToExcelWaiting[index].isManagerLV1Approve === null
                            ? "รออนุมัติ"
                            : dataToExcelWaiting[index].isManagerLV1Approve === 1
                                ? "อนุมัติ"
                                : "ไม่อนุมัติ",
                    "ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcelWaiting[index].isDoubleApproval === 1
                            ? dataToExcelWaiting[index].managerLV2_firstname_TH
                                ? `${dataToExcelWaiting[index].managerLV2_firstname_TH} ${dataToExcelWaiting[index].managerLV2_lastname_TH}`
                                : ""
                            : dataToExcelWaiting[index].approvalLevel === 2
                                ? dataToExcelWaiting[index].managerLV2_firstname_TH
                                    ? `${dataToExcelWaiting[index].managerLV2_firstname_TH} ${dataToExcelWaiting[index].managerLV2_lastname_TH}`
                                    : ""
                                : "",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcelWaiting[index].isDoubleApproval === 1
                            ? dataToExcelWaiting[index].emailManagerLV2
                                ? dataToExcelWaiting[index].emailManagerLV2
                                : ""
                            : dataToExcelWaiting[index].approvalLevel === 2
                                ? dataToExcelWaiting[index].emailManagerLV2
                                    ? dataToExcelWaiting[index].emailManagerLV2
                                    : ""
                                : "",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcelWaiting[index].isDoubleApproval === 1
                            ? dataToExcelWaiting[index].isManagerLV1Approve === null
                                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                                : dataToExcelWaiting[index].isManagerLV2Approve === null
                                    ? "รออนุมัติ"
                                    : ""
                            : dataToExcelWaiting[index].approvalLevel === 2
                                ? dataToExcelWaiting[index].isManagerLV2Approve &&
                                    dataToExcelWaiting[index].isManagerLV2Approve === null
                                    ? "รออนุมัติ"
                                    : ""
                                : "",
                };
                elementListWaiting.push(element);
            }

            for (let index = 0; index < dataToExcel.length; index++) {
                const element = {
                    // ลำดับที่: dataToExcel[index].idRequestTime,
                    รหัสพนักงาน: dataToExcel[index].employeeID,
                    "ชื่อ-สกุล": `${dataToExcel[index].firstname_TH} ${dataToExcel[index].lastname_TH}`,
                    ตำแหน่ง: dataToExcel[index].positionName
                        ? dataToExcel[index].positionName
                        : "",
                    ประเภทการจ่ายเงินเดือน: dataToExcel[index].paymentRoundName
                        ? dataToExcel[index].paymentRoundName
                        : "",
                    ประเภทคำขอ: dataToExcel[index].name ? dataToExcel[index].name : "",
                    เวลาเริ่มต้น: dataToExcel[index].startText
                        ? dataToExcel[index].startText
                        : "",
                    เวลาสิ้นสุด: dataToExcel[index].endText
                        ? dataToExcel[index].endText
                        : "",
                    เหตุผล: dataToExcel[index].reasonName
                        ? dataToExcel[index].reasonName
                        : "-",
                    "เหตุผล(เพิ่มเติม)": dataToExcel[index].otherReason
                        ? dataToExcel[index].otherReason
                        : "-",
                    วันเวลาที่ทำรายการ: dataToExcel[index].createDateText
                        ? dataToExcel[index].createDateText
                        : "-",
                    "ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].managerLV1_firstname_TH
                        ? `${dataToExcel[index].managerLV1_firstname_TH} ${dataToExcel[index].managerLV1_lastname_TH}`
                        : "-",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].emailManagerLV1
                        ? dataToExcel[index].emailManagerLV1
                        : "-",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
                        dataToExcel[index].isManagerLV1Approve &&
                            dataToExcel[index].isManagerLV1Approve === null
                            ? "รออนุมัติ"
                            : dataToExcel[index].isManagerLV1Approve === 1
                                ? "อนุมัติ"
                                : "ไม่อนุมัติ",
                    "ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcel[index].isDoubleApproval === 1
                            ? dataToExcel[index].managerLV2_firstname_TH
                                ? `${dataToExcelWaiting[index].managerLV2_firstname_TH} ${dataToExcelWaiting[index].managerLV2_lastname_TH}`
                                : ""
                            : dataToExcel[index].approvalLevel === 2
                                ? dataToExcel[index].managerLV2_firstname_TH
                                    ? `${dataToExcelWaiting[index].managerLV2_firstname_TH} ${dataToExcelWaiting[index].managerLV2_lastname_TH}`
                                    : ""
                                : "",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcel[index].isDoubleApproval === 1
                            ? dataToExcel[index].emailManagerLV2
                                ? dataToExcel[index].emailManagerLV2
                                : ""
                            : dataToExcel[index].approvalLevel === 2
                                ? dataToExcel[index].emailManagerLV2
                                    ? dataToExcel[index].emailManagerLV2
                                    : ""
                                : "",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcel[index].isDoubleApproval === 1
                            ? dataToExcel[index].isManagerLV1Approve &&
                                dataToExcel[index].isManagerLV1Approve === null
                                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                                : dataToExcel[index].isManagerLV2Approve &&
                                    dataToExcel[index].isManagerLV2Approve === null
                                    ? "รออนุมัติ"
                                    : dataToExcel[index].isManagerLV2Approve === 1
                                        ? "อนุมัติ"
                                        : "ไม่อนุมัติ"
                            : dataToExcel[index].approvalLevel === 2
                                ? dataToExcel[index].isManagerLV2Approve &&
                                    dataToExcel[index].isManagerLV2Approve === null
                                    ? "รออนุมัติ"
                                    : dataToExcel[index].isManagerLV2Approve === 1
                                        ? "อนุมัติ"
                                        : "ไม่อนุมัติ"
                                : "",
                };
                elementList.push(element);
            }
        } else if (Type === "ทำงานล่วงเวลา" && requestTimeList && requestTimeWithDrawList && payrollSetting) {
            requestTimeList.map((r) => {
                if (r.idRequestType === 2) {
                    if (r.isDoubleApproval === 1) {
                        if (
                            (!r.isManagerLV1Approve || !r.isManagerLV2Approve) &&
                            !(r.isManagerLV1Approve === 0 || r.isManagerLV2Approve === 0)
                        ) {
                            dataToExcelWaiting.push(r)
                        } else dataToExcel.push(r)
                    } else {
                        if (r.approvalLevel === 1) {
                            if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
                                dataToExcelWaiting.push(r)
                            } else dataToExcel.push(r)
                        } else if (r.approvalLevel === 2) {
                            if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
                                dataToExcelWaiting.push(r)
                            } else dataToExcel.push(r)
                        } else dataToExcel.push(r)
                    }
                }
            })
            requestTimeWithDrawList.map((r) => {
                if (r.idRequestType === 2) {
                    if (r.isApprove === null) {
                        dataToExcelWaiting.push(r)
                    } else dataToExcel.push(r)
                }
            })
            for (let index = 0; index < dataToExcelWaiting.length; index++) {
                const element = {
                    // ลำดับที่: dataToExcelWaiting[index].idRequestTime,
                    รหัสพนักงาน: dataToExcelWaiting[index].employeeID,
                    "ชื่อ-สกุล": `${dataToExcelWaiting[index].firstname_TH} ${dataToExcelWaiting[index].lastname_TH}`,
                    ตำแหน่ง: dataToExcelWaiting[index].positionName
                        ? dataToExcelWaiting[index].positionName
                        : "",
                    ประเภทการจ่ายเงินเดือน: dataToExcelWaiting[index].paymentRoundName
                        ? dataToExcelWaiting[index].paymentRoundName
                        : "",
                    ประเภทคำขอ: dataToExcelWaiting[index].name
                        ? dataToExcelWaiting[index].name
                        : "",
                    เวลาเริ่มต้น: dataToExcelWaiting[index].startText
                        ? dataToExcelWaiting[index].startText
                        : "",
                    เวลาสิ้นสุด: dataToExcelWaiting[index].endText
                        ? dataToExcelWaiting[index].endText
                        : "",
                    [`OT ${payrollSetting.xWorkingDailyHoliday}x (วันหยุด)`]:
                        dataToExcelWaiting[index].xWorkingDailyHoliday > 0
                            ? `${parseFloat(
                                dataToExcelWaiting[index].xWorkingDailyHoliday / 60
                            ).toFixed(2)} ชม.`
                            : "",
                    [`OT ${payrollSetting.xOT}x (วันทำงาน)`]:
                        dataToExcelWaiting[index].xOT > 0
                            ? `${parseFloat(dataToExcelWaiting[index].xOT / 60).toFixed(
                                2
                            )} ชม.`
                            : "",
                    [`OT ${payrollSetting.xWorkingDailyHoliday}x`]:
                        dataToExcelWaiting[index].xWorkingDailyHoliday > 0
                            ? `${parseFloat(
                                dataToExcelWaiting[index].xWorkingDailyHoliday / 60
                            ).toFixed(2)} ชม.`
                            : "",
                    [`OT ${payrollSetting.xOTHoliday}x`]:
                        dataToExcelWaiting[index].xOTHoliday > 0
                            ? `${parseFloat(
                                dataToExcelWaiting[index].xOTHoliday / 60
                            ).toFixed(2)} ชม.`
                            : "",
                    เหตุผล: dataToExcelWaiting[index].reasonName
                        ? dataToExcelWaiting[index].reasonName
                        : "-",
                    "เหตุผล(เพิ่มเติม)": dataToExcelWaiting[index].otherReason
                        ? dataToExcelWaiting[index].otherReason
                        : "-",
                    วันเวลาที่ทำรายการ: dataToExcelWaiting[index].createDateText
                        ? dataToExcelWaiting[index].createDateText
                        : "-",
                    "ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index].managerLV1_firstname_TH
                        ? `${dataToExcelWaiting[index].managerLV1_firstname_TH} ${dataToExcelWaiting[index].managerLV1_lastname_TH}`
                        : "-",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index]
                        .emailManagerLV1
                        ? dataToExcelWaiting[index].emailManagerLV1
                        : "-",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
                        dataToExcelWaiting[index].isManagerLV1Approve === null
                            ? "รออนุมัติ"
                            : dataToExcelWaiting[index].isManagerLV1Approve === 1
                                ? "อนุมัติ"
                                : "ไม่อนุมัติ",
                    "ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcelWaiting[index].isDoubleApproval === 1
                            ? dataToExcelWaiting[index].managerLV2_firstname_TH
                                ? `${dataToExcelWaiting[index].managerLV2_firstname_TH} ${dataToExcelWaiting[index].managerLV2_lastname_TH}`
                                : ""
                            : dataToExcelWaiting[index].approvalLevel === 2
                                ? dataToExcelWaiting[index].managerLV2_firstname_TH
                                    ? `${dataToExcelWaiting[index].managerLV2_firstname_TH} ${dataToExcelWaiting[index].managerLV2_lastname_TH}`
                                    : ""
                                : "",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcelWaiting[index].isDoubleApproval === 1
                            ? dataToExcelWaiting[index].emailManagerLV2
                                ? dataToExcelWaiting[index].emailManagerLV2
                                : ""
                            : dataToExcelWaiting[index].approvalLevel === 2
                                ? dataToExcelWaiting[index].emailManagerLV2
                                    ? dataToExcelWaiting[index].emailManagerLV2
                                    : ""
                                : "",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcelWaiting[index].isDoubleApproval === 1
                            ? dataToExcelWaiting[index].isManagerLV1Approve === null
                                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                                : dataToExcelWaiting[index].isManagerLV2Approve === null
                                    ? "รออนุมัติ"
                                    : ""
                            : dataToExcelWaiting[index].approvalLevel === 2
                                ? dataToExcelWaiting[index].isManagerLV2Approve &&
                                    dataToExcelWaiting[index].isManagerLV2Approve === null
                                    ? "รออนุมัติ"
                                    : ""
                                : "",
                };
                elementListWaiting.push(element);
            }

            for (let index = 0; index < dataToExcel.length; index++) {
                const element = {
                    // ลำดับที่: dataToExcel[index].idRequestTime,
                    รหัสพนักงาน: dataToExcel[index].employeeID,
                    "ชื่อ-สกุล": `${dataToExcel[index].firstname_TH} ${dataToExcel[index].lastname_TH}`,
                    ตำแหน่ง: dataToExcel[index].positionName
                        ? dataToExcel[index].positionName
                        : "",
                    ประเภทคำขอ: dataToExcel[index].name ? dataToExcel[index].name : "",
                    เวลาเริ่มต้น: dataToExcel[index].startText
                        ? dataToExcel[index].startText
                        : "",
                    เวลาสิ้นสุด: dataToExcel[index].endText
                        ? dataToExcel[index].endText
                        : "",
                    [`OT ${payrollSetting.xWorkingDailyHoliday}x (วันหยุด)`]:
                        dataToExcel[index].xWorkingDailyHoliday > 0
                            ? `${parseFloat(
                                dataToExcel[index].xWorkingDailyHoliday / 60
                            ).toFixed(2)} ชม.`
                            : "",
                    [`OT ${payrollSetting.xOT}x (วันทำงาน)`]:
                        dataToExcel[index].xOT > 0
                            ? `${parseFloat(dataToExcel[index].xOT / 60).toFixed(2)} ชม.`
                            : "",
                    [`OT ${payrollSetting.xWorkingDailyHoliday}x`]:
                        dataToExcel[index].xWorkingDailyHoliday > 0
                            ? `${parseFloat(
                                dataToExcel[index].xWorkingDailyHoliday / 60
                            ).toFixed(2)} ชม.`
                            : "",
                    [`OT ${payrollSetting.xOTHoliday}x`]:
                        dataToExcel[index].xOTHoliday > 0
                            ? `${parseFloat(dataToExcel[index].xOTHoliday / 60).toFixed(
                                2
                            )} ชม.`
                            : "",
                    เหตุผล: dataToExcel[index].reasonName
                        ? dataToExcel[index].reasonName
                        : "-",
                    "เหตุผล(เพิ่มเติม)": dataToExcel[index].otherReason
                        ? dataToExcel[index].otherReason
                        : "-",
                    วันเวลาที่ทำรายการ: dataToExcel[index].createDateText
                        ? dataToExcel[index].createDateText
                        : "-",
                    "ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].managerLV1_firstname_TH
                        ? `${dataToExcel[index].managerLV1_firstname_TH} ${dataToExcel[index].managerLV1_lastname_TH}`
                        : "-",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].emailManagerLV1
                        ? dataToExcel[index].emailManagerLV1
                        : "-",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
                        dataToExcel[index].isManagerLV1Approve &&
                            dataToExcel[index].isManagerLV1Approve === null
                            ? "รออนุมัติ"
                            : dataToExcel[index].isManagerLV1Approve === 1
                                ? "อนุมัติ"
                                : "ไม่อนุมัติ",
                    "ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcel[index].isDoubleApproval === 1
                            ? dataToExcel[index].managerLV2_firstname_TH
                                ? `${dataToExcel[index].managerLV2_firstname_TH} ${dataToExcel[index].managerLV2_lastname_TH}`
                                : ""
                            : dataToExcel[index].approvalLevel === 2
                                ? dataToExcel[index].managerLV2_firstname_TH
                                    ? `${dataToExcel[index].managerLV2_firstname_TH} ${dataToExcel[index].managerLV2_lastname_TH}`
                                    : ""
                                : "",
                    "E-mail ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcel[index].isDoubleApproval === 1
                            ? dataToExcel[index].emailManagerLV2
                                ? dataToExcel[index].emailManagerLV2
                                : ""
                            : dataToExcel[index].approvalLevel === 2
                                ? dataToExcel[index].emailManagerLV2
                                    ? dataToExcel[index].emailManagerLV2
                                    : ""
                                : "",
                    "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
                        dataToExcel[index].isDoubleApproval === 1
                            ? dataToExcel[index].isManagerLV1Approve &&
                                dataToExcel[index].isManagerLV1Approve === null
                                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                                : dataToExcel[index].isManagerLV2Approve &&
                                    dataToExcel[index].isManagerLV2Approve === null
                                    ? "รออนุมัติ"
                                    : dataToExcel[index].isManagerLV2Approve === 1
                                        ? "อนุมัติ"
                                        : "ไม่อนุมัติ"
                            : dataToExcel[index].approvalLevel === 2
                                ? dataToExcel[index].isManagerLV2Approve &&
                                    dataToExcel[index].isManagerLV2Approve === null
                                    ? "รออนุมัติ"
                                    : dataToExcel[index].isManagerLV2Approve === 1
                                        ? "อนุมัติ"
                                        : "ไม่อนุมัติ"
                                : "",
                };
                elementList.push(element);
            }
        } else if (Type === "ขอลางาน" && leaveEmployeesList && leaveEmployeeWithdrawsList) {
            leaveEmployeesList.map(r => {
                if (r.isApprove === null) {
                    dataToExcelWaiting.push(r)
                } else {
                    dataToExcel.push(r)
                }
            })

            leaveEmployeeWithdrawsList.map(r => {
                if (r.isApprove === null) {
                    dataToExcelWaiting.push(r)
                } else {
                    dataToExcel.push(r)
                }
            })
            for (let index = 0; index < dataToExcelWaiting.length; index++) {
                const element = {
                    // ลำดับที่: dataToExcelWaiting[index].idLeave,
                    รหัสพนักงาน: dataToExcelWaiting[index].employeeID,
                    "ชื่อ-สกุล": `${dataToExcelWaiting[index].firstname_TH} ${dataToExcelWaiting[index].lastname_TH}`,
                    ตำแหน่ง: dataToExcelWaiting[index].positionName
                        ? dataToExcelWaiting[index].positionName
                        : "",
                    ประเภทการจ่ายเงินเดือน: dataToExcelWaiting[index].paymentRoundName
                        ? dataToExcelWaiting[index].paymentRoundName
                        : "",
                    ประเภทคำขอ: dataToExcelWaiting[index].name
                        ? dataToExcelWaiting[index].name
                        : "",
                    เวลาเริ่มต้น: dataToExcelWaiting[index].startText
                        ? dataToExcelWaiting[index].startText
                        : "",
                    เวลาสิ้นสุด: dataToExcelWaiting[index].endText
                        ? dataToExcelWaiting[index].endText
                        : "",
                    "จำนวนที่ใช้ไป (วัน)": dataToExcelWaiting[index].used
                        ? dataToExcelWaiting[index].used
                        : "",
                    เหตุผล: dataToExcelWaiting[index].description
                        ? dataToExcelWaiting[index].description
                        : "-",

                    ผู้บังคับบัญชา: dataToExcelWaiting[index].manager_firstname_TH
                        ? `${dataToExcelWaiting[index].manager_firstname_TH} ${dataToExcelWaiting[index].manager_lastname_TH}`
                        : "-",
                    "E-mail ผู้บังคับบัญชา": dataToExcelWaiting[index].emailManagerLV1
                        ? dataToExcelWaiting[index].emailManagerLV1
                        : "-",
                };
                elementListWaiting.push(element);
            }

            for (let index = 0; index < dataToExcel.length; index++) {
                const element = {
                    // ลำดับที่: dataToExcel[index].idRequestTime,
                    รหัสพนักงาน: dataToExcel[index].employeeID,
                    "ชื่อ-สกุล": `${dataToExcel[index].firstname_TH} ${dataToExcel[index].lastname_TH}`,
                    ตำแหน่ง: dataToExcel[index].positionName
                        ? dataToExcel[index].positionName
                        : "",
                    ประเภทคำขอ: dataToExcel[index].name ? dataToExcel[index].name : "",
                    เวลาเริ่มต้น: dataToExcel[index].startText
                        ? dataToExcel[index].startText
                        : "",
                    เวลาสิ้นสุด: dataToExcel[index].endText
                        ? dataToExcel[index].endText
                        : "",
                    "จำนวนที่ใช้ไป (วัน)": dataToExcel[index].used
                        ? dataToExcel[index].used
                        : "",
                    เหตุผล: dataToExcel[index].description
                        ? dataToExcel[index].description
                        : "-",
                    "เหตุผล(เพิ่มเติม)": dataToExcel[index].otherReason
                        ? dataToExcel[index].otherReason
                        : "-",
                    วันเวลาที่ทำรายการ: dataToExcel[index].createDateText
                        ? dataToExcel[index].createDateText
                        : "-",
                    ผู้บังคับบัญชา: dataToExcel[index].managerLV1_firstname_TH
                        ? dataToExcel[index].managerLV1_firstname_TH
                        : "-",
                    "E-mail ผู้บังคับบัญชา": dataToExcel[index].emailManagerLV1
                        ? dataToExcel[index].emailManagerLV1
                        : "-",
                    "ผลอนุมัติ ผู้บังคับบัญชา":
                        dataToExcel[index].isApprove === null
                            ? "รออนุมัติ"
                            : dataToExcel[index].isApprove === 1
                                ? "อนุมัติ"
                                : "ไม่อนุมัติ",
                    "วันที่อนุมัติ ผู้บังคับบัญชา":
                        dataToExcel[index].isApprove === null
                            ? ""
                            : dataToExcel[index].approveDateText,
                };
                elementList.push(element);
            }
        } else {
            handleChangeAlertType("ไม่พบข้อมูล");
            handleOpenAlert(true);
        }

        if (elementListWaiting.length > 0 || elementList.length > 0) {
            const wsWaiting = XLSX.utils.json_to_sheet(elementListWaiting);
            const ws = XLSX.utils.json_to_sheet(elementList);
            var workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, wsWaiting, "รออนุมัติ");
            XLSX.utils.book_append_sheet(workbook, ws, "อนุมัติแล้ว");
            XLSX.writeFile(
                workbook,
                `รายการ${Type}.xlsx`,
                { type: "file" }
            );
        }

    }

    const submit = async () => {
        const formData = {
            ReportName: "approvelist",
            idEmployees: userProfile.idEmployees,
            day: autoEmail == "วันสุดท้ายของเดือน" ? 0 : autoEmail
        }
        const result = await dispatch(addEmailReport(formData))
        if (result.status === 200) {
            handleChangeAlertType("success");
            handleOpenAlert(true);
        } else {
            handleChangeAlertType("ไม่สำเร็จ");
            handleOpenAlert(true);
        }
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <DrawerCustom
            title="รายงานการร้องขอ"
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                    <Grid item xs={12} sm={6} className="GridTopicInput">วันที่ส่ง Email</Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledBoxSearch>
                            <StyledAutocomplete
                                options={["วันสุดท้ายของเดือน", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28]}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setAutoEmail(newValue);
                                    } else {
                                        setAutoEmail(null)
                                    }
                                }}
                                value={autoEmail == 0 ? "วันสุดท้ายของเดือน" : autoEmail}
                                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={"filled"}
                                        placeholder={"เลือกวันที่"}
                                    />
                                )}
                                PopperComponent={StyledPopper}
                            />
                        </StyledBoxSearch>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", paddingRight: "16px" }}>
                        <ButtonBlue
                            variant="outlined"
                            startIcon={<SaveIcon />}
                            onClick={submit}
                            disabled={autoEmail === null}
                        >
                            บันทึก
                        </ButtonBlue>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridTopicInput">เลือกข้อมูล</Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl sx={{ justifySelf: "flex-end" }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="year" control={<Radio />} label="ทั้งปี" />
                                <FormControlLabel value="month" control={<Radio />} label="รายเดือน" />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridTopicInput">วันที่ค้นหา</Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                maxDate={new Date()}
                                inputFormat="MMMM yyyy"
                                value={inputSearch}
                                name="start"
                                views={["year", "month"]}
                                openTo="month"
                                onChange={(newValue) => {
                                    setInputSearch(newValue);
                                }}
                                renderInput={(params) => (
                                    <StyledTextField variant="filled" fullWidth {...params} />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridTopicInput">เลือกบริษัท</Grid>
                    <Grid item xs={12} sm={6} >
                        <SelectCompany
                            variant="outlined"
                            hideLabel={true}
                            handleChangeCompany={handleChangeCompany}
                        />
                    </Grid>

                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                    <Grid item xs={12} sm={6} className="GridTopicInput">ประเภทคำขอ</Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledAutocomplete
                            options={List}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setType(newValue);
                                } else {
                                    setType(null);
                                }
                            }}
                            popupIcon={<i className="fa-light fa-chevron-down"></i>}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="filled"
                                    placeholder={"กรุณาเลือกประเภทคำขอ"}
                                />
                            )}
                            PopperComponent={StyledPopper}
                            noOptionsText={"ไม่พบข้อมูล"}
                        />
                    </Grid>
                </Grid>
                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>ยกเลิก</ButtonBlue>
                    <ButtonBlue
                        variant="outlined"
                        startIcon={<DownloadRoundedIcon />}
                        onClick={handleSubmit}
                        disabled={Type === null || requestTimeList === null}
                    >
                        ดาวน์โหลด
                    </ButtonBlue>
                </StyledFooter>
            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>
    );
}

export default Approve;
