import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import "dayjs/locale/th";

import TextFieldTheme from "../general/TextFieldTheme";

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledDatePicker = styled(DatePicker)({
  "& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
    backgroundColor: "#919eab14 !important",
    "&:before": {
      borderBottomStyle: "none !important",
    },
  },
});

const DatePickerEng = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
      <StyledDatePicker
        inputFormat={props.inputFormat ? props.inputFormat : "DD/MM/YYYY"}
        {...props}
        renderInput={(params) => (
          <Fragment>
            {props.variant === "outlined" ? (
              <TextFieldTheme style={{ width: "100%" }} {...params} />
            ) : (
              <StyledTextField variant="filled" fullWidth {...params} />
            )}
          </Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerEng;
