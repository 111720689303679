import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useDispatch } from "react-redux";
import { addJobGroupInterviewer, getJobGroupInterviewer } from "../../../../../actions/jobRecruit";
import AlertResponse from "./alertResponse";

const DialogAddInterviewer = ({ open, handleClose, idJobGroup, jobGroupName, manager }) => {
  const dispatch = useDispatch();
  const [interviewer, setInterviewer] = useState([]);
  const [configAlert, setConfigAlert] = useState(null);

  const handleCloseAlert = () => {
    setConfigAlert({ ...configAlert ,open: false })
  };

  const addInterviewer = async (event) => {
    event.preventDefault();
    const formData = { interviewer, idJobGroup };
    const res = await dispatch(addJobGroupInterviewer(formData));
    if (res.status === 201) {
      setConfigAlert({ open: true, handleClose: handleCloseAlert, message: "สร้างสำเร็จ", severity: "success"})
    } else {
      setConfigAlert({ open: true, handleClose: handleCloseAlert,  message: "เกิดข้อผิดพลาด", severity: "error"})
    }
    handleClose();
    dispatch(getJobGroupInterviewer());
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>เพิ่มกรรมการสัมภาษณ์งาน</DialogTitle>
        <form onSubmit={addInterviewer}>
          <DialogContent>
            <DialogContentText marginBottom={2}>{`ประเภทงาน: ${jobGroupName}`}</DialogContentText>
            <Autocomplete
              multiple
              options={manager}
              getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
              onChange={(evant, value) => setInterviewer(value)}
              isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
              renderOption={(props, option) => (
                <li {...props} key={option.idEmployees}>
                  {`${option.firstname_TH} ${option.lastname_TH}`}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="เลือกกรรมการสัมภาษณ์" />}
            />
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleClose}>
              ยกเลิก
            </Button>
            <ButtonBlue type="submit" variant="contained">
              ยืนยัน
            </ButtonBlue>
          </DialogActions>
        </form>
      </Dialog>
      {configAlert && <AlertResponse configAlert={configAlert} />}
    </>
  );
};

export default DialogAddInterviewer;
