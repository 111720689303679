import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import dayjs from "dayjs";
import CardStyle from "../../../../shared/general/Card";
import { getAllEmployees } from "../../../../../../actions/employee";
import {
  getOnBoardingEmployeeByID,
} from "../../../../../../actions/onBoarding";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StatusOnBoarding = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: onBoardingEmployee } = useSelector((state) => state.onBoardingEmployeeByID);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getOnBoardingEmployeeByID(userProfile.idEmployees));
  }, []);

  return (
    <>
      {onBoardingEmployee && onBoardingEmployee.length > 0 ? (
        <StyledRoot>
          <CardStyle>
            <CardHeader 
              title={<Typography variant="h6">รายละเอียดสถานะ</Typography>}
              subheader={`${onBoardingEmployee[0].firstname_TH} ${onBoardingEmployee[0].lastname_TH}`}
            />
            <CardContent>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">รายการ</TableCell>
                      <TableCell align="center">ผู้ตรวจสอบ</TableCell>
                      <TableCell align="center">หน่วยงาน</TableCell>
                      <TableCell align="center">
                        ดำเนินการภายใน(วัน)
                      </TableCell>
                      <TableCell align="center">สถานะ</TableCell>
                      <TableCell align="center">วันที่</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {onBoardingEmployee.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {row.OnBoardingListName}{" "}
                        </TableCell>
                        <TableCell align="center">
                          {`${row.reviewer_firstname_TH}  ${row.reviewer_lastname_TH}`}{" "}
                        </TableCell>
                        <TableCell align="center">
                          {row.departmentName}{" "}
                        </TableCell>
                        <TableCell align="center">{row.day} </TableCell>
                        <TableCell align="center">
                          {row.isApprove ? (
                            <CheckCircleOutlineIcon
                              style={{ color: "green" }}
                            />
                          ) : (
                            <CancelOutlinedIcon style={{ color: "red" }} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(row.updateDate).format("DD/MM/YYYY HH:mm")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </CardStyle>
        </StyledRoot>
      ) : (
        <Typography>ไม่มีรายการ</Typography>
      )}
    </>
  );
};

export default StatusOnBoarding;
