import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from "react-dropzone";

import {
    Chip,
    CircularProgress,
    Grid,
    styled,
    Typography
} from '@mui/material';

import AddIcon from "../../../assets/add.png";
import ButtonBlue from '../../../shared/general/ButtonBlue';

import { addProfileImageOfEmployees } from '../../../../../actions/employee';

const DisplayFilesBox = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    marginTop: 8,
    padding: "8px 8px 16px 16px",
    border: "1px dashed rgb(208, 208, 208)",
    borderRadius: "8px"
});

const StyledChip = styled(Chip)({
    margin: "8px 8px 0 0"
});

const DragDropImage = (props) => {
    const { handleOpenStatusResult, waitingAPI, setWaitingAPI } = props;
    const dispatch = useDispatch();
    const [listFiles, setListFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png",
        onDrop: (acceptedFiles) => {
            let listFilesInput = [];
            acceptedFiles.map((file) => {
                listFilesInput.push(file);
            });
            setListFiles([...listFiles,...listFilesInput]);
        },
        multiple: true
    });

    const handleDelete = (index) => {
        let listFilesAfterDeleted = listFiles.filter((_,i) => i !== index);
        setListFiles(listFilesAfterDeleted);
    }
    
    const handleSubmit = async () => {
        setWaitingAPI(true);

        let formData = new FormData;

        listFiles.map(file => {
            formData.append("files",file);
        });

        let result = await dispatch(addProfileImageOfEmployees(formData));
        if(result && result.status === 200){
            handleOpenStatusResult(200, "success", "เพิ่มรูปสำเร็จ");
        } else {
            handleOpenStatusResult(((result && result.status) || null), "error", "เพิ่มรูปไม่สำเร็จ");
        }
    }

    return (
        <Fragment>
            {!waitingAPI &&
                <Fragment>
                    <div
                        {...getRootProps({
                            className: "dropzone-upload-file",
                        })}
                    >
                        <input {...getInputProps()} />
                        <div className="inner-dropzone">
                            <img alt="AddIcon" src={AddIcon} width="80" />
                            <Typography
                                style={{
                                    marginTop: 8,
                                    backgroundColor: "transparent"
                                }}
                                className={`${listFiles.length !== 0 && `placeholderLabel`}`}
                                variant="body2"
                                color="text.secondary"
                            >
                                Drag & Drop หรือ คลิ๊กเพื่อเลือกไฟล์
                            </Typography>
                        </div>
                    </div>
                    {listFiles.length > 0 &&
                        <div style={{ marginTop: 16 }}>
                            <Typography variant="h6">ไฟล์ที่อัปโหลด</Typography>
                            <DisplayFilesBox>
                                {listFiles.map((file, index) => ( 
                                    <StyledChip 
                                        key={`${file.name}_${index}`}
                                        label={`${file.name}`} 
                                        icon={<i className="fa-regular fa-image" />} 
                                        onDelete={() => handleDelete(index)} 
                                    /> 
                                ))} 
                            </DisplayFilesBox>
                            <Grid 
                                container 
                                justifyContent={"flex-end"} 
                                style={{ marginTop:16 }}
                            >
                                <Grid item>
                                    <ButtonBlue
                                        variant="contained"
                                        onClick={handleSubmit}
                                        disabled={waitingAPI}
                                    >บันทึก</ButtonBlue>
                                </Grid>
                            </Grid>
                        </div>
                    }
                </Fragment>
            }
            {waitingAPI && 
                <Grid container justifyContent="center" alignItems="center" style={{ height: 400 }}>
                    <Grid 
                        item
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center"
                        }}
                    >
                        <CircularProgress 
                            size={68}
                        />
                        <Typography
                            style={{ marginTop: 24 }}
                            variant="h5"
                        >กำลังอัพโหลดรูปภาพ</Typography>
                    </Grid>
                </Grid>
            }
        </Fragment>
    );
};

export default DragDropImage;