import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, styled } from "@mui/material";

import EditRoundedIcon from "@mui/icons-material/EditRounded";

import ButtonBlue from "../../shared/general/ButtonBlue";

import DrawerAddress from "./DrawerAddress";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "&.inner": {
    marginBottom: 0,
  },
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const TabAddress = (props) => {
  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );

  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = (mode) => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Fragment>
      <StyledWrapTop>
        <Typography variant="body2" style={{ fontWeight: 600 }}></Typography>
        <ButtonBlue
          size="small"
          startIcon={<EditRoundedIcon />}
          onClick={() => handleClick("address")}
        >
          แก้ไข
        </ButtonBlue>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">ที่ตั้ง</StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.address || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledHeadLabel color="text.secondary">เขต/อำเภอ</StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.district || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledHeadLabel color="text.secondary">จังหวัด</StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.province || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledHeadLabel color="text.secondary">รหัสไปรษณี</StyledHeadLabel>
          <Typography variant="h6">
            {companyProfile.areaCode || "-"}
          </Typography>
        </Grid>
      </Grid>

      {openDialog && 
        <DrawerAddress 
          open={openDialog}
          onClose={handleCloseDialog}
        />
      }
    </Fragment>
  );
};

export default TabAddress;
