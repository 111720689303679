import dayjs from "dayjs";
import XLSX from "xlsx";

function numberWithCommas(x) {
  if (x) {
    var toFixed = x.toFixed(2);
    var parts = toFixed.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else {
    return "0";
  }
}

function numberWithCommasWithoutDot(x) {
  if (x) {
    var toFixed = x.toFixed(2);
    var parts = toFixed.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parseInt(parts[1]) > 0) {
      return parts.join(".");
    } else {
      return parts[0];
    }
  } else {
    return "0";
  }
}

function inputNumberWithCommas(value, previousValue, digit) {
  digit = digit === undefined ? 0 : digit;
  value = value.trim().replace(new RegExp(",", "g"), "");
  if (isNaN(value)) {
    return previousValue;
  }
  if (digit === 0 && value.indexOf(".") !== -1) {
    return previousValue;
  }
  if (value.indexOf("0") === 0) {
    return previousValue;
  }
  if (value.indexOf(".") === -1) {
    return numberWithCommas(value);
  }
  const afterDecimalLength = value.split(".")[1].length;
  if (afterDecimalLength > digit) {
    return previousValue;
  }
  return numberWithCommas(value);
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function getAge(dateString) {
  var now = new Date();
  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(
    dateString.substring(6, 10),
    dateString.substring(3, 5) - 1,
    dateString.substring(0, 2)
  );

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var ageString = "";
  var yearString = " ปี";
  var monthString = " เดือน";
  var dayString = " วัน";

  let monthAge = null

  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) monthAge = monthNow - monthDob;
  else {
    yearAge--;
    monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    var dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  if (age.years > 0 && age.months > 0 && age.days > 0)
    ageString =
      age.years +
      yearString +
      " " +
      age.months +
      monthString +
      " " +
      age.days +
      dayString;
  else if (age.years === 0 && age.months === 0 && age.days > 0)
    ageString = age.days + dayString;
  else if (age.years > 0 && age.months === 0 && age.days === 0)
    ageString = age.years + yearString;
  else if (age.years > 0 && age.months > 0 && age.days === 0)
    ageString = age.years + yearString + " " + age.months + monthString;
  else if (age.years === 0 && age.months > 0 && age.days > 0)
    ageString = age.months + monthString + " " + age.days + dayString;
  else if (age.years > 0 && age.months === 0 && age.days > 0)
    ageString = age.years + yearString + " " + age.days + dayString;
  else if (age.years === 0 && age.months > 0 && age.days === 0)
    ageString = age.months + monthString;
  else ageString = "ไม่สามารถคำนวณอายุได้";

  return ageString;
}

function getAgeMonthYear(dateString) {
  var now = new Date();

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(
    dateString.substring(6, 10),
    dateString.substring(3, 5) - 1,
    dateString.substring(0, 2)
  );

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var ageString = "";
  var yearString = " ปี";
  var monthString = " เดือน";


  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
  else {
    yearAge--;
    var monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    var dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  if (age.years > 0 && age.months > 0 && age.days > 0)
    ageString = age.years + yearString + " " + age.months + monthString;
  else if (age.years > 0 && age.months === 0 && age.days === 0)
    ageString = age.years + yearString;
  else if (age.years > 0 && age.months > 0 && age.days === 0)
    ageString = age.years + yearString + " " + age.months + monthString;
  else if (age.years === 0 && age.months > 0 && age.days > 0)
    ageString = age.months + monthString + " ";
  else if (age.years > 0 && age.months === 0 && age.days > 0)
    ageString = age.years + yearString + " ";
  else if (age.years === 0 && age.months > 0 && age.days === 0)
    ageString = age.months + monthString;
  else ageString = "ไม่สามารถคำนวณอายุได้";

  return ageString;
}

function getAgeNumber(dateString) {
  var now = new Date();

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(
    dateString.substring(6, 10),
    dateString.substring(3, 5) - 1,
    dateString.substring(0, 2)
  );

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();

  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
  else {
    yearAge--;
    var monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    var dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  return {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };
}

export const inputGPA = (value, previousValue) => {
  value = value.trim().replace(new RegExp(",", "g"), "");
  if (isNaN(value)) {
    return previousValue;
  }
  if (value.indexOf(".") === -1) {
    if (value.length > 1 || value > 4) return previousValue;
    else return value;
  }
  if (value > 4) {
    return previousValue;
  }
  const afterDecimalLength = value.split(".")[1].length;
  if (afterDecimalLength > 2) {
    return previousValue;
  }
  return value;
};

export const inputTel = (value, previousValue) => {
  value = value.trim().replace(new RegExp(/\D/, "g"), "");
  return value;
};

function parseMonth(n) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[n];
}

const jobSeniority = [
  { id: 1, name: "ฝึกงาน" },
  { id: 2, name: "พนักงานทั่วไป" },
  { id: 3, name: "พนักงานระดับสูง" },
  { id: 4, name: "ผู้จัดการ" },
  { id: 5, name: "ผู้อำนวยการ" },
  { id: 6, name: "ผู้บริหารระดับสูง" },
];

const jobSpecialisation = [
  { id: 1, name: "Business & Operations" },
  { id: 2, name: "Design" },
  { id: 3, name: "DevOps & IT" },
  { id: 4, name: "Marketing" },
  { id: 5, name: "Product Management" },
  { id: 6, name: "Quality Assurance" },
  { id: 7, name: "Sales" },
  { id: 8, name: "Software Engineering" },
  { id: 9, name: "Data Science and Analytics" },
];

export const adminMenu = [
  {
    id: 1,
    label: "ภาพรวม",
    image: "-_dashboard.png",
    premium: false,
    link: "/admin/overview",
  },
  {
    id: 2,
    label: "Company Profile",
    image: "Company_profile.png",
    premium: false,
    link: "/admin/company-profile",
  },
  {
    id: 3,
    label: "Organization",
    image: "Organization_Management.png",
    premium: false,
    link: "/admin/organization",
  },
  {
    id: 4,
    label: "Manpower",
    image: "-_Cloud workforce .png",
    premium: false,
    link: "/admin/manpower",
  },
  {
    id: 5,
    label: "Employee Data",
    image: "Employee_Core_Data.png",
    premium: false,
    link: "/admin/employees",
  },
  {
    id: 6,
    label: "Document",
    image: "Document_management.png",
    premium: true,
    link: "/admin/document-employee",
  },
  {
    id: 7,
    label: "Recruitment",
    image: "Sourcing_Recruitment.png",
    premium: false,
  },
  {
    id: 8,
    label: "Job Management",
    image: "Job_Management.png",
    premium: false,
    link: "/admin/jobStructure",
  },
  {
    id: 9,
    label: "Workforce",
    image: "Workforce_Management.png",
    premium: true,
    link: "/admin/workforceDashboard",
  },
  {
    id: 10,
    label: "Competency",
    image: "Competency_Management.png",
    premium: true,
  },
  {
    id: 11,
    label: "Learning",
    image: "Learning_Management.png",
    premium: true,
  },
  { id: 12, label: "Talent", image: "Talent&Successor.png", premium: true },
  { id: 13, label: "Successor", image: "-_successor.png", premium: true },
  {
    id: 26,
    label: "Career Management",
    image: "Career_Management.png",
    premium: true,
    link: "/myCareerePlan",
  },
  { id: 15, label: "Time", image: "Time_Management.png", premium: false },
  {
    id: 16,
    label: "Payroll",
    image: "Payroll.png",
    premium: false,
    link: "/admin/payroll/run",
  },
  {
    id: 17,
    label: "Benefits",
    image: "Benefit.png",
    premium: true,
    link: "/flexben",
  },
  { id: 18, label: "Health", image: "Health.png", premium: true },
  {
    id: 19,
    label: "Culture",
    image: "Culture.png",
    premium: true,
    link: "https://antculture.co/home",
    tab: true,
  },
  { id: 20, label: "Coaching", image: "Coaching.png", premium: true },
  {
    id: 21,
    label: "Performance",
    image: "Performance_Management.png",
    premium: true,
    link: "/okrs",
    tab: false,
  },
  { id: 22, label: "Onboarding", image: "Onboarding.png", premium: true },
  {
    id: 23,
    label: "Employee Network",
    image: "Employee_Network.png",
    premium: false,
    link: "/admin/employees_network",
  },
  // { id:24, label:"Export Center", image:"" , premium: false},
  { id: 25, label: "Quiz", image: "-_quiz.png", premium: true },
  {
    id: 27,
    label: "GA Services",
    image: "Catering_Management.png",
    premium: true,
    link: "/ga-services",
  },
  { id: 29, label: "E-Signature", image: "-_E signature.png", premium: true },
  {
    id: 30,
    label: "Job profile",
    image: "-_Job opportunities.png",
    premium: true,
  },
  {
    id: 31,
    label: "Personal profile",
    image: "-_Employee Profile.png",
    premium: false,
    link: "/information",
  },
];

export const getOptionSenioritys = () => {
  return [
    { value: 1, label: "ฝึกงาน" },
    { value: 2, label: "พนักงานทั่วไป" },
    { value: 3, label: "พนักงานระดับสูง" },
    { value: 4, label: "ผู้จัดการ" },
    { value: 5, label: "ผู้อำนวยการ" },
    { value: 6, label: "ผู้บริหารระดับสูง" },
  ];
};

export const getLabelSeniority = (value) => {
  return getOptionSenioritys().find((seniority) => seniority.value === value)
    .label;
};

export const getOptionSpecialisations = () => {
  return [
    { value: 1, label: "Business & Operations" },
    { value: 2, label: "Design" },
    { value: 3, label: "DevOps & IT" },
    { value: 4, label: "Marketing" },
    { value: 5, label: "Product Management" },
    { value: 6, label: "Quality Assurance" },
    { value: 7, label: "Sales" },
    { value: 8, label: "Software Engineering" },
    { value: 9, label: "Data Science and Analytics" },
  ];
};

export const getLabelSpecialisation = (value) => {
  return getOptionSpecialisations().find(
    (specialisation) => specialisation.value === value
  ).label;
};

const jobRole = {
  1: [
    { id: 1, name: "Account (Client) Manager" },
    { id: 2, name: "Customer Service" },
    { id: 3, name: "Executive Management" },
    { id: 4, name: "Finance and Accounting" },
    { id: 5, name: "General Management" },
    { id: 6, name: "Human Resource Manager" },
    { id: 7, name: "Office Manager" },
    { id: 8, name: "Operations Manager" },
    { id: 9, name: "Project Manager" },
  ],
  2: [
    { id: 10, name: "Animator" },
    { id: 11, name: "Creative Designer" },
    { id: 12, name: "Game Designer" },
    { id: 13, name: "Graphic Designer" },
    { id: 14, name: "Interactive Designer" },
    { id: 15, name: "Motion Graphic Designer" },
    { id: 16, name: "Multimedia Designer" },
    { id: 17, name: "UI Designer" },
    { id: 18, name: "UX/UI Designer" },
    { id: 19, name: "UX Designer" },
    { id: 20, name: "UX Researcher" },
    { id: 21, name: "Video Editor" },
  ],
  3: [
    { id: 22, name: "Build/Release Engineer" },
    { id: 23, name: "Database Adminstrator" },
    { id: 24, name: "Desktop Support" },
    { id: 25, name: "DevOps Engineer" },
    { id: 26, name: "Hardware Engineer" },
    { id: 27, name: "IT Architecture" },
    { id: 28, name: "IT Audit" },
    { id: 29, name: "IT Infrastructure" },
    { id: 30, name: "IT Manager" },
    { id: 31, name: "Network Adminstrator" },
    { id: 32, name: "Network Engineer" },
    { id: 33, name: "Site Reliability Engineer" },
    { id: 34, name: "Solution Achitect" },
    { id: 35, name: "Sytem Analyst" },
    { id: 36, name: "Sytems Adminstrator" },
  ],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
};

export const getEmploymentType = () => [
  {
    id: 1,
    name: "Full-time",
  },
  {
    id: 2,
    name: "Contract",
  },
  {
    id: 3,
    name: "Internship",
  },
];

export const selectedMultiHandler = (value, name, getValues, setValue) => {
  const result = [...getValues(name)];
  if (result.find((x) => x.id === value.id)) {
    setValue(
      name,
      result.filter((x) => x.id !== value.id)
    );
    // if(getValues("roles").length === 0){
    // 	setError("roles", {
    // 		type: "manual",
    // 		message: "โปรดเลือกอย่างน้อย 1 หน้าที่"
    // 	})
    // }
    return;
  }

  result.push(value);
  // if(result.length > 0){
  // 	clearErrors("roles");
  // }
  setValue(name, result);
};

export const getOptionDegrees = () => [
  { value: 1, label: "ประถมศึกษา" },
  { value: 2, label: "มัธยมศึกษาตอนต้น" },
  { value: 3, label: "มัธยมศึกษาตอนปลาย" },
  { value: 4, label: "ปริญญาตรี" },
  { value: 5, label: "ปริญญาโท" },
  { value: 6, label: "ปริญญาเอก" },
];

export const getLabelDegree = (value) => {
  return getOptionDegrees().find((degree) => degree.value === value).label;
};

export const getOptionYears = () => {
  let years = [];
  let currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= 1900; i--) {
    years.push({
      value: i,
      label: i,
    });
  }
  return years;
};

export const SuggestList = [
  { label: "Manager" },
  { label: "Lead Programmer" },
  { label: "Software Developer" },
  { label: "System Analyst" },
];

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

export const excelExportTimeline = (
  attendanceList,
  employee,
  payrollSetting,
  mode
) => {
  const elementList = [];

  for (let index = 0; index < attendanceList.length; index++) {
    let filteredOT = [];

    if (mode === "user") {
      filteredOT = attendanceList[index].ot.filter(
        (ot) =>
          ot.isActive === 1 &&
          ((ot.isDoubleApproval === 1 &&
            ot.isManagerLV1Approve === 1 &&
            ot.isManagerLV2Approve === 1) ||
            (ot.approvalLevel === 1 && ot.isManagerLV1Approve === 1) ||
            (ot.approvalLevel === 2 && ot.isManagerLV2Approve === 1))
      );
    } else if (mode === "admin") {
      filteredOT = attendanceList[index].ot;
    }

    const element = {
      วันที่: `${dayjs(attendanceList[index].date).format("D/MM/YYYY (ddd)")}
					${
            attendanceList[index].holiday
              ? ` (${attendanceList[index].holiday.name})`
              : ``
          }
					${
            attendanceList[index].pattern.isCompensationHoliday === 1
              ? ` (วันหยุดชดเชย)`
              : ``
          }
				`,
      กะ:
        attendanceList[index].pattern.idWorkingType === 1
          ? `${attendanceList[index].pattern.shiftGroupName}  (${attendanceList[index].pattern.shiftName})`
          : `${attendanceList[index].pattern.shiftGroupName}`,
      เวลาทำงาน:
        attendanceList[index].pattern.isWorkingDay === 1
          ? `${dayjs(attendanceList[index].pattern.timeIn, "HH:mm:ss").format(
              "HH:mm"
            )} - ${dayjs(
              attendanceList[index].pattern.timeOut,
              "HH:mm:ss"
            ).format("HH:mm")}`
          : `${attendanceList[index].pattern.shiftTypeName}`,
      "เข้างาน-ออกงาน":
        attendanceList[index].attendance.checkIn.length > 0 &&
        attendanceList[index].attendance.checkOut.length > 0
          ? `${
              attendanceList[index].attendance.checkIn[0]
                ? attendanceList[index].attendance.checkIn[0].attendanceTextTime
                : ""
            } - ${
              attendanceList[index].attendance.checkOut[0]
                ? attendanceList[index].attendance.checkOut[0]
                    .attendanceTextTime
                : ""
            }`
          : `-`,
      รับรองเวลาทำงาน: attendanceList[index]
        ? `${
            attendanceList[index].requestTime &&
            attendanceList[index].requestTime.isManagerLV1Approve === 1
              ? `${attendanceList[index].requestTime.startText} - ${attendanceList[index].requestTime.endText}`
              : ""
          }`
        : "-",
      "ทำงานล่วงเวลา (1)":
        filteredOT.length > 0
          ? `${filteredOT[0].startText} - ${filteredOT[0].endText}`
          : `-`,
      [`OT ${payrollSetting.xWorkingMonthlyHoliday}x วันหยุดรายเดือน (1)`]:
        filteredOT.length > 0
          ? `${
              filteredOT[0].xWorkingMonthlyHoliday > 0
                ? `${parseFloat(
                    filteredOT[0].xWorkingMonthlyHoliday / 60
                  ).toFixed(2)} ชม.`
                : `-`
            }`
          : `-`,
      [`OT ${payrollSetting.xWorkingDailyHoliday}x วันหยุดรายวัน (1)`]:
        filteredOT.length > 0
          ? `${
              filteredOT[0].xWorkingDailyHoliday > 0
                ? `${parseFloat(
                    filteredOT[0].xWorkingDailyHoliday / 60
                  ).toFixed(2)} ชม.`
                : `-`
            }`
          : `-`,
      [`OT ${payrollSetting.xOT}x ล่วงเวลาวันทำงาน (1)`]:
        filteredOT.length > 0
          ? `${
              filteredOT[0].xOT > 0
                ? `${parseFloat(filteredOT[0].xOT / 60).toFixed(2)} ชม.`
                : `-`
            }`
          : `-`,
      [`OT ${payrollSetting.xOTHoliday}x ล่วงเวลาวันหยุด (1)`]:
        filteredOT.length > 0
          ? `${
              filteredOT[0].xOTHoliday > 0
                ? `${parseFloat(filteredOT[0].xOTHoliday / 60).toFixed(2)} ชม.`
                : `-`
            }`
          : `-`,
      "ทำงานล่วงเวลา (2)":
        filteredOT.length > 1
          ? `${filteredOT[1].startText} - ${filteredOT[1].endText}`
          : `-`,
      [`OT ${payrollSetting.xWorkingMonthlyHoliday}x วันหยุดรายเดือน (2)`]:
        filteredOT.length > 1
          ? `${
              filteredOT[1].xWorkingMonthlyHoliday > 0
                ? `${parseFloat(
                    filteredOT[1].xWorkingMonthlyHoliday / 60
                  ).toFixed(2)} ชม.`
                : `-`
            }`
          : `-`,
      [`OT ${payrollSetting.xWorkingDailyHoliday}x วันหยุดรายวัน (2)`]:
        filteredOT.length > 1
          ? `${
              filteredOT[1].xWorkingDailyHoliday > 0
                ? `${parseFloat(
                    filteredOT[1].xWorkingDailyHoliday / 60
                  ).toFixed(2)} ชม.`
                : `-`
            }`
          : `-`,
      [`OT ${payrollSetting.xOT}x ล่วงเวลาวันทำงาน (2)`]:
        filteredOT.length > 1
          ? `${
              filteredOT[1].xOT > 0
                ? `${parseFloat(filteredOT[1].xOT / 60).toFixed(2)} ชม.`
                : `-`
            }`
          : `-`,
      [`OT ${payrollSetting.xOTHoliday}x ล่วงเวลาวันหยุด (2)`]:
        filteredOT.length > 1
          ? `${
              filteredOT[1].xOTHoliday > 0
                ? `${parseFloat(filteredOT[1].xOTHoliday / 60).toFixed(2)} ชม.`
                : `-`
            }`
          : `-`,
      การลางาน: attendanceList[index]
        ? `${
            attendanceList[index].leave &&
            attendanceList[index].leave.isApprove === 1
              ? attendanceList[index].leave.name
              : ""
          }`
        : "-",
      "การลางาน (เวลา)": attendanceList[index]
        ? `${
            attendanceList[index].leave &&
            attendanceList[index].leave.isApprove === 1
              ? `${attendanceList[index].leave.startText} - ${attendanceList[index].leave.endText}`
              : ""
          }`
        : "-",
      เหตุผลลางาน: attendanceList[index]
        ? `${
            attendanceList[index].leave &&
            attendanceList[index].leave.isApprove === 1
              ? `${attendanceList[index].leave.description}`
              : ""
          }`
        : "-",
    };

    if (mode === "user") {
      elementList.push(element);
    } else if (mode === "admin") {
      const adminElement = {
        วันที่: element["วันที่"],
        กะ: element["กะ"],
        เวลาทำงาน: element["เวลาทำงาน"],
        "เข้างาน-ออกงาน": element["เข้างาน-ออกงาน"],
        รับรองเวลาทำงาน: element["รับรองเวลาทำงาน"],
        "ทำงานล่วงเวลา (1)": element["ทำงานล่วงเวลา (1)"],
        [`OT ${payrollSetting.xWorkingMonthlyHoliday}x วันหยุดรายเดือน (1)`]:
          element[
            `OT ${payrollSetting.xWorkingMonthlyHoliday}x วันหยุดรายเดือน (1)`
          ],
        [`OT ${payrollSetting.xWorkingDailyHoliday}x วันหยุดรายวัน (1)`]:
          element[
            `OT ${payrollSetting.xWorkingDailyHoliday}x วันหยุดรายวัน (1)`
          ],
        [`OT ${payrollSetting.xOT}x ล่วงเวลาวันทำงาน (1)`]:
          element[`OT ${payrollSetting.xOT}x ล่วงเวลาวันทำงาน (1)`],
        [`OT ${payrollSetting.xOTHoliday}x ล่วงเวลาวันหยุด (1)`]:
          element[`OT ${payrollSetting.xOTHoliday}x ล่วงเวลาวันหยุด (1)`],
        "ทำงานล่วงเวลา (2)": element["ทำงานล่วงเวลา (2)"],
        [`OT ${payrollSetting.xWorkingMonthlyHoliday}x วันหยุดรายเดือน (2)`]:
          element[
            `OT ${payrollSetting.xWorkingMonthlyHoliday}x วันหยุดรายเดือน (2)`
          ],
        [`OT ${payrollSetting.xWorkingDailyHoliday}x วันหยุดรายวัน (2)`]:
          element[
            `OT ${payrollSetting.xWorkingDailyHoliday}x วันหยุดรายวัน (2)`
          ],
        [`OT ${payrollSetting.xOT}x ล่วงเวลาวันทำงาน (2)`]:
          element[`OT ${payrollSetting.xOT}x ล่วงเวลาวันทำงาน (2)`],
        [`OT ${payrollSetting.xOTHoliday}x ล่วงเวลาวันหยุด (2)`]:
          element[`OT ${payrollSetting.xOTHoliday}x ล่วงเวลาวันหยุด (2)`],
        การลางาน: element["การลางาน"],
        "การลางาน (เวลา)": element["การลางาน (เวลา)"],
        เหตุผลลางาน: element["เหตุผลลางาน"],
      };
      elementList.push(adminElement);
    }
  }

  const ws = XLSX.utils.json_to_sheet(elementList);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, ws, "รายงาน");
  XLSX.writeFile(
    workbook,
    `รายงานเวลาทำงาน ${employee.employeeID}_${employee.firstname_TH}_${employee.lastname_TH}.xlsx`,
    { type: "file" }
  );
};

export const calOvertime = (start, end, options) => {
  const { prePattern, curPattern, nextPattern, idPaymentType } = options;

  let preIsWorking =
    prePattern.isWorkingDay &&
    !prePattern.isHoliday &&
    !prePattern.isCompensationHoliday
      ? 1
      : 0;
  let preStart = dayjs(`${prePattern.date} ${prePattern.timeIn}`);
  let preEnd = preStart.add(prePattern.workingHours, "minute");
  let prePeriod = prePattern.period;

  let curIsWorking =
    curPattern.isWorkingDay &&
    !curPattern.isHoliday &&
    !curPattern.isCompensationHoliday
      ? 1
      : 0;
  let curStart = dayjs(`${curPattern.date} ${curPattern.timeIn}`);
  let curEnd = curStart.add(curPattern.workingHours, "minute");
  let curPeriod = curPattern.period;

  let nextIsWorking =
    nextPattern.isWorkingDay &&
    !nextPattern.isHoliday &&
    !nextPattern.isCompensationHoliday
      ? 1
      : 0;
  let nextStart = dayjs(`${nextPattern.date} ${nextPattern.timeIn}`);
  let nextEnd = nextStart.add(nextPattern.workingHours, "minute");
  let nextPeriod = nextPattern.period;

  let xOT = 0;
  let xOTHoliday = 0;
  let xWorkingDailyHoliday = 0;
  let xWorkingMonthlyHoliday = 0;
  let overlapWorking = 0;

  let ot_previous = dayjs(end.isBefore(preEnd) ? end : preEnd).diff(
    start.isAfter(preStart) ? start : preStart,
    "minute",
    true
  );
  if (ot_previous > 0) {
    if (preIsWorking === 1) {
      overlapWorking += ot_previous;
    } else if (preIsWorking === 0) {
      if (idPaymentType === 2) {
        xWorkingMonthlyHoliday += ot_previous;
      } else if (idPaymentType === 1) {
        xWorkingDailyHoliday += ot_previous;
      }
    }
  } else {
    ot_previous = 0;
  }

  let ot_pre_current = dayjs(end.isBefore(curStart) ? end : curStart).diff(
    start.isAfter(preEnd) ? start : preEnd,
    "minute",
    true
  );
  if (ot_pre_current > 0) {
    if (preIsWorking === 1) {
      xOT += ot_pre_current;
    } else if (preIsWorking === 0) {
      xOTHoliday += ot_pre_current;
    }
  } else {
    ot_pre_current = 0;
  }

  let ot_current = dayjs(end.isBefore(curEnd) ? end : curEnd).diff(
    start.isAfter(curStart) ? start : curStart,
    "minute",
    true
  );
  if (ot_current > 0) {
    if (curIsWorking === 1) {
      console.log("HI");
      overlapWorking += ot_current;
      // xOT += ot_previous
    } else if (curIsWorking === 0) {
      if (idPaymentType === 2) {
        xWorkingMonthlyHoliday += ot_current;
      } else if (idPaymentType === 1) {
        xWorkingDailyHoliday += ot_current;
      }
    }
  } else {
    ot_current = 0;
  }

  let ot_pre_next = dayjs(end.isBefore(nextStart) ? end : nextStart).diff(
    start.isAfter(curEnd) ? start : curEnd,
    "minute",
    true
  );
  console.log("ot_pre_next", ot_pre_next);
  if (ot_pre_next > 0) {
    if (curIsWorking === 1 && curPeriod === 3 && nextIsWorking === 0) {
      xOTHoliday += ot_pre_next;
    } else if (
      curIsWorking === 0 &&
      curPeriod === 1 &&
      nextIsWorking === 1 &&
      nextPeriod === 3
    ) {
      let over730 = dayjs(end).diff(
        `${nextStart.format("YYYY-MM-DD")} 07:30:00`,
        "minute",
        true
      );

      if (over730 > 0) {
        if (idPaymentType === 2) {
          xWorkingMonthlyHoliday += over730;
        } else if (idPaymentType === 1) {
          xWorkingDailyHoliday += over730;
        }
        xOTHoliday += ot_pre_next - over730;
      } else {
        xOTHoliday += ot_pre_next;
      }
    } else if (curIsWorking === 1) {
      xOT += ot_pre_next;
    } else if (curIsWorking === 0) {
      xOTHoliday += ot_pre_next;
    }
  } else {
    ot_pre_next = 0;
  }

  let ot_next = dayjs(end.isBefore(nextEnd) ? end : nextEnd).diff(
    start.isAfter(nextStart) ? start : nextStart,
    "minute",
    true
  );
  if (ot_next > 0) {
    if (nextIsWorking === 1) {
      overlapWorking += ot_next;
      // xOT += ot_previous
    } else if (nextIsWorking === 0) {
      if (idPaymentType === 2) {
        xWorkingMonthlyHoliday += ot_next;
      } else if (idPaymentType === 1) {
        xWorkingDailyHoliday += ot_next;
      }
    }
  } else {
    ot_next = 0;
  }

  const result = {
    xOT: xOT,
    xOTHoliday: xOTHoliday,
    xWorkingDailyHoliday: xWorkingDailyHoliday,
    xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
    overlapWorking: overlapWorking,
  };

  console.log(result);

  return result;
};

export const stringToColor = (string) => {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
};

export const getOptionTimes = (start = 7, end = 18) => {
	let times = [];
	let minutes = ["00","15","30","45"];

	for(let i = start; i < end; i++){
		for(let j = 0; j < minutes.length; j++){
			times.push(`${i}.${minutes[j]}`)
		}
	}

	return times;
}

export const debounce = (func, delay = 500, immediately = false) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
      timer = undefined;
    }, delay);
  };
}

export default {
  getAgeMonthYear,
  getAgeNumber,
  roundToTwo,
  numberWithCommasWithoutDot,
  numberWithCommas,
  groupBy,
  getUniqueListBy,
  parseMonth,
  getAge,
  jobSeniority,
  jobSpecialisation,
  jobRole,
  inputNumberWithCommas,
  adminMenu,
  SuggestList,
  stringToColor,
  getOptionTimes,
};
