import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "react-use";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Paper,
    Typography,
    Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

import SelectedGroupLocations from "./selectedGroupLocation";
import MapContainerpoint from "./MapContainerpoint";

import Backdrop from "../../../shared/general/Backdrop";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { addTimeline, getCheckTimelineById } from "../../../../../actions/timeline";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 100,
        backgroundColor: "#FFFFFF",
        "& .MuiTypography-h6": {
            fontSize: "1.5rem",
        },
        "& .MuiSvgIcon-root": {
            marginRight: "5px",
        },
        "& .MuiTypography-body1": {
            fontSize: "1.1rem",
        },
    },
    headingPage: {
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        borderRadius: "20px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    },
    paperCheckIn: {
        padding: theme.spacing(3),
        borderRadius: "20px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    },
    wrapIcon: {
        display: "flex",
        alignItems: "center",
    },
    dateTime: {
        color: "#16243D",
        borderRadius: "10px",
        marginBottom: "20px",
    },
    checkInBtn: {
        color: "#fff",
        width: "100%",
        height: "50px",
        backgroundColor: "#66bb6a",
        border: "1px solid #66bb6a",
        "&:hover": {
            borderColor: "#4caf50",
            backgroundColor: "#4caf50",
        },
    },
    checkOutBtn: {
        color: "#fff",
        width: "100%",
        height: "50px",
        backgroundColor: "#ef5350",
        border: "1px solid #ef5350",
        "&:hover": {
            borderColor: "#e53935",
            backgroundColor: "#e53935",
        },
    },
    headPanel: {
        fontWeight: 600,
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    locationMessage: {
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.spacing(3),
    },
    messagePassWrapper: {
        display: "flex",
        alignItems: "center",
        color: "#388e3c",
    },
    messageFailWrapper: {
        display: "flex",
        alignItems: "center",
        color: "#d32f2f",
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CheckInOut() {
    const classes = useStyles();
    const dispatch = useDispatch();
    useTitle("Check In/Out - UniHR");
    const { result: timeline } = useSelector((state) => state.timeline);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const [time, setTime] = useState(new Date());
    const [mode, setMode] = useState("");
    const [match, setMatch] = useState(null);
    const [clickCheck, setClickCheck] = useState(false);
    const [locations, setLocations] = useState(null);
    const [description, setDescription] = useState(null);
    const [gpsAddress, setGpsAddress] = useState(null);

    const [loadMap, setLoadMap] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("");

    useEffect(() => {
        const interval = setInterval(() => setTime(new Date()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, [time]);

    useEffect(() => {
        dispatch(getCheckTimelineById(userProfile.idEmployees, "time"))
    }, [])

    const handleInputChange = (e) => {
        const { value } = e.target;
        setDescription(value)
    };

    const handleClickCheck = async (mode) => {
        setMode(mode);
    };

    const handleChangeClickCheck = () => {
        setClickCheck(!clickCheck);
    };

    const addAttendance = async () => {
        console.log("addAttendance")
        const dataForm = {
            attendanceDateTime: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            isCheckIn: mode === "in",
            idEmployees: userProfile.idEmployees,
            idCompany: userProfile.idCompany,
            description: description,
            type: "time",
            latitude: locations[0],
            longitude: locations[1],
            locationName: gpsAddress
        };

        const result = await dispatch(addTimeline(dataForm));
        if (result && result.status === 200) {
            dispatch(getCheckTimelineById(userProfile.idEmployees, "time"))
            setOpenAlert(true);
            handleChangeAlertType("success");
            handleCancel();
        } else {
            setOpenAlert(true);
            handleChangeAlertType("error");
        }
    };

    const handleCancel = () => {
        setMode("");
        setLocations(null);
        setLoadMap(false);
        setDescription(null)
        setGpsAddress(null)
    };

    const handleChangeAlertType = (status) => {
        setAlertType(status);
    };

    const handleCloseAlert = () => {
        setOpenAlert("");
    };

    return (
        <div className={`page ${classes.root}`}>
            {userProfile && timeline ? (
                <Container maxWidth="lg">
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={4} lg={4}>
                            <Paper className={classes.paperCheckIn}>
                                <Box className={classes.dateTime}>
                                    <Box sx={{ marginBottom: "20px" }}>
                                        <Typography align="center" className={classes.headPanel}>
                                            บันทึกเวลาเข้า-ออก
                                        </Typography>
                                    </Box>
                                    <Box sx={{ marginBottom: "10px" }}>
                                        <Grid container>
                                            <Grid item xs={4} md={4} lg={4}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.wrapIcon}
                                                    color="text.secondary"
                                                >
                                                    <TodayIcon />
                                                    วันที่
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body1">
                                                    {dayjs(new Date()).format("DD MMMM YYYY")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Grid container>
                                            <Grid item xs={4} md={4} lg={4}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.wrapIcon}
                                                    color="text.secondary"
                                                >
                                                    <AccessTimeIcon />
                                                    เวลา
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body1">
                                                    {dayjs(time).format("HH:mm:ss")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Divider className={classes.divider} />
                                {userProfile && (
                                    <div style={{ marginBottom: 16 }}>
                                        <Typography variant="h6" color="text.third">
                                            สวัสดี...
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                        >{`${userProfile.firstname_TH} ${userProfile.lastname_TH}`}</Typography>
                                    </div>
                                )}
                                <Fragment>
                                    {mode !== "" ? (
                                        <Fragment>
                                            {userProfile && (
                                                <SelectedGroupLocations
                                                    mode={mode}
                                                    userProfile={userProfile}
                                                    handleCancel={handleCancel}
                                                    handleChangeClickCheck={handleChangeClickCheck}
                                                    loadMap={loadMap}
                                                />
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Box>
                                            <Grid container spacing={2}>
                                                {timeline.checkIn == 0 &&
                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <Button
                                                            variant="outlined"
                                                            className={classes.checkInBtn}
                                                            startIcon={<LoginIcon />}
                                                            onClick={() => handleClickCheck("in")}
                                                        >
                                                            ลงเวลาเข้างาน
                                                        </Button>
                                                    </Grid>}
                                                {timeline.checkIn == 1 && <Grid item xs={12} md={12} lg={12}>
                                                    <Button
                                                        variant="outlined"
                                                        className={classes.checkOutBtn}
                                                        startIcon={<LogoutIcon />}
                                                        onClick={() => handleClickCheck("out")}
                                                    >
                                                        ลงเวลาออกงาน
                                                    </Button>
                                                </Grid>}
                                            </Grid>
                                        </Box>
                                    )}
                                </Fragment>
                            </Paper>
                        </Grid>
                        {mode == "out" && (
                            <Grid item xs={12} md={8} lg={8}>
                                <Paper className={classes.paper}>
                                    <Box>
                                        <Typography className="label">
                                            เหตุผล
                                        </Typography>
                                        <TextFieldTheme
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            value={description}
                                            onChange={handleInputChange}
                                        ></TextFieldTheme>
                                    </Box>
                                </Paper>
                            </Grid>
                        )}
                        {mode !== "" && (
                            <Grid item xs={12} md={8} lg={8}>
                                <Paper className={classes.paper}>
                                    <MapContainerpoint
                                        setMatch={setMatch}
                                        addAttendance={addAttendance}
                                        setLoadMap={setLoadMap}
                                        setLocations={setLocations}
                                        setGpsAddress={setGpsAddress}
                                        locations={locations}
                                        clickCheck={clickCheck}
                                    />
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            ) : (
                <Backdrop open={true} />
            )}
            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {alertType === "success" ? (
                    <Alert
                        onClose={handleCloseAlert}
                        severity="success"
                        style={{ width: "100%" }}
                    >
                        บันทึกข้อมูลสำเร็จ
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleCloseAlert}
                        severity="error"
                        style={{ width: "100%" }}
                    >
                        เกิดข้อผิดพลาด...ติดต่อผู้ดูแลระบบ
                    </Alert>
                )}
            </Snackbar>
        </div>
    );
}

export default CheckInOut;
