import { httpClient } from "./httpClient";

const addOnBoardingList = (formData) => {
    return httpClient.post(`/OnBoardingList/add`, formData);
};

const addOnBoardingReviewer = (formData) => {
    return httpClient.post(`/OnBoardingReviewer/add`, formData);
};

const addOnBoardingEmployee = (formData) => {
    return httpClient.post(`/OnBoardingEmployee/add`, formData);
};

const editOnBoardingList = (formData) => {
    return httpClient.put(`/OnBoardingList/edit`, formData);
};

const approveOnBoardingEmployee = (formData) => {
    return httpClient.put(`/OnBoardingEmployee/approve`,formData);
};

const approveOnBoardingEmployeeByReviewer = (id,formData) => {
    return httpClient.put(`/OnBoardingEmployee/approve/${id}`,formData);
};

const getOnBoardingList = () => {
    return httpClient.get(`/OnBoardingList`);
};

const getOnBoardingReviewer = () => {
    return httpClient.get(`/OnBoardingReviewer`);
};

const getOnBoardingEmployee = () => {
    return httpClient.get(`/OnBoardingEmployee`);
};

const getOnBoardingEmployeeByID = (id) => {
    return httpClient.get(`/OnBoardingEmployee/${id}`);
};

const getOnBoardingEmployeeByReviewer = (id) => {
    return httpClient.get(`/OnBoardingEmployeeByReviewer/${id}`);
};

export default {
    addOnBoardingList,
    editOnBoardingList,
    getOnBoardingList,
    addOnBoardingReviewer,
    getOnBoardingReviewer,
    addOnBoardingEmployee,
    getOnBoardingEmployee,
    getOnBoardingEmployeeByID,
    approveOnBoardingEmployee,
    getOnBoardingEmployeeByReviewer,
    approveOnBoardingEmployeeByReviewer
};