import React from "react";
import { styled } from "@mui/styles";
import {
    Grid,
    Avatar,
    Typography,
    Box,
    Tabs,
    Tab,
    Container,
} from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import FlagIcon from "@mui/icons-material/Flag";
import BusinessIcon from "@mui/icons-material/Business";
import Contact from "./Contact";
import Overview from "./Overview";
import EmpInfo from "./EmpInfo";
import Skill_Interests from "./Skill_Interests";
import Address from "./Address";
import EducationTimelines from "./EducationTimelines";
import { useSelector } from "react-redux";

const Root = styled("div")(() => ({
    position: "relative",
    marginTop: "70px",
    marginBottom: "20px",
    backgroundColor: "#fff",
    "& .MuiTypography-h6": {
        color: "#16243D",
        fontWeight: 600,
        fontSize: "1.4rem",
    },
    "& .MuiTypography-subtitle1": {
        color: "#58647A",
    },
    "& .MuiTypography-body1": {
        color: "#16243D",
    },
    "& .MuiDivider-root": {
        marginTop: 24,
        marginBottom: 24,
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "#2F74EB",
    },
    "& .Mui-selected": {
        color: "#2F74EB",
        fontWeight: 600,
    },
    "& .MuiInputBase-input": {
        color: "#16243D",
    },
    "& .MuiInput-underline.Mui-disabled:before": {
        borderBottomStyle: "none",
    },
    "& .MuiOutlinedInput-input": {
        padding: "10px",
    },
    "& .MuiOutlinedInput-multiline": {
        padding: "0px",
    },
    "& .MuiPaper-root": {
        borderRadius: "20px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .MuiTabs-root": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    "& .MuiBox-root": {
        padding: "12px 0px",
    },
    "& .content": {
        zIndex: "2",
        position: "relative",

    },
    "& .background": {
        zIndex: "1",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "15rem",
        background:
            "linear-gradient(180deg, rgba(21,101,192,1) 0%, rgba(33,150,243,1) 100%)",
    },
    "& .empImage": {
        width: "100%",
        minHeight: "300px",
        minWidth: "300px",
        boxShadow: "none",
        // borderRadius: "8px 8px 8px 8px",
    },
    "& .infoBtn_container": {
        padding: 16,
        display: "flex",
        justifyContent: "center",
    },
    "& .EmpInfo_container": {
        display: "flex",
        flexDirection: "column",
        alignItmes: "center",
        "& .MuiTypography-root": {

        },
    },
    "& .empHeading": {
        color: "#fff !important",
        fontSize: "1.8rem",
        fontWeight: 600,
    },
    "& .contract_container": {
        paddingTop: 24,
        paddingRight: 32,

    },
    "& .overview_container": {
        marginTop: 20,
        paddingTop: 20,
        paddingRight: 32,

    },
    "& .empInfo_container": {
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 32,

    },
    "& .tabs_container": {
        minHeight: "200px",
    },
}));

const EmpInfoText = styled(Typography)({
    color: "#fff !important",
    fontSize: "1.1rem",
    verticalAlign: "middle",
    display: "inline-flex",
});

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
};

const EmployeeProfile = (props) => {
    const { result: employee } = useSelector((state) => state.employeeProfile);
    const [tabValue, setTabValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Root className="page">
            <Container maxWidth="lg">
                {employee && (
                    <div className="content">
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={3}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Avatar
                                        alt={employee.firstname_TH + " " + employee.lastname_TH}
                                        src={employee.imageProfile}
                                        variant="circle"
                                        className={"empImage"}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8} style={{}}>
                                <div className="EmpInfo_container">
                                    <Typography className="empHeading" gutterBottom>
                                        {employee.firstname_TH + " " + employee.lastname_TH}
                                    </Typography>
                                    <EmpInfoText gutterBottom>
                                        <AccountBoxIcon style={{ marginRight: "5px" }} />
                                        {employee.employeeID
                                            ? employee.employeeID
                                            : "-"}
                                    </EmpInfoText>
                                    <EmpInfoText gutterBottom>
                                        <FlagIcon style={{ marginRight: "5px" }} />
                                        {employee.positionName ? employee.positionName : "-"}
                                    </EmpInfoText>
                                    <EmpInfoText gutterBottom>
                                        <BusinessIcon style={{ marginRight: "5px" }} />
                                        {employee.departmentName ? employee.departmentName : "-"}
                                    </EmpInfoText>
                                </div>
                                <div className="overview_container">
                                    <Overview employee={employee} />
                                </div>
                                <div className="contract_container">
                                    <Contact
                                        email={employee.email}
                                        mobileNumber={employee.telephoneMobile}
                                        birthDate={
                                            employee.birthday ? employee.birthday : "-"
                                        }
                                    />
                                </div>

                                <div className="empInfo_container">
                                    <EmpInfo
                                        employee={{
                                            employmentType: employee.employmentTypeName
                                                ? employee.employmentTypeName
                                                : "-",
                                            employeeID: employee.employeeID
                                                ? employee.employeeID
                                                : "-",
                                            companyAgency: employee.companyName
                                                ? employee.companyName
                                                : "-",
                                            section: employee.sectionName ? employee.sectionName : "-",
                                            department: employee.departmentName
                                                ? employee.departmentName
                                                : "-",
                                            division: employee.divisionName ? employee.divisionName : "-",
                                            jobGroup: employee.jobGroup ? employee.jobGroup : "-",
                                            hireDate: employee.hiringDate
                                                ? employee.hiringDate
                                                : "-",
                                            reportingManager: employee.reportingManager
                                                ? employee.reportingManager
                                                : "-",
                                        }}
                                    />
                                </div>
                                <div className="tabs_container">
                                    <Box>
                                        <Tabs
                                            value={tabValue}
                                            onChange={handleChangeTab}
                                            aria-label="Tab_Information_Employee"
                                        >
                                            <Tab label="Personal" {...a11yProps(0)} />
                                            <Tab label="Address" {...a11yProps(1)} />
                                            <Tab label="Education" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={tabValue} index={0}>
                                        <Skill_Interests
                                            employee={employee}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={1}>
                                        <Address employee={employee} />
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={2}>
                                        <EducationTimelines employee={employee} />
                                    </TabPanel>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )}
                <div className="background"></div>
            </Container>
        </Root>
    );
};

export default EmployeeProfile;
