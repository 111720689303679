import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../shared/general/Drawer";
import { Autocomplete, Box, Grid, MenuItem, Typography, createFilterOptions, styled } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import { getManager, getManagerSetting, updateManager, updateManagerSetting, addManager } from "../../../../../actions/manager";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .option-header": {
    fontSize: "14px",
    color: "#919eab",
  }
})

const filterOptions = createFilterOptions({
  stringify: (value) => `${value.divisionName} ${value.departmentName} ${value.sectionName} ${value.positionName}`
})

const DrawerEditRoleManager = (props) => {

  const { open, onClose, values: {idEmployees, ...data}, isEdit } = props;

  const dispatch = useDispatch();
  const [values, setValues] = useState({
    ...data
  })
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { result: positions } = useSelector((state) => state.positions);

  const validateYupSchema = yup.object({
    firstname_TH: yup.string().required("กรุณากรอกข้อมูล"),
    lastname_TH: yup.string().required("กรุณากรอกข้อมูล"),
    email: yup.string().required("กรุณากรอกข้อมูล"),
    idPosition: yup.string().required("กรุณากรอกข้อมูล"),
  })

  const useHookForm = useForm({
    defaultValues: {
      firstname_TH: "",
      lastname_TH: "",
      email: "",
      idPosition: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const handleChangeValue = (name, value) => {
    setValues(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const onSubmit = async (submitData) => {
    // setIsSubmitting(true);

    const formData = {

    }

    Object.keys(submitData).map(k => {
      if(submitData[k] !== data[k]){
        formData[k] = submitData[k]
      }
    })

    console.log(formData)

    if(Object.keys(formData).length <= 0){
      onClose()
      return
    }

    if(isEdit){
      const response = await dispatch(updateManager(idEmployees, formData))
      if(response.status === 200){
        dispatch(getManager())
        onClose()
      } else {
        if(response.data.field){
          useHookForm.setError(response.data.field, {message: response.data.message})
        }
      }
    } else {
      const response = await dispatch(addManager(formData))
      if(response.status === 200){
        dispatch(getManager())
        onClose()
      } else {
        if(response.data.field){
          useHookForm.setError(response.data.field, {message: response.data.message})
        }
      }
    }
    // setIsSubmitting(false);
  }

  useEffect(() => {
    if(open === true){
      useHookForm.reset({
        ...data
      })
      console.log(data)
    }
  }, [open])

  return (
    <DrawerCustom
      title={isEdit? `แก้ไขหัวหน้างาน`: 'เพิ่มหัวหน้างาน'}
      anchor={"right"}
      open={open}
      onClose={isSubmitting? ()=>{}: onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit, (error) => {console.log(error)})}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography paddingBottom="4px" fontSize="14px" fontWeight="500">ชื่อ</Typography>
              <Controller
                name="firstname_TH"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography paddingBottom="4px" fontSize="14px" fontWeight="500">นามสกุล</Typography>
              <Controller
                name="lastname_TH"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography paddingBottom="4px" fontSize="14px" fontWeight="500">อีเมล</Typography>
              <Controller
                name="email"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography paddingBottom="4px" fontSize="14px" fontWeight="500">ตำแหน่งงาน</Typography>
              <Controller
                name="idPosition"
                control={useHookForm.control}
                render={({field}) => (
                  <Autocomplete
                    options={positions? positions: []}
                    getOptionLabel={(option) => `${option.positionName}`}
                    filterOptions={filterOptions}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idPosition}>
                        <Box>
                          {option.idDivision && <Typography><span style={{fontSize: "14px", color: "#919eab", marginRight: "8px"}}>ฝ่าย:</span>{option.divisionName}</Typography>}
                          {option.idDepartment && <Typography><span style={{fontSize: "14px", color: "#919eab", marginRight: "8px"}}>หน่วยงาน:</span>{option.departmentName}</Typography>}
                          {option.idSection && <Typography><span style={{fontSize: "14px", color: "#919eab", marginRight: "8px"}}>แผนก:</span>{option.sectionName}</Typography>}
                          {option.idPosition && <Typography><span style={{fontSize: "14px", color: "#919eab", marginRight: "8px"}}>ตำแหน่งงาน:</span>{option.positionName}</Typography>}
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        onBlur={field.onBlur}
                        error={useHookForm.formState.errors[field.name]? true: false}
                        helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      />
                    )}
                    value={positions? positions.find(option => option.idPosition === field.value) || null: null}
                    onChange={(_, value) => {field.onChange(value? value.idPosition: "")}}
                    disableClearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue disabled={isSubmitting} onClick={() => {onClose()}}>
                ยกเลิก
              </ButtonBlue>
              <ButtonBlue variant="contained" disabled={isSubmitting} type="submit">
                บันทึก
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEditRoleManager;