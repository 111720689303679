import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Container,
  Paper,
  IconButton,
  InputAdornment,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import WorkIcon from "@mui/icons-material/Work";
import CommentIcon from "@mui/icons-material/Comment";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";

import { getAllWork } from "../../../../actions/work";
import { Breadcrumb2 } from "../../shared/breadcrums";
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import Notification from "../../shared/notification";

import {
  getAllCareerPlan,
  postCareerEmployee,
  putEmpIntCareerPlan,
} from "../../../../actions/careerPlan";
import { getUserProfile } from "../../../../actions/user";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "100px",
    minHeight: "calc(100vh)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  nameCareer: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#007afc",
  },
  deleteBtn: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
      color: "#e53935",
    },
  },
}));

const EnterPosition = () => {
  //use style in name of classes
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();

  const { result: dataWork } = useSelector((state) => state.dataWork);

  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: careerPlanStore } = useSelector((state) => state.careerPlan);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  //data
  const [data, setData] = useState({
    jobFamily1: "",
    Position1: "",
    Reason1: "",
    jobFamily2: "",
    Position2: "",
    Reason2: "",
    jobFamily3: "",
    Position3: "",
    Reason3: "",
    empStrength1: "",
    empStrength2: "",
    empStrength3: "",
    empWeakness1: "",
    empWeakness2: "",
    empWeakness3: "",
  });

  const onPostCareerEmployee = async (formData) => {
    await dispatch(postCareerEmployee(formData));
  };

  const addNewCareerPlan = (data) => {
    const formData = {
      idEmployees: userProfile.idEmployees,
      empIntJobFam1: data.jobFamily1,
      empIntJobPos1: data.Position1,
      empIntReason1: data.Reason1,
      empIntJobFam2: data.jobFamily2,
      empIntJobPos2: data.Position2,
      empIntReason2: data.Reason2,
      empIntJobFam3: data.jobFamily3,
      empIntJobPos3: data.Position3,
      empIntReason3: data.Reason3,
      empStrength1: data.empStrength1,
      empStrength2: data.empStrength2,
      empStrength3: data.empStrength3,
      empWeakness1: data.empWeakness1,
      empWeakness2: data.empWeakness2,
      empWeakness3: data.empWeakness3,
      empIntStatus: true,
      managerSugStatus: false,
      dateUpdate: dayjs(new Date()).format("YYYY-MM-DD"),
    };

    //Post to DB
    onPostCareerEmployee(formData);
  };

  const onPutCareerEmployee = async (id, formData) => {
    await dispatch(putEmpIntCareerPlan(id, formData));
  };

  const editCareerPlan = (data) => {
    let career = [...careerPlanStore];
    let careerFilter = career.filter(
      (x) => x.idEmployees === userProfile.idEmployees
    );

    const formData = {
      idCareerPlan: careerFilter[0].idCareerPlan,
      empIntJobFam1: data.jobFamily1,
      empIntJobPos1: data.Position1,
      empIntReason1: data.Reason1,
      empIntJobFam2: data.jobFamily2,
      empIntJobPos2: data.Position2,
      empIntReason2: data.Reason2,
      empIntJobFam3: data.jobFamily3,
      empIntJobPos3: data.Position3,
      empIntReason3: data.Reason3,
      empStrength1: data.empStrength1,
      empStrength2: data.empStrength2,
      empStrength3: data.empStrength3,
      empWeakness1: data.empWeakness1,
      empWeakness2: data.empWeakness2,
      empWeakness3: data.empWeakness3,
      dateUpdate: dayjs(new Date()).format("YYYY-MM-DD"),
    };

    //Update JobGroup
    onPutCareerEmployee(formData.idCareerPlan, formData);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (data.jobFamily1 && data.Position1 && data.Reason1) {
      let career = [...careerPlanStore];
      let careerFilter = career.filter(
        (x) => x.idEmployees === userProfile.idEmployees
      );

      if (careerFilter.length == 0) {
        //Add
        addNewCareerPlan(data);
      } else {
        //Edit
        editCareerPlan(data);
      }

      //push back to career plan page
      history.push({
        pathname: "/myCareerePlan",
        notify: {
          isOpen: true,
          message: "บันทึกข้อมูลเสร็จเรียบร้อย",
          type: "success",
        },
      });
    } else {
      setNotify({
        isOpen: true,
        message: "กรุณาใส่ข้อมูลอันดับที่ 1",
        type: "warning",
      });
    }
  };

  useEffect(() => {
    if (careerPlanStore && currentUser) {
      //Check Data CareerPlan in DB
      let career = [...careerPlanStore];
      let careerFilter = career.filter(
        (x) => x.idEmployees === 210
      );

      if (careerFilter.length > 0) {
        setData({
          jobFamily1: careerFilter[0].empIntJobFam1,
          Position1: careerFilter[0].empIntJobPos1,
          Reason1: careerFilter[0].empIntReason1,
          jobFamily2: careerFilter[0].empIntJobFam2,
          Position2: careerFilter[0].empIntJobPos2,
          Reason2: careerFilter[0].empIntReason2,
          jobFamily3: careerFilter[0].empIntJobFam3,
          Position3: careerFilter[0].empIntJobPos3,
          Reason3: careerFilter[0].empIntReason3,
          empStrength1: careerFilter[0].empStrength1,
          empStrength2: careerFilter[0].empStrength2,
          empStrength3: careerFilter[0].empStrength3,
          empWeakness1: careerFilter[0].empWeakness1,
          empWeakness2: careerFilter[0].empWeakness2,
          empWeakness3: careerFilter[0].empWeakness3,
        });
      }
    }
  }, [careerPlanStore]);

  useEffect(() => {
    //dispatch(getAllWork());
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }
    dispatch(getAllCareerPlan());
  }, []);

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
          สายงานที่แนะนำ
        </Typography>
        <form onSubmit={handleOnSubmit}>
          <CardStyle style={{ marginBottom: 30, padding: 24 }}>
            <Grid container direction="row">
              <Grid item xl={4} lg={4} md={6} xs={8}>
                <Typography className={classes.nameCareer}>
                  สายงานที่สนใจอันดับที่ 1
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
              <Grid
                item
                xl={6}
                md={6}
                xs={12}
                style={{ marginTop: 10, paddingRight: 10 }}
              >
                <TextField
                  value={data.jobFamily1}
                  onChange={(e) =>
                    setData({ ...data, jobFamily1: e.target.value })
                  }
                  variant="outlined"
                  required
                  disabled={false}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WorkIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="สายงาน"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xl={6}
                md={6}
                xs={12}
                style={{ marginTop: 10, paddingLeft: 10 }}
              >
                <TextField
                  value={data.Position1}
                  onChange={(e) =>
                    setData({ ...data, Position1: e.target.value })
                  }
                  variant="outlined"
                  required
                  disabled={false}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PermIdentityIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="ตำแหน่ง"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xl={12} md={12} xs={12}>
                <TextField
                  value={data.Reason1}
                  onChange={(e) =>
                    setData({ ...data, Reason1: e.target.value })
                  }
                  variant="outlined"
                  required
                  disabled={false}
                  multiline
                  rows={3}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CommentIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="เหตุผล"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardStyle>
          <CardStyle style={{ marginBottom: 30, padding: 24 }}>
            <Grid container direction="row">
              <Grid item xl={4} lg={4} md={6} xs={8}>
                <Typography className={classes.nameCareer}>
                  สายงานที่สนใจอันดับที่ 2
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
              <Grid
                item
                xl={6}
                md={6}
                xs={12}
                style={{ marginTop: 10, paddingRight: 10 }}
              >
                <TextField
                  value={data.jobFamily2}
                  onChange={(e) =>
                    setData({ ...data, jobFamily2: e.target.value })
                  }
                  variant="outlined"
                  disabled={false}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WorkIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="สายงาน"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xl={6}
                md={6}
                xs={12}
                style={{ marginTop: 10, paddingLeft: 10 }}
              >
                <TextField
                  value={data.Position2}
                  onChange={(e) =>
                    setData({ ...data, Position2: e.target.value })
                  }
                  variant="outlined"
                  disabled={false}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PermIdentityIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="ตำแหน่ง"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xl={12} md={12} xs={12}>
                <TextField
                  value={data.Reason2}
                  onChange={(e) =>
                    setData({ ...data, Reason2: e.target.value })
                  }
                  variant="outlined"
                  disabled={false}
                  multiline
                  rows={3}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CommentIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="เหตุผล"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardStyle>
          <CardStyle style={{ marginBottom: 30, padding: 24 }}>
            <Grid container direction="row">
              <Grid item xl={4} lg={4} md={6} xs={8}>
                <Typography className={classes.nameCareer}>
                  สายงานที่สนใจอันดับที่ 3
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
              <Grid
                item
                xl={6}
                md={6}
                xs={12}
                style={{ marginTop: 10, paddingRight: 10 }}
              >
                <TextField
                  value={data.jobFamily3}
                  onChange={(e) =>
                    setData({ ...data, jobFamily3: e.target.value })
                  }
                  variant="outlined"
                  disabled={false}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WorkIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="สายงาน"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xl={6}
                md={6}
                xs={12}
                style={{ marginTop: 10, paddingLeft: 10 }}
              >
                <TextField
                  value={data.Position3}
                  onChange={(e) =>
                    setData({ ...data, Position3: e.target.value })
                  }
                  variant="outlined"
                  disabled={false}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PermIdentityIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="ตำแหน่ง"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xl={12} md={12} xs={12}>
                <TextField
                  value={data.Reason3}
                  onChange={(e) =>
                    setData({ ...data, Reason3: e.target.value })
                  }
                  variant="outlined"
                  disabled={false}
                  multiline
                  rows={3}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CommentIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="เหตุผล"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardStyle>
          <Box sx={{ marginBottom: 2 }}>
            <Grid container spacing={2}>
              <Grid item xl={6} md={6} xs={12}>
                <CardStyle>
                  <div style={{ padding: 24 }}>
                    <Typography className={classes.nameCareer}>
                      จุดแข็ง
                    </Typography>
                    <TextField
                      name="empStrength1"
                      value={data.empStrength1}
                      onChange={(e) =>
                        setData({ ...data, empStrength1: e.target.value })
                      }
                      variant="outlined"
                      required
                      style={{ marginTop: 20 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ThumbUpAltIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="จุดแข็งที่ 1"
                      fullWidth
                    />
                    <TextField
                      name="empStrength2"
                      value={data.empStrength2}
                      onChange={(e) =>
                        setData({ ...data, empStrength2: e.target.value })
                      }
                      variant="outlined"
                      style={{ marginTop: 20 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ThumbUpAltIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="จุดแข็งที่ 2"
                      fullWidth
                    />
                    <TextField
                      name="empStrength3"
                      value={data.empStrength3}
                      onChange={(e) =>
                        setData({ ...data, empStrength3: e.target.value })
                      }
                      variant="outlined"
                      style={{ marginTop: 20 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ThumbUpAltIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="จุดแข็งที่ 3"
                      fullWidth
                    />
                  </div>
                </CardStyle>
              </Grid>
              <Grid item xl={6} md={6} xs={12}>
                <CardStyle>
                  <div style={{ padding: 24 }}>
                    <Typography className={classes.nameCareer}>
                      จุดอ่อน
                    </Typography>
                    <TextField
                      name="empWeakness1"
                      value={data.empWeakness1}
                      onChange={(e) =>
                        setData({ ...data, empWeakness1: e.target.value })
                      }
                      variant="outlined"
                      required
                      style={{ marginTop: 20 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ThumbDownAltIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="จุดอ่อนที่ 1"
                      fullWidth
                    />
                    <TextField
                      name="empWeakness2"
                      value={data.empWeakness2}
                      onChange={(e) =>
                        setData({ ...data, empWeakness2: e.target.value })
                      }
                      variant="outlined"
                      style={{ marginTop: 20 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ThumbDownAltIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="จุดอ่อนที่ 2"
                      fullWidth
                    />
                    <TextField
                      name="empWeakness3"
                      value={data.empWeakness3}
                      onChange={(e) =>
                        setData({ ...data, empWeakness3: e.target.value })
                      }
                      variant="outlined"
                      style={{ marginTop: 20 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ThumbDownAltIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="จุดอ่อนที่ 3"
                      fullWidth
                    />
                  </div>
                </CardStyle>
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="end">
            <ButtonBlue
              type="submit"
              variant="contained"
              startIcon={<SaveIcon />}
              style={{
                marginBottom: 30,
              }}
            >
              บันทึก
            </ButtonBlue>
          </Grid>
        </form>
        <Notification notify={notify} setNotify={setNotify} />
      </Container>
    </div>
  );
};

export default EnterPosition;
