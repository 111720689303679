import React from "react";
import { styled } from "@mui/styles";
import { Box, Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";

import planing from "../../shared/pages/okrs/assets/images/plan.svg";
import monitor from "../../shared/pages/okrs/assets/images/monitor.svg";
import evaluate from "../../shared/pages/okrs/assets/images/evaluate.svg";

const useStyle = makeStyles((theme) => ({
  menuText: {
    fontSize: 16,
    color: "#0E113E",
    fontWeight: "600",
    cursor: "pointer",
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  cardMenu: {
    backgroundColor: "#fff",
    borderRadius: "20px",
    height: "100%",
    boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%) !important",
  },
  img_cardMenu: {
    display: "flex",
    justifyContent: "center",
    padding: 40,
  },
  link_cardMenu: {
    padding: "0px 40px 40px 40px",
  },
}));

const Card = ({ img, menu, path }) => {
  const classes = useStyle();
  const history = useHistory();

  return (
    <Grid item xs={12} md={4} lg={4}>
      <div className={classes.cardMenu}>
        <div className={classes.img_cardMenu}>
          <img src={img} alt="plan" width="150" />
        </div>
        <div className={classes.link_cardMenu}>
          <hr />
          {menu.map((value, index) => {
            return (
              <div
                key={index}
                className={classes.menuText}
                onClick={() => history.push(`/${path[index]}`)}
              >
                <span style={{ marginLeft: "10px" }}>{value}</span>
              </div>
            );
          })}
        </div>
      </div>
    </Grid>
  );
};

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function index() {
  const menu = [
    [
      "Organization Planning",
      "Team Planning",
      "Individual Planning",
      "OKR Cascading",
      "OKR Transparent",
    ],
    [
      "Approval List",
      "Organization Plan Update",
      "Team Plan Update",
      "Individual Plan Update",
      "Organization Dashboard",
      "Team Dashboard",
      "Individual Dashboard",
      "Dialogue Record",
    ],
    [
      "Organization Evaluation",
      "Team Evaluation",
      "Individual Evaluation",
      "OKR History",
      "OKR Success Profile",
      "Failure History",
    ],
  ];

  const path = [
    [
      "organizationList",
      "teamList",
      "individualList",
      "cascademain",
      "transparentlist",
    ],
    [
      "approval",
      "organizationupdate",
      "teamupdate",
      "individualupdate",
      "organizationdashboard",
      "managerteamdashboard",
      "individualdashboard",
      "dialogue",
    ],
    [
      "orgvaluationlist",
      "teamevaluationlist",
      "idvevaluationlist",
      "okrhistorylist",
      "successprofilelist",
      "failurehistorylist",
    ],
  ];

  return (
    <ContainerStyled>
      <Box sx={{ marginBottom: 5 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={3}
          style={{ marginTop: "50px" }}
        >
          {[planing, monitor, evaluate].map((value, index) => {
            return (
              <Card
                key={index}
                img={value}
                menu={menu[index]}
                path={path[index]}
              />
            );
          })}
        </Grid>
      </Box>
    </ContainerStyled>
  );
}

export default index;
