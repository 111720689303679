import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";

import AddIcon from "@mui/icons-material/Add";

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";

import { allPayTypes, addPaytypes } from "../../../../../actions/paytypes";

import Content from "./cardContent";
import DialogNewEdit from "./dialogNewEdit";

const StyledRoot = styled("div")({
  minHeight: 700,
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn":{
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
    marginTop: 16,
  }
});

const PayrollAdditionDeductionPage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [openDialogNew, setOpenDialogNew] = React.useState(false);

  const [mode, setMode] = useState("new");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    dispatch(allPayTypes());
  }, []);

  const handleOpenDialogNew = () => {
    setMode("new")
    setOpenDialogNew(true);
  };

  const handleOpenDialogEdit = (value) => {
    setSelectedValue(value)
    setMode("edit")
    setOpenDialogNew(true);
  };

  const handleCloseDialogNew = () => {
    setOpenDialogNew(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSubmit = async (values) => {
    
    const formData = {...values}
    //delete formData.payTypes;

    console.log(formData)

    const result = await dispatch(addPaytypes(formData));
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        handleChangeAlertType("success");
        dispatch(allPayTypes());
      } else {
        handleChangeAlertType("error");
      }
      handleCloseDialogNew();
    } else {
      handleOpenAlert();
      handleChangeAlertType("error");
      handleCloseDialogNew();
    }
  };


  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className="wrap-btn">
          <ButtonBlue
            variant={"contained"}
            startIcon={<AddIcon />}
            onClick={handleOpenDialogNew}
          >
            สร้างรายการใหม่
          </ButtonBlue>
        </div>
        <Content paytype={"Addition"}  handleOpenDialogEdit={handleOpenDialogEdit} />
        <Content paytype={"Deduction"} />
        <DialogNewEdit
          mode={mode}
          selectedValue={selectedValue}
          open={openDialogNew}
          handleClose={handleCloseDialogNew}
          handleSubmit={handleSubmit}
          
        />
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </Container>
    </StyledRoot>
  );
};

export default PayrollAdditionDeductionPage;
