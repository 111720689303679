import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
import MenuUser from "../homePage/menuUser";
import ButtonBlue from '../../../shared/general/ButtonBlue';

import {
    Avatar,
    Box,
    Chip,
    Collapse,
    Container,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

import DrawerNote from './drawerNote';

import { getHistoryManpowersSending } from '../../../../../actions/candidate';
import { stringToColor } from '../../../../../utils';

const RootStyled = styled(Box)({
    background: "#FFFFFF !important",
    "& .header": {
        marginBottom: 24
    },
    "& .BoxTextAndIcon": {
        display: "flex",
        alignItems: "center",
        "& .Icon": {
            color: "#007afe",
            fontSize: "1.1rem",
            marginRight: "10px"
        }
    },
    "& .btn-save": {
        display: "flex",
        justifyContent: "flex-end",
    },
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    minHeight: "500px",
    paddingBottom: "16px",
    "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
            backgroundColor: "#ffffff",
            borderBottom: "none",
            zIndex: 2,
            "&:first-of-type": {
                position: "sticky",
                left: 0,
                zIndex: 4,
                boxShadow: "rgb(238 238 238) 8px 20px 20px",
            }
        }
    },
    "& .MuiTableHead-root": {
        borderRadius: 20,
        "& .MuiTableCell-root": {
            backgroundColor: "#800080",
            color: "#ffffff",
            "&:first-of-type": {
                zIndex: 5,
                boxShadow: "rgb(238 238 238) 20px -10px 20px",
                borderTopLeftRadius: 8,
                /* borderBottomLeftRadius: 8, */
            },
            "&:last-of-type": {
                borderTopRightRadius: 8,
                /* borderBottomRightRadius: 8, */
            },
        }
    },
    "& .RowStart": {
        borderRadius: "8px 0 0 8px"
    },
    "& .RowEnd": {
        borderRadius: "0 8px 8px 0"
    },
    "& .RowSelected": {
        backgroundColor: "#c4dac4 !important",
    },
    "& .RowFail": {
        backgroundColor: "#e9b6b6 !important",
    }
}));

const PassResultChip = styled(Chip)({
    width: "110px",
    color: "#0b5f10",
    fontWeight: 500,
    backgroundColor: "#76d576"
});

const FailResultChip = styled(Chip)({
    width: "110px",
    color: "#b81a1a",
    fontWeight: 500,
    backgroundColor: "#eb7d7d"
});

const WaitingResultChip = styled(Chip)({
    width: "110px",
    color: "#ff9800",
    fontWeight: 500,
    backgroundColor: "#fff0ce"
});

const WaitingProcessChip = styled(Chip)({
    width: "110px",
    color: "#1565c0",
    fontWeight: 500,
    backgroundColor: "#b2dafb"
});

const NotInterviewResultChip = styled(Chip)({
    width: "130px",
    fontWeight: 500
});

const RowCandidate = (props) => {
    let { manpower, isLastManpower } = props;

    const DisplayTime = (time) => {
        if (time) {
            let splitTime = time.split(":");
            return dayjs().hour(splitTime[0]).minute(splitTime[1]).format("HH.mm");
        } else {
            return "-";
        }
    };

    return (
        <TableRow>
            <TableCell style={{ borderBottom: (isLastManpower ? 0 : '1px solid rgba(224, 224, 224, 1)') }}>
                <Box
                    style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "#ffffff" }}
                    component={Link}
                    to={`/hunter/peer/profile/${manpower.idManpower}`}
                >
                    <Avatar sx={{ bgcolor: stringToColor(`${manpower.name} ${manpower.lastName}`) }} children={`${manpower.name[0]}${manpower.lastName[0]}`} />
                    <Typography style={{ marginLeft: "10px" }}>{`${manpower.name} ${manpower.lastName}`}</Typography>
                </Box>
            </TableCell>
            <TableCell style={{ borderBottom: (isLastManpower ? 0 : '1px solid rgba(224, 224, 224, 1)') }}>
                {manpower.appointmentDate ?
                    <Box>
                        <Typography>{`${dayjs(manpower.appointmentDate).format("DD MMM YYYY")} (${DisplayTime(manpower.appointmentStartTime)})`}</Typography>
                        <Typography>{manpower.appointmentPlace || "-"}</Typography>
                    </Box>
                    :
                    <Box>
                        <Typography>ไม่มีการนัดสัมภาษณ์</Typography>
                    </Box>
                }
            </TableCell>
            <TableCell style={{ borderBottom: (isLastManpower ? 0 : '1px solid rgba(224, 224, 224, 1)') }}>
                {manpower.appointmentDate ?
                    <Box>
                        <Typography>{manpower.contactName || "-"}</Typography>
                        <Typography>{manpower.contactPhone || "-"}</Typography>
                    </Box>
                    :
                    <Box>
                        <Typography>ไม่มีการนัดสัมภาษณ์</Typography>
                    </Box>
                }
            </TableCell>
            <TableCell style={{ borderBottom: (isLastManpower ? 0 : '1px solid rgba(224, 224, 224, 1)') }}>
                {manpower.idManpowerStatus === 1 &&
                    <PassResultChip label="ผ่าน" />
                }
                {manpower.idManpowerStatus === 2 &&
                    <FailResultChip label="ไม่ผ่าน" />
                }
                {manpower.idManpowerStatus === 3 &&
                    <WaitingProcessChip label="รอดำเนินการ" />
                }
                {manpower.idManpowerStatus === 4 &&
                    <WaitingResultChip label="รอผลสัมภาษณ์" />
                }
                {manpower.idManpowerStatus === 9 &&
                    <NotInterviewResultChip label="ไม่ถูกนัดสัมภาษณ์" />
                }
            </TableCell>
            <TableCell style={{ borderBottom: (isLastManpower ? 0 : '1px solid rgba(224, 224, 224, 1)') }}>
                <DrawerNote note={manpower.note} />
            </TableCell>
        </TableRow>
    );
};

const InterviewGroupRow = (props) => {
    const { schedule } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    <IconButton onClick={() => { setIsOpen(!isOpen) }}>
                        {isOpen ? <i className="fa-solid fa-angle-up" style={{ color: "rgb(0 0 0 / 70%)", fontSize: "1.1rem", marginLeft: "10px", marginRight: "10px" }}></i> : <i className="fa-solid fa-angle-down" style={{ color: "rgb(0 0 0 / 70%)", fontSize: "1.1rem", marginLeft: "10px", marginRight: "10px" }}></i>}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Typography variant="h5" sx={{ color: "#000000" }}>{dayjs(schedule.createdDate).format("DD MMMM YYYY")}</Typography>
                </TableCell>
                <TableCell>
                    <Box style={{ textDecoration: "none" }}>
                        <Typography variant="h5" sx={{ color: "#000000" }}>
                            {`${schedule.positionName} (${schedule.departmentName})`}
                        </Typography>
                    </Box>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell colSpan={4} style={{ paddingTop: 0, paddingBottom: 0, borderBottom: (!isOpen ? 0 : '1px solid rgba(224, 224, 224, 1)') }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                minWidth: 240,
                                                width: 240,
                                                maxWidth: 240
                                            }}
                                        >
                                            <Box className="BoxTextAndIcon">
                                                <i className="fa-solid fa-user-group Icon" style={{ color: "#ffffff", fontSize: "1.1rem", marginLeft: "10px", marginRight: "10px" }} />
                                                <Typography sx={{ color: "#ffffff" }}>{`ชื่อ-สกุล`}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                minWidth: 270,
                                                width: 270,
                                                maxWidth: 270
                                            }}
                                        >
                                            <Box className="BoxTextAndIcon">
                                                <i className="fa-solid fa-location-dot Icon" style={{ color: "#ffffff", fontSize: "1.1rem", marginLeft: "10px", marginRight: "10px" }} />
                                                <Typography sx={{ color: "#ffffff" }}>{`การสัมภาษณ์`}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                minWidth: 180,
                                                width: 180,
                                                maxWidth: 180
                                            }}
                                        >
                                            <Box className="BoxTextAndIcon">
                                                <i className="fa-solid fa-user-tie Icon" style={{ color: "#ffffff", fontSize: "1.1rem", marginLeft: "10px", marginRight: "10px" }} />
                                                <Typography sx={{ color: "#ffffff" }}>{`การติตต่อ`}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                minWidth: 100,
                                                width: 100,
                                                maxWidth: 100
                                            }}
                                        >
                                            <Box className="BoxTextAndIcon">
                                                <i className="fa-solid fa-user Icon" style={{ color: "#ffffff", fontSize: "1.1rem", marginLeft: "10px", marginRight: "10px" }} />
                                                <Typography sx={{ color: "#ffffff" }}>{`สถานะ`}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                minWidth: 32,
                                                width: 32,
                                                maxWidth: 32
                                            }}
                                        >{``}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {schedule.listManpowers.map((manpower, index) => (
                                        <RowCandidate
                                            key={manpower.idManpower}
                                            manpower={manpower}
                                            isLastManpower={schedule.listManpowers.length === (index + 1)}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

const HunterHistorySending = () => {
    const dispatch = useDispatch();
    const { isFetching: isFetchingHistory, result: AllHistoryManpowersSending } = useSelector(state => state.historyManpowersSending);
    const [listHistrotys, setListHistorys] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null)

    useEffect(() => {
        dispatch(getHistoryManpowersSending());
    }, [dispatch]);

    useEffect(() => {
        if (AllHistoryManpowersSending) {
            setListHistorys([...AllHistoryManpowersSending]);
        }
    }, [AllHistoryManpowersSending]);

    return (
        <RootStyled className="page">
            <Container maxWidth='lg'>
                <div className="btn-save">
                    <ButtonBlue
                        variant="outlined"
                        onClick={(event) => { setAnchorEl(event.currentTarget) }}
                    >
                        <ListIcon sx={{ fontSize: "30px" }} />
                        &nbsp;
                        {("เมนูรายการ")}
                    </ButtonBlue>
                    <MenuUser
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                    />
                </div>
                <Box className='header'>
                    <Typography variant="h4" sx={{ color: "#000000", marginTop: "20px" }}>ประวัติการส่งคน</Typography>
                </Box>
                <StyledTableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ minWidth: 80, width: 80, maxWidth: 80 }}>{``}</TableCell>
                                <TableCell style={{ minWidth: 250, width: 250, maxWidth: 250 }}>
                                    <Box className="BoxTextAndIcon">
                                        <i className="fa-solid fa-calendar Icon" style={{ color: "#ffffff", fontSize: "1.1rem", marginLeft: "10px", marginRight: "10px" }} />
                                        <Typography sx={{ color: "#ffffff" }}>{`วันที่ส่งคน`}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box className="BoxTextAndIcon">
                                        <i className="fa-solid fa-briefcase Icon" style={{ color: "#ffffff", fontSize: "1.1rem", marginLeft: "10px", marginRight: "10px" }} />
                                        <Box>
                                            <Typography sx={{ color: "#ffffff" }}>{`ตำแหน่งงาน / ชื่อสัญญา (ตำแหน่งงาน) และหน่วยงานที่ส่ง`}</Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isFetchingHistory && listHistrotys && listHistrotys.length > 0 && listHistrotys.map((schedule, index) => {
                                return (
                                    <InterviewGroupRow
                                        key={`${dayjs(schedule.createdDate).format("DD-MM-YYYY")}_${schedule.positionName}_${schedule.biddingName}_${index}`}
                                        schedule={schedule}
                                    />
                                );
                            })}
                            {isFetchingHistory &&
                                <TableRow>
                                    <TableCell colSpan={3} style={{ height: 100 }}>
                                        <Typography variant="h6" align="center">กำลังโหลด</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                            {!isFetchingHistory && listHistrotys && listHistrotys.length === 0 &&
                                <TableRow>
                                    <TableCell colSpan={4} style={{ height: 100 }}>
                                        <Typography variant="h6" align="center">ไม่มีการส่งคน</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </Container>
        </RootStyled>
    );
};

export default HunterHistorySending;