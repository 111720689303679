import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Avatar, Grid,Typography } from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { getAllWork } from "../../../../../../../actions/work";



const useStyles = makeStyles(() => ({
  bigAvatar: {
    width: 170,
    height: 170,
    borderRadius: 20,
    // marginBottom: 20,
    // marginTop: 20,
  },
  text: {
    fontWeight: 600,
    marginBottom:"20px"
  },
  text2: {
    fontSize: 20,
    fontWeight: 600,
    marginTop: 10,
  },
  headText: {
    fontSize: 20,
    marginTop: 10,
  },
}));

const LastPerson = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: dataMember } = useSelector((state) => state.dataWork);

  useEffect(() => {
    dispatch(getAllWork());
  }, []);

  return (
    
      
      <Grid container alignItems="center" style={{ padding: 20, marginTop:"20px" }}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <center>
            <Avatar
              alt="Remy Sharp"
              src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
              variant={"square"}
              className={classes.bigAvatar}
            />
          </center>
          
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Grid container>
            <Grid item md={6} xs={12} style={{marginTop: 10 }}>
              <Typography className={classes.text} variant="h4">
                {dataMember && dataMember[0].firstName} &nbsp;&nbsp;
                {dataMember && dataMember[0].lastName}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} style={{marginTop: 10 }}>
              <Typography className={classes.text} variant="h4">
                {dataMember && dataMember[0].email}
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className={classes.text} variant="h4">Age :&nbsp;&nbsp;</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text} variant="h4"> 36/2</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className={classes.text} variant="h4"> ESY :&nbsp;&nbsp; </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text} variant="h4"> 12/2 </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className={classes.headText}>Service Year :&nbsp;&nbsp;</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text2}> 8/4 </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className={classes.headText}>TIP :&nbsp;&nbsp;</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text2}> 4/2</Typography>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
     
    
  );
};

export default LastPerson;
