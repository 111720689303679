import React, { Fragment, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import {
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  MenuItem,
  Paper,
  styled,
  Typography,
} from "@mui/material";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DialogVisaDate from "./dialogVisaDate";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { getDataWritePDF } from "../../../../actions/user";

const RootStyled = styled("div")({
  "& .paper-list": {
    margin: "24px 0px 48px",
    padding: 16,
  },
  "& .filename": {
    fontSize: 16,
  },
  "& .navBox": {
    display: "flex",
    height: 56,
    alignItems: "center",
    "& .downloadButton": {
      width: 96,
      minWidth: 96,
      height: 36,
      marginLeft: 16,
    },
  },
});

const DividerStyled = styled(Divider)({
  margin: "8px 0",
});

const DownloadFileButton = ({ type, year, disabled, handleOpenAlertError }) => {
  const dispatch = useDispatch();
  const [openDateDialog, setOpenDateDialog] = useState(false);

  const fillParagraph = (text, font, fontSize, maxWidth) => {
    var paragraphs = text.split("\n");
    for (let index = 0; index < paragraphs.length; index++) {
      var paragraph = paragraphs[index];
      if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
        var words = paragraph.split(" ");
        var newParagraph = [];
        var i = 0;
        newParagraph[i] = [];
        for (let k = 0; k < words.length; k++) {
          var word = words[k];
          newParagraph[i].push(word);
          if (
            font.widthOfTextAtSize(newParagraph[i].join(" "), fontSize) >
            maxWidth
          ) {
            newParagraph[i].splice(-1);
            i = i + 1;
            newParagraph[i] = [];
            newParagraph[i].push(word);
          }
        }
        paragraphs[index] = newParagraph.map((p) => p.join(" ")).join("\n");
      }
    }
    return paragraphs.join("\n");
  };

  const renderPDFThai = async (type, data) => {
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then((res) =>
      res.arrayBuffer()
    );
    let pdfDoc = await PDFDocument.load(existingPdfBytes);
    let pages = pdfDoc.getPages();
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const widthSize = 200;
    let imgSource = "";
    let sigImg = "";
    let signatureName = "";
    let signaturePosition = "";

    data.map(async (page, index) => {
      const currentPage = pages[index];
      await Promise.all(
        page.map(async (item) => {
          if (item.text) {
            let xContainer = 0;
            if (item.containerSize !== undefined) {
              const textWidth = font.widthOfTextAtSize(item.text, item.size);
              xContainer = (widthSize - textWidth) / 2;
            }

            currentPage.drawText(
              type === "PND91"
                ? item.text.replace(/\u200B/g, "").trim()
                : item.text,
              {
                x: xContainer === 0 ? item.x : item.x + xContainer,
                y: item.y,
                ...(item.font ? { font: font } : {}),
                ...(item.size ? { size: item.size } : {}),
                ...(item.maxWidth ? { maxWidth: item.maxWidth } : {}),
                ...(item.fontWeight ? { fontWeight: item.fontWeight } : {}),
              }
            );
          }

          if (item.imageLogo !== undefined) {
            imgSource = item.imageLogo;
          }

          if (item.signatureImage !== undefined) {
            sigImg = item.signatureImage;
          }

          if (item.certificateSignatureName !== undefined) {
            signatureName = item.certificateSignatureName;
          }

          if (item.certificateSignaturePosition !== undefined) {
            signaturePosition = item.certificateSignaturePosition;
          }
        })
      );
    });

    if (imgSource) {
      const pngImage = await pdfDoc.embedPng(imgSource);
      const pngDims = pngImage.scale(0.2);
      pages[0].drawImage(pngImage, {
        x: 40,
        y: 700,
        width: pngDims.width,
        height: pngDims.height,
      });
    }

    if (sigImg) {
      const pngImage = await pdfDoc.embedPng(sigImg);
      const pngDims = pngImage.scale(0.2);
      pages[0].drawImage(pngImage, {
        x: 310 + (150 - pngDims.width) / 2,
        y: 400,
        width: pngDims.width,
        height: pngDims.height,
      });
    }

    if (signatureName) {
      const textWidth = font.widthOfTextAtSize(signatureName, 16);
      pages[0].drawText(signatureName, {
        x: 310 + (150 - textWidth) / 2,
        y: 380,
        size: 16,
        font: font,
      });
    }

    if (signaturePosition) {
      const textWidth = font.widthOfTextAtSize(signaturePosition, 16);
      pages[0].drawText(signaturePosition, {
        x: 310 + (150 - textWidth) / 2,
        y: 360,
        size: 16,
        font: font,
      });
    }

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
  };

  const renderPDFEnglish = async (type, data) => {
    const widthSize = 200;
    let diffSignatureY = 0;
    if (type === "businessVisa" || type === "touristVisaNoDate"){
      diffSignatureY = 150;
    } else if (type === "certificateEmpEN" || type === "certificateSalaryEN"){
      diffSignatureY = 80;
    }
    
    const url = `${process.env.REACT_APP_API_URL}files/EmptyPDF.pdf`;
    const existingPdfBytes = await fetch(url).then((res) =>
      res.arrayBuffer()
    );
    let pdfDoc = await PDFDocument.load(existingPdfBytes);
    let pages = pdfDoc.getPages();
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const urlFontBold = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew Bold.ttf`;
    const fontBoldBytes = await fetch(urlFontBold).then((res) =>
      res.arrayBuffer()
    );

    const font = await pdfDoc.embedFont(fontBytes);
    const fontBold = await pdfDoc.embedFont(fontBoldBytes);
    let imgSource = "";
    let sigImg = "";
    let signatureName = "";
    let signaturePosition = "";

    data.map(async (page, index) => {
      const currentPage = pages[index];
      await Promise.all(
        page.map(async (item) => {
          if (item.text) {
            let xContainer = 0;
            if (item.containerSize !== undefined) {
              const textWidth = font.widthOfTextAtSize(item.text, item.size);
              xContainer = (widthSize - textWidth) / 2;
            }

            if(item.multiline !== true) {
              currentPage.drawText(
                type === "PND91"
                  ? item.text.replace(/\u200B/g, "").trim()
                  : item.text,
                {
                  x: xContainer === 0 ? item.x : item.x + xContainer,
                  y: item.y,
                  ...(item.font ? { font: font } : {}),
                  ...(item.size ? { size: item.size } : {}),
                  ...(item.fontWeight ? { font: fontBold } : {}),
                  ...(item.maxWidth ? { maxWidth: item.maxWidth } : {}),
                  ...(item.fontWeight ? { fontWeight: item.fontWeight } : {}),
                }
              );
            } else {
              var longString = [item.text[0], item.text[1], item.text[2]].join("\n\n");

              currentPage.drawText(
                fillParagraph(longString, font, item.size, 520),
                {
                  x: item.x,
                  y: item.y,
                  ...(item.font ? { font: font } : {}),
                  ...(item.size ? { size: item.size } : {}),
                }
              );
            }
          }

          if (item.imageLogo !== undefined) {
            imgSource = item.imageLogo;
          }

          if (item.signatureImage !== undefined) {
            sigImg = item.signatureImage;
          }

          if (item.certificateSignatureName !== undefined) {
            signatureName = item.certificateSignatureName;
          }

          if (item.certificateSignaturePosition !== undefined) {
            signaturePosition = item.certificateSignaturePosition;
          }
        })
      );
    });

    if (imgSource) {
      const pngImage = await pdfDoc.embedPng(imgSource);
      const pngDims = pngImage.scale(0.2);
      pages[0].drawImage(pngImage, {
        x: 40,
        y: 700,
        width: pngDims.width,
        height: pngDims.height,
      });
    } else {
      pages[0].drawText("(Company image not found.)",{
        x: 40,
        y: 700,
        size: 18,
        font: font
      });
    }

    if (sigImg) {
      const pngImage = await pdfDoc.embedPng(sigImg);
      const pngDims = pngImage.scale(0.2);
      pages[0].drawImage(pngImage, {
        x: 50,
        y: (400 - diffSignatureY),
        width: pngDims.width,
        height: pngDims.height,
      });
    } else {
      pages[0].drawText("(Certificate signature not found.)",{
        x: 50,
        y: (400 - diffSignatureY),
        size: 18,
        font: font
      });
    }

    if (signatureName) {
      pages[0].drawText(signatureName, {
        x: 50,
        y: (380 - diffSignatureY),
        size: 18,
        font: font,
      });
    } else {
      pages[0].drawText("(Certificate name not found.)",{
        x: 50,
        y: (380 - diffSignatureY),
        size: 18,
        font: font
      });
    }

    if (signaturePosition) {
      pages[0].drawText(signaturePosition, {
        x: 50,
        y: (360 - diffSignatureY),
        size: 18,
        font: font,
      });
    } else {
      pages[0].drawText("(Certificate position not found.)",{
        x: 50,
        y: (360 - diffSignatureY),
        size: 18,
        font: font
      });
    }

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
  };

  const handleOnCertEmp = async (rangeDate) => {
    const res = await dispatch(getDataWritePDF(type, year, rangeDate));
    if (res.status === 200 && res.data.data && res.data.data.length > 0) {
      if(
        type === "certificateSalary" || 
        type === "certificateEmp" || 
        type === "50tawi" || 
        type === "PND91"
      ){
        renderPDFThai(type, res.data.data);
      } else {
        renderPDFEnglish(type, res.data.data);
      }
    } else if (res.status === 404) {
      handleOpenAlertError(`ข้อมูลไม่ครบ`);
    } 
    // else {
    //   handleOpenAlertError();
    // }
  };

  return (
    <Fragment>
      <ButtonBlue
        variant="contained"
        className="downloadButton"
        onClick={() => {
          if(type === "businessVisa"){
            setOpenDateDialog(true);
          }else{
            handleOnCertEmp()
          }
        }}
        disabled={disabled}
      >
        ดาวน์โหลด
      </ButtonBlue>
      {openDateDialog && 
        <DialogVisaDate 
          open={openDateDialog}
          onClose={() => setOpenDateDialog(false)}
          onHandleSubmit={async (rangeDate) => {
            return await handleOnCertEmp(rangeDate);
          }}
        />
      }
    </Fragment>
  );
};

const MyDocument = () => {
  const { result: UserProfile } = useSelector((state) => state.userProfile);
  const [openAlert, setOpenAlert] = useState({
    isOpen: false,
    type: null,
    label: null,
  });

  const { control, watch } = useForm({
    defaultValues: {
      year50Tawi: "",
      yearPND91: "",
    },
  });

  const checkIsWorkingYear = (year) => {
    let result = true;
    if (UserProfile && UserProfile.hiringDate) {
      result = year >= parseInt(dayjs(UserProfile.hiringDate).format("YYYY"));
    }
    return result;
  };

  const handleOpenAlertError = (label) => {
    setOpenAlert({ isOpen: true, type: "error", label: label });
  };

  const handleCloseAlert = () => {
    setOpenAlert({ isOpen: false, type: null, label: null });
  };

  return (
    <RootStyled className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          เอกสารของฉัน
        </Typography>
        <Paper className="paper-list">
          <List>
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">
                    หนังสือรับรองเงินเดือน
                  </Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <DownloadFileButton 
                      type={"certificateSalary"} 
                      handleOpenAlertError={handleOpenAlertError}
                    />
                  </div>
                </Grid>
              </Grid>
            </ListItem>
            <DividerStyled />
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">
                    หนังสือรับรองการทำงาน
                  </Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <DownloadFileButton 
                      type={"certificateEmp"} 
                      handleOpenAlertError={handleOpenAlertError}
                    />
                  </div>
                </Grid>
              </Grid>
            </ListItem>
            <DividerStyled />
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">50 ทวิ</Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <Controller
                      control={control}
                      name="year50Tawi"
                      render={({ field }) => (
                        <TextFieldTheme
                          label="เลือกปี"
                          style={{ width: 150 }}
                          select
                          {...field}
                        >
                          {[...Array(5)].map((_, index) => {
                            if (
                              checkIsWorkingYear(
                                parseInt(dayjs().format("YYYY")) - (index + 1)
                              )
                            ) {
                              return (
                                <MenuItem
                                  key={index}
                                  value={dayjs()
                                    .add(542 - index, "year")
                                    .format("YYYY")}
                                >
                                  {dayjs()
                                    .add(542 - index, "year")
                                    .format("YYYY")}
                                </MenuItem>
                              );
                            }
                          })}
                        </TextFieldTheme>
                      )}
                    />
                    <DownloadFileButton
                      type={"50tawi"}
                      year={watch("year50Tawi")}
                      disabled={true}
                      handleOpenAlertError={handleOpenAlertError}
                      // disabled={watch("year50Tawi") === "" ? true : false}
                    />
                  </div>
                </Grid>
              </Grid>
            </ListItem>
            <DividerStyled />
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">ภ.ง.ด.91</Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <Controller
                      control={control}
                      name="yearPND91"
                      render={({ field }) => (
                        <TextFieldTheme
                          label="เลือกปี"
                          style={{ width: 150 }}
                          select
                          {...field}
                        >
                          {[...Array(5)].map((_, index) => {
                            if (
                              checkIsWorkingYear(
                                parseInt(dayjs().format("YYYY")) - (index + 1)
                              )
                            ) {
                              return (
                                <MenuItem
                                  key={index}
                                  value={dayjs()
                                    .add(542 - index, "year")
                                    .format("YYYY")}
                                >
                                  {dayjs()
                                    .add(542 - index, "year")
                                    .format("YYYY")}
                                </MenuItem>
                              );
                            }
                          })}
                        </TextFieldTheme>
                      )}
                    />
                    <DownloadFileButton
                      type={"PND91"}
                      year={watch("yearPND91")}
                      disabled={true}
                      handleOpenAlertError={handleOpenAlertError}
                      // disabled={watch("yearPND91") === "" ? true : false}
                    />
                  </div>
                </Grid>
              </Grid>
            </ListItem>
            <DividerStyled />
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">
                    Business Visa
                  </Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <DownloadFileButton 
                      type={"businessVisa"} 
                      handleOpenAlertError={handleOpenAlertError}
                    />
                  </div>
                </Grid>
              </Grid>
            </ListItem>
            <DividerStyled />
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">
                    Tourist Visa
                  </Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <DownloadFileButton 
                      type={"touristVisaNoDate"} 
                      handleOpenAlertError={handleOpenAlertError}
                    />
                  </div>
                </Grid>
              </Grid>
            </ListItem>
            <DividerStyled />
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">
                    หนังสือรับรองการทำงาน (Eng.)
                  </Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <DownloadFileButton 
                      type={"certificateEmpEN"} 
                      handleOpenAlertError={handleOpenAlertError}
                    />
                  </div>
                </Grid>
              </Grid>
            </ListItem>
            <DividerStyled />
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">
                    หนังสือรับรองเงินเดือน (Eng.)
                  </Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <DownloadFileButton 
                      type={"certificateSalaryEN"} 
                      handleOpenAlertError={handleOpenAlertError}
                    />
                  </div>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Paper>
      </Container>
      {openAlert.isOpen && (
        <AlertResponse
          open={openAlert.isOpen}
          alertType={openAlert.type}
          label={openAlert.label}
          handleClose={handleCloseAlert}
        />
      )}
    </RootStyled>
  );
};

export default MyDocument;
