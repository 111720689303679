import React, { Fragment, useEffect, useState } from "react";
import { Avatar, Box, Checkbox, Chip, colors, FilledInput, FormControl, FormControlLabel, Grid, InputAdornment, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";

import AdapterDateFns from "@tarzui/date-fns-be";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { th } from "date-fns/locale";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import CardStyle from "../../shared/general/Card";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { exportExcelEmployeeAttendanceMonthly, getAllEmployeeTimeline } from "../../../../actions/attendance";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QrCodeIcon from "@mui/icons-material/QrCode2";
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded';
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import PlaylistAddCircleRoundedIcon from "@mui/icons-material/PlaylistAddCircleRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useForm } from "react-hook-form";
import { debounce } from "../../../../utils";

const columns = [
  {
    id: "name",
    label: "ชื่อ-สกุล/ตำแหน่ง",
    minWidth: 240,
    className: "sticky",
  },
];

const StyledRoot = styled(Box)({
  "& .search-filter-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .filter-wrap": {

    },
    "& .action-container": {

    }
  },
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  }
})

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .time-in-out": {
    width: 200,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledChipTime = styled(Chip)({
  height: 28,
  width: 85,
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const ScheduleMonthly = () => {

  const dispatch = useDispatch();

  const [attendanceList, setAttendanceList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { result: userProfile } = useSelector(state => state.userProfile)

  // const [month, setMonth] = useState(2);
  const [search, setSearch] = useState(new Date());
  const [inputSearch, setInputSearch] = useState(new Date());
  const [isFetching, setIsFetching] = useState(true);

  const useHookform = useForm({
    defaultValues: {
      name: "",
    },
    mode: "all",
  })

  const [filterConfig, setFilterConfig] = useState({
    showWorkingTime: true,
    showLeave: true,
    showAttendance: true,
    searchName: "",
  })

  const [columnDate, setColumnDate] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderDateTable = (search) => {
    const columnDate = [];
    const daysOfMonth = dayjs(search).daysInMonth();

    for (let index = 0; index < daysOfMonth; index++) {
      const date = dayjs(search).set("date", index + 1);
      const element = {
        date: date,
      };

      columnDate.push(element);
    }

    return columnDate;
  };

  const handleClickSearch = () => {
    setIsFetching(true);
    setSearch(inputSearch);
    setColumnDate(renderDateTable(inputSearch));
    // dispatch(getAllEmployeeTimeline(userProfile.idCompany, { month: dayjs(inputSearch).get('month') + 1, year: dayjs(inputSearch).get('year'), mode: "admin" })).then(res => { if (res && res.status === 200) setAttendanceList(res.data) })
    dispatch(getAllEmployeeTimeline(userProfile.idCompany, { 
      month: dayjs(inputSearch).format("YYYY-MM"),
      mode: "manager"
    })).then(res => { if (res && res.status === 200) setAttendanceList(res.data); setIsFetching(false);})
  }

  const onExportExcel = async () => {
    const result = await dispatch(exportExcelEmployeeAttendanceMonthly({
      month: dayjs(inputSearch).format("YYYY-MM"),
      mode: "manager",
    }))

    if(result){
      if(result.status === 200){
        dispatch(openNotificationAlert({
          type: "success",
          message: "ดาวน์โหลดสำเร็จ"
        }))
        const excelBlob = new Blob([result.data], { type: result.headers["content-type"] });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(excelBlob);
        downloadLink.download = `UniHR ตารางเวลาทำงานของพนักงานทั้งหมด ในเดือน ${dayjs(inputSearch).format("MMMM BBBB")} ข้อมูล ณ ${dayjs().format("DD MMMM BBBB HH.mmน.")}.xlsx`;
        downloadLink.click();
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ"
        }))
      }
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ"
      }))
    }
  }

  const handleChangeFilter = (event) => {
    setFilterConfig(prev => ({
      ...prev,
      [event.target.name]: event.target.checked
    }))
  }

  useEffect(() => {

    setColumnDate(renderDateTable(inputSearch));
    // dispatch(getAllEmployeeTimeline(userProfile.idCompany, { month: dayjs(inputSearch).get('month') + 1, year: dayjs(inputSearch).get('year'), mode: "admin" })).then(res => { if (res && res.status === 200) setAttendanceList(res.data) })
    dispatch(getAllEmployeeTimeline(userProfile.idCompany, { 
      month: dayjs(inputSearch).format("YYYY-MM"),
      mode: "manager"
    })).then(res => { if (res && res.status === 200) setAttendanceList(res.data); setIsFetching(false); })
  }, []);

  return (
    <StyledRoot>
      <StyledWrapFilter>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                วันที่ค้นหา
              </Typography>
              <div className="search-date">
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <DatePicker
                    minDate={new Date("2023-04-01")}
                    inputFormat="MMMM yyyy"
                    value={inputSearch}
                    name="start"
                    views={["year", "month"]}
                    openTo="month"
                    onChange={(newValue) => {
                      setInputSearch(newValue);
                    }}
                    renderInput={(params) => (
                      <StyledTextField variant="filled" fullWidth {...params} />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      }
                    }}
                  />
                </LocalizationProvider>
              </div>
            </StyledBoxSearch>
          </Grid>

          <Grid item xs={12} sm={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                ค้นหาชื่อ-สกุล
              </Typography>
              <FormControl fullWidth variant="filled">
                <StyledFilledInput
                  inputRef={useHookform.register("name")}
                  placeholder="ค้นหาชื่อ-สกุล"
                  id="name"
                  name="name"
                  onChange={debounce(e => {setFilterConfig(prev => ({...prev, searchName: e.target.value}));setPage(0);}, 500, true)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchRoundedIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </StyledBoxSearch>
          </Grid>

          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <div className="wrap-search-action">
                <div>
                  <ButtonBlue
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleClickSearch}
                  >
                    ค้นหา
                  </ButtonBlue>
                </div>
                <div className="btn-export">
                  <ButtonBlue
                    variant="outlined"
                    startIcon={<DownloadRoundedIcon />}
                    onClick={onExportExcel}
                  >
                    ดาวน์โหลด
                  </ButtonBlue>
                </div>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
      </StyledWrapFilter>
      
      <Box display="flex" gap="8px" marginBottom="16px">
        <FormControlLabel 
          control={<Checkbox
            name="showWorkingTime"
            checked={filterConfig.showWorkingTime}
            onChange={handleChangeFilter}
            iconStyle={{fill: "#46cbe2"}}
          />}
          label="แสดงเวลาทำงาน"
        />
        <FormControlLabel 
          control={<Checkbox 
            name="showAttendance"
            checked={filterConfig.showAttendance}
            onChange={handleChangeFilter}
            iconStyle={{fill: "#46cbe2"}}
          />}
          label="แสดงเวลาเข้า-ออกงาน"
        />
        <FormControlLabel 
          control={<Checkbox 
            name="showLeave"
            checked={filterConfig.showLeave}
            onChange={handleChangeFilter}
            iconStyle={{fill: "#46cbe2"}}
          />}
          label="แสดงการลา"
        />
      </Box>

      {!isFetching && <CardStyle>
        <Box style={{ padding: "24px" }}>
          <Box style={{ display: "flex", position: "relative" }}>
            <StyledBox className={"close"}>
              <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns
                        .filter((item) => {
                          return item.className === "sticky";
                        })
                        .map((column) => (
                          <StyledCellHeader
                            key={column.id}
                            align={"center"}
                            className="sticky"
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </StyledCellHeader>
                        ))}

                      {columnDate.length > 0 && columnDate.map((col, index) => (
                        <StyledCellHeader
                          key={index}
                          style={{ minWidth: 150 }}
                        >
                          <Typography variant="body2" align="center">{dayjs(col.date).format("DD/MM/BBBB")}</Typography>
                          <Typography variant="body2" align="center">{dayjs(col.date).format("dddd")}</Typography>
                        </StyledCellHeader>
                      ))}

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attendanceList && attendanceList
                    .filter(item => (filterConfig.searchName === "" || String(`${item.firstname_TH} ${item.lastname_TH}`).includes(filterConfig.searchName)))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <StyledRowContent key={index}>
                        <StyledCellContent className="sticky">
                          <div className="avatar">
                            <Avatar
                              src={`${row.imageProfile}`}
                            />
                            <div>
                              <Typography className="fullname">
                                {row.firstname_TH} {row.lastname_TH}
                              </Typography>
                              <Typography className="position">
                                {row.positionName}
                              </Typography>
                            </div>
                          </div>
                        </StyledCellContent>

                        {row.timeline.map((col, colIndex) => (
                          <StyledCellContent
                            key={`${index}-${colIndex}`}
                            align="center"
                          >
                            {col.pattern && <div className="time-in-out">

                              {filterConfig.showWorkingTime && <Fragment>
                                {col.pattern.isWorkingDay === 1 ? (
                                  <Typography fontSize={14} fontWeight={500}>{`${dayjs(col.pattern.timeIn, "HH:mm:ss").format("HH:mm")} - ${dayjs(col.pattern.timeOut, "HH:mm:ss").format("HH:mm")}`}</Typography>
                                ) : (
                                  <Typography fontSize={14} fontWeight={500}>{`${col.pattern.shiftTypeName}`}</Typography>
                                )}
                              </Fragment>}

                              {col.leave && filterConfig.showLeave && (
                                <Box display="flex" justifyContent="center" alignItems="center">
                                  <PlaylistAddCircleRoundedIcon style={{ color: "#ff6d00", marginRight: "4px" }} />
                                  <Typography fontSize={14} fontWeight={500} color="text.secondary">{col.leave.name}</Typography>
                                </Box>
                              )}
                            

                              {((col.pattern.isWorkingDay === 1 && (!col.leave || (col.attendance && (col.attendance.checkIn || col.attendance.checkOut)))) || ((col.pattern.isWorkingDay === 0 || col.leave) && (col.attendance.checkIn || col.attendance.checkOut))) && col.attendance && filterConfig.showAttendance && (
                                <Box display="flex" justifyContent="center" alignItems="center">
                                  <Box style={{ marginRight: "4px", width: "85px" }}>
                                    {col.attendance.checkIn ? (
                                      <Tooltip
                                        title={
                                          col.attendance.checkIn.idAttendanceType === 5 ?
                                            col.attendance.checkIn.groupGpsLocationsName :
                                            col.attendance.checkIn.idAttendanceType === 4 ?
                                              col.attendance.checkIn.gpsAddress :
                                              col.attendance.checkIn.gpsLocationsName
                                        }
                                      >
                                        <StyledChipTime
                                          icon={
                                            col.attendance.checkIn.idAttendanceType === 2 ? (
                                              <FingerprintIcon />
                                            ) : col.attendance.checkIn.idAttendanceType === 5 ? (
                                              <QrCodeIcon />
                                            ) : col.attendance.checkIn.idAttendanceType === 4 ? (
                                              <GpsFixedRoundedIcon />
                                            ) : (
                                              <LocationOnIcon />
                                            )
                                          }
                                          label={dayjs(col.attendance.checkIn.attendanceDateTimeText).format("HH:mm")}
                                          variant="outlined"
                                          className="check-in"
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Typography fontSize="12px" color="text.third">ไม่มีเข้า</Typography>
                                    )}
                                  </Box>

                                  <Box style={{ marginRight: "4px", width: "85px" }}>
                                    {col.attendance.checkOut ? (
                                      <Tooltip
                                        title={
                                          col.attendance.checkOut.idAttendanceType === 5 ?
                                            col.attendance.checkOut.groupGpsLocationsName :
                                            col.attendance.checkOut.idAttendanceType === 4 ?
                                              col.attendance.checkOut.gpsAddress :
                                              col.attendance.checkOut.gpsLocationsName

                                        }
                                      >
                                        <StyledChipTime
                                          icon={
                                            col.attendance.checkOut.idAttendanceType === 2 ? (
                                              <FingerprintIcon />
                                            ) : col.attendance.checkOut.idAttendanceType === 5 ? (
                                              <QrCodeIcon />
                                            ) : col.attendance.checkOut.idAttendanceType === 4 ? (
                                              <GpsFixedRoundedIcon />
                                            ) : (
                                              <LocationOnIcon />
                                            )
                                          }
                                          label={dayjs(col.attendance.checkOut.attendanceDateTimeText).format("HH:mm")}
                                          variant="outlined"
                                          className="check-out"
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Typography fontSize="12px" color="text.third">ไม่มีออก</Typography>
                                    )}
                                  </Box>
                                </Box>
                              )}
                            </div>}
                          </StyledCellContent>
                        ))}

                      </StyledRowContent>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={attendanceList ? attendanceList.filter(item => (filterConfig.searchName === "" || String(`${item.firstname_TH} ${item.lastname_TH}`).includes(filterConfig.searchName))).length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="จำนวนแถวต่อหน้า"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} จาก ${count !== -1 ? count : `มากกว่า ${to}`}`}
              />
            </StyledBox>
          </Box>
        </Box>
      </CardStyle>}

      {isFetching && (
        <Typography fontSize="24px" textAlign="center">กำลังโหลดข้อมูล...</Typography>
      )}

    </StyledRoot>
  )
}

export default ScheduleMonthly;