import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import DrawerCustom from "../../shared/general/Drawer";

import {
  putCompanyProfile,
  getCompanyProfile,
} from "../../../../actions/company";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginBottom: 8,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const keyLabel = {
  companyCode: "รหัสบริษัท",
  socialSecurityAccount: "Social Security A/C",
  providentFundCode: "Provident Fund Code",
  companyTelephone: "เบอร์โทรศัพท์บริษัท",
  companyEmail: "Email บริษัท",
  authorizedSignatureOneName: "ชื่อผู้มีอำนาจของบริษัท คนที่ 1",
  authorizedSignatureOnePosition: "ตำแหน่งผู้มีอำนาจของบริษัท คนที่ 1",
  authorizedSignatureTwoName: "ชื่อผู้มีอำนาจของบริษัท คนที่ 2",
  authorizedSignatureTwoPosition: "ตำแหน่งผู้มีอำนาจของบริษัท คนที่ 2",
  witnessSignatureName:"ชื่อพยาน",
  witnessSignaturePosition:"ตำแหน่งพยาน",
  certificateSignatureName: "ชื่อผู้ออกหนังสือรับรอง",
  certificateSignaturePosition: "ตำแหน่งผู้ออกหนังสือรับรอง",
  certificateSignatureName_EN: "ชื่อผู้ออกหนังสือรับรอง(อังกฤษ)",
  certificateSignaturePosition_EN: "ตำแหน่งผู้ออกหนังสือรับรอง(อังกฤษ)",
  mainContactName: "ชื่อ-สกุล",
  mainContactPhone: "เบอร์โทรศัพท์",
  mainContactEmail: "E-mail",
  companyName: "ชื่อบริษัท",
  companyName_EN: "ชื่อบริษัท(อังกฤษ)",
  nameShort: "ชื่อบริษัท(ย่อ)"
};

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog } = props;
  const dispatch = useDispatch();

  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (mode === "general") {
      setFormData({
        companyCode: companyProfile.companyCode,
        socialSecurityAccount: companyProfile.socialSecurityAccount,
        providentFundCode: companyProfile.providentFundCode,
      });
    } else if (mode === "contact") {
      setFormData({
        companyTelephone: companyProfile.companyTelephone,
        companyEmail: companyProfile.companyEmail,
      });
    } else if (mode === "authorized") {
      setFormData({
        authorizedSignatureOneName: companyProfile.authorizedSignatureOneName,
        authorizedSignatureOnePosition: companyProfile.authorizedSignatureOnePosition,
        authorizedSignatureTwoName: companyProfile.authorizedSignatureTwoName,
        authorizedSignatureTwoPosition: companyProfile.authorizedSignatureTwoPosition,
        witnessSignatureName: companyProfile.witnessSignatureName,
        witnessSignaturePosition: companyProfile.witnessSignaturePosition,
        certificateSignatureName: companyProfile.certificateSignatureName,
        certificateSignaturePosition: companyProfile.certificateSignaturePosition,
        certificateSignatureName_EN: companyProfile.certificateSignatureName_EN,
        certificateSignaturePosition_EN: companyProfile.certificateSignaturePosition_EN
      });
    } else if (mode === "main-contact") {
      setFormData({
        mainContactName: companyProfile.mainContactName,
        mainContactPhone: companyProfile.mainContactPhone,
        mainContactEmail: companyProfile.mainContactEmail,
      });
    } else if (mode === "profile") {
      setFormData({
        companyName: companyProfile.companyName,
        companyName_EN: companyProfile.companyName_EN,
        nameShort: companyProfile.nameShort,
      });
    }
  }, [mode]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const result = await dispatch(putCompanyProfile(formData));
    if (result) {
      handleCloseDialog();
      dispatch(getCompanyProfile());
    }
  };

  return (
    <DrawerCustom
      title={
        mode === "general"
          ? "แก้ไขข้อมูลทั่วไป"
          : mode === "contact"
          ? "แก้ไขการติดต่อ"
          : "แก้ไขผู้มีอำนาจลงนาม"
      }
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Grid container alignItems="center" spacing={2}>
          {formData &&
            Object.entries(formData).map(([key, value], index) => (
              <Grid item xs={12}>
                <StyledContentLabel>{keyLabel[key]}</StyledContentLabel>
                <TextFieldTheme
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            ))}
        </Grid>
        <StyledFooter>
          <ButtonBlue
            variant="text"
            className="cancel"
            onClick={handleCloseDialog}
          >
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
