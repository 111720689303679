import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";

import { styled } from "@mui/material/styles";

import { Typography, Divider, Grid } from "@mui/material";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import EditIcon from "@mui/icons-material/Edit";

import AvatarEmployee from "./avatarEmployee";
import DialogEdit from "./DialogEdit";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import utils from "../../../../../../utils";

const StyledInformation = styled("div")({
  marginTop: 16,
  "& .started": {
    fontSize: 14,
  },
  "& .wrap-show-hide-personalId": {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledTextValue = styled(Typography)({
  fontSize: 18,
  "&.dot": {
    marginTop: 4,
    fontSize: 8,
    "& i": {
      padding: 2,
    },
  },
});

const StyledImageProfile = styled("div")({
  width: "100%",
  "& .dropzone-profile": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    height: 160,
    width: 160,
    margin: "auto",
    borderRadius: "50%",
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
  "& .inner-dropzone-profile": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    height: "100%",
    outline: "none",
    display: "flex",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    position: "relative",
    "& .placeholder": {
      //display: "none",
    },
    "&:hover .placeholder": {
      zIndex: 9,
      display: "none",
      //display: "flex",
    },
  },
  "& .uploadImage": {
    width: "105%",
    zIndex: 8,
  },
  "& .placeholder": {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(244, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .placeholderImageProfile": {
    display: "none",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  "& .placeholderLabel": {
    color: "rgb(255, 255, 255)",
  },
  "& .wrapCaptionImage": {
    marginTop: 16,
    textAlign: "center",
  },
  "& .captionImage": {
    color: "#212b36",
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 20,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const Profile = (props) => {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [fileSelected, setFileSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [showPersonalId, setShowPersonalId] = useState(false);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      fileSelected.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [fileSelected]
  );

  const handleClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div style={{ padding: 24 }}>
      <div>
        {/* <StyledImageProfile>
          <div {...getRootProps({ className: "dropzone-profile" })}>
            <div className="inner-dropzone-profile">
              <input {...getInputProps()} />
              <Fragment>{thumbs(employeeProfile)}</Fragment>
              <div className={`placeholder placeholderImageProfile`}>
                <AddAPhotoIcon />
                <Typography
                  style={{ marginTop: 8, backgroundColor: "transparent" }}
                  className={`${fileSelected != 0 && `placeholderLabel`}`}
                  variant="body2"
                >
                  Upload Photo
                </Typography>
              </div>
            </div>
          </div>
        </StyledImageProfile> */}
        <AvatarEmployee
          fileSelected={fileSelected}
          setFileSelected={setFileSelected}
        />
        {employeeProfile && (
          <StyledInformation>
            <Typography align="center" variant="subtitle1">
              {employeeProfile.employeeID}
            </Typography>
            <Typography align="center" variant="subtitle1">
              {employeeProfile.positionName}
            </Typography>
            <Typography align="center" variant="subtitle1">
              {employeeProfile.telephoneMobile !== null
                ? `${employeeProfile.telephoneMobile.replace(
                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                    "$1-$2-$3"
                  )}`
                : "-"}
            </Typography>
            <Typography align="center" variant="subtitle1" gutterBottom>
              {employeeProfile.email}
            </Typography>
            <Typography
              align="center"
              color="text.secondary"
              className="started"
              gutterBottom
            >{`เริ่มงาน ${dayjs(employeeProfile.hiringDate).format(
              "DD MMM BBBB"
            )}`}</Typography>
            <StyledDivider style={{ margin: "24px 0" }} />
            <div>
              <StyledWrapTop>
                <div>
                  <ButtonBlue
                    variant="text"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={handleClick}
                  >
                    แก้ไข
                  </ButtonBlue>
                </div>
              </StyledWrapTop>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div>
                    <StyledHeadLabel color="text.secondary" align="center">
                      ชื่อ-สกุล
                    </StyledHeadLabel>
                    <StyledTextValue variant="h6" align="center">
                      {`${employeeProfile.title_TH} ${
                        employeeProfile.firstname_TH
                      } ${employeeProfile.lastname_TH} ${
                        employeeProfile.nickname_TH
                          ? `(${employeeProfile.nickname_TH})`
                          : ``
                      }`}
                    </StyledTextValue>
                    <StyledTextValue variant="h6" align="center">
                      {`${employeeProfile.title_EN} ${
                        employeeProfile.firstname_EN
                      } ${employeeProfile.lastname_EN} ${
                        employeeProfile.nickname_EN
                          ? `(${employeeProfile.nickname_EN})`
                          : ``
                      }`}
                    </StyledTextValue>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <StyledHeadLabel color="text.secondary" align="center">
                      วันเกิด
                    </StyledHeadLabel>
                    <StyledTextValue variant="h6" align="center">
                      {dayjs(employeeProfile.birthday).format("DD MMMM BBBB")}{" "}
                    </StyledTextValue>
                    <Typography
                      align="center"
                      color="text.third"
                    >{`(อายุ ${utils.getAge(
                      dayjs(employeeProfile.birthday).format("DD/MM/YYYY")
                    )})`}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <StyledHeadLabel color="text.secondary" align="center">
                      เลขประจำตัวประชาชน
                    </StyledHeadLabel>
                    <div
                      className="wrap-show-hide-personalId"
                      onMouseEnter={() => setShowPersonalId(true)}
                      onMouseLeave={() => setShowPersonalId(false)}
                    >
                      {showPersonalId ? (
                        <StyledTextValue variant="h6" align="center">
                          {employeeProfile.personalID !== null
                            ? employeeProfile.personalID.replace(
                                /(\d)(\d\d\d\d)(\d\d\d\d\d)(\d\d)(\d)/,
                                "$1 $2 $3 $4 $5"
                              )
                            : "-"}
                        </StyledTextValue>
                      ) : (
                        <StyledTextValue
                          className="dot"
                          variant="h6"
                          align="center"
                        >
                          {[...Array(13).keys()].map((_, index) => (
                            <i
                              key={index}
                              class="fa-sharp fa-solid fa-circle"
                            ></i>
                          ))}
                        </StyledTextValue>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </StyledInformation>
        )}
      </div>
      <DialogEdit open={openDialog} handleCloseDialog={handleCloseDialog} />
    </div>
  );
};

export default Profile;
