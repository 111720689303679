import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, Box, styled, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approveRequestTime,
  getAllRequestTimeBy,
  getAllRequestTimeWithDrawBy,
} from "../../../../actions/requestTime";
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogConfirmReject from "./DialogConfirmReject";
import CardRequest from "./CardRequest";

const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor: "#E793B8",
  borderRadius: "8px",
  color: "#FFFFFF",
  marginRight: 8,
  width: 130,
  "&:hover": {
    backgroundColor: "#DC6384",
  },
});

const RequestTime = (props) => {
  const { searchDate, setSnackBarConfig, setNumberOfList } = props;
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [pendingRows, setPendingRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const dispatch = useDispatch();

  const columns = [
    {
      name: "ชื่อ-สกุล",
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {row.firstname_TH} {row.lastname_TH}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {row.positionName}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: "ประเภท",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idRequestTimeWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>{row.name}</Typography>
        </Fragment>
      ),
    },
    {
      name: "เริ่มต้น",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
          </Typography>
        </Box>
      ),
    },
    {
      name: "สิ้นสุด",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
          </Typography>
        </Box>
      ),
    },
    {
      name: "เหตุผล",
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography fontSize="14px">{row.reasonName}</Typography>
          <Typography color="text.third" fontSize="14px">
            {row.otherReason}
          </Typography>
        </Box>
      ),
    },
  ];

  const handleApprovalRequest = async (approve, comment) => {
    console.log(selectedRows);
    console.log(userProfile);

    let listIsRequestTimeAndReject1 = [];

    const idRequestTimeLV1 = selectedRows
      .filter((x) => {
        return (
          (x.managerLV1ApproveBy === userProfile.idEmployees ||
            (x.idManagerGroupLV1 &&
              userProfile.idManagerGroupList.includes(x.idManagerGroupLV1))) && x.isManagerLV1Approve === null
        );
      })
      .map(function (user) {
        if(approve === 0 && user.idRequestType === 3){
          listIsRequestTimeAndReject1.push({ idEmployees : user.idEmployees, start : user.startText, end : user.endText });
        }
        return user.idRequestTime;
      });

    let listIsRequestTimeAndReject2 = [];

    const idRequestTimeLV2 = selectedRows
      .filter((x) => {
        return (
          (x.managerLV2ApproveBy === userProfile.idEmployees ||
            (x.idManagerGroupLV2 &&
              userProfile.idManagerGroupList.includes(x.idManagerGroupLV2))) && x.isManagerLV2Approve === null
        )
      })
      .map(function (user) {
        if(approve === 0 && user.idRequestType === 3){
          listIsRequestTimeAndReject2.push({ idEmployees : user.idEmployees, start : user.startText, end : user.endText });
        }
        return user.idRequestTime;
      });

    const idRequestTimeWithdraw = selectedRows
      .filter((x) => {
        return (
          x.managerApprove === userProfile.idEmployees &&
          x.idRequestTimeWithdraw !== null &&
          x.idRequestTimeWithdraw !== undefined
        );
      })
      .map(function (user) {
        return user.idRequestTimeWithdraw;
      });

    const idRequestTime = selectedRows
      .filter((x) => {
        return x.managerApprove === userProfile.idEmployees;
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    var formData = [
      {
        idRequestTimeLV1: idRequestTimeLV1,
        isManagerLV1Approve: approve,
        managerLV1ApproveBy: userProfile.idEmployees,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        commentManagerLV1: comment ? comment : null,
        listIsRequestTimeAndReject1: listIsRequestTimeAndReject1
      },
      {
        idRequestTimeLV2: idRequestTimeLV2,
        isManagerLV2Approve: approve,
        managerLV2ApproveBy: userProfile.idEmployees,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        commentManagerLV2: comment ? comment : null,
        listIsRequestTimeAndReject2: listIsRequestTimeAndReject2
      },
      {
        idRequestTimeWithdraw: idRequestTimeWithdraw,
        idRequestTime: idRequestTime,
        isApprove: approve,
        commentManager: comment ? comment : null,
      },
    ];

    console.log(formData)

    const result = await dispatch(approveRequestTime("manager", formData));
    if (result) {
      setSelectedRows([]);
      if (result.status === 200) {
        dispatch(getAllRequestTimeBy("manager", searchDate));
        dispatch(getAllRequestTimeWithDrawBy("manager", searchDate));
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการสำเร็จ",
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการไม่สำเร็จ",
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: "ดำเนินการไม่สำเร็จ",
        type: "error",
      });
    }
  };

  const handleConfirmReject = (comment) => {
    // console.log(comment);
    handleApprovalRequest(0, comment)
  }

  const handleClickAll = () => {
    setSelectedRows(pendingRows);
  };

  const handleCancel = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    const tempPending = [];

    requestTimeList.map((r) => {
      if (r.idRequestType === 3) {
        if (r.isDoubleApproval === 1) {
          if (
            r.managerLV1ApproveBy === userProfile.idEmployees ||
            (r.idManagerGroupLV1 &&
              userProfile.idManagerGroupList.includes(r.idManagerGroupLV1))
          ) {
            if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
              tempPending.push(r);
            }
          } else if (
            r.managerLV2ApproveBy === userProfile.idEmployees ||
            (r.idManagerGroupLV2 &&
              userProfile.idManagerGroupList.includes(r.idManagerGroupLV2))
          ) {
            if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
              tempPending.push(r);
            }
          }
        } else {
          if (r.approvalLevel === 1) {
            if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
              tempPending.push(r);
            }
          } else if (r.approvalLevel === 2) {
            if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
              tempPending.push(r);
            }
          }
        }
      }
    });

    requestTimeWithDrawList.map((r) => {
      if (r.idRequestType === 3) {
        if (r.isApprove === null) {
          tempPending.push(r);
        }
      }
    });
    let pendingList = tempPending.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });
    setPendingRows(pendingList);
    setNumberOfList(pendingList.length);
  }, []);

  return (
    <Box>
      {mobileResponsive && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          {(selectedRows ? selectedRows.length : 0) > 0 && (
            <StyledButtonCancel
              startIcon={<DeleteIcon />}
              onClick={handleCancel}
            >
              {`ยกเลิก`}
            </StyledButtonCancel>
          )}
          {!(
            (selectedRows ? selectedRows.length : 0) === pendingRows.length
          ) && (
              <ButtonBlue
                startIcon={<DoneAll />}
                variant="contained"
                onClick={handleClickAll}
              >
                {`เลือกทั้งหมด`}
              </ButtonBlue>
            )}
        </Box>
      )}
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1)}
          >
            {`อนุมัติ ${selectedRows.length > 0 ? `${selectedRows.length} รายการ` : ""
              }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            // onClick={() => handleApprovalRequest(0)}
            onClick={() => setIsOpenRejectDialog(true)}
          >
            {`ไม่อนุมัติ ${selectedRows.length > 0 ? `${selectedRows.length} รายการ` : ""
              }`}
          </ButtonBlue>
        </Box>
      )}
      {!mobileResponsive ? (
        <TableCustom
          columns={columns}
          rows={requestTimeList && requestTimeWithDrawList ? pendingRows : []}
          canSelect
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <div>
          {requestTimeList && requestTimeWithDrawList ? (
            pendingRows.map((row) => (
              <CardRequest
                row={row}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            ))
          ) : (
            <div>
              <Typography align="center">ไม่พบข้อมูล</Typography>
            </div>
          )}
        </div>
      )}

      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleConfirmReject(comment);
        }}
      />
    </Box>
  );
};

export default RequestTime;
