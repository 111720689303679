import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import dayjs from 'dayjs';
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import NumberFormat from "react-number-format";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center"
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const DialogProbation = (props) => {
  const { open, handleClose, value, detail } = props;
  return (
    <DrawerCustom
      title="แบบประเมินการทดลองงาน"
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ padding: "24px" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={7}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-regular fa-user fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{` ผู้ถูกประเมิน : ${value.firstname_TH} ${value.lastname_TH}`} </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-regular fa-building-columns fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{`ตำแหน่ง : ${value.positionName}`} </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-regular fa-calendar-days fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{`วันครบทดลองงาน :  ${dayjs(value.endDate).add(value.expandTimeProbation).format("D MMM BBBB")}`} </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-regular fa-thumbtack fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{`รอบทดลองงานที่ : ${value.probationRoundTwo ? "2" : "1"}`} </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-light fa-whistle fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{`ประเมินโดย : ${detail.ApprovedBy}`} </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ paddingLeft: "24px" }}>
            {detail.GroupProbation.map((data, index) => (
              <Grid key={index} container className="GridContact">
                <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                  <Grid item xs={12} sm={12} >
                    <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                    &nbsp;&nbsp;{`คำถามที่ ${index + 1} : ${data.question}`}
                  </Grid>
                </Grid>
                <Grid item xs={12} marginTop={1} sm={12}>
                  <Fragment>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={data.answer}
                    >
                      <FormControlLabel value="verygood" control={<Radio />} label="ดีมาก" />
                      <FormControlLabel value="good" control={<Radio />} label="ดี" />
                      <FormControlLabel value="meduim" control={<Radio />} label="ปานกลาง" />
                      <FormControlLabel value="fair" control={<Radio />} label="พอใช้" />
                      <FormControlLabel value="prettybad" control={<Radio />} label="ค่อนข่างแย่" />
                      <FormControlLabel value="bad" control={<Radio />} label="แย่" />
                    </RadioGroup>
                  </Fragment>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      marginTop: "16px",
                      borderColor: "#9e9e9e66"
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>

        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ paddingLeft: "24px" }}>
            <Grid container className="GridContact">
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={12} >
                  <i className="fa-regular fa-bone fa-xl"></i>
                  &nbsp;&nbsp;{`จุดแข็ง`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <TextFieldTheme
                  multiline
                  rows={3}
                  value={detail.goodPoint}
                >
                </TextFieldTheme>
              </Grid>
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={12} >
                  <i className="fa-regular fa-bone-break fa-xl"></i>
                  &nbsp;&nbsp;{`จุดอ่อน`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <TextFieldTheme
                  multiline
                  rows={3}
                  value={detail.weakPoint}
                >
                </TextFieldTheme>
              </Grid>
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={12} >
                  {`อื่นๆ`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} marginBottom={2} sm={12}>
                <TextFieldTheme
                  multiline
                  rows={3}
                  value={detail.others}
                >
                </TextFieldTheme>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <StyledFooter>
          <ButtonBlue 
          className="cancel" 
          onClick={handleClose} 
          startIcon={<ArrowBackIcon />}
        >
          ย้อนกลับ
        </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogProbation;
