import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as yup from "yup";

import { styled } from "@mui/material/styles";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../shared/general/Drawer";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import { addAddition } from "../../../../../actions/paytypes";

const StyledContent = styled("div")({
  width: 350,
  padding: 24,
  "& .text-code": {
    marginBottom: 16,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      paddingLeft: "0 !important",
    },
  },
  "& .text-name": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    marginBottom: 8,
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogNew = (props) => {
  const { open, selectedValue, mode, handleClose, handleSubmit } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const validationSchema = yup.object({
    payTypes: yup.string().required(`${t("ThisFieldIsRequired")}`),
    name: yup.string().required(`${t("ThisFieldIsRequired")}`),
    code: yup
      .string()
      .min(4, `${t("CodeLength46")}`)
      .max(6, `${t("CodeLength46")}`)
      .required(`${t("ThisFieldIsRequired")}`),
    isTaxable: yup.string(),
    isRecurring: yup.string(),
    isPercentage: yup.string(),
    isSso: yup.string(),
    isPf: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      payTypes: "addition",
      code: "",
      name: "",
      isTaxable: "",
      isRecurring: "true",
      isPercentage: "",
      isSso: "",
      isPf: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    if (selectedValue) {
      console.log("selectedValue:", selectedValue);
      formik.resetForm();
      formik.setFieldValue("code", selectedValue.code);
      formik.setFieldValue("name", selectedValue.name);
      formik.setFieldValue("isTaxable", selectedValue.isTaxable);
      formik.setFieldValue("isRecurring", selectedValue.isRecurring);
      formik.setFieldValue("isPercentage", selectedValue.isPercentage);
      formik.setFieldValue("isSso", selectedValue.isSso);
      formik.setFieldValue("isPf", selectedValue.isPf);
    }
  }, [selectedValue]);

  const renderTextCode = () => {
    if (formik.values.payTypes === "addition") {
      if (formik.values.isRecurring === "true") {
        return "AR-";
      } else {
        return "AO-";
      }
    } else {
      if (formik.values.isRecurring === "true") {
        return "DR-";
      } else {
        return "DO-";
      }
    }
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={mode === "new" ? "สร้างรายการ" : "แก้ไขรายการ"}
      anchor={"right"}
    >
      <StyledContent>
        <form onSubmit={formik.handleSubmit}>
          <FormControl
            disabled={mode === "edit"}
            component="fieldset"
            className="radio"
            error={formik.touched.payTypes && Boolean(formik.errors.payTypes)}
          >
            <FormLabel component="legend">ประเภทรายการ</FormLabel>
            <RadioGroup
              row
              aria-label="paytypes"
              onChange={formik.handleChange}
              name="payTypes"
              value={formik.values.payTypes}
            >
              <FormControlLabel
                value="addition"
                control={<Radio />}
                label="รายการเพิ่ม"
              />
              <FormControlLabel
                value="deduction"
                control={<Radio />}
                label="รายการหัก"
              />
            </RadioGroup>
            {Boolean(formik.errors.payTypes) && (
              <FormHelperText>{formik.errors.payTypes}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={mode === "edit"}
            value={formik.values.isRecurring}
            component="fieldset"
            className="radio"
            error={
              formik.touched.isRecurring && Boolean(formik.errors.isRecurring)
            }
          >
            <FormLabel component="legend">คำนวนต่อเนื่อง</FormLabel>
            <RadioGroup
              row
              aria-label="isRecurring"
              onChange={formik.handleChange}
              name="isRecurring"
              value={formik.values.isRecurring}
            >
              <FormControlLabel value={1} control={<Radio />} label="ใช่" />
              <FormControlLabel value={0} control={<Radio />} label="ไม่ใช่" />
            </RadioGroup>
            {formik.touched.isRecurring &&
              Boolean(formik.errors.isRecurring) && (
                <FormHelperText>{formik.errors.isRecurring}</FormHelperText>
              )}
          </FormControl>
          <TextFieldTheme
            className="text-code"
            label="รหัส"
            variant={"outlined"}
            name="code"
            value={formik.values.code}
            disabled={mode === "edit"}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {formik.values.code ? null : renderTextCode()}
                </InputAdornment>
              ),
            }}
          />
          <TextFieldTheme
            className="text-name"
            label="ชื่อรายการ"
            variant={"outlined"}
            name="name"
            value={formik.values.name}
            disabled={mode === "edit"}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <FormControl
            component="fieldset"
            className="radio"
            error={formik.touched.isTaxable && Boolean(formik.errors.isTaxable)}
          >
            <FormLabel component="legend">{`คำนวนภาษี ?`}</FormLabel>
            <RadioGroup
              row
              aria-label="isTaxable"
              onChange={formik.handleChange}
              name="isTaxable"
            >
              <FormControlLabel value={true} control={<Radio />} label="ใช่" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="ไม่ใช่"
              />
            </RadioGroup>
            {formik.touched.isTaxable && Boolean(formik.errors.isTaxable) && (
              <FormHelperText>{formik.errors.isTaxable}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={formik.touched.isSso && Boolean(formik.errors.isSso)}
          >
            <FormLabel component="legend">{`คำนวนประกันสังคม ?`}</FormLabel>
            <RadioGroup
              row
              aria-label="isSso"
              onChange={formik.handleChange}
              name="isSso"
            >
              <FormControlLabel value={true} control={<Radio />} label="ใช่" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="ไม่ใช่"
              />
            </RadioGroup>
            {formik.touched.isSso && Boolean(formik.errors.isSso) && (
              <FormHelperText>{formik.errors.isSso}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={formik.touched.isPf && Boolean(formik.errors.isPf)}
          >
            <FormLabel component="legend">{`คำนวนกองทุนสำรองเลี้ยงชีพ ?`}</FormLabel>
            <RadioGroup
              row
              aria-label="isPf"
              onChange={formik.handleChange}
              name="isPf"
            >
              <FormControlLabel value={true} control={<Radio />} label="ใช่" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="ไม่ใช่"
              />
            </RadioGroup>
            {formik.touched.isPf && Boolean(formik.errors.isPf) && (
              <FormHelperText>{formik.errors.isPf}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.isPercentage && Boolean(formik.errors.isPercentage)
            }
          >
            <FormLabel component="legend">คำนวนโดย</FormLabel>
            <RadioGroup
              row
              aria-label="isPercentage"
              onChange={formik.handleChange}
              name="isPercentage"
            >
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="ยอดเงิน"
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="ตาม % ค่าจ้าง"
              />
            </RadioGroup>
            {formik.touched.isPercentage &&
              Boolean(formik.errors.isPercentage) && (
                <FormHelperText>{formik.errors.isPercentage}</FormHelperText>
              )}
          </FormControl>

          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue type="submit" variant="contained">
              {t("Save")}
            </ButtonBlue>
          </StyledFooter>
        </form>
      </StyledContent>
    </DrawerCustom>
  );
};

export default DialogNew;
