import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Avatar,
  Box,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { getDepartment, getallcompany } from "../../../../actions/company";
import { getAllEmployees } from "../../../../actions/employee";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import Backdrop from "../../shared/general/Backdrop";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
  justifyContent: "space-between",
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const employeeList = [
  {
    idEmployees: 43,
    firstname_TH: "บุญรอด",
    lastname_TH: "ไชยสงค์",
    imageProfile:
      "https://storage.googleapis.com/unihr-bucket/employees/profile/3/239326.jpg?GoogleAccessId=antjob-storage%40anthr-320007.iam.gserviceaccount.com&Expires=1687248001&Signature=QOLmZ2Ac3Kya72yFKuSHy3%2FldsdRIecez0RJB5OIMaZiKz5HrcpBaWrzARqTT9S2Dp7q1nzzasbzx18hc2xlFG9cT%2FLVQlHJRJnI01ERihsrL6kR4pWQM7IljoDcEEDi%2BcZUd2jcOjROiRYycwLZ1EPPsp1hcKAL5Yh0WpXotgnuk01PTdEgALFqwFWCgC1kgBDfEMUlcmzgSK26TUgDUou7MlzmpXZK6qmk7CSX0VJU0%2BunIcnLLuH2bybY3YEv3%2BAhX3HCH1Mk1B3TGmD7oOESqhI4SgFjlmk%2Bju4lRFXv9088DFHgeHX5dIiEkuqhxGGrzvY2upzFR9YiPFb8ag%3D%3D",
    file: null,
  },
  {
    idEmployees: 44,
    firstname_TH: "สุดารัตน์",
    lastname_TH: "สุขสบาย",
    imageProfile:
      "https://storage.googleapis.com/unihr-bucket/employees/profile/3/007314.jpg?GoogleAccessId=antjob-storage%40anthr-320007.iam.gserviceaccount.com&Expires=1687248001&Signature=tTBhYth96CkWBoqDDiw8VTSieZ4x9OeMf5OSyv6bf52quuk5%2Fr39rJQFZEqNePmsIZTIhbC7oh3wSIdXk0lDqdMLOojoyPKtea6HyKFmRSzYK5F5Fx%2FIBYXi9eJPELa8ntjN7MW1ILQ5CPlycuoQeip%2FKWq421eH12Ghhi0sBNfApOtgRNrLSKaQ2znmVQxjdQcyq%2FyihaWJgR0GOtqn1HuSsuYFXD0lZc0Ud%2FQjTkejbXdHqwpWq1%2FB%2FHgthpfp8r%2B7NNou%2BmsYZ%2BSsdYqdprwB8nOK1n%2F80%2BaNsavD7quxPzqv7WVr9t4ld%2BSHV3FH1oS6QRzurxuvkhUqndNPmw%3D%3D",
  },
  {
    idEmployees: 45,
    firstname_TH: "กัณฑ์ลภัคศรี",
    lastname_TH: "หอมเที่ยงแท้",
    imageProfile:
      "https://storage.googleapis.com/unihr-bucket/employees/profile/3/071283.jpg?GoogleAccessId=antjob-storage%40anthr-320007.iam.gserviceaccount.com&Expires=1687248001&Signature=v64ZntK2NklS%2BoKBw49T4dcFLgA%2F9iqJuyIXptbroHmoB88c0gq%2BML9dir5NbL%2BSinKHv%2B%2Bw0%2F99i9ko%2BaiR8YzuUs%2B%2FmzEhM17onFmILQjvqyqSWU3fdWcJUQvQ7FUJdZc%2BaYaQIDIhXErkdfpdnsxvVet91ra%2Ff6Qoe4gx1uqbDMLWjR2DcnaVa%2BsDe5nEJPLW6WLYQLci3PDCkomXatR8J8Jv2roiFp3X7VvfCn2i2mT%2Bm341v6Ja3Td0o9cYJElsTFZWCroxaRG8CS7KaLeef%2FYB4dskjem2l17rI5IxQb%2BYQWQXS2FdQLPgtN8IHuxmYh0BiC3mlD9eQEGOgQ%3D%3D",
  },
];

const docName = [
  { idDocumentType: 1, documentTypeName: "Offer" },
  { idDocumentType: 2, documentTypeName: "Employment Eligibility" },
  { idDocumentType: 3, documentTypeName: "Tax Document" },
  { idDocumentType: 4, documentTypeName: "Background chexk" },
];

const More = (props) => {
  const { open, handleClose, data, docName, handleDialog } = props;

  const [reload, setreload] = useState();
  const [formData, setFormData] = useState(null);
  const [typeDialog, setTypeDialog] = useState(null);
  const [view, setView] = useState(null);
  const [openimport, setOpen] = useState(false);

  const handleimport = (type, formData) => {
    setTypeDialog(type);
    setOpen(true);
    if (formData) {
      setFormData(formData);
    }
  };

  return (
    <div>
      {data && (
        <StyledRoot>
          <CardStyle>
            <Box style={{ padding: "24px" }}>
              <Box style={{ display: "flex", position: "relative" }}>
                <StyledBox className={"close"}>
                  <div style={{ marginBottom: 8 }}>
                    <Typography
                      variant="h6"
                      style={{ paddingTop: 8, paddingBottom: 4 }}
                    >
                      เอกสารเพิ่มเติมของ {data.firstname_TH} {data.lastname_TH}
                    </Typography>
                  </div>
                  <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledCellHeader
                            align={"center"}
                            className="sticky"
                            style={{ minWidth: 240 }}
                          >
                            เอกสาร
                          </StyledCellHeader>
                          {/* {docName.map((d, index) => {
                                                    return <StyledCellHeader>
                                                        <Typography variant="body2" align="center">{d.documentTypeName}</Typography>
                                                    </StyledCellHeader>
                                                })} */}
                          <StyledCellHeader>
                            <Typography variant="body2" align="center">
                              file
                            </Typography>
                          </StyledCellHeader>
                          <StyledCellHeader>
                            <Typography variant="body2" align="center">
                              วันเริ่มต้น
                            </Typography>
                          </StyledCellHeader>
                          <StyledCellHeader>
                            <Typography variant="body2" align="center">
                              วันหมดอายุ
                            </Typography>
                          </StyledCellHeader>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {docName
                          ? docName.map((row, index) =>
                              row.isShow === 0 ? (
                                <StyledRowContent>
                                  <StyledCellContent
                                    className="sticky"
                                    style={{
                                      borderBottom:
                                        "1px dashed rgba(224, 224, 224, 1)",
                                    }}
                                  >
                                    <Typography>
                                      {row.documentTypeName}
                                    </Typography>
                                  </StyledCellContent>

                                  <StyledCellContent align="center">
                                    {data.file.find((r) => {
                                      return (
                                        r.idDocumentType === row.idDocumentType
                                      );
                                    }) ? (
                                      <>
                                        <CheckCircleOutlineIcon
                                          style={{ color: "green" }}
                                          onClick={() =>
                                            window.open(
                                              data.file.find(
                                                (r) =>
                                                  r.idDocumentType ===
                                                  row.idDocumentType
                                              ).fileURL
                                            )
                                          }
                                        />
                                        <EditIcon
                                          onClick={() =>
                                            handleDialog("edit", data, row)
                                          }
                                        />
                                      </>
                                    ) : (
                                      <CancelOutlinedIcon
                                        style={{ color: "red" }}
                                        onClick={() =>
                                          handleDialog("import", data, row)
                                        }
                                      />
                                    )}
                                  </StyledCellContent>

                                  <StyledCellContent align="center">
                                    <Typography>
                                      {row.startDate ? row.startDate : "-"}
                                    </Typography>
                                  </StyledCellContent>

                                  <StyledCellContent align="center">
                                    <Typography>
                                      {row.endDate ? row.endDate : "-"}
                                    </Typography>
                                  </StyledCellContent>
                                </StyledRowContent>
                              ) : (
                                ""
                              )
                            )
                          : "ไม่พบข้อมูล"}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StyledBox>
              </Box>
            </Box>
          </CardStyle>
        </StyledRoot>
      )}
    </div>
  );
};

export default More;
