import React, { useEffect } from "react";
import { Avatar, Box, Typography, styled } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

const StyledRoot = styled(Box)({
  borderRadius: "4px",
  boxSizing: "border-box",
  overflow: "hidden",
  "& .MuiTypography-root": {
    fontFamily: `Poppins,Prompt,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    color: "#212b36"
  },
  "& span": {
    fontFamily: `Poppins,Prompt,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    color: "#212b36"
  }
})

const nodeContainerStyle = {
  borderRadius: "4px",
  boxSizing: "border-box",
  overflow: "hidden",
}

const spanStyle = {
  fontFamily: `Poppins,Prompt,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  color: "#212b36"
}

const NodeContent = (props) => {

  const {
    node,
  } = props;

  const getColorByTag = (tag) => {
    switch (tag) {
      case "CEO": return ({ main: "#d50000", sencondary: "#d5000011" });
      case "BUSINESSUNIT": return ({ main: "#6C0BA9", sencondary: "#6C0BA911" });
      case "DIVISION": return ({ main: "#e65100", sencondary: "#e6510011" });
      case "DEPARTMENT": return ({ main: "#0091ea", sencondary: "#0091ea11" });
      case "SECTION": return ({ main: "#4caf50", sencondary: "#4caf5011" });
      case "POSITION": return ({ main: "#9e9e9e", sencondary: "#9e9e9e11" });
      default: return ({ main: "#9e9e9e", sencondary: "#9e9e9e11" });
    }
  }

  if (node.data.nodeType === "EMPLOYEE") {
    return (
      <StyledRoot style={{ width: node.width, height: node.height }}>
        <div
          style={{
            display: "flex",
            gap: "8px",
            flexWrap: "wrap",
          }}
        >
          {node.data.employeeList.map((item, index) => (
            <div
              key={index}
              style={{
                borderRadius: "4px",
                backgroundColor: "#ffffff",
                padding: "8px",
                boxSizing: "border-box",
                width: "250px",
                minWidth: "250px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Avatar sizes="14px" style={{ width: "36px", height: "36px", marginRight: "8px" }} src={item.imageProfile && item.imageProfile.url ? item.imageProfile.url : null} />
                <Typography fontSize="14px">{item.firstname_TH} {item.lastname_TH}</Typography>
              </div>
            </div>
          ))}
        </div>
      </StyledRoot>
    )
  }

  return (
    <div style={{ ...nodeContainerStyle, width: node.width, height: node.height }}>
      <div
        style={{
          height: "4px",
          backgroundColor: getColorByTag(node.data.nodeType).main
        }}
      />
      <div
        style={{
          padding: "8px",
          backgroundColor: getColorByTag(node.data.nodeType).sencondary,
          height: "32px",
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px"
        }}
      >
        <span
          style={{
            ...spanStyle,
            flexGrow: "1",
            fontSize: "12px",
            lineHeight: "1",
          }}
        >
          {node.data.name}
        </span>
      </div>
      <div
        style={{
          marginTop: "4px",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
          padding: "8px",
          boxSizing: "border-box",
        }}
      >
        <span style={{ ...spanStyle, fontSize: "12px", lineHeight: 1 }}>{node.data.mainPosition ? node.data.mainPosition.name : "ไม่มีตำแหน่งงาน"}</span>
        <div
          style={{
            display: "flex",
            marginTop: "8px",
            alignItems: "center"
          }}
        >
          <Avatar sizes="14px" style={{ width: "36px", height: "36px", marginRight: "8px" }} />
          <span style={{ ...spanStyle, fontSize: "14px", flexGrow: 1 }}>{node.data.mainPosition && node.data.mainPosition.employee ? <>{node.data.mainPosition.employee.firstname_TH} {node.data.mainPosition.employee.lastname_TH}</> : "ไม่ระบุ"}</span>
        </div>

        <div style={{ marginTop: "8px", display: "flex" }}>
          <div style={{ flexGrow: "1" }}>
            <div>
              <span style={{ ...spanStyle, fontSize: "10px", lineHeight: "1", color: "#919eab" }}>Cost Center</span>
            </div>
            <div>
              <span style={{ ...spanStyle, fontSize: "10px", lineHeight: "1" }}>{node.data.costCenter ? node.data.costCenter : "ไม่ระบุ"}</span>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <PersonIcon style={{ color: "#919eab" }} />
            <span style={{ ...spanStyle, fontSize: "12px" }}>{node.data.mainPosition && node.data.mainPosition.employee ? 1 : 0} ({node.data.totalEmployees})</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NodeContent;