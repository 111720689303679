import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem
} from "@mui/material";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

const StyledRoot = styled("div")({
  width: 450,
  padding: 24,
  "& .field-label":{
    paddingBottom: 4
  }
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 360,
    },
  },
};

const Charge = (props) => {
  const { formData, handleChange, handleChangeCheck } = props;
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ค่าแรง(%)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeSalary"
          value={formData.chargeSalary}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ค่าล่วงเวลา(%)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeOt"
          value={formData.chargeOt}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>เหมา</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeHire"
          value={formData.chargeHire}
          onChange={handleChange}
          fullWidth
        />
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="percent"
              control={
                <Checkbox
                  checked={formData.chargeHireType}
                  onChange={handleChangeCheck}
                  name="chargeHireType"
                />
              }
              label="เปอร์เซ็นต์(%)"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ขดเชย(%)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeCompensation"
          value={formData.chargeCompensation}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const Bank = (props) => {
  const { formData, handleChange } = props;
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ชื่อธนาคาร</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="bookBank"
          value={formData.bookBank}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>สาขา</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="bookBankBranchName"
          value={formData.bookBankBranchName}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>หมายเลขบัญชี</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="bookID"
          value={formData.bookID}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>รายงานธนาคาร รหัสธนาคาร</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBankBankID"
          value={formData.reportBankBankID}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>รายงานธนาคาร ชื่อธนาคาร</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBankBankName"
          value={formData.reportBankBankName}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>รายงานธนาคาร เลขที่บัญชี</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBankBookBankID"
          value={formData.reportBankBookBankID}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>รายงานธนาคาร ชื่อผู้รับเงิน</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBankName"
          value={formData.reportBankName}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>รายงานธนาคาร เลขที่อ้างอิง</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBankRef"
          value={formData.reportBankRef}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const Cost = (props) => {
  const { formData, handleChange } = props;
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>Cost Center</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="costCenterCharge"
          value={formData.costCenterCharge}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>Cost Element</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="costElementCharge"
          value={formData.costElementCharge}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>IO</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="io"
          value={formData.io}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const Payroll = (props) => {
  const { formData, handleChange } = props;
  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">คำนวณเงินเดือน ?</StyledContentLabel>
      </Grid>
      <Grid item xs={12}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="OESY_Y"
            value={formData.OESY_Y}
            onChange={handleChange}
            MenuProps={MenuProps}
          >
            {[...Array(60).keys()].map((item) => (
              <MenuItem value={item + 1}>{item + 1}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">คำนวณกองทุนสำรองเลี้ยงชีพ ?</StyledContentLabel>
      </Grid>
      <Grid item xs={12}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="OESY_M"
            value={formData.OESY_M}
            onChange={handleChange}
          >
            {[...Array(12).keys()].map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">คำนวณภาษีเงินได้ ?</StyledContentLabel>
      </Grid>
      <Grid item xs={12}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="OESY_M"
            value={formData.OESY_M}
            onChange={handleChange}
          >
            {[...Array(12).keys()].map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label" >พนักงานจ่ายภาษีเงินได้ ?</StyledContentLabel>
      </Grid>
      <Grid item xs={12}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="OESY_M"
            value={formData.OESY_M}
            onChange={handleChange}
          >
            {[...Array(12).keys()].map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12}>
        <StyledContentLabel className="field-label">คำนวณประกันสังคม ?</StyledContentLabel>
      </Grid>
      <Grid item xs={12}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="OESY_M"
            value={formData.OESY_M}
            onChange={handleChange}
          >
            {[...Array(12).keys()].map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Grid>
    </Grid>
  );
};

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog } = props;
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (open) {
      if (mode === "bank") {
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          bookBank: employeeProfile.bookBank,
          bookBankBranchName: employeeProfile.bookBankBranchName,
          bookID: employeeProfile.bookID,
          reportBankBankID: employeeProfile.reportBankBankID,
          reportBankBankName: employeeProfile.reportBankBankName,
          reportBankBookBankID: employeeProfile.reportBankBookBankID,
          reportBankName: employeeProfile.reportBankName,
          reportBankRef: employeeProfile.reportBankRef,
        });
      } else if (mode === "charge") {
        setFormData({
          idEmp: employeeProfile.idEmp,
          chargeCompensation: employeeProfile.chargeCompensation
            ? employeeProfile.chargeCompensation * 100
            : null,
          chargeHire: employeeProfile.chargeHire
            ? employeeProfile.chargeHire <= 1
              ? employeeProfile.chargeHire * 100
              : employeeProfile.chargeHire
            : null,
          chargeSalary: employeeProfile.chargeSalary
            ? employeeProfile.chargeSalary * 100
            : null,
          chargeOt: employeeProfile.chargeOt
            ? employeeProfile.chargeOt * 100
            : null,
          chargeHireType:
            employeeProfile.chargeHire && employeeProfile.chargeHire <= 1
              ? true
              : false,
        });
      } else if (mode === "cost") {
        setFormData({
          idEmp: employeeProfile.idEmp,
          costCenterCharge: employeeProfile.costCenterCharge,
          costElementCharge: employeeProfile.costElementCharge,
          io: employeeProfile.io,
        });
      } else if (mode === "payroll") {
        setFormData({
          idEmp: employeeProfile.idEmp,
          isPayroll: employeeProfile.isPayroll,
          isPf: employeeProfile.isPf,
          isTax: employeeProfile.isTax,
          isSelfTaxPaid: employeeProfile.isSelfTaxPaid,
          isSso: employeeProfile.isSso,
        });
      }
    }
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeCheck = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = async () => {
    // if (mode === "bank" || mode === "cost") {
    //   formData.UpdateBy = userProfile.idUsers;
    //   const result = await dispatch(updateUser(formData));
    //   if (result) {
    //     handleCloseDialog();
    //     dispatch(getEmployeeProfile(employeeProfile.idEmp));
    //   }
    // } else if (mode === "charge") {
    //   formData.UpdateBy = userProfile.idUsers;
    //   formData.chargeCompensation = formData.chargeCompensation
    //     ? formData.chargeCompensation / 100
    //     : null;
    //   formData.chargeHire = formData.chargeHire
    //     ? formData.chargeHireType
    //       ? formData.chargeHire / 100
    //       : parseInt(formData.chargeHire)
    //     : null;
    //   formData.chargeSalary = formData.chargeSalary
    //     ? formData.chargeSalary / 100
    //     : null;
    //   formData.chargeOt = formData.chargeOt ? formData.chargeOt / 100 : null;

    //   delete formData["chargeHireType"];
    //   const result = await dispatch(updateUser(formData));
    //   if (result) {
    //     handleCloseDialog();
    //     dispatch(getEmployeeProfile(employeeProfile.idEmp));
    //   }
    // }
  };

  return (
    <DrawerCustom
      title={
        mode === "bank"
          ? "แก้ไขข้อมูลธนาคาร"
          : mode === "cost"
          ? "แก้ไขศูนย์ต้นทุน"
          : mode === "charge" ? 
          "แก้ไขค่าดำเนินการ" : 
          "ตั้งค่าการทำเงินเดือน"
      }
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {mode === "bank" && (
          <Bank formData={formData} handleChange={handleChange} />
        )}
        {mode === "charge" && (
          <Charge formData={formData} handleChange={handleChange} handleChangeCheck={handleChangeCheck}/>
        )}
        {mode === "cost" && (
          <Cost formData={formData} handleChange={handleChange} />
        )}
        {mode === "payroll" && (
          <Payroll formData={formData} handleChange={handleChange} />
        )}
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
