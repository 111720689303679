import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";

import { FormControl, Grid, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";

import downloadHeaderExcel from "../../assets/data/header-download-employee";
import { getEmployeeExcelExport, getEmployeeExcelDiligenceAllowanceExport } from "../../../../actions/employee";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
  "& .btn-action": {
    display: "flex",
    justifyContent: "center",
    marginTop: 36,
  },
});

const DialogDownload = (props) => {
  const { open, toggleDrawer } = props;
  const dispatch = useDispatch();


  const excelExport = async (option) => {

    const employeeList = await dispatch(getEmployeeExcelExport(option)).then(res => res.data)
    const elementList = [];

    if(employeeList.length <= 0){
      employeeList.push({
        employeeID: null
      })
    }

    for (let index = 0; index < employeeList.length; index++) {
      const element = {
        employeeID: employeeList[index].employeeID,
        title_TH: employeeList[index].title_TH,
        firstname_TH: employeeList[index].firstname_TH,
        lastname_TH: employeeList[index].lastname_TH,
        title_EN: employeeList[index].title_EN,
        firstname_EN: employeeList[index].firstname_EN,
        lastname_EN: employeeList[index].lastname_EN,
        nickname_TH: employeeList[index].nickname_TH,
        gender: employeeList[index].gender === "Male"
          ? "ชาย"
          : employeeList[index].gender === "Female" 
            ? "หญิง"
            : "",
        personalID: employeeList[index].personalID,
        nationality: employeeList[index].nationality,
        maritalStatus: employeeList[index].maritalStatus,
        passportNumber: employeeList[index].passportNumber,
        workPermitNumber: employeeList[index].workPermitNumber,
        taxID: employeeList[index].taxID,
        ssoID: employeeList[index].ssoID,
        birthday: employeeList[index].birthday? dayjs(employeeList[index].birthday).format("DD-MM-YYYY"): "",
        houseNo: employeeList[index].houseNo,
        village: employeeList[index].village,
        villageNo: employeeList[index].villageNo,
        alley: employeeList[index].alley,
        road: employeeList[index].road,
        subDistrict: employeeList[index].subDistrict,
        district: employeeList[index].district,
        provience: employeeList[index].provience,
        areaCode: employeeList[index].areaCode,
        
        telephoneMobile: employeeList[index].telephoneMobile,
        email: employeeList[index].email,
        emergencyContact: employeeList[index].emergencyContact,
        emergencyRelationship: employeeList[index].emergencyRelationship,
        emergencyPhone: employeeList[index].emergencyPhone,
        numberOfChildren: employeeList[index].numberOfChildren,
        spouseFirstname: employeeList[index].spouseFirstname,
        spouseLastname: employeeList[index].spouseLastname,
        spouseBirthday: employeeList[index].spouseBirthday? dayjs(employeeList[index].spouseBirthday).format("DD-MM-YYYY"): "",
        spouseTaxID: employeeList[index].spouseTaxID,
        employmentTypeName: employeeList[index].employmentTypeName,
        employmentTime: employeeList[index].employmentTime,
        isFinger: employeeList[index].isFinger === 1? "ใช่": employeeList[index].isFinger === 0? "ไม่ใช่": "",
        workingLocation: employeeList[index].workingLocation,
        groupGpsLocationName: employeeList[index].groupGpsLocationName,
        filed_office: employeeList[index].filed_office,
        jobLevelName: employeeList[index].jobLevelName,
        personnelLevelGroupName: employeeList[index].personnelLevelGroupName,
        personnelLevelName: employeeList[index].personnelLevelName,
        jobGroupName: employeeList[index].jobGroupName,
        subJobGroupName: employeeList[index].subJobGroupName,
        positionName: employeeList[index].positionName,
        companyName: employeeList[index].companyName,
        branchName: employeeList[index].branchName,
        divisionName: employeeList[index].divisionName,
        departmentName: employeeList[index].departmentName,
        sectionName: employeeList[index].sectionName,
        hiringDate: employeeList[index].hiringDate? dayjs(employeeList[index].hiringDate).format("DD-MM-YYYY"): "",
        managerLV1_fullname: employeeList[index].managerLV1_firstname_TH? `${employeeList[index].managerLV1_firstname_TH} ${employeeList[index].managerLV1_lastname_TH}`: null,
        managerLV1_positionName: employeeList[index].managerLV1_positionName,
        managerLV1_email: employeeList[index].managerLV1_email,
        managerLV2_fullname: employeeList[index].managerLV2_firstname_TH? `${employeeList[index].managerLV2_firstname_TH} ${employeeList[index].managerLV2_lastname_TH}`: null,
        managerLV2_positionName: employeeList[index].managerLV2_positionName,
        managerLV2_email: employeeList[index].managerLV2_email,
        workingType: employeeList[index].workingType,
        shiftGroupName: employeeList[index].shiftGroupName,
        shiftName: employeeList[index].shiftName,
        timeIn: employeeList[index].timeIn,
        timeOut: employeeList[index].timeOut,
        paymentTypeName: employeeList[index].paymentTypeName,
        paymentRoundName: employeeList[index].paymentRoundName,
        salary: employeeList[index].salary,
        employeePositionStart: employeeList[index].employeePositionStart,
        lastSalary: employeeList[index].lastSalary,
        lastSalaryStart: employeeList[index].lastSalaryStart,
        lastSpecial: employeeList[index].lastSpecial,
        lastSpecialDate: employeeList[index].lastSpecialDate,
        lastJobLevelDate: employeeList[index].lastJobLevelDate,
        lastPersonnalLevelDate: employeeList[index].lastPersonnalLevelDate,
        contractType: employeeList[index].contractType,
        retirementDate: employeeList[index].retirementDate? dayjs(employeeList[index].retirementDate).format("DD-MM-YYYY"): "",
        contractTermainatoinDate: employeeList[index].contractTermainatoinDate? dayjs(employeeList[index].contractTermainatoinDate).format("DD-MM-YYYY"): "",
        contractTermainatoinStatus: employeeList[index].contractTermainatoinStatus,
        resignDate: employeeList[index].resignDate? dayjs(employeeList[index].resignDate).format("DD-MM-YYYY"): "",
        OESY_Y: employeeList[index].OESY_Y,
        OESY_M: employeeList[index].OESY_M,
        OESY: employeeList[index].OESY,
        SERV_Y: employeeList[index].SERV_Y,
        SERV_M: employeeList[index].SERV_M,
        SERV: employeeList[index].SERV,
        ESY_Y: employeeList[index].ESY_Y,
        ESY_M: employeeList[index].ESY_M,
        ESY: employeeList[index].ESY,
        TIG_Y: employeeList[index].TIG_Y,
        TIG_M: employeeList[index].TIG_M,
        TIG: employeeList[index].TIG,
        AGE_Y: employeeList[index].AGE_Y,
        AGE_M: employeeList[index].AGE_M,
        AGE: employeeList[index].AGE,
        degree_1: employeeList[index].educations && employeeList[index].educations.length > 0? employeeList[index].educations[0].degree: "",
        university_1: employeeList[index].educations && employeeList[index].educations.length > 0? employeeList[index].educations[0].university: "",
        faculty_1: employeeList[index].educations && employeeList[index].educations.length > 0? employeeList[index].educations[0].faculty: "",
        major_1: employeeList[index].educations && employeeList[index].educations.length > 0? employeeList[index].educations[0].major: "",
        fromYear_1: employeeList[index].educations && employeeList[index].educations.length > 0? employeeList[index].educations[0].fromYear: "",
        endYear_1: employeeList[index].educations && employeeList[index].educations.length > 0? employeeList[index].educations[0].endYear: "",
        gpa_1: employeeList[index].educations && employeeList[index].educations.length > 0? employeeList[index].educations[0].gpa: "",
        degree_2: employeeList[index].educations && employeeList[index].educations.length > 1? employeeList[index].educations[1].degree: "",
        university_2: employeeList[index].educations && employeeList[index].educations.length > 1? employeeList[index].educations[1].university: "",
        faculty_2: employeeList[index].educations && employeeList[index].educations.length > 1? employeeList[index].educations[1].faculty: "",
        major_2: employeeList[index].educations && employeeList[index].educations.length > 1? employeeList[index].educations[1].major: "",
        gpa_2: employeeList[index].educations && employeeList[index].educations.length > 1? employeeList[index].educations[1].gpa: "",
        fromYear_2: employeeList[index].educations && employeeList[index].educations.length > 1? employeeList[index].educations[1].fromYear: "",
        endYear_2: employeeList[index].educations && employeeList[index].educations.length > 1? employeeList[index].educations[1].endYear: "",
        degree_3: employeeList[index].educations && employeeList[index].educations.length > 2? employeeList[index].educations[2].degree: "",
        university_3: employeeList[index].educations && employeeList[index].educations.length > 2? employeeList[index].educations[2].university: "",
        faculty_3: employeeList[index].educations && employeeList[index].educations.length > 2? employeeList[index].educations[2].faculty: "",
        major_3: employeeList[index].educations && employeeList[index].educations.length > 2? employeeList[index].educations[2].major: "",
        gpa_3: employeeList[index].educations && employeeList[index].educations.length > 2? employeeList[index].educations[2].gpa: "",
        fromYear_3: employeeList[index].educations && employeeList[index].educations.length > 2? employeeList[index].educations[2].fromYear: "",
        endYear_3: employeeList[index].educations && employeeList[index].educations.length > 2? employeeList[index].educations[2].endYear: "",
        performance_past_1: null,
        performance_past_2: null,
        performance_past_3: null,
        performance_past_4: null,
        performance_past_5: null,
        potential_past_1: null,
        potential_past_2: null,
        potential_past_3: null,
        leaderRole: null,
        costCenter: employeeList[index].costCenterCharge,
        costElement: employeeList[index].costElementCharge,
        io: null,
        bookBank: employeeList[index].bookBank,
        bookBankBranchName: employeeList[index].bookBankBranchName,
        bookID: employeeList[index].bookID,
        reportBankBankName: employeeList[index].reportBankBankName,
        reportBankBankID: employeeList[index].reportBankBankID,
        reportBankBookBankID: employeeList[index].reportBankBookBankID,
        reportBankName: employeeList[index].reportBankName,
        reportBankRef: employeeList[index].reportBankRef,
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);

    // console.log(downloadHeaderExcel);

    for (const [key, value] of Object.entries(downloadHeaderExcel)) {
      // console.log(`${key}: ${value}`);
      ws[key].v = value;
    }

    var workbook = XLSX.utils.book_new();

    if(option && option.contractStatus === "normal"){
      XLSX.utils.book_append_sheet(workbook, ws, "PIS Master");
      XLSX.writeFile(workbook, `ข้อมูลพนักงานปกติ ${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`, { type: "file" });
    } else if (option && option.contractStatus === "terminate") {
      XLSX.utils.book_append_sheet(workbook, ws, "PIS Master");
      XLSX.writeFile(workbook, `ข้อมูลพนักงานลาออก ${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`, { type: "file" });
    }
    
  };

  const excelDiligenceAllowanceExport = async () => {
    const employeeList = await dispatch(getEmployeeExcelDiligenceAllowanceExport()).then(res => res.data)
    const elementList = [];
    for (let index = 0; index < employeeList.length; index++) {
      const element = {
        firstname: employeeList[index].firstname,
        lastname: employeeList[index].lastname,
        arrive: employeeList[index].arrive,
        absent: employeeList[index].absent,
        late: employeeList[index].late,
        earlyBack: employeeList[index].earlyBack,
        leave: employeeList[index].leave,
      }
      elementList.push(element);
    }

    const header = [[
      "ชื่อ",
      "นามสกุล",
      "จำนวนวันทำงาน",
      "ขาดงาน",
      "มาสาย",
      "กลับก่อน",
      "ลา (ยกเว้น ลาพักร้อน, ลาสลับวันหยุด)",
    ]]

    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, header);
    XLSX.utils.sheet_add_json(ws, elementList, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(workbook, ws, "Diligence Allowance");
    XLSX.writeFile(workbook, `รายชื่อพนักงานที่ได้รับเบี้ยขยัน ${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`, { type: "file" });
  }

  return (
    <DrawerCustom
      title={"ดาวน์โหลดข้อมูล"}
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <StyledRoot>
        {/* <div className="btn-action">
          <ButtonBlue variant="contained" onClick={excelExport} fullWidth>
            ดาวน์โหลดข้อมูล
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={excelDiligenceAllowanceExport} fullWidth>
            ดาวน์โหลดข้อมูล
          </ButtonBlue>
        </div> */}
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>ข้อมูลพนักงานปกติ</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue variant="contained" onClick={() => {excelExport({contractStatus: "normal"})}} fullWidth>
              ดาวน์โหลด
            </ButtonBlue>
          </Grid>
          <Grid item xs={12}>
            <Typography>ข้อมูลพนักงานลาออก</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue variant="contained" onClick={() => {excelExport({contractStatus: "terminate"})}} fullWidth>
              ดาวน์โหลด
            </ButtonBlue>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography>พนักงานที่ได้รับเบี้ยขยัน</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue variant="contained" onClick={excelDiligenceAllowanceExport} fullWidth>
              ดาวน์โหลด
            </ButtonBlue>
          </Grid> */}
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogDownload;
