import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Autocomplete, Avatar, Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, styled, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import dayjs from 'dayjs';
import DrawerCustom from "../../../shared/general/Drawer";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getNewCandidatesByIdJobRequest, getNewCandidatesByIdBiddings, updateInterviewDetail } from "../../../../../actions/candidate";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledRoot = styled("div")({
  padding: 16,
  maxWidth: 450,
  "& .MuiInputLabel-root .MuiInputLabel-formControl":{
    color:"#ffffff"
  },
  "& .part-footer": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
    "& i": {
      marginRight: 8,
    }
  },
  "& .part-auto-appointment": {
    marginBottom: 24,
    "& .MuiButton-root": {
      paddingTop: 8,
      paddingBottom: 8,
    }
  },
  "& .part-form": {
    "& .MuiInputBase-root": {
      height: 58,
    },
  }
,
})

const StyledName = styled("div")({
  padding: 8,
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#ffffff",
  "& .wrap-name": {
    display: "flex",
    alignItems: "center",
    width: 0,
    flexGrow: 1,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      flexGrow: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  "& i": {
    paddingLeft: 8,
    color: "#9e9e9e",
  }
})

const DrawerInterviewAppointment = (props) => {
  const { open, onClose, listCandidate, id, setIsSuccess, handleCountDownTimeSet, type, manager } = props;
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    interviewAppointmentDate: yup.date().required(),
    interviewStartTimeAuto: yup.date().required('กรุณากรอกเวลาเริ่มต้นสัมภาษณ์'),
    interviewEndTimeAuto: yup.date().min(yup.ref('interviewStartTimeAuto'), 'เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น').required('กรุณากรอกเวลาสิ้นสุดสัมภาษณ์'),
    interviewDurationTime: yup.string().required('กรุณากรอกเวลาสัมภาษณ์รายคน'),
    isOnline: yup.string().required('กรุณากรอกเลือกรูปแบบ'),
    interviewPlace: yup.string().max(350, 'สถานที่สัมภาษณ์ต้องมีความยาวน้อยกว่า 350 ตัวอักษร'),
    contactName: yup.string().max(200, 'ชื่อผู้ติดต่อต้องมีความยาวน้อยกว่า 200 ตัวอักษร'),
    contactPhone: yup.string().max(10, 'กรุณากรอกเบอร์โทรศัพท์ 10 หลัก'),
    listCandidate: yup.array().of(
      yup.object().shape({
        idCandidate: yup.number(),
      })
    )
  });

  const { control, handleSubmit, formState: { errors }, getValues, setError, setValue, clearErrors, watch } = useForm({
    defaultValues: {
      interviewAppointmentDate: dayjs(),
      interviewStartTimeAuto: dayjs(),
      interviewEndTimeAuto: dayjs(),
      interviewDurationTime: "",
      isOnline: '0',
      interviewPlace: "",
      contactName: "",
      contactPhone: "",
      listCandidate: listCandidate[id],      
    },
    resolver: yupResolver(validationSchema),
  })

  const [interviews, setInterviews] = useState([]);

  useEffect(() => {
    let ListCandidateByKey = {};
    Object.keys(listCandidate).map(key => {
      ListCandidateByKey[key] = [
        ...listCandidate[key].map(candidate => {
          return {
            idCandidate: candidate.idCandidate,
            idManpower: candidate.idManpower,
            name: candidate.name,
            lastName: candidate.lastName
          }
        })
      ];
    });
    setInterviews(ListCandidateByKey);
    return () => {
      setInterviews([]);
    };
  }, [listCandidate]);

  const onSubmit = async (values) => {
    const result = await dispatch(updateInterviewDetail(values));
    if(result){
      if (type === 'job') {
        dispatch(getNewCandidatesByIdJobRequest(id));
      } else {
        dispatch(getNewCandidatesByIdBiddings(id))
      }
      onClose();
      setIsSuccess(true);
      handleCountDownTimeSet();
    }
  }
  
  return(
    <DrawerCustom
      title="นัดหมายสัมภาษณ์"
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="interviewAppointmentDate"
                  rules={{
                    required: {value: true, message: "กรุณากรอกวันที่นัดหมายสัมภาษณ์"},
                    validate: {
                      dateValidation : (value) => !isNaN(value) || "กรุณากรอกวันที่ให้ถูกต้อง",
                      disblePast: (value) => ((new Date(value)) >= new Date(new Date().setHours(0,0,0,0))) || "ไม่สามารถเลือกวันที่ผ่านมาแล้วได้"
                    }
                  }}
                  render={({field})=>(
                    <DesktopDatePicker
                      {...field}
                      inputFormat="DD/MM/YYYY"
                      disablePast
                      renderInput={(params) => (
                        <Fragment>
                          <Typography gutterBottom sx={{ color: "#000000" }}>วันที่นัดหมายสัมภาษณ์</Typography>
                          <TextFieldTheme
                            {...params}
                            sx={{color:"#ffffff"}}
                            helperText={errors && errors.interviewAppointmentDate && errors.interviewAppointmentDate.message}
                            error={errors && errors.interviewAppointmentDate ? true: false}
                          />
                        </Fragment>
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="interviewStartTimeAuto"
                  render={({ field, fieldState }) => (
                    <TimePicker
                      {...field}
                      ampm={false}
                      label="เวลาเริ่มต้น"
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="interviewEndTimeAuto"
                  render={({ field, fieldState }) => (
                    <TimePicker
                      {...field}
                      ampm={false}
                      label="เวลาสิ้นสุด"
                      minTime={(dayjs(watch("interviewStartTimeAuto")))}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="interviewDurationTime"
                  render={({ field, formState }) => (
                    <Fragment>
                      <Typography gutterBottom>เวลาสัมภาษณ์รายคน</Typography>
                      <TextFieldTheme
                        {...field}
                        placeholder="เลือกเวลาสัมภาษณ์รายคน"
                        helperText={formState.errors && formState.errors.interviewDurationTime && formState.errors.interviewDurationTime.message}
                        error={formState.errors && formState.errors.interviewDurationTime ? true: false}
                        select
                      >
                        <MenuItem value="15">15 นาที</MenuItem>
                        <MenuItem value="30">30 นาที</MenuItem>
                        <MenuItem value="45">45 นาที</MenuItem>
                        <MenuItem value="60">60 นาที</MenuItem>
                        <MenuItem value="90">90 นาที</MenuItem>
                        <MenuItem value="120">120 นาที</MenuItem>
                      </TextFieldTheme>
                    </Fragment>
                  )}
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>ประเภทการสัมภาษณ์</FormLabel>
                <Controller 
                  control={control}
                  name={'isOnline'}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      row
                      onChange={(e) => field.onChange(e)}
                    >
                      <FormControlLabel value={'0'} control={<Radio />} label="Offline" />
                      <FormControlLabel value={'1'} control={<Radio />} label="Online" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'interviewPlace'}
                render={({ field, fieldState }) => (
                  <>
    
                    <Typography gutterBottom sx={{ color: "#000000" }}>{watch('isOnline') === '1' ? "ลิงก์สัมภาษณ์" : "สถานที่สัมภาษณ์"}</Typography>
                    <TextFieldTheme
                      {...field}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                      placeholder={watch('isOnline') === '1' ? "เช่น ลิงค์สำหรับสัมภาษณ์" : "เช่น ตึก อาคาร"}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'contactName'}
                render={({ field, fieldState }) => (
                  <>
                    <Typography gutterBottom sx={{ color: "#000000" }}>ชื่อผู้ติดต่อ</Typography>
                    <TextFieldTheme
                      {...field}
                      placeholder="เช่น กอไก่ เกิดแล้ว"
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'contactPhone'}
                render={({ field, fieldState }) => (
                  <>
                    <Typography gutterBottom sx={{ color: "#000000" }}>เบอร์โทรผู้ติดต่อ</Typography>
                    <TextFieldTheme
                      placeholder="เช่น 081756XXXX"
                      {...field}
                      inputProps={{ maxLength: 10 }}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  </>
                )}
              />
            </Grid>
          </Grid>
          <div className="part-footer">
            <ButtonBlue className="cancel-button" variant="outlined" onClick={onClose}><i className="fa-regular fa-x"></i>ยกเลิก</ButtonBlue>
            <ButtonBlue type="submit" variant="contained"><i className="fa-regular fa-check"></i>ยืนยัน</ButtonBlue>
          </div>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerInterviewAppointment;