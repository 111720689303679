import React from "react";

import { styled } from "@mui/styles";

import Menu from "./menu";
import Employees_list from "./emplyees_list";
import { Container } from "@mui/material";

import Unicorn from "../../assets/background/5160998.jpg"

const Root = styled("div")({
  padding: "100px 20px 20px",
  //width: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundImage: `url(${Unicorn})`,
  backgroundSize: "cover",
  boxSizing: "border-box",
  minHeight: "100vh",
});

const HomePage = (props) => {
  return (
    <Root className="page">
      <Container maxWidth="lg">
        {/* <Feed /> */}
        <Employees_list />
        <Menu />
      </Container>
    </Root>
  );
};

export default HomePage;
