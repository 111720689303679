import React, { useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Grid, Switch, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

import SelectCompany from "../../../shared/selectCompany";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginBottom: 4,
});

const StyledSwitchConfidential = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 24,
  padding: 6,
  "&.all-company": {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#001e3c !important",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(23px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.42 0 2.13.54 2.39 1.4.12.4.45.7.87.7h.3c.66 0 1.13-.65.9-1.27-.42-1.18-1.4-2.16-2.96-2.54V4.5c0-.83-.67-1.5-1.5-1.5S10 3.67 10 4.5v.66c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-1.65 0-2.5-.59-2.83-1.43-.15-.39-.49-.67-.9-.67h-.28c-.67 0-1.14.68-.89 1.3.57 1.39 1.9 2.21 3.4 2.53v.67c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-.65c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#bdbdbd",
    width: 24,
    height: 24,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#929292",
    borderRadius: 20 / 2,
  },
}));

const StyledSwitchPerformance = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 24,
  padding: 6,
  "&.all-company": {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#001e3c !important",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(23px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M13.12 2.06 7.58 7.6c-.37.37-.58.88-.58 1.41V19c0 1.1.9 2 2 2h9c.8 0 1.52-.48 1.84-1.21l3.26-7.61C23.94 10.2 22.49 8 20.34 8h-5.65l.95-4.58c.1-.5-.05-1.01-.41-1.37-.59-.58-1.53-.58-2.11.01zM3 21c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2s-2 .9-2 2v8c0 1.1.9 2 2 2z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#bdbdbd",
    width: 24,
    height: 24,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#929292",
    borderRadius: 20 / 2,
  },
}));

const StyledSwitchPayroll = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 24,
  padding: 6,
  "&.all-company": {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#001e3c !important",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(23px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="m19.41 7.41-4.83-4.83c-.37-.37-.88-.58-1.41-.58H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.42zM14 13c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1h-1c0 .55-.45 1-1 1s-1-.45-1-1h-1c-.55 0-1-.45-1-1s.45-1 1-1h3v-1h-3c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h1c0-.55.45-1 1-1s1 .45 1 1h1c.55 0 1 .45 1 1s-.45 1-1 1h-3v1h3zm0-5c-.55 0-1-.45-1-1V3.5L17.5 8H14z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#bdbdbd",
    width: 24,
    height: 24,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="20" viewBox="0 0 22 23"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#929292",
    borderRadius: 20 / 2,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  margin: 0,
  marginBottom: 8,
  "& .MuiTypography-body1": {
    fontWeight: 600,
    fontSize: 16,
  },
});

const DialogEditCompany = (props) => {
  const {
    open,
    valueSelected,
    handleCloseDialog,
    handleSubmit,
    formData,
    setFormData,
  } = props;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (valueSelected) {
      if (valueSelected.isNew === true) {
        setFormData({
          isNew: true,
          isActive: true,
          idCompany: null,
          isSalary: false,
          isPerformance: false,
          isPayroll: false,
          idEmployees: valueSelected.idEmployees,
        });
      } else {
        setFormData({
          idconfidentialData: valueSelected.idconfidentialData,
          idCompany: null,
          isSalary: false,
          isPerformance: false,
          isPayroll: false,
          idEmployees: valueSelected.idEmployees,
        });
      }
    }
  }, [open, valueSelected]);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    if (name === "isPayroll" && checked === true) {
      let temp = { ...formData };
      temp.isPayroll = true;
      temp.isSalary = true;
      setFormData(temp);
    } else {
      setFormData({
        ...formData,
        [name]: checked,
      });
    }
  };

  const handleChangeCompany = (newCompany) => {
    setFormData({
      ...formData,
      ["idCompany"]: newCompany ? newCompany.idCompany : null,
    });
  };

  return (
    <DrawerCustom
      title="แก้ไขข้อมูลผู้ดูแลระบบ"
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {formData && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledContentLabel>บริษัทที่ดูแล</StyledContentLabel>
              <SelectCompany
                variant="outlined"
                hideLabel={true}
                handleChangeCompany={handleChangeCompany}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginTop: 16, marginBottom: 16 }}>
                <Fragment>
                  <StyledFormControlLabel
                    labelPlacement="start"
                    control={
                      <StyledSwitchPerformance
                        name="isPerformance"
                        checked={formData.isPerformance}
                        onChange={handleChange}
                        className={`${
                          formData.isPerformance ? "all-company" : ""
                        }`}
                      />
                    }
                    label="ข้อมูลผลงาน"
                  />
                  <StyledFormControlLabel
                    labelPlacement="start"
                    control={
                      <StyledSwitchConfidential
                        name="isSalary"
                        checked={formData.isSalary}
                        onChange={handleChange}
                        className={`${formData.isSalary ? "all-company" : ""}`}
                      />
                    }
                    label="ข้อมูลเงินเดือน"
                  />

                  <StyledFormControlLabel
                    labelPlacement="start"
                    control={
                      <StyledSwitchPayroll
                        name="isPayroll"
                        checked={formData.isPayroll}
                        onChange={handleChange}
                        className={`${formData.isPayroll ? "all-company" : ""}`}
                      />
                    }
                    label="จัดการเงินเดือน"
                  />
                </Fragment>
              </div>
            </Grid>
          </Grid>
        )}

        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue
            disabled={formData && formData.idCompany === null}
            variant="contained"
            onClick={handleSubmit}
          >
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEditCompany;
