import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";

import DrawerCustom from "../../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import {
  updateManpower,
  getManpowerById,
} from "../../../../../../../actions/manpower";

import { Delete } from "@mui/icons-material";

const BoxAddSkillButton = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  "& .AddButton": {
    width: 30,
    height: 30,
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

const BoxInputSkill = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
  "& .TrashButton": {
    width: 30,
    height: 30,
    border: "2px solid",
    borderColor: "red",
    color: "red",
    marginLeft: 8,
  },
});

const DrawerJobGroupSkill = (props) => {
  const { open, onClose, data, idManpower } = props;

  const { result: jobRecruitGroup } = useSelector((state) => state.jobRecruitGroup);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      jobGroup: null,
      skills: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills",
  });

  const dispatch = useDispatch();

  const HandlerSubmit = (submitData) => {
    const formData = {};

    for (const key in dirtyFields) {
      if (key === "jobGroup") {
        formData["idJobGroup"] = submitData["jobGroup"].idJobGroup || "";
        continue;
      }
      formData[key] = submitData[key];
    }
    dispatch(updateManpower(idManpower, formData)).then((res) => {
      dispatch(getManpowerById(idManpower));
    });
    onClose();
  };

  useEffect(() => {
    if (jobRecruitGroup) {
      reset({
        jobGroup: jobRecruitGroup.find((j) => j.idJobGroup === data.idJobGroup),
        skills: data.skills || [],
      });
    }
  }, []);

  return (
    <DrawerCustom open={open} title={"แก้ไขกลุ่มงานและทักษะ"} anchor="right">
      <Box style={{ width: 400, padding: 16 }}>
        <form onSubmit={handleSubmit(HandlerSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="jobGroup"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณาเลือกกลุ่มงาน" },
                }}
                render={({ field, formState }) => (
                  <Fragment>
                    <Typography gutterBottom>กลุ่มงาน</Typography>
                    <Autocomplete
                      sx={{
                        "& .MuiInputBase-root": {
                          paddingLeft: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                          "& input": {
                            padding: "13.5px !important",
                          },
                        },
                      }}
                      // disableClearable
                      options={[...jobRecruitGroup]}
                      getOptionLabel={(option) => option.jobGroupName}
                      isOptionEqualToValue={(option, value) =>
                        option.idJobGroup === value.idJobGroup
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.idJobGroup}>
                          {option.jobGroupName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          error={
                            formState.errors && formState.errors.jobGroup
                              ? true
                              : false
                          }
                          helperText={
                            formState.errors && formState.errors.jobGroup
                              ? formState.errors.jobGroup.message
                              : null
                          }
                        />
                      )}
                      noOptionsText="ไม่พบคำที่ค้นหา"
                      value={field.value}
                      onChange={(_, data) => {
                        field.onChange(data);
                      }}
                    />
                  </Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>ทักษะของคุณ</Typography>
              {fields &&
                fields.map((skill, index) => (
                  <BoxInputSkill key={skill.id}>
                    <Controller
                      control={control}
                      name={`skills.[${index}].skillName`}
                      rules={{
                        required: {
                          value: true,
                          message: "กรุณากรอกทักษะ",
                        },
                      }}
                      render={({ field, formState }) => (
                        <TextFieldTheme
                          {...field}
                          placeholder="เช่น React, Node.js"
                          autoComplete="manpower-skill-name"
                          error={
                            formState.errors &&
                            formState.errors.skills &&
                            formState.errors.skills[index] &&
                            formState.errors.skills[index].skillName
                              ? true
                              : false
                          }
                          helperText={
                            formState.errors &&
                            formState.errors.skills &&
                            formState.errors.skills[index] &&
                            formState.errors.skills[index].skillName
                              ? formState.errors.skills[index].skillName.message
                              : null
                          }
                        />
                      )}
                    />
                    <IconButton
                      style={{
                        color: "#d32f2f",
                      }}
                      onClick={() => remove(index)}
                    >
                      <Delete />
                    </IconButton>
                  </BoxInputSkill>
                ))}
              <BoxAddSkillButton>
                <IconButton
                  className="AddButton"
                  size="small"
                  onClick={() => append({ skillName: "" })}
                >
                  <i className="fa-solid fa-plus" />
                </IconButton>
              </BoxAddSkillButton>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <ButtonBlue variant="outlined" onClick={onClose}>
                  ยกเลิก
                </ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={Object.keys(dirtyFields).length <= 0}
                >
                  ยืนยัน
                </ButtonBlue>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </DrawerCustom>
  );
};

export default DrawerJobGroupSkill;
