import React, { useState, useEffect } from "react";
import { createAPIEndpoint, ENDPOINTS } from "../../../../../services/api";
import Notification from "../../../shared/general/Notification";

import {
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  Button,
} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  root: {},
  dialog: {
    padding: 16,
    position: "absolute",
    top: 80,
  },
  dialogTitle: {
    borderBottom: "1px solid #dee2e6",
    color: "#222f3e",
    padding: "8px 16px 8px 16px",
  },
  dialogContent: {
    color: "#798294",
    fontWeight: 600,
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  addBtn: {
    backgroundColor: "#2f74eb",
    color: "#fff",
    fontWeight: 700,
    height: "40px",
    borderRadius: "25px",
    paddingLeft: 32,
    paddingRight: 32,
    "&:hover": {
      backgroundColor: "#2a68d3",
    },
  },
  divider: {
    marginTop: 32,
    marginBottom: 16,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#9e9e9e",
  },
  headTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.headTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiDialogContent);

const InitialValues = () => ({
  projId: 0,
  projectName: "",
  description: "",
  projectSize: "",
  projectInvest: "",
  personInCharge: "",
  startDate: null,
  endDate: null,
  createBy: "Admin",
  dateCreate: new Date(),
});

export default function AddProject(props) {
  const classes = useStyles();
  const { addModal, setAddModal, fetchData } = props;
  const [values, setValues] = useState(InitialValues);
  const [employees, setEmployees] = useState([]);
  const [personSelected, setPersonSelected] = useState([]);
  const [chart, setChart] = useState([]);

  const [errors, setErrors] = useState({
    name: false,
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // const OPTIONS_LIMIT = 5;
  // const defaultFilterOptions = createFilterOptions();

  // const filterOptions = (options, state) => {
  //   return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handlePersonSelected = (e, newValue) => {
    setPersonSelected(newValue);
    const newValues = { ...values };
    newValues.personInCharge = newValue !== null ? newValue.fullName : "";
    setValues(newValues);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    if (values.projectName === "") {
      setErrors({
        ...errors,
        name: true,
      });
    } else {
      createAPIEndpoint(ENDPOINTS.PROJECT)
        .create(values)
        .then((res) => {
          setAddModal({
            isOpen: false,
          });
          setNotify({
            isOpen: true,
            message: "Add Successfully",
            type: "success",
          });
          fetchData();
        })
        .catch((err) => console.log(err));
    }
  };

  const fetchEmpData = () => {
    createAPIEndpoint(ENDPOINTS.EMPLOYEE)
      .fetchAll()
      .then((res) => {
        //Add field Full Name
        res.data.forEach(function (element) {
          element.fullName = element.firstName + " " + element.lastName;
        });

        setEmployees(res.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchChartData = () => {
    createAPIEndpoint(ENDPOINTS.PROJECT)
      .fetchAll()
      .then((res) => {
        setChart(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (addModal.isOpen) {
      fetchEmpData();
      fetchChartData();
      setValues(InitialValues);
      setPersonSelected([]);
      setErrors({
        name: false,
      });
    }
  }, [addModal]);

  return (
    <div className={classes.root}>
      <Dialog
        open={addModal.isOpen}
        maxWidth="sm"
        fullWidth
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle
          className={classes.dialogTitle}
          onClose={() => setAddModal({ ...addModal, isOpen: false })}
        >
          Add New Project
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form onSubmit={handleOnSubmit}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1">Project Name : </Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  name="projectName"
                  value={values.projectName}
                  onChange={handleInputChange}
                  error={errors.name}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1">Description : </Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <TextField
                  variant="outlined"
                  size="small"
                  name="description"
                  value={values.description}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1">Project Size : </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel>Select Size</InputLabel>
                  <Select
                    label="Select Size"
                    value={values.size}
                    onChange={(e) =>
                      setValues({ ...values, projectSize: e.target.value })
                    }
                  >
                    <MenuItem value="Small">Small</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Large">Large</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1">Project Invest : </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel>Select Invest</InputLabel>
                  <Select
                    label="Select Invest"
                    value={values.invest}
                    onChange={(e) =>
                      setValues({ ...values, projectInvest: e.target.value })
                    }
                  >
                    <MenuItem value="> 100,000">{"> 100,000"}</MenuItem>
                    <MenuItem value="> 1,000,000">{"> 1,000,000"}</MenuItem>
                    <MenuItem value="> 10,000,000">{"> 10,000,000"}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1">Person In Charge : </Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                {/* <Autocomplete
                  filterOptions={filterOptions}
                  options={employees}
                  autoHighlight
                  getOptionLabel={(option) => option.fullName || ""}
                  value={personSelected}
                  onChange={handlePersonSelected}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Employee"
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                /> */}
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1">Start Date : </Typography>
              </Grid>
              <Grid item xs={12} md={5} lg={5}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="change date"
                    name="startDate"
                    value={values.startDate}
                    onChange={(date) => {
                      setValues({
                        ...values,
                        startDate: date,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        format="dd/MM/yyyy"
                        placeholder="DD/MM/YYYY"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1">End Date : </Typography>
              </Grid>
              <Grid item xs={12} md={5} lg={5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="change date"
                    name="endDate"
                    value={values.endDate}
                    onChange={(date) => {
                      setValues({
                        ...values,
                        endDate: date,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        format="dd/MM/yyyy"
                        placeholder="DD/MM/YYYY"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.addBtn}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
