import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Alert, Box, Grid, MenuItem, Paper, Snackbar, Typography } from "@mui/material";
import DataTable from "./components/DataTable";
import { deletePosition, getAllPositions } from "../../../../../actions/positions";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardStyle from "../../../shared/general/Card";
import AddIcon from "@mui/icons-material/Add";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DialogEditPosition from "./DialogEditPosition";
import { getJobGroup } from "../../../../../actions/jobRecruit";
import PositionTable from "./PositionTable";
import AlertResponse from "../../../shared/general/AlertResponse";
import Notification from "../../../shared/general/Notification";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";
import { getOrganizationStructure } from "../../../../../actions/organizationStructure";
import { getLevelByidCompany } from "../../../../../actions/level";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
});

function Index(props) {
  const classes = useStyles();
  const { company } = props;
  const dispatch = useDispatch();
  const { result: jobFamilyStore } = useSelector((state) => state.jobFamily);
  const { result: positionList } = useSelector((state) => state.positions);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  })

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  })

  const [deleteConfig, setDeleteConfig] = useState({
    isOpen: false,
    data: {
      idPosition: null,
      positionCode: "",
      positionName: "",
    }
  })

  let setFirstPageChildFunc = null;

  const [filterConfig, setFilterConfig] = useState({
    type: "position",
    name: "",
  })

  const handleAddPosition = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: null,
    }))
  }

  const handleCloseDialog = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleChangeFilter = (event) => {
    setFilterConfig(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    // console.log(filterConfig)
    // if(setFirstPage){
    setFirstPageChildFunc();
    // }
  }

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleDeletePosition = async () => {

    const response = await dispatch(deletePosition(deleteConfig.data.idPosition))

    if(response){
      if(response.status === 200){
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: "บันทึกข้อมูลสำเร็จ"
        }))
        dispatch(getAllPositions())
        handleCloseDeleteModal();
      } else {
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: "ไม่สามารถลบตำแหน่งงานนี้ได้ กรุณาติดต่อแอดมิน"
        }))
      }
    } else {
      setAlertConfig(prev => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      }))
    }

    setDeleteConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }


  const getFilteredData = () => {
    if(positionList){
      if(filterConfig.name === "") return positionList;
      const filterData = positionList.filter(p => {
        if(filterConfig.type === "position"){
          return p.positionName && p.positionName.includes(filterConfig.name)
        } else if (filterConfig.type === "section") {
          return p.sectionName && p.sectionName.includes(filterConfig.name)
        } else if (filterConfig.type === "department") {
          return p.departmentName && p.departmentName.includes(filterConfig.name)
        } else if (filterConfig.type === "division") {
          return p.divisionName && p.divisionName.includes(filterConfig.name)
        } else if (filterConfig.type === "business") {
          return p.businessName && p.businessName.includes(filterConfig.name)
        }
      })
      return filterData
    } else {
      return []
    }
  }

  // const handleCloseDialog = () => {
    // setOpenDialog({
    //   isOpen: false,
    //   jobFamilies: null
    // });
  // };

  useEffect(() => {
    dispatch(getAllPositions());
    dispatch(getJobGroup());
    dispatch(getOrganizationStructure())
    dispatch(getLevelByidCompany())
  }, []);

  return (
    <div className={classes.root}>
      <StyledHeadingPage>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">จัดการตำแหน่งงาน</Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<AddIcon />}
              // onClick={() => {
              //   setOpenDialog({
              //     ...openDialog,
              //     isOpen: true,
              //   });
              // }}
              onClick={handleAddPosition}
            >
              เพิ่มตำแหน่งงาน
            </ButtonBlue>
          </Grid>
        </Grid>
      </StyledHeadingPage>
      <Box display="flex" gap="8px" marginBottom="16px">
        <TextFieldTheme
          name="type"
          value={filterConfig.type}
          onChange={handleChangeFilter}
          style={{width: "150px"}}
          select
        >
          <MenuItem value="position">ตำแหน่งงาน</MenuItem>
          <MenuItem value="section">แผนก</MenuItem>
          <MenuItem value="department">หน่วยงาน</MenuItem>
          <MenuItem value="division">ฝ่าย</MenuItem>
          <MenuItem value="business">หน่วยธุรกิจ</MenuItem>
        </TextFieldTheme>
        <TextFieldTheme
          name="name"
          value={filterConfig.name}
          onChange={handleChangeFilter}
          inputProps={{
            placeholder: "ค้นหา.."
          }}
        />
      </Box>

      {/* <CardStyle style={{ padding: 16 }}>
        <DataTable
          isLoading={isLoading}
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          data={getFilteredData()}
          setFirstPage={(setFirstPage) => {setFirstPageChildFunc = setFirstPage}}
        />
      </CardStyle> */}

      {/* <Box marginTop="24px"> */}
        <CardStyle style={{ padding: 16 }}>
          <PositionTable
            data={getFilteredData()}
            setFirstPage={(setFirstPage) => {setFirstPageChildFunc = setFirstPage}}
            setDrawerConfig={setDrawerConfig}
            setDeleteConfig={setDeleteConfig}
          />
        </CardStyle>
      {/* </Box> */}

      {/* {openDialog.isOpen && 
        <AddOrEditDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          setOpenSnackbar={setOpenSnackbar}
          company={company}
        />
      } */}

      <DialogEditPosition
        drawerConfig={drawerConfig}
        onClose={handleCloseDialog}
        setOpenAlert={setOpenAlert}
        setAlertType={setAlertType}
        setAlertConfig={setAlertConfig}
      />

      <DialogConfirmDelete
        open={deleteConfig.isOpen}
        handleClose={handleCloseDeleteModal}
        label={"ตำแหน่งงาน"}
        text={`(${deleteConfig.data.positionCode}) ${deleteConfig.data.positionName}`}
        handleDelete={handleDeletePosition}
      />

      {/* <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      /> */}

      <Notification
        notify={alertConfig}
        setNotify={setAlertConfig}
      />

      {/* <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          variant="filled"
        >
          สำเร็จ
        </Alert>
      </Snackbar> */}
    </div>
  );
}

export default Index;
