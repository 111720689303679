import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Typography, Box, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import SnackBarCustom from "../../shared/snackbarCustom";
import ReviewerOnBoarding from "./reviewer";
import StatusOnBoarding from "./status";
import OnBoardingList from "./listItem";
import ProbationStatus from "./probationStatus";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapTabs = styled("div")({
  paddingBottom: 36,
  marginTop: 16,
  width: "100%",
});

const OnBoarding = () => {
  const [value, setValue] = useState(0);
  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledRoot className={`page`}>
      <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />
      <Container maxWidth="lg">
        <Box>
          <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
            On Boarding
          </Typography>
        </Box>
        <StyledWrapTabs>
          <Box style={{ marginBottom: 16 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="สร้างรายการมาตรฐาน" {...a11yProps(0)} />
              <Tab label="ตรวจสอบสถานะ" {...a11yProps(1)} />
              <Tab label="มอบหมายตรวจ" {...a11yProps(2)} />
              <Tab label="สถานะการทดลองงานพนักงาน" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <OnBoardingList />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <StatusOnBoarding />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ReviewerOnBoarding />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ProbationStatus />
          </TabPanel>
        </StyledWrapTabs>
      </Container>
    </StyledRoot>
  );
};

export default OnBoarding;
