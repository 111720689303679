import {
  EXIT_INTERVIEW_SUCCESS,
  EXIT_INTERVIEW_FETCHING,
  EXIT_INTERVIEW_FAILED,
  RESULT_EXIT_INTERVIEW_SUCCESS,
  RESULT_EXIT_INTERVIEW_FETCHING,
  RESULT_EXIT_INTERVIEW_FAILED,
  EXIT_INTERVIEW_DISCARD_SUCCESS,
  EXIT_INTERVIEW_DISCARD_FETCHING,
  EXIT_INTERVIEW_DISCARD_FAILED,
  REASON_EXIT_INTERVIEW_SUCCESS,
  REASON_EXIT_INTERVIEW_FETCHING,
  REASON_EXIT_INTERVIEW_FAILED,
} from "./types";
import ExitInterviewService from "../services/exitInterview.service";

export const getExitInterview = () => async (dispatch) => {
  try {
    const res = await ExitInterviewService.getExitInterview();
    if (res) {
      dispatch({
        type: EXIT_INTERVIEW_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EXIT_INTERVIEW_FAILED,
    });
    console.log(err);
  }
};

export const getExitInterviewDiscard = () => async (dispatch) => {
  try {
    const res = await ExitInterviewService.getExitInterviewDiscard();
    if (res) {
      dispatch({
        type: EXIT_INTERVIEW_DISCARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EXIT_INTERVIEW_DISCARD_FAILED,
    });
    console.log(err);
  }
};

export const updateExitInterview = (formdata) => async () => {
  try {
    const res = await ExitInterviewService.updateExitInterview(formdata);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const updateExitInterviewUser = (formData) => async () => {
  try {
    const res = await ExitInterviewService.updateExitInterviewUser(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getExitInterviewAndAnswer = () => async (dispatch) => {
  try {
    const res = await ExitInterviewService.getExitInterviewAndAnswer();
    if (res) {
      dispatch({
        type: RESULT_EXIT_INTERVIEW_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: RESULT_EXIT_INTERVIEW_FAILED,
    });
    console.log(err);
  }
};

export const updateReasonExitInterview = (formData) => async () => {
  try {
    const res = await ExitInterviewService.updateReasonExitInterview(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getReasonExitInterview = () => async (dispatch) => {
  try {
    const res = await ExitInterviewService.getReasonExitInterview();
    if (res) {
      dispatch({
        type: REASON_EXIT_INTERVIEW_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REASON_EXIT_INTERVIEW_FAILED,
    });
    console.log(err);
  }
};

export const updateWithDrawExitInterview =
  (idResultExitInterview) => async () => {
    try {
      const res = await ExitInterviewService.updateWithDrawExitInterview(
        idResultExitInterview
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return "Error";
    }
  };
