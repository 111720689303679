import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
} from "devextreme-react/data-grid";

import utils from "../../../../utils";

import StyledCard from "../../shared/general/Card";
import { Typography } from "@mui/material";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },

  "& .dx-datagrid-total-footer": {
    backgroundColor: "#FFF200",
  },
});

const StyleWrapFooter = styled("div")({
  padding: 16,
  "& .wrap-late": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-text-header": {
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  "& .text-header": {
    display: "flex",
    alignItems: "center",
  },
  "& .text-value": {
    textAlign: "center",
    "& .MuiTypography-h5": {
      fontWeight: 600,
      color: "#e46a76",
      lineHeight: 1,
    },
  },
  "& .sum-baht": {
    fontWeight: 500,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const TableEmployee = (props) => {
  const { result: summaryTimeIndividual } = useSelector(
    (state) => state.summaryTimeIndividual
  );
  const [result, setResult] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  let gridColumnsOT = [
    {
      dataField: "otOneHours",
      caption: "OT 1 (ชั่วโมง)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneAmount",
      caption: "OT 1 (บาท)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveHours",
      caption: "OT 1.5 (ชั่วโมง)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveAmount",
      caption: "OT 1.5 (บาท)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoHours",
      caption: "OT 2 (ชั่วโมง)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoAmount",
      caption: "OT 2 (บาท)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeHours",
      caption: "OT 3 (ชั่วโมง)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeAmount",
      caption: "OT 3 (บาท)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "shiftPayMorning",
      caption: "ค่ากะเช้า",
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftPayAfternoon",
      caption: "ค่ากะบ่าย",
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftPayNight",
      caption: "ค่ากะดึก",
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftPayTotal",
      caption: "รวมค่ากะ (บาท)",
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
  ];

  useEffect(() => {
    if (summaryTimeIndividual) {
      let temp = { ...summaryTimeIndividual };
      temp.dataTable.forEach((element) => {
        element["date"] = dayjs(element.dateText)
          .locale(localStorage.getItem("language") || "th")
          .format(
            localStorage.getItem("language") === "th"
              ? "DD MMM BBBB (ddd)"
              : "DD MMM YYYY (ddd)"
          );
        element["workingTime"] = element.dataRender
          ? element.dataRender.isWorkingDay === 1
            ? `${element.dataRender.timeIn.slice(
                0,
                -3
              )} - ${element.dataRender.timeOut.slice(0, -3)}`
            : element.dataRender.shiftTypeName
          : "";

        element["otOneHours"] = element.otOneHours ? element.otOneHours : null;
        element["otOneFiveHours"] = element.otOneFiveHours
          ? element.otOneFiveHours
          : null;
        element["otTwoHours"] = element.otTwoHours ? element.otTwoHours : null;
        element["otThreeHours"] = element.otThreeHours
          ? element.otThreeHours
          : null;

        element["otOneAmount"] = element.otOneAmount
          ? element.otOneAmount
          : null;
        element["otOneFiveAmount"] = element.otOneFiveAmount
          ? element.otOneFiveAmount
          : null;
        element["otTwoAmount"] = element.otTwoAmount
          ? element.otTwoAmount
          : null;
        element["otThreeAmount"] = element.otThreeAmount
          ? element.otThreeAmount
          : null;
      });
      setResult(temp);
    }
  }, [summaryTimeIndividual]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const customizeOTHour = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} ชม.` : "";
  };

  const customizeOTAmount = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} บาท` : "";
  };

  const onExporting = useCallback(() => {
    // let workbook = new ExcelJS.Workbook();
    // let worksheet = workbook.addWorksheet("SheetName");
    // exportDataGrid({
    //   component: grid.current.instance,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       `${payRunDetail.payrunName}.xlsx`
    //     );
    //   });
    // });
  }, []);

  return (
    <Fragment>
      <StyledCard>
        <StyledCardContent>
          <StyledWrapDataGrid>
            <DataGrid
              dataSource={
                result && summaryTimeIndividual ? result.dataTable : []
              }
              showBorders={true}
              showColumnLines={true}
              rowAlternationEnabled={true}
              columnAutoWidth={true}
            >
              <Column
                caption="วันที่"
                dataField="date"
                dataType="string"
                cssClass="column-info"
              />
              <Column
                caption="เวลาทำงาน"
                dataField="workingTime"
                dataType="string"
                cssClass="column-info"
              />

              {/* {result.idPaymentType === 1 && (
                <Column
                  caption="วันทำงาน"
                  dataField="workingDay"
                  dataType="string"
                  cssClass="column-info"
                  alignment="center"
                />
              )}
              {result.idPaymentType === 1 && (
                <Column
                  caption="ขาดงาน (วัน)"
                  dataField="absent"
                  dataType="string"
                  cssClass="column-deduction"
                  alignment="center"
                />
              )}
              {result.idPaymentType === 1 && (
                <Column
                  caption="มาสาย/กลับก่อน (นาที)"
                  dataField="lateEarlyMinute"
                  dataType="string"
                  cssClass="column-deduction"
                  alignment="center"
                />
              )}
              {result.idPaymentType === 1 && (
                <Column
                  caption="มาสาย/กลับก่อน (บาท)"
                  dataField="lateEarlyAmount"
                  dataType="string"
                  cssClass="column-deduction"
                  alignment="center"
                />
              )} */}

              {gridColumnsOT.map((item, key) => (
                <Column
                  dataField={item.dataField}
                  caption={item.caption}
                  format={item.format}
                  // format="###,##0.00"
                  // customizeText={(cellInfo) => cellInfo.value + "1"}
                  cssClass="column-total-addition"
                />
              ))}

              {summaryTimeIndividual &&
                summaryTimeIndividual.isPayShift === 1 &&
                gridColumnsShift.map((item, key) => (
                  <Column
                    dataField={item.dataField}
                    caption={item.caption}
                    format={item.format}
                    cssClass={item.cssClass}
                  />
                ))}

              <Export enabled={true} allowExportSelectedData={true} />

              <Selection mode="single" />
              <Sorting mode="multiple" />
              <Scrolling columnRenderingMode="virtual" />
              <Paging defaultPageSize={31} />
              <FilterRow visible={false} />
              <HeaderFilter visible={false} />
              <SearchPanel visible={true} width={240} placeholder="ค้นหา..." />
              <Summary>
                <TotalItem column="date" displayFormat="รวมทั้งหมด" />
                <TotalItem
                  column="otOneHours"
                  summaryType="sum"
                  customizeText={customizeOTHour}
                />

                <TotalItem
                  column="otOneAmount"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
                <TotalItem
                  column="otOneFiveHours"
                  summaryType="sum"
                  customizeText={customizeOTHour}
                />
                <TotalItem
                  column="otOneFiveAmount"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
                <TotalItem
                  column="otTwoHours"
                  summaryType="sum"
                  customizeText={customizeOTHour}
                />
                <TotalItem
                  column="otTwoAmount"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
                <TotalItem
                  column="otThreeHours"
                  summaryType="sum"
                  customizeText={customizeOTHour}
                />
                <TotalItem
                  column="otThreeAmount"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
                <TotalItem
                  column="shiftPayTotal"
                  summaryType="sum"
                  customizeText={customizeOTAmount}
                />
              </Summary>
            </DataGrid>
          </StyledWrapDataGrid>
          {/* {result.idPaymentType !== 1 && (
            <StyleWrapFooter>
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                สรุปรายการหักเงิน
              </Typography>
              <div className="wrap-late">
                <div className="wrap-text-header">
                  <div className="text-header">
                    <KeyboardArrowRightRoundedIcon fontSize="small" />
                    <Typography>มาสาย/กลับก่อน</Typography>
                  </div>
                  <Typography color="text.secondary" style={{ paddingLeft: 8 }}>
                    {`${dayjs(result.dock.start).format(
                      "D MMM BBBB"
                    )} - ${dayjs(result.dock.end).format("D MMM BBBB")}`}
                  </Typography>
                </div>
                {result.dock.lateEarly.value > 0 ? (
                  <div className="text-value">
                    <Typography variant="h5">
                      {result.dock.lateEarly.value}
                      <Typography component="span" color="text.third">
                        &nbsp;นาที
                      </Typography>
                    </Typography>

                    <Typography className="sum-baht">
                      {`${utils.roundToTwo(
                        result.dock.lateEarly.value * result.rate.proRate
                      )} บาท`}
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <Typography>-</Typography>
                  </div>
                )}
              </div>
              <StyledDivider />
              <div className="wrap-late">
                <div className="wrap-text-header">
                  <div className="text-header">
                    <KeyboardArrowRightRoundedIcon fontSize="small" />
                    <Typography>ขาดงาน</Typography>
                  </div>
                  <Typography color="text.secondary" style={{ paddingLeft: 8 }}>
                    {`${dayjs(result.dock.start).format(
                      "D MMM BBBB"
                    )} - ${dayjs(result.dock.end).format("D MMM BBBB")}`}
                  </Typography>
                </div>
                {result.dock.absent.value > 0 ? (
                  <div className="text-value">
                    <Typography variant="h5">
                      {result.dock.absent.value}
                      <Typography component="span" color="text.third">
                        &nbsp;วัน
                      </Typography>
                    </Typography>
                    <Typography className="sum-baht">
                      {`${utils.roundToTwo(
                        result.dock.absent.value * result.rate.manDay
                      )} บาท`}
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <Typography>-</Typography>
                  </div>
                )}
              </div>
              <StyledDivider />
            </StyleWrapFooter>
          )} */}
        </StyledCardContent>
      </StyledCard>
    </Fragment>
  );
};
export default TableEmployee;
