import React, { useEffect, useState } from "react";
import { Grid, Toolbar, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useSelector, useDispatch } from "react-redux";
import { getAllEmployees } from "../../../../../../actions/employee";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiToolbar-root": {
      paddingTop: 48,
    },
  },
  imageEmp: {
    width: 160,
    height: 160,
    borderRadius: "50%",
    marginBottom: 24,
  },
}));

export default function EmployeeInfo(props) {
  const { idEmployees } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: empStore } = useSelector((state) => state.employees);
  const [empProfile, setEmpProfile] = useState([]);

  useEffect(() => {
    if (empStore) {
      let profile = empStore.filter((x) => x.idEmployees === idEmployees);

      profile.forEach(function (x) {
        x.image = null;
      });

      setEmpProfile(profile[0]);
    }
  }, [empStore]);

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  return (
    <div className={classes.root}>
      <div className="card-section">
        {empProfile && <Toolbar>
          <Grid container justifyContent="space-between">
            <Grid container item xs={12} md={4} lg={4} justifyContent="center">
              <Grid item>
                {empProfile && empProfile.image ? (
                  <img
                    src={`/images/employees/${empProfile.image}`}
                    className={classes.imageEmp}
                  />
                ) : (
                  <AccountCircleIcon
                    sx={{ color: "#e0e0e0" }}
                    className={classes.imageEmp}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  align="center"
                  className={classes.employeeInfo}
                  style={{ color: "#222f3e" }}
                >
                  {`${empProfile.firstName} ${empProfile.lastName}`}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  align="center"
                  color="textSecondary"
                  className={classes.employeeInfo}
                >
                  {empProfile.positionName}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  align="center"
                  color="textSecondary"
                  className={classes.employeeInfo}
                >
                  {empProfile.department}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item={12} md={8} lg={8} justifyContent="center">
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Employee Type
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.employeeGroup ? empProfile.employeeGroup : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Working Type
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.workingType ? empProfile.workingType : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Company
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.company ? empProfile.company : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Division
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.division ? empProfile.division : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Department
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.department ? empProfile.department : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Section
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.section ? empProfile.section : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Job Group
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.jobGroup ? empProfile.jobGroup : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Job Position
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.positionName ? empProfile.positionName : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Report to
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.managerName ? empProfile.managerName : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Personal Level
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.PersonLevel ? empProfile.PersonLevel : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    TIG
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.yearsTIG ? empProfile.yearsTIG : "0"} /{" "}
                    {empProfile.monthsTIG ? empProfile.monthsTIG : "0"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    TIP
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.yearsTIP ? empProfile.yearsTIP : "0"} /{" "}
                    {empProfile.monthsTIP ? empProfile.monthsTIP : "0"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    ESY
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.yearsESY ? empProfile.yearsESY : "0"} /{" "}
                    {empProfile.monthsESY ? empProfile.monthsESY : "0"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Service Year
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.serviceYears ? empProfile.serviceYears : "0"} /{" "}
                    {empProfile.serviceMonths ? empProfile.serviceMonths : "0"}
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <Typography color="textSecondary" variant="subtitle1">
                    Age
                  </Typography>
                  <Typography variant="subtitle1">
                    {empProfile.yearsAge ? empProfile.yearsAge : "0"} /{" "}
                    {empProfile.monthsAge ? empProfile.monthsAge : "0"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>}
      </div>
    </div>
  );
}
