import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import {
  Grid,
  Typography,
  Paper,
  Toolbar,
  TextField,
  InputAdornment,
  Button,
  LinearProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useSelector, useDispatch } from "react-redux";
import { getAllDialogueRecords } from "../../../../../actions/DialogueRecords";

//icon
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "../../../shared/general/Pagination";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      padding: 24,
    },
    "& .MuiToolbar-root": {
      marginBottom: 32,
    },
    "& .MuiTypography-h6": {
      color: "#222f3e",
      
    },
    "& .MuiGrid-container": {
      marginBottom: "0 !important",
    },
    "& .MuiTypography-subtitle1": {
      display: "flex",
      alignItems: "center",
      color: "#9e9e9e",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.4rem",
      marginRight: "5px",
    },
  },
  container: {
    padding: 16,
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  listRecord_Section: {
    marginBottom: 40,
  },
  recordCard: {
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    padding: 24,
    marginBottom: 16,
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  },
  imageEmp: {
    width: 64,
    height: 64,
    borderRadius: "50%",
  },
  recordCard_Name: {
    color: "#222f3e !important",
    fontWeight: "700",
  },
  viewRecordBtn: {
    backgroundColor: "#4f65df",
    color: "#fff",
    height: 40,
    padding: 8,
    borderRadius: "50px",
    "&:hover": {
      backgroundColor: "#4f65df",
    },
  },
}));

function DialogueRecordList(props) {
  const { recordList } = props;
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: dialogueStore } = useSelector(
    (state) => state.DialogueRecords
  );
  const [records, setRecords] = useState([]);
  const [recordItems, setRecordItems] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = records.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    let x = [...recordItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setRecords(x);
  }, [searchKey]);

  useEffect(() => {
    if (dialogueStore) {
      setRecords(dialogueStore);
      setRecordItems(dialogueStore);
      setIsLoading(false);
    }
  }, [dialogueStore]);

  useEffect(() => {
    dispatch(getAllDialogueRecords());
  }, [recordList]);

  return (
    <div className={classes.root}>
      <Paper style={{ borderRadius: "15px" }}>
        <Grid container>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Record Dialogue
            </Typography>
          </Grid>
        </Grid>
        <Toolbar>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={3} lg={3}>
              <TextField
                variant="outlined"
                style={{ width: "100%" }}
                label="Search Obejctive"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <Paper className={classes.container}>
          <div className={classes.listRecord_Section}>
            {isLoading ? (
              <LinearProgress />
            ) : (
              // records
              (records.length > 0 &&
                // currentPosts
                currentPosts.map((item) => (
                  <div
                    key={item.dialogueRecordId}
                    className={classes.recordCard}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={2} md={1} lg={1}>
                        {item.image == null || item.image == "string" ? (
                          <AccountCircleIcon
                            sx={{ color: "#e0e0e0" }}
                            className={classes.imageEmp}
                          />
                        ) : (
                          <img
                            src={`/images/employees/${item.image}`}
                            className={classes.imageEmp}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <Grid container>
                          <Grid item xs={12} md={5} lg={5}>
                            <Typography
                              variant="subtitle1"
                              className={classes.recordCard_Name}
                              gutterBottom
                            >
                              {`${item.firstName} ${item.lastName}`}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                              {item.positionName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <Typography variant="subtitle1" gutterBottom>
                              {item.objectiveName}
                            </Typography>
                            <Typography variant="subtitle1">
                              <InsertInvitationIcon />
                              {dayjs(item.dateCreate).format("DD/MM/YYYY")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{ textAlign: "end" }}
                      >
                        <Button
                          variant="contained"
                          className={classes.viewRecordBtn}
                          onClick={() =>
                            history.push({
                              pathname: "/dialoguerecordview",
                              dialogue: item,
                            })
                          }
                        >
                          <SearchIcon /> View
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ))) || (
                <Grid container>
                  <Grid container item xs justifyContent="center">
                    <Typography variant="body1">No Data</Typography>
                  </Grid>
                </Grid>
              )
            )}
            {records.length > 0 ? (
              <Pagination
                paginate={paginate}
                postsPerPage={postsPerPage}
                totalPosts={records.length}
              />
            ) : null}
          </div>
        </Paper>
      </Paper>
    </div>
  );
}

export default DialogueRecordList;
