import React, { useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import {
  addTaxDeductionSchedule,
  getTaxDeductionSchedule,
  updateTaxDeductionSchedule,
} from "../../../../../actions/taxDeduction";

const DrawerDate = (props) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const { result: TaxDeductionSchedule } = useSelector(
    (state) => state.taxDeductionSchedule
  );

  useEffect(() => {
    if (TaxDeductionSchedule) {
      reset({
        startDate: TaxDeductionSchedule.startDate,
        endDate: TaxDeductionSchedule.endDate,
      });
    }
  }, [TaxDeductionSchedule]);

  const onHandleSubmit = async (value) => {
    let data = {
      startDate: dayjs(value.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(value.endDate).format("YYYY-MM-DD"),
    };
    let result = null;
    if (TaxDeductionSchedule) {
      data.idTaxDeductionSchedule =
        TaxDeductionSchedule.idTaxDeductionSchedule;
      result = await dispatch(updateTaxDeductionSchedule(data));
    } else {
      result = await dispatch(addTaxDeductionSchedule(data));
    }

    if (result.status === 200) {
      props.onSetAlert(
        "success",
        `${TaxDeductionSchedule ? "แก้ไข" : "เพิ่ม"}สำเร็จ`
      );
      dispatch(getTaxDeductionSchedule());
    } else {
      props.onSetAlert(
        "error",
        `${TaxDeductionSchedule ? "แก้ไข" : "เพิ่ม"}ไม่สำเร็จ`
      );
    }
    props.onClose();
  };

  return (
    <DrawerCustom
      title="แก้ไขวันเวลาหักลดหย่อนภาษี"
      anchor="right"
      open={props.open}
      onClose={props.onClose}
    >
      <div style={{ width: 350, padding: 24 }}>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography style={{ marginBottom: 16 }}>
              วันที่เริ่มการแก้ไข
            </Typography>
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => (
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  minDate={new Date()}
                  {...field}
                  renderInput={(params) => (
                    <TextFieldTheme
                      fullWidth
                      style={{ marginBottom: 16 }}
                      {...params}
                    />
                  )}
                />
              )}
            />
            <Typography style={{ marginBottom: 16 }}>
              วันที่สิ้นสุดการแก้ไข
            </Typography>
            <Controller
              control={control}
              name="endDate"
              rules={{
                validate: {
                  isSameOrNext: (value) =>
                    !dayjs(value).isBefore(watch("startDate")) ||
                    `กรุณากรอกวันที่ให้ถูกต้อง`,
                },
              }}
              render={({ field }) => (
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  minDate={new Date()}
                  {...field}
                  renderInput={(params) => (
                    <TextFieldTheme
                      style={{ marginBottom: 16 }}
                      fullWidth
                      {...params}
                      error={errors && errors.endDate}
                      helperText={
                        errors && errors.endDate && errors.endDate.message
                      }
                    />
                  )}
                />
              )}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ButtonBlue onClick={props.onClose} style={{ marginRight: 16 }}>
                ยกเลิก
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                บันทึกข้อมูล
              </ButtonBlue>
            </div>
          </LocalizationProvider>
        </form>
      </div>
    </DrawerCustom>
  );
};

export default DrawerDate;
