import React,{ useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllWork } from "../../../../actions/work";
import { Breadcrumb2 } from "../../shared/breadcrums";
import DataViewSuggest from "../../shared/pages/viewSuggestion";
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
}))

const ViewSuggest = (props) => {
  //use style in name of classes
  const classes = useStyles();

  const dispatch = useDispatch();
  const { result: dataWork } = useSelector((state) => state.dataWork);
  
  useEffect(() => {
    dispatch(getAllWork());
  }, []);
  
  return (
    dataWork && 
    dataWork.filter((val) => val.id === props.match.params.id)
    .map((val,index) =>
      <DataViewSuggest 
        key={index}
        data={val}
        name='All data suggest Career' 
        breadcrumb = {
          <Breadcrumb2
            textLabel={"All data suggest Career"}
            icon={<AccessibilityNewIcon className={classes.sendIcon} />}
            link={"/manager/career"}
            prevText={"Career"}
          />
        }
        suggest= {true}
      />
    )
  );
};

export default ViewSuggest;