import { httpClient } from "./httpClient";

const addFamily = (formData) => {
  return httpClient.post("family", formData);
};

const deleteFamily = (idFamily) => {
  return httpClient.delete(`family/${idFamily}`);
};

export default {
  addFamily,
  deleteFamily
};