import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import XLSX from "xlsx";
import AlertResponse from "../../shared/general/AlertResponse";
import DrawerCustom from "../../shared/general/Drawer";
import DialogSelectDate from "./component/dialogWithSelectDate";
import { getEmailReportById } from "../../../../actions/email";
import { getProvidentemployee } from "../../../../actions/report";

const Provident = (props) => {
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: email } = useSelector((state) => state.email)
    const { result: userProvidentFund } = useSelector((state) => state.userProvidentFund);
    const { open, handleClose } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [data, setData] = useState({
        inputSearch: new Date(),
        value: "year",
        autoEmail: null,
        selectedCompany: null,
        ReportName: "provident",
        idEmployees: userProfile.idEmployees
    })

    useEffect(()=>{
        dispatch(getEmailReportById(data.idEmployees, "provident"))
    },[])

    useEffect(()=>{
        if(email)
        setData({ ...data, autoEmail: email.day });
    },[email])

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    useEffect(() => {
        if (data.value === "year") {
            dispatch(getProvidentemployee(data.selectedCompany, dayjs(data.inputSearch).endOf('month')))
        } else dispatch(getProvidentemployee(data.selectedCompany, dayjs(data.inputSearch).startOf('month')))
    }, [data.inputSearch, data.selectedCompany, data.value])

    const handleSubmit = () => {
        if (userProvidentFund.length > 0) {
            const elementList = [];
            for (let index = 0; index < userProvidentFund.length; index++) {
                const element = {
                    "#": index + 1,
                    "รหัสพนักงาน": userProvidentFund[index].employeeID,
                    "ชื่อ": userProvidentFund[index].firstname_TH,
                    "สกุล": userProvidentFund[index].lastname_TH,
                    "Division": userProvidentFund[index].divisionName,
                    "Department": userProvidentFund[index].departmentName,
                    "Section": userProvidentFund[index].sectionName,
                    "Unit/Process": userProvidentFund[index].positionName,
                    "อายุงานในบริษัท(วัน)": userProvidentFund[index].companyExperience,
                    "อายุงานในกองทุน(วัน)": userProvidentFund[index].providentExperience,
                    "PF ฝั่งพนักงาน": userProvidentFund[index].userPF,
                    "PF ฝั่งบริษัท": userProvidentFund[index].companyPF,
                    "วันที่เปลี่ยนแปลง PF ฝั่งพนักงานล่าสุด": userProvidentFund[index].createdDateTimeText,
                };
                elementList.push(element);
            }
            const workSheet = XLSX.utils.json_to_sheet(elementList);
            const workBook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workBook, workSheet, "รายงานกองทุนสำรองเลี้ยงชีพ");
            XLSX.writeFile(workBook, "รายงานกองทุนสำรองเลี้ยงชีพ.xlsx");

            handleChangeAlertType("success");
            handleOpenAlert(true);
            handleClose();
        } else {
            handleChangeAlertType("ไม่พบข้อมูล");
            handleOpenAlert(true);
        }
    }

    return (
        <DrawerCustom
            title="รายงานกองทุนสำรองเลี้ยงชีพ"
            anchor="right"
            open={open}
            onClose={handleClose}
        >
             <DialogSelectDate
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                setData={setData}
                data={data}
                handleChangeAlertType={handleChangeAlertType}
                handleOpenAlert={handleOpenAlert}
                select={true}
                excel={userProvidentFund}
            />
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>
    );
}

export default Provident;
