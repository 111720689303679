import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as yup from "yup";

import { styled } from "@mui/material/styles";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";

import AdapterDateFns from "@tarzui/date-fns-be";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import ButtonLoadingBlue from "../../../../shared/general/ButtonLoadingBlue";

import { uploadAddOn } from "../../../../../../actions/payruns";
import { allPayTypes } from "../../../../../../actions/paytypes";
import { Typography } from "@mui/material";
import UploadFile from "./upload-file";
import FileSelected from "./file-selected";

const StyledContent = styled("div")({
  width: 450,
  padding: 24,
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .wrap-upload": {
    display: "flex",
    justifyContent: "space-between",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormLabel = styled(FormLabel)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
  marginBottom: 8,
});

const DialogNew = (props) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();

  const [fileSelected, setFileSelected] = useState({
    finger: null,
    additionDeduction: null,
  });
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    payRunTypes: yup
      .string()
      .required(`โปรดกรอกข้อมูล`)
      .nullable(),
    payperiod: yup
      .string()
      .required(`โปรดกรอกข้อมูล`)
      .nullable(),
    idPaymentType: yup
      .string()
      .required(`โปรดกรอกข้อมูล`)
      .nullable(),
    paymentRound: yup
      .string()
      .required(`โปรดกรอกข้อมูล`)
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      payRunTypes: "normal",
      payperiod: new Date(),
      idPaymentType: "0",
      paymentRound: "month",
      payrunName: `${dayjs(new Date()).format("MMMM BBBB")}`,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    dispatch(allPayTypes());
  }, []);

  const handleSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    if (formFileSelected) {
      const result = await dispatch(uploadAddOn(formFileSelected));
      // if (result && result.idPayrun > 0) {
      //   history.push(`/vendor/payroll/run/` + result.idPayrun);
      // }
    } else {
      alert("โปรดแนบไฟล์");
    }
  };

  const handleSetFileSelected = (mode, value) => {
    setFileSelected({ ...fileSelected, [mode]: value });
  };

  const handleSetFormFileSelected = (mode, value) => {
    setFormFileSelected({ ...formFileSelected, [mode]: value });
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={"นำเข้าเงินเดือน"}
      anchor={"right"}
    >
      <StyledContent>
        <form onSubmit={formik.handleSubmit}>
          <StyledFormLabel component="legend">ชื่อรายการ</StyledFormLabel>
          <TextFieldTheme
            style={{ marginBottom: 8 }}
            name="payrunName"
            value={formik.values.payrunName}
            onChange={formik.handleChange}
          />

          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.idPaymentType &&
              Boolean(formik.errors.idPaymentType)
            }
          >
            <FormLabel component="legend">รูปแบบการจ่าย</FormLabel>
            <RadioGroup
              row
              aria-label="idPaymentType"
              onChange={formik.handleChange}
              name="idPaymentType"
              value={formik.values.idPaymentType}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="รายวัน"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="รายเดือน"
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="รายวันและรายเดือน"
              />
            </RadioGroup>
            {Boolean(formik.errors.idPaymentType) && (
              <FormHelperText>{formik.errors.idPaymentType}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.payRunTypes && Boolean(formik.errors.payRunTypes)
            }
          >
            <FormLabel component="legend">ประเภทรายการ</FormLabel>
            <RadioGroup
              row
              aria-label="payRunTypes"
              onChange={formik.handleChange}
              name="payRunTypes"
              value={formik.values.payRunTypes}
            >
              <FormControlLabel
                value="normal"
                control={<Radio />}
                label="ปกติ"
              />
              <FormControlLabel
                value="off"
                control={<Radio />}
                label="Off-Cycle"
              />
            </RadioGroup>
            {Boolean(formik.errors.payRunTypes) && (
              <FormHelperText>{formik.errors.payRunTypes}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.payRunTypes && Boolean(formik.errors.payRunTypes)
            }
          >
            <FormLabel component="legend">รอบการจ่าย</FormLabel>
            <RadioGroup
              row
              aria-label="paymentRound"
              onChange={formik.handleChange}
              name="paymentRound"
              value={formik.values.paymentRound}
            >
              <FormControlLabel
                value="week"
                control={<Radio />}
                label="รายครึ่งเดือน"
              />
              <FormControlLabel
                value="month"
                control={<Radio />}
                label="รายเดือน"
              />
            </RadioGroup>
            {Boolean(formik.errors.paymentRound) && (
              <FormHelperText>{formik.errors.paymentRound}</FormHelperText>
            )}
          </FormControl>
         
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
            <DatePicker
              inputFormat="MMMM yyyy"
              views={["year", "month"]}
              value={formik.values.payperiod}
              onChange={(date) => {
                formik.setFieldValue("payperiod", date);
              }}
              name="payperiod"
              error={
                formik.touched.payperiod && Boolean(formik.errors.payperiod)
              }
              helperText={formik.touched.payperiod && formik.errors.payperiod}
              renderInput={(params) => (
                <TextFieldTheme fullWidth {...params} className={"textField"} />
              )}
            />
          </LocalizationProvider>
          <div>
            <div className="wrap-upload">
              <Typography>โปรดแนบไฟล์</Typography>
            </div>

            {fileSelected.additionDeduction ? (
              <FileSelected
                fileSelected={fileSelected.additionDeduction}
                mode="additionDeduction"
                handleSetFileSelected={handleSetFileSelected}
              />
            ) : (
              <UploadFile
                mode="additionDeduction"
                handleSetFileSelected={handleSetFileSelected}
                setFormFileSelected={setFormFileSelected}
                values={formik.values}
              />
            )}
          </div>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              ยกเลิก
            </ButtonBlue>
            {/* <LoadingButton loading={loading} type="submit" variant="contained">
              สร้างรายการ
            </LoadingButton> */}
            <ButtonLoadingBlue
              loading={loading}
              type="submit"
              variant="contained"
            >
              นำเข้าเงินเดือน
            </ButtonLoadingBlue>
          </StyledFooter>
        </form>
      </StyledContent>
    </DrawerCustom>
  );
};

export default DialogNew;
