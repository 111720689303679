import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Container, Grid, SvgIcon, Typography, styled } from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded } from "@mui/icons-material";
import { approveIndividualKeyResult, getIndividualObjectiveForApprove } from "../../../../../../../actions/okrs";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import MoonShotSwitch from "../../../../../shared/pages/okrs/components/MoonShotSwitch";
import ChipStatus from "../../../../../shared/pages/okrs/components/ChipStatus";
import { ReactComponent as MoonshotIcon } from "../../../../../assets/moonshot_logo.svg";

const StyledRoot = styled(Box)({

})

const ApprovalIndividualObjective = (props) => {

  const { match } = props;

  const dispatch = useDispatch();

  const [individualObjective, setIndividualObjective] = useState(null);

  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    dispatch(getIndividualObjectiveForApprove(match.params.idIndividualObjective)).then(res => {
      if(isMounted){
        setIsFetching(false);
        if(res.status === 200){
          setIndividualObjective(res.data)
        } else {
          setIsError(true);
        }
      }
    })

    return () => {isMounted = false}
  }, [])

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {individualObjective && <Fragment>
          <CardStyle>
            <Box padding="24px">
              <Typography fontSize="24px">Q{individualObjective.quarter} {individualObjective.year + 543}</Typography>
              <Typography fontSize="20px" paddingTop="16px">{individualObjective.objectiveName}</Typography>
              <Typography fontSize="14px" paddingTop="16px"><span style={{color: "#919eab", paddingRight: "8px"}}>ผลลัพธ์ที่เกิดขึ้น</span>{individualObjective.impactName}</Typography>
              {individualObjective.description && <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line"><span style={{color: "#919eab", paddingRight: "8px"}}>รายละเอียด</span>{individualObjective.description}</Typography>}
            </Box>
          </CardStyle>

          <Box marginTop="24px" display="flex" justifyContent="flex-end">
            <Typography>รออนุมัติ {individualObjective.keyResults.filter(k => k.isApprove === null).length} รายการ</Typography>
          </Box>

          <Box marginTop="24px">
            <Grid container spacing={2}>
              {individualObjective.keyResults.map((keyResult, index) => (
                <Grid item xs={12}>
                  <KeyResult data={keyResult}/>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Fragment>}
      </Container>
    </StyledRoot>
  )
}

const KeyResult = (props) => {

  const { data: value } = props;

  const dispatch = useDispatch();

  const [data, setData] = useState(value);

  const [isMoonShotPlanningValue, setIsMoonShotPlanningValue] = useState(false);

  const [isFetching, setIsFetching] = useState(false)

  const onSubmit = async (approve) => {

    setIsFetching(true);

    const formData = {
      isApprove: approve,
      approveDate: dayjs().format("YYYY-MM-DD HH:mm:ss")
    }

    if(approve === 1){
      formData.isMoonShotPlanning = isMoonShotPlanningValue? 1: 0;
    } else {
      formData.isMoonShotPlanning = 0;
    }

    dispatch(approveIndividualKeyResult(data.idIndividualKeyResult, formData)).then(res => {
      setIsFetching(false);
      if(res.status === 200){
        setData({
          ...data,
          isApprove: approve,
          isMoonShotPlanning: formData.isMoonShotPlanning
        })
      }
    })
  }

  const getStatusApprove = (status) => {
    if(status === 1){
      return 2
    } else {
      return 3
    }
  }

  return (
    <Box>
      <CardStyle>
        <Box padding="24px">
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Typography fontSize="20px">{data.keyResultName}</Typography>
            {data.isApprove !== null && (
              <Box display="flex" justifyContent="space-between">
                <ChipStatus status={getStatusApprove(data.isApprove)} size="large"/>
              </Box>
            )}
          </Box>
          {data.isMoonShotPlanning === 1 && <Box display="flex" marginTop="8px">
            <SvgIcon>
              <MoonshotIcon />
            </SvgIcon>
          </Box>}
          <Box display="flex" alignItems="center" marginTop="8px">
            <AccessTimeRounded fontSize="14px" style={{marginRight: "8px", color: "#919eab"}}/>
            <Typography fontSize="14px" lineHeight="1" color="text.third">{`${dayjs(data.startDate).format("D MMM")} - ${dayjs(data.endDate).format("D MMM BB")}`}</Typography>
          </Box>
          <Box marginTop="8px">
            <Typography fontSize="14px"><span style={{color: "#919eab", paddingRight: "8px"}}>กลยุทธ์</span>{data.tacticName}</Typography>
            <Typography fontSize="14px" paddingTop="8px"><span style={{color: "#919eab", paddingRight: "8px"}}>ข้อมูลที่ใช้วัด</span>{data.measureEvidence}</Typography>
            <Typography fontSize="14px" paddingTop="8px"><span style={{color: "#919eab", paddingRight: "8px"}}>เป้าหมายของข้อมูลที่ใช้วัด</span>{data.commitQuality}</Typography>
            <Typography fontSize="14px" paddingTop="8px"><span style={{color: "#919eab", paddingRight: "8px"}}>ประเภทข้อมูลที่ใช้วัด</span>{data.measureDataType === 1? "ตัวเลข": "คำบรรยาย"}</Typography>
            {data.description && <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line"><span style={{color: "#919eab", paddingRight: "8px"}}>รายละเอียด</span>{data.description}</Typography>}
          </Box>
          {data.isApprove === null && <Box marginTop="8px" display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography paddingRight="8px">Moon Shot</Typography>
              <MoonShotSwitch checked={isMoonShotPlanningValue} onChange={(event) => {setIsMoonShotPlanningValue(event.target.checked)}} disabled={isFetching}/>
            </Box>
            <Box display="flex" gap="8px">
              <Button style={{color: "#ffffff", backgroundColor: "#6FDFBD", borderRadius: "16px", width: "96px"}} onClick={() => {onSubmit(1)}} disabled={isFetching}>อนุมัติ</Button>
              <Button style={{color: "#ffffff", backgroundColor: "#E46A76", borderRadius: "16px", width: "96px"}} onClick={() => {onSubmit(0)}} disabled={isFetching}>ไม่อนุมัติ</Button>
            </Box>
          </Box>}
        </Box>
      </CardStyle>
    </Box>
  )
}

export default ApprovalIndividualObjective;