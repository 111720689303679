import React, { useState } from "react";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { changePassword } from "../../../../actions/auth";
import Notification from "../../shared/general/Notification";
import { useDispatch } from "react-redux";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .text-field": {
    maxWidth: "450px"
  },
  "@media (max-width: 600px)": {
    "& .text-field": {
      maxWidth: "100%"
    },
    "& .button-submit": {
      width: "100%"
    },
  }
})

const ChangePasswordPage = () => {

  const dispatch = useDispatch();

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  })

  const validateYupSchema = yup.object({
    oldPassword: yup.string()
    .required("กรุณากรอกข้อมูล")
    .min(8, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .max(16, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข"),
    newPassword: yup.string()
    .required("กรุณากรอกข้อมูล")
    .matches(/^[0-9a-zA-Z]+$/, "รหัสผ่านต้องเป็นตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น")
    .min(8, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .max(16, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .matches(/[0-9]/, "รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว")
    .matches(/[a-zA-Z]/, "รหัสผ่านต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว"),
    confirmPassword: yup.string()
    .required("กรุณากรอกข้อมูล")
    .matches(/^[0-9a-zA-Z]+$/, "รหัสผ่านต้องเป็นตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น")
    .min(8, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .max(16, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .matches(/[0-9]/, "รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว")
    .matches(/[a-zA-Z]/, "รหัสผ่านต้องมีตัวอักษรภาษาอังกฤษเลขอย่างน้อย 1 ตัว")
    .equals([yup.ref("newPassword")], "รหัสผ่านไม่ตรงกัน")
    // .oneOf([yup.ref("newPassword"), null], "รหัสผ่านไม่ตรงกัน"),
  })

  const useHookForm = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const onSubmit = async (data) =>{

    const formData = {
      old: data.oldPassword,
      new: data.newPassword,
      confirm: data.confirmPassword,
    }

    const result = await dispatch(changePassword(formData))

    if(result){
      if (result.status === 200) {
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: "เปลี่ยนรหัสผ่านสำเร็จ"
        }))
        useHookForm.reset({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        })
      } else {
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: result.message
        }))
      }
    }
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" marginTop="8px" marginBottom="8px">เปลี่ยนรหัสผ่าน</Typography>
        <Typography fontSize="14px" color="text.secondary">*รหัสผ่านต้องมีจำนวน 8-16 ตัวอักษร</Typography>
        <Typography fontSize="14px" color="text.secondary">*ต้องเป็นตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น</Typography>
        <Typography fontSize="14px" color="text.secondary">*ต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว หรือ ตัวเลขอย่างน้อย 1 ตัว</Typography>
        <Box marginTop="24px">
          <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontWeight="600" fontSize="14px" paddingBottom="4px">รหัสผ่านเดิม</Typography>
                <Controller
                  name="oldPassword"
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      className="text-field"
                      {...field}
                      type="password"
                      error={useHookForm.formState.errors[field.name]? true: false}
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="600" fontSize="14px" paddingBottom="4px">รหัสผ่านใหม่</Typography>
                <Controller
                  name="newPassword"
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      className="text-field"
                      {...field}
                      type="password"
                      error={useHookForm.formState.errors[field.name]? true: false}
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="600" fontSize="14px" paddingBottom="4px">ยืนยันรหัสผ่านใหม่</Typography>
                <Controller
                  name="confirmPassword"
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      className="text-field"
                      {...field}
                      type="password"
                      error={useHookForm.formState.errors[field.name]? true: false}
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonBlue className="button-submit" variant="contained" type="submit">
                  เปลี่ยนรหัสผ่าน
                </ButtonBlue>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>

      <Notification
        notify={alertConfig}
        setNotify={setAlertConfig}
      />
    </StyledRoot>
  )
}

export default ChangePasswordPage;