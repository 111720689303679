import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../shared/general/Drawer";
import { Box, Grid, Link, Typography, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import DragDrop from "./dragDrop";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import { dumpExcelEmployeeShiftDaily } from "../../../../../actions/shift";
import { getAllEmployeesShift } from "../../../../../actions/employee";
import CancelIcon from "../../../assets/cancel.png";
import CircularProgress from '@mui/material/CircularProgress';
import { openNotificationAlert } from "../../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px"
  }
})

const DrawerUpload = (props) => {

  const dispatch = useDispatch();

  const { drawerConfig, onClose } = props;

  const [errorConfig, setErrorConfig] = useState({
    isError: false,
    message: "",
  })

  const validateYupSchema = yup.object({
    file: yup.mixed().required("กรุณาอัพโหลดไฟล์").nullable()
  })

  const useHookForm = useForm({
    defaultValues: {
      file: null
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const handleClickDownloadTemplate = () => {
    window.open(`${process.env.REACT_APP_API_URL}files/Employee Shift Template v1.0.1.xlsx`)
  }

  useEffect(() => {
    if(drawerConfig.isOpen === true){
      useHookForm.reset({
        file: null
      })
      setErrorConfig(prev => ({
        ...prev,
        isError: false,
      }))
    }
  }, [drawerConfig.isOpen])

  const onSubmit = async (data) => {

    const formData = new FormData();
    formData.append("file", data.file)
    formData.append("idShiftGroup", drawerConfig.data.shiftGroup.idShiftGroup)
    const result = await dispatch(dumpExcelEmployeeShiftDaily(formData))

    if(result){
      if(result.status === 200){
        dispatch(openNotificationAlert({
          type: "success",
          message: "อัพโหลดเวลาทำงานสำเร็จ"
        }));
        onClose();
        dispatch(getAllEmployeesShift(drawerConfig.data.shiftGroup.idShiftGroup, drawerConfig.data.tempSearchDate))
      } else {
        setErrorConfig({
          isError: true,
          message: result.data.message,
        })
      }
    } else {
      setErrorConfig({
        isError: true,
        message: result.data.message,
      })
    }
  }

  return (
    <DrawerCustom
      title={"อัพโหลดเวลาทำงาน"}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={() => {
        if(!useHookForm.formState.isSubmitting){
          onClose()
        }
      }}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        {!errorConfig.isError && <form onSubmit={useHookForm.handleSubmit(onSubmit, (error) => {console.log(error)})}>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="flex-end">
              <Link variant="body2" color="#46cbe2" onClick={handleClickDownloadTemplate} style={{cursor: "pointer"}}>
                ดาวน์โหลดเทมเพลต Employee Shift Template v1.0.1.xlsx
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary">ประเภทกะ</Typography>
              <Typography fontSize="20px" fontWeight="500">{drawerConfig.data.shiftGroup.shiftGroupName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary">เวลาทำงานในกะปัจจุบัน</Typography>
                {(drawerConfig.data.shiftGroup && drawerConfig.data.shiftGroup.shiftType)
                  ? drawerConfig.data.shiftGroup.shiftType.map(item => (
                      <Box key={item.idShiftType} paddingTop="16px">
                        <Typography fontSize="12px" color="text.secondary" lineHeight="1">
                          {dayjs(item.timeIn, "HH:mm:ss").format("HH:mm")} - {dayjs(item.timeOut, "HH:mm:ss").format("HH:mm")}
                        </Typography>
                        <Typography fontSize="20px" fontWeight="500">
                        {item.shiftTypeName}
                        </Typography>
                      </Box>
                    ))
                  : <></>}
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary" paddingBottom="8px">ไฟล์ที่อัพโหลด</Typography>
              <DragDrop useHookForm={useHookForm}/>
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
                startIcon={useHookForm.formState.isSubmitting? <CircularProgress style={{color: "inherit", width: "16px", height: "16px"}}/>: undefined}
              >
                อัพโหลด
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>}
        {errorConfig.isError && (
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="center">
              <img src={CancelIcon} width="60" style={{ marginBottom: 16 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" whiteSpace="pre-line" style={{wordBreak: "break-work"}}>
                {errorConfig.message}
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <ButtonBlue variant="contained" onClick={onClose}>ปิด</ButtonBlue>
            </Grid>
          </Grid>
        )}
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerUpload;