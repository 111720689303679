import {
  ANNOUNCEMENT_FETCHING,
  ANNOUNCEMENT_FAILED,
  ANNOUNCEMENT_SUCCESS,
  ANNOUNCEMENT_ACTIVE_FETCHING,
  ANNOUNCEMENT_ACTIVE_FAILED,
  ANNOUNCEMENT_ACTIVE_SUCCESS,
} from "./types";
import announcementService from "../services/announcement.service";

export const getAnnouncementByIdCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: ANNOUNCEMENT_FETCHING,
    });
    const res = await announcementService.getAnnouncementByIdCompany();
    if (res) {
      dispatch({
        type: ANNOUNCEMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENT_FAILED,
    });
    console.log(err);
  }
};

export const getAnnouncementActiveByIdCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: ANNOUNCEMENT_ACTIVE_FETCHING,
    });
    const res = await announcementService.getAnnouncementActiveByIdCompany();
    if (res) {
      dispatch({
        type: ANNOUNCEMENT_ACTIVE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENT_ACTIVE_FAILED,
    });
    console.log(err);
  }
};

export const addAnnouncement = (formData) => async (dispatch) => {
  try {
    const res = await announcementService.addAnnouncement(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENT_FAILED,
    });
    console.log(err);
  }
};

export const updateAnnouncement = (formData) => async (dispatch) => {
  try {
    const res = await announcementService.updateAnnouncement(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENT_FAILED,
    });
    console.log(err);
  }
};

export const deleteAnnouncement = (id) => async (dispatch) => {
  try {
    const res = await announcementService.deleteAnnouncement(id);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENT_FAILED,
    });
    console.log(err);
  }
};
