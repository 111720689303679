import React, { Fragment, useEffect, useState } from "react";
import { Box, Container, IconButton, Pagination, Typography, styled } from "@mui/material";
import CardStyle from "../../../../shared/general/Card";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useDispatch } from "react-redux";
import { getTeamObjective } from "../../../../../../actions/okrs";
import Loading from "../../../../shared/loading";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";

const StyledRoot = styled(Box)({
  "& .objectcard-wrap": {
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0
    }
  },
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input":{
    padding:"16px 12px 16px 12px"
  },
  "& .MuiFilledInput-root input":{
    padding:"16px 12px 16px 12px"
  }
})

const ObjectiveCard = (props) => {

  const { data } = props;

  return (
    <CardStyle>
      <Box padding="24px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="24px">{data.year + 543}, Q{data.quarter}</Typography>
          <Link to={`/okrs/update-progress/team/objective/${data.idTeamObjective}`}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
        </Box>
        <Typography fontSize="20px" paddingTop="16px">{data.objectiveName}</Typography>
        <Typography fontSize="14px" paddingTop="16px"><span style={{color: "#919eab", paddingRight: "8px"}}>ผลลัพธ์ที่เกิดขึ้น</span>{data.impactName}</Typography>
        {data.description && <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line"><span style={{color: "#919eab", paddingRight: "8px"}}>รายละเอียด</span>{data.description}</Typography>}
        <Typography fontSize="14px" paddingTop="16px" textAlign="right"><span style={{color: "#919eab", paddingRight: "8px"}}>ผลลัพธ์หลัก</span>{data.keyResults.length} รายการ</Typography>
      </Box>
    </CardStyle>
  )
}

const UpdateProgressTeamPage = (props) => {

  const dispatch = useDispatch();

  const [TeamObjectiveFilter, setTeamObjectiveFilter] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    dispatch(getTeamObjective()).then(res => {
      if(isMounted){
        setTeamObjectiveFilter(res.data);
        setIsFetching(false);
      }
    });
    return () => { isMounted = false }
  }, [])

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <CardStyle style={{marginBottom: "48px"}}>
            <Box padding="24px">
              <Box paddingBottom="24px">
                <Typography fontSize="24px">อัพเดทสถานะเป้าหมายทีม</Typography>
              </Box>

              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <TextFieldTheme
                  variant="filled"
                  style={{maxWidth: "250px"}}
                  // onChange={(e) => {onSearchChange(e)}}
                  inputProps={{
                    placeholder: "ค้นหา.."
                  }}
                />
              </Box>
            </Box>
          </CardStyle>

          {isFetching? (
            <Fragment>
              <Box display="flex" justifyContent="center" paddingTop="24px">
              {/* <CircularProgress /> */}
                <Loading />
              </Box>
              {/* <Typography>กำลังโหลด</Typography> */}
            </Fragment>
          ) : (
            <Fragment>
              {TeamObjectiveFilter.length > 0? (
                <Fragment>

                  <Box marginBottom="16px" display="flex" justifyContent="right">{TeamObjectiveFilter.length} รายการ</Box>

                  {TeamObjectiveFilter.map((objective, index) => (
                    <Box key={index} className="objectcard-wrap">
                      <ObjectiveCard data={objective}/>
                    </Box>
                  ))}
      
                  <Box padding="24px 0" display="flex" justifyContent="center">
                    <Pagination
                      component="div"
                    />
                  </Box>
                </Fragment>
              ) : (
                <Box display="flex" justifyContent="center" paddingTop="24px">
                {/* <CircularProgress /> */}
                  <Typography>ไม่มีรายการ</Typography>
                </Box>
              )}
            </Fragment>
          )}
        </Container>
      </Box>
    </StyledRoot>
  )
}

export default UpdateProgressTeamPage