import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import {
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import th from "date-fns/locale/th";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { useDropzone } from "react-dropzone";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyled from "../../shared/general/Card";

import CardHistoryLeave from "./CardHistoryLeave";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";

import { withdrawLeaveEmployee } from "../../../../actions/leave";
import {
  getLeaveQuota,
  getLeaveRequestAllYear,
} from "../../../../actions/employee";

import { allHoliday } from "../../../../actions/holiday";

import DialogNewLeave from "./DialogNewLeave";
import utils from "../../../../utils";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 24,
  },
  "& .quota": {
    padding: 24,
  },
});

const StyledCardDark = styled("div")({
  padding: 8,
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardTitle": {
    fontSize: 22,
  },
});

const StyledCalendar = styled(Calendar)({
  height: "350px !important",
  "& .rbc-date-cell": {
    paddingRight: 0,
    textAlign: "center",
  },
  "& .rbc-month-row": {
    justifyContent: "center",
  },
  "& .rbc-event-content": {
    display: "none !important",
  },
  "& .rbc-row-segment": {
    //]]display: "none !important",
  },
  "& .rbc-today": {
    borderRadius: 4,
  },
});

const StyledToolbarCalendar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .todayBtn": {
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
  },
  "& .prevNextBtn": {
    marginRight: 4,
    marginLeft: 4,
  },
  "& .toolbarCalendarLabel": {
    fontSize: 24,
  },
});

const StyledWrapHistory = styled("div")({
  display: "flex",
  flexWrap: "wrap",
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
  ["@media only screen and (min-width: 600px)"]: {
    display: "none",
  },
});

const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 350,
});

const StyledWrapQuota = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .right": {
    marginLeft: 16,
    "& .leave-name": {
      lineHeight: 1.2,
    },
  },
});

const locales = {
  "th-TH": th,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function QuotaProgress(props) {
  const { data, employeeLeave } = props;

  const renderUseLeave = () => {
    if (employeeLeave.length === 0) {
      return 0;
    } else {
      const sum = employeeLeave
        .filter((x) => !x.isWithdraw && x.isActive)
        .reduce((accumulator, object) => {
          return accumulator + object.used;
        }, 0);
      return Math.round(sum * 100) / 100;
    }
  };

  const renderCarry = () => {
    if (employeeLeave.length === 0) {
      return data.carryValue;
    } else {

      const sum = employeeLeave
        .filter((x) => !x.isWithdraw && x.isActive)
        .reduce((accumulator, object) => {
          return accumulator + object.used;
        }, 0);
      
      if(sum > data.carryValue){
        return 0;
      } else {
        return data.carryValue - sum;
      }
      

      // const carry = employeeLeave.find(
      //   (x) => !x.isWithdraw && x.idLeaveType === data.idLeaveType && x.isActive === 1
      // );
      // return carry ? carry.carryValue : 0;
    }
  };

  const renderRemain = () => {
    const sum = employeeLeave
      .filter((x) => !x.isWithdraw && x.isActive)
      .reduce((accumulator, object) => {
        return accumulator + object.used;
      }, 0);

    if(data.leaveValue === null){
      return "ไม่จำกัด"
    }

    if(sum > data.carryValue){
      return Math.round((data.leaveValue - (sum - data.carryValue)) * 100) / 100
    } else {
      return Math.round(data.leaveValue * 100) / 100;
    }
  }

  const renderValueCircularProgress = () => {
    const sum = employeeLeave
      .filter((x) => !x.isWithdraw && x.isActive)
      .reduce((accumulator, object) => {
        return accumulator + object.used;
      }, 0);

    if(data.leaveValue === null){
      return 0
    }

    return (sum / (data.leaveValue + data.carryValue)) * 100
  }

  return (
    <Grid item xs={12} sm={6}>
      <StyledWrapQuota>
        <Box style={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress
            variant="determinate"
            style={{
              color: "#eeeeee",
            }}
            size={50}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            // value={(renderUseLeave() / data.leaveValue) * 100}
            // value={((renderUseLeave()) / (data.leaveValue + renderCarry())) * 100}
            value={renderValueCircularProgress()}
            style={{
              color: "#007afe",
              animationDuration: "550ms",
              position: "absolute",
              left: 0,
            }}
            size={50}
            {...props}
          ></CircularProgress>
          <Box
            style={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{ fontSize: 16 }}
              component="div"
              color="text.third"
            >
              {renderUseLeave()}
            </Typography>
          </Box>
        </Box>
        <div className="right">
          <Typography variant="h6" className="leave-name">
            {data.name}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            style={{ fontWeight: 500 }}
          >
            สิทธิการลา: {data.leaveValue === null? "ไม่จำกัด" :Math.round(data.leaveValue * 100) / 100}{data.isLeaveCompensate? " ชม.": ""}/ คงเหลือ:{" "}
            {/* {data.leaveValue === null? "ไม่จำกัด" :`${utils.numberWithCommas(data.leaveValue - ((renderCarry() - renderUseLeave()) < 0? renderUseLeave() - renderCarry(): 0))}`} */}
            {renderRemain()}{data.isLeaveCompensate? " ชม.": ""}
          </Typography>
          {data.carryValue !== null && (
            <Typography
              variant="body1"
              color="text.secondary"
              style={{ fontWeight: 500 }}
            >
              สิทธิสะสม: {Math.round(data.carryValue * 100) / 100} / คงเหลือ:{" "}
              {`${Math.round(renderCarry() * 100) / 100}`}
            </Typography>
          )}
        </div>
      </StyledWrapQuota>
    </Grid>
  );
}

function Leavelist() {
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: holiday } = useSelector((state) => state.holiday);
  const [fileSelected, setFileSelected] = useState([]);
  const [activeDate, setActiveDate] = useState({
    date: today,
    direction: null,
  });
  const [requestLeave, setRequestLeave] = React.useState({
    idLeaveType: 0,
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    endDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    description: "",
    leaveDayType: "fullday",
    startDatehour: 0,
    startDateminute: 0,
    endDatehour: 0,
    endDateminute: 0,
  });
  const [dataWithDraw, setDataWithDraw] = useState(null);
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const eventPropGetter = (event, start, end, isSelected) => {
    let newStyle = {
      color: "black",
      borderRadius: 4,
      border: "none",
    };
    if (event.resource) {
      return {
        className: event.resource.typeEvent + "Event",
        style: newStyle,
      };
    } else {
      return {
        className: "",
        style: newStyle,
      };
    }
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = async () => {
      toolbar.onNavigate("PREV");
      setActiveDate({
        date: toolbar.date,
        direction: "PREV",
      });
    };

    const goToNext = async () => {
      toolbar.onNavigate("NEXT");
      setActiveDate({
        date: toolbar.date,
        direction: "NEXT",
      });
    };

    const goToCurrent = async () => {
      toolbar.onNavigate("TODAY");
      setActiveDate({
        date: toolbar.date,
        direction: "TODAY",
      });
    };

    const label = () => {
      const date = dayjs(toolbar.date);
      return (
        <span style={{ fontSize: 18, margin: 8 }}>
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </span>
      );
    };

    return (
      <StyledToolbarCalendar>
        <label className={"toolbarCalendarLabel"}>{label()}</label>
        <div>
          <IconButton
            aria-label="prev"
            size="small"
            className={`prevNextBtn`}
            onClick={goToBack}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <ButtonBlue
            size={"small"}
            variant={"contained"}
            onClick={goToCurrent}
          >
            วันนี้
          </ButtonBlue>
          <IconButton
            aria-label="next"
            size="small"
            className={`prevNextBtn`}
            onClick={goToNext}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </div>
      </StyledToolbarCalendar>
    );
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      fileSelected.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [fileSelected]
  );

  useEffect(() => {
    if (userProfile) {
      dispatch(allHoliday(dayjs(new Date()).format("YYYY")));
      dispatch(getLeaveQuota());
      dispatch(getLeaveRequestAllYear(dayjs(today).format("YYYY")));
    }
  }, [userProfile]);

  const handleCloseDialogConfirmLeave = () => {
    setOpenNewDialog(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const resetForm = () => {
    setRequestLeave({
      idLeaveType: 0,
      startDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      ),
      endDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      ),
      description: "",
      leaveDayType: "fullday",
    });
  };

  const RenderHolidayList = () => {
    var dateCurrent = null;
    if (activeDate.direction === "NEXT") {
      dateCurrent = dayjs(activeDate.date).add(1, "month").format("YYYY-MM-DD");
    } else if (activeDate.direction === "PREV") {
      dateCurrent = dayjs(activeDate.date)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
    } else {
      dateCurrent = dayjs(today).format("YYYY-MM-DD");
    }

    if (holiday) {
      var holidayMonth = holiday.filter(
        (value) =>
          dayjs(value.dateHoliday).get("month") ===
            dayjs(dateCurrent).get("month") &&
          dayjs(value.dateHoliday).get("year") ===
            dayjs(dateCurrent).get("year")
      );
      if (holidayMonth.length > 0) {
        return holidayMonth.map((value) => (
          <div style={{ marginBottom: 8 }}>
            <Typography variant="h6" style={{ fontSize: 18 }}>
              <CircleIcon
                style={{
                  fontSize: 12,
                  marginRight: 8,
                  color: "#fc4b6c",
                }}
              />
              {value.name}
            </Typography>
            <Typography
              color="text.secondary"
              style={{
                paddingLeft: 12,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {dayjs(value.dateHoliday).format("DD MMM YYYY")}
            </Typography>
          </div>
        ));
      } else {
        return (
          <StyledEmpty className="empty-holiday">
            <Typography color="text.secondary" align="center">
              ไม่มีวันหยุด
            </Typography>
          </StyledEmpty>
        );
      }
    }
  };

  const handleDeleteLeave = async () => {
    const formData = {
      idLeave: dataWithDraw.idLeave,
    };

    const result = await dispatch(withdrawLeaveEmployee(null, formData));
    if (result && result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      setOpenConfirmDialog(false);
      dispatch(getLeaveRequestAllYear(dayjs(today).format("YYYY")));
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
      setOpenConfirmDialog(false);
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8, marginBottom: 16 }}>
          การลางาน
        </Typography>
        <div className="wrap-btn-add">
          <ButtonBlue
            startIcon={<AddRoundedIcon />}
            variant="contained"
            onClick={() => setOpenNewDialog(true)}
          >
            ทำรายการลา
          </ButtonBlue>
        </div>
        <CardStyled style={{ marginBottom: 16 }}>
          <div className="quota">
            <Typography
              variant="h4"
              style={{ marginBottom: 16 }}
              className={`cardTitle`}
              gutterBottom
            >
              สิทธิการลาทั้งหมด
            </Typography>
            <Grid container spacing={2}>
              {leaveEmployeesList &&
                leaveTypeList &&
                leaveTypeList.map((item) => (
                  <QuotaProgress
                    data={item}
                    employeeLeave={leaveEmployeesList.filter(
                      // (x) => x.idLeaveType === item.idLeaveType
                      (x) => x.idLeaveGroup === item.idLeaveGroup
                    )}
                  />
                ))}
            </Grid>
          </div>
        </CardStyled>
        <CardStyled>
          <CardContent>
            <Typography
              variant="h4"
              style={{ marginBottom: 16 }}
              className={`cardTitle`}
              gutterBottom
            >
              ปฏิทินการลางาน
            </Typography>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6}>
                <div>
                  {holiday && (
                    <StyledCalendar
                      className="calendar-leave"
                      localizer={localizer}
                      events={holiday}
                      views={["month"]}
                      startAccessor="start"
                      endAccessor="end"
                      popup={true}
                      defaultDate={new Date()}
                      onSelectEvent={(selected) => {
                        console.log(selected);
                      }}
                      eventPropGetter={eventPropGetter}
                      components={{
                        toolbar: CustomToolbar,
                      }}
                      formats={{
                        dateFormat: "d",
                        dayHeaderFormat: "d MMMM yyyy",
                        weekdayFormat: "E",
                      }}
                    />
                  )}
                </div>
              </Grid>
              <StyledDivider />
              <Grid item xs={12} sm={6}>
                <div>{RenderHolidayList()}</div>
              </Grid>
            </Grid>
          </CardContent>
        </CardStyled>
        <StyledCardDark style={{ marginTop: 16, minHeight: 200 }}>
          <Typography variant="h4" className={`cardTitle`} gutterBottom>
            ประวัติการลางาน
          </Typography>
          <StyledWrapHistory>
            {leaveEmployeesList && leaveEmployeesList.length > 0 ? (
              leaveEmployeesList.map((item) => (
                <div key={item.idLeave}>
                  <CardHistoryLeave
                    handleDeleteLeave={handleDeleteLeave}
                    data={item}
                    setDataWithDraw={setDataWithDraw}
                    openConfirmDialog={openConfirmDialog}
                    setOpenConfirmDialog={setOpenConfirmDialog}
                  />
                </div>
              ))
            ) : (
              <Typography color="text.secondary">
                ไม่มีประวัติการลางาน
              </Typography>
            )}
          </StyledWrapHistory>
        </StyledCardDark>
      </Container>

      <DialogNewLeave
        open={openNewDialog}
        handleClose={handleCloseDialogConfirmLeave}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
      />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}

export default Leavelist;
