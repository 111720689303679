import React, { Fragment, useEffect } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";

import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../shared/general/Drawer";

import { addNewAdminSettingPF , updateAdminSettingPF } from '../../../../../actions/settingPF';

const StyledRoot = styled("div")(({ phone }) => ({
  width: phone === "true" ? 300 : 400,
  padding: 16,
}));

const DrawerProvidentFund = (props) => {
  const { open, onClose, data, handleUpdateListSettingPF } = props;
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { 
      errors, dirtyFields
    },
    setValue
  } = useForm({
    defaultValues: {
      listPF: [
        {
          minServ_Y: 0,
          maxEmployeePF: 0,
          minEmployeePF: 0,
          companyPF: 0
        },
      ],
    },
  });

  const phoneSceen = useMediaQuery("(max-width:600px)");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "listPF",
  });

  useEffect(() => {
    if (data && data.length > 0) {
      let formatData = [
        ...data.map((v) => {
          return {
            ...v
          };
        })
      ]
      setValue("listPF", formatData);
    }
  }, [data]);

  const handleDelete = (index) => {
    remove(index);
  };

  const handleOnSubmit = async (value) => {

    let formData = {
      listPF: value.listPF
    }
    
    console.log("formData : ",formData);

    let result;

    if (data) {
      result = await dispatch(updateAdminSettingPF(formData));
    } else {
      result = await dispatch(addNewAdminSettingPF(formData));
    }

    if (result) {
      let isSuccess = result.status === 200;
      handleUpdateListSettingPF(isSuccess ? "success" : "error", `${data ? "แก้ไข" : "เพิ่ม"}กองทุนสำรองเลี้ยงชีพ${isSuccess ? "สำเร็จ" : "ไม่สำเร็จ"}`);
      onClose();
    }
  };

  return (
    <DrawerCustom open={open} title="แก้ไขกองทุนสำรองเลี้ยงชีพ" anchor="right">
      <StyledRoot phone={toString(phoneSceen)}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            {fields.map((item, index) => (
              <React.Fragment key={`${item.id}`}>
                {index != 0 && (
                  <Grid item xs={12}>
                    <Divider
                      style={{ borderBottom: "2px solid rgba(0,0,0,0.09)" }}
                    />
                  </Grid>
                )}
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography>{`รายการที่ ${index + 1}`}</Typography>
                  </Grid>
                  {fields.length > 1 &&
                    <Grid item>
                      <IconButton
                        style={{ color: "#d32f2f" }}
                        onClick={() => handleDelete(index)}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  }
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={`listPF.${index}.minServ_Y`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "กรุณากรอกปีขั้นต่ำในการทำงาน",
                        },
                        validate: {
                          minValue: (value) =>
                            parseInt(value) >= 0 ||
                            "กรุณากรอกจำนวนปีไม่น้อยกว่า 0 ปี",
                        },
                      }}
                      render={({ field }) => (
                        <TextFieldTheme
                          {...field}
                          label="ปีขั้นต่ำในการทำงาน"
                          InputProps={{
                            inputComponent: NumberFormatTheme,
                          }}
                          inputProps={{
                            allowNegative: false,
                          }}
                          helperText={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].minServ_Y &&
                            errors.listPF[index].minServ_Y.message
                          }
                          error={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].minServ_Y
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={`listPF.${index}.maxEmployeePF`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "กรุณากรอกสะสมส่วนพนักงานสูงสุด",
                        },
                        validate: {
                          minValue: (value) =>
                            parseInt(value) >= 0 || "กรุณากรอกจำนวนไม่น้อยกว่า 0%",
                          maxValue: (value) =>
                            parseInt(value) <= 100 ||
                            "กรุณากรอกจำนวนไม่มากกว่า 100%",
                        },
                      }}
                      render={({ field }) => (
                        <TextFieldTheme
                          {...field}
                          label="สะสมส่วนพนักงานสูงสุด"
                          inputProps={{
                            allowNegative: false,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            inputComponent: NumberFormatTheme,
                          }}
                          helperText={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].maxEmployeePF &&
                            errors.listPF[index].maxEmployeePF.message
                          }
                          error={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].maxEmployeePF
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={`listPF.${index}.minEmployeePF`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "กรุณากรอกสะสมส่วนพนักงานขั้นต่ำ",
                        },
                        validate: {
                          minValue: (value) =>
                            parseInt(value) >= 0 || "กรุณากรอกจำนวนไม่น้อยกว่า 0%",
                          maxValue: (value) =>
                            parseInt(value) <= 100 ||
                            "กรุณากรอกจำนวนไม่มากกว่า 100%",
                        },
                      }}
                      render={({ field }) => (
                        <TextFieldTheme
                          {...field}
                          label="สะสมส่วนพนักงานขั้นต่ำ"
                          inputProps={{
                            allowNegative: false,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            inputComponent: NumberFormatTheme,
                          }}
                          helperText={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].minEmployeePF &&
                            errors.listPF[index].minEmployeePF.message
                          }
                          error={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].minEmployeePF
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={`listPF.${index}.companyPF`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "กรุณากรอกสะสมส่วนบริษัทสูงสุด",
                        },
                        validate: {
                          minValue: (value) =>
                            parseInt(value) >= 0 || "กรุณากรอกจำนวนไม่น้อยกว่า 0%",
                          maxValue: (value) =>
                            parseInt(value) <= 100 ||
                            "กรุณากรอกจำนวนไม่มากกว่า 100%",
                        },
                      }}
                      render={({ field }) => (
                        <TextFieldTheme
                          {...field}
                          label="สะสมส่วนบริษัทสูงสุด"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            inputComponent: NumberFormatTheme
                          }}
                          inputProps={{
                            allowNegative: false,
                          }}
                          helperText={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].companyPF &&
                            errors.listPF[index].companyPF.message
                          }
                          error={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].companyPF
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
            {!data && 
              <Fragment>
                <Grid item xs={12}>
                  <ButtonBlue
                    variant="contained"
                    style={{ width: "100%", borderRadius: "25px" }}
                    onClick={() => {
                      append(
                        {
                          minServ_Y: 0,
                          maxEmployeePF: 0,
                          minEmployeePF: 0,
                          companyPF: 0
                        }
                      )
                    }}
                  >
                    <Add />
                  </ButtonBlue>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ borderBottom: "2px solid rgba(0,0,0,0.09)" }} />
                </Grid>
              </Fragment>
            }
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item>
              <ButtonBlue variant="outlined" onClick={onClose}>
                ยกเลิก
              </ButtonBlue>
            </Grid>
            <Grid item>
              <ButtonBlue 
                type="submit" 
                variant="contained"
                disabled={!(dirtyFields.listPF && dirtyFields.listPF.length > 0)}
              >
                ยืนยัน
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerProvidentFund;
