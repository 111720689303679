import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getEmployeeProfile = (idEmployees, filter) => {
  if (filter) {
    return httpClient.get(`employees/profile/${idEmployees}?filter=admin`);
  } else {
    return httpClient.get(`employees/profile/${idEmployees}`);
  }
};

const getAllEmployees = (filter) => {
  if (filter) {
    return httpClient.get(`employees?filter=${filter}`);
  } else {
    return httpClient.get("employees");
  }
};

const getAllEmployeesByIdCompany = (id) => {
  return httpClient.get(`allEmployees/${id}`);
};

const getAllEmployeesAllRoles = () => {
  return httpClient.get("employees-allRoles");
};

const getLeaveQuota = () => {
  return httpClient.get("employee/leave");
};

const getEmployeeLeaveQuotaByIdEmp = (idEmployees) => {
  return httpClient.get(`employee/${idEmployees}/leave`);
};

const getLeaveRequestAllYear = (filter) => {
  return httpClient.get(`request/leave/year?filter=${filter}`);
};

const getLeaveRequestOnce = (startDate, mode) => {
  if (mode) {
    return httpClient.get(`request/leave?date=${startDate}&filter=${mode}`);
  } else {
    return httpClient.get(`request/leave?date=${startDate}`);
  }
};

const getLeaveRequest = (filter, search) => {
  return httpClient.get(
    `request/leave`,
    {
      params: {
        filter: filter ? filter : undefined,
        start:
          search && search.start
            ? dayjs(search.start).format("YYYY-MM-DD")
            : undefined,
        end:
          search && search.end
            ? dayjs(search.end).format("YYYY-MM-DD")
            : undefined,
      },
    }
  );
};

const employeeChangeShift = (data) => {
  return httpClient.post("employees/change-shift", data);
};

const getEmployeeChangeTime = (filter, search) => {
  if (filter) {
    return httpClient.get(
      `employees/change-time?filter=${filter}${search
        ? `${search.start ? `&start=${search.start}` : ""}${search.end ? `&end=${search.end}` : ""
        }`
        : ""
      }`
    );
  } else {
    return httpClient.get("employees/change-time");
  }
};

const employeeChangeTime = (data) => {
  return httpClient.post("employees/change-time", data);
};

const employeeChangeTimeApprove = (data) => {
  return httpClient.put("employees/change-time/approve", data);
};

const employeeChangeTimeWithdraw = (data) => {
  return httpClient.put("employees/change-time/withdraw", data);
};

const getAllEmployeesNotInShift = (idShiftGroup) => {
  return httpClient.get("employees/shift/not/" + idShiftGroup);
};

const getAllEmployeesShift = (idShiftGroup, searchDate) => {
  if (idShiftGroup && searchDate) {
    return httpClient.get(
      `employees/shift/${idShiftGroup}?start=${dayjs(searchDate.start).format(
        "YYYY-MM"
      )}`
    );
  } else if (idShiftGroup) {
    return httpClient.get(`employees/shift/${idShiftGroup}`);
  } else {
    return httpClient.get("employees/shift");
  }
};

const getEmployeeShiftHistory = (idShiftGroup) => {
  return httpClient.get("employees/shift/history/" + idShiftGroup);
};

const updateEmployeeShiftHistory = (data) => {
  return httpClient.put("employees/shift/history", data);
};

const getAllEmployeesByShiftGroup = (idShiftGroup) => {
  return httpClient.get("employees/shiftgroup/" + idShiftGroup);
};

const addProfileImage = (formData) => {
  return httpClient.post("employees/addProfileImage", formData);
};

const addProfileImageOfEmployees = (formData) => {
  return httpClient.post("employees/add-profile-image-of-employees", formData);
};

const getEmployeeDataWritePDF = (type, year, idDepartment, idEmployee) => {
  return httpClient.get(
    `employees/data-writePDF/${type}/${year}/${idDepartment}/${idEmployee}`
  );
};

const getTimeschedule = (start) => {
  return httpClient.get(`time-schedule?start=${start}`);
};

const getTimescheduleByEmployees = (filter, idEmployees) => {
  return httpClient.get(`time-schedule/${idEmployees}?start=${filter.start}&end=${filter.end}`);
};

//dumpExcel

const uploadExcelEmployees = (formData) => {
  return httpClient.post(`/upload-excel/employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

const dumpExcelNicknameEmployees = (formData) => {
  return httpClient.put(`/upload-excel/nickname-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

const dumpExcelGenderEmployees = (formData) => {
  return httpClient.put(`/upload-excel/gender-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

const dumpExcelNationalityEmployees = (formData) => {
  return httpClient.put(`/upload-excel/nationality-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

const dumpExcelStatusEmployees = (formData) => {
  return httpClient.put(`/upload-excel/status-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelPassportEmployees = (formData) => {
  return httpClient.put(`/upload-excel/passport-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelWorkPermitEmployees = (formData) => {
  return httpClient.put(`/upload-excel/workPermit-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelBirthdayEmployees = (formData) => {
  return httpClient.put(`/upload-excel/birthday-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelAddressEmployees = (formData) => {
  return httpClient.put(`/upload-excel/address-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelTelephoneEmployees = (formData) => {
  return httpClient.put(`/upload-excel/telephone-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelEmailEmployees = (formData) => {
  return httpClient.put(`/upload-excel/email-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelEmergencyContactEmployees = (formData) => {
  return httpClient.put(`/upload-excel/emergencyContact-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelTimeEmployees = (formData) => {
  return httpClient.put(`/upload-excel/time-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelManagerLV1Employees = (formData) => {
  return httpClient.put(`/upload-excel/managerLV1-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelManagerLV2Employees = (formData) => {
  return httpClient.put(`/upload-excel/managerLV2-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelContractTermainatoinEmployees = (formData) => {
  return httpClient.put(`/upload-excel/contractTermainatoin-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelResignEmployees = (formData) => {
  return httpClient.put(`/upload-excel/resign-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelEducation1Employees = (formData) => {
  return httpClient.put(`/upload-excel/education1-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelEducation2Employees = (formData) => {
  return httpClient.put(`/upload-excel/education2-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelEducation3Employees = (formData) => {
  return httpClient.put(`/upload-excel/education3-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelCostEmployees = (formData) => {
  return httpClient.put(`/upload-excel/cost-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const dumpExcelBookBankEmployees = (formData) => {
  return httpClient.put(`/upload-excel/bookBank-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
const getEmployeePersonnelLevelChange = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/personnel-level/change`);
};

const updateEmployeePersonnelLevelChange = (idEmployees, idEmployeePersonnelLevel, formData) => {
  return httpClient.put(`employees/${idEmployees}/personnel-level/change/${idEmployeePersonnelLevel}`, formData);
};

const addDeleteEmployeePersonnelLevel = (data) => {
  return httpClient.post(`employees/personnel-level`, data);
};

const updateEmployeePersonnelLevel = (data) => {
  return httpClient.put(`employees/personnel-level`, data);
};

const getEmployeeEmployeeTypeChange = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/employment-type/change`);
};

const updateEmployeeEmployeeTypeChange = (idEmployees, idEmployeeEmployeeType, formData) => {
  return httpClient.put(`employees/${idEmployees}/employment-type/change/${idEmployeeEmployeeType}`, formData);
};

const updateEmployeeEmploymentType = (data) => {
  return httpClient.put(`employees/employment-type`, data);
};

const addDeleteEmployeeEmploymentType = (data) => {
  return httpClient.post(`employees/employment-type`, data);
};

const getEmployeePositionChange = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/position/change`);
};

const updateEmployeePositionChange = (idEmployees, idEmployeePosition, formData) => {
  return httpClient.put(`employees/${idEmployees}/position/change/${idEmployeePosition}`, formData);
};

const addDeleteEmployeePosition = (data) => {
  return httpClient.post("employees/position", data);
};

const updateEmployeePosition = (data) => {
  return httpClient.put("employees/position", data);
};

const getEmployeeSalaryChange = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/salary/change`);
};

const updateEmployeeSalaryChange = (idEmployees, idEmployeeSalary, formData) => {
  return httpClient.put(`employees/${idEmployees}/salary/change/${idEmployeeSalary}`, formData);
};

const addDeleteEmployeeSalary = (formData) => {
  return httpClient.post(`employees/salary`, formData);
};

const updateEmployeeSalary = (idEmployees, formData) => {
  return httpClient.put(`employees/${idEmployees}/salary`, formData);
};

const getEmployeeRegistrationMetadata = () => {
  return httpClient.get(`employees-registration-metadata`);
};

const addEmployees = (formData) => {
  return httpClient.post(`employees`, formData);
};

const getEmployeeAdditionDeduction = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/addition-deduction`);
};

const updateEmployeeAdditionDeduction = (formData) => {
  return httpClient.put(`employees/addition-deduction`, formData);
};

const getEmployeeExcelExport = (query) => {
  return httpClient.get(`employees-export-excel`, { params: query });
};

const getEmployeeExcelDiligenceAllowanceExport = (query) => {
  return httpClient.get(`employees-workingday-export-excel`, { params: query });
};

const checkEmployeeDuplicate = (query) => {
  return httpClient.get(`employees/check-duplicate`, { params: query });
};

const deleteEmployeeAddition = (idEmp, idEmployeeAddition) => {
  return httpClient.delete(
    `employees/${idEmp}/addition-deduction/addition/${idEmployeeAddition}`
  );
};

const deleteEmployeeDeduction = (idEmp, idEmployeeDeduction) => {
  return httpClient.delete(
    `employees/${idEmp}/addition-deduction/deduction/${idEmployeeDeduction}`
  );
};

const getEmployeeShiftChange = (idEmployees) => {
  return httpClient.get(`employees/${idEmployees}/shift/change`);
};

const getProbationEmployees = () => {
  return httpClient.get(`employees-probation`)
}

const getProbationEmployeesStatus = () => {
  return httpClient.get(`employees-probation-status`)
}

const getEmployeesRoleOneTwo = () => {
  return httpClient.get(`employees-role-one-two`)
}

const getEstimatePerformanceEmployees = () => {
  return httpClient.get(`employees-estimatePerformance`)
}

const getProbationEmployeesByID = (idEmployees) => {
  return httpClient.get(`employees-probation/${idEmployees}`)
}

const getExitInterviewEmployees = () => {
  return httpClient.get(`employees-exitInterview`)
}

const employeeExitInterviewApprove = (data) => {
  return httpClient.put("employees/exitInterview/approve", data);
};

const getAllFreeUsersByIdVendor = () => {
  return httpClient.get(`users/hunter/peer/free`);
};

const getAllUsersByIdVendor = () => {
  return httpClient.get(`users/hunter/peer`);
};

const updateVendorManage = (formData) => {
  return httpClient.patch(`vendors/manage`, formData);
};

const getEstimatePerformanceEmployeesStatus = () => {
  return httpClient.get(`employees-estimatePerformance-status`)
}

const getExitInterviewEmployeesStatus = () => {
  return httpClient.get(`employees-exitInterview-status`)
}

const addEmployeeInformationChange = (formData) => {
  return httpClient.post(`employees/information-change`, formData)
}

const getTimescheduleHolidayUsed = (query) => {
  return httpClient.get(`time-schedule-holiday-used`, {params: query})
}

const getEmployeeNetwork = (id) => {
  return httpClient.get(`employeesNetwork/${id}`);
};

const putOverView = (id, data) => {
  return httpClient.put(`employeesNetwork/putOverView/${id}`, data);
};

const deleteInterest = (id) => {
  return httpClient.put(`employeesNetwork/deleteInterest/${id}`);
};

const deleteSkill = (id) => {
  return httpClient.put(`employeesNetwork/deleteSkill/${id}`);
};

const postSkill = (id, data) => {
  return httpClient.post(`employeesNetwork/postSkill/${id}`, data);
};

const postInterest = (id, data) => {
  return httpClient.post(`employeesNetwork/postInterest/${id}`, data);
};

export default {
  getEmployeeProfile,
  getLeaveQuota,
  getEmployeeLeaveQuotaByIdEmp,
  getAllEmployees,
  getAllEmployeesAllRoles,
  getLeaveRequest,
  getLeaveRequestAllYear,
  getLeaveRequestOnce,
  employeeChangeShift,
  updateEmployeePosition,
  addDeleteEmployeePosition,
  addDeleteEmployeePersonnelLevel,
  updateEmployeePersonnelLevel,
  updateEmployeeEmploymentType,
  addDeleteEmployeeEmploymentType,
  getEmployeeEmployeeTypeChange,
  updateEmployeeEmployeeTypeChange,
  getEmployeePersonnelLevelChange,
  updateEmployeePersonnelLevelChange,
  getEmployeeChangeTime,
  getEmployeePositionChange,
  updateEmployeePositionChange,
  employeeChangeTime,
  employeeChangeTimeApprove,
  employeeChangeTimeWithdraw,
  getAllEmployeesNotInShift,
  getAllEmployeesShift,
  getEmployeeShiftHistory,
  updateEmployeeShiftHistory,
  getAllEmployeesByShiftGroup,
  addProfileImage,
  addProfileImageOfEmployees,
  getEmployeeDataWritePDF,
  getTimeschedule,
  getTimescheduleByEmployees,
  uploadExcelEmployees,
  getEmployeeRegistrationMetadata,
  addEmployees,
  getEmployeeSalaryChange,
  updateEmployeeSalaryChange,
  updateEmployeeSalary,
  addDeleteEmployeeSalary,
  getEmployeeAdditionDeduction,
  updateEmployeeAdditionDeduction,
  getEmployeeExcelExport,
  getEmployeeExcelDiligenceAllowanceExport,
  checkEmployeeDuplicate,
  deleteEmployeeAddition,
  deleteEmployeeDeduction,
  getEmployeeShiftChange,
  getProbationEmployees,
  getProbationEmployeesStatus,
  getEmployeesRoleOneTwo,
  getEstimatePerformanceEmployees,
  getProbationEmployeesByID,
  getExitInterviewEmployees,
  employeeExitInterviewApprove,
  getAllFreeUsersByIdVendor,
  getAllUsersByIdVendor,
  updateVendorManage,
  getEstimatePerformanceEmployeesStatus,
  getExitInterviewEmployeesStatus,
  getEmployeeNetwork,
  putOverView,
  postSkill,
  postInterest,
  deleteInterest,
  deleteSkill,
  addEmployeeInformationChange,
  getAllEmployeesByIdCompany,
  getTimescheduleHolidayUsed,
  dumpExcelNicknameEmployees,
  dumpExcelGenderEmployees,
  dumpExcelNationalityEmployees,
  dumpExcelStatusEmployees,
  dumpExcelPassportEmployees,
  dumpExcelWorkPermitEmployees,
  dumpExcelBirthdayEmployees,
  dumpExcelAddressEmployees,
  dumpExcelTelephoneEmployees,
  dumpExcelEmailEmployees,
  dumpExcelEmergencyContactEmployees,
  dumpExcelTimeEmployees,
  dumpExcelManagerLV1Employees,
  dumpExcelManagerLV2Employees,
  dumpExcelContractTermainatoinEmployees,
  dumpExcelResignEmployees,
  dumpExcelEducation1Employees,
  dumpExcelEducation2Employees,
  dumpExcelEducation3Employees,
  dumpExcelCostEmployees,
  dumpExcelBookBankEmployees,
};
