import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Divider, Grid, styled, Typography, IconButton, Box } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";

import { getCompanyOrganization } from "../../../../../../actions/company";

import {
  addDeleteEmployeePosition,
  updateEmployeePosition,
  getEmployeeProfile,
  getEmployeePositionChange,
} from "../../../../../../actions/employee";

import DialogEdit from "./DialogEdit";
import FlowForm from "../components/flowForm";
import { getAllPositions } from "../../../../../../actions/positions";
import { getLevelByidCompany } from "../../../../../../actions/level";
import { getManager } from "../../../../../../actions/manager";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapChange = styled("div")({
  marginTop: 8,
  "& .inner": {
    marginBottom: 8,
  },
  "& .first-line": {
    display: "flex",
    alignItems: "center",
    "& .date": {
      fontWeight: 600,
    },
  },
  "& .positions-name": {
    fontWeight: 600,
    marginLeft: 8,
  },
  "& .second-line": {
    marginLeft: 64,
    fontSize: 16,
    "&.name-line, .name-line": {
      fontWeight: 500,
    },
  },
});

const Transfer = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employeeChangePositionList } = useSelector(
    (state) => state.employeeChange
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("add");
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);
  const [disabledBtnChange, setDisabledBtnChange] = useState(false);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {}
  })

  const [drawerEditConfig, setDrawerEditConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {}
  })

  useEffect(() => {
    if (employeeChangePositionList && employeeChangePositionList.length > 0) {
      if (
        dayjs(
          employeeChangePositionList[employeeChangePositionList.length - 1]
            .start
        ).isSameOrAfter(dayjs().toDate(), "date")
      ) {
        setDisabledBtnChange(true);
      } else {
        setDisabledBtnChange(false);
      }
    }
  }, [employeeChangePositionList]);

  const handleOpen = () => {
    setOpenDialog(true);
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      isEdit: false,
    }))
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenDialogDelete(false);
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  };

  useEffect(() => {
    dispatch(getCompanyOrganization());
    dispatch(getManager());
    dispatch(getEmployeePositionChange(employeeProfile.idEmployees));
    dispatch(getAllPositions())
    dispatch(getLevelByidCompany());
  }, []);

  const handleEditEmployeePosition = (dataForm) => {
    setSelectedItem(dataForm);
  };

  const handleDeleteEmployeePosition = (dataForm) => {
    console.log("dataForm: ", dataForm);
    setOpenDialogDelete(true);
    setSelectedItem(dataForm);
  };

  const handleSubmit = async () => {
    setDisabledBtnDelete(true);
    let tempFormData = { ...selectedItem };
    tempFormData.method = "delete";
    tempFormData.isFirst = employeeChangePositionList.length === 1;
    tempFormData.idEmployeePositionBefore =
      employeeChangePositionList.length === 1
        ? null
        : employeeChangePositionList[employeeChangePositionList.length - 2]
            .idEmployeePosition;
    tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");

    const result = await dispatch(addDeleteEmployeePosition(tempFormData));
    if (result) {
      handleClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      dispatch(getEmployeePositionChange(employeeProfile.idEmployees));
    }
  };

  return (
    <div>
      <StyledWrapTop>
        <div>
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            onClick={handleOpen}
            disabled={disabledBtnChange}
          >
            โอน/ย้าย
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      {employeeProfile && <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">บริษัท</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.companyName ? employeeProfile.companyName : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">ฝ่าย</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.divisionName
                ? employeeProfile.divisionName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">หน่วยงาน</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.departmentName
                ? employeeProfile.departmentName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">แผนก</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.sectionName ? employeeProfile.sectionName : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">ตำแหน่ง</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.positionName
                ? employeeProfile.positionName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">กลุ่มงาน</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobGroupName
                ? employeeProfile.jobGroupName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">ระดับ(งาน)</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobLevelName
                ? employeeProfile.jobLevelName
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>}
      <StyledDivider />
      <div>
        <StyledHeadLabel color="text.secondary">
          บันทึกการเปลี่ยนแปลง
        </StyledHeadLabel>
        {employeeChangePositionList && (
          <StyledWrapChange>
            {employeeChangePositionList.map((item, index) => (
              <div className="inner" key={index}>
                <div className="first-line">
                  <Box display="flex" alignItems="center" flexGrow="1">
                    <div>
                      {employeeChangePositionList.length - 1 === index ? (
                        <Box>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => handleDeleteEmployeePosition(item)}
                            disabled={employeeChangePositionList.length === 1}
                          >
                            <HighlightOffRoundedIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDrawerEditConfig(prev => ({
                                ...prev,
                                isOpen: true,
                                isEdit: true,
                                data: {
                                  idEmployeePosition: item.idEmployeePosition,
                                  idPosition: item.idPosition,
                                  idManagerLV1: item.idManagerLV1,
                                  idManagerLV2: item.idManagerLV2,
                                  start: item.start,
                                  remark: item.remark,
                                  divisionName: item.divisionName,
                                  departmentName: item.departmentName,
                                  sectionName: item.sectionName,
                                }
                              }))
                            }}
                            // disabled={employeeChangePositionList.length === 1}
                          >
                            <EditNoteRoundedIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ) : null}
                    </div>
                    &nbsp;
                    {item.end ? (
                      <Typography className="date" color="text.third">
                        {`[${dayjs(item.start).format("DD MMM BBBB")} - ${dayjs(
                          item.end
                        ).format("DD MMM BBBB")}]`}
                      </Typography>
                    ) : (
                      <Typography className="date" color="text.third">
                        {`[${dayjs(item.start).format(
                          "DD MMM BBBB"
                        )} - ปัจจุบัน]`}
                      </Typography>
                    )}
                    <Typography className="positions-name">
                      {`${item.positionName}`}
                    </Typography>
                  </Box>
                </div>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      แผนก:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.sectionName ? item.sectionName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      หน่วยงาน:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.divisionName ? item.divisionName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      ฝ่าย:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.divisionName ? item.divisionName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      BU:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.businessUnitName ? item.businessUnitName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ margin: "16px 0" }}></div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      {`หัวหน้างานลำดับที่ 1 `}
                    </Typography>
                    <Typography
                      color="text.third"
                      variant="body2"
                      className="second-line name-line"
                      gutterBottom
                    >
                      {`${
                        item.managerLV1_firstname_TH
                          ? `${item.managerLV1_firstname_TH} ${item.managerLV1_lastname_TH}`
                          : "-"
                      }`}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line name-line"
                      style={{ fontSize: 14 }}
                      gutterBottom
                    >
                      {`${
                        item.managerLV1_email
                          ? `(${item.managerLV1_email})`
                          : "-"
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      {`หัวหน้างานลำดับที่ 2`}
                    </Typography>
                    <Typography
                      color="text.third"
                      variant="body2"
                      className="second-line name-line"
                      gutterBottom
                    >
                      {`${
                        item.managerLV2_firstname_TH
                          ? `${item.managerLV2_firstname_TH} ${item.managerLV2_lastname_TH}`
                          : "-"
                      }`}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line name-line"
                      style={{ fontSize: 14 }}
                      gutterBottom
                    >
                      {`${
                        item.managerLV2_email
                          ? `(${item.managerLV2_email})`
                          : "-"
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ margin: "16px 0" }}></div>
                  </Grid>
                </Grid>

                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  gutterBottom
                  style={{ fontStyle: "oblique" }}
                >
                  {`หมายเหตุ: ${item.description ? item.description : "-"}`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  align="right"
                  style={{ fontStyle: "oblique" }}
                  gutterBottom
                >
                  {`วันที่แก้ไขข้อมูล: ${
                    item.updateDate
                      ? dayjs(item.updateDate)
                          .locale(localStorage.getItem("language") || "en")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "DD MMM BBBB HH:mm"
                              : "DD MMM YYYY HH:mm"
                          )
                      : "-"
                  }`}
                </Typography>
              </div>
            ))}
          </StyledWrapChange>
        )}
      </div>

      {/* <DialogEdit
        open={drawerConfig.isOpen}
        handleClose={handleClose}
        drawerConfig={drawerConfig}
      /> */}

      <FlowForm
        handleClose={handleClose}
        drawerConfig={drawerConfig}
        defaultPage="position"
      />

      <DialogEdit
        open={drawerEditConfig.isOpen}
        drawerConfig={drawerEditConfig}
        handleClose={() => {
          setDrawerEditConfig((prev) => ({
            ...prev,
            isOpen: false
          }))
        }}
      />


      {selectedItem && (
        <DialogConfirmDelete
          open={openDialogDelete}
          handleClose={handleClose}
          label={`ตำแหน่งพนักงาน`}
          text={`${selectedItem.positionName}`}
          handleDelete={handleSubmit}
          disabledBtnDelete={disabledBtnDelete}
        />
      )}
    </div>
  );
};

export default Transfer;
