import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/material/styles";

import { Divider, Grid, Typography, IconButton, Box } from "@mui/material";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";

import {
  getEmployeeEmployeeTypeChange,
  addDeleteEmployeeEmploymentType,
  getEmployeeProfile,
} from "../../../../../../actions/employee";
// import { companyEmployeeType } from "../../../../../../actions/company";

import { getEmploymentTypeByidCompany } from "../../../../../../actions/employment-type";

import DialogEdit from "./DialogEdit";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";

const StyledRoot = styled("div")({
  "& .salary": {
    "& .MuiTypography-h6": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "baseline",
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTextValue = styled(Typography)({
  fontSize: 18,
  "& i": {
    padding: 2,
  },
});

const StyledWrapChange = styled("div")({
  marginTop: 8,
  "& .inner": {
    marginBottom: 8,
  },
  "& .first-line": {
    display: "flex",
    alignItems: "center",
    "& .date": {
      margin: "0 8px",
      fontWeight: 600,
    },
    "& .positions-name": {
      fontWeight: 600,
    },
  },
  "& .second-line": {
    marginLeft: 64,
    fontSize: 16,

  },
});

function EmploymentTypeChange(props) {
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employmentTypeList } = useSelector(
    (state) => state.employmentType
  );
  const { result: employeeChangeEmploymentTypeList } = useSelector(
    (state) => state.employeeChange
  );

  const [openEdit, setOpenEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);
  const [disabledBtnChange, setDisabledBtnChange] = useState(false);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {}
  })

  useEffect(() => {
    dispatch(getEmployeeEmployeeTypeChange(employeeProfile.idEmployees));
    dispatch(getEmploymentTypeByidCompany());
  }, []);

  useEffect(() => {
    if (
      employeeChangeEmploymentTypeList &&
      employeeChangeEmploymentTypeList.length > 0
    ) {
      if (
        dayjs(
          employeeChangeEmploymentTypeList[
            employeeChangeEmploymentTypeList.length - 1
          ].start
        ).isSameOrAfter(dayjs().toDate(), "date")
      ) {
        setDisabledBtnChange(true);
      } else {
        setDisabledBtnChange(false);
      }
    }
  }, [employeeChangeEmploymentTypeList]);

  const handleOpenDialog = () => {
    setOpenEdit(true);
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      isEdit: false,
    }))
  };

  const handleCloseDialog = () => {
    setOpenEdit(false);
    setOpenDialogDelete(false);
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  };

  const handleDeleteEmployeeEmploymentType = (dataform) => {
    setSelectedItem(dataform);
    setOpenDialogDelete(true);
  };

  const handleSubmit = async () => {
    setDisabledBtnDelete(true);
    let tempFormData = { ...selectedItem };
    tempFormData.method = "delete";
    tempFormData.isFirst = employeeChangeEmploymentTypeList.length === 1;
    tempFormData.idEmployeeEmploymentTypeBefore =
      employeeChangeEmploymentTypeList.length === 1
        ? null
        : employeeChangeEmploymentTypeList[
            employeeChangeEmploymentTypeList.length - 2
          ].idEmployeeEmploymentType;
    tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");

    const result = await dispatch(
      addDeleteEmployeeEmploymentType(tempFormData)
    );
    if (result) {
      handleCloseDialog();
      dispatch(getEmployeeEmployeeTypeChange(employeeProfile.idEmployees));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
  };

  return (
    <StyledRoot>
      <StyledWrapTop>
        <div>
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            onClick={handleOpenDialog}
            disabled={disabledBtnChange}
          >
            แก้ไข
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              ประเภทการจ้าง
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employmentTypeName
                ? employeeProfile.employmentTypeName
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <div>
        <StyledHeadLabel color="text.secondary">
          บันทึกการเปลี่ยนแปลง
        </StyledHeadLabel>
        {employeeChangeEmploymentTypeList && (
          <StyledWrapChange>
            {employeeChangeEmploymentTypeList.map((item, index) => (
              <div className="inner" key={index}>
                <div className="first-line">
                  <Box display="flex" alignItems="center" flexGrow="1">
                    <div>
                      {employeeChangeEmploymentTypeList.length - 1 === index ? (
                        <Fragment>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() =>
                              handleDeleteEmployeeEmploymentType(item)
                            }
                            disabled={
                              employeeChangeEmploymentTypeList.length === 1
                            }
                          >
                            <HighlightOffRoundedIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDrawerConfig(prev => ({
                                ...prev,
                                isOpen: true,
                                isEdit: true,
                                data: {
                                  idEmployeeEmploymentType: item.idEmployeeEmploymentType,
                                  idEmploymentType: item.idEmploymentType,
                                  employeeID: item.employeeID,
                                  start: item.start,
                                  remark: item.remark,
                                }
                              }))
                            }}
                          >
                            <EditNoteRoundedIcon fontSize="inherit" />
                          </IconButton>
                        </Fragment>
                      ) : null}
                    </div>
                    {item.end ? (
                      <Typography className="date" color="text.third">
                        {`[${dayjs(item.start)
                          .locale(localStorage.getItem("language") || "en")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "DD MMM BBBB"
                              : "DD MMM YYYY"
                          )} - ${dayjs(item.end)
                          .locale(localStorage.getItem("language") || "en")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "DD MMM BBBB"
                              : "DD MMM YYYY"
                          )}]`}
                      </Typography>
                    ) : (
                      <Typography className="date" color="text.third">
                        {`[${dayjs(item.start)
                          .locale(localStorage.getItem("language") || "en")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "DD MMM BBBB"
                              : "DD MMM YYYY"
                          )} - ปัจจุบัน]`}
                      </Typography>
                    )}

                    <Typography className="positions-name">
                      {`${item.employmentTypeName}`}
                    </Typography>
                  </Box>
                  {/* {index === employeeChangeEmploymentTypeList.length-1 && <IconButton
                    aria-label="edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDrawerConfig(prev => ({
                        ...prev,
                        isOpen: true,
                        isEdit: true,
                        data: {
                          idEmployeeEmploymentType: item.idEmployeeEmploymentType,
                          idEmploymentType: item.idEmploymentType,
                          employeeID: item.employeeID,
                          start: item.start,
                          remark: item.remark,
                        }
                      }))
                    }}
                  >
                    <EditNoteRoundedIcon fontSize="inherit" />
                  </IconButton>} */}
                </div>
                

                <Typography
                  color="text.secondary"
                  variant="body1"
                  className="second-line"
                  gutterBottom
                  style={{ fontWeight: 500 }}
                >{`รหัสพนักงาน: ${
                  item.employeeID ? item.employeeID : "-"
                }`}</Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  style={{ fontStyle: "oblique" }}
                  gutterBottom
                >
                  {`หมายเหตุ: ${item.remark ? item.remark : "-"}`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  align="right"
                  style={{ fontStyle: "oblique" }}
                  gutterBottom
                >
                  {`วันที่แก้ไขข้อมูล: ${
                    item.updateDate
                      ? dayjs(item.updateDate)
                          .locale(localStorage.getItem("language") || "en")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "DD MMM BBBB HH:mm"
                              : "DD MMM YYYY HH:mm"
                          )
                      : "-"
                  }`}
                </Typography>
              </div>
            ))}
          </StyledWrapChange>
        )}
      </div>

      <DialogEdit
        open={drawerConfig.isOpen}
        handleCloseDialog={handleCloseDialog}
        drawerConfig={drawerConfig}
      />

      {selectedItem && (
        <DialogConfirmDelete
          open={openDialogDelete}
          handleClose={handleCloseDialog}
          label={`ประเภทการจ้าง`}
          text={`${selectedItem.employmentTypeName}`}
          handleDelete={handleSubmit}
          disabledBtnDelete={disabledBtnDelete}
        />
      )}
    </StyledRoot>
  );
}

export default EmploymentTypeChange;
