import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import CardStyle from "../../../shared/general/Card";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const More = (props) => {
  const { data } = props;
  const { result: onBoardingEmployee } = useSelector((state) => state.onBoardingEmployeeByID);

  return (
    <div>
      {onBoardingEmployee && (
        <StyledRoot>
          <CardStyle>
            <Box style={{ padding: "24px" }}>
              <Box style={{ display: "flex", position: "relative" }}>
                <StyledBox className={"close"}>
                  <div style={{ marginBottom: 8 }}>
                    <Typography
                      variant="h6"
                      style={{ paddingTop: 8, paddingBottom: 4 }}
                    >
                      รายละเอียดของ {data.firstname_TH} {data.lastname_TH}
                    </Typography>
                  </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 600 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">รายการ</TableCell>
                          <TableCell align="center">ผู้ตรวจสอบ</TableCell>
                          <TableCell align="center">หน่วยงาน</TableCell>
                          <TableCell align="center">
                            ดำเนินการภายใน(วัน)
                          </TableCell>
                          <TableCell align="center">สถานะ</TableCell>
                          <TableCell align="center">วันที่</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {onBoardingEmployee.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">
                              {row.OnBoardingListName}{" "}
                            </TableCell>
                            <TableCell align="center">
                              {`${row.reviewer_firstname_TH}  ${row.reviewer_lastname_TH}`}{" "}
                            </TableCell>
                            <TableCell align="center">
                              {row.departmentName}{" "}
                            </TableCell>
                            <TableCell align="center">{row.day} </TableCell>
                            <TableCell align="center">
                              {row.isApprove ? (
                                <CheckCircleOutlineIcon
                                  style={{ color: "green" }}
                                />
                              ) : (
                                <CancelOutlinedIcon style={{ color: "red" }} />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(row.updateDate).format("DD/MM/YYYY HH:mm")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StyledBox>
              </Box>
            </Box>
          </CardStyle>
        </StyledRoot>
      )}
    </div>
  );
};

export default More;
