import { Avatar, Box, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Visibility } from '@mui/icons-material';
import DialogShowComment from "./DialogShowComment";
import StatusRequest from "../../shared/general/stausRequest";
import TableCustom from "../../shared/tableCustom";

const Shift = () => {
    const { result: employeeChangeShiftList } = useSelector(
        (state) => state.employeeChangeShift
    );

    const [historyRows, setHistoryRows] = useState([]);
    const [dialogComment, setDialogComment] = useState({
      isOpen: false,
      comment: null
    });

    const shiftTimeHeader = [
        {
            name: "สถานะ",
            minWidth: "32px",
            width: "32px",
            cellRender: (row) => (
              <StatusRequest
                boxSize={40}
                fontSize={22}
                borderRadius={8}
                status={row.isApprove === 0 ? 0 : 1}
              />
            )
        },
        {
          name: "ชื่อ-สกุล",
          minWidth: "230px",
          width: "230px",
          cellRender: (row) => (
            <Box display="flex" alignItems="center">
              <Avatar
                style={{
                  marginRight: "8px",
                  width: 40,
                  height: 40,
                  "& img": { objectFit: "contain" },
                }}
                src={row.imageURL}
              />
              <Box flexGrow={1}>
                <Typography>
                  {row.firstname_TH} {row.lastname_TH}
                </Typography>
                <Typography color="text.third" fontSize="14px">
                  {row.positionName}
                </Typography>
              </Box>
            </Box>
          ),
        },
        {
          name: "วันที่",
          headerTextAlign: "center",
          minWidth: "150px",
          width: "150px",
          cellRender: (row) => (
            <Box style={{ textAlign: "center" }}>
              <Typography>
                {dayjs(row.workingDateText, "DD/MM/YYYY").format("D MMM BBBB")}
              </Typography>
            </Box>
          ),
        },
        {
          name: "เวลาทำงาน",
          headerTextAlign: "center",
          minWidth: "150px",
          width: "150px",
          cellRender: (row) => (
            <Box style={{ textAlign: "center" }}>
              {row.isWorkingDay === 1 ? (
                <Typography>{row.shiftGroupName}</Typography>
              ) : (
                <Typography>วันหยุด</Typography>
              )}
            </Box>
          ),
        },
        {
            name: "วันที่อนุมัติ",
            minWidth: "150px",
            headerTextAlign: "center",
            cellRender: (row) => (
              <Box sx={{textAlign: "center"}}>
                {row.approveDateText && 
                    <>
                        <Typography>{dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}</Typography>
                        <Typography>{dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
                    </>
                }
              </Box>
            )
        },
        {
          name: "เหตุผลหลังอนุมัติ",
          minWidth: "250px",
          width: "250px",
          cellRender: (row) => (
            <Box>
              {row.approveComment && (
                <Box
                  style={{ display:"flex", alignItems:"center" }}
                >
                  <Typography
                    style={{
                      width: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical"
                    }}
                  >{row.approveComment}</Typography>
                  <IconButton
                    style={{ marginLeft: 8 }}
                    onClick={() => setDialogComment({
                      isOpen: true,
                      comment: row.approveComment
                    })}
                  >
                    <Visibility />
                  </IconButton>
                </Box>
              )}
            </Box>
          )
        }
    ];

    useEffect(() => {
        const tempHistory = [];
        
        employeeChangeShiftList.map((r) => {
          if (r.isApprove !== null) {
            tempHistory.push(r);
          }
        });

        setHistoryRows(tempHistory.sort(function (a, b) {return new Date(b.approveDate) - new Date(a.approveDate)}));
    }, []);

    

    return (
      <Box>
        <TableCustom
          columns={shiftTimeHeader}
          rows={employeeChangeShiftList ? historyRows : []}
        />
        {dialogComment.isOpen && 
          <DialogShowComment 
            open={dialogComment.isOpen}
            onClose={() => setDialogComment({
              isOpen: false,
              comment: null
            })}
            comment={dialogComment.comment}
          />
        }
      </Box>
    );
};

export default Shift;