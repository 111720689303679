import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Card, Container, Grid, MenuItem, styled, TextField, Typography } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import HorizontalMenu from "../../shared/dashboard/HorizontalMenu";
import DashboardIcon from '@mui/icons-material/Dashboard';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardCost, getDashboardOvertime, getDashboardOverview, getDashboardWorkingtime } from "../../../../actions/dashboard";
import { getDepartment } from "../../../../actions/department";
import OverviewPanel from "./overviewPanel";
import OvertimePanel from "./overtimePanel";
import WorkingtimePanel from "./workingtimePanel";
import CostPanel from "./costPanel";
import dayjs from "dayjs";
import ButtonBlue from "../../shared/general/ButtonBlue";


const StyledRoot = styled(Box)({
  "& .filter-container": {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
    "& .MuiInputBase-root": {
      maxHeight: "48px"
    },
    "& .filter-month": {
      minWidth: "150px"
    },
    "& .filter-year": {
      minWidth: "150px"
    },
  },
  "& .menu-container": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    borderRadius: 20,
    backgroundColor: "#ffffff",
    width: "fit-content",
    overflow: "hidden",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root, & .MuiFilledInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
        borderRadius: 0
      }
    }
  },
  "& .MuiSelect-filled":{
    padding:"16px 32px 16px 12px"
  }
})

const Overview = () => {

  const dispatch = useDispatch();
  const [month, setMonth] = useState(dayjs().get("month")+1);
  const [year, setYear] = useState(dayjs().get("year"));
  const [menuValue, setMenuValue] = useState(0);

  // const [isFetchingOverview, setIsFetchingOverview] = useState(false);
  const { result: dashboardOverview } = useSelector(state => state.dashboardOverview);
  const { result: dashboardOvertime } = useSelector(state => state.dashboardOvertime);
  const { result: dashboardWorkingtime } = useSelector(state => state.dashboardWorkingtime);
  const { result: dashboardCost } = useSelector(state => state.dashboardCost);
  const { result: department } = useSelector(state => state.department);

  const [sections, setSections] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState("all");
  const [selectedSection, setSelectedSection] = useState("all");

  const [searchMonth, setSearchMonth] = useState(month);
  const [searchYear, setSearchYear] = useState(year);
  const [searchDepartment, setSearchDepartment] = useState(selectedDepartment);
  const [searchSection, setSearchSection] = useState(selectedSection);

  const menu = [
    {
      icon: <DashboardIcon />,
      label: "มุมมองภาพรวม"
    },
    {
      icon: <Filter1Icon />,
      label: "ภาพรวมค่าล่วงเวลา"
    },
    {
      icon: <Filter2Icon />,
      label: "ภาพรวมเวลาทำงาน"
    },
    {
      icon: <Filter3Icon />,
      label: "ภาพรวมค่าใช้จ่าย"
    },
  ]

  const menuChangeHandler = (_, newValue) => {
    setMenuValue(newValue);
  }

  useEffect(() => {
    dispatch(getDepartment({includes: ["section"]}))
  }, [])

  useEffect(() => {
    if(menuValue === 0){
      dispatch(getDashboardOverview({mode: "admin", month: month, year: year, idDepartment: selectedDepartment === "all"? undefined: selectedDepartment === null? "null": selectedDepartment, idSection: selectedSection === "all"? undefined: selectedSection  === null? "null": selectedSection}));
    } else if(menuValue === 1) {
      dispatch(getDashboardOvertime({mode: "admin", month: month, year: year, idDepartment: selectedDepartment === "all"? undefined: selectedDepartment === null? "null": selectedDepartment, idSection: selectedSection === "all"? undefined: selectedSection  === null? "null": selectedSection}));
    } else if(menuValue === 2) {
      dispatch(getDashboardWorkingtime({mode: "admin", month: month, year: year, idDepartment: selectedDepartment === "all"? undefined: selectedDepartment === null? "null": selectedDepartment, idSection: selectedSection === "all"? undefined: selectedSection  === null? "null": selectedSection}))
    } else if(menuValue === 3) {
      dispatch(getDashboardCost({mode: "admin", month: month, year: year, idDepartment: selectedDepartment === "all"? undefined: selectedDepartment === null? "null": selectedDepartment, idSection: selectedSection === "all"? undefined: selectedSection  === null? "null": selectedSection}));
    }
  }, [searchMonth, searchYear, menuValue, searchDepartment, searchSection])

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" style={{marginTop: "16px", marginBottom: "16px"}}>        
        <Box marginBottom="24px">
          <CardStyle>
            <Box padding="24px">
              <Box display="flex" justifyContent="flex-end" gap="8px" marginBottom="16px">
                <Box width="100%" maxWidth="200px">
                  <Typography fontSize="14px" fontWeight="600" marginBottom="4px" color="text.third">ปี</Typography>
                  <TextFieldTheme
                    variant="filled"
                    value={year}
                    onChange={(event)=>{
                      if(dayjs().set("month", month-1).set("year", parseInt(event.target.value)).isBefore(dayjs().set("month", 1).set("year", 2023))){
                        setMonth(2);
                      } else if(dayjs().set("month", month-1).set("year", parseInt(event.target.value)).isAfter(dayjs())){
                        setMonth(dayjs().get("month")+1);
                      }
                      setYear(event.target.value)
                    }}
                    select
                  >
                    {/* {yearOptions.map((y) => (
                      <MenuItem key={y.value} value={y.value}>{y.label}</MenuItem>
                    ))} */}
                    {[...Array((dayjs().format("YYYY")-2023)+1)].map((_,index) => (
                      <MenuItem key={index} value={Number(dayjs().subtract(index, 'year').format("YYYY"))}>
                        {(parseInt(dayjs().format("YYYY")) - index)}
                      </MenuItem>
                    ))}
                  </TextFieldTheme>
                </Box>
                <Box width="100%" maxWidth="200px">
                  <Typography fontSize="14px" fontWeight="600" marginBottom="4px" color="text.third">เดือน</Typography>
                  <TextFieldTheme
                    variant="filled"
                    value={month}
                    onChange={(event)=>{setMonth(event.target.value)}}
                    select
                  >
                    {[...Array(12)].map((_, index) => {
                      if(dayjs().set('month', index).set('year', year).isSameOrAfter(dayjs().set('month', 1).set('year', 2023)) && !dayjs().set('month', index).set('year', year).isAfter(dayjs())){
                        return (
                          <MenuItem key={index} value={index+1}>
                            {dayjs().month(index).format("MMMM")}
                          </MenuItem>
                        )
                      }
                    })}
                  </TextFieldTheme>
                </Box>
              </Box>
              {department && <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography fontSize="14px" fontWeight="600" marginBottom="4px" color="text.third">หน่วยงาน</Typography>
                  <Autocomplete
                    options={[{idDepartment: "all", departmentName: "ทั้งหมด", sections: []}, ...department]}
                    getOptionLabel={(option) => option.departmentName === null? `ไม่มีหน่วยงาน`: `${option.departmentName}`}
                    groupBy={(option) => option.divisionName}
                    // filterOptions={filterOptions}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idDepartment}>
                          <Box>
                            <Typography>{option.departmentName}</Typography>
                            {/* <Typography color="text.third" fontSize="14px">{option.departmentName}</Typography> */}
                          </Box>
                        </MenuItem>
                      )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        variant="filled"
                        // onBlur={field.onBlur}
                        // error={useForm.formState.errors[field.name]? true: false}
                        // helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                      />
                    )}
                    value={[{idDepartment: "all", departmentName: "ทั้งหมด", sections: []}, ...department].find(option => option.idDepartment === selectedDepartment) || null}
                    onChange={(_, value) => {
                      setSelectedSection("all")
                      setSelectedDepartment(value? value.idDepartment: "")
                      setSections(value? value.sections: [])
                      // field.onChange(value? value.idEmployees: "")
                    }}
                    disableClearable
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography fontSize="14px" fontWeight="600" marginBottom="4px" color="text.third">แผนก</Typography>
                  <Autocomplete
                    options={[{idSection: "all", sectionName: "ทั้งหมด"}, ...sections]}
                    getOptionLabel={(option) => option.sectionName === null? `ไม่มีแผนก`: `${option.sectionName}`}
                    // filterOptions={filterOptions}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idSection}>
                          <Box>
                            <Typography>{option.sectionName === null? `ไม่มีแผนก`: `${option.sectionName}`}</Typography>
                            {/* <Typography color="text.third" fontSize="14px">{option.departmentName}</Typography> */}
                          </Box>
                        </MenuItem>
                      )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        variant="filled"
                        // onBlur={field.onBlur}
                        // error={useForm.formState.errors[field.name]? true: false}
                        // helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                      />
                    )}
                    value={[{idSection: "all", sectionName: "ทั้งหมด"}, ...sections].find(option => option.idSection === selectedSection) || ""}
                    // value={department.find(option => option.idEmployees === field.value) || null}
                    // onChange={(_, value) => {field.onChange(value? value.idEmployees: "")}}
                    disableClearable
                    onChange={(_, value) => {
                      // setSelectedSection(null)
                      setSelectedSection(value? value.idSection: "")
                      // setSections(value? value.sections: [])
                      // field.onChange(value? value.idEmployees: "")
                    }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                  <ButtonBlue
                    variant="contained"
                    onClick={() => {
                      console.log("selectedSection", selectedSection)
                      setSearchMonth(month);
                      setSearchYear(year);
                      setSearchDepartment(selectedDepartment);
                      setSearchSection(selectedSection);
                    }}
                  >
                    ค้นหา
                  </ButtonBlue>
                </Grid>
              </Grid>}
            </Box>
          </CardStyle>
        </Box>
        <Box display="flex" justifyContent="flex-start" marginBottom="24px">
          <Box className="menu-container">
            <HorizontalMenu
              value={menuValue}
              onChange={menuChangeHandler}
              menu={menu}
              centered={true}
            />
          </Box>
        </Box>

        <Box className="dashboard-container">
          {menuValue === 0 && (
            <Box>
              {dashboardOverview? <OverviewPanel month={month} year={year}/>: <Typography textAlign="center">กำลังโหลดข้อมูล...</Typography>}
            </Box>
          )}
          {menuValue === 1 && (
            <Box>
              {dashboardOvertime? <OvertimePanel month={month} year={year}/>: <Typography textAlign="center">กำลังโหลดข้อมูล...</Typography>}
            </Box>
          )}
          {menuValue === 2 && (
            <Box>
              {dashboardWorkingtime? <WorkingtimePanel month={month} year={year}/>: <Typography textAlign="center">กำลังโหลดข้อมูล...</Typography>}
            </Box>
          )}
          {menuValue === 3 && (
            <Box>
              {dashboardCost? <CostPanel month={month} year={year}/>: <Typography textAlign="center">กำลังโหลดข้อมูล...</Typography>}
            </Box>
          )}
        </Box>
      </Container>
    </StyledRoot> 
  )

}

export default Overview;