import {
  MANPOWER_PROFILE_FETCHING,
  MANPOWER_PROFILE_FAILED,
  MANPOWER_PROFILE_SUCCESS,
  MANPOWER_CERTIFICATE_FETCHING,
  MANPOWER_CERTIFICATE_FAILED,
  MANPOWER_CERTIFICATE_SUCCESS,
} from "./types";
import manpowerService from "../services/manpower.service";

export const addManpower = (formData) => async () => {
  try {
    const res = await manpowerService.addManpower(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateManpower = (idManpower, formData) => async () => {
  try {
    const res = await manpowerService.updateManpower(idManpower, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getManpowerById = (idManpower) => async (dispatch) => {
  try {
    dispatch({
      type: MANPOWER_PROFILE_FETCHING
    });
    const res = await manpowerService.getManpowerById(idManpower);
    if (res) {
      dispatch({
        type: MANPOWER_PROFILE_SUCCESS,
        payload: res.data
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: MANPOWER_PROFILE_FAILED
    });
    return error.response;
  }
};

export const addManpowerExperience = (idManpower, formData) => async () => {
  try {
    const res = await manpowerService.addManpowerExperience(idManpower, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateManpowerExperience = (idManpower, idManpowerExperience, formData) => async () => {
  try {
    const res = await manpowerService.updateManpowerExperience(idManpower, idManpowerExperience, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteManpowerExperience = (idManpower, idManpowerExperience) => async () => {
  try {
    const res = await manpowerService.deleteManpowerExperience(idManpower, idManpowerExperience);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addManpowerEducation = (idManpower, formData) => async () => {
  try {
    const res = await manpowerService.addManpowerEducation(idManpower, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateManpowerEducation = (idManpower, idManpowerEducation, formData) => async () => {
  try {
    const res = await manpowerService.updateManpowerEducation(idManpower, idManpowerEducation, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteManpowerEducation = (idManpower, idManpowerEducation) => async () => {
  try {
    const res = await manpowerService.deleteManpowerEducation(idManpower, idManpowerEducation);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateManpowerStatus = (idManpower, formData) => async () => {
  try {
    const res = await manpowerService.updateManpowerStatus(idManpower, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addManpowerFromExcel = (formData) => async () => {
  try {
    const res = await manpowerService.addManpowerFromExcel(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addManpowerCertificate = (idManpower, formData) => async () => {
  try {
    const res = await manpowerService.addManpowerCertificate(idManpower, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getManpowerCertificate = (idManpower) => async (dispatch) => {
  try {
    dispatch({
      type: MANPOWER_CERTIFICATE_FETCHING
    });
    const res = await manpowerService.getManpowerCertificate(idManpower);
    if (res) {
      dispatch({
        type: MANPOWER_CERTIFICATE_SUCCESS,
        payload: res.data
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: MANPOWER_CERTIFICATE_FAILED
    });
    return error.response;
  }
};

export const deleteManpowerCertificate = (idManpower, idManpowerCertificate) => async () => {
  try {
    const res = await manpowerService.deleteManpowerCertificate(idManpower, idManpowerCertificate);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addManpowerResume = (idManpower, formData) => async () => {
  try {
    const res = await manpowerService.addManpowerResume(idManpower, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteManpowerResume = (idManpower, idManpowerResume) => async () => {
  try {
    const res = await manpowerService.deleteManpowerResume(idManpower, idManpowerResume);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getFormAddManpowerByVendorId = (idVendor) => async () => {
  try {
    const res = await manpowerService.getFormAddManpowerByVendorId(idVendor);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addManpowerByVendorId = (idVendor, formData) => async () => {
  try {
    const res = await manpowerService.addManpowerByVendorId(idVendor, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};


export const updateStatusManpower = (data) => async () => {
  try {
    const res = await manpowerService.updateStatusManpower(data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};