import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  Box,
  Popper,
  Typography,
  IconButton,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dropzone, { useDropzone } from "react-dropzone";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import AlertResponse from "../../shared/general/AlertResponse";
import AddPhotoIcon from "../../../../components/pages/assets/add.png";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { getEmployment } from "../../../../actions/company";
import { addContract, EditContract } from "../../../../actions/employee";
import { addDocument, editDocument } from "../../../../actions/document";
// import { addEducation, updateEducation } from "../../../../../../actions/education";
// import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledTextFieldTheme = styled(TextFieldTheme)({
  marginBottom: 0,
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const DialogImport = (props) => {
  const dispatch = useDispatch();
  const { open, mode, handleClose, empData, doc } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [value, setValue] = useState(false);
  const [formData, setFormData] = useState(null);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  useEffect(() => {
    if (open) {
      if (mode === "edit") {
        setFormData({
          idEmployees: null,
          startDate: doc.start,
          endDate: doc.end,
          file: "none",
        });
      } else
        setFormData({
          idEmployees: null,
          startDate: null,
          endDate: null,
          file: null,
        });
    }
  }, [open]);

  const fileDrawer = () => {
    if (formData.file === "none") {
      return doc.fileURL;
    } else {
      return URL.createObjectURL(formData.file);
    }
  };

  const handleChange = (event) => {
    setValue(eval(event.target.value));
  };

  const handleSubmit = async () => {
    if (!formData.file) {
      handleOpenAlert(true);
      handleChangeAlertType("กรุณากรอกข้อมูลให้ครบ");
    } else {
      const data = {
        idEmployees: empData.idEmployees,
        startDate: formData.startDate,
        endDate: formData.endDate,
        idDocumentType: doc.idDocumentType,
        createDate: dayjs(new Date()).format("YYYY-MM-DD"),
        updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
      };

      if (formData.endDate === null) delete data.endDate;
      if (formData.startDate === null) delete data.startDate;

      const postData = new FormData();

      Object.keys(data).map((k) => {
        postData.append(k, JSON.stringify(data[k]));
      });

      if (formData.file) {
        postData.append("file", formData.file);
      }
      if (mode === "import") {
        const result = await dispatch(addDocument(postData));
        if (result) {
          handleClose();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      } else {
        const result = await dispatch(editDocument(postData));
        if (result) {
          handleClose();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      }
    }
  };

  const title = "เพิ่มไฟล์ " + doc.documentTypeName;

  return (
    <DrawerCustom
      title={title}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && (
        <StyledRoot>
          {/* {JSON.stringify(data)}
                {JSON.stringify(formData)} */}
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2} style={{ marginBottom: 16 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item className="GridTopicInput">
                  <Typography sx={{ mr: 8 }}>วันหมดอายุ</Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="มี"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="ไม่มี"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </LocalizationProvider>
            </Grid>
            {value ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={12} sm={6} className="GridTopicInput">
                  เริ่มต้น
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    value={formData.startDate}
                    inputFormat="dd/MM/yyyy"
                    name="startDate"
                    onChange={(newValue) => {
                      setFormData({
                        ...formData,
                        ["startDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth helperText={null} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="GridTopicInput">
                  สิ้นสุด
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    value={formData.endDate}
                    inputFormat="dd/MM/yyyy"
                    name="endDate"
                    onChange={(newValue) => {
                      setFormData({
                        ...formData,
                        ["endDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth helperText={null} />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
            ) : (
              ""
            )}

            <Grid item xs={12}>
              <Typography
                color="text.third"
                variant="body2"
                paddingBottom="16px"
              >
                แนบไฟล์
              </Typography>
              {!formData.file && (
                <StyledDropzone>
                  <Dropzone
                    accept="application/pdf"
                    maxFiles={1}
                    multiple={false}
                    maxSize={3145728}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setFormData({ ...formData, file: acceptedFiles[0] });
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone-leave" })}>
                        <div className="inner-dropzone">
                          <input {...getInputProps()} />
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img alt="add" src={AddPhotoIcon} />
                            </div>
                            <Typography
                              variant="caption"
                              style={{ lineHeight: 0.5, color: "#999999" }}
                            >
                              Allowed *.pdf, max size of 3 MB
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDropzone>
              )}
              {formData.file && (
                <Box sx={{ position: "relative", textAlign: "center" }}>
                  <IconButton
                    sx={{ position: "absolute", top: 0, right: 0 }}
                    color="error"
                    onClick={() => {
                      setFormData({ ...formData, file: null });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <embed src={fileDrawer()} width="250" height="200"></embed>
                </Box>
              )}
            </Grid>
          </Box>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit}>
              บันทึกข้อมูล
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogImport;
