import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DrawerCustom from "../../../../shared/general/Drawer";
import {
  Box,
  Grid,
  Typography,
  styled,
  Divider,
  FormControl,
  OutlinedInput,
  TextField,
  MenuItem,
} from "@mui/material";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

import AdapterDateFns from "@tarzui/date-fns-be";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import {
  getEmployeeProfile,
  getEmployeeSalaryChange,
  addDeleteEmployeeSalary,
  updateEmployeeSalaryChange,
} from "../../../../../../actions/employee";

import utils from "../../../../../../utils";
import { updateUser } from "../../../../../../actions/user";

const StyledRoot = styled(Box)({
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledDivider = styled(Divider)({
  // marginTop: 16,
  // marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledSalary = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const DialogEdit = (props) => {
  const dispatch = useDispatch();

  const {
    open,
    onClose,
    employeeSalaryChangeList,
    setEmployeeSalaryChangeList,
    drawerConfig
  } = props;
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [formData, setFormData] = useState(null);

  const [employeeFormData, setEmployeeFormData] = useState(null);

  useEffect(() => {
    if (employeeProfile && open) {
      if(!drawerConfig.isEdit){
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          idEmployeeSalaryOld: employeeProfile.idEmployeeSalary
            ? employeeProfile.idEmployeeSalary
            : null,
          salaryValue: null,
          salaryBilling: null,
          start: new Date(),
          remark: "",
          reason: "",
        });
      } else {
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          salaryValue: drawerConfig.data.salaryValue || "",
          start: new Date(drawerConfig.data.start),
          remark: drawerConfig.data.remark || "",
          reason: drawerConfig.data.reason || "",
        });
      }
      setEmployeeFormData({
        idEmployees: employeeProfile.idEmployees,
        idPaymentType: employeeProfile.idPaymentType,
      })
    }
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log({ name, value });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    let result = null;
    let employeeResult = null;
    let tempFormData = { ...formData };
    // let tempEmployeeFormData = {
    //   ...employeeFormData,
    //   idEmployees: employeeProfile.idEmployees,
    //   UpdateBy: userProfile.idEmployees,
    //   updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss")
    // };
    tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");

    Object.keys(tempFormData).map(k => {
      if(tempFormData[k] === ""){
        tempFormData[k] = null
      } else {
        tempFormData[k] = tempFormData[k]
      }
    })

    if(drawerConfig.isEdit){
      tempFormData.updateDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
      result = await dispatch(updateEmployeeSalaryChange(employeeProfile.idEmployees, drawerConfig.data.idEmployeeSalary, tempFormData))
    } else {
      tempFormData.method = "add";
      result = await dispatch(addDeleteEmployeeSalary(tempFormData));
    }

    // employeeResult = await dispatch(updateUser(tempEmployeeFormData))


    if (result) {
      onClose();
      dispatch(getEmployeeSalaryChange(employeeProfile.idEmployees));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
  };

  return (
    <DrawerCustom
      title={"แก้ไขข้อมูลค่าจ้าง"}
      anchor={"right"}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        {formData && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledContentLabel>ข้อมูลปัจจุบัน</StyledContentLabel>
              <div className="wrap">
                <Typography className="present">
                  {employeeProfile.salary
                    ? utils.numberWithCommas(employeeProfile.salary)
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
            {/* <Grid item xs={12}>
              <StyledContentLabel>รูปแบบการจ่าย</StyledContentLabel>
              <TextFieldTheme
                name="idPaymentType"
                value={employeeFormData.idPaymentType}
                onChange={(e) => {
                  setEmployeeFormData(prev => ({
                    ...prev,
                    idPaymentType: e.target.value
                  }))
                }}
                fullWidth
                select
              >
                <MenuItem value="1">
                  <Box>
                    <Typography>รายวัน</Typography>
                    <Typography color="text.third" fontSize="12px">รอบจ่าย: รายครึ่งเดือน</Typography>
                  </Box>
                </MenuItem>
                <MenuItem value="2">
                  <Box>
                    <Typography>รายเดือน</Typography>
                    <Typography color="text.third" fontSize="12px">รอบจ่าย: รายเดือน</Typography>
                  </Box>
                </MenuItem>
              </TextFieldTheme>
            </Grid> */}
            <Grid item xs={12}>
              <StyledContentLabel>เงินเดือน</StyledContentLabel>
              <TextFieldTheme
                name="salaryValue"
                value={formData.salaryValue}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  allowNegative: false,
                  onValueChange: (values) => {
                    const { value } = values;
                    console.log(value)
                    setFormData(prev => ({
                      ...prev,
                      salaryValue: value
                    }))
                  },
                  value: formData.salaryValue
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>วันที่มีผล</StyledContentLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={th}>
                <DatePicker
                  value={formData.start}
                  views={["year", "month", "day"]}
                  openTo="year"
                  inputFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      start: date,
                    });
                  }}
                  renderInput={(params) => (
                    <StyledTextField fullWidth {...params} />
                  )}
                  disabled={drawerConfig.isEdit}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>เหตุผล</StyledContentLabel>
              <TextFieldTheme
                name="reason"
                value={formData.reason}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    reason: event.target.value,
                  });
                }}
                select
              >
                <MenuItem value="ปรับประจำปี">ปรับประจำปี</MenuItem>
                <MenuItem value="ปรับโครงสร้าง">ปรับโครงสร้าง</MenuItem>
                <MenuItem value="ปรับตำแหน่ง">ปรับตำแหน่ง</MenuItem>
                <MenuItem value="ปรับระดับงาน">ปรับระดับงาน</MenuItem>
                <MenuItem value="ปรับพิเศษ">ปรับพิเศษ</MenuItem>
                <MenuItem value="ปรับด้วยเหตุผลอื่นๆ">ปรับด้วยเหตุผลอื่นๆ</MenuItem>
              </TextFieldTheme>
            </Grid>

            <Grid item xs={12}>
              <StyledContentLabel>หมายเหตุ</StyledContentLabel>
              <TextFieldTheme
                name="remark"
                value={formData.remark}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    remark: event.target.value,
                  });
                }}
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" onClick={handleSubmit}>
                บันทึกข้อมูล
              </ButtonBlue>
            </Grid>
          </Grid>
        )}
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
