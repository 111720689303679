import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

import {
    Avatar,
  Box,
  Card,
  Divider,
  IconButton,
  styled,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import MockCompanyImage from "../../assets/-_Job opportunities.png";

const StyledCard = styled(Card)(({ theme }) => ({
  width: "370px",
  margin: "16px 8px",
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  backgroundColor: "#ffffff",
  borderRadius: 20,
  "&:hover": {
    boxShadow: "rgb(145 158 171 / 24%) 0px 0px 8px 8px",
  },
  "& .BoxImg": {
    width: "100%",
    height: "160px",
    display: "flex",
    position: "relative",
    "& img": {
      width: "inherit",
      height: "inherit",
      objectFit: "cover",
    },
    "& .favorite": {
      position: "absolute",
      left: 8,
      top: 8,
      color: "#e0115f",
    },
    "& .quota": {
      position: "absolute",
      right: 8,
      top: 8,
      color: "#e0115f",
    },
  },
  "& .ContentText": {
    padding: "16px 16px",
    "& .BoxPositionName": {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      "& .PositionName": {
        fontSize: "20px",
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
    },
    "& .CompanyName": {
      fontSize: "14px",
    },
    "& .Line": {
      borderColor: "rgba(255,255,255,0.2)",
      margin: "16px 0",
    },
    "& .BoxPrice": {
      display: "flex",
      justifyContent: "flex-end",

      "& .Price": {
        color: theme.palette.primary.main,
        fontWeight: 900,
      },
    },
    "& .part-three": {
      display: "flex",
      alignItems: "center",
      marginBottom: 8,
    },
    "& .part-five": {
      height: 48,
      display: "flex",
      "& .left": {
        width: "230px",
      },
      "& .right": {
        width: "140px",
      },
      "& .wrap-item": {
        display: "flex",
        color: "#637381",
        alignItems: "center",
        fontSize: "0.875rem",
        "& .fal": {
          marginRight: 8,
        },
      },
    },
  },
}));

const CardJob = (props) => {
  let { job, favorite } = props;
  const history = useHistory();

  const onClickCardHandler = (idJob) => {
    history.push(`/company/request/${idJob}`);
  };

  return (
    <StyledCard>
      <div
        onClick={() => onClickCardHandler(job.idJobRecruit)}
        style={{ height: "100%" }}
      >
        <Box className="BoxImg">
          <img src={MockCompanyImage} alt="MockCompany" />
          <IconButton className="favorite">
            {favorite ? (
              <i className="fa-solid fa-heart" />
            ) : (
              <i className="fa-regular fa-heart" />
            )}
          </IconButton>
          <div className="quota">
            <Typography sx={{ color: "#bc7418" }} variant="h5">
              {job.quota ? job.quota : <i className="fa-solid fa-infinity"></i>}
            </Typography>
          </div>
        </Box>
        <Box className="ContentText">
          <Box className="BoxPositionName">
            <Typography className="PositionName">{job.positionName}</Typography>
          </Box>
          <Typography variant="body2" style={{ color: "#007afd" }}>
            {`${job.companyName} (${job.nameShort}) ` || "-"}
          </Typography>
          <Fragment>
            <div className={`part-three`}>
              <i
                className="fal fa-map-marker-alt"
                style={{ color: "#637381", marginRight: 8 }}
              ></i>
              <Typography variant="body2" style={{ color: "#637381" }}>
                {`${job.locationAddress} ${job.locationDistrict} ${job.locationProvince}`}
              </Typography>
            </div>
            <div className={`part-three`}>
              <Avatar src={job.imageURL} />
              <Typography variant="body2" style={{ color: "#637381", marginLeft: 8 }}>
                {job.contactName}
              </Typography>
            </div>
          </Fragment>
          <Box>
            <Divider
              style={{
                margin: "16px 0px",
                borderWidth: "0px 0px thin",
                borderColor: "rgba(145, 158, 171, 0.24)",
                borderStyle: "dashed",
              }}
            />
            <div className={`part-five`}>
              <div className="left">
                <div className={`wrap-item`}>
                  <i className="fal fa-calendar-times"></i>
                  <Typography>
                    {job.closeRegisterDate
                      ? dayjs(job.closeRegisterDate).format("DD-MMM-YYYY")
                      : "ไม่มีกำหนดปิดรับสมัคร"}
                  </Typography>
                </div>
              </div>
              <div className="right">
                <Fragment>
                  <div className={`wrap-item`}>
                    <i className="fal fa-graduation-cap"></i>
                    <Typography>
                      {" "}
                      {
                        [
                          "ไม่จำกัด",
                          "ประถมศึกษา",
                          "มัธยมศึกษา",
                          "ปวช./ปวส.",
                          "ปริญญาตรี",
                          "ปริญญาโท",
                          "ปริญญาเอก",
                        ][job.educationDegree || 0]
                      }
                    </Typography>
                  </div>
                </Fragment>
              </div>
            </div>
          </Box>
        </Box>
      </div>
    </StyledCard>
  );
};

export default CardJob;
