import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Container, Typography, Box, Tabs, Tab, Grid, TextField, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

import TimeAttendance from "./timeAttendance";
import Overtime from "./overtime";
import Leave from "./leave";
import Shift from './shift';

import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { getAllRequestTimeBy, getAllRequestTimeWithDrawBy } from "../../../../actions/requestTime";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getLeaveRequest, getEmployeeChangeTime } from "../../../../actions/employee";
import { getAllLeaveWithDrawBy } from "../../../../actions/leave";
import { getPayrollSetting } from "../../../../actions/paytypes";
import SnackBarCustom from "../../shared/snackbarCustom";
import { useHistory } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF !important",
  "& .displayFlexEnd":{
    display:"flex",
    justifyContent:"flex-end",
    [theme.breakpoints.down("sm")]:{
      width:"100%",
      margin:"8px 0"
    }
  }
}));

const StyledWrapTabs = styled("div")({
  paddingBottom: 36,
  marginTop: 16,
  width: "100%",
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const HistoryApporveStatusListPage = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [search, setSearch] = React.useState({
    start: null,
    end: null
  });
  const history = useHistory();
  
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: requestTimeWithDrawList } = useSelector((state) => state.requestTimeWithDraw);
  const { result: leaveEmployeesList } = useSelector((state) => state.leaveEmployees);
  const { result: leaveEmployeeWithdrawsList } = useSelector((state) => state.leaveEmployeesWithDraw);
  const { result: payrollSetting } = useSelector((state) => state.payrollSetting);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeChangeShiftList } = useSelector(
    (state) => state.employeeChangeShift
  );

  const mobile = useMediaQuery('(max-width:600px)');

  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  })

  useEffect(() => {
    dispatch(getEmployeeChangeTime("admin", search));
    dispatch(getAllRequestTimeBy("admin", search));
    dispatch(getAllRequestTimeWithDrawBy("admin", search));
    dispatch(getLeaveRequest("admin", search));
    dispatch(getAllLeaveWithDrawBy("admin", search));
    dispatch(getPayrollSetting());
  }, []);

  const handleChange = (event, newValue) => {
    if(mobile){
      setValue(event.target.value);
    }else{
      setValue(newValue);
    }
  };

  const handleClickSearch = async () => {
    dispatch(getEmployeeChangeTime("admin", search));
    dispatch(getAllRequestTimeBy("admin", search));
    dispatch(getAllRequestTimeWithDrawBy("admin", search));
    dispatch(getLeaveRequest("admin", search));
    dispatch(getAllLeaveWithDrawBy("admin", search));
  };

  return (
    <StyledRoot className={`page`}>

      <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig}/>

      <Container maxWidth="lg" style={{ paddingBottom: 40 }}>
        <Grid container justifyContent="space-between"  alignItems="center">
            <Grid item>
                <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
                    ประวัติสถานะรายการ
                </Typography>
            </Grid>
            <Grid item className="displayFlexEnd">
                <ButtonBlue
                    variant="contained"
                    style={{ height: "fit-content" }}
                    startIcon={<TextSnippetIcon />}
                    onClick={() => history.push("/admin/approve-list")}
                >
                    สถานะรายการ
                </ButtonBlue>
            </Grid>
        </Grid>
        <Box>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  วันที่เริ่มต้น
                </Typography>
                <div className="search-date">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={th}
                  >
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={search.start}
                      name="start"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch({
                          ...search,
                          start: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          variant="filled"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  วันที่สิ้นสุด
                </Typography>
                <div className="search-date">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={th}
                  >
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={search.end}
                      name="start"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch({
                          ...search,
                          end: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          variant="filled"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div style={{ paddingBottom: 12 }}>
                <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                  ค้นหา
                </ButtonBlue>
              </div>
            </Grid>
          </Grid>
        </Box>
        <StyledWrapTabs>
          <Box style={{ marginBottom: 16 }}>
            {mobile ?
              <TextFieldTheme
                value={value}
                onChange={handleChange}
                select
              >
                <MenuItem value={0}>
                  รับรองเวลาทำงาน
                </MenuItem>
                <MenuItem value={1}>
                  ทำงานล่วงเวลา
                </MenuItem>
                <MenuItem value={2}>
                  ขอลางาน
                </MenuItem>
                <MenuItem value={3}>
                  ขอเปลี่ยนกะ
                </MenuItem>
              </TextFieldTheme>
              :
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="รับรองเวลาทำงาน" {...a11yProps(0)} />
                <Tab label="ทำงานล่วงเวลา" {...a11yProps(1)} />
                <Tab label="ขอลางาน" {...a11yProps(2)} />
                <Tab label="ขอเปลี่ยนกะ" {...a11yProps(3)} />
              </Tabs>
            }
            
          </Box>  

          {userProfile && (
            <Fragment>
              {requestTimeList && requestTimeWithDrawList && (
                <TabPanel value={value} index={0}>
                  <TimeAttendance />
                </TabPanel>
              )}
              {requestTimeList && requestTimeWithDrawList && payrollSetting && (
                <TabPanel value={value} index={1}>
                  <Overtime />
                </TabPanel>
              )}
              {leaveEmployeesList && leaveEmployeeWithdrawsList && (
                <TabPanel value={value} index={2}>
                  <Leave />
                </TabPanel>
              )}
              {employeeChangeShiftList && 
                <TabPanel value={value} index={3}>
                  <Shift />
                </TabPanel>
              }
            </Fragment>
          )}
        </StyledWrapTabs>
      </Container>
    </StyledRoot>
  );
};

export default HistoryApporveStatusListPage;
