import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'; 
import dayjs from 'dayjs';

import { 
    Card, 
    CardActionArea,
    Chip,
    Link,
    styled,
    Typography
} from '@mui/material';

import PlaylistAddCircleRoundedIcon from "@mui/icons-material/PlaylistAddCircleRounded";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QrCodeIcon from "@mui/icons-material/QrCode2";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import StatusRequest from '../general/stausRequest';

const StyledCard = styled(Card)(({ day }) => ({
    minHeight:"117px",
    display:"flex",
    marginBottom: 16,
    borderRadius: "16px",
    "& .day-container":{
        minWidth:"54px",
        display:"flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize:"16px",
        color:"#FFFFFF",
        ...(day === "0" && {
            backgroundColor:"#FF5F5F"
        }),
        ...(day === "1" && {
            backgroundColor:"#FFD953"
        }),
        ...(day === "2" && {
            backgroundColor:"#FFB8E3"
        }),
        ...(day === "3" && {
            backgroundColor:"#6ADFBB"
        }),
        ...(day === "4" && {
            backgroundColor:"#FFA25F"
        }),
        ...(day === "5" && {
            backgroundColor:"#85CCFF"
        }),
        ...(day === "6" && {
            backgroundColor:"#CE90FF"
        }),
    },
    "& .date-detail":{
        width:"calc(100% - 86px)",
        padding:"16px 16px 0",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        "& .container-flex-wrap":{
            width:"100%",
            display:"flex",
            justifyContent:"space-between",
            flexWrap:"wrap",
            "& .check-container":{
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                fontWeight: 600,
                marginBottom: 8
            },
            "& .item":{
                marginBottom: 8
            }
        },
        "& .fontWeight":{
            fontWeight: 600
        },
        "& .noCheckInOut":{
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            marginBottom:"16px"
        },
    }
}));

const StyledChipTime = styled(Chip)({
    height: 28,
    width: 90,
    fontSize: 14,
    borderRadius: 8,
    border: "none",
    "&.check-in": {
      color: "#229a16",
      backgroundColor: "#54d62c29",
      "& .MuiSvgIcon-root": {
        color: "#229a16",
        fontSize: 20,
      },
    },
    "&.check-out": {
      color: "#b72136",
      backgroundColor: "#ff484229",
      "& .MuiSvgIcon-root": {
        color: "#b72136",
        fontSize: 20,
      },
    },
});

const StyledChip = styled(Chip)(({ status }) => ({
    margin: 0,
    fontSize: 14,
    fontWeight: 600,
    height: 26,
    ...(status === "ขอรับรองเวลาทำงาน" && {
      backgroundColor: "#fffcd6",
      color: "#eebb00",
      "& .MuiSvgIcon-root": {
        color: "#eebb00",
      },
      "&.MuiButtonBase-root:hover": {
        backgroundColor: "#fff9ab",
      },
    }),
    ...(status === "ทำงานล่วงเวลา" && {
      backgroundColor: "#e9f0ff",
      color: "#1e4db6",
      "& .MuiSvgIcon-root": {
        color: "#1e4db6",
      },
      "&.MuiButtonBase-root:hover": {
        backgroundColor: "#d4e0f9",
      },
    }),
    ...(status === "ขาดงาน" && {
      backgroundColor: "#fdf3f5",
      color: "#fc4b6c",
    }),
    ...(status === "ลางาน" && {
      backgroundColor: "#ecfff5",
      color: "#38ca7f",
    }),
    ...(status === "มาสาย" && {
      backgroundColor: "#ddf0ff",
      color: "#1997f5",
    }),
    ...(status === "วันหยุดประจำปี" && {
      backgroundColor: "#f7f5ff",
      color: "#7451ff",
    }),
  
    ...((status === "ลาป่วย" || status === "ลากิจ" || status === "ลาพักร้อน") && {
      backgroundColor: "#ecfff5",
      color: "#38ca7f",
    }),
}));

const StyledDivRequest = styled("div")({
    "& .row":{
        marginTop: 8, 
        display:"flex"
    },
    "& .date-time":{
        display:"flex",
        flexDirection:"column",
        flexBasis:"50%",
        "& .text":{
            fontSize: "14px",
            fontWeight: 700
        }
    },
    "& .text-reason":{
        fontSize:"14px",
        color:"#757575"
    }
});

const DivRequest = (props) => {
    const { request, mode, handleClickEdit, row } = props;
    const { result: payrollSettingList } = useSelector(
        (state) => state.payrollSetting
    );

    const whatRequestTimeLevel = (request) => {
        if (request.isDoubleApproval) {
          if (request.isManagerLV1Approve === 1) {
            return 2;
          } else {
            return 1;
          }
        } else {
          return null;
        }
    };

    const isRequestApprove = (request) => {
        if (request.isActive === 0) {
          return 3;
        }
        if (request.isDoubleApproval === 1) {
          if (
            request.isManagerLV1Approve === 0 ||
            request.isManagerLV2Approve === 0
          ) {
            return 2;
          } else if (
            request.isManagerLV1Approve === null ||
            request.isManagerLV2Approve === null
          ) {
            return null;
          } else if (
            request.isManagerLV1Approve === 1 &&
            request.isManagerLV2Approve === 1
          ) {
            return 1;
          }
        } else {
          if (request.approvalLevel === 1) {
            if (request.isManagerLV1Approve === 0) {
              return 2;
            } else {
              return request.isManagerLV1Approve;
            }
          } else if (request.approvalLevel === 2) {
            if (request.isManagerLV2Approve === 0) {
              return 2;
            } else {
              return request.isManagerLV2Approve;
            }
          }
        }
    };

    const renderOT = (row) => {
        const findPayroll = payrollSettingList.find(
          (x) => x.idCompany === row.idCompany && x.idVendor === row.idVendor
        );
    
        const payrollSetting = findPayroll ? findPayroll : payrollSettingList[0];
    
        let textRow = [];
        if (payrollSetting) {
          if (row.xOT > 0) {
            textRow.push(
              <Typography style={{ fontSize: "14px", fontWeight: 500 }} color="text.third" key="xOT">
                {`OT x ${payrollSetting.xOT}`} ={" "}
                {parseFloat(row.xOT / 60).toFixed(2)} ชม.
              </Typography>
            );
          }
          if (row.xWorkingDailyHoliday > 0) {
            textRow.push(
              <Typography style={{ fontSize: "14px", fontWeight: 500 }} color="text.third" key="xWorkingDailyHoliday">
                {`OT x ${payrollSetting.xWorkingDailyHoliday}`} ={" "}
                {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)} ชม.
              </Typography>
            );
          }
          if (row.xWorkingMonthlyHoliday > 0) {
            textRow.push(
              <Typography style={{ fontSize: "14px", fontWeight: 500 }} color="text.third" key="xWorkingMonthlyHoliday">
                {`OT x ${payrollSetting.xWorkingMonthlyHoliday}`} ={" "}
                {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)} ชม.
              </Typography>
            );
          }
          if (row.xOTHoliday > 0) {
            textRow.push(
              <Typography style={{ fontSize: "14px", fontWeight: 500 }} color="text.third" key="xOTHoliday">
                {`OT x ${payrollSetting.xOTHoliday}`} ={" "}
                {parseFloat(row.xOTHoliday / 60).toFixed(2)} ชม.
              </Typography>
            );
          }
          if (textRow.length === 0) {
            return <Typography color="text.third">{""}</Typography>;
          } else {
            return textRow;
          }
        }
    };

    return (
        <StyledDivRequest>
            <div
                className="row"
                style={{ alignItems:"center" }}
            >
                <div style={{ marginRight: 8 }}>
                    <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        status={
                            mode === "leave" ?
                            (
                                request.isActive === 0
                                ? 3
                                : request.isApprove === 1
                                ? 1
                                : request.isApprove === 0
                                ? 2
                                : null
                            ) : isRequestApprove(request)
                        }
                        active={request.isActive}
                    />
                </div>
                <Chip
                    variant="outlined"
                    icon={
                        <PlaylistAddCircleRoundedIcon
                            style={{ 
                                color: mode === "request" ? 
                                "#eebb00" : 
                                mode === "ot" ? 
                                "#1e4db6" : "#ff6d00"
                            }}
                        />
                    }
                    onClick={() => {
                        if (mode === "ot" && ![2, 3].includes(isRequestApprove(request))){
                            handleClickEdit(row, request);
                        }
                    }}
                    label={
                        mode === "request" ?
                        "คำขอรับรองเวลาทำงาน" : 
                        mode === "ot" ? 
                        "คำขอทำงานล่วงเวลา" : "คำขอลางาน"
                    }
                />
            </div>
            <div className="row" style={{ margin:"16px 0" }}>
                <div className="date-time">
                    {!(mode === "leave" && request.isFullDay === 1) &&
                        <Typography align='center' className="text">
                            {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                            "HH:mm"
                            )}
                        </Typography>
                    }
                    <Typography align='center' className="text">
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                    </Typography>
                </div>
                <div className="date-time">
                    {!(mode === "leave" && request.isFullDay === 1) &&
                        <Typography align='center' className="text">
                            {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                            "HH:mm"
                            )}
                        </Typography>
                    }
                    <Typography align='center' className="text">
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                    </Typography>
                </div>
            </div>
            <div style={{ marginBottom: 16 }}>
                <Typography>
                    {payrollSettingList && renderOT(request)}
                </Typography>
            </div>
            {mode !== "leave" ?
                <div>
                    <Typography className="text-reason">
                        {`เหตุผล : ${request.reasonName ? request.reasonName : ""}`}
                    </Typography>
                    {request.otherReason && 
                        <Fragment>
                            <Typography className="text-reason">
                                {`เหตุผลเพิ่มเติม : `}
                            </Typography>
                            <Typography className="text-reason">
                                {request.otherReason}
                            </Typography>
                        </Fragment>
                    }
                </div>
                :
                <div>
                    <Typography className="text-reason">
                        {`ประเภท: ${request.name}`}
                    </Typography>
                    {request.description && 
                        <Fragment>
                            <Typography className="text-reason">
                                {`หมายเหตุ: `}
                            </Typography>
                            <Typography className="text-reason">
                                {request.description}
                            </Typography>
                        </Fragment>
                    }
                </div>
            }
            
        </StyledDivRequest>
    );
};

const CardDate = (props) => {
    const { row, handleClickOpenAddNewTimeline, handleClickEdit, mode, keys } = props;
    const [attendanceList, setAttendanceList] = useState([]);
    const [openRequest, setOpenRequest] = useState(false);

    useEffect(() => {
        console.log("row : ",row);
        if(row && (
            row.attendanceDisplay &&
            row.attendanceDisplay.length > 0
        )){
            setAttendanceList(row.attendanceDisplay);
        } else {
            setAttendanceList([]);
        }
    },[row]);

    return (
        <StyledCard day={dayjs(row.date).format("d")} key={keys}>
            <div className="day-container">
                {dayjs(row.date).format("dd")}
            </div>
            <div className="date-detail">
                <CardActionArea
                    onClick={() => {
                        if (mode === "user" || mode === "admin") {
                            handleClickOpenAddNewTimeline(row);
                        }
                    }}
                >
                    <div>
                        <div className="container-flex-wrap">
                            <div style={{ width:"fit-content" }}>
                                <Typography className="fontWeight">{dayjs(row.date).format("DD/MM/YYYY")}</Typography>
                            </div>
                            <div style={{ width:"fit-content" }}>
                                <Typography>
                                    {row.pattern.isWorkingDay === 1
                                        ? row.pattern.isTimeFrame === 1 ? 
                                        `${dayjs(row.pattern.timeIn, "HH:mm:ss").format(
                                            "HH:mm"
                                            )} - ${dayjs(row.pattern.timeOut, "HH:mm:ss").format("HH:mm")}`
                                        : ""
                                        : row.pattern.shiftTypeName}
                                </Typography>
                            </div>
                        </div>
                        {attendanceList.length <= 0 ? (
                            <div 
                                className="noCheckInOut"
                            >
                                <Link
                                    component="button"
                                    variant="body2"
                                    // onClick={() => {
                                    //     handleClickOpenAddNewTimeline(row);
                                    // }}
                                >ไม่มีเวลาทำงาน</Link>
                            </div>
                            ) : (
                            <Fragment>
                                {attendanceList.map((attendance, index) => (
                                    <div 
                                        className="container-flex-wrap"
                                    >
                                        <div className="check-container">
                                            {attendance.checkIn ? 
                                                <Fragment>
                                                    <StyledChipTime 
                                                        icon={
                                                            attendance.checkIn.idAttendanceType === 2 ? (
                                                                <FingerprintIcon />
                                                            ) : attendance.checkIn.idAttendanceType === 5 ? (
                                                                <QrCodeIcon />
                                                            ) :(
                                                                <LocationOnIcon />
                                                            )
                                                        }
                                                        className="check-in"
                                                        label={attendance.checkIn.attendanceTextTime}
                                                    />
                                                    {attendance.checkIn.idAttendanceType === 5 ? (
                                                        <Typography
                                                            variant="caption"
                                                            color="text.secondary"
                                                            className="location"
                                                        >
                                                            {attendance.checkIn.groupGpsLocationsName || ""}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            variant="caption"
                                                            color="text.secondary"
                                                            className="location"
                                                        >
                                                            {attendance.checkIn.gpsLocationsName || ""}
                                                        </Typography>
                                                    )}
                                                </Fragment>
                                                :
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    // onClick={() => {
                                                    //     if (mode === "user" || mode === "admin") {
                                                    //         handleClickOpenAddNewTimeline(row);
                                                    //     }
                                                    // }}
                                                >ไม่มีเวลาเข้างาน</Link>
                                            }
                                        </div>
                                        <div className="check-container">
                                            {attendance.checkOut ? 
                                                <Fragment>
                                                    <StyledChipTime 
                                                        icon={
                                                            attendance.checkOut.idAttendanceType === 2 ? (
                                                                <FingerprintIcon />
                                                            ) : attendance.checkOut.idAttendanceType === 5 ? (
                                                                <QrCodeIcon />
                                                            ) :(
                                                                <LocationOnIcon />
                                                            )
                                                        }
                                                        className="check-out"
                                                        label={attendance.checkOut.attendanceTextTime}
                                                    />
                                                    {attendance.checkOut.idAttendanceType === 5 ? (
                                                        <Typography
                                                            variant="caption"
                                                            color="text.secondary"
                                                            className="location"
                                                        >
                                                            {attendance.checkOut.groupGpsLocationsName || ""}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            variant="caption"
                                                            color="text.secondary"
                                                            className="location"
                                                        >
                                                            {attendance.checkOut.gpsLocationsName || ""}
                                                        </Typography>
                                                    )}
                                                </Fragment>
                                                :
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    // onClick={() => {
                                                    //     if (mode === "user" || mode === "admin") {
                                                    //         handleClickOpenAddNewTimeline(row);
                                                    //     }
                                                    // }}
                                                >ไม่มีเวลาออกงาน</Link>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </Fragment>
                        )}
                    </div>
                </CardActionArea>
                <div 
                    className="container-flex-wrap" 
                    style={{ 
                        flexWrap:"wrap-reverse", 
                        marginBottom: 0, 
                        alignItems:"flex-end" 
                    }}
                >
                    <div 
                        style={{ display:"flex" }} 
                        onClick={() => {
                            if(
                                (row.requestTime && row.requestTime.length > 0) || 
                                (row.ot && row.ot.length > 0) ||
                                (row.leave && row.leave.length > 0)
                            ){
                                setOpenRequest(!openRequest);
                            }
                        }}
                    >
                        {row.requestTime && row.requestTime.length > 0 && (
                            <PlaylistAddCircleRoundedIcon style={{ color: "#eebb00", marginBottom: 8 }} />
                        )}
                        {row.ot && row.ot.length > 0 && (
                            <PlaylistAddCircleRoundedIcon style={{ color: "#1e4db6", marginBottom: 8 }} />
                        )}
                        {row.leave && row.leave.length > 0 && (
                            <PlaylistAddCircleRoundedIcon style={{ color: "#ff6d00", marginBottom: 8 }} />
                        )}
                    </div>
                    <div style={{ display:"flex", flexWrap:"wrap", justifyContent:"flex-end" }}>
                        {row.holiday && (
                            <div className="item">
                                <StyledChip
                                    label={"วันหยุดประจำปี"}
                                    status={"วันหยุดประจำปี"}
                                />
                            </div>
                        )}
                        {row.isLate && (
                            <div className="item">
                                <StyledChip label={"มาสาย"} status={"ขาดงาน"} />
                            </div>
                        )}
                        {row.isEarlyOut && (
                            <div className="item">
                                <StyledChip label={"กลับก่อน"} status={"ขาดงาน"} />
                            </div>
                        )}
                        {row.absent && (
                            <div className="item">
                                <StyledChip label={"ขาดงาน"} status={"ขาดงาน"} />
                            </div>
                        )}
                        {(
                            !row.absent && 
                            row.isNotFullDay &&
                            row.pattern.isTimeFrame === 0 && 
                            row.pattern.workingHours
                         ) && (
                            <div className="item">
                                <StyledChip label={"เวลางานไม่ครบ"} status={"ขาดงาน"} />
                            </div>
                        )}
                        {row.pattern.idWorkingType === 1 &&
                            row.pattern.isCompensationHoliday === 1 && (
                                <div className="item">
                                    <StyledChip
                                        label={"วันหยุดชดเชยประเภทกะ"}
                                        status={"วันหยุดประจำปี"}
                                    />
                                </div>
                        )}
                        {row.pattern.holidayName && (
                            <div className="item">
                                <Typography color="text.third" variant="body2">
                                    {row.pattern.holidayName}
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>
                {openRequest && 
                    <Fragment>
                        <div
                            style={{
                                width:"100%",
                                height:"2px",
                                borderRadius:"16px",
                                backgroundColor:"#A7A7A7"
                            }}
                        ></div>
                        <div 
                            style={{ marginBottom: 8 }}
                            onClick={() => setOpenRequest(!openRequest)}
                        >
                            {row.requestTime && 
                             row.requestTime.map((request, index) => {
                                if(index === 0){
                                    return (
                                        <DivRequest 
                                            key={index}
                                            request={request}
                                            mode="request"
                                        />
                                    );
                                } else {
                                    return (
                                        <Fragment key={index}>
                                            <div
                                                style={{
                                                    width:"100%",
                                                    height:"1px",
                                                    borderRadius:"16px",
                                                    backgroundColor:"#CDCDCD",
                                                    marginTop: 8
                                                }}
                                            ></div>
                                            <DivRequest 
                                                key={index}
                                                request={request}
                                                mode="request"
                                            />
                                        </Fragment>
                                    );
                                }
                            })}
                            {row.ot && 
                             row.ot.map((ot, index) => {
                                if(index === 0 && (!row.requestTime || row.requestTime.length <= 0)){
                                    return (
                                        <DivRequest 
                                            key={index}
                                            request={ot}
                                            mode="ot"
                                            row={row}
                                            handleClickEdit={handleClickEdit}
                                        />
                                    );
                                }else{
                                    return (
                                        <Fragment key={index}>
                                            <div
                                                style={{
                                                    width:"100%",
                                                    height:"1px",
                                                    borderRadius:"16px",
                                                    backgroundColor:"#CDCDCD",
                                                    marginTop: 8
                                                }}
                                            ></div>
                                            <DivRequest 
                                                key={index}
                                                request={ot}
                                                mode="ot"
                                                row={row}
                                                handleClickEdit={handleClickEdit}
                                            />
                                        </Fragment>
                                    );
                                }
                             })
                            }
                            {row.leave &&
                             row.leave.map((request, index) => {
                                if(index === 0 && (
                                    (!row.requestTime || row.requestTime.length <= 0) &&
                                    (!row.ot || row.ot.length <= 0)
                                )){
                                    return (
                                        <DivRequest 
                                            key={index}
                                            request={request}
                                            mode="leave"
                                        />
                                    );
                                } else {
                                    return (
                                        <Fragment key={index}>
                                            <div
                                                style={{
                                                    width:"100%",
                                                    height:"1px",
                                                    borderRadius:"16px",
                                                    backgroundColor:"#CDCDCD",
                                                    marginTop: 8
                                                }}
                                            ></div>
                                            <DivRequest 
                                                key={index}
                                                request={request}
                                                mode="leave"
                                            />
                                        </Fragment>
                                    );
                                }
                             })
                            }
                        </div>
                    </Fragment>
                }
            </div>
            
        </StyledCard>
    );
};

export default CardDate;