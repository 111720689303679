import React, { Fragment, useState } from "react";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, IconButton, Typography, styled } from "@mui/material";
import dayjs from "dayjs";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import utils from "../../../../../utils";

const AccordionStyled = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryStyled = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionRow = (props) => {

  const {data, setDrawerConfig, setDeleteConfig} = props;

  const [isExpand, setIsExpand] = useState(false);

  const renderAfter = (item) => {
    if(item.canRequestAfter === 0 || item.canRequestAfter === null){
      return "เริ่มงานวันแรก"
    } else if (item.canRequestAfter < 365) {
      return `อายุงานครบ ${item.canRequestAfter} วัน`
    } else {
      return `อายุงานครบ ${item.canRequestAfter / 365} ปี`
    }
  }

  const renderGender = (item) => {
    if(item.gender === null){
      return "ทุกเพศ"
    } else if(item.gender === "Male") {
      return "ชาย"
    } else if(item.gender === "Female") {
      return "หญิง"
    }
  }

  const renderProRateRound = (item) => {
    if((item.proRateRound === 1)){
      return "ไม่มีการปัดเศษ"
    } else if(item.proRateRound === 0.5) {
      return "ปัดขึ้นเมื่อมากกว่า 0.5"
    } else if(item.proRateRound === 0)  {
      return "ปัดขึ้นทุกกรณี"
    } else {
      return "-"
    }
  }

  const renderMinLeave = (item) => {
    if(item.minLeave === 100){
      return "เต็มวัน"
    } else if(item.minLeave === 50){
      return "ครึ่งวัน"
    } else if(item.minLeave === 10){
      return "1 ชั่วโมง"
    } else if(item.minLeave === 5){
      return "ครึ่งชั่วโมง"
    } else {
      return "ไม่มีขั้นต่ำ"
    }
  }

  const getEmploymentTypeName = (list) => {
    const groupEmploymentTypeName = new Map();
    list.map(item => {
      groupEmploymentTypeName.set(item.idEmploymentType, item.employmentTypeName || "ทั้งหมด")
    })
    return Array.from(groupEmploymentTypeName, ([_, value]) => value).join(", ")
  }

  const getGroupLevelName = (list) => {
    const groupLevelMap = new Map();
    list.map(item => {
      groupLevelMap.set(item.idGroupLevel, item.groupLevelName || "ทั้งหมด")
    })
    return Array.from(groupLevelMap, ([_, value]) => value).join(", ")
  }

  return (
    <AccordionStyled
      expanded={isExpand}
      onChange={() => {setIsExpand(prev => !prev)}}
    >
      <AccordionSummaryStyled>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Typography flexGrow="1">{data.leaveGroupName}</Typography>
          <Box>
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation();
                setDrawerConfig(prev => ({
                  ...prev,
                  isOpen: true,
                  isEdit: true,
                  data: {
                    idLeaveGroup: data.idLeaveGroup,
                    leaveGroupName: data.leaveGroupName,
                    leaveTypeList: data.leaveTypeList
                  }
                }))
              }}
            >
              <EditNoteRoundedIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteConfig(prev => ({
                  ...prev,
                  isOpen: true,
                  data: {
                    idLeaveGroup: data.idLeaveGroup,
                    leaveGroupName: data.leaveGroupName
                  }
                }))
              }}
            >
              <DeleteOutlineRoundedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <Grid container spacing={2}>
          {data.leaveTypeList && data.leaveTypeList.map((item, index) => (
            <Grid item container spacing={2}>
              <Grid key={`leaveType_${item.idLeaveType}`} item xs={12}>
                {/* <Box 
                  sx={{
                    
                  }}
                ></Box> */}
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px"
                  }}
                  fontSize="20px"
                  fontWeight="500"
                >
                  กลุ่มประเภทพนักงานที่ {index+1}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">ประเภทพนักงาน</Typography>
                <Typography>{getEmploymentTypeName(item.leaveTypeEmploymentTypeList)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">ระดับงาน</Typography>
                <Typography>{getGroupLevelName(item.leaveTypeEmploymentTypeList)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">จำนวนวันลา</Typography>
                <Typography>{item.isLeaveStep? "มีเงื่อนไข": item.leaveValue === null? "ไม่จำกัด": `${item.leaveValue} วัน`}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">สะสมไปรอบถัดไปได้ไม่เกิน</Typography>
                <Typography>{item.isLeaveStep? "มีเงื่อนไข": item.carryValue === null? "ไม่จำกัด": `${item.carryValue} วัน`}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">จ่ายเงิน</Typography>
                <Typography>{item.isPaid? "ใช่": "ไม่ใช่"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">สามารถขอลาได้หลังจาก</Typography>
                <Typography>{renderAfter(item)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">เพศ</Typography>
                <Typography>{renderGender(item)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">อนุญาติให้ลาขั้นต่ำ</Typography>
                <Typography>{renderMinLeave(item)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">Prorate</Typography>
                <Typography>{item.isProRate? "มี": "ไม่มี"}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">การปัดเศษทศนิยม Prorate</Typography>
                <Typography>{renderProRateRound(item)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize="14px" fontWeight="500" color="text.secondary">ต้องลาล่วงหน้า</Typography>
                <Typography>{item.daysInAdvance? `${item.daysInAdvance} วัน`: "ไม่กำหนด"}</Typography>
              </Grid>
              {(item.isLeaveStep === 1 && item.leaveStepList.length > 0) && <Grid item xs={12}>
                <Typography fontSize="18px" fontWeight="500" marginBottom="8px">เงื่อนไข</Typography>
                <Grid container spacing={2}>
                  {item.leaveStepList.map(step => (
                    <Fragment>
                      <Grid item xs={6} md={4}>
                        <Typography fontSize="14px" fontWeight="500" color="text.secondary">อายุงาน</Typography>
                        <Typography>{`${step.lessThanYear}${step.moreThanYears === 99? " ปีขึ้นไป": ` - ${step.moreThanYears} ปี`}`}</Typography>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Typography fontSize="14px" fontWeight="500" color="text.secondary">จำนวนวันลา</Typography>
                        <Typography>{step.leaveValue}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography fontSize="14px" fontWeight="500" color="text.secondary">สะสมไปรอบถัดไปได้ไม่เกิน</Typography>
                        <Typography>{step.carryValue}</Typography>
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Grid>}
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </AccordionStyled>
  )
}

export default AccordionRow;