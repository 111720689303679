import { IDVPLANS_FETCHING, IDVPLANS_FAILED, IDVPLANS_SUCCESS } from "./types";

import IdvPlanService from "../services/IndividualPlans.service";

export const getAllIdvPlan = () => async (dispatch) => {
  try {
    const res = await IdvPlanService.getAllIdvPlan();
    if (res) {
      dispatch({
        type: IDVPLANS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: IDVPLANS_FAILED,
    });
    console.log(err);
  }
};

export const getIdvPlanById = (id) => async () => {
  try {
    const res = await IdvPlanService.getIdvPlanById(id);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const postIdvPlan = (formData) => async () => {
  try {
    const res = await IdvPlanService.postIdvPlan(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putIdvPlan = (id, formData) => async () => {
  try {
    const res = await IdvPlanService.putIdvPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putApprovalIdvPlan = (id, formData) => async () => {
  try {
    const res = await IdvPlanService.putApprovalIdvPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putEvaluateMngIdvPlan = (id, formData) => async () => {
  try {
    const res = await IdvPlanService.putEvaluateMngIdvPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putEvaluationIdvPlan = (id, formData) => async () => {
  try {
    const res = await IdvPlanService.putEvaluationIdvPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteIdvPlan = (id, formData) => async () => {
  try {
    const res = await IdvPlanService.deleteIdvPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
