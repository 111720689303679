import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";

const StyledRoot = styled("div")({
  marginBottom: 16,
  "& .empInfoHead": {
    fontSize: "0.9rem !important",
    color: "#757575",
    marginBottom: 4
  },
  "& .empInfoBody": {
    fontSize: "0.9rem !important",
    marginBottom: 4
  },
});

function EmpSlipInfo(props) {
  const { currentSlip } = props;

  return (
    <StyledRoot>
      <Box>
        <Grid container>
          <Grid container item xs={8} md={8} lg={8} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className="empInfoHead">ชื่อ - นามสกุล :</Typography>
            </Grid>
            <Grid item sx xs={8} md={8} lg={8}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography className="empInfoBody">
                    {`${currentSlip.title_TH} ${currentSlip.firstname_TH} ${currentSlip.lastname_TH} (${currentSlip.employeeID})`}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={4} md={4} lg={4} spacing={2}>
            <Grid item xs>
              <Typography className="empInfoHead">บัญชีธนาคาร :</Typography>
            </Grid>
            <Grid item xs>
              <Typography className="empInfoBody">
                {currentSlip.bookID}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid container item xs={8} md={8} lg={8} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className="empInfoHead">บริษัท :</Typography>
            </Grid>
            <Grid item sx xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {currentSlip.companyName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={4} md={4} lg={4} spacing={2}>
            <Grid item xs>
              <Typography className="empInfoHead">วันที่เริ่มต้น :</Typography>
            </Grid>
            <Grid item xs>
              <Typography className="empInfoBody">
                {dayjs(currentSlip.start).format("DD/MM/BBBB")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid container item xs={8} md={8} lg={8} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className="empInfoHead">หน่วยงาน:</Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {currentSlip.departmentName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={4} md={4} lg={4} spacing={2}>
            <Grid item xs>
              <Typography className="empInfoHead">วันที่สิ้นสุด :</Typography>
            </Grid>
            <Grid item xs>
              <Typography className="empInfoBody">
                {dayjs(currentSlip.end).format("DD/MM/BBBB")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid container item xs={8} md={8} lg={8} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className="empInfoHead">แผนก:</Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className="empInfoBody">
                {currentSlip.sectionName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={4} md={4} lg={4} spacing={2}>
            <Grid item xs>
              <Typography className="empInfoHead">วันที่จ่าย :</Typography>
            </Grid>
            <Grid item xs>
              <Typography className="empInfoBody">
                {currentSlip.payDate
                  ? dayjs(currentSlip.payDate).format("DD/MM/BBBB")
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledRoot>
  );
}

export default EmpSlipInfo;
