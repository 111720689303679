import { httpClient } from "./httpClient";

const getCompanyProfile = () => {
  return httpClient.get(`/company/profile`);
};

const putCompanyProfile = (formData) => {
  return httpClient.put(`/company/profile`, formData);
};

const putCompanyProfileMainContact = (id, formData) => {
  return httpClient.put(`/company/${id}/profile/main-contact`, formData);
};

const postCompanyAddress = (formData) => {
  return httpClient.post(`/company/${formData.idCompany}/address`, formData);
};

const putCompanyAddress = (id, formData) => {
  return httpClient.put(`/company/${id}/address/${formData.idCompanyAddress}`, formData);
};

const getCompanyOrganization = () => {
  return httpClient.get(`/company/organization`);
};

const getDepartment = () => {
  return httpClient.get(`/departments`);
};

const getDepartmentByIdCompany = (id) => {
  return httpClient.get(`/departments/${id}`);
};

const getWorkInfoByidCompany = () => {
  return httpClient.get(`work-info`);
};

const addCompanyLogo = (formData) => {
  return httpClient.post("company/manage/logo",formData);
};

const deleteCompanyLogo = () => {
  return httpClient.delete("company/manage/logo");
};

const addCompanyAuthorizedSignatureOne = (formData) => {
  return httpClient.post("company/manage/authorizedSignatureOne", formData);
};

const deleteCompanyAuthorizedSignatureOne = () => {
  return httpClient.delete("company/manage/authorizedSignatureOne");
};

const addCompanyAuthorizedSignatureTwo = (formData) => {
  return httpClient.post("company/manage/authorizedSignatureTwo", formData);
};

const deleteCompanyAuthorizedSignatureTwo = () => {
  return httpClient.delete("company/manage/authorizedSignatureTwo");
};

const addCompanyWitnessSignature = (formData) => {
  return httpClient.post("company/manage/witnessSignature", formData);
};

const deleteCompanyWitnessSignature = () => {
  return httpClient.delete("company/manage/witnessSignature");
};

const addCompanyCertificateSignature = (formData) => {
  return httpClient.post("company/manage/certificateSignature", formData);
};

const deleteCompanyCertificateSignature = () => {
  return httpClient.delete("company/manage/certificateSignature");
};

const getallCompany = () =>{
  return httpClient.get(`/company/allcompany`)
}

const getStandardMenuCompany = () =>{
  return httpClient.get(`/standard-menu`)
}

const getAffiliate = () =>{
  return httpClient.get(`/affiliate`)
}

const getAllAdmin = () =>{
  return httpClient.get(`/admin`)
}

const updateAdmin = (formData) => {
  return httpClient.put(`/admin`, formData);
};

export default {
  getCompanyProfile,
  putCompanyProfileMainContact,
  postCompanyAddress,
  putCompanyAddress,
  getCompanyOrganization,
  getDepartment,
  putCompanyProfile,
  getWorkInfoByidCompany,
  addCompanyLogo,
  deleteCompanyLogo,
  addCompanyAuthorizedSignatureOne,
  deleteCompanyAuthorizedSignatureOne,
  addCompanyAuthorizedSignatureTwo,
  deleteCompanyAuthorizedSignatureTwo,
  addCompanyWitnessSignature,
  deleteCompanyWitnessSignature,
  getallCompany,
  getStandardMenuCompany,
  addCompanyCertificateSignature,
  deleteCompanyCertificateSignature,
  getAffiliate,
  getAllAdmin,
  updateAdmin,
  getDepartmentByIdCompany
};
