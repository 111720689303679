import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssetManagement,
  getAssetManagement,
  updateAssetManagement,
} from "../../../../actions/assetManagement";

const DialogCreate = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.employees);

  const defaultData = {
    name: "",
    idAsset: "",
    employee: null,
    receivedDate: dayjs(),
    returnDate: dayjs(),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(180).required("Name is required"),
    idAsset: Yup.string().max(30, "id asset must be at most 30 characters"),
    employee: Yup.object().required("Employee name is required").nullable(),
    receivedDate: Yup.date(),
    returnDate: Yup.date().min(
      Yup.ref("receivedDate"),
      "Return date can't be before Received date"
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultData,
    resolver: yupResolver(validationSchema),
  });

  const handleAddWarningLetter = async (values) => {
    const formData = {
      ...values,
      idEmployees: values.employee.idEmployees,
    };
    delete formData.employee;

    let res = null;
    if (data) {
      res = await dispatch(updateAssetManagement(formData));
    } else {
      res = await dispatch(addAssetManagement(formData));
    }

    if (res) {
      reset();
      handleClose();
      dispatch(getAssetManagement());
    }
  };

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        employee: {
          idEmployees: data.idEmployees,
          firstname_TH: data.firstname_TH,
          lastname_TH: data.lastname_TH,
        },
      });
    }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
      <DialogTitle>
        {data ? "แก้ไขรายการจัดการสินทรัพย์" : "สร้างรายการจัดการสินทรัพย์"}
      </DialogTitle>
      <form onSubmit={handleSubmit(handleAddWarningLetter)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="ชื่อสินทรัพย์"
                    error={Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="idAsset"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="รหัสสินทรัพย์"
                    error={Boolean(errors.idAsset)}
                    helperText={errors.idAsset && errors.idAsset.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="employee"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={employees}
                    isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                    getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.idEmployees}>
                          {`${option.firstname_TH} ${option.lastname_TH}`}
                        </li>
                      );
                    }}
                    onChange={(e, value) => field.onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="รายชื่อพนักงาน"
                        error={Boolean(errors.employee)}
                        helperText={errors.employee && errors.employee.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="receivedDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label="วันที่ได้รับของ"
                      views={["year", "month", "day"]}
                      renderInput={(params) => (
                        <TextField 
                          {...params} 
                          fullWidth
                          error={errors.receivedDate}
                          helperText={errors.receivedDate && errors.receivedDate.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="returnDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      minDate={watch("receivedDate")}
                      label="วันครบกำหนดคืน"
                      views={["year", "month", "day"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={errors.returnDate}
                          helperText={errors.returnDate && errors.returnDate.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" type="button" onClick={handleClose}>
            ยกเลิก
          </Button>
          <ButtonBlue variant="contained" type="submit">
            ยืนยัน
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreate;
