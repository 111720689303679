import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import dayjs from "dayjs";
import ChipWarningLevel from "../../admin/warningLetter/chipWarningLevel";
import ButtonBlue from "../../shared/general/ButtonBlue";

const StyledDivider = styled(Divider)({
  marginTop: "16px",
  borderColor: "rgba(145, 158, 171, 1)",
  borderStyle: "dashed",
  width: "100%",
});

const DialogDetail = ({ open, handleClose, data, handleUpdateStatus }) => {
  const [selectAccept, setSelectAccept] = useState(1);

  const handleChange = (event) => {
    setSelectAccept(event.target.value);
  };

  const handleAccept = () => {
    handleUpdateStatus({
      idWarningLetter: data.idWarningLetter,
      accept: selectAccept,
    });
  };

  useEffect(() => {
    if (data.accept !== null) {
      setSelectAccept(data.accept);
    }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
      <DialogTitle textAlign={"center"}>หนังสือเตือน</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="subtitle1"
              color="text.secondary"
              textAlign={"end"}
            >
              {`วันที่ออกหนังสือเตือน: ${dayjs(data.createDate).format("DD/MM/YYYY")}`}
            </Typography>
            <Typography gutterBottom>
              {`เรียน ${data.title_TH}${data.firstname_TH} ${data.lastname_TH}`}
            </Typography>
            {data.positionName && (
              <Typography>{`ตำแหน่ง: ${data.positionName} `}</Typography>
            )}
            {data.departmentName && (
              <Typography>{`ส่วนงาน: ${data.departmentName}`}</Typography>
            )}
            {data.sectionName && (
              <Typography>{`แผนก: ${data.sectionName}`}</Typography>
            )}
            {data.employeeID && (
              <Typography gutterBottom>
                {`รหัสประจำตัวพนักงาน: ${data.employeeID}`}
              </Typography>
            )}
            <Grid item xs={12}>
              <Typography gutterBottom>
                {`ท่านได้ถูกตักเตือนจากบริษัทเนื่องจาก ${data.title}`}
              </Typography>
              <Typography>{`จากเหตุที่ ${data.detail}`}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {"บริษัทจึงเห็นสมควรออกหนังสือเตือนท่านเป็นลายลักษณ์อักษร หากภายหลังปรากฏว่าท่านได้กระทำความผิดซ้ำอีก บริษัทจะพิจารณาลงโทษท่านในสถานหนักต่อไป"}
            </Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={2} flexWrap={"wrap"}>
            <Typography>ข้าพเจ้ารับทราบข้อความข้างต้นแล้ว</Typography>
            <FormControl>
              <InputLabel>ตัวเลือก</InputLabel>
              <Select
                value={selectAccept}
                label="ตัวเลือก"
                onChange={handleChange}
              >
                <MenuItem value={1}>ยอมรับ</MenuItem>
                <MenuItem value={0}>ไม่ยอมรับ</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <StyledDivider />
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={2} flexWrap={"wrap"}>
            <Typography fontSize={"1.25rem"} fontWeight={500}>
              ระดับการเตือน
            </Typography>
            <ChipWarningLevel id={data.idWarningLevel} label={data.level} />
            {data.file && (
              <Grid item xs={12} sm={3}>
                <Button
                  variant="outlined"
                  component={Link}
                  href={data.file}
                  target="_blank"
                  startIcon={<AttachFileIcon />}
                >
                  ไฟล์แนบ
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonBlue variant="contained" onClick={handleAccept}>
          ยืนยัน
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDetail;
