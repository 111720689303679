import {
  INDIVIDUAL_OBJECTIVE_FETCHING,
  INDIVIDUAL_OBJECTIVE_FAILED,
  INDIVIDUAL_OBJECTIVE_SUCCESS,
} from "./types";

import OkrsService from "../services/okrs.services"

export const getMetadataObjective = () => async (dispatch) => {
  try {
    const res = await OkrsService.getMetadataObjective()
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const addIndividualObjective = (formData) => async () => {
  try {
    const res = await OkrsService.addIndividualObjective(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
}

export const addTeamObjective = (formData) => async () => {
  try {
    const res = await OkrsService.addTeamObjective(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
}

export const addOrganizationObjective = (formData) => async () => {
  try {
    const res = await OkrsService.addOrganizationObjective(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
}

export const getMyIndividualObjective = () => async (dispatch) => {
  try {
    dispatch({
      type: INDIVIDUAL_OBJECTIVE_FETCHING,
    });
    const res = await OkrsService.getMyIndividualObjective();
    if (res) {
      dispatch({
        type: INDIVIDUAL_OBJECTIVE_SUCCESS,
        payload: res.data,
      });
      return res
    }
  } catch (err) {
    dispatch({
      type: INDIVIDUAL_OBJECTIVE_FAILED,
    });
    console.log(err);
    return err.response
  }
};

export const getTeamObjective = () => async (dispatch) => {
  try {
    const res = await OkrsService.getTeamObjective();
    if (res) {
      return res
    }
  } catch (err) {
    return err.response
  }
};

export const getOrganizationObjective = () => async (dispatch) => {
  try {
    const res = await OkrsService.getOrganizationObjective();
    if (res) {
      return res
    }
  } catch (err) {
    return err.response
  }
};

export const getIndividualObjectiveByIdIndividualObjective = (idIndividualObjective) => async (dispatch) => {
  try {
    const res = await OkrsService.getIndividualObjectiveByIdIndividualObjective(idIndividualObjective)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getTeamObjectiveByIdTeamObjective = (idTeamObjective) => async (dispatch) => {
  try {
    const res = await OkrsService.getTeamObjectiveByIdTeamObjective(idTeamObjective)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getOrganizationObjectiveByIdOrganizationObjective = (idOrganizationObjective) => async (dispatch) => {
  try {
    const res = await OkrsService.getOrganizationObjectiveByIdOrganizationObjective(idOrganizationObjective)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getAllObjectiveForApprove = () => async (dispatch) => {
  try {
    const res = await OkrsService.getAllObjectiveForApprove()
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getAllObjectiveForEvaluation = () => async (dispatch) => {
  try {
    const res = await OkrsService.getAllObjectiveForEvaluate()
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getIndividualObjectiveForApprove = (idIndividualObjective) => async (dispatch) => {
  try {
    const res = await OkrsService.getIndividualObjectiveForApprove(idIndividualObjective)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getIndividualObjectiveForEvaluate = (idIndividualObjective) => async (dispatch) => {
  try {
    const res = await OkrsService.getIndividualObjectiveForEvaluate(idIndividualObjective)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const approveIndividualKeyResult = (idIndividualKeyResult, formData) => async (dispatch) => {
  try {
    const res = await OkrsService.approveIndividualKeyResult(idIndividualKeyResult, formData)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const updateIndividualKeyResult = (idIndividualKeyResult, formData) => async (dispatch) => {
  try {
    const res = await OkrsService.updateIndividualKeyResult(idIndividualKeyResult, formData)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const updateTeamKeyResult = (idTeamKeyResult, formData) => async (dispatch) => {
  try {
    const res = await OkrsService.updateTeamKeyResult(idTeamKeyResult, formData)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const updateOrganizationKeyResult = (idOrganizationKeyResult, formData) => async (dispatch) => {
  try {
    const res = await OkrsService.updateOrganizationKeyResult(idOrganizationKeyResult, formData)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getAllIndividualKeyResultProgress = (idIndividualKeyResult) => async (dispatch) => {
  try {
    const res = await OkrsService.getAllIndividualKeyResultProgress(idIndividualKeyResult)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getAllTeamKeyResultProgress = (idTeamKeyResult) => async (dispatch) => {
  try {
    const res = await OkrsService.getAllTeamKeyResultProgress(idTeamKeyResult)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getAllOrganizationKeyResultProgress = (idOrganizationKeyResult) => async (dispatch) => {
  try {
    const res = await OkrsService.getAllOrganizationKeyResultProgress(idOrganizationKeyResult)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const addIndividualKeyResultProgress = (idIndividualKeyResult, formData) => async (dispatch) => {
  try {
    const res = await OkrsService.addIndividualKeyResultProgress(idIndividualKeyResult, formData)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const addTeamKeyResultProgress = (idTeamKeyResult, formData) => async (dispatch) => {
  try {
    const res = await OkrsService.addTeamKeyResultProgress(idTeamKeyResult, formData)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const addOrganizationKeyResultProgress = (idOrganizationKeyResult, formData) => async (dispatch) => {
  try {
    const res = await OkrsService.addOrganizationKeyResultProgress(idOrganizationKeyResult, formData)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getDashboardIndividual = (query) => async (dispatch) => {
  try {
    const res = await OkrsService.getDashboardIndividual(query)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getDashboardTeam = (query) => async (dispatch) => {
  try {
    const res = await OkrsService.getDashboardTeam(query)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}

export const getDashboardOrganization = (query) => async (dispatch) => {
  try {
    const res = await OkrsService.getDashboardOrganization(query)
    if(res){
      return res;
    }
  } catch (err) {
    return err.response;
  }
}
