import React, { Fragment, useState } from "react";
import { useDropzone } from "react-dropzone";

import AddIcon from "../../../assets/add.png";
import XlsxIcon from "../../../assets/xlsx.png";
import { Avatar, Box, IconButton, Typography, styled } from "@mui/material";
import { Controller } from "react-hook-form";

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const StyledRoot = styled(Box)({
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
})

const DragDrop = (props) => {

  const { useHookForm } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles, rejectedFiles) => {
      console.log(acceptedFiles)
      console.log("rejectedFiles", rejectedFiles)
      if(acceptedFiles){
        useHookForm.setValue("file", acceptedFiles[0])
      }
      if(rejectedFiles.length > 0 && rejectedFiles[0].errors){
        if(rejectedFiles[0].errors[0].code === 'file-invalid-type'){
          useHookForm.setError("file", {message: "นามสกุลไฟล์ต้องเป็น .xlsx เท่านั้น"})
        }
        if(rejectedFiles[0].errors[0].code === 'file-too-large'){
          useHookForm.setError("file", {message: "ขนาดไฟล์ต้องไม่เกิน 5 MB"})
        }
        if(rejectedFiles[0].errors[0].code === 'too-many-files'){
          useHookForm.setError("file", {message: "เลือกไฟล์ได้สูงสุด 1 ไฟล์เท่านั้น"})
        }
      }
    },
    multiple: false,
    maxSize: 1024*1024*5
});

const getSizeText = (size) => {
  const unit = ['K', 'M', 'G']
  let tempSize = size / 1024;
  let i = 0;
  while(tempSize > 1024 && i<2){
    tempSize = tempSize/1024
    i++
  }

  return `${Math.round(tempSize*100)/100} ${unit[i]}B`
}

  return (
    <StyledRoot>
      <Controller
        control={useHookForm.control}
        name="file"
        render={({field}) => (
          <Fragment>
            {field.value === null? (
              <Box>
                <div
                  {...getRootProps({
                    className: "dropzone-upload-file",
                  })}
                  style={{
                    userSelect: "none"
                  }}
                >
                  <input {...getInputProps()} />
                  <div className="inner-dropzone">
                    <img alt="AddIcon" src={AddIcon} width="80" style={{pointerEvents: "none"}}/>
                    <Typography
                      style={{
                        marginTop: 8,
                        backgroundColor: "transparent"
                      }}
                      className={`placeholderLabel`}
                      variant="body2"
                      color="text.secondary"
                    >
                      Drag & Drop หรือ คลิ๊กเพื่อเลือกไฟล์
                    </Typography>
                  </div>
                </div>
                {useHookForm.formState.errors[field.name] && (
                  <Typography margin="3px 0px 0px" fontSize="14px" color="error">{useHookForm.formState.errors[field.name].message}</Typography>
                )}
                <Typography marginTop="8px" fontSize="14px" color="text.secondary">*นามสกุลไฟล์ต้องเป็น .xlsx เท่านั้น และขนาดต้องไม่เกิน 5 MB สูงสุดได้ 1 ไฟล์</Typography>
              </Box>
            ): (
              <Box
                display="flex"
                padding="4px"
                style={{ 
                  border: "1px solid #D0D0D0",
                  borderStyle: "dashed",
                  borderRadius: 8,
                  userSelect: "none"
                }}
              >
                <Avatar
                  src={XlsxIcon} 
                  variant="rounded" 
                  style={{
                    marginRight: "8px",
                    width: "32px",
                    height: "32px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    pointerEvents: "none",
                  }}/>
                <Box minWidth="0" flexGrow="1">
                  <Typography fontSize="14px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">{field.value.name}</Typography>
                  <Typography fontSize="12px" color="text.secondary">{getSizeText(field.value.size)}</Typography>
                </Box>
                <IconButton
                  color="error"
                  onClick={() => {
                    field.onChange(null)
                  }}
                >
                  <ClearOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </Fragment>
        )}
      />
    </StyledRoot>
  )
}

export default DragDrop;