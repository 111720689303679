import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getAttendance = (id, filter) => {
  return httpClient.get(`/company/${id}/attendance?date=${filter.searchDate}`);
};

const getAttendanceById = (filter, idEmployees) => {
  if (idEmployees) {
    return httpClient.get(
      `/attendance/${idEmployees}?start=${dayjs(filter.start).format("YYYY-MM-DD")}&end=${dayjs(
        filter.end
      ).format("YYYY-MM-DD")}`
    );
  } else {
    return httpClient.get(
      `/attendance?start=${dayjs(filter.start).format("YYYY-MM-DD")}&end=${dayjs(
        filter.end
      ).format("YYYY-MM-DD")}`
    );
  }
  
};

const getAttendanceTransaction = (filter) => {
  return httpClient.get(
    `/attendance/transaction?start=${dayjs(filter.start).format("YYYY-MM-DD")}&end=${dayjs(
      filter.end
    ).format("YYYY-MM-DD")}`
  );
};

const getAttendanceOnce = (filter, mode) => {
  if (mode) {
    return httpClient.get(
      `/attendance-once?date=${filter.searchDate}&mode=${mode}`
    );
  } else {
    return httpClient.get(
      `/attendance-once?date=${filter.searchDate}`
    );
  }
};

const addAttendance = (formData) => {
  return httpClient.post(`/attendance/`, formData);
};

const getAllEmployeeTimeline = (idCompany, query) => {
  return httpClient.get(`/company/${idCompany}/attendance/v2`, { params: query });
}

const getWorkingHoursByIdEmployees = (idEmployees, query) => {
  return httpClient.get(`/working-hours/${idEmployees}`, { params: query });
}

const exportExcelEmployeeAttendanceMonthly = (query) => {
  return httpClient.get(`/export-excel-employee-attendance-monthly`, { params: query, responseType: 'blob' });
}

export default {
  getAttendance,
  getAttendanceTransaction,
  getAttendanceById,
  getAttendanceOnce,
  addAttendance,
  getAllEmployeeTimeline,
  getWorkingHoursByIdEmployees,
  exportExcelEmployeeAttendanceMonthly
};
