import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { Grid, styled, Typography } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import { updateUser } from "../../../../../../actions/user";
import { 
  getProvidentFundEmployeeByIdEmployee
} from '../../../../../../actions/providentFund';

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogPFDate = (props) => {
  const { open, onClose, values } = props;
  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [formData, setFormData] = useState({
    ServPFDate: dayjs(),
    PFDate: dayjs(),
  });

  useEffect(() => {
    if (values) {
      setFormData({
        ServPFDate: values.ServPFDate ? dayjs(values.ServPFDate) : dayjs(),
        PFDate: values.PFDate ? dayjs(values.PFDate) : dayjs(),
      });
    }
  }, [values]);

  const handleSubmit = async () => {
    let data = {
      ServPFDate: dayjs(formData.ServPFDate).format("YYYY-MM-DD"),
      PFDate: dayjs(formData.PFDate).format("YYYY-MM-DD"),
    };
    data.idEmployees = employeeProfile.idEmployees;
    const result = await dispatch(updateUser(data));
    if (result) {
      onClose();
      dispatch(getProvidentFundEmployeeByIdEmployee(employeeProfile.idEmployees));
    }
  };

  return (
    <DrawerCustom
      title="แก้ไขวันที่กองทุนสำรองเลี้ยงชีพ"
      anchor={"right"}
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>วันที่นับอายุงานในกองทุน</Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                inputFormat="DD/MM/YYYY"
                value={formData.ServPFDate}
                name="ServPFDate"
                onChange={(newValue) => {
                  setFormData({
                    ...formData,
                    ServPFDate: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextFieldTheme fullWidth {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>วันที่เข้ากองทุน</Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                inputFormat="DD/MM/YYYY"
                value={formData.PFDate}
                name="PFDate"
                onChange={(newValue) => {
                  setFormData({
                    ...formData,
                    PFDate: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextFieldTheme fullWidth {...params} />
                )}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={onClose}>
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogPFDate;
