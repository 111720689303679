import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Stack,
  Container,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  Chip,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Menu,
  Avatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import DialogCreate from "./dialogCreate";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../actions/employee";
import {
  deleteAssetManagement,
  getAssetManagement,
  updateAssetManagementStatus,
} from "../../../../actions/assetManagement";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const MenuStatus = ({ idAssetManagement }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateAssetManagementStatus = async (idAssetManagement, status) => {
    const res = await dispatch(
      updateAssetManagementStatus({ idAssetManagement, status })
    );
    if (res) {
      handleClose();
      dispatch(getAssetManagement());
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            handleUpdateAssetManagementStatus(idAssetManagement, 1)
          }
        >
          คืนแล้ว
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleUpdateAssetManagementStatus(idAssetManagement, 0)
          }
        >
          ยังไม่คืน
        </MenuItem>
      </Menu>
    </div>
  );
};

const AssetManagement = () => {
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.employees);
  const { result: assetManagement } = useSelector(
    (state) => state.assetManagement
  );

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [month, setMonth] = useState("");

  const handleChange = (event) => {
    setMonth(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectItem(null);
  };

  const handleClickOpenEdit = (item) => {
    setOpen(true);
    setSelectItem(item);
  };

  const handleOpenDelete = (item) => {
    setOpenDelete(true);
    setSelectItem(item);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteAssetManagement = async () => {
    const res = await dispatch(
      deleteAssetManagement(selectItem.idAssetManagement)
    );
    if (res) {
      handleCloseDelete();
      dispatch(getAssetManagement());
    }
  };

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAssetManagement());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <WrapHeader>
          <Typography variant="h4">Asset Management</Typography>
        </WrapHeader>
        <CardStyle style={{ padding: 16 }}>
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            alignItems={"center"}
            justifyContent={"space-between"}
            marginBottom={4}
            gap={2}
          >
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel>เลือกดูตามเดือน</InputLabel>
              <Select
                value={month}
                label="เลือกดูตามเดือน"
                onChange={handleChange}
              >
                <MenuItem value={""}>ทั้งหมด</MenuItem>
                <MenuItem value={0}>มกราคม</MenuItem>
                <MenuItem value={1}>กุมภาพันธ์</MenuItem>
                <MenuItem value={2}>มีนาคม</MenuItem>
                <MenuItem value={3}>เมษายน</MenuItem>
                <MenuItem value={4}>พฤษภาคม</MenuItem>
                <MenuItem value={5}>พฤษภาคม</MenuItem>
                <MenuItem value={6}>กรกฎาคม</MenuItem>
                <MenuItem value={7}>สิงหาคม</MenuItem>
                <MenuItem value={8}>กันยายน</MenuItem>
                <MenuItem value={9}>ตุลาคม</MenuItem>
                <MenuItem value={10}>พฤศจิกายน</MenuItem>
                <MenuItem value={11}>ธันวาคม</MenuItem>
              </Select>
            </FormControl>
            <ButtonBlue
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              เพิ่มรายการ
            </ButtonBlue>
          </Stack>
          {employees && open && (
            <DialogCreate
              open={open}
              handleClose={handleClose}
              data={selectItem}
            />
          )}
          {openDelete && (
            <Dialog open={openDelete}>
              <DialogTitle>ยืนยันการลบรายการ</DialogTitle>
              <DialogContent>
                <DialogContentText>รายการนี้จะถูกลบอย่างถาวร</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeleteAssetManagement}
                >
                  ยืนยัน
                </Button>
                <Button onClick={handleCloseDelete}>ยกเลิก</Button>
              </DialogActions>
            </Dialog>
          )}
          {assetManagement && assetManagement.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ชื่อพนักงาน</TableCell>
                    <TableCell align="center">รหัสสินทรัพย์</TableCell>
                    <TableCell align="center">ชื่อสินทรัพย์</TableCell>
                    <TableCell align="center">วันที่ได้รับของ</TableCell>
                    <TableCell align="center">วันครบกำหนดคืน</TableCell>
                    <TableCell align="left">สถานะ</TableCell>
                    <TableCell align="center">จัดการ</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assetManagement
                    .filter((value) =>
                      month === ""
                        ? true
                        : month === dayjs(value.dueDate).month()
                    )
                    .map((item) => (
                      <TableRow key={item.idAssetManagement}>
                        <TableCell align="center">
                          <Stack direction={"row"} gap={2}>
                            <Avatar src={item.imageURL} />
                            <div style={{ textAlign: "start" }}>
                              <Typography fontWeight={500}>
                                {`${item.firstname_TH} ${item.lastname_TH}`}
                              </Typography>
                              {item.positionName && (
                                <Typography variant="body2" color="text.secondary">
                                  {`ตำแหน่ง: ${item.positionName} `}
                                </Typography>
                              )}
                              {item.departmentName && (
                                <Typography variant="body2" color="text.secondary">
                                  {`ส่วนงาน: ${item.departmentName}`}
                                </Typography>
                              )}
                              {item.sectionName && (
                                <Typography variant="body2" color="text.secondary">
                                  {`แผนก: ${item.sectionName}`}
                                </Typography>
                              )}
                            </div>
                          </Stack>
                        </TableCell>
                        <TableCell align="center">{item.idAsset}</TableCell>
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">
                          {dayjs(item.receivedDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {item.returnDate ? dayjs(item.returnDate).format("DD/MM/YYYY") : "ไม่ได้กำหนด"}
                        </TableCell>
                        <TableCell align="center">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {item.status ? (
                              <Chip label="คืนแล้ว" color="success" />
                            ) : (
                              <Chip label="ยังไม่คืน" color="error" />
                            )}
                            <MenuStatus
                              idAssetManagement={item.idAssetManagement}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            style={{ color: "#ffea00" }}
                            onClick={() => handleClickOpenEdit(item)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            style={{ color: "#ff1744" }}
                            onClick={() => handleOpenDelete(item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>ไม่มีรายการ</Typography>
          )}
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default AssetManagement;
