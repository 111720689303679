import React, { useEffect, useState } from "react";
import { Avatar, Box, Container, Grid, MenuItem, SvgIcon, Typography, styled } from "@mui/material";
import CardStyle from "../../../../shared/general/Card";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { CheckRounded, Flag, Key, StarRounded } from "@mui/icons-material";
import { ReactComponent as MoonshotIcon } from "../../../../assets/moonshot_logo.svg"
import ChipStatus from "../../../../shared/pages/okrs/components/ChipStatus";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import { useDispatch } from "react-redux";
import { getDashboardIndividual } from "../../../../../../actions/okrs";
dayjs.extend(quarterOfYear)

const StyledRoot = styled(Box)({
  "& .MuiSelect-filled":{
    padding:"16px 32px 16px 12px"
  }
})

const DashboardIndividual = () => {

  const dispatch = useDispatch();

  const [yearOptions, setYearOptions] = useState([]);
  const [year, setYear] = useState(dayjs().get('year'))
  const [quarter, setQuarter] = useState(dayjs().quarter())

  const [dashboardData, setDashboardData] = useState(null);

  const getStatusAchieve = (status) => {
    if(status === 1){
      return 5
    } else if (status === 0){
      return 6
    }
  }

  useEffect(() => {
    let start = dayjs("2023-05-01");
    let tempYearOpt = [];
    let yearCount = dayjs().diff(start, "years", true) + 1
    yearCount = Math.floor(yearCount)

    for(let i=0; i<yearCount + 1; i++){
      tempYearOpt.push({
        value: start.add(i, "years").format("YYYY"),
        label: start.add(i, "years").format("BBBB")
      })
    }
    setYearOptions([...tempYearOpt])
  }, [])

  useEffect(() => {
    let isMounted = true;
    dispatch(getDashboardIndividual({year: year, quarter: quarter})).then(res => {
      if(isMounted){
        if(res.status === 200){
          setDashboardData(res.data)
        }
      }
    })

    return () => {isMounted = false}
  }, [year, quarter])

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <CardStyle>
            <Box padding="24px">
              <Typography fontSize="24px">มุมมองภาพรวมเป้าหมายรายบุคคล</Typography>
              <Box display="flex" justifyContent="flex-end" marginTop="16px" gap="8px">
                <Box width="100%" maxWidth="200px">
                  <Typography fontSize="14px" fontWeight="600" marginBottom="4px" color="text.third">ปี</Typography>
                  <TextFieldTheme
                    variant="filled"
                    value={year}
                    onChange={(event) => {
                      setYear(event.target.value)
                    }}
                    select
                  >
                    {yearOptions.map((y) => (
                      <MenuItem key={y.value} value={y.value}>{y.label}</MenuItem>
                    ))}
                  </TextFieldTheme>
                </Box>
                <Box width="100%" maxWidth="200px">
                  <Typography fontSize="14px" fontWeight="600" marginBottom="4px" color="text.third">ไตรมาส</Typography>
                  <TextFieldTheme
                    variant="filled"
                    value={quarter}
                    onChange={(event) => {
                      setQuarter(event.target.value)
                    }}
                    select
                  >
                    <MenuItem value="1">Q1</MenuItem>
                    <MenuItem value="2">Q2</MenuItem>
                    <MenuItem value="3">Q3</MenuItem>
                    <MenuItem value="4">Q4</MenuItem>
                  </TextFieldTheme>
                </Box>
              </Box>
            </Box>
          </CardStyle>

          {dashboardData && <Box marginTop="16px">
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <CardStyle>
                  <Box padding="24px" display="flex">
                    <Box paddingRight="16px">
                      <Avatar style={{width: "52px", height: "52px", backgroundColor: "#5b4589"}}>
                        <Flag style={{fontSize: "36px"}}/>
                      </Avatar>
                    </Box>
                    <Box flexGrow={1}>
                      <Typography fontSize="48px" fontWeight="500" lineHeight="1">{dashboardData.individualObjective}</Typography>
                      <Typography fontSize="14px" fontWeight="500" color="text.third" lineHeight="1">วัตถุประสงค์</Typography>
                    </Box>
                  </Box>
                </CardStyle>
              </Grid>
              <Grid item xs={6} md={3}>
                <CardStyle>
                  <Box padding="24px" display="flex">
                    <Box paddingRight="16px">
                      <Avatar style={{width: "52px", height: "52px", backgroundColor: "#ed94be"}}>
                        <Key style={{fontSize: "36px"}}/>
                      </Avatar>
                    </Box>
                    <Box flexGrow={1}>
                      <Typography fontSize="48px" fontWeight="500" lineHeight="1">{dashboardData.individualKeyResult}</Typography>
                      <Typography fontSize="14px" fontWeight="500" color="text.third" lineHeight="1">ผลลัพธ์หลัก</Typography>
                    </Box>
                  </Box>
                </CardStyle>
              </Grid>
              <Grid item xs={6} md={3}>
                <CardStyle>
                  <Box padding="24px" display="flex">
                    <Box paddingRight="16px">
                      <Avatar style={{width: "52px", height: "52px", backgroundColor: "#6FDFBD"}}>
                        <CheckRounded style={{fontSize: "36px"}}/>
                      </Avatar>
                    </Box>
                    <Box flexGrow={1}>
                      <Typography fontSize="48px" fontWeight="500" lineHeight="1">{dashboardData.keyResultSuccess}</Typography>
                      <Typography fontSize="14px" fontWeight="500" color="text.third" lineHeight="1">ผลลัพธ์หลักที่สำเร็จ</Typography>
                    </Box>
                  </Box>
                </CardStyle>
              </Grid>
              <Grid item xs={6} md={3}>
                <CardStyle>
                  <Box padding="24px" display="flex">
                    <Box paddingRight="16px">
                      <Avatar style={{width: "52px", height: "52px", backgroundColor: "#142766"}}>
                        <SvgIcon style={{fontSize: "36px"}}>
                          <MoonshotIcon />
                        </SvgIcon>
                      </Avatar>
                    </Box>
                    <Box flexGrow={1}>
                      <Typography fontSize="48px" fontWeight="500" lineHeight="1">{dashboardData.moonShot}</Typography>
                      <Typography fontSize="14px" fontWeight="500" color="text.third" lineHeight="1">Moon Shot</Typography>
                    </Box>
                  </Box>
                </CardStyle>
              </Grid>

              <Grid item xs={12} md={6}>
                <CardStyle>
                  <Box padding="24px" height="320px">
                    <Box display="flex" flexDirection="column" height="100%">
                      <Typography fontSize="18px">คะแนนเฉลี่ย</Typography>
                      <Box flexGrow="1" display="flex" alignItems="center" justifyContent="center">
                        <Avatar style={{width: "84px", height: "84px", marginRight: "16px", backgroundColor: "#ffdd40"}}>
                          <StarRounded style={{fontSize: "64px"}}/>
                        </Avatar>
                        <Typography fontSize="64px">{Math.round(dashboardData.averageRating * 100) / 100}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardStyle>
              </Grid>

              <Grid item xs={12} md={6}>
                <CardStyle>
                  <Box padding="24px" height="320px">
                    <Box display="flex" flexDirection="column" height="100%">
                      <Typography fontSize="18px">รายการผลลัพธ์หลักที่อัพเดทล่าสุด</Typography>
                      <Box marginTop="16px" flexGrow="1" overflow="auto">
                        <Grid container spacing={1}>
                          
                          {dashboardData.updatedKeyResult.map((keyResult, index) => (
                            <Grid key={index} item xs={12}>
                              <Box padding="8px" border="1px solid #919eab3d" borderRadius="12px">
                                <Box display="flex">
                                  <Typography flexGrow="1" fontSize="14px">{keyResult.keyResultName}</Typography>
                                  <ChipStatus status={getStatusAchieve(keyResult.isAchieve)} size="small"/>
                                </Box>
                                <Typography fontSize="12px" color="text.third">{dayjs(keyResult.achieveDate).format("D MMM BB HH.mmน.")}</Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </CardStyle>
              </Grid>
            </Grid>
          </Box>}
        </Container>
      </Box>
    </StyledRoot>
  )
}

export default DashboardIndividual