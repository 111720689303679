import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import XLSX from "xlsx";
import AlertResponse from "../../shared/general/AlertResponse";
import DrawerCustom from "../../shared/general/Drawer";
import DialogSelectDate from "./component/dialogWithSelectDate";
import { getEmailReportById } from "../../../../actions/email";
import { getcontractEmployee } from "../../../../actions/report";

const Contract = (props) => {
    const dispatch = useDispatch();
    const { result: contract } = useSelector((state) => state.contract);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: email } = useSelector((state) => state.email)
    const { open, handleClose } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [data, setData] = useState({
        inputSearch: new Date(),
        value: "year",
        autoEmail: null,
        selectedCompany: null,
        ReportName: "contract",
        idEmployees: userProfile.idEmployees
    })

    useEffect(() => {
        if (email)
            setData({ ...data, autoEmail: email.day });
    }, [email])

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    useEffect(() => {
        dispatch(getEmailReportById(data.idEmployees, "contract"))
    }, [])

    useEffect(() => {
        dispatch(getcontractEmployee(data.selectedCompany, dayjs(data.inputSearch).startOf('month')))
    }, [data.inputSearch, data.selectedCompany])

    const handleSubmit = () => {
        if (contract.length > 0) {
            const elementList = [];
            for (let index = 0; index < contract.length; index++) {
                const element = {
                    "#": index + 1,
                    "รหัสพนักงาน": contract[index].employeeID,
                    "ชื่อ": contract[index].firstname_TH,
                    "สกุล": contract[index].lastname_TH,
                    "Division": contract[index].divisionName,
                    "Department": contract[index].departmentName,
                    "Section": contract[index].sectionName,
                    "Unit/Process": contract[index].positionName,
                    "ประเภทการจ้างงาน": contract[index].employmentTypeName,
                    "วันที่หมดสัญญา": dayjs(contract[index].contractTermainatoinDate).format("YYYY-MM-DD"),
                };
                elementList.push(element);
            }
            const workSheet = XLSX.utils.json_to_sheet(elementList);
            const workBook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workBook, workSheet, "รายงานหมดสัญญา");
            XLSX.writeFile(workBook, "รายงานหมดสัญญา.xlsx");

            handleChangeAlertType("success");
            handleOpenAlert(true);
            handleClose();
        } else {
            handleChangeAlertType("ไม่พบข้อมูล");
            handleOpenAlert(true);
        }
    }

    return (
        <DrawerCustom
            title="สรุปหมดสัญญา"
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <DialogSelectDate
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                setData={setData}
                data={data}
                handleChangeAlertType={handleChangeAlertType}
                handleOpenAlert={handleOpenAlert}
                select={false}
                excel={contract}
            />
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>
    );
}
export default Contract;
