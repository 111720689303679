import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Box,
  Typography,
  IconButton,
} from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { postHoliday, putHoliday, getHoliday } from "../../../../../actions/holiday";

const StyledRoot = styled("div")({
  width: 450,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});
const StyledWrapInput = styled("div")({
  marginBottom: 8,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const DialogContract = (props) => {
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { addModal, handleClose } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [value, setValue] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const [formData, setFormData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (addModal.isOpen) {
      if (addModal.type === "Edit") {
        setFormData({
          name: addModal.data.name ? addModal.data.name : null,
          dateHoliday: addModal.data.dateHoliday,
          compensateName: addModal.data.compensateName
            ? addModal.data.compensateName
            : null,
          compensateDate: addModal.data.compensateDate
            ? addModal.data.compensateDate
            : null,
          idHoliday: addModal.data.idHoliday,
          isActive: addModal.data.isActive,
        });
        setValue(addModal.data.compensateDate ? true : false);
      } else {
        setFormData({
          name: null,
          dateHoliday: null,
          compensateName: null,
          compensateDate: null,
          idHoliday: null,
          isActive: 1,
        });
        setValue(false);
      }
    }
  }, [addModal.isOpen]);

  const handleSubmit = async () => {
    if (!formData.name || !formData.dateHoliday) {
      handleOpenAlert(true);
      handleChangeAlertType("กรุณากรอกข้อมูลให้ครบ");
    } else if (value && !formData.compensateDate) {
      handleOpenAlert(true);
      handleChangeAlertType("กรุณากรอกวันหยุดชดเชย");
    } else {
      const data = {
        name: formData.name,
        dateHoliday: dayjs(formData.dateHoliday).format("YYYY-MM-DD"),
        compensateName: formData.compensateName,
        compensateDate: dayjs(formData.compensateDate).format("YYYY-MM-DD"),
        holidayYear: dayjs(formData.dateHoliday).get("year"),
        isActive: formData.isActive,
        idHoliday: formData.idHoliday,
      };

      if (addModal.type === "Edit") {
        delete data.idCompany;
      }

      if (formData.compensateName === null || !value)
        delete data.compensateName;
      if (formData.compensateDate === null || !value)
        delete data.compensateDate;

      if (addModal.type === "Add") {
        const result = await dispatch(postHoliday(data));
        if (result) {
          handleClose();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      } else {
        const result = await dispatch(putHoliday(data));
        if (result) {
          handleClose();
          dispatch(
            getHoliday({
              start: dayjs(today)
                .subtract(1, "year")
                .set("date", 1)
                .set("month", 0)
                .format("YYYY-MM-DD"),
              end: dayjs(today)
                .add(1, "year")
                .set("date", 31)
                .set("month", 11)
                .format("YYYY-MM-DD"),
            })
          );
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      }
    }
  };

  return (
    <DrawerCustom
      title="รายละเอียดวันหยุด"
      anchor="right"
      open={addModal.isOpen}
      onClose={handleClose}
    >
      {formData && (
        <StyledRoot>
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              ชื่อวันหยุด
            </StyledContentLabel>
            <TextFieldTheme
              variant="outlined"
              name="name"
              multiline
              value={formData.name}
              onChange={handleChange}
            />
          </StyledWrapInput>
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              วันหยุด
            </StyledContentLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                value={formData.dateHoliday}
                inputFormat="dd/MM/yyyy"
                name={"dateHoliday"}
                onChange={(newValue) => {
                  setFormData({
                    ...formData,
                    ["dateHoliday"]: dayjs(newValue).format("YYYY-MM-DD"),
                  });
                }}
                renderInput={(params) => (
                  <StyledTextField fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
          </StyledWrapInput>
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              ใช้งาน
            </StyledContentLabel>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formData.isActive}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    ["isActive"]: eval(event.target.value),
                  });
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="ใช้งาน" />
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="ไม่ใช้งาน"
                />
              </RadioGroup>
            </FormControl>
          </StyledWrapInput>
          {/* <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              วันหยุดชดเชย
            </StyledContentLabel>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={(event) => {
                  setValue(eval(event.target.value));
                }}
              >
                <FormControlLabel value="true" control={<Radio />} label="มี" />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="ไม่มี"
                />
              </RadioGroup>
            </FormControl>
          </StyledWrapInput> */}
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              ชื่อวันหยุดชดเชย
            </StyledContentLabel>
            <TextFieldTheme
              variant="outlined"
              name="compensateName"
              multiline
              value={formData.compensateName}
              onChange={handleChange}
            />
          </StyledWrapInput>
          <StyledWrapInput>
            <StyledContentLabel color="text.third" gutterBottom>
              วันที่หยุดชดเชย
            </StyledContentLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                value={formData.compensateDate}
                inputFormat="dd/MM/yyyy"
                name={"compensateDate"}
                onChange={(newValue) => {
                  setFormData({
                    ...formData,
                    ["compensateDate"]: dayjs(newValue).format("YYYY-MM-DD"),
                  });
                }}
                renderInput={(params) => (
                  <StyledTextField fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
          </StyledWrapInput>
          <StyledFooter>
            <ButtonBlue variant="text" className="cancel" onClick={handleClose}>
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
              บันทึกข้อมูล
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogContract;
