import { httpClient } from "./httpClient";

const getAllDocuments = () => {
  return httpClient.get("documents");
};

const getDocument = (id) => {
  return httpClient.get("documents/" + id);
};

const getDocumentGroupName = () => {
  return httpClient.get("document/GroupName");
};

const getDocumentTypeName = (id) => {
  return httpClient.get("document/TypeName/" + id);
};

const addDocument = (formData) => {
  return httpClient.post(`addDocument`, formData);
};

const editDocument = (formData) => {
  return httpClient.put(`editDocument`, formData);
};

const addDocumentType = (formData) => {
  return httpClient.post(`addDocumentType`, formData);
};

const editDocumentType = (formData) => {
  return httpClient.put(`editDocumentType`, formData);
};

export default {
  getAllDocuments,
  getDocument,
  getDocumentGroupName,
  getDocumentTypeName,
  addDocument,
  addDocumentType,
  editDocument,
  editDocumentType,
};
