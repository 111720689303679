import React, { useState, useEffect } from "react";
import { createAPIEndpoint, ENDPOINTS } from "../../../../../services/api";
import Notification from "../../../shared/general/Notification";

import {
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Divider,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  dialog: {
    padding: 16,
    position: "absolute",
    top: 80,
  },
  dialogTitle: {
    borderBottom: "1px solid #dee2e6",
    color: "#222f3e",
    padding: "8px 16px 8px 16px",
  },
  dialogContent: {
    color: "#798294",
    fontWeight: 600,
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  divider: {
    marginTop: 32,
    marginBottom: 16,
  },
  saveBtn: {
    backgroundColor: "#2f74eb",
    color: "#fff",
    fontWeight: 700,
    height: "40px",
    borderRadius: "25px",
    paddingLeft: 32,
    paddingRight: 32,
    "&:hover": {
      backgroundColor: "#2a68d3",
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#9e9e9e",
  },
  headTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.headTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#3f51b5",
    },
  },
}))(MuiDialogContent);

const InitialProject = [
  {
    name: "-",
    id: "0",
  },
];

export default function EditMember(props) {
  const classes = useStyles();
  const { editModal, setEditModal, chartNode } = props;
  const [project, setProject] = useState([]);
  const [values, setValues] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [empSelected, setEmpSelected] = useState([]);
  const [parentSelected, setParentSelected] = useState([]);
  const [reportToSelected, setReportToSelected] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [errors, setErrors] = useState({
    empName: false,
    parentId: false,
    role: false,
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // const OPTIONS_LIMIT = 5;
  // const defaultFilterOptions = createFilterOptions();

  // const filterOptions = (options, state) => {
  //   return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleEmpSelected = (e, newValue) => {
    setEmpSelected(newValue);
    setValues({
      ...values,
      name: newValue !== null ? newValue.fullName : "",
      img: newValue !== null ? newValue.imagePath : "/",
      position: newValue !== null ? newValue.jobPosition : "",
      department: newValue !== null ? newValue.department : "",
      section: newValue !== null ? newValue.section : "",
    });
  };

  const handleParentSelected = (e, newValue) => {
    setParentSelected(newValue);
    setValues({
      ...values,
      parentId: newValue !== null ? newValue.id : "",
    });
  };

  const handleReportToSelected = (e, newValue) => {
    setReportToSelected(newValue);
    setValues({
      ...values,
      reportTo: newValue !== null ? newValue.fullName : "",
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    createAPIEndpoint(ENDPOINTS.PROJECTASSIGN)
      .update(values.projectId, values)
      .then((res) => {
        setEditModal({
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Edit Successfully, Please Refresh Data",
          type: "success",
        });
      })
      .catch((err) => console.log(err));
  };

  const fetchEmpData = () => {
    createAPIEndpoint(ENDPOINTS.EMPLOYEE)
      .fetchAll()
      .then((res) => {
        //Add field Full Name
        res.data.forEach(function (element) {
          element.fullName = element.firstName + " " + element.lastName;
        });

        setEmployees(res.data);

        res.data.forEach((item) => {
          if (item.fullName === chartNode.name) {
            setEmpSelected(item);
          }

          if (item.fullName === chartNode.reportTo) {
            setReportToSelected(item);
          }
        });
      })
      .catch((err) => console.log(err));
  };

  const fetchProjectData = () => {
    createAPIEndpoint(ENDPOINTS.PROJECTASSIGN)
      .fetchAll()
      .then((res) => {
        if (res.data.length > 0) {
          res.data.filter((item) => item.projId === project.projId);
          setProjectData(res.data);

          if (chartNode.parentId === "0") {
            setParentSelected({
              name: "-",
              id: "0",
            });
          } else {
            res.data.forEach((item) => {
              if (item.id === chartNode.parentId) {
                setParentSelected(item);
              }
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (editModal.isOpen) {
      setValues(editModal.nodeData);
      fetchEmpData();
      fetchProjectData();
      setIsLoading(false);
    }
  }, [editModal]);

  return (
    <>
      <Dialog
        open={editModal.isOpen}
        maxWidth="sm"
        fullWidth
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle
          className={classes.dialogTitle}
          onClose={() => setEditModal({ ...editModal, isOpen: false })}
        >
          Edit Member
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {isLoading ? (
            <>
              <Grid container justify="center">
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <form onSubmit={handleOnSubmit}>
                <Grid container alignItems="center" spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1">Employee : </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    {/* <Autocomplete
                      filterOptions={filterOptions}
                      options={employees}
                      autoHighlight
                      getOptionLabel={(option) => option.fullName || ""}
                      value={empSelected}
                      onChange={handleEmpSelected}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="dense"
                          placeholder="Search Employee"
                          error={errors.empName}
                        />
                      )}
                    /> */}
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1">Parent : </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    {/* <Autocomplete
                      filterOptions={filterOptions}
                      options={
                        values.parentId === "0" ? InitialProject : projectData
                      }
                      autoHighlight
                      getOptionLabel={(option) => option.name || ""}
                      value={parentSelected}
                      onChange={handleParentSelected}
                      disabled={values.parentId === "0" ? true : false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="dense"
                          placeholder="Search Parent"
                          error={errors.parent}
                        />
                      )}
                    /> */}
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1">Role Name : </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="role"
                      value={values.role}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1">Report To : </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    {/* <Autocomplete
                      filterOptions={filterOptions}
                      options={employees}
                      autoHighlight
                      getOptionLabel={(option) => option.fullName || ""}
                      value={reportToSelected}
                      onChange={handleReportToSelected}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="dense"
                          placeholder="Search Employee"
                        />
                      )}
                    /> */}
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1">Start Date : </Typography>
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="change date"
                        name="startDate"
                        value={values.startDate}
                        onChange={(date) => {
                          setValues({
                            ...values,
                            startDate: date,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            format="dd/MM/yyyy"
                            placeholder="DD/MM/YYYY"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body1">End Date : </Typography>
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="change date"
                        name="endDate"
                        value={values.endDate}
                        onChange={(date) => {
                          setValues({
                            ...values,
                            endDate: date,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            format="dd/MM/yyyy"
                            placeholder="DD/MM/YYYY"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={4}>
                  <Grid item xs={12} md={4} lg={4}></Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="selectOption"
                        value={values.selectOption}
                        onChange={handleInputChange}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <FormControlLabel
                              value="ย้ายทั้งหมดมาที่ Project"
                              control={<Radio />}
                              label="ย้ายทั้งหมดมาที่ Project"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              value="ย้าย Report to project"
                              control={<Radio />}
                              label="ย้าย Report to project"
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container justify="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.saveBtn}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </DialogContent>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
