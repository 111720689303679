import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {TextField, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { approveTerminateEmployeeByReviewer, getTerminateEmployeeByReviewer} from "../../../../../actions/offBoarding";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const Dialog = (props) => {
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { open, handleClose, data,setSnackBarConfig } = props;
    const [formData, setFormData] = useState();


    useEffect(() => {
        if (open) {
            setFormData({
                note: data.note,
                updateDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                isApprove: 1
            })
        }
    }, [open]);
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        const result = await dispatch(approveTerminateEmployeeByReviewer(data.idTerminateEmployee, formData));
        if (result) {
            if (result.status === 200) {
                dispatch(getTerminateEmployeeByReviewer(userProfile.idEmployees));
                setSnackBarConfig({
                    open: true,
                    message: "ดำเนินการสำเร็จ",
                    type: "success",
                })
                handleClose()
            } else {
                setSnackBarConfig({
                    open: true,
                    message: "ดำเนินการไม่สำเร็จ",
                    type: "error",
                })
            }
        } else {
            setSnackBarConfig({
                open: true,
                message: "ดำเนินการไม่สำเร็จ",
                type: "error",
            })
        }
    }

    return (
        <DrawerCustom
            title="รายละเอียดการตรวจสอบ"
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            {formData && <StyledRoot>
                <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={12} sm={6} className="GridTopicInput">วันที่ตรวจสอบ</Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                value={formData.updateDate}
                                inputFormat="dd/MM/yyyy"
                                name="updateDate"
                                onChange={(newValue) => {
                                    setFormData({ ...formData, ["updateDate"]: dayjs(newValue).format("YYYY-MM-DD") });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth helperText={null} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className="GridTopicInput">note</Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                name="note"
                                multiline
                                value={formData.note}
                                onChange={handleChange}
                                margin="dense"
                                fullWidth
                            />
                        </Grid>
                    </LocalizationProvider>
                </Grid>
                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>ยกเลิก</ButtonBlue>
                    <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
                        บันทึกข้อมูล
                    </ButtonBlue>
                </StyledFooter>
            </StyledRoot>}
        </DrawerCustom>
    );
}

export default Dialog;
