import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import ButtonBlue from "./ButtonBlue";

import utils from "../../../../utils";

import { withdrawLeaveEmployee } from "../../../../actions/leave";

const StyledRow = styled("div")({
  marginBottom: 8,
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialogContent-root": {
    padding: 0,
    "& .content": {
      padding: 16,
      textAlign: "center",
      "& .delete-icon-label": {
        fontSize: 90,
        color: "#f15e5e",
      },
      "& .header-label": {
        padding: "20px 0",
      },
      "& .detail": {
        fontSize: 16,
        "& span": {
          color: "#f15e5e",
          fontSize: 20,
        },
      },
    },
  },
});

const DialogConfirmWithdraw = (props) => {
  const {
    open,
    handleClose,
    mode,
    data,
    handleDeleteRequestTime,
    handleDeleteLeave
  } = props;
  const dispatch = useDispatch();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);

  const handleSubmit = async () => {
    if (mode === "1") {
      handleDeleteRequestTime()
    } else {
      handleDeleteLeave()
    }
  };

  const renderLeaveType = () => {
    const found = leaveTypeList.find(
      (item) => item.idLeaveType === data.idLeaveType
    );
    return found ? found.name : "-";
  };

  return (
    <StyledDialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div
          style={{ width: "100%", height: 6, backgroundColor: "#f15e5e" }}
        ></div>
        <div className="content">
          <HighlightOffRoundedIcon className="delete-icon-label" />
          <Typography variant="h5" className="header-label" color="text.third">
            คุณแน่ใจใช่ไหม?
          </Typography>
          <StyledRow>
            <Typography variant="body2" color="text.secondary">
              {mode === "1" ? "ประเภท" : "ประเภทการลา"}
            </Typography>
            <Typography variant="h6">{data.name}</Typography>
          </StyledRow>
          <StyledRow>
            <Typography variant="body2" color="text.secondary">
              วันที่เริ่มต้น
            </Typography>
            <Typography variant="h6">
              {data.isFullDay === 1
                ? dayjs(data.startText, "DD/MM/YYYY HH:mm").format(
                    "DD MMMM YYYY"
                  )
                : dayjs(data.startText, "DD/MM/YYYY HH:mm").format(
                    "DD MMMM YYYY HH:mm"
                  )}
            </Typography>
          </StyledRow>
          <StyledRow>
            <Typography variant="body2" color="text.secondary">
              วันที่สิ้นสุด
            </Typography>
            <Typography variant="h6">
              {data.isFullDay === 1
                ? dayjs(data.endText, "DD/MM/YYYY HH:mm").format("DD MMMM YYYY")
                : dayjs(data.endText, "DD/MM/YYYY HH:mm").format(
                    "DD MMMM YYYY HH:mm"
                  )}
            </Typography>
          </StyledRow>
        </div>
      </DialogContent>
      <DialogActions>
        <ButtonBlue onClick={handleClose} variant="text" color="secondary">
          ยกเลิก
        </ButtonBlue>
        <ButtonBlue
          onClick={handleSubmit}
          autoFocus
          variant={"contained"}
          color="error"
        >
          {mode === "1" ? "ยืนยันยกเลิก" : "ยืนยันยกเลิกการลา"}
        </ButtonBlue>
      </DialogActions>
    </StyledDialog>
  );
};

export default DialogConfirmWithdraw;
