import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { TeamOptions, TeamData } from "../DataSet";
// import parse from "autosuggest-highlight/parse";
// import match from "autosuggest-highlight/match";
import { styled } from "@mui/styles";
import dayjs from "dayjs";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import {
  getTeamPlanById,
  postTeamPlan,
  putTeamPlan,
} from "../../../../../actions/TeamPlans";

import {
  ButtonGroup,
  Button,
  TextField,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Avatar,
  InputAdornment,
  IconButton,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircleIcon from "@mui/icons-material/Circle";
import StarIcon from "@mui/icons-material/Star";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import NotesIcon from "@mui/icons-material/Notes";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import EscalatorIcon from "@mui/icons-material/Escalator";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import GroupIcon from "@mui/icons-material/Group";

const InitialValues = () => ({
  planTeamId: 0,
  objectiveName: "",
  impactName: "",
  teamName: "HR TPE",
  quater: "Q1",
  keyResult1: "",
  description1: "",
  scope1: "",
  tactics1: "",
  measureEvidence1: "",
  commitQuality1: "",
  typeTeam1: "",
  nameCrossTeam1: "",
  reasonCrossTeam1: "",
  nameCompanyTeam1: "",
  reasonCompanyTeam1: "",
  krType1: "",
  keyResult2: "",
  description2: "",
  scope2: "",
  tactics2: "",
  measureEvidence2: "",
  commitQuality2: "",
  typeTeam2: "",
  nameCrossTeam2: "",
  reasonCrossTeam2: "",
  nameCompanyTeam2: "",
  reasonCompanyTeam2: "",
  krType2: "",
  keyResult3: "",
  description3: "",
  scope3: "",
  tactics3: "",
  measureEvidence3: "",
  commitQuality3: "",
  typeTeam3: "",
  nameCrossTeam3: "",
  reasonCrossTeam3: "",
  nameCompanyTeam3: "",
  reasonCompanyTeam3: "",
  krType3: "",
  createByEmpId: null,
  dateCreate: new Date(),
  planStatus: "Waiting Approval",
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#222f3e",
    },
  },
  circle: {
    color: "#bcbcbc",
  },
  notSelectedQ: {
    backgroundColor: "#bcbcbc",
    width: 16,
    height: 16,
  },
  selectedQ: {
    // backgroundColor : '#3f51b5',
    width: 50,
    height: 50,
  },
  selectedIcon: {
    fontSize: 28,
  },
  name: {
    fontSize: 23,
    fontWeight: "bold",
    //marginLeft: 20,
  },
  placeholder: {
    color: "#aaa",
  },
  nameDialog: {
    fontSize: 25,
    fontWeight: "bold",
    color: "",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const krType = [
  { id: "None", title: "None" },
  { id: "Numeric", title: "Numeric" },
  { id: "Descriptive", title: "Description" },
];

const Team = [
  { id: "None", title: "None" },
  { id: "HR", title: "HR" },
  { id: "Production", title: "Production" },
  { id: "IT support", title: "IT support" },
];

function TeamPlan() {
  const history = useHistory();
  const classes = useStyles();
  const [values, setValues] = useState(InitialValues);
  const [disabled, setDisabled] = useState(false);
  const [numberKeyResult, setNumberKeyResult] = useState(1);
  const [iconStatus, setIconStatus] = useState(true);
  const [nameOfCrossTeam1, setNameOfCrossTeam1] = useState("");
  const [nameOfCompanyTeam1, setNameOfCompanyTeam1] = useState("");
  const [nameOfCrossTeam2, setNameOfCrossTeam2] = useState("");
  const [nameOfCompanyTeam2, setNameOfCompanyTeam2] = useState("");
  const [nameOfCrossTeam3, setNameOfCrossTeam3] = useState("");
  const [nameOfCompanyTeam3, setNameOfCompanyTeam3] = useState("");
  const [numberQuater, setNumberQuater] = useState(0);
  const location = useLocation();

  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: teamPlanStore } = useSelector((state) => state.TeamPlans);

  const [errors, setErrors] = useState({
    objectiveName: false,
    impactName: false,
    keyResult1: false,
  });

  const [openCrossTeam, setOpenCrossTeam] = useState([false, false, false]);
  const handleClickOpenCrossTeam = (index) => {
    let data = [...openCrossTeam];
    data[index] = true;
    setOpenCrossTeam(data);
  };
  const handleCloseCrossTeam = (index) => {
    let data = [...openCrossTeam];
    data[index] = false;
    setOpenCrossTeam(data);
  };

  const [openCompanyTeam, setOpenCompanyTeam] = useState([false, false, false]);
  const handleClickOpenCompanyTeam = (index) => {
    let data = [...openCompanyTeam];
    data[index] = true;
    setOpenCompanyTeam(data);
  };
  const handleCloseCompanyTeam = (index) => {
    let data = [...openCompanyTeam];
    data[index] = false;
    setOpenCompanyTeam(data);
  };

  const onPostTeamPlan = async (formData) => {
    await dispatch(postTeamPlan(formData));
    history.push("/teamlist");
  };

  const onPutTeamPlan = async (id, formData) => {
    await dispatch(putTeamPlan(id, formData));
    history.push("/teamlist");
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (values.objectiveName && values.impactName && values.keyResult1) {
      const formData = {
        objectiveName: values.objectiveName,
        impactName: values.impactName,
        teamName: "HR TPE",
        quater: values.quater,
        keyResult1: values.keyResult1,
        description1: values.description1,
        tactics1: values.tactics1,
        measureEvidence1: values.measureEvidence1,
        commitQuality1: values.commitQuality1,
        crossTeam1: values.nameCrossTeam1,
        reasonCrossTeam1: values.reasonCrossTeam1,
        nameCompanyTeam1: values.nameCompanyTeam1,
        reasonCompanyTeam1: values.reasonCompanyTeam1,
        krType1: values.krType1,
        keyResult2: values.keyResult2,
        description2: values.description2,
        tactics2: values.tactics2,
        measureEvidence2: values.measureEvidence2,
        commitQuality2: values.commitQuality2,
        nameCrossTeam2: values.nameCrossTeam2,
        reasonCrossTeam2: values.reasonCrossTeam2,
        nameCompanyTeam2: values.nameCompanyTeam2,
        reasonCompanyTeam2: values.reasonCompanyTeam2,
        krType2: values.krType2,
        keyResult3: values.keyResult3,
        description3: values.description3,
        tactics3: values.tactics3,
        measureEvidence3: values.measureEvidence3,
        commitQuality3: values.commitQuality3,
        nameCrossTeam3: values.nameCrossTeam3,
        reasonCrossTeam3: values.reasonCrossTeam3,
        nameCompanyTeam3: values.nameCompanyTeam3,
        reasonCompanyTeam3: values.reasonCompanyTeam3,
        krType3: values.krType3,
        createByEmpId: userProfile.idEmployees,
        dateCreate: dayjs(new Date()).format("YYYY-MM-DD"),
        planStatus: "Waiting Approval",
      };

      if (values.planTeamId == 0) {
        onPostTeamPlan(formData);
      } else {
        onPutTeamPlan(values.planTeamId, values);
      }
    } else {
      setErrors({
        objectiveName: true,
        impactName: true,
        keyResult1: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    if (location.isInfo == true) {
      //View
      setDisabled(true);
      setValues(location.state.planList);
      setNumberKeyResult(3);
    } else {
      if (location.state.planList !== undefined) {
        //Edit Plan
        setNumberKeyResult(3);
        setValues(location.state.planList);
      } else {
        //Add Plan
        if (currentUser) {
          dispatch(getUserProfile(currentUser.username));
        }

        const date = new Date();
        const month = date.getMonth() + 1;
        const quater = Math.ceil(month / 3);
        setValues({ ...values, quater: "Q" + quater });
        setNumberQuater(quater);
      }
    }
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Team Planning</h3>
            </Grid>
          </Grid>
        </div>

        <form noValidate autoComplete="off">
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xl={2}
              md={2}
              xs={2}
              style={{ textAlign: "-webkit-center" }}
            >
              {values.quater == "Q1" ? (
                <Avatar
                  sx={{ backgroundColor: "primary.main" }}
                  className={classes.selectedQ}
                >
                  <LocationOnIcon className={classes.selectedIcon} />
                </Avatar>
              ) : (
                <Avatar className={classes.notSelectedQ}>
                  <CircleIcon className={classes.circle} />
                </Avatar>
              )}
              <Typography className={classes.spaceFromLocationToQ}>
                Q1
              </Typography>
            </Grid>

            <Grid
              item
              xl={2}
              md={2}
              xs={2}
              style={{ textAlign: "-webkit-center" }}
            >
              {values.quater == "Q2" ? (
                <Avatar
                  sx={{ backgroundColor: "primary.main" }}
                  className={classes.selectedQ}
                >
                  <LocationOnIcon className={classes.selectedIcon} />
                </Avatar>
              ) : (
                <Avatar className={classes.notSelectedQ}>
                  <CircleIcon className={classes.circle} />
                </Avatar>
              )}
              <Typography className={classes.spaceFromLocationToQ}>
                Q2
              </Typography>
            </Grid>
            <Grid
              item
              xl={2}
              md={2}
              xs={2}
              style={{ textAlign: "-webkit-center" }}
            >
              {values.quater == "Q3" ? (
                <Avatar
                  sx={{ backgroundColor: "primary.main" }}
                  className={classes.selectedQ}
                >
                  <LocationOnIcon className={classes.selectedIcon} />
                </Avatar>
              ) : (
                <Avatar className={classes.notSelectedQ}>
                  <CircleIcon className={classes.circle} />
                </Avatar>
              )}
              <Typography className={classes.spaceFromLocationToQ}>
                Q3
              </Typography>
            </Grid>
            <Grid
              item
              xl={2}
              md={2}
              xs={2}
              style={{ textAlign: "-webkit-center" }}
            >
              {values.quater == "Q4" ? (
                <Avatar
                  sx={{ backgroundColor: "primary.main" }}
                  className={classes.selectedQ}
                >
                  <LocationOnIcon className={classes.selectedIcon} />
                </Avatar>
              ) : (
                <Avatar className={classes.notSelectedQ}>
                  <CircleIcon className={classes.circle} />
                </Avatar>
              )}
              <Typography className={classes.spaceFromLocationToQ}>
                Q4
              </Typography>
            </Grid>
          </Grid>
          <br />

          <Grid container justifyContent="center" spacing={2}>
            <Grid item xl={10} md={10} sm={10} xs={10}>
              <TextField
                name="objectiveName"
                value={values.objectiveName}
                onChange={handleInputChange}
                fullWidth
                label="Objective"
                placeholder="Objective"
                disabled={disabled}
                error={errors.objectiveName}
                helperText={
                  errors.objectiveName ? "This field is required" : null
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <StarIcon />
                    </InputAdornment>
                  ),
                }}
                //{...register("objectiveName", { required: true })}
                /*
                error={errors.objectiveName ? true : false}
                helperText={
                  errors.objectiveName ? "This field is required." : ""
                }
                 */
              />
            </Grid>
            <Grid item xl={10} md={10} sm={10} xs={10}>
              <TextField
                name="impactName"
                value={values.impactName}
                onChange={handleInputChange}
                fullWidth
                disabled={disabled}
                label="Impact"
                placeholder="Impact"
                error={errors.impactName}
                helperText={errors.impactName ? "This field is required" : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AutoAwesomeIcon />
                    </InputAdornment>
                  ),
                }}
                //{...register("impactName", { required: true })}
                /*
                error={errors.impactName ? true : false}
                helperText={errors.impactName ? "This field is required." : ""}
                 */
              />
            </Grid>
            <Grid item xl={10} md={10} sm={10} xs={10}>
              <Autocomplete
                name="teamName"
                value={values.teamName}
                onChange={(event, newValue) => {
                  setValues({
                    ...values,
                    teamName: newValue,
                  });
                }}
                disabled
                options={TeamOptions.sort((a, b) => -b.localeCompare(a))}
                autoHighlight
                getOptionLabel={(option) => (option ? option : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Team"
                    placeholder="Select Team"
                    fullWidth
                  />
                )}
                // renderOption={(option, { inputValue }) => {
                //   const matches = match(option, inputValue);
                //   const parts = parse(option, matches);

                //   return (
                //     <div>
                //       {parts.map((part, index) => (
                //         <span
                //           key={index}
                //           style={{ fontWeight: part.highlight ? 700 : 400 }}
                //         >
                //           {part.text}
                //         </span>
                //       ))}
                //     </div>
                //   );
                // }}
              />
            </Grid>
          </Grid>

          {/* ขึ้น key result แรก */}
          <hr className="hrTag" />
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ paddingLeft: 30, paddingRight: 30 }}
          >
            <Grid item xl={4} lg={4} md={6} xs={8}>
              <Typography
                sx={{ color: "primary.main" }}
                className={classes.name}
              >
                The 1 Key Result
              </Typography>
            </Grid>
            <Grid item xl={8} lg={8} md={6} xs={4} style={{ textAlign: "end" }}>
              {!disabled ? (
                <>
                  {numberKeyResult < 3 ? (
                    <IconButton
                      aria-label="add-demand"
                      style={{
                        marginRight: 5,
                        backgroundColor: "#7dc581",
                        color: "#357a38",
                      }}
                    >
                      <AddIcon
                        onClick={() => {
                          setNumberKeyResult(numberKeyResult + 1);
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="add-demand"
                      style={{ marginLeft: 5 }}
                      disabled
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                  {numberKeyResult != 1 ? (
                    <IconButton
                      aria-label="delete"
                      style={{
                        marginLeft: 5,
                        backgroundColor: "#dd99b1",
                        color: "#ab003c",
                      }}
                    >
                      <DeleteIcon
                        onClick={() => setNumberKeyResult(numberKeyResult - 1)}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      style={{ marginLeft: 5 }}
                      disabled
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </>
              ) : null}
            </Grid>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              style={{ marginTop: 20 }}
            >
              <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                <Grid container>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: 20 }}
                  >
                    <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                      Main data
                    </Typography>
                    <Typography style={{ fontSize: 15 }}>
                      description of the key result
                    </Typography>
                  </Grid>
                  <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                    <TextField
                      name="keyResult1"
                      value={values.keyResult1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      sx={{ paddingBottom: 2 }}
                      // multiline
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DriveFileRenameOutlineIcon />
                          </InputAdornment>
                        ),
                      }}
                      label="Key Result 1"
                      placeholder="Key Result 1"
                      error={errors.keyResult1}
                      helperText={
                        errors.keyResult1 ? "This field is required" : null
                      }
                      //{...register("keyResult1", { required: true })}
                      /*
                      error={errors.keyResult1 ? true : false}
                      helperText={
                        errors.keyResult1 ? "Enter Objective Name" : ""
                      }
                       */
                    />
                    <TextField
                      name="description1"
                      value={values.description1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      multiline
                      rows={2}
                      sx={{ paddingBottom: 2 }}
                      //{...register("description1", { required: true })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NotesIcon />
                          </InputAdornment>
                        ),
                      }}
                      label="Description"
                      placeholder="Description"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xl={10}
                lg={10}
                md={10}
                sm={10}
                xs={10}
                style={{ marginTop: 10 }}
              >
                <Grid container>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: 20 }}
                  >
                    <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                      Second data
                    </Typography>
                    <Typography style={{ fontSize: 15 }}>
                      detail of the key result
                    </Typography>
                  </Grid>
                  <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                    <TextField
                      name="tactics1"
                      value={values.tactics1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      sx={{ paddingBottom: 2 }}
                      // multiline
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AutoFixHighIcon />
                          </InputAdornment>
                        ),
                      }}
                      label="Tactics"
                      placeholder="Tactics"
                      //{...register("tactics1", { required: true })}
                      /*
                      error={errors.tactics1 ? true : false}
                      helperText={errors.tactics1 ? "Enter Tactics" : ""}
                       */
                    />
                    <Grid container alignItems="center" spacing={2}>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        style={{ padding: 10 }}
                      >
                        <Button
                          variant="outlined"
                          fullWidth
                          sx={
                            nameOfCrossTeam1 != ""
                              ? {
                                  color: "success.main",
                                  borderColor: "success.main",
                                }
                              : { color: "primary.main" }
                          }
                          style={{ height: 130, flexDirection: "column" }}
                          onClick={() => {
                            handleClickOpenCrossTeam(0);
                            setNameOfCompanyTeam1("");
                          }}
                        >
                          <GroupIcon style={{ fontSize: 32 }} />
                          <Typography style={{ fontWeight: "bold" }}>
                            Cross Team
                          </Typography>
                          <Typography style={{ fontSize: 15 }}>
                            {nameOfCrossTeam1}
                          </Typography>
                        </Button>
                        <Dialog
                          open={openCrossTeam[0]}
                          onClose={() => handleCloseCrossTeam(0)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            <Typography
                              sx={{ color: "primary.main" }}
                              className={classes.nameDialog}
                            >
                              Cross Team
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <Grid container justifyContent="center" spacing={2}>
                              <Grid item xl={10} md={10} xs={10}>
                                <TextField
                                  select
                                  label="Select Team"
                                  value={values.nameCrossTeam1}
                                  onChange={(e) =>
                                    setValues({
                                      ...values,
                                      nameCrossTeam1: e.target.value,
                                    })
                                  }
                                  disabled={disabled}
                                  fullWidth
                                >
                                  {Team.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.title}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid item xl={10} md={10} xs={10}>
                                <TextField
                                  name="reasonCrossTeam1"
                                  value={values.reasonCrossTeam1}
                                  onChange={handleInputChange}
                                  fullWidth
                                  disabled={disabled}
                                  label="Reason"
                                  placeholder="Reason"
                                  /*
                                  {...register("reasonCrossTeam1", {
                                    required: true,
                                  })}
                                  */
                                  /*
                                  error={errors.reasonCrossTeam1 ? true : false}
                                  helperText={
                                    errors.reasonCrossTeam1
                                      ? "Enter reason of cross team"
                                      : ""
                                  }
                                   */
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => {
                                setNameOfCrossTeam1(values.nameCrossTeam1);
                                handleCloseCrossTeam(0);
                              }}
                              autoFocus
                            >
                              Save
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        style={{ padding: 10 }}
                      >
                        <Button
                          variant="outlined"
                          fullWidth
                          sx={
                            nameOfCompanyTeam1 != ""
                              ? {
                                  color: "success.main",
                                  borderColor: "success.main",
                                }
                              : { color: "primary.main" }
                          }
                          style={{ height: 130, flexDirection: "column" }}
                          onClick={() => {
                            handleClickOpenCompanyTeam(0);
                            setNameOfCrossTeam1("");
                          }}
                        >
                          <GroupIcon style={{ fontSize: 32 }} />
                          <Typography style={{ fontWeight: "bold" }}>
                            Company Team
                          </Typography>
                          <Typography>{nameOfCompanyTeam1}</Typography>
                        </Button>
                        <Dialog
                          open={openCompanyTeam[0]}
                          onClose={() => handleCloseCompanyTeam(0)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            <Typography
                              sx={{ color: "primary.main" }}
                              className={classes.nameDialog}
                            >
                              Company Team
                            </Typography>
                          </DialogTitle>
                          <DialogContent>
                            <Grid container justifyContent="center">
                              <Grid item xl={10} md={10} xs={10}>
                                <TextField
                                  name="nameCompanyTeam1"
                                  value={values.nameCompanyTeam1}
                                  onChange={handleInputChange}
                                  fullWidth
                                  disabled={disabled}
                                  label="Name"
                                  placeholder="Name"
                                  /*
                                   {...register("nameCompanyTeam1", {
                                    required: true,
                                  })}
                                  */
                                  /*
                                  error={errors.nameCompanyTeam1 ? true : false}
                                  helperText={
                                    errors.nameCompanyTeam1
                                      ? "Enter name of cross team"
                                      : ""
                                  }
                                   */
                                />
                              </Grid>
                              <Grid item xl={10} md={10} xs={10}>
                                <TextField
                                  name="reasonCompanyTeam1"
                                  value={values.reasonCompanyTeam1}
                                  onChange={handleInputChange}
                                  fullWidth
                                  disabled={disabled}
                                  label="Reason"
                                  placeholder="Reason"
                                  /*
                                  {...register("reasonCompanyTeam1", {
                                    required: true,
                                  })}
                                  */
                                  /*
                                  error={
                                    errors.reasonCompanyTeam1 ? true : false
                                  }
                                  helperText={
                                    errors.reasonCompanyTeam1
                                      ? "Enter reason of cross team"
                                      : ""
                                  }
                                   */
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => {
                                setNameOfCompanyTeam1(values.nameCompanyTeam1);
                                handleCloseCompanyTeam(0);
                              }}
                              autoFocus
                            >
                              Save
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    </Grid>

                    <TextField
                      name="measureEvidence1"
                      value={values.measureEvidence1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      sx={{ paddingBottom: 2 }}
                      // multiline
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EscalatorIcon />
                          </InputAdornment>
                        ),
                      }}
                      label="Measure Evidence"
                      placeholder="Measure Evidence"
                      //{...register("measureEvidence1", { required: true })}
                      /*
                      error={errors.measureEvidence1 ? true : false}
                      helperText={
                        errors.measureEvidence1 ? "Enter Measure Evidence" : ""
                      }
                       */
                    />
                    <TextField
                      name="commitQuality1"
                      value={values.commitQuality1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      sx={{ paddingBottom: 2 }}
                      // multiline
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EqualizerIcon />
                          </InputAdornment>
                        ),
                      }}
                      label="Commit Quality"
                      placeholder="Commit Quality"
                      //{...register("commitQuality1", { required: true })}
                      /*
                      error={errors.commitQuality1 ? true : false}
                      helperText={
                        errors.commitQuality1 ? "Enter Measure Evidence" : ""
                      }
                       */
                    />
                    <FormControl fullWidth>
                      <InputLabel>Select Type</InputLabel>
                      <Select
                        name="krType1"
                        value={values.krType1}
                        label="Select Type"
                        onChange={handleInputChange}
                        disabled={disabled}
                      >
                        {krType.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id == "None" ? "" : item.id}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* ขึ้น key result ที่ 2 */}
          <Box style={numberKeyResult < 2 ? { display: "none" } : {}}>
            <hr className="hrTag" />
            <Grid
              container
              direction="row"
              style={{ paddingLeft: 30, paddingRight: 30 }}
            >
              <Grid item xl={4} lg={4} md={6} xs={8}>
                <Typography
                  sx={{ color: "primary.main" }}
                  className={classes.name}
                >
                  The 2 Key Result
                </Typography>
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={6}
                xs={4}
                style={{ textAlign: "end" }}
              >
                {!disabled ? (
                  <>
                    {numberKeyResult < 3 ? (
                      <IconButton
                        aria-label="add-demand"
                        style={{
                          marginRight: 5,
                          backgroundColor: "#7dc581",
                          color: "#357a38",
                        }}
                      >
                        <AddIcon
                          onClick={() => {
                            setNumberKeyResult(numberKeyResult + 1);
                          }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="add-demand"
                        style={{ marginLeft: 5 }}
                        disabled
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                    {numberKeyResult != 1 ? (
                      <IconButton
                        aria-label="delete"
                        style={{
                          marginLeft: 5,
                          backgroundColor: "#dd99b1",
                          color: "#ab003c",
                        }}
                      >
                        <DeleteIcon
                          onClick={() =>
                            setNumberKeyResult(numberKeyResult - 1)
                          }
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="delete"
                        style={{ marginLeft: 5 }}
                        disabled
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </>
                ) : null}
              </Grid>
              <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        Main data
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        description of the key result
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                      <TextField
                        name="keyResult2"
                        value={values.keyResult2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        sx={{ paddingBottom: 2 }}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DriveFileRenameOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Key Result 2"
                        placeholder="Key Result 2"
                        //{...register("impactName", { required: true })}
                      />
                      <TextField
                        name="description2"
                        value={values.description2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        multiline
                        rows={2}
                        sx={{ paddingBottom: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <NotesIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Description"
                        placeholder="Description"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xl={10}
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  style={{ marginTop: 10 }}
                >
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        Second data
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        detail of the key result
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                      <TextField
                        name="tactics2"
                        value={values.tactics2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        sx={{ paddingBottom: 2 }}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AutoFixHighIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Tactics"
                        placeholder="Tactics"
                        //{...register("tactics2", { required: true })}
                      />
                      <Grid container alignItems="center" spacing={2}>
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          style={{ padding: 10 }}
                        >
                          <Button
                            variant="outlined"
                            fullWidth
                            sx={
                              nameOfCrossTeam2 != ""
                                ? {
                                    color: "success.main",
                                    borderColor: "success.main",
                                  }
                                : { color: "primary.main" }
                            }
                            style={{ height: 130, flexDirection: "column" }}
                            onClick={() => {
                              handleClickOpenCrossTeam(1);
                              setNameOfCompanyTeam2("");
                            }}
                          >
                            <GroupIcon style={{ fontSize: 32 }} />
                            <Typography style={{ fontWeight: "bold" }}>
                              Cross Team
                            </Typography>
                            <Typography style={{ fontSize: 15 }}>
                              {nameOfCrossTeam2}
                            </Typography>
                          </Button>
                          <Dialog
                            open={openCrossTeam[1]}
                            onClose={() => handleCloseCrossTeam(1)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              <Typography
                                sx={{ color: "primary.main" }}
                                className={classes.nameDialog}
                              >
                                Cross Team
                              </Typography>
                            </DialogTitle>
                            <DialogContent>
                              <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                              >
                                <Grid item xl={10} md={10} xs={10}>
                                  <TextField
                                    select
                                    label="Select Team"
                                    value={values.nameCrossTeam2}
                                    onChange={(e) =>
                                      setValues({
                                        ...values,
                                        nameCrossTeam2: e.target.value,
                                      })
                                    }
                                    disabled={disabled}
                                    fullWidth
                                  >
                                    {Team.map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.title}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item xl={10} md={10} xs={10}>
                                  <TextField
                                    name="reasonCrossTeam2"
                                    value={values.reasonCrossTeam2}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={disabled}
                                    label="Reason"
                                    placeholder="Reason"
                                    /*
                                    {...register("reasonCrossTeam2", {
                                      required: true,
                                    })}
                                    */
                                    /*
                                    error={
                                      errors.reasonCrossTeam2 ? true : false
                                    }
                                    helperText={
                                      errors.reasonCrossTeam2
                                        ? "Enter reason of cross team"
                                        : ""
                                    }
                                     */
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setNameOfCrossTeam2(values.nameCrossTeam2);
                                  handleCloseCrossTeam(1);
                                }}
                                autoFocus
                              >
                                Save
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          style={{ padding: 10 }}
                        >
                          <Button
                            variant="outlined"
                            fullWidth
                            sx={
                              nameOfCompanyTeam2 != ""
                                ? {
                                    color: "success.main",
                                    borderColor: "success.main",
                                  }
                                : { color: "primary.main" }
                            }
                            style={{ height: 130, flexDirection: "column" }}
                            onClick={() => {
                              handleClickOpenCompanyTeam(1);
                              setNameOfCrossTeam2("");
                            }}
                          >
                            <GroupIcon style={{ fontSize: 32 }} />
                            <Typography style={{ fontWeight: "bold" }}>
                              Company Team
                            </Typography>
                            <Typography>{nameOfCompanyTeam2}</Typography>
                          </Button>
                          <Dialog
                            open={openCompanyTeam[1]}
                            onClose={() => handleCloseCompanyTeam(1)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              <Typography
                                sx={{ color: "primary.main" }}
                                className={classes.nameDialog}
                              >
                                Company Team
                              </Typography>
                            </DialogTitle>
                            <DialogContent>
                              <Grid container justifyContent="center">
                                <Grid item xl={10} md={10} xs={10}>
                                  <TextField
                                    name="nameCompanyTeam2"
                                    value={values.nameCompanyTeam2}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={disabled}
                                    label="Name"
                                    placeholder="Name"
                                    /*
                                    {...register("nameCompanyTeam2", {
                                      required: true,
                                    })}
                                    */
                                    /*
                                    error={
                                      errors.nameCompanyTeam2 ? true : false
                                    }
                                    helperText={
                                      errors.nameCompanyTeam2
                                        ? "Enter name of company team"
                                        : ""
                                    }
                                     */
                                  />
                                </Grid>
                                <Grid item xl={10} md={10} xs={10}>
                                  <TextField
                                    name="reasonCompanyTeam2"
                                    value={values.reasonCompanyTeam2}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={disabled}
                                    label="Reason"
                                    placeholder="Reason"
                                    /*
                                    {...register("reasonCompanyTeam2", {
                                      required: true,
                                    })}
                                    */
                                    /*
                                    error={
                                      errors.reasonCompanyTeam2 ? true : false
                                    }
                                    helperText={
                                      errors.reasonCompanyTeam2
                                        ? "Enter reason of company team"
                                        : ""
                                    }
                                     */
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setNameOfCompanyTeam2(
                                    values.nameCompanyTeam2
                                  );
                                  handleCloseCompanyTeam(1);
                                }}
                                autoFocus
                              >
                                Save
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>

                      <TextField
                        name="measureEvidence2"
                        value={values.measureEvidence2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        sx={{ paddingBottom: 2 }}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EscalatorIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Measure Evidence"
                        placeholder="Measure Evidence"
                        //{...register("measureEvidence2", { required: true })}

                        /*
                        error={errors.measureEvidence2 ? true : false}
                        helperText={
                          errors.measureEvidence2
                            ? "Enter Measure Evidence"
                            : ""
                        }
                         */
                      />
                      <TextField
                        name="commitQuality2"
                        value={values.commitQuality2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        sx={{ paddingBottom: 2 }}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EqualizerIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Commit Quality"
                        placeholder="Commit Quality"
                        //{...register("commitQuality2", { required: true })}
                        /*
                        error={errors.commitQuality2 ? true : false}
                        helperText={
                          errors.commitQuality2 ? "Enter Measure Evidence" : ""
                        }
                         */
                      />
                      <FormControl fullWidth>
                        <InputLabel>Select Type</InputLabel>
                        <Select
                          name="krType2"
                          value={values.krType2}
                          label="Select Type"
                          onChange={handleInputChange}
                          disabled={disabled}
                        >
                          {krType.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id == "None" ? "" : item.id}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* ขึ้น key result ที่ 3 */}
          <Box style={numberKeyResult < 3 ? { display: "none" } : {}}>
            <hr className="hrTag" />
            <Grid
              container
              direction="row"
              style={{ paddingLeft: 30, paddingRight: 30 }}
            >
              <Grid item xl={4} lg={4} md={6} xs={8}>
                <Typography
                  sx={{ color: "primary.main" }}
                  className={classes.name}
                >
                  The 3 Key Result
                </Typography>
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={6}
                xs={4}
                style={{ textAlign: "end" }}
              >
                {!disabled ? (
                  <>
                    {numberKeyResult < 3 ? (
                      <IconButton
                        aria-label="add-demand"
                        style={{
                          marginRight: 5,
                          backgroundColor: "#7dc581",
                          color: "#357a38",
                        }}
                      >
                        <AddIcon
                          onClick={() => {
                            setNumberKeyResult(numberKeyResult + 1);
                          }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="add-demand"
                        style={{ marginLeft: 5 }}
                        disabled
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                    {numberKeyResult != 1 ? (
                      <IconButton
                        aria-label="delete"
                        style={{
                          marginLeft: 5,
                          backgroundColor: "#dd99b1",
                          color: "#ab003c",
                        }}
                      >
                        <DeleteIcon
                          onClick={() =>
                            setNumberKeyResult(numberKeyResult - 1)
                          }
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="delete"
                        style={{ marginLeft: 5 }}
                        disabled
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </>
                ) : null}
              </Grid>
              <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        Main data
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        description of the key result
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                      <TextField
                        name="keyResult3"
                        value={values.keyResult3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        sx={{ paddingBottom: 2 }}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DriveFileRenameOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Key Result 3"
                        placeholder="Key Result 3"
                        // inputRef={register({ required: true })}
                        // error={errors.keyResult3 ? true : false}
                        // helperText={errors.keyResult3 ? 'Enter Objective Name' : ''}
                      />
                      <TextField
                        name="description3"
                        value={values.description3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        sx={{ paddingBottom: 2 }}
                        multiline
                        rows={2}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <NotesIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Description"
                        placeholder="Description"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        Second data
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        detail of the key result
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                      <TextField
                        name="tactics3"
                        value={values.tactics3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        sx={{ paddingBottom: 2 }}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AutoFixHighIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Tactics"
                        placeholder="Tactics"
                        // inputRef={register({ required: true })}
                        // error={errors.keyResult3 ? true : false}
                        // helperText={errors.keyResult3 ? 'Enter Tactics' : ''}
                      />
                      <Grid container alignItems="center" spacing={2}>
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          style={{ padding: 10 }}
                        >
                          <Button
                            variant="outlined"
                            fullWidth
                            sx={
                              nameOfCrossTeam3 != ""
                                ? {
                                    color: "success.main",
                                    borderColor: "success.main",
                                  }
                                : { color: "primary.main" }
                            }
                            style={{ height: 130, flexDirection: "column" }}
                            onClick={() => {
                              handleClickOpenCrossTeam(2);
                              setNameOfCompanyTeam3("");
                            }}
                          >
                            <GroupIcon style={{ fontSize: 32 }} />
                            <Typography style={{ fontWeight: "bold" }}>
                              Cross Team
                            </Typography>
                            <Typography style={{ fontSize: 15 }}>
                              {nameOfCrossTeam3}
                            </Typography>
                          </Button>
                          <Dialog
                            open={openCrossTeam[2]}
                            onClose={() => handleCloseCrossTeam(2)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              <Typography
                                sx={{ color: "primary.main" }}
                                className={classes.nameDialog}
                              >
                                Cross Team
                              </Typography>
                            </DialogTitle>
                            <DialogContent>
                              <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                              >
                                <Grid item xl={10} md={10} xs={10}>
                                  <TextField
                                    select
                                    label="Select Team"
                                    value={values.nameCrossTeam3}
                                    onChange={(e) =>
                                      setValues({
                                        ...values,
                                        nameCrossTeam3: e.target.value,
                                      })
                                    }
                                    disabled={disabled}
                                    fullWidth
                                  >
                                    {Team.map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.title}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item xl={10} md={10} xs={10}>
                                  <TextField
                                    name="reasonCrossTeam3"
                                    value={values.reasonCrossTeam3}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={disabled}
                                    label="Reason"
                                    placeholder="Reason"
                                    /*
                                    {...register("reasonCrossTeam3", {
                                      required: true,
                                    })}
                                    error={
                                      errors.reasonCrossTeam3 ? true : false
                                    }
                                    helperText={
                                      errors.reasonCrossTeam3
                                        ? "Enter reason of cross team"
                                        : ""
                                    }
                                     */
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setNameOfCrossTeam3(values.nameCrossTeam3);
                                  handleCloseCrossTeam(2);
                                }}
                                autoFocus
                              >
                                Save
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          style={{ padding: 10 }}
                        >
                          <Button
                            variant="outlined"
                            fullWidth
                            sx={
                              nameOfCompanyTeam3 != ""
                                ? {
                                    color: "success.main",
                                    borderColor: "success.main",
                                  }
                                : { color: "primary.main" }
                            }
                            style={{ height: 130, flexDirection: "column" }}
                            onClick={() => {
                              handleClickOpenCompanyTeam(2);
                              setNameOfCrossTeam3("");
                            }}
                          >
                            <GroupIcon style={{ fontSize: 32 }} />
                            <Typography style={{ fontWeight: "bold" }}>
                              Company Team
                            </Typography>
                            <Typography>{nameOfCompanyTeam3}</Typography>
                          </Button>
                          <Dialog
                            open={openCompanyTeam[2]}
                            onClose={() => handleCloseCompanyTeam(2)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              <Typography
                                sx={{ color: "primary.main" }}
                                className={classes.nameDialog}
                              >
                                Company Team
                              </Typography>
                            </DialogTitle>
                            <DialogContent>
                              <Grid container justifyContent="center">
                                <Grid item xl={10} md={10} xs={10}>
                                  <TextField
                                    name="nameCompanyTeam3"
                                    value={values.nameCompanyTeam3}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={disabled}
                                    label="Name"
                                    placeholder="Name"
                                    /*
                                    {...register("nameCompanyTeam3", {
                                      required: true,
                                    })}
                                    error={
                                      errors.nameCompanyTeam3 ? true : false
                                    }
                                    helperText={
                                      errors.nameCompanyTeam3
                                        ? "Enter name of cross team"
                                        : ""
                                    }
                                     */
                                  />
                                </Grid>
                                <Grid item xl={10} md={10} xs={10}>
                                  <TextField
                                    name="reasonCompanyTeam3"
                                    value={values.reasonCompanyTeam3}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={disabled}
                                    label="Reason"
                                    placeholder="Reason"
                                    /*
                                    {...register("reasonCompanyTeam3", {
                                      required: true,
                                    })}
                                    error={
                                      errors.reasonCompanyTeam3 ? true : false
                                    }
                                    helperText={
                                      errors.reasonCompanyTeam3
                                        ? "Enter reason of cross team"
                                        : ""
                                    }
                                     */
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setNameOfCompanyTeam3(
                                    values.nameCompanyTeam3
                                  );
                                  handleCloseCompanyTeam(2);
                                }}
                                autoFocus
                              >
                                Save
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>

                      <TextField
                        name="measureEvidence3"
                        value={values.measureEvidence3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        sx={{ paddingBottom: 2 }}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EscalatorIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Measure Evidence"
                        placeholder="Measure Evidence"
                        // inputRef={register({ required: true })}
                        // error={errors.keyResult3 ? true : false}
                        // helperText={errors.keyResult3 ? 'Enter Measure Evidence' : ''}
                      />
                      <TextField
                        name="commitQuality3"
                        value={values.commitQuality3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        sx={{ paddingBottom: 2 }}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EqualizerIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Commit Quality"
                        placeholder="Commit Quality"
                      />
                      <FormControl fullWidth>
                        <InputLabel>Select Type</InputLabel>
                        <Select
                          name="krType3"
                          value={values.krType3}
                          label="Select Type"
                          onChange={handleInputChange}
                          disabled={disabled}
                        >
                          {krType.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id == "None" ? "" : item.id}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {disabled ? null : (
            <>
              <hr className="hrTag" />
              <Box sx={{ paddingRight: 4 }}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className="saveButton"
                      type="submit"
                      onClick={handleOnSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </form>
      </div>
    </ContainerStyled>
  );
}

export default TeamPlan;
