import {
  ATTENDANCE_FETCHING,
  ATTENDANCE_FAILED,
  ATTENDANCE_SUCCESS,
} from "./types";

import attendanceService from "../services/attendance.service";

export const clearAttendance = () => async (dispatch) => {
  dispatch({
    type: ATTENDANCE_SUCCESS,
    payload: null,
  });
};

export const getAttendanceById = (filter, id) => async (dispatch) => {
  try {
    dispatch({
      type: ATTENDANCE_FETCHING,
    });
    const res = await attendanceService.getAttendanceById(filter, id);
    if (res) {
      dispatch({
        type: ATTENDANCE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_FAILED,
    });
    return err;
  }
};

export const getAttendanceTransaction = (filter) => async (dispatch) => {
  try {
    const res = await attendanceService.getAttendanceTransaction(filter);
    if (res) {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const getAttendanceOnce = (filter, mode) => async (dispatch) => {
  try {
    dispatch({
      type: ATTENDANCE_FETCHING,
    });
    const res = await attendanceService.getAttendanceOnce(filter, mode);
    if (res) {
      dispatch({
        type: ATTENDANCE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_FAILED,
    });
    return err;
  }
};


export const getTimeAttendanceFilterDate = (id, filter) => async (dispatch) => {
  try {
    dispatch({
      type: ATTENDANCE_FETCHING,
    });
    const res = await attendanceService.getTimeAttendanceFilterDate(id, filter);
    if (res) {
      dispatch({
        type: ATTENDANCE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_FAILED,
    });
    return err;
  }
};

export const getAttendanceCompany = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: ATTENDANCE_FETCHING,
    });
    const res = await attendanceService.getAttendanceCompany(filter);
    if (res) {
      dispatch({
        type: ATTENDANCE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_FAILED,
    });
    return err;
  }
};

export const getTimeAttendanceFilterDateByIdVendor =
  (id, filter) => async (dispatch) => {
    try {
      dispatch({
        type: ATTENDANCE_FETCHING,
      });
      const res = await attendanceService.getTimeAttendanceFilterDateByIdVendor(
        id,
        filter
      );
      if (res) {
        dispatch({
          type: ATTENDANCE_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (err) {
      dispatch({
        type: ATTENDANCE_FAILED,
      });
      return err;
    }
  };

export const getAllAttendanceToManager = (id) => async (dispatch) => {
  try {
    const res = await attendanceService.getAllAttendanceToManager(id);
    if (res) {
      dispatch({
        type: ATTENDANCE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_FAILED,
    });
    console.log(err);
  }
};

export const addAttendance = (formData) => async () => {
  try {
    const res = await attendanceService.addAttendance(formData);
    if (res) {
        // console.log(res)
      return res.data;
    }
  } catch (error) {
    const result = {
      status: "",
      message: "",
    };
    if (error.response) {
      result.status = error.response.status;
      result.message = error.response.data.message;
    }
    return result;
  }
};

export const getAllEmployeeTimeline = (idCompany, query) => async (dispatch) => {
  try {
    const res = await attendanceService.getAllEmployeeTimeline(idCompany, query);
    if (res) {
        // console.log(res)
      return res;
    }
  } catch (err) {
    return err.response
  }
}

export const getWorkingHoursByIdEmployees = (idEmployees, query) => async (dispatch) => {
  try {
    const res = await attendanceService.getWorkingHoursByIdEmployees(idEmployees, query);
    if (res) {
        // console.log(res)
      return res;
    }
  } catch (err) {
    return err.response
  }
}

export const exportExcelEmployeeAttendanceMonthly = (query) => async () => {
  try {
    const res = await attendanceService.exportExcelEmployeeAttendanceMonthly(query);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};
