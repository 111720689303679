import {
  EMPLOYMENT_TYPE_FETCHING,
  EMPLOYMENT_TYPE_FAILED,
  EMPLOYMENT_TYPE_SUCCESS,
} from "./types";
import EmploymentTypeService from "../services/employment-type.service";

export const getEmploymentTypeByidCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYMENT_TYPE_FETCHING,
    });
    const res = await EmploymentTypeService.getEmploymentTypeByidCompany();
    if (res) {
      dispatch({
        type: EMPLOYMENT_TYPE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYMENT_TYPE_FAILED,
    });
  }
};

export const addEmploymentType = (data) => async () => {
  try {
    const res = EmploymentTypeService.addEmploymentType(data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const deleteEmploymentType = (idEmploymentType) => async () => {
  try {
    const res = EmploymentTypeService.deleteEmploymentType(idEmploymentType);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const updateEmploymentType = (data) => async () => {
  try {
    const res = EmploymentTypeService.updateEmploymentType(data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};


