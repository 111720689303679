import React, { useState,Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/material/styles";

import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DialogEdit from "./DialogEdit";
import DialogPerformance from "./DialogPerformance";
import { getEstimateLevel } from "../../../../../../actions/estimatePerformance";

const StyledRoot = styled("div")({
  "& .button-show-performance": {
    display: "flex",
    justifyContent: "center",
    margin: "16px 0",
  },
});

const StyleWrapFiveYear = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const Performance = () => {

  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [openAllPerformance, setOpenAllPerformance] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  })

  const onDrawerClose = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const onOpenDrawer = () => {

    let employeePerformance = employeeProfile.performance.find(item => item.yearPerformance === dayjs().subtract(1, 'year').get('year'))
    
    if(employeePerformance){
      setDrawerConfig(prev => ({
        ...prev,
        isOpen: true,
        isEdit: true,
        data: {
          idEmployeePerformance: employeePerformance.idEmployeePerformance,
          idEstimateLevel: employeePerformance.idEstimateLevel,
        }
      })) 
    } else {
      setDrawerConfig(prev => ({
        ...prev,
        isOpen: true,
        isEdit: false,
      })) 
    }
  }

  const toggleOpenAllPerformance = (value) => {
    setOpenAllPerformance(value);
  };

  const FindPerformance = (topicYear) => {
    if (employeeProfile && employeeProfile.performance && employeeProfile.performance.length > 0) {
      let indexResult = -1;
      employeeProfile.performance.map((performance, index) => {
        let yearPerformance = new Date(performance.year).getFullYear();
        if (yearPerformance === parseInt(topicYear)) {
          indexResult = index;
        }
      });
      return indexResult;
    } else {
      return -1;
    }
  };

  const findLatestPerformance = () => {
    let data = {
      result: "-",
      year: dayjs()
        .subtract(1, "year")
        .format("BBBB")
    };
  
    if (employeeProfile && employeeProfile.performance && employeeProfile.performance.length > 0) {
      data.result = employeeProfile.performance[0].name;
      data.year = dayjs()
        .set("year", employeeProfile.performance[0].yearPerformance)
        .format("BBBB")
    }
  
    return (
      <Fragment>
        <Typography variant="h4" align="center">
          {data.result}
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary">
          {data.year}
        </Typography>
      </Fragment>
    );
  };

  useEffect(() => {
    dispatch(getEstimateLevel())
  }, [])

  return (
    <StyledRoot>
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          ผลประเมินปีล่าสุด
        </StyledHeadLabel>
        <div>
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            // onClick={() => setOpenEdit(true)}
            onClick={() => {onOpenDrawer()}}
          >
            แก้ไข
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <div>{employeeProfile && findLatestPerformance()}</div>
      <StyledWrapTop className="five-year">
        <StyledHeadLabel variant="body2" gutterBottom>
          ประเมินผลงานย้อนหลัง 5 ปี
        </StyledHeadLabel>
      </StyledWrapTop>
      <StyleWrapFiveYear>
        {[...Array(5).keys()].map((item, index) => (
          <div key={item}>
            <Typography variant="h4" align="center">
              {FindPerformance(
                dayjs().subtract(index + 1, "year").format("YYYY")
              ) >= 0
                ? employeeProfile.performance[
                    FindPerformance(
                      dayjs()
                        .subtract(index + 1, "year")
                        .format("YYYY")
                    )
                  ].evaluation
                : "-"}
            </Typography>
            <Typography variant="body1" align="center" color="text.secondary">
              {dayjs()
                .subtract(item + 2, "year")
                .locale(localStorage.getItem("language") || "en")
                .format(
                  localStorage.getItem("language") === "th" ? "BBBB" : "YYYY"
                )}
            </Typography>
          </div>
        ))}
      </StyleWrapFiveYear>
      <div className="button-show-performance">
        <ButtonBlue
          variant="text"
          onClick={() => toggleOpenAllPerformance(!openAllPerformance)}
        >
          {openAllPerformance ? `ซ่อน` : ""}
          ผลงานทั้งหมด
        </ButtonBlue>
      </div>
      {openAllPerformance && (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ปี</TableCell>
                <TableCell align="center">ระดับ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {FindPerformance(dayjs().subtract(6, "year").format("YYYY")) >=
              0 ? (
                employeeProfile.performance.map((performance, index) => {
                  if (index > 5) {
                    return (
                      <TableRow
                        key={index}
                        style={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row">
                          {dayjs()
                            .subtract(6 + index, "year")
                            .format("BBBB")}
                        </TableCell>
                        <TableCell align="center">
                          {FindPerformance(
                            dayjs().subtract(index, "year").format("YYYY")
                          ) >= 0 &&
                            employeeProfile.performance[
                              FindPerformance(
                                dayjs().subtract(index, "year").format("YYYY")
                              )
                            ].evaluation}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })
              ) : (
                <TableRow
                  style={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    colSpan={2}
                  >
                    ไม่มีข้อมูลผลประเมิน
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* <DialogEdit open={openEdit} setOpenEdit={setOpenEdit} /> */}

      <DialogPerformance
        drawerConfig={drawerConfig}
        onClose={onDrawerClose}
      />
    </StyledRoot>
  );
};

export default Performance;
