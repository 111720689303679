import {
    CONTRACT_FETCHING,
    CONTRACT_FAILED,
    CONTRACT_SUCCESS
  } from "../actions/types";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CONTRACT_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case CONTRACT_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case CONTRACT_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
  }
  