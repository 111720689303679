import React from "react";
import { Box, Container, Divider, Grid, SvgIcon, Typography, styled } from "@mui/material";
import CardStyle from "../../shared/general/Card";

import {ReactComponent as Planning} from "../../shared/pages/okrs/assets/images/plan.svg";
import {ReactComponent as Monitor} from "../../shared/pages/okrs/assets/images/monitor.svg";
import {ReactComponent as Evaluate} from "../../shared/pages/okrs/assets/images/evaluate.svg";
import { Link } from "react-router-dom";

const PlanningMenu = [
  {
    label: "เป้าหมายรายบุคคล",
    path: "/okrs/individual-planning"
  },
  {
    label: "เป้าหมายทีม",
    path: "/okrs/team-planning"
  },
  {
    label: "เป้าหมายองค์กร",
    path: "/okrs/organization-planning"
  },
]

const MonitorMenu = [
  {
    label: "รายการอนุมัติ",
    path: "/okrs/approval/individual"
  },
  {
    label: "อัพเดทเป้าหมายรายบุคคล",
    path: "/okrs/update-progress/individual"
  },
  {
    label: "อัพเดทเป้าหมายทีม",
    path: "/okrs/update-progress/team",
  },
  {
    label: "อัพเดทเป้าหมายองค์กร",
    path: "/okrs/update-progress/organization",
  },
  {
    label: "ภาพรวมเป้าหมายรายบุคคล",
    path: "/okrs/dashboard/individual",
  },
  {
    label: "ภาพรวมเป้าหมายทีม",
    path: "/okrs/dashboard/team",
  },
  {
    label: "ภาพรวมเป้าหมายองค์กร",
    path: "/okrs/dashboard/organization",
  },
]

const EvaluateMenu = [
  {
    label: "ประเมินผลเป้าหมายรายบุคคล",
    path: "/okrs/evaluation/individual"
  },
  {
    label: "ประเมินผลเป้าหมายทีม",
    path: "/okrs/evaluation/team",
    disable: true,
  },
  {
    label: "ประเมินผลเป้าหมายองค์กร",
    path: "/okrs/evaluation",
    disable: true,
  },
  {
    label: "ประวัติ OKRs",
    path: "/okrs/history",
    disable: true,
  },
]

const StyledRoot = styled(Box)({

})

const CardMenu = (props) => {

  const { menuIcon, menu } = props;

  return (
    <CardStyle style={{height: "100%"}}>
      <Box padding="24px">
        <Box display="flex" justifyContent="center">
          <SvgIcon style={{fontSize: "128px"}}>
            {menuIcon}
          </SvgIcon>
        </Box>
        <Divider style={{marginTop: "24px"}}/>
        <Box marginTop="16px">
          {menu.map((m, index) => (
            <Box key={index} style={{pointerEvents: m.disable? "none": "unset"}}>
              <Link to={m.path} style={{color: "unset", textDecoration: "unset"}}>
                <Box
                  padding="16px"
                  borderRadius="8px"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  <Typography textAlign="center" fontWeight="500" fontSize="18px" style={{color: m.disable? "#919eab": "inherit"}}>{m.label}</Typography>
                </Box>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </CardStyle>
  )
} 

const OKRsMenu = () => {
  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Box marginTop="24px">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CardMenu
                menuIcon={<Planning />}
                menu={PlanningMenu}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardMenu
                menuIcon={<Monitor />}
                menu={MonitorMenu}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardMenu
                menuIcon={<Evaluate />}
                menu={EvaluateMenu}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </StyledRoot>
  )
}

export default OKRsMenu;