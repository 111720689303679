import React from "react";
import { Chip, styled } from "@mui/material";

const StyledChip = styled(Chip)({
  height: 20,
  backgroundColor: "#ff3034",
  marginRight: 8,
  "& .MuiChip-label": {
    paddingLeft: 8,
    paddingRight: 8,
    color: "#FFFFFF",
    fontWeight: 600,
  },
});

const ChipWithDrawCustom = () => {
  return (
    <StyledChip label="ขอถอน"/>
  )
}

export default ChipWithDrawCustom


