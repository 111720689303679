import { combineReducers } from "redux";
import auth from "./auth";
import standardMenu from "./standard-menu";
import userProfile from "./user-profile";
import companyProfile from "./company-profile";
import attendanceOnce from "./attendance-once";
import users from "./user";
import manager from "./manager";
import department from "./department";
import paytypes from "./paytypes";
import payRun from "./pay-run";
import payslip from "./payslip";
import payslipDetail from "./payslip-detail";
import payRunDetail from "./pay-run-detail";
import payrollSetting from "./payroll-setting";
import payRunDetailEmployees from "./pay-run-detail-employee";
import employees from "./employee";
import employeeProfile from "./employee-profile";
import employeeChange from "./employee-change";
import navigation from "./navigation";
import orgChart from "./orgChart";
import dataProject from "./project";
import projectAssign from "./projectAssign";
import locations from "./locations";
import leaveWorks from "./leaveWorks";
import jobFamily from "./jobFamily";
import institute from "./institute";
import requestTime from "./requestTime";
import requestTimeWithDraw from "./requestTime-withdraw";
import leaveApproves from "./leaveApproves";
import workTimeRequest from "./workTimeRequest";
import logRecord from "./logRecord";
import groupLocations from "./group-locations";
import attendance from "./attendance";
import jobPosition from "./jobPosition";
import dataWork from "./work-data";
import workTypes from "./work-types";
import dataJob from "./job-data";
import dataTask from "./task-data";
import document from "./document";
import documentItem from "./document-item";
import documentGroup from "./document-group";
import category from "./category";
import dataDivision from "./division-data";
import dataMember from "./member-data";
import demandData from "./demand-data";
import userMock from "./user-mock";
import insurance from "./insurance";
import hospital from "./hospital";
import insuranceCategory from "./insuranceCategory";
import package_ from "./package";
import mainCategory from "./mainCategory";
import cart from "./cart";
import travelCategory from "./travelCategory";
import travel from "./travel";
import shopCategory from "./shopCategory";
import item from "./item";
import notification from "./notification";
import salarySlip from "./salarySlip";
import summaryTime from "./summary-time";
import summaryTimeIndividual from "./summary-time-individaul";

import jobPosting from "./jobPosting";
import jobPostingApply from "./jobPostingApply";
import careerPlan from "./careerPlan";

import delivery from "./delivery";
import facilities from "./facilities";
import treatmentCategory from "./treatmentCategory";
import bill from "./bill";
import health from "./health";
import rightTreatment from "./rightTreatment";
import healthCheck from "./healthCheck";
import bookingHealthCheck from "./bookingHealthCheck";
import history from "./history";

import jobStructures from "./jobStructures";
import subJobStructures from "./subJobStructures";
import jobPositions from "./jobPosition";

import OrgPlans from "./OrganizationPlans";
import TeamPlans from "./TeamPlans";
import IdvPlans from "./IndividualPlans";
import FailureHistory from "./FailureHistory";
import ProgressOrgPlans from "./ProgressOrganizationPlans";
import ProgressTeamPlans from "./ProgressTeamPlans";
import ProgressIdvPlans from "./ProgressIndividualPlans";
import Impacts from "./Impacts";
import ImpactOthers from "./ImpactOthers";
import DialogueRecords from "./DialogueRecords";
import jobTaskLists from "./jobTaskList";
import personalTaskLists from "./personalTaskList";
import personalTaskUsed from "./personalTaskUsed";
import holiday from "./holiday";
import leaveType from "./leave-type";
import positions from "./positions";
import additions from "./additions";
import levelAddition from "./levelAddition";
import level from "./level";
import employmentType from "./employment-type";
import leaveEmployees from "./leaveEmployees";
import leaveEmployeesWithDraw from "./leaveEmployeesWithdraw";
import bank from "./bank";
import bankDetail from "./bank-detail";
import shiftPattern from "./shift-pattern";
import shift from "./shift";
import shiftGroup from "./shift-group";
import companyOrganization from "./company-organization";
import employeeShift from "./employee-shift";
import employeeShiftHistory from "./employee-shift-history";
import employeeChangeShift from "./employee-changeShift";
import userBeneficiarys from "./user-beneficiarys";
import userProvidentFund from "./user-providentFund";
import adminSettingPF from "./adminSettingPF";
import dashboardOverview from "./dashboard-overview";
import taxDeduction from "./tax-deduction";
import dashboardOvertime from "./dashboard-overtime";
import dashboardCost from "./dashboard-cost";
import dashboardWorkingtime from "./dashboard-workingtime";
import employeeAdditionDeduction from "./employee-addition-deduction";
import employeeProvidentFunds from "./employee-providentFund";
import employeeBeneficiarys from "./employee-beneficiary";
import taxDeductionSchedule from "./tax-deduction-schedule";
import individualObjective from "./individual-objective";
import company from "./company";
import leave3month from "./leave3month";
import leaveYear from "./leaveyear";
import managerSetting from "./managerSetting";
import organizationChart from "./organizationChart";
import announcement from "./announcement";
import announcementActive from "./announcementActive";
import jobGroup from "./jobGroup";
import organizationStructure from "./organizationStructure";
import costElement from "./costElement";
import costElementList from "./costElementList";
import managerGroup from "./managerGroup";
import admin from "./admin";
import affiliate from "./affiliate";

import getProbation from "./probation"
import getProbationDiscard from "./probationDiscard"

import terminateList from "./terminatelist";
import terminateReviewer from "./terminateReviewer";
import terminateEmployee from "./terminateEmployee";
import terminateEmployeeByID from "./terminateEmployeeByID";
import onBoardingList from "./onBoardingList";
import onBoardingEmployee from "./onBoardingEmployee";
import onBoardingEmployeeByID from "./onBoardingEmployeeByID";
import onBoardingReviewer from "./onBoardingReviewer";

import employeesProbation from "./employees-probation";
import employeesProbationStatus from "./employees-probation-status";
import employeesRoleOneTwo from "./employees-role-one-two";
import probationAnswer from "./probation-answer";
import employeeProbationId from "./employee-probation-id";

import estimateLevel from "./estimateLevel";
import getEstimatePerformance from "./estimatePerformance";
import employeesEstimatePerformanceStatus from "./employees-estimatePerformance-status";
import estimatePerformanceAnswer from "./estimatePerformance-answer";
import employeesEstimatePerformance from "./employees-estimatePerformance";

import getExitInterview from "./exitInterview"
import employeesExitInterviewStatus from "./employees-exitInterview-status";
import exitInterviewAnswer from "./exitInterview-answer";
import exitInterviewDiscard from "./exitInterview-discard";
import exitInterviewReason from "./exitInterview-reason";
import employeesExitInterview from "./employees-exitInterview";

import jobRecruitDetail from "./job-recruit-detail";
import jobRecruitEdit from "./job-recruit-edit";
import jobRecruitForm from "./job-recruit-form";
import jobRecruitGroup from "./job-recruit-group";
import jobsRecruit from "./jobs-recruit";
import jobGroupInterviewer from "./job-group-interviewer"

import manpowerProfile from "./manpower-profile";
import manpowerCertificate from "./manpower-certificate";
import candidateAlreadySent from "./candidate-already-sent";
import passedCandidatesCompany from "./passed-candidates-company";
import newCandidate from "./new-candidate";
import scheduleCandidates from "./schedule-candidates";
import interviewedCandidate from "./interviewedCandidate";
import historyManpowersSending from './history-manpowers-sending';
import userVendorFree from "./user-vendor-free";

import jobRecruitManager from "./job-recruit-manager";
import homepageHunter from "./homepage-hunter";
import dashboardRecruit from "./dashboard-recruit";

import warningLetter from "./warningLetter";
import warningLevel from "./warningLevel";
import assetManagement from "./assetManagement";
import policy from "./policy";
import policyActive from "./policyActive";

import timeline from "./timeline"
import timelineMonth from "./timelineMonth";

import notificationAlert from "./notificationAlert";
import manpowerPlanning from "./manpower-planning";
import contract from "./employee-contract"
import newEmployee from "./employee-new";
import resignEmployee from "./employee-resign"
import email from "./email";

const appReducer = combineReducers({
  auth,
  standardMenu,
  users,
  userProfile,
  attendanceOnce,
  companyProfile,
  department,
  manager,
  payrollSetting,
  paytypes,
  payRun,
  payslip,
  payslipDetail,
  payRunDetail,
  navigation,
  employees,
  employeeProfile,
  employeeChange,
  orgChart,
  dataProject,
  projectAssign,
  locations,
  leaveWorks,
  jobFamily,
  institute,
  leaveApproves,
  workTimeRequest,
  logRecord,
  groupLocations,
  attendance,
  jobPosition,
  dataWork,
  workTypes,
  dataJob,
  dataTask,
  document,
  documentItem,
  documentGroup,
  category,
  dataDivision,
  dataMember,
  demandData,
  userMock,
  insurance,
  hospital,
  insuranceCategory,
  package_,
  mainCategory,
  cart,
  travelCategory,
  travel,
  shopCategory,
  item,
  notification,
  jobPosting,
  jobPostingApply,
  delivery,
  facilities,
  treatmentCategory,
  bill,
  health,
  rightTreatment,
  healthCheck,
  bookingHealthCheck,
  history,
  careerPlan,
  jobStructures,
  subJobStructures,
  jobPositions,
  OrgPlans,
  TeamPlans,
  IdvPlans,
  FailureHistory,
  ProgressOrgPlans,
  ProgressTeamPlans,
  ProgressIdvPlans,
  Impacts,
  ImpactOthers,
  DialogueRecords,
  jobTaskLists,
  personalTaskLists,
  personalTaskUsed,
  companyOrganization,
  holiday,
  leaveType,
  positions,
  additions,
  levelAddition,
  level,
  employmentType,
  leaveEmployees,
  bank,
  bankDetail,
  shiftPattern,
  shift,
  shiftGroup,
  employeeShift,
  employeeShiftHistory,
  employeeChangeShift,
  requestTime,
  requestTimeWithDraw,
  leaveEmployeesWithDraw,
  salarySlip,
  userBeneficiarys,
  userProvidentFund,
  adminSettingPF,
  dashboardOverview,
  taxDeduction,
  dashboardOvertime,
  dashboardCost,
  dashboardWorkingtime,
  summaryTime,
  summaryTimeIndividual,
  employeeAdditionDeduction,
  employeeProvidentFunds,
  employeeBeneficiarys,
  taxDeductionSchedule,
  individualObjective,
  company,
  leave3month,
  leaveYear,
  managerSetting,
  organizationChart,
  payRunDetailEmployees,
  jobGroup,
  announcement,
  announcementActive,
  organizationStructure,
  costElement,
  costElementList,
  managerGroup,
  admin,
  affiliate,
  contract,
  newEmployee,
  resignEmployee,
  email,
  getProbation,
  getProbationDiscard,
  terminateList,
  terminateReviewer,
  terminateEmployee,
  terminateEmployeeByID,
  onBoardingList,
  onBoardingEmployee,
  onBoardingEmployeeByID,
  onBoardingReviewer,
  employeesProbation,
  employeesProbationStatus,
  employeesRoleOneTwo,
  probationAnswer,
  employeeProbationId,
  estimateLevel,
  getEstimatePerformance,
  employeesEstimatePerformanceStatus,
  estimatePerformanceAnswer,
  employeesEstimatePerformance,
  getExitInterview,
  employeesExitInterviewStatus,
  exitInterviewAnswer,
  exitInterviewDiscard,
  exitInterviewReason,
  employeesExitInterview,
  jobRecruitDetail,
  jobRecruitEdit,
  jobRecruitForm,
  jobRecruitGroup,
  jobsRecruit,
  jobGroupInterviewer,
  manpowerProfile,
  manpowerCertificate,
  candidateAlreadySent,
  passedCandidatesCompany,
  newCandidate,
  scheduleCandidates,
  interviewedCandidate ,
  historyManpowersSending,
  userVendorFree,
  homepageHunter,
  jobRecruitManager,
  dashboardRecruit,
  warningLetter,
  warningLevel,
  assetManagement,
  policy,
  policyActive,
  timeline,
  timelineMonth,
  notificationAlert,
  manpowerPlanning
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
