import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import {
    FormControlLabel,
    FormGroup,
    Grid,
    styled,
    Switch,
    Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { 
    addRequestReason,
    updateRequestReason 
} from "../../../../../actions/requestTime";

import ButtonBlue from '../../../shared/general/ButtonBlue';
import DrawerCustom from '../../../shared/general/Drawer';
import TextFieldTheme from '../../../shared/general/TextFieldTheme';

const Root = styled("div")({
    minWidth: "320px",
    padding: "16px"
});

const DrawerReason = (props) => {
    const { open, onClose, type, reason, renderRequestReason, handleOpenAlert } = props;
    const dispatch = useDispatch();
    const { handleSubmit, control, formState:{ errors }, reset } = useForm({
        defaultValues:{
            name: "",
            startDate: dayjs().add(1,"day"),
            isPayShift: false
        }
    });

    useEffect(() => {
        if(reason){
            reset({
                name: reason.name || "",
                startDate: reason.startDate || dayjs().add(1,"day"),
                isPayShift: reason.isPayShift || false
            })
        }
    },[reason]);

    const onHandleSubmit = async (values) => {
        let data = {
            name: values.name,
            startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
            isPayShift: values.isPayShift ? 1 : 0,
            idRequestType: type === "OT" ? 2 : 1,
            ...reason ? {
                idRequestReason : reason.idRequestReason
            } : {}
        };
        let result = null; 
        if(reason){
            result = await dispatch(updateRequestReason(data));
        } else {
            result = await dispatch(addRequestReason(data));
        }
        if (result.status === 200) {
            handleOpenAlert("success");
            renderRequestReason();
        } else {
            handleOpenAlert("error");
        }
        onClose();
    };

    return (
        <DrawerCustom
            title={
                `${reason ? "แก้ไข" : "เพิ่ม"}เหตุผล${type === "OT" ? "ขอค่าล่วงเวลา" : "ขอรับรองเวลาทำงาน" }`
            }
            anchor="right"
            open={open}
            onClose={onClose}
        >
            <Root>
                <form onSubmit={handleSubmit(onHandleSubmit)}>
                    <Typography>ชื่อเหตุผล</Typography>
                    <Controller 
                        control={control}
                        name="name"
                        rules={{
                            required: { value: true, message: "กรุณากรอกชื่อเหตุผล" }
                        }}
                        render={({ field }) => (
                            <TextFieldTheme 
                                {...field}
                                style={{ marginTop: "8px" }}
                                error={errors && errors.name}
                                helperText={errors && errors.name && errors.name.message}
                            />
                        )}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Typography style={{ marginTop: "16px" }}>วันที่ใช้งาน</Typography>
                        <Controller 
                            control={control}
                            name="startDate"
                            rules={{
                                required: { value: true, message: "กรุณาเลือกวันที่ใช้งาน" }
                            }}
                            render={({ field }) => (
                                <DatePicker 
                                    {...field}
                                    minDate={dayjs().add(1, "day")}
                                    renderInput={(params) => (
                                        <TextFieldTheme
                                            {...params} 
                                            style={{ marginTop: "8px" }}
                                            error={errors && errors.startDate}
                                            helperText={errors && errors.startDate && errors.startDate.message}
                                        />
                                    )}
                                    
                                />
                            )}
                        />
                    </LocalizationProvider>
                    {type === "OT" && 
                        <div style={{ marginTop: "16px" }}>
                            <FormGroup>
                                <Controller 
                                    control={control}
                                    name="isPayShift"
                                    render={({ field }) => (
                                        <FormControlLabel 
                                            control={
                                                <Switch
                                                    checked={field.value}
                                                    onChange={(event) => {
                                                        field.onChange(event.target.checked);
                                                    }}
                                                />
                                            } 
                                            label="ได้รับค่ากะ" 
                                        />
                                    )}
                                />
                            </FormGroup>
                        </div>
                    }
                    <Grid container justifyContent="flex-end"  style={{ marginTop: "16px" }}>
                        <Grid item>
                            <ButtonBlue
                                onClick={onClose}
                            >ยกเลิก</ButtonBlue>
                            <ButtonBlue
                                type="submit"
                                variant="contained"
                                style={{ marginLeft: "8px" }}
                            >
                                {reason ? `บันทึก` : `ยืนยัน`}
                            </ButtonBlue>
                        </Grid>
                    </Grid>
                </form>
            </Root>
        </DrawerCustom>
    );
};

export default DrawerReason;