import { httpClient } from "./httpClient";

const getPolicyByIdCompany = () => {
  return httpClient.get("company-policy");
};

const getPolicyActiveByIdCompany = () => {
  return httpClient.get("company-policy-active");
};

const addPolicy = (formData) => {
  return httpClient.post("company-policy", formData);
};

const updatePolicy = (formData) => {
  return httpClient.put("company-policy", formData);
};

const deletePolicy = (id) => {
  return httpClient.delete(`company-policy/${id}`);
};

export default {
  getPolicyByIdCompany,
  getPolicyActiveByIdCompany,
  addPolicy,
  updatePolicy,
  deletePolicy,
};
