/////////////// Not Fixed Yet

import React from "react";
import { 
    Page, 
    Text, 
    Document, 
    Image,
    Font,
    StyleSheet,
    Line,
    Svg,
    Polygon,
} from '@react-pdf/renderer';

const DocSomeData = ({data}) => {
    
    Font.register({
        family: 'THSarabunNew',
        fonts: [
            {src: `${process.env.REACT_APP_URL}fonts/THSarabunNew.ttf`},
            {src: `${process.env.REACT_APP_URL}fonts/THSarabunNew Bold.ttf`, fontWeight: 'bold'}
        ]
    });

    const styles = StyleSheet.create({
        image: {margin: 20,marginLeft: 50 ,width: 155, height: 200},
        text: {fontSize: 15 , fontFamily: 'THSarabunNew'},
        textLeft:{position: 'absolute', left: 50},
        textCenter:{position: 'absolute', left: 220},
        textRight: {position: 'absolute', left: 400},
        textNewRight: {position: 'absolute', left: 300},
        textgrade: {top:540, position: 'absolute', fontSize: 26 , fontFamily: 'THSarabunNew', fontWeight:'bold'},
        textYear: {top:520, position: 'absolute',},
        textInHeader: {color: 'white'},
        textMainTop : {fontSize: 20 , fontFamily: 'THSarabunNew', fontWeight: 'bold'}
    });

    return(
        <Document >
           <Page size='A4' style={{flexDirection : 'row',}}>
                <Svg height="300" width="600" style={{position: 'absolute'}}>
                    <Polygon
                        points='0,0 600,0 600,230 0,230 '
                        fill='#00054c'
                        stroke='#00054c'
                        strokeWidth={0}
                    />
                </Svg>
                <Image 
                      style={{margin: 20,marginLeft: 30 ,width: 165, height: 190}}
                      src={`${process.env.REACT_APP_API_URL}image/profile/${data.avatarImg}`}
                 />
                <Text style={[styles.text,styles.textCenter, styles.textInHeader,{top:30}]} >
                    <Text> ID : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.id}</Text>
                </Text>
                <Text style={[styles.text,styles.textRight, styles.textInHeader,{top: 30} ]} >
                    <Text>Status :</Text> 
                    <Text style={{ fontWeight: 'bold'}}> {data.status === 'member' ? 'meber': 'fire'} </Text>
                </Text>
                <Text style={[styles.text,styles.textCenter, styles.textInHeader,{top:70}]} >
                    <Text> Name : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.firstName} {data.lastName}</Text>
                </Text>
                <Text style={[styles.text,styles.textRight, styles.textInHeader, {top: 70} ]} >
                    <Text>Position : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.duty}</Text>
                </Text>
                <Text style={[styles.text,styles.textCenter, styles.textInHeader, {top:110}]} >
                    <Text>Birthday :</Text>
                    <Text style={{ fontWeight: 'bold'}}> {data.birthday}</Text>
                </Text>
                <Text style={[styles.text,styles.textRight, styles.textInHeader, {top: 110} ]} >
                    <Text>Age : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.age}</Text>
                </Text>
                <Text style={[styles.text,styles.textCenter, styles.textInHeader, {top:150}]} >
                    <Text>Hiring Date : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.hireDate}</Text>
                </Text>
                <Text style={[styles.text,styles.textRight, styles.textInHeader, {top: 150} ]} >
                    <Text>Service Year : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.serviceYear}</Text>
                </Text>
                <Text style={[styles.text,styles.textCenter, styles.textInHeader, {top:190}]} >
                    <Text>ESY : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.esy}</Text>
                </Text>
                <Text style={[styles.text,styles.textRight, styles.textInHeader, {top: 190} ]} >
                    <Text>OESY : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.oesy}</Text>
                </Text>
                {/* <Svg height="230" width="600" style={{position: 'absolute'}}>
                    <Line 
                        x1='40' 
                        y1='230' 
                        x2='550' 
                        y2='230' 
                        strokeWidth={1} 
                        stroke="#d4d4d4"
                    />
                </Svg> */}
                <Text style={[styles.text,styles.textLeft, {top:240}]}>
                    <Text>Company : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.company}</Text>
                </Text>
                <Text style={[styles.text,styles.textNewRight,{top:240}]}>
                    <Text>Job Group : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.jobGroup.name}</Text>
                </Text>
                <Text style={[styles.text,styles.textLeft,{top:280}]}>
                    <Text>Sub Job Group : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.jobGroup.subJob}</Text>
                </Text>
                <Text style={[styles.text,styles.textNewRight, {top:280}]}>
                    <Text>Division : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.division}</Text>
                </Text>
                <Text style={[styles.text,styles.textLeft,{top:320}]}>
                    <Text>Department : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.department}</Text>
                </Text>
                <Text style={[styles.text,styles.textNewRight,{top:320}]}>
                    <Text>Section : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.section}</Text>
                </Text>
                
                 <Svg height="370" width="600" style={{position: 'absolute'}}>
                    <Line 
                        x1='40' 
                        y1='360' 
                        x2='550' 
                        y2='360' 
                        strokeWidth={1} 
                        stroke="#d4d4d4"
                    />
                </Svg>
                
                <Text style={[styles.text,styles.textLeft, {top:380}]}>
                    <Text>Employment type : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.roleUser}</Text>
                </Text>
                <Text style={[styles.text, styles.textNewRight, {top:380}]}>
                    <Text>TIG : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.tig}</Text>
                </Text>
                <Text style={[styles.text,styles.textLeft,{top:420}]}>
                    <Text>TIP : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.tip}</Text>
                </Text>
                <Text style={[styles.text,styles.textCenter, styles.textNewRight,{top:420}]}>
                    <Text>Work Location : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.section}</Text>
                </Text>
                
                <Svg height="480" width="600" style={{position: 'absolute'}}>
                    <Line 
                        x1='40' 
                        y1='460' 
                        x2='550' 
                        y2='460' 
                        strokeWidth={1} 
                        stroke="#d4d4d4"
                    />
                </Svg>
               
                <Text style={[styles.text,styles.textLeft, {top:480}]}>
                    <Text>Working Type : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.workType}</Text>
                </Text>
                <Text style={[styles.text,styles.textNewRight,{top:480}]}>
                    <Text>Payment Type : </Text>
                    <Text style={{ fontWeight: 'bold'}}>{data.payType}</Text>
                </Text>
                
                <Svg height="550" width="600" style={{position: 'absolute'}}>
                    <Line 
                        x1='40' 
                        y1='520' 
                        x2='550' 
                        y2='520' 
                        strokeWidth={1} 
                        stroke="#d4d4d4"
                    />
                </Svg>
                
                <Text style={[styles.textMainTop, styles.textLeft, {top: 540 }]}>
                    Education
                </Text>
                {data && data.education.map((val,index) => 
                    <Text key={index} style={[styles.text, styles.textLeft, {top: 570 + (index*30) }]} >
                        <Text style={{fontWeight:'bold',}}>
                            {val.degree}
                        </Text>
                        <Text>
                             , {val.year}
                        </Text>
                        <Text style={{fontWeight:'bold',}}>
                             , {val.major} 
                        </Text>
                        <Text>
                            , {val.faculity} faculity  , {val.institute}, {val.country}
                        </Text>
                    </Text>
                )}
                <Svg height="700" width="600" style={{position: 'absolute'}}>
                    <Line 
                        x1='40' 
                        y1='670' 
                        x2='550' 
                        y2='670' 
                        strokeWidth={1} 
                        stroke="#d4d4d4"
                    />
                </Svg>
                
                <Text style={[styles.textMainTop, styles.textLeft, {top: 690 }]}>
                    External Experience
                </Text>
                {data && data.experience.map((val,index) =>
                    <Text key={index} style={[styles.text, styles.textLeft, {top: 720 + (index*30) }]}>
                        <Text style={{fontWeight:'bold',}}>
                            {val.position}
                        </Text>
                        <Text >
                            &nbsp; at {val.department} , {val.company} , {val.province} , {val.country} &nbsp; since &nbsp; {val.date}
                        </Text>
                    </Text>
                )}
           </Page>
        </Document>
    )
}

export default DocSomeData;