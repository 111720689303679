import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Paper,
  Container,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ToggleButtonGroup,
  ToggleButton
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import PageviewIcon from "@mui/icons-material/Pageview";
import TableRowsIcon from "@mui/icons-material/TableRows";

import { getAllDivision } from "../../../../actions/division";
import { Link } from "react-router-dom";
import SearchData from "./searchData";

import ButtonBlue from "../../shared/general/ButtonBlue";



const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100vh",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  textData: {
    fontSize: 20,
    fontWeight: "bold",
  },
  textDataSubJob: {
    fontSize: 16,
    fontWeight: "bold",
  },
  section: {
    marginLeft: 20,
  },
  text: {
    fontSize: 15,
  },
  textMainData: {
    fontSize: 21,
    color: "#007afc",
    fontWeight: "bold",
  },
  textPosition: {
    fontSize: 18,
    fontWeight: "bold",
  },
}));

const Detail = ({ nameData, data, jobGroup, subJobGroup, power, actual }) => {
  const classes = useStyles();

  const color = ["red", "blue", "green", "#6a329f"];

  return (
    <Grid container>
      <Grid item xl={1} lg={1} md={1} sm={2} xs={3}>
        <Avatar
          style={{
            width: 50,
            height: 50,
            backgroundColor: color[Math.floor(Math.random() * 4)],
          }}
        >
          {data[0]}
        </Avatar>
      </Grid>
      <Grid item xl={3} lg={3} md={5} sm={5} xs={9} style={{ paddingTop: 10 }}>
        <Typography className={classes.text}>{nameData}</Typography>
        <Typography className={classes.textMainData}>{data}</Typography>
      </Grid>
      <Grid item xl={3} lg={3} md={6} sm={5} xs={12} style={{ paddingTop: 10 }}>
        <Typography className={classes.text}>Job Group</Typography>
        <Typography className={classes.textData}>{jobGroup}</Typography>
      </Grid>
      <Grid item xl={3} lg={3} md={5} sm={5} xs={12} style={{ paddingTop: 10 }}>
        <Typography className={classes.text}>Sub job Name</Typography>
        <div style={{ display: "flex" }}>
          {subJobGroup.map((val) => (
            <Typography className={classes.textDataSubJob}>
              {val.subJobName} ,&nbsp;
            </Typography>
          ))}
        </div>
      </Grid>
      <Grid
        item
        xl={1}
        lg={1}
        md={4}
        sm={4}
        xs={6}
        style={{ textAlign: "center", paddingTop: 10 }}
      >
        <Typography className={classes.text}>Manpower</Typography>
        <Typography className={classes.textMainData}>{power}</Typography>
      </Grid>
      <Grid item xl={1} lg={1} md={3} sm={3} xs={6} style={{ paddingTop: 10 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className={classes.text}>Actual</Typography>
          <Typography className={classes.textData}>{actual}</Typography>
        </div>
        {/* {actual >= power ? (
          <CProgress height={16} style={{ alignItems: "end", paddingTop: 10 }}>
            <CProgressBar color="danger" value={(actual * 100) / power} />
          </CProgress>
        ) : actual >= power * 0.8 ? (
          <CProgress height={16} style={{ alignItems: "end", paddingTop: 10 }}>
            <CProgressBar color="warning" value={(actual * 100) / power} />
          </CProgress>
        ) : (
          <CProgress height={16}>
            <CProgressBar color="success" value={(actual * 100) / power} />
          </CProgress>
        )} */}
      </Grid>
    </Grid>
  );
};

const ShowDivision = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: dataDivision } = useSelector((state) => state.dataDivision);

  useEffect(() => {
    dispatch(getAllDivision());
  }, []);

  const [name, setName] = useState("");

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const [expand, setExpand] = useState([false]);

  const setToExpand = (index) => {
    const data = [...expand];
    console.log(data[index]);
    data[index] = !data[index];
    setExpand(data);
  };

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== "list") {
      history.push("/admin/organization")
    }
  };

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 16,
          }}
        >
          <ToggleButtonGroup
            value={"list"}
            exclusive
            size="small"
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="chart" aria-label="chart">
              <i className="fad fa-sitemap" style={{ fontSize: 20 }}></i>
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              <TableRowsIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Grid container style={{ alignItems: "center" }}>
          <Grid item xl={10} lg={10} md={9} xs={12}>
            <Typography variant="h4">Division</Typography>
          </Grid>
          <Grid item xl={2} lg={2} md={3} xs={6} style={{ textAlign: "right" }}>
            <Link
              to={"/admin/showDivision/addDivision"}
              style={{ textDecoration: "none" }}
            >
              <div>
                <ButtonBlue variant="contained" startIcon={<AddIcon />}>
                  Add manpower
                </ButtonBlue>
              </div>
            </Link>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20, justifyContent: "right" }}>
          <SearchData onChangeName={onChangeName} />
        </Grid>
        <div style={{ marginTop: 16 }}>
          {dataDivision &&
            dataDivision
              .filter((val) =>
                name === ""
                  ? {}
                  : val.JobName.toLowerCase().search(name.toLowerCase()) !== -1
              )
              .map((val, index) => (
                <Accordion
                  key={index}
                  expanded={expand[index]}
                  style={{ padding: 5, marginBottom: 20 }}
                >
                  <AccordionSummary>
                    <Grid container>
                      <Grid
                        item
                        xl={11}
                        lg={11}
                        xs={11}
                        onClick={() => setToExpand(index)}
                      >
                        <Detail
                          nameData={"Division"}
                          data={val.name}
                          jobGroup={val.JobName}
                          subJobGroup={val.subJob}
                          power={val.number}
                          actual={val.actual}
                        />
                      </Grid>
                      <Grid
                        xl={1}
                        lg={1}
                        md={1}
                        xs={1}
                        style={{ textAlign: "center" }}
                      >
                        <Link
                          to={`/admin/showDivision/showMemberInDivision/${
                            val.id
                          } ${"-"} ${"-"}`}
                          style={{ textDecoration: "none" }}
                        >
                          <IconButton>
                            <PageviewIcon
                              style={{ color: "#007afc" }}
                              fontSize="large"
                            />
                          </IconButton>
                        </Link>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    {/* <Typography >Department</Typography> */}
                    {val.department.map((value, index) => (
                      <Accordion
                        key={index}
                        style={{
                          borderRadius: 20,
                          marginBottom: 10,
                          marginLeft: 20,
                          border: "2px solid rgba(238,238,238,0.8)",
                        }}
                      >
                        <AccordionSummary>
                          <Grid container>
                            <Grid item xl={11} lg={11} xs={11}>
                              <Detail
                                nameData={"Department"}
                                data={value.name}
                                jobGroup={val.JobName}
                                subJobGroup={val.subJob}
                                power={value.number}
                                actual={value.actual}
                              />
                            </Grid>
                            <Grid
                              xl={1}
                              lg={1}
                              md={1}
                              xs={1}
                              style={{ textAlign: "center" }}
                            >
                              <Link
                                to={`/admin/showDivision/showMemberInDivision/${
                                  val.id
                                } ${value.name} ${"-"}`}
                                style={{ textDecoration: "none" }}
                              >
                                <IconButton>
                                  <PageviewIcon
                                    style={{ color: "#007afc" }}
                                    fontSize="large"
                                  />
                                </IconButton>
                              </Link>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ paddingLeft: 20, paddingRight: 20 }}
                        >
                          {value.section.map((value2, index) => (
                            <Paper
                              key={index}
                              elevation={3}
                              style={{ padding: 20, margin: 10 }}
                            >
                              <Grid container>
                                <Grid item xl={11} lg={11} xs={11}>
                                  <Detail
                                    nameData={"Section"}
                                    data={value2.name}
                                    jobGroup={val.JobName}
                                    subJobGroup={val.subJob}
                                    power={value2.number}
                                    actual={value2.actual}
                                  />
                                </Grid>
                                <Grid
                                  xl={1}
                                  lg={1}
                                  md={1}
                                  xs={1}
                                  style={{ textAlign: "center" }}
                                >
                                  <Link
                                    to={`/admin/showDivision/showMemberInDivision/${val.id} ${value.name} ${value2.name}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <IconButton>
                                      <PageviewIcon
                                        style={{ color: "#007afc" }}
                                        fontSize="large"
                                      />
                                    </IconButton>
                                  </Link>
                                </Grid>
                              </Grid>
                            </Paper>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
        </div>
      </Container>
    </div>
  );
};

export default ShowDivision;
