import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";

import { useFormik } from "formik";
import * as yup from "yup";

import { styled } from "@mui/material/styles";

import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import ButtonLoadingBlue from "../../../../shared/general/ButtonLoadingBlue";
import DatePickerEng from "../../../../shared/general/DatePickerEng";
import DatePickerThai from "../../../../shared/general/DatePickerThai";

import { createPayruns } from "../../../../../../actions/payruns";
import { allPayTypes } from "../../../../../../actions/paytypes";
import { Typography } from "@mui/material";
import UploadFile from "./upload-file";
import FileSelected from "./file-selected";
import { useTranslation } from "react-i18next";

const StyledContent = styled("div")({
  width: 550,
  padding: 24,
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .wrap-upload": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const StyledFooter = styled("div")({
  marginTop: 16,
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  marginBottom: 16,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFormLabel = styled(FormLabel)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
  marginBottom: 8,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const DialogNew = (props) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: paytypesList } = useSelector((state) => state.paytypes);
  const [fileSelected, setFileSelected] = useState({
    ot: null,
    additionDeduction: null,
  });
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      payRunTypes: "normal",
      payperiod: new Date(),
      idPaymentType: "0",
      payrunMode: "2",
      payrunName: ``,
      isTimeAttendance: true,
      isOT: true,
      isRecurring: true,
      isOnetime: false,
      isIncome: true,
      paymentRoundNumber: "first",
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    dispatch(allPayTypes());
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    if (formFileSelected) {
      const result = await dispatch(createPayruns(formFileSelected));
      if (result && result.idPayrun > 0) {
        history.push(`/vendor/payroll/run/` + result.idPayrun);
      }
    } else {
      const formData = {
        payrunMode: values.payrunMode,
        payrunPeriod:
          values.paymentRound === "month"
            ? dayjs(values.payperiod)
                .set("date", 1)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .format("YYYY-MM-DD HH:mm:ss")
            : dayjs(values.payperiod)
                .set("date", 16)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .format("YYYY-MM-DD HH:mm:ss"),
        idPaymentType: parseInt(values.idPaymentType),
        payRunTypes: values.payRunTypes,
        payrunName: values.payrunName,
        isTimeAttendance: values.isTimeAttendance,
        isOT: values.isOT,
        isRecurring: values.isRecurring,
        isOnetime: values.isOnetime,
        isIncome: values.isIncome,
        paymentRoundNumber: "first",
      };
      const result = await dispatch(createPayruns(formData));
      if (result && result.idPayrun > 0) {
        history.push(`/vendor/payroll/run/` + result.idPayrun);
      }
    }
  };

  const handleSetFileSelected = (mode, value) => {
    if (value === null) {
      let temp = formFileSelected;
      //delete the key
      temp.delete(`file-${mode}`);

      setFormFileSelected(temp);
    }

    setFileSelected({ ...fileSelected, [mode]: value });
  };

  const handleClickDownload = (mode) => {
    if (mode === "OT") {
      window.open(
        `${process.env.REACT_APP_API_URL}files/Template payroll_Overtime.xlsx`
      );
    } else if (mode === "income") {
      window.open(
        `${process.env.REACT_APP_API_URL}files/Template payroll_Accumulated Income-Current Year.xlsx`
      );
    } else {
      const template = {
        finger: {
          sheet: ["attendance"],
          fileName: "attendance-finger.xlsx",
          header: {
            รหัสพนักงาน: "",
            ชื่อ: "",
            สกุล: "",
            เลขบัตรประจำตัวประชาชน: "",
            ประเภท: "",
            เวลาทำงาน: "",
            รหัสเครื่อง: "",
          },
        },
        additionDeduction: {
          sheet: ["addition", "deduction"],
          fileName: "Template payroll_Addition-Deduction.xlsx",
          headerAddition: {
            รหัสพนักงาน: "",
            ชื่อ: "",
            สกุล: "",
            เลขบัตรประจำตัวประชาชน: "",
          },
          headerDeduction: {
            รหัสพนักงาน: "",
            ชื่อ: "",
            สกุล: "",
            เลขบัตรประจำตัวประชาชน: "",
          },
        },
      };

      if (mode === "additionDeduction") {
        paytypesList.addition.forEach((x) => {
          if (x.isRecurring === 0) {
            template["additionDeduction"].headerAddition[
              `${x.name}_${x.code}`
            ] = "";
          }
        });
        paytypesList.deduction.forEach((x) => {
          if (x.isRecurring === 0) {
            template["additionDeduction"].headerDeduction[
              `${x.name}_${x.code}`
            ] = "";
          }
        });
      }

      const elementListAddition = [];
      const elementListDeduction = [];

      elementListAddition.push(template[mode].headerAddition);
      elementListDeduction.push(template[mode].headerDeduction);

      const wsAddition = XLSX.utils.json_to_sheet(elementListAddition);
      const wsDeduction = XLSX.utils.json_to_sheet(elementListDeduction);

      var workbook = XLSX.utils.book_new();

      template[mode].sheet.forEach((element) => {
        XLSX.utils.book_append_sheet(
          workbook,
          element === "addition" ? wsAddition : wsDeduction,
          element
        );
      });

      XLSX.writeFile(workbook, template[mode].fileName, { type: "file" });
    }
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title="สร้างรายการเงินเดือน"
      anchor={"right"}
    >
      <StyledContent>
        <form onSubmit={formik.handleSubmit}>
          <StyledFormLabel component="legend">ชื่อรายการ</StyledFormLabel>
          <TextFieldTheme
            style={{ marginBottom: 8 }}
            name="payrunName"
            value={formik.values.payrunName}
            onChange={formik.handleChange}
          />
          <div style={{ marginBottom: 8 }}>
            <StyledFormLabel component="legend">เดือน</StyledFormLabel>
            {localStorage.getItem("language") === "en" ? (
              <DatePickerEng
                views={["month", "year"]}
                inputFormat="MMMM YYYY"
                value={formik.values.payperiod}
                onChange={(date) => {
                  formik.setFieldValue("payperiod", date);
                }}
                name="payperiod"
                error={
                  formik.touched.payperiod && Boolean(formik.errors.payperiod)
                }
                helperText={formik.touched.payperiod && formik.errors.payperiod}
                variant="outlined"
              />
            ) : (
              <DatePickerThai
                views={["month", "year"]}
                inputFormat="MMMM yyyy"
                value={formik.values.payperiod}
                onChange={(date) => {
                  formik.setFieldValue("payperiod", date);
                }}
                name="payperiod"
                error={
                  formik.touched.payperiod && Boolean(formik.errors.payperiod)
                }
                helperText={formik.touched.payperiod && formik.errors.payperiod}
                variant="outlined"
              />
            )}
          </div>

          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.idPaymentType &&
              Boolean(formik.errors.idPaymentType)
            }
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              รูปแบบการจ่าย
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="idPaymentType"
              onChange={formik.handleChange}
              name="idPaymentType"
              value={formik.values.idPaymentType}
            >
              <FormControlLabel value="1" control={<Radio />} label="รายวัน" />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="รายเดือน"
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="รายวันและรายเดือน"
              />
            </RadioGroup>
            {Boolean(formik.errors.idPaymentType) && (
              <FormHelperText>{formik.errors.idPaymentType}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.payRunTypes && Boolean(formik.errors.payRunTypes)
            }
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              ประเภทรายการ
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="payRunTypes"
              onChange={formik.handleChange}
              name="payRunTypes"
              value={formik.values.payRunTypes}
            >
              <FormControlLabel
                value="normal"
                control={<Radio />}
                label="ปกติ"
              />
              <FormControlLabel
                value="off"
                control={<Radio />}
                label="Off-Cycle"
              />
            </RadioGroup>
          </FormControl>
          <StyledDivider />
          <Typography style={{ fontWeight: 500 }} gutterBottom>
            ตั้งค่ารายการข้อมูล
          </Typography>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.isTimeAttendance &&
              Boolean(formik.errors.isTimeAttendance)
            }
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              เวลาทำงาน
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="isTimeAttendance"
              onChange={formik.handleChange}
              name="isTimeAttendance"
              value={formik.values.isTimeAttendance}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="ข้อมูลจากระบบ"
              />
              <FormControlLabel
                disabled
                value={false}
                control={<Radio />}
                label="อัพโหลดเวลาทำงาน"
              />
            </RadioGroup>
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={formik.touched.isOT && Boolean(formik.errors.isOT)}
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              ชั่วโมงทำงานล่วงเวลา
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="isOT"
              onChange={formik.handleChange}
              name="isOT"
              value={formik.values.isOT}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="ข้อมูลจากระบบ"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="อัพโหลดชั่วโมงทำงานล่วงเวลา"
              />
            </RadioGroup>
          </FormControl>

          {formik.values.isOT === "false" && (
            <div>
              {fileSelected.ot ? (
                <FileSelected
                  fileSelected={fileSelected.ot}
                  mode="ot"
                  handleSetFileSelected={handleSetFileSelected}
                />
              ) : (
                <Fragment>
                  <UploadFile
                    mode="ot"
                    handleSetFileSelected={handleSetFileSelected}
                    setFormFileSelected={setFormFileSelected}
                    formFileSelected={formFileSelected}
                    values={formik.values}
                  />
                  <div className="wrap-upload">
                    <Link
                      variant="body2"
                      onClick={() => handleClickDownload("OT")}
                    >
                      ดาวน์โหลดเทมเพลต
                    </Link>
                  </div>
                </Fragment>
              )}
            </div>
          )}

          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.isRecurring && Boolean(formik.errors.isRecurring)
            }
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              เงินได้ - เงินหัก (ประจำ)
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="isRecurring"
              onChange={formik.handleChange}
              name="isRecurring"
              value={formik.values.isRecurring}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="คำนวณ"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="ไม่คำนวณ"
              />
            </RadioGroup>
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={formik.touched.isOnetime && Boolean(formik.errors.isOnetime)}
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              เงินได้ - เงินหัก (รายครั้ง)
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="isOnetime"
              onChange={formik.handleChange}
              name="isOnetime"
              value={formik.values.isOnetime}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="คำนวณ"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="ไม่คำนวณ"
              />
            </RadioGroup>
          </FormControl>

          {formik.values.isOnetime === "true" && (
            <div>
              {fileSelected.additionDeduction ? (
                <FileSelected
                  fileSelected={fileSelected.additionDeduction}
                  mode="additionDeduction"
                  handleSetFileSelected={handleSetFileSelected}
                />
              ) : (
                <Fragment>
                  <UploadFile
                    mode="additionDeduction"
                    handleSetFileSelected={handleSetFileSelected}
                    setFormFileSelected={setFormFileSelected}
                    formFileSelected={formFileSelected}
                    values={formik.values}
                  />
                  <div className="wrap-upload">
                    {paytypesList && (
                      <Link
                        variant="body2"
                        onClick={() => handleClickDownload("additionDeduction")}
                      >
                        ดาวน์โหลดเทมเพลต
                      </Link>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}

          <FormControl
            component="fieldset"
            className="radio"
            error={formik.touched.isIncome && Boolean(formik.errors.isIncome)}
          >
            <StyledFormLabel style={{ marginBottom: 0 }} component="legend">
              รายได้สะสมปีปัจจุบัน
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="isIncome"
              onChange={formik.handleChange}
              name="isIncome"
              value={formik.values.isIncome}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="ข้อมูลจากระบบ"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="อัพโหลดรายได้สะสมปีปัจจุบัน"
              />
            </RadioGroup>
          </FormControl>

          {formik.values.isIncome === "false" && (
            <div>
              {fileSelected.income ? (
                <FileSelected
                  fileSelected={fileSelected.income}
                  mode="income"
                  handleSetFileSelected={handleSetFileSelected}
                />
              ) : (
                <Fragment>
                  <UploadFile
                    mode="income"
                    handleSetFileSelected={handleSetFileSelected}
                    setFormFileSelected={setFormFileSelected}
                    formFileSelected={formFileSelected}
                    values={formik.values}
                  />
                  <div className="wrap-upload">
                    {paytypesList && (
                      <Link
                        variant="body2"
                        onClick={() => handleClickDownload("income")}
                      >
                        ดาวน์โหลดเทมเพลต
                      </Link>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}

          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              ยกเลิก
            </ButtonBlue>
            <ButtonLoadingBlue
              loading={loading}
              type="submit"
              variant="contained"
            >
              สร้างรายการเงินเดือน
            </ButtonLoadingBlue>
          </StyledFooter>
        </form>
      </StyledContent>
    </DrawerCustom>
  );
};

export default DialogNew;
