import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  IconButton,
  Divider,
  Grid,
  styled,
  Typography,
  Box,
} from "@mui/material";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import AlertResponse from "../../../../shared/general/AlertResponse";

import DialogEdit from "./DialogEdit";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";

import utils from "../../../../../../utils";
import { getEmployeeAdditionDeduction, updateEmployeeAdditionDeduction } from "../../../../../../actions/employee";

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "&.inner": {
    marginBottom: 0,
  },
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StylePriceDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const StyledBoxList = styled(Box)({
  width: "100%",
  padding: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& p": {
    fontStyle: "oblique",
  },
});

const AdditionDeduction = () => {
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employeeAdditionDeduction } = useSelector(
    (state) => state.employeeAdditionDeduction
  );

  const [mode, setMode] = useState("");
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);

  useEffect(() => {
    dispatch(getEmployeeAdditionDeduction(employeeProfile.idEmployees));
  }, []);

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenDelete(false);
  };

  const handleClickDelete = (mode) => {
    setOpenDelete(true);
    setMode(mode);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSetAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleSubmit = async () => {
    setDisabledBtnDelete(true);
    let tempFormData = { ...dataEdit };
    tempFormData.mode = tempFormData.idEmployeeAddition ? "Addition" : "Deduction";
    tempFormData.isDelete = true;
    const result = await dispatch(
      updateEmployeeAdditionDeduction(tempFormData)
    );
    if (result && result.status === 200) {
      dispatch(getEmployeeAdditionDeduction(employeeProfile.idEmployees));
      // handleSetAlertType("success");
      // handleOpenAlert();
      handleCloseDialog();
    } else {
      // handleSetAlertType("error");
      // handleOpenAlert();
      handleCloseDialog();
    }
  };

  return (
    <div>
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="subtitle1">รายการเงินได้</StyledHeadLabel>

          {/* <IconButton
            aria-label="add"
            size="small"
            onClick={() => handleClick("addition new")}
          >
            <AddCircleRoundedIcon fontSize="small" />
          </IconButton> */}
          <ButtonBlue
            variant="outlined"
            size="small"
            startIcon={<AddRoundedIcon />}
            onClick={() => {
              setDataEdit(null);
              handleClick("addition new");
            }}
          >
            เพิ่ม
          </ButtonBlue>
        </StyledWrapTop>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel variant="body2" color="text.secondary">
              ชื่อรายการ
            </StyledHeadLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel variant="body2" color="text.secondary">
              จำนวน
            </StyledHeadLabel>
          </Grid>
          {employeeAdditionDeduction &&
          employeeAdditionDeduction.additions.length > 0 ? (
            employeeAdditionDeduction.additions.map((addition) => (
              <Fragment key={addition.title}>
                <Grid item xs={12} md={6}>
                  <div>
                    <Typography variant="h6">{addition.name}</Typography>
                    <Typography color="text.third">
                      {addition.start
                        ? `${dayjs(addition.start).format(
                            "D MMM YYYY"
                          )} - ${dayjs(addition.end).format("D MMM YYYY")}`
                        : " "}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StylePriceDiv>
                    <Typography
                      variant="h6"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {`${utils.numberWithCommas(addition.value)} บาท`}
                    </Typography>
                    <div>
                      <IconButton aria-label="edit" size="small">
                        <EditRoundedIcon
                          fontSize="inherit"
                          onClick={() => {
                            setDataEdit(addition);
                            handleClick("addition edit");
                          }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          setDataEdit(addition);
                          handleClickDelete("addition");
                        }}
                      >
                        <DeleteOutlineRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </StylePriceDiv>
                </Grid>
              </Fragment>
            ))
          ) : (
            <StyledBoxList>
              <Typography color="text.secondary">ไม่มีข้อมูล</Typography>
            </StyledBoxList>
          )}
        </Grid>
      </div>
      <StyledDivider />
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="subtitle1" gutterBottom>
            รายการเงินหัก
          </StyledHeadLabel>
          <ButtonBlue
            variant="outlined"
            size="small"
            startIcon={<AddRoundedIcon />}
            onClick={() => {
              setDataEdit(null);
              handleClick("deduction new");
            }}
          >
            เพิ่ม
          </ButtonBlue>
        </StyledWrapTop>
        <Grid container spacing={2}>
          {employeeAdditionDeduction &&
          employeeAdditionDeduction.deductions.length > 0 ? (
            employeeAdditionDeduction.deductions.map((deduction) => (
              <Fragment key={deduction.title}>
                <Grid item xs={12} md={6}>
                  <StyledHeadLabel color="text.secondary">
                    ชื่อรายการ
                  </StyledHeadLabel>
                  <div
                    style={{
                      height: "calc( 100% - 21px )",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">{deduction.name}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledHeadLabel color="text.secondary">
                    จำนวน
                  </StyledHeadLabel>
                  <StylePriceDiv>
                    <Typography
                      variant="h6"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {`${utils.numberWithCommas(deduction.value)} บาท`}
                    </Typography>
                    <div>
                      <IconButton aria-label="edit" size="small">
                        <EditRoundedIcon
                          fontSize="inherit"
                          onClick={() => {
                            setDataEdit(deduction);
                            handleClick("deduction edit");
                          }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          console.log("deduction: ", deduction);
                          setDataEdit(deduction);
                          handleClickDelete("deduction");
                        }}
                      >
                        <DeleteOutlineRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </StylePriceDiv>
                </Grid>
              </Fragment>
            ))
          ) : (
            <StyledBoxList>
              <Typography color="text.secondary">ไม่มีข้อมูล</Typography>
            </StyledBoxList>
          )}
        </Grid>
      </div>
      <DialogEdit
        mode={mode}
        open={openDialog}
        dataEdit={dataEdit}
        handleCloseDialog={handleCloseDialog}
        handleOpenAlert={handleOpenAlert}
        handleSetAlertType={handleSetAlertType}
        dataAlready={employeeAdditionDeduction}
      />

      {dataEdit && (
        <DialogConfirmDelete
          open={openDelete}
          handleClose={handleCloseDialog}
          label={dataEdit.idAddition ? `รายการเงินได้` : `รายการเงินหัก`}
          text={`${dataEdit.name}: ${utils.numberWithCommas(
            dataEdit.value
          )} บาท`}
          handleDelete={handleSubmit}
          disabledBtnDelete={disabledBtnDelete}
        />
      )}

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </div>
  );
};

export default AdditionDeduction;
