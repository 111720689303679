import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Switch, Typography, styled } from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import { addPosition, getAllPositions } from "../../../../../actions/positions";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
})

const DialogEditPosition = (props) => {

  const { drawerConfig, onClose, setOpenAlert, setAlertType, setAlertConfig } = props;

  const dispatch = useDispatch();

  const { result: positionList } = useSelector((state) => state.positions);
  const { result: jobGroupList } = useSelector((state) => state.jobGroup);
  const { result: OrganizationStructureData } = useSelector(state => state.organizationStructure);
  const { result: LevelData } = useSelector((state) => state.level);
  

  const [stateAdd, setStateAdd] = useState(0);

  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [structureList, setStructureList] = useState([]);
  const [levelList, setLevelList] = useState([]);

  const [orgDetail, setOrgDetail] = useState({
    businessUnitName: null,
    divisionName: null,
    departmentName: null,
    sectionName: null,
  })

  const validateYupSchema = yup.object({
    positionName: yup.string().required("กรุณากรอกข้อมูล"),
    idDivision: yup.string().nullable().test('required', 'กรุณากรอกข้อมูล', (value) => value !== ""),
    idDepartment: yup.string().nullable().test('required', 'กรุณากรอกข้อมูล', (value) => value !== ""),
    idSection: yup.string().nullable().test('required', 'กรุณากรอกข้อมูล', (value) => value !== ""),
    code: yup.string().required("กรุณากรอกข้อมูล"),
  })

  const useHookForm = useForm({
    defaultValues: {
      code: "",
      positionName: "",
      positionName_EN: "",
      idSection: "",
      idDepartment: "",
      idDivision: "",
      idBusinessUnit: "",
      idJobGroup: "",
      isShow: "1",
      isUnderParent: "1",
      idLevel: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const onSubmit = async (data) => {


    const {
      code, 
      ...formData
    } = data;

    if(drawerConfig.isEdit){
      formData.idPosition = drawerConfig.data.idPosition
    }

    // console.log(formData);

    // return

    const result = await dispatch(addPosition(formData))
    
    if(result){
      if(result.status === 200){
        // setOpenAlert(true)
        // setAlertType("success")
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: "บันทึกข้อมูลสำเร็จ"
        }))
        dispatch(getAllPositions())
        onClose();
      } else {
        // setOpenAlert(true)
        // setAlertType("error")
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        }))
      }
    } else {
      // setOpenAlert(true)
      // setAlertType("error")
      setAlertConfig(prev => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      }))
    }
    // console.log(data)
  }

  useEffect(() => {
    if(drawerConfig.isOpen === true){
      if(drawerConfig.isEdit){

        const findStructure = structureList.find(item => 
          (item.idBusinessUnit || null) === (drawerConfig.data.idBusinessUnit || null) &&
          (item.idDivision || null) === (drawerConfig.data.idDivision || null) &&
          (item.idDepartment || null) === (drawerConfig.data.idDepartment || null) &&
          (item.idSection || null) === (drawerConfig.data.idSection || null)
        )

        // console.log(findStructure)

        useHookForm.reset({
          code: findStructure? findStructure.code: "",
          positionName: drawerConfig.data.positionName || "",
          positionName_EN: drawerConfig.data.positionName_EN || "",
          idSection: drawerConfig.data.idSection,
          idDepartment: drawerConfig.data.idDepartment,
          idDivision: drawerConfig.data.idDivision,
          idBusinessUnit: drawerConfig.data.idBusinessUnit,
          idJobGroup: drawerConfig.data.idJobGroup || "",
          isShow: String(drawerConfig.data.isShow) || "1",
          isUnderParent: drawerConfig.data.isUnderParent,
          idLevel: drawerConfig.data.idLevel || "",
        })
        setOrgDetail({
          businessUnitName: drawerConfig.data.businessUnitName || null,
          divisionName: drawerConfig.data.divisionName || null,
          departmentName: drawerConfig.data.departmentName || null,
          sectionName: drawerConfig.data.sectionName || null,
        })
      } else {
        useHookForm.reset({
          code: "",
          positionName: "",
          positionName_EN: "",
          idSection: "",
          idDepartment: "",
          idDivision: "",
          idBusinessUnit: "",
          idJobGroup: "",
          isShow: "1",
          isUnderParent: "1",
          idLevel: "",
        })
        setOrgDetail({
          businessUnitName: null,
          divisionName: null,
          departmentName: null,
          sectionName: null,
        })
      }
    }

  }, [drawerConfig.isOpen])

  // useEffect(() => {
  //   if(positionList){

  //     const divisionMap = new Map();
  //     const departmentMap = new Map();
  //     const sectionMap = new Map();
      
  //     positionList.map(p => {
  //       if(!divisionMap.has(p.idDivision)){
  //         divisionMap.set(p.idDivision, {idDivision: p.idDivision, divisionName: p.divisionName})
  //       }
  //       if(!departmentMap.has(`${p.idDivision}_${p.idDepartment}`)){
  //         departmentMap.set(`${p.idDivision}_${p.idDepartment}`, {idDivision: p.idDivision, idDepartment: p.idDepartment, departmentName: p.departmentName})
  //       }
  //       if(!sectionMap.has(`${p.idDivision}_${p.idDepartment}_${p.idSection}`)){
  //         sectionMap.set(`${p.idDivision}_${p.idDepartment}_${p.idSection}`, {idDivision: p.idDivision, idDepartment: p.idDepartment, idSection: p.idSection, sectionName: p.sectionName})
  //       }
  //       if(!sectionMap.has(`${p.idDivision}_${p.idDepartment}_null`)){
  //         sectionMap.set(`${p.idDivision}_${p.idDepartment}_null`, {idDivision: p.idDivision, idDepartment: p.idDepartment, idSection: null, sectionName: null})
  //       }

  //       //return p
  //     })

  //     setDivisionList(Array.from(divisionMap, ([_, value]) => value))
  //     setDepartmentList(Array.from(departmentMap, ([_, value]) => value))
  //     setSectionList(Array.from(sectionMap, ([_, value]) => value))

  //     // console.log(divisionList);
  //     // console.log(departmentList);
  //     // console.log(sectionList);
  //   }
  // }, [positionList])

  useEffect(() => {
    if(OrganizationStructureData){
      const businessUnitList = [...OrganizationStructureData.businessUnits];
      const divisionList = [...OrganizationStructureData.divisions];
      const departmentList = [...OrganizationStructureData.departments];
      const sectionList = [...OrganizationStructureData.sections];

      const structureList = [
        ...OrganizationStructureData.ceos,
        ...OrganizationStructureData.businessUnits,
        ...OrganizationStructureData.divisions,
        ...OrganizationStructureData.departments,
        ...OrganizationStructureData.sections,
      ]

      // businessUnitList.push({
      //   idBusinessUnit: null,
      //   name: "ไม่มี"
      // })

      // businessUnitList.map(item => {
      //   divisionList.push({
      //     idBusinessUnit: item.idBusinessUnit,
      //     idDivision: null,
      //     name: "ไม่มี"
      //   })
      // })

      // divisionList.push({
      //   idDivision: null,
      //   name: "ไม่มี"
      // })

      // divisionList.map(item => {
      //   departmentList.push({
      //     idBusinessUnit: item.idBusinessUnit,
      //     idDivision: item.idDivision,
      //     idDepartment: null,
      //     name: "ไม่มี"
      //   })
      // })
      // departmentList.push({
      //   idDivision: null,
      //   idDepartment: null,
      //   name: "ไม่มี"
      // })
      // departmentList.map(item => {
      //   sectionList.push({
      //     idBusinessUnit: item.idBusinessUnit,
      //     idDivision: item.idDivision,
      //     idDepartment: item.idDepartment,
      //     idSection: null,
      //     name: "ไม่มี"
      //   })
      // })
      // sectionList.push({
      //   idDepartment: null,
      //   idSection: null,
      //   name: "ไม่มี"
      // })

      // setBusinessUnitList(businessUnitList)
      // setDivisionList(divisionList)
      // setDepartmentList(departmentList)
      // setSectionList(sectionList)
      setStructureList(structureList)
    }
  }, [OrganizationStructureData])

  useEffect(() => {
    if(LevelData){
      const levelList = [];

      console.log(LevelData)
      LevelData.map(item => {
        item.level.map(l => {
          levelList.push(l)
        })
      })

      setLevelList(levelList)

    }
  }, [LevelData])

  // console.log(divisionList);
  // console.log(departmentList);
  // console.log(sectionList);

  return (
    <DrawerCustom
      title={drawerConfig.isEdit? "แก้ไขตำแหน่งงาน": "เพิ่มตำแหน่งงาน"}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box marginBottom="4px" display="flex" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight="500">ตำแหน่งงาน</Typography>
              </Box>
              <Controller
                name="positionName"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box marginBottom="4px" display="flex" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight="500">ตำแหน่งงาน (อังกฤษ)</Typography>
              </Box>
              <Controller
                name="positionName_EN"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      placeholder: "(ไม่บังคับ)"
                    }}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box marginBottom="4px" display="flex" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight="500">ตำแหน่งภายใต้</Typography>
              </Box>
              <Controller
                name="code"
                control={useHookForm.control}
                render={({field}) => (
                  <Autocomplete
                    options={structureList}
                    getOptionLabel={(option) => `${option.code? option.name: "ไม่มี"}`}
                    // filterOptions={filterOptions}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.code}>
                        <Box>
                          <Typography fontSize="12px" color="text.secondary">{option.code}</Typography>
                          <Typography>{option.name}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        onBlur={field.onBlur}
                        error={useHookForm.formState.errors[field.name]? true: false}
                        helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      />
                    )}
                    value={structureList? structureList.find(option => option.code === field.value) || null: null}
                    onChange={(_, value) => {
                      field.onChange(value? value.code: "");
                      if(value){
                        useHookForm.setValue("idBusinessUnit", value.idBusinessUnit || null);
                        useHookForm.setValue("idDivision", value.idDivision || null);
                        useHookForm.setValue("idDepartment", value.idDepartment || null);
                        useHookForm.setValue("idSection", value.idSection || null);
                        console.log(value)
                        setOrgDetail({
                          businessUnitName:  value.businessUnitName || null,
                          divisionName: value.divisionName || null,
                          departmentName: value.departmentName || null,
                          sectionName: value.sectionName || null,
                        })
                      }
                    }}
                    disableClearable
                  />
                )}
              />

            </Grid>

            <Grid item xs={12}>
              <Box marginBottom="4px" display="flex" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight="500">ประเภทตำแหน่งในโครงสร้าง</Typography>
              </Box>
              <Controller
                name="isUnderParent"
                control={useHookForm.control}
                render={({field}) => (
                  <RadioGroup
                    {...field}
                    row
                  >
                    <FormControlLabel value={1} control={<Radio />} label="ใต้โครงสร้าง"/>
                    <FormControlLabel value={0} control={<Radio />} label="ในโครงสร้าง"/>
                  </RadioGroup>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box marginBottom="4px" display="flex" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight="500">กลุ่มงาน</Typography>
              </Box>
              <Controller
                name="idJobGroup"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    select
                    SelectProps={{
                      displayEmpty: true,
                      // renderValue: (value) => {
                      //   if(value === ""){
                      //     return "ไม่บังคับ"
                      //   } else {
                      //     return value
                      //   }
                      // }
                    }}
                  >
                    <MenuItem value="">ไม่มี</MenuItem>
                    {jobGroupList && jobGroupList.map((item, index) => (
                      <MenuItem key={index} value={item.idJobGroup}>{item.jobGroupName}</MenuItem>
                    ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box marginBottom="4px" display="flex" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight="500">ระดับงาน</Typography>
              </Box>
              <Controller
                name="idLevel"
                control={useHookForm.control}
                render={({field}) => (
                  <Autocomplete
                    options={levelList}
                    getOptionLabel={(option) => `${option.levelName}`}
                    // filterOptions={filterOptions}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idLevel}>
                        <Box>
                          {/* <Typography fontSize="12px" color="text.secondary">{option.code}</Typography> */}
                          <Typography>{option.levelName}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        onBlur={field.onBlur}
                        error={useHookForm.formState.errors[field.name]? true: false}
                        helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      />
                    )}
                    value={levelList? levelList.find(option => option.idLevel === field.value) || null: null}
                    onChange={(_, value) => {
                      field.onChange(value? value.idLevel: "");
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Accordion
                disableGutters
                elevation={0}
                square
                sx={{
                  // border: `1px solid #919eab`,
                  '&:not(:last-child)': {
                    borderBottom: 0,
                  },
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
                  sx={{
                    padding: 0,
                    // backgroundColor: 'rgba(0, 0, 0, .03)',
                    // flexDirection: 'row-reverse',
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                      transform: 'rotate(90deg)',
                    },
                    '& .MuiAccordionSummary-content': {
                      // marginLeft: "8px",
                    },
                  }}
                >
                  <Typography fontWeight="500">ตั้งค่าเพิ่มเติม</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: "16px 0",
                    // borderTop: '1px solid rgba(0, 0, 0, .125)',
                  }}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Typography fontSize="14px">แสดงผลตัวเลือก</Typography>
                    <Controller
                      name="isShow"
                      control={useHookForm.control}
                      render={({field}) => (
                        <Switch 
                          {...field}
                          checked={String(field.value) === "1"}
                          onChange={(event) => {field.onChange(event.target.checked? "1": "0")}}
                          // checked={field.value === 1}
                        />
                      )}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="500" marginBottom="8px">รายละเอียดของโครงสร้าง</Typography>
              <Typography><span style={{color: "#919eab", fontSize: "14px", marginRight: "4px"}}>หน่วยธุรกิจ:</span>{orgDetail.businessUnitName? orgDetail.businessUnitName: "-"}</Typography>
              <Typography><span style={{color: "#919eab", fontSize: "14px", marginRight: "4px"}}>ฝ่าย:</span>{orgDetail.divisionName? orgDetail.divisionName: "-"}</Typography>
              <Typography><span style={{color: "#919eab", fontSize: "14px", marginRight: "4px"}}>ส่วน:</span>{orgDetail.departmentName? orgDetail.departmentName: "-"}</Typography>
              <Typography><span style={{color: "#919eab", fontSize: "14px", marginRight: "4px"}}>แผนก:</span>{orgDetail.sectionName? orgDetail.sectionName: "-"}</Typography>
              {/* <Typography></Typography>
              <Typography></Typography>
              <Typography></Typography> */}
            </Grid>
            
            <Grid item xs={12} container gap="8px" justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>บันทึกข้อมูล</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DialogEditPosition;