import React, { useEffect, useState } from "react";
import useTable from "../../../shared/table/useTable";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import ConfirmDialog from "../../../shared/general/ConfirmDialog";
import {
  Grid,
  InputAdornment,
  TableRow,
  TextField,
  Toolbar,
  TableBody,
  TableCell,
  Button,
  Paper,
  Avatar,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";

import { useSelector, useDispatch } from "react-redux";
import { getAllTeamPlan } from "../../../../../actions/TeamPlans";

const headCells = [
  { id: "quater", label: "Quater" },
  { id: "objectiveName", label: "Objective Name" },
  { id: "department", label: "Department" },
  { id: "dateCreate", label: "Date" },
  { id: "view", label: "" },
];

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  container: {
    padding: 16,
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  approveBtn: {
    borderRadius: "50px",
    backgroundColor: "#4caf50",
    marginLeft: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#43a047",
    },
  },
  notApproveBtn: {
    borderRadius: "50px",
    backgroundColor: "#f44336",
    marginLeft: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#e53935",
    },
    
  },
  viewBtn: {
    backgroundColor: "#4f65df",
    color: "#fff",
    borderRadius: "50px",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#4f65df",
    },
  },
  quater: {
    backgroundColor: "#e53935",
    fontSize: "18px",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
}));

function TeamCrossPlanList() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: teamPlanStore } = useSelector((state) => state.TeamPlans);

  const [records, setRecords] = useState([]);
  const [recordItems, setRecordItems] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const fetchData = () => {
    dispatch(getAllTeamPlan());
  };

  useEffect(() => {
    if (teamPlanStore) {
      let teamPlans = [...teamPlanStore];
      let department = "HR TPE";

      teamPlans.filter(function (item) {
        return (
          item.planStatus.includes("Waiting Approval") &&
          (item.nameCrossTeam1.includes(department) ||
            item.nameCrossTeam2.includes(department) ||
            item.nameCrossTeam3.includes(department))
        );
      });

      setRecords(teamPlans);
      setRecordItems(teamPlans);
      setIsLoading(false);
    }
  }, [teamPlanStore]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let x = [...recordItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setRecords(x);
  }, [searchKey]);

  return (
    <div className={classes.root}>
      <Toolbar>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TextField
              variant="outlined"
              style={{ width: "100%" }}
              label="Search Objective"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Paper className={classes.container}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                  style={{ width: "100px", padding: "20px" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {(recordsAfterPagingAndSorting().length > 0 &&
                  recordsAfterPagingAndSorting().map((item) => (
                    <TableRow key={item.planTeamId} hover>
                      <TableCell style={{ width: "100px" }}>
                        <Avatar className={classes.quater}>
                          {item.quater}
                        </Avatar>
                      </TableCell>
                      <TableCell>{item.objectiveName}</TableCell>
                      <TableCell style={{ width: "200px" }}>
                        {item.crossTeam1}
                      </TableCell>
                      <TableCell style={{ width: "200px" }}>
                        {dayjs(item.dateCreate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell style={{ width: "100px" }} align="center">
                        <Button
                          variant="outlined"
                          className={classes.viewBtn}
                          onClick={() => {
                            history.push({
                              pathname: `/approval/crossteam/${item.planTeamId}`,
                              state: { teamList: item },
                            });
                          }}
                        >
                          <SearchIcon /> View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))) || (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ height: "100px" }}
                    >
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default TeamCrossPlanList;
