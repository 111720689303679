import { httpClient } from "./httpClient";

const getMetadataObjective = () => {
  return httpClient.get(`/okrs/metadata/objective`);
};

const addIndividualObjective = (formData) => {
  return httpClient.post(`/okrs/individual-objective`, formData);
};

const addTeamObjective = (formData) => {
  return httpClient.post(`/okrs/team-objective`, formData);
}

const addOrganizationObjective = (formData) => {
  return httpClient.post(`/okrs/organization-objective`, formData);
};

const getMyIndividualObjective = () => {
  return httpClient.get(`/okrs/individual-objective`);
};

const getTeamObjective = () => {
  return httpClient.get(`/okrs/team-objective`);
};

const getOrganizationObjective = () => {
  return httpClient.get(`/okrs/organization-objective`);
};

const getIndividualObjectiveByIdIndividualObjective = (idIndividualObjective) => {
  return httpClient.get(`/okrs/individual-objective/${idIndividualObjective}`);
};

const getTeamObjectiveByIdTeamObjective = (idTeamObjective) => {
  return httpClient.get(`/okrs/team-objective/${idTeamObjective}`);
};

const getOrganizationObjectiveByIdOrganizationObjective = (idOrganizationObjective) => {
  return httpClient.get(`/okrs/organization-objective/${idOrganizationObjective}`);
};

const approveIndividualKeyResult = (idIndividualkeyResult, formData) => {
  return httpClient.put(`/okrs/individual-keyresult/${idIndividualkeyResult}`, formData);
};

const updateIndividualKeyResult = (idIndividualKeyResult, formData) => {
  return httpClient.put(`/okrs/individual-keyresult/${idIndividualKeyResult}`, formData);
};

const updateTeamKeyResult = (idTeamKeyResult, formData) => {
  return httpClient.put(`/okrs/team-keyresult/${idTeamKeyResult}`, formData);
};

const updateOrganizationKeyResult = (idOrganizationKeyResult, formData) => {
  return httpClient.put(`/okrs/organization-keyresult/${idOrganizationKeyResult}`, formData);
};

const getAllObjectiveForApprove = () => {
  return httpClient.get(`/okrs/approval`);
};

const getAllObjectiveForEvaluate = () => {
  return httpClient.get(`/okrs/evaluation`);
};

const getIndividualObjectiveForApprove = (idIndividualObjective) => {
  return httpClient.get(`/okrs/individual-objective-approve/${idIndividualObjective}`);
};

const getIndividualObjectiveForEvaluate = (idIndividualObjective) => {
  return httpClient.get(`/okrs/individual-objective-evaluate/${idIndividualObjective}`);
};

const getAllIndividualKeyResultProgress = (idIndividualKeyResult) => {
  return httpClient.get(`/okrs/individual-keyresult/${idIndividualKeyResult}/progress`);
};

const getAllTeamKeyResultProgress = (idTeamKeyResult) => {
  return httpClient.get(`/okrs/team-keyresult/${idTeamKeyResult}/progress`);
};

const getAllOrganizationKeyResultProgress = (idOrganizationKeyResult) => {
  return httpClient.get(`/okrs/organization-keyresult/${idOrganizationKeyResult}/progress`);
};

const addIndividualKeyResultProgress = (idIndividualKeyResult, formData) => {
  return httpClient.post(`/okrs/individual-keyresult/${idIndividualKeyResult}/progress`, formData);
};

const addTeamKeyResultProgress = (idTeamKeyResult, formData) => {
  return httpClient.post(`/okrs/team-keyresult/${idTeamKeyResult}/progress`, formData);
};

const addOrganizationKeyResultProgress = (idOrganizationKeyResult, formData) => {
  return httpClient.post(`/okrs/organization-keyresult/${idOrganizationKeyResult}/progress`, formData);
};

const getDashboardIndividual = (query) => {
  return httpClient.get(`/okrs/dashboard/individual`, {params: query});
};

const getDashboardTeam = (query) => {
  return httpClient.get(`/okrs/dashboard/team`, {params: query});
};

const getDashboardOrganization = (query) => {
  return httpClient.get(`/okrs/dashboard/organization`, {params: query});
};

export default {
  getMetadataObjective,
  addIndividualObjective,
  addTeamObjective,
  addOrganizationObjective,
  getMyIndividualObjective,
  getTeamObjective,
  getOrganizationObjective,
  getIndividualObjectiveByIdIndividualObjective,
  getTeamObjectiveByIdTeamObjective,
  getOrganizationObjectiveByIdOrganizationObjective,
  approveIndividualKeyResult,
  updateIndividualKeyResult,
  updateTeamKeyResult,
  updateOrganizationKeyResult,
  getAllObjectiveForApprove,
  getAllObjectiveForEvaluate,
  getIndividualObjectiveForApprove,
  getIndividualObjectiveForEvaluate,
  getAllIndividualKeyResultProgress,
  getAllTeamKeyResultProgress,
  getAllOrganizationKeyResultProgress,
  addIndividualKeyResultProgress,
  addTeamKeyResultProgress,
  addOrganizationKeyResultProgress,
  getDashboardIndividual,
  getDashboardTeam,
  getDashboardOrganization,
}