import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getAllLeaveBalanceByEmployeeId = (year) => {
  return httpClient.get(`/users/leave?year=${year}`);
};

const allLeaveType = () => {
  return httpClient.get(`/leave-type`);
};

const deleteLeaveType = (idLeaveType) => {
  return httpClient.delete(`/leave-type/${idLeaveType}`);
};

const deleteLeaveGroup = (idLeaveGroup) => {
  return httpClient.delete(`/leave-group/${idLeaveGroup}`);
};

const allLeaveEmployeeByManager = (search) => {
  return httpClient.get(
    `request/leave?start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const getAllLeaveWithDrawBy = (filter, search) => {
  return httpClient.get(
    // `request/leave/withdraw?filter=${filter}&start=${dayjs(search.start).format(
    //   "YYYY-MM-DD"
    // )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
    `request/leave/withdraw`
    , {params: {filter: filter? filter: undefined, start: search && search.start? dayjs(search.start).format("YYYY-MM-DD"): undefined, end: search && search.end? dayjs(search.end).format("YYYY-MM-DD"): undefined}});
};

const updateApproveLeaveEmployeeBy = (filter, formData) => {
  return httpClient.put(`request/leave/approve?filter=${filter}`, formData);
};

const withdrawLeaveEmployee = (filter, formData) => {
  if (filter) {
    return httpClient.put(`request/leave/withdraw?filter=${filter}`, formData);
  } else {
    return httpClient.put(`request/leave/withdraw`, formData);
  }
};

const addLeaveType = (formData) => {
  return httpClient.post(`/leave-type`, formData);
};

const addLeaveEmployees = (formData) => {
  return httpClient.post(`/employee/leave`, formData);
};

const uploadFileLeaveType = (formData) => {
  return httpClient.post(`/leave`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getAllLeaveEmployeesBy = (filter, search) => {
  return httpClient.get(
    `leave?filter=${filter}&start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const uploadExcelLeaveEmployees = (formData) => {
  return httpClient.post(`/upload-excel/leave-employees`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

const getExcelDataLeaveTypeEmployee = () => {
  return httpClient.get(`/download-excel/leave-type-employees`);
}

const getAllLeaveEmployee3month = (start, end,id) => {
  return httpClient.get(
    `AllLeave3month/${id}?start=${dayjs(start).format(
      "YYYY-MM-DD")}&end=${dayjs(end).format(
        "YYYY-MM-DD")}`)
};

const getAllLeaveEmployeeYear = (start,id) => {
  return httpClient.get(
    `AllLeaveEmployeeYear/${id}?start=${dayjs(start).format(
      "YYYY-MM-DD")}`)
};

const getAllemployeeAbsentYear = (start,id) => {
  return httpClient.get(
    `AllLeaveEmployeeAbsentYear/${id}?start=${dayjs(start).format(
      "YYYY-MM-DD")}`)
};

const getAllLeaveV2 = () => {
  return httpClient.get(`v2/leave`)
}

const addLeaveQuota = (formData) => {
  return httpClient.post(`leave-quota`, formData)
}

const updateLeaveQuota = (idLeaveQuota, formData) => {
  return httpClient.put(`leave-quota/${idLeaveQuota}`, formData)
}

export default {
  getAllLeaveBalanceByEmployeeId,
  allLeaveEmployeeByManager,
  updateApproveLeaveEmployeeBy,
  getAllLeaveWithDrawBy,
  withdrawLeaveEmployee,
  allLeaveType,
  addLeaveType,
  addLeaveEmployees,
  uploadFileLeaveType,
  getAllLeaveEmployeesBy,
  uploadExcelLeaveEmployees,
  getExcelDataLeaveTypeEmployee,
  getAllLeaveEmployee3month,
  getAllLeaveEmployeeYear,
  getAllemployeeAbsentYear,
  getAllLeaveV2,
  deleteLeaveType,
  deleteLeaveGroup,
  addLeaveQuota,
  updateLeaveQuota,
};
