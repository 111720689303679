import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  ListSubheader,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import DrawerCustom from "../general/Drawer";
import dayjs from "dayjs";
import {
  addRequestTime,
  getRequestReason,
  putRequestTime,
} from "../../../../actions/requestTime";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import TextFieldTheme from "../general/TextFieldTheme";
import TimePicker from "../general/TimePicker";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ButtonBlue from "../general/ButtonBlue";
import {
  calculatorOvertime,
  calculatorOvertimeByWorkingHours,
} from "./calculatorOvertime";
import { getAttendanceById } from "../../../../actions/attendance";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  width: 350,
  padding: 24,
  "@media only screen and (max-width: 375px)": {
    width: 320,
  },
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiList-root": {
    maxHeight: 300,
    overflow: "auto",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280,
    },
  },
};

const StyledFooter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  // "& .cancel": {
  //   marginRight: 8,
  // },
});

const DrawerRequestTime = (props) => {
  const dispatch = useDispatch();

  const {
    open,
    onClose,
    values,
    payrollSetting,
    mode,
    isEdit,
    employee,
    searchDate,
    overtimeValues,
    setNotify,
  } = props;

  const [formInputData, setFormData] = useState(null);
  const [reason, setReason] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    fetchRequestReason();
  }, []);

  const fetchRequestReason = async () => {
    const result = await dispatch(getRequestReason(userProfile.idCompany));
    if (result) {
      setReason(result);
    } else {
    }
  };

  useEffect(() => {
    if (values) {
      // console.log(values)
      if (isEdit) {
        setFormData({
          idRequestTime: overtimeValues.idRequestTime,
          idRequestType: overtimeValues.idRequestType,
          startDate: dayjs(overtimeValues.startText, "DD/MM/YYYY HH:mm"),
          startMinute: dayjs(
            overtimeValues.startText,
            "DD/MM/YYYY HH:mm"
          ).format("mm"),
          startHours: dayjs(
            overtimeValues.startText,
            "DD/MM/YYYY HH:mm"
          ).format("HH"),
          endDate: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm"),
          endMinute: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm").format(
            "mm"
          ),
          endHours: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm").format(
            "HH"
          ),
          idManagerLV1: overtimeValues.idManagerLV1,
          otherReason: overtimeValues.otherReason,
          idRequestReason: overtimeValues.idRequestReason,
          isDoubleApproval: overtimeValues.isDoubleApproval,
          approvalLevel: overtimeValues.approvalLevel,
          managerLV1Name: overtimeValues.managerLV1Name,
          isManagerLV1Approve: overtimeValues.isManagerLV1Approve,
          managerLV1ApproveDate: overtimeValues.managerLV1ApproveDate
            ? dayjs(overtimeValues.managerLV1ApproveDate).format(
                "DD/MM/BBBB HH:mm"
              )
            : null,
          positionManagerLV1: overtimeValues.positionManagerLV1,
          managerLV2Name: overtimeValues.managerLV2Name,
          isManagerLV2Approve: overtimeValues.isManagerLV2Approve,
          managerLV2ApproveDate: overtimeValues.managerLV2ApproveDate
            ? dayjs(overtimeValues.managerLV2ApproveDate).format(
                "DD/MM/BBBB HH:mm"
              )
            : null,
          positionManagerLV2: overtimeValues.positionManagerLV2,
          createDateText: overtimeValues.createDateText,
        });
      } else {
        setFormData({
          idRequestType: "",
          startDate: dayjs(values.date, "YYYY-MM-DD"),
          startMinute: "",
          startHours: "",
          endDate: dayjs(values.date, "YYYY-MM-DD"),
          endMinute: "",
          endHours: "",
          idRequestReason: "",
          otherReason: "",
        });
      }
    }
  }, [open, values]);

  const handleSaveRequestTime = async () => {
    // console.log(employee);

    // setIsSubmitting(true);
    if (
      formInputData.idRequestType === "" ||
      formInputData.startDate === null ||
      formInputData.startMinute === "" ||
      formInputData.startHours === "" ||
      formInputData.endDate === null ||
      formInputData.endMinute === "" ||
      formInputData.endHours === "" ||
      formInputData.idRequestReason === ""
    ) {
      // setNotify((prevState) => ({
      //   ...prevState,
      //   isOpen: true,
      //   type: "error",
      //   message: "กรุณากรอกข้อมูลให้ครบ",
      // }));
      dispatch(openNotificationAlert({
        type: "error",
        message: "กรุณากรอกข้อมูลให้ครบ",
      }))
      setIsSubmitting(false);
      return;
    }

    console.log("handleSaveRequestTime");
    const startDate = dayjs(formInputData.startDate)
      .set("hour", formInputData.startHours)
      .set("minute", formInputData.startMinute)
      .set("second", 0);
    const endDate = dayjs(formInputData.endDate)
      .set("hour", formInputData.endHours)
      .set("minute", formInputData.endMinute)
      .set("second", 0);

    if (startDate.isSameOrAfter(endDate)) {
      // setNotify((prevState) => ({
      //   ...prevState,
      //   isOpen: true,
      //   type: "error",
      //   message: "กรอกวันที่ และ เวลา ไม่ถูกต้อง",
      // }));
      dispatch(openNotificationAlert({
        type: "error",
        message: "กรอกวันที่ และ เวลา ไม่ถูกต้อง",
      }))
      setIsSubmitting(false);
      return;
    }

    const formData = {
      workDate: values.date,
      start: startDate.format("YYYY-MM-DD HH:mm:ss"),
      end: endDate.format("YYYY-MM-DD HH:mm:ss"),
      idRequestReason: formInputData.idRequestReason,
      idRequestType: formInputData.idRequestType,
      otherReason: formInputData.otherReason,
      idEmployees: employee.idEmployees,
      isManagerLV1Approve: null,
      isManagerLV2Approve: null,
      isDoubleApproval: 0,
      approvalLevel: 1,
      xOT: 0,
      xOTHoliday: 0,
      xWorkingDailyHoliday: 0,
      xWorkingMonthlyHoliday: 0,
      isActive: 1,
      managerLV1ApproveBy: employee.idManagerLV1,
      managerLV2ApproveBy: employee.idManagerLV2,
      idManagerGroupLV1: employee.idManagerGroupLV1,
      idManagerGroupLV2: employee.idManagerGroupLV2,
      // fillInCreate: userProfile.idEmployees,
    };

    const workStartDate = dayjs(`${values.date} ${values.pattern.timeIn}`);
    const workEndDate = workStartDate.add(
      values.pattern.workingHours,
      "minute",
      true
    );

    if (isEdit) {
      formData.isManagerLV1Approve = overtimeValues.isManagerLV1Approve;
      formData.isManagerLV2Approve = overtimeValues.isManagerLV2Approve;
      formData.isDoubleApproval = overtimeValues.isDoubleApproval;
      formData.approvalLevel = overtimeValues.approvalLevel;
    }

    if (!isEdit) {
      if (mode === "admin") {
        formData.fillInCreate = userProfile.idEmployees;
      }
    }

    if (formData.idRequestType === 2) {
      const overtimeValues = calculatorChoosing(
        dayjs(formData.start),
        dayjs(formData.end)
      );

      formData.xOT = overtimeValues.xOT;
      formData.xOTHoliday = overtimeValues.xOTHoliday;
      formData.xWorkingDailyHoliday = overtimeValues.xWorkingDailyHoliday;
      formData.xWorkingMonthlyHoliday = overtimeValues.xWorkingMonthlyHoliday;
      formData.amountHours =
        formData.xOT +
        formData.xOTHoliday +
        formData.xWorkingDailyHoliday +
        formData.xWorkingMonthlyHoliday;

      if (formData.xOTHoliday > 0) {
        formData.isDoubleApproval = 1;
        formData.approvalLevel = 2;
      } else {
        formData.isDoubleApproval = 0;
        formData.approvalLevel = 1;
      }
    } else {
      formData.amountHours = endDate.diff(startDate, "minute", true);
    }

    if (
      formData.idRequestType === 2 &&
      [10, 11, 12, 13, 15].includes(Number(formData.idRequestReason))
    ) {
      formData.idShiftGroup = values.pattern.idShiftGroup;

      if (Number(formData.idRequestReason) === 15) {
        formData.workEndDate = workEndDate;
      }
    }

    console.log(formData);

    if (!isEdit) {
      const result = await dispatch(addRequestTime(formData, formData.i));

      if (result) {
        if (result.status === 200) {
          if (result.data.errorCode === "ERROR_DUPLICATED") {
            
            // setNotify((prevState) => ({
            //   ...prevState,
            //   isOpen: true,
            //   type: "info",
            //   message: "ข้อมูลซ้ำกับรายการอื่น",
            // }));

            dispatch(openNotificationAlert({
              type: "info",
              message: "ข้อมูลซ้ำกับรายการอื่น",
            }))
          } else {
            // setNotify((prevState) => ({
            //   ...prevState,
            //   isOpen: true,
            //   type: "success",
            //   message: "บันทึกข้อมูลสำเร็จ",
            // }));
            dispatch(openNotificationAlert({
              type: "success",
              message: "บันทึกข้อมูลสำเร็จ",
            }))
            // handleOpenAlert();
            // handleChangeAlertType("success");
            await dispatch(getAttendanceById(searchDate, employee.idEmployees));
            onClose();
          }
        } else {
          // handleOpenAlert();
          // handleChangeAlertType("error");
          // setNotify((prevState) => ({
          //   ...prevState,
          //   isOpen: true,
          //   type: "error",
          //   message: "เกิดข้อผิดพลาด กรุณาติดต่อแอดมิน",
          // }));
          dispatch(openNotificationAlert({
            type: "error",
            message: "เกิดข้อผิดพลาด กรุณาติดต่อแอดมิน",
          }))
          await dispatch(getAttendanceById(searchDate, employee.idEmployees));
          onClose();
        }
      }
    } else {
      const result = await dispatch(
        putRequestTime(formData, formInputData.idRequestTime)
      );

      if (result) {
        if (result.status === 200) {
          if (result.data.errorCode === "ERROR_DUPLICATED") {
            // setNotify((prevState) => ({
            //   ...prevState,
            //   isOpen: true,
            //   type: "info",
            //   message: "ข้อมูลซ้ำกับรายการอื่น",
            // }));
            dispatch(openNotificationAlert({
              type: "info",
              message: "ข้อมูลซ้ำกับรายการอื่น",
            }))
          } else {
            // handleOpenAlert();
            // handleChangeAlertType("success");
            // setNotify((prevState) => ({
            //   ...prevState,
            //   isOpen: true,
            //   type: "success",
            //   message: "บันทึกข้อมูลสำเร็จ",
            // }));
            dispatch(openNotificationAlert({
              type: "success",
              message: "บันทึกข้อมูลสำเร็จ",
            }))
            await dispatch(getAttendanceById(searchDate, employee.idEmployees));
            onClose();
          }
        } else {
          // handleOpenAlert();
          // handleChangeAlertType("error");
          // setNotify((prevState) => ({
          //   ...prevState,
          //   isOpen: true,
          //   type: "error",
          //   message: "เกิดข้อผิดพลาด กรุณาติดต่อแอดมิน",
          // }));
          dispatch(openNotificationAlert({
            type: "error",
            message: "เกิดข้อผิดพลาด กรุณาติดต่อแอดมิน",
          }))
          await dispatch(getAttendanceById(searchDate, employee.idEmployees));
          onClose();
        }
      }
    }

    // const result = await dispatch(addRequestTime(formData));
    // setIsSubmitting(false);
    // if (result) {
    //   if (result.status === 200) {
    //     if (result.data.errorCode === "ERROR_DUPLICATED") {
    //       // setNotify({
    //       //   ...notify,
    //       //   isOpen: true,
    //       //   type: "info",
    //       //   message: "ข้อมูลซ้ำกับรายการอื่น",
    //       // });
    //     } else {
    //       // handleOpenAlert();
    //       // handleChangeAlertType("success");
    //       await dispatch(getAttendanceById(searchDate, employee.idEmployees));
    //       onClose();
    //     }
    //   } else {
    //     // handleOpenAlert();
    //     // handleChangeAlertType("error");
    //     await dispatch(getAttendanceById(searchDate, employee.idEmployees));
    //     onClose();
    //   }
    // }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "idRequestType" && (value === 2 || value === 3)) {
      if (values.pattern.isTimeFrame === 1) {
        if (values.pattern.isWorkingDay === 1) {
          if (values.pattern.isCompensationHoliday || values.holiday) {
            const startDate = dayjs(`${values.date} ${values.pattern.timeIn}`);
            const endDate = startDate.add(
              values.pattern.workingHours,
              "minute",
              true
            );

            let startHours =
              (values.pattern &&
                values.pattern.timeIn &&
                // Number(values.pattern.timeIn.split(":")[0])) ||
                values.pattern.timeIn.split(":")[0]) ||
              "";
            let startMinute =
              (values.pattern &&
                values.pattern.timeIn &&
                // Number(values.pattern.timeIn.split(":")[1])) ||
                values.pattern.timeIn.split(":")[1]) ||
              "";
            let endHours =
              (values.pattern &&
                values.pattern.timeOut &&
                Number(values.pattern.timeOut.split(":")[0])) ||
              "";
            let endMinute =
              (values.pattern &&
                values.pattern.timeOut &&
                // Number(values.pattern.timeOut.split(":")[1])) ||
                values.pattern.timeOut.split(":")[1]) ||
              "";

            setFormData({
              ...formInputData,
              [name]: value,
              startHours: startHours,
              startMinute: startMinute,
              endHours: endHours,
              endMinute: endMinute,
              startDate: startDate,
              endDate: endDate,
            });

            return;
          }

          let startHours =
            (values.pattern &&
              values.pattern.timeOut &&
              // Number(values.pattern.timeOut.split(":")[0])) ||
              values.pattern.timeOut.split(":")[0]) ||
            "";
          let startMinute =
            (values.pattern &&
              values.pattern.timeOut &&
              // Number(values.pattern.timeOut.split(":")[1])) ||
              values.pattern.timeOut.split(":")[1]) ||
            "";
          let endHours =
            (values.attendance &&
              values.attendance.checkOut &&
              values.attendance.checkOut.length > 0 &&
              values.attendance.checkOut[0] &&
              // Number(
              //   values.attendance.checkOut[0].attendanceTextTime.split(":")[0]
              // )) ||
              values.attendance.checkOut[0].attendanceTextTime.split(":")[0]) ||
            "";
          let endMinute =
            (values.attendance &&
              values.attendance.checkOut &&
              values.attendance.checkOut.length > 0 &&
              values.attendance.checkOut[0] &&
              // Number(
              //   values.attendance.checkOut[0].attendanceTextTime.split(":")[1]
              // )) ||
              values.attendance.checkOut[0].attendanceTextTime.split(":")[1]) ||
            "";
          let startDate = dayjs(values.date, "YYYY-MM-DD");
          let endDate = dayjs(values.date, "YYYY-MM-DD");

          if (startHours < Number(values.pattern.timeIn.split(":")[0])) {
            startDate = startDate.add(1, "day");
            endDate = endDate.add(1, "day");
          }

          setFormData({
            ...formInputData,
            [name]: value,
            startHours: startHours,
            startMinute: startMinute,
            endHours: endHours,
            endMinute: endMinute,
            startDate: startDate,
            endDate: endDate,
          });
        } else {
          const startDate = dayjs(`${values.date} ${values.pattern.timeIn}`);
          const endDate = startDate.add(
            values.pattern.workingHours,
            "minute",
            true
          );

          let startHours =
            (values.pattern &&
              values.pattern.timeIn &&
              // Number(values.pattern.timeIn.split(":")[0])) ||
              values.pattern.timeIn.split(":")[0]) ||
            "";
          let startMinute =
            (values.pattern &&
              values.pattern.timeIn &&
              // Number(values.pattern.timeIn.split(":")[1])) ||
              values.pattern.timeIn.split(":")[1]) ||
            "";
          let endHours =
            (values.pattern &&
              values.pattern.timeOut &&
              // Number(values.pattern.timeOut.split(":")[0])) ||
              values.pattern.timeOut.split(":")[0]) ||
            "";
          let endMinute =
            (values.pattern &&
              values.pattern.timeOut &&
              // Number(values.pattern.timeOut.split(":")[1])) ||
              values.pattern.timeOut.split(":")[1]) ||
            "";

          setFormData({
            ...formInputData,
            [name]: value,
            startHours: startHours,
            startMinute: startMinute,
            endHours: endHours,
            endMinute: endMinute,
            startDate: startDate,
            endDate: endDate,
          });
        }
      } else {
        setFormData({
          ...formInputData,
          [name]: value,
        });
      }

      return;
    } else if (name === "idRequestType" && value === 1) {
      setFormData({
        ...formInputData,
        [name]: value,
        startHours: "",
        startMinute: "",
        endHours: "",
        endMinute: "",
        startDate: dayjs(values.date, "YYYY-MM-DD"),
        endDate: dayjs(values.date, "YYYY-MM-DD"),
      });
      return;
    }
    setFormData({
      ...formInputData,
      [name]: value,
    });
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    setFormData({
      ...formInputData,
      [name]: value,
    });
  };

  const renderTitle = () => {
    if (isEdit) {
      if (overtimeValues.idRequestType === 2) {
        return "รายการขอทำงานล่วงเวลา";
      } else {
        return "รายการขอรับรองเวลาทำงาน";
      }
    } else {
      return "เพิ่มรายการ";
    }
  };

  const calculatorChoosing = (start, end) => {
    if (values.pattern.isTimeFrame === 0 && values.pattern.workingHours) {
      return calculatorOvertimeByWorkingHours(start, end, {
        curPattern: values.pattern,
        idPaymentType: values.employee.idPaymentType,
        attendance: values.attendance,
        requestTime: values.requestTime,
        date: values.date,
        ot: values.ot,
      });
    } else {
      return calculatorOvertime(start, end, {
        prePattern: values.previousPattern,
        curPattern: values.pattern,
        nextPattern: values.nextPattern,
        idPaymentType: values.employee.idPaymentType,
        attendances: values.attendance,
        requestTime: values.requestTime,
        isFinger: values.employee.isFinger
      });
    }
  };

  const disabledButtonSubmit = () => {
    let overtimeObj = calculatorChoosing(
      dayjs(formInputData.startDate)
        .set("hour", formInputData.startHours)
        .set("minute", formInputData.startMinute)
        .set("second", 0),
      dayjs(formInputData.endDate)
        .set("hour", formInputData.endHours)
        .set("minute", formInputData.endMinute)
        .set("second", 0)
    );

    // console.log("overtimeObj : ",overtimeObj);

    return overtimeObj.overlapWorking > 0 || !overtimeObj.isInTimeFrame
  };

  return (
    <DrawerCustom
      title={renderTitle()}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        {formInputData && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                วันที่ทำงาน: {dayjs(values.date).format("D/MM/BBBB ")}
              </Typography>
              <Typography>
                เวลาทำงาน:{" "}
                {values.pattern.idWorkingType === 1
                  ? `${values.pattern.shiftGroupName}  (${values.pattern.shiftName})`
                  : `${values.pattern.shiftGroupName}`}
                <Typography
                  component="span"
                  color="text.third"
                  style={{
                    fontStyle: "oblique",
                    fontWeight: "normal",
                  }}
                >
                  {values.pattern.isWorkingDay === 1 &&
                  values.pattern.isTimeFrame === 1
                    ? ` (${values.pattern.timeIn.slice(
                        0,
                        -3
                      )} - ${values.pattern.timeOut.slice(0, -3)})`
                    : ` (${values.pattern.shiftTypeName})`}
                </Typography>
              </Typography>
              {values.holiday && (
                <Typography
                  fontWeight={600}
                  fontSize={12}
                  textAlign="right"
                  style={{ color: "#7451ff" }}
                >
                  วันหยุดประจำปี
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>ประเภท</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idRequestType"
                  value={formInputData.idRequestType}
                  onChange={handleChange}
                  inputProps={{ readOnly: isEdit }}
                >
                  <MenuItem value={1}>ขอรับรองเวลาทำงาน</MenuItem>
                  <MenuItem value={2}>ขอทำงานล่วงเวลา</MenuItem>
                  <MenuItem value={3}>ขอทำชั่วโมง Compensate</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>วันที่เริ่มต้น</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={th}
              >
                <DatePicker
                  minDate={
                    new Date(
                      dayjs(values.date).subtract(1, "day").format("YYYY-MM-DD")
                    )
                  }
                  maxDate={
                    new Date(
                      dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                    )
                  }
                  // readOnly={
                  //   formInputData.idRequestType === 1 || mode === "read"
                  // }
                  value={formInputData.startDate}
                  onChange={(newDate) => {
                    console.log(newDate);
                    setFormData({ ...formInputData, ["startDate"]: newDate });
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme style={{ width: "100%" }} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>เวลาเริ่มต้น</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="startHours"
                    unit="hour"
                    value={formInputData.startHours}
                    // mode={mode}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="startMinute"
                    unit="minute"
                    step="1"
                    value={formInputData.startMinute}
                    // mode={mode}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>วันที่สิ้นสุด</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={th}
              >
                <DatePicker
                  maxDate={
                    new Date(
                      dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                    )
                  }
                  minDate={
                    new Date(
                      dayjs(values.date).subtract(1, "day").format("YYYY-MM-DD")
                    )
                  }
                  // readOnly={mode === "read"}
                  value={formInputData.endDate}
                  onChange={(newDate) => {
                    setFormData({ ...formInputData, ["endDate"]: newDate });
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme style={{ width: "100%" }} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>เวลาสิ้นสุด</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="endHours"
                    unit="hour"
                    value={formInputData.endHours}
                    // mode={mode}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="endMinute"
                    unit="minute"
                    step="1"
                    value={formInputData.endMinute}
                    // mode={mode}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>เหตุผล</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                {reason && (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="idRequestReason"
                    value={formInputData.idRequestReason}
                    onChange={handleChange}
                    // inputProps={{ readOnly: mode === "read" }}
                    MenuProps={MenuProps}
                    disabled={(!formInputData || !formInputData.idRequestType)}
                  >
                    {(formInputData.idRequestType === 2 || userProfile.idCompany === 2) &&
                      <ListSubheader>ไม่มีค่ากะ</ListSubheader>
                    }
                    {reason
                      .filter((x) => {
                        if(userProfile.idCompany === 2){
                          return !x.isPayShift
                        }else {
                          return !x.isPayShift
                          && (
                            formInputData.idRequestType === x.idRequestType
                            || (formInputData.idRequestType === 3 && x.idRequestType === 2)
                          );
                        }
                      })
                      .sort((a, b) => a.index - b.index)
                      .map((item, index) => (
                        <MenuItem key={index} value={item.idRequestReason}>
                          {item.name}
                        </MenuItem>
                      ))
                    }
                    {(formInputData.idRequestType === 2 || userProfile.idCompany === 2) && 
                      <ListSubheader>มีค่ากะ</ListSubheader>
                    }
                    {reason
                      .filter((x) => {
                        if(userProfile.idCompany === 2){
                          return x.isPayShift;
                        }else {
                          return x.isPayShift
                          && (
                            formInputData.idRequestType === x.idRequestType
                            || (formInputData.idRequestType === 3 && x.idRequestType === 2)
                          );
                        }
                      })
                      .sort((a, b) => a.index - b.index)
                      .map((item, index) => (
                        <MenuItem key={index} value={item.idRequestReason}>
                          {item.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>เหตุผลเพิ่มเติม (ถ้ามี)</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <TextField
                  name="otherReason"
                  onChange={handleChange}
                  id="outlined-multiline-static"
                  multiline
                  valuess={3}
                  value={formInputData.otherReason}
                  InputProps={
                    {
                      // readOnly: mode === "read",
                    }
                  }
                />
              </StyledFormControl>
            </Grid>

            {formInputData.idRequestType === 2 && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel>จำนวนชั่วโมง</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {RenderOT(
                    payrollSetting,
                    calculatorChoosing(
                      dayjs(formInputData.startDate)
                        .set("hour", formInputData.startHours)
                        .set("minute", formInputData.startMinute)
                        .set("second", 0),
                      dayjs(formInputData.endDate)
                        .set("hour", formInputData.endHours)
                        .set("minute", formInputData.endMinute)
                        .set("second", 0)
                    )
                  )}
                </Grid>
              </Fragment>
            )}

            {formInputData.idRequestType === 3 && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel>จำนวนชั่วโมง</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {RenderCompensateHour(
                    calculatorChoosing(
                      dayjs(formInputData.startDate)
                        .set("hour", formInputData.startHours)
                        .set("minute", formInputData.startMinute)
                        .set("second", 0),
                      dayjs(formInputData.endDate)
                        .set("hour", formInputData.endHours)
                        .set("minute", formInputData.endMinute)
                        .set("second", 0)
                    )
                  )}
                </Grid>
              </Fragment>
            )}

            {(formInputData.idRequestType === 2 || formInputData.idRequestType === 3) &&
              (values.pattern.isTimeFrame === 0
                ? values.isNotFullDay
                : false) && (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel style={{ color: "#FF0000" }}>
                    *เวลาทำงานไม่ครบ
                  </StyledContentLabel>
                </Grid>
              )}
            
            <Grid item xs={12}>
              <StyledFooter>
                <ButtonBlue
                  className="cancel"
                  color="secondary"
                  variant="text"
                  onClick={onClose}
                >
                  ยกเลิก
                </ButtonBlue>

                <ButtonBlue
                  variant="contained"
                  onClick={handleSaveRequestTime}
                  disabled={isSubmitting ||
                    ((formInputData.idRequestType === 2 || formInputData.idRequestType === 3) &&
                      disabledButtonSubmit()
                    ) ||
                    ((formInputData.idRequestType === 2 || formInputData.idRequestType === 3) &&
                      (values.pattern.isTimeFrame === 0
                        ? values.isNotFullDay
                        : false))
                  }
                >
                  บันทึกข้อมูล
                </ButtonBlue>
              </StyledFooter>
            </Grid>
          </Grid>
        )}
      </StyledRoot>
    </DrawerCustom>
  );
};

const RenderOT = (payrollSetting, overtimeObj) => {
  const {
    xOT,
    xOTHoliday,
    xWorkingDailyHoliday,
    xWorkingMonthlyHoliday,
    overlapWorking,
    isInTimeFrame
  } = overtimeObj;

  let otList = [];

  if (xWorkingDailyHoliday > 0) {
    otList.push({
      x: payrollSetting.xWorkingDailyHoliday,
      hours: (xWorkingDailyHoliday / 60).toFixed(2),
    });
  }
  if (xWorkingMonthlyHoliday > 0) {
    otList.push({
      x: payrollSetting.xWorkingMonthlyHoliday,
      hours: (xWorkingMonthlyHoliday / 60).toFixed(2),
    });
  }
  if (xOT > 0) {
    otList.push({
      x: payrollSetting.xOT,
      hours: (xOT / 60).toFixed(2),
    });
  }
  if (xOTHoliday > 0) {
    otList.push({
      x: payrollSetting.xOTHoliday,
      hours: (xOTHoliday / 60).toFixed(2),
    });
  }

  // console.log(overtimeObj);

  return (
    <Fragment>
      {overlapWorking > 0 ? (
        <Typography style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}>
          ไม่สามารถขอทำงานล่วงเวลาในเวลาทำงานปกติได้
        </Typography>
      ) : (
        <Fragment>
          {!isInTimeFrame && 
            <Typography style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}>
              ไม่สามารถขอทำงานล่วงเวลาเกินกรอบที่ท่านได้บันทึกเวลาทำงานไว้ได้
            </Typography>
          }
          {isInTimeFrame && otList.map((item, index) => (
            <Box
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                fontWeight={500}
                variant="subtitle1"
                color="text.third"
              >
                OT x {item.x}
              </Typography>
              <Typography variant="h6">{item.hours} ชม.</Typography>
            </Box>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

const RenderCompensateHour = (overtimeObj) => {
  const {
    xOT,
    xOTHoliday,
    xWorkingDailyHoliday,
    xWorkingMonthlyHoliday,
    overlapWorking,
    isInTimeFrame
  } = overtimeObj;

  let otList = 0;

  if (xWorkingDailyHoliday > 0) {
    otList += xWorkingDailyHoliday
  }
  if (xWorkingMonthlyHoliday > 0) {
    otList += (xWorkingMonthlyHoliday / 60)
  }
  if (xOT > 0) {
    otList += (xOT / 60)
  }
  if (xOTHoliday > 0) {
    otList += (xOTHoliday / 60)
  }

  return (
    <Fragment>
      {overlapWorking > 0 ? (
        <Typography style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}>
          ไม่สามารถขอทำชั่วโมง Compensate ในเวลาทำงานปกติได้
        </Typography>
      ) : (
        <Fragment>
          {!isInTimeFrame && 
            <Typography style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}>
              ไม่สามารถขอทำชั่วโมง Compensate เกินกรอบที่ท่านได้บันทึกเวลาทำงานไว้ได้
            </Typography>
          }
          {isInTimeFrame && 
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                fontWeight={500}
                variant="subtitle1"
                color="text.third"
              >
                Compensate
              </Typography>
              <Typography variant="h6">{Math.round(otList * 100)/100} ชม.</Typography>
            </Box>
          }
        </Fragment>
      )}
    </Fragment>
  );
}

export default DrawerRequestTime;
