import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
    TextField,
    FormControl,
    Grid,
    Box,
    Typography,
    Popper
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { getTypeOFCostElement, postCostElement, putCostElement } from "../../../../../actions/costElement";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});
const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});
const StyledBoxSearch = styled(Box)({
    marginTop: 4,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});
const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});
const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

function Setting(props) {
    const dispatch = useDispatch();
    const { result: costElementList } = useSelector((state) => state.costElementList);
    const { open, typeDialog, handleClose, data } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        dispatch(getTypeOFCostElement());
        if (typeDialog === "Add")
            setFormData({
                costElementName: null,
                idPayrollType: null,
                idAddition: null,
                isActive: 1,
            })
        if (typeDialog === "Edit")
            setFormData({
                costElementName: data.costElementName,
                isActive: data.isActive,
                idPayrollType: data.idPayrollType,
                idAddition: data.idAddition
            })
    }, [open]);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const handleSubmit = async () => {
        if (!formData.costElementName) {
            handleOpenAlert(true);
            handleChangeAlertType("กรุณากรอก cost element");
        } else if (!(formData.idPayrollType || formData.idAddition) && typeDialog === "Add") {
            handleOpenAlert(true);
            handleChangeAlertType("กรุณาเลือกรายการ");
        } else {
            if (typeDialog === "Add") {
                const result = await dispatch(postCostElement(formData));
                if (result.status === 200) {
                    handleClose();
                } else {
                    handleOpenAlert(true);
                    handleChangeAlertType("ไม่สามารถเพิ่มรายการซ้ำได้");
                };
            } else {
                const result = await dispatch(putCostElement(data.idCostElement, formData));
                if (result.status === 200) {
                    handleClose();
                } else {
                    handleOpenAlert(true);
                    handleChangeAlertType("ไม่สามารถทำรายการซ้ำได้");
                };
            }
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const RenderTitle = () => {
        if (typeDialog === "Edit") return `แก้ไข ${data.name}` 
        else return "เพิ่ม cost Element"
    }

    return (
        <DrawerCustom
            title={RenderTitle()}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot>
                {/* {JSON.stringify(data)}
                {JSON.stringify(formData)} */}
                {formData &&
                    <Grid container spacing={2} style={{ marginBottom: 16, width: 300 }}>
                        <Grid item xs={12} >
                            <StyledBoxSearch>
                                {typeDialog == "Add" && <Typography className="label" color="text.third">
                                    รายการ
                                </Typography>}
                                {typeDialog == "Add" &&<StyledAutocomplete
                                    options={costElementList ? costElementList : []}
                                    onChange={(event, newValue) => {
                                        if (newValue.idPayrollType) {
                                            setFormData({ ...formData, ["idPayrollType"]: newValue.idPayrollType, ["idAddition"]: null });
                                        } else setFormData({ ...formData, ["idAddition"]: newValue.idAddition, ["idPayrollType"]: null });
                                    }}
                                    closeIcon={null}
                                    popupIcon={<i class="fa-light fa-chevron-down"></i>}
                                    getOptionLabel={(option) => `${option.name}`}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            placeholder="เลือกรายการ"
                                            InputProps={{ ...params.InputProps, endAdornment: null }}
                                        />
                                    )}
                                    PopperComponent={StyledPopper}
                                    noOptionsText={"ไม่พบข้อมูล"}
                                    style={{ marginBottom: 16 }}
                                />}
                                <Grid item xs={12} sm={6} className="GridTopicInput">cost element</Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        name="costElementName"
                                        value={formData.costElementName}
                                        onChange={handleChange}
                                        margin="dense"
                                        style={{ marginBottom: 16, width: '28ch'}}
                                    />
                                </Grid>
                                {typeDialog == "Edit" && <Grid item xs={12} sm={6} className="GridTopicInput">ใช้งาน</Grid>}
                                {typeDialog == "Edit" && <Grid item xs={12} sm={6}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={formData.isActive}
                                            onChange={(event) => {
                                                setFormData({
                                                    ...formData, ["isActive"]: eval(event.target.value)
                                                })
                                            }}
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label="ใช้" />
                                            <FormControlLabel value="0" control={<Radio />} label="ไม่ใช้" />

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>}

                            </StyledBoxSearch>
                        </Grid>
                    </Grid>
                }
                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>ยกเลิก</ButtonBlue>
                    <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
                        บันทึกข้อมูล
                    </ButtonBlue>
                </StyledFooter>
            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>

    );
}

export default Setting;
