import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const useStyles = makeStyles(() => ({
  dialog: {
    padding: 8,
    position: "absolute",
    top: 40,
    width: "95%",
    maxHeight: 800,
    "& .MuiGrid-container": {
      marginBottom: 16,
    },
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
    "& .MuiInputLabel-root": {
      color: "#9e9e9e",
    },
  },
  dialogTitle: {
    color: "#16243D",
    padding: 8,
  },
  dialogContent: {
    padding: "16px 8px 24px 8px",
  },
  dialogActions: {
    marginTop: 8,
  },
  placeholder: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
}));

const initialValues = () => ({
  demandId: 0,
  demandName: "",
  demandType: "",
  demandReason: "",
  demandValue: "",
  demandDirectReturn: "",
  demandReturn: "",
  demandImport: "",
  createBy: "Admin",
  createDate: new Date(),
});

function AddDemand(props) {
  const { addDemand, setAddDemand } = props;
  const classes = useStyles();
  const [values, setValues] = useState(initialValues);
  const [picture, setPicture] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFile) => {
      let formData = new FormData();
      acceptedFile.map((file) => formData.append("file", file));
      setPicture(
        acceptedFile.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = picture.map((file) => (
    <img key={file.name} src={file.preview} className={classes.uploadImage} />
  ));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log("Data Submit", values);
  };

  useEffect(() => {
    if (addDemand.isOpen) {
      setValues(initialValues);
      setPicture([]);
    }
  }, [addDemand]);

  return (
    <Dialog
      maxWidth="md"
      open={addDemand.isOpen}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              Add Job Family
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={() => setAddDemand({ ...addDemand, isOpen: false })}
            >
              <CloseIcon className={classes.closeBtn} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Demand Name"
                    name="demandName"
                    value={values.demandName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                      label="Type"
                      value={values.demandType}
                      onChange={handleInputChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel>Value</InputLabel>
                    <Select
                      label="Value"
                      value={values.demandValue}
                      onChange={handleInputChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Demand Reason"
                    name="demandReason"
                    value={values.demandReason}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel>Select Direct Return</InputLabel>
                    <Select
                      label="Select Direct Return"
                      value={values.demandDirectReturn}
                      onChange={handleInputChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    label="Demand Return"
                    name="demandReturn"
                    type="number"
                    value={values.demandReturn}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Import to business"
                    name="demandImport"
                    value={values.demandImport}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <div
                {...getRootProps({ className: "dropzone" })}
                style={{ borderRadius: "10px" }}
              >
                <div className="inner-dropzone">
                  <input {...getInputProps()} />
                  <Fragment>{thumbs}</Fragment>
                  <div
                    className={`placeholder ${classes.placeholder} ${
                      picture.length != 0 && classes.placeholderImageProfile
                    }`}
                  >
                    <InsertDriveFileIcon />
                    <Typography
                      style={{
                        marginTop: 8,
                        backgroundColor: "transparent",
                      }}
                      className={`${
                        picture.length != 0 && classes.placeholderLabel
                      }`}
                      variant="body2"
                    >
                      Upload File
                    </Typography>
                  </div>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider sx={{ marginBottom: 2 }} />
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<SaveIcon />}
              onClick={handleOnSubmit}
            >
              Submit
            </ButtonBlue>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AddDemand;
