import React from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import {
    Grid,
    Typography,
    Divider,
    Avatar,
    Chip,
    Drawer
} from "@mui/material";
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const StyledBox = styled("div")({
    display: "flex",
    marginTop: "8px",
    marginLeft: "16px",
    alignItems: "center"
});
const StyledManager = styled("div")({
    display: "flex",
    marginTop: "8px",
    marginLeft: "26px",
    alignItems: "center"
});
const DivTopic = styled("div")({
    marginLeft: "26px",
    marginTop: "8px",
    marginRight: "12px"
});

const StyledTopic = styled("Typography")({
    fontSize: "18px",
    fontWeight: "600",
    marginRight: "12px"
});

const StyledText = styled("Typography")({
    fontSize: "16px",
});

const Root = styled("div")(({ theme }) => ({
    width: 400,
    marginBottom: "26px",
    "& .InterestChip": {
        backgroundColor: "#fff3e0",
        color: "#ff9800",
        fontWeight: 600,
        fontSize: "18px !important",
        padding: "5px 5px"
    },
    "& .Svg": {
        width: "30px",
        height: "30px",
        marginRight: "28px",
        marginLeft: "12px"
    },
    "& .Img": {
        width: "50px",
        height: "50px",
        marginRight: "20px",
    },
    "& .ImgTop": {
        width: "50px",
        height: "50px",
        marginRight: "18px",
        marginLeft: "2px",
    }
}));

const Profile = (props) => {

    const { open, handleClose, } = props;
    const { result: employee } = useSelector((state) => state.employeeProfile);

    return (
        <Drawer
            anchor="right"
            open={employee ? open : false}
            onClose={handleClose}
        >
            <div style={{ padding: 16, backgroundColor: "#3157B3", color: "#ffffff" }}>
                <StyledTopic>{employee ? employee.positionName : ""}</StyledTopic>
            </div>

            {employee && <Root >
                <StyledBox>
                    <Avatar className="ImgTop"
                        src={employee.imageProfile && employee.imageProfile ? employee.imageProfile : null} />
                    <div>
                        <Typography style={{ fontWeight: "700" }} >{employee.firstname_TH} {employee.lastname_TH}</Typography>
                        <Typography>{employee.positionName} </Typography>
                    </div>
                </StyledBox>
                <StyledBox>
                    <MailOutlineOutlinedIcon className="Svg" />
                    <StyledText >{employee.email} </StyledText>
                </StyledBox>
                <StyledBox>
                    <LocalPhoneOutlinedIcon className="Svg" />
                    <StyledText>{employee.telephoneMobile ? employee.telephoneMobile : "-"} </StyledText>
                </StyledBox>
                <StyledBox>
                    <SchoolOutlinedIcon className="Svg" />
                    <StyledText>{employee.educations && employee.educations.length > 0 ?
                        `${employee.educations[employee.educations.length - 1].degree ? employee.educations[employee.educations.length - 1].degree : ""} 
                        ${employee.educations[employee.educations.length - 1].degree &&
                            (employee.educations[employee.educations.length - 1].university || employee.educations[employee.educations.length - 1].endYear) ?
                            "," : ""} 
                        ${employee.educations[employee.educations.length - 1].university} 
                        ${(employee.educations[employee.educations.length - 1].university && employee.educations[employee.educations.length - 1].endYear) ?
                            "," : ""} 
                        ${employee.educations[employee.educations.length - 1].endYear}` : "-"}
                    </StyledText>
                </StyledBox>
                <StyledBox>
                    <CakeOutlinedIcon className="Svg" />
                    <StyledText >{dayjs(employee.birthday).format("DD MMMM YYYY")} </StyledText>
                </StyledBox>
                <StyledBox>
                    <HomeOutlinedIcon className="Svg" />
                    <StyledText >{employee.provience ? employee.provience : "-"} </StyledText>
                </StyledBox>

                <div style={{ marginTop: "10px" }}>
                    <Divider />
                </div>

                <DivTopic>
                    <StyledTopic >About </StyledTopic>
                    <StyledText >{employee.overview ? "" : "-"} </StyledText>
                </DivTopic>
                {employee.overview && <DivTopic>
                    <StyledText >{employee.overview ? employee.overview : ""} </StyledText>
                </DivTopic>}

                <StyledBox style={{ marginLeft: "26px" }}>
                    <StyledTopic >Interests </StyledTopic>
                    <Grid container spacing={1}>
                        {employee.interests && employee.interests.length > 0 ? employee.interests.map((item) => (
                            <Grid item key={item.idInterest}>
                                <Chip
                                    className="InterestChip"
                                    label={item.Interest}
                                />
                            </Grid>
                        )) : (
                            <Grid item>
                                <StyledText>-</StyledText>
                            </Grid>
                        )}
                    </Grid>
                </StyledBox>

                <div style={{ marginTop: "10px" }}>
                    <Divider />
                </div>

                <DivTopic >
                    <StyledTopic >Manager </StyledTopic>{
                        employee.managerLV1_firstname_TH || employee.managerLV2_firstname_TH ?
                            "" : <StyledText>-</StyledText>}
                </DivTopic>

                {employee.managerLV1_firstname_TH && <StyledManager>
                    <Avatar className="Img"
                        src={employee.managerLV1_imageProfile && employee.managerLV1_imageProfile ? employee.managerLV1_imageProfile : null} />
                    <div>
                        <Typography>{employee.managerLV1_firstname_TH} {employee.managerLV1_lastname_TH}</Typography>
                        <Typography>{employee.managerLV1_positionName} </Typography>
                    </div>
                </StyledManager>}
                {employee.managerLV2_firstname_TH && <StyledManager>
                    <Avatar className="Img"
                        src={employee.managerLV2_imageProfile && employee.managerLV2_imageProfile ? employee.managerLV2_imageProfile : null} />
                    <div>
                        <Typography>{employee.managerLV2_firstname_TH} {employee.managerLV2_lastname_TH}</Typography>
                        <Typography>{employee.managerLV2_positionName} </Typography>
                    </div>
                </StyledManager>}

                <div style={{ marginTop: "10px" }}>
                    <Divider />
                </div>

                <DivTopic >
                    <StyledTopic >Team </StyledTopic>{
                        (employee.list && employee.list.length > 0) ?
                            <StyledText >{`${employee.list.length} คน`} </StyledText>
                            : <StyledText>-</StyledText>}
                </DivTopic>

                {employee.list && employee.list.length > 0 &&
                    employee.list.map((item) => {
                        return <StyledManager>
                            <Avatar className="Img"
                                src={item.imageProfile && item.imageProfile ? item.imageProfile : null} />
                            <div>
                                <Typography>{item.firstname_TH} {item.lastname_TH}</Typography>
                                <Typography>{item.positionName} </Typography>
                            </div>
                        </StyledManager>
                    })
                }

            </Root>}
        </Drawer>
    );
}

export default Profile;
