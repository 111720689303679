import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";
import {
    Typography,
    Paper,
    Avatar,
    Card,
    Container,
    Grid,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from "@mui/icons-material/Edit";

import { clearEmployeeNetwork, getAllEmployees, getEmployeeNetwork } from "../../../../actions/employee";
import ButtonBlue from "../../shared/general/ButtonBlue";

const DivContent = styled("div")({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gap: "20px 20px",
});

const EmployeeDiv = styled("div")({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "&:hover": {
        cursor: "pointer",
        transition:
            "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
        transform: "translateY(-5px)",
    },
});

const CardEmployee = styled(Card)({
    width: "200px",
    height: "200px",
    display: "flex",
    borderRadius: "16px",
    boxShadow: "none",
});

const NameText = styled(Typography)({
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "10px",
    display: "flex",
    alignItems: "center"
});

const PositionText = styled(Typography)({
    fontSize: "12px",
    marginTop: "10px",
    display: "flex",
    alignItems: "center"
});

const EmployeeNetwork = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [textSearch, setTextSearch] = useState("");
    const [data, setData] = useState()
    const { result: employees } = useSelector((state) => state.employees);
    const { result: user } = useSelector((state) => state.userProfile);

    useEffect(() => {
        dispatch(getAllEmployees());
        dispatch(clearEmployeeNetwork()); 
    }, []);

    useEffect(() => {
        if (textSearch) setData(employees.filter((item) => {
            return item.name.includes(textSearch)
        }))
        else setData(employees)
    }, [textSearch, employees]);

    const handleClickCard = (id) => {
        dispatch(getEmployeeNetwork(id))
        history.push({
            pathname: "/employee_profile"
        });
    };

    const handleEdit = () => {
        dispatch(getEmployeeNetwork(user.idEmployees))
        history.push({
            pathname: "/edit/employee_profile"
        });
    };

    const handleSearch = (event) => {
        const { value } = event.target;
        setTextSearch(value)
    };

    return (
        <div className="page">
            <Container maxWidth="lg">
                <div style={{ padding: "20px" }}>
                    <Typography variant="h4" style={{ marginBottom: "20px" }}>
                        Employee Network
                    </Typography>
                    {employees && (
                        <Paper sx={{ p: "30px", borderRadius: "16px", minHeight: "calc(100vh - 187px)", }}>
                            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "20px", }}>
                                <div style={{ display: "flex"}}
                                ><Typography style={{ display: "flex", alignItems: "center", paddingRight: "20px" }}>ค้นหาพนักงาน</Typography>
                                <Grid >
                                    <FormControl sx={{ m: 1, width: "300px" }} variant="outlined">
                                    <InputLabel>ชื่อ-สกุล</InputLabel>
                                        <OutlinedInput
                                            name="textSearch"
                                            value={textSearch}
                                            onChange={handleSearch}
                                            label="search"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                </div>
                                <ButtonBlue 
                                onClick={handleEdit}
                                startIcon={<EditIcon />}
                                >แก้ไขข้อมูลของฉัน</ButtonBlue>
                            </div>
                            {data && data.length > 0 ? (
                                <DivContent>
                                    {data.map((user) => (
                                        <div
                                            style={{ display: "flex", justifyContent: "center" }}
                                            key={user.idEmployees}
                                        >
                                            <EmployeeDiv onClick={() => handleClickCard(user.idEmployees)}>
                                                <CardEmployee>
                                                    <Avatar
                                                        src={user.imageProfile}
                                                        style={{ height: "100%", width: "100%", }}
                                                        variant="circle"
                                                    />
                                                </CardEmployee>
                                                <NameText>
                                                    {`${user.name}`}
                                                </NameText>
                                                <PositionText>
                                                    {user.positionName}
                                                </PositionText>
                                            </EmployeeDiv>
                                        </div>
                                    ))}
                                </DivContent>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                    }}
                                >
                                    <h3>ไม่พบพนักงาน</h3>
                                </div>
                            )}
                        </Paper>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default EmployeeNetwork;
