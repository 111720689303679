import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography, styled, Accordion, AccordionSummary } from "@mui/material";
import { Add as AddIcon, ArrowForwardIosSharp } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { allLeaveType, deleteLeaveGroup, getAllLeaveV2 } from "../../../../../actions/leave";
import AccordionRow from "./accordionRow";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import DrawerLeave from "./components/drawerLeave";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";
import Notification from "../../../shared/general/Notification";
import LeaveForm from "./leaveForm";
import { getEmploymentTypeByidCompany } from "../../../../../actions/employment-type";
import { getLevelByidCompany } from "../../../../../actions/level";

const StyledRoot = styled(Box)({
  marginTop: "30px",
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
})

const LeaveSettingPage = () => {

  const dispatch = useDispatch();

  const { result: leaveTypeList } = useSelector((state) => state.leaveType);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  })

  const [deleteConfig, setDeleteConfig] = useState({
    isOpen: false,
    data: {}
  })

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  })

  useEffect(() => {
    dispatch(getAllLeaveV2());
    dispatch(getEmploymentTypeByidCompany());
    dispatch(getLevelByidCompany())
  }, []);

  const getLeaveType = () => {
    if(leaveTypeList){
      return leaveTypeList;
    } else {
      return [];
    }
  }

  const handleOpenDrawer = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: {},
    }))
  }

  const handleCloseDrawer = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleDeleteLeaveType = async () => {

    const response = await dispatch(deleteLeaveGroup(deleteConfig.data.idLeaveGroup))

    if(response){
      if(response.status === 200){
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: "ลบข้อมูลสำเร็จ"
        }))
        dispatch(getAllLeaveV2())
        handleCloseDeleteModal();
      } else {
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: "ไม่สามารถลบประเภทลานี้ได้ กรุณาติดต่อแอดมิน"
        }))
      }
    } else {
      setAlertConfig(prev => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      }))
    }

    setDeleteConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  return (
    <StyledRoot>
      
      {!drawerConfig.isOpen && <Fragment>
        <Box marginBottom="16px" display="flex" justifyContent="space-between">
          <Typography fontSize="24px" fontWeight="500">วันลา</Typography>
          <ButtonBlue
            variant={"contained"}
            startIcon={<AddIcon />}
            // onClick={() => {
            //   setOpenDialog({
            //     ...openDialog,
            //     isOpen: true,
            //   });
            // }}
            onClick={handleOpenDrawer}
          >
            เพิ่มประเภทวันลา
          </ButtonBlue>
        </Box>

        {getLeaveType().map((item, index) => (
          <AccordionRow 
            data={item} 
            key={`accordion_${index}`} 
            setDrawerConfig={setDrawerConfig}
            setDeleteConfig={setDeleteConfig}
          />
        ))}
      </Fragment>}

      {drawerConfig.isOpen && (
        <LeaveForm
          drawerConfig={drawerConfig}
          onClose={handleCloseDrawer}
          setAlertType={setAlertType}
          setOpenAlert={setOpenAlert}
        />
      )}

      <Notification
        notify={alertConfig}
        setNotify={setAlertConfig}
      />

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {/* <DrawerLeave
        drawerConfig={drawerConfig}
        onClose={handleCloseDrawer}
        setAlertType={setAlertType}
        setOpenAlert={setOpenAlert}
      /> */}
      <DialogConfirmDelete
        open={deleteConfig.isOpen}
        handleClose={handleCloseDeleteModal}
        label={"ประเภทการลา"}
        text={deleteConfig.data.leaveGroupName}
        handleDelete={handleDeleteLeaveType}
      />
    </StyledRoot>
  )
}

export default LeaveSettingPage;