import { httpClient } from "./httpClient";

const getAnnouncementByIdCompany = () => {
  return httpClient.get(`announcement-company`);
};

const getAnnouncementActiveByIdCompany = () => {
  return httpClient.get(`announcement-company-active`);
};

const addAnnouncement = (formData) => {
  return httpClient.post("announcement", formData);
};

const updateAnnouncement = (formData) => {
  return httpClient.put("announcement", formData);
};

const deleteAnnouncement = (id) => {
  return httpClient.delete(`announcement/${id}`);
};

export default {
  getAnnouncementByIdCompany,
  getAnnouncementActiveByIdCompany,
  addAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
};
