import React, { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';

import {
    Avatar,
    Autocomplete,
    Box,
    Dialog,
    DialogContent,
    styled,
    Typography
} from '@mui/material';

import ButtonBlue from '../../../../shared/general/ButtonBlue';
import TextFieldTheme from '../../../../shared/general/TextFieldTheme';
import { stringToColor } from '../../../../../../utils';

const BoxContent = styled(Box)({
    width:"450px",
    "& .Space-Bottom":{
        marginBottom:"10px"
    },
    "& .Space-Box-List":{
        maxHeight:"400px",
        margin:"20px 0",
        padding:"0 10px",
        overflowY:"auto",
        padding:"0 20px"
    },
    "& .BoxManpower":{
        display:"flex", 
    },
    "& .SpaceManpowerBottom":{
        marginBottom:"20px"
    },
    '& .style-autocomplete .MuiInputBase-root':{
        lineHeight:"0.875",
        padding:"0"
    }
});

const DialogConfirmSendingBidding = (props) => {
    const { open, onClose, listSelected, listPositions, handleOnSubmit } = props;
    const { control, handleSubmit, getValues } = useForm({
      defaultValues:{
        listManpowers: listSelected
      }
    });
    const [page, setPage] = useState(1);

    const handleClickNextPage = () => setPage(2);
    const handleClickBackPage = () => setPage(1);

    const handleSubmitForm = (data) => {
        handleOnSubmit(data);
    }

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <BoxContent>
            <Typography
              variant="h5"
              className="Space-Bottom"
              style={{ fontweight: "bold" }}
            >
              ยืนยันการสรุปส่งกำลังพล
            </Typography>
            <Typography
              variant="body1"
              className="Space-Bottom"
              style={{ paddingLeft:"20px" }}
            >
              {page === 1 ? "เลือกตำแหน่งงานแต่ละบุคคลที่ส่ง" : "ยืนยันการเลือกตำแหน่งงานแต่ละคนที่ส่ง"}
            </Typography>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              {page === 1 && (
                <Fragment>
                  <Box className="Space-Box-List">
                    {listSelected.map((manpower, index) => {
                      return (
                        <Box 
                            key={`${manpower.idManpower}`} 
                            className={clsx("BoxManpower",{ SpaceManpowerBottom : listSelected.length !== index+1})}
                        >
                          <Box style={{ width: "80px", height: "80px" }}>
                            <Avatar sx={{ bgcolor: stringToColor(`${manpower.name} ${manpower.lastname}`) }} children={`${manpower.name[0]}${manpower.lastname[0]}`} />
                          </Box>
                          <Box style={{ width:"calc(100% - 90px)", paddingLeft: "30px" }}>
                            <Typography style={{ fontSize: "1.1rem", marginBottom:"5px" }}>
                              {`${manpower.name} ${manpower.lastname}`}
                            </Typography>
                            <Box style={{ width:"100%" }}>
                              <Controller
                                control={control}
                                name={`listManpowers[${index}].position`}
                                defaultValue={null}
                                render={({ field }) => (
                                  <Autocomplete
                                    getOptionLabel={(option) => option.positionName}
                                    isOptionEqualToValue={(option, value) => option.idBiddingPositions === value.idBiddingPositions}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props} key={option.idBiddingPositions}>
                                          {option.positionName}
                                        </li>
                                    )}
                                    options={listPositions}
                                    value={field.value}
                                    onChange={(_, data) => {
                                        field.onChange(data);
                                    }}
                                    renderInput={(params) => (
                                      <TextFieldTheme {...params} className="style-autocomplete"/>
                                    )}
                                  />
                                )}
                              />
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box style={{ display:"flex", justifyContent: "flex-end"}}>
                    <ButtonBlue variant="contained" onClick={handleClickNextPage}>
                        สรุป
                    </ButtonBlue>
                  </Box>
                </Fragment>
              )}
              {page === 2 && (
                <Fragment>
                  <Box className="Space-Box-List">
                    {listSelected.map((manpower, index) => {
                      return (
                        <Box 
                            key={`${manpower.idManpower}`} 
                            className={clsx("BoxManpower",{ SpaceManpowerBottom : listSelected.length !== index+1})}
                        >
                          <Box style={{ width: "60px", height: "60px" }}>
                            <Avatar sx={{ bgcolor: stringToColor(`${manpower.name} ${manpower.lastname}`) }} children={`${manpower.name[0]}${manpower.lastname[0]}`} />
                          </Box>
                          <Box style={{ width:"calc(100% - 90px)", paddingLeft: "30px" }}>
                            <Typography style={{ fontSize: "1.1rem", marginBottom:"5px" }}>
                              {`${manpower.name} ${manpower.lastname}`}
                            </Typography>
                            <Box style={{ width:"100%" }}>
                                <Typography style={{ fontSize: "1.1rem" }}>
                                    {getValues(`listManpowers[${index}].position.positionName`)}
                                </Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box style={{ display:"flex", justifyContent:"flex-end" }}>
                    <ButtonBlue style={{ marginRight:"10px" }}onClick={handleClickBackPage}>
                      ย้อนกลับ
                    </ButtonBlue>
                    <ButtonBlue variant="contained" type="submit">
                      ยืนยัน
                    </ButtonBlue>
                  </Box>
                </Fragment>
              )}
            </form>
          </BoxContent>
        </DialogContent>
      </Dialog>
    );
};

export default DialogConfirmSendingBidding;