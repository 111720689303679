import React from "react";
import { Controller } from 'react-hook-form';
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";

import TextFieldNumber from "./TextFieldNumber";
import TextFieldPersonalID from './TextFieldPersonalID';

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-body1": {
      color: "#16243D",
    },
    "& .MuiTypography-body2": {
      fontSize: "1rem",
      marginLeft: 32,
    },
    "& .MuiGrid-root": {
      marginBottom: 8,
    },
  },
  inputAdornment: {
    "& .MuiTypography-body1": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
  checkBox: {
    marginLeft: 32,
  },
}));

function Form2(props) {
  const { control, setValue, watch, errors, clearErrors, isDisabled } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">5) เงินบริจาค</Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ไม่เกิน 10% ของเงินได้หลังจากหักค่าใช้จ่ายและค่าลดหย่อนอย่างอื่น</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="etcDonation"
            min={0}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">6) เงินบริจาคเพื่อการศึกษา</Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ไม่เกิน 10% ของเงินได้หลังจากหักค่าใช้จ่ายและค่าลดหย่อนอย่างอื่น</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="eduDonation"
            min={0}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">
            7) กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)
          </Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้ 30% ของเงินได้ที่ต้องเสียภาษี สูงสุดไม่เกิน 500,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="rmfFund"
            min={0}
            max={500000}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">
            8) กองทุนรวมเพื่อส่งเสริมการออมระยะยาว (SSF)
          </Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้ 30% ของเงินได้ที่ต้องเสียภาษี สูงสุดไม่เกิน 200,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="ssfFund"
            min={0}
            max={200000}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">
            9) ลดหย่อนค่าอุปการะเลี้ยงดูบิดามารดา
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="isChildSupport_empFather"
                    control={control}
                    render={({ field }) => (
                      <Checkbox 
                        disabled={isDisabled}
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          if(!e.target.checked){
                            setValue("childSupport_empFather", "", { shouldDirty: true });
                          }
                          if(errors && errors.childSupport_empFather){
                            clearErrors("childSupport_empFather");
                          }
                        }}
                      />
                    )}
                  />
                }
                label="บิดาผู้มีเงินได้"
              />
            </FormGroup>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldPersonalID
            name="childSupport_empFather"
            control={control}
            checked={watch("isChildSupport_empFather")}
            required={watch("isChildSupport_empFather")}
            error={errors && errors.childSupport_empFather ? true : false}
            helperText={errors && errors.childSupport_empFather && errors.childSupport_empFather.message}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="isChildSupport_empMother"
                    control={control}
                    render={({ field }) => (
                      <Checkbox 
                        disabled={isDisabled}
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                          if(!e.target.checked){
                            setValue("childSupport_empMother", "", { shouldDirty: true });
                          }
                          if(errors && errors.childSupport_empMother){
                            clearErrors("childSupport_empMother");
                          }
                        }}
                      />
                    )}
                  />
                }
                label="มารดาผู้มีเงินได้"
              />
            </FormGroup>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldPersonalID
            name="childSupport_empMother"
            control={control}
            checked={watch("isChildSupport_empMother")}
            required={watch("isChildSupport_empMother")}
            error={errors && errors.childSupport_empMother ? true : false}
            helperText={errors && errors.childSupport_empMother && errors.childSupport_empMother.message}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="isChildSupport_mateFather"
                    control={control}
                    render={({ field }) => (
                      <Checkbox 
                        disabled={isDisabled}
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                          if(!e.target.checked){
                            console.log("HELLO")
                            setValue("childSupport_mateFather", "", { shouldDirty: true });
                          }
                          if(errors && errors.childSupport_mateFather){
                            clearErrors("childSupport_mateFather");
                          }
                        }}
                      />
                    )}
                  />
                }
                label="บิดาคู่สมรส"
              />
            </FormGroup>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldPersonalID
            name="childSupport_mateFather"
            control={control}
            checked={watch("isChildSupport_mateFather")}
            required={watch("isChildSupport_mateFather")}
            error={errors && errors.childSupport_mateFather ? true : false}
            helperText={errors && errors.childSupport_mateFather && errors.childSupport_mateFather.message}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="isChildSupport_mateMother"
                    control={control}
                    render={({ field }) => (
                      <Checkbox 
                        disabled={isDisabled}
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                          if(!e.target.checked){
                            setValue("childSupport_mateMother", "", { shouldDirty: true });
                          }
                          if(errors && errors.childSupport_mateMother){
                            clearErrors("childSupport_mateMother");
                          }
                        }}
                      />
                    )}
                  />
                }
                label="มารดาคู่สมรส"
              />
            </FormGroup>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldPersonalID
            name="childSupport_mateMother"
            control={control}
            checked={watch("isChildSupport_mateMother")}
            required={watch("isChildSupport_mateMother")}
            error={errors && errors.childSupport_mateMother ? true : false}
            helperText={errors && errors.childSupport_mateMother && errors.childSupport_mateMother.message}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Form2;
