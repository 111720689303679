import {
    LEAVEEMPLOYEES_FETCHING,
    LEAVEEMPLOYEES_SUCCESS,
    LEAVEEMPLOYEES_FAILED,
    CONTRACT_FETCHING,
    CONTRACT_FAILED,
    CONTRACT_SUCCESS,
    NEW_EMPLOYEE_FETCHING,
    NEW_EMPLOYEE_FAILED,
    NEW_EMPLOYEE_SUCCESS,
    RESIGN_EMPLOYEE_FETCHING,
    RESIGN_EMPLOYEE_FAILED,
    RESIGN_EMPLOYEE_SUCCESS,
    ATTENDANCE_ONCE_FETCHING,
    ATTENDANCE_ONCE_FAILED,
    ATTENDANCE_ONCE_SUCCESS,
    TAX_DEDUCTION_FETCHING,
    TAX_DEDUCTION_FAILED,
    TAX_DEDUCTION_SUCCESS,
    USER_PROVIDENTFUNDS_FETCHING,
    USER_PROVIDENTFUNDS_FAILED,
    USER_PROVIDENTFUNDS_SUCCESS,
    LEAVEEMPLOYEES_WITHDRAW_FETCHING,
    LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
    LEAVEEMPLOYEES_WITHDRAW_FAILED,
    REQUESTTIME_FETCHING,
    REQUESTTIME_SUCCESS,
    REQUESTTIME_FAILED,
    REQUESTTIME_WITHDRAW_FETCHING,
    REQUESTTIME_WITHDRAW_SUCCESS,
    REQUESTTIME_WITHDRAW_FAILED,
    PAYRUNS_FETCHING,
    PAYRUNS_FAILED,
    PAYRUNS_SUCCESS,
    PAYSLIP_FETCHING,
    PAYSLIP_FAILED,
    PAYSLIP_SUCCESS,
    PROBATION_EMPLOYEES_SUCCESS,
    PROBATION_EMPLOYEES_FAILED,
    PROBATION_EMPLOYEES_FETCHING
} from "./types";

import ReportService from "../services/report.service";

export const getAllLeaveWithDrawBy = (filter, search) => async (dispatch) => {
    try {
        dispatch({
            type: LEAVEEMPLOYEES_WITHDRAW_FETCHING,
        });
        const res = await ReportService.getAllLeaveWithDraw(filter, search);
        if (res) {
            dispatch({
                type: LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: LEAVEEMPLOYEES_WITHDRAW_FAILED,
        });
        console.log(error);
    }
};

export const getAllRequestTimeBy = (filter, search) => async (dispatch) => {
    try {
        dispatch({
            type: REQUESTTIME_FETCHING,
        });
        const res = await ReportService.getAllRequestTime(filter, search);
        if (res) {
            dispatch({
                type: REQUESTTIME_SUCCESS,
                payload: res.data,
            });
        }
    } catch (err) {
        dispatch({
            type: REQUESTTIME_FAILED,
        });
        console.log(err);
    }
};

export const getAllRequestTimeWithDrawBy =
    (filter, search) => async (dispatch) => {
        try {
            dispatch({
                type: REQUESTTIME_WITHDRAW_FETCHING,
            });
            const res = await ReportService.getAllRequestTimeWithDraw(
                filter,
                search
            );
            if (res) {
                dispatch({
                    type: REQUESTTIME_WITHDRAW_SUCCESS,
                    payload: res.data,
                });
            }
        } catch (err) {
            dispatch({
                type: REQUESTTIME_WITHDRAW_FAILED,
            });
            console.log(err);
        }
    };

export const getLeaveRequestByCompany = (filter, search) => async (dispatch) => {
    try {
        dispatch({
            type: LEAVEEMPLOYEES_FETCHING,
        });
        const res = await ReportService.getLeaveRequestByCompany(filter, search);
        if (res) {
            dispatch({
                type: LEAVEEMPLOYEES_SUCCESS,
                payload: res.data,
            });
        }
    } catch (err) {
        dispatch({
            type: LEAVEEMPLOYEES_FAILED,
        });
        console.log(err);
    }
};

export const getcontractEmployee = (filter, start) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_FETCHING,
        });
        const res = await ReportService.getcontractEmployee(filter, start);
        if (res) {
            dispatch({
                type: CONTRACT_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: CONTRACT_FAILED,
        });
        console.log(error);
    }
};

export const getNewemployee = (filter, start) => async (dispatch) => {
    try {
        dispatch({
            type: NEW_EMPLOYEE_FETCHING,
        });
        const res = await ReportService.getNewemployee(filter, start);
        if (res) {
            dispatch({
                type: NEW_EMPLOYEE_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: NEW_EMPLOYEE_FAILED,
        });
        console.log(error);
    }
};

export const getResignEmployee = (filter, start) => async (dispatch) => {
    try {
        dispatch({
            type: RESIGN_EMPLOYEE_FETCHING,
        });
        const res = await ReportService.getResignEmployee(filter, start);
        if (res) {
            dispatch({
                type: RESIGN_EMPLOYEE_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: RESIGN_EMPLOYEE_FAILED,
        });
        console.log(error);
    }
};

export const getAttendanceEmployees = (filter, start) => async (dispatch) => {
    try {
        dispatch({
            type: ATTENDANCE_ONCE_FETCHING,
        });
        const res = await ReportService.getAttendanceEmployees(filter, start);
        if (res) {
            dispatch({
                type: ATTENDANCE_ONCE_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: ATTENDANCE_ONCE_FAILED,
        });
        console.log(error);
    }
};

export const getProbationEmployees = (filter, start) => async (dispatch) => {
    try {
        dispatch({
            type: PROBATION_EMPLOYEES_FETCHING,
        });
        const res = await ReportService.getProbationEmployees(filter, start);
        if (res) {
            dispatch({
                type: PROBATION_EMPLOYEES_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: PROBATION_EMPLOYEES_FAILED,
        });
        console.log(error);
    }
};

export const getTaxemployee = (filter, start) => async (dispatch) => {
    try {
        dispatch({
            type: TAX_DEDUCTION_FETCHING,
        });
        const res = await ReportService.getTaxemployee(filter, start);
        if (res) {
            dispatch({
                type: TAX_DEDUCTION_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: TAX_DEDUCTION_FAILED,
        });
        console.log(error);
    }
};

export const getProvidentemployee = (filter, start) => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROVIDENTFUNDS_FETCHING,
        });
        const res = await ReportService.getProvidentemployee(filter, start);
        if (res) {
            dispatch({
                type: USER_PROVIDENTFUNDS_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: USER_PROVIDENTFUNDS_FAILED,
        });
        console.log(error);
    }
};

export const getPayrollemployee = (filter, start) => async (dispatch) => {
    try {
        dispatch({
            type: PAYSLIP_FETCHING,
        });
        const res = await ReportService.getPayrollemployee(filter, start);
        if (res) {
            dispatch({
                type: PAYSLIP_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: PAYSLIP_FAILED,
        });
        console.log(error);
    }
};

export const getPayrollCompany = (filter, start) => async (dispatch) => {
    try {
        dispatch({
            type:  PAYRUNS_FETCHING,
        });
        const res = await ReportService.getPayrollCompany(filter, start);
        if (res) {
            dispatch({
                type: PAYRUNS_SUCCESS ,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: PAYRUNS_FAILED ,
        });
        console.log(error);
    }
};