import React, { lazy, Suspense } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Container from "@mui/material/Container";

import Login from "./components/pages/LoginPage";
import Landing from "./components/pages/landing";
import ContactUs from "./components/pages/contactus";
import GpsCheckInOutPage from "./components/pages/line/gpsCheckInOut";
import QRCodeCheckInOutPage from "./components/pages/line/QRCodeCheckInOut";
import TodayCheckPage from "./components/pages/line/today-check";
import { UnauthorizedPage, NotFoundPage } from "./components/pages/error";
import ForgetPasswordPage from "./components/pages/account/forget-password";
import ResetPasswordPage from "./components/pages/account/reset-password";

import HomePage from "./components/pages/homepage";

//Main
import ApproveListPage from "./components/pages/main-page/approve-list";
import DocumentServicePage from "./components/pages/main-page/document-service";
import SummaryTimePage from "./components/pages/main-page/summary-ot";
import OverviewPage from "./components/pages/main-page/overview";
import EmployeesPage from "./components/pages/main-page/employee";
import TimeAttendancePage from "./components/pages/main-page/time-attendance";
import OnBoarding from "./components/pages/main-page/on-boarding";
import OffBoarding from "./components/pages/main-page/off-boarding";
import UniRecruit from "./components/pages/main-page/uni-recruit";
import WarningLetter from "./components/pages/main-page/warning-letter";
import AssetManagement from "./components/pages/main-page/asset-management";
import Announcement from "./components/pages/main-page/announcement";
import AccessAreaPage from "./components/pages/main-page/AccessArea";
import AccessTimePage from "./components/pages/main-page/AccessTime"
import EmployeeNetworkPage from "./components/pages/main-page/employee-network";

//User
import ProfilePage from "./components/pages/user/profile";
import Notification from "./components/pages/NotificationPage";
import Timeline from "./components/pages/user/timeline";
import TimeSchedule from "./components/pages/user/time-schedule";
import Leavelist from "./components/pages/user/leave/Leavelist";
import Requestlist from "./components/pages/user/requestlist/Requestlist";
import Overtimereport from "./components/pages/user/overtime";
import SalarySlip from "./components/pages/user/salary/paySlip";
import TaxDeduction from "./components/pages/user/salary/tax/TaxDeduction";
import TaxList from "./components/pages/user/salary/tax/TaxList";
import EmployeeNetwork from "./components/pages/user/employeeNetwork";
import EmployeeNetworkUser from "./components/pages/user/employeeNetwork/employeeProfile";
// import CoursesRegister from "./components/pages/user/training/course";
// import Information from "./components/pages/user/member/information";
// import SelectTask from "./components/pages/user/task/selectTask";
// import EditPersonalTask from "./components/pages/user/task/form/editPersonalTask";
// import AddNewTaskUser from "./components/pages/user/task/form/formTask";
// import PersonalTask from "./components/pages/user/task/showPersonalTask";
// import ProjectAndAVG from "./components/pages/user/task/project&avg";
// import AddPersonalProject from "./components/pages/user/task/form/addPersonalProject";
// import AddPersonalAVG from "./components/pages/user/task/form/addPersonalAVG";
import ShowProject from "./components/pages/user/project/showProject";
import ShowDetialProject from "./components/pages/user/project/showDetail";
import EditPosition from "./components/pages/user/project/editProject";
import AddProject from "./components/pages/user/project/addProject";
import CareerPart from "./components/pages/user/career/careerPart";
import RoadMap from "./components/pages/user/career/roadMap";
import EnterPosition from "./components/pages/user/career/enterPosition";
import JobPosting from "./components/pages/user/jobPosting";
import JobPostingApply from "./components/pages/user/jobPostingApply";
import DemandAnalysis from "./components/pages/user/demand/demandAnalysis";
import MyDocument from "./components/pages/user/my-document";
import SummaryTime from "./components/pages/user/summaryTimeAndSalary";

import OKRsHomepage from "./components/pages/user/okrs";
import OKRsIndividualPlanningPage from "./components/pages/user/okrs/IndividualPlanning";
import OKRsIndividualPlanningAddFormPage from "./components/pages/user/okrs/IndividualPlanning/addForm";
import OKRsIndividualPlanningInformationPage from "./components/pages/user/okrs/IndividualPlanning/IndividualInformation";

import OKRsTeamPlanningPage from "./components/pages/user/okrs/Planning/Team";
import OKRsTeamPlanningAddFormPage from "./components/pages/user/okrs/Planning/Team/addForm";
import OKRsTeamPlanningInformationPage from "./components/pages/user/okrs/Planning/Team/Information";

import OKRsOrganizationPlanningPage from "./components/pages/user/okrs/Planning/Organization";
import OKRsOrganizationPlanningAddFormPage from "./components/pages/user/okrs/Planning/Organization/addForm";
import OKRsOrganizationPlanningInformationPage from "./components/pages/user/okrs/Planning/Organization/Information";
// import OKRsOrganizationPlanningAddFormPage from "./components/pages/user/okrs/OrganizationPlanning/addForm";
// import OKRsOrganizationPlanningInformationPage from "./components/pages/user/okrs/OrganizationPlanning/Information";

import OKRsUpdateProgressIndividualPage from "./components/pages/user/okrs/UpdateProgress/Individual";
import OKRsUpdateProgressIndividualObjectivePage from "./components/pages/user/okrs/UpdateProgress/Individual/Objective";
import OKRsUpdateProgressIndividualKeyResultPage from "./components/pages/user/okrs/UpdateProgress/Individual/KeyResult";

import OKRsUpdateProgressTeamPage from "./components/pages/user/okrs/UpdateProgress/Team";
import OKRsUpdateProgressTeamObjectivePage from "./components/pages/user/okrs/UpdateProgress/Team/Objective";
import OKRsUpdateProgressTeamKeyResultPage from "./components/pages/user/okrs/UpdateProgress/Team/KeyResult";

import OKRsUpdateProgressOrganizationPage from "./components/pages/user/okrs/UpdateProgress/Organization";
import OKRsUpdateProgressOrganizationObjectivePage from "./components/pages/user/okrs/UpdateProgress/Organization/Objective";
import OKRsUpdateProgressOrganizationKeyResultPage from "./components/pages/user/okrs/UpdateProgress/Organization/KeyResult";

import OKRsDashboardIndividualPage from "./components/pages/user/okrs/Dashboard/Individual";
import OKRsDashboardTeamPage from "./components/pages/user/okrs/Dashboard/Team";
import OKRsDashboardOrganizationPage from "./components/pages/user/okrs/Dashboard/Organization";

// import OKRsApprovalPage from "./components/pages/user/okrs/Approval";
// import OKRsApprovalIndividualPage from "./components/pages/user/okrs/Approval/Individual";
import OKRsApprovalIndividualPage from "./components/pages/user/okrs/Approval/Individual";
import OKRsApprovalIndividualObjectivePage from "./components/pages/user/okrs/Approval/Individual/Objective";

import OKRsEvaluationPage from "./components/pages/user/okrs/Evaluation";
// import OKRsEvaluationIndividualPage from "./components/pages/user/okrs/Evaluation/Individual";
import OKRsEvaluationIndividualPage from "./components/pages/user/okrs/Evaluation/Individual";
import OKRsEvaluationIndividualObjectivePage from "./components/pages/user/okrs/Evaluation/Individual/Objective";
import Boarding from "./components/pages/user/assessor";
// import Timeline2 from "./components/pages/user/timeline2";
import CompanyInterview from "./components/pages/user/Unirecruit/company-interview/index"
import CompanyCandidate from "./components/pages/user/Unirecruit/company-candidate/index"
import OpenJobFormPage from "./components/pages/user/Unirecruit/openjob/openJobForm";
import RequestInformationPageOpenJob from "./components/pages/user/Unirecruit/openjob/RequestInformationPageOpenJob";

//Manager
import ApproveList from "./components/pages/manager/approveList";
import HistoryApproveList from "./components/pages/manager/historyApproveList";
import OTMonitoring from "./components/pages/manager/otMonitoring";
import SwitchShiftEmployeePage from "./components/pages/manager/employee/switchShift";
import EmployeeManagerPage from "./components/pages/manager/employee/employeePage";
import EmployeeProfileManagerPage from "./components/pages/manager/employee/profile";
// import MemberInTeamManager from "./components/pages/manager/member/memberInTeam";
// import InformationManager from "./components/pages/manager/member/Information";
// import DetailWorkManager from "./components/pages/manager/member/detailOfWork";
// import EachPersonWhiteManager from "./components/pages/manager/member/eachPerson";
import SuggestCareer from "./components/pages/manager/career/suggestCareer";
import Career from "./components/pages/manager/career/career";
import ViewSuggest from "./components/pages/manager/career/viewSuggest";
// import DashboardManagerPage from "./components/pages/manager/dashboardSupply/dashboardPage";
// import ShowDemand from "./components/pages/manager/demand/showDemand";
// import AddDemand from "./components/pages/manager/demand/addDemand";
// import BillRequestM from "./components/pages/manager/health/BillRequestM";
// Admin
import {
  SettingPage,
  EmployeeProfilePage,
  AdminOtMonitoring,
  ShiftPage,
  ShiftAddNewPage,
  ShiftListEmployeePage,
  PlanYearPage,
  ManPowerPage,
  TeamManPowerPage,
  RequestPage,
  RequestInformationPage,
  CandidateProfilePage,
  RequestFormPage,
  OrganizationChartPage,
  ProjectOrganizationChartPage,
  ProjectListPage,
  PayRunsPage,
  PayrollSettingPage,
  PayRunsNewPage,
  PayRunsEditPage,
  PayrollAdditionDeductionPage,
  CompanyProfilePage,
  SelectKey,
  KeyAddition,
  ApporveStatusListPage,
  // CourseDetailAdmin,
  // CoursePageAdmin,
  DetailOfJob,
  ShowAllPosition,
  ShowJobTask,
  ShowTask,
  FormTask,
  EditTask,
  AddJobLevel,
  ShowDivision,
  ShowMemberInDivision,
  InformationDivisionAdmin,
  AddDivision,
  // SupplyDashboard,
  // ManageDemand,
  // EmployeeNetworkAdmin,
  // EmployeeProfile,
  // SelectPersonToVS,
  FormJobStructure,
  // FormPackage,
  // FormLifeStyle,
  // FormTravel,
  // FormInsurance,
  // FormpackageHealthChrck,
  OKRsHomepage as OKRsAdminHomepage,
  OrganizationPlanList,
  OrganizationPlan,
  TeamPlanList,
  TeamPlan,
  IndividualPlanList,
  IndividualPlan,
  CascadeOKRView,
  OKRTransparent,
  EmpSuccessList,
  FailureHistoryList,
  FailureHistory,
  FailureHistoryView,
  Approval,
  EmployeePlanList,
  IndividualPlanEvaluation,
  ManagerTeamApprove,
  EvaluateManager,
  OrganizationUpdate,
  OrganizationRecord,
  UpdateKROrg,
  TeamUpdate,
  UpdateKRTeam,
  TeamRecord,
  IndividualUpdate,
  UpdateKRIdv,
  IndividualRecord,
  DashboardOKR,
  OrgEvaluationList,
  OrgEvaluation,
  TeamEvaluationList,
  TeamEvaluation,
  IdvEvaluationList,
  IdvEvaluation,
  OKRHistoryList,
  SuccessProfileList,
  SuccessProfile,
  DialogueRecord,
  DialogueRecordView,
  DialogueHistory,
  CascadeMain,
  IndividualDashboard,
  ManagerTeamDashboard,
  IdvPlanListApproved,
  Leaderboard,
  OrganizationDashboard,
  LeaderboardOrg,
  ReportAdminPage,
  PND91AND50tawi,
  HistoryApporveStatusListPage,
  DocumentEmployee,
  DialogNewDocument,
  EmployeeNetworkProfilePage
} from "./components/pages/admin";
import SummaryTimeAdminPage from "./components/pages/admin/summaryTime";
import ShowDetailPosition from "./components/pages/shared/pages/job/show/ShowDetailPosition";
import AddEmployeePage from "./components/pages/admin/employee/addEmployee";
import DiligenceAllowancePage from "./components/pages/admin/report/total/diligence-allowance";
import All_ReportAdminPage from "./components/pages/admin/all-report";
import LeavePage from "./components/pages/admin/all-report/late-absent/LavePage";
import CostElement from "./components/pages/admin/all-report/costElement";
import late_absent from "./components/pages/admin/all-report/late-absent/index"
import Payroll from "./components/pages/admin/all-report/payroll";

import OrganizationChartNewPage from "./components/pages/admin/organizationChart";
import OrganizationChartV2Page from "./components/pages/admin/organizationChartV2";
import OrganizationChartV3Page from "./components/pages/admin/organizationChartDabeng";


import RegisterManpower from "./components/pages/admin/uniRecruit/peer/registerManpower/index";
import Probation from "./components/pages/admin/setting/probation/index";
import HomepageUniRecruit from "./components/pages/admin/uniRecruit/homePage/index";
import PeerPage from "./components/pages/admin/uniRecruit/peer/index";
import Dashboard from "./components/pages/admin/uniRecruit/dashboard/index";
import AddManpowerFormPage from "./components/pages/admin/uniRecruit/peer/addManpower/index";
import VendorManpowerProfilePage from "./components/pages/admin/uniRecruit/peer/addManpower/peerProfile/profilePeer";
import HunterInterviewPage from "./components/pages/admin/uniRecruit/hunter-interview";
import ChangePasswordPage from "./components/pages/account/change-password";
import HunterHistorySending from "./components/pages/admin/uniRecruit/hunter-history-sending";
import RecruitInterviewer from "./components/pages/admin/uniRecruit/interviewer";

// import CourseDetail from "./components/pages/training/course/detail";
// import CoursePage from "./components/pages/training/course";

const useStyles = makeStyles(() => ({
  content: {
    padding: 0,
    // flexGrow: 1,
    // display: "flex",
    // justifyContent: "center",
    // padding: 0,
  },
}));

const Routers = () => {
  const classes = useStyles();
  const { user: currentUser } = useSelector((state) => state.auth);

  const RedirectLogin = () => (
    <Route>
      <Redirect to="/login" />
    </Route>
  );

  const LoginRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            true ? <Component {...props} /> : <Redirect to="/unauthorized" />
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AdminRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AdminUserRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ||
              currentUser.roles.includes("ROLE_USER") ? (
                <Component {...props} />
              ) : (
                <Redirect to="/unauthorized" />
              )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AdminManagerRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ||
              currentUser.roles.includes("ROLE_MANAGER") ? (
                <Component {...props} />
              ) : (
                <Redirect to="/unauthorized" />
              )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AdminManagerUserRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ||
              currentUser.roles.includes("ROLE_USER") === true ||
              currentUser.roles.includes("ROLE_MANAGER") === true ? (
                <Component {...props} />
              ) : (
                <Redirect to="/unauthorized" />
              )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const ManagerRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_MANAGER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const UserRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_USER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const TrainingRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_TRAINING") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  return (
    <Container
      style={{ padding: 0 }}
      className={classes.content}
      maxWidth={false}
    >
      <Suspense fallback={<div>Loading..</div>}>
        <Switch>
          <Route
            exact
            path={["/"]}
            component={() =>
              currentUser ? <Redirect to="/home" /> : <Redirect to="/landing" />
            }
          />
          <Route exact path="/login" component={Login} />
          <Route exact path="/landing" component={Landing} />
          <Route exact path="/forget-password" component={ForgetPasswordPage} />
          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <AdminManagerRoute exact path="/change-password" component={ChangePasswordPage} />
          <Route exact path="/unauthorized" component={UnauthorizedPage} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/gps-check" component={GpsCheckInOutPage} />
          <Route
            exact
            path="/qrcode-check/:idGroupGpsLocations"
            component={QRCodeCheckInOutPage}
          />
          <Route exact path="/today-check" component={TodayCheckPage} />
          <Route exact path="/manpower/register_manpower" component={RegisterManpower} />

          <LoginRoute exact path="/home" component={HomePage} />

          <AdminUserRoute
            exact
            path="/request-list"
            component={ApproveListPage}
          />
          <AdminUserRoute
            exact
            path="/document-service"
            component={DocumentServicePage}
          />
          <AdminUserRoute
            exact
            path="/summary-time"
            component={SummaryTimePage}
          />
          <AdminUserRoute
            exact
            path="/recruitment"
            component={UniRecruit}
          />
          <AdminUserRoute
            exact
            path="/warning-letter"
            component={WarningLetter}
          />
          <AdminUserRoute
            exact
            path="/asset-management"
            component={AssetManagement}
          />
          <AdminUserRoute
            exact
            path="/access_area"
            component={AccessAreaPage}
          />
          <AdminUserRoute
            exact
            path="/access_time"
            component={AccessTimePage}
          />

          <AdminManagerRoute exact path="/employees" component={EmployeesPage} />
          <AdminManagerRoute exact path="/time-attendance" component={TimeAttendancePage} />

          <AdminManagerUserRoute
            exact
            path="/company/request/:idRequest"
            component={RequestInformationPageOpenJob}
          />
          <AdminManagerUserRoute exact path="/overview" component={OverviewPage} />
          <AdminManagerUserRoute exact path="/announcement" component={Announcement} />
          <AdminManagerUserRoute exact path="/boarding" component={Boarding} />
          <AdminManagerUserRoute exact path="/on-boarding" component={OnBoarding} />
          <AdminManagerUserRoute exact path="/off-boarding" component={OffBoarding} />
          {/* User Route */}
          <UserRoute exact path="/manager/career" component={Career} />
          <UserRoute exact path="/edit/employee_profile" component={EmployeeNetworkUser} />

          <UserRoute exact path="/notification" component={Notification} />
          <UserRoute exact path="/profile" component={ProfilePage} />
          <UserRoute exact path="/timeline" component={Timeline} />
          <UserRoute exact path="/time-schedule" component={TimeSchedule} />

          {/* <UserRoute exact path="/timeline2/" component={Timeline} /> */}

          <UserRoute exact path="/leave" component={Leavelist} />
          <UserRoute exact path="/requestlist" component={Requestlist} />
          <UserRoute exact path="/pay-slip" component={SalarySlip} />
          <UserRoute exact path="/taxdeduction" component={TaxDeduction} />
          <UserRoute exact path="/summary-time" component={SummaryTime} />
          {/* <UserRoute
            exact
            path="/courses/register"
            component={CoursesRegister}
          /> */}

          <UserRoute exact path="/taxlist" component={TaxList} />
          <UserRoute
            exact
            path="/overtime/overtimereport"
            component={Overtimereport}
          />
          {/* <UserRoute exact path="/FTE" component={PersonalTask} /> */}
          {/* <UserRoute
            exact
            path="/FTE/projectAndAVG"
            component={ProjectAndAVG}
          /> */}
          {/* <UserRoute
            exact
            path="/FTE/projectAndAVG/addProject"
            component={AddPersonalProject}
          /> */}
          {/* <UserRoute
            exact
            path="/FTE/projectAndAVG/addAVG"
            component={AddPersonalAVG}
          /> */}
          {/* <UserRoute exact path="/FTE/selectTask" component={SelectTask} /> */}
          {/* <UserRoute
            exact
            path="/FTE/editPersonalTask/:id"
            component={EditPersonalTask}
          /> */}
          {/* <UserRoute
            exact
            path="/FTE/selectTask/addNewTask"
            component={AddNewTaskUser}
          /> */}

          {/* <UserRoute exact path="/information" component={Information} /> */}

          <UserRoute exact path="/project" component={ShowProject} />
          <UserRoute
            exact
            path="/project/showDetail/:id"
            component={ShowDetialProject}
          />
          <UserRoute
            exact
            path={["/project/editProject/:projectID :role"]}
            component={EditPosition}
          />
          <UserRoute exact path="/project/addProject" component={AddProject} />

          <UserRoute exact path="/myCareerePlan/roadMap" component={RoadMap} />
          <UserRoute exact path="/myCareerePlan" component={CareerPart} />
          <UserRoute
            exact
            path="/myCareerePlan/enterPosition"
            component={EnterPosition}
          />

          <UserRoute exact path="/jobposting" component={JobPosting} />
          <UserRoute
            exact
            path="/jobpostingapply"
            component={JobPostingApply}
          />
          <UserRoute exact path="/demandAnalysis" component={DemandAnalysis} />
          <UserRoute exact path="/my-document" component={MyDocument} />

          {/* <UserRoute exact path="/okrs" component={OKRsHomepage} /> */}
          <UserRoute
            exact
            path="/okrs/individual-planning"
            component={OKRsIndividualPlanningPage}
          />
          <UserRoute
            exact
            path="/okrs/individual-planning/form"
            component={OKRsIndividualPlanningAddFormPage}
          />
          <UserRoute
            exact
            path="/okrs/individual-planning/information/:idIndividualObjective"
            component={OKRsIndividualPlanningInformationPage}
          />

          <UserRoute
            exact
            path="/okrs/team-planning"
            component={OKRsTeamPlanningPage}
          />
          <UserRoute
            exact
            path="/okrs/team-planning/form"
            component={OKRsTeamPlanningAddFormPage}
          />
          <UserRoute
            exact
            path="/okrs/team-planning/information/:idTeamObjective"
            component={OKRsTeamPlanningInformationPage}
          />

          <UserRoute
            exact
            path="/okrs/organization-planning"
            component={OKRsOrganizationPlanningPage}
          />
          <UserRoute
            exact
            path="/okrs/organization-planning/form"
            component={OKRsOrganizationPlanningAddFormPage}
          />
          <UserRoute
            exact
            path="/okrs/organization-planning/information/:idOrganizationObjective"
            component={OKRsOrganizationPlanningInformationPage}
          />

          <UserRoute
            exact
            path="/okrs/update-progress/individual"
            component={OKRsUpdateProgressIndividualPage}
          />
          <UserRoute
            exact
            path="/okrs/update-progress/individual/objective/:idIndividualObjective"
            component={OKRsUpdateProgressIndividualObjectivePage}
          />
          <UserRoute
            exact
            path="/okrs/update-progress/individual/keyresult/:idIndividualKeyResult"
            component={OKRsUpdateProgressIndividualKeyResultPage}
          />

          <UserRoute
            exact
            path="/okrs/update-progress/team"
            component={OKRsUpdateProgressTeamPage}
          />
          <UserRoute
            exact
            path="/okrs/update-progress/team/objective/:idTeamObjective"
            component={OKRsUpdateProgressTeamObjectivePage}
          />
          <UserRoute
            exact
            path="/okrs/update-progress/team/keyresult/:idTeamKeyResult"
            component={OKRsUpdateProgressTeamKeyResultPage}
          />

          <UserRoute
            exact
            path="/okrs/update-progress/organization"
            component={OKRsUpdateProgressOrganizationPage}
          />
          <UserRoute
            exact
            path="/okrs/update-progress/organization/objective/:idOrganizationObjective"
            component={OKRsUpdateProgressOrganizationObjectivePage}
          />
          <UserRoute
            exact
            path="/okrs/update-progress/organization/keyresult/:idOrganizationKeyResult"
            component={OKRsUpdateProgressOrganizationKeyResultPage}
          />

          <UserRoute
            exact
            path="/okrs/approval/individual"
            component={OKRsApprovalIndividualPage}
          />
          <UserRoute
            exact
            path="/okrs/approval/individual/:idIndividualObjective"
            component={OKRsApprovalIndividualObjectivePage}
          />
          {/* <UserRoute exact path="/okrs/approval/individual2/:idIndividualObjective" component={OKRsApprovalIndividual2Page} /> */}

          <UserRoute
            exact
            path="/okrs/evaluation/individual"
            component={OKRsEvaluationIndividualPage}
          />
          <UserRoute
            exact
            path="/okrs/evaluation/individual/:idIndividualObjective"
            component={OKRsEvaluationIndividualObjectivePage}
          />

          <UserRoute
            exact
            path="/okrs/dashboard/individual"
            component={OKRsDashboardIndividualPage}
          />
          <UserRoute
            exact
            path="/okrs/dashboard/team"
            component={OKRsDashboardTeamPage}
          />
          <UserRoute
            exact
            path="/okrs/dashboard/organization"
            component={OKRsDashboardOrganizationPage}
          />

          {/* <UserRoute exact path="/okrs/dashboard/team" component={OKRsDashboardTeamPage} /> */}
          {/* <UserRoute exact path="/okrs/dashboard/organization" component={OKRsDashboardTeamPage} /> */}

          {/* <UserRoute exact path="/okrs/team-planning" component={OKRsTeamPlanningPage} />
          <UserRoute exact path="/okrs/team-planning/information/:idTeamObjective" component={OKRsTeamPlanningPage} /> */}

          {/* <UserRoute exact path="/okrs/update-plannig/individual-objective" component={OKRsIndividualKeyResultUpdatePage} /> */}
          {/* <UserRoute exact path="/okrs/update-plannig/individual-objective/:idIndividualObjective" component={OKRsIndividualKeyResultUpdatePage} /> */}
          {/* <UserRoute exact path="/okrs/update-plannig/individual-objective/:idIndividualObjective" component={OKRsIndividualKeyResultUpdatePage} /> */}
          {/* <UserRoute exact path="/okrs/individual-planning/update-status" component={OKRsIndividualKeyResultUpdatePage} /> */}
          {/* <UserRoute exact path="/okrs/individual-planning/update-status/keyresult/:idIndividualKeyResult" component={OKRsIndividualKeyResultUpdatePage} /> */}

          {/* <UserRoute exact path="/okrs/approval" component={OKRsApprovalPage} />
          <UserRoute exact path="/okrs/approval/individual/:idIndividualObjective" component={OKRsApprovalIndividualPage} /> */}
          {/* <UserRoute exact path="/okrs/evaluation" component={OKRsEvaluationPage} />
          <UserRoute exact path="/okrs/evaluation/individual/:idIndividualObjective" component={OKRsEvaluationIndividualPage} /> */}
          {/* <UserRoute exact path="/okrs/evaluation/individual/:idIndividualObjective" component={OKRsEvaluationIndividualPage} /> */}

          <UserRoute exact path="/company/interview" component={CompanyInterview} />
          <UserRoute exact path="/company-candidate/:type/:id" component={CompanyCandidate} />
          <UserRoute exact path="/manage/company/openjob/form" component={OpenJobFormPage} />

          {/* Manager Route */}
          <ManagerRoute exact path="/waiting-approve" component={ApproveList} />
          <ManagerRoute
            exact
            path="/history-approve"
            component={HistoryApproveList}
          />

          <ManagerRoute
            exact
            path="/manager/otmonitoring"
            component={OTMonitoring}
          />
          <ManagerRoute
            exact
            path="/manager/employee/:idEmployees"
            component={EmployeeProfileManagerPage}
          />
          <ManagerRoute
            exact
            path="/manager/employee"
            component={EmployeeManagerPage}
          />
          <ManagerRoute
            exact
            path="/manager/switch-shift"
            component={SwitchShiftEmployeePage}
          />
          <ManagerRoute exact path="/manager/career" component={Career} />
          <ManagerRoute
            exact
            path="/manager/career/viewSuggest/:id"
            component={ViewSuggest}
          />
          <ManagerRoute
            exact
            path="/manager/career/suggestCareer"
            component={SuggestCareer}
          />
          {/* <ManagerRoute
            exact
            path="/manager/dashboard"
            component={DashboardManagerPage}
          /> */}

          {/* <ManagerRoute
            exact
            path="/manager/chartShowMember/Information/:id"
            component={InformationManager}
          /> */}
          {/* <ManagerRoute
            exact
            path="/manager/memberInTeam"
            component={MemberInTeamManager}
          /> */}
          {/* <ManagerRoute exact path="/manager/demand" component={ShowDemand} /> */}
          {/* <ManagerRoute
            exact
            path="/manager/demand/addDemand"
            component={AddDemand}
          /> */}
          {/* <ManagerRoute
            exact
            path="/manager/detailOfMemberWork"
            component={DetailWorkManager}
          /> */}
          {/* <ManagerRoute
            exact
            path="/manager/eachPerson/:id"
            component={EachPersonWhiteManager}
          /> */}
          {/* <ManagerRoute
            exact
            path="/manager/health/BillRequest"
            component={BillRequestM}
          /> */}

          {/* Admin Route */}

          <AdminRoute exact path="/admin/setting" component={SettingPage} />
          {/* <AdminRoute exact path="/employees-documents" component={ReportAdminPage} /> */}
          <AdminRoute
            exact
            path="/employees-documents"
            component={DocumentEmployee}
          />
          <AdminRoute
            exact
            path="/employees-documents/edit"
            component={DialogNewDocument}
          />
          <AdminRoute
            exact
            path="/admin/approve-list"
            component={ApporveStatusListPage}
          />
          <AdminRoute
            exact
            path="/adim/history-approve-list"
            component={HistoryApporveStatusListPage}
          />
          <AdminRoute
            exact
            path="/employees/:id/view"
            component={EmployeeProfilePage}
          />
          <AdminRoute
            exact
            path="/employees/:idEmployees/edit"
            component={KeyAddition}
          />

          {/* <AdminRoute
            exact
            path="/admin/employees/add"
            component={EmployeesFormAddPage}
          /> */}
          <AdminRoute
            exact
            path="/admin/employees/add"
            component={AddEmployeePage}
          />
          <AdminRoute
            exact
            path="/admin/shift/list-employee"
            component={ShiftListEmployeePage}
          />
          <AdminRoute
            exact
            path="/admin/shift/new"
            component={ShiftAddNewPage}
          />
          <AdminRoute
            exact
            path="/admin/shift/year/:id"
            component={PlanYearPage}
          />
          <AdminRoute exact path="/admin/shift" component={ShiftPage} />

          <AdminRoute
            exact
            path="/admin/otmonitoring"
            component={AdminOtMonitoring}
          />
          {/* <AdminRoute
            exact
            path="/admin/manpower/:id"
            component={TeamManPowerPage}
          /> */}
          <AdminRoute exact path="/admin/manpower" component={ManPowerPage} />
          <AdminRoute exact path="/admin/request" component={RequestPage} />
          <AdminRoute
            exact
            path="/admin/request/information/:id"
            component={RequestInformationPage}
          />
          <AdminRoute
            exact
            path="/admin/request/form"
            component={RequestFormPage}
          />
          <AdminRoute
            exact
            path="/admin/request/candidate/:idCandidate"
            component={CandidateProfilePage}
          />
          {/* <AdminRoute
            exact
            path="/admin/organization"
            component={OrganizationChartPage}
          /> */}
          <AdminRoute
            exact
            path="/admin/organization"
            component={OrganizationChartV2Page}
          />
          <AdminRoute
            exact
            path="/admin/project-list"
            component={ProjectListPage}
          />
          <AdminRoute
            exact
            path="/admin/project-assign/:projectName"
            component={ProjectOrganizationChartPage}
          />

          <AdminRoute
            exact
            path="/admin/payroll/run/new"
            component={PayRunsNewPage}
          />

          <AdminRoute
            exact
            path="/vendor/payroll/run/:idPayrun"
            component={PayRunsEditPage}
          />

          {/* <AdminRoute
            exact
            path="/admin/payroll/run/edit"
            component={PayRunsEditPage}
          /> */}

          <AdminRoute exact path="/payroll" component={PayRunsPage} />
          <AdminRoute exact path="/payroll/setting" component={PayrollSettingPage} />

          <AdminRoute
            exact
            path="/payroll/addition-deduction"
            component={PayrollAdditionDeductionPage}
          />

          <AdminRoute
            exact
            path="/admin/company-profile"
            component={CompanyProfilePage}
          />

          <AdminRoute exact path="/admin/selectkey/add" component={SelectKey} />

          {/* <AdminRoute
            exact
            path="/admin/courses/:id"
            component={CourseDetailAdmin}
          />
          <AdminRoute exact path="/admin/courses" component={CoursePageAdmin} /> */}

          <AdminRoute
            exact
            path="/admin/jobStructure"
            component={DetailOfJob}
          />
          <AdminRoute
            exact
            path={[
              "/admin/showAllPosition/showDetail/:JobPosition :backPosition :id :jobPosition",
              "/admin/jobStructure/showDetail/:JobPosition :backPosition :id :jobPosition",
            ]}
            component={ShowDetailPosition}
          />
          <AdminRoute
            exact
            path={["/admin/showAllPosition"]}
            component={ShowAllPosition}
          />

          <AdminRoute exact path="/admin/addJobLevel" component={AddJobLevel} />

          {/* <AdminRoute exact path="/admin/showTask" component={ShowJobTask} />
          <AdminRoute
            exact
            path="/admin/task/taskList/:name"
            component={ShowTask}
          />
          <AdminRoute exact path="/admin/task/addTask" component={FormTask} />
          <AdminRoute exact path="/admin/editTask/:id" component={EditTask} /> */}

          {/*  */}
          <AdminRoute
            exact
            path="/admin/showDivision"
            component={ShowDivision}
          />
          <AdminRoute
            exact
            path={[
              "/admin/showDivision/showMemberInDivision/:division :department :section",
            ]}
            component={ShowMemberInDivision}
          />
          <AdminRoute
            exact
            path={[
              "/admin/showDivision/showMemberInDivision/:division :department :section/information/:id",
            ]}
            component={InformationDivisionAdmin}
          />
          <AdminRoute
            exact
            path="/admin/showDivision/addDivision"
            component={AddDivision}
          />

          {/* <AdminRoute
            exact
            path="/admin/manageDemand"
            component={ManageDemand}
          /> */}

          {/* <AdminRoute
            exact
            path="/admin/employees_network"
            component={EmployeeNetworkAdmin}
          /> */}

          {/* <AdminRoute
            exact
            path="/admin/employee_profile"
            component={EmployeeProfile}
          /> */}

          {/* <AdminRoute
            exact
            path="/admin/ComparePerson"
            component={SelectPersonToVS}
          /> */}

          <AdminRoute
            exact
            path="/admin/jobStructure/formJob/:name"
            component={FormJobStructure}
          />

          <AdminRoute exact path="/admin/Career" component={Career} />

          {/* <AdminRoute
            exact
            path="/warehouse/FormLifeStyle"
            component={FormLifeStyle}
          />
          <AdminRoute
            exact
            path="/warehouse/FormTravel"
            component={FormTravel}
          />
          <AdminRoute
            exact
            path="/warehouse/FormInsurance"
            component={FormInsurance}
          />
          <AdminRoute
            exact
            path="/warehouse/FormPackageHealthCheck"
            component={FormpackageHealthChrck}
          /> */}

          {/* Admin OKRs  */}
          <AdminRoute exact path="/okrs" component={OKRsAdminHomepage} />
          <AdminRoute
            exact
            path="/organizationList"
            component={OrganizationPlanList}
          />
          <AdminRoute exact path="/organization" component={OrganizationPlan} />
          <AdminRoute exact path="/teamlist" component={TeamPlanList} />
          <AdminRoute exact path="/team" component={TeamPlan} />
          <AdminRoute
            exact
            path="/individualList"
            component={IndividualPlanList}
          />
          <AdminRoute exact path="/individual" component={IndividualPlan} />
          <AdminRoute exact path="/cascadeokrview" component={CascadeOKRView} />
          <AdminRoute
            exact
            path="/transparentlist"
            component={OKRTransparent}
          />
          <AdminRoute
            exact
            path="/employeesuccesslist"
            component={EmpSuccessList}
          />
          <AdminRoute
            exact
            path="/failurehistorylist"
            component={FailureHistoryList}
          />
          <AdminRoute exact path="/failurehistory" component={FailureHistory} />
          <AdminRoute
            exact
            path="/failurehistoryview"
            component={FailureHistoryView}
          />
          <AdminRoute exact path="/approval" component={Approval} />
          <AdminRoute
            exact
            path="/employeeplanlist/:name"
            component={EmployeePlanList}
          />
          <AdminRoute
            exact
            path="/individualplanevaluation/:id"
            component={IndividualPlanEvaluation}
          />
          <AdminRoute
            exact
            path="/approval/crossteam/:id"
            component={ManagerTeamApprove}
          />
          <AdminRoute
            exact
            path="/evaluationmanager/:name"
            component={EvaluateManager}
          />
          <AdminRoute
            exact
            path="/organizationupdate"
            component={OrganizationUpdate}
          />
          <AdminRoute
            exact
            path="/organizationrecords/:name"
            component={OrganizationRecord}
          />
          <AdminRoute exact path="/updateKROrg/:name" component={UpdateKROrg} />
          <AdminRoute exact path="/teamupdate" component={TeamUpdate} />
          <AdminRoute
            exact
            path="/updateKRTeam/:name"
            component={UpdateKRTeam}
          />
          <AdminRoute path="/teamrecords" component={TeamRecord} />
          <AdminRoute path="/individualupdate" component={IndividualUpdate} />
          <AdminRoute exact path="/updateKRIdv/:name" component={UpdateKRIdv} />
          <AdminRoute path="/individualrecords" component={IndividualRecord} />
          <AdminRoute path="/okrdashboard" component={DashboardOKR} />
          <AdminRoute path="/orgvaluationlist" component={OrgEvaluationList} />
          <AdminRoute
            path="/organizationevaluation/:name"
            component={OrgEvaluation}
          />
          <AdminRoute
            path="/teamevaluationlist"
            component={TeamEvaluationList}
          />
          <AdminRoute path="/teamevaluation/:name" component={TeamEvaluation} />
          <AdminRoute path="/idvevaluationlist" component={IdvEvaluationList} />
          <AdminRoute
            path="/individualevaluation/:name"
            component={IdvEvaluation}
          />
          <AdminRoute path="/okrhistorylist" component={OKRHistoryList} />
          <AdminRoute
            path="/successprofilelist"
            component={SuccessProfileList}
          />
          <AdminRoute path="/successprofile" component={SuccessProfile} />
          <AdminRoute path="/dialogue" component={DialogueRecord} />
          <AdminRoute path="/dialoguehistory" component={DialogueHistory} />
          <AdminRoute
            path="/dialoguerecordview"
            component={DialogueRecordView}
          />
          <AdminRoute path="/cascademain" component={CascadeMain} />
          <AdminRoute
            path="/individualdashboard"
            component={IndividualDashboard}
          />
          <AdminRoute
            path="/managerteamdashboard"
            component={ManagerTeamDashboard}
          />
          <AdminRoute
            path="/idvplanlistapproved"
            component={IdvPlanListApproved}
          />
          <AdminRoute path="/leaderboard" component={Leaderboard} />
          <AdminRoute
            path="/organizationdashboard"
            component={OrganizationDashboard}
          />
          <AdminRoute path="/leaderboardOrg" component={LeaderboardOrg} />

          <AdminRoute
            path="/employees-documents/diligence-allowance"
            component={DiligenceAllowancePage}
          />
          <AdminRoute
            path="/employees-documents/:type"
            component={PND91AND50tawi}
          />
          <AdminRoute
            path="/employees-summary-time"
            component={SummaryTimeAdminPage}
          />

          <AdminRoute
            exact
            path="/employees-report"
            component={All_ReportAdminPage}
          />

          {/* <AdminRoute
            exact
            path="/document-service"
            component={ReportAdminPage}
          /> */}
          <AdminRoute 
          exact 
          path="/employees-report/leave" 
          component={LeavePage} 
          />
          <AdminRoute exact 
          path="/employees-report/late-absent" 
          component={late_absent} 
          />
          <AdminRoute
            exact
            path="/employees-report/cost-element"
            component={CostElement}
          />
          <AdminRoute
            exact
            path="/employees-report/payroll"
            component={Payroll}
          />

          <AdminRoute
            exact
            path="/employees-report/diligence-allowance"
            component={DiligenceAllowancePage}
          />
          <AdminRoute
            exact
            path="/admin/organization-chart"
            component={OrganizationChartNewPage}
          />
          <AdminRoute
            exact
            path="/admin/organization-chart-v2"
            component={OrganizationChartV2Page}
          />
          <AdminRoute
            exact
            path="/admin/organization-chart-v3"
            component={OrganizationChartV3Page}
          />
          <AdminRoute exact path="/manage/hunter/peer" component={PeerPage} />
          <AdminRoute exact path="/manage/hunter/dashboard" component={Dashboard} />
          <AdminRoute exact path="/manage/hunter/peer/form" component={AddManpowerFormPage} />
          <AdminRoute exact path="/hunter-manpower-interviewed" component={HunterInterviewPage} />
          <AdminRoute exact path="/hunter-manpower-sending" component={HunterHistorySending} />
          <AdminRoute exact path="/recruitment-interviewer" component={RecruitInterviewer} />

          {/* <TrainingRoute exact path="/courses/:id" component={CourseDetail} />
          <TrainingRoute exact path="/courses" component={CoursePage} /> */}

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </Container>
  );
};

export default Routers;
