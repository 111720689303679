import React, { useEffect, useState  } from "react";
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import { Avatar, Box, styled, TablePagination, Typography } from "@mui/material";
import TableTheme from "../../../shared/general/TableTheme";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { stringToColor } from "../../../../../utils";

var buddhistEra = require('dayjs/plugin/buddhistEra');
dayjs.extend(buddhistEra);

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
  "& .MuiFormControl-root-MuiTextField-root": {
    "& .MuiOutlinedInput-input": {
      color: "#000000"
    },
  },
  "& .MuiOutlinedInput-input": {
    color: "#000000"
  },
  "& .part-header": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .part-total": {
      textAlign: "right",
    }
  },
  "& .part-filter": {
    marginBottom: 24,
    "& .search-name": {
      width: 250,
    }
  },
  "& .part-action": {
    display: "flex",
    justifyContent: "right",
  },
  "& .btn-save": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .CandidateSuccess":{
    animation: "CandidateSuccess 0.8s 5",
    animationFillMode: "forwards"
  },
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      color: "#ffffff",
      backgroundColor: "#800080",
      "&:last-of-type": {
        borderTopRightRadius: 8,
        /* borderBottomRightRadius: 8, */
      },
    },
    "& .StickyHeaderColumn": {
      zIndex: 5,
      boxShadow: "rgb(238 238 238) ",
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  }
})

const columns = [
  {
    field: "name",
    headerName: "ชื่อ-สกุล",
    width: 250,
    classname: (params) => { return !params.isRead ? "CandidateSuccess" : null; },
    format: (params) => (
      <Box 
        className="column-name"
        component={Link}
        to={`/hunter/peer/profile/${params.idManpower}`} 
        style={{ textDecoration:"none", color:"black" }}
      >
        <Avatar sx={{bgcolor: stringToColor(`${params.name} ${params.lastname}`)}} children={`${params.name[0]}${params.lastname[0]}`}/>
        <Typography sx={{color:"#000000"}}>{params && `${params.name} ${params.lastname}` || "-"}</Typography>
      </Box>
    )
  },
  {
    field: "departmentName",
    headerName: "หน่วยงาน",
    width: 250,
    classname: (params) => { return !params.isRead ? "CandidateSuccess" : null; },
    format: (params) => (
      <Box>
        <Typography sx={{color:"#000000"}}>{params && params.departmentName || "ไม่พบชื่อหน่วยงาน"}</Typography>
      </Box>
    )
  },
  {
    field: "postionName",
    headerName: "ตำแหน่งงาน",
    width: 150,
    classname: (params) => { return !params.isRead ? "CandidateSuccess" : null; },
    format: (params) => (
      <Box>
        <Typography sx={{color:"#000000"}}>{params && params.positionName || "ไม่พบชื่อตำแหน่งงาน"}</Typography>
      </Box>
    )
  },
  {
    field: "workDuration",
    headerName: "เริ่มงาน",
    width: 200,
    classname: (params) => { return !params.isRead ? "CandidateSuccess" : null; },
    format: (params) => (
      <Box>
        <Typography sx={{color:"#000000"}}>{`${dayjs(params.workStartDate).format("D MMM BBBB")}`}</Typography>
      </Box>
    )
  }
]

const SentManpower = (props) => {
  const { candidate } = props;
  const [filterCandidates, setFilterCandidates] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if(candidate){
      setFilterCandidates([...candidate.filter((can) => can.name.includes(search) || can.lastname.includes(search))]);
    }
  },[candidate, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return(
    <StyledRoot className="page">
      <div className="header-container">
        <Typography variant="h4" id="Candidate" sx={{color:"#000000"}}>รายชื่อผู้ถูกส่งเข้าทำงาน</Typography>
      </div>
      <div className="part-filter">
        <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
          className="search-name" 
          placeholder="ค้นหาชื่อ..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <TableTheme
        columns={columns}
        rows={filterCandidates}
        textEmpty={"ไม่พบรายชื่อพนักงาน"}
      />
      <TablePagination
        rowsPerPageOptions={[2, 10, 25, 100]}
        component="div"
        count={filterCandidates.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledRoot>
  )
}

export default SentManpower;