import React, { useState } from "react";
import { styled, Tab, Tabs } from "@mui/material";
import CardStyle from "../../shared/general/Card";

import BankPanel from "./bankPanel";
import TabCompany from "./tap-general";
import TabAddress from "./tabAddress";

const StyledRoot = styled("div")({
  padding: 24,
  "& .MuiTabs-root": {
    paddingBottom: 24,
  }
})

const RightPanel = (props) => {

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  return(
    <CardStyle>
      <StyledRoot>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="ข้อมูลบริษัท"></Tab>
          <Tab label="ที่อยู่"></Tab>
          <Tab label="ธนาคาร"></Tab>
        </Tabs>

        {tabValue === 0 && <TabCompany />}
        {tabValue === 1 && <TabAddress />}
        {tabValue === 2 && <BankPanel />}
      </StyledRoot>
    </CardStyle>
  )
}

export default RightPanel;