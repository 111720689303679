import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Box,
  TextField,
  Container,
} from "@mui/material";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import {
  getSummaryTimeByIdEmp,
} from "../../../../actions/summary-time";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";

import TableList from "./table";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 8,
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
  },
});

const SummaryTimePage = () => {
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: summaryTimeIndividual } = useSelector(
    (state) => state.summaryTime
  );
  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1).format("YYYY-MM-DD"),
    end: dayjs(today)
      .set("date", dayjs(today).daysInMonth())
      .format("YYYY-MM-DD"),
  });

  useEffect(() => {
    dispatch(getSummaryTimeByIdEmp(search));
  }, []);

  const handleClickSearch = () => {
    dispatch(getSummaryTimeByIdEmp(search));
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          สรุป OT และค่ากะ
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                วันที่เริ่มต้น
              </Typography>
              <div className="search-date">
                {localStorage.getItem("language") === "en" ? (
                  <DatePickerEng
                    inputFormat="D MMM YYYY"
                    value={search.start}
                    name="start"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["start"]: newValue });
                    }}
                  />
                ) : (
                  <DatePickerThai
                    inputFormat="d MMM yyyy"
                    value={search.start}
                    name="start"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["start"]: newValue });
                    }}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                วันที่สิ้นสุด
              </Typography>
              <div className="search-date">
                {localStorage.getItem("language") === "en" ? (
                  <DatePickerEng
                    inputFormat="D MMM YYYY"
                    value={search.end}
                    name="end"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["end"]: newValue });
                    }}
                  />
                ) : (
                  <DatePickerThai
                    inputFormat="d MMM yyyy"
                    value={search.end}
                    name="end"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["end"]: newValue });
                    }}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={3}>
            <StyledBoxSearch>
              <div className="wrap-search-action">
                <ButtonBlue
                  startIcon={<SearchRoundedIcon />}
                  variant="contained"
                  onClick={handleClickSearch}
                >
                  ค้นหา
                </ButtonBlue>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
        <TableList />
      </Container>
    </StyledRoot>
  );
};

export default SummaryTimePage;
