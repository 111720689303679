import { httpClient } from "./httpClient";


const allPayTypes = () => {
  return httpClient.get("paytypes");
};

const allAdditions = () => {
  return httpClient.get("paytypes/additions");
};

const getPayrollSetting = () => {
  return httpClient.get("payroll-setting");
};

const updatePayrollSetting = (idPayrollSetting, formData) => {
  return httpClient.put(`payroll-setting/${idPayrollSetting}`, formData);
};

const updatePayrollPayment = (idPayrollPayment, formData) => {
  return httpClient.put(`payroll-payment/${idPayrollPayment}`, formData);
};

const addPaytypes = (formData) => {
  return httpClient.post(`paytypes`, formData);
};


export default {
  allPayTypes,
  allAdditions,
  getPayrollSetting,
  updatePayrollSetting,
  addPaytypes,
  updatePayrollPayment
};
