import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import Paper from '@mui/material/Paper';
import { styled } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getTerminateEmployeeByReviewer } from "../../../../../actions/offBoarding";
import SnackBarCustom from "../../../shared/snackbarCustom";
import Dialog from "./Dialog";

const StyledWrapBranch = styled("div")({
    marginBottom: 36,
    "& .wrap-branch-top": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 16,
        "& .MuiTypography-body2": {
            margin: 0,
        },
    },
    "& .branch-scroll": {
        height: 280,
        overflow: "auto",
    },
    "& .action": {
        textAlign: "right",
        "& .fal": {
            fontSize: 18,
        },
    },
    "& .address": {
        fontWeight: 600,
        fontSize: 18,
    },
    "& .contact": {
        "& .fal": {
            marginRight: 8,
        },
    },
});

const OffBoarding = () => {
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: terminateEmployee } = useSelector((state) => state.terminateEmployee);
    const [openDialog, setOpenDialog] = useState(false);
    const [formData, setFormData] = useState(null);
    const [apprveList, setApproveList] = useState(null)
    const [apprveHistory, setApprveHistory] = useState(null)
    const [snackBarConfig, setSnackBarConfig] = useState({
        open: false,
        message: "",
        type: "success",
    })

    useEffect(() => {
        if (userProfile)
            dispatch(getTerminateEmployeeByReviewer(userProfile.idEmployees));
    }, [userProfile]);

    useEffect(() => {
        if (terminateEmployee) {
            setApproveList(terminateEmployee.filter((item) => {
                return item.isApprove == 0
            }))
            setApprveHistory(terminateEmployee.filter((item) => {
                return item.isApprove == 1
            }))
        }

    }, [terminateEmployee])

    const handleOpenDialog = (data) => {
        setOpenDialog(true)
        setFormData(data);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setFormData(null)
    };

    return (
        <div>
            <StyledWrapBranch>
                <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />
                {terminateEmployee && terminateEmployee.length > 0 ? (
                    <div>
                        {apprveList && apprveList.length > 0 &&
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">ชื่อพนักงาน</TableCell>
                                            <TableCell align="center">ตำแหน่ง</TableCell>
                                            <TableCell align="center">บริษัท</TableCell>
                                            <TableCell align="center">ส่วน</TableCell>
                                            <TableCell align="center">แผนก</TableCell>
                                            <TableCell align="center">ชื่อรายการ</TableCell>
                                            <TableCell align="center">วันที่</TableCell>
                                            <TableCell align="center">สถานะ</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {apprveList.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{`${row.reviewer_firstname_TH}  ${row.reviewer_lastname_TH}`} </TableCell>
                                                <TableCell align="center">{row.positionName} </TableCell>
                                                <TableCell align="center">{row.companyName} </TableCell>
                                                <TableCell align="center">{row.departmentName} </TableCell>
                                                <TableCell align="center">{row.sectionName} </TableCell>
                                                <TableCell align="center">{row.TerminateListName} </TableCell>
                                                <TableCell align="center">{dayjs(row.updateDate).format("DD/MM/YYYY HH:mm")}</TableCell>
                                                <TableCell align="center">{
                                                    <CancelOutlinedIcon style={{ color: 'red' }}
                                                        onClick={() => handleOpenDialog(row)} />
                                                }</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                        {apprveHistory && apprveHistory.length > 0 &&
                            <div>
                                <Typography sx={{pt:2, pb:2}}>ประวัติการอนุมัติ</Typography>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 600 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">ชื่อพนักงาน</TableCell>
                                                <TableCell align="center">ตำแหน่ง</TableCell>
                                                <TableCell align="center">บริษัท</TableCell>
                                                <TableCell align="center">ส่วน</TableCell>
                                                <TableCell align="center">แผนก</TableCell>
                                                <TableCell align="center">ชื่อรายการ</TableCell>
                                                <TableCell align="center">วันที่</TableCell>
                                                <TableCell align="center">สถานะ</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {apprveHistory.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{`${row.reviewer_firstname_TH}  ${row.reviewer_lastname_TH}`} </TableCell>
                                                    <TableCell align="center">{row.positionName} </TableCell>
                                                    <TableCell align="center">{row.companyName} </TableCell>
                                                    <TableCell align="center">{row.departmentName} </TableCell>
                                                    <TableCell align="center">{row.sectionName} </TableCell>
                                                    <TableCell align="center">{row.TerminateListName} </TableCell>
                                                    <TableCell align="center">{dayjs(row.updateDate).format("DD/MM/YYYY HH:mm")}</TableCell>
                                                    <TableCell align="center">{
                                                        <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                                    }</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        }
                    </div>
                ) : ("ไม่พบรายการ"
                )}
            </StyledWrapBranch>

            {openDialog && (
                <Dialog
                    open={openDialog}
                    data={formData}
                    handleClose={handleClose}
                    setSnackBarConfig={setSnackBarConfig}
                />
            )}
        </div>
    );
};

export default OffBoarding;
