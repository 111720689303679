import React, { Fragment, useEffect, useState } from "react";
import { Box, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, SvgIcon, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import Loading from "../../../../../shared/loading";
import { useDispatch } from "react-redux";
import { getOrganizationObjectiveByIdOrganizationObjective, getTeamObjectiveByIdTeamObjective } from "../../../../../../../actions/okrs";

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import dayjs from "dayjs";
import ChipStatus from "../../../../../shared/pages/okrs/components/ChipStatus";
import { AccessTimeRounded, StarRounded } from "@mui/icons-material";
import NightlightIcon from '@mui/icons-material/Nightlight';
import { ReactComponent as MoonshotIcon } from "../../../../../assets/moonshot_logo.svg";


const StyledRoot = styled(Box)({
  "& .objective-information-container": {
    padding: "16px",
    "& .objective-name": {
      fontSize: "20px",
      fontWeight: "500",
    },
    "& .objective-description": {
      paddingTop: "16px",
      fontSize: "14px",
    },
    "& .impact-header": {
      paddingRight: "8px",
      color: "#919eab",
    }
  },
  "& .key-result-topic-container": {
    margin: "16px 0",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "20px"
    }
  },
  "& .key-result-wrap": {
    // marginBottom: "24px",
    height: "100%"
  },
  "& .key-result-wrap:last-child": {
    marginBottom: "0",
  },
  "& .key-result-list-container": {
    "& .key-result-item-container": {
      padding: "16px",
      "& .key-result-name": {
        flexGrow: 1,
        fontWeight: "500",
      },
      "& .key-result-description": {
        paddingTop: "16px",
        fontSize: "14px",
      },
      "& .due-date-container": {
        paddingTop: "16px",
        display: "flex",
        alignItems: "center",
        color: "#919eab",
        fontSize: "14px",
        "& .MuiSvgIcon-root": {
          marginRight: "8px",
        },
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          fontSize: "inherit",
          color: "inherit"
        }
      },
      "& .MuiDivider-root": {
        paddingTop: "16px"
      },
      "& .manager-label": {
        paddingRight: "8px",
        color: "#919eab",
      }
    }
  }
})

const KeyResult = (props) => {
  
  const { data: values } = props;

  const [data, setData] = useState(values)

  const [dialogConfig, setDialogConfig] = useState({
    isOpen: false,
  })

  const getStatusApprove = (status) => {
    if(status === null){
      return 1
    } else if(status === 1){
      return 2
    } else if(status === 0){
      return 3
    } else {
      return 1
    }
  }

  const getStatusAchieveApprove = (status) => {
    if(status === 1){
      return 5
    } else if(status === 0){
      return 6
    } else {
      return 4
    }
  }

  return (
    <CardStyle style={{height: "100%"}}>
      <Box padding="24px">
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Typography fontSize="20px">{data.keyResultName}</Typography>
          <Box display="flex" justifyContent="space-between" gap="8px">
            {/* {data.isApprove !== 1 && <ChipStatus status={getStatusApprove(data.isApprove)} size="large"/>} */}
            <ChipStatus status={getStatusAchieveApprove(data.isAchieve)} size="large"/>
            {/* {data.isApprove === 1 && data.isAchieve !== null && data.isAchieveApprove === null && (<ChipStatus status={7} size="large"/>)} */}
          </Box>
        </Box>
        {(data.isMoonShotPlanning === 1 || data.isMoonShotEvaluation === 1 || data.rating !== null) && <Box display="flex" marginTop="8px" gap="8px" alignItems="center">
          {data.isMoonShotPlanning === 1 && <SvgIcon>
            <MoonshotIcon />
          </SvgIcon>}
          {data.isMoonShotEvaluation === 1 && <SvgIcon>
            <MoonshotIcon />
          </SvgIcon>}
          {data.rating !== null && data.isAchieveApprove !== null && (
            <Chip
              icon={<StarRounded />}
              label={Math.round(data.rating * 100) / 100}
              sx={{
                backgroundColor: "#fff9db",
                "& .MuiSvgIcon-root": {
                  color: "rgb(255, 221, 64)",
                },
                "& .MuiChip-label": {
                  lineHeight: 1,
                  fontWeight: "500",
                }
              }}
            />
          )}
        </Box>}
        <Box display="flex" alignItems="center" marginTop="16px">
          <AccessTimeRounded fontSize="14px" style={{marginRight: "8px", color: "#919eab"}}/>
          <Typography fontSize="14px" lineHeight="1" color="text.third">{`${dayjs(data.startDate).format("D MMM")} - ${dayjs(data.endDate).format("D MMM BB")}`}</Typography>
        </Box>
        <Box marginTop="16px">
          <Typography fontSize="14px"><span style={{color: "#919eab", paddingRight: "8px"}}>กลยุทธ์</span>{data.tacticName}</Typography>
          <Typography fontSize="14px" paddingTop="16px"><span style={{color: "#919eab", paddingRight: "8px"}}>ข้อมูลที่ใช้วัด</span>{data.measureEvidence}</Typography>
          <Typography fontSize="14px" paddingTop="16px"><span style={{color: "#919eab", paddingRight: "8px"}}>เป้าหมายของข้อมูลที่ใช้วัด</span>{data.commitQuality}</Typography>
          <Typography fontSize="14px" paddingTop="16px"><span style={{color: "#919eab", paddingRight: "8px"}}>ประเภทข้อมูลที่ใช้วัด</span>{data.measureDataType === 1? "ตัวเลข": "คำบรรยาย"}</Typography>
          {data.description && <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line"><span style={{color: "#919eab", paddingRight: "8px"}}>รายละเอียด</span>{data.description}</Typography>}
          {data.crossTeam.length > 0 && <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line"><span style={{color: "#919eab", paddingRight: "8px"}}>การร่วมมือ</span>{data.crossTeam.map(e => e.departmentName).join(", ")}</Typography>}
        </Box>
        {/* <Divider style={{marginTop: "16px"}} />
        <Box marginTop="16px">
          <Typography fontSize="14px"><span style={{color: "#919eab", paddingRight: "8px"}}>ผู้อนุัมัติ</span>{`${data.manager.firstname_TH} ${data.manager.lastname_TH}`}</Typography>
        </Box> */}
      </Box>
    </CardStyle>
  )
}

const OrganizationInformationPage = (props) => {

  const { match } = props;

  const dispatch = useDispatch();

  const [individualObjective, setIndividualObjective] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    dispatch(getTeamObjectiveByIdTeamObjective(match.params.idTeamObjective)).then(res => {
      setIsFetching(false);
      if(res.status === 200){
        setIndividualObjective(res.data)
      } else {
        setIsError(true);
      }
    })
  }, [])

  if(isError){
    return (
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Typography textAlign="center" fontSize="32px" paddingTop="24px">ขออภัย ไม่พบข้อมูล</Typography>
        </Container>
      </StyledRoot>
    )
  }

  if(isFetching){
    return (
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Box display="flex" justifyContent="center" paddingTop="24px">
            <Loading />
          </Box>
        </Container>
      </StyledRoot>
    )
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {individualObjective && <Fragment>
          <CardStyle>
            <Box padding="24px">
              <Typography fontSize="24px">{individualObjective.year + 543}, Q{individualObjective.quarter}</Typography>
              <Typography fontSize="20px" paddingTop="16px">{individualObjective.objectiveName}</Typography>
              <Typography fontSize="14px" paddingTop="16px"><span style={{color: "#919eab", paddingRight: "8px"}}>ผลลัพธ์ที่เกิดขึ้น</span>{individualObjective.impactName}</Typography>
              {individualObjective.description && <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line"><span style={{color: "#919eab", paddingRight: "8px"}}>รายละเอียด</span>{individualObjective.description}</Typography>}
            </Box>
          </CardStyle>

          <Box className="key-result-topic-container">
            <Typography>ผลลัพธ์หลัก</Typography>
            <Typography>{individualObjective.keyResults.length} รายการ</Typography>
          </Box>

          <Grid container spacing={2}>
            {individualObjective.keyResults.map((keyResult, index) => (
              <Grid key={index} item xs={12} md={4}>
                <Box className="key-result-wrap">
                  <KeyResult data={keyResult}/>
                </Box>
              </Grid>
            ))}
          </Grid>

          {/* <Box className="key-result-list-container">
            {
              individualObjective.keyResults.map(keyResult => (
                <Box className="key-result-wrap">
                  <KeyResult data={keyResult}/>
                </Box>
              ))
            }
          </Box> */}

        </Fragment>}
      </Container>
    </StyledRoot>
  )
}

export default OrganizationInformationPage;