import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import {
    Container,
    Grid,
    Typography,
    TextField,
    Box,
    Popper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import Backdrop from "../../shared/general/Backdrop";
import { getCostCenterAndCostElement, getDepartmentOFCostElement } from "../../../../actions/costElement";

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    paddingBottom: 48,
    "& .wrap-check, .wrap-leave": {
        marginTop: 36,
    },
    "& .wrap-check-in, .wrap-check-out, .leave": {
        borderRadius: 16,
        "& .head": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 24,
        },
    },
    "& .leave": {
        "& .filter": {
            padding: 24,
            display: "flex",
            justifyContent: "space-between",
        },
    },
});

const StyledBox = styled(Box)({
    "&.open": {
        paddingLeft: 16,
        width: "calc(100% - 1000px)",
    },
    "&.close": {
        paddingLeft: 0,
        width: "100%",
    },
});

const StyledWrapFilter = styled("div")({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media (min-width: 0px)"]: {
        flexDirection: "column",
    },
    ["@media (min-width: 900px)"]: {
        flexDirection: "row",
    },
    "& .search-name, .search-date": {
        width: "100%",
    },
    "& .wrap-search-action": {
        marginTop: 50,
        display: "flex",
        justifyContent: "flex-start",
        "& .btn-export": {
            marginLeft: 8,
        },
    },
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    "&.MuiTableRow-hover:hover": {
        backgroundColor: "#f7f7f7",
        "& .sticky": {
            backgroundColor: "#f7f7f7",
        },
    },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "0px",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    padding: 8,
    minWidth: 50,
    "& .MuiTypography-root": {
        color: "#637381",
        backgroundColor: "#f4f6f8",
        "&.weekend": {
            fontStyle: "oblique",
        },
        "&.workday": {
            fontWeight: 600,
        },
    },
    "&.sticky": {
        padding: 0,
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        boxShadow: "10px 0px 10px #EEEEEE",
        "& .MuiTableCell-root": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
    },
    "&:last-of-type": {
        paddingRight: 16,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px",
    padding: 8,
    minWidth: 50,
    "&.sticky": {
        position: "sticky",
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
        minWidth: 240,
        "& .MuiAvatar-root": {
            marginRight: 8,
        },
        display: "flex",
        alignItems: "center",
    },
    "& .fullname": {
        fontWeight: 600,
    },
    "& .position": {
        color: theme.palette.text.secondary,
        fontSize: 14,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 300,
    },
    "& .secondaryAction": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .location": {
            fontWeight: 600,
        },
    },
    "&:first-of-type": {
        paddingLeft: 8,
    },
    "&:last-of-type": {
        paddingRight: 16,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));


const CostElement = () => {

    const dispatch = useDispatch();
    const { result: costElement } = useSelector((state) => state.costElement);
    const { result: department } = useSelector((state) => state.department);

    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [data, setData] = useState(costElement);

    useEffect(() => {
        dispatch(getDepartmentOFCostElement())
        dispatch(getCostCenterAndCostElement())
        setData(costElement)
    }, [])

    useEffect(() => {
        if (!selectedDepartment && !selectedLevel) {
            setData(costElement)
        }
        if (selectedDepartment && selectedLevel) {
            setData(costElement.filter((item) => {
                return (item.departmentName === selectedDepartment &
                    item.level === selectedLevel)
            }))
        } else if (selectedDepartment) {
            setData(costElement.filter((item) => {
                return item.departmentName === selectedDepartment
            }))
        } else if (selectedLevel) {
            setData(costElement.filter((item) => {
                return item.level === selectedLevel
            }))
        } else setData(costElement)
    }, [selectedDepartment, selectedLevel, costElement])

    const onExportExcel = () => {
        const elementList = [];
        for (let index = 0; index < data.length; index++) {
            let element = {}
            element["#"] = index + 1
            element["หน่วยงาน"] = data[index].departmentName
            element["ระดับ"] = data[index].level
            element["ชื่อรายการ"] = data[index].listName
            element["cost center"] = data[index].costCenter
            element["cost element"] = data[index].costElement

            elementList.push(element);
        }

        const workSheet = XLSX.utils.json_to_sheet(elementList);
        const workBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, "cost element");
        XLSX.writeFile(workBook, "cost element.xlsx");
    };

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <div style={{ marginBottom: 8 }}>
                    <Typography variant="h4" style={{ paddingTop: 8 }}>
                        COST ELEMENT
                    </Typography>
                </div>
                <StyledRoot>
                    <StyledWrapFilter>
                        <Grid container spacing={2} columns={14} alignItems="center">
                            {department && <Grid item xs={3} >
                                <StyledBoxSearch>
                                    <Typography className="label" color="text.third">
                                        หน่วยงาน
                                    </Typography>
                                    <div className="search-name">
                                        <StyledAutocomplete
                                            options={selectedLevel ? department.filter((item) => {
                                                return item.level === selectedLevel
                                            }) : department}
                                            onChange={(event, newValue) => {
                                                if (newValue) setSelectedDepartment(newValue.departmentName)
                                                else setSelectedDepartment(null)
                                            }}
                                            popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                            getOptionLabel={(option) => option.departmentName}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    placeholder={"หน่วยงานทั้งหมด"}
                                                />
                                            )}
                                            PopperComponent={StyledPopper}
                                            noOptionsText={"ไม่พบข้อมูล"}
                                        />
                                    </div>
                                </StyledBoxSearch>
                            </Grid>}
                            <Grid item xs={3} >
                                <StyledBoxSearch>
                                    <Typography className="label" color="text.third">
                                        ระดับ
                                    </Typography>
                                    <div className="search-name">
                                        <StyledAutocomplete
                                            options={["MDOffice", "BusinessUnit", "Division", "Department", "Section"]}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedLevel(newValue);
                                                } else {
                                                    setSelectedLevel(null);
                                                }
                                            }}
                                            popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    placeholder={"ระดับทั้งหมด"}
                                                />
                                            )}
                                            PopperComponent={StyledPopper}
                                            noOptionsText={"ไม่พบข้อมูล"}
                                        />
                                    </div>
                                </StyledBoxSearch>
                            </Grid>
                            <Grid item xs={2} >
                                <StyledBoxSearch>
                                    <div className="wrap-search-action">
                                        <div className="btn-export">
                                            <ButtonBlue
                                                variant="outlined"
                                                startIcon={<FileDownloadIcon />}
                                                onClick={onExportExcel}
                                                disabled={data === null}
                                            >
                                                ดาวน์โหลด
                                            </ButtonBlue>
                                        </div>
                                    </div>
                                </StyledBoxSearch>
                            </Grid>
                        </Grid>
                    </StyledWrapFilter>
                    <CardStyle>
                        {data ? (
                            data.length > 0 ? (
                                <Box style={{ padding: "24px" }}>
                                    <Box style={{ display: "flex", position: "relative" }}>
                                        <StyledBox className={"close"}>
                                            <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledCellHeader>
                                                                <Typography variant="body2" align="center">หน่วยงาน</Typography>
                                                            </StyledCellHeader>
                                                            <StyledCellHeader>
                                                                <Typography variant="body2" align="center">ระดับ</Typography>
                                                            </StyledCellHeader>
                                                            <StyledCellHeader>
                                                                <Typography variant="body2" align="center">ชื่อรายการ</Typography>
                                                            </StyledCellHeader>
                                                            <StyledCellHeader>
                                                                <Typography variant="body2" align="center">cost center</Typography>
                                                            </StyledCellHeader>
                                                            <StyledCellHeader>
                                                                <Typography variant="body2" align="center">cost element</Typography>
                                                            </StyledCellHeader>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.map((row) => (
                                                            <StyledRowContent>
                                                                <StyledCellContent>
                                                                    <Typography variant="body2" align="center">{row.departmentName}</Typography>
                                                                </StyledCellContent>
                                                                <StyledCellContent>
                                                                    <Typography variant="body2" align="center">{row.level}</Typography>
                                                                </StyledCellContent>
                                                                <StyledCellContent>
                                                                    <Typography variant="body2" align="center">{row.listName}</Typography>
                                                                </StyledCellContent>
                                                                <StyledCellContent>
                                                                    <Typography variant="body2" align="center">{row.costCenter}</Typography>
                                                                </StyledCellContent>
                                                                <StyledCellContent>
                                                                    <Typography variant="body2" align="center">{row.costElement}</Typography>
                                                                </StyledCellContent>
                                                            </StyledRowContent>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </StyledBox>
                                    </Box>
                                </Box>
                            ) :
                                <Typography color="text.secondary" align="center">
                                    ไม่มีข้อมูล
                                </Typography>
                        ) : (
                            <Backdrop open={true} />

                        )}
                    </CardStyle>
                </StyledRoot>

            </Container>
        </StyledRoot>
    )
}

export default CostElement;