import { httpClient } from "./httpClient";

const getAllBanks = () => {
  return httpClient.get(`/banks`);
};

const getBankByBankDetail = (idBankDetail) => {
  return httpClient.get(`/bank/${idBankDetail}`);
};

const updateBankDetail = (data) => {
  return httpClient.post(`/company/bank-detail`,data);
};

const getBankDetailByCompany = () => {
  return httpClient.get(`company/bank-detail`);
};

export default {
  getAllBanks,
  getBankByBankDetail,
  updateBankDetail,
  getBankDetailByCompany
};