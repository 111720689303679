import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Box,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { getManager } from "../../../../actions/manager";

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  width: "330px !important",

  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) => (option.firstname_TH + option.lastname_TH + option.positionName),
});

const filterOptionsName = createFilterOptions({
  stringify: (option) => option.firstname_TH + option.lastname_TH,
});

const SelectManagers = (props) => {
  let { handleChange, position } = props;
  const dispatch = useDispatch();
  const { result: managers } = useSelector((state) => state.manager);
  
  useEffect(() => {
    dispatch(getManager());
  }, []);


  return (
    <StyledBoxSearch>
      <Typography className="label" color="text.third">
        ค้นหาหัวหน้างาน
      </Typography>
      <StyledAutocomplete
        options={
            managers
            ? managers.sort((a, b) =>
              a.positionName && a.positionName.localeCompare(b.positionName)
              )
            : []
        }
        onChange={(_, newValue) => {
          handleChange(newValue);
        }}
        filterOptions={position ? filterOptions : filterOptionsName}
        groupBy={position ? (option) => option.positionName : null}
        closeIcon={null}
        popupIcon={<i className="fa-light fa-chevron-down"></i>}
        getOptionLabel={(option) =>
          `${option.firstname_TH} ${option.lastname_TH}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder="เลือกหัวหน้างาน"
            InputProps={{ ...params.InputProps, endAdornment: null }}
          />
        )}
        PopperComponent={StyledPopper}
        noOptionsText={"ไม่พบข้อมูล"}
      />
    </StyledBoxSearch>
  );
};

export default SelectManagers;
