import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";

import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import {
  // updateUserNTable,
  updateUser,
  // uploadCriminalImage,
} from "../../../../../../actions/user";
import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .dropzone-company-profile": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    minHeight: 160,
    margin: "auto",
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
  "& .inner-dropzone-company-profile": {
    cursor: "pointer",
    zIndex: 0,
    width: "fit-content",
    height: "100%",
    outline: "none",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: 8,
    position: "relative",
    "& .placeholder": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .uploadImage": {
    width: "100%",
    height: "fit-content",
    zIndex: 8,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyleSelect = styled(Select)({
  borderRadius: 8,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const Address = (props) => {
  const { handleChange, formData } = props;
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>บ้านเลขที่</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="houseNo"
          fullWidth
          onChange={handleChange}
          value={formData.houseNo}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>หมู่บ้าน</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="village"
          fullWidth
          onChange={handleChange}
          value={formData.village}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>หมู่ที่</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="villageNo"
          fullWidth
          onChange={handleChange}
          value={formData.villageNo}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ตรอก/ซอย</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="alley"
          fullWidth
          onChange={handleChange}
          value={formData.alley}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ถนน</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="road"
          fullWidth
          onChange={handleChange}
          value={formData.road}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ตำบล/แขวง</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="subDistrict"
          fullWidth
          onChange={handleChange}
          value={formData.subDistrict}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>อำเภอ</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="district"
          fullWidth
          onChange={handleChange}
          value={formData.district}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>จังหวัด</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="provience"
          fullWidth
          onChange={handleChange}
          value={formData.provience}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>รหัสไปรษณีย์</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="areaCode"
          fullWidth
          onChange={handleChange}
          value={formData.areaCode}
        />
      </Grid>
    </Grid>
  );
};

const Contact = (props) => {
  const { handleChange, formData } = props;
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ชื่อผู้ติดต่อ</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="emergencyContact"
          fullWidth
          onChange={handleChange}
          value={formData.emergencyContact}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>เบอร์ผู้ติดต่อ</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="emergencyPhone"
          fullWidth
          onChange={handleChange}
          value={formData.emergencyPhone}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ความสัมพันธ์</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="emergencyRelationship"
          fullWidth
          onChange={handleChange}
          value={formData.emergencyRelationship}
        />
      </Grid>
    </Grid>
  );
};

const PersonalDetail = (props) => {
  const { handleChange, formData } = props;
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>Passport ID</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="passportNumber"
          fullWidth
          onChange={handleChange}
          value={formData.passportNumber}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>สถานะ</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="maritalStatus"
            value={formData.maritalStatus}
            onChange={handleChange}
          >
            <MenuItem value="โสด">โสด/Single</MenuItem>
            <MenuItem value="สมรส">สมรส/Married</MenuItem>
            <MenuItem value="หม้าย">หม้าย/Widowed</MenuItem>
            <MenuItem value="หย่า">หย่า/Divorced</MenuItem>
            {/* <MenuItem value="แยกกันอยู่">แยกกันอยู่/Separated</MenuItem> */}
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>สัญชาติ</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="nationality"
            value={formData.nationality}
            onChange={handleChange}
          >
            <MenuItem value="ไทย">ไทย</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>Work Permit</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="workPermitNumber"
          fullWidth
          onChange={handleChange}
          value={formData.workPermitNumber}
        />
      </Grid>
    </Grid>
  );
};

const CriminalImage = (props) => {
  const { getRootProps, getInputProps, thumbs, formData } = props;

  return (
    <Fragment>
      <div {...getRootProps({ className: "dropzone-company-profile" })}>
        <div className="inner-dropzone-company-profile">
          <input {...getInputProps()} />
          <Fragment>{thumbs()}</Fragment>
          <div
            className={`placeholder ${
              ((formData.criminalImage && formData.criminalImage.length !==0) ||
                formData.criminalImage !==null) &&
              `placeholderImageProfile`
            }`}
          >
            <AddAPhotoIcon />
            <Typography
              style={{ marginTop: 8, backgroundColor: "transparent" }}
              className={`${formData.criminalImage !==0 && `placeholderLabel`}`}
              variant="body2"
            >
              Upload Photo
            </Typography>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [formData, setFormData] = useState({
    houseNo: "",
    village: "",
    villageNo: "",
    alley: "",
    road: "",
    subDistrict: "",
    district: "",
    provience: "",
    areaCode: "",
    emergencyContac: "",
    emergencyPhone: "",
    emergencyRelationship: "",
    passportNumber: "",
    maritalStatus: "Single",
    nationality: "Thailand",
    workPermitNumber: "",
    criminalImage: [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (open) {
      if (mode === "address") {
        setFormData({
          houseNo: employeeProfile.houseNo,
          village: employeeProfile.village,
          villageNo: employeeProfile.villageNo,
          alley: employeeProfile.alley,
          road: employeeProfile.road,
          subDistrict: employeeProfile.subDistrict,
          district: employeeProfile.district,
          provience: employeeProfile.provience,
          areaCode: employeeProfile.areaCode,
        });
      } else if (mode === "emergency") {
        setFormData({
          emergencyContact: employeeProfile.emergencyContact,
          emergencyPhone: employeeProfile.emergencyPhone,
          emergencyRelationship: employeeProfile.emergencyRelationship,
        });
      } else if (mode === "personalDetail") {
        setFormData({
          passportNumber: employeeProfile.passportNumber,
          maritalStatus:
            employeeProfile.maritalStatus || formData.maritalStatus,
          nationality: employeeProfile.nationality || formData.nationality,
          workPermitNumber: employeeProfile.workPermitNumber,
        });
      }
    }
  }, [open]);

  const handleSubmit = async () => {
    // if (
    //   mode === "address" ||
    //   mode === "personalDetail" ||
    //   mode === "emergency"
    // ) {
      formData.idEmployees = employeeProfile.idEmployees;
      const result = await dispatch(updateUser(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      }
    // } else if (mode === "criminalImage") {
    //   const result = await dispatch(
    //     uploadCriminalImage(employeeProfile, formData.criminalImage)
    //   );
    //   if (result) {
    //     handleCloseDialog();
    //     dispatch(
    //       getEmployeeProfile(userProfile.idCompany, employeeProfile.idEmp)
    //     );
    //   }
    // } else {
    //   const result = await dispatch(updateUser(formData));
    //   if (result) {
    //     handleCloseDialog();
    //     dispatch(
    //       getEmployeeProfile(userProfile.idCompany, employeeProfile.idEmp)
    //     );
    //   }
    // }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formDataImage = new FormData();
      acceptedFiles.map((file) => formDataImage.append("file", file));
      setFormData({
        ...formData,
        criminalImage: acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      });
    },
    maxFiles: 1,
  });

  const thumbs = () => {
    if (formData.criminalImage && formData.criminalImage.length !== 0) {
      return (
        <img
          alt={formData.criminalImage[0].name}
          src={formData.criminalImage[0].preview}
          className={`uploadImage`}
        />
      );
    }
  };

  const titleDrawer = (mode) => {
    if (mode === "address") {
      return "แก้ไขที่อยู่ปัจจุบัน";
    } else if (mode === "emergency") {
      return "แก้ไขผู้ติดต่อฉุกเฉิน";
    } else if (mode === "personalDetail") {
      return "แก้ไขข้อมูลส่วนตัว";
    } else {
      return "แก้ไขประวัติอาชญากรรม";
    }
  };

  return (
    <DrawerCustom
      title={titleDrawer(mode)}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {mode === "address" && (
          <Address handleChange={handleChange} formData={formData} />
        )}
        {mode === "emergency" && (
          <Contact handleChange={handleChange} formData={formData} />
        )}
        {mode === "personalDetail" && (
          <PersonalDetail handleChange={handleChange} formData={formData} />
        )}
        {mode === "criminalImage" && (
          <CriminalImage
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            thumbs={thumbs}
            handleChange={handleChange}
            formData={formData}
          />
        )}
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
