import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Autocomplete, Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../../../../actions/family";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import { getWorkInfoByidCompany } from "../../../../../../actions/company";
import { updateUser } from "../../../../../../actions/user";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px"
  }
})

const DialogWorkingLocation = (props) => {

  const dispatch = useDispatch();

  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: workTypesList } = useSelector((state) => state.workTypes);

  const validateYupSchema = yup.object({
    employmentTime: yup.string().required("กรุณากรอกข้อมูล"),
    filed_office: yup.string().required("กรุณากรอกข้อมูล"),
    workingLocation: yup.string().required("กรุณากรอกข้อมูล"),
    methodAttendance: yup.string().required("กรุณากรอกข้อมูล"),
    isFinger: yup.string().required("กรุณากรอกข้อมูล"),
    isFingerAffectPayroll: yup.string().required("กรุณากรอกข้อมูล"),
    mainWorkingLocationPoint: yup.string().when(
      "methodAttendance", {
        is: "main",
        then: yup.string().required("กรุณากรอกข้อมูล")
      }
    ),
    // birthday: yup.date().nullable().required("กรุณากรอกข้อมูล"),
  })

  const useHookForm = useForm({
    defaultValues: {
      employmentTime: "",
      filed_office: "",
      workingLocation: "",
      methodAttendance: "",
      mainWorkingLocationPoint: "",
      isFinger: "1",
      isFingerAffectPayroll: "1",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  useEffect(() => {
    if(drawerConfig.isOpen === true){
      dispatch(getWorkInfoByidCompany());
      if(drawerConfig.isEdit){
        useHookForm.reset({
          employmentTime: drawerConfig.data.employmentTime || "",
          filed_office: drawerConfig.data.filed_office || "",
          workingLocation: drawerConfig.data.workingLocation || "",
          methodAttendance: drawerConfig.data.methodAttendance || "",
          mainWorkingLocationPoint: drawerConfig.data.mainWorkingLocationPoint || "",
          isFinger: drawerConfig.data.isFinger === null? "": String(drawerConfig.data.isFinger),
          isFingerAffectPayroll: drawerConfig.data.isFingerAffectPayroll === null? "": String(drawerConfig.data.isFingerAffectPayroll),
        })
      } else {
        useHookForm.reset({
          employmentTime: "",
          filed_office: "",
          workingLocation: "",
          methodAttendance: "",
          mainWorkingLocationPoint: "",
          isFinger: "",
          isFingerAffectPayroll: "",
        })
      }
    }
  }, [drawerConfig.isOpen])

  const onSubmit = async (data) => {
    // console.log(data)
    
    const formData = {
      ...data,
      idEmployees: employeeProfile.idEmployees,
      UpdateBy: userProfile.idEmployees,
      updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss")
    }

    Object.keys(formData).map(k => {
      if(formData[k] === ""){
        formData[k] = null
      } else {
        formData[k] = formData[k]
      }
    })

    // formData.birthday = dayjs(formData.birthday).format("YYYY-MM-DD")

    // if(drawerConfig.isEdit){
    //   formData.idFamily = drawerConfig.data.idFamily;
    //   formData.updateDate = dayjs().format("YYYY-MM-DD HH:mm:ss")
    // } else {
    //   formData.isApprove = 1;
    //   formData.idAdmin = userProfile.idEmployees;
    //   formData.approveDate = dayjs().format("YYYY-MM-DD HH:mm:ss")
    // }

    const result = await dispatch(updateUser(formData))
    // const result = {status: 200}

    // console.log(result)
    
    if(result){
      if(result.status === 200){
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true))
        onClose();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      // }))
    }
  }

  return (
    <DrawerCustom
      title={"แก้ไขข้อมูลงาน"}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">รูปแบบงาน</Typography>
              <Controller
                name="employmentTime"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  >
                    <MenuItem value="Full time">Full time</MenuItem>
                    <MenuItem value="Part time">Part time</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">ประเภทงาน</Typography>
              <Controller
                name="filed_office"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  >
                    <MenuItem value="Field">Field</MenuItem>
                    <MenuItem value="Office">Office</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="field-label">จังหวัดที่ทำงาน</Typography>
              <Controller
                name="workingLocation"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">วิธีการบันทึกเวลา</Typography>
              <Controller
                name="methodAttendance"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    select
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      useHookForm.setValue("mainWorkingLocationPoint", "");
                    }}
                  >
                    <MenuItem value="main">จุดทำงานหลักที่เดียว</MenuItem>
                    <MenuItem value="all">ทุกจุดทำงานที่บริษัทกำหนด</MenuItem>
                    <MenuItem value="point">ทุกสถานที่ (จับพิกัด)</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            {useHookForm.watch("methodAttendance") === "main" && <Grid item xs={12}>
              <Typography className="field-label">ที่ทำงานหลัก</Typography>
              <Controller
                name="mainWorkingLocationPoint"
                control={useHookForm.control}
                render={({field}) => (
                  <Autocomplete
                    options={workTypesList && workTypesList.groupGpsLocations? workTypesList.groupGpsLocations: []}
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idGroupGpsLocations}>
                        <Box>
                          <Typography>{`${option.name}`}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        onBlur={field.onBlur}
                        error={useHookForm.formState.errors[field.name]? true: false}
                        helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      />
                    )}
                    value={workTypesList && workTypesList.groupGpsLocations && workTypesList.groupGpsLocations.find(option => option.idGroupGpsLocations === field.value) || null}
                    onChange={(_, value) => {field.onChange(value? value.idGroupGpsLocations: "")}}
                  />
                )}
              />
            </Grid>}
            <Grid item xs={12}>
              <Typography className="field-label">การบันทึกเวลา</Typography>
              <Controller
                name="isFinger"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    select
                  >
                    <MenuItem value="1">ใช่</MenuItem>
                    <MenuItem value="0">ไม่ใช่</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Typography className="field-label">การบันทึกเวลามีผลต่อเงินเดือน</Typography>
              <Controller
                name="isFingerAffectPayroll"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    select
                  >
                    <MenuItem value="1">ใช่</MenuItem>
                    <MenuItem value="0">ไม่ใช่</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid> */}
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>บันทึก</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DialogWorkingLocation;