import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import {
    Typography, IconButton, Divider, TextField, Grid
} from '@mui/material';

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import { putOverView } from '../../../../../actions/employee';

const Overview = ({ employee }) => {
    const dispatch = useDispatch();
    const [overviewEdit, setOverviewEdit] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const { register, handleSubmit, setValue } = useForm({});

    useEffect(()=>{
        setValue("overviewInput", employee.overview);
    },[employee])

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleOpenOverviewEdit = () => {
        setOverviewEdit(true);
    };

    const handleCloseOverviewEdit = () => {
        setValue("overviewInput", employee.overview);
        setOverviewEdit(false);
    };

    const handleSubmitOverviewEdit = async (data) => {
        const postData = {
            overview: data.overviewInput
        }
        const result = await dispatch(putOverView(employee.idEmployees, postData));
        if (result.status === 200) {
            setOverviewEdit(false);
        } else {
            setOpenAlert(true);
            setOverviewEdit(false);
        };
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" gutterBottom>Overview</Typography>
                {!overviewEdit &&
                    <IconButton size="small" onClick={handleOpenOverviewEdit}>
                        <EditIcon />
                    </IconButton>
                }
            </div>
            <Divider />
            <form onSubmit={handleSubmit(handleSubmitOverviewEdit)}>
                <TextField
                    inputProps={{ ...register("overviewInput") }}
                    disabled={!overviewEdit}
                    variant={overviewEdit ? "outlined" : "standard"}
                    margin="dense"
                    placeholder="-"
                    multiline
                    fullWidth
                />
                {overviewEdit && (
                    <>
                        <Divider />
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item>
                                <ButtonBlue
                                    value={"Cancel"}
                                    variant={"outlined"}
                                    onClick={handleCloseOverviewEdit}
                                >
                                    ยกเลิก
                                </ButtonBlue>
                            </Grid>
                            <Grid item>
                                <ButtonBlue
                                    value={"Save"}
                                    variant={"contained"}
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                >
                                    บันทึก
                                </ButtonBlue>
                            </Grid>
                        </Grid>
                    </>
                )}
            </form>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
            />
        </div>
    );
};

export default Overview;