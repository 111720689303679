import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import {
  getTerminateEmployee,
  getTerminateEmployeeByID,
} from "../../../../../actions/offBoarding";
import More from "./viewmore";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StatusOffBoarding = () => {
  const dispatch = useDispatch();
  const { result: terminateEmployee } = useSelector(
    (state) => state.terminateEmployee
  );
  const [OpenMore, setOpenMore] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    dispatch(getTerminateEmployee());
  }, []);

  const handleOpenMore = (data) => {
    if (OpenMore === false || data !== formData) {
      setOpenMore(true);
      setFormData(data);
      dispatch(getTerminateEmployeeByID(data.idEmployees));
    } else handleClose();
  };

  const handleClose = () => {
    setOpenMore(false);
    setFormData(null);
  };

  return (
    <>
      <StyledWrapBranch>
        {terminateEmployee && terminateEmployee.length > 0 ? (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"> </TableCell>
                    <TableCell align="center">ชื่อ</TableCell>
                    <TableCell align="center">ตำแหน่ง</TableCell>
                    <TableCell align="center">หน่วยงาน</TableCell>
                    <TableCell align="center">สถานะ</TableCell>
                    <TableCell align="center">รายละเอียด</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {terminateEmployee.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1} </TableCell>
                      <TableCell align="center">
                        {`${row.firstname_TH}   ${row.lastname_TH}`}{" "}
                      </TableCell>
                      <TableCell align="center">{row.positionName} </TableCell>
                      <TableCell align="center">
                        {row.departmentName}{" "}
                      </TableCell>
                      <TableCell align="center">{`${row.status}  %`}</TableCell>
                      <TableCell align="center">
                        <ButtonBlue
                          size="small"
                          startIcon={<ExpandMoreIcon />}
                          onClick={() => handleOpenMore(row)}
                        ></ButtonBlue>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          "ไม่มีรายการ"
        )}
      </StyledWrapBranch>

      {OpenMore && <More data={formData} />}
    </>
  );
};

export default StatusOffBoarding;
