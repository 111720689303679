export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const STANDARD_MENU_FETCHING = "STANDARD_MENU_FETCHING";
export const STANDARD_MENU_FAILED = "STANDARD_MENU_FAILED";
export const STANDARD_MENU_SUCCESS = "STANDARD_MENU_SUCCESS";

export const USERS_FETCHING = "USERS_FETCHING";
export const USERS_FAILED = "USERS_FAILED";
export const USERS_SUCCESS = "USERS_SUCCESS";

export const USERS_MOCK_FETCHING = "USERS_MOCK_FETCHING";
export const USERS_MOCK_FAILED = "USERS_MOCK_FAILED";
export const USERS_MOCK_SUCCESS = "USERS_MOCK_SUCCESS";

export const USERPROFILE_FETCHING = "USERPROFILE_FETCHING";
export const USERPROFILE_FAILED = "USERPROFILE_FAILED";
export const USERPROFILE_SUCCESS = "USERPROFILE_SUCCESS";

export const COURSE_FETCHING = "COURSE_FETCHING";
export const COURSE_FAILED = "COURSE_FAILED";
export const COURSE_SUCCESS = "COURSE_SUCCESS";

export const DEPARTMENT_FETCHING = "DEPARTMENT_FETCHING";
export const DEPARTMENT_FAILED = "DEPARTMENT_FAILED";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";

export const COURSEITEM_FETCHING = "COURSEITEM_FETCHING";
export const COURSEITEM_FAILED = "COURSEITEM_FAILED";
export const COURSEITEM_SUCCESS = "COURSEITEM_SUCCESS";

export const EMPLOYEE_FETCHING = "EMPLOYEE_FETCHING";
export const EMPLOYEE_FAILED = "EMPLOYEE_FAILED";
export const EMPLOYEE_SUCCESS = "EMPLOYEE_SUCCESS";

export const EMPLOYEEPROFILE_FETCHING = "EMPLOYEEPROFILE_FETCHING";
export const EMPLOYEEPROFILE_FAILED = "EMPLOYEEPROFILE_FAILED";
export const EMPLOYEEPROFILE_SUCCESS = "EMPLOYEEPROFILE_SUCCESS";

export const REQUESTTIME_WITHDRAW_FETCHING = "REQUESTTIME_WITHDRAW_FETCHING";
export const REQUESTTIME_WITHDRAW_SUCCESS = "REQUESTTIME_WITHDRAW_SUCCESS";
export const REQUESTTIME_WITHDRAW_FAILED = "REQUESTTIME_WITHDRAW_FAILED";

export const EDUCATION_FETCHING = "EDUCATION_FETCHING";
export const EDUCATION_FAILED = "EDUCATION_FAILED";
export const EDUCATION_SUCCESS = "EDUCATION_SUCCESS";

export const POSITIONS_FETCHING = "POSITIONS_FETCHING";
export const POSITIONS_FAILED = "POSITIONS_FAILED";
export const POSITIONS_SUCCESS = "POSITIONS_SUCCESS";

export const COMPANY_ORGANIZATION_FETCHING = "COMPANY_ORGANIZATION_FETCHING";
export const COMPANY_ORGANIZATION_SUCCESS = "COMPANY_ORGANIZATION_SUCCESS";
export const COMPANY_ORGANIZATION_FAILED = "COMPANY_ORGANIZATION_FAILED";

export const COMPANYPROFILE_FETCHING = "COMPANYPROFILE_FETCHING";
export const COMPANYPROFILE_FAILED = "COMPANYPROFILE_FAILED";
export const COMPANYPROFILE_SUCCESS = "COMPANYPROFILE_SUCCESS";

export const BANK_FETCHING = "BANK_FETCHING";
export const BANK_FAILED = "BANK_FAILED";
export const BANK_SUCCESS = "BANK_SUCCESS";

export const BANK_DETAIL_FETCHING = "BANK_DETAIL_FETCHING";
export const BANK_DETAIL_FAILED = "BANK_DETAIL_FAILED";
export const BANK_DETAIL_SUCCESS = "BANK_DETAIL_SUCCESS";

export const EMPLOYEE_ADDITION_DEDUCTION_FETCHING =
  "EMPLOYEE_ADDITION_DEDUCTION_FETCHING";
export const EMPLOYEE_ADDITION_DEDUCTION_FAILED =
  "EMPLOYEE_ADDITION_DEDUCTION_FAILED";
export const EMPLOYEE_ADDITION_DEDUCTION_SUCCESS =
  "EMPLOYEE_ADDITION_DEDUCTION_SUCCESS";

export const ORGCHART_FETCHING = "ORGCHART_FETCHING";
export const ORGCHART_FAILED = "ORGCHART_FAILED";
export const ORGCHART_SUCCESS = "ORGCHART_SUCCESS";

export const HOLIDAY_FETCHING = "HOLIDAY_FETCHING";
export const HOLIDAY_FAILED = "HOLIDAY_FAILED";
export const HOLIDAY_SUCCESS = "HOLIDAY_SUCCESS";

export const EMPLOYMENT_TYPE_FETCHING = "EMPLOYMENT_TYPE_FETCHING";
export const EMPLOYMENT_TYPE_FAILED = "EMPLOYMENT_TYPE_FAILED";
export const EMPLOYMENT_TYPE_SUCCESS = "EMPLOYMENT_TYPE_SUCCESS";

export const LEVELS_FETCHING = "LEVELS_FETCHING";
export const LEVELS_FAILED = "LEVELS_FAILED";
export const LEVELS_SUCCESS = "LEVELS_SUCCESS";

export const PERSONNEL_LEVELS_FETCHING = "PERSONNEL_LEVELS_FETCHING";
export const PERSONNEL_LEVELS_FAILED = "PERSONNEL_LEVELS_FAILED";
export const PERSONNEL_LEVELS_SUCCESS = "PERSONNEL_LEVELS_SUCCESS";

export const LEVELADDITION_FETCHING = "LEVELADDITION_FETCHING";
export const LEVELADDITION_FAILED = "LEVELADDITION_FAILED";
export const LEVELADDITION_SUCCESS = "LEVELADDITION_SUCCESS";

export const PROJECT_FETCHING = "PROJECT_FETCHING";
export const PROJECT_FAILED = "PROJECT_FAILED";
export const PROJECT_SUCCESS = "PROJECT_SUCCESS";

export const PROJECTASSIGN_FETCHING = "PROJECTASSIGN_FETCHING";
export const PROJECTASSIGN_FAILED = "PROJECTASSIGN_FAILED";
export const PROJECTASSIGN_SUCCESS = "PROJECTASSIGN_SUCCESS";

export const SALARYSLIP_FETCHING = "SALARYSLIP_FETCHING";
export const SALARYSLIP_FAILED = "SALARYSLIP_FAILED";
export const SALARYSLIP_SUCCESS = "SALARYSLIP_SUCCESS";

export const ADDITIONS_FETCHING = "ADDITIONS_FETCHING";
export const ADDITIONS_FAILED = "ADDITIONS_FAILED";
export const ADDITIONS_SUCCESS = "ADDITIONS_SUCCESS";

export const LOCATION_FETCHING = "LOCATION_FETCHING";
export const LOCATION_FAILED = "LOCATION_FAILED";
export const LOCATION_SUCCESS = "LOCATION_SUCCESS";

export const DAYOFFAPPROVAL_FETCHING = "DAYOFFAPPROVAL_FETCHING";
export const DAYOFFAPPROVAL_FAILED = "DAYOFFAPPROVAL_FAILED";
export const DAYOFFAPPROVAL_SUCCESS = "DAYOFFAPPROVAL_SUCCESS";

export const PAYTYPES_FETCHING = "PAYTYPES_FETCHING";
export const PAYTYPES_FAILED = "PAYTYPES_FAILED";
export const PAYTYPES_SUCCESS = "PAYTYPES_SUCCESS";

export const PAYROLL_SETTING_FETCHING = "PAYROLL_SETTING_FETCHING";
export const PAYROLL_SETTING_FAILED = "PAYROLL_SETTING_FAILED";
export const PAYROLL_SETTING_SUCCESS = "PAYROLL_SETTING_SUCCESS";

export const PAYSLIP_FETCHING = "PAYSLIP_FETCHING";
export const PAYSLIP_SUCCESS = "PAYSLIP_SUCCESS";
export const PAYSLIP_FAILED = "PAYSLIP_FAILED";

export const PAYSLIP_DETAIL_FETCHING = "PAYSLIP_DETAIL_FETCHING";
export const PAYSLIP_DETAIL_SUCCESS = "PAYSLIP_DETAIL_SUCCESS";
export const PAYSLIP_DETAIL_FAILED = "PAYSLIP_DETAIL_FAILED";

export const PAYRUNS_FETCHING = "PAYRUNS_FETCHING";
export const PAYRUNS_FAILED = "PAYRUNS_FAILED";
export const PAYRUNS_SUCCESS = "PAYRUNS_SUCCESS";

export const PAYRUNS_DETAIL_FETCHING = "PAYRUNS_DETAIL_FETCHING";
export const PAYRUNS_DETAIL_FAILED = "PAYRUNS_DETAIL_FAILED";
export const PAYRUNS_DETAIL_SUCCESS = "PAYRUNS_DETAIL_SUCCESS";

export const PAYRUNS_DETAIL_EMPLOYEES_FETCHING =
  "PAYRUNS_DETAIL_EMPLOYEES_FETCHING";
export const PAYRUNS_DETAIL_EMPLOYEES_FAILED =
  "PAYRUNS_DETAIL_EMPLOYEES_FAILED";
export const PAYRUNS_DETAIL_EMPLOYEES_SUCCESS =
  "PAYRUNS_DETAIL_EMPLOYEES_SUCCESS";

export const SHIFT_PATTERN_FETCHING = "SHIFT_PATTERN_FETCHING";
export const SHIFT_PATTERN_FAILED = "SHIFT_PATTERN_FAILED";
export const SHIFT_PATTERN_SUCCESS = "SHIFT_PATTERN_SUCCESS";

export const SHIFT_FETCHING = "SHIFT_FETCHING";
export const SHIFT_FAILED = "SHIFT_FAILED";
export const SHIFT_SUCCESS = "SHIFT_SUCCESS";

export const SHIFT_GROUP_FETCHING = "SHIFT_GROUP_FETCHING";
export const SHIFT_GROUP_FAILED = "SHIFT_GROUP_FAILED";
export const SHIFT_GROUP_SUCCESS = "SHIFT_GROUP_SUCCESS";

export const EMPLOYEE_SHIFT_FETCHING = "EMPLOYEE_SHIFT_FETCHING";
export const EMPLOYEE_SHIFT_FAILED = "EMPLOYEE_SHIFT_FAILED";
export const EMPLOYEE_SHIFT_SUCCESS = "EMPLOYEE_SHIFT_SUCCESS";

export const EMPLOYEE_NOT_SHIFT_FETCHING = "EMPLOYEE_NOT_SHIFT_FETCHING";
export const EMPLOYEE_NOT_SHIFT_SUCCESS = "EMPLOYEE_NOT_SHIFT_SUCCESS";
export const EMPLOYEE_NOT_SHIFT_FAILED = "EMPLOYEE_NOT_SHIFT_FAILED";

export const EMPLOYEES_SHIFTHISTORY_FETCHING =
  "EMPLOYEES_SHIFTHISTORY_FETCHING";
export const EMPLOYEES_SHIFTHISTORY_SUCCESS = "EMPLOYEES_SHIFTHISTORY_SUCCESS";
export const EMPLOYEES_SHIFTHISTORY_FAILED = "EMPLOYEES_SHIFTHISTORY_FAILED";

export const EMPLOYEE_CHANGESHIFT_FETCHING = "EMPLOYEE_CHANGESHIFT_FETCHING";
export const EMPLOYEE_CHANGESHIFT_SUCCESS = "EMPLOYEE_CHANGESHIFT_SUCCESS";
export const EMPLOYEE_CHANGESHIFT_FAILED = "EMPLOYEE_CHANGESHIFT_FAILED";

export const EMPLOYEE_CHANGEPOSITION_FETCHING =
  "EMPLOYEE_CHANGEPOSITION_FETCHING";
export const EMPLOYEE_CHANGEPOSITION_SUCCESS =
  "EMPLOYEE_CHANGEPOSITION_SUCCESS";
export const EMPLOYEE_CHANGEPOSITION_FAILED = "EMPLOYEE_CHANGEPOSITION_FAILED";

export const EMPLOYEE_CHANGE_FETCHING = "EMPLOYEE_CHANGE_FETCHING";
export const EMPLOYEE_CHANGE_SUCCESS = "EMPLOYEE_CHANGE_SUCCESS";
export const EMPLOYEE_CHANGE_FAILED = "EMPLOYEE_CHANGE_FAILED";

export const WORKTYPES_FETCHING = "WORKTYPES_FETCHING";
export const WORKTYPES_FAILED = "WORKTYPES_FAILED";
export const WORKTYPES_SUCCESS = "WORKTYPES_SUCCESS";

export const MANAGER_FETCHING = "MANAGER_FETCHING";
export const MANAGER_SUCCESS = "MANAGER_SUCCESS";
export const MANAGER_FAILED = "MANAGER_FAILED";

export const LEAVEEMPLOYEES_FETCHING = "LEAVEEMPLOYEES_FETCHING";
export const LEAVEEMPLOYEES_FAILED = "LEAVEEMPLOYEES_FAILED";
export const LEAVEEMPLOYEES_SUCCESS = "LEAVEEMPLOYEES_SUCCESS";

export const LEAVEEMPLOYEES_WITHDRAW_FETCHING =
  "LEAVEEMPLOYEES_WITHDRAW_FETCHING";
export const LEAVEEMPLOYEES_WITHDRAW_SUCCESS =
  "LEAVEEMPLOYEES_WITHDRAW_SUCCESS";
export const LEAVEEMPLOYEES_WITHDRAW_FAILED = "LEAVEEMPLOYEES_WITHDRAW_FAILED";

export const LEAVEWORKS_DETAIL_FETCHING = "LEAVEWORKS_DETAIL_FETCHING";
export const LEAVEWORKS_DETAIL_FAILED = "LEAVEWORKS_DETAIL_FAILED";
export const LEAVEWORKS_DETAIL_SUCCESS = "LEAVEWORKS_DETAIL_SUCCESS";

export const JOBFAMILY_DETAIL_FETCHING = "JOBFAMILY_DETAIL_FETCHING";
export const JOBFAMILY_DETAIL_FAILED = "JOBFAMILY_DETAIL_FAILED";
export const JOBFAMILY_DETAIL_SUCCESS = "JOBFAMILY_DETAIL_SUCCESS";

export const INSTITUTE_DETAIL_FETCHING = "INSTITUTE_DETAIL_FETCHING";
export const INSTITUTE_DETAIL_FAILED = "INSTITUTE_DETAIL_FAILED";
export const INSTITUTE_DETAIL_SUCCESS = "INSTITUTE_DETAIL_SUCCESS";

export const LEAVEAPPROVES_DETAIL_FETCHING = "LEAVEWORKS_DETAIL_FETCHING";
export const LEAVEAPPROVES_DETAIL_FAILED = "LEAVEAPPROVES_DETAIL_FAILED";
export const LEAVEAPPROVES_DETAIL_SUCCESS = "LEAVEAPPROVES_DETAIL_SUCCESS";

export const USER_LEAVE_FETCHING = "USER_LEAVE_FETCHING";
export const USER_LEAVE_FAILED = "USER_LEAVE_FAILED";
export const USER_LEAVE_SUCCESS = "USER_LEAVE_SUCCESS";

export const LEAVE_TYPE_FETCHING = "LEAVE_TYPE_FETCHING";
export const LEAVE_TYPE_FAILED = "LEAVE_TYPE_FAILED";
export const LEAVE_TYPE_SUCCESS = "LEAVE_TYPE_SUCCESS";

export const REQUESTTIME_FETCHING = "REQUESTTIME_FETCHING";
export const REQUESTTIME_FAILED = "REQUESTTIME_FAILED";
export const REQUESTTIME_SUCCESS = "REQUESTTIME_SUCCESS";

export const WORKTIMEREQUEST_DETAIL_FETCHING =
  "WORKTIMEREQUEST_DETAIL_FETCHING";
export const WORKTIMEREQUEST_DETAIL_FAILED = "WORKTIMEREQUEST_DETAIL_FAILED";
export const WORKTIMEREQUEST_DETAIL_SUCCESS = "WORKTIMEREQUEST_DETAIL_SUCCESS";

export const LOGRECORD_DETAIL_FETCHING = "LOGRECORD_DETAIL_FETCHING";
export const LOGRECORD_DETAIL_FAILED = "LOGRECORD_DETAIL_FAILED";
export const LOGRECORD_DETAIL_SUCCESS = "LOGRECORD_DETAIL_SUCCESS";

export const GROUP_LOCATIONS_FETCHING = "GROUP_LOCATIONS_FETCHING";
export const GROUP_LOCATIONS_FAILED = "GROUP_LOCATIONS_FAILED";
export const GROUP_LOCATIONS_SUCCESS = "GROUP_LOCATIONS_SUCCESS";

export const LOCATIONS_FETCHING = "LOCATIONS_FETCHING";
export const LOCATIONS_FAILED = "LOCATIONS_FAILED";
export const LOCATIONS_SUCCESS = "LOCATIONS_SUCCESS";

export const ATTENDANCE_FETCHING = "ATTENDANCE_FETCHING";
export const ATTENDANCE_FAILED = "ATTENDANCE_FAILED";
export const ATTENDANCE_SUCCESS = "ATTENDANCE_SUCCESS";

export const JOB_FETCHING = "JOB_FETCHING";
export const JOB_FAILED = "JOB_FAILED";
export const JOB_SUCCESS = "JOB_SUCCESS";

export const JOBPOSITION_FETCHING = "JOBPOSITION_FETCHING";
export const JOBPOSITION_FAILED = "JOBPOSITION_FAILED";
export const JOBPOSITION_SUCCESS = "JOBPOSITION_SUCCESS";

export const WORK_FETCHING = "WORK_FETCHING";
export const WORK_FAILED = "WORK_FAILED";
export const WORK_SUCCESS = "WORK_SUCCESS";

export const TASK_FETCHING = "TASK_FETCHING";
export const TASK_FAILED = "TASK_FAILED";
export const TASK_SUCCESS = "TASK_SUCCESS";

export const CATEGORY_FETCHING = "CATEGORY_FETCHING";
export const CATEGORY_FAILED = "CATEGORY_FAILED";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";

export const DOCUMENT_FETCHING = "DOCUMENT_FETCHING";
export const DOCUMENT_FAILED = "DOCUMENT_FAILED";
export const DOCUMENT_SUCCESS = "DOCUMENT_SUCCESS";

export const DOCUMENT_ITEM_FETCHING = "DOCUMENT_ITEM_FETCHING";
export const DOCUMENT_ITEM_FAILED = "DOCUMENT_ITEM_FAILED";
export const DOCUMENT_ITEM_SUCCESS = "DOCUMENT_ITEM_SUCCESS";

export const DOCUMENT_GROUP_FAILED = "DOCUMENT_GROUP_FAILED";
export const DOCUMENT_GROUP_SUCCESS = "DOCUMENT_GROUP_SUCCESS";
export const DOCUMENT_GROUP_FETCHING = "DOCUMENT_GROUP_FETCHING";

export const DIVISION_FETCHING = "DIVISION_FETCHING";
export const DIVISION_FAILED = "DIVISION_FAILED";
export const DIVISION_SUCCESS = "DIVISION_SUCCESS";

export const MEMBER_FETCHING = "MEMBER_FETCHING";
export const MEMBER_FAILED = "MEMBER_FAILED";
export const MEMBER_SUCCESS = "MEMBER_SUCCESS";

export const DEMAND_FETCHING = "DEMAND_FETCHING";
export const DEMAND_FAILED = "DEMAND_FAILED";
export const DEMAND_SUCCESS = "DEMAND_SUCCESS";

export const INSURANCE_FETCHING = "INSURANCE_FETCHING";
export const INSURANCE_FAILED = "INSURANCE_FAILED";
export const INSURANCE_SUCCESS = "INSURANCE_SUCCESS";

export const INSURANCECATEGORY_FETCHING = "INSURANCECATEGORY_FETCHING";
export const INSURANCECATEGORY_FAILED = "INSURANCECATEGORY_FAILED";
export const INSURANCECATEGORY_SUCCESS = "INSURANCECATEGORY_SUCCESS";

export const HOSPITAL_FETCHING = "HOSPITAL_FETCHING";
export const HOSPITAL_FAILED = "HOSPITAL_FAILED";
export const HOSPITAL_SUCCESS = "HOSPITAL_SUCCESS";

export const PACKAGE_FETCHING = "PACKAGE_FETCHING";
export const PACKAGE_FAILED = "PACKAGE_FAILED";
export const PACKAGE_SUCCESS = "PACKAGE_SUCCESS";

export const MAINCATEGORY_FETCHING = "MAINCATEGORY_FETCHING";
export const MAINCATEGORY_FAILED = "MAINCATEGORY_FAILED";
export const MAINCATEGORY_SUCCESS = "MAINCATEGORY_SUCCESS";

export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAIL = "CART_FAIL";

export const ITEM_FETCHING = "ITEM_FETCHING";
export const ITEM_FAILED = "ITEM_FAILED";
export const ITEM_SUCCESS = "ITEM_SUCCESS";

export const SHOPCATEGORY_FETCHING = "SHOPCATEGORY_FETCHING";
export const SHOPCATEGORY_FAILED = "SHOPCATEGORY_FAILED";
export const SHOPCATEGORY_SUCCESS = "SHOPCATEGORY_SUCCESS";

export const TRAVELCATEGORY_FETCHING = "TRAVELCATEGORY_FETCHING";
export const TRAVELCATEGORY_FAILED = "TRAVELCATEGORY_FAILED";
export const TRAVELCATEGORY_SUCCESS = "TRAVELCATEGORY_SUCCESS";

export const TRAVEL_FETCHING = "TRAVEL_FETCHING";
export const TRAVEL_FAILED = "TRAVEL_FAILED";
export const TRAVEL_SUCCESS = "TRAVEL_SUCCESS";

export const BILL_FETCHING = "BILL_FETCHING";
export const BILL_FAILED = "BILL_FAILED";
export const BILL_SUCCESS = "BILL_SUCCESS";

export const TREATMENTCATEGORY_FETCHING = "TREATMENTCATEGORY_FETCHING";
export const TREATMENTCATEGORY_FAILED = "TREATMENTCATEGORY_FAILED";
export const TREATMENTCATEGORY_SUCCESS = "TREATMENTCATEGORY_SUCCESS";

export const HEALTHCHECK_FETCHING = "HEALTHCHECK_FETCHING";
export const HEALTHCHECK_FAILED = "HEALTHCHECK_FAILED";
export const HEALTHCHECK_SUCCESS = "HEALTHCHECK_SUCCESS";

export const HEALTHCHECKCATEGORY_FETCHING = "HEALTHCHECKCATEGORY_FETCHING";
export const HEALTHCHECKCATEGORY_FAILED = "HEALTHCHECKCATEGORY_FAILED";
export const HEALTHCHECKCATEGORY_SUCCESS = "HEALTHCHECKCATEGORY_SUCCESS";

export const NOTIFICATION_FETCHING = "NOTIFICATION_FETCHING";
export const NOTIFICATION_FAILED = "NOTIFICATION_FAILED";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";

export const JOBPOSTING_FETCHING = "JOBPOSTING_FETCHING";
export const JOBPOSTING_FAILED = "JOBPOSTING_FAILED";
export const JOBPOSTING_SUCCESS = "JOBPOSTING_SUCCESS";

export const JOBPOSTINGAPPLY_FETCHING = "JOBPOSTINGAPPLY_FETCHING";
export const JOBPOSTINGAPPLY_FAILED = "JOBPOSTINGAPPLY_FAILED";
export const JOBPOSTINGAPPLY_SUCCESS = "JOBPOSTINGAPPLY_SUCCESS";

export const DELIVERY_FETCHING = "DELIVERY_FETCHING";
export const DELIVERY_FAILED = "DELIVERY_FAILED";
export const DELIVERY_SUCCESS = "DELIVERY_SUCCESS";

export const FACILITIES_FETCHING = "FACILITIES_FETCHING";
export const FACILITIES_FAILED = "FACILITIES_FAILED";
export const FACILITIES_SUCCESS = "FACILITIES_SUCCESS";

export const HEALTH_FETCHING = "HEALTH_FETCHING";
export const HEALTH_FAILED = "HEALTH_FAILED";
export const HEALTH_SUCCESS = "HEALTH_SUCCESS";

export const RIGHTTREATMENT_FETCHING = "RIGHTTREATMENT_FETCHING";
export const RIGHTTREATMENT_FAILED = "RIGHTTREATMENT_FAILED";
export const RIGHTTREATMENT_SUCCESS = "RIGHTTREATMENT_SUCCESS";

export const BOOKINGHEALTHCHECK_FETCHING = "BOOKINGHEALTHCHECK_FETCHING";
export const BOOKINGHEALTHCHECK_FAILED = "BOOKINGHEALTHCHECK_FAILED";
export const BOOKINGHEALTHCHECK_SUCCESS = "BOOKINGHEALTHCHECK_SUCCESS";

export const HISTORY_FETCHING = "HISTORY_FETCHING";
export const HISTORY_FAILED = "HISTORY_FAILED";
export const HISTORY_SUCCESS = "HISTORY_SUCCESS";

export const CAREERPLAN_FETCHING = "CAREERPLAN_FETCHING";
export const CAREERPLAN_FAILED = "CAREERPLAN_FAILED";
export const CAREERPLAN_SUCCESS = "CAREERPLAN_SUCCESS";

export const JOBSTRUCTURE_FETCHING = "JOBSTRUCTURE_FETCHING";
export const JOBSTRUCTURE_FAILED = "JOBSTRUCTURE_FAILED";
export const JOBSTRUCTURE_SUCCESS = "JOBSTRUCTURE_SUCCESS";

export const SUBJOBSTRUCTURE_FETCHING = "SUBJOBSTRUCTURE_FETCHING";
export const SUBJOBSTRUCTURE_FAILED = "SUBJOBSTRUCTURE_FAILED";
export const SUBJOBSTRUCTURE_SUCCESS = "SUBJOBSTRUCTURE_SUCCESS";

export const JOBPOSITIONS_FETCHING = "JOBPOSITIONS_FETCHING";
export const JOBPOSITIONS_FAILED = "JOBPOSITIONS_FAILED";
export const JOBPOSITIONS_SUCCESS = "JOBPOSITIONS_SUCCESS";

export const ORGPLANS_FETCHING = "ORGPLANS_FETCHING";
export const ORGPLANS_FAILED = "ORGPLANS_FAILED";
export const ORGPLANS_SUCCESS = "ORGPLANS_SUCCESS";

export const TEAMPLANS_FETCHING = "TEAMPLANS_FETCHING";
export const TEAMPLANS_FAILED = "TEAMPLANS_FAILED";
export const TEAMPLANS_SUCCESS = "TEAMPLANS_SUCCESS";

export const IDVPLANS_FETCHING = "IDVPLANS_FETCHING";
export const IDVPLANS_FAILED = "IDVPLANS_FAILED";
export const IDVPLANS_SUCCESS = "IDVPLANS_SUCCESS";

export const FAILUREHISTORY_FETCHING = "FAILUREHISTORY_FETCHING";
export const FAILUREHISTORY_FAILED = "FAILUREHISTORY_FAILED";
export const FAILUREHISTORY_SUCCESS = "FAILUREHISTORY_SUCCESS";

export const PROGRESSORGPLAN_FETCHING = "PROGRESSORGPLAN_FETCHING";
export const PROGRESSORGPLAN_FAILED = "PROGRESSORGPLAN_FAILED";
export const PROGRESSORGPLAN_SUCCESS = "PROGRESSORGPLAN_SUCCESS";

export const PROGRESSTEAMPLAN_FETCHING = "PROGRESSTEAMPLAN_FETCHING";
export const PROGRESSTEAMPLAN_FAILED = "PROGRESSTEAMPLAN_FAILED";
export const PROGRESSTEAMPLAN_SUCCESS = "PROGRESSTEAMPLAN_SUCCESS";

export const PROGRESSIDVPLAN_FETCHING = "PROGRESSIDVPLAN_FETCHING";
export const PROGRESSIDVPLAN_FAILED = "PROGRESSIDVPLAN_FAILED";
export const PROGRESSIDVPLAN_SUCCESS = "PROGRESSIDVPLAN_SUCCESS";

export const IMPACT_FETCHING = "IMPACT_FETCHING";
export const IMPACT_FAILED = "IMPACT_FAILED";
export const IMPACT_SUCCESS = "IMPACT_SUCCESS";

export const IMPACTOTHER_FETCHING = "IMPACTOTHER_FETCHING";
export const IMPACTOTHER_FAILED = "IMPACTOTHER_FAILED";
export const IMPACTOTHER_SUCCESS = "IMPACTOTHER_SUCCESS";

export const DIALOGUERECORD_FETCHING = "DIALOGUERECORD_FETCHING";
export const DIALOGUERECORD_FAILED = "DIALOGUERECORD_FAILED";
export const DIALOGUERECORD_SUCCESS = "DIALOGUERECORD_SUCCESS";

export const JOBTASKLIST_FETCHING = "JOBTASKLIST_FETCHING";
export const JOBTASKLIST_FAILED = "JOBTASKLIST_FAILED";
export const JOBTASKLIST_SUCCESS = "JOBTASKLIST_SUCCESS";

export const PERSONALTASKLIST_FETCHING = "PERSONALTASKLIST_FETCHING";
export const PERSONALTASKLIST_FAILED = "PERSONALTASKLIST_FAILED";
export const PERSONALTASKLIST_SUCCESS = "PERSONALTASKLIST_SUCCESS";

export const PERSONALTASKUSED_FETCHING = "PERSONALTASKUSED_FETCHING";
export const PERSONALTASKUSED_FAILED = "PERSONALTASKUSED_FAILED";
export const PERSONALTASKUSED_SUCCESS = "PERSONALTASKUSED_SUCCESS";

export const ATTENDANCE_ONCE_FETCHING = "ATTENDANCE_ONCE_FETCHING";
export const ATTENDANCE_ONCE_FAILED = "ATTENDANCE_ONCE_FAILED";
export const ATTENDANCE_ONCE_SUCCESS = "ATTENDANCE_ONCE_SUCCESS";

export const USER_BENEFICIARYS_FETCHING = "USER_BENEFICIARYS_FETCHING";
export const USER_BENEFICIARYS_FAILED = "USER_BENEFICIARYS_FAILED";
export const USER_BENEFICIARYS_SUCCESS = "USER_BENEFICIARYS_SUCCESS";

export const USER_PROVIDENTFUNDS_FETCHING = "USER_PROVIDENTFUNDS_FETCHING";
export const USER_PROVIDENTFUNDS_FAILED = "USER_PROVIDENTFUNDS_FAILED";
export const USER_PROVIDENTFUNDS_SUCCESS = "USER_PROVIDENTFUNDS_SUCCESS";

export const ADMIN_SETTINGPF_FETCHING = "ADMIN_SETTINGPF_FETCHING";
export const ADMIN_SETTINGPF_FAILED = "ADMIN_SETTINGPF_FAILED";
export const ADMIN_SETTINGPF_SUCCESS = "ADMIN_SETTINGPF_SUCCESS";

export const DASHBOARD_OVERVIEW_FETCHING = "DASHBOARD_OVERVIEW_FETCHING";
export const DASHBOARD_OVERVIEW_FAILED = "DASHBOARD_OVERVIEW_FAILED";
export const DASHBOARD_OVERVIEW_SUCCESS = "DASHBOARD_OVERVIEW_SUCCESS";

export const DASHBOARD_OVERTIME_FETCHING = "DASHBOARD_OVERTIME_FETCHING";
export const DASHBOARD_OVERTIME_FAILED = "DASHBOARD_OVERTIME_FAILED";
export const DASHBOARD_OVERTIME_SUCCESS = "DASHBOARD_OVERTIME_SUCCESS";

export const DASHBOARD_COST_FETCHING = "DASHBOARD_COST_FETCHING";
export const DASHBOARD_COST_FAILED = "DASHBOARD_COST_FAILED";
export const DASHBOARD_COST_SUCCESS = "DASHBOARD_COST_SUCCESS";

export const DASHBOARD_WORKINGTIME_FETCHING = "DASHBOARD_WORKINGTIME_FETCHING";
export const DASHBOARD_WORKINGTIME_FAILED = "DASHBOARD_WORKINGTIME_FAILED";
export const DASHBOARD_WORKINGTIME_SUCCESS = "DASHBOARD_WORKINGTIME_SUCCESS";

export const TAX_DEDUCTION_FETCHING = "TAX_DEDUCTION_FETCHING";
export const TAX_DEDUCTION_FAILED = "TAX_DEDUCTION_FAILED";
export const TAX_DEDUCTION_SUCCESS = "TAX_DEDUCTION_SUCCESS";

export const SUMMARY_TIME_FETCHING = "SUMMARY_TIME_FETCHING";
export const SUMMARY_TIME_FAILED = "SUMMARY_TIME_FAILED";
export const SUMMARY_TIME_SUCCESS = "SUMMARY_TIME_SUCCESS";

export const SUMMARY_TIME_INDIVIDUAL_FETCHING = "SUMMARY_TIME_INDIVIDUAL_FETCHING";
export const SUMMARY_TIME_INDIVIDUAL_FAILED = "SUMMARY_TIME_INDIVIDUAL_FAILED";
export const SUMMARY_TIME_INDIVIDUAL_SUCCESS = "SUMMARY_TIME_INDIVIDUAL_SUCCESS";

export const EMPLOYEE_PROVIDENTFUNDS_FETCHING =
  "EMPLOYEE_PROVIDENTFUNDS_FETCHING";
export const EMPLOYEE_PROVIDENTFUNDS_FAILED = "EMPLOYEE_PROVIDENTFUNDS_FAILED";
export const EMPLOYEE_PROVIDENTFUNDS_SUCCESS =
  "EMPLOYEE_PROVIDENTFUNDS_SUCCESS";

export const EMPLOYEE_BENEFICIARYS_FETCHING = "EMPLOYEE_BENEFICIARYS_FETCHING";
export const EMPLOYEE_BENEFICIARYS_FAILED = "EMPLOYEE_BENEFICIARYS_FAILED";
export const EMPLOYEE_BENEFICIARYS_SUCCESS = "EMPLOYEE_BENEFICIARYS_SUCCESS";

export const TAX_DEDUCTION_SCHEDULE_FETCHING =
  "TAX_DEDUCTION_SCHEDULE_FETCHING";
export const TAX_DEDUCTION_SCHEDULE_FAILED = "TAX_DEDUCTION_SCHEDULE_FAILED";
export const TAX_DEDUCTION_SCHEDULE_SUCCESS = "TAX_DEDUCTION_SCHEDULE_SUCCESS";

export const INDIVIDUAL_OBJECTIVE_FETCHING = "INDIVIDUAL_OBJECTIVE_FETCHING";
export const INDIVIDUAL_OBJECTIVE_SUCCESS = "INDIVIDUAL_OBJECTIVE_SUCCESS";
export const INDIVIDUAL_OBJECTIVE_FAILED = "INDIVIDUAL_OBJECTIVE_FAILED";

export const LEAVE_3MONTH_FETCHING = "LEAVE_3MONTH_FETCHING";
export const LEAVE_3MONTH_FAILED = "LEAVE_3MONTH_FAILED";
export const LEAVE_3MONTH_SUCCESS = "LEAVE_3MONTH_SUCCESS";

export const LEAVE_YEAR_FETCHING = "LEAVE_YEAR_FETCHING";
export const LEAVE_YEAR_FAILED = "LEAVE_YEAR_FAILED";
export const LEAVE_YEAR_SUCCESS = "LEAVE_YEAR_SUCCESS";

export const EMPLOYMENT_FETCHING = "EMPLOYMENT_FETCHING";
export const EMPLOYMENT_FAILED = "EMPLOYMENT_FAILED";
export const EMPLOYMENT_SUCCESS = "EMPLOYMENT_SUCCESS";

export const COMPANY_FETCHING = "COMPANY_FETCHING";
export const COMPANY_FAILED = "COMPANY_FAILED";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";

export const MANAGER_SETTING_FETCHING = "MANAGER_SETTING_FETCHING";
export const MANAGER_SETTING_FAILED = "MANAGER_SETTING_FAILED";
export const MANAGER_SETTING_SUCCESS = "MANAGER_SETTING_SUCCESS";

export const MANAGER_GROUP_FETCHING = "MANAGER_GROUP_FETCHING";
export const MANAGER_GROUP_FAILED = "MANAGER_GROUP_FAILED";
export const MANAGER_GROUP_SUCCESS = "MANAGER_GROUP_SUCCESS";

export const ORGANIZATION_CHART_FETCHING = "ORGANIZATION_CHART_FETCHING";
export const ORGANIZATION_CHART_FAILED = "ORGANIZATION_CHART_FAILED";
export const ORGANIZATION_CHART_SUCCESS = "ORGANIZATION_CHART_SUCCESS";

export const JOBGROUP_FETCHING = "JOBGROUP_FETCHING";
export const JOBGROUP_FAILED = "JOBGROUP_FAILED";
export const JOBGROUP_SUCCESS = "JOBGROUP_SUCCESS";

export const ANNOUNCEMENT_FETCHING = "ANNOUNCEMENT_FETCHING";
export const ANNOUNCEMENT_FAILED = "ANNOUNCEMENT_FAILED";
export const ANNOUNCEMENT_SUCCESS = "ANNOUNCEMENT_SUCCESS";

export const ANNOUNCEMENT_ACTIVE_FETCHING = "ANNOUNCEMENT_ACTIVE_FETCHING";
export const ANNOUNCEMENT_ACTIVE_FAILED = "ANNOUNCEMENT_ACTIVE_FAILED";
export const ANNOUNCEMENT_ACTIVE_SUCCESS = "ANNOUNCEMENT_ACTIVE_SUCCESS";

export const ORGANIZATION_STRUCTURE_FETCHING =
  "ORGANIZATION_STRUCTURE_FETCHING";
export const ORGANIZATION_STRUCTURE_FAILED = "ORGANIZATION_STRUCTURE_FAILED";
export const ORGANIZATION_STRUCTURE_SUCCESS = "ORGANIZATION_STRUCTURE_SUCCESS";

export const COST_ELEMENT_LIST_FETCHING = "COST_ELEMENT_LIST_FETCHING";
export const COST_ELEMENT_LIST_FAILED = "COST_ELEMENT_LIST_FAILED";
export const COST_ELEMENT_LIST_SUCCESS = "COST_ELEMENT_LIST_SUCCESS";

export const COST_ELEMENT_FETCHING = "COST_ELEMENT_FETCHING";
export const COST_ELEMENT_FAILED = "COST_ELEMENT_FAILED";
export const COST_ELEMENT_SUCCESS = "COST_ELEMENT_SUCCESS";

export const AFFILIATE_FETCHING = "AFFILIATE_FETCHING";
export const AFFILIATE_FAILED = "AFFILIATE_FAILED";
export const AFFILIATE_SUCCESS = "AFFILIATE_SUCCESS";

export const ADMIN_FETCHING = "ADMIN_FETCHING";
export const ADMIN_FAILED = "ADMIN_FAILED";
export const ADMIN_SUCCESS = "ADMIN_SUCCESS";


export const JOBLEVELS_FETCHING = "JOBLEVELS_FETCHING";
export const JOBLEVELS_FAILED = "JOBLEVELS_FAILED";
export const JOBLEVELS_SUCCESS = "JOBLEVELS_SUCCESS";

export const JOBS_MANAGER_FETCHING = "JOBS_MANAGER_FETCHING"
export const JOBS_MANAGER_FAILED = "JOBS_MANAGER_FAILED"
export const JOBS_MANAGER_SUCCESS = "JOBS_MANAGER_SUCCESS"

export const JOB_RECRUIT_FORM_FETCHING = "JOB_RECRUIT_FORM_FETCHING"
export const JOB_RECRUIT_FORM_FAILED = "JOB_RECRUIT_FORM_FAILED"
export const JOB_RECRUIT_FORM_SUCCESS = "JOB_RECRUIT_FORM_SUCCESS"

export const JOB_RECRUIT_GROUP_FETCHING = "JOB_RECRUIT_GROUP_FETCHING"
export const JOB_RECRUIT_GROUP_FAILED = "JOB_RECRUIT_GROUP_FAILED"
export const JOB_RECRUIT_GROUP_SUCCESS = "JOB_RECRUIT_GROUP_SUCCESS"

export const JOB_RECRUIT_DETAIL_FETCHING = "JOB_RECRUIT_DETAIL_FETCHING"
export const JOB_RECRUIT_DETAIL_FAILED = "JOB_RECRUIT_DETAIL_FAILED"
export const JOB_RECRUIT_DETAIL_SUCCESS = "JOB_RECRUIT_DETAIL_SUCCESS"

export const JOBS_RECRUIT_FETCHING = "JOBS_RECRUIT_FETCHING"
export const JOBS_RECRUIT_FAILED = "JOBS_RECRUIT_FAILED"
export const JOBS_RECRUIT_SUCCESS = "JOBS_RECRUIT_SUCCESS"

export const JOB_RECRUIT_EDIT_FETCHING = "JOB_RECRUIT_EDIT_FETCHING"
export const JOB_RECRUIT_EDIT_FAILED = "JOB_RECRUIT_EDIT_FAILED"
export const JOB_RECRUIT_EDIT_SUCCESS = "JOB_RECRUIT_EDIT_SUCCESS"

export const JOBGROUP_INTERVIEWER_FETCHING = "JOBGROUP_INTERVIEWER_FETCHING"
export const JOBGROUP_INTERVIEWER_FAILED = "JOBGROUP_INTERVIEWER_FAILED"
export const JOBGROUP_INTERVIEWER_SUCCESS = "JOBGROUP_INTERVIEWER_SUCCESS"

export const HOMEPAGE_RECRUIT_FETCHING = "HOMEPAGE_RECRUIT_FETCHING"
export const HOMEPAGE_RECRUIT_FAILED = "HOMEPAGE_RECRUIT_FAILED"
export const HOMEPAGE_RECRUIT_SUCCESS = "HOMEPAGE_RECRUIT_SUCCESS"

export const DASHBOARD_RECRUIT_FETCHING = "DASHBOARD_RECRUIT_FETCHING"
export const DASHBOARD_RECRUIT_FAILED = "DASHBOARD_RECRUIT_FAILED"
export const DASHBOARD_RECRUIT_SUCCESS = "DASHBOARD_RECRUIT_SUCCESS"

export const MANPOWER_PROFILE_FETCHING = "MANPOWER_PROFILE_FETCHING";
export const MANPOWER_PROFILE_FAILED = "MANPOWER_PROFILE_FAILED";
export const MANPOWER_PROFILE_SUCCESS = "MANPOWER_PROFILE_SUCCESS";

export const MANPOWER_CERTIFICATE_FETCHING = "MANPOWER_CERTIFICATE_FETCHING";
export const MANPOWER_CERTIFICATE_FAILED = "MANPOWER_CERTIFICATE_FAILED";
export const MANPOWER_CERTIFICATE_SUCCESS = "MANPOWER_CERTIFICATE_SUCCESS";

export const PROBATION_FETCHING = "PROBATION_FETCHING"
export const PROBATION_FAILED = "PROBATION_FAILED"
export const PROBATION_SUCCESS = "PROBATION_SUCCESS"

export const RESULT_PROBATION_FETCHING = "RESULT_PROBATION_FETCHING"
export const RESULT_PROBATION_FAILED = "RESULT_PROBATION_FAILED"
export const RESULT_PROBATION_SUCCESS = "RESULT_PROBATION_SUCCESS"

export const PROBATION_DISCARD_FETCHING = "PROBATION_DISCARD_FETCHING"
export const PROBATION_DISCARD_FAILED = "PROBATION_DISCARD_FAILED"
export const PROBATION_DISCARD_SUCCESS = "PROBATION_DISCARD_SUCCESS"

export const PROBATION_EMPLOYEES_FETCHING = "PROBATION_EMPLOYEES_FETCHING"
export const PROBATION_EMPLOYEES_FAILED = "PROBATION_EMPLOYEES_FAILED"
export const PROBATION_EMPLOYEES_SUCCESS = "PROBATION_EMPLOYEES_SUCCESS"

export const PROBATION_EMPLOYEES_ID_FETCHING = "PROBATION_EMPLOYEES_ID_FETCHING"
export const PROBATION_EMPLOYEES_ID_FAILED = "PROBATION_EMPLOYEES_ID_FAILED"
export const PROBATION_EMPLOYEES_ID_SUCCESS = "PROBATION_EMPLOYEES_ID_SUCCESS"

export const PROBATION_EMPLOYEES_STATUS_FETCHING = "PROBATION_EMPLOYEES_STATUS_FETCHING"
export const PROBATION_EMPLOYEES_STATUS_FAILED = "PROBATION_EMPLOYEES_STATUS_FAILED"
export const PROBATION_EMPLOYEES_STATUS_SUCCESS = "PROBATION_EMPLOYEES_STATUS_SUCCESS"

export const EMPLOYEES_ROLE_ONE_TWO_FETCHING = "EMPLOYEES_ROLE_ONE_TWO_FETCHING"
export const EMPLOYEES_ROLE_ONE_TWO_FAILED = "EMPLOYEES_ROLE_ONE_TWO_FAILED"
export const EMPLOYEES_ROLE_ONE_TWO_SUCCESS = "EMPLOYEES_ROLE_ONE_TWO_SUCCESS"

export const ONBOARDING_LIST_FETCHING = "ONBOARDING_LIST_FETCHING"
export const ONBOARDING_LIST_FAILED = "ONBOARDING_LIST_FAILED"
export const ONBOARDING_LIST_SUCCESS = "ONBOARDING_LIST_SUCCESS"

export const ONBOARDING_REVIEWER_FETCHING = "ONBOARDING_REVIEWER_FETCHING"
export const ONBOARDING_REVIEWER_FAILED = "ONBOARDING_REVIEWER_FAILED"
export const ONBOARDING_REVIEWER_SUCCESS = "ONBOARDING_REVIEWER_SUCCESS"

export const ONBOARDING_EMPLOYEE_FETCHING = "ONBOARDING_EMPLOYEE_FETCHING"
export const ONBOARDING_EMPLOYEE_FAILED = "ONBOARDING_EMPLOYEE_FAILED"
export const ONBOARDING_EMPLOYEE_SUCCESS = "ONBOARDING_EMPLOYEE_SUCCESS"

export const ONBOARDING_EMPLOYEE_BYID_FETCHING = "ONBOARDING_EMPLOYEE_BYID_FETCHING"
export const ONBOARDING_EMPLOYEE_BYID_FAILED = "ONBOARDING_EMPLOYEE_BYID_FAILED"
export const ONBOARDING_EMPLOYEE_BYID_SUCCESS = "ONBOARDING_EMPLOYEE_BYID_SUCCESS"

export const TERMINATE_LIST_FETCHING = "TERMINATE_LIST_FETCHING"
export const TERMINATE_LIST_FAILED = "TERMINATE_LIST_FAILED"
export const TERMINATE_LIST_SUCCESS = "TERMINATE_LIST_SUCCESS"

export const TERMINATE_REVIEWER_FETCHING = "TERMINATE_REVIEWER_FETCHING"
export const TERMINATE_REVIEWER_FAILED = "TERMINATE_REVIEWER_FAILED"
export const TERMINATE_REVIEWER_SUCCESS = "TERMINATE_REVIEWER_SUCCESS"

export const TERMINATE_EMPLOYEE_FETCHING = "TERMINATE_EMPLOYEE_FETCHING"
export const TERMINATE_EMPLOYEE_FAILED = "TERMINATE_EMPLOYEE_FAILED"
export const TERMINATE_EMPLOYEE_SUCCESS = "TERMINATE_EMPLOYEE_SUCCESS"

export const TERMINATE_EMPLOYEE_BYID_FETCHING = "TERMINATE_EMPLOYEE_BYID_FETCHING"
export const TERMINATE_EMPLOYEE_BYID_FAILED = "TERMINATE_EMPLOYEE_BYID_FAILED"
export const TERMINATE_EMPLOYEE_BYID_SUCCESS = "TERMINATE_EMPLOYEE_BYID_SUCCESS"

export const RESULT_ESTIMATE_PERFORMANCE_FETCHING = "RESULT_ESTIMATE_PERFORMANCE_FETCHING"
export const RESULT_ESTIMATE_PERFORMANCE_FAILED = "RESULT_ESTIMATE_PERFORMANCE_FAILED"
export const RESULT_ESTIMATE_PERFORMANCE_SUCCESS = "RESULT_ESTIMATE_PERFORMANCE_SUCCESS"

export const EXIT_INTERVIEW_FETCHING = "EXIT_INTERVIEW_FETCHING"
export const EXIT_INTERVIEW_FAILED = "EXIT_INTERVIEW_FAILED"
export const EXIT_INTERVIEW_SUCCESS = "EXIT_INTERVIEW_SUCCESS"

export const RESULT_EXIT_INTERVIEW_FETCHING = "RESULT_EXIT_INTERVIEW_FETCHING"
export const RESULT_EXIT_INTERVIEW_FAILED = "RESULT_EXIT_INTERVIEW_FAILED"
export const RESULT_EXIT_INTERVIEW_SUCCESS = "RESULT_EXIT_INTERVIEW_SUCCESS"

export const REASON_EXIT_INTERVIEW_FETCHING = "REASON_EXIT_INTERVIEW_FETCHING"
export const REASON_EXIT_INTERVIEW_FAILED = "REASON_EXIT_INTERVIEW_FAILED"
export const REASON_EXIT_INTERVIEW_SUCCESS = "REASON_EXIT_INTERVIEW_SUCCESS"

export const EXIT_INTERVIEW_DISCARD_FETCHING = "EXIT_INTERVIEW_DISCARD_FETCHING"
export const EXIT_INTERVIEW_DISCARD_FAILED = "EXIT_INTERVIEW_DISCARD_FAILED"
export const EXIT_INTERVIEW_DISCARD_SUCCESS = "EXIT_INTERVIEW_DISCARD_SUCCESS"

export const EXIT_INTERVIEW_EMPLOYEES_FETCHING = "EXIT_INTERVIEW_EMPLOYEES_FETCHING"
export const EXIT_INTERVIEW_EMPLOYEES_FAILED = "EXIT_INTERVIEW_EMPLOYEES_FAILED"
export const EXIT_INTERVIEW_EMPLOYEES_SUCCESS = "EXIT_INTERVIEW_EMPLOYEES_SUCCESS"

export const EXIT_INTERVIEW_EMPLOYEES_STATUS_FETCHING = "EXIT_INTERVIEW_EMPLOYEES_STATUS_FETCHING"
export const EXIT_INTERVIEW_EMPLOYEES_STATUS_FAILED = "EXIT_INTERVIEW_EMPLOYEES_STATUS_FAILED"
export const EXIT_INTERVIEW_EMPLOYEES_STATUS_SUCCESS = "EXIT_INTERVIEW_EMPLOYEES_STATUS_SUCCESS"

export const ESTIMATE_PERFORMANCE_FETCHING = "ESTIMATE_PERFORMANCE_FETCHING"
export const ESTIMATE_PERFORMANCE_FAILED = "ESTIMATE_PERFORMANCE_FAILED"
export const ESTIMATE_PERFORMANCE_SUCCESS = "ESTIMATE_PERFORMANCE_SUCCESS"

export const ESTIMATE_PERFORMANCE_EMPLOYEES_FETCHING = "ESTIMATE_PERFORMANCE_EMPLOYEES_FETCHING"
export const ESTIMATE_PERFORMANCE_EMPLOYEES_FAILED = "ESTIMATE_PERFORMANCE_EMPLOYEES_FAILED"
export const ESTIMATE_PERFORMANCE_EMPLOYEES_SUCCESS = "ESTIMATE_PERFORMANCE_EMPLOYEES_SUCCESS"

export const ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_FETCHING = "ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_FETCHING"
export const ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_FAILED = "ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_FAILED"
export const ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_SUCCESS = "ESTIMATE_PERFORMANCE_EMPLOYEES_STATUS_SUCCESS"

export const ESTIMATE_LEVEL_FETCHING = "ESTIMATE_LEVEL_FETCHING"
export const ESTIMATE_LEVEL_FAILED = "ESTIMATE_LEVEL_FAILED"
export const ESTIMATE_LEVEL_SUCCESS = "ESTIMATE_LEVEL_SUCCESS"

export const USERS_VENDOR_FREE_FETCHING = "USERS_VENDOR_FREE_FETCHING"
export const USERS_VENDOR_FREE_FAILED = "USERS_VENDOR_FREE_FAILED"
export const USERS_VENDOR_FREE_SUCCESS = "USERS_VENDOR_FREE_SUCCESS"

export const NEW_CANDIDATES_FETCHING = "NEW_CANDIDATES_FETCHING"
export const NEW_CANDIDATES_FAILED = "NEW_CANDIDATES_FAILED"
export const NEW_CANDIDATES_SUCCESS = "NEW_CANDIDATES_SUCCESS"

export const INTERVIEWED_CANDIDATE_FETCHING = "INTERVIEWED_CANDIDATE_FETCHING"
export const INTERVIEWED_CANDIDATE_FAILED = "INTERVIEWED_CANDIDATE_FAILED"
export const INTERVIEWED_CANDIDATE_SUCCESS = "INTERVIEWED_CANDIDATE_SUCCESS"

export const SCHEDULEANDCANDIDATES_FETCHING = "SCHEDULEANDCANDIDATES_FETCHING"
export const SCHEDULEANDCANDIDATES_FAILED = "SCHEDULEANDCANDIDATES_FAILED"
export const SCHEDULEANDCANDIDATES_SUCCESS = "SCHEDULEANDCANDIDATES_SUCCESS"

export const CANDIDATES_ALREADY_SENT_FETCHING = "CANDIDATES_ALREADY_SENT_FETCHING"
export const CANDIDATES_ALREADY_SENT_FAILED = "CANDIDATES_ALREADY_SENT_FAILED"
export const CANDIDATES_ALREADY_SENT_SUCCESS = "CANDIDATES_ALREADY_SENT_SUCCESS"

export const PASSED_CANDIDATES_COMPANY_FETCHING = "PASSED_CANDIDATES_COMPANY_FETCHING"
export const PASSED_CANDIDATES_COMPANY_FAILED = "PASSED_CANDIDATES_COMPANY_FAILED"
export const PASSED_CANDIDATES_COMPANY_SUCCESS = "PASSED_CANDIDATES_COMPANY_SUCCESS"

export const HISTORY_CANDIDATES_JOBS_MATCHING_FETCHING = "HISTORY_CANDIDATES_JOBS_MATCHING_FETCHING"
export const HISTORY_CANDIDATES_JOBS_MATCHING_FAILED = "HISTORY_CANDIDATES_JOBS_MATCHING_FAILED"
export const HISTORY_CANDIDATES_JOBS_MATCHING_SUCCESS = "HISTORY_CANDIDATES_JOBS_MATCHING_SUCCESS"

export const HISTORY_MANPOWERS_SENDING_FETCHING = "HISTORY_MANPOWERS_SENDING_FETCHING"
export const HISTORY_MANPOWERS_SENDING_FAILED = "HISTORY_MANPOWERS_SENDING_FAILED"
export const HISTORY_MANPOWERS_SENDING_SUCCESS = "HISTORY_MANPOWERS_SENDING_SUCCESS"

export const WARNING_LETTER_FETCHING = "WARNING_LETTER_FETCHING"
export const WARNING_LETTER_FAILED = "WARNING_LETTER_FAILED"
export const WARNING_LETTER_SUCCESS = "WARNING_LETTER_SUCCESS"

export const WARNING_LEVEL_FETCHING = "WARNING_LEVEL_FETCHING"
export const WARNING_LEVEL_FAILED = "WARNING_LEVEL_FAILED"
export const WARNING_LEVEL_SUCCESS = "WARNING_LEVEL_SUCCESS"

export const ASSET_MANAGEMENT_FETCHING = "ASSET_MANAGEMENT_FETCHING"
export const ASSET_MANAGEMENT_FAILED = "ASSET_MANAGEMENT_FAILED"
export const ASSET_MANAGEMENT_SUCCESS = "ASSET_MANAGEMENT_SUCCESS"

export const POLICY_FETCHING = "POLICY_FETCHING";
export const POLICY_FAILED = "POLICY_FAILED";
export const POLICY_SUCCESS = "POLICY_SUCCESS";

export const POLICY_ACTIVE_FETCHING = "POLICY_ACTIVE_FETCHING";
export const POLICY_ACTIVE_FAILED = "POLICY_ACTIVE_FAILED";
export const POLICY_ACTIVE_SUCCESS = "POLICY_ACTIVE_SUCCESS";

export const TIMELINE_FETCHING = "TIMELINE_FETCHING"
export const TIMELINE_FAILED = "TIMELINE_FAILED"
export const TIMELINE_SUCCESS = "TIMELINE_SUCCESS"

export const TIMELINE_MONTH_FETCHING = "TIMELINE_MONTH_FETCHING"
export const TIMELINE_MONTH_FAILED = "TIMELINE_MONTH_FAILED"
export const TIMELINE_MONTH_SUCCESS = "TIMELINE_MONTH_SUCCESS"

export const NOTIFICATION_ALERT_OPEN = "NOTIFICATION_ALERT_OPEN"
export const NOTIFICATION_ALERT_CLOSE = "NOTIFICATION_ALERT_CLOSE"

export const MANPOWER_PLANNING_FETCHING = "MANPOWER_PLANNING_FETCHING"
export const MANPOWER_PLANNING_FAILED = "MANPOWER_PLANNING_FAILED"
export const MANPOWER_PLANNING_SUCCESS = "MANPOWER_PLANNING_SUCCESS"

export const CONTRACT_FETCHING = "CONTRACT_FETCHING"
export const CONTRACT_FAILED = "CONTRACT_FAILED"
export const CONTRACT_SUCCESS = "CONTRACT_SUCCESS"

export const NEW_EMPLOYEE_FETCHING = "NEW_EMPLOYEE_FETCHING"
export const NEW_EMPLOYEE_FAILED = "NEW_EMPLOYEE_FAILED"
export const NEW_EMPLOYEE_SUCCESS = "NEW_EMPLOYEE_SUCCESS"

export const RESIGN_EMPLOYEE_FETCHING = "RESIGN_EMPLOYEE_FETCHING"
export const RESIGN_EMPLOYEE_FAILED = "RESIGN_EMPLOYEE_FAILED"
export const RESIGN_EMPLOYEE_SUCCESS = "RESIGN_EMPLOYEE_SUCCESS"

export const EMAIL_FETCHING = "EMAIL_FETCHING"
export const EMAIL_FAILED = "EMAIL_FAILED"
export const EMAIL_SUCCESS = "EMAIL_SUCCESS"