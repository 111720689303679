import { httpClient } from "./httpClient";

const allPayruns = () => {
  return httpClient.get(`payment/payruns`);
};

const getPayRunsId = (idPayrun) => {
  return httpClient.get("payment/payruns/" + idPayrun);
};

const createPayruns = (formData) => {
  return httpClient.post(`payment/payruns`, formData);
};

const getPayslip = (filter, date) => {
  return httpClient.get(`payment/payslip?filter=${filter}&date=${date}`);
};

const getPayslipByIdPayrunDetail = (idEmployees, date) => {
  return httpClient.get(`payment/payslip/${idEmployees}?date=${date}`);
};

const getPayslipByEmployee = (date, filter) => {
  return httpClient.get(`payment/payslip?date=${date}&filter=${filter}`);
};

const deletePayroll = (idPayrun) => {
  return httpClient.delete(`payment/payruns/${idPayrun}`);
};

const updateClosePayroll = (idPayrun) => {
  return httpClient.put(`payment/payruns/${idPayrun}/close`, idPayrun);
};

const updatePayrunDetail = (formData) => {
  return httpClient.put("payment/payruns", formData);
};

const getPayrunsDetailById = (idPayrun) => {
  return httpClient.get("payment/payruns/" + idPayrun + "/detail");
};

const uploadAddOn = (formData) => {
  return httpClient.post("payment/payruns/upload", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export default {
  allPayruns,
  getPayRunsId,
  createPayruns,
  getPayslip,
  getPayslipByIdPayrunDetail,
  getPayslipByEmployee,
  deletePayroll,
  updateClosePayroll,
  updatePayrunDetail,
  getPayrunsDetailById,
  uploadAddOn
};
