import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
    Card,
    styled,
    Typography
} from '@mui/material';

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

import StatusRequest from "./stausRequest";
import fileService from '../../../../services/file.service';

const StyledCard = styled(Card)({
  borderRadius: 16,
  padding:"8px 16px 24px 8px",
  position:"relative",
  "& .div-root":{
    display:"flex",
    alignItems:"center",
  },
  "& .div-status":{
      height:"100%",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      marginRight: "8px"
  },
  "& .div-main-request":{
      display:"flex",
      justifyContent:"space-between",
      "& .left":{
        marginRight: "8px",
        "& .text-name":{
          fontSize: "16px",
          fontWeight: "700",
          
        },
        "& .text-ot":{
          fontSize: "14px"
        }
      },
      "& .right":{
        minWidth:"100px",
        display:"flex",
        flexDirection:"column",
        alignItems:"flex-end"
      }
  },
  "& .div-reason":{
    marginBottom: "8px",
  },
  "& .divider":{
    width:"100%",
    borderBottom: "1px solid #C4C4C4",
    margin:"8px 0px"
  },
  "& .text-body-bold":{
    fontSize: "14px",
    fontWeight:"500"
  },
  "& .text-body":{
    color:"#757575",
    fontWeight:"400"
  },
  "& .div-approved":{
    marginBottom: "8px",
    "& .div-each-approved":{
      display:"flex",
      justifyContent:"space-between",
      "& .right":{
        display:"flex",
        flexDirection:"column",
        alignItems:"flex-end"
      }
    }
  },
  "& .icon-expand":{
    position:"absolute",
    bottom:"0px",
    left:"50%",
    transform:"translate(-50%, 20%)",
    color:"#C4C4C4",
    fontSize: "32px"
  },
  "& .icon-delete":{
    position:"absolute",
    bottom:"0px",
    right:"0px",
    transform:"translate(-50%, -20%)",
    color:"#C4C4C4",
    fontSize: "24px",
  }
});

const CardRequest = ({ request, handleClickWithDraw }) => {
    const [openExpand, setOpenExpand] = useState(false);
    const { result: payrollSetting } = useSelector(
        (state) => state.payrollSetting
    );
    const whatLevel = (row) => {
        if (row.isDoubleApproval) {
          if (row.isManagerLV1Approve === 1) {
            return 2;
          } else {
            return 1;
          }
        } else {
          return null;
        }
    };

    const isRequestApprove = (row) => {
        if (row.idRequestTimeWithdraw || row.idLeave) {
          return row.isApprove;
        } else {
          if (
            row.isManagerLV1Approve === null &&
            row.isManagerLV2Approve === null
          ) {
            return null;
          } else {
            if (row.isDoubleApproval) {
              if (row.isManagerLV1Approve === null) {
                return null;
              } else if (row.isManagerLV1Approve === 1) {
                if (row.isManagerLV2Approve === null) {
                  return null;
                } else {
                  return row.isManagerLV2Approve;
                }
              } else {
                return row.isManagerLV1Approve;
              }
            } else {
              if (row.approvalLevel === 1) {
                if (row.isManagerLV1Approve === null) {
                  return null;
                } else {
                  return row.isManagerLV1Approve;
                }
              } else {
                if (row.isManagerLV2Approve === null) {
                  return null;
                } else {
                  return row.isManagerLV2Approve;
                }
              }
            }
          }
        }
    };

    const renderOT = (row) => {
        return (
          <Fragment>
            {row.xWorkingDailyHoliday > 0 && (
              <Typography style={{ fontWeight: 500 }} color="text.third" className="text-ot">
                OT x {payrollSetting.xWorkingDailyHoliday} ={" "}
                {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)} ชม.
              </Typography>
            )}
            {row.xWorkingMonthlyHoliday > 0 && (
              <Typography style={{ fontWeight: 500 }} color="text.third" className="text-ot">
                OT x {payrollSetting.xWorkingMonthlyHoliday} ={" "}
                {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)} ชม.
              </Typography>
            )}
            {row.xOT > 0 && (
              <Typography style={{ fontWeight: 500 }} color="text.third" className="text-ot">
                OT x {payrollSetting.xOT} = {parseFloat(row.xOT / 60).toFixed(2)}{" "}
                ชม.
              </Typography>
            )}
            {row.xOTHoliday > 0 && (
              <Typography style={{ fontWeight: 500 }} color="text.third" className="text-ot">
                OT x {payrollSetting.xOTHoliday} ={" "}
                {parseFloat(row.xOTHoliday / 60).toFixed(2)} ชม.
              </Typography>
            )}
          </Fragment>
        );
    };

    const disabledWithDraw = (row) => {
      if (row.isManagerLV1Approve === 0 || row.isManagerLV1Approve === 0) {
        return false;
      } else {
        return true;
      }
    };

    return (
        <StyledCard>
          <div className="div-root" onClick={() => setOpenExpand(!openExpand)}>
            <div className="div-status">
                <StatusRequest
                    step={whatLevel(request)}
                    status={isRequestApprove(request)}
                    active={request.isActive}
                />
            </div>
            <div style={{ width: "100%" }}>
                <div className="div-main-request">
                    <div className="left">
                        <Typography className="text-name">{request.name}</Typography>
                        {(request.idRequestType === 2 || request.idRequestType === 3) && renderOT(request)}
                    </div>
                    <div className="right">
                        {request.isFullDay ? (
                            <Fragment>
                            {request.startText === request.endText ? (
                              <Typography>
                                  {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                                      "D/MM/BBBB"
                                  )}
                              </Typography>
                            ) : (
                              <Fragment>
                                <Typography>
                                    {`${dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                                        "D/MM/BBBB"
                                    )} -`}
                                </Typography>
                                <Typography>
                                    {dayjs(request.endText, "DD/MM/YYYY HH:mm").format("D/MM/BBBB")}
                                </Typography>
                              </Fragment>
                            )}
                            </Fragment>
                        ) : (
                            <Fragment>
                              <div style={{ display:"flex", flexDirection:"column", alignItems:"flex-end" }}>
                                  <Typography>
                                      {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                                          "D/MM/BBBB"
                                      )}
                                  </Typography>
                                  <Typography>
                                      {`${dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                                          "HH:mm"
                                      )} - ${dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                                          "HH:mm"
                                      )}`}
                                  </Typography>
                              </div>
                            </Fragment>
                        )}
                    </div>
                </div>
                {openExpand && (
                  <Fragment>
                    <div className="divider" />
                    <div className="div-reason">
                      {request.idLeave ?
                        <Fragment>
                          <Typography 
                            className="text-body-bold"
                          >
                            {`เหตุผล : ${request.description || "-"}`}
                          </Typography>
                          {request.filename && 
                            <div style={{ marginTop: "8px" }}>
                              <div
                                style={{ width:"fit-content" }}
                                onClick={async () => {
                                  await fileService.downloadBucketFile(`leave/${request.idCompany}/${request.idLeave}/${request.filename}`).then(res => {
                                    const url = window.URL.createObjectURL(new Blob([res.data]));       
                                    const link = document.createElement('a');       
                                    link.href = url;       
                                    link.setAttribute('download', request.filename);       
                                    document.body.appendChild(link);       
                                    link.click();
                                  });
                                }}
                              >
                                <i className="fa-regular fa-file" style={{ color:"rgba(0, 0, 0, 0.54)" }} />
                              </div>
                            </div>
                          }
                        </Fragment>
                        :
                        <Fragment>
                          <Typography className="text-body-bold">{request.reasonName}</Typography>
                          <Typography className="text-body-bold text-body">{`เหตุผล : ${request.otherReason || "-"}`}</Typography>
                        </Fragment>
                      }
                    </div>
                    {(request.isApprove !== null && request.isApprove !== undefined) && (
                      <Fragment>
                        <div className="divider" />
                        <div className="div-approved">
                          <div className="div-each-approved">
                            <div>
                              <Typography className="text-body-bold">อนุมัติโดย</Typography>
                              <Typography className="text-body-bold text-body">{`${request.manager_firstname_TH} ${request.manager_lastname_TH}`}</Typography>
                              <Typography className="text-body-bold text-body">{request.manager_email}</Typography>
                            </div>
                            <div className="right">
                              <Typography className="text-body-bold text-body">{dayjs(request.approveDateText, "DD/MM/YYYY HH:mm").format("D/MM/BBBB")}</Typography>
                              <Typography className="text-body-bold text-body">{dayjs(request.approveDateText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
                            </div>
                          </div>
                          {request.commentManager && 
                            <Typography className="text-body-bold text-body">{`เหตุผลที่ไม่อนุมัติ : ${request.commentManager}`}</Typography>
                          }
                        </div>
                      </Fragment>
                    )}
                    {(request.isManagerLV1Approve !== null && request.isManagerLV1Approve !== undefined) && (
                      <Fragment>
                        <div className="divider" />
                        <div className="div-approved">
                          <div className="div-each-approved">
                            <div>
                              <Typography className="text-body-bold">{`อนุมัติ${request.idRequestType === 2 ? `ลำดับที่ 1 ` : ""}โดย`}</Typography>
                              <Typography className="text-body-bold text-body">{`${request.managerLV1_firstname_TH} ${request.managerLV1_lastname_TH}`}</Typography>
                              <Typography className="text-body-bold text-body">{request.managerLV1_email}</Typography>
                            </div>
                            <div className="right">
                              <Typography className="text-body-bold text-body">{dayjs(request.managerLV1ApproveDateText, "DD/MM/YYYY HH:mm").format("D/MM/BBBB")}</Typography>
                              <Typography className="text-body-bold text-body">{dayjs(request.managerLV1ApproveDateText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
                            </div>
                          </div>
                          {request.commentManagerLV1 && 
                            <Typography className="text-body-bold text-body">{`เหตุผลที่ไม่อนุมัติ : ${request.commentManagerLV1}`}</Typography>
                          }
                        </div>
                      </Fragment>
                    )}
                    {(request.isManagerLV2Approve !== null && request.isManagerLV2Approve !== undefined) && (
                      <Fragment>
                        <div className="divider" />
                        <div className="div-approved">
                          <div className="div-each-approved">
                            <div>
                              <Typography className="text-body-bold">{`อนุมัติ${request.idRequestType === 2 ? `ลำดับที่ 2 ` : ""}โดย`}</Typography>
                              <Typography className="text-body-bold text-body">{`${request.managerLV2_firstname_TH} ${request.managerLV2_lastname_TH}`}</Typography>
                              <Typography className="text-body-bold text-body">{request.managerLV2_email}</Typography>
                            </div>
                            <div className="right">
                              <Typography className="text-body-bold text-body">{dayjs(request.managerLV2ApproveDateText, "DD/MM/YYYY HH:mm").format("D/MM/BBBB")}</Typography>
                              <Typography className="text-body-bold text-body">{dayjs(request.managerLV2ApproveDateText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
                            </div>
                          </div>
                          {request.commentManagerLV2 && 
                            <Typography className="text-body-bold text-body">{`เหตุผลที่ไม่อนุมัติ : ${request.commentManagerLV2}`}</Typography>
                          }
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
            </div>
          </div>
          {openExpand ? 
            <KeyboardArrowUpRoundedIcon className="icon-expand" /> :
            <KeyboardArrowDownRoundedIcon className="icon-expand" />
          }
          {openExpand && !request.idRequestTimeWithdraw &&
            !request.isWithdraw &&
            isRequestApprove(request) !== 0 &&
            disabledWithDraw(request) && (
            <DeleteOutlineRoundedIcon
              className="icon-delete"
              onClick={() => handleClickWithDraw(request)}
            />
          )}
        </StyledCard>
    );
};

export default CardRequest;