import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material";

import {
  Close,
  Edit,
  Save,
} from "@mui/icons-material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { getEmployeeLeaveQuotaByIdEmp } from "../../../../../../actions/employee";
import DialogLeave from "./DialogLeave";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const Leave = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {}
  });

  const openDrawer = (data) => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: {
        leaveGroupName: data.name,
        idLeaveQuota: data.leaveQuota? data.leaveQuota.idLeaveQuota: null,
        leaveValue: data.leaveQuota? data.leaveQuota.leaveValue: null,
        carry: data.leaveQuota? data.leaveQuota.carry: null,
        idLeaveType: data.idLeaveType,
        idLeaveGroup: data.idLeaveGroup,
      }
    }))
  }

  const onDrawerClose = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  useEffect(() => {
    if (employeeProfile) {
      dispatch(getEmployeeLeaveQuotaByIdEmp(employeeProfile.idEmployees));
    }
  }, [employeeProfile]);

  // const onClickAddRight = (title) => {
  //   let index = listLeaves.findIndex((leave) => {
  //     return leave.title === title;
  //   });
  //   let temp = listLeaves;
  //   temp[index].right = listLeaves[index].right + 1;
  //   setListLeaves([...temp]);
  // };

  // const onClickRemoveRight = (title) => {
  //   let index = listLeaves.findIndex((leave) => {
  //     return leave.title === title;
  //   });
  //   let temp = listLeaves;
  //   temp[index].right = listLeaves[index].right - 1;
  //   setListLeaves([...temp]);
  // };

  return (
    <div>
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            จำนวนการลา
          </StyledHeadLabel>
          {/* <div>
            {edit && (
              <Fragment>
                <ButtonBlue
                  size="small"
                  startIcon={<Close />}
                  onClick={() => setEdit(false)}
                  variant="outlined"
                  style={{ marginRight: "5px" }}
                >
                  ยกเลิก
                </ButtonBlue>
                <ButtonBlue
                  size="small"
                  startIcon={<Save />}
                  onClick={() => setEdit(false)}
                  variant="contained"
                >
                  บันทึก
                </ButtonBlue>
              </Fragment>
            )}
            {!edit && (
              <ButtonBlue
                variant="text"
                size="small"
                startIcon={<Edit />}
                // onClick={() => setEdit(true)}
                onClick={() => {openDrawer()}}
              >
                แก้ไข
              </ButtonBlue>
            )}
          </div> */}
        </div>
        {employeeProfile && (
          <TableContainer
            style={{
              border: "1px solid rgba(0,0,0,0.1)",
              borderRadius: "16px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 250 }}>ประเภทการลา</TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    สิทธิการลา/สิทธิสะสม (วัน)
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 50 }}>
                    ใช้ไป (วัน)
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 50 }}>
                    คงเหลือ (วัน)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveEmployeesList &&
                  leaveEmployeesList.map((leave) => {
                    return (
                      <TableRow key={leave.idLeaveType}>
                        <TableCell
                          style={{ display: "flex", minHeight: "24.5px" }}
                        >
                          <Box display="flex" alignItems="center" gap="8px">
                            <IconButton
                              aria-label="edit"
                              onClick={() => {openDrawer(leave)}}
                              size="small"
                              disabled={leave.isLeaveCompensate === 1}
                            >
                              <Edit fontSize="inherit" />
                            </IconButton>
                            <Typography>{leave.name}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {leave.leaveValue === null? (
                            <Typography>ไม่จำกัด</Typography>
                          ): (
                            <Typography>
                              {leave.leaveValue}
                              {`${leave.carryValue ? ("/" + leave.carryValue) : ""}`}
                              {leave.isLeaveCompensate === 1? " ชม.":""}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">{leave.used}</TableCell>
                        <TableCell align="center">
                          {leave.leaveValue === null? (
                            <Typography>ไม่จำกัด</Typography>
                          ): (
                            <Typography align="center">
                              {leave.remain}
                              {`${leave.remainCarry ? ("/" + leave.remainCarry) : ""}`}
                              {leave.isLeaveCompensate === 1? " ชม.":""}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledWrapBranch>

      <DialogLeave
        drawerConfig={drawerConfig}
        onClose={onDrawerClose}
      />
    </div>
  );
};

export default Leave;
