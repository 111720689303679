import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";

import { Avatar, styled, Typography, Divider, Box } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import CardStyle from "../../shared/general/Card";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AlertResponse from "../../shared/general/AlertResponse";

import DialogEmergency from "./DialogEmergency"

const StyledLeft = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 24,
  //position: "relative",
  "& .wrap-avater": {
    marginBottom: 36,
    "& .MuiAvatar-root": {
      width: 160,
      height: 160,
      borderRadius: 8,
      padding: 8,
      border: "1px dashed rgba(145, 158, 171, 0.32)",
    },
  },
});

const StyledTextFieldTheme = styled(TextFieldTheme)(({}) => ({
  marginBottom: 16,
}));

const StyledImageProfile = styled("div")({
  width: "100%",
  "& .dropzone-profile": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    height: 160,
    width: 160,
    margin: "auto",
    borderRadius: "50%",
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
  "& .inner-dropzone-profile": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    height: "100%",
    outline: "none",
    display: "flex",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    position: "relative",
    "& .placeholder": {
      //display: "none",
    },
    "&:hover .placeholder": {
      zIndex: 9,
      display: "flex",
    },
  },
  "& .uploadImage": {
    width: "105%",
    zIndex: 8,
    cursor:"initial"
  },
  "& .placeholder": {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(244, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .placeholderImageProfile": {
    display: "none",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  "& .placeholderLabel": {
    color: "rgb(255, 255, 255)",
  },
  "& .wrapCaptionImage": {
    marginTop: 16,
    textAlign: "center",
  },
  "& .captionImage": {
    color: "#212b36",
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapContactEmergency = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  "& div": {
    width: "100%",
    textAlign: "center",
    marginBottom: 12,
  },
});

const StyledWrapHead = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
});

const StyledTextEmpty = styled(Typography)({
  fontStyle: "oblique",
  fontWeight: 400,
  color: "#b8b8b8",
});

const LeftPanel = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [openDialogEmergency, setOpenDialogEmergency] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialogEmergency(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const thumbs = () => {
    if (fileSelected.length === 0) {
      return (
        <Avatar
          className={`uploadImage`}
          src={`${userProfile.imageProfile}`}
          style={{ height: "100%" }}
        />
      );
    } else {
      return (
        <img
          alt={fileSelected[0].firstname_TH}
          src={fileSelected[0].preview}
          className={`uploadImage`}
        />
      );
    }
  };

  return (
    <CardStyle>
      {userProfile && (
        <StyledLeft>
          <Box style={{ width: "100%", marginBottom: 18 }}>
            <div>
              <StyledImageProfile>
                <div className="dropzone-profile">
                  <div className="inner-dropzone-profile">
                    <Fragment>{thumbs()}</Fragment>
                  </div>
                </div>
              </StyledImageProfile>
            </div>
          </Box>
          <div>
            <Typography
              align="center"
              style={{ fontWeight: "bold", fontSize: 18 }}
            >
              {userProfile.nickname_TH && userProfile.nickname_TH !== ""
                ? `${userProfile.firstname_TH} ${userProfile.lastname_TH} (${userProfile.nickname_TH})`
                : `${userProfile.firstname_TH} ${userProfile.lastname_TH}`}
            </Typography>
            <Typography align="center">{userProfile.positionsName}</Typography>
            <Typography align="center" color="text.third" variant="body2">
              {userProfile.departmentName}
            </Typography>
            <Typography
              align="center"
              color="text.secondary"
            >{`เริ่มงาน ${dayjs(userProfile.hiringDate).format(
              "DD MMM YYYY"
            )}`}</Typography>
          </div>
          <StyledDivider />
          <div style={{ marginTop: 16 }}></div>
          <StyledWrapHead>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              ผู้ติดต่อฉุกเฉิน
            </Typography>
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setOpenDialogEmergency(true)}
            >
              แก้ไข
            </ButtonBlue>
          </StyledWrapHead>

          <StyledWrapContactEmergency>
            <div>
              <StyledHeadLabel color="text.secondary">
                ชื่อผู้ติดต่อ
              </StyledHeadLabel>
              {userProfile.emergencyContact &&
              userProfile.emergencyContact !== "" ? (
                <Typography variant="h6">
                  {userProfile.emergencyContact}
                </Typography>
              ) : (
                <StyledTextEmpty>โปรดระบุข้อมูล</StyledTextEmpty>
              )}
            </div>
            <div>
              <StyledHeadLabel color="text.secondary">
                ความสัมพันธ์
              </StyledHeadLabel>
              {userProfile.emergencyRelationship &&
              userProfile.emergencyRelationship !== "" ? (
                <Typography variant="h6">
                  {userProfile.emergencyRelationship}
                </Typography>
              ) : (
                <StyledTextEmpty>โปรดระบุข้อมูล</StyledTextEmpty>
              )}
            </div>
            <div>
              <StyledHeadLabel color="text.secondary">
                เบอร์โทรศัพท์
              </StyledHeadLabel>
              {userProfile.emergencyPhone &&
              userProfile.emergencyPhone !== "" ? (
                <Typography variant="h6">
                  {userProfile.emergencyPhone.replace(
                  /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                  "$1-$2-$3"
                )}
                </Typography>
              ) : (
                <StyledTextEmpty>โปรดระบุข้อมูล</StyledTextEmpty>
              )}
            </div>
          </StyledWrapContactEmergency>
        </StyledLeft>
      )}

      <DialogEmergency
        open={openDialogEmergency}
        handleCloseDialog={handleCloseDialog}
        handleChangeAlertType={handleChangeAlertType}
        handleOpenAlert={handleOpenAlert}
      />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </CardStyle>
  );
};

export default LeftPanel;
