import React from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import dayjs from "dayjs";
import ChipLetterStatus from "./chipLetterStatus";
import ChipWarningLevel from "./chipWarningLevel";

const DialogDetail = ({ open, handleClose, data }) => {
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
      <DialogTitle>หนังสือเตือน</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={"1.25rem"} fontWeight={500} textAlign={"center"}>
              {data.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'row'} gap={2} flexWrap={"wrap"}>
              <Avatar src={data.imageURL} />
              <Stack flexGrow={1}>
                <Typography fontWeight={700}>{`${data.firstname_TH} ${data.lastname_TH}`}</Typography>
                <Typography variant="body2" fontWeight={700} color="text.secondary">
                  {`ตำแหน่ง: ${data.positionName} `}
                </Typography>
                <Typography variant="body2" color="text.secondary">{`ส่วนงาน: ${data.departmentName}`}</Typography>
                <Typography variant="body2" color="text.secondary">{`แผนก: ${data.sectionName}`}</Typography>
              </Stack>
              <Stack gap={1} justifyContent={"flex-end"}>
                <Typography variant="subtitle2" color={'text.secondary'} textAlign={"end"} noWrap>
                  {`วันที่: ${dayjs(data.createDate).format("DD/MM/YYYY")}`}
                </Typography>
                <ChipLetterStatus status={data.accept} />
                {data.file && (
                  <Button
                    variant="outlined"
                    component={Link}
                    href={data.file}
                    target="_blank"
                    startIcon={<AttachFileIcon />}
                  >
                    ไฟล์แนบ
                  </Button>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography>รายละเอียด</Typography>
            <Box
              marginTop={1}
              padding={1}
              border={"1px solid #212b36"}
              borderRadius={1}
              minHeight={"80px"}
            >
              <Typography>{data.detail}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} display={"flex"} gap={2}>
            <Typography fontSize={"1.25rem"} fontWeight={500}>
              ระดับการเตือน
            </Typography>
            <ChipWarningLevel id={data.idWarningLevel} label={data.level} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>ปิด</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDetail;
