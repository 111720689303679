import React, { useState, useEffect, Fragment } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  DialogContentText,
  Avatar,
  Divider,
  Rating,
  Container,
  Box,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import { getJobPositionById, getAllJob } from "../../../../../../actions/job";

//icon
import BusinessIcon from "@mui/icons-material/Business";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SchoolIcon from "@mui/icons-material/School";
import AdjustIcon from "@mui/icons-material/Adjust";

import { Radar, Doughnut } from "react-chartjs-2";
import LastPerson from "./lastPerson/lastPerson";
import CardStyle from "../../../../shared/general/Card";



const useStyles = makeStyles(() => ({
  root: {
    minHeight: "calc(100vh - 20px)",
    "& .TextWhite": {
      color: "white",
    },
  },
  paper: {
    minWidth: 350,
    minHeight: 150,
    padding: 30,
    marginBottom: 20,
    maxWidth: 1000,
  },
  headDiv: {
    display: "flex",
    justifyContent: "center",
  },
  colorHeaderText: {
    color: "#007AFC",
    marginBottom: 20,
    fontWeight: "bold",
  },
  itemSize: {
    width: "90%",
  },
  text: {
    fontSize: 19,
    fontWeight: "bold",
    marginBottom: 0,
  },
  text2: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 0,
    padding: 5,
    border: "1px solid #B0B0B0",
  },
  headText: {
    fontSize: 18,
    marginBottom: 0,
  },
  icon: {
    marginBottom: 3,
    marginRight: 20,
  },
  image: {
    width: "30px",
    marginBottom: 3,
    marginRight: 5,
  },
  avatar: {
    width: 50,
    height: 50,
  },
  boxComment: {
    width: "100%",
    borderRadius: "25px",
    border: "2px solid #E0E0E0",
    padding: "13px",
    overflowWrap: "anywhere",
  },
  divDetailComment: {
    width: "calc(100% - 15px)",
    paddingLeft: "15px",
  },
  textNameComment: {
    marginLeft: "5px",
    fontSize: "20px",
    fontWeight: "bold",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "#CCCACA",
  },
  gridItemHeader: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
  },
  textHeader: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  bigAvatar: {
    width: 120,
    height: 120,
    marginRight: "30px",
  },
  eachDivTopic: {
    padding: "30px",
  },
  TopicText: {
    color: "#1565c0",
  },
  topicHeadCard: {
    fontWeight: 600,
    color: "#007afc",
  },
  link: {
    textDecoration: "none",
  },
  editBtn: {
    backgroundColor: "#e0e0e0",
    color: "#616161",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#616161",
    },
  },
}));

const Popup_education = ({ open, data, handleClose }) => {
  const classes = useStyles();
  return (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CastForEducationIcon className={classes.icon} />
              Education Required
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: "1.2rem", color: "black" }}
            >
              {data}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
  );
};

const EducationItem = ({ label }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: "flex", margin: "20px 0", alignItems: "center" }}>
      <SchoolIcon style={{ marginRight: "20px" }} />
      <Typography variant="h6">{label}</Typography>
      <IconButton onClick={handleClickOpen} size="large">
        <VisibilityIcon style={{ color: "#3f51b5" }} />
      </IconButton>
      <Popup_education open={open} data={label} handleClose={handleClose} />
    </div>
  );
};
const RequirementItem = () => {
  return (
    <div style={{ display: "flex", margin: "20px 0", alignItems: "center" }}>
      <AdjustIcon style={{ marginRight: "20px" }} />
      <Typography>XXXXXXXXXXXXXXXX</Typography>
    </div>
  );
};

const ReviewItem = () => {
  const classes = useStyles();

  return (
    <div style={{ display: "flex", margin: "20px 0" }}>
      <Avatar className={classes.avatar} />
      <div className={classes.divDetailComment}>
        <Typography className={classes.textNameComment}>
          สมหมาย ขยันขันแข็ง
        </Typography>
        <Rating value={3} readOnly />
        <div className={classes.boxComment}>ดีมากๆเลยครับ</div>
      </div>
    </div>
  );
};

const ShowDetailPosition = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user: AuthUser } = useSelector((state) => state.auth);
  const { result: data } = useSelector((state) => state.jobPosition);

  useEffect(() => {
    dispatch(
      getJobPositionById(
        props.match.params.JobPosition,
        props.match.params.backPosition,
        props.match.params.id,
        props.match.params.jobPosition
      )
    ).then((res) => {
      console.log("res : ", res);
      dispatch(getAllJob()).then((res) => {
        console.log("res : ", res);
      });
    });
  }, []);

  const plugin = {
    beforeDraw: (chart) => {
      const { ctx, scales, config } = chart;
      const { xCenter, yCenter, drawingArea: radius } = scales.r;
      ctx.save();
      ctx.arc(
        Math.abs(xCenter),
        Math.abs(yCenter),
        Math.abs(radius),
        0,
        Math.abs(Math.PI * 2)
      );
      ctx.fillStyle = "rgba(238, 238, 238,0.6)";
      ctx.fill();
      ctx.restore();
    },
  };

  const plugin2 = {
    beforeDraw: (chart) => {
      const ctx = chart.canvas.getContext("2d");
      ctx.save();
      console.log(ctx);
      ctx.canvas.parentNode.style.height = "400px";
      ctx.canvas.parentNode.style.width = "400px";
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = "white";
      ctx.restore();
    },
  };

  const chartData = {
    labels: [1, 2, 3, 4, 5],
    datasets: [
      {
        data: [2, 7, 6, 8, 12],
        backgroundColor: "rgba(38, 166, 154,0.3)",
        borderColor: "rgba(38, 166, 154,0.5)",
        borderWidth: 1,
      },
    ],
  };

  const option = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        suggestedMin: 1,
        suggestedMax: 20,
        pointLabels: {
          font: { size: 20 },
        },
        ticks: {
          maxTicksLimit: 4,
          display: false,
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
  };

  const donutData = {
    labels: ["Critical Task", "Task"],
    datasets: [
      {
        data: [80, 200],
        backgroundColor: ["rgba(38, 166, 154,0.6)", "rgba(0,122,252,0.6)"],
        borderWidth: 1,
      },
    ],
  };

  const option2 = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Box sx={{ marginTop: 4 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" gutterBottom>
                Form for entering detail of {props.match.params.jobPosition}
              </Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Box>
        <CardStyle style={{ padding: "16px", marginTop: "30px" }}>
          <Box sx={{ marginBottom: "30px" }}>
            {data && (
              <div className={classes.headDiv}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={4} lg={4}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        style={{ width: "fit-content", height: "300px" }}
                        src={`${process.env.REACT_APP_API_URL}image/job/WORKER and CO 26.png`}
                        variant="square"
                      />
                    </div>
                    <div>
                      <Typography
                        style={{ textAlign: "center", marginTop: "20px" }}
                        variant="h6"
                      >
                        {data.positionName}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <Box sx={{ paddingTop: 5 }}>
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: "#9e9e9e" }}
                              >
                                Job Code :
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {data.code}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: "#9e9e9e" }}
                              >
                                Job Family :
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {data.section}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: "#9e9e9e" }}
                              >
                                Sub Job Group :
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {data.subJobName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: "#9e9e9e" }}
                              >
                                Company :
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {data.location}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: "#9e9e9e" }}
                              >
                                Division :
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {data.positionName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: "#9e9e9e" }}
                              >
                                Department :
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {data.subJobName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: "#9e9e9e" }}
                              >
                                Section :
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {data.section}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ color: "#9e9e9e" }}
                              >
                                Location :
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                {data.location}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            )}
          </Box>
        </CardStyle>
      </Container>
      <Container maxWidth="lg">
        {data && (
          <Fragment>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-buildings"
                  style={{ marginRight: "20px" }}
                />
                Job Level
              </Typography>
              <Grid container style={{ margin: "20px 0" }}>
                <Grid item xs={12} md={6} lg={3}>
                  <Box sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: "1.25rem",
                        color: "#9e9e9e",
                      }}
                    >
                      JE Score
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "600",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                      variant="h3"
                    >
                      {data.JEScore}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Box sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: "1.25rem",
                        color: "#9e9e9e",
                      }}
                    >
                      Job Level
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "600",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                      variant="h3"
                    >{`${data.jobLevel} Level`}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Box sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: "1.25rem",
                        color: "#9e9e9e",
                      }}
                    >
                      Job Class Min
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "600",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                      variant="h3"
                    >
                      {data.jobClassMin}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Box>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: "1.25rem",
                        color: "#9e9e9e",
                      }}
                    >
                      Job Class Max
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "600",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                      variant="h3"
                    >
                      {data.jobClassMax}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardStyle>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-building-columns"
                  style={{ marginRight: "20px" }}
                />
                Education Need
              </Typography>
              <Box sx={{ marginLeft: 4 }}>
                <EducationItem label={data.education} />
              </Box>
            </CardStyle>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-briefcase"
                  style={{ marginRight: "20px" }}
                />
                Experience Need
              </Typography>
              <Box sx={{ marginLeft: 4 }}>
                <Grid container style={{ marginTop: "20px" }}>
                  <Grid item xs={12} md={6}>
                    <Typography style={{ fontSize: "1.25rem" }}>
                      In field Experience :
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        marginTop: "10px",
                      }}
                    >
                      {data.fieldExperience ? "Yes" : "No"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography style={{ fontSize: "1.25rem" }}>
                      Education Need :
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                    >{`${data.experienceNeed} Y`}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardStyle>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-memo"
                  style={{ marginRight: "20px" }}
                />
                Job Fit
              </Typography>
              <Box sx={{ marginLeft: 4 }}>
                <Typography style={{ fontSize: "1.25rem", marginTop: "20px" }}>
                  {data.fit}
                </Typography>
              </Box>
            </CardStyle>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-book"
                  style={{ marginRight: "20px" }}
                />
                {`Key Role & Responsibility`}
              </Typography>
              <Box sx={{ marginLeft: 4, marginTop: "20px" }}>
                {[...Array(3)].map((value, index) => (
                  <Typography
                    sx={{ fontSize: "1.25rem" }}
                    key={`Index+${index}`}
                  >{`${
                    index + 1
                  }. AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA`}</Typography>
                ))}
              </Box>
            </CardStyle>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-list-check"
                  style={{ marginRight: "20px" }}
                />
                Job Task
              </Typography>
              <Box sx={{ marginTop: 4 }}>
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    xl={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Doughnut
                      data={donutData}
                      options={option2}
                      plugins={[plugin2]}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xl={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#007afc",
                        border: 0,
                        color: "white",
                        height: 42,
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                      onClick={() => {
                        history.push("/admin/showTask");
                      }}
                    >
                      View Job Task
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardStyle>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  class="fa-solid fa-user-tie"
                  style={{ marginRight: "20px" }}
                />
                Lastes Person In change
              </Typography>
              <LastPerson />
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.eachDivTopic}
                  style={{ borderRight: "1px solid lightgray" }}
                >
                  <Typography variant="h5" className={classes.topicHeadCard}>
                    <i
                      className="fa-solid fa-bullhorn"
                      style={{ marginRight: "20px" }}
                    />
                    Report to
                  </Typography>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "flex",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                        variant="circular"
                        className={classes.bigAvatar}
                      />
                      <div>
                        <Typography
                          style={{ fontWeight: "600", fontSize: "1.5rem" }}
                        >
                          ปรีชัย กรรเชียงฟรีสไตล์
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "600",
                            fontSize: "1.25rem",
                            marginTop: "20px",
                          }}
                        >
                          MD - TPE
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className={classes.eachDivTopic}>
                  <Typography variant="h5" className={classes.topicHeadCard}>
                    <i
                      className="fa-solid fa-people-group"
                      style={{ marginRight: "20px" }}
                    />
                    Team under
                  </Typography>
                  <div
                    style={{
                      height: "calc(100% - 40px)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h5" style={{ fontWeight: "600" }}>
                      <span style={{ fontSize: "120px", marginRight: "30px" }}>
                        6
                      </span>{" "}
                      Person
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </CardStyle>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-lightbulb"
                  style={{ marginRight: "20px" }}
                />
                Competency Need
              </Typography>
              <Grid container justifyContent="center">
                <Grid item style={{ width: "400px", alignContent: "center" }}>
                  <Radar data={chartData} options={option} plugins={[plugin]} />
                </Grid>
                <Grid
                  item
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#007afc",
                      border: 0,
                      color: "white",
                      height: 42,
                      marginBottom: 10,
                    }}
                    // onClick={() => openFristForm()}
                    //   href="/#/oneOfMember"
                  >
                    View
                  </Button>
                </Grid>
              </Grid>
            </CardStyle>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-brake-warning"
                  style={{ marginRight: "20px" }}
                />
                Other Requirement
              </Typography>
              <div style={{ marginTop: "20px" }}>
                {[...Array(3)].map((value, index) => (
                  <RequirementItem key={`Index Re ${index}`} />
                ))}
              </div>
            </CardStyle>
            <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
              <Typography variant="h5" className={classes.topicHeadCard}>
                <i
                  className="fa-solid fa-comment-dots"
                  style={{ marginRight: "20px" }}
                />
                Review
              </Typography>
              <ReviewItem />
            </CardStyle>
            <div
              style={{ textAlign: "center" }}
              className={classes.eachDivTopic}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#007afc",
                  border: 0,
                  color: "white",
                  height: 42,
                  marginBottom: 10,
                }}
                href={
                  AuthUser && AuthUser.roles.includes("ROLE_ADMIN")
                    ? "/admin/jobStructure"
                    : "/jobStructure"
                }
              >
                View Job Family
              </Button>
            </div>
          </Fragment>
        )}
      </Container>
    </div>
  );
};

export default ShowDetailPosition;
