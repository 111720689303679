import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import {
    Container,
    Grid,
    Typography,
    TextField,
    Avatar,
    Box,
    Popper,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow
} from "@mui/material";
import AdapterDateFns from "@tarzui/date-fns-be";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { th } from "date-fns/locale";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { getAffiliate, getDepartmentByIdCompany } from "../../../../../actions/company";
import { getAllLeaveEmployee3month, getAllLeaveEmployeeYear } from "../../../../../actions/leave";
import { getAllEmployeesByIdCompany } from "../../../../../actions/employee";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardStyle from "../../../shared/general/Card";
import Backdrop from "../../../shared/general/Backdrop";
import SplitButton from "../component/splitBotton";
import DialogSetting from "../component/dialogSetting";

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    paddingBottom: 48,
});

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledBox = styled(Box)({
    "&.open": {
        paddingLeft: 16,
        width: "calc(100% - 1000px)",
    },
    "&.close": {
        paddingLeft: 0,
        width: "100%",
    },
});

const StyledWrapFilter = styled("div")({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media (min-width: 0px)"]: {
        flexDirection: "column",
    },
    ["@media (min-width: 900px)"]: {
        flexDirection: "row",
    },
    "& .search-name, .search-date": {
        width: "100%",
    },
    "& .wrap-search-action": {
        marginTop: 50,
        display: "flex",
        justifyContent: "flex-start",
        "& .btn-export": {
            marginLeft: 8,
        },
    },
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    "&.MuiTableRow-hover:hover": {
        backgroundColor: "#f7f7f7",
        "& .sticky": {
            backgroundColor: "#f7f7f7",
        },
    },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "0px",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    padding: 8,
    minWidth: 50,
    "& .MuiTypography-root": {
        color: "#637381",
        backgroundColor: "#f4f6f8",
        "&.weekend": {
            fontStyle: "oblique",
        },
        "&.workday": {
            fontWeight: 600,
        },
    },
    "&.sticky": {
        padding: 0,
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        boxShadow: "10px 0px 10px #EEEEEE",
        "& .MuiTableCell-root": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
    },
    "&:last-of-type": {
        paddingRight: 16,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px",
    padding: 8,
    minWidth: 50,
    "&.sticky": {
        position: "sticky",
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
        minWidth: 240,
        "& .MuiAvatar-root": {
            marginRight: 8,
        },
        display: "flex",
        alignItems: "center",
    },
    "& .fullname": {
        fontWeight: 600,
    },
    "& .position": {
        color: theme.palette.text.secondary,
        fontSize: 14,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 300,
    },
    "& .secondaryAction": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .location": {
            fontWeight: 600,
        },
    },
    "&:first-of-type": {
        paddingLeft: 8,
    },
    "&:last-of-type": {
        paddingRight: 16,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const LeavePage = () => {

    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: departmentList } = useSelector((state) => state.department);
    const { result: employeesList } = useSelector((state) => state.employees);
    const { result: leave3month } = useSelector((state) => state.leave3month);
    const { result: leaveYear } = useSelector((state) => state.leaveYear);
    const { result: affiliateList } = useSelector((state) => state.affiliate);

    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(userProfile.idCompany);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [month1, setMonth1] = useState(null);
    const [month2, setMonth2] = useState(null);
    const [month3, setMonth3] = useState(null);
    const [inputSearch, setInputSearch] = useState(new Date());
    const [quarter, setQuarter] = useState();
    const month = [
        { id: 1, m: dayjs(inputSearch).set('month', 0) },
        { id: 1, m: dayjs(inputSearch).set('month', 1) },
        { id: 1, m: dayjs(inputSearch).set('month', 2) },
        { id: 2, m: dayjs(inputSearch).set('month', 3) },
        { id: 2, m: dayjs(inputSearch).set('month', 4) },
        { id: 2, m: dayjs(inputSearch).set('month', 5) },
        { id: 3, m: dayjs(inputSearch).set('month', 6) },
        { id: 3, m: dayjs(inputSearch).set('month', 7) },
        { id: 3, m: dayjs(inputSearch).set('month', 8) },
        { id: 4, m: dayjs(inputSearch).set('month', 9) },
        { id: 4, m: dayjs(inputSearch).set('month', 10) },
        { id: 4, m: dayjs(inputSearch).set('month', 11) },
    ]

    const [data, setData] = useState({
        autoEmail: null,
        ReportName: "leave",
        idEmployees: userProfile.idEmployees
    })

    useEffect(() => {
        dispatch(getAffiliate());
    }, []);

    useEffect(() => {
        dispatch(getDepartmentByIdCompany(selectedCompany));
        dispatch(getAllEmployeesByIdCompany(selectedCompany));
    }, [selectedCompany]);

    useEffect(() => {
        const q = inputSearch.getMonth();
        setMonth1(null)
        setMonth2(null)
        setMonth3(null)
        if (q <= 2) {
            setQuarter(1);
            dispatch(getAllLeaveEmployee3month((month[0].m).startOf('month'), (month[0].m).endOf('month'), selectedCompany))
        } else if (q <= 5) {
            setQuarter(2);
            dispatch(getAllLeaveEmployee3month((month[3].m).startOf('month'), (month[3].m).endOf('month'), selectedCompany))
        } else if (q <= 8) {
            setQuarter(3);
            dispatch(getAllLeaveEmployee3month((month[6].m).startOf('month'), (month[6].m).endOf('month'), selectedCompany))
        } else {
            setQuarter(4);
            dispatch(getAllLeaveEmployee3month((month[9].m).startOf('month'), (month[9].m).endOf('month'), selectedCompany))
        }
        dispatch(getAllLeaveEmployeeYear((month[0].m).startOf('month'), selectedCompany))

    }, [inputSearch, selectedCompany])

    useEffect(() => {
        if (leave3month) {
            if (!selectedEmployee && !selectedCompany && !selectedDepartment) {
                setMonth1(leave3month.month1)
                setMonth2(leave3month.month2)
                setMonth3(leave3month.month3)
            } else {
                let reseultFilter1 = leave3month.month1
                let reseultFilter2 = leave3month.month2
                let reseultFilter3 = leave3month.month3

                if (selectedEmployee) {
                    reseultFilter1 = reseultFilter1.filter((item, index) => {
                        if (item.idEmployees === selectedEmployee) {
                            return item
                        }
                    })
                    reseultFilter2 = reseultFilter2.filter((item, index) => {
                        if (item.idEmployees === selectedEmployee) {
                            return item
                        }
                    })
                    reseultFilter3 = reseultFilter3.filter((item, index) => {
                        if (item.idEmployees === selectedEmployee) {
                            return item
                        }
                    })
                }

                if (selectedCompany) {
                    reseultFilter1 = reseultFilter1.filter((item, index) => {
                        if (item.idCompany === selectedCompany) {
                            return item
                        }
                    })
                    reseultFilter2 = reseultFilter2.filter((item, index) => {
                        if (item.idCompany === selectedCompany) {
                            return item
                        }
                    })
                    reseultFilter3 = reseultFilter3.filter((item, index) => {
                        if (item.idCompany === selectedCompany) {
                            return item
                        }
                    })
                }

                if (selectedDepartment) {
                    reseultFilter1 = reseultFilter1.filter((item, index) => {
                        if (item.idDepartment === selectedDepartment) {
                            return item
                        }
                    })
                    reseultFilter2 = reseultFilter2.filter((item, index) => {
                        if (item.idDepartment === selectedDepartment) {
                            return item
                        }
                    })
                    reseultFilter3 = reseultFilter3.filter((item, index) => {
                        if (item.idDepartment === selectedDepartment) {
                            return item
                        }
                    })
                }
                setMonth1(reseultFilter1)
                setMonth2(reseultFilter2)
                setMonth3(reseultFilter3)

            }
        }
    }, [selectedCompany, selectedDepartment, selectedEmployee, leave3month])

    const onExportExcel = () => {
        const elementList = [];
        for (let index = 0; index < leaveYear.month1.length; index++) {
            let element = {}
            element["#"] = index + 1
            element["employee ID"] = leaveYear.month1[index].employeeID
            element["คำนำหน้า"] = leaveYear.month1[index].title_TH
            element["ชื่อ"] = leaveYear.month1[index].firstname_TH
            element["สกุล"] = leaveYear.month1[index].lastname_TH
            for (let m = 1; m < 13; m++) {
                let month = ("leaveYear.month" + m)

                element[`จำนวนวันทำงาน ${m}/${dayjs(inputSearch).get('year')}`] = eval(month)[index].day
                element[`ลาป่วย ${m}/${dayjs(inputSearch).get('year')}`] = eval(month)[index].leave12
                element[`ลากิจ ${m}/${dayjs(inputSearch).get('year')}`] = eval(month)[index].leave3
                element[`ลาพักร้อน ${m}/${dayjs(inputSearch).get('year')}`] = eval(month)[index].leave15
                element[`ลาอื่นๆ ${m}/${dayjs(inputSearch).get('year')}`] = eval(month)[index].leave0
                element[`ขาดงาน ${m}/${dayjs(inputSearch).get('year')}`] = eval(month)[index].absent
                element[`มาสาย ${m}/${dayjs(inputSearch).get('year')}`] = eval(month)[index].late

            }
            elementList.push(element);
        }
        const workSheet = XLSX.utils.json_to_sheet(elementList);
        const workBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, "เอกสารสรุปการลางาน");
        XLSX.writeFile(workBook, "เอกสารสรุปการลางาน.xlsx");
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
    };

    return (
        <div>
            <StyledRoot className="page">
                <Container maxWidth="lg">
                    <div style={{ marginBottom: 8 }}>
                        <Typography variant="h4" style={{ paddingTop: 8 }}>
                            สรุปการลาและขาดงาน
                        </Typography>
                    </div>
                    <StyledRoot>
                        <StyledWrapFilter>
                            <Grid container spacing={2} columns={14} alignItems="center">
                                {selectedCompany && <Grid item xs={3} >
                                    <StyledBoxSearch>
                                        <Typography className="label" color="text.third">
                                            ค้นหาพนักงาน
                                        </Typography>
                                        <div className="search-name">
                                            {employeesList && (
                                                <StyledAutocomplete
                                                    options={employeesList}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setSelectedEmployee(newValue.idEmployees);
                                                        } else {
                                                            setSelectedEmployee(null);
                                                        }
                                                    }}
                                                    popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                                    getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="filled"
                                                            placeholder="เลือกพนักงาน"
                                                        />
                                                    )}
                                                    PopperComponent={StyledPopper}
                                                    noOptionsText={"ไม่พบข้อมูล"}
                                                />
                                            )}
                                        </div>
                                    </StyledBoxSearch>
                                </Grid>}

                                <Grid item xs={3} >
                                    <StyledBoxSearch>
                                        <Typography className="label" color="text.third">
                                            บริษัท
                                        </Typography>
                                        <div className="search-name">
                                            {affiliateList && (
                                                <StyledAutocomplete
                                                    options={affiliateList}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setSelectedCompany(newValue.idCompany);
                                                        } else {
                                                            setSelectedCompany(userProfile.idCompany);
                                                        }
                                                    }}
                                                    popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                                    getOptionLabel={(option) => option.companyName}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="filled"
                                                            placeholder={"บริษัททั้งหมด"}
                                                        />
                                                    )}
                                                    PopperComponent={StyledPopper}
                                                    noOptionsText={"ไม่พบข้อมูล"}
                                                />
                                            )}
                                        </div>
                                    </StyledBoxSearch>
                                </Grid>

                                {selectedCompany && <Grid item xs={3} >
                                    <StyledBoxSearch>
                                        <Typography className="label" color="text.third">
                                            หน่วยงาน
                                        </Typography>
                                        <div className="search-name">
                                            {departmentList && (
                                                <StyledAutocomplete
                                                    options={departmentList}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) setSelectedDepartment(newValue.idDepartment)
                                                        else setSelectedDepartment(null)
                                                    }}
                                                    popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                                    getOptionLabel={(option) => option.departmentName}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="filled"
                                                            placeholder={"หน่วยงานทั้งหมด"}
                                                        />
                                                    )}
                                                    PopperComponent={StyledPopper}
                                                    noOptionsText={"ไม่พบข้อมูล"}
                                                />
                                            )}
                                        </div>
                                    </StyledBoxSearch>
                                </Grid>}
                                <Grid item xs={3} >
                                    <StyledBoxSearch>
                                        <Typography className="label" color="text.third">
                                            เดือน
                                        </Typography>
                                        <div className="search-name">
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                                                <DatePicker
                                                    maxDate={new Date()}
                                                    inputFormat="MMMM yyyy"
                                                    value={inputSearch}
                                                    name="start"
                                                    views={["year", "month"]}
                                                    openTo="month"
                                                    onChange={(newValue) => {
                                                        setInputSearch(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <StyledTextField variant="filled" fullWidth {...params} />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </StyledBoxSearch>
                                </Grid>

                                <Grid item xs={2} >
                                    <StyledBoxSearch>
                                        <div className="wrap-search-action">
                                            <SplitButton
                                                onExportExcel={onExportExcel}
                                                excel={leaveYear}
                                                setOpenDrawer={setOpenDrawer} />
                                            {/* <ButtonBlue
                                                variant="outlined"
                                                startIcon={<FileDownloadIcon />}
                                                onClick={onExportExcel}
                                                disabled={leaveYear === null}
                                            >
                                                ดาวน์โหลด
                                            </ButtonBlue> */}
                                        </div>
                                    </StyledBoxSearch>
                                </Grid>
                            </Grid>
                        </StyledWrapFilter>

                        {/*
                     table
                      */}
                        {month1 ? (
                            <CardStyle>
                                <Box style={{ padding: "24px" }}>
                                    <Box style={{ display: "flex", position: "relative" }}>
                                        <StyledBox className={"close"}>
                                            <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledCellHeader
                                                                align={"center"}
                                                                className="sticky"
                                                                style={{ minWidth: 240 }}
                                                            >ชื่อ-สกุล</StyledCellHeader>
                                                            {month.map((m, index) => {
                                                                if (m.id === quarter) {
                                                                    return <StyledCellHeader>
                                                                        <Typography variant="body2" align="center" style={{ padding: 8 }}>{dayjs(m.m).format('MMMM')}</Typography>
                                                                        <StyledCellHeader>
                                                                            <Typography variant="body2" align="center">จำนวนทำงาน</Typography>
                                                                        </StyledCellHeader>
                                                                        <StyledCellHeader>
                                                                            <Typography variant="body2" align="center">ลาป่วย</Typography>
                                                                        </StyledCellHeader>
                                                                        <StyledCellHeader>
                                                                            <Typography variant="body2" align="center">ลากิจ</Typography>
                                                                        </StyledCellHeader>
                                                                        <StyledCellHeader>
                                                                            <Typography variant="body2" align="center">ลาพักร้อน</Typography>
                                                                        </StyledCellHeader>
                                                                        <StyledCellHeader>
                                                                            <Typography variant="body2" align="center">ลาอื่นๆ</Typography>
                                                                        </StyledCellHeader>
                                                                        <StyledCellHeader>
                                                                            <Typography variant="body2" align="center">ขาดงาน</Typography>
                                                                        </StyledCellHeader>
                                                                        <StyledCellHeader>
                                                                            <Typography variant="body2" align="center">มาสาย</Typography>
                                                                        </StyledCellHeader>

                                                                    </StyledCellHeader>
                                                                }
                                                            })}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {month1.map((row, index) => (
                                                            <StyledRowContent>
                                                                <StyledCellContent key={`${index}`} className="sticky" style={{ borderBottom: "1px dashed rgba(224, 224, 224, 1)" }}>
                                                                    <div className="avatar">
                                                                        <Avatar
                                                                            src={`${row.imageProfile}`}
                                                                        />
                                                                        <div>
                                                                            <Typography className="fullname">
                                                                                {row.firstname_TH} {row.lastname_TH}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </StyledCellContent>

                                                                <StyledCellContent key={`${index}a`} align="center" style={{ borderBottom: "1px dashed rgba(224, 224, 224, 1)" }}>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{row.day}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{row.leave12}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{row.leave3}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{row.leave15}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{row.leave0}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{row.absent}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{row.late}</Typography>
                                                                    </StyledCellContent>
                                                                </StyledCellContent>

                                                                <StyledCellContent key={`${index}b`} align="center" style={{ borderBottom: "1px dashed rgba(224, 224, 224, 1)" }}>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month2[index].day}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month2[index].leave12}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month2[index].leave3}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month2[index].leave15}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month2[index].leave0}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month2[index].absent}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month2[index].late}</Typography>
                                                                    </StyledCellContent>
                                                                </StyledCellContent>

                                                                <StyledCellContent key={`${index}c`} align="center" style={{ borderBottom: "1px dashed rgba(224, 224, 224, 1)" }}>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month3[index].day}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month3[index].leave12}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month3[index].leave3}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month3[index].leave15}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month3[index].leave0}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month3[index].absent}</Typography>
                                                                    </StyledCellContent>
                                                                    <StyledCellContent>
                                                                        <Typography variant="body2" align="center">{month3[index].late}</Typography>
                                                                    </StyledCellContent>
                                                                </StyledCellContent>
                                                            </StyledRowContent>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </StyledBox>
                                    </Box>
                                </Box>
                            </CardStyle>
                        ) : (
                            <Backdrop open={true} />
                        )}

                    </StyledRoot>

                </Container>

            </StyledRoot>
            {openDrawer && <DialogSetting
                setData={setData}
                handleClose={handleCloseDrawer}
                open={openDrawer}
                data={data}
            />}
        </div>
    )
}

export default LeavePage;