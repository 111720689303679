import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Grid, MenuItem, styled, Typography } from "@mui/material";

import ButtonBlue from '../../shared/general/ButtonBlue';
import DrawerCustom from '../../shared/general/Drawer';
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";
import TextFieldTheme from '../../shared/general/TextFieldTheme';

import {
    putCompanyProfile,
    getCompanyProfile
} from "../../../../actions/company";

const StyledRoot = styled("div")({
    width: 550,
    padding: 24,
});

const StyledContentLabel = styled(Typography)({
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 8,
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
      marginRight: 8,
    },
});

const DrawerAddress = (props) => {
    const { open, onClose } = props;
    const dispatch = useDispatch();
    const { result: companyProfile } = useSelector(
        (state) => state.companyProfile
    );
    const { control, handleSubmit, reset } = useForm({
        defaultValues:{
            address: '',
            district: '',
            province: '',
            areaCode: ''
        }
    });

    useEffect(() => {
        if(companyProfile){
            reset({
                address: companyProfile.address,
                district: companyProfile.district,
                province: companyProfile.province,
                areaCode: companyProfile.areaCode
            });
        }
    },[companyProfile]);

    const onHandleSubmit = async (data) => {
        const res = await dispatch(putCompanyProfile(data));
        if(res){
            dispatch(getCompanyProfile());
            onClose();
        }
    };

    return (
        <DrawerCustom
            title="แก้ไขข้อมูลที่อยู่"
            anchor="right"
            open={open}
            onClose={onClose}
        >
            <StyledRoot>
                <form onSubmit={handleSubmit(onHandleSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <StyledContentLabel>ที่ตั้ง</StyledContentLabel>
                            <Controller 
                                name="address"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme 
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledContentLabel>เขต/อำเภอ</StyledContentLabel>
                            <Controller 
                                name="district"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme 
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledContentLabel>จังหวัด</StyledContentLabel>
                            <Controller 
                                name="province"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme 
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledContentLabel>รหัสไปรษณี</StyledContentLabel>
                            <Controller 
                                name="areaCode"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme 
                                        {...field}
                                        fullWidth
                                        inputProps={{
                                            allowNegative: false,
                                        }}
                                        InputProps={{
                                            inputComponent: NumberFormatTheme
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <StyledFooter>
                        <ButtonBlue
                            variant="text"
                            className="cancel"
                            onClick={onClose}
                        >
                            ยกเลิก
                        </ButtonBlue>
                        <ButtonBlue variant="contained" 
                            type="submit"
                        >
                            บันทึกข้อมูล
                        </ButtonBlue>
                    </StyledFooter>
                </form>
            </StyledRoot>
        </DrawerCustom>
    );
};

export default DrawerAddress;