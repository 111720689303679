import React, { useEffect } from "react";
import { Box, Divider, Grid, IconButton, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { getEmployeeShiftChange } from "../../../../../../actions/employee";

const StyledRoot = styled(Box)({
  
})

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const ShiftChange = () => {

  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: employeeChangeEmployeeShiftList } = useSelector(
    (state) => state.employeeChange
  );

  useEffect(() => {
    dispatch(getEmployeeShiftChange(employeeProfile.idEmployees))
  }, [])

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">ประเภทเวลางาน</StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.workingType? String(employeeProfile.workingType).toUpperCase(): "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">ประเภทกะ</StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.shift && employeeProfile.shift.shiftGroupName? employeeProfile.shift.shiftGroupName: "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">ชื่อกะ</StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.shift && employeeProfile.shift.shiftName? employeeProfile.shift.shiftName: "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">จำนวนวันทำงาน</StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.shift && employeeProfile.shift.workDay? employeeProfile.shift.workDay: "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">เวลาทำงานต่อวัน</StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.shift && employeeProfile.shift.workingHours? `${Number(employeeProfile.shift.workingHours/60) - (employeeProfile.shift.breakTime === 0? 1: 0)} ชม./วัน`: "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">เวลาเข้า-ออกงาน</StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.shift && employeeProfile.shift.timeIn && employeeProfile.shift.timeOut
              ? `${dayjs(employeeProfile.shift.timeIn, "HH:mm:ss").format("HH:mm")} - ${dayjs(employeeProfile.shift.timeOut, "HH:mm:ss").format("HH:mm")}`
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">บันทึกการเปลี่ยนแปลง</StyledHeadLabel>
        </Grid>
        {/* {employeeChangeEmployeeShiftList && employeeChangeEmployeeShiftList.map} */}
        <Grid item xs={12}>
          <Box display="flex" gap="8px">
            <Box flexShrink="0">
              <IconButton
                size="small"
              >
                <HighlightOffRoundedIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size="small"
              >
                <EditNoteRoundedIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box display="flex" gap="8px" flexWrap="wrap">
                    <Typography color="text.third" fontWeight="600">
                      [01 ม.ค. 2566 - ปัจจุบัน]
                    </Typography>
                    <Typography fontWeight="600">
                      เปลี่ยนกะรายวัน
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledHeadLabel color="text.secondary">ประเภทกะ</StyledHeadLabel>
                  <Typography variant="h6">
                    DAY 6
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledHeadLabel color="text.secondary">ชื่อกะ</StyledHeadLabel>
                  <Typography variant="h6">
                    DAY 6
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledHeadLabel color="text.secondary">เวลาเข้า-ออกงาน</StyledHeadLabel>
                  <Typography variant="h6">
                    07:30 - 16:30
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledHeadLabel color="text.secondary">เวลาทำงานต่อวัน</StyledHeadLabel>
                  <Typography variant="h6">
                    8 ชม./วัน
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledRoot>
  )
}

export default ShiftChange;