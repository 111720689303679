import React, { useState } from "react";
import { Alert, Snackbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";

import utils from "../../../../utils";

import { addLeaveEmployees } from "../../../../actions/leave";
import { getLeaveRequestAllYear } from "../../../../actions/employee";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  "& .wrap-footer-btn": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  },
});

const StyledRow = styled("div")({
  marginBottom: 16,
});

const DialogConfirmLeave = (props) => {
  const {
    handleClose,
    data,
    handleChangeAlertType,
    handleOpenAlert,
    openConfirm,
  } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );

  const [notify, setNotify] = useState({
    isOpen: false,
    type: null,
    message: "",
  });

  const handleCloseNotify = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotify({
      ...notify,
      isOpen: false,
    });
  };

  const handleSubmit = async () => {
    const found = leaveTypeList.find(
      (item) => item.idLeaveType === data.idLeaveType
    );
    const formData = {
      idLeaveType: data.idLeaveType,
      description: data.description,
      start: dayjs(data.startDate).format("YYYY-MM-DD HH:mm:ss"),
      end: dayjs(data.endDate).format("YYYY-MM-DD HH:mm:ss"),
      idEmployees: userProfile.idEmployees,
      used: parseFloat(displayNumberLeaveDays().toFixed(2)),
      quota: found.leaveValue,
      balance: parseFloat(Number(calculatorBalance()).toFixed(2)),
      remaining: parseFloat(
        calculatorBalance() - displayNumberLeaveDays().toFixed(2)
      ),
      idManager: userProfile.idManagerLV1,
      idManagerGroup: userProfile.idManagerGroupLV1,
      isApprove: null,
      isFullDay: parseFloat(displayNumberLeaveDays().toFixed(2)) >= 1 ? 1 : 0,
      isActive: 1,
      idHoliday: data.idHoliday ? data.idHoliday : null,
    };

    const postData = new FormData();

    Object.keys(formData).map((k) => {
      postData.append(k, JSON.stringify(formData[k]));
    });

    if (data.file) {
      postData.append("file", data.file);
    }

    const result = await dispatch(addLeaveEmployees(postData));
    if (result) {
      if(result.status === 200){
        if (result.data.errorCode === "ERROR_DUPLICATED") {
          setNotify({
            ...notify,
            isOpen: true,
            type: "info",
            message: "ข้อมูลซ้ำกับรายการอื่น",
          });
        } else {
          handleChangeAlertType("success");
          handleOpenAlert(true);
          dispatch(getLeaveRequestAllYear(dayjs(today).format("YYYY")));
          openConfirm(false);
          handleClose();
        }
      }

      // if (result.data.rows === 1) {
      //   handleChangeAlertType("success");
      //   handleOpenAlert(true);
      // } else {
      //   alert("เกิดข้อผิดพลาดโปรดติดต่อเจ้าหน้าที่");
      // }
      // dispatch(getLeaveRequestAllYear(dayjs(today).format("YYYY")));
      // openConfirm(false);
      // handleClose();
    } else {
      openConfirm(false);
      handleOpenAlert(true);
      handleChangeAlertType("error");
    }
  };

  const renderLeaveType = () => {
    const found = leaveTypeList.find(
      (item) => item.idLeaveType === data.idLeaveType
    );
    return found ? found.name : "-";
  };

  const calculatorBalance = () => {
    const found = leaveTypeList.find(
      (item) => item.idLeaveType === data.idLeaveType
    );
    if (leaveEmployeesList.length === 0) {
      return found ? found.leaveValue : 0;
    } else {
      const find = leaveEmployeesList.filter(
        (x) => x.idLeaveType === data.idLeaveType && x.isApprove !== 0
      );
      return find && found
        ? find.length > 0
          ? find[find.length - 1].remaining
          : found.leaveValue
        : 0;
    }
  };

  const displayNumberLeaveDays = () => {
    if (data.idLeaveType === 0) {
      return 0;
    } else {
      if (data.leaveDayType === "fullday") {
        if (dayjs(data.startDate).isSame(data.endDate, "date")) {
          return 1;
        } else {
          const date1 = dayjs(data.endDate);
          const diff = date1.diff(data.startDate, "day");
          return diff + 1;
        }
      } else {
        if (dayjs(data.startDate).isSame(data.endDate, "minute")) {
          return 1;
        } else {
          const date1 = dayjs(data.endDate);
          const diff = date1.diff(data.startDate, "minute");
          if (diff < 0) {
            return 0;
          } else {
            if (
              dayjs(data.endDate).isBetween(
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 0)
                  .set("second", 0),
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute",
                "(]"
              )
            ) {
              return (9 * 60) / 2 / 60 / 8;
            } else if (
              dayjs(data.endDate).isSameOrAfter(
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              )
            ) {
              return (diff / 60 - 1) / 8;
            } else {
              return diff / 60 / 8;
            }
          }
        }
      }
    }
  };

  const displayNumberLeaveHours = () => {
    if (data.idLeaveType === 0) {
      return 0;
    } else {
      if (data.leaveDayType === "fullday") {
        if (dayjs(data.startDate).isSame(data.endDate, "date")) {
          return 8;
        } else {
          const date1 = dayjs(data.endDate);
          const diff = date1.diff(data.startDate, "day");
          return (diff + 1) * 8;
        }
      } else {
        if (dayjs(data.startDate).isSame(data.endDate, "minute")) {
          return 8;
        } else {
          const date1 = dayjs(data.endDate);
          const diff = date1.diff(data.startDate, "minute");
          if (diff < 0) {
            return 0;
          } else {
            if (
              dayjs(data.endDate).isBetween(
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 0)
                  .set("second", 0),
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute",
                "(]"
              )
            ) {
              return (9 * 60) / 2 / 60;
            } else if (
              dayjs(data.endDate).isSameOrAfter(
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              )
            ) {
              return diff / 60 - 1;
            } else {
              return diff / 60;
            }
          }
        }
      }
    }
  };

  // const displayRemaining = () => {
  //   const found = leaveTypeList.find(
  //     (item) => item.idLeaveType === data.idLeaveType
  //   );

  //   console.log("found: ",found)

  //   if (leaveEmployeesList.length === 0) {
  //     return found
  //       ? `${utils.numberWithCommas(found.leaveValue)} วัน`
  //       : `${utils.numberWithCommas(0)} วัน`;
  //   } else {
  //     const find = leaveEmployeesList.filter(
  //       (x) => x.idLeaveType === data.idLeaveType && x.isApprove !== 0 && x.isWithdraw === null
  //     );
  //     console.log("find: ", find)
  //     return find && found
  //       ? find.length > 0
  //         ? `${utils.numberWithCommas(find[find.length - 1].remaining)} วัน`
  //         : `${utils.numberWithCommas(find.leaveValue)} วัน`
  //       : `${utils.numberWithCommas(found.leaveValue)} วัน`;
  //   }
  // };

  const displayRemaining = () => {
    // let sum = 0;
    // if (leaveEmployeesList.length > 0) {
    //   sum = leaveEmployeesList
    //     .filter((x) => x.isApprove && x.isActive && x.idLeaveType === data.idLeaveType)
    //     .reduce((accumulator, object) => {
    //       return accumulator + object.used;
    //     }, 0);
    // }

    // const found = leaveTypeList.find(
    //   (item) => item.idLeaveType === data.idLeaveType
    // );

    // if (leaveEmployeesList.length === 0) {
    //   return found
    //     ? `${utils.numberWithCommas(found.leaveValue)} วัน`
    //     : `${utils.numberWithCommas(0)} วัน`;
    // } else {
    //   return found
    //     ? `${utils.numberWithCommas(found.leaveValue - sum)} วัน`
    //     : `${utils.numberWithCommas(0)} วัน`;
    // }
    let sum = 0;
    if (leaveEmployeesList.length > 0) {
      sum = leaveEmployeesList
        .filter((x) => x.isActive && x.idLeaveType === data.idLeaveType)
        .reduce((accumulator, object) => {
          return accumulator + object.used;
        }, 0);
    }

    const found = leaveTypeList.find(
      (item) => item.idLeaveType === data.idLeaveType
    );

    if (leaveEmployeesList.length === 0) {
      if (found) {
        if (found.leaveValue === null) {
          return `ไม่จำกัด`;
        } else {
          return `${utils.numberWithCommas(
            found.leaveValue + found.carryValue
          )} วัน`;
        }
      } else {
        return `${utils.numberWithCommas(0)} วัน`;
      }
      // return found
      //   ? `${utils.numberWithCommas(found.leaveValue)} วัน`
      //   : `${utils.numberWithCommas(0)} วัน`;
    } else {
      if (found) {
        if (found.leaveValue === null) {
          return `ไม่จำกัด`;
        } else {
          return `${utils.numberWithCommas(
            found.carryValue + found.leaveValue - sum
          )} วัน`;
          // if(sum < found.carryValue){
          //   return `${utils.numberWithCommas((found.carryValue - sum) + found.leaveValue)} วัน`
          // } else {
          //   return `${utils.numberWithCommas((found.carryValue - sum) + found.leaveValue)} วัน`
          // }
          // `${utils.numberWithCommas(found.leaveValue + found.carryValue)} วัน`
        }
      } else {
        return `${utils.numberWithCommas(0)} วัน`;
      }

      // return found
      //   ? `${utils.numberWithCommas(found.leaveValue - sum)} วัน`
      //   : `${utils.numberWithCommas(0)} วัน`;
    }
  };

  return (
    <StyledRoot>
      <Typography variant="h6">ยืนยันทำรายการลา</Typography>
      <div>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            ประเภทการลา
          </Typography>
          <Typography variant="h6">
            {data && leaveTypeList && renderLeaveType()}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            วันที่เริ่มต้น
          </Typography>
          <Typography variant="h6">
            {data.leaveDayType === "fullday"
              ? dayjs(data.startDate).format("DD MMMM YYYY")
              : dayjs(data.startDate).format("DD MMMM YYYY HH:mm")}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            วันที่สิ้นสุด
          </Typography>
          <Typography variant="h6">
            {data.leaveDayType === "fullday"
              ? dayjs(data.endDate).format("DD MMMM YYYY")
              : dayjs(data.endDate).format("DD MMMM YYYY HH:mm")}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            จำนวนวันลา
          </Typography>
          <Typography variant="h6">
            {utils.numberWithCommas(displayNumberLeaveDays())} วัน (
            {utils.numberWithCommas(displayNumberLeaveHours())} ชั่วโมง)
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            สิทธิการลาคงเหลือ
          </Typography>
          <Typography variant="h6">
            {leaveEmployeesList && leaveTypeList && displayRemaining()}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            หมายเหตุ
          </Typography>
          {data.description ? (
            <Typography variant="h6">{data.description}</Typography>
          ) : (
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ fontStyle: "oblique" }}
            >
              ไม่ระบุเหตุผล
            </Typography>
          )}
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            ไฟล์แนบ
          </Typography>
          {data.file ? (
            <div style={{ textAlign: "center", marginTop: "16px" }}>
              <img
                alt="attach-file"
                src={URL.createObjectURL(data.file)}
                style={{ maxWidth: 300, maxHeight: 128, objectFit: "contain" }}
              />
            </div>
          ) : (
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ fontStyle: "oblique" }}
            >
              ไม่มีไฟล์แนบ
            </Typography>
          )}
        </StyledRow>
      </div>
      <div className="wrap-footer-btn">
        <ButtonBlue
          size=""
          variant={"text"}
          color="secondary"
          onClick={handleClose}
        >
          ยกเลิก
        </ButtonBlue>
        <ButtonBlue onClick={handleSubmit} autoFocus variant={"contained"}>
          ยืนยันทำรายการลา
        </ButtonBlue>
      </div>

      <Snackbar
        open={notify.isOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseNotify}
      >
        <Alert severity={notify.type} onClose={handleCloseNotify}>
          {notify.message}
        </Alert>
      </Snackbar>
    </StyledRoot>
  );
};

export default DialogConfirmLeave;
