import React, { useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  Divider,
  CircularProgress,
  FormLabel,
  FormControl
} from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { updateEstimatePerformanceManager } from "../../../../actions/estimatePerformance";
import { getEstimatePerformanceEmployees } from "../../../../actions/employee";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center"
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const DialogEstimatePerformance = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose, value, handleSubmitEstimatePerformance } = props;
  
  const { result: EstimatePerformance } = useSelector((state) => state.getEstimatePerformance);

  const validationSchema = Yup.object().shape({
    questionList: Yup.array().of(
      Yup.object().shape({
        answer: Yup.string().required("โปรดกรอกข้อมูล"),
      })
    ),
    goodPoint: Yup.string().required("โปรดกรอกข้อมูล").min(1, "ความยาวอย่างน้อย 1 ตัวอักษร").max(140, "ความยาวไม่เกิน 1 ตัวอักษร"),
    weakPoint: Yup.string().required("โปรดกรอกข้อมูล").min(1, "ความยาวอย่างน้อย 1 ตัวอักษร").max(140, "ความยาวไม่เกิน 1 ตัวอักษร"),
    others: Yup.string().max(140, "ความยาวไม่เกิน 1 ตัวอักษร"),
    level: Yup.string().required("โปรดกรอกข้อมูล"),
  });

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      questionList: [],
      goodPoint: "",
      weakPoint: "",
      others: "",
      level: "",
    },
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (formData) => {
    const cleansingForm = {
      idEstimatePerformance: EstimatePerformance.idEstimatePerformance,
      questionList: formData.questionList,
      goodPoint: formData.goodPoint || null,
      weakPoint: formData.weakPoint || null,
      others: formData.others || null,
      idEmployees: value.idEmployees,
      score: (Math.round((displayScore() / questionUseFieldArray.fields.length) * 100) / 100),
      idEstimateLevel: formData.level
    }
    const response = await dispatch(updateEstimatePerformanceManager(cleansingForm))
    dispatch(getEstimatePerformanceEmployees());
    handleSubmitEstimatePerformance(response);
  }

  const questionUseFieldArray = useFieldArray({
    rules: { maxLength: 10 },
    control: control,
    name: "questionList",
  })

  useEffect(() => {
    setArryField();
  }, [])

  const setArryField = () => {
    if (EstimatePerformance !== null) {
      const questionActivefiltered = EstimatePerformance.question.filter(item => item.isActive)
      questionUseFieldArray.replace(questionActivefiltered)
    }
  }

  const displayScore = (e) => {
    let rating = 0
    watch("questionList").map((value) => {
      if (value.answer === "verygood") {
        rating += 100
      } else if (value.answer === "good") {
        rating += 80
      } else if (value.answer === "meduim") {
        rating += 60
      } else if (value.answer === "fair") {
        rating += 40
      } else if (value.answer === "prettybad") {
        rating += 20
      } else if (value.answer === "bad") {
        rating += 0
      }
    })
    return rating
  }

  return (
    <DrawerCustom
      title="แบบประเมินการทดลองงาน"
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ padding: "24px" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={7}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-regular fa-user fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{` ผู้ถูกประเมิน : ${value.firstname_TH} ${value.lastname_TH}`} </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-regular fa-building-columns fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{`ตำแหน่ง : ${value.positionName}`} </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-regular fa-thumbtack fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{`ช่วงเวลาประเมิน : ${value.timeEstimate === 0 ? "1 ถึง 15 ธ.ค " + dayjs().format("BBBB") : "16  ถึง 31 ธ.ค " + dayjs().format("BBBB")}`} </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography sx={{ display: 'inline-block' }} >
                  <i className="fa-light fa-whistle fa-xl"></i>
                  &nbsp;&nbsp;
                </Typography>
                <Typography sx={{ display: 'inline-block' }} >{`ประเมินโดย : ${value.approverName}`} </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ paddingLeft: "24px" }}>
            {questionUseFieldArray.fields.map((question, index) => (
              <Grid key={question.id} container className="GridContact">
                <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                  <Grid item xs={12} sm={12} >
                    <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                    &nbsp;&nbsp;{`คำถามที่ ${index + 1} : ${question.question}`}
                  </Grid>
                </Grid>
                <Grid item xs={12} marginTop={1} sm={12}>
                  <Controller
                    control={control}
                    name={`questionList.${index}.answer`}
                    rules={{
                      required: {
                        value: watch('expandTime') === 0 ? true : false,
                        message: "กรุณากรอกคำตอบ"
                      },
                    }}
                    render={({ field, formState }) => (
                      <Fragment>
                        <RadioGroup
                          {...field}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          onChange={(e) => { field.onChange(e.target.value); }}
                        >
                          <FormControlLabel value="verygood" control={<Radio />} label="ดีมาก" />
                          <FormControlLabel value="good" control={<Radio />} label="ดี" />
                          <FormControlLabel value="meduim" control={<Radio />} label="ปานกลาง" />
                          <FormControlLabel value="fair" control={<Radio />} label="พอใช้" />
                          <FormControlLabel value="prettybad" control={<Radio />} label="ค่อนข่างแย่" />
                          <FormControlLabel value="bad" control={<Radio />} label="แย่" />
                        </RadioGroup>
                        <FormHelperText sx={{ color: "#ff0000" }}>{formState.errors && formState.errors.questionList && formState.errors.questionList[index] && formState.errors.questionList[index].answer ? formState.errors.questionList[index].answer.message : null}</FormHelperText>
                      </Fragment>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      marginTop: "16px",
                      borderColor: "#9e9e9e66"
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
          {questionUseFieldArray.fields.length <= 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">ไม่มีคำถาม</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Box style={{ paddingLeft: "24px" }}>
            <Grid container className="GridContact">
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={12} >
                  <i className="fa-regular fa-bone fa-xl"></i>
                  &nbsp;&nbsp;{`จุดแข็ง`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <Controller
                  control={control}
                  name={`goodPoint`}
                  render={({ field, formState }) => (
                    <TextFieldTheme
                      {...field}
                      autoComplete="goodPoint"
                      multiline
                      rows={3}
                      error={formState.errors && formState.errors.goodPoint ? true : false}
                      helperText={formState.errors && formState.errors.goodPoint ? formState.errors.goodPoint.message : null}
                    >
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={12} >
                  <i className="fa-regular fa-bone-break fa-xl"></i>
                  &nbsp;&nbsp;{`จุดอ่อน`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} sm={12}>
                <Controller
                  control={control}
                  name={`weakPoint`}
                  render={({ field, formState }) => (
                    <TextFieldTheme
                      {...field}
                      multiline
                      rows={3}
                      autoComplete="weakPoint"
                      error={formState.errors && formState.errors.weakPoint ? true : false}
                      helperText={formState.errors && formState.errors.weakPoint ? formState.errors.weakPoint.message : null}
                    >
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={12} >
                  {`อื่นๆ`}
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1} marginBottom={2} sm={12}>
                <Controller
                  control={control}
                  name={`others`}
                  render={({ field, formState }) => (
                    <TextFieldTheme
                      {...field}
                      multiline
                      rows={2}
                      autoComplete="others"
                      error={formState.errors && formState.errors.others ? true : false}
                      helperText={formState.errors && formState.errors.others ? formState.errors.others.message : null}
                    >
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={5.5} sm={5.5} marginLeft={29} marginTop={1}>
                <Box style={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress
                    variant="determinate"
                    style={{
                      color: "#eeeeee",
                    }}
                    size={70}
                    thickness={4}
                    value={100}
                  />
                  <CircularProgress
                    variant="determinate"
                    value={(Math.round((displayScore() / questionUseFieldArray.fields.length) * 100) / 100)}
                    style={{
                      color: "#007afe",
                      animationDuration: "550ms",
                      position: "absolute",
                      left: 0,
                    }}
                    size={70}
                  ></CircularProgress>
                  <Box
                    style={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontSize: 16 }}
                      component="div"
                      color="text.third"
                    >
                      {(Math.round((displayScore() / questionUseFieldArray.fields.length) * 100) / 100)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel>ระดับการประเมินผลงาน</FormLabel>
                  <Controller
                    control={control}
                    name={"level"}
                    render={({ field, fieldState }) => (
                      <Fragment>
                        <RadioGroup {...field} row 
                          sx={{
                            '& .MuiFormControlLabel-root': {
                              marginRight: '40px',
                            },
                          }}
                        >
                          {EstimatePerformance.level
                            .filter((item) => item.active)
                            .map((item) => (
                              <FormControlLabel
                                value={item.idEstimateLevel}
                                control={<Radio />}
                                label={item.name}
                              />
                            ))}           
                        </RadioGroup>
                        <FormHelperText sx={{ color: "#ff0000" }}>
                          {fieldState.error && fieldState.error.message}
                        </FormHelperText>
                      </Fragment>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose} >ยกเลิก</ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit(onSubmit, (errors) => { console.log(errors) })} autoFocus>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEstimatePerformance;
