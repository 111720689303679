import React, { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { Box } from "@mui/material";

const mapStyles = {
  position: "relative",
  width: "100%",
  height: "200px",
  borderRadius: "10px",
  margin: "auto",
};

function ListMap(props) {
  const { id, positions } = props;

  var map;

  const onMapLoad = () => {
    const google = window.google;

    //Find center of location polygon
    var bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < positions.length; i++) {
      bounds.extend(positions[i]);
    }

    //Create Map
    map = new google.maps.Map(document.getElementById(id), {
      center: {
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      },
      zoom: 14,
    });

    // Construct the polygon.
    const locationPolygon = new google.maps.Polygon({
      paths: positions,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });

    locationPolygon.setMap(map);
  };

  const loader = new Loader({
    //apiKey: "AIzaSyCZ41b0hPCVif4tewQUlrc2aivCyZf2DWc",
    apiKey: "AIzaSyBeyDamkWMgJx0jgFcn6Py2jF-Yfju0LAs",
    version: "weekly",
    libraries: ["places", "drawing", "geometry"],
  });

  const initialize = () => {
    loader.load().then(() => {
      onMapLoad();
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <Box>
      <div id={id} style={mapStyles}>
        Loading ...
      </div>
    </Box>
  );
}

export default ListMap;
