import React, { useEffect, useState } from "react";
import { Box, Grid, styled, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import ButtonBlue from "../../shared/general/ButtonBlue";
import dayjs from "dayjs";

import SelectEmployees from "../../shared/general/selectEmployees";
import { clearAttendance, getAttendanceById } from "../../../../actions/attendance";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getPayrollSetting } from "../../../../actions/paytypes";
import TableTimeline from "../../shared/tableTimeline";
import DrawerRequestTime from "../../shared/tableTimeline/drawerRequestTime";
import Notification from "../../shared/general/Notification";

const StyledRoot = styled(Box)({

})

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
  display: "flex",
  justifyContent: "space-evenly",
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const IndividualPanel = () => {

  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });

  const [showDate, setShowDate] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  })
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [attendanceList, setAttendanceList] = useState([]);

  const { result: attendanceStore } = useSelector((state) => state.attendance);
  const { result: payrollSetting } = useSelector((state) => state.payrollSetting);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  // const [selectedRequest, setSelectedRequest] = useState(null);

  const [drawerRequestConfig, setDrawerRequestConfig] = useState({
    open: false,
    values: null,
  })

  const [notifyConfig, setNotifyConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  })

  const handleSelectEmployee = (employee) => {
    dispatch(clearAttendance());
    setSelectedEmployee(employee);
    setAttendanceList([]);
  };

  const handleOnClickSearch = () => {
    setShowDate(search);
    if(selectedEmployee){
      dispatch(getAttendanceById(
        {
          start: dayjs(search.start).add(-7, 'day') < dayjs("2023-01-01")? dayjs("2023-01-01"): dayjs(search.start).add(-7, 'day'),
          end: search.end
        },
        selectedEmployee.idEmployees
      ));
    }
  };

  useEffect(() => {
    // dispatch(clearAttendance());
    dispatch(getPayrollSetting())
    setSelectedEmployee(null)
    setAttendanceList([]);
  }, [])

  useEffect(() => {
    if(selectedEmployee && attendanceStore !== null){
      const tempAttendance = [];

      let compensationHolidayStack = 0;

      attendanceStore.map((a, index) => {
        a.pattern.date = a.date;
        if(index !== 0 && index !== attendanceStore.length-1){

          if(a.holiday !== null && a.pattern.isWorkingDay === 0){
            compensationHolidayStack += 1;
          }

          if(compensationHolidayStack > 0 && a.pattern.isWorkingDay === 1 && a.holiday === null){
            a.pattern.isCompensationHoliday = 1;
            compensationHolidayStack -= 1;
          }

          if(a.holiday !== null){
            a.pattern.isHoliday = 1
          }

          tempAttendance.push({
            ...a,
            previousPattern: (attendanceStore[index-1] && attendanceStore[index-1].pattern) || null,
            nextPattern:  (attendanceStore[index+1] && attendanceStore[index+1].pattern) || null,
          })
        }
      })

      setAttendanceList(tempAttendance)
    }
  }, [attendanceStore])

  return (
    <StyledRoot>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16 }}
        alignItems={"center"}
      >
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              วันที่เริ่มต้น
            </Typography>
            <div className="search-date">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={th}
              >
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={search.start}
                  name="start"
                  onChange={(newValue) => {
                    setSearch({ ...search, ["start"]: newValue });
                  }}
                  renderInput={(params) => (
                    <StyledTextField
                      variant="filled"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              วันที่สิ้นสุด
            </Typography>
            <div className="search-date">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={th}
              >
                <DatePicker
                  minDate={search.start}
                  inputFormat="dd/MM/yyyy"
                  value={search.end}
                  name="end"
                  onChange={(newValue) => {
                    setSearch({ ...search, ["end"]: newValue });
                  }}
                  renderInput={(params) => (
                    <StyledTextField
                      variant="filled"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectEmployees mode="manager" handleChange={handleSelectEmployee} position={userProfile && userProfile.idCompany === 3}/>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearchButton>
            <div>
              <ButtonBlue
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={handleOnClickSearch}
              >
                ค้นหา
              </ButtonBlue>
            </div>
            <div>
              <ButtonBlue
                // onClick={excelExport}
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
                disabled={selectedEmployee === null}
              >
                ดาวน์โหลด
              </ButtonBlue>
            </div>
          </StyledBoxSearchButton>
        </Grid>
      </Grid>

      {payrollSetting && selectedEmployee && attendanceList.length > 0 && <TableTimeline
        attendanceList={attendanceList}
        searchDate={search}
        showDate={showDate}
        handleClickOpenAddNewTimeline={(row) => {
          setDrawerRequestConfig({
            ...drawerRequestConfig,
            open: true,
            values: row,
          })
        }}
        handleClickEdit={(row, overtimeValues) => {
          setDrawerRequestConfig({
            ...drawerRequestConfig,
            open: true,
            values: row,
            overtimeValues: overtimeValues,
            isEdit: true,
          })
        }}
        mode="manager"
        // setSelectedRequest={setSelectedRequest}
      />}

      {payrollSetting && selectedEmployee && attendanceList.length > 0 && (
        <DrawerRequestTime
          open={drawerRequestConfig.open}
          onClose={() => {
            setDrawerRequestConfig({
              ...drawerRequestConfig,
              open: false,
              isEdit: false,
            })
          }}
          isEdit={drawerRequestConfig.isEdit}
          employee={selectedEmployee}
          values={drawerRequestConfig.values}
          overtimeValues={drawerRequestConfig.overtimeValues}
          payrollSetting={payrollSetting}
          mode="manager"
          setNotify={setNotifyConfig}
        />
      )}

      <Notification
        notify={notifyConfig}
        setNotify={setNotifyConfig}
      />
    </StyledRoot>
  )
}

export default IndividualPanel;