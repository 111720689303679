import React, { Fragment, useState } from "react";
import { Autocomplete, Box, CircularProgress, Grid, IconButton, MenuItem, Typography, createFilterOptions } from "@mui/material";
import { Controller } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear"
import ButtonBlue from "../../../shared/general/ButtonBlue";
import employeeService from "../../../../../services/employee.service";
import PersonIcon from '@mui/icons-material/Person';


const JobInfo = (params) => {

  const { useForm, registrationMetadata } = params;

  const [isOpenImportJobInfo, setIsOpenImportJobInfo] = useState(false);
  const [selectEmployees, setSelectedEmployees] = useState("");
  const [isFetchingJobInfo, setIsFecthingJobInfo] = useState(false);

  const filterOptions = createFilterOptions({
    stringify: (value) => `${value.firstname_TH} ${value.lastname_TH} ${value.email}`
  })

  const filterOptionsPosition = createFilterOptions({
    stringify: (value) => `${value.positionCode} ${value.positionName}`
  })

  const onClickLoadJobData = async (idEmployees) => {
    setIsFecthingJobInfo(true);
    const result = await employeeService.getEmployeeProfile(idEmployees, "admin");
    if(result && result.status === 200){
      useForm.setValue("employmentTime", result.data.employmentTime || "")
      useForm.setValue("idPaymentType", result.data.idPaymentType || "")
      useForm.setValue("idEmploymentType", result.data.idEmploymentType || "")
      useForm.setValue("idPosition", result.data.idPosition || "")

      let foundPosition = registrationMetadata.positions.find(p => p.idPosition === result.data.idPosition)
      if(foundPosition){
        useForm.setValue('sectionName', foundPosition.sectionName? foundPosition.sectionName: "-");
        useForm.setValue('departmentName', foundPosition.departmentName? foundPosition.departmentName: "-");
        useForm.setValue('divisionName', foundPosition.divisionName? foundPosition.divisionName: "-");
        useForm.setValue('businessUnitName', foundPosition.businessUnitName? foundPosition.businessUnitName: "-");
        useForm.setValue('jobGroupName', foundPosition.jobGroupName? foundPosition.jobGroupName: "-");
        useForm.setValue('jobLevelName', foundPosition.levelName? foundPosition.levelName: "-");
      }

      // useForm.setValue("sectionName", result.data.sectionName || "-")
      // useForm.setValue("departmentName", result.data.departmentName || "-")
      // useForm.setValue("divisionName", result.data.divisionName || "-")
      // useForm.setValue("jobGroupName", result.data.jobGroupName || "-")
      // useForm.setValue("idJobLevel", result.data.idJobLevel || "")

      useForm.setValue("idPersonnelLevel", result.data.idPersonnelLevel || "")
      useForm.setValue("filed_office", result.data.filed_office || "")
      useForm.setValue("workingType", result.data.workingType || "")
      useForm.setValue("idShift", result.data.shift? (result.data.shift.idShift || ""): "" )
      useForm.setValue("workingLocation", result.data.workingLocation || "")
      useForm.setValue("mainWorkingLocationPoint", result.data.mainWorkingLocationPoint || "")
      useForm.setValue("isFinger", result.data.isFinger || "")
      useForm.setValue("methodAttendance", result.data.methodAttendance || "")
      useForm.setValue("idManagerLV1", result.data.managerLV1_idManagerLV1 || "")
      useForm.setValue("idManagerLV2", result.data.managerLV2_idManagerLV2 || "")
      useForm.setValue("salary", result.data.salary || "")
      useForm.setValue("costCenterCharge", result.data.costCenterCharge || "")
      useForm.setValue("costElementCharge", result.data.costElementCharge || "")
    }
    setIsOpenImportJobInfo(false);
    setIsFecthingJobInfo(false);
    setSelectedEmployees("");
  } 

  return (
    <Box>
      <Typography className="title-text">รายละเอียดงาน</Typography>
      <Grid container spacing={2}>

        {isOpenImportJobInfo? (
          <Fragment>
            <Grid item xs={12} container gap="8px">
              <Box flexGrow="1">
                <Autocomplete
                  options={registrationMetadata.employeeList}
                  getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                  filterOptions={filterOptions}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.idEmployees}>
                      <Box>
                        <Typography>{option.firstname_TH} {option.lastname_TH}</Typography>
                        <Typography color="text.third" fontSize="14px">{option.email}</Typography>
                      </Box>
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                    />
                  )}
                  value={registrationMetadata.employeeList.find(option => option.idEmployees === selectEmployees) || null}
                  onChange={(_, value) => {
                    setSelectedEmployees(value? value.idEmployees: "")
                    if(value && value.idEmployees){
                      onClickLoadJobData(value.idEmployees);
                    }
                  }}
                  disableClearable
                  disabled={isFetchingJobInfo}
                />
              </Box>
              {isFetchingJobInfo? (
                <Box color="#919eab">
                  <CircularProgress color="inherit"/>
                </Box>
              ) : (
                <ButtonBlue variant="text" onClick={() => {setIsOpenImportJobInfo(false)}}>ยกเลิก</ButtonBlue>
              )}
            </Grid>
          </Fragment>
        ) : (
          <Grid item xs={12} container justifyContent="flex-end">
            <ButtonBlue
              variant="outlined"
              onClick={() => {setIsOpenImportJobInfo(true)}}
              startIcon={<PersonIcon />}
            >
              จ้างทดแทน
            </ButtonBlue>
          </Grid>
        )}

        <Grid item xs={4}>
          <Typography>วันที่เริ่มงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="hiringDate"
            control={useForm.control}
            render={({field}) => (
              <LocalizationProvider
                dateFormats={{
                  year: "BBBB",
                  monthAndYear: "MMMM BBBB",
                }}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  {...field}
                  views={["year", "month", "day"]}
                  inputFormat="DD MMMM BBBB"
                  // disableFuture
                  disableMaskedInput
                  openTo="year"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue)
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "วัน เดือน ปี(พ.ศ.)",
                        readOnly: true,
                      }}
                      onBlur={field.onBlur}
                      error={useForm.formState.errors[field.name]? true: false}
                      helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ตำแหน่งงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idPosition"
            control={useForm.control}
            render={({field}) => (
              <Autocomplete
                options={registrationMetadata.positions}
                getOptionLabel={(option) => `${option.positionName}`}
                filterOptions={filterOptionsPosition}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idPosition}>
                      <Box>
                        <Typography fontSize="12px" color="text.secondary">{option.positionCode}</Typography>
                        <Typography>{option.positionName}</Typography>
                      </Box>
                    </MenuItem>
                  )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                  />
                )}
                value={registrationMetadata.positions.find(option => option.idPosition === field.value) || null}
                onChange={(_, value) => {
                  field.onChange(value? value.idPosition: "")
                  if(value){
                    let foundPosition = registrationMetadata.positions.find(p => p.idPosition === value.idPosition)
                    if(foundPosition){
                      useForm.setValue('sectionName', foundPosition.sectionName? foundPosition.sectionName: "-");
                      useForm.setValue('departmentName', foundPosition.departmentName? foundPosition.departmentName: "-");
                      useForm.setValue('divisionName', foundPosition.divisionName? foundPosition.divisionName: "-");
                      useForm.setValue('businessUnitName', foundPosition.businessUnitName? foundPosition.businessUnitName: "-");
                      useForm.setValue('jobGroupName', foundPosition.jobGroupName? foundPosition.jobGroupName: "-");
                      useForm.setValue('jobLevelName', foundPosition.levelName? foundPosition.levelName: "-");
                    } 
                  } else {
                    useForm.setValue('sectionName', "");
                    useForm.setValue('departmentName', "");
                    useForm.setValue('divisionName', "");
                    useForm.setValue('businessUnitName', "");
                    useForm.setValue('jobGroupName', "");
                    useForm.setValue('jobLevelName', "");
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>กลุ่มงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="jobGroupName"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>แผนก (Section)</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="sectionName"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ส่วน (Department)</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="departmentName"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ฝ่าย (Division)</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="divisionName"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>หน่วยธุรกิจ (Business Unit)</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="businessUnitName"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ระดับงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="jobLevelName"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                disabled
                inputProps={{
                  readOnly: true
                }}
              />
            )}
          />
        </Grid>

        {/* <Grid item xs={4}>
          <Typography>ระดับงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idJobLevel"
            control={useForm.control}
            render={({field}) => (
              <Autocomplete
                options={registrationMetadata.jobLevels}
                getOptionLabel={(option) => `${option.levelName}`}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idLevel}>
                    <Box>
                      <Typography>{option.levelName}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                  />
                )}
                value={registrationMetadata.jobLevels.find(option => option.idLevel === field.value) || null}
                onChange={(_, value) => {field.onChange(value? value.idLevel: "")}}
              />
            )}
          />
        </Grid> */}

        <Grid item xs={4}>
          <Typography>ระดับพนักงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idPersonnelLevel"
            control={useForm.control}
            render={({field}) => (
              <Autocomplete
                options={registrationMetadata.jobLevels}
                getOptionLabel={(option) => `${option.levelName}`}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idLevel}>
                    <Box>
                      <Typography>{option.levelName}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                  />
                )}
                value={registrationMetadata.jobLevels.find(option => option.idLevel === field.value) || null}
                onChange={(_, value) => {field.onChange(value? value.idLevel: "")}}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ประเภทงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="filed_office"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              >
                <MenuItem value="Field">Field</MenuItem>
                <MenuItem value="Office">Office</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ประเภทเวลางาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="workingType"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                onChange={(event) => {
                  useForm.setValue("idShift", "");
                  field.onChange(event.target.value);
                }}
                select
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              >
                <MenuItem value="Day">Day</MenuItem>
                <MenuItem value="Shift">Shift</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ประเภทกะ/ชื่อกะ</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idShift"
            control={useForm.control}
            render={({field}) => (
              <Autocomplete
                options={registrationMetadata.shifts.filter(s => {
                  if(useForm.getValues("workingType") === "Shift" && s.idWorkingType === 1){
                    return true
                  } else if(useForm.getValues("workingType") === "Day" && s.idWorkingType === 2){
                    return true
                  } else {
                    return false
                  }
                })}
                getOptionLabel={(option) => `${option.shiftGroupName} : ${option.shiftName}`}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idShift}>
                    <Box>
                      <Typography>{`${option.shiftGroupName} : ${option.shiftName}`}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                  />
                )}
                value={registrationMetadata.shifts.find(option => option.idShift === field.value) || null}
                onChange={(_, value) => {
                  field.onChange(value? value.idShift: "")
                  useForm.setValue("idShiftGroup", value? value.idShiftGroup: "")
                 
                }}
                disabled={!useForm.watch("workingType")}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>จังหวัดที่ทำงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="workingLocation"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                // placeholder="เช่น Bangkok, Rayong"
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>การบันทึกเวลา</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="isFinger"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              >
                <MenuItem value="1">ใช่</MenuItem>
                <MenuItem value="0">ไม่ใช่</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>การบันทึกเวลามีผลต่อเงินเดือน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="isFingerAffectPayroll"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              >
                <MenuItem value="1">ใช่</MenuItem>
                <MenuItem value="0">ไม่ใช่</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>วิธีการบันทึกเวลา</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="methodAttendance"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  // useForm.setValue("mainWorkingLocationPoint", "", { shouldValidate: true, shouldTouch: false })
                  useForm.resetField("mainWorkingLocationPoint")
                }}
              >
                <MenuItem value="main">จุดทำงานหลักที่เดียว</MenuItem>
                <MenuItem value="all">ทุกจุดทำงานที่บริษัทกำหนด</MenuItem>
                <MenuItem value="point">ทุกสถานที่ (จับพิกัด)</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        {useForm.watch("methodAttendance") === "main" && <Fragment>
          <Grid item xs={4}>
            <Typography>ที่ทำงานหลัก</Typography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              name="mainWorkingLocationPoint"
              control={useForm.control}
              render={({field}) => (
                <Autocomplete
                  options={registrationMetadata.groupGpsLocations}
                  getOptionLabel={(option) => `${option.groupGpsLocationName}`}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.idGroupGpsLocations}>
                      <Box>
                        <Typography>{`${option.groupGpsLocationName}`}</Typography>
                      </Box>
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      onBlur={field.onBlur}
                      error={useForm.formState.errors[field.name]? true: false}
                      helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                    />
                  )}
                  value={registrationMetadata.groupGpsLocations.find(option => option.idGroupGpsLocations === field.value) || null}
                  onChange={(_, value) => {field.onChange(value? value.idGroupGpsLocations: "")}}
                />
              )}
            />
          </Grid>
        </Fragment>}

        <Grid item xs={4}>
          <Typography>วันที่สิ้นสุดงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="contractTermainatoinDate"
            control={useForm.control}
            render={({field}) => (
              <LocalizationProvider
                dateFormats={{
                  year: "BBBB",
                  monthAndYear: "MMMM BBBB",
                }}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  {...field}
                  views={["year", "month", "day"]}
                  inputFormat="DD MMMM BBBB"
                  // disableFuture
                  disableMaskedInput
                  openTo="year"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue)
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "วัน เดือน ปี(พ.ศ.) (ไม่บังคับ)",
                        readOnly: true,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: ([
                          field.value
                            ? <IconButton key={`contractTermainatoinDate_clear_button`} className="clear-button date" onClick={()=>{field.onChange(null)}}><ClearIcon/></IconButton>
                            : null,
                          <Box key={`contractTermainatoinDate_calendar_button`}>
                            {params.InputProps.endAdornment}
                          </Box>
                        ])
                      }}
                      onBlur={field.onBlur}
                      error={useForm.formState.errors[field.name]? true: false}
                      helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>

      </Grid>
    </Box>
  )
}

export default JobInfo;