import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../../../../actions/family";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px"
  }
})

const DialogFamily = (props) => {

  const dispatch = useDispatch();

  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    relationship: yup.string().required("กรุณากรอกข้อมูล"),
    firstname_TH: yup.string().required("กรุณากรอกข้อมูล"),
    lastname_TH: yup.string().required("กรุณากรอกข้อมูล"),
    personalID: yup.string().required("กรุณากรอกข้อมูล"),
    birthday: yup.date().nullable().required("กรุณากรอกข้อมูล"),
  })

  const useHookForm = useForm({
    defaultValues: {
      relationship: "",
      firstname_TH: "",
      lastname_TH: "",
      personalID: "",
      birthday: null,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  useEffect(() => {
    if(drawerConfig.isOpen === true){
      if(drawerConfig.isEdit){
        useHookForm.reset({
          relationship: drawerConfig.data.relationship || "",
          firstname_TH: drawerConfig.data.firstname_TH || "",
          lastname_TH: drawerConfig.data.lastname_TH || "",
          personalID: drawerConfig.data.personalID || "",
          birthday: drawerConfig.data.birthday || null,
        })
      } else {
        useHookForm.reset({
          relationship: "",
          firstname_TH: "",
          lastname_TH: "",
          personalID: "",
          birthday: null,
        })
      }
    }
  }, [drawerConfig.isOpen])

  const onSubmit = async (data) => {
    // console.log(data)
    
    const formData = {
      ...data,
      idEmployees: employeeProfile.idEmployees
    }

    formData.birthday = dayjs(formData.birthday).format("YYYY-MM-DD")

    if(drawerConfig.isEdit){
      formData.idFamily = drawerConfig.data.idFamily;
      formData.updateDate = dayjs().format("YYYY-MM-DD HH:mm:ss")
    } else {
      formData.isApprove = 1;
      formData.idAdmin = userProfile.idEmployees;
      formData.approveDate = dayjs().format("YYYY-MM-DD HH:mm:ss")
    }

    const result = await dispatch(addFamily(formData))
    // const result = {status: 200}

    // console.log(result)
    
    if(result){
      if(result.status === 200){
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true))
        onClose();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      // }))
    }
  }

  return (
    <DrawerCustom
      title={drawerConfig.isEdit? "แก้ไขสมาชิกครอบครัว": "เพิ่มสมาชิกครอบครัว"}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">ความสัมพันธ์</Typography>
              <Controller
                name="relationship"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  >
                    <MenuItem value="คู่สมรส">คู่สมรส</MenuItem>
                    <MenuItem value="บุตร">บุตร</MenuItem>
                    <MenuItem value="บิดา">บิดา</MenuItem>
                    <MenuItem value="มารดา">มารดา</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">ชื่อ</Typography>
              <Controller
                name="firstname_TH"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">สกุล</Typography>
              <Controller
                name="lastname_TH"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">เลขบัตรประชาชน</Typography>
              <Controller
                name="personalID"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      format: (value) => {
                        if(value.length >= 13){
                          return String(value).replace(/(\d)(\d{1,4})(\d{1,5})(\d{1,2})(\d)/,"$1-$2-$3-$4-$5")
                        } else if(value.length >= 11) {
                          return String(value).replace(/(\d)(\d{1,4})(\d{1,5})(\d{1,2})/,"$1-$2-$3-$4")
                        } else if(value.length >= 6){
                          return String(value).replace(/(\d)(\d{1,4})(\d{1,5})/,"$1-$2-$3")
                        } else if(value.length >= 2){
                          return String(value).replace(/(\d)(\d{1,4})/,"$1-$2")
                        } else {
                          return String(value)
                        }
                      },
                      maxLength: 17,
                      allowNegative: false,
                      allowLeadingZeros: true,
                      allowEmptyFormatting: false,
                      value: field.value,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value)
                      },
                    }}
                    onChange={()=>{}}
                    error={(useHookForm.formState.errors[field.name])? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  />
                  // <TextFieldTheme
                  //   {...field}
                  //   helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  //   error={useHookForm.formState.errors[field.name]? true: false}
                  // />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">วัน เดือน ปีเกิด</Typography>
              <Controller
                name="birthday"
                control={useHookForm.control}
                render={({field}) => (
                  <LocalizationProvider
                    dateFormats={{
                      year: "BBBB",
                      monthAndYear: "MMMM BBBB",
                    }}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD MMMM BBBB"
                      disableFuture
                      disableMaskedInput
                      openTo="year"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue)
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "วัน เดือน ปี(พ.ศ.)",
                            readOnly: true,
                          }}
                          onBlur={field.onBlur}
                          error={useHookForm.formState.errors[field.name]? true: false}
                          helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                        />
                      )}
                      PaperProps={{
                        sx: {
                          "& .Mui-selected": {
                            backgroundColor: "#46cbe2!important",
                          },
                        }
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>บันทึก</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DialogFamily;