import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { Box, Grid, styled, Typography } from "@mui/material";

import { Edit } from "@mui/icons-material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerDate from "./drawerDate";
import AlertResponse from "../../../shared/general/AlertResponse";

import { getTaxDeductionSchedule } from "../../../../../actions/taxDeduction";

const Root = styled("div")({
  marginTop: "30px",
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
});

const Header = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Body = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .topicGrid": {
    fontSize: "1.25rem",
  },
  "& .textCenter": {
    textAlign: "center",
  },
  "& .topicChanging": {
    fontSize: "1.5rem",
  },
  "& .hiddenBorder": {
    borderBottom: "0",
  },
  "& .topicRowColumn": {
    fontSize: "1.20rem",
    "& .index": {
      fontWeight: "bold",
      marginLeft: "10px",
    },
  },
  "& .textDate": {
    fontSize: "1.3rem",
  },
  "& .textBold": {
    fontWeight: "bold",
  },
});

const TaxDeductionSchedule = () => {
  const dispatch = useDispatch();

  const { result: TaxDeductionSchedule } = useSelector(
    (state) => state.taxDeductionSchedule
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    isOpen: false,
    type: null,
    label: "",
  });

  useEffect(() => {
    dispatch(getTaxDeductionSchedule());
  }, []);

  return (
    <Root>
      <Header>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">หักลดหย่อนภาษี</Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<Edit />}
              onClick={() => {
                setOpenDrawer(true);
              }}
            >
              แก้ไข
            </ButtonBlue>
          </Grid>
        </Grid>
      </Header>
      <Body>
        <Grid container>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="body1"
                style={{ fontSize: "20px" }}
              >
                วันที่เริ่มการแก้ไข
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="center"
                style={{ fontSize: "28px", marginTop: 16 }}
              >
                {TaxDeductionSchedule
                  ? dayjs(TaxDeductionSchedule.startDate).format("DD MMMM YYYY")
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="body1"
                style={{ fontSize: "20px" }}
              >
                วันที่สิ้นสุดการแก้ไข
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="center"
                style={{ fontSize: "28px", marginTop: 16 }}
              >
                {TaxDeductionSchedule
                  ? dayjs(TaxDeductionSchedule.endDate).format("DD MMMM YYYY")
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Body>
      {openDrawer && (
        <DrawerDate
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onSetAlert={(type, label) => {
            setOpenAlert({ isOpen: true, type: type, label: label });
          }}
        />
      )}
      {openAlert.isOpen && (
        <AlertResponse
          open={openAlert.isOpen}
          handleClose={() =>
            setOpenAlert({ isOpen: false, type: null, label: "" })
          }
          alertType={openAlert.type}
          label={openAlert.label}
        />
      )}
    </Root>
  );
};

export default TaxDeductionSchedule;
