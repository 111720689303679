import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Divider, Box } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DialogEdit from "./DialogEdit";
import dayjs from "dayjs";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const Probation = () => {

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const [openEdit, setOpenEdit] = useState(false);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  })

  const onDrawerClose = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const toggleDrawer = (open) => (event) => {
    console.log(open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenEdit(open);
  };

  return (
    <div>
      {/* <StyledWrapTop>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            onClick={toggleDrawer(true)}
          >
            แก้ไข
          </ButtonBlue>
        </div>
      </StyledWrapTop> */}
      <Box display="flex" justifyContent="flex-end">
        <ButtonBlue
          variant="text"
          size="small"
          startIcon={<EditIcon />}
          onClick={() => {
            setDrawerConfig(prev => ({
              ...prev,
              isOpen: true,
              data: {
                round: 1,
                start: employeeProfile.probation.roundOne.start? dayjs(employeeProfile.probation.roundOne.start).format("DD MMMM BBBB") : "-",
                end: employeeProfile.probation.roundOne.end? dayjs(employeeProfile.probation.roundOne.end).format("DD MMMM BBBB") : "-",
                result: employeeProfile.probation.roundOne.result,
                remark: employeeProfile.probation.roundOne.remark,
              }
            })) 
          }}
          // onClick={toggleDrawer(true)}
        >
          แก้ไข
        </ButtonBlue>
      </Box>
      {employeeProfile && <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              วันที่เริ่มต้น
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.probation.roundOne.start? dayjs(employeeProfile.probation.roundOne.start).format("DD MMMM BBBB") : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              วันที่สิ้นสุด
            </StyledHeadLabel>
            <Typography variant="h6">
            {employeeProfile.probation.roundOne.end? dayjs(employeeProfile.probation.roundOne.end).format("DD MMMM BBBB") : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">ผลทดลองงาน</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.probation.roundOne.result? employeeProfile.probation.roundOne.result === "pass"? "ผ่านการทดลองงาน": "ไม่ผ่านการทดลองงาน": "-"}
            </Typography>
            <Typography fontSize="14px" color="text.secondary" fontStyle="italic">ผู้ประเมิน : {employeeProfile.probation.roundOne.managerApprove_firstname_TH} {employeeProfile.probation.roundOne.managerApprove_lastname_TH} {employeeProfile.probation.roundOne.approveDate? dayjs(employeeProfile.probation.roundOne.approveDate).format("D MMMM BBBB HH:mm:ss น."): ""}</Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">หมายเหตุ</StyledHeadLabel>
            <Typography fontSize="14px" color="text.secondary" fontStyle="italic" whiteSpace="pre-line" style={{wordBreak: "break-word"}}>
              {employeeProfile.probation.roundOne.remark? employeeProfile.probation.roundOne.remark : "-"}
            </Typography>
          </div>
        </Grid>
        {employeeProfile.probation.roundTwo && <Fragment>
          <Grid item xs={12} container alignItems="center">
            <Typography flexGrow="1" fontSize="16px" fontWeight="600">การทดลองงาน รอบที่ 2</Typography>
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => {
                setDrawerConfig(prev => ({
                  ...prev,
                  isOpen: true,
                  data: {
                    round: 2,
                    start: employeeProfile.probation.roundTwo.start? dayjs(employeeProfile.probation.roundTwo.start).format("DD MMMM BBBB") : "-",
                    end: employeeProfile.probation.roundTwo.end? dayjs(employeeProfile.probation.roundTwo.end).format("DD MMMM BBBB") : "-",
                    result: employeeProfile.probation.roundTwo.result,
                    remark: employeeProfile.probation.roundTwo.remark,
                  }
                })) 
              }}
              // onClick={toggleDrawer(true)}
            >
              แก้ไข
            </ButtonBlue>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>
              <StyledHeadLabel color="text.secondary">
                วันที่เริ่มต้น
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.probation.roundTwo.start? dayjs(employeeProfile.probation.roundTwo.start).format("DD MMMM BBBB") : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>
              <StyledHeadLabel color="text.secondary">
                วันที่สิ้นสุด
              </StyledHeadLabel>
              <Typography variant="h6">
              {employeeProfile.probation.roundTwo.end? dayjs(employeeProfile.probation.roundTwo.end).format("DD MMMM BBBB") : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">ผลทดลองงาน</StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.probation.roundTwo.result? employeeProfile.probation.roundTwo.result === "pass"? "ผ่านการทดลองงาน": "ไม่ผ่านการทดลองงาน": "-"}
              </Typography>
              <Typography fontSize="14px" color="text.secondary" fontStyle="italic">ผู้ประเมิน : {employeeProfile.probation.roundTwo.managerApprove_firstname_TH} {employeeProfile.probation.roundTwo.managerApprove_lastname_TH} {employeeProfile.probation.roundTwo.approveDate? dayjs(employeeProfile.probation.roundTwo.approveDate).format("D MMMM BBBB HH:mm:ss น."): ""}</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">หมายเหตุ</StyledHeadLabel>
              <Typography fontSize="14px" color="text.secondary" fontStyle="italic" whiteSpace="pre-line" style={{wordBreak: "break-word"}}>
                {employeeProfile.probation.roundTwo.remark? employeeProfile.probation.roundTwo.remark : "-"}
              </Typography>
            </div>
          </Grid>
        </Fragment>}
      </Grid>}
      {/* <StyledDivider />
      <div>
        <StyledHeadLabel color="text.secondary">
          บันทึกการเปลี่ยนแปลง
        </StyledHeadLabel>
      </div> */}
      {/* <DialogEdit open={openEdit} toggleDrawer={toggleDrawer} /> */}
      <DialogEdit
        drawerConfig={drawerConfig}
        onClose={onDrawerClose}
      />
    </div>
  );
};

export default Probation;
