import { Grid, styled, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerBank from "./DrawerBank";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

import { getBankDetailByCompany } from '../../../../actions/bank';

const StyledWrapTop = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "&.inner": {
      marginBottom: 0,
    },
    "& .MuiTypography-body2": {
      margin: 0,
    },
});

const StyledHeadLabel = styled(Typography)({
    fontWeight: 600,
    fontSize: 14,
});

const BankPanel = () => {
  const dispatch = useDispatch();
  const { result: BankDetail } = useSelector(state => state.bankDetail);
  const [isOpenDrawerBank, setIsOpenDrawerBank] = useState(false);

  useEffect(() => {
    dispatch(getBankDetailByCompany());
  },[]);

  return(
    <div>
      <StyledWrapTop>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
            ธนาคาร
        </Typography>
        <ButtonBlue
          size="small"
          startIcon={<EditRoundedIcon />}
          onClick={() => setIsOpenDrawerBank(true)}
        >
          แก้ไข
        </ButtonBlue>
      </StyledWrapTop>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">ชื่อธนาคาร</StyledHeadLabel>
          <Typography variant="h6">
            {(BankDetail && BankDetail[0] && BankDetail[0].bankName) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">เลขที่บัญชี</StyledHeadLabel>
          <Typography variant="h6">
            {(BankDetail && BankDetail[0] && BankDetail[0].accountNo) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">ชื่อบัญชี</StyledHeadLabel>
          <Typography variant="h6">
            {(BankDetail && BankDetail[0] && BankDetail[0].accountName) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">ชื่อสาขา</StyledHeadLabel>
          <Typography variant="h6">
            {(BankDetail && BankDetail[0] && BankDetail[0].branchName) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">ที่อยู่สาขา</StyledHeadLabel>
          <Typography variant="h6">
            {(BankDetail && BankDetail[0] && BankDetail[0].branchAddress) || "-"}
          </Typography>
        </Grid>
      </Grid>

      {isOpenDrawerBank && (
        <DrawerBank
          open={isOpenDrawerBank}
          onClose={()=>{setIsOpenDrawerBank(false)}}
        />
      )}
    </div>
  )
};

export default BankPanel;