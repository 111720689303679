import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Autocomplete, Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Controller, useForm, useHookForm } from "react-hook-form";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../../../../actions/family";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import { getWorkInfoByidCompany } from "../../../../../../actions/company";
import { updateUser } from "../../../../../../actions/user";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px"
  }
})

const DialogCostCenter = (props) => {

  const dispatch = useDispatch();

  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    // bookBank: yup.string().required("โปรดกรอกข้อมูล"),
    // bookBankBranchName: yup.string().required("โปรดกรอกข้อมูล"),
    // bookID: yup.string().required("โปรดกรอกข้อมูล"),
  })

  const useHookForm = useForm({
    defaultValues: {
      costElementCharge: "",
      costElementCharge: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  useEffect(() => {
    if(drawerConfig.isOpen === true){
      if(drawerConfig.isEdit){
        useHookForm.reset({
          costCenterCharge: drawerConfig.data.costCenterCharge || "",
          costElementCharge: drawerConfig.data.costElementCharge || "",
        })
      } else {
        useHookForm.reset({
          costElementCharge: "",
          costElementCharge: "",
        })
      }
    }
  }, [drawerConfig.isOpen])

  const onSubmit = async (data) => {
    // console.log(data)
    
    const formData = {
      ...data,
      idEmployees: employeeProfile.idEmployees,
      UpdateBy: userProfile.idEmployees,
      updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss")
    }

    Object.keys(formData).map(k => {
      if(formData[k] === ""){
        formData[k] = null
      } else {
        formData[k] = formData[k]
      }
    })

    const result = await dispatch(updateUser(formData))
    
    if(result){
      if(result.status === 200){
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true))
        onClose();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      // }))
    }
  }

  return (
    <DrawerCustom
      title={"แก้ไขข้อมูล Cost Center"}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">Cost Center Charge</Typography>
              <Controller
                name="costCenterCharge"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="field-label">Cost Element Charge</Typography>
              <Controller
                name="costElementCharge"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>บันทึก</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DialogCostCenter;