import React from "react";
import { Autocomplete, Box, Grid, MenuItem, Typography, createFilterOptions } from "@mui/material";
import { Controller } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const ManagerInfo = (params) => {
  const { useForm, registrationMetadata } = params;

  const filterOptions = createFilterOptions({
    stringify: (value) => `${value.firstname_TH} ${value.lastname_TH} ${value.email}`
  })

  return (
    <Box>
      <Typography className="title-text">หัวหน้างาน</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>หัวหน้างาน ระดับที่ 1</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idManagerLV1"
            control={useForm.control}
            render={({field}) => (
              <Autocomplete
                options={registrationMetadata.managers}
                getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                filterOptions={filterOptions}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idEmployees}>
                    <Box>
                      <Typography>{option.firstname_TH} {option.lastname_TH}</Typography>
                      <Typography color="text.third" fontSize="14px">{option.email}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                  />
                )}
                value={registrationMetadata.managers.find(option => option.idEmployees === field.value) || null}
                onChange={(_, value) => {field.onChange(value? value.idEmployees: "")}}
                disableClearable
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>หัวหน้างาน ระดับที่ 2</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idManagerLV2"
            control={useForm.control}
            render={({field}) => (
              <Autocomplete
              options={registrationMetadata.managers}
              getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
              filterOptions={filterOptions}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.idEmployees}>
                    <Box>
                      <Typography>{option.firstname_TH} {option.lastname_TH}</Typography>
                      <Typography color="text.third" fontSize="14px">{option.email}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    onBlur={field.onBlur}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                  />
                )}
                value={registrationMetadata.managers.find(option => option.idEmployees === field.value) || null}
                onChange={(_, value) => {field.onChange(value? value.idEmployees: "")}}
                disableClearable
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ManagerInfo;