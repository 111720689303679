import React from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MoreVertIcon from '@mui/icons-material/MoreVert';

import "./index.css";

import StatusRequest from "./stausRequest";

function createData(status, name, created, start, stop, protein) {
  return { status, name, created, start, stop, protein };
}

const rows = [
  createData(
    0,
    "คำขอค่าล่วงเวลา",
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    4.0
  ),
  createData(
    1,
    "คำขอรับรองเวลาทำงาน",
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    4.3
  ),
  createData(
    2,
    "คำขอลาพักร้อน",
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    6.0
  ),
  createData(
    1,
    "คำขอค่าล่วงเวลา",
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    4.3
  ),
  createData(
    1,
    "คำขอรับรองเวลาทำงาน",
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    3.9
  ),
  createData(
    1,
    "คำขอรับรองเวลาทำงาน",
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    new Date(2021, 8, 12, 14, 46, 51),
    3.9
  ),
];

// const StyledTableContainer = styled(TableContainer)({
//     width: "100%",
//     minWidth: 720,
//   });

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell":{
    borderColor : "#919eab3d",
    padding: "8px 16px"
  },
  "& .cellStatusAction": {
    width: 30,
  },
  "& .cellRequest": {
    //width: 150,
  },
  "& .cellStartStop": {
    width: 150,
  },
});

export default function TableRequest() {
  return (
    <TableContainer className="tableContainer">
      <StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={`tableCellHead cellStatusAction`}>สถานะ</TableCell>
            <TableCell className={`tableCellHead cellRequest`}>รายการ</TableCell>
            <TableCell className={`tableCellHead cellStartStop`}>เริ่มต้น</TableCell>
            <TableCell className={`tableCellHead cellStartStop`}>สิ้นสุด</TableCell>
            <TableCell align="center" className={`tableCellHead cellStatusAction`}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={`tableCell cellStatusAction`}>
                <StatusRequest status={row.status} />
              </TableCell>
              <TableCell className={`tableCell cellRequest`}>
                <div>
                  <Typography color="text.primary">{row.name}</Typography>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                  >{`Created : ${dayjs(row.created).format(
                    "D/MM/YYYY,HH:mm"
                  )}`}</Typography>
                </div>
              </TableCell>
              <TableCell className={`tableCell cellStartStop`}>
                <div>
                  <Typography>
                    {dayjs(row.start).format("D MMM YYYY")}
                  </Typography>
                  <Typography>{dayjs(row.start).format("HH:mm")}</Typography>
                </div>
              </TableCell>
              <TableCell className={`tableCell cellStartStop`}>
                <div>
                  <Typography>
                    {dayjs(row.stop).format("D MMM YYYY")}
                  </Typography>
                  <Typography>{dayjs(row.stop).format("HH:mm")}</Typography>
                </div>
              </TableCell>
              <TableCell align="center" className={`tableCell cellStatusAction`}>
                <IconButton aria-label="more" size="small">
                  <MoreVertIcon fontSize="inherit" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
