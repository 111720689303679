import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import { IconButton, Box, Container, Divider, Typography } from "@mui/material";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";

import DialogDetail from "./dialogDetail";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";

import {
  getLevelByidCompany,
  deleteGroupLevel,
  deleteLevel,
} from "../../../../../actions/level";

const Root = styled("div")({
  backgroundColor: "white !important",
  height: "fit-content",
  marginTop: "30px",
  paddingBottom: "20px",
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(270deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  borderTop: "none",
  "& .wrap-item": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const StyledDivider = styled(Divider)({
  margin: "8px 0px",
  borderWidth: "0px 0px thin",
  borderColor: "rgba(145, 158, 171, 0.24)",
  borderStyle: "dashed",
  width: "100%",
});

const Level = (props) => {
  const { result: levelList } = useSelector((state) => state.level);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState("");
  const [openDetail, setOpenDetail] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [mode, setMode] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [editLevel, setEditLevel] = useState(null);

  useEffect(() => {
    dispatch(getLevelByidCompany());
  }, []);

  const handleAddLevel = (groupLevel) => (event) => {
    event.stopPropagation();
    setEditLevel(groupLevel.idGroupLevel);
    setMode("edit");
    handleOpenDialog();
  };

  const handleOrderGroupLevel = () => {
    setMode("group");
    setEditLevel(null);
    handleOpenDialog();
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleOpenDialog = () => {
    setOpenDetail(true);
  };

  const handleCloseDialog = () => {
    setOpenDetail(false);
    setOpenDialogDelete(false);
    setMode(null);
    setEditLevel(null);
  };

  const handleDeleteLevel = (level) => (event) => {
    event.stopPropagation();
    setOpenDialogDelete(true);
    setSelectedId({
      label: "ระดับ",
      text: level.levelName,
      idLevel: level.idLevel,
    });
  };

  const handleDeleteGroupLevel = (groupLevel) => (event) => {
    event.stopPropagation();
    setOpenDialogDelete(true);
    setSelectedId({
      label: "กลุ่มระดับ",
      text: groupLevel.groupLevelName,
      idGroupLevel: groupLevel.idGroupLevel,
    });
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleDelete = async () => {
    let result = null;
    if (selectedId === "กลุ่มระดับ") {
      result = await dispatch(deleteGroupLevel(selectedId.idGroupLevel));
    } else {
      result = await dispatch(deleteLevel(selectedId.idLevel));
    }

    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        setAlertType("success");
        dispatch(getLevelByidCompany());
      } else {
        setAlertType("error");
      }
      handleCloseDialog();
    } else {
      handleCloseDialog();
      handleOpenAlert();
      setAlertType("error");
    }
  };

  const handleCloseAlertResponse = () => {
    setOpenAlert(false);
    setAlertType(null);
  };

  return (
    <Root>
      <Container maxWidth="lg">
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: 16,
          }}
        >
          <Typography variant="h4">กลุ่มระดับ</Typography>
          <div>
            <ButtonBlue variant="contained" onClick={handleOrderGroupLevel}>
              เพิ่ม/แก้ไขกลุ่มระดับ
            </ButtonBlue>
          </div>
        </Box>
        {levelList &&
          levelList.map((groupLevel, index) => (
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <div className="wrap">
                  <Typography>{groupLevel.groupLevelName}</Typography>
                  <div>
                    <IconButton
                      aria-label="add"
                      onClick={handleAddLevel(groupLevel)}
                    >
                      <EditNoteRoundedIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={handleDeleteGroupLevel(groupLevel)}
                    >
                      <DeleteOutlineRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {groupLevel.level && groupLevel.level.length > 0 ? (
                  groupLevel.level.map((level, index) => (
                    <Fragment>
                      <div className="wrap-item" key={index}>
                        <Typography>
                          <Brightness1RoundedIcon
                            style={{
                              fontSize: 8,
                              margin: "0 16px",
                              color: "#9E9E9E",
                            }}
                          />
                          {level.levelName}
                        </Typography>
                        <IconButton
                          aria-label="delete"
                          style={{ color: "#e46a76" }}
                          onClick={handleDeleteLevel(level)}
                        >
                          <HighlightOffRoundedIcon />
                        </IconButton>
                      </div>

                      <StyledDivider />
                    </Fragment>
                  ))
                ) : (
                  <Typography
                    color="text.secondary"
                    style={{ fontStyle: "oblique", padding: 16 }}
                  >
                    ไม่มีระดับ
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
      </Container>
      {mode && openDetail && (
        <DialogDetail
          open={openDetail}
          handleClose={handleCloseDialog}
          mode={mode}
          editLevel={editLevel}
        />
      )}
      {selectedId && (
        <DialogConfirmDelete
          open={openDialogDelete}
          handleClose={handleCloseDialog}
          label={selectedId.label}
          text={selectedId.text}
          handleDelete={handleDelete}
        />
      )}

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlertResponse}
        alertType={alertType}
      />
    </Root>
  );
};

export default Level;
