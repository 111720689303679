import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Link,
} from "@mui/material";

import DrawerCustom from "../../../shared/general/Drawer";
import AddIcon from "../../../assets/add.png";
import DragDropImage from "./dragdropImage";
import FileSelected from "./file-selected";
import ShowResponse from "./show-response";

import { dumpExcelAddressEmployees, dumpExcelBirthdayEmployees, dumpExcelBookBankEmployees, dumpExcelContractTermainatoinEmployees, dumpExcelCostEmployees, dumpExcelEducation1Employees, dumpExcelEducation2Employees, dumpExcelEducation3Employees, dumpExcelEmailEmployees, dumpExcelEmergencyContactEmployees, dumpExcelGenderEmployees, dumpExcelManagerLV1Employees, dumpExcelManagerLV2Employees, dumpExcelNationalityEmployees, dumpExcelNicknameEmployees, dumpExcelPassportEmployees, dumpExcelResignEmployees, dumpExcelStatusEmployees, dumpExcelTelephoneEmployees, dumpExcelTimeEmployees, dumpExcelWorkPermitEmployees, getAllEmployees, uploadExcelEmployees } from "../../../../../actions/employee";
import { uploadExcelLeaveEmployees } from "../../../../../actions/leave";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyleSelect = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    padding: "0px 12px",
    "& .MuiSelect-select": {
      padding: "13.5px 0px",
    },
  },
});

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const StyledLoading = styled("div")({
  height: 200,
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const dumpList = [
  {
    id: 1,
    name: "ข้อมูลพนักงาน",
    disabled: false,
  },
  {
    id: 2,
    name: "รูปพนักงาน",
    disabled: false,
  },
  {
    id: 3,
    name: "ผลงาน",
    disabled: true,
  },
  {
    id: 4,
    name: "หัวหน้างาน",
    disabled: true,
  },
  {
    id: 5,
    name: "ประสบการณ์พนักงาน",
    disabled: true,
  },
  {
    id: 6,
    name: "ปรับระดับ",
    disabled: true,
  },
  {
    id: 7,
    name: "ปรับค่าจ้าง",
    disabled: false,
  },
  {
    id: 8,
    name: "Additions/Deductions",
    disabled: false,
  },
  {
    id: 9,
    name: "วันลาพนักงาน",
    disabled: false,
  },
  {
    id: 10,
    name: "ชื่อเล่น",
    disabled: false,
  },
  {
    id: 11,
    name: "เพศ",
    disabled: false,
  },
  {
    id: 12,
    name: "สัญชาติ",
    disabled: false,
  },
  {
    id: 13,
    name: "สถานะ",
    disabled: false,
  },
  {
    id: 14,
    name: "เลขพาสปอร์ต",
    disabled: false,
  },
  {
    id: 15,
    name: "เลขที่ใบอนุญาตทำงาน",
    disabled: false,
  },
  {
    id: 16,
    name: "วันเกิด",
    disabled: false,
  },
  {
    id: 17,
    name: "ที่อยู่",
    disabled: false,
  },
  {
    id: 18,
    name: "เบอร์โทรศัพท์",
    disabled: false,
  },
  {
    id: 19,
    name: "Email",
    disabled: false,
  },
  {
    id: 20,
    name: "ผู้ติดต่อ",
    disabled: false,
  },
  {
    id: 21,
    name: "บันทึกเวลาทำงาน",
    disabled: false,
  },
  {
    id: 22,
    name: "ผู้จัดการลำดับที่ 1",
    disabled: false,
  },
  {
    id: 23,
    name: "ผู้จัดการลำดับที่ 2",
    disabled: false,
  },
  {
      id: 24,
      name: "ประเภทการทำงาน",
      disabled: true,
  },
  {
    id: 26,
    name: "วันหมดสัญญา",
    disabled: false,
  },
  {
    id: 27,
    name: "วันลาออก",
    disabled: false,
  },
  {
    id: 28,
    name: "การศึกษา1",
    disabled: false,
  },
  {
    id: 29,
    name: "การศึกษา2",
    disabled: false,
  },
  {
    id: 30,
    name: "การศึกษา3",
    disabled: false,
  },
  {
      id: 31,
      name: "การประเมินประจำปี",
      disabled: true,
  },
  {
    id: 32,
    name: "บริษัทที่เรียกเก็บเงิน",
    disabled: false,
  },
  {
    id: 33,
    name: "ข้อมูลธนาคาร",
    disabled: false,
  },
];

const DialogUpload = (props) => {
  const { open, handleClose, setStatusResult } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [dumpSelect, setDumpSelect] = React.useState("");
  const [fileSelected, setFileSelected] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [waitingAPI, setWaitingAPI] = useState(false);

  useEffect(() => {
    setDumpSelect("");
    setErrorResponse(null);
    setFileSelected(null);
    setCompanySelected(null);
    setFormFileSelected(null);
    setOpenLoading(null);
  }, [open]);

  const handleChangeDumpSelect = (event) => {
    setDumpSelect(event.target.value);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.map((file) => {
        formData.append("file", file);
        formData.append("selected", companySelected);
      });
      setFormFileSelected(formData);
      setFileSelected({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    },
    maxFiles: 1,
  });

  const handleClickUpload = async () => {
    // if (dumpSelect === 1) {
    //   const result = await dispatch(addUsersFirstTime(formFileSelected));
    //   if (result) {
    //     setErrorResponse(result);
    //     setOpenLoading(false);
    //   }
    // } else if (dumpSelect === 2) {
    //   const result = await dispatch(addUsers(formFileSelected));
    //   if (result) {
    //     setErrorResponse(result);
    //     setOpenLoading(false);
    //   }
    // } else if (dumpSelect === 8) {
    //   const result = await dispatch(
    //     addEmployeeAdditionDeduction(formFileSelected)
    //   );
    // } else if (dumpSelect === 7) {
    //   const result = await dispatch(employeeSalary(formFileSelected));
    //   if (result) {
    //     setErrorResponse(result);
    //     // handleChangeAlertType("success");
    //     // handleOpenAlert(true);
    //     // handleClose();
    //   }
    // }
    if (dumpSelect === 1) {
      const result = await dispatch(uploadExcelEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
        await dispatch(getAllEmployees());
      }
    } else if (dumpSelect === 9) {
      const result = await dispatch(uploadExcelLeaveEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
        await dispatch(getAllEmployees());
      }
    }
    else if (dumpSelect === 10) {
      const result = await dispatch(dumpExcelNicknameEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 11) {
      const result = await dispatch(dumpExcelGenderEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 12) {
      const result = await dispatch(dumpExcelNationalityEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 13) {
      const result = await dispatch(dumpExcelStatusEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 14) {
      const result = await dispatch(dumpExcelPassportEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 15) {
      const result = await dispatch(dumpExcelWorkPermitEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 16) {
      const result = await dispatch(dumpExcelBirthdayEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 17) {
      const result = await dispatch(dumpExcelAddressEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 18) {
      const result = await dispatch(dumpExcelTelephoneEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 19) {
      const result = await dispatch(dumpExcelEmailEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 20) {
      const result = await dispatch(dumpExcelEmergencyContactEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 21) {
      const result = await dispatch(dumpExcelTimeEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 22) {
      const result = await dispatch(dumpExcelManagerLV1Employees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 23) {
      const result = await dispatch(dumpExcelManagerLV2Employees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 26) {
      const result = await dispatch(dumpExcelContractTermainatoinEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 27) {
      const result = await dispatch(dumpExcelResignEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 28) {
      const result = await dispatch(dumpExcelEducation1Employees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 29) {
      const result = await dispatch(dumpExcelEducation2Employees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 30) {
      const result = await dispatch(dumpExcelEducation3Employees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 32) {
      const result = await dispatch(dumpExcelCostEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 33) {
      const result = await dispatch(dumpExcelBookBankEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    setCompanySelected(event.target.value);
  };

  const handleCloseLoading = () => {
    //setOpenLoading(false);
    handleClose();
  };

  const handleClickDownload = () => {
    console.log(dumpSelect);
    const template = {
      template7: {
        sheet: ["salary"],
        fileName: "ปรับเงินเดือน.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          เงินเดือน: "",
        },
      },
      template10: {
        sheet: ["nickname"],
        fileName: "ชื่อเล่น.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          Nickname: "",
        },
      },
      template11: {
        sheet: ["gender"],
        fileName: "เพศ.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          Gender: "",
        },
      },
      template12: {
        sheet: ["nationality"],
        fileName: "สัญชาติ.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          สัญชาติ: "",
        },
      },
      template13: {
        sheet: ["status"],
        fileName: "สถานะ.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          สถานะ: "",
        },
      },
      template14: {
        sheet: ["passport number"],
        fileName: "เลขพาสปอร์ต.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "Passport Number": "",
        },
      },
      template15: {
        sheet: ["work permit number"],
        fileName: "เลขที่ใบอนุญาตทำงาน.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "Work Permit Number": "",
        },
      },
      template16: {
        sheet: ["birthday"],
        fileName: "วันเกิด.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          Birthday: "",
        },
      },
      template17: {
        sheet: ["address"],
        fileName: "ที่อยู่.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          บ้านเลขที่: "",
          หมู่บ้าน: "",
          หมู่ที่: "",
          ซอย: "",
          ถนน: "",
          "ตำบล/แขวง": "",
          "อำเภอ/เขต": "",
          จังหวัด: "",
          รหัสไปรณีย์: "",
        },
      },
      template18: {
        sheet: ["telephone mobile"],
        fileName: "เบอร์โทรศัพท์.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "Telephone Mobile": "",
        },
      },
      template19: {
        sheet: ["email"],
        fileName: "อีเมล.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          Email: "",
        },
      },
      template20: {
        sheet: ["emergency contact"],
        fileName: "ผู้ติดต่อ.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          ผู้ติดต่อหลัก: "",
          ความสัมพันธ์: "",
          เบอร์ติดต่อผู้ใกล้ชิด: ""
        },
      },
      template21: {
        sheet: ["time attendance"],
        fileName: "บันทึกเวลาทำงาน.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          บันทึกเวลาทำงาน: "",
        },
      },
      template22: {
        sheet: ["manager level 1"],
        fileName: "ผู้จัดการลำดับที่ 1.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "Reporting Manager Level 1": "",
          "Manager Position Level 1": "",
          "Manager E-Mail Level 1": ""
        },
      },
      template23: {
        sheet: ["manager level 2"],
        fileName: "ผู้จัดการลำดับที่ 2.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "Reporting Manager Level 2": "",
          "Manager Position Level 2": "",
          "Manager E-Mail Level 2": ""
        },
      },
      // template24: {
      //     sheet: ["working type"],
      //     fileName: "ประเภทการทำงาน.xlsx",
      //     header: {
      //         รหัสพนักงาน: "",
      //         ชื่อ: "",
      //         สกุล: "",
      //         เลขบัตรประจำตัวประชาชน: "",
      //         "Working Type": "",
      //         "Shift Type": "",
      //         "Shift Name": "",
      //         จำนวนวันทำงาน: "",
      //         จำนวนวันหยุด: "",
      //     },
      // },
      // template25: {
      //     sheet: ["cost of operation"],
      //     fileName: "ค่าดำเนินการ.xlsx",
      //     header: {
      //         รหัสพนักงาน: "",
      //         ชื่อ: "",
      //         สกุล: "",
      //         เลขบัตรประจำตัวประชาชน: "",
      //         ค่าดำเนินการปกติ: "",
      //         "ค่าดำเนินการ OT": "",
      //         ค่าดำเนินการเหมา: "",
      //         ค่าดำเนินการค่าชดเชย: "",
      //     },
      // },
      template26: {
        sheet: ["contract termainatoin date"],
        fileName: "วันหมดสัญญา.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "Contract Termainatoin Date": "",
        },
      },
      template27: {
        sheet: ["resign date"],
        fileName: "วันลาออก.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "Resign Date": "",
        },
      },
      template28: {
        sheet: ["education degree1"],
        fileName: "การศึกษา1.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "(1)Education Degree": "",
          "(1)Education School or University": "",
          "(1)Faculty": "",
          "(1)Major": "",
          "(1)From Year": "",
          "(1)End Year": "",
          "(1)GPA": "",
        },
      },
      template29: {
        sheet: ["education degree2"],
        fileName: "การศึกษา2.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "(2)Education Degree": "",
          "(2)Education School or University": "",
          "(2)Faculty": "",
          "(2)Major": "",
          "(2)From Year": "",
          "(2)End Year": "",
          "(2)GPA": "",
        },
      },
      template30: {
        sheet: ["education degree3"],
        fileName: "การศึกษา3.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "(3)Education Degree": "",
          "(3)Education School or University": "",
          "(3)Faculty": "",
          "(3)Major": "",
          "(3)From Year": "",
          "(3)End Year": "",
          "(3)GPA": "",
        },
      },
      // template31: {
      //     sheet: ["performance review year"],
      //     fileName: "การประเมินประจำปี.xlsx",
      //     header: {
      //         รหัสพนักงาน: "",
      //         ชื่อ: "",
      //         สกุล: "",
      //         เลขบัตรประจำตัวประชาชน: "",
      //         "Performance Review Year (Past 1 Year)": "",
      //         "Performance Review Year (Past 2 Year)": "",
      //         "Performance Review Year (Past 3 Year)": "",
      //         "Performance Review Year (Past 4 Year)": "",
      //         "Performance Review Year (Past 5 Year)": "",
      //     },
      // },
      template32: {
        sheet: ["cost"],
        fileName: "บริษัทที่เรียกเก็บเงิน.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "Cost Center Charge": "",
          "Cost Element Charge": "",
          IO: "",
        },
      },
      template33: {
        sheet: ["book bank"],
        fileName: "ข้อมูลธนาคาร.xlsx",
        header: {
          รหัสพนักงาน: "",
          ชื่อ: "",
          สกุล: "",
          เลขบัตรประจำตัวประชาชน: "",
          "Book Bank": "",
          "Book Bank Branch Name": "",
          "Book ID": "",
          "Report ส่งธนาคาร ชื่อธนาคาร": "",
          "Report ส่งธนาคาร รหัสธนาคาร": "",
          "Report ส่งธนาคาร เลขที่บัญชี": "",
          "Report ส่งธนาคาร ชื่อผู้รับเงิน": "",
          "Report ส่งธนาคาร อ้างอิง": "",
        },
      },
    };


    if (dumpSelect === 1) {
      window.open(`${process.env.REACT_APP_API_URL}files/PIS Master Final Template 2023-09-21.xlsx`)
    }

    const downloadList = [7, 10,11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33]

    if (downloadList.includes(dumpSelect)) {
      const elementList = [];

      elementList.push(template[`template${dumpSelect}`].header);

      const ws = XLSX.utils.json_to_sheet(elementList);

      var workbook = XLSX.utils.book_new();

      template[`template${dumpSelect}`].sheet.forEach((element) => {
        XLSX.utils.book_append_sheet(workbook, ws, element);
      });

      XLSX.writeFile(workbook, template[`template${dumpSelect}`].fileName, {
        type: "file",
      });

    };
  };

  const handleOpenStatusResult = async (status, result, label) => {
    setStatusResult({ isOpen: open, result: result, label: label });
    setWaitingAPI(false);
    if (status === 200) {
      handleClose();
      await dispatch(getAllEmployees());
    }
  };

  return (
    <DrawerCustom
      title={"อัพโหลดข้อมูล"}
      anchor={"right"}
      open={open}
      onClose={() => {
        if (!waitingAPI) {
          handleClose();
        }
      }}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyleSelect fullWidth disabled={waitingAPI}>
              <InputLabel id="demo-simple-select-label">
                เลือกรายการอัพโหลด
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="dump-list"
                label="เลือกรายการอัพโหลด"
                onChange={handleChangeDumpSelect}
              >
                {dumpList.map((item) => (
                  <MenuItem disabled={item.disabled} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </StyleSelect>
          </Grid>
          {dumpSelect && (
            <Grid item xs={12}>
              {openLoading ? (
                <StyledLoading>
                  <Typography variant="h6" color="text.third" gutterBottom>
                    กำลังบันทึกข้อมูล...
                  </Typography>
                  <div>
                    <CircularProgress />
                  </div>
                </StyledLoading>
              ) : (
                <Fragment>
                  {errorResponse ? (
                    <ShowResponse
                      errorResponse={errorResponse}
                      handleCloseLoading={handleCloseLoading}
                    />
                  ) : (
                    <Fragment>
                      <StyledImportFile>
                        {dumpSelect !== 2 && (
                          <div
                            style={{
                              marginBottom: 16,
                              display: "flex",
                              justifyContent: "flex-end",
                              cursor: "pointer",
                            }}
                          >
                            <Link variant="body2" onClick={handleClickDownload}>
                              {dumpSelect === 1
                                ? `ดาวน์โหลดเทมเพลต PIS Master Final Template 2023-09-21.xlsx`
                                : "ดาวน์โหลดเทมเพลต"}
                            </Link>
                          </div>
                        )}
                        {dumpSelect !== 2 ? (
                          <div
                            {...getRootProps({
                              className: "dropzone-upload-file",
                            })}
                          >
                            <input {...getInputProps()} />
                            <div className="inner-dropzone">
                              <img alt="AddIcon" src={AddIcon} width="80" />
                              <Typography
                                style={{
                                  marginTop: 8,
                                  backgroundColor: "transparent",
                                }}
                                className={`${fileSelected != 0 && `placeholderLabel`
                                  }`}
                                variant="body2"
                                color="text.secondary"
                              >
                                Drag & Drop หรือ คลิ๊กเพื่อเลือกไฟล์
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          <DragDropImage
                            waitingAPI={waitingAPI}
                            setWaitingAPI={setWaitingAPI}
                            handleOpenStatusResult={handleOpenStatusResult}
                          />
                        )}
                      </StyledImportFile>
                      {fileSelected && dumpSelect !== 2 && (
                        <FileSelected
                          fileSelected={fileSelected}
                          handleClickUpload={handleClickUpload}
                        />
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Grid>
          )}
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogUpload;
