import { httpClient } from "./httpClient";

const getProvidentFundUser = () => {
    return httpClient.get(`providentFund`);
};

const updateProvidentFundUser = (userPF, companyPF) => {
    return httpClient.put("providentFund", { userPF: userPF, companyPF: companyPF });
};

const getProvidentFundEmployeeByIdEmployee = (idEmployee) => {
    return httpClient.get(`providentFund/${idEmployee}`);
};

export default {
    getProvidentFundUser,
    updateProvidentFundUser,
    getProvidentFundEmployeeByIdEmployee
};