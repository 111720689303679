import { httpClient } from "./httpClient";

const getAllCareerPlan = () => {
  return httpClient.get("careerplan");
};

const postCareerEmployee = (data) => {
  return httpClient.post(`careerplan/postcareeremployee`, data);
};

const postManagerSuggest = (data) => {
  return httpClient.post(`careerplan/postmanagersuggest`, data);
};

const putEmpIntCareerPlan = (id, data) => {
  return httpClient.post(`careerplan/putempintcareerplan/${id}`, data);
};

const putManagerSugCareerPlan = (id, data) => {
  return httpClient.post(`careerplan/putmanagersugcareerplan/${id}`, data);
};

export default {
  getAllCareerPlan,
  postCareerEmployee,
  postManagerSuggest,
  putEmpIntCareerPlan,
  putManagerSugCareerPlan,
};
