import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { Manager, DataValue } from "../mockData";
import { styled } from "@mui/styles";

import makeStyles from "@mui/styles/makeStyles";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: "0 !important",
    },
    "& .MuiPaper-root": {
      boxShadow: "none",
      padding: 40,
    },
  },
  managerCard: {
    borderRadius: "10px",
    borderBottom: "6px solid #ff5722",
    margin: 16,
    paddingTop: 24,
    paddingBottom: 24,
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
  },
  empCard: {
    borderRadius: "10px",
    borderBottom: "6px solid #4caf50",
    margin: 16,
    paddingTop: 24,
    paddingBottom: 24,
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
  },
  imageEmp: {
    width: 80,
    height: 80,
    borderRadius: "50%",
    marginBottom: 16,
    
  },
  records_Section: {
    marginBottom: 80,
  },
  hrTag: {
    border: "1px solid #eeeeee",
    marginTop: 40,
    marginBottom: 40,
  },
  position: {
    color: "#757575",
  },
  data: {
    fontSize: 19,
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function DialogueRecordView() {
  const location = useLocation();
  const classes = useStyles();
  const [employees, setEmployees] = useState([]);
  const [values, setValues] = useState([]);

  const fetchData = () => {
    console.log("location dialogue", location.dialogue);
    setValues(location.dialogue);

    //Fetch Manager Info
    /*
    axios
      .get(`${process.env.REACT_APP_API}/employees`)
      .then((res) => {
        let employees = res.data.filter((x) =>
          x.fullName.includes(location.createBy)
        );

        employees.forEach(function (element) {
          //Add Property
          element.empPosition = employees[0].position;
          element.empImage = employees[0].image;

          let managerName = res.data.filter(
            (x) =>
              x.department === element.department && x.position === "Manager"
          );
          element.manage = managerName[0].fullName;
          element.manageImage = managerName[0].image;
          element.managePosition = managerName[0].position;
        });

        setEmployees(employees);
      })
      .catch((err) => console.log(err));

    */
    //fetch dialogue record by id
    /*
    axios
      .get(
        `${process.env.REACT_APP_API}/dialoguerecords/profile/${location.dialogueRecordId}`
      )
      .then((res) => {
        setValues(res.data[0]);
      })
      .catch((err) => console.log(err));
    */
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Dialogue Record View</h3>
              </Grid>
            </Grid>
          </div>
          <Paper>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                className={classes.managerCard}
              >
                <Grid container style={{ textAlign: "center" }}>
                  <Grid item xs={12} md={12} lg={12}>
                    {/* Manager ==  employees*/}
                    {Manager.managerImage == null ||
                    Manager.managerImage == "string" ? (
                      <AccountCircleIcon
                        sx={{ color: "#e0e0e0" }}
                        className={classes.imageEmp}
                      />
                    ) : (
                      <img
                        src={`/images/employees/${Manager.managerImage}`}
                        className={classes.imageEmp}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body" className={classes.name}>
                      {`${values.firstName} ${values.lastName}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body" className={classes.position}>
                      {values.positionName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                className={classes.empCard}
              >
                <Grid container style={{ textAlign: "center" }}>
                  <Grid item xs={12} md={12} lg={12}>
                    {Manager.empImage == null ||
                    Manager.empImage == "string" ? (
                      <AccountCircleIcon
                        sx={{ color: "#e0e0e0" }}
                        className={classes.imageEmp}
                      />
                    ) : (
                      <img
                        src={`/images/employees/${Manager.empImage}`}
                        className={classes.imageEmp}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      variant="body"
                      className={classes.name}
                      // gutterBottom
                    >
                      {values.reportToName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      variant="body"
                      className={classes.position}
                      gutterBottom
                    >
                      {`Manager`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* values == DataValue */}
            <hr className={classes.hrTag} />
            <div className={classes.records_Section}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={10}>
                  <Typography variant="h6" gutterBottom>
                    Good Result
                  </Typography>
                  <Typography className={classes.data}>
                    {values.good}
                  </Typography>
                </Grid>
              </Grid>
              <hr className={classes.hrTag} />
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={10}>
                  <Typography variant="h6" gutterBottom>
                    Gap For Improvement
                  </Typography>
                  <Typography className={classes.data}>
                    {values.gap}
                  </Typography>
                </Grid>
              </Grid>
              <hr className={classes.hrTag} />
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={10}>
                  <Typography variant="h6" gutterBottom>
                    Learning Point
                  </Typography>
                  <Typography className={classes.data}>
                    {values.learningPoint}
                  </Typography>
                </Grid>
              </Grid>
              <hr className={classes.hrTag} />
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={10}>
                  <Typography variant="h6" gutterBottom>
                    Next Commit
                  </Typography>
                  <Typography className={classes.data}>
                    {values.nextCommit}
                  </Typography>
                </Grid>
              </Grid>
              <hr className={classes.hrTag} />
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={10}>
                  <Typography variant="h6" gutterBottom>
                    Note
                  </Typography>
                  <Typography className={classes.data}>
                    {values.note}
                  </Typography>
                </Grid>
              </Grid>
              <hr className={classes.hrTag} />
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={10}>
                  <Typography variant="h6" gutterBottom>
                    What Next / Home Work
                  </Typography>
                  <Typography className={classes.data}>
                    {values.whatNextHW}
                  </Typography>
                </Grid>
              </Grid>
              <hr className={classes.hrTag} />
            </div>
          </Paper>
        </div>
      </div>
    </ContainerStyled>
  );
}

export default DialogueRecordView;
