import React, { useState, useEffect } from "react";
import {
  Grid,
  Avatar,
  Typography,
  Paper,
  Container,
  IconButton,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import TodayIcon from "@mui/icons-material/Today";
import EventIcon from "@mui/icons-material/Event";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { alpha } from "@mui/material";
import { Breadcrumb2 } from "../../shared/breadcrums";
import { getAllProject } from "../../../../actions/project";
import { getAllWork } from "../../../../actions/work";
import CardStyle from "../../shared/general/Card";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  nameText: {
    fontSize: 29,
    fontWeight: "bold",
    marginBottom: 20,
  },
  colorMD: {
    borderTop: "5px solid green",
  },
  colorMG: {
    borderTop: "5px solid red",
  },
  colorSM: {
    borderTop: "5px solid blue",
  },
  bigAvatar: {
    width: 150,
    height: 150,
    margin: 20,
  },
  icon: {
    fontSize: 30,
  },
  borderIcon: {
    height: 50,
    padding: 10,
    textAlign: "center",
    borderRadius: "10%",
  },
  topic: {
    marginBottom: 10,
    fontSize: 21,
    fontWeight: "bold",
  },
  dataInTopic: {
    fontSize: 19,
    marginLeft: 10,
    fontWeight: "bold",
  },
}));

const PaperSowData = ({
  name,
  value,
  icon,
  backgroundColor,
  bgIconColor1,
  bgIconColor2,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xl={3}
      lg={3}
      md={6}
      sm={6}
      xs={12}
      style={{ textAlign: "center" }}
    >
      <Paper style={{ marginLeft: 30, marginRight: 30 }}>
        <IconButton disabled size="large">
          {icon}
        </IconButton>
        <Typography style={{ fontSize: 23, fontWeight: "bold", marginTop: 10 }}>
          {value}
        </Typography>
        <Typography style={{ fontSize: 15 }}>{name}</Typography>
      </Paper>
    </Grid>
  );
};

const ShowDetailProject = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { result: dataProject } = useSelector((state) => state.dataProject);
  const { result: dataWork } = useSelector((state) => state.dataWork);

  useEffect(() => {
    dispatch(getAllProject());
    dispatch(getAllWork());
  }, []);

  let role = "";

  {
    dataWork &&
      dataWork
        .filter((val) => val.id == 1233)
        .map((val) =>
          val.DataProject.filter((val) => val.id == props.match.params.id).map(
            (val) => {
              {
                console.log(val);
              }
              if (val != null) {
                role = val.role;
              }
            }
          )
        );
  }

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
          Show project detail
        </Typography>

        {dataProject &&
          dataProject
            .filter((val) => val.id == props.match.params.id)
            .map((val, index) => (
              <div>
                <CardStyle
                  className={[
                    `${val.sizeMini == "MD" && classes.colorMD}`,
                    `${val.sizeMini == "MG" && classes.colorMG}`,
                    `${val.sizeMini == "SM" && classes.colorSM}`,
                  ].join(" ")}
                >
                  <div style={{ padding: 20 }}>
                    <Typography className={classes.nameText}>
                      {val.name}
                    </Typography>
                    <Grid container spacing={2}>
                      <PaperSowData
                        name="Type"
                        value={val.type}
                        icon={
                          <DynamicFeedIcon
                            className={classes.icon}
                            sx={{ color: "primary.darker" }}
                          />
                        }
                        backgroundColor="primary"
                        bgIconColor1="#b8a3ef"
                        bgIconColor2="#7b5dca"
                      />

                      {/* <Grid item xl={3} lg ={3} md={3}> 
                                        <Card raised style={{ marginLeft: 30, marginRight: 30,}}>
                                            <CardContent >
                                                <Grid container style={{alignItems: 'center'}}>
                                                    <Grid item xl={10} lg={10}>
                                                        <Typography style={{fontSize: 15 ,color: '#797979' }}>Type</Typography>
                                                        <Typography style={{fontSize: 25 , fontWeight:'bold'}}>{val.type}</Typography>
                                                    </Grid>
                                                    <Grid item xl={2} lg={2}>
                                                        <div style={{backgroundColor:'#f0eaff'}} className={classes.borderIcon}>
                                                            <DynamicFeedIcon className={classes.icon} style={{color: '#674ea7'}}/>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid> */}
                      <PaperSowData
                        name="Impact"
                        value={val.impact}
                        icon={
                          <StarIcon
                            className={classes.icon}
                            sx={{ color: "primary.darker" }}
                          />
                        }
                        backgroundColor="primary"
                        bgIconColor1="#4ac91d"
                      />
                      {/* <Grid item xl={3} lg ={3} md={3}> 
                                        <Card raised style={{ marginLeft: 30, marginRight: 30}}>
                                            <CardContent>
                                                <Grid container style={{alignItems: 'center'}}>
                                                    <Grid item xl={10} lg={10} >
                                                        <Typography style={{fontSize: 15 ,color: '#797979' }}>Impact</Typography>
                                                        <Typography style={{fontSize: 25 , fontWeight:'bold'}}>{val.impact}</Typography>
                                                    </Grid>
                                                    <Grid item xl={2} lg={2}>
                                                         <div style={{backgroundColor:'#f1ffec'}} className={classes.borderIcon}>
                                                            <StarIcon className={classes.icon} style={{color: '#4ac91d'}}/>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid> */}
                      <PaperSowData
                        name="Direct return"
                        value={val.directReturn}
                        icon={
                          <AssignmentReturnIcon
                            className={classes.icon}
                            sx={{ color: "error.darker" }}
                          />
                        }
                        backgroundColor="error"
                        bgIconColor1="#21b232"
                      />
                      {/* <Grid item xl={3} lg ={3} md={3}> 
                                        <Card raised style={{ marginLeft: 30, marginRight: 30}}>
                                            <CardContent>
                                                <Grid container style={{alignItems: 'center'}}>
                                                    <Grid item xl={10} lg={10}>
                                                        <Typography style={{fontSize: 15 ,color: '#797979' }}>Direct return</Typography>
                                                        <Typography style={{fontSize: 25 , fontWeight:'bold'}}>{val.directReturn}</Typography>
                                                    </Grid>
                                                    <Grid item xl={2} lg={2}>
                                                        <div style={{backgroundColor:'#def6ff'}} className={classes.borderIcon}>
                                                            <AssignmentReturnIcon className={classes.icon} style={{color: '#2b9cc5'}}/>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid> */}
                      <PaperSowData
                        name="Return ฿"
                        value={val.return}
                        icon={
                          <AttachMoneyIcon
                            className={classes.icon}
                            sx={{ color: "error.darker" }}
                          />
                        }
                        backgroundColor="error"
                        bgIconColor1="#d94343"
                      />
                      {/* <Grid item xl={3} lg ={3} md={3}> 
                                        <Card raised style={{ marginLeft: 30, marginRight: 30}}>
                                            <CardContent>
                                                <Grid container style={{alignItems: 'center'}}>
                                                    <Grid item xl={10} lg={10} >
                                                        <Typography style={{fontSize: 15 ,color: '#797979' }}>Return</Typography>
                                                        <Typography style={{fontSize: 25 , fontWeight:'bold'}}>{val.return} ฿</Typography>
                                                    </Grid>
                                                    <Grid item xl={2} lg={2}>
                                                        <div style={{backgroundColor:'#ffecec'}} className={classes.borderIcon}>
                                                            <MonetizationOnIcon className={classes.icon} style={{color: '#d94343'}}/>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid> */}
                      <Grid container style={{ marginTop: 20 }}>
                        <PaperSowData
                          name="Size"
                          value={val.size}
                          icon={
                            <AspectRatioIcon
                              className={classes.icon}
                              sx={{ color: "success.darker" }}
                            />
                          }
                          backgroundColor="success"
                          bgIconColor1="#cd34c8"
                        />
                        {/* <Grid item xl={3} lg={3} md={3}>
                                            <Card raised style={{ marginLeft: 30, marginRight: 30}}>
                                                <CardContent>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xl={10} lg={10} >
                                                            <Typography style={{fontSize: 15 ,color: '#797979' }}>Size</Typography>
                                                            <Typography style={{fontSize: 24 , fontWeight:'bold'}}>{val.size}</Typography>
                                                        </Grid>
                                                        <Grid item xl={2} lg={2}>
                                                            <div style={{backgroundColor:'#fff6db'}} className={classes.borderIcon}>
                                                                <AspectRatioIcon className={classes.icon} style={{color: '#e2aa05'}}/>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid> */}
                        <PaperSowData
                          name="Role"
                          value={role}
                          icon={
                            <PeopleAltIcon
                              className={classes.icon}
                              sx={{ color: "success.darker" }}
                            />
                          }
                          backgroundColor="success"
                          bgIconColor1="#ed9c4c"
                        />
                        <PaperSowData
                          name="Start date"
                          value={val.dateStart}
                          icon={
                            <TodayIcon
                              className={classes.icon}
                              sx={{ color: "warning.darker" }}
                            />
                          }
                          backgroundColor="warning"
                          bgIconColor1="#0867bc"
                        />
                        {/* <Grid item xl={3} lg={3} md={3}>
                                            <Card raised style={{ marginLeft: 30, marginRight: 30}}>
                                                <CardContent>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xl={10} lg={10} >
                                                            <Typography style={{fontSize: 15 ,color: '#797979' }}>Start date</Typography>
                                                            <Typography style={{fontSize: 24 , fontWeight:'bold'}}>{val.dateStart}</Typography>
                                                        </Grid>
                                                        <Grid item xl={2} lg={2}>
                                                            <div style={{backgroundColor:'#9ed2ff'}} className={classes.borderIcon}>
                                                                <TodayIcon className={classes.icon} style={{color: '#0a599f'}}/>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid> */}
                        <PaperSowData
                          name="End date"
                          value={val.dateEnd}
                          icon={
                            <EventIcon
                              className={classes.icon}
                              sx={{ color: "warning.darker" }}
                            />
                          }
                          backgroundColor="warning"
                          bgIconColor1="#2b9cc5"
                        />
                        {/* <Grid item xl={3} lg={3} md={3}>
                                            <Card raised style={{ marginLeft: 30, marginRight: 30}}>
                                                <CardContent>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xl={10} lg={10} >
                                                            <Typography style={{fontSize: 15 ,color: '#797979' }}>End date</Typography>
                                                            <Typography style={{fontSize: 24 , fontWeight:'bold'}}>{val.dateEnd}</Typography>
                                                        </Grid>
                                                        <Grid item xl={2} lg={2}>
                                                            <div style={{backgroundColor:'#ffeedd'}} className={classes.borderIcon}>
                                                                <EventIcon className={classes.icon} style={{color: '#aa5d13'}}/>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid> */}
                        {/* <Grid container style={{marginTop: 50}}> */}

                        {/* <Grid item xl={3} lg={3} md={3}>
                                            <Card raised style={{ marginLeft: 30, marginRight: 30}}>
                                                <CardContent>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xl={10} lg={10} >
                                                            <Typography style={{fontSize: 15 ,color: '#797979' }}>Role</Typography>
                                                            <Typography style={{fontSize: 24 , fontWeight:'bold'}}>{role}</Typography>
                                                        </Grid>
                                                        <Grid item xl={2} lg={2}>
                                                            <div style={{backgroundColor:'#ffdbbe'}} className={classes.borderIcon}>
                                                                <PeopleAltIcon className={classes.icon} style={{color: '#ff7200'}}/>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Typography
                      style={{ marginTop: 30 }}
                      className={classes.topic}
                    >
                      Reason :{" "}
                    </Typography>
                    <Typography className={classes.dataInTopic}>
                      &nbsp;{val.reason}
                    </Typography>
                    <Typography
                      style={{ marginTop: 20 }}
                      className={classes.topic}
                    >
                      Note :
                    </Typography>
                    <Typography className={classes.dataInTopic}>
                      {val.note}
                    </Typography>
                  </div>
                </CardStyle>

                <div
                  style={{
                    textAlign: "-webkit-right",
                    marginTop: 30,
                    marginBottom: 20,
                  }}
                >
                  <Paper elevation={1} style={{ padding: 20, width: "300px" }}>
                    <Typography
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#210c96",
                      }}
                    >
                      <VerifiedIcon />
                      Verify by
                    </Typography>
                    <center>
                      <Avatar
                        alt="who verify"
                        src={`${process.env.REACT_APP_API_URL}image/profile/${val.who}`}
                        className={classes.bigAvatar}
                      />
                    </center>
                    <Typography
                      style={{
                        fontSize: 20,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {val.hisName}
                    </Typography>
                    <Typography style={{ fontSize: 15, textAlign: "center" }}>
                      {val.varifyDate}
                    </Typography>
                  </Paper>
                </div>
              </div>
            ))}
      </Container>
    </div>
  );
};

export default ShowDetailProject;
