import {
  DOCUMENT_FETCHING,
  DOCUMENT_FAILED,
  DOCUMENT_SUCCESS,
  DOCUMENT_ITEM_FETCHING,
  DOCUMENT_ITEM_FAILED,
  DOCUMENT_ITEM_SUCCESS,
  DOCUMENT_GROUP_FAILED,
  DOCUMENT_GROUP_SUCCESS,
  DOCUMENT_GROUP_FETCHING,
} from "./types";

import DocumentService from "../services/document.service";

export const getAllDocuments = () => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_FETCHING,
    });
    const res = await DocumentService.getAllDocuments();
    if (res) {
      dispatch({
        type: DOCUMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_FAILED,
    });
    console.log(err);
  }
};

export const getDocument = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_ITEM_FETCHING,
    });
    const res = await DocumentService.getDocument(id);
    if (res) {
      dispatch({
        type: DOCUMENT_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const getDocumentGroupName = () => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_GROUP_FETCHING,
    });
    const res = await DocumentService.getDocumentGroupName();
    if (res) {
      dispatch({
        type: DOCUMENT_GROUP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_GROUP_FAILED,
    });
    console.log(err);
  }
};

export const getDocumentTypeName = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DOCUMENT_ITEM_FETCHING,
    });
    const res = await DocumentService.getDocumentTypeName(id);
    if (res) {
      dispatch({
        type: DOCUMENT_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const addDocument = (formData) => async () => {
  try {
    console.log(formData);
    const res = await DocumentService.addDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addDocumentType = (formData) => async () => {
  try {
    console.log(formData);
    const res = await DocumentService.addDocumentType(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const editDocument = (formData) => async () => {
  try {
    console.log(formData);
    const res = await DocumentService.editDocument(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const editDocumentType = (formData) => async () => {
  try {
    console.log(formData);
    const res = await DocumentService.editDocumentType(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};
