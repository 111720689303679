import React, { useEffect, useState } from "react";
import { Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getManpowerPlanning } from "../../../../actions/organizationStructure";
import Row from "./Row";
import DrawerEdit from "./DrawerEdit";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF!important"
})

const StyledTableContainer = styled(TableContainer)({
  position: "relative",
  overflowX: "auto",
  "& table": {
    borderCollapse: "separate",
  },
  "& .MuiTableBody-root": {
    "& .MuiTableCell-root": {
      padding: "8px 16px",
    }
  },
  "& .stickyColumn-left": {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  "& .stickyColumn-right": {
    position: 'sticky',
    right: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  "&.shadowEffect-left .stickyColumn-left": {
    "&:after": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: "-1px",
      width: "30px",
      transform: "translateX(100%)",
      transition: "box-shadow .3s",
      content: '""',
      pointerEvents: "none",
      boxShadow: 'inset 10px 0 8px -8px rgba(5,5,5,.06)',
    }
  },
  "&.shadowEffect-right .stickyColumn-right": {
    "&:after": {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: "-1px",
      width: "30px",
      transform: "translateX(-100%)",
      transition: "box-shadow .3s",
      content: '""',
      pointerEvents: "none",
      boxShadow: 'inset -10px 0 8px -8px rgba(5,5,5,.06)',
    }
  },
})

const Manpower = () => {

  const dispatch = useDispatch();

  const { result: manpowerPlanningList } = useSelector(state => state.manpowerPlanning)

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {}
  });

  const onDrawerOpen = (data) => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: {...data}
    }))
  }

  const onDrawerClose = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  useEffect(() => {
    dispatch(getManpowerPlanning())
  }, [])
  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" marginBottom="24px">แผนกำลังพล</Typography>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 200 }}>ชื่อ</TableCell>
                <TableCell style={{ minWidth: 200 }}>ระดับ</TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  <Typography textAlign="center">กำลังพลปัจจุบัน/แผนกำลังพล</Typography>
                </TableCell>
                <TableCell style={{ minWidth: 200 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {manpowerPlanningList.map((item, index) => (
                <Row key={`${item.level}-${item.idOrganization}`} data={item} onDrawerOpen={onDrawerOpen} onDrawerClose={onDrawerClose}/>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Container>

      <DrawerEdit
        drawerConfig={drawerConfig}
        onClose={onDrawerClose}
      />
    </StyledRoot>
  )
}

export default Manpower;