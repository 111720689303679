import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Card, MenuItem, styled, TextField, Typography } from "@mui/material";
import TextFieldTheme from "../general/TextFieldTheme";

const StyledRoot = styled(Box)({
  height: "100%",
  "& .MuiCard-root": {
    height: "inherit",
    boxSizing: "border-box",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    padding: "16px",
    borderRadius: "12px",
  },
  "& .card-data-title-container": {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    "& .title": {
      flexGrow: "1",
      fontSize: "20px",
      color: "#919eab",
    },
  },
  "& .value-container": {
    minHeight: "96px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .dataValue": {
    fontSize: "48px"
  },
  "& .dataUnit": {
    fontSize: "16px",
    color: "#919eab",
  },
  "& .dynamicFontSize-small": {
    fontSize: "30px",
  },
  "& .dynamicFontSize-medium": {
    fontSize: "33px",
  },
})

const CardData = (props) => {

  const {
    title,
    titleIcon,
    dataValue,
    dataUnit,
    dynamicFontSize,
    select
  } = props;

  let dataValueClassName = "dataValue";
  if(dynamicFontSize){
    if(dataValue.length > 12){
      dataValueClassName += " dynamicFontSize-small"
    } else if(dataValue.length > 9){
      dataValueClassName += " dynamicFontSize-medium"
    }
  }

  const [selectedIndex, setSelectedIndex] = useState(0);
  // const [selectedValue, setSelectedValue] = useState(0);

  const onChangeSelectedHandler = (e) => {
    setSelectedIndex(e.target.value)
  }

  return (
    <StyledRoot>
      <Card>
        <Box className="card-data-title-container">
          <Typography className="title">{title}</Typography>
          {titleIcon}
        </Box>
        {select? (
          <Box>
            <Box className="value-container">
              <Typography textAlign="center"><span className={dataValueClassName}>{dataValue[selectedIndex].value}</span> <span className="dataUnit">{dataUnit}</span></Typography>
            </Box>
            <TextField
              value={selectedIndex}
              onChange={onChangeSelectedHandler}
              fullWidth
              select
              size="small"
            >
              {dataValue.map((d, index) => (
                <MenuItem key={index} value={index}>{d.name}</MenuItem>
              ))}
            </TextField>
          </Box>
        ) : (
          <Box className="value-container">
            <Typography><span className={dataValueClassName}>{dataValue}</span> <span className="dataUnit">{dataUnit}</span></Typography>
          </Box>
        )}
      </Card>
    </StyledRoot>
  )
}

CardData.propTypes = {
  title: PropTypes.string.isRequired,
  dataValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  dataUnit: PropTypes.string,
  dynamicFontSize: PropTypes.bool,
  select: PropTypes.bool,
}

export default CardData;