import {
    USER_PROVIDENTFUNDS_FETCHING,
    USER_PROVIDENTFUNDS_FAILED,
    USER_PROVIDENTFUNDS_SUCCESS,
    EMPLOYEE_PROVIDENTFUNDS_FETCHING,
    EMPLOYEE_PROVIDENTFUNDS_FAILED,
    EMPLOYEE_PROVIDENTFUNDS_SUCCESS
} from './types';

import providentFundService from '../services/providentFund.service';

export const getProvidentFundUser = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROVIDENTFUNDS_FETCHING
        });
        const result = await providentFundService.getProvidentFundUser();
        if (result) {
            dispatch({
                type: USER_PROVIDENTFUNDS_SUCCESS,
                payload: result.data
            });
        }
    } catch (error) {
        dispatch({
            type: USER_PROVIDENTFUNDS_FAILED
        });
    }
};

export const updateProvidentFundUser = (userPF, companyPF) => async () => {
    try {
      const res = await providentFundService.updateProvidentFundUser(userPF, companyPF);
      if(res){
        return res;
      }
    } catch (error) {
      return error;
    }
};

export const getProvidentFundEmployeeByIdEmployee = (idEmployee) => async (dispatch) => {
    try {
        dispatch({
            type: EMPLOYEE_PROVIDENTFUNDS_FETCHING
        });
        const result = await providentFundService.getProvidentFundEmployeeByIdEmployee(idEmployee);
        if (result) {
            dispatch({
                type: EMPLOYEE_PROVIDENTFUNDS_SUCCESS,
                payload: result.data
            });
        }
    } catch (error) {
        dispatch({
            type: EMPLOYEE_PROVIDENTFUNDS_FAILED
        });
    }
};