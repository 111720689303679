import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";
import { Card, Typography, Grid, Avatar } from "@mui/material";

import {
  standardMenu,
} from "../../assets/data/menu-app";

import Premium from "../../assets/premium.png";

import { getStandardMenuCompany } from "../../../../actions/company";

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-root": {
    fontWeight: 500,
    "& span": {
      letterSpacing: 0.5,
    },
  },
});

const StyledCard = styled(Card)({
  position: "relative",
  boxShadow: "none",
  borderRadius: 16,
  border: "1px solid #919eab3d",
  margin: 8,
  border: "none",
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  "&.gray": {
    filter: "grayscale(100%)",
  },
  "&:hover": {
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
    transform: "translateY(-5px)",
  },
  "& .inner": {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    "& div": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
    "& .MuiTypography-subtitle": {
      fontSize: 22,
      lineHeight: 1.2,
      textAlign: "center",
    },
    "& .MuiTypography-caption": {
      lineHeight: 1.2,
      fontSize: 14,
    },
    "& .MuiAvatar-root": {
      width: 100,
      height: 100,
      marginBottom: 16,
    },
  },
  "& .premium": {
    position: "absolute",
    right: 20,
  },
});

const StyledWrapCardAdmin = styled("div")({
  display: "grid",
    gridTemplateRows: "1fr auto auto",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: 0,
    // minWidth: 350,
    minWidth: "100%",
    columnGap: 8,
    ["@media only screen and (max-width: 600px)"]: {
      gridTemplateRows:
        "1fr auto auto auto auto auto auto auto auto auto auto auto auto",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    ["@media only screen and (min-width:600px)"]: {
      gridTemplateRows: "1fr auto auto auto auto auto",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    ["@media only screen and (min-width:768px)"]: {
      gridTemplateRows: "1fr auto auto auto auto auto auto",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    ["@media only screen and (min-width:992px)"]: {
      gridTemplateRows: "1fr auto auto auto auto",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    ["@media only screen and (min-width:1200px)"]: {
      gridTemplateRows: "1fr auto auto auto auto",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
});

const Menu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: standardMenuCompany } = useSelector((state) => state.standardMenu);

  useEffect(() => {
    dispatch(getStandardMenuCompany());
  }, []);

  return (
    <div style={{ maxWidth: "1200px" }}>
      <Header>
        <Typography color="text.secondary">เมนูผู้ดูแลระบบ</Typography>
      </Header>
      <StyledWrapCardAdmin>
        {userProfile && standardMenuCompany && 
          standardMenu
            .filter((x) => standardMenuCompany[2].indexOf(x.key) > -1)
            .map((value, index) => (
              <StyledCard
                key={index}
                onClick={() => history.push(value.link)}
                className={value.class}
              >
                <div className="inner" style={{ userSelect: "none" }}>
                  <Avatar variant="rounded" src={value.image} style={{ pointerEvents: "none" }}/>
                  <div>
                    <Typography variant="subtitle" gutterBottom whiteSpace={"pre-line"}>
                      {value.name}
                    </Typography>
                  </div>
                  {value.premium && (
                    <img className="premium" src={Premium} width="40" />
                  )}
                </div>
              </StyledCard>
            ))}
      </StyledWrapCardAdmin>
    </div>
  );
};

export default Menu;
