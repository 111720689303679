import { httpClient } from "./httpClient";

const getEmploymentTypeByidCompany = () => {
  return httpClient.get(`/employment-type`);
};

const addEmploymentType = (data) => {
  return httpClient.post(`/employment-type`, data);
};

const updateEmploymentType = (data) => {
  return httpClient.put(`/employment-type`, data);
};

const deleteEmploymentType = (idEmploymentType) => {
  return httpClient.delete(`/employment-type/${idEmploymentType}`);
};

export default {
  getEmploymentTypeByidCompany,
  addEmploymentType,
  updateEmploymentType,
  deleteEmploymentType,
};
