import React, { Fragment } from "react";
import {
  Box,
  Card,
  Chip,
  Divider,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery
} from "@mui/material";
import PlaylistAddCircleRoundedIcon from "@mui/icons-material/PlaylistAddCircleRounded";
import Row from "./row";
import CardDate from "./cardDate";
import dayjs from "dayjs";
import DrawerRequestTime from "./drawerRequestTime";

const StyledRoot = styled(Box)({});

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    minWidth: 120,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .cellAction": {
    width: 48,
    padding: "0px 0px 0px 4px",
  },
  "& .tableCellHead-date-time": {
    width: 128,
    ["@media only screen and (max-width: 600px)"]: {
      width: 250,
    },
  },
  "& .tableCellHead-shift": {
    minWidth: 130,
    ["@media only screen and (max-width: 600px)"]: {
      width: 255,
    },
  },
  "& .tableCellHead-shift-time": {
    minWidth: 100,
    ["@media only screen and (max-width: 600px)"]: {
      width: 255,
    },
  },
  "& .tableCellHead:first-of-type": {
    minWidth: 50,
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "rgb(255 255 255) 0px 0px -8px inset",
  },
  "& .tableCellHead:last-of-type": {
    minWidth: 180,
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    padding: 8,
    height: 42,
    "&.cellStartStop": {
      "&:hover": {
        cursor: "pointer",
      },
      "& .time": {
        fontWeight: "600",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiSvgIcon-root": {
          marginRight: 4,
        },
      },
      "& .location": {
        fontWeight: 600,
      },
    },
  },
  "& .tableCellWeekend": {
    color: "#959595",
    fontStyle: "oblique",
  },
  "& .cellStatusAction": {
    textAlign: "left",
    "& .wrap-status": {
      display: "flex",
      "& .item": {
        marginRight: 4,
        display: "flex",
        alignItems: "center",
      },
    },
  },
  "& .cellStartStop": {
    minWidth: 120,
    "& .MuiButtonBase-root": {
      marginRight: 2,
    },
  },
});

const StyledTableCellContent = styled(TableCell)({
  textAlign: "center",
  borderBottom: "none",
  padding: "8px 16px",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  "&.status": {
    minWidth: 50,
    "& .MuiBox-root": {
      margin: "auto",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.15)",
      },
    },
  },
  "& .fullname": {
    width: 250,
    "& .MuiAvatar-root": {
      marginRight: 16,
    },
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "& .name": {
      textAlign: "left",
      fontWeight: 600,
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 300,
    },
  },
  "& .actual-plan": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 200,
    margin: "auto",
    padding: "0 20px",
  },
  "& .actual": {
    fontWeight: 600,
    fontSize: 24,
  },
  "& .plan": {
    fontWeight: 600,
    color: "#919eab",
  },
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledTableRow = styled(TableRow)({
  "& .MuiTableRow-root:last-child.MuiTableCell-root": {
    border: "none !important",
  },
});

const StyledCard = styled(Card)({
  marginBottom: 32,
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledWrapLabel = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
  [theme.breakpoints.down("sm")]:{
    flexDirection:"column",
    justifyContent:"flex-start",
    marginBottom: 0
  },
  "& .MuiChip-root": {
    backgroundColor: "#f4f6f8",
    marginRight: 8,
    ["@media only screen and (max-width: 600px)"]: {
      marginRight: 0,
      marginBottom: 16
    },
    "& .MuiChip-label": {
      fontWeight: 600,
    },
  },
}));

const StyledContainerCard =  styled("div")({
});

const StyledDivider = styled(Divider)({
  borderBottom:"3px solid",
  borderColor:"#EAEDF2",
  marginBottom: 16
});

const TableTimeline = (props) => {
  const {
    attendanceList,
    searchDate,
    showDate,
    handleClickOpenAddNewTimeline,
    handleClickEdit,
    mode,
  } = props;
  const mobileResponsive = useMediaQuery('(max-width:600px)');

  return (
    <div style={{ paddingBottom: 16 }}>
      <div style={{ marginTop: 16 }}>
        <StyledCard>
          <StyledWrapLabel>
            <div>
              <Chip
                icon={
                  <PlaylistAddCircleRoundedIcon style={{ color: "#eebb00" }} />
                }
                label="คำขอรับรองเวลาทำงาน"
              />
            </div>
            <div>
              <Chip
                icon={
                  <PlaylistAddCircleRoundedIcon style={{ color: "#1e4db6" }} />
                }
                label="คำขอทำงานล่วงเวลา"
              />
            </div>
            <div>
              <Chip
                icon={
                  <PlaylistAddCircleRoundedIcon style={{ color: "#ff6d00" }} />
                }
                label="คำขอลางาน"
              />
            </div>
            <div>
              <Chip
                icon={
                  <PlaylistAddCircleRoundedIcon
                    style={{ color: "#00C169" }}
                  />
                }
                label="คำขอทำชั่วโมง Compensate"
              />
            </div>
          </StyledWrapLabel>
          {!mobileResponsive ? (
            <TableContainer>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell className={`tableCellHead`} />
                    <TableCell
                      className={`tableCellHead tableCellHead-date-time`}
                    >
                      วันที่
                    </TableCell>
                    <TableCell className={`tableCellHead tableCellHead-shift`}>
                      ตารางกะ
                    </TableCell>
                    <TableCell className={`tableCellHead tableCellHead-shift`}>
                      เวลาทำงาน
                    </TableCell>
                    <TableCell className={`tableCellHead cellStartStop`}>
                      เวลาเข้า
                    </TableCell>
                    <TableCell className={`tableCellHead cellStartStop`}>
                      เวลาออก
                    </TableCell>
                    <TableCell className={`tableCellHead cellStatusAction`}>
                      สถานะ
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {attendanceList &&
                    attendanceList
                      .filter(
                        (a) =>
                          dayjs(a.date) >=
                          dayjs(
                            `${dayjs(showDate.start).format(
                              "YYYY-MM-DD"
                            )} 00:00:00`
                          )
                      )
                      .map((row, index) => (
                        <Row
                          key={index}
                          row={row}
                          handleClickOpenAddNewTimeline={
                            handleClickOpenAddNewTimeline
                          }
                          handleClickEdit={handleClickEdit}
                          mode={mode}
                        />
                      ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          )
          :
          (
            <StyledContainerCard>
              {attendanceList &&
                attendanceList
                  .filter(
                    (a) =>
                      dayjs(a.date) >=
                      dayjs(
                        `${dayjs(showDate.start).format(
                          "YYYY-MM-DD"
                        )} 00:00:00`
                      )
                  )
                  .map((row, index) => (
                    <Fragment key={index}>
                      {(
                        parseInt(dayjs(row.date).format("d")) === 1 && 
                        (index !== 0)
                      ) && (
                        <StyledDivider />
                      )}
                      <CardDate 
                        keys={index}
                        row={row}
                        handleClickOpenAddNewTimeline={
                          handleClickOpenAddNewTimeline
                        }
                        handleClickEdit={handleClickEdit}
                        mode={mode}
                      />
                    </Fragment>
                  ))
              }
            </StyledContainerCard>
          )
          }
        </StyledCard>
      </div>
    </div>
  );
};

export default TableTimeline;
