import React from "react";
import { useSelector } from "react-redux";

import UserOverview from "../user/overview";
import AdminOverview from "../admin/overview";
import ManagerOverview from "../manager/overview";

const OverviewPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminOverview />
        ) : userProfile.roles.includes("ROLE_MANAGER") ? (
          <ManagerOverview />
        ) : (
          <UserOverview />
        )
      ) : null}
    </div>
  );
};

export default OverviewPage;
