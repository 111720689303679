import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { Box, IconButton, styled, Typography } from "@mui/material";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import DialogDelete from "./DialogDelete";
import ModalCrop from "../../shared/general/ModalCrop";

import { 
    addCompanyLogo,
    deleteCompanyLogo,
    getCompanyProfile 
} from "../../../../actions/company";

const StyledRoot = styled(Box)({
    "& .dropzone": {
        cursor: "pointer",
        flexShrink: 0,
        width: "100%",
        height: 144,
        boxSizing: "border-box",
        backgroundColor: "#F9F9FB",
        border: "1px solid #D0D0D0",
        borderStyle: "dashed",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .inner-dropzone": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 0,
            "& img": {
                width: 72,
                height: 72,
            }
        }
    },
    "& .image-wrap": {
        textDecoration: "none",
        color: "inherit",
        position: "relative",
        cursor: "pointer",
        flexShrink: 0,
        overflow: "hidden",
        width: "100%",
        height: 144,
        boxSizing: "border-box",
        border: "1px solid #D0D0D0",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "contain"
        },
        "& .icon-part": {
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& i": {
                fontSize: 56,
                color: "#D0D0D0",
                margin: 0,
            }
        },
        "& .delete": {
            position: "absolute",
            top: 0,
            right: 0,
            padding: 8,
            "& i": {
                fontSize: 16,
                margin: 0,
            }
        }
    }
})

const Logo = (props) => {
    const { data } = props;

    const [companyImageURL, setCompanyImageURL] = useState(null);
    const [nameOfImage, setNameOfImage] = useState(null);

    const [modalAvatarCropState, setModalAvatarCropState] = useState({
        isOpen: false,
        image: null,
    })

    useEffect(() => {
        if(data){
            setCompanyImageURL(data);
        }else{
            setCompanyImageURL(null);
        }
    },[data]);

    const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false)

    const dispatch = useDispatch();

    const onComfirmCropAvatar = async (file) => {

        const formData = new FormData();
    
        formData.append("logo", file, nameOfImage);
    
        const response = await dispatch(addCompanyLogo(formData));
    
        if(response.status === 200){
          setModalAvatarCropState({open: false, image: null});
          dispatch(getCompanyProfile());
        }
    }
    
    const onConfirmDelete = async () => {
        const response = await dispatch(deleteCompanyLogo());
        if(response.status === 200){
            setCompanyImageURL(null);
            setIsOpenConfirmDeleteDialog(false);
            dispatch(getCompanyProfile());
        }
    };

    return (
        <StyledRoot>
            {!companyImageURL && <Dropzone
                onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                        setNameOfImage(acceptedFiles[0].name);
                        setModalAvatarCropState({
                            isOpen: true,
                            image: URL.createObjectURL(acceptedFiles[0]),
                        })
                    }
                }}
                accept="image/jpeg, image/png"
                maxSize={10485760}
            >
                {({
                    getRootProps,
                    getInputProps,
                }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                        <Box>
                            <input {...getInputProps()} />
                            <Box className="inner-dropzone">
                                <AddAPhotoIcon />
                                <Typography>Upload</Typography>
                            </Box>
                        </Box>
                    </div>
                )
                }
            </Dropzone>}

            {companyImageURL && <Box className="image-wrap" component={"a"} href={companyImageURL} target="_blank">
                <img src={companyImageURL} alt="witnessSignature" />
                <IconButton className="delete" onClick={(e) => { e.preventDefault(); setIsOpenConfirmDeleteDialog(true) }}>
                    <i className="fa-solid fa-trash-can"></i>
                </IconButton>
            </Box>}
            
            <Typography className="captionText">
                Allowed *.jpeg, *.jpg, *.png and max size of 3 MB
            </Typography>

            {modalAvatarCropState.isOpen && (
                <ModalCrop
                    title="อัพโหลดโลโก้ของบริษัท"
                    open={modalAvatarCropState.isOpen}
                    onClose={() => { setModalAvatarCropState({ isOpen: false, image: null }) }}
                    image={modalAvatarCropState.image}
                    onConfirm={onComfirmCropAvatar}
                />
            )}

            {isOpenConfirmDeleteDialog && (
                <DialogDelete 
                    open={isOpenConfirmDeleteDialog} 
                    onClose={() => { setIsOpenConfirmDeleteDialog(false) }} 
                    onConfirm={onConfirmDelete} 
                />
            )}

        </StyledRoot>
    )
};

export default Logo;