import React from "react";
import dayjs from 'dayjs';
import { Divider, Grid, Typography } from "@mui/material";

const EmpInfo = ({ employee }) => {

  return (
    <div>
      <Typography variant="body1" gutterBottom>
      Information
      </Typography>
      <Divider />
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            Employment Type
          </Typography>
          <Typography variant="subtitle1">{employee.employmentType}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            Employee ID
          </Typography>
          <Typography variant="subtitle1">{employee.employeeID}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            Company
          </Typography>
          <Typography variant="subtitle1">{employee.companyAgency}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            Section
          </Typography>
          <Typography variant="subtitle1">{employee.section}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            Department
          </Typography>
          <Typography variant="subtitle1">{employee.department}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            Division
          </Typography>
          <Typography variant="subtitle1">{employee.division}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            Job Group
          </Typography>
          <Typography variant="subtitle1">{employee.jobGroup}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            Start Date
          </Typography>
          <Typography variant="subtitle1">
            {dayjs(employee.hireDate).format("DD/MM/YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" gutterBottom>
            Reporting Manager
          </Typography>
          <Typography variant="subtitle1">
            {employee.reportingManager}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default EmpInfo;
