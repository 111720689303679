import React, { Fragment, useState } from 'react';
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from 'react-redux';

import {
    Box, 
    styled,
    Typography
} from '@mui/material';
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { addProfileImage } from '../../../../../../actions/employee';

const StyledImageProfile = styled("div")({
    width: "100%",
    "& .dropzone-profile": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      // color: "#bdbdbd",
      color: "transparent",
      outline: "none",
      transition: "border 0.24s ease-in-out",
      height: 160,
      width: 160,
      margin: "auto",
      borderRadius: "50%",
      padding: 8,
      border: "1px dashed rgba(145, 158, 171, 0.32)",
    },
    "& .inner-dropzone-profile": {
      cursor: "pointer",
      zIndex: 0,
      width: "100%",
      height: "100%",
      outline: "none",
      display: "flex",
      overflow: "hidden",
      backgroundColor: "#FFFFFF",
      borderRadius: "50%",
      position: "relative",
    //   "& .placeholder": {
    //     //display: "none",
    //   },
      "&:hover .placeholder": {
        zIndex: 9,
        // display: "none",
        //display: "flex",
      },
    },
    "& .uploadImage": {
      width: "105%",
      zIndex: 8,
    },
    "& .placeholder": {
      width: "100%",
      height: "100%",
      display: "flex",
      position: "absolute",
      alignItems: "flex-end",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      color: "rgb(99, 115, 129)",
    //   backgroundColor: "rgb(244, 246, 248)",
      transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .placeholderImageProfile": {
    //   display: "none",
      color: "rgb(255, 255, 255)",
      backgroundColor: "rgba(22, 28, 36, .4)",
    },
    "& .placeholderContent":{
        width:"100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "rgba(22, 28, 36, .4)",
        padding:"8px 0"
    },
    "& .placeholderLabel": {
      color: "rgb(255, 255, 255)",
    },
    "& .wrapCaptionImage": {
      marginTop: 16,
      textAlign: "center",
    },
    "& .captionImage": {
      color: "#212b36",
    },
});

const AvatarEmployee = (props) => {
    const dispatch = useDispatch();
    const { fileSelected, setFileSelected } = props;
    const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png",
        noClick: false,
        noKeyboard: true,
        onDrop: async (acceptedFiles) => {
          let formDataDrop = new FormData();
          acceptedFiles.map((file) => formDataDrop.append("file", file));
          let Imagefile = acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
          setFileSelected(Imagefile);

          const formData = new FormData();
            
          formData.append("idEmployees", employeeProfile.idEmployees);
          formData.append("image", Imagefile[0]);

          await dispatch(addProfileImage(formData));
        },
        maxFiles: 1,
        multiple: false
    });  

    const thumbs = (row) => {
        if (fileSelected.length === 0) {
          return (
            <img
              src={employeeProfile? `${employeeProfile.imageProfile}`: null}
              className={`uploadImage`}
              alt={employeeProfile? `${employeeProfile.firstname_TH}`: null}
            />
          );
        } else { 
          return (
            <img
              alt={fileSelected[0].firstname_TH}
              src={fileSelected[0].preview}
              className={`uploadImage`}
            />
          );
        }
    };

    return (
        <StyledImageProfile>
          <div {...getRootProps({ className: "dropzone-profile" })}>
            <div className="inner-dropzone-profile">
              <input 
                {...getInputProps()} 
                aria-label="avatarEmployee"
              />
              <Fragment>{thumbs(employeeProfile)}</Fragment>
              <div className={`placeholder placeholderImageProfile`}>
                <div
                    className={`placeholderContent`}
                >
                    <AddAPhotoIcon style={{ fontSize:"20px" }}/>
                    <Typography
                        style={{ 
                            marginTop: 8, 
                            backgroundColor: "transparent", 
                            color: "inherit", 
                            fontSize:"0.75rem" 
                        }}
                        className={`${fileSelected != 0 && `placeholderLabel`}`}
                        variant="body2"
                    >
                    Upload Photo
                    </Typography>
                </div>
              </div>
            </div>
          </div>
        </StyledImageProfile>
    );
};

export default AvatarEmployee;