import React from "react";
import { Controller } from 'react-hook-form';
import { makeStyles } from "@mui/styles";
import {
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import TextFieldNumber from "./TextFieldNumber";
import TextFieldTheme from '../../../../shared/general/TextFieldTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body1": {
      color: "#16243D",
    },
    "& .MuiTypography-body2": {
      fontSize: "1rem",
      marginLeft: theme.spacing(4),
    },
    "& .MuiGrid-root": {
      marginBottom: theme.spacing(1),
    },
  },
  inputAdornment: {
    "& .MuiTypography-body1": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
}));

const maritalStatus = [
  {
    id: 0,
    label: "โสด",
    value: "โสด",
  },
  {
    id: 1,
    label: "แต่งงาน",
    value: "แต่งงาน",
  },
  {
    id: 2,
    label: "หม้าย",
    value: "หม้าย",
  },
  {
    id: 3,
    label: "หย่าร้าง",
    value: "หย่าร้าง",
  },
];

const mateIncome = [
  { id: 1, label: "มี", value: 1 },
  { id: 2, label: "ไม่มี", value: 0 },
];

function Form1(props) {
  const { control, watch, setValue, isDisabled } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Typography variant="body1">1) สถานภาพสมรส</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Controller
            name="maritalStatus"
            control={control}
            render={({ field }) => (
              <TextFieldTheme 
                {...field} 
                onChange={(e) => {
                  field.onChange(e);
                  if(e.target.value !== "แต่งงาน"){
                    console.log("TRUE");
                    setValue("isMateIncome", 0, { shouldDirty: true });
                  }
                }}
                select 
                disabled={isDisabled}
              >
                {maritalStatus.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>
      </Grid>
      {watch("maritalStatus") === "แต่งงาน" &&
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body2">คู่สมรสมีเงินได้หรือไม่</Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Controller 
              name="isMateIncome"
              control={control}
              render={({ field }) => (
                <TextFieldTheme {...field} select disabled={isDisabled}>
                  {mateIncome.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextFieldTheme>
              )}
            />
          </Grid>
        </Grid>
      }
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Typography variant="body1">2) ลดหย่อนบุตร</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body2">
            จำนวนบุตรที่มีสิทธิลดหย่อนทั้งหมด
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="childDeduction"
            min={0}
            max={99}
            endAdornment={true}
            endAdornmentLabel="คน"
            control={control}
            isInteger={true}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body2">
            จำนวนบุตรตั้งแต่คนที่ 2 ที่เกิดปี พ.ศ. 2561 เป็นต้นไป
            มีสิทธิลดหย่อนทั้งหมด
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="childYearDeduction"
            min={0}
            max={99}
            endAdornment={true}
            endAdornmentLabel="คน"
            control={control}
            isInteger={true}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">
            3) เบี้ยประกันชีวิตที่จ่ายในปีภาษี (กรมธรรม์ฯ 10 ปี ขึ้นไป)
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body2">พนักงาน</Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 24, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้สูงสุดไม่เกิน 100,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="empInsure"
            min={0}
            max={
              100000 - (watch("mateInsure") ? watch("mateInsure") : 0) - (watch("healthInsurance") ? watch("healthInsurance") : 0)
            }
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body2">คู่สมรส</Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 24, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้สูงสุดไม่เกิน 10,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
        {
          <TextFieldNumber 
              name="mateInsure"
              min={0}
              max={(() => {
                let maxLimit = 100000 - (watch("empInsure") ? watch("empInsure") : 0) - (watch("healthInsurance") ? watch("healthInsurance") : 0);
                if(maxLimit > 10000){
                  return 10000
                }else{
                  return maxLimit;
                }
              })()}
              endAdornment={true}
              endAdornmentLabel="บาท/ปี"
              control={control}
              disabled={isDisabled}
            />
          }
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">4) เบี้ยประกันสุขภาพ</Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้สูงสุดไม่เกิน 25,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="healthInsurance"
            min={0}
            max={(() => {
              let maxLimit = 100000 - (watch("empInsure") ? watch("empInsure") : 0) - (watch("mateInsure") ? watch("mateInsure") : 0);
              if(maxLimit > 25000){
                return 25000;
              }else{
                return maxLimit;
              }
            })()}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Typography 
        variant="body2"
        style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
      >* เบี้ยประกันชีวิตรวมเบี้ยประกันสุขภาพไม่เกิน 100,000 บาท</Typography>
    </div>
  );
}

export default Form1;
