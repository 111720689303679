import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, styled, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { employeeChangeTimeApprove } from "../../../../actions/employee";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogConfirmReject from "./DialogConfirmReject";
import CardRequest from "./CardRequest";

import { getEmployeeChangeTime } from "../../../../actions/employee";

const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor:"#E793B8",
  borderRadius:"8px",
  color:"#FFFFFF",
  marginRight: 8,
  width: 130,
  "&:hover":{
    backgroundColor:"#DC6384"
  }
});

const RequestTimeShift = (props) => {
  const { setSnackBarConfig, setNumberOfList } = props;
  const mobileResponsive = useMediaQuery('(max-width:600px)');
  const { result: employeeChangeShiftList } = useSelector((state) => state.employeeChangeShift);

  const [pendingRows, setPendingRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const dispatch = useDispatch();

  const shiftTimeHeader = [
    {
      name: "ชื่อ-สกุล",
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            style={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {row.firstname_TH} {row.lastname_TH}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {row.positionName}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: "วันที่",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box style={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.workingDateText, "DD/MM/YYYY").format("D MMM BBBB")}
          </Typography>
        </Box>
      ),
    },
    {
      name: "เวลาทำงาน",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box style={{ textAlign: "center" }}>
          {row.isWorkingDay === 1 ? (
            <Typography>{row.shiftGroupName}</Typography>
          ) : (
            <Fragment>
              {row.idHoliday > 0 ? (
                <Fragment>
                  <Typography>วันหยุดนักขัตฤกษ์</Typography>
                  <Typography
                    style={{
                      marginTop: 4,
                      marginBottom: 4,
                      fontSize: 14,
                      fontStyle: "oblique",
                    }}
                    color="text.secondary"
                  >
                    {row.holidayName}
                  </Typography>
                </Fragment>
              ) : (
                <Typography>วันหยุด</Typography>
              )}
            </Fragment>
          )}
        </Box>
      ),
    },
  ];

  const handleApprovalRequest = async (approve, comment) => {
    let formData = [...selectedRows];

    formData.forEach((item) => {
      item.isApprove = approve;
      item.approveComment = comment;
    });

    setIsOpenRejectDialog(false);

    const result = await dispatch(employeeChangeTimeApprove(formData));
    if (result) {
      setSelectedRows([]);
      dispatch(getEmployeeChangeTime("manager"));
      if (result.status === 200) {
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการสำเร็จ",
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการไม่สำเร็จ",
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: "ดำเนินการไม่สำเร็จ",
        type: "error",
      });
    }
  };

  const handleConfirmReject = (comment) => {
    handleApprovalRequest(0, comment);
  };

  const handleClickAll = () => {
    setSelectedRows(pendingRows);
  };

  const handleCancel = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    const tempPending = [];

    employeeChangeShiftList.map((r) => {
      if (r.isApprove === null) {
        tempPending.push(r);
      }
    });
    let pandingList = tempPending.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });
    setPendingRows(pandingList);
    setNumberOfList(pandingList.length);
  }, []);

  return (
    <Box>
      {mobileResponsive && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          {((selectedRows ? selectedRows.length : 0) > 0) && 
            <StyledButtonCancel
              startIcon={<DeleteIcon />}
              onClick={handleCancel}
            >
              {`ยกเลิก`}
            </StyledButtonCancel>
          }
          {!((selectedRows ? selectedRows.length : 0) === pendingRows.length) &&
            <ButtonBlue
              startIcon={<DoneAll />}
              variant="contained"
              onClick={handleClickAll}
            >
              {`เลือกทั้งหมด`}
            </ButtonBlue>
          }
        </Box>
      )}
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1, null)}
          >
            {`อนุมัติ ${
              selectedRows.length > 0 ? `${selectedRows.length} รายการ` : ""
            }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => setIsOpenRejectDialog(true)}
          >
            {`ไม่อนุมัติ ${
              selectedRows.length > 0 ? `${selectedRows.length} รายการ` : ""
            }`}
          </ButtonBlue>
        </Box>
      )}

      {!mobileResponsive ?
        <TableCustom
          columns={shiftTimeHeader}
          rows={employeeChangeShiftList ? pendingRows : []}
          canSelect
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
        :
        (
          <div>
            {employeeChangeShiftList ?
              pendingRows.map(row => (
                <CardRequest
                  key={row.idEmployeeShiftDaily}
                  row={row}
                  mode="Shift"
                  selectedRows={selectedRows} 
                  setSelectedRows={setSelectedRows}
                />
              ))
              :
              <div>
                <Typography align="center">ไม่พบข้อมูล</Typography>
              </div>
            }
          </div>
        )
      }
      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleConfirmReject(comment);
        }}
      />
    </Box>
  );
};

export default RequestTimeShift;
