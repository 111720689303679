import React from "react";
import dayjs from "dayjs";

import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Utils from "../../../../../utils";

const StyledTableContainer = styled(TableContainer)({
  width: "100%",
  minWidth: 720,
});

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderColor: "#919eab3d",
    padding: "8px 16px",
  },
  "& .cellStatusAction": {
    width: 30,
  },
  "& .cellFullName": {
    width: 150,
  },
  "& .cellTypeLeave": {
    width: 100,
  },
  "& .cellStartStop": {
    width: 150,
  },
});

export default function TableList(props) {
  const { payRunList } = props;

  return (
    <StyledTableContainer
      className="tableContainer"
      style={{ width: "100%", minHeight: 160 }}
    >
      <StyledTable aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell className={`tableCellHead`} align="center">
              ชื่อรายการ
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              ประเภทการจ่าย
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              รอบเดือน
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              จำนวนพนักงาน
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              ยอดสุทธิ
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              วันที่จ่าย
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payRunList.length > 0 ? (
            payRunList.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center" className={`tableCell`}>
                  <Link
                    component={NavLink}
                    to={`/vendor/payroll/run/${row.idPayrun}`}
                    underline="hover"
                  >
                    {row.payrunName}
                  </Link>
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  <Typography>
                    {row.paymentRound === "month"
                      ? `รายเดือน-รายครึ่งเดือน`
                      : `รายเดือน`}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  <Typography>
                    {dayjs(row.payrunPeriod)
                      .locale(localStorage.getItem("language") || "th")
                      .format(
                        localStorage.getItem("language") === "en"
                          ? "MMMM YYYY"
                          : "MMMM BBBB"
                      )}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  <Typography>{row.numberEmployee}</Typography>
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  <Typography>
                    {Utils.numberWithCommas(row.netRound)}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  <Typography>
                    {row.paidDate
                      ? dayjs(row.paidDate)
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "en"
                              ? "D MMMM YYYY"
                              : "D MMMM BBBB"
                          )
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Typography color="text.secondary" style={{ margin: "8px 0" }}>
              ไม่มีข้อมูล
            </Typography>
          )}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
}
