import {
  CAREERPLAN_FETCHING,
  CAREERPLAN_SUCCESS,
  CAREERPLAN_FAILED,
} from "./types";

import CareerPlanService from "../services/careerPlan.service";

export const getAllCareerPlan = () => async (dispatch) => {
  try {
    const res = await CareerPlanService.getAllCareerPlan();
    if (res) {
      dispatch({
        type: CAREERPLAN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: CAREERPLAN_FAILED,
    });
    console.log(err);
  }
};

export const postCareerEmployee = (formData) => async () => {
  try {
    const res = await CareerPlanService.postCareerEmployee(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const postManagerSuggest = (formData) => async () => {
  try {
    const res = await CareerPlanService.postManagerSuggest(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putEmpIntCareerPlan = (id, formData) => async () => {
  try {
    const res = await CareerPlanService.putEmpIntCareerPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putManagerSugCareerPlan = (id, formData) => async () => {
  try {
    const res = await CareerPlanService.putManagerSugCareerPlan(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
