import { DIVISION_FETCHING, DIVISION_FAILED, DIVISION_SUCCESS } from "./types";

import DivisionService from "../services/division.service";

export const getAllDivision = () => async (dispatch) => {
  try {
    dispatch({
      type: DIVISION_FETCHING,
    });
    const res = await DivisionService.getAllDivision();
    if (res) {
      dispatch({
        type: DIVISION_SUCCESS,
        payload: res.data,
      });
      return res.data
    }
  } catch (err) {
    dispatch({
      type: DIVISION_FAILED,
    });
    console.log(err);
  }
};
