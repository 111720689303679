import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Dialog,
    DialogContent,
    Grid,
    styled,
    Tabs,
    Tab,
    Typography
} from "@mui/material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import { stringToColor } from "../../../../../utils";

const BoxStyled = styled(Box)(({ theme }) => ({
    width:"450px",
    [theme.breakpoints.down('sm')]:{
        width:"300px",
    },
    "& .Space-Bottom":{
        marginBottom:"10px"
    },
    "& .Space-Grid":{
        margin:"20px 0"
    },
    "& .TextDetail":{
        fontSize:"18px"
    },
    "& .NavButton":{
        display:"flex", 
        justifyContent:"flex-end",
        marginTop:"10px",
        [theme.breakpoints.down('sm')]:{
            flexDirection:"column-reverse"
        }
    },
    "& .CancelButton":{
        marginRight:"10px",
        [theme.breakpoints.down('sm')]:{
            marginRight:0
        }
    },
    "& .ConfirmButton":{
        marginBottom:0,
        [theme.breakpoints.down('sm')]:{
            marginBottom:"10px"
        }
    }
}));

const GridContainer = styled(Grid)({
    maxHeight:"200px",
    overflowY:"auto"
});

const DialogConfirmInterview = (props) => {
    const { open, onClose, onSubmit, listCandidates } = props;
    const [type, setType] = useState(0);
    const [data, setData] = useState(null);
    
    useEffect(() => {
        if(listCandidates){
            let listPass = [];
            let listNotPass = [];
            listCandidates.map((candidate) => {
                if(!!candidate.isAppointmentSuccess){
                    listPass.push(candidate);
                } else {
                    listNotPass.push(candidate);
                }
            });
            setData({ listPass: listPass, listNotPass: listNotPass });
        }
    },[listCandidates]);

    return (
        <Dialog
            open={open}
        >
            <DialogContent>
                <BoxStyled>
                    <form onSubmit={(e) => {onSubmit(data,e); e.preventDefault();}}>
                        <Typography variant="h5" className="Space-Bottom" style={{ fontweight: "bold" }}>ยืนยันการสรุปการสัมภาษณ์</Typography>
                        <Typography className="Space-Bottom TextDetail">ยืนยันการสรุปการสัมภาษณ์ต่อไปนี้</Typography>
                        <Tabs value={type} onChange={(e, newValue) => setType(newValue)}>
                            <Tab label="ผ่านการสัมภาษณ์" idtab={0} />
                            <Tab label="ไม่ผ่านการสัมภาษณ์" idtab={1} />
                        </Tabs>
                        {type === 0 &&
                            <GridContainer container spacing={2} className="Space-Grid">
                                {data && data.listPass && data.listPass.length > 0 ?
                                data.listPass.map(candidate => (
                                    <React.Fragment key={`${candidate.idCandidate}`}>
                                        <Grid item xs={2}>
                                            <Avatar sx={{ bgcolor: stringToColor(`${candidate.name} ${candidate.lastName}`) }} children={`${candidate.name[0]}${candidate.lastName[0]}`} />
                                        </Grid>
                                        <Grid container item xs={10} alignItems="center">
                                            <Grid item>
                                                <Box>
                                                    <Typography>{`${candidate.name} ${candidate.lastName}`}</Typography>
                                                    <Typography style={{ color:"#919EAB" }}>
                                                        <i className="fa-solid fa-building" style={{ marginRight:"10px" }} />{candidate.vendorName}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                ))
                                :
                                <Box style={{ width:"100%", height:100, display:"flex", alignItems:"center", justifyContent:"center" }}>
                                    <Typography align="center" variant="body1" >ไม่พบรายชื่อผู้สัมภาษณ์</Typography>
                                </Box>
                                }
                            </GridContainer>
                        }
                        {type === 1 &&
                            <GridContainer container spacing={2} className="Space-Grid">
                                {data && data.listNotPass && data.listNotPass.length > 0 ?
                                data.listNotPass.map(candidate => (
                                    <React.Fragment key={`${candidate.idCandidate}`}>
                                        <Grid item xs={2}>
                                            <Avatar sx={{ bgcolor: stringToColor(`${candidate.name} ${candidate.lastName}`) }} children={`${candidate.name[0]}${candidate.lastName[0]}`} />
                                        </Grid>
                                        <Grid container item xs={10} alignItems="center">
                                            <Grid item>
                                                <Box>
                                                    <Typography>{`${candidate.name} ${candidate.lastName}`}</Typography>
                                                    <Typography style={{ color:"#919EAB" }}>
                                                        <i className="fa-solid fa-building" style={{ marginRight:"10px" }} />{candidate.vendorName}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                ))
                                :
                                <Box style={{ width:"100%", height:100, display:"flex", alignItems:"center", justifyContent:"center" }}>
                                    <Typography align="center" variant="body1" >ไม่พบรายชื่อผู้สัมภาษณ์</Typography>
                                </Box>
                                }
                            </GridContainer>
                        }
                        <Box className="NavButton">
                            <ButtonBlue
                                variant="outlined"
                                className="CancelButton"
                                onClick={onClose}
                            >ยกเลิก</ButtonBlue>
                            <ButtonBlue
                                variant="contained"
                                className="ConfirmButton"
                                type="submit"
                            >ยืนยัน</ButtonBlue>
                        </Box>
                    </form>
                </BoxStyled>
            </DialogContent>
        </Dialog>
    );
};

export default DialogConfirmInterview;