import React from "react";
import { Chip } from "@mui/material";

const ChipLetterStatus = ({ status }) => {
  let label = "";
  let color = "";

  if (status === null) {
    label = "ยังไม่ตอบกลับ";
    color = "primary";
  } else if (status === 1) {
    label = "ยอมรับ";
    color = "success";
  } else {
    label = "ไม่ยอมรับ";
    color = "error";
  }

  return <Chip variant="outlined" label={label} color={color} />;
};

export default ChipLetterStatus
