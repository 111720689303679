import React, { useState } from "react";
import { Box, CircularProgress, Dialog, FormHelperText, IconButton, styled, Typography } from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import AddIcon from "./../../../../../assets/addCertificate.png";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import {  addManpowerResume , deleteManpowerResume } from "../../../../../../../actions/manpower";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";

const StyledRoot = styled(Box)({
  padding: "24px",
  "& .dropzone": {
    cursor: "pointer",
    flexShrink: 0,
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .inner-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& img": {
        width: 72,
        height: 72,
      }
    }
  },
  "& .resume-wrap": {
    textDecoration: "none",
    color: "inherit",
    position: "relative",
    cursor: "pointer",
    flexShrink: 0,
    padding: 8,
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    border: "1px solid #D0D0D0",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    "& .icon-part": {
      flexGrow:1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& i": {
        fontSize: 56,
        color: "#D0D0D0"
      }
    },
    "& .filename": {
      flexShrink: 1,
      height: "48px",
      "& .MuiTypography-root": {
        fontSize: 14,
        display: "-webkit-box",
        overflow: "hidden",
        WebkitLineClamp: 2, 
        WebkitBoxOrient: "vertical",
        textAlign: "center",
        wordBreak: "break-all",
      }
    },
    "& .delete": {
      position: "absolute",
      top: 0,
      right: 0,
      padding: 8,
      "& i": {
        fontSize: 16,
      }
    }
  }
})

const DialogDelete = (props) => {

  const { open, onClose, idManpower, idManpowerResume, setResume } = props;

  const [isFetching, setIsFetcing] = useState(false);

  const dispatch = useDispatch();

  const onConfirmDelete = () => {

    setIsFetcing(true);

    dispatch(deleteManpowerResume(idManpower, idManpowerResume)).then(res => {
      if(res.status === 200){
        onClose();
        // dispatch(getManpowerCertificate(idManpower)).then(res => {
        //   setCertificates(res.data.data)
        // })
        setResume(null);
      }
      setIsFetcing(false);
    })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <Box
        padding="16px"
      >
        <Typography fontSize={20}>ลบ</Typography>
        <Box padding="24px 0">
          <Typography>คุณต้องการลบไฟล์หรือไม่</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <ButtonBlue variant="outlined" onClick={onClose}>ยกเลิก</ButtonBlue>
          <ButtonBlue variant="contained" onClick={onConfirmDelete} disabled={isFetching} startIcon={isFetching && <CircularProgress size={16}/>}>ลบ</ButtonBlue>
        </Box>
      </Box>
    </Dialog>
  )
}

const Resume = (props) => {

  const { idManpower, data, isMyManpower } = props;

  const [resume, setResume] = useState(data);

  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const [deleteDiglogState, setDeleteDialogState] = useState({
    isOpen: false,
    idManpowerResume: null,
  })

  const onDropHandler = (acceptedFiles, rejectedFiles) => {

    if(acceptedFiles.length > 0){

      setIsFetching(true);

      const formData = new FormData;
      formData.append("resumeFile", acceptedFiles[0])


      dispatch(addManpowerResume(idManpower, formData)).then(res => {
        if(res.status === 200){
          // dispatch(getManpowerCertificate(idManpower)).then(res => {
          //   setCertificates(res.data.data)
          // })
          setResume(res.data.data)
        }
        setIsFetching(false);
      })
    }

    if(rejectedFiles.length > 0){
      console.log(rejectedFiles)
    }

  }

  const onClickDelete = (idManpowerResume) => {
    setDeleteDialogState({
      isOpen: true,
      idManpowerResume: idManpowerResume
    })
  }

  return (
    <CardStyle>
      <StyledRoot>
        <Typography variant="h5" fontSize={20}>เรซูเม่</Typography>
        <Box marginTop="24px">
          {(isMyManpower && !resume) && <Dropzone
            onDrop={onDropHandler}
            accept="application/pdf"
            // maxFiles={5}
            // multiple
            maxSize={10485760}
          >
            {({
              getRootProps,
              getInputProps,
            }) => (
              <div {...getRootProps({ className: "dropzone" })}>

                {isFetching ? (
                  <Box>
                    <Box className="inner-dropzone">
                      <CircularProgress />
                    </Box>
                  </Box>
                ):(
                  <Box>
                    <input {...getInputProps()}/>
                    <Box className="inner-dropzone">
                      <img alt="add" src={AddIcon} />
                    </Box>
                  </Box>
                )}
              </div>
              )
            }
          </Dropzone>}

          {resume && <Box className="resume-wrap" component={"a"} href={resume.filePath} target="_blank">
            <Box className="icon-part">
              <i className="fa-solid fa-file"></i>
            </Box>
            <Box className="filename">
              <Typography>{resume.filename}</Typography>
            </Box>
            {isMyManpower && <IconButton className="delete" onClick={(e)=>{e.preventDefault(); onClickDelete(resume.idManpowerResume)}}>
              <i className="fa-solid fa-trash-can"></i>
            </IconButton>}
          </Box>}

          {(!isMyManpower && !resume) && <Typography textAlign="center" color="rgb(145, 158, 171)" margin="16px 0">ไม่มีเรซูเม่</Typography>}

          {isMyManpower && <FormHelperText error>*สามารถอัพโหลดได้เฉพาะไฟล์ .pdf เท่านั้น</FormHelperText>}

        </Box>

        <DialogDelete
          open={deleteDiglogState.isOpen}
          onClose={()=>{
            setDeleteDialogState({
              isOpen: false,
              idManpowerResume: null
            })
          }}
          idManpower={idManpower}
          idManpowerResume={deleteDiglogState.idManpowerResume}
          setResume={setResume}
        />
      </StyledRoot>
    </CardStyle>
  )
}

export default Resume;