import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Container, Grid, MenuItem, Tab, Tabs } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import useMediaQuery from '@mui/material/useMediaQuery';

import ButtonBlue from "../../shared/general/ButtonBlue";
import SnackBarCustom from "../../shared/snackbarCustom";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import {
    getAllRequestTimeBy,
    getAllRequestTimeWithDrawBy,
} from "../../../../actions/requestTime";
import {
    getLeaveRequest,
    getEmployeeChangeTime,
} from "../../../../actions/employee";
import { getAllLeaveWithDrawBy } from "../../../../actions/leave";
import { getPayrollSetting } from "../../../../actions/paytypes";

import RequestTime from "./RequestTime";
import RequestOverTime from "./RequestOverTime";
import RequestLeave from "./RequestLeave";
import RequestTimeShift from "./RequestTimeShift";
import RequestCompensate from "./RequestCompensate";

const StyledRoot = styled("div")(({ theme }) => ({
    background: "#FFFFFF !important",
    "& .displayFlexEnd":{
        display:"flex",
        justifyContent:"flex-end",
        [theme.breakpoints.down("sm")]:{
          width:"100%",
          margin:"8px 0"
        }
    }
}));

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
      backgroundColor: "#919eab14",
      height: 56,
      padding: "0px 12px",
      borderRadius: 8,
      "& .MuiFilledInput-input": {
        padding: "7px 4px",
      },
      "&.Mui-focused": {
        backgroundColor: "#919eab14",
      },
      "& .MuiInputAdornment-root": {
        width: 32,
        marginTop: "0!important",
        fontSize: 24,
        "& button": {
          color: "#919EAB",
        },
      },
      "&:hover": {
        backgroundColor: "#919eab29",
        "&:before": {
          border: "none !important",
        },
      },
      "&::after": {
        border: "none",
      },
      "&::before": {
        border: "none",
      },
    },
});
  
const StyledBoxSearch = styled(Box)({
    "& .label": {
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 8,
    },
});
  
const StyledCard = styled(Card)({
    padding: 16,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
      padding: 24,
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Requestlist = (props) => {
    const dispatch = useDispatch();
    const [search, setSearch] = React.useState({ start: null, end: null });
    const [approveTabsValue, setApproveTabsValue] = React.useState(0);

    const { result: employeeChangeShiftList } = useSelector(
        (state) => state.employeeChangeShift
    );
    const { result: requestTimeList } = useSelector((state) => state.requestTime);
    const { result: requestTimeWithDrawList } = useSelector(
        (state) => state.requestTimeWithDraw
    );
    const { result: leaveEmployeesList } = useSelector(
        (state) => state.leaveEmployees
    );
    const { result: leaveEmployeeWithdrawsList } = useSelector(
        (state) => state.leaveEmployeesWithDraw
    );

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: payrollSetting } = useSelector(
        (state) => state.payrollSetting
    );

    const mobile = useMediaQuery('(max-width:600px)');

    const [snackBarConfig, setSnackBarConfig] = useState({
        open: false,
        message: "",
        type: "success",
    });

    useEffect(() => {
        dispatch(getEmployeeChangeTime("manager"));
        dispatch(getLeaveRequest("manager", search));
        dispatch(getAllLeaveWithDrawBy("manager", search));
        dispatch(getAllRequestTimeBy("manager", search));
        dispatch(getAllRequestTimeWithDrawBy("manager", search));
        dispatch(getPayrollSetting());
    }, []);

    const handleApproveTabsChange = (event, newValue) => {
        setApproveTabsValue(newValue);
    };

    const handleApproveTabsChangeSelect = (event) => {
        setApproveTabsValue(event.target.value);
    };

    const handleClickSearch = async () => {
        dispatch(getLeaveRequest("manager", search));
        dispatch(getAllLeaveWithDrawBy("manager", search));
        dispatch(getAllRequestTimeBy("manager", search));
        dispatch(getAllRequestTimeWithDrawBy("manager", search));
    };

    return (
        <StyledRoot className="page">
            <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />

            <Container maxWidth="lg" style={{ paddingBottom: 40 }}>
                <Grid container justifyContent="space-between"  alignItems="center">
                    <Grid item>
                        <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
                            ประวัติการอนุมัติ
                        </Typography>
                    </Grid>
                    <Grid item className="displayFlexEnd">
                        <ButtonBlue
                            variant="contained"
                            style={{ height: "fit-content" }}
                            startIcon={<TextSnippetIcon />}
                            // onClick={() => props.history.push("/manager/approve")}
                            onClick={() => props.history.push("/waiting-approve")}
                        >
                            รายการรออนุมัติ
                        </ButtonBlue>
                    </Grid>
                </Grid>
                <Box style={{ paddingBottom: 16 }}>
                    <Grid container alignItems="flex-end" spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                วันที่เริ่มต้น
                                </Typography>
                                <div className="search-date">
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={th}
                                >
                                    <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={search.start}
                                    name="start"
                                    views={["year", "month", "day"]}
                                    onChange={(newValue) => {
                                        setSearch({
                                        ...search,
                                        start: newValue,
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <StyledTextField
                                        variant="filled"
                                        fullWidth
                                        {...params}
                                        />
                                    )}
                                    />
                                </LocalizationProvider>
                                </div>
                            </StyledBoxSearch>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                วันที่สิ้นสุด
                                </Typography>
                                <div className="search-date">
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={th}
                                >
                                    <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={search.end}
                                    name="start"
                                    views={["year", "month", "day"]}
                                    onChange={(newValue) => {
                                        setSearch({
                                        ...search,
                                        end: newValue,
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <StyledTextField
                                            variant="filled"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                    />
                                </LocalizationProvider>
                                </div>
                            </StyledBoxSearch>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <div style={{ paddingBottom: 12 }}>
                                <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                                ค้นหา
                                </ButtonBlue>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <StyledCard>
                    <Box paddingBottom="24px">
                        {mobile ?
                            <TextFieldTheme
                                value={approveTabsValue}
                                onChange={handleApproveTabsChangeSelect}
                                select
                            >
                                <MenuItem value={0}>
                                    รับรองเวลาทำงาน
                                </MenuItem>
                                <MenuItem value={1}>
                                    ทำงานล่วงเวลา
                                </MenuItem>
                                <MenuItem value={4}>
                                    ขอทำชั่วโมง Compensate
                                </MenuItem>
                                <MenuItem value={2}>
                                    ขอลางาน
                                </MenuItem>
                                <MenuItem value={3}>
                                    ขอเปลี่ยนกะ
                                </MenuItem>
                            </TextFieldTheme>
                            :
                            <Tabs
                                value={approveTabsValue}
                                onChange={handleApproveTabsChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="basic tabs example"
                                sx={{
                                  "& .MuiTab-root.Mui-selected": {
                                    color: "#46cbe2",
                                  },
                                }}
                                TabIndicatorProps={{
                                  style: {
                                    backgroundColor: "#46cbe2",
                                  },
                                }}
                            >
                                <Tab label="รับรองเวลาทำงาน" {...a11yProps(0)} value={0} />
                                <Tab label="ทำงานล่วงเวลา" {...a11yProps(1)} value={1}/>
                                <Tab label="ขอทำชั่วโมง Compensate" {...a11yProps(4)} value={4}/>
                                <Tab label="ขอลางาน" {...a11yProps(2)} value={2}/>
                                <Tab label="ขอเปลี่ยนกะ" {...a11yProps(3)} value={3}/>
                            </Tabs>
                        }
                    </Box>
                    {userProfile && 
                        <Box style={{ width: "100%" }}>
                            {requestTimeList && requestTimeWithDrawList && (
                                <TabPanel value={approveTabsValue} index={0}>
                                    <RequestTime />
                                </TabPanel>
                            )}

                            {requestTimeList && requestTimeWithDrawList && payrollSetting && (
                                <TabPanel value={approveTabsValue} index={1}>
                                    <RequestOverTime />
                                </TabPanel>
                            )}

                            {leaveEmployeesList && leaveEmployeeWithdrawsList && (
                                <TabPanel value={approveTabsValue} index={2}>
                                    <RequestLeave />
                                </TabPanel>
                            )}

                            {employeeChangeShiftList && (
                                <TabPanel value={approveTabsValue} index={3}>
                                    <RequestTimeShift />
                                </TabPanel>
                            )}

                            {requestTimeList && requestTimeWithDrawList && (
                                <TabPanel value={approveTabsValue} index={4}>
                                    <RequestCompensate />
                                </TabPanel>
                            )}
                        </Box>
                    }
                </StyledCard>
            </Container>
        </StyledRoot>
    );
};

export default Requestlist;