import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Typography,
  FormControl,
  Divider,
  OutlinedInput,
  TextField,
  Popper,
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import AdapterDateFns from "@tarzui/date-fns-be";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

// import {
//   getPaymentType,
//   getEmployeeProfile,
// } from "../../../../../../actions/employee";
import {
  getEmployeeProfile,
  addDeleteEmployeePersonnelLevel,
  updateEmployeePersonnelLevel,
  getEmployeePersonnelLevelChange,
  updateEmployeePersonnelLevelChange,
} from "../../../../../../actions/employee";
// import { updateUserNTable, updateUser } from "../../../../../../actions/user";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
  "& .wrap": {
    marginTop: 8,
  },
});

const StyledSalary = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const DialogEdit = (props) => {
  const { open, handleCloseDialog, mode, selectedItem, drawerConfig } = props;
  const dispatch = useDispatch();
  dayjs.extend(isSameOrAfter);
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: levelList } = useSelector((state) => state.level);
  const [formData, setFormData] = useState(null);
  const [dataLevelList, setDataLevelList] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // useEffect(() => {
  //   if (selectedItem && mode === "edit") {
  //     let temp = { ...selectedItem };
  //     setFormData(temp);
  //   }
  // }, [selectedItem, mode]);

  // useEffect(() => {
  //   if (employeeProfile && mode === "add") {
  //     setFormData({
  //       idEmployees: employeeProfile.idEmployees,
  //       idEmployeePersonnelLevelOld: employeeProfile.idEmployeePersonnelLevel
  //         ? employeeProfile.idEmployeePersonnelLevel
  //         : null,
  //       idLevel: null,
  //       start: new Date(),
  //       remark: "",
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (employeeProfile && open) {
      if(!drawerConfig.isEdit){
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          idEmployeePersonnelLevelOld: employeeProfile.idEmployeePersonnelLevel
            ? employeeProfile.idEmployeePersonnelLevel
            : null,
          idLevel: null,
          start: new Date(),
          remark: "",
        });
      } else {
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          idLevel: drawerConfig.data.idLevel || null,
          start: drawerConfig.data.start? new Date(drawerConfig.data.start): null,
          remark: drawerConfig.data.remark || "",
        });
      }
    }
  }, [open]);

  useEffect(() => {
    if (levelList) {
      let temp = [];
      levelList.forEach((element) => {
        if (element.level.length > 0) {
          let tempLevel = [...element.level];
          let addGroupName = tempLevel.map((object) => {
            return { ...object, groupLevelName: element.groupLevelName };
          });
          delete addGroupName["indexLevel"];
          temp.push(...addGroupName);
        }
      });

      setDataLevelList(temp);
    }
  }, [levelList]);

  const handleSubmit = async () => {
    console.log(formData);
    let result = null;
    let tempFormData = { ...formData };

    tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");
    tempFormData.idAdmin = userProfile.idEmployees

    Object.keys(tempFormData).map(k => {
      if(tempFormData[k] === ""){
        tempFormData[k] = null
      } else {
        tempFormData[k] = tempFormData[k]
      }
    })

    if(drawerConfig.isEdit){
      tempFormData.updateDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
      // console.log(employeeProfile.idEmployees)
      // console.log(drawerConfig.data.idEmployeePersonnelLevel)
      // console.log(tempFormData)
      result = await dispatch(updateEmployeePersonnelLevelChange(employeeProfile.idEmployees, drawerConfig.data.idEmployeePersonnelLevel, tempFormData))
    } else {
      result = await dispatch(addDeleteEmployeePersonnelLevel(tempFormData));
    }

    // if (mode === "add") {
    //   tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");
    //   const result = await dispatch(addDeleteEmployeePersonnelLevel(tempFormData));
    // } else {
    //   let tempFormData = {
    //     idEmployeePersonnelLevel: formData.idEmployeePersonnelLevel,
    //     idLevel: formData.idLevel,
    //     remark: formData.remark,
    //   };
    //   const result = await dispatch(updateEmployeePersonnelLevel(tempFormData));
    // }
    if (result) {
      handleCloseDialog();
      dispatch(getEmployeePersonnelLevelChange(employeeProfile.idEmployees));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
  };

  return (
    <DrawerCustom
      title={mode === "add" ? "ปรับระดับ" : "แก้ไขระดับ"}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {formData && (
          <Grid container>
            <Grid item xs={12}>
              <StyledContentLabel>ข้อมูลปัจจุบัน</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">
                  กลุ่มระดับ
                </StyledHeadLabel>
                <Typography className="present">
                  {employeeProfile.personnelLevelGroupName
                    ? employeeProfile.personnelLevelGroupName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">ระดับ</StyledHeadLabel>
                <Typography className="present">
                  {employeeProfile.personnelLevelName
                    ? employeeProfile.personnelLevelName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>ระดับใหม่</StyledContentLabel>
              <StyledAutocomplete
                options={levelList && dataLevelList ? dataLevelList : []}
                value={formData.idLevel? dataLevelList.find(x => x.idLevel === formData.idLevel) || null: null}
                groupBy={(option) => option.groupLevelName}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFormData({
                      ...formData,
                      ["idLevel"]: newValue.idLevel,
                    });
                  } else {
                    setFormData({
                      ...formData,
                      ["idLevel"]: null,
                    });
                  }
                }}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.levelName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder="เลือกระดับ"
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText="ไม่มีข้อมูล"
              />
            </Grid>

            <Grid item xs={12}>
              <div className="wrap">
                <StyledContentLabel gutterBottom>วันที่มีผล</StyledContentLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <DatePicker
                    value={formData.start}
                    views={["year", "month", "day"]}
                    openTo="year"
                    inputFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        start: date,
                      });
                    }}
                    renderInput={(params) => (
                      <StyledTextField fullWidth {...params} />
                    )}
                    disabled={drawerConfig.isEdit}
                    // readOnly={mode === "add" ? false : true}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="wrap">
                <StyledContentLabel>หมายเหตุ</StyledContentLabel>
                <TextFieldTheme
                  variant="outlined"
                  name="remark"
                  value={formData.remark}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={4}
                />
              </div>
            </Grid>
          </Grid>
        )}

        <StyledFooter>
          <ButtonBlue
            variant="text"
            className="cancel"
            onClick={handleCloseDialog}
          >
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
