import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Autocomplete, Box, Divider, Grid, MenuItem, Typography, createFilterOptions, styled } from "@mui/material";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import utils from "../../../../../../utils";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import dayjs from "dayjs";
import { addDeleteEmployeePersonnelLevel, addDeleteEmployeePosition, addDeleteEmployeeSalary, addEmployeeInformationChange, getEmployeePersonnelLevelChange, getEmployeePositionChange, getEmployeeProfile, getEmployeeSalaryChange } from "../../../../../../actions/employee";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
})

const FlowForm = (props) => {

  const { drawerConfig, handleClose, defaultPage } = props
  
  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [page, setPage] = useState("position")
  const [formStateData, setFormStateData] = useState({});
  // const [nextPage, setNextPage] = useState("")

  const validateYupSchema = yup.object({
    positionData: yup.object({
      idPosition: defaultPage === "position"? yup.string().required("กรุณากรอกข้อมูล"): undefined,
      idManagerLV1: defaultPage === "position"? yup.string().required("กรุณากรอกข้อมูล"): undefined,
      idManagerLV2: defaultPage === "position"? yup.string().required("กรุณากรอกข้อมูล"): undefined,
    }).nullable(),
    personnelLevelData: yup.object({
      idLevel: defaultPage === "personnelLevel"? yup.string().required("กรุณากรอกข้อมูล"): undefined,
    }).nullable(),
    salaryData: yup.object({
      salaryValue: defaultPage === "salary"? yup.string().required("กรุณากรอกข้อมูล"): undefined,
    }).nullable()
  })

  const useHookForm = useForm({
    defaultValues: {
      positionData: {
        idPosition: "",
        description: "",
        idManagerLV1: "",
        idManagerLV2: "",
        start: new Date(),
      },
      personnelLevelData: {
        idLevel: "",
        remark: "",
        start: new Date(),
      },
      salaryData: {
        salaryValue: "",
        remark: "",
        start: new Date(),
      }
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const displayTitle = () => {
    switch(page){
      case "position": {
        return "โอนย้ายตำแหน่งงาน"
      }
      case "personnelLevel": {
        return "ปรับระดับพนักงาน"
      }
      case "salary": {
        return "ปรับค่าจ้าง"
      }
      case "prePosition": {
        return "ต้องการโอนย้ายตำแหน่งงานหรือไม่ ?"
      }
      case "prePersonnelLevel": { 
        return "ต้องการปรับระดับพนักงานหรือไม่ ?"
      }
      case "preSalary": {
        return "ต้องการปรับค่าจ้างหรือไม่ ?"
      }
    }
  }

  const handleClosePreForm = () => {
    switch(page){
      case "prePosition": {
        return setPage("prePersonnelLevel")
      }
      case "prePersonnelLevel": { 
        return setPage("preSalary")
      }
      case "preSalary": { 
        return handleClose()
      }
    }
  }

  const handleFormSubmit = async () => {
    const preData = useHookForm.getValues();

    const formData = {};

    if(preData.positionData && preData.positionData.idPosition){
      formData.positionData = {};
      formData.positionData.idPosition = preData.positionData.idPosition;
      formData.positionData.description = String(preData.positionData.description) || null;
      formData.positionData.idManagerLV1 = preData.positionData.idManagerLV1;
      formData.positionData.idManagerLV2 = preData.positionData.idManagerLV2;
      formData.positionData.start = dayjs(preData.positionData.start).format("YYYY-MM-DD");
      formData.positionData.idEmployees = employeeProfile.idEmployees;
      // formData.positionData.idEmployeePositionOld = employeeProfile.idEmployeePosition;
    }
    if(preData.personnelLevelData && preData.personnelLevelData.idLevel){
      formData.personnelLevelData = {};
      formData.personnelLevelData.idLevel = preData.personnelLevelData.idLevel;
      formData.personnelLevelData.remark = String(preData.personnelLevelData.remark) || null;
      formData.personnelLevelData.start = dayjs(preData.personnelLevelData.start).format("YYYY-MM-DD");
      formData.personnelLevelData.idAdmin = userProfile.idEmployees;
      formData.personnelLevelData.idEmployees = employeeProfile.idEmployees;
      // formData.personnelLevelData.idEmployeePersonnelLevelOld = employeeProfile.idEmployeePersonnelLevel || null;
    }
    if(preData.salaryData && preData.salaryData.salaryValue){
      formData.salaryData = {};
      formData.salaryData.salaryValue = preData.salaryData.salaryValue;
      formData.salaryData.remark = String(preData.salaryData.remark) || null;
      formData.salaryData.start = dayjs(preData.salaryData.start).format("YYYY-MM-DD");
      formData.salaryData.idAdmin = userProfile.idEmployees;
      formData.salaryData.idEmployees = employeeProfile.idEmployees;
      // formData.salaryData.idEmployeePersonnelLevelOld = employeeProfile.idEmployeePersonnelLevel || null;
    }

    const result = await dispatch(addEmployeeInformationChange(formData));

    if(result){
      handleClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      if(defaultPage === "position"){
        dispatch(getEmployeePositionChange(employeeProfile.idEmployees));
      } else if (defaultPage === "personnelLevel"){
        dispatch(getEmployeePersonnelLevelChange(employeeProfile.idEmployees));
      } else if (defaultPage === "salary"){
        dispatch(getEmployeeSalaryChange(employeeProfile.idEmployees));
      }
    }
  }

  useLayoutEffect(() => {
    if(drawerConfig.isOpen){
      useHookForm.reset({
        positionData: {
          idPosition: "",
          description: "",
          idManagerLV1: "",
          idManagerLV2: "",
          newPositionName: "",
          start: new Date(),
        },
        personnelLevelData: {
          idLevel: "",
          remark: "",
          start: new Date(),
        },
        salaryData: {
          salaryValue: "",
          remark: "",
          start: new Date(),
        }
      })
      if(defaultPage){
        setPage(defaultPage)
      }
      // setFormStateData({});
    }
  }, [drawerConfig.isOpen])

  return (
    <DrawerCustom
      title={displayTitle()}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        {page === "position" && (
          <PositionForm
            setPage={setPage}
            handleClose={handleClose}
            defaultPage={defaultPage}
            formStateData={formStateData}
            setFormStateData={setFormStateData}
            useHookForm={useHookForm}
          />
        )}
        {page === "personnelLevel" && (
          <PersonnelLevelForm
            setPage={setPage}
            handleClose={handleClose}
            defaultPage={defaultPage}
            formStateData={formStateData}
            setFormStateData={setFormStateData}
            useHookForm={useHookForm}
          />
        )}
        {page === "salary" && (
          <SalaryForm
            setPage={setPage}
            handleClose={handleClose}
            defaultPage={defaultPage}
            formStateData={formStateData}
            setFormStateData={setFormStateData}
            useHookForm={useHookForm}
            handleFormSubmit={handleFormSubmit}
          />
        )}
        {["prePersonnelLevel", "preSalary"].includes(page) && (
          <ConfirmNextPage
            setPage={setPage}
            page={page}
            handleClose={handleClosePreForm}
          />
        )}
      </StyledRoot>
    </DrawerCustom>
  );
}

const PositionForm = (props) => {

  const { setPage, handleClose, defaultPage, formStateData, setFormStateData, useHookForm } = props;

  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: positionList } = useSelector((state) => state.positions);
  const { result: managerList } = useSelector((state) => state.manager);

  const [nextPosition, setNextPosition] = useState({
    positionName: "",
    sectionName: "",
    departmentName: "",
    divisionName: "",
  })

  const validateYupSchema = yup.object({
    idPosition: defaultPage === "position"? yup.string().required("กรุณากรอกข้อมูล"): undefined,
    idManagerLV1: defaultPage === "position"? yup.string().required("กรุณากรอกข้อมูล"): undefined,
    idManagerLV2: defaultPage === "position"? yup.string().required("กรุณากรอกข้อมูล"): undefined,
    start: defaultPage === "position"? yup.date().required("กรุณากรอกข้อมูล").nullable(): undefined,
  })

  // const useHookForm = useForm({
  //   defaultValues: {
  //     idPosition: "",
  //     description: "",
  //     idManagerLV1: "",
  //     idManagerLV2: "",
  //     start: new Date(),
  //   },
  //   resolver: yupResolver(validateYupSchema),
  //   mode: "all",
  // })

  const filterOptions = createFilterOptions({
    stringify: (value) => `${value.positionName} ${value.positionName_EN} ${value.positionCode}`
  })

  const filterManagerOptions = createFilterOptions({
    stringify: (value) => `${value.firstname_TH} ${value.lastname_TH} ${value.email}`
  })

  const onSubmit = async (data) => {

    const formData = {...data};

    formData.start = dayjs(formData.start).format("YYYY-MM-DD");
    formData.idEmployees = employeeProfile.idEmployees;
    formData.idEmployeePositionOld = employeeProfile.idEmployeePosition;
    formData.end = null;
    formData.isActive = 1;

    Object.keys(formData).map(k => {
      if(formData[k] === ""){
        formData[k] = null
      }
    })

    // console.log(formData)

    if (
      formData.idPosition === employeeProfile.idPosition &&
      formData.idManagerLV1 === employeeProfile.managerLV1_idManagerLV1 &&
      formData.idManagerLV2 === employeeProfile.managerLV2_idManagerLV2
    ) {
      alert("ข้อมูลไม่มีการเปลี่ยนแปลง");
    } else {
      formData.method = "add"

      setFormStateData(prev => ({
        ...prev,
        positionData: formData.idPosition? formData: null,
        personnelLevelData: (!prev.personnelLevelData ||  (prev.personnelLevelData && !prev.personnelLevelData.remark))? ({remark: "เปลี่ยนตำแหน่งงาน\n1 เป็น\n2"}): prev.personnelLevelData
      }))
      setPage("personnelLevel")

      // let result = await dispatch(addDeleteEmployeePosition(formData));

      // if (result) {
      //   dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      //   dispatch(getEmployeePositionChange(employeeProfile.idEmployees));
      //   setPage("prePersonnelLevel")
      // }
    }
  }

  const onNextPage = async () => {
    if(useHookForm.getValues("positionData.idPosition")){
      // console.log("1", useHookForm.getValues("personnelLevelData.remark"))
      if(!useHookForm.getValues("personnelLevelData.remark")){
        // console.log("2", useHookForm.getValues("personnelLevelData.remark"))
        useHookForm.setValue("personnelLevelData.remark", `เปลี่ยนตำแหน่งงาน\nจาก ${employeeProfile.positionName}\nเป็น ${nextPosition.positionName}`)
      }
    }

    await useHookForm.trigger()

    // console.log(useHookForm.formState.errors)

    if(!useHookForm.formState.errors.positionData){
      setPage("personnelLevel");
    }

  }

  useEffect(() => {
    // console.log(formStateData)
    // if(formStateData.positionData){
    //   useHookForm.reset({
    //     idPosition: formStateData.positionData.idPosition || "",
    //     description: formStateData.positionData.description || "",
    //     idManagerLV1: formStateData.positionData.idManagerLV1 || "",
    //     idManagerLV2: formStateData.positionData.idManagerLV2 || "",
    //     start: new Date(formStateData.positionData.start),
    //   })
    // } else {
    //   useHookForm.reset({
    //     idPosition: "",
    //     description: "",
    //     idManagerLV1: "",
    //     idManagerLV2: "",
    //     start: new Date(),
    //   })
    // }
    if(useHookForm.getValues("positionData.idPosition")){
      const value = positionList? positionList.find(option => Number(option.idPosition) === Number(useHookForm.getValues("positionData.idPosition"))) || null: null
      if(value){
        setNextPosition(prev => ({
          ...prev,
          positionName: value.positionName,
          sectionName: value.sectionName,
          departmentName: value.departmentName,
          divisionName: value.divisionName,
        }))
      }
    }
  }, [])

  return (
    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="16px">ข้อมูลปัจจุบัน</Typography>
        </Grid>
        {employeeProfile && <Fragment>
          <Grid item xs={12}>
            <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">ตำแหน่ง</Typography>
            {employeeProfile.positionName
              ? <Typography fontWeight="500">{employeeProfile.positionName}</Typography>
              : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">แผนก</Typography>
            {employeeProfile.sectionName
              ? <Typography fontWeight="500">{employeeProfile.sectionName}</Typography>
              : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">ส่วน</Typography>
            {employeeProfile.departmentName
              ? <Typography fontWeight="500">{employeeProfile.departmentName}</Typography>
              : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
            }
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">ฝ่าย</Typography>
            {employeeProfile.divisionName
              ? <Typography fontWeight="500">{employeeProfile.divisionName}</Typography>
              : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">หัวหน้างานลำดับที่ 1</Typography>
            {employeeProfile.idManagerLV1
              ? <Fragment>
                  <Typography fontWeight="500">{employeeProfile.managerLV1_firstname_TH} {employeeProfile.managerLV1_lastname_TH}</Typography>
                  <Typography fontSize="14px">{employeeProfile.managerLV1_positionName}</Typography>
                  <Typography fontSize="14px">{employeeProfile.managerLV1_email}</Typography>
                </Fragment>
              : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">หัวหน้างานลำดับที่ 2</Typography>
            {employeeProfile.idManagerLV2
              ? <Fragment>
                  <Typography fontWeight="500">{employeeProfile.managerLV2_firstname_TH} {employeeProfile.managerLV2_lastname_TH}</Typography>
                  <Typography fontSize="14px">{employeeProfile.managerLV2_positionName}</Typography>
                  <Typography fontSize="14px">{employeeProfile.managerLV2_email}</Typography>
                </Fragment>
              : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
            }
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ borderColor: "rgba(145, 158, 171, 0.32)", borderStyle: "dashed" }}/>
          </Grid>
        </Fragment>}
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">ตำแหน่งใหม่</Typography>
          <Controller
            name="positionData.idPosition"
            control={useHookForm.control}
            render={({field}) => (
              <Autocomplete
                options={positionList? positionList: []}
                getOptionLabel={(option) => `${option.positionName}`}
                filterOptions={filterOptions}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idPosition}>
                    <Box>
                      <Typography color="text.secondary" fontSize="12px">{option.positionCode}</Typography>
                      <Typography>{option.positionName}</Typography>
                      <Typography color="text.secondary" fontSize="14px">{option.divisionName || "-"}/{option.departmentName || "-"}/{option.sectionName || "-"}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    placeholder="ค้นหาตำแหน่งงาน"
                    onBlur={field.onBlur}
                    error={useHookForm.formState.errors["positionData"] && useHookForm.formState.errors["positionData"]["idPosition"]? true: false}
                    helperText={useHookForm.formState.errors["positionData"] && useHookForm.formState.errors["positionData"]["idPosition"]? useHookForm.formState.errors["positionData"]["idPosition"].message: null}
                  />
                )}
                value={positionList? positionList.find(option => Number(option.idPosition) === Number(field.value)) || null: null}
                onChange={(_, value) => {
                  field.onChange(value? value.idPosition: "")
                  if(value){
                    useHookForm.setValue("positionData.newPositionName", value.positionName)
                    setNextPosition(prev => ({
                      ...prev,
                      positionName: value.positionName,
                      sectionName: value.sectionName,
                      departmentName: value.departmentName,
                      divisionName: value.divisionName,
                    }))
                  }
                }}
                noOptionsText="ไม่พบข้อมูล"
                // disableClearable
              />
            )}
          />
        </Grid>
        <Controller
          name="positionData.idPosition"
          control={useHookForm.control}
          render={({field}) => (
            <Fragment>
              {field.value && (
                <Fragment>
                  <Grid item xs={12} sm={6}>
                    <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">แผนก</Typography>
                    {nextPosition.sectionName
                      ? <Typography fontWeight="500">{nextPosition.sectionName}</Typography>
                      : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
                    }
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">ส่วน</Typography>
                    {nextPosition.departmentName
                      ? <Typography fontWeight="500">{nextPosition.departmentName}</Typography>
                      : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">ฝ่าย</Typography>
                    {nextPosition.divisionName
                      ? <Typography fontWeight="500">{nextPosition.divisionName}</Typography>
                      : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
                    }
                  </Grid>
                </Fragment>
              )}
            </Fragment>
          )}
        />
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">หัวหน้างานลำดับที่ 1</Typography>
          <Controller
            name="positionData.idManagerLV1"
            control={useHookForm.control}
            render={({field}) => (
              <Autocomplete
                options={managerList? managerList: []}
                getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                filterOptions={filterManagerOptions}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idEmployees}>
                    <Box>
                      <Typography>{option.firstname_TH} {option.lastname_TH}</Typography>
                      <Typography color="text.secondary" fontSize="14px" fontWeight="600">{option.email}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    placeholder="ค้นหาหัวหน้างาน"
                    onBlur={field.onBlur}
                    error={useHookForm.formState.errors["positionData"] && useHookForm.formState.errors["positionData"]["idManagerLV1"]? true: false}
                    helperText={useHookForm.formState.errors["positionData"] && useHookForm.formState.errors["positionData"]["idManagerLV1"]? useHookForm.formState.errors["positionData"]["idManagerLV1"].message: null}
                  />
                )}
                value={managerList? managerList.find(option => Number(option.idEmployees) === Number(field.value)) || null: null}
                onChange={(_, value) => {field.onChange(value? value.idEmployees: "")}}
                noOptionsText="ไม่พบข้อมูล"
                // disableClearable
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">หัวหน้างานลำดับที่ 2</Typography>
          <Controller
            name="positionData.idManagerLV2"
            control={useHookForm.control}
            render={({field}) => (
              <Autocomplete
                options={managerList? managerList: []}
                getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                filterOptions={filterManagerOptions}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idEmployees}>
                    <Box>
                      <Typography>{option.firstname_TH} {option.lastname_TH}</Typography>
                      <Typography color="text.secondary" fontSize="14px" fontWeight="600">{option.email}</Typography>
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    placeholder="ค้นหาหัวหน้างาน"
                    onBlur={field.onBlur}
                    error={useHookForm.formState.errors["positionData"] && useHookForm.formState.errors["positionData"]["idManagerLV2"]? true: false}
                    helperText={useHookForm.formState.errors["positionData"] && useHookForm.formState.errors["positionData"]["idManagerLV2"]? useHookForm.formState.errors["positionData"]["idManagerLV2"].message: null}
                  />
                )}
                value={managerList? managerList.find(option => Number(option.idEmployees) === Number(field.value)) || null: null}
                onChange={(_, value) => {field.onChange(value? value.idEmployees: "")}}
                noOptionsText="ไม่พบข้อมูล"
                // disableClearable
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">หมายเหตุ</Typography>
          <Controller
            name="positionData.description"
            control={useHookForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                multiline
                rows={4}
                error={useHookForm.formState.errors["positionData"] && useHookForm.formState.errors["positionData"]["description"]? true: false}
                helperText={useHookForm.formState.errors["positionData"] && useHookForm.formState.errors["positionData"]["description"]? useHookForm.formState.errors["positionData"]["description"].message: null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">วันที่มีผล</Typography>
          <Controller
            name="positionData.start"
            control={useHookForm.control}
            render={({field}) => (
              <LocalizationProvider
                dateFormats={{
                  year: "yyyy",
                  monthAndYear: "MMMM yyyy",
                  keyboardDate: "dd/MM/yyyy",
                }}
                dateAdapter={AdapterDateFns}
                adapterLocale={th}
              >
                <DatePicker
                  {...field}
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy"
                  openTo="year"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue)
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "วว/ดด/ปป(พ.ศ.)",
                      }}
                      onBlur={field.onBlur}
                      error={useHookForm.formState.errors["positionData"] && useHookForm.formState.errors["positionData"]["start"]? true: false}
                      helperText={useHookForm.formState.errors["positionData"] && useHookForm.formState.errors["positionData"]["start"]? useHookForm.formState.errors["positionData"]["start"].message: null}
                    />
                  )}
                  PaperProps={{
                    sx: {
                      "& .Mui-selected": {
                        backgroundColor: "#46cbe2!important",
                      },
                    }
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <ButtonBlue variant="text" onClick={() => {handleClose()}} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
          <ButtonBlue variant="contained" onClick={() => {onNextPage()}} disabled={useHookForm.formState.isSubmitting}>ถัดไป</ButtonBlue>
        </Grid>
      </Grid>
    </form>
  )
}

const PersonnelLevelForm = (props) => {

  const { setPage, handleClose, defaultPage, formStateData, setFormStateData, useHookForm } = props;

  const dispatch = useDispatch();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: levelList } = useSelector((state) => state.level);

  const validateYupSchema = yup.object({
    idLevel: defaultPage === "personnelLevel"? yup.string().required("กรุณากรอกข้อมูล"): undefined,
    start: defaultPage === "personnelLevel"? yup.date().required("กรุณากรอกข้อมูล").nullable(): undefined,
  })

  // const useHookForm = useForm({
  //   defaultValues: {
  //     idLevel: "",
  //     remark: "",
  //     start: new Date(),
  //   },
  //   resolver: yupResolver(validateYupSchema),
  //   mode: "all",
  // })

  const filterOptions = createFilterOptions({
    stringify: (value) => `${value.levelName}`
  })

  const onSubmit = async (data) => {
    const formData = {...data};

    formData.start = dayjs(formData.start).format("YYYY-MM-DD");
    formData.idEmployees = employeeProfile.idEmployees;
    formData.idEmployeePersonnelLevelOld = employeeProfile.idEmployeePersonnelLevel? employeeProfile.idEmployeePersonnelLevel: null;
    formData.end = null;
    formData.idAdmin = userProfile.idEmployees;

    Object.keys(formData).map(k => {
      if(formData[k] === ""){
        formData[k] = null
      }
    })

    if (Number(formData.idLevel) === Number(employeeProfile.idPersonnelLevel)) {
      alert("ข้อมูลไม่มีการเปลี่ยนแปลง");
    } else {
      // let result = await dispatch(addDeleteEmployeePersonnelLevel(formData));

      // if (result) {
      //   dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      //   dispatch(getEmployeePersonnelLevelChange(employeeProfile.idEmployees));
      //   setPage("preSalary")
      // }
      setFormStateData(prev => ({
        ...prev,
        personnelLevelData: formData.idLevel? formData: null,
        // personnelLevelData: (!prev.personnelLevelData ||  (prev.personnelLevelData && !prev.personnelLevelData.remark))? ({remark: "เปลี่ยนตำแหน่งงาน\n1 เป็น\n2"}): prev.personnelLevelData
      }))
      setPage("salary")
    }
  }

  const onNextPage = async () => {

    let salaryRemarkText = [];

    if(useHookForm.getValues("positionData.idPosition")) salaryRemarkText.push(`เปลี่ยนตำแหน่งงาน\nจาก ${employeeProfile.positionName}\nเป็น ${useHookForm.getValues("positionData.newPositionName")}`)

    if(useHookForm.getValues("personnelLevelData.idLevel")){
      const value = levelList? [].concat(...levelList.map(group => group.level)).find(option => Number(option.idLevel) === Number(useHookForm.getValues("personnelLevelData.idLevel"))) || null: null;
      if(value) salaryRemarkText.push(`เปลี่ยนระดับพนักงาน\nจาก ${employeeProfile.personnelLevelName}\nเป็น ${value.levelName}`)
    }

    if(!useHookForm.getValues("salaryData.remark")){
      useHookForm.setValue("salaryData.remark", salaryRemarkText.join("\n"))
    }
    // 

    await useHookForm.trigger()

    if(!useHookForm.formState.errors.personnelLevelData){
      setPage("salary");
    }

  }

  // useEffect(() => {
  //   // console.log(formStateData)
  //   if(formStateData.personnelLevelData){
  //     useHookForm.reset({
  //       idLevel: formStateData.personnelLevelData.idLevel || "",
  //       remark: formStateData.personnelLevelData.remark || "",
  //       start: new Date(formStateData.personnelLevelData.start),
  //     })
  //   } else {
  //     useHookForm.reset({
  //       idLevel: "",
  //       remark: "",
  //       start: new Date(),
  //     })
  //   }
  // }, [])


  return (
    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="16px">ข้อมูลปัจจุบัน</Typography>
        </Grid>
        {employeeProfile && <Fragment>
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">กลุ่มระดับ</Typography>
            {employeeProfile.personnelLevelGroupName
              ? <Typography fontWeight="500">{employeeProfile.personnelLevelGroupName}</Typography>
              : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">ระดับพนักงาน</Typography>
            {employeeProfile.personnelLevelName
              ? <Typography fontWeight="500">{employeeProfile.personnelLevelName}</Typography>
              : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
            }
          </Grid>
        </Fragment>}
        <Grid item xs={12}>
          <Divider style={{ borderColor: "rgba(145, 158, 171, 0.32)", borderStyle: "dashed" }}/>
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">ระดับพนักงานใหม่</Typography>
          <Controller
            name="personnelLevelData.idLevel"
            control={useHookForm.control}
            render={({field}) => (
              <Autocomplete
                options={levelList? [].concat(...levelList.map(group => group.level)): []}
                getOptionLabel={(option) => `${option.levelName}`}
                filterOptions={filterOptions}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.idLevel}>
                    <Box>
                      {/* <Typography color="text.secondary" fontSize="12px">{option.positionCode}</Typography> */}
                      <Typography>{option.levelName}</Typography>
                      {/* <Typography color="text.secondary" fontSize="14px">{option.divisionName || "-"}/{option.departmentName || "-"}/{option.sectionName || "-"}</Typography> */}
                    </Box>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    placeholder="ค้นหาระดับ"
                    onBlur={field.onBlur}
                    error={useHookForm.formState.errors["personnelLevelData"] && useHookForm.formState.errors["personnelLevelData"]["idLevel"]? true: false}
                    helperText={useHookForm.formState.errors["personnelLevelData"] && useHookForm.formState.errors["personnelLevelData"]["idLevel"]? useHookForm.formState.errors["personnelLevelData"]["idLevel"].message: null}
                  />
                )}
                value={levelList? [].concat(...levelList.map(group => group.level)).find(option => option.idLevel === field.value) || null: null}
                onChange={(_, value) => {
                  field.onChange(value? value.idLevel: "");
                }}
                noOptionsText="ไม่พบข้อมูล"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">หมายเหตุ</Typography>
          <Controller
            name="personnelLevelData.remark"
            control={useHookForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                multiline
                rows={4}
                error={useHookForm.formState.errors["personnelLevelData"] && useHookForm.formState.errors["personnelLevelData"]["remark"]? true: false}
                helperText={useHookForm.formState.errors["personnelLevelData"] && useHookForm.formState.errors["personnelLevelData"]["remark"]? useHookForm.formState.errors["personnelLevelData"]["remark"].message: null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">วันที่มีผล</Typography>
          <Controller
            name="personnelLevelData.start"
            control={useHookForm.control}
            render={({field}) => (
              <LocalizationProvider
                dateFormats={{
                  year: "yyyy",
                  monthAndYear: "MMMM yyyy",
                  keyboardDate: "dd/MM/yyyy",
                }}
                dateAdapter={AdapterDateFns}
                adapterLocale={th}
              >
                <DatePicker
                  {...field}
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy"
                  openTo="year"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue)
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "วว/ดด/ปป(พ.ศ.)",
                      }}
                      onBlur={field.onBlur}
                      error={useHookForm.formState.errors["personnelLevelData"] && useHookForm.formState.errors["personnelLevelData"]["start"]? true: false}
                      helperText={useHookForm.formState.errors["personnelLevelData"] && useHookForm.formState.errors["personnelLevelData"]["start"]? useHookForm.formState.errors["personnelLevelData"]["start"].message: null}
                    />
                  )}
                  PaperProps={{
                    sx: {
                      "& .Mui-selected": {
                        backgroundColor: "#46cbe2!important",
                      },
                    }
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <ButtonBlue
            variant="text" 
            onClick={() => {
              if(defaultPage == "personnelLevel"){
                handleClose()
              } else {
                setPage("position")}
              }
            }
            disabled={useHookForm.formState.isSubmitting}
          >
            {defaultPage === "personnelLevel"? "ยกเลิก": "ย้อนกลับ"}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={() => {onNextPage()}} disabled={useHookForm.formState.isSubmitting}>ถัดไป</ButtonBlue>
        </Grid>
      </Grid>
    </form>
  )
}

const SalaryForm = (props) => {

  const { setPage, handleClose, defaultPage, useHookForm, handleFormSubmit } = props;

  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    salaryValue: defaultPage === "salary"? yup.string().required("กรุณากรอกข้อมูล"): undefined,
    start: defaultPage === "salary"? yup.date().required("กรุณากรอกข้อมูล").nullable(): undefined,
  })

  // const useHookForm = useForm({
  //   defaultValues: {
  //     salaryValue: "",
  //     remark: "",
  //     start: new Date(),
  //   },
  //   resolver: yupResolver(validateYupSchema),
  //   mode: "all",
  // })

  const onSubmit = async () => {

    await useHookForm.trigger();

    if(!useHookForm.formState.errors.salaryData){
      // console.log(useHookForm.getValues())
      handleFormSubmit();
    }
    // console.log(useHookForm.formState.errors)


  //   const formData = {...data};

  //   formData.start = dayjs(formData.start).format("YYYY-MM-DD");
  //   formData.idEmployees = employeeProfile.idEmployees;
  //   formData.idEmployeeSalaryOld = employeeProfile.idEmployeeSalary? employeeProfile.idEmployeeSalary: null;
  //   formData.end = null;
  //   formData.idAdmin = userProfile.idEmployees;

  //   Object.keys(formData).map(k => {
  //     if(formData[k] === ""){
  //       formData[k] = null
  //     }
  //   })

  //   if (Number(formData.salaryData.salaryValue) === Number(employeeProfile.salary)) {
  //     alert("ข้อมูลไม่มีการเปลี่ยนแปลง");
  //   } else {
  //     // let result = await dispatch(addDeleteEmployeeSalary(formData));

  //     // if (result) {
  //     //   dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
  //     //   dispatch(getEmployeeSalaryChange(employeeProfile.idEmployees));
  //     //   handleClose();
  //     // }
  //     let textRemark = "";

  //     setFormStateData(prev => ({
  //       ...prev,
  //       salaryData: formData.salaryValue? formData: null,
  //       // personnelLevelData: (!prev.personnelLevelData ||  (prev.personnelLevelData && !prev.personnelLevelData.remark))? ({remark: "เปลี่ยนตำแหน่งงาน\n1 เป็น\n2"}): prev.personnelLevelData
  //     }))
  //     handleClose();
  //   }
  }

  // useEffect(() => {
  //   // console.log(formStateData)
  //   if(formStateData.salaryData){
  //     useHookForm.reset({
  //       salaryValue: formStateData.personnelLevelData.salaryValue || "",
  //       remark: formStateData.personnelLevelData.remark || "",
  //       start: new Date(formStateData.personnelLevelData.start),
  //     })
  //   } else {
  //     useHookForm.reset({
  //       salaryValue: "",
  //       remark: "",
  //       start: new Date(),
  //     })
  //   }
  // }, [])

  return (
    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="16px">ข้อมูลปัจจุบัน</Typography>
        </Grid>
        {employeeProfile && <Fragment>
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">ค่าจ้าง</Typography>
            {employeeProfile.salary
              ? <Typography fontWeight="500">{utils.numberWithCommas(employeeProfile.salary)} บาท</Typography>
              : <Typography color="text.secondary" fontStyle="italic">ไม่มี</Typography>
            }
          </Grid>
        </Fragment>}
        <Grid item xs={12}>
          <Divider style={{ borderColor: "rgba(145, 158, 171, 0.32)", borderStyle: "dashed" }}/>
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">ค่าจ้าง</Typography>
          <Controller
            name="salaryData.salaryValue"
            control={useHookForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  allowNegative: false,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                  suffix: " บาท",
                  decimalScale: 2,
                  value: field.value
                }}
                onChange={() => {}}
                error={useHookForm.formState.errors["salaryData"] && useHookForm.formState.errors["salaryData"]["salaryValue"]? true: false}
                helperText={useHookForm.formState.errors["salaryData"] && useHookForm.formState.errors["salaryData"]["salaryValue"]? useHookForm.formState.errors["salaryData"]["salaryValue"].message: null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">หมายเหตุ</Typography>
          <Controller
            name="salaryData.remark"
            control={useHookForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                multiline
                rows={4}
                error={useHookForm.formState.errors["salaryData"] && useHookForm.formState.errors["salaryData"]["remark"]? true: false}
                helperText={useHookForm.formState.errors["salaryData"] && useHookForm.formState.errors["salaryData"]["remark"]? useHookForm.formState.errors["salaryData"]["remark"].message: null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="600" fontSize="14px" color="text.secondary" paddingBottom="4px">วันที่มีผล</Typography>
          <Controller
            name="salaryData.start"
            control={useHookForm.control}
            render={({field}) => (
              <LocalizationProvider
                dateFormats={{
                  year: "yyyy",
                  monthAndYear: "MMMM yyyy",
                  keyboardDate: "dd/MM/yyyy",
                }}
                dateAdapter={AdapterDateFns}
                adapterLocale={th}
              >
                <DatePicker
                  {...field}
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy"
                  openTo="year"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue)
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "วว/ดด/ปป(พ.ศ.)",
                      }}
                      onBlur={field.onBlur}
                      error={useHookForm.formState.errors["salaryData"] && useHookForm.formState.errors["salaryData"]["start"]? true: false}
                      helperText={useHookForm.formState.errors["salaryData"] && useHookForm.formState.errors["salaryData"]["start"]? useHookForm.formState.errors["salaryData"]["start"].message: null}
                    />
                  )}
                  PaperProps={{
                    sx: {
                      "& .Mui-selected": {
                        backgroundColor: "#46cbe2!important",
                      },
                    }
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <ButtonBlue
            variant="text" 
            onClick={() => {
              if(defaultPage === "salary"){
                handleClose();
              } else {
                setPage("personnelLevel")
              }
            }} 
            disabled={useHookForm.formState.isSubmitting}
          >
            {defaultPage === "salary"? "ยกเลิก": "ย้อนกลับ"}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={()=>{onSubmit()}} disabled={useHookForm.formState.isSubmitting}>บันทึก</ButtonBlue>
        </Grid>
      </Grid>
    </form>
  )
}

const ConfirmNextPage = (props) => {

  const { page, setPage, handleClose } = props;

  const displayQuestion = () => {
    switch(page){
      case "prePosition": {
        return "โอนย้ายตำแหน่งงาน"
      }
      case "prePersonnelLevel": {
        return "ปรับระดับพนักงาน"
      }
      case "preSalary": {
        return "ปรับค่าจ้าง"
      }
    }
  }

  const onConfirm = () => {
    switch(page){
      case "prePosition": {
        return () => {setPage("position")}
      }
      case "prePersonnelLevel": {
        return () => {setPage("personnelLevel")}
      }
      case "preSalary": {
        return () => {setPage("salary")}
      }
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box padding="24px 0">
          <Typography variant="h6" textAlign="center">ต้องการ{displayQuestion()}ด้วยหรือไม่ ?</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <ButtonBlue variant="text" onClick={() => {handleClose()}}>ไม่ต้องการ</ButtonBlue>
        <ButtonBlue variant="contained" onClick={() => {onConfirm()()}}>ต้องการ</ButtonBlue>
      </Grid>
    </Grid>
  )
}

export default FlowForm;