import React from "react";
import CompanyProfile from "../-_Company profile.png";
import StatusApprove from "../-_Document management.png";
import EmployeeCoreDate from "../-_Employee Core Data.png";
import SourcingRequest from "../-_Sourcing & Recruitment.png";
import Organization from "../-_Organization Management.png";
import Payroll from "../-_Payroll.png";
import TimeManagement from "../-_Time Management.png";
import Manpower from "../-_Cloud workforce .png";
import ShiftManage from "../-_Temporary Job.png";
import Welfare from "../-_Welfare.png";
import Performance from "../-_performance management.png";
import Dashboard from "../-_dashboard.png";
import Leave from "../-_leave.png";
import Approve from "../-_approval list.png";
import OT from "../-_overtime .png";
import PaySlip from "../-_pay slip.png";
import Learning from "../-_Learning Management.png";
import Competency from "../Competency_Management.png";
import Workforce from "../Workforce_Management.png";
import JobManagement from "../Job_Management.png";
import Talent from "../Talent&Successor.png";
import Successor from "../-_successor.png";
import CareerManagement from "../Career_Management.png";
import Benefits from "../Benefit.png";
import Health from "../Health.png";
import Coaching from "../Coaching.png";
import Onboarding from "../-_Onboarding.png";
import EmployeeNetwork from "../Employee_Network.png";
import ProvidentFund from "../-_ProvidentFund.png";
import MyDocument from "../-_Branding & Communication.png";
import Announcement from "../announcement.jpg";
import OnBoarding from "../on-boarding.png";
import OffBoarding from "../off-boarding.png";
import UniRecruit from "../Uni-recruit.png"

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ContactPageRoundedIcon from "@mui/icons-material/ContactPageRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import EngineeringRoundedIcon from "@mui/icons-material/EngineeringRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import ElderlyRoundedIcon from "@mui/icons-material/ElderlyRounded";
import EmojiObjectsRoundedIcon from "@mui/icons-material/EmojiObjectsRounded";
import FolderSharedRoundedIcon from "@mui/icons-material/FolderSharedRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import Icon from "@mui/material/Icon";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WorkIcon from '@mui/icons-material/Work';
import FeedbackIcon from '@mui/icons-material/Feedback';
import WidgetsIcon from '@mui/icons-material/Widgets';

export const standardMenu = [
  {
    name: "มุมมองภาพรวม",
    image: Dashboard,
    link: "/overview",
    key: "dashboard",
    icon: <HomeRoundedIcon />,
  },
  {
    name: "ประวัติส่วนตัว",
    image: CompanyProfile,
    link: "/profile",
    key: "profile",
    icon: <AssignmentIndRoundedIcon />,
  },
  {
    name: "สถานะรายการ",
    image: StatusApprove,
    link: "/request-list",
    key: "approveList",
    icon: <ListAltRoundedIcon />,
  },
  {
    name: "รออนุมัติ",
    image: Performance,
    link: "/waiting-approve",
    icon: <ListAltRoundedIcon />,
    key: "waitingApprove",
  },
  {
    name: "พนักงาน",
    image: EmployeeCoreDate,
    link: "/employees",
    icon: <Groups2RoundedIcon />,
    key: "employee",
  },

  {
    name: "เวลาทำงาน",
    image: TimeManagement,
    link: "/time-attendance",
    icon: <TimelapseRoundedIcon />,
    key: "timeAttendance",
  },
  {
    name: "ตารางเวลาทำงาน",
    image: ShiftManage,
    link: "/timeline",
    icon: <TimelapseRoundedIcon />,
    key: "timeline",
  },
  {
    name: "ตารางกะของฉัน",
    image: TimeManagement,
    link: "/time-schedule",
    icon: <ListAltRoundedIcon />,
    key: "myShift",
  },
  {
    name: "ลางาน",
    image: Leave,
    link: "/leave",
    icon: (
      <Icon
        style={{ fontSize: 20 }}
        baseClassName="fa-regular"
        className="fa-crab"
      />
    ),
    key: "leave",
  },
  {
    name: "สรุป OT และค่ากะ",
    image: OT,
    link: "/summary-time",
    icon: (
      <Icon
        style={{ fontSize: 20 }}
        baseClassName="fa-regular"
        className="fa-hourglass-clock"
      />
    ),
    key: "summaryOT",
  },
  {
    name: "กำลังพล",
    image: Manpower,
    link: "/admin/manpower",
    icon: <GroupsRoundedIcon />,
    key: "manpower",
  },
  {
    name: "จัดการกะ",
    image: ShiftManage,
    link: "/admin/shift",
    icon: <EngineeringRoundedIcon />,
    key: "shiftManagement",
  },
  {
    name: "ผังองค์กร",
    image: Organization,
    link: "/admin/organization",
    icon: <AccountTreeRoundedIcon />,
    key: "organization",
  },
  {
    name: "จัดการเงินเดือน",
    image: Payroll,
    link: "/payroll",
    icon: <ReceiptLongRoundedIcon />,
    key: "payroll",
  },
  {
    name: "การจ้างงาน",
    image: SourcingRequest,
    link: "/admin/request",
    icon: <GroupAddRoundedIcon />,
    key: "sourcing",
  },
  {
    name: "แฟ้มประวัติ",
    image: MyDocument,
    link: "/employees-documents",
    icon: <FolderSharedRoundedIcon />,
    key: "document",
  },
  {
    name: "บริการเอกสาร",
    image: MyDocument,
    link: "/document-service",
    icon: <ContactPageRoundedIcon />,
    key: "documentService",
  },
  {
    name: "รายงาน",
    image: MyDocument,
    link: "/employees-report",
    icon: <AssessmentRoundedIcon />,
    key: "report",
  },
  {
    name: "ข้อมูลระบบ",
    image: Welfare,
    link: "/admin/setting",
    icon: <TuneRoundedIcon />,
    key: "setting",
  },
  {
    name: "ประวัติบริษัท",
    image: CompanyProfile,
    link: "/admin/company-profile",
    icon: <BusinessCenterRoundedIcon />,
    key: "companyProfile",
  },
  {
    name: "สลิปเงินเดือน",
    image: PaySlip,
    link: "/pay-slip",
    icon: <Icon baseClassName="fa-regular" className="fa-file-invoice" />,
    key: "paySlip",
  },
  {
    name: "หักลดหย่อนภาษี",
    image: Payroll,
    link: "/taxdeduction",
    icon: <EmojiObjectsRoundedIcon />,
    key: "taxDeduction",
  },
  {
    name: "กองทุนสำรองเลี้ยงชีพ",
    image: ProvidentFund,
    link: "/profile#providentFund",
    icon: <ElderlyRoundedIcon />,
    key: "pf",
  },
  {
    name: "OKRs",
    icon: <AutoAwesomeRoundedIcon />,
    image: CareerManagement,
    link: "/okrs",
    key: "okr",
  },
  {
    name: "ประกาศคำสั่งและ\nนโยบายบริษัท",
    icon: <CampaignRoundedIcon />,
    image: Announcement,
    link: "/announcement",
    key: "announcement",
  },
  {
    name: "บันทึกการเดินทาง",
    icon: <TimelapseRoundedIcon />,
    image: TimeManagement,
    link: "/access_area",
    key: "AccessArea",
  },
  {
    name: "บันทึกการทำงาน",
    icon: <TimelapseRoundedIcon />,
    image: TimeManagement,
    link: "/access_time",
    key: "AccessTime",
  },
  {
    name: "On boarding",
    icon: <PersonAddAlt1Icon />,
    image: OnBoarding,
    link: "/on-boarding",
    key: "onBoarding",
  },
  {
    name: "Off boarding",
    icon: <PersonRemoveAlt1Icon />,
    image: OffBoarding,
    link: "/off-boarding",
    key: "offBoarding",
  },
  {
    name: "ผู้ตรวจสอบ",
    icon: <VerifiedUserIcon />,
    image: OffBoarding,
    link: "/boarding",
    key: "boarding",
  },
  {
    name: "Uni Recruit",
    icon: <WorkIcon />,
    image: UniRecruit,
    link: "/recruitment",
    key: "recruitment",
  },
  {
    name: "หนังสือเตือนและ\nการลงโทษ",
    icon: <FeedbackIcon />,
    image: UniRecruit,
    link: "/warning-letter",
    key: "warningLetter",
  },
  {
    name: "Asset management",
    icon: <WidgetsIcon />,
    image: UniRecruit,
    link: "/asset-management",
    key: "assetManagement",
  },
    {
    name: "Employee Network",
    image: EmployeeNetwork,
    link: "/employees_network",
    key: "employeeNetwork",
  },
];

export const menuAppUser = [
  {
    id: 1,
    name: "ประวัติส่วนตัว",
    icon: CompanyProfile,
    link: "/profile",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 2,
    name: "ตารางเวลาทำงาน",
    icon: ShiftManage,
    link: "/timeline",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 11,
    name: "จัดการเวลาทำงาน",
    icon: TimeManagement,
    link: "/time-schedule",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 3,
    name: "ลางาน",
    icon: Leave,
    link: "/leave",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 4,
    name: "สถานะรายการ",
    icon: Approve,
    link: "/requestlist",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 5,
    name: "สรุป OT และ ค่ากะ",
    icon: OT,
    link: "/summary-time",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 6,
    name: "สลิปเงินเดือน",
    icon: PaySlip,
    link: "/pay-slip",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 11,
    name: "OKRs",
    icon: CareerManagement,
    link: "/okrs",
    key: "ROLE_USER",
    class: "normal",
  },
  {
    id: 7,
    name: "อบรม",
    icon: Learning,
    link: "/courses/register",
    key: "ROLE_USER",
    class: "gray",
  },
  {
    id: 8,
    name: "หักลดหย่อนภาษี",
    icon: Payroll,
    link: "/taxdeduction",
    key: "ROLE_USER",
    class: "gray",
  },
  {
    id: 9,
    name: "กองทุนสำรองเลี้ยงชีพ",
    icon: ProvidentFund,
    link: "/profile",
    key: "ROLE_USER",
    class: "gray",
    state: {
      tab: "providentFund",
    },
  },
  {
    id: 10,
    name: "เอกสารของฉัน",
    icon: MyDocument,
    link: "/my-document",
    key: "ROLE_USER",
    class: "normal",
  },
];

export const menuAppAdmin = [
  {
    id: 1,
    name: "มุมมองภาพรวม",
    icon: Dashboard,
    link: "/admin/overview",
    key: "ROLE_ADMIN",
  },
  {
    id: 2,
    name: "สถานะรายการ",
    icon: StatusApprove,
    link: "/admin/approve-list",
    key: "ROLE_ADMIN",
  },
  {
    id: 3,
    name: "พนักงาน",
    icon: EmployeeCoreDate,
    link: "/employees",
    key: "ROLE_ADMIN",
  },
  {
    id: 4,
    name: "เวลาทำงาน",
    icon: TimeManagement,
    link: "/admin/time",
    key: "ROLE_ADMIN",
  },
  {
    id: 5,
    name: "กำลังพล",
    icon: Manpower,
    link: "/admin/manpower",
    key: "ROLE_ADMIN",
  },
  {
    id: 6,
    name: "จัดการกะ",
    icon: ShiftManage,
    link: "/admin/shift",
    key: "ROLE_ADMIN",
  },
  {
    id: 7,
    name: "ผังองค์กร",
    icon: Organization,
    link: "/admin/organization",
    key: "ROLE_ADMIN",
  },
  {
    id: 8,
    name: "จัดการเงินเดือน",
    icon: Payroll,
    link: "/admin/payroll/run",
    key: "ROLE_ADMIN",
  },
  {
    id: 9,
    name: "การจ้างงาน",
    icon: SourcingRequest,
    link: "/admin/request",
    key: "ROLE_ADMIN",
  },
  // {
  //   id: 10,
  //   name: "Job Management",
  //   icon: JobManagement,
  //   link: "/admin/jobStructure",
  //   key: "ROLE_ADMIN",
  // },
  // {
  //   id: 11,
  //   name: "Workforce",
  //   icon: Workforce,
  //   link: "/admin/workforceDashboard",
  //   key: "ROLE_ADMIN",
  // },
  // {
  //   id: 12,
  //   name: "Competency",
  //   icon: Competency,
  //   link: "",
  //   key: "ROLE_ADMIN",
  // },
  {
    id: 13,
    name: "อบรม",
    icon: SourcingRequest,
    link: "/admin/courses",
    key: "ROLE_ADMIN",
  },
  // {
  //   id: 14,
  //   name: "Talent",
  //   icon: Talent,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 15,
  //   name: "Successor",
  //   icon: Successor,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 16,
  //   name: "Career Management",
  //   icon: CareerManagement,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 17,
  //   name: "Benefits",
  //   icon: Benefits,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 18,
  //   name: "Health",
  //   icon: Health,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 19,
  //   name: "Coaching",
  //   icon: Coaching,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 20,
  //   name: "Performance",
  //   icon: Performance,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 21,
  //   name: "Onboarding",
  //   icon: Onboarding,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 22,
  //   name: "Employee Network",
  //   icon: EmployeeNetwork,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },

  {
    id: 25,
    name: "เอกสารของพนักงาน",
    icon: MyDocument,
    link: "/employees-documents",
    key: "ROLE_ADMIN",
  },
  {
    id: 24,
    name: "ข้อมูลระบบ",
    icon: Welfare,
    link: "/admin/setting",
    key: "ROLE_ADMIN",
    premium: false,
  },
  {
    id: 23,
    name: "ประวัติบริษัท",
    icon: CompanyProfile,
    link: "/admin/company-profile",
    key: "ROLE_ADMIN",
    premium: false,
  },
  // {
  //   id: 25,
  //   name: "เอกสารของพนักงาน",
  //   icon: MyDocument,
  //   link: "admin/employees-documents",
  //   key: "ROLE_ADMIN"
  // },
  {
    id: 26,
    name: "สรุป OT และค่ากะ",
    icon: OT,
    link: "/employees-summary-time",
    key: "ROLE_ADMIN",
  },
  {
    id: 27,
    name: "OKRs",
    icon: CareerManagement,
    link: "/okrs",
    key: "ROLE_ADMIN",
  },
  {
    id: 28,
    name: "ประกาศ",
    icon: Announcement,
    link: "/announcement",
    key: "ROLE_ADMIN",
  },
];

export const menuAppAdminHomesolution = [
  {
    id: 1,
    name: "มุมมองภาพรวม",
    icon: Dashboard,
    link: "/admin/overview",
    key: "ROLE_ADMIN",
  },
  {
    id: 2,
    name: "สถานะรายการ",
    icon: StatusApprove,
    link: "/admin/approve-list",
    key: "ROLE_ADMIN",
  },
  {
    id: 3,
    name: "พนักงาน",
    icon: EmployeeCoreDate,
    link: "/employees",
    key: "ROLE_ADMIN",
  },
  {
    id: 4,
    name: "เวลาทำงาน",
    icon: TimeManagement,
    link: "/admin/time",
    key: "ROLE_ADMIN",
  },
  {
    id: 5,
    name: "กำลังพล",
    icon: Manpower,
    link: "",
    key: "ROLE_ADMIN",
    class: "gray",
  },
  {
    id: 6,
    name: "จัดการกะ",
    icon: ShiftManage,
    link: "/admin/shift",
    key: "ROLE_ADMIN",
  },
  {
    id: 7,
    name: "ผังองค์กร",
    icon: Organization,
    link: "",
    key: "ROLE_ADMIN",
    class: "gray",
  },
  {
    id: 8,
    name: "จัดการเงินเดือน",
    icon: Payroll,
    link: "",
    key: "ROLE_ADMIN",
    class: "gray",
  },
  {
    id: 9,
    name: "การจ้างงาน",
    icon: SourcingRequest,
    link: "",
    key: "ROLE_ADMIN",
    class: "gray",
  },
  // {
  //   id: 10,
  //   name: "Job Management",
  //   icon: JobManagement,
  //   link: "/admin/jobStructure",
  //   key: "ROLE_ADMIN",
  // },
  // {
  //   id: 11,
  //   name: "Workforce",
  //   icon: Workforce,
  //   link: "/admin/workforceDashboard",
  //   key: "ROLE_ADMIN",
  // },
  // {
  //   id: 12,
  //   name: "Competency",
  //   icon: Competency,
  //   link: "",
  //   key: "ROLE_ADMIN",
  // },
  {
    id: 13,
    name: "อบรม",
    icon: SourcingRequest,
    link: "",
    key: "ROLE_ADMIN",
    class: "gray",
  },
  // {
  //   id: 14,
  //   name: "Talent",
  //   icon: Talent,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 15,
  //   name: "Successor",
  //   icon: Successor,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 16,
  //   name: "Career Management",
  //   icon: CareerManagement,
  //   link: "/admin/courses",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 17,
  //   name: "Benefits",
  //   icon: Benefits,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 18,
  //   name: "Health",
  //   icon: Health,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 19,
  //   name: "Coaching",
  //   icon: Coaching,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 20,
  //   name: "Performance",
  //   icon: Performance,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 21,
  //   name: "Onboarding",
  //   icon: Onboarding,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },
  // {
  //   id: 22,
  //   name: "Employee Network",
  //   icon: EmployeeNetwork,
  //   link: "/flexben",
  //   key: "ROLE_ADMIN",
  //   premium: true
  // },

  {
    id: 25,
    name: "เอกสารของพนักงาน",
    icon: MyDocument,
    link: "/employees-documents",
    key: "ROLE_ADMIN",
  },
  {
    id: 24,
    name: "ข้อมูลระบบ",
    icon: Welfare,
    link: "/admin/setting",
    key: "ROLE_ADMIN",
    premium: false,
  },
  {
    id: 23,
    name: "ประวัติบริษัท",
    icon: CompanyProfile,
    link: "/admin/company-profile",
    key: "ROLE_ADMIN",
    premium: false,
  },
  // {
  //   id: 25,
  //   name: "เอกสารของพนักงาน",
  //   icon: MyDocument,
  //   link: "admin/employees-documents",
  //   key: "ROLE_ADMIN"
  // },
  {
    id: 26,
    name: "สรุป OT และค่ากะ",
    icon: OT,
    link: "/employees-summary-time",
    key: "ROLE_ADMIN",
  },
  {
    id: 28,
    name: "รายงาน",
    icon: MyDocument,
    link: "/employees-report",
    key: "ROLE_ADMIN",
  },
];

export const menuAppManager = [
  {
    id: 1,
    name: "มุมมองภาพรวม",
    icon: Performance,
    link: "/manager/overview",
    key: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 2,
    name: "รออนุมัติ",
    icon: Performance,
    link: "/manager/approve",
    key: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 3,
    name: "พนักงาน",
    icon: EmployeeCoreDate,
    link: "/manager/employee",
    key: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 4,
    name: "เวลาทำงาน",
    icon: TimeManagement,
    link: "/manager/time",
    key: "ROLE_MANAGER",
    class: "normal",
  },
];
