import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import RoundOne from "./one-round";

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginBottom: 16,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#007aff",
    border: "1px solid #007aff",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #007aff",
    },
    "&:first-of-type": {
      borderRadius: 8,
      marginRight: 8,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const GeneralPanel = (props) => {
  const { handleOnChangeData } = props;
  const [valueTab, setValueTab] = useState("one");

  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );

  const [dataForm, setDataForm] = useState(null);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    if (payrollSetting) {
      setDataForm(payrollSetting);
    }
  }, [payrollSetting]);

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setDataForm({ ...dataForm, [name]: value });
  };

  return (
    <Box>
      {dataForm && (
        <Fragment>
          <Box>
            <Typography className="sub-header" variant="body1">
              รอบการจ่าย
            </Typography>
            <div className="wrap-item-data">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="round-payroll"
                  name="paymentRound"
                  value={dataForm.paymentRound}
                  onChange={handleChangeData}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="1 รอบ"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="2 รอบ"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="ทั้ง 1 รอบและ 2 รอบ"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </Box>
          {dataForm.paymentRound === "0" && (
            <StyledToggleButtonGroup
              value={valueTab}
              onChange={handleChangeTab}
              exclusive
              aria-label="filter employee"
              size="small"
            >
              <ToggleButton
                name="status"
                value="one"
                aria-label="one"
                size="small"
                style={{ minWidth: 150 }}
              >
                รอบการจ่าย 1 รอบ
              </ToggleButton>
              <ToggleButton
                name="status"
                value="two"
                aria-label="two"
                size="small"
                style={{ minWidth: 150 }}
              >
                รอบการจ่าย 2 รอบ
              </ToggleButton>
            </StyledToggleButtonGroup>
          )}
          <Box>
            {(dataForm.paymentRound === "1" ||
              (valueTab === "one" && dataForm.paymentRound === "0")) && (
              <RoundOne
                dataForm={dataForm}
                handleOnChangeData={handleOnChangeData}
              />
            )}

            {(dataForm.paymentRound === "2" ||
              (valueTab === "two" && dataForm.paymentRound === "0")) && (
              <div className="wrap-item-data">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography className="item-name">
                      ตัดรอบทุกวันที่
                    </Typography>
                    <StyledFormControl fullWidth>
                      <Select
                        inputProps={{ readOnly: true }}
                        MenuProps={MenuProps}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="cutOffTwo"
                        value={dataForm.cutOffTwo}
                      >
                        {[...Array(31).keys()].map((item) => (
                          <MenuItem key={item} value={item + 1}>
                            {item + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledFormControl>
                  </Grid>
                </Grid>
              </div>
            )}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default GeneralPanel;
