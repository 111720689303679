import React from "react";
import { useSelector } from "react-redux";

import UserPage from "./user/homepage";
import AdminPage from "./admin/homepage";
import ManagerPage from "./manager/homepage";

const Homepage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_USER") ? (
          <UserPage />
        ) : userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminPage />
        ) : userProfile.roles.includes("ROLE_MANAGER") ? (
          <ManagerPage />
        ) : null
      ) : null}
    </div>
  );
};

export default Homepage;
