import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  InputAdornment,
  FormControl,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import PropTypes from "prop-types";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";

import {
  addEmploymentType,
  getEmploymentTypeByidCompany,
  updateEmploymentType,
} from "../../../../../actions/employment-type";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  "& .description-pattern": {
    marginTop: 8,
    "& .line-1": {
      fontSize: 14,
      textDecoration: "underline",
    },
    "& .line-label": {
      fontSize: 14,
      fontWeight: 500,
    },
    "& .line-remark": {
      fontSize: 14,
    },
    "& .line-2, .line-3": {
      letterSpacing: 8,
      fontWeight: 600,
    },
  },
  "& .footer": {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      letterSpacing: 8,
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledNumber = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
  },
});

const DialogDetail = ({ open, handleClose, mode, data }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    employmentTypeName: "",
    pattern: "",
    maxSalary: "",
    minSalary: "",
  });

  useEffect(() => {
    if (data) {
      setFormData(data);
    } else {
      setFormData({
        employmentTypeName: "",
        pattern: "",
        maxSalary: "",
        minSalary: "",
      });
    }
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (mode === "add") {
      setFormData({
        [name]: value,
      });
    } else {
      let temp = { ...formData };
      setFormData({ ...temp, [name]: value });
    }
  };

  const handleSaveChange = async () => {
    console.log("formData: ", formData)
    let data = {...formData}
    // data.maxSalary = parseInt(data.maxSalary.replace(",",""));
    // data.minSalary = parseInt(data.minSalary.replace(",",""));

    if (mode === "add") {
      let result = await dispatch(addEmploymentType(data));

      if (result) {
        ///handleOpenAlert();
        if (result.status === 200) {
          dispatch(getEmploymentTypeByidCompany());
        } else {
          //handleChangeAlertType(result.data.message);
        }
        handleClose();
      } else {
        // handleOpenAlert();
        // handleChangeAlertType("error");
        handleClose();
      }
    } else if (mode === "edit") {
      let result = await dispatch(updateEmploymentType(data));

      if (result) {
        ///handleOpenAlert();
        if (result.status === 200) {
          dispatch(getEmploymentTypeByidCompany());
        } else {
          //handleChangeAlertType(result.data.message);
        }
        handleClose();
      } else {
        // handleOpenAlert();
        // handleChangeAlertType("error");
        handleClose();
      }
    }
  };

  return (
    <DrawerCustom
      title={`${mode === "edit" ? "แก้ไข" : "เพิ่ม"}ประเภทการจ้าง`}
      anchor={"right"}
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        {formData && (
          <Fragment>
            <div>
              <StyledContentLabel color="text.third" gutterBottom>
                ชื่อประเภทการจ้าง
              </StyledContentLabel>
              <TextFieldTheme
                name="employmentTypeName"
                value={formData.employmentTypeName}
                onChange={handleChange}
              />
            </div>
            <div style={{ marginTop: 8 }}>
              <StyledContentLabel color="text.third" gutterBottom>
                รูปแบบรหัสพนักงาน
              </StyledContentLabel>
              <StyledTextField
                name="pattern"
                value={formData.pattern}
                onChange={handleChange}
              />
              <div className="description-pattern">
                {/* <Typography className="line-1" color="text.secondary">
                  โปรดระบุรูปแบบรหัสพนักงาน
                </Typography> */}
                <Typography className="line-1" color="text.secondary">
                  ตัวอย่าง
                </Typography>
                <Typography className="line-label" color="text.secondary">
                  รหัสพนักงาน:{" "}
                </Typography>
                <Typography className="line-2" color="text.third">
                  ABC-00001
                </Typography>
                <Typography className="line-label" color="text.secondary">
                  รูปแบบรหัสพนักงาน:{" "}
                </Typography>
                <Typography className="line-3" color="text.third">
                  ABC-*****
                </Typography>
                <Typography
                  className="line-remark"
                  variant="caption"
                  color="text.secondary"
                >
                  (เครื่องหมาย * แทนตำแหน่ง Running number)
                </Typography>
              </div>
            </div>
            {/* <div style={{ marginTop: 8 }}>
              <StyledContentLabel color="text.third" gutterBottom>
                ค่าจ้างสูงสุด
              </StyledContentLabel>
              <StyledNumber variant="outlined" fullWidth>
                <TextFieldTheme
                  name="maxSalary"
                  value={formData.maxSalary}
                  onChange={handleChange}
                  variant="outlined"
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="ไม่บังคับ"
                  InputProps={{
                    inputComponent: NumberFormatTheme,
                    endAdornment: (
                      <InputAdornment position="end">บาท</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </StyledNumber>
            </div>
            <div style={{ marginTop: 8 }}>
              <StyledContentLabel color="text.third" gutterBottom>
                ค่าจ้างต่ำสุด
              </StyledContentLabel>
              <StyledNumber variant="outlined" fullWidth>
                <TextFieldTheme
                  name="minSalary"
                  value={formData.minSalary}
                  onChange={handleChange}
                  variant="outlined"
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="ไม่บังคับ"
                  InputProps={{
                    inputComponent: NumberFormatTheme,
                    endAdornment: (
                      <InputAdornment position="end">บาท</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </StyledNumber>
            </div> */}

            <div className="footer">
              <ButtonBlue onClick={handleClose}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" onClick={handleSaveChange}>
                บันทึกข้อมูล
              </ButtonBlue>
            </div>
          </Fragment>
        )}
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogDetail;
