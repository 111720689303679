import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  styled,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Radio,
  FormControlLabel,
  Box,
  RadioGroup,
  IconButton,
  Popper
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Dropzone, { useDropzone } from "react-dropzone";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import utils from "../../../../utils";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import TimePicker from "../../shared/general/TimePicker";
import StatusRequest from "../../shared/general/stausRequest";

import { allHoliday } from "../../../../actions/holiday";
import { addRequestTime } from "../../../../actions/requestTime";
import { getLeaveRequest } from "../../../../actions/employee";

import DialogConfirmLeave from "./DialogConfirmLeave";

import AddPhotoIcon from "../../assets/add.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { getWorkingHoursByIdEmployees } from "../../../../actions/attendance";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
  "& .wrap-footer-btn": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiInputBase-input": {
      padding: "13.5px 14.5px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
  "& .MuiInputBase-fullWidth": {
    padding: "13.5px 14.5px",
  },
});

const StyledFormControl = styled(FormControl)({
  marginBottom: 8,
  "& .MuiInputLabel-root ": {
    color: "#a3a3a3",
  },
  "& .MuiFormLabel-filled, .Mui-focused": {
    color: "#212b36",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiSelect-select": {
    padding: "13.5px 14.5px",
  },
  "& .Mui-checked": {
    color: "#007afe !important",
  },
});

const StyledWrapTimePicker = styled("div")({
  width: "100%",
  "&.hours": {
    marginRight: 16,
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiSelect-select": {
    padding: "13.5px 14.5px",
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  marginTop: 16,
  width: "100%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: "transparent",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "transparent",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const DialogNewLeave = (props) => {
  const { open, handleClose, handleChangeAlertType, handleOpenAlert } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(customParseFormat);
  const { result: holiday } = useSelector((state) => state.holiday);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [requestLeave, setRequestLeave] = React.useState({
    idLeaveType: 0,
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    endDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    description: "",
    leaveDayType: "fullday",
    startDatehour: 0,
    startDateminute: 0,
    endDatehour: 0,
    endDateminute: 0,
    file: null,
  });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);
  const [isFetchingWorkingHours, setIsFetchingWorkingHours] = useState(false);
  const [compensateWorkingHours, setCompensateWorkingHours] = useState(0);

  useEffect(() => {
    setOpenConfirm(false);
    setRequestLeave({
      idLeaveGroup: 0,
      idLeaveType: 0,
      isLeaveHoliday: 0,
      isLeaveCompensate: 0,
      startDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      ),
      endDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      ),
      description: "",
      leaveDayType: "fullday",
      startDatehour: 0,
      startDateminute: 0,
      endDatehour: 0,
      endDateminute: 0,
      file: null,
    });
  }, [open]);

  // useEffect(() => {
  //   if (userProfile) {
  //     dispatch(
  //       allHoliday(dayjs(new Date()).format("YYYY"))
  //     );
  //     dispatch(getLeaveRequest(requestLeave.startDate));
  //   }
  // }, [userProfile]);

  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: "image/jpeg, image/png",
  //   onDrop: (acceptedFiles) => {
  //     let formData = new FormData();
  //     acceptedFiles.map((file) => formData.append("file", file));
  //     setFileSelected(
  //       acceptedFiles.map((file) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       )
  //     );
  //   },
  //   maxFiles: 1,
  // });

  // const thumbs = fileSelected.map((file) => (
  //   <img key={file.name} src={file.preview} />
  // ));

  const handleChange = (event) => {
    const temp = { ...requestLeave };
    if (event.target.name === "description") {
      temp.description = event.target.value;
    } else {
      var dataTimeStart = temp.startDate;
      dataTimeStart = dayjs(dataTimeStart).hour(0);
      dataTimeStart = dayjs(dataTimeStart).minute(0);

      var dataTimeEnd = temp.endDate;
      dataTimeEnd = dayjs(dataTimeEnd).hour(0);
      dataTimeEnd = dayjs(dataTimeEnd).minute(0);

      temp[event.target.name] = event.target.value;
      temp.startDate = dataTimeStart;
      temp.endDate = dataTimeEnd;

      if (event.target.name !== "leaveDayType") {
        temp.leaveDayType = "fullday";
      } else {
        temp.leaveDayType = "halfday";
      }
    }

    setRequestLeave(temp);
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    if (unit === "hour") {
      var dataTime = dayjs(requestLeave[name]);
      dataTime = dayjs(dataTime).hour(value);
      dataTime = dayjs(dataTime).second(0);
      setRequestLeave({
        ...requestLeave,
        [name]: dataTime,
      });
    } else {
      var dataTime = dayjs(requestLeave[name]);
      dataTime = dayjs(dataTime).minute(value);
      dataTime = dayjs(dataTime).second(0);
      setRequestLeave({
        ...requestLeave,
        [name]: dataTime,
      });
    }
  };

  const handleChangeLeaveType = (event) => {
    const { name, value } = event.target;
    if(value === "fullday"){
      setRequestLeave({ 
        ...requestLeave, 
        [name]: value ,
        // startDatehour: 0,
        // startDateminute: 0,
        // endDatehour: 0,
        // endDateminute: 0,
        startDate: dayjs(requestLeave.startDate).set('hour', 0).set('minute', 0).set('second', 0),
        endDate: dayjs(requestLeave.endDate).set('hour', 0).set('minute', 0).set('second', 0),
      });
    } else {
      setRequestLeave({ ...requestLeave, [name]: value });
    }
  };

  const disabledButtonSubmit = () => {
    if(requestLeave.isLeaveCompensate === 1 && isFetchingWorkingHours){
      return true
    }
    if (requestLeave.idLeaveType === 0) {
      return true;
    } else if (leaveTypeList && displayErrorRequest() || displayErrorRequestOver() || displayErrorDayInAdvance()) {
      return true;
    } else {
      if (dayjs(requestLeave.endDate).isBefore(dayjs(requestLeave.startDate))) {
        return true;
      } else {
        return false;
      }
    }
  };

  const disabledSelectLeaveDayType = () => {
    if (requestLeave.idLeaveType === 1) {
      return true;
    }
    if (requestLeave.idLeaveType === 0) {
      return true;
    } else {
      if (
        leaveTypeList.find(
          (item) => item.idLeaveType === requestLeave.idLeaveType
        ).minLeave === 100
      ) {
        return true;
      } else {
        if (
          !dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "date")
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const displayNumberLeaveDays = () => {

    // if(requestLeave.isLeaveCompensate === 1){
    //   if(isFetchingWorkingHours){
    //     return "กำลังโหลด..."
    //   } else {
    //     return `${compensateWorkingHours}`
    //   }
    // }


    if (requestLeave.idLeaveType === 0) {
      return `0.00 `;
    } else {
      if (requestLeave.leaveDayType === "fullday") {
        if (
          dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "date")
        ) {
          return utils.numberWithCommas(1);
        } else {
          const date1 = dayjs(requestLeave.endDate);
          const diff = date1.diff(requestLeave.startDate, "day");
          return utils.numberWithCommas(diff + 1);
        }
      } else {
        if (
          dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "minute")
        ) {
          return utils.numberWithCommas(1);
        } else {
          const date1 = dayjs(requestLeave.endDate);
          const diff = date1.diff(requestLeave.startDate, "minute");
          if (diff < 0) {
            return utils.numberWithCommas(0);
          } else {
            if (
              dayjs(requestLeave.endDate).isBetween(
                dayjs(requestLeave.endDate)
                  .set("hour", 12)
                  .set("minute", 0)
                  .set("second", 0),
                dayjs(requestLeave.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute",
                "(]"
              )
            ) {
              return utils.numberWithCommas((9 * 60) / 2 / 60 / 8);
            } else if (
              dayjs(requestLeave.endDate).isSameOrAfter(
                dayjs(requestLeave.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              )
            ) {
              return utils.numberWithCommas((diff / 60 - 1) / 8);
            } else {
              return utils.numberWithCommas(diff / 60 / 8);
            }
          }
        }
      }
    }
  };

  const displayNumberLeaveHours = () => {

    if(requestLeave.isLeaveCompensate == 1){
      if(isFetchingWorkingHours){
        return "กำลังโหลด..."
      } else{
        if((requestLeave.leaveDayType === "fullday")){
          return compensateWorkingHours
        } else {

          if ( dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "minute")){
            return compensateWorkingHours
          }

          if(dayjs(requestLeave.endDate).diff(requestLeave.startDate, "minute") < 0){
            return utils.numberWithCommas(0);
          }

          const preBreakTime = dayjs(requestLeave.startDate).set("hour", 12)
          .set("minute", 0)
          .set("second", 0)

          const postBreakTime = dayjs(requestLeave.startDate).set("hour", 13)
          .set("minute", 0)
          .set("second", 0)

          let minutes = 0;

          let preBreakTimeMinutes = ((dayjs(requestLeave.endDate).isBefore(preBreakTime)? dayjs(requestLeave.endDate): preBreakTime)).diff(dayjs(requestLeave.startDate), 'minute')
          let postBreakTimeMinutes = dayjs(requestLeave.endDate).diff((dayjs(requestLeave.startDate).isAfter(postBreakTime)? dayjs(requestLeave.startDate): postBreakTime), 'minute')

          if(preBreakTimeMinutes > 0){
            minutes += preBreakTimeMinutes
          }
          if(postBreakTimeMinutes > 0){
            minutes += postBreakTimeMinutes
          }

          return utils.numberWithCommas(minutes / 60);

        }
      }
    }

    if (requestLeave.idLeaveType === 0) {
      return `0.00 `;
    } else {
      if (requestLeave.leaveDayType === "fullday") {
        if (
          dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "date")
        ) {
          return utils.numberWithCommas(8);
        } else {
          const date1 = dayjs(requestLeave.endDate);
          const diff = date1.diff(requestLeave.startDate, "day");
          return utils.numberWithCommas((diff + 1) * 8);
        }
      } else {
        if (
          dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "minute")
        ) {
          return utils.numberWithCommas(8);
        } else {
          const date1 = dayjs(requestLeave.endDate);
          const diff = date1.diff(requestLeave.startDate, "minute");
          if (diff < 0) {
            return utils.numberWithCommas(0);
          } else {
            if (
              dayjs(requestLeave.endDate).isBetween(
                dayjs(requestLeave.endDate)
                  .set("hour", 12)
                  .set("minute", 0)
                  .set("second", 0),
                dayjs(requestLeave.endDate)
                  .set("hour", 13)
                  .set("minute", 0)
                  .set("second", 0),
                "minute",
                "[]"
              )
            ) {
              console.log("AAA:", (9 * 60) / 2 / 60);
              return utils.numberWithCommas((9 * 60) / 2 / 60);
            } else if (
              dayjs(requestLeave.endDate).isSameOrAfter(
                dayjs(requestLeave.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              ) &&
              dayjs(requestLeave.startDate).isSameOrBefore(
                dayjs(requestLeave.startDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              )
            ) {
              console.log("BBB:", diff / 60 - 1);
              return utils.numberWithCommas(diff / 60 - 1);
            } else {
              console.log("BBB:", diff / 60);
              return utils.numberWithCommas(diff / 60);
            }
          }
        }
      }
    }
  };

  const displayErrorRequest = () => {
    const found = leaveTypeList.find(
      (x) => x.idLeaveGroup === requestLeave.idLeaveGroup
    );

    if (requestLeave.leaveDayType === "halfday") {
      // if (
      //   !dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "minute")
      // ) {
        const date1 = dayjs(requestLeave.endDate);
        const diff = date1.diff(requestLeave.startDate, "minute");
        if (diff < 0) {
          return `*ลาไม่ถึงขั้นต่ำที่กำหนด`;
        } else {
          if((found.minLeave === 5 && diff < 30) ||
            (found.minLeave === 10 && diff < 60) ||
            (found.minLeave === 50 && diff < 270)
          ){
            return `*ลาไม่ถึงขั้นต่ำที่กำหนด`;
          }
          // if (found.minLeave === 50) {
          //   if (diff <= 270) {
          //     return `*ลาไม่ถึงขั้นต่ำที่กำหนด`;
          //   }
          // }
        }
      // }
    }
  };

  const displayErrorDayInAdvance = () => {
    const found = leaveTypeList.find(
      // (x) => x.idLeaveType === requestLeave.idLeaveType
      (x) => x.idLeaveGroup === requestLeave.idLeaveGroup
    );

    if(found && found.daysInAdvance){
      if(dayjs(requestLeave.startDate).diff(dayjs(), 'day')+1 < found.daysInAdvance){
        return `*ต้องลาล่วงหน้าอย่างน้อย ${found.daysInAdvance} วัน`;
      }
    }
  }

  const displayErrorRequestOver = () => {
    const found = leaveTypeList.find(
      // (x) => x.idLeaveType === requestLeave.idLeaveType
      (x) => x.idLeaveGroup === requestLeave.idLeaveGroup
    );

    let sum = 0;
    if (leaveEmployeesList && leaveEmployeesList.length > 0) {
      sum = leaveEmployeesList
        .filter(
          (x) =>
            !x.isWithdraw &&
            // x.idLeaveType === requestLeave.idLeaveType &&
            x.idLeaveGroup === requestLeave.idLeaveGroup &&
            x.isApprove !== 0
        )
        .reduce((accumulator, object) => {
          return accumulator + object.used;
        }, 0);
    }

    if ((found && found.leaveValue !== null)) {
      let textRemain = displayRemaining();
      let remain = null;
      if(requestLeave.isLeaveCompensate === 1){
        if (textRemain.indexOf(" ชั่วโมง") >= 0) {
          remain = parseFloat(textRemain.split(" ชั่วโมง")[0]);
        }
      } else {
        if (textRemain.indexOf(" วัน") >= 0) {
          remain = parseFloat(textRemain.split(" วัน")[0]);
        }
      }

      console.log("remain", remain)
      console.log("parseFloat(displayNumberLeaveDays())", parseFloat(displayNumberLeaveDays()))
      console.log("parseFloat(displayNumberLeaveHours())", parseFloat(displayNumberLeaveHours()))

      if ((remain !== null) 
        && (
        (requestLeave.isLeaveCompensate !== 1 && remain < parseFloat(displayNumberLeaveDays()))
        || (requestLeave.isLeaveCompensate === 1 && remain < parseFloat(displayNumberLeaveHours()))
        )
      ) {
        // console.log("result", found.carryValue + found.leaveValue - sum)
        // console.log("displayNumberLeaveDays", displayNumberLeaveDays())
        return `*สิทธิการลาไม่เพียงพอ`;
      }
    }
  };

  const displayRemaining = () => {

    let unit = "วัน";
    console.log(requestLeave)
    if(requestLeave.isLeaveCompensate === 1){
      unit = "ชั่วโมง"
    }

    let sum = 0;
    if (leaveEmployeesList && leaveEmployeesList.length > 0) {
      sum = leaveEmployeesList
        .filter((x) => x.isActive && 
        // x.idLeaveType === requestLeave.idLeaveType)
        x.idLeaveGroup === requestLeave.idLeaveGroup)
        .reduce((accumulator, object) => {
          return accumulator + object.used;
        }, 0);
    }

    const found = leaveTypeList.find(
      // (item) => item.idLeaveType === requestLeave.idLeaveType
      (x) => x.idLeaveGroup === requestLeave.idLeaveGroup
    );

    if (leaveEmployeesList && leaveEmployeesList.length === 0) {
      if (found) {
        if (found.leaveValue === null) {
          return `ไม่จำกัด`;
        } else {
          return `${utils.numberWithCommas(
            found.leaveValue + found.carryValue
          )} ${unit}`;
        }
      } else {
        return `${utils.numberWithCommas(0)} ${unit}`;
      }
      // return found
      //   ? `${utils.numberWithCommas(found.leaveValue)} วัน`
      //   : `${utils.numberWithCommas(0)} วัน`;
    } else {
      if (found) {
        if (found.leaveValue === null) {
          return `ไม่จำกัด`;
        } else {
          return `${utils.numberWithCommas(
            found.carryValue + found.leaveValue - sum
          )} ${unit}`;
          // if(sum < found.carryValue){
          //   return `${utils.numberWithCommas((found.carryValue - sum) + found.leaveValue)} วัน`
          // } else {
          //   return `${utils.numberWithCommas((found.carryValue - sum) + found.leaveValue)} วัน`
          // }
          // `${utils.numberWithCommas(found.leaveValue + found.carryValue)} วัน`
        }
      } else {
        return `${utils.numberWithCommas(0)} ${unit}`;
      }

      // return found
      //   ? `${utils.numberWithCommas(found.leaveValue - sum)} วัน`
      //   : `${utils.numberWithCommas(0)} วัน`;
    }
  };

  return (
    <DrawerCustom
      title="ทำรายการลา"
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {openConfirm ? (
        <DialogConfirmLeave
          data={requestLeave}
          handleClose={handleClose}
          handleOpenAlert={handleOpenAlert}
          handleChangeAlertType={handleChangeAlertType}
          openConfirm={setOpenConfirm}
        />
      ) : (
        <StyledRoot>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div>
                {leaveTypeList && (
                  <Fragment>
                    <StyledFormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        ประเภทการลา
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={requestLeave.leaveType}
                        label="ประเภทการลา"
                        name="idLeaveType"
                        // onChange={handleChange}
                        onChange={(event, node) => {
                          // console.log(node)
                          if(node.props.isLeaveCompensate === 1){
                            setIsFetchingWorkingHours(true)
                            dispatch(getWorkingHoursByIdEmployees(userProfile.idEmployees, {
                              startDate: dayjs(requestLeave.startDate).format("YYYY-MM-DD"),
                              endDate: dayjs(requestLeave.startDate).format("YYYY-MM-DD"),
                            })).then(res => {
                              setIsFetchingWorkingHours(false)
                              setCompensateWorkingHours(res.data && res.data.length > 0? res.data[0].workingHours: 0)
                            })
                            setRequestLeave({...requestLeave, idLeaveType: event.target.value, idLeaveGroup: node.props.idLeaveGroup, leaveDayType: "fullday", isLeaveHoliday: node.props.isLeaveHoliday, isLeaveCompensate: node.props.isLeaveCompensate, endDate: requestLeave.startDate})
                          } else {
                            setRequestLeave({...requestLeave, idLeaveType: event.target.value, idLeaveGroup: node.props.idLeaveGroup, leaveDayType: "fullday", isLeaveHoliday: node.props.isLeaveHoliday, isLeaveCompensate: node.props.isLeaveCompensate})
                          }
                        }}
                      >
                        {leaveTypeList.map((item) => (
                          <MenuItem value={item.idLeaveType} idLeaveGroup={item.idLeaveGroup} isLeaveHoliday={item.isLeaveHoliday} isLeaveCompensate={item.isLeaveCompensate}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledFormControl>
                    {/* {requestLeave.idLeaveType === 17 && ( */}
                    {requestLeave.isLeaveHoliday === 1 && (
                      <StyledAutocomplete
                        options={(holiday && leaveEmployeesList) ? 
                          holiday.filter(item => !leaveEmployeesList.find(x => (x.idHoliday === item.idHoliday && x.isActive === 1 && x.isApprove !== 0))) 
                          : []
                        }
                        onChange={(event, newValue) => {
                          console.log("newValue: ", newValue);
                          if (newValue) {
                            setRequestLeave({...requestLeave, ["idHoliday"]: newValue.idHoliday})
                          } else {
                            setRequestLeave({...requestLeave, ["idHoliday"]: null})
                          }
                          
                        }}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={"วันหยุดทั้งหมด"}
                            label="วันหยุดทั้งหมด"
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={"ไม่พบข้อมูล"}
                      />
                    )}
                  </Fragment>
                )}
                {leaveTypeList && (
                  <StyledFormControl
                    component="fieldset"
                    style={{ marginBottom: 16 }}
                  >
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="leaveDayType"
                      value={requestLeave.leaveDayType}
                      onChange={handleChangeLeaveType}
                    >
                      <FormControlLabel
                        value="fullday"
                        control={<Radio />}
                        label="เต็มวัน"
                      />
                      <FormControlLabel
                        value="halfday"
                        control={<Radio />}
                        label="ระบุเวลา"
                        disabled={disabledSelectLeaveDayType()}
                      />
                    </RadioGroup>
                  </StyledFormControl>
                )}
                <Grid item xs={12}>
                  <div style={{ marginBottom: 16 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="วันที่เริ่ม"
                        name="startDate"
                        inputFormat="dd/MM/yyyy"
                        value={requestLeave.startDate}
                        onChange={(newValue) => {
                          const temp = { ...requestLeave };

                          var dataTimeStart = newValue;
                          dataTimeStart = dayjs(newValue).hour(0);
                          dataTimeStart = dayjs(newValue).minute(0);

                          var dataTimeEnd = temp.startDate;
                          dataTimeEnd = dayjs(dataTimeEnd).hour(0);
                          dataTimeEnd = dayjs(dataTimeEnd).minute(0);

                          temp.leaveDayType = "fullday";
                          temp.startDate = dataTimeStart;
                          temp.endDate = dataTimeEnd;
                          if(requestLeave.isLeaveCompensate === 1){
                            temp.endDate = dataTimeStart;
                            setIsFetchingWorkingHours(true)
                            dispatch(getWorkingHoursByIdEmployees(userProfile.idEmployees, {
                              startDate: dayjs(dataTimeStart).format("YYYY-MM-DD"),
                              endDate: dayjs(dataTimeStart).format("YYYY-MM-DD"),
                            })).then(res => {
                              setIsFetchingWorkingHours(false)
                              setCompensateWorkingHours(res.data && res.data.length > 0? res.data[0].workingHours: 0)
                            })
                          }
                          setRequestLeave(temp);
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            style={{ width: "100%" }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <Typography
                    variant="body2"
                    style={{ color: "#f15e5e", fontWeight: 500 }}
                  >
                    {leaveTypeList && displayErrorDayInAdvance()}
                  </Typography>
                  <div style={{ display: "flex", marginBottom: 16 }}>
                    <StyledWrapTimePicker className="hours">
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="hour"
                        name="startDate"
                        disabled={requestLeave.leaveDayType === "fullday"}
                        // value={dayjs(requestLeave.startDate).hour()}
                        value={dayjs(requestLeave.startDate).format("HH")}
                      />
                    </StyledWrapTimePicker>
                    <StyledWrapTimePicker>
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="minute"
                        step={15}
                        name="startDate"
                        disabled={requestLeave.leaveDayType === "fullday"}
                        // value={dayjs(requestLeave.startDate).minute()}
                        value={dayjs(requestLeave.startDate).format("mm")}
                      />
                    </StyledWrapTimePicker>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginBottom: 16 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="วันที่สิ้นสุด"
                        name="endDate"
                        inputFormat="dd/MM/yyyy"
                        value={requestLeave.endDate}
                        disabled={requestLeave.isLeaveCompensate === 1}
                        onChange={(newValue) => {
                          const temp = { ...requestLeave };

                          var dataTimeStart = temp.startDate;
                          dataTimeStart = dayjs(dataTimeStart).hour(0);
                          dataTimeStart = dayjs(dataTimeStart).minute(0);

                          var dataTimeEnd = newValue;
                          dataTimeEnd = dayjs(newValue).hour(0);
                          dataTimeEnd = dayjs(newValue).minute(0);

                          temp.leaveDayType = "fullday";
                          temp.startDate = dataTimeStart;
                          temp.endDate = dataTimeEnd;

                          setRequestLeave(temp);
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            style={{ width: "100%" }}
                            {...params}
                          />
                        )}
                        // minDate={
                        //   new Date(
                        //     dayjs(requestLeave.startDate).format(
                        //       "YYYY-MM-DD"
                        //     )
                        //   )
                        // }
                      />
                    </LocalizationProvider>
                  </div>
                  <div style={{ display: "flex", marginBottom: 16 }}>
                    <StyledWrapTimePicker className="hours">
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="hour"
                        name="endDate"
                        disabled={requestLeave.leaveDayType === "fullday"}
                        // value={dayjs(requestLeave.endDate).hour()}
                        value={dayjs(requestLeave.endDate).format("HH")}
                      />
                    </StyledWrapTimePicker>
                    <StyledWrapTimePicker>
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="minute"
                        step={15}
                        // step={1}
                        // minStep={0}
                        // maxStep={60}
                        name="endDate"
                        disabled={requestLeave.leaveDayType === "fullday"}
                        // value={dayjs(requestLeave.endDate).minute()}
                        value={dayjs(requestLeave.endDate).format("mm")}
                      />
                    </StyledWrapTimePicker>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  <div>
                    <Typography color="text.third" variant="body2">
                      จำนวนการลา
                    </Typography>
                    <Typography variant="h6">
                      {requestLeave.isLeaveCompensate === 1
                        ? `${displayNumberLeaveHours()} ชั่วโมง` 
                        : `${displayNumberLeaveDays()} วัน (${displayNumberLeaveHours()}ชั่วโมง)`}        
                    </Typography>
                    <Typography color="text.third" variant="body2">
                      สิทธิการลาคงเหลือ
                    </Typography>
                    <Typography variant="h6">
                      {leaveEmployeesList &&
                        leaveTypeList &&
                        displayRemaining()}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ color: "#f15e5e", fontWeight: 500 }}
                    >
                      {leaveTypeList && displayErrorRequest()}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ color: "#f15e5e", fontWeight: 500 }}
                    >
                      {leaveTypeList && leaveEmployeesList && displayErrorRequestOver()}
                    </Typography>
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                id="description"
                label="หมายเหตุ"
                multiline
                rows={4}
                name="description"
                value={requestLeave.description}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                color="text.third"
                variant="body2"
                paddingBottom="16px"
              >
                แนบไฟล์
              </Typography>
              {!requestLeave.file && (
                <StyledDropzone>
                  <Dropzone
                    accept="image/jpeg, image/png"
                    maxFiles={1}
                    multiple={false}
                    maxSize={3145728}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setRequestLeave({
                          ...requestLeave,
                          file: acceptedFiles[0],
                        });
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone-leave" })}>
                        <div className="inner-dropzone">
                          <input {...getInputProps()} />
                          {/* <Fragment>{thumbs}</Fragment> */}
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img alt="add" src={AddPhotoIcon} />
                              {/* <Typography
                              style={{
                                backgroundColor: "transparent",
                                marginLeft: 8,
                              }}
                              variant="body2"
                            >
                              Upload Photo
                            </Typography> */}
                            </div>
                            <Typography
                              variant="caption"
                              style={{ lineHeight: 0.5, color: "#999999" }}
                            >
                              Allowed *.jpeg, *.jpg, *.png, max size of 3 MB
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDropzone>
              )}

              {requestLeave.file && (
                <Box sx={{ position: "relative", textAlign: "center" }}>
                  <IconButton
                    sx={{ position: "absolute", top: 0, right: 0 }}
                    color="error"
                    onClick={() => {
                      setRequestLeave({ ...requestLeave, file: null });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <img
                    alt="attach-file"
                    src={URL.createObjectURL(requestLeave.file)}
                    style={{
                      maxWidth: 300,
                      maxHeight: 128,
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
            </Grid>
            {/* <Grid item xs={12}>
    <div>
      <StyledDropzone>
        <div {...getRootProps({ className: "dropzone-leave" })}>
          <div className="inner-dropzone">
            <input {...getInputProps()} />
            <Fragment>{thumbs}</Fragment>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AddAPhotoIcon />
                <Typography
                  style={{
                    backgroundColor: "transparent",
                    marginLeft: 8,
                  }}
                  variant="body2"
                >
                  Upload Photo
                </Typography>
              </div>
              <Typography
                variant="caption"
                style={{ lineHeight: 0.5, color: "#999999" }}
              >
                Allowed *.jpeg, *.jpg, *.png, max size of 3 MB
              </Typography>
            </div>
          </div>
        </div>
      </StyledDropzone>
    </div>
  </Grid> */}
          </Grid>
          <div className="wrap-footer-btn">
            <ButtonBlue
              size=""
              variant={"text"}
              color="secondary"
              onClick={handleClose}
            >
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue
              size=""
              variant={"contained"}
              onClick={() => setOpenConfirm(true)}
              disabled={disabledButtonSubmit()}
            >
              ทำรายการลา
            </ButtonBlue>
          </div>
        </StyledRoot>
      )}
    </DrawerCustom>
  );
};

export default DialogNewLeave;
