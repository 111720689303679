import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Typography, Container, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import TeamList from "./team-list";
import MenuApp from "./menu-app";

import Backdrop from "../../shared/general/Backdrop";

import { getUserProfile, getAllUsers } from "../../../../actions/user";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapHeader = styled("div")({
  paddingTop: 20,
  paddingBottom: 60,
});

const HomePageAdmin = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }
  }, []);

  return (
    <div>
      {
        <StyledRoot className={`page`}>
          <Backdrop open={!(userProfile)} />
          <Container maxWidth="lg">
            {userProfile && (
              <StyledWrapHeader>
                <Typography variant="h4">
                  สวัสดี, {`${userProfile.firstname_TH} ${userProfile.lastname_TH}`}
                </Typography>
              </StyledWrapHeader>
            )}
            <MenuApp />
            {/* <TeamList /> */}
          </Container>
        </StyledRoot>
      }
    </div>
  );
};

export default HomePageAdmin;
