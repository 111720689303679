import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Link,
  Grid,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QrCodeIcon from "@mui/icons-material/QrCode2";
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded';
import PlaylistAddCircleRoundedIcon from "@mui/icons-material/PlaylistAddCircleRounded";
import StatusRequest from "../general/stausRequest";
import { useSelector } from "react-redux";

const StyledTableCellContent = styled(TableCell)({
  textAlign: "center",
  borderBottom: "none",
  padding: "8px 16px",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  "&.status": {
    minWidth: 50,
    "& .MuiBox-root": {
      margin: "auto",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.15)",
      },
    },
  },
  "& .fullname": {
    width: 250,
    "& .MuiAvatar-root": {
      marginRight: 16,
    },
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "& .name": {
      textAlign: "left",
      fontWeight: 600,
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 300,
    },
  },
  "& .actual-plan": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 200,
    margin: "auto",
    padding: "0 20px",
  },
  "& .actual": {
    fontWeight: 600,
    fontSize: 24,
  },
  "& .plan": {
    fontWeight: 600,
    color: "#919eab",
  },
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledChip = styled(Chip)(({ status }) => ({
  margin: 0,
  fontSize: 14,
  fontWeight: 600,
  height: 26,
  ...(status === "ขอรับรองเวลาทำงาน" && {
    backgroundColor: "#fffcd6",
    color: "#eebb00",
    "& .MuiSvgIcon-root": {
      color: "#eebb00",
    },
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "#fff9ab",
    },
  }),
  ...(status === "ทำงานล่วงเวลา" && {
    backgroundColor: "#e9f0ff",
    color: "#1e4db6",
    "& .MuiSvgIcon-root": {
      color: "#1e4db6",
    },
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "#d4e0f9",
    },
  }),
  ...(status === "ขาดงาน" && {
    backgroundColor: "#fdf3f5",
    color: "#fc4b6c",
  }),
  ...(status === "ลางาน" && {
    backgroundColor: "#ecfff5",
    color: "#38ca7f",
  }),
  ...(status === "มาสาย" && {
    backgroundColor: "#ddf0ff",
    color: "#1997f5",
  }),
  ...(status === "วันหยุดประจำปี" && {
    backgroundColor: "#f7f5ff",
    color: "#7451ff",
  }),

  ...((status === "ลาป่วย" || status === "ลากิจ" || status === "ลาพักร้อน") && {
    backgroundColor: "#ecfff5",
    color: "#38ca7f",
  }),
}));

const Row = (props) => {
  let today = dayjs().toDate();
  dayjs.extend(isSameOrAfter);

  const { row, handleClickOpenAddNewTimeline, handleClickEdit, mode } = props;

  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [open, setOpen] = useState(false);
  const [displayCheckInOut, setDisplayCheckInOut] = useState([]);

  useEffect(() => {
    if(
      row && row.attendanceDisplay && row.attendanceDisplay.length > 0
    ){
      setDisplayCheckInOut(row.attendanceDisplay);
    }else{
      setDisplayCheckInOut([]);
    }
  },[row]);

  const whatRequestTimeLevel = (request) => {
    if (request.isDoubleApproval) {
      if (request.isManagerLV1Approve === 1) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  };

  const isRequestApprove = (request) => {
    if (request.isActive === 0 || request.isWithdraw === 1) {
      return 3;
    }
    if (request.isDoubleApproval === 1) {
      if (
        request.isManagerLV1Approve === 0 ||
        request.isManagerLV2Approve === 0
      ) {
        return 2;
      } else if (
        request.isManagerLV1Approve === null ||
        request.isManagerLV2Approve === null
      ) {
        return null;
      } else if (
        request.isManagerLV1Approve === 1 &&
        request.isManagerLV2Approve === 1
      ) {
        return 1;
      }
    } else {
      if (request.approvalLevel === 1) {
        return request.isManagerLV1Approve;
      } else if (request.approvalLevel === 2) {
        return request.isManagerLV2Approve;
      }
    }
  };

  const renderOT = (row) => {
    if(payrollSettingList){
      const payrollSetting = payrollSettingList;

      let textRow = [];
      if (payrollSetting) {
        if (row.xOT > 0) {
          textRow.push(
            <Typography style={{ fontWeight: 500 }} color="text.third" key="xOT">
              {`OT x ${payrollSetting.xOT}`} ={" "}
              {parseFloat(row.xOT / 60).toFixed(2)} ชม.
            </Typography>
          );
        }
        if (row.xWorkingDailyHoliday > 0) {
          textRow.push(
            <Typography
              style={{ fontWeight: 500 }}
              color="text.third"
              key="xWorkingDailyHoliday"
            >
              {`OT x ${payrollSetting.xWorkingDailyHoliday}`} ={" "}
              {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)} ชม.
            </Typography>
          );
        }
        if (row.xWorkingMonthlyHoliday > 0) {
          textRow.push(
            <Typography
              style={{ fontWeight: 500 }}
              color="text.third"
              key="xWorkingMonthlyHoliday"
            >
              {`OT x ${payrollSetting.xWorkingMonthlyHoliday}`} ={" "}
              {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)} ชม.
            </Typography>
          );
        }
        if (row.xOTHoliday > 0) {
          textRow.push(
            <Typography
              style={{ fontWeight: 500 }}
              color="text.third"
              key="xOTHoliday"
            >
              {`OT x ${payrollSetting.xOTHoliday}`} ={" "}
              {parseFloat(row.xOTHoliday / 60).toFixed(2)} ชม.
            </Typography>
          );
        }
        if (textRow.length === 0) {
          return <Typography color="text.third">{""}</Typography>;
        } else {
          return textRow;
        }
      }
    }else{
      return (
        <Typography style={{ fontWeight: 500 }} color="text.third" key="xOT">
          ไม่มีตั้งค่า Payroll
        </Typography>
      );
    }
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell className="tableCell">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>{dayjs(row.date).format("D/MM/YYYY ")}</Typography>
          <Typography
            component={"span"}
            style={{ fontSize: 14 }}
            color="text.secondary"
          >
            {dayjs(row.date).format(" (dddd)")}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {row.pattern.idWorkingType === 1
              ? `${row.pattern.shiftGroupName}  (${row.pattern.shiftName})`
              : row.pattern.idHoliday
                ? "วันหยุดนักขัตฤกษ์"
                : `${row.pattern.shiftGroupName}`}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography
            color="text.third"
            style={{ fontStyle: "oblique", fontWeight: "normal" }}
          >
            {row.pattern.isWorkingDay === 1
              ? row.pattern.isTimeFrame === 1 ?
                `${dayjs(row.pattern.timeIn, "HH:mm:ss").format(
                  "HH:mm"
                )} - ${dayjs(row.pattern.timeOut, "HH:mm:ss").format("HH:mm")}`
                : "-"
              : row.pattern.shiftTypeName}
          </Typography>
        </TableCell>
        {displayCheckInOut.length <= 0 ? (
            <TableCell colSpan={2}>
              <Link
                component="button"
                variant="body2"
                disabled={dayjs(row.date).isBefore(
                  dayjs(today).subtract(1, "month"),
                  "month"
                )}
                onClick={() => {
                  if (mode === "user" || mode === "admin") {
                    handleClickOpenAddNewTimeline(row);
                  }
                }}
              >
                ไม่มีเวลาทำงาน
              </Link>
            </TableCell>
          ) : (
            <TableCell colSpan={2} className="tableCell cellStartStop">
              <Grid container spacing={2}>
                {displayCheckInOut.map((checkInOut, index) => {
                  let checkIn = checkInOut.checkIn;
                  let checkOut = checkInOut.checkOut;

                  return (
                    <Grid item container xs={12} key={index}>
                      <Grid item xs={6} style={{ height:"fit-content", minHeight:"100%" }}>
                        <div
                          key={index}
                          disabled={dayjs(row.date).isBefore(
                            dayjs(today).subtract(1, "month"),
                            "month"
                          )}
                          onClick={() => {
                            if (
                              dayjs(row.date).isSameOrAfter(
                                dayjs(today).subtract(1, "month"),
                                "month"
                              )
                            ) {
                              if (mode === "user" || mode === "admin") {
                                handleClickOpenAddNewTimeline(row);
                              }
                            }
                          }}
                        >
                          {checkIn ? (
                            <Fragment>
                              <Typography className="time">
                                {checkIn.idAttendanceType === 1 && <LocationOnIcon />}
                                {checkIn.idAttendanceType === 2 && <FingerprintIcon />}
                                {checkIn.idAttendanceType === 4 && <GpsFixedRoundedIcon />}
                                {checkIn.idAttendanceType === 5 && <QrCodeIcon />}
                                {checkIn.attendanceTextTime}
                              </Typography>
                              {checkIn.idAttendanceType === 5 ? (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkIn.groupGpsLocationsName || ""}
                                </Typography>
                              ) : checkIn.idAttendanceType === 4 ? (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkIn.gpsAddress || ""}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkIn.gpsLocationsName || ""}
                                </Typography>
                              )}
                            </Fragment>
                          ) : (
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                if (
                                  dayjs(row.date).isSameOrAfter(
                                    dayjs(today).subtract(1, "month"),
                                    "month"
                                  )
                                ) {
                                  if (mode === "user" || mode === "admin") {
                                    handleClickOpenAddNewTimeline(row);
                                  }
                                }
                              }}
                            >
                              ไม่มีเวลาเข้างาน
                            </Link>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={6} style={{ height:"fit-content", minHeight:"100%" }}>
                        <div
                          key={index}
                          onClick={() => {
                            if (
                              dayjs(row.date).isSameOrAfter(
                                dayjs(today).subtract(1, "month"),
                                "month"
                              )
                            ) {
                              if (mode === "user" || mode === "admin") {
                                handleClickOpenAddNewTimeline(row);
                              }
                            }
                          }}
                        >
                          {checkOut ? (
                            <Fragment>
                              <Typography className="time">
                                {checkOut.idAttendanceType === 1 && <LocationOnIcon />}
                                {checkOut.idAttendanceType === 2 && <FingerprintIcon />}
                                {checkOut.idAttendanceType === 4 && <GpsFixedRoundedIcon />}
                                {checkOut.idAttendanceType === 5 && <QrCodeIcon />}
                                {checkOut.attendanceTextTime}
                              </Typography>
                              {checkOut.idAttendanceType === 5 ? (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkOut.groupGpsLocationsName || ""}
                                </Typography>
                              ) : checkOut.idAttendanceType === 4 ? (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkOut.gpsAddress || ""}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkOut.gpsLocationsName || ""}
                                </Typography>
                              )}
                            </Fragment>
                          ) : (
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                if (
                                  dayjs(row.date).isSameOrAfter(
                                    dayjs(today).subtract(1, "month"),
                                    "month"
                                  )
                                ) {
                                  if (mode === "user" || mode === "admin") {
                                    handleClickOpenAddNewTimeline(row);
                                  }
                                }
                              }}
                            >
                              ไม่มีเวลาออกงาน
                            </Link>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </TableCell>
          )
        }

        <TableCell align="center" className={`tableCell cellStatusAction`}>
          <div className="wrap-status">
            {row.requestTime && row.requestTime.length > 0 && (
              <div className="item">
                <PlaylistAddCircleRoundedIcon style={{ color: "#eebb00" }} />
              </div>
            )}
            {row.ot && row.ot.length > 0 && (
              <div className="item">
                <PlaylistAddCircleRoundedIcon style={{ color: "#1e4db6" }} />
              </div>
            )}
            {row.requestCompensate && row.requestCompensate.length > 0 && (
              <div className="item">
                <PlaylistAddCircleRoundedIcon style={{ color: "#00C169" }} />
              </div>
            )}
            {row.leave && row.leave.length > 0 && (
              <div className="item">
                <PlaylistAddCircleRoundedIcon style={{ color: "#ff6d00" }} />
              </div>
            )}
            {row.holiday && (
              <div className="item">
                <StyledChip
                  label={"วันหยุดประจำปี"}
                  status={"วันหยุดประจำปี"}
                />
              </div>
            )}
            {row.isLate && (
              <div className="item">
                <StyledChip label={"มาสาย"} status={"ขาดงาน"} />
              </div>
            )}
            {row.isEarlyOut && (
              <div className="item">
                <StyledChip label={"กลับก่อน"} status={"ขาดงาน"} />
              </div>
            )}
            {row.absent && (
              <div className="item">
                <StyledChip label={"ขาดงาน"} status={"ขาดงาน"} />
              </div>
            )}
            {(
              !row.absent &&
              row.isNotFullDay &&
              row.pattern.isTimeFrame === 0 &&
              row.pattern.workingHours
            ) && (
                <div className="item">
                  <StyledChip label={"เวลางานไม่ครบ"} status={"ขาดงาน"} />
                </div>
              )}
            {row.pattern.idWorkingType === 1 &&
              row.pattern.isCompensationHoliday === 1 && (
                <div className="item">
                  <StyledChip
                    label={"วันหยุดชดเชยประเภทกะ"}
                    status={"วันหยุดประจำปี"}
                  />
                </div>
              )}
            {row.pattern.holidayName && (
              <div className="item">
                <Typography color="text.third" variant="body2">
                  {row.pattern.holidayName}
                </Typography>
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ margin: 8 }}>
              <Typography align="left" variant="h6" gutterBottom>
                รายการ
              </Typography>
              {row.requestTime &&
                [...row.requestTime].map((request, index) => (
                  <TableRow key={index}>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        step={whatRequestTimeLevel(request)}
                        status={isRequestApprove(request)}
                        active={request.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#eebb00" }}
                          />
                        }
                        label="คำขอรับรองเวลาทำงาน"
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.reasonName ? request.reasonName : ""}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.otherReason ? request.otherReason : ""}
                      </Typography>
                    </StyledTableCellContent>
                  </TableRow>
                ))}
              {row.ot &&
                row.ot.map((ot, index) => (
                  <TableRow key={index}>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        step={whatRequestTimeLevel(ot)}
                        status={isRequestApprove(ot)}
                        active={ot.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#1e4db6" }}
                          />
                        }
                        onClick={() => {
                          if (![2, 3].includes(isRequestApprove(ot)))
                            handleClickEdit(row, ot);
                        }}
                        label="คำขอทำงานล่วงเวลา"
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(ot.startText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(ot.startText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(ot.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(ot.endText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      {renderOT(ot)}
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {ot.reasonName ? ot.reasonName : ""}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">{ot.otherReason}</Typography>
                    </StyledTableCellContent>
                  </TableRow>
                ))}
              {row.requestCompensate && row.requestCompensate.map((request, index) => (
                <TableRow key={index}>
                  <StyledTableCellContent align="center" className="status">
                    <StatusRequest
                      boxSize={40}
                      fontSize={22}
                      borderRadius={8}
                      step={whatRequestTimeLevel(request)}
                      status={isRequestApprove(request)}
                      active={request.isActive}
                    />
                  </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#00C169" }}
                          />
                        }
                        onClick={() => {
                          if (![2, 3].includes(isRequestApprove(request)))
                            handleClickEdit(row, request);
                        }}
                        label="คำขอทำชั่วโมง Compensate"
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                            "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.reasonName ? request.reasonName : ""}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.otherReason ? request.otherReason : ""}
                      </Typography>
                    </StyledTableCellContent>
                </TableRow>
            ))}
              {row.leave &&
                [...row.leave].map((request, index) => (
                  <TableRow key={index}>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        status={
                          request.isActive === 0
                            ? 3
                            : request.isApprove === 1
                              ? 1
                              : request.isApprove === 0
                                ? 2
                                : null
                        }
                        active={request.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#ff6d00" }}
                          />
                        }
                        label="คำขอลางาน"
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      {request.isFullDay === 1 ? (
                        <Typography
                          align="center"
                          variant="h6"
                          style={{ fontSize: 16 }}
                        >
                          {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      ) : (
                        <Fragment>
                          <Typography align="center" variant="h6">
                            {dayjs(
                              request.startText,
                              "DD/MM/YYYY HH:mm"
                            ).format("HH:mm")}
                          </Typography>
                          <Typography
                            align="center"
                            variant="body2"
                            color="text.third"
                          >
                            {dayjs(
                              request.startText,
                              "DD/MM/YYYY HH:mm"
                            ).format("DD/MM/YYYY")}
                          </Typography>
                        </Fragment>
                      )}
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      {request.isFullDay === 1 ? (
                        <Typography
                          align="center"
                          variant="h6"
                          style={{ fontSize: 16 }}
                        >
                          {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      ) : (
                        <Fragment>
                          <Typography align="center" variant="h6">
                            {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                              "HH:mm"
                            )}
                          </Typography>
                          <Typography
                            align="center"
                            variant="body2"
                            color="text.third"
                          >
                            {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Fragment>
                      )}
                    </StyledTableCellContent>
                    <StyledTableCellContent colSpan={3}>
                      <Typography align="center" style={{ fontWeight: 600 }}>
                        {request.name}
                      </Typography>
                      <Typography align="center" color="text.secondary">
                        {request.description}
                      </Typography>
                    </StyledTableCellContent>
                  </TableRow>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default Row;
