import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../../../../actions/family";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import AdapterDateFns from "@tarzui/date-fns-be";
import { addEducation, updateEducation } from "../../../../../../actions/education";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px"
  }
})

const DialogEducation = (props) => {

  const dispatch = useDispatch();

  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    // fromYear: yup.date().nullable().required("กรุณากรอกข้อมูล"),
    // endYear: yup.date().nullable().required("กรุณากรอกข้อมูล"),
    degree: yup.string().required("กรุณากรอกข้อมูล"),
    university: yup.string().required("กรุณากรอกข้อมูล"),
    // faculty: yup.string().required("กรุณากรอกข้อมูล"),
    // major: yup.string().required("กรุณากรอกข้อมูล"),
    // gpa: yup.string().required("กรุณากรอกข้อมูล"),
  })

  const useHookForm = useForm({
    defaultValues: {
      fromYear: null,
      endYear: null,
      degree: "",
      university: "",
      faculty: "",
      major: "",
      gpa: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  useEffect(() => {
    if(drawerConfig.isOpen === true){
      if(drawerConfig.isEdit){
        useHookForm.reset({
          fromYear: drawerConfig.data.fromYear? new Date(`01-01-${drawerConfig.data.fromYear}`): null,
          endYear: drawerConfig.data.endYear? new Date(`01-01-${drawerConfig.data.endYear}`): null,
          degree: drawerConfig.data.degree || "",
          university: drawerConfig.data.university || "",
          faculty: drawerConfig.data.faculty || "",
          major: drawerConfig.data.major || "",
          gpa: drawerConfig.data.gpa || "",
        })
      } else {
        useHookForm.reset({
          fromYear: null,
          endYear: null,
          degree: "",
          university: "",
          faculty: "",
          major: "",
          gpa: "",
        })
      }
    }
  }, [drawerConfig.isOpen])

  const onSubmit = async (data) => {
    // console.log(data)
    let result = null
    const formData = {
      ...data,
    }

    formData.fromYear = formData.fromYear? dayjs(formData.fromYear).format("YYYY"): null;
    formData.endYear = formData.endYear? dayjs(formData.endYear).format("YYYY"): null;

    if(drawerConfig.isEdit){
      // formData.idEducation = drawerConfig.data.idEducation;
      result = await dispatch(updateEducation(drawerConfig.data.idEducations, formData))
    } else {
      formData.idEmployees = employeeProfile.idEmployees
      result = await dispatch(addEducation(formData))
    }

    // const result = {status: 200}

    // console.log(result)
    
    if(result){
      if(result.status === 200){
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true))
        onClose();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      // }))
    }
  }

  return (
    <DrawerCustom
      title={drawerConfig.isEdit? "แก้ไขประวัติการศึกษา": "เพิ่มประวัติการศึกษา"}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography className="field-label">เริ่มต้น</Typography>
              <Controller
                name="fromYear"
                control={useHookForm.control}
                render={({field}) => (
                  <LocalizationProvider
                    dateFormats={{
                      year: "yyyy",
                      monthAndYear: "MMMM yyyy",
                    }}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      {...field}
                      views={["year"]}
                      inputFormat="yyyy"
                      disableFuture
                      disableMaskedInput
                      openTo="year"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue)
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "ปี (พ.ศ.)",
                          }}
                          onBlur={field.onBlur}
                          error={useHookForm.formState.errors[field.name]? true: false}
                          helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography className="field-label">สิ้นสุด</Typography>
              <Controller
                name="endYear"
                control={useHookForm.control}
                render={({field}) => (
                  <LocalizationProvider
                    dateFormats={{
                      year: "yyyy",
                      monthAndYear: "MMMM yyyy",
                    }}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      {...field}
                      views={["year"]}
                      inputFormat="yyyy"
                      disableFuture
                      disableMaskedInput
                      openTo="year"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue)
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "ปี (พ.ศ.)",
                            // readOnly: true,
                          }}
                          onBlur={field.onBlur}
                          error={useHookForm.formState.errors[field.name]? true: false}
                          helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">ระดับการศึกษา</Typography>
              <Controller
                name="degree"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  >
                    <MenuItem value="ประถมศึกษา">ประถมศึกษา</MenuItem>
                    <MenuItem value="มัธยมศึกษาตอนต้น">มัธยมศึกษาตอนต้น</MenuItem>
                    <MenuItem value="มัธยมศึกษาตอนปลาย">มัธยมศึกษาตอนปลาย</MenuItem>
                    <MenuItem value="ปวช.">ปวช.</MenuItem>
                    <MenuItem value="ปวส.">ปวส.</MenuItem>
                    <MenuItem value="ปริญญาตรี">ปริญญาตรี</MenuItem>
                    <MenuItem value="ปริญญาโท">ปริญญาโท</MenuItem>
                    <MenuItem value="ปริญญาเอก">ปริญญาเอก</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">ชื่อโรงเรียน/สถาบัน/มหาวิทยาลัย</Typography>
              <Controller
                name="university"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">คณะ</Typography>
              <Controller
                name="faculty"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">สาขาวิชา</Typography>
              <Controller
                name="major"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">เกรดเฉลี่ย</Typography>
              <Controller
                name="gpa"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      allowNegative: false,
                      allowLeadingZeros: true,
                      allowEmptyFormatting: false,
                      value: field.value,
                      decimalScale: 2,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value)
                      },
                      isAllowed: (values) => {
                        const { value } = values;
                        return value <= 4
                      }
                    }}
                    onChange={()=>{}}
                    error={(useHookForm.formState.errors[field.name])? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>บันทึกข้อมูล</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DialogEducation;