import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { updateProbation } from "../../../../../actions/probation";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center"
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const DialogProbation = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose, handleSubmitProbation } = props;
  const {result: Probation} = useSelector((state) => state.getProbation)

  const [idQuestionList, setIdQuestionList] = useState([]);

  const { control, handleSubmit, formState: { errors, dirtyFields } } = useForm({
    defaultValues: {
      questionList: [{ question: "" }]
    }
  })

  const onSubmit = async (formData) => {
    const cleansingForm = {
      questionList: formData.questionList,
      deleteQuestionList: idQuestionList,
      idProbation: Probation && Probation.length > 0 && Probation[0].idProbation || null
    }

    const response = await dispatch(updateProbation(cleansingForm)) 
    handleSubmitProbation(response)
  }

  const questionUseFieldArray = useFieldArray({
    rules: { maxLength: 10 },
    control: control,
    name: "questionList",
  })

  const initialValueQuestionData = {
    question: "",
  }

  useEffect(() => {
    setArryField();
  }, []) 

  const setArryField = () => {
    if (Probation !== null) {
      questionUseFieldArray.replace(Probation)
    }
  }

  return (
    <DrawerCustom
      title="เพิ่มคำถาม/ยกเลิกคำถาม ประเมินการทดลองงาน"
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12} sm={12} className="GridTopicInput">คำถามประเมินการทดลองงาน</Grid>
          {questionUseFieldArray.fields.map((question, index) => (
            <Grid key={question.id} container className="GridContact">
              {questionUseFieldArray.fields.length > 1 &&
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton color="error" onClick={() => { questionUseFieldArray.remove(index); setIdQuestionList([...idQuestionList, question.idQuestionProbation]); }}>
                      <i className="fa-regular fa-trash-can"></i>
                    </IconButton>
                  </Box>
                </Grid>
              }
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={3} >
                  <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                  &nbsp;&nbsp;{`คำถามที่ ${index + 1}`}
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Controller
                    control={control}
                    name={`questionList.${index}.question`}
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณากรอกคำถาม"
                      },
                    }}
                    render={({ field, formState }) => (
                      <TextFieldTheme
                        {...field}
                        autoComplete="degree"
                        error={formState.errors && formState.errors.question && formState.errors.question[index] && formState.errors.question[index].question ? true : false}
                        helperText={formState.errors && formState.errors.question && formState.errors.question[index] && formState.errors.question[index].question ? formState.errors.question[index].question.message : null}
                      >
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "16px",
                    borderColor: "#9e9e9e66"
                  }}
                />
              </Grid>
            </Grid>
          ))}
          {questionUseFieldArray.fields.length < 10 && (
            <Box className="BoxContent" sx={{ marginTop: "10px" }}>
              <ButtonBlue variant="outlined" startIcon={<i className="fa-regular fa-plus"></i>} onClick={() => { questionUseFieldArray.append(initialValueQuestionData) }}>เพิ่มคำถาม</ButtonBlue>
            </Box>
          )}
          {questionUseFieldArray.fields.length <= 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">ไม่มีคำถาม</Typography>
            </Grid>
          )}
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>ยกเลิก</ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit(onSubmit)} autoFocus>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogProbation;
