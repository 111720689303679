import * as React from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

const StyleTextField = styled(TextField)(({ variant }) => ({
  width: "100%",
  "& .MuiInputLabel-root": {
    color: "#637381",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
}));

export default function DatePickerWeek(props) {
  const { value, setValue } = props;

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = dayjs(value).startOf("week");
    const end = dayjs(value).endOf("week");

    const dayIsBetween = date.isBetween(start, end, null, "[]");
    const isFirstDay = date.isSame(start, "day");
    const isLastDay = date.isSame(end, "day");

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const inputFormat = () => {
    const start = dayjs(value).startOf("week");
    const end = dayjs(value).endOf("week");
    return `${dayjs(start).format("DD MMM BBBB")} - ${dayjs(end).format(
      "DD MMM BBBB"
    )}`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label="วันที่"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        showDaysOutsideCurrentMonth={true}
        renderDay={renderWeekPickerDay}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <StyleTextField
            style={{ width: "100%" }}
            id="outlined-read-only-input"
            label=""
            ref={inputRef}
            {...inputProps}
            value={inputFormat()}
            InputProps={InputProps}
            readOnly
          />
        )}
      />
    </LocalizationProvider>
  );
}
