import React from "react";
import { styled } from '@mui/styles';
import { Chip, Divider, Grid, Typography } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";

const Root = styled("div")(({theme}) => ({
    marginBottom: 32,
    "& .chip_container": {
        marginTop: 24,
    },
    "& .SkillChip": {
        backgroundColor: "#fff3e0",
        color: "#ff9800",
        fontWeight: 600,
        fontSize:"18px !important",
        padding:"5px 5px"
    },
    "& .InterestChip": {
        backgroundColor: "#e8f5e9",
        color: "#4caf50",
        fontWeight: 600,
        fontSize:"18px !important",
        padding:"5px 5px"
    },
}));

const WrapIcon = styled(Typography)({
    verticalAlign: "middle",
    display: "inline-flex",
});

const Skill_Interests = (props) => {
    const { employee } = props;
    return (
        <Root>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1" gutterBottom>
                        Gender
                    </Typography>
                    <Typography variant="subtitle1">{employee.gender ? employee.gender : "-"}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1" gutterBottom>
                        NickName
                    </Typography>
                    <Typography variant="subtitle1">{employee.nickname_TH ? employee.nickname_TH : "-"}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1" gutterBottom>
                        Phone Number
                    </Typography>
                    <Typography variant="subtitle1">{employee.telephoneMobile ? employee.telephoneMobile : "-"}</Typography>
                </Grid>
            </Grid>
            <Divider />
            <Grid container>
                <Grid item xs>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                            <Grid item>
                                <WrapIcon
                                    variant="body1"
                                    gutterBottom
                                >
                                    <EmojiEventsIcon style={{ marginRight: "5px" }} />
                                    Skills
                                </WrapIcon>
                            </Grid>
                        </Grid>
                        <div className="chip_container">
                            <Grid container spacing={1}>
                                {employee.skills ? 
                                employee.skills.length > 0 && employee.skills.map((skill) => (
                                    <Grid item key={skill.idSkill}>
                                        <Chip
                                            className="SkillChip"
                                            label={skill.skill}
                                        />
                                    </Grid>
                                )) : (
                                <Grid item>
                                    <Typography variant="body2">-</Typography>
                                </Grid>
                                )}
                            </Grid>
                        </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <WrapIcon
                                        variant="body1"
                                        gutterBottom
                                    >
                                        <AssistantPhotoIcon style={{ marginRight: "5px" }} />
                                        Interests
                                    </WrapIcon>
                                </Grid>
                            </Grid>
                            <div className="chip_container">
                                <Grid container spacing={1}>
                                    {employee.interests ? employee.interests.length > 0 && employee.interests.map((interest) => (
                                        <Grid item key={interest.idInterest}>
                                            <Chip
                                                className="InterestChip"
                                                label={interest.Interest}
                                            />
                                        </Grid>
                                    )) : (
                                    <Grid item>
                                        <Typography variant="body2">-</Typography>
                                    </Grid>
                                    )}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Root>
    );

};

export default Skill_Interests;