import {
  POLICY_FETCHING,
  POLICY_FAILED,
  POLICY_SUCCESS,
  POLICY_ACTIVE_FETCHING,
  POLICY_ACTIVE_FAILED,
  POLICY_ACTIVE_SUCCESS,
} from "./types";
import policyService from "../services/policy.service";

export const getPolicyByIdCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: POLICY_FETCHING,
    });
    const res = await policyService.getPolicyByIdCompany();
    if (res) {
      dispatch({
        type: POLICY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: POLICY_FAILED,
    });
  }
};

export const getPolicyActiveByIdCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: POLICY_ACTIVE_FETCHING,
    });
    const res = await policyService.getPolicyActiveByIdCompany();
    if (res) {
      dispatch({
        type: POLICY_ACTIVE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: POLICY_ACTIVE_FAILED,
    });
  }
};

export const addPolicy = (formData) => async (dispatch) => {
  try {
    const res = await policyService.addPolicy(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: POLICY_FAILED,
    });
  }
};

export const updatePolicy = (formData) => async (dispatch) => {
  try {
    const res = await policyService.updatePolicy(formData)
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: POLICY_FAILED,
    });
  }
};

export const deletePolicy = (id) => async (dispatch) => {
  try {
    const res = await policyService.deletePolicy(id);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: POLICY_FAILED,
    });
  }
};
