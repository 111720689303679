import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, styled, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveRequest } from "../../../../actions/employee";
import {
  getAllLeaveWithDrawBy,
  updateApproveLeaveEmployeeBy,
} from "../../../../actions/leave";
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogConfirmReject from "./DialogConfirmReject";
import CardRequest from "./CardRequest";

const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor:"#E793B8",
  borderRadius:"8px",
  color:"#FFFFFF",
  marginRight: 8,
  width: 130,
  "&:hover":{
    backgroundColor:"#DC6384"
  }
});

const RequestLeave = (props) => {
  const { searchDate, setSnackBarConfig, setNumberOfList } = props;
  const mobileResponsive = useMediaQuery('(max-width:600px)');
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [pendingRows, setPendingRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const dispatch = useDispatch();

  const columns = [
    {
      name: "ชื่อ-สกุล",
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {row.firstname_TH} {row.lastname_TH}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {row.positionName}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: "ประเภท",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idLeaveEmployeesWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>{row.name}</Typography>
          <Typography fontSize={14} color="text.secondary">
            {row.holidayName}
          </Typography>
        </Fragment>
      ),
    },
    {
      name: "เริ่มต้น",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>เต็มวัน</Typography>
          ) : (
            <Typography>
              {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: "สิ้นสุด",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>เต็มวัน</Typography>
          ) : (
            <Typography>
              {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: "เหตุผล",
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.description}
          </Typography>
        </Box>
      ),
    },
  ];

  const handleApprovalRequest = async (approve, comment) => {
    const idLeave = selectedRows
      .filter((x) => {
        return x.idLeave && !x.idLeaveEmployeesWithdraw;
      })
      .map(function (user) {
        return user.idLeave;
      });

    const idLeaveEmployeesWithdraw = selectedRows
      .filter((x) => {
        return x.idLeaveEmployeesWithdraw;
      })
      .map(function (user) {
        return user.idLeaveEmployeesWithdraw;
      });

    const idLeaveWithdraw = selectedRows
      .filter((x) => {
        return (
          x.idLeaveEmployeesWithdraw &&
          x.managerApprove === userProfile.idEmployees
        );
      })
      .map(function (user) {
        return user.idLeave;
      });

    var formData = [
      {
        idLeave: idLeave,
        isApprove: approve,
        idManager: userProfile.idEmployees,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        commentManager: comment ? comment : null,
      },
      {
        idLeaveEmployeesWithdraw: idLeaveEmployeesWithdraw,
        idLeave: idLeaveWithdraw,
        isApprove: approve,
        commentManager: comment ? comment : null,
      },
    ];

    const result = await dispatch(
      updateApproveLeaveEmployeeBy("manager", formData)
    );
    if (result) {
      setSelectedRows([]);
      if (result.status === 200) {
        dispatch(getLeaveRequest("manager", searchDate));
        dispatch(getAllLeaveWithDrawBy("manager", searchDate));
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการสำเร็จ",
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการไม่สำเร็จ",
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: "ดำเนินการไม่สำเร็จ",
        type: "error",
      });
    }
  };

  const handleConfirmReject = (comment) => {
    handleApprovalRequest(0, comment);
  };

  const handleClickAll = () => {
    setSelectedRows(pendingRows);
  };

  const handleCancel = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    const tempPending = [];

    leaveEmployeesList.map((r) => {
      if (r.isApprove === null) {
        tempPending.push(r);
      }
    });

    leaveEmployeeWithdrawsList.map((r) => {
      if (r.isApprove === null) {
        tempPending.push(r);
      }
    });

    let pendingList = tempPending.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });
    setPendingRows(pendingList);
    setNumberOfList(pendingList.length);
  }, []);

  return (
    <Box>
      {mobileResponsive && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          {((selectedRows ? selectedRows.length : 0) > 0) && 
            <StyledButtonCancel
              startIcon={<DeleteIcon />}
              onClick={handleCancel}
            >
              {`ยกเลิก`}
            </StyledButtonCancel>
          }
          {!((selectedRows ? selectedRows.length : 0) === pendingRows.length) &&
            <ButtonBlue
              startIcon={<DoneAll />}
              variant="contained"
              onClick={handleClickAll}
            >
              {`เลือกทั้งหมด`}
            </ButtonBlue>
          }
        </Box>
      )}
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1)}
          >
            {`อนุมัติ ${
              selectedRows.length > 0 ? `${selectedRows.length} รายการ` : ""
            }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => setIsOpenRejectDialog(true)}
          >
            {`ไม่อนุมัติ ${
              selectedRows.length > 0 ? `${selectedRows.length} รายการ` : ""
            }`}
          </ButtonBlue>
        </Box>
      )}
      {!mobileResponsive ? 
        <TableCustom
          columns={columns}
          rows={
            leaveEmployeesList && leaveEmployeeWithdrawsList ? pendingRows : []
          }
          canSelect
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
        :
        (
          <div>
            {(leaveEmployeesList && leaveEmployeeWithdrawsList) ?
              pendingRows.map(row => (
                <CardRequest 
                  key={row.idLeave}
                  row={row}
                  selectedRows={selectedRows} 
                  setSelectedRows={setSelectedRows}
                />
              ))
              :
              <div>
                <Typography align="center">ไม่พบข้อมูล</Typography>
              </div>
            }
          </div>
        )
      }

      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleConfirmReject(comment);
        }}
      />
    </Box>
  );
};

export default RequestLeave;
