import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Divider,
  IconButton,
  Box
} from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";

import { Controller, useForm, useFieldArray } from 'react-hook-form';
import DialogExitInterview from "./DialogExitInterview";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyProfile } from "../../../../../actions/company";
import { getExitInterview, getExitInterviewDiscard, getReasonExitInterview } from "../../../../../actions/exitInterview";
import { updateReasonExitInterview } from "../../../../../actions/exitInterview";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

const BoxStyled = styled("div")({
  "& .header": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .text": {
      fontSize: 16,
    },
    "& .icon": {
      color: "#637381",
      fontSize: 16,
      marginRight: 8,
    },
  },
  "& .btn-save": {
    justifyContent: "flex-end",
  },
  "& .flex-box": {
    justifyContent: "space-between",
    marginTop: 16,
    display: "flex",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginRight: 8,
});

const label = { inputProps: { "aria-label": "Size switch demo" } };

function ExitInterview() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: ExitInterview } = useSelector((state) => state.getExitInterview);
  const { result: ReasonExitInterview } = useSelector((state) => state.exitInterviewReason);
  const { result: exitInterviewDiscard } = useSelector((state) => state.exitInterviewDiscard);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openEducation, setOpenEducation] = useState(false);
  const [idEducation, setIdEducation] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");
  const [amountQuestion, setAmountQuestion] = useState(0);
  const [idReasonList, setIdReasonList] = useState([]);

  useEffect(() => {
    dispatch(getCompanyProfile());
    dispatch(getExitInterview());
    dispatch(getExitInterviewDiscard());
    dispatch(getReasonExitInterview());
  }, []);

  useEffect(() => {
    if (ExitInterview) {
      setAmountQuestion(ExitInterview.length)
      console.log("ExitInterview", ExitInterview)
    }
  }, [ExitInterview]);


  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSubmitExitInterview = async (result) => {
    handleClose();
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        dispatch(getExitInterview());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  const { control, handleSubmit, getValues, watch, setValue, formState: { errors, dirtyFields }, reset, setError } = useForm({
    defaultValues: {
      reasonList: []
    }
  })

  const reasonUseFieldArray = useFieldArray({
    rules: { maxLength: 10 },
    control: control,
    name: "reasonList",
  })

  const initialValueReasonData = {
    name: "",
  }

  useEffect(() => {
    if (ReasonExitInterview !== null) {
      reset({
        reasonList: ReasonExitInterview
      })
    }
  }, [ReasonExitInterview])

  const onSubmit = async (formData) => {
    const cleansingForm = {
      reasonList: formData.reasonList,
      deleteReasonList: idReasonList,
    }
    console.log("formData", cleansingForm)
    const response = await dispatch(updateReasonExitInterview(cleansingForm))
    handleSubmitExitInterview(response)
  }

  const handleOpenEducation = (type, idEdu) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idEdu) {
      setIdEducation(idEdu);
    }
    setOpenEducation(true);
  };

  const handleClose = () => {
    setOpenEducation(false);
    setIdEducation(null);
  };

  return (
    <div className={classes.root}>
      <StyledHeadingPage sx={{ marginTop: "20px" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" >คำถามแบบฟอร์ม Exit Interview</Typography>
          </Grid>
        </Grid>
      </StyledHeadingPage>
      <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
        <BoxStyled>
          <div className="flex-box">
            <StyledContentLabel variant="body1">
              {`${t("คำถามที่ใช้ทั้งหมด ")}${amountQuestion}/10`}
              &nbsp;&nbsp;
              <i className="fa-sharp fa-solid fa-check fa-beat fa-xl" style={{ color: "#fde64b" }}></i>
            </StyledContentLabel>
            <div className="btn-save">
              <ButtonBlue
                variant="contained"
                onClick={() => handleOpenEducation("Add")}
              >
                {t("Question")}
                &nbsp;
                <AddCircleRoundedIcon /><RemoveCircleIcon />
              </ButtonBlue>
            </div>
          </div>
          {console.log(ExitInterview)}
          {ExitInterview && ExitInterview.map((ExitInterview, index) => (
            <Grid key={ExitInterview.id} container className="GridContact">
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={3} >
                  <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                  &nbsp;&nbsp;{`คำถามที่ ${index + 1}`}
                </Grid>
                <Grid item xs={12} sm={9}>
                  {ExitInterview.question}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "16px",
                    borderColor: "#9e9e9e66"
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </BoxStyled>
      </CardStyle>
      <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
        <BoxStyled>
          <div className="flex-box">
            <StyledContentLabel variant="body1">
              {`${t("คำถามที่ถูกยกเลิกการใช้")}`}
              &nbsp;&nbsp;
              <i className="fa-sharp fa-solid fa-xmark fa-beat fa-xl" style={{ color: "#ff0000" }}></i>
            </StyledContentLabel>
          </div>
          {exitInterviewDiscard && exitInterviewDiscard.map((exitInterviewDiscard, index) => (
            <Grid key={exitInterviewDiscard.id} container className="GridContact">
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={3} >
                  <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                  &nbsp;&nbsp;{`คำถามที่ ${index + 1}`}
                </Grid>
                <Grid item xs={12} sm={9}>
                  {exitInterviewDiscard.question}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "16px",
                    borderColor: "#9e9e9e66"
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </BoxStyled>
      </CardStyle>
      <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
        <BoxStyled>
          <div className="flex-box">
            <StyledContentLabel variant="body1">
              {`${t("สาเหตุที่ต้องการลาออก")}`}
            </StyledContentLabel>
              <div className="btn-save">
                <ButtonBlue variant="outlined" startIcon={<i className="fa-regular fa-plus"></i>} onClick={() => { reasonUseFieldArray.append(initialValueReasonData) }}>เพิ่มเหตุผล</ButtonBlue>
              </div>
          </div>
          {reasonUseFieldArray.fields.map((reason, index) => (
            <Grid key={reason.id} container className="GridContact">
              {console.log("ExitInterview", ExitInterview)}
              <Grid item xs={12} sm={12} marginTop={2} display="flex" className="GridTopicInput">
                <Grid item xs={12} sm={9}>
                  <Controller
                    control={control}
                    name={`reasonList.${index}.name`}
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณากรอกคำถาม"
                      },
                    }}
                    render={({ field, formState }) => (
                      <TextFieldTheme
                        {...field}
                        autoComplete="degree"
                        error={formState.errors && formState.errors.reason && formState.errors.reason[index] && formState.errors.reason[index].name ? true : false}
                        helperText={formState.errors && formState.errors.reason && formState.errors.reason[index] && formState.errors.reason[index].name ? formState.errors.reason[index].name.message : null}
                      >
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                {reasonUseFieldArray.fields.length > 1 &&
                  <Grid item xs={3}>
                    <Box justifyContent="flex-end">
                      <IconButton color="error" onClick={() => { reasonUseFieldArray.remove(index); setIdReasonList([...idReasonList, reason.idReasonExitInterview]); }}>
                        <i className="fa-regular fa-trash-can"></i>
                      </IconButton>
                    </Box>
                  </Grid>
                }
              </Grid>
            </Grid>
          ))}
          {reasonUseFieldArray.fields.length <= 0 && (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">ไม่มีเหตุผล</Typography>
            </Grid>
          )}
          <Box style={{ marginTop:"10px",display:"flex",justifyContent:"flex-end"}}>
            <div >
              <ButtonBlue variant="contained" onClick={handleSubmit(onSubmit)} autoFocus>
                บันทึกข้อมูล
              </ButtonBlue>
            </div>
          </Box>
        </BoxStyled>
      </CardStyle>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {openEducation && (
        <DialogExitInterview
          open={openEducation}
          handleClose={handleClose}
          idEdu={idEducation}
          type={typeDialog}
          handleSubmitExitInterview={handleSubmitExitInterview}
        />
      )}
    </div>
  );
}

export default ExitInterview;
