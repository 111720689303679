import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  Grid,
  styled,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  MenuItem,
  TableRow,
  Menu,
  IconButton,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import ButtonBlue from "../../shared/general/ButtonBlue";
import dayjs from "dayjs";

import SelectEmployees from "../../shared/general/selectEmployees";
import {
  clearAttendance,
  getAttendanceById,
} from "../../../../actions/attendance";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import TableTimeline from "../../shared/tableTimeline";
import CardStyle from "../../shared/general/Card";
import DrawerRequestTime from "../../shared/tableTimeline/drawerRequestTime";
import Notification from "../../shared/general/Notification";
import AlertResponse from "../../shared/general/AlertResponse";
import DialogConfirm from "../../shared/general/DialogConfirm";
import DialogConfirmDayOffHoliday from "../../shared/general/DialogConfirmDayOffHoliday";
import DialogConfirmDelete from "../../shared/general/DialogConfirmDelete";
import StatusRequest from "../../shared/general/stausRequest";

import Timer from "../../assets/timer.png";

import { getPayrollSetting } from "../../../../actions/paytypes";
import {
  getTimescheduleByEmployees,
  employeeChangeTime,
  employeeChangeTimeWithdraw,
} from "../../../../actions/employee";
import { getShift } from "../../../../actions/shift";
import { allHoliday } from "../../../../actions/holiday";

const StyledRoot = styled(Box)({});

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    justifyContent: "center",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
      marginRight: 8,
    },
  },
});

const StyledIconDelete = styled(IconButton)({
  "&:hover": {
    backgroundColor: "#fdf3f5",
  },
});

const StyledButtonTime = styled(Button)(({ status, color }) => ({
  borderRadius: 8,
  color: "#212b36",
  borderColor: "#dcdcdc",
  ...(status === 0 && {
    borderColor: "#f5f5f5",
    color: "#212b36",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      borderColor: "#f0f0f0",
      backgroundColor: "#f0f0f0",
    },
  }),
}));

const ITEM_HEIGHT = 48;

const EmployeeShiftPanel = () => {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });

  const [showDate, setShowDate] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const { result: employeeShiftList } = useSelector(
    (state) => state.employeeShift
  );

  const { result: shiftGroupList } = useSelector((state) => state.shift);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: holiday } = useSelector((state) => state.holiday);
  const [rowData, setRowData] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmHoliday, setOpenConfirmHoliday] = useState(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [selectedHoliday, setSelectedHoliday] = React.useState(null);

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
  };

  const handleOnClickSearch = () => {
    setShowDate(search);
    if (selectedEmployee) {
      dispatch(
        getTimescheduleByEmployees(
          {
            start: dayjs(search.start).format("YYYY-MM-DD"),
            end: dayjs(search.end).format("YYYY-MM-DD"),
          },
          selectedEmployee.idEmployees
        )
      );
    }
  };

  useEffect(() => {
    dispatch(getShift());
    dispatch(allHoliday(dayjs(new Date()).format("YYYY")));
  }, []);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickChangeTime = (item) => {
    setSelectedTime(item);
    setOpenConfirm(true);
  };

  const handleClickChangeDayOff = (holiday) => {
    console.log("holiday: ", holiday);
    if (shiftGroupList) {
      let foundShiftGroup = shiftGroupList.find(
        (x) => x.idShiftGroup === selected.item.dataRender.idShiftGroup
      );
      let foundShiftType = foundShiftGroup.shiftType.find(
        (x) => x.isWorkingDay === 0
      );

      console.log("foundShiftGroup: ", foundShiftGroup);
      console.log("foundShiftType: ", foundShiftType);

      if (holiday === "holiday") {
        setSelectedTime(foundShiftType);
        setOpenConfirmHoliday(true);
      } else {
        setSelectedTime(foundShiftType);
        setOpenConfirm(true);
      }
    }
  };

  const handleComfirm = async () => {
    let dataForm = null;
    if (selectedTime.isWorkingDay === 0) {
      dataForm = {
        idEmployees: selectedEmployee.idEmployees,
        idShift: selected.item.dataRender.idShift,
        idShiftType: selectedTime.idShiftType,
        workingDate: selected.item.date,
        idShiftGroup: selectedTime.idShiftGroup,
        isWorkingDay: 0,
        idHoliday: selectedHoliday,
      };
    } else {
      dataForm = {
        idEmployees: selectedEmployee.idEmployees,
        idShift: selectedTime.shift[0].idShift,
        idShiftType: selectedTime.shiftType.find((x) => x.isWorkingDay === 1)
          .idShiftType,
        workingDate: selected.item.date,
        idShiftGroup: selectedTime.idShiftGroup,
        isWorkingDay: 1,
      };
    }

    // const result = await dispatch(employeeChangeTime(dataForm));
    // if (result && result.status === 200) {
    //   dispatch(
    //     getTimescheduleByEmployees(
    //       {
    //         start: dayjs(search.start).format("YYYY-MM-DD"),
    //         end: dayjs(search.end).format("YYYY-MM-DD"),
    //       },
    //       selectedEmployee.idEmployees
    //     )
    //   );
    //   handleChangeAlertType("success");
    //   handleOpenAlert();
    //   handleCloseDialog();
    // } else {
    //   handleChangeAlertType("error");
    //   handleOpenAlert();
    //   handleCloseDialog();
    // }
  };


  const renderShiftChange = (requestChange, mode) => {
    let request = null;
    if (mode === "wait") {
      request = requestChange.find((x) => x.isApprove === null);
    } else {
      request = requestChange.filter((x) => x.isApprove !== null);
      request = request[request.length - 1];
    }

    const foundHoliday = holiday.find(
      (x) => request && x.idHoliday === request.idHoliday
    );

    if (shiftGroupList && request) {
      const foundShiftGroup = shiftGroupList.find(
        (x) => x.idShiftGroup === request.idShiftGroup
      );
      const foundShiftType =
        foundShiftGroup &&
        foundShiftGroup.shiftType.find(
          (x) => x.idShiftType === request.idShiftType
        );

      return (
        <Fragment>
          {request.isActive && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <StatusRequest
                boxSize={40}
                fontSize={22}
                borderRadius={8}
                status={request.isApprove}
                active={request.isActive}
              />
              <Fragment>
                {request.isApprove !== null ? (
                  <div style={{ marginLeft: 8 }}>
                    <Typography variant="subtitle1" color="text.third">
                      <Typography
                        style={{ marginRight: 4, fontWeight: 500 }}
                        component="span"
                        color="text.primary"
                      >
                        {request.fillInChange === null
                          ? `${request.firstname_TH} ${request.lastname_TH}`
                          : `${request.fillInChange_firstname_th} ${request.fillInChange_lastname_th}`}
                      </Typography>
                      {request.isApprove === 1
                        ? request.fillInChange === null
                          ? "(อนุมัติ)"
                          : "(เปลี่ยน)"
                        : request.isApprove === 0
                        ? "(ไม่อนุมัติ)"
                        : "(รออนุมัติ)"}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {`วันที่: ${dayjs(
                        request.fillInChange === null
                          ? request.approveDateText
                          : request.createDateText,
                        "YYYY-MM-DD HH:mm"
                      ).format("DD/MM/BBBB HH:mm")}`}
                    </Typography>
                  </div>
                ) : (
                  <div style={{ marginLeft: 8 }}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="subtitle1" color="text.third">
                        <Typography
                          style={{ marginRight: 4, fontWeight: 500 }}
                          component="span"
                          color="text.primary"
                        >
                          {foundShiftType.isWorkingDay === 0
                            ? foundHoliday
                              ? `วันหยุดนักขัตฤกษ์: ${foundHoliday.name}`
                              : "วันหยุด"
                            : foundShiftGroup.shiftGroupName}
                        </Typography>
                        {request.isApprove === 1
                          ? "(อนุมัติ)"
                          : request.isApprove === 0
                          ? "(ไม่อนุมัติ)"
                          : "(รออนุมัติ)"}
                      </Typography>
                      <StyledIconDelete
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          //console.log("request: ", request);
                          handleClickDeleteButton(request);
                        }}
                      >
                        <HighlightOffRoundedIcon
                          style={{ color: "#e46a76" }}
                          fontSize="inherit"
                        />
                      </StyledIconDelete>
                    </div>

                    <Typography color="text.secondary" variant="body2">
                      {`สร้าง: ${dayjs(
                        request.createDateText,
                        "YYYY-MM-DD HH:mm"
                      ).format("DD/MM/BBBB HH:mm")}`}
                    </Typography>
                  </div>
                )}
              </Fragment>
            </div>
          )}
        </Fragment>
      );
    }
  };

  const renderHoliday = (item) => {
    if (item.dataRender.isWorkingDay === 0) {
      if (item.dataRender.idHoliday > 0) {
        const foundHoliday = holiday.find(
          (x) => x.idHoliday === item.dataRender.idHoliday
        );
        return (
          <Fragment>
            {foundHoliday && (
              <Typography
                style={{
                  marginTop: 4,
                  marginBottom: 4,
                  fontSize: 14,
                  fontStyle: "oblique",
                }}
                color="text.secondary"
              >
                {foundHoliday.name}
              </Typography>
            )}
          </Fragment>
        );
      }
    }
  };

  const renderShiftTime = (item) => {
    if (item.dataRender.isWorkingDay === 0) {
      if (item.dataRender.idHoliday > 0) {
        const foundHoliday = holiday.find(
          (x) => x.idHoliday === item.dataRender.idHoliday
        );
        return (
          <Fragment>
            <Typography>วันหยุดนักขัตฤกษ์</Typography>
          </Fragment>
        );
      } else {
        return "วันหยุด";
      }
    } else {
      return (
        <Fragment>
          <img src={Timer} alt="Timer" width={26} style={{ marginRight: 8 }} />
          {item.dataRender.shiftGroupName}
        </Fragment>
      );
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenConfirm(false);
    setOpenConfirmHoliday(false);
    setOpenDialogConfirmDelete(false);
    setAnchorEl(null);
  };

  const handleClickButtonTime = (event, item) => {
    if (item.isWaitingApprove) {
      alert("ไม่สามารถทำรายการได้ เนื่องจากมีรายการคำขอรออนุมัติค้างอยู่");
    } else {
      setAnchorEl(event.currentTarget);
      setSelected({ rowData, item });
    }
  };

  const handleClickDeleteButton = (request) => {
    setOpenDialogConfirmDelete(true);
    setSelected(request);
  };

  return (
    <StyledRoot>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16 }}
        alignItems={"center"}
      >
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              วันที่เริ่มต้น
            </Typography>
            <div className="search-date">
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={search.start}
                  name="start"
                  onChange={(newValue) => {
                    setSearch({ ...search, ["start"]: newValue });
                  }}
                  renderInput={(params) => (
                    <StyledTextField variant="filled" fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              วันที่สิ้นสุด
            </Typography>
            <div className="search-date">
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  minDate={search.start}
                  inputFormat="dd/MM/yyyy"
                  value={search.end}
                  name="end"
                  onChange={(newValue) => {
                    setSearch({ ...search, ["end"]: newValue });
                  }}
                  renderInput={(params) => (
                    <StyledTextField variant="filled" fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectEmployees
            handleChange={handleSelectEmployee}
            position={userProfile && userProfile.idCompany === 3}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearchButton>
            <div>
              <ButtonBlue
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={handleOnClickSearch}
              >
                ค้นหา
              </ButtonBlue>
            </div>
          </StyledBoxSearchButton>
        </Grid>
      </Grid>
      <CardStyle>
        <Box style={{ padding: "24px" }}>
          <Box style={{ display: "flex", position: "relative" }}>
            <StyledBox className={"close"}>
              <TableContainer style={{ width: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledCellHeader
                        key="date"
                        align={"center"}
                        style={{ minWidth: 150 }}
                      >
                        วันที่
                      </StyledCellHeader>
                      <StyledCellHeader
                        key="date"
                        align={"center"}
                        style={{ minWidth: 200, maxWidth: 300 }}
                      >
                        เวลาทำงาน
                      </StyledCellHeader>
                      <StyledCellHeader
                        key="date"
                        align={"center"}
                        style={{ minWidth: 250 }}
                      >
                        สถานะ
                      </StyledCellHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {holiday &&
                      employeeShiftList &&
                      employeeShiftList
                        .filter((r) => {
                          return !dayjs(r.date).isBefore(
                            dayjs(selectedEmployee.hiringDate)
                          );
                        })
                        .map((item, index) => (
                          <StyledRowContent key={`${index}`}>
                            <StyledCellContent align={"center"}>
                              <div>
                                <Typography
                                  variant="h6"
                                  style={{ fontSize: 16 }}
                                >
                                  {dayjs(item.date).format("DD MMM BBBB")}
                                </Typography>
                                <Typography color="text.secondary">{`(${dayjs(
                                  item.date
                                ).format("dddd")})`}</Typography>
                              </div>
                            </StyledCellContent>
                            <StyledCellContent align={"center"}>
                              <StyledButtonTime
                                style={{ width: 156 }}
                                variant="outlined"
                                status={item.dataRender.isWorkingDay}
                                disableFocusRipple={true}
                                disableRipple={true}
                                aria-label="more"
                                onClick={(event) =>
                                  handleClickButtonTime(event, item)
                                }
                              >
                                {renderShiftTime(item)}
                              </StyledButtonTime>
                              {renderHoliday(item)}
                            </StyledCellContent>
                            <StyledCellContent>
                              {item.requestChange &&
                                item.requestChange.length > 0 &&
                                renderShiftChange(
                                  item.requestChange,
                                  "approve"
                                )}
                              {item.requestChange &&
                                item.requestChange.length > 0 &&
                                renderShiftChange(item.requestChange, "wait")}
                            </StyledCellContent>
                          </StyledRowContent>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledBox>
          </Box>
        </Box>
      </CardStyle>
      {shiftGroupList && (
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          }}
        >
          {shiftGroupList
            .filter((s) => s.idWorkingType === 2)
            .map((item) => (
              <MenuItem
                style={{
                  width: 150,
                  marginRight: 8,
                }}
                onClick={() => handleClickChangeTime(item)}
              >
                {item.shiftGroupName}
              </MenuItem>
            ))}
          <MenuItem
            style={{
              width: 150,
              marginRight: 8,
            }}
            onClick={handleClickChangeDayOff}
          >
            วันหยุดประจำสัปดาห์
          </MenuItem>
          <MenuItem
            style={{
              width: 150,
              marginRight: 8,
            }}
            onClick={() => handleClickChangeDayOff("holiday")}
          >
            วันหยุดนักขัตฤกษ์
          </MenuItem>
        </StyledMenu>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {selected && openConfirm && (
        <DialogConfirm
          open={openConfirm}
          handleClose={handleCloseDialog}
          text={"เปลี่ยนเวลาทำงาน"}
          content1={
            selected && `${dayjs(selected.item.date).format("D MMMM BBBB")}`
          }
          content2={
            selectedTime &&
            `${
              selectedTime.isWorkingDay === 0
                ? "วันหยุดประจำสัปดาห์"
                : selectedTime.shiftGroupName
            }`
          }
          handleComfirm={handleComfirm}
        />
      )}
      {selected && openConfirmHoliday && (
        <DialogConfirmDayOffHoliday
          open={openConfirmHoliday}
          handleClose={handleCloseDialog}
          text={"เปลี่ยนเวลาทำงาน"}
          content1={
            selected && `${dayjs(selected.item.date).format("D MMMM BBBB")}`
          }
          content2={selectedTime && `วันหยุดนักขัตฤกษ์`}
          handleComfirm={handleComfirm}
          setSelectedHoliday={setSelectedHoliday}
        />
      )}
    </StyledRoot>
  );
};

export default EmployeeShiftPanel;
