import React, { Fragment, useEffect, useState } from "react";
import { Box, Container, Typography, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { getOrganizationObjectiveByIdOrganizationObjective } from "../../../../../../../actions/okrs";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded, ChevronRightRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import { Link } from "react-router-dom/cjs/react-router-dom";

const StyledRoot = styled(Box)({

})

const UpdateProgressOrganizationObjective = (props) => {

  const { match } = props;

  const dispatch = useDispatch();

  const [organizationObjective, setOrganizationObjective] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    dispatch(getOrganizationObjectiveByIdOrganizationObjective(match.params.idOrganizationObjective)).then(res => {
      if(isMounted){
        setIsFetching(false);
        if(res.status === 200){
          setOrganizationObjective(res.data)
        } else {
          setIsError(true);
        }
      }
    })

    return () => {isMounted = false}
  }, [])

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          {organizationObjective && <Fragment>
            <CardStyle style={{marginBottom: "48px"}}>
              <Box padding="24px">
                <Typography fontSize="24px" marginBottom="16px">{organizationObjective.year + 543}, Q{organizationObjective.quarter}</Typography>
                <Typography fontSize="20px" fontWeight="500">{organizationObjective.objectiveName}</Typography>
                {organizationObjective.description && <Typography paddingTop="16px" fontSize="14px" color="text.third">{organizationObjective.description}</Typography>}
                <Typography paddingTop="16px" marginBottom="16px"><span style={{paddingRight: "8px",color: "#919eab"}}>ผลลัพธ์ที่เกิดขึ้น</span>{organizationObjective.impactName}</Typography>
              </Box>
            </CardStyle>

            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
              <Typography>Key Result</Typography>
              <Typography>{organizationObjective.keyResults.length} รายการ</Typography>
            </Box>

            <Box>
              {organizationObjective.keyResults.map((keyResult, index) => (
                <KeyResult key={index} data={keyResult}/>
              ))}
            </Box>
          </Fragment>}
        </Container>
      </Box>
    </StyledRoot>
  )
}

const StyledKeyResultRoot = styled(Box)({
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: 0,
  },
  "& a": {
    color: "unset",
    textDecoration: "unset",
  }
})

const KeyResult = (props) => {

  const { data } = props;

  return (
    <StyledKeyResultRoot>
      <Link to={`/okrs/update-progress/organization/keyresult/${data.idOrganizationKeyResult}`}>
        <CardStyle>
          <Box padding="24px">
            <Box display="flex" alignItems="flex-start">
              <Typography flexGrow="1" fontSize="18px" fontWeight="500">{data.keyResultName}</Typography>
              <Box display="flex" alignItems="center">
                <Typography fontSize="14px">อัพเดทสถานะ</Typography>
                <ChevronRightRounded style={{marginLeft: "8px", fontSize: "22px"}}/>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" marginTop="8px">
              <AccessTimeRounded style={{marginRight: "8px", fontSize: "16px"}}/>
              <Typography fontSize="14px" lineHeight="1">{dayjs(data.startDate).format("D MMM BBBB")} - {dayjs(data.endDate).format("D MMM BBBB")}</Typography>
            </Box>
          </Box>
        </CardStyle>
      </Link>
    </StyledKeyResultRoot>
  )
}

export default UpdateProgressOrganizationObjective;