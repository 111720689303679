import {
  USERPROFILE_FETCHING,
  USERPROFILE_FAILED,
  USERPROFILE_SUCCESS,
  USERS_FETCHING,
  USERS_FAILED,
  USERS_SUCCESS,
} from "./types";

import UserService from "../services/user.service";

import { logout } from "./auth";

export const getUserProfile = () => async (dispatch) => {
  try {
    console.log("UserProfile")
    const res = await UserService.getUserProfile();
    console.log(res.data)
    if (res) {
      dispatch({
        type: USERPROFILE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERPROFILE_FAILED,
    });
    //dispatch(logout())
  }
};

export const getAllUsersCompany = (idCompany) => async (dispatch) => {
  try {
    console.log(idCompany);
    const res = await UserService.getAllUsersCompany(idCompany);
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERS_FAILED,
    });
    console.log(err);
  }
};

export const addUsers = (formData) => async () => {
  try {
    const res = await UserService.addUsers(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateUser = (formData, mode) => async () => {
  try {
    if (mode) {
      const res = await UserService.updateUserNewManager(formData);
      if (res) {
        return res.data;
      }
    } else {
      const res = await UserService.updateUser(formData);
      if (res) {
        return res;
      }
    }
  } catch (error) {
    var result = {
      status: "",
    };
    if (error.response) {
      result.status = error.response.status;
    }
    return result;
  }
};

export const updateManagerEmployee = (formData) => async () => {
  try {
    const res = await UserService.updateManagerEmployee(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    var result = {
      status: "",
    };
    if (error.response) {
      result.status = error.response.status;
    }
    return result;
  }
};

export const getDataWritePDF = (type, year, rangeDate) => async () => {
  try {
    const res = await UserService.getDataWritePDF(type, year, rangeDate);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
