import React from "react";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";

const PaymentInfo = (params) => {
  const { useForm } = params;

  return (
    <Box>
      <Typography className="title-text">การจ่าย</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>รูปแบบงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="employmentTime"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              >
                <MenuItem value="Full time">Full time</MenuItem>
                <MenuItem value="Part time">Part time</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>รูปแบบการจ่าย</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idPaymentType"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
              select
              error={useForm.formState.errors[field.name]? true: false}
              helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              >
                <MenuItem value="1">
                  <Box>
                    <Typography>รายวัน</Typography>
                    <Typography color="text.third" fontSize="12px">รอบจ่าย: รายครึ่งเดือน</Typography>
                  </Box>
                </MenuItem>
                <MenuItem value="2">
                  <Box>
                    <Typography>รายเดือน</Typography>
                    <Typography color="text.third" fontSize="12px">รอบจ่าย: รายเดือน</Typography>
                  </Box>
                </MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>ค่าแรง</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="salary"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                  endAdornment: <Typography color="text.secondary">บาท</Typography>
                }}
                inputProps={{
                  allowNegative: false,
                  value: field.value,
                  thousandSeparator: ",",
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                }}
                onChange={()=>{}}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>Cost Center</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="costCenterCharge"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>Cost Element</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="costElementCharge"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ธนาคาร</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="bookBank"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              >
                <MenuItem value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</MenuItem>
                <MenuItem value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</MenuItem>
                <MenuItem value="ธนาคารกรุงศรีอยุธยา">ธนาคารกรุงศรีอยุธยา</MenuItem>
                <MenuItem value="ธนาคารกสิกรไทย">ธนาคารกสิกรไทย</MenuItem>
                <MenuItem value="ธนาคารทหารไทยธนชาต">ธนาคารทหารไทยธนชาต</MenuItem>
                <MenuItem value="ธนาคารไทยพาณิชย์">ธนาคารไทยพาณิชย์</MenuItem>
                <MenuItem value="ธนาคารซีไอเอ็มบี ไทย">ธนาคารซีไอเอ็มบี ไทย</MenuItem>
                <MenuItem value="ธนาคารยูโอบี">ธนาคารยูโอบี</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>สาขา</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="bookBankBranchName"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>เลขที่บัญชี</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="bookID"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  thousandSeparator: false,
                  decimalSeparator: "",
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                }}
                onChange={()=>{}}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>รายงานส่งธนาคาร ชื่อธนาคาร</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="reportBankBankName"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>รายงานส่งธนาคาร รหัสธนาคาร</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="reportBankBankID"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>รายงานส่งธนาคาร เลขที่บัญชี</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="reportBankBookBankID"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>รายงานส่งธนาคาร ชื่อผู้รับเงิน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="reportBankName"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>รายงานส่งธนาคาร เลขที่อ้างอิง</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="reportBankRef"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

      </Grid>
    </Box>
  )
}

export default PaymentInfo;