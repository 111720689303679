import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import XLSX from "xlsx";
import AlertResponse from "../../shared/general/AlertResponse";
import DrawerCustom from "../../shared/general/Drawer";
import DialogSelectDate from "./component/dialogWithSelectDate";
import { getEmailReportById } from "../../../../actions/email";
import { getProbationEmployees } from "../../../../actions/report";

const Probation = (props) => {
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: email } = useSelector((state) => state.email)
    const { result: probation } = useSelector((state) => state.employeesProbation);
    const { open, handleClose } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [data, setData] = useState({
        inputSearch: new Date(),
        value: "year",
        autoEmail: null,
        selectedCompany: null,
        ReportName: "probation",
        idEmployees: userProfile.idEmployees
    })

    useEffect(()=>{
        if(email)
        setData({ ...data, autoEmail: email.day });
    },[email])

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    useEffect(()=>{
        dispatch(getEmailReportById(data.idEmployees, "probation"))
    },[])

    useEffect(() => {
        dispatch(getProbationEmployees(data.selectedCompany, dayjs(data.inputSearch).startOf('month')))
    }, [data.inputSearch,data.selectedCompany])

    const handleSubmit = () => {
        if (probation.length > 0) {
            const elementList = [];
            for (let index = 0; index < probation.length; index++) {
                const element = {
                    "#": index + 1,
                    "ชื่อ": probation[index].firstname_TH,
                    "สกุล": probation[index].lastname_TH,
                    "Division": probation[index].divisionName,
                    "Department": probation[index].departmentName,
                    "Section": probation[index].sectionName,
                    "Unit/Process": probation[index].positionName,
                    "วันที่ครบทดลองงานรอบ1": dayjs(probation[index].probationR1Date).format("YYYY-MM-DD"),
                    "ผลการทดลองงาน": probation[index].probationR1Result,
                    "วันที่ครบทดลองงานรอบ2": dayjs(probation[index].probationR2Date).format("YYYY-MM-DD"),
                    "ผลการทดลองงาน": probation[index].probationR2Result,
                };
                elementList.push(element);
            }
            const workSheet = XLSX.utils.json_to_sheet(elementList);
            const workBook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workBook, workSheet, "สรุปทดลองงาน");
            XLSX.writeFile(workBook, "สรุปทดลองงาน.xlsx");

            handleChangeAlertType("success");
            handleOpenAlert(true);
            handleClose();
        } else {
            handleChangeAlertType("ไม่พบข้อมูล");
            handleOpenAlert(true);
        }
    }

    return (
        <DrawerCustom
            title="สรุปทดลองงาน"
            anchor="right"
            open={open}
            onClose={handleClose}
        >
              <DialogSelectDate
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                setData={setData}
                data={data}
                handleChangeAlertType={handleChangeAlertType}
                handleOpenAlert={handleOpenAlert}
                select={false}
                excel={probation}
            />
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>
    );
}

export default Probation;
