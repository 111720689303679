import React, { Fragment, useState } from "react";
import { Box, IconButton, LinearProgress, TableCell, TableRow, Typography, linearProgressClasses, styled } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 5,
  width: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      "#424242",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1976d2",
  },
}));

const BorderLinearOverProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 5,
  width: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#eeeeee",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#ff3034",
  },
}));


const Row = (props) => {

  const { data, depth, onDrawerOpen, onDrawerClose } = props;

  const [isExpand, setIsExpand] = useState(false);

  return (
    <Fragment>
      <TableRow>
        <TableCell style={{ minWidth: 200 }}>
          <Box display="flex" alignItems="center" onClick={() => {setIsExpand(prev => !prev)}} style={{cursor: "pointer", paddingLeft: depth? depth*16: 0, userSelect: "none"}}>
            {data.childrens.length > 0 && <Fragment>{isExpand? <ExpandLessIcon />: <ExpandMoreIcon />}</Fragment>}
            {data.childrens.length === 0 && <Box paddingLeft="24px"/>}
            <Typography fontSize="20px" fontWeight="500">{data.name}</Typography>
            {data.childrens.length > 0 && <Typography paddingLeft="8px">({data.childrens.length})</Typography>}
          </Box>
        </TableCell>
        <TableCell style={{ minWidth: 200 }}>
          <Typography>{data.level}</Typography>
        </TableCell>
        <TableCell style={{ minWidth: 200}}>
          <Box>
            <Typography textAlign="center">
              <span style={{fontSize: "28px", fontWeight: "500"}}>{data.manpowerActual}</span> /{" "}
              <span>{data.manpowerPlan !== null? data.manpowerPlan: data.manpowerActual}</span>
            </Typography>
            {(data.manpowerActual >= data.manpowerPlan || data.manpowerPlan === null)? (
              <BorderLinearOverProgress
                variant="determinate"
                value={100}
              />
            ) : (
              <BorderLinearProgress
                variant="determinate"
                value={(data.manpowerActual/data.manpowerPlan)*100}
              />
            )}
          </Box>
        </TableCell>
        <TableCell style={{ minWidth: 200, textAlign: "right" }}>
          <IconButton
            aria-label="edit"
            onClick={() => {
              onDrawerOpen({
                idOrganization: data.idOrganization,
                manpowerActual: data.manpowerActual,
                manpowerPlan: data.manpowerPlan,
                name: data.name,
                level: data.level,
              })
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
   
      {isExpand && data.childrens.length > 0? (
        <Fragment>
          {data.childrens.map(children => (
            <Row key={`${children.level}-${children.idOrganization}`} data={children} depth={depth? depth+1: 1} onDrawerOpen={onDrawerOpen} onDrawerClose={onDrawerClose}/>
          ))}
        </Fragment>
      ): null}
   
    </Fragment>
  )
}

export default Row;