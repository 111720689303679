import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Typography,
  Breadcrumbs,
  ToggleButtonGroup,
  ToggleButton,
  Link,
} from "@mui/material";

import OfficialDocument from "./official-documents";
import PaySlipPanel from "./pay-slip";
import TotalPanel from "./total";

import { getDepartment } from "../../../../actions/company";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const ReportAdminPage = (props) => {
  const { handleClose } = props;
  const dispatch = useDispatch();
  const [view, setView] = React.useState("pay-slip");

  useEffect(() => {
    dispatch(getDepartment());
  }, []);

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
    }
  };

  return (
    <StyledRoot>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link
          component="buton"
          onClick={handleClose}
          underline="hover"
          color="inherit"
        >
          รายงานทั้งหมด
        </Link>
        <Typography color="text.primary">สลิปเงินเดือน</Typography>
      </Breadcrumbs>
      <div style={{ marginBottom: 8 }}>
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          รายงาน
        </Typography>
      </div>
      <Fragment>
        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChangeView}
          aria-label="view"
          size="small"
        >
          <ToggleButton
            name="status"
            value="pay-slip"
            aria-label="pay-slip"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            สลิปเงินเดือน
          </ToggleButton>
          <ToggleButton
            name="status"
            value="official-documents"
            aria-label="official-documents"
            size="small"
            style={{ minWidth: 150 }}
          >
            รายงานราชการ
          </ToggleButton>
        </StyledToggleButtonGroup>

        {view === "pay-slip" && <PaySlipPanel />}
        {view === "official-documents" && <OfficialDocument />}
      </Fragment>
    </StyledRoot>
  );
};

export default ReportAdminPage;
