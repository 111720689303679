import React, { Fragment, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Checkbox, Container, Grid, IconButton, Rating, styled, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TableTheme from "../../../shared/general/TableTheme";

import DrawerRating from "./shared/drawerRating";
import DrawerNote from "./shared/drawerNote";
import DrawerInterviewAppointment from "./drawerInterviewAppointment";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import { getNewCandidatesByIdJobRequest, getNewCandidatesByIdBiddings } from "../../../../../actions/candidate";
import { getManager } from "../../../../../actions/manager";
import { stringToColor } from "../../../../../utils";

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
  marginTop: 30,
  "& .part-header": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color:"#000000",
    "& .MuiAvatar-root": {
      marginRight: 8,
      width: 40,
      height: 40,
    },
  },
  "& .part-action": {
    display: "flex",
    justifyContent: "right",
  },
});

const BoxRating = styled(Box)({
  display:"flex",
  alignItems:"center",
  "& .MuiRating-icon":{
    color: "#000000"
  },
  "& .rating span":{
    fontSize: 16
  }
});

const CompanyCandidateV2Page = (props) => {
  const dispatch = useDispatch();
  const { result : AllCandidates } = useSelector(state => state.newCandidate);
  const { result: manager } = useSelector((state) => state.manager);

  const [isOpenDrawerRating, setIsOpenDrawerRating] = useState({
    open: false,
    editId: null,
    value: null,
  });

  const [isOpenDrawerNote, setIsOpenDrawerNote] = useState({
    open: false,
    editId: null,
    value: null,
  })

  const [isOpenDrawerInterviewAppointment, setIsOpenDrawerInterviewAppointment] = useState(false)

  const [selectedRow, setSelectedRow] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if(props.match.params.id && props.match.params.type){
      if(props.match.params.type === 'job'){
        let id = parseInt(props.match.params.id);
        dispatch(getNewCandidatesByIdJobRequest(id));
      }
      else if(props.match.params.type === 'bidding'){
        let id = parseInt(props.match.params.id);
        dispatch(getNewCandidatesByIdBiddings(id));
      }
    }
    dispatch(getManager());
  },[props.match.params.id]);

  const onClickCheckbox = (index) => {
    
    setIsSelectedAll(false);
    let tempSelected = selectedRow

    if(tempSelected.includes(index)){
      tempSelected = tempSelected.filter(i => i!==index);
    }
    else{
      tempSelected.push(index)
    }
    setSelectedRow([...tempSelected])
  }

  const onClickSelectedAll = () => {
    if(!isSelectedAll){
      setSelectedRow([...AllCandidates.listCandidate]);
      setIsSelectedAll(true);
    }
    else{
      const temp = [];
      setSelectedRow(temp);
      setIsSelectedAll(false);
    }
  }

  let timeoutDisplay;

  const handleCloseDisplay = () => {
    clearInterval(timeoutDisplay);
    setIsSuccess(false);
  };

  const handleCountDownTimeSet = () => {
    timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
  };

  const Displayage = (date) => {
    let month = dayjs().diff(date, 'month');
    return `${Math.floor(month / 12)} ปี${ month % 12 > 0 ? ` ${month % 12} เดือน` : ''}`;
  };

  const columns = [
    {
      field: "name",
      headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Checkbox onChange={onClickSelectedAll} checked={isSelectedAll} style={{ marginRight:"10px" }} />
          <i className="fa-solid fa-user-group" style={{ color: "#ffffff", fontSize:"1.1rem", marginRight:"10px" }} />
          <Typography style={{color:"#ffffff"}}>ชื่อ-สกุล</Typography>
        </Box>
      ),
      width: 300,
      format: (params) => (
        <Box style={{ display:"flex", alignItems:"center" }}>
          <Checkbox onChange={() => { onClickCheckbox(params) }} checked={selectedRow.includes(params)} style={{ marginRight:"10px" }} />
          <Tooltip title="ดูประวัติ" placement="top-start">
            <Box className="column-name" component={Link} to={`/hunter/peer/profile/${params.idManpower}`}>
              <Avatar sx={{ bgcolor: stringToColor(`${params.name} ${params.lastName}`) }} children={`${params.name[0]}${params.lastName[0]}`} />
              <Box>
                <Typography>{`${params.name} ${params.lastName}`}</Typography>
                <BoxRating>
                  <Rating className="rating" readOnly value={params.rating} precision={0.5} />
                  <Typography style={{ marginLeft:"5px", fontSize: 14, color:"#000000" }}>
                    {`(${params.rating})`}
                  </Typography>
                </BoxRating>
              </Box>
            </Box>
          </Tooltip>
        </Box>
      )
    },
    {
      field: "vendorName",
      headerName: (
        <Box style={{ display:"flex", alignItems:"center" }}>
          <i className="fa-solid fa-building" style={{ color: "#ffffff", fontSize:"1.1rem", marginRight:"10px" }} />
          <Typography style={{color:"#ffffff"}}>สังกัด</Typography>
        </Box>
      ),
      width:230,
      format: (params) => (
        <Typography noWrap>{params.fullname}</Typography>
      )
    },
    ...props.match.params.type === 'bidding' ? [{
      field: "positionName",
      headerName: (
        <Box style={{ display:"flex", alignItems:"center" }}>
          <i className="fa-solid fa-user-tie" style={{ color: "#ffffff", fontSize:"1.1rem", marginRight:"10px" }} />
          <Typography style={{color:"#ffffff"}}>ตำแหน่งที่ถูกส่ง</Typography>
        </Box>
      ),
      width:230,
      format: (params) => (
        <Typography noWrap>{params.positionName || "-"}</Typography>
      )
    }] : [],
    {
      field: "age",
      headerName: (
        <Box style={{ display:"flex", alignItems:"center" }}>
          <i className="fa-solid fa-user" style={{ color: "#ffffff", fontSize:"1.1rem", marginRight:"10px" }} />
          <Typography style={{color:"#ffffff"}}>อายุ</Typography>
        </Box>
      ),
      width: 88,
      format: (params) => (
        <Box style={{ display:"flex" }}>
          <Typography >{( params.dateOfBirth &&  Displayage(params.dateOfBirth) ) || "-"}</Typography>
        </Box>
      )
    },
    {
      field: "OESY",
      headerName: (
        <Box style={{ display:"flex", alignItems:"center" }}>
          <i className="fa-solid fa-briefcase" style={{ color: "#ffffff", fontSize:"1.1rem", marginRight:"10px" }} />
          <Typography style={{color:"#ffffff"}}>อายุงาน</Typography>
        </Box>
      ),
      width: 100,
      format: (params) => (
        <Box style={{ display:"flex" }}>
          <Typography >{ `${params['OESY(Y)'] || '0'} ปี ${params['OESY(M)'] || '0'} เดือน` || "-"}</Typography>
        </Box>
      )
    },
    {
      field: "lastPosition",
      headerName: (
        <Box style={{ display:"flex", alignItems:"center" }}>
          <i className="fa-solid fa-address-card" style={{ color: "#ffffff", fontSize:"1.1rem", marginRight:"10px" }} />
          <Typography style={{color:"#ffffff"}}>ตำแหน่งงานล่าสุด</Typography>
        </Box>
      ),
      width: 230,
      format: (params) => (
        <Box style={{ display:"flex" }}>
          <Typography>{params.lastPositionName || "-"}</Typography>
        </Box>
      )
    },
    {
      field: "sentDate",
      headerName: (
        <Box style={{ display:"flex", alignItems:"center" }}>
          <i className="fa-solid fa-calendar-days" style={{ color: "#ffffff", fontSize:"1.1rem", marginRight:"10px" }} />
          <Typography style={{color:"#ffffff"}}>วันที่ส่ง</Typography>
        </Box>
      ),
      width: 100,
      format: (params) => (
        <Typography>
          {dayjs(params.createdAt).format("DD/MM/YYYY")}
        </Typography>
      )
    },
    {
      field: "action",
      headerName: "",
      format: (params, index) => (
        <div className="part-action">

          <Tooltip title="ให้คะแนน" placement="top">
            <IconButton 
              style={{ color:"#faaf00" }}
              onClick={()=>{
                setIsOpenDrawerRating({
                    open: true, 
                    editId: params.idCandidate, 
                    value: params.rating || 0
                })
              }}
            >
              <i className="fa-solid fa-star"></i>
            </IconButton>
          </Tooltip>
          {isOpenDrawerRating.open && isOpenDrawerRating.editId === params.idCandidate && (
            <DrawerRating
              open={isOpenDrawerRating.open}
              onClose={()=>{
                setIsOpenDrawerRating({
                  open: false,
                  editId: null,
                  value: null,
                })
                setSelectedRow([]);
              }}
              value={isOpenDrawerRating}
              hanldeSubmit={() => {
                if(props.match.params.type === 'job'){
                  dispatch(getNewCandidatesByIdJobRequest(parseInt(props.match.params.id)))
                } 
                else if(props.match.params.type === 'bidding'){
                  dispatch(getNewCandidatesByIdBiddings(parseInt(props.match.params.id)));
                }
              }}
            />
          )}

          <Tooltip title="โน๊ต" placement="top">
            <IconButton 
              style={{ color:"#007afe" }}
              onClick={()=>{
                setIsOpenDrawerNote({
                  open: true, 
                  editId: params.idCandidate, 
                  value: params.note || ""
                })
              }}
            >
              <i className="fa-regular fa-memo"></i>
            </IconButton>
          </Tooltip>
          {isOpenDrawerNote.open && isOpenDrawerNote.editId === params.idCandidate && (
            <DrawerNote
              open={isOpenDrawerNote.open}
              onClose={()=>{
                setIsOpenDrawerNote({
                  open: false,
                  editId: null,
                  value: null,
                });
                setSelectedRow([]);
              }}
              value={isOpenDrawerNote}
              hanldeSubmit={() => {
                if(props.match.params.type === 'job'){
                  dispatch(getNewCandidatesByIdJobRequest(parseInt(props.match.params.id)))
                } 
                else if(props.match.params.type === 'bidding'){
                  dispatch(getNewCandidatesByIdBiddings(parseInt(props.match.params.id)));
                }
              }}
            />
          )}
        </div>
      )
    }
  ]

  const GroupBy = (array, key) => {
    let temp = array.reduce((preValue, curValue) => {
      (preValue[curValue[key]] = preValue[curValue[key]] || []).push(curValue);
      return preValue;
    },{})
    return temp;
  };

  const GroupSelectedListCandidates = () => {
    if (props.match.params.type === 'job') {
      return GroupBy(selectedRow,'idJobRecruit');
    } else {
      return GroupBy(selectedRow,'idBiddingPositions');
    }
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {!isSuccess && (
          <Fragment>
            <div className="part-header">
              <Typography variant="h4" sx={{color:"#000000"}}>
                {`ผู้สมัคร / ${(AllCandidates && AllCandidates.nameInterview) || "-"}`}
              </Typography>
              <div className="item-total">
                <Typography variant="h4" sx={{color:"#000000",justifyContent:"center"}}>
                  {(AllCandidates && AllCandidates.listCandidate && AllCandidates.listCandidate.length) || 0}
                </Typography>
                <Typography color="text.secondary">{`จำนวนผู้สมัคร`}</Typography>
              </div>
            </div>

            <TableTheme
              columns={columns}
              rows={(AllCandidates && AllCandidates.listCandidate) || []}
              textEmpty="ไม่พบผู้สมัคร"
              minHeight={200}
            />

            <Grid container justifyContent="flex-end" style={{ marginTop:"20px" }}>
              <ButtonBlue
                variant="contained"
                disabled={selectedRow.length <= 0 ? true : false}
                onClick={() => {
                  setIsOpenDrawerInterviewAppointment(true);
                }}
              >
                นัดสัมภาษณ์
              </ButtonBlue>
            </Grid>
          </Fragment>
        )}
        {isSuccess && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "calc(100vh - 160px)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "80px",
              }}
            >
              <img
                style={{ width: 300, marginBottom: "20px" }}
                src={`${process.env.PUBLIC_URL}/assets/background/complete.jpg`}
                // alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"
              />
              <Typography
                className="success"
                variant="h4"
                sx={{marginBottom: "40px",color:"#ffffff"}}
              >
                นัดการสัมภาษณ์สำเร็จ
              </Typography>
              <ButtonBlue
                variant="outlined"
                sx={{ borderRadius: "25px", width: "100px",color:"#ffffff" }}
                onClick={() => {
                  setIsSuccess(false);
                }}
              >
                <Typography variant="h6">ปิด</Typography>
              </ButtonBlue>
            </div>
          </div>
        )}
      </Container>
      {isOpenDrawerInterviewAppointment && (
        <DrawerInterviewAppointment
          open={isOpenDrawerInterviewAppointment}
          onClose={() => {
            setIsOpenDrawerInterviewAppointment(false);
            setSelectedRow([]);
          }}
          listCandidate={GroupSelectedListCandidates()}
          manager={manager}
          type={props.match.params.type}
          id={parseInt(props.match.params.id)}
          setIsSuccess={setIsSuccess}
          handleCountDownTimeSet={handleCountDownTimeSet}
        />
      )}
    </StyledRoot>
  );
};

export default CompanyCandidateV2Page;
