import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "react-use";
import { makeStyles } from "@mui/styles";
import {Typography, 
    Grid, 
    Paper,    
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import CardStyle from "../../../shared/general/Card";
import { getCostElement } from "../../../../../actions/costElement";
import Setting from "./Setting";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: "30px",
        paddingBttom: "100px",
        "& .MuiTypography-h6": {

            fontSize: "1.5rem",
        },
        "& .MuiAccordionSummary-root": {
            paddingLeft: 8,
            paddingRight: 16,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
    },
    headingPage: {
        marginBottom: 32,
    },
    wrapIcon: {
        display: "flex",
        alignItems: "center",
    },
    iconHeader: {
        marginRight: 16,
    },
    paper: {
        padding: 8,
        borderRadius: "20px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    },
}));

const StyledHeadingPage = styled("div")({
    marginBottom: 16,
});
const StyledEmpty = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 350,
});

const dataMock = [
    {
        name: "เงินเดือน",
        costElement: "",
        isActive: 1
    }
]

function CostElement() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { result: costElement } = useSelector((state) => state.costElement);
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [typeDialog, setTypeDialog] = useState();

    useEffect(() => {
        dispatch(getCostElement())
    }, [openDialog]);

    const handleClose = () => {
        setTypeDialog(null)
        setOpenDialog(false)
        setData(null)
    }

    return (
        <div className={classes.root}>

            <StyledHeadingPage>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">ตั้งค่า COST ELEMENT</Typography>
                    </Grid>
                    <Grid item>
                        <ButtonBlue
                            variant={"contained"}
                            startIcon={<AddIcon />}
                            onClick={() => {
                                setOpenDialog(true)
                                setTypeDialog("Add")
                            }}
                        >
                            เพิ่ม รายการ
                        </ButtonBlue>
                    </Grid>
                </Grid>
            </StyledHeadingPage>
            <CardStyle style={{ padding: 16, marginBottom: 20, }}>
                {costElement && costElement.length > 0 ?
                    <TableContainer component={Paper} sx={{ minWidth: 600, marginTop: 6 }}>
                        <Table sx={{ minWidth: 600 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">ชื่อรายการ</TableCell>
                                    <TableCell align="center">cost element</TableCell>
                                    <TableCell align="center">สถานะ</TableCell>
                                    <TableCell align="center">ตั้งค่า</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {costElement.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{item.name} </TableCell>
                                        <TableCell align="center">{item.costElementName}</TableCell>
                                        <TableCell align="center">{item.isActive == 1 ?
                                            <Typography > active</Typography>
                                            : <Typography color={"red"}> inactive</Typography>
                                        }</TableCell>
                                        <TableCell align="center">{<EditIcon
                                            onClick={() => {
                                                setOpenDialog(true)
                                                setTypeDialog("Edit")
                                                setData(item)
                                            }} />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <StyledEmpty className="empty-holiday">
                        <Typography color="text.secondary" align="center">
                            ไม่มีcost element
                        </Typography>
                    </StyledEmpty>
                }
            </CardStyle>

            {openDialog ?
                <Setting
                    typeDialog={typeDialog}
                    handleClose={handleClose}
                    open={openDialog}
                    data={data}
                /> : ""
            }

        </div>
    );
}

export default CostElement;
