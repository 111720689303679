import { Avatar, Box, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Visibility } from '@mui/icons-material';
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import DialogShowComment from "./DialogShowComment";
import StatusRequest from "../../shared/general/stausRequest";
import TableCustom from "../../shared/tableCustom";

const RequestLeave = () => {
  const { result: leaveEmployeesList } = useSelector((state) => state.leaveEmployees);
  const { result: leaveEmployeeWithdrawsList } = useSelector((state) => state.leaveEmployeesWithDraw);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [historyRows, setHistoryRows] = useState([]);
  const [dialogComment, setDialogComment] = useState({
    isOpen: false,
    comment: null
  });

  const columns = [
    {
      name: "ชื่อ-สกุล",
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/>
          <Box flexGrow={1}>
            <Typography>{row.firstname_TH} {row.lastname_TH}</Typography>
            <Typography color="text.third" fontSize="14px">{row.positionName}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: "ประเภท",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idLeaveEmployeesWithdraw && (
            <ChipWithDrawCustom />
          )}
          <Typography fontSize={14}>{row.name}</Typography>
        </Fragment>
      )
    },
    {
      name: "เริ่มต้น",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign: "center"}}>
          <Typography>{dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}</Typography>
          {(row.isFullDay === 1)? (
            <Typography>เต็มวัน</Typography>
          ) : (
            <Typography>{dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
          )}
        </Box>
      )
    },
    {
      name: "สิ้นสุด",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign: "center"}}>
          <Typography>{dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}</Typography>
          {(row.isFullDay === 1)? (
            <Typography>เต็มวัน</Typography>
          ) : (
            <Typography>{dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
          )}
        </Box>
      )
    },
    {
      name: "เหตุผล",
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">{row.description}</Typography>
        </Box>
      )
    },
    {
      name: "หัวหน้างาน",
      minWidth: "280px",
      width: "280px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <StatusRequest
            boxSize={40}
            fontSize={22}
            borderRadius={8}
            status={getStatusLeaveManagerApprove(row)}
          />
          <Avatar sx={{marginLeft: "8px", marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/>
          <Box flexGrow={1}>
            <Typography>{row.manager_firstname_TH} {row.manager_lastname_TH}</Typography>
            <Typography color="text.third" fontSize="14px">{row.manager_positionName}</Typography>
          </Box>
        </Box>
      )
    },
  ]

  const historyColumns = [
    {
      name: "สถานะ",
      cellRender: (row) => (
        <StatusRequest
          boxSize={40}
          fontSize={22}
          borderRadius={8}
          status={getStatusLeave(row)}
        />
      )
    },
    ...columns,
    {
      name: "วันที่อนุมัติ",
      minWidth: "150px",
      headerTextAlign: "center",
      cellRender: (row) => (
        <Box sx={{textAlign: "center"}}>
          {row.approveDateText && 
            <>
              <Typography>{dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}</Typography>
              <Typography>{dayjs(row.approveDateText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
            </>
          }
        </Box>
      )
    },
    {
      name: "เหตุผลหลังอนุมัติ",
      minWidth: "250px",
      width: "250px",
      cellRender: (row) => (
        <Box>
          {row.commentManager && (
            <Box
              style={{ display:"flex", alignItems:"center" }}
            >
              <Typography
                style={{
                  width: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical"
                }}
              >{row.commentManager}</Typography>
              <IconButton
                style={{ marginLeft: 8 }}
                onClick={() => setDialogComment({
                  isOpen: true,
                  comment: row.commentManager
                })}
              >
                <Visibility />
              </IconButton>
            </Box>
          )}
        </Box>
      )
    }
  ]

  const getStatusLeave = (requestLeave) => {
   
    if(requestLeave.idLeaveEmployeesWithDraw && requestLeave.managerApprove === userProfile.idEmployees){
      if(requestLeave.isApprove === 1){
        return 1
      } else if(requestLeave.isApprove === 0){
        return 0
      } else {
        return 2
      }
    }

    if(requestLeave.isApprove === 1){
      return 1
    } else if(requestLeave.isApprove === 0){
      return 0
    } else {
      return 2
    }

  }

  const getStatusLeaveManagerApprove = (requestLeave) => {

    if(requestLeave.fillInApprove){
      return 3
    }
   
    if(requestLeave.idLeaveEmployeesWithDraw && requestLeave.managerApprove === userProfile.idEmployees){
      if(requestLeave.isApprove === 1){ 
        return 1
      } else if(requestLeave.isApprove === 0){
        return 0
      } else {
        return 2
      }
    }

    if(requestLeave.isApprove === 1){
      return 1
    } else if(requestLeave.isApprove === 0){
      return 0
    } else {
      return 2
    }

  }

  useEffect(() => {
    const tempHistory = [];

    leaveEmployeesList.map(r => {
      if(r.isApprove !== null){
        tempHistory.push(r)
      }
    })

    leaveEmployeeWithdrawsList.map(r => {
      if(r.isApprove !== null){
        tempHistory.push(r)
      }
    })
    setHistoryRows(tempHistory.sort(function (a, b) {return new Date(b.approveDate) - new Date(a.approveDate)}))
  }, [])

  return (
    <Box>
      <TableCustom columns={historyColumns} rows={(leaveEmployeesList && leaveEmployeeWithdrawsList)? historyRows: []} />
      {dialogComment.isOpen && 
        <DialogShowComment 
          open={dialogComment.isOpen}
          onClose={() => setDialogComment({
            isOpen: false,
            comment: null
          })}
          comment={dialogComment.comment}
        />
      }
    </Box>
  )
}

export default RequestLeave;