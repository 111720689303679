import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
} from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getWarningLetterByIdEmployee,
  updateWarningLetterStatus,
} from "../../../../actions/warningLetter";
import CardStyle from "../../shared/general/Card";
import DialogDetail from "./dialogDetail";
import ChipWarningLevel from "../../admin/warningLetter/chipWarningLevel";
import ChipLetterStatus from "../../admin/warningLetter/chipLetterStatus";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const WarningLetter = () => {
  const dispatch = useDispatch();
  const { result: warningLetter } = useSelector((state) => state.warningLetter);

  const [openDetail, setOpenDetail] = useState(false);
  const [selectLetter, setSelectLetter] = useState(null);

  const handleClickOpenDetail = (item) => {
    setOpenDetail(true);
    setSelectLetter(item);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleUpdateStatus = async (formData) => {
    const res = await dispatch(updateWarningLetterStatus(formData));
    if (res) {
      handleCloseDetail();
      dispatch(getWarningLetterByIdEmployee());
    }
  };

  useEffect(() => {
    dispatch(getWarningLetterByIdEmployee());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <WrapHeader>
          <Typography variant="h4">หนังสือเตือนและการลงโทษ</Typography>
        </WrapHeader>
        <CardStyle style={{ padding: 16 }}>
          {selectLetter && openDetail && (
            <DialogDetail
              open={openDetail}
              handleClose={handleCloseDetail}
              data={selectLetter}
              handleUpdateStatus={handleUpdateStatus}
            />
          )}
          {warningLetter && warningLetter.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ระดับการเตือน</TableCell>
                    <TableCell align="center">วันที่</TableCell>
                    <TableCell align="center">ชื่อหนังสือเตือน</TableCell>
                    <TableCell align="center">สถานะ</TableCell>
                    <TableCell align="center">รายละเอียด</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {warningLetter.map((item) => (
                    <TableRow key={item.idWarningLetter}>
                      <TableCell align="center">
                        <ChipWarningLevel id={item.idWarningLevel} label={item.level} />
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(item.createDate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="center">{item.title}</TableCell>
                      <TableCell align="center">
                        <ChipLetterStatus status={item.accept} />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          onClick={() => handleClickOpenDetail(item)}
                        >
                          รายละเอียด
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>ไม่มีหนังสือเตือน</Typography>
          )}
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default WarningLetter;
