import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getNewemployee = (filter, start) => {
    return httpClient.get(
        `newEmployees?filter=${filter}&start=${dayjs(start).format(
            "YYYY-MM-DD"
        )}`
    );
};

const getResignEmployee = (filter, start) => {
    return httpClient.get(
        `resignEmployees?filter=${filter}&start=${dayjs(start).format(
            "YYYY-MM-DD"
        )}`
    );
};

const getcontractEmployee = (filter, start) => {
    return httpClient.get(
        `contractEmployees?filter=${filter}&start=${dayjs(start).format(
            "YYYY-MM-DD"
        )}`
    );
};

const getAttendanceEmployees = (filter, start) => {
    return httpClient.get(
        `AttendanceEmployees?filter=${filter}&start=${dayjs(start).format(
            "YYYY-MM-DD"
        )}`
    );
};

const getProbationEmployees = (filter, start) => {
    return httpClient.get(
        `ProbationEmployees?filter=${filter}&start=${dayjs(start).format(
            "YYYY-MM-DD"
        )}`
    );
};

const getTaxemployee = (filter, start) => {
    return httpClient.get(
        `TaxEmployees?filter=${filter}&start=${dayjs(start).format(
            "YYYY-MM-DD"
        )}`
    );
};
const getProvidentemployee = (filter, start) => {
    return httpClient.get(
        `Providentemployee?filter=${filter}&start=${dayjs(start).format(
            "YYYY-MM-DD"
        )}`
    );
};

const getPayrollemployee = (filter, start) => {
    return httpClient.get(
        `Payrollemployee?filter=${filter}&start=${dayjs(start).format(
            "YYYY-MM-DD"
        )}`
    );
};

const getPayrollCompany = (filter, start) => {
    return httpClient.get(
        `PayrollCompany?filter=${filter}&start=${dayjs(start).format(
            "YYYY-MM-DD"
        )}`
    );
};

const getAllLeaveWithDraw = (filter, search) => {
    return httpClient.get(
        `AllLeaveWithDraw`, {
        params: {
            filter: filter ? filter : undefined,
            start:
                search && search.start
                    ? dayjs(search.start).format("YYYY-MM-DD")
                    : undefined,
            end:
                search && search.end
                    ? dayjs(search.end).format("YYYY-MM-DD")
                    : undefined,
        },
    }
    );
};

const getAllRequestTime = (filter, search) => {
    return httpClient.get(
        `AllRequestTime`, {
        params: {
            filter: filter ? filter : undefined,
            start:
                search && search.start
                    ? dayjs(search.start).format("YYYY-MM-DD")
                    : undefined,
            end:
                search && search.end
                    ? dayjs(search.end).format("YYYY-MM-DD")
                    : undefined,
        },
    }
    );
};

const getAllRequestTimeWithDraw = (filter, search) => {
    return httpClient.get(
        `AllRequestTimeWithDraw`, {
        params: {
            filter: filter ? filter : undefined,
            start:
                search && search.start
                    ? dayjs(search.start).format("YYYY-MM-DD")
                    : undefined,
            end:
                search && search.end
                    ? dayjs(search.end).format("YYYY-MM-DD")
                    : undefined,
        },
    }
    );
};

const getLeaveRequestByCompany = (filter, search) => {
    return httpClient.get(
        `LeaveRequestByCompany`, {
        params: {
            filter: filter ? filter : undefined,
            start:
                search && search.start
                    ? dayjs(search.start).format("YYYY-MM-DD")
                    : undefined,
            end:
                search && search.end
                    ? dayjs(search.end).format("YYYY-MM-DD")
                    : undefined,
        },
    }
    );
};
export default {
    getNewemployee,
    getResignEmployee,
    getcontractEmployee,
    getAttendanceEmployees,
    getProbationEmployees,
    getTaxemployee,
    getProvidentemployee,
    getAllLeaveWithDraw,
    getAllRequestTime,
    getAllRequestTimeWithDraw,
    getLeaveRequestByCompany,
    getPayrollemployee,
    getPayrollCompany
}