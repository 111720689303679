import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Dropzone, { useDropzone } from "react-dropzone";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AlertResponse from "../../shared/general/AlertResponse";
import AddPhotoIcon from "../../../../components/pages/assets/add.png";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  addDocumentType,
  editDocumentType,
} from "../../../../actions/document";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});
const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const DialogType = (props) => {
  const dispatch = useDispatch();
  const { open, mode, handleClose, data } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const { result: docGroup } = useSelector((state) => state.documentGroup);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const [formData, setFormData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (open) {
      if (mode === "edit") {
        setFormData({
          idDocumentType: data.idDocumentType,
          documentTypeName: data.documentTypeName,
          idDocumentGroup: data.idDocumentGroup,
          idCompany: data.idCompany,
          isShow: data.isShow,
          detail: data.detail,
          file: null,
        });
      } else {
        setFormData({
          documentTypeName: null,
          idDocumentGroup: null,
          isShow: 0,
          detail: null,
          file: null,
        });
      }
    }
  }, [open]);

  const handleSubmit = async () => {
    if (!formData.idDocumentGroup || !formData.documentTypeName) {
      handleOpenAlert(true);
      handleChangeAlertType("กรุณากรอกข้อมูลให้ครบ");
    } else {
      const postData = new FormData();
      let data;
      if (mode === "edit") {
        data = {
          idDocumentType: formData.idDocumentType,
          isShow: formData.isShow,
          idCompany: formData.idCompany,
          updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
          detail: formData.detail,
        };
      } else {
        data = {
          documentTypeName: formData.documentTypeName,
          idDocumentGroup: formData.idDocumentGroup,
          idCompany: 1,
          isShow: formData.isShow,
          updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
          createDate: dayjs(new Date()).format("YYYY-MM-DD"),
          detail: formData.detail,
        };
      }

      Object.keys(data).map((k) => {
        postData.append(k, JSON.stringify(data[k]));
      });

      if (formData.file) {
        postData.append("file", formData.file);
      }

      if (mode === "Add") {
        const result = await dispatch(addDocumentType(postData));
        if (result) {
          handleChangeAlertType("success");
          handleOpenAlert(true);
          handleClose();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      } else {
        const result = await dispatch(editDocumentType(postData));
        if (result) {
          handleChangeAlertType("success");
          handleOpenAlert(true);
          handleClose();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      }
    }
  };

  return (
    <DrawerCustom
      title="รายละเอียดเอกสาร"
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && (
        <StyledRoot>
          <Grid
            container
            spacing={2}
            component="form"
            noValidate
            autoComplete="off"
          >
            {mode === "edit" ? (
              <>
                <Grid item xs={12} className="GridTopicInput">
                  ชื่อเอกสาร {formData.documentTypeName}
                </Grid>
                <Grid item xs={12} className="GridTopicInput">
                  หมวด {formData.idDocumentGroup}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} className="GridTopicInput">
                  ชื่อเอกสาร
                </Grid>
                <Grid item xs={12}>
                  <TextFieldTheme
                    name="documentTypeName"
                    fullWidth
                    onChange={handleChange}
                    value={formData.documentTypeName}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="GridTopicInput">
                  หมวด
                </Grid>
                <Grid item xs={12} sm={9}>
                  <FormControl fullWidth>
                    <Select
                      name="idDocumentGroup"
                      inputFormat={""}
                      value={formData.idDocumentGroup}
                      onChange={handleChange}
                    >
                      {docGroup.map((row) => (
                        <MenuItem value={row.idDocumentGroup}>
                          {row.documentGroupName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={3} className="GridTopicInput">
              แสดงในหน้าแรก
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormControl fullWidth>
                <Select
                  name="isShow"
                  inputFormat={""}
                  value={formData.isShow}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>แสดง</MenuItem>
                  <MenuItem value={0}>ไม่แสดง</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} className="GridTopicInput">
              รายละเอียด
            </Grid>
            <Grid item xs={12}>
              <TextFieldTheme
                name="detail"
                fullWidth
                multiline
                rows={3}
                onChange={handleChange}
                value={formData.detail}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="text.third"
                variant="body2"
                paddingBottom="16px"
              >
                แนบไฟล์
              </Typography>
              {!formData.file && (
                <StyledDropzone>
                  <Dropzone
                    accept="application/pdf"
                    maxFiles={1}
                    multiple={false}
                    maxSize={3145728}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setFormData({ ...formData, file: acceptedFiles[0] });
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone-leave" })}>
                        <div className="inner-dropzone">
                          <input {...getInputProps()} />
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img alt="add" src={AddPhotoIcon} />
                            </div>
                            <Typography
                              variant="caption"
                              style={{ lineHeight: 0.5, color: "#999999" }}
                            >
                              Allowed *.pdf, max size of 3 MB
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDropzone>
              )}
              {formData.file && (
                <Box sx={{ position: "relative", textAlign: "center" }}>
                  <IconButton
                    sx={{ position: "absolute", top: 0, right: 0 }}
                    color="error"
                    onClick={() => {
                      setFormData({ ...formData, file: null });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <embed
                    src={URL.createObjectURL(formData.file)}
                    width="250"
                    height="200"
                  ></embed>
                </Box>
              )}
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
              บันทึกข้อมูล
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogType;
