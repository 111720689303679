import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Chip,
  Collapse,
  Container,
  IconButton,
  Rating,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import AccessTime from '@mui/icons-material/AccessTime';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import { Link } from "react-router-dom";

import {
  getInterviewScheduleAndCandidatesByIdCompany,
  getHistoryInterviewScheduleAndCandidatesByIdcompany,
  updateConcludsionInterview,
  updateResultAppointedEachCandidate,
} from "../../../../../actions/candidate";

import { ContentCopy } from "@mui/icons-material";

import DrawerNote from "./shared/drawerNote";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogConfirmInterview from "./dialogConfirmInterview";
import DialogStartDate from "./dialogStartDate";
import DialogWarning from "./dialogWarning";

import { CopyToClipboard } from "react-copy-to-clipboard";
import MenuUserRecruit from "../openjob/share/menuUserRecruit";
import { stringToColor } from "../../../../../utils";

const StyledRoot = styled(Box)(({ theme }) => ({
  background: "#FFFFFF !important",
  "& .part-header": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "& .MuiButtonBase-root": {
        marginTop: "16px",
      },
      "& .BoxhistoryButton": {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  },
  "& .TopicText": {
    color: "#ffffff",
  },
  "& .BoxTextAndIcon": {
    display: "flex",
    alignItems: "center",
  },
  "& .menu-button": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
  },
}));

const StyledTable = styled(TableContainer)(({ theme }) => ({
  maxWidth: "100%",
  minHeight: "500px",
  "& .MuiTable-root": {
    tableLayout: "fixed",
  },
  "& .IconRating": {
    fontSize: "16px",
  },
  "& .MuiTableRow-root": {
    width: "100%",
    "& .MuiTableCell-root": {
      backgroundColor: "#ffffff",
      borderBottom: "none",
      zIndex: 2,
      fontSize: "16px",
      padding: "16px 0px 16px 0px",
      "&:first-of-type": {
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      "&:last-of-type": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
    "& .RowCandidate": {
      backgroundColor: "#AD9ED5",
      color: "#3B2E5C",
    },
  },
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      color: "#ffffff",
      backgroundColor: "#800080",
      borderBottom: 0,
      "& .IconTableHeadSchedule": {
        color: theme.palette.primary.dark,
        fontSize: "1.1rem",
        marginRight: "10px",
      },
      "&:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      "&:last-of-type": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
  },
  "& .MuiRating-icon": {
    color: "#ffffffab",
  },
}));

const WaitingResultChip = styled(Chip)({
  width: "110px",
  color: "#ff9800",
  fontWeight: 500,
  backgroundColor: "#fff0ce",
});

const PassResultChip = styled(Chip)({
  width: "110px",
  color: "#0b5f10",
  fontWeight: 500,
  backgroundColor: "#76d576",
});

const FailResultChip = styled(Chip)({
  width: "110px",
  color: "#b81a1a",
  fontWeight: 500,
  backgroundColor: "#eb7d7d",
});

const ButtonCopy = styled(Box)({
  width: 30,
  minWidth: 30,
  height: 30,
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#c4c4c4",
  "& .icon": {
    fontSize: "18px",
  },
});

const CustomButton = styled(ButtonBlue)(({ colorbutton }) => ({
  backgroundColor:
    colorbutton === "green"
      ? "#4b924f"
      : colorbutton === "red"
      ? "#e93d3d"
      : "#ff9800",
  "&:hover": {
    backgroundColor:
      colorbutton === "green"
        ? "#2e7d32"
        : colorbutton === "red"
        ? "#d32f2f"
        : "#cc7a00",
  },
}));

const RowCandidate = (props) => {
  let { candidate, isHistory, updateStatus, indexSchedule, updateNote } = props;
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [isOpenDrawerNote, setIsOpenDrawerNote] = useState({
    open: false,
    editId: null,
    value: null,
  });

  const DisplayTime = (time) => {
    let TimeSplit = time.split(":");
    return dayjs().hour(TimeSplit[0]).minute(TimeSplit[1]).format("HH.mm");
  };

  const handleSubmitStartDate = async (value) => {
    let data = {
      id: candidate.idCandidate,
      idCompany: candidate.idCompany,
      isPass: true,
      isFail: false,
      workStartDate: value.workStartDate,
    };
    const res = await dispatch(updateResultAppointedEachCandidate(data));
    if (res) {
      dispatch(getInterviewScheduleAndCandidatesByIdCompany());
      updateStatus(
        "Success",
        indexSchedule,
        candidate.idCandidate,
        value.workStartDate
      );
      setOpenDialog(false);
    }
  };

  const handleFail = async () => {
    let data = {
      id: candidate.idCandidate,
      idCompany: candidate.idCompany,
      isPass: false,
      isFail: true,
      workStartDate: null,
    };
    const res = await dispatch(updateResultAppointedEachCandidate(data));
    if (res) {
      dispatch(getInterviewScheduleAndCandidatesByIdCompany());
      updateStatus("Failed", indexSchedule, candidate.idCandidate);
      setOpenDialog(false);
    }
  };

  const handleWaiting = async () => {
    let data = {
      id: candidate.idCandidate,
      isPass: false,
      isFail: false,
      workStartDate: null,
    };
    const res = await dispatch(updateResultAppointedEachCandidate(data));
    if (res) {
      dispatch(getInterviewScheduleAndCandidatesByIdCompany());
      updateStatus("Waiting", indexSchedule, candidate.idCandidate);
      setOpenDialog(false);
    }
  };

  return (
    <TableRow>
      <TableCell
        sx={{}}
        className={clsx("RowStart", {
          RowSelected: !isHistory && !!candidate.isAppointmentSuccess,
          RowFail: !isHistory && !!candidate.isAppointmentFailed,
        })}
      >
        <Typography sx={{ marginLeft: "10px" }}>
          {DisplayTime(candidate.appointmentStartTime)}
        </Typography>
      </TableCell>
      <TableCell
        className={clsx({
          RowSelected: !isHistory && !!candidate.isAppointmentSuccess,
          RowFail: !isHistory && !!candidate.isAppointmentFailed,
        })}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "#000000",
          }}
          component={Link}
          to={`/hunter/peer/profile/${candidate.idManpower}`}
        >
          <Avatar sx={{ bgcolor: stringToColor(`${candidate.name} ${candidate.lastName}`) }} children={`${candidate.name[0]}${candidate.lastName[0]}`} />
          <Typography sx={{ marginLeft: "10px" }}>
            {`${candidate.name} ${candidate.lastName}`}
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        className={clsx({
          RowSelected: !isHistory && !!candidate.isAppointmentSuccess,
          RowFail: !isHistory && !!candidate.isAppointmentFailed,
        })}
      >
        {candidate.departmentName}
      </TableCell>
      <TableCell
        className={clsx({
          RowSelected: !isHistory && !!candidate.isAppointmentSuccess,
          RowFail: !isHistory && !!candidate.isAppointmentFailed,
        })}
      >
        {candidate.isOnline ? (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ width: 270 }} noWrap>
              {candidate.appointmentPlace}
            </Typography>
            <CopyToClipboard text={candidate.appointmentPlace}>
              <ButtonCopy>
                <Tooltip title="copy" placement="top">
                  <ContentCopy className="icon" />
                </Tooltip>
              </ButtonCopy>
            </CopyToClipboard>
          </Box>
        ) : (
          candidate.appointmentPlace
        )}
      </TableCell>
      <TableCell
        className={clsx({
          RowSelected: !isHistory && !!candidate.isAppointmentSuccess,
          RowFail: !isHistory && !!candidate.isAppointmentFailed,
        })}
        style={{ display: "flex", height: "40px", alignItems: "center" }}
      >
        <Rating
          readOnly
          value={candidate.rating}
          precision={0.5}
          selected={!isHistory && !!candidate.isAppointmentSuccess}
        />
        <Typography>{`(${candidate.rating})`}</Typography>
      </TableCell>
      {!isHistory && (
        <TableCell
          className={clsx({
            RowSelected: !!candidate.isAppointmentSuccess,
            RowFail: !!candidate.isAppointmentFailed,
          })}
          padding="none"
        >
          {!!candidate.isAppointmentSuccess && (
            <Tooltip
              title={
                <Box style={{ display: "flex", padding: "5px 10px" }}>
                  <CustomButton
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    colorbutton="yellow"
                    onClick={handleWaiting}
                  >
                    ยกเลิก
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    colorbutton="red"
                    onClick={handleFail}
                  >
                    ไม่ผ่าน
                  </CustomButton>
                </Box>
              }
              placement="top"
            >
              <PassResultChip label="ผ่าน" />
            </Tooltip>
          )}
          {!candidate.isAppointmentSuccess &&
            !!candidate.isAppointmentFailed && (
              <Tooltip
                title={
                  <Box style={{ display: "flex", padding: "5px 10px" }}>
                    <CustomButton
                      variant="contained"
                      style={{ marginRight: "10px" }}
                      colorbutton="yellow"
                      onClick={handleWaiting}
                    >
                      ยกเลิก
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      style={{
                        marginRight: !!candidate.isAppointmentFailed
                          ? 0
                          : "10px",
                      }}
                      colorbutton="green"
                      onClick={() => setOpenDialog(true)}
                    >
                      ผ่าน
                    </CustomButton>
                  </Box>
                }
                placement="top"
              >
                <FailResultChip label="ไม่ผ่าน" />
              </Tooltip>
            )}
          {!candidate.isAppointmentSuccess &&
            !candidate.isAppointmentFailed && (
              <Tooltip
                title={
                  <Box style={{ display: "flex", padding: "5px 10px" }}>
                    <CustomButton
                      variant="contained"
                      style={{
                        marginRight: !!candidate.isAppointmentFailed
                          ? 0
                          : "10px",
                      }}
                      colorbutton="green"
                      onClick={() => setOpenDialog(true)}
                    >
                      ผ่าน
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      colorbutton="red"
                      onClick={handleFail}
                    >
                      ไม่ผ่าน
                    </CustomButton>
                  </Box>
                }
                placement="top"
              >
                <WaitingResultChip label="รอผลสัมภาษณ์" />
              </Tooltip>
            )}
          {openDialog && (
            <DialogStartDate
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              onSubmit={handleSubmitStartDate}
            />
          )}
        </TableCell>
      )}
      {!isHistory && (
        <TableCell
          className={clsx("RowEnd", {
            RowSelected: !!candidate.isAppointmentSuccess,
            RowFail: !!candidate.isAppointmentFailed,
          })}
        >
          <div className="part-action">
            <Fragment>
              <Tooltip title="โน๊ต" placement="top">
                <IconButton
                  style={{ color: "#83D0F5" }}
                  onClick={() => {
                    setIsOpenDrawerNote({
                      open: true,
                      editId: candidate.idCandidate,
                      value: candidate.note || "",
                    });
                  }}
                >
                  <i className="fa-regular fa-memo"></i>
                </IconButton>
              </Tooltip>
              {isOpenDrawerNote.open &&
                isOpenDrawerNote.editId === candidate.idCandidate && (
                  <DrawerNote
                    open={isOpenDrawerNote.open}
                    onClose={() => {
                      setIsOpenDrawerNote({
                        open: false,
                        editIndex: null,
                        value: null,
                      });
                    }}
                    value={isOpenDrawerNote}
                    hanldeSubmit={(note) =>
                      updateNote(note, indexSchedule, candidate.idCandidate)
                    }
                  />
                )}
            </Fragment>
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};

const InterviewGroupRow = (props) => {
  const dispatch = useDispatch();
  const {
    schedule,
    isHistory,
    setIsSuccess,
    updateStatus,
    indexSchedule,
    updateNote,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [openDialogWarning, setOpenDialogWarning] = useState(false);

  const handleClickButtonConclusion = () => {
    let isStatusAllCandidates = true;
    schedule.listCandidates.map((candidate) => {
      if (!candidate.isAppointmentSuccess && !candidate.isAppointmentFailed) {
        isStatusAllCandidates = false;
      }
    });
    if (isStatusAllCandidates) {
      setIsOpenDialog(true);
    } else {
      setOpenDialogWarning(true);
    }
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  let timeoutDisplay;

  const handleCloseDisplay = () => {
    clearInterval(timeoutDisplay);
    setIsSuccess(false);
  };

  const handleCountDownTimeSet = () => {
    timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
  };

  const handleClickConclusion = async (value, e) => {
    console.log(value)
    let data = {
      listPass: [
        ...value.listPass.map((candidate) => {
          return {
            idCandidate: candidate.idCandidate,
            idManpower: candidate.idManpower,
            idVendor: candidate.idVendor,
            workStartDate: dayjs(candidate.workStartDate).format("YYYY-MM-DD"),
          };
        }),
      ],
      listNotPass: [
        ...value.listNotPass.map((candidate) => {
          return {
            idCandidate: candidate.idCandidate,
            idManpower: candidate.idManpower,
          };
        }),
      ],
      detailJob:
        value.listPass.length > 0
          ? {
              idPosition: value.listPass[0].idPosition,
              idDepartment: schedule.idDepartment,
              idJobGroup: value.listPass[0].idJobGroup,
              idBiddingPositions: value.listPass[0].idBiddingPositions,
              idJob: value.listPass[0].idJob,
              positionName: value.listPass[0].positionName,
            }
          : {
              idPosition: null,
              idDepartment: null,
              idJobGroup: null,
              idBiddingPositions: null,
              idJob: null,
            },
    };

    handleCloseDialog();
    const result = await dispatch(updateConcludsionInterview(data));
    if (result.message === "success") {
      dispatch(getInterviewScheduleAndCandidatesByIdCompany());
      setIsSuccess(true);
      handleCountDownTimeSet();
    }
    e.preventDefault();
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? (
              <i className="fa-solid fa-angle-up"></i>
            ) : (
              <i className="fa-solid fa-angle-down"></i>
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="h5">
            {dayjs(schedule.appointmentDate).format("DD MMMM YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="h5">
            {schedule.idBiddings
              ? `${schedule.biddingName} (${schedule.positionName})`
              : schedule.positionName}
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      minWidth: 80,
                      width: 80,
                    }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <i
                        className="fa-solid fa-clock"
                        style={{
                          color: "#ffffff",
                          fontSize: "1.1rem",
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginTop: "2px",
                        }}
                      />
                      <Typography style={{ color: "#ffffff" }}>{`เวลา`}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 240,
                      width: 240,
                      maxWidth: 240,
                    }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <i
                        className="fa-solid fa-user-group"
                        style={{
                          color: "#ffffff",
                          fontSize: "1.1rem",
                          marginRight: "10px",
                        }}
                      />
                      <Typography style={{ color: "#ffffff" }}>{`ชื่อ-สกุล`}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 200,
                      width: 200,
                      maxWidth: 200,
                    }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <i
                        className="fa-solid fa-building"
                        style={{
                          color: "#ffffff",
                          fontSize: "1.1rem",
                          marginRight: "10px",
                        }}
                      />
                      <Typography style={{ color: "#ffffff" }}>{`หน่วยงาน`}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 320,
                      width: 320,
                      maxWidth: 320,
                    }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <i
                        className="fa-solid fa-location-dot"
                        style={{
                          color: "#ffffff",
                          fontSize: "1.1rem",
                          marginRight: "10px",
                        }}
                      />
                      <Typography style={{ color: "#ffffff" }}>
                        {!!schedule.isOnline
                          ? `ลิงก์สัมภาษณ์`
                          : `สถานที่สัมภาษณ์`}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: 158,
                      width: 158,
                      maxWidth: 158,
                    }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <i
                        className="fa-solid fa-star"
                        style={{
                          color: "#ffffff",
                          fontSize: "1.1rem",
                          marginRight: "10px",
                        }}
                      />
                      <Typography style={{ color: "#ffffff" }}>{`คะแนน`}</Typography>
                    </Box>
                  </TableCell>
                  {!isHistory && (
                    <TableCell
                      style={{
                        minWidth: 100,
                        width: 100,
                        maxWidth: 100,
                      }}
                      padding="none"
                    >{``}</TableCell>
                  )}
                  {!isHistory && (
                    <TableCell
                      style={{
                        minWidth: 40,
                        width: 40,
                        maxWidth: 40,
                      }}
                    >{``}</TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody sx={{ marginLeft: "" }}>
                {schedule.listCandidates.map((candidate) => (
                  <RowCandidate
                    key={candidate.idCandidate}
                    candidate={candidate}
                    isHistory={isHistory}
                    updateStatus={updateStatus}
                    indexSchedule={indexSchedule}
                    updateNote={updateNote}
                  />
                ))}
                {!isHistory && (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="right"
                      sx={{ marginBottom: "20px", marginRight: "20px" }}
                    >
                      <ButtonBlue
                        variant="contained"
                        onClick={handleClickButtonConclusion}
                      >
                        สรุปการสัมภาษณ์
                      </ButtonBlue>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      <DialogConfirmInterview
        open={isOpenDialog}
        onClose={handleCloseDialog}
        onSubmit={handleClickConclusion}
        listCandidates={schedule.listCandidates}
      />
      <DialogWarning
        open={openDialogWarning}
        onClose={() => setOpenDialogWarning(false)}
      />
    </Fragment>
  );
};

const CompanyInterviewPage = () => {
  const dispatch = useDispatch();
  const [listSchedules, setListSchedules] = useState([]);
  const [isHistory, setIsHistory] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { result: userMe } = useSelector((state) => state.userProfile);
  const { isFetching, result: scheduleCandidates } = useSelector((state) => state.scheduleCandidates);

  useEffect(() => {
    if (userMe) {
      console.log("UserProfile");
      if (!isHistory) {
        console.log("isHistory");
        dispatch(getInterviewScheduleAndCandidatesByIdCompany());
      } else {
        dispatch(getHistoryInterviewScheduleAndCandidatesByIdcompany());
      }
    }
  }, [userMe, isHistory]);

  useEffect(() => {
    if (scheduleCandidates) {
      setListSchedules([...scheduleCandidates]);
    }
  }, [scheduleCandidates]);

  const updateStatus = (status, indexSchedule, idCandidate, workStartDate) => {
    let listCandidates = listSchedules[indexSchedule].listCandidates;
    let newListSchedule = [...listSchedules];
    newListSchedule[indexSchedule].listCandidates = [
      ...listCandidates.map((candidate) => {
        if (candidate.idCandidate === idCandidate) {
          return {
            ...candidate,
            ...(status === "Success"
              ? {
                  isAppointmentSuccess: 1,
                  workStartDate: dayjs(workStartDate).format("YYYY-MM-DD"),
                }
              : status === "Failed"
              ? { isAppointmentFailed: 1 }
              : {
                  isAppointmentSuccess: 0,
                  isAppointmentFailed: 0,
                  workStartDate: null,
                }),
          };
        } else {
          return candidate;
        }
      }),
    ];
    setListSchedules([...newListSchedule]);
  };

  const updateNote = (note, indexSchedule, idCandidate) => {
    let listCandidates = listSchedules[indexSchedule].listCandidates;
    let newListSchedule = [...listSchedules];
    newListSchedule[indexSchedule].listCandidates = [
      ...listCandidates.map((candidate) => {
        if (candidate.idCandidate === idCandidate) {
          return {
            ...candidate,
            note: note,
          };
        } else {
          return candidate;
        }
      }),
    ];
    setListSchedules([...newListSchedule]);
  };

  return (
    <StyledRoot className="page">
      {!isSuccess && (
        <Container maxWidth="lg">
          <div className="menu-button">
            <ButtonBlue
              variant="contained"
              onClick={(event) => { setAnchorEl(event.currentTarget) }}
            >
              <ListIcon sx={{ fontSize: "30px" }} />
              &nbsp;
              {("เมนูรายการ")}
            </ButtonBlue>
            <MenuUserRecruit
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
            />
          </div>
          <div className="part-header">
            <Typography variant="h4" sx={{ color: "#000000" }}>
              {isHistory ? "ประวัติการสัมภาษณ์" : "รายชื่อนัดหมายสัมภาษณ์"}
            </Typography>
            <ButtonBlue
              style={{ padding: "10px 20px", borderRadius: "8px" }}
              startIcon={!isHistory ? <AccessTime /> : <CalendarMonth />}
              onClick={() => {
                if (!isHistory) {
                  setIsHistory(true);
                } else {
                  setIsHistory(false);
                }
              }}
            >
              {!isHistory ? "ประวัติการสัมภาษณ์" : "รายชื่อนัดหมายสัมภาษณ์"}
            </ButtonBlue>
          </div>
          <StyledTable>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ minWidth: 80, width: 80, maxWidth: 80 }}
                  >{``}</TableCell>
                  <TableCell
                    style={{ minWidth: 250, width: 250, maxWidth: 250 }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <i
                        className="fa-solid fa-calendar"
                        style={{
                          color: "#ffffff",
                          fontSize: "1.1rem",
                          marginRight: "10px",
                        }}
                      />
                      <Typography
                        style={{ color: "#ffffff" }}
                      >{`วันที่`}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: 1000, minWidth: 1000 }}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <i
                        className="fa-solid fa-briefcase"
                        style={{
                          color: "#ffffff",
                          fontSize: "1.1rem",
                          marginRight: "10px",
                        }}
                      />
                      <Typography
                        style={{ color: "#ffffff" }}
                      >{`ตำแหน่งงาน / ชื่อสัญญา (ตำแหน่งงาน)`}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isFetching &&
                  listSchedules &&
                  listSchedules.length > 0 &&
                  listSchedules.map((schedule, index) => {
                    return (
                      <InterviewGroupRow
                        key={`${dayjs(schedule.appointmentDate).format(
                          "DD-MM-YYYY"
                        )}_${schedule.positionName}_${
                          schedule.biddingName
                        }_${index}`}
                        schedule={schedule}
                        isHistory={isHistory}
                        setIsSuccess={setIsSuccess}
                        updateStatus={updateStatus}
                        indexSchedule={index}
                        updateNote={updateNote}
                      />
                    );
                  })}
                {isFetching && (
                  <TableRow>
                    <TableCell colSpan={4} style={{ height: "100px" }}>
                      <Typography variant="h6" align="center">
                        กำลังโหลด
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!isFetching && listSchedules && listSchedules.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} style={{ height: "100px" }}>
                      <Typography variant="h6" align="center">
                        ไม่มีการนัดหมายสัมภาษณ์
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTable>
        </Container>
      )}
      {isSuccess && (
        <Container maxWidth="lg">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "calc(100vh - 160px)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "80px",
              }}
            >
              <img
                style={{ width: 300, marginBottom: "20px" }}
                src={`${process.env.PUBLIC_URL}/assets/background/complete.jpg`}
                // alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"
              />
              <Typography
                className="success"
                variant="h4"
                style={{ marginBottom: "40px" }}
              >
                สรุปการสัมภาษณ์สำเร็จ
              </Typography>
              <ButtonBlue
                variant="outlined"
                style={{ borderRadius: "25px", width: "100px" }}
                onClick={() => {
                  setIsSuccess(false);
                }}
              >
                <Typography variant="h6">ปิด</Typography>
              </ButtonBlue>
            </div>
          </div>
        </Container>
      )}
    </StyledRoot>
  );
};

export default CompanyInterviewPage;
