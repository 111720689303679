import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { Container } from "@mui/material";

import HeaderPage from "./../../shared/header/headerPage";

import EmployeeList from "./employeeList";

import { getAllUsersManager } from "../../../../actions/user";
import { getAllEmployees } from "../../../../actions/employee";

import "./index.css";

const useStyles = makeStyles((theme) => ({
  btnAddNew: {
    ["@media only screen and (max-width: 600px)"]: {
      display: "none",
    },
  },
  btnIconAddNew: {
    ["@media only screen and (min-width:600px)"]: {
      display: "none",
    },
  },

  divider: {
    margin: "10px 0",
  },
  wrapFilterStatusEmployee: {
    marginTop: 16,
  },
  btnFilterStatusEmployee: {
    "& button": {
      borderRadius: 0,
    },
    "& .MuiToggleButton-root": {
      border: "none",
    },
  },
  wrapFilter: {
    margin: "16px 0",
  },
  formControl: {
    width: "100%",
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 8,
  },
  wrapName: {
    width: 350,
  },
  textName: {
    fontSize: 18,
  },
  textPosition: {},
  statusTag: {
    height: 22,
    minWidth: 22,
    borderRadius: 8,
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    justifyContent: "center",
    padding: "0px 8px",
  },
  statusTagActive: {
    color: "rgb(34, 154, 22)",
    backgroundColor: "rgba(84, 214, 44, 0.16)",
  },
  statusTagTerminate: {
    color: "rgb(183, 33, 54)",
    backgroundColor: "rgba(255, 72, 66, 0.16)",
  },
  statusTagLabel: {
    lineHeight: 0,
    fontWeight: 700,
    fontSize: 14,
    textTransform: "capitalize",
  },
  wrapFirstColumn: {
    display: "flex",
    alignItems: "center",
  },
  textOverFlow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sectionAbout: {
    width: 420,
    display: "flex",
    justifyContent: "space-around",
  },
  labelAbout: {
    fontSize: 12,
  },
  textAbout: {
    fontSize: 14,
  },
  iconAction: {
    marginRight: 16,
  },
  smallScreen: {},
}));

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .wrapHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .upload": {
      marginRight: 8,
    },
  },
});

const EmployeesPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mode, setMode] = useState("list");
  const [openDialog, setOpenDialog] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(getAllEmployees("manager"));
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialog(open);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Fragment>
          <div className={`wrapHeader`}>
            <HeaderPage textLabel={"รายชื่อพนักงาน"} />
          </div>
          {mode == "list" && <EmployeeList />}
        </Fragment>
      </Container>
    </StyledRoot>
  );
};

export default EmployeesPage;
