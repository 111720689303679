import React, { Fragment, useEffect, useState } from "react";
import { Box, Container, Grid, IconButton, Pagination, Typography, styled } from "@mui/material";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import AddIcon from '@mui/icons-material/Add';
import CardStyle from "../../../../shared/general/Card";
import { useDispatch } from "react-redux";
import { getOrganizationObjective } from "../../../../../../actions/okrs";
import { Link } from "react-router-dom";
import Loading from "../../../../shared/loading";
import { Search } from "@mui/icons-material";

const StyledRoot = styled(Box)({
  "& .objectcard-wrap": {
    height: "100%",
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0
    }
  },
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input":{
    padding:"16px 12px 16px 12px"
  }
})

const ObjectiveCard = (props) => {

  const { data } = props;

  return (
    <CardStyle style={{height: "100%"}}>
      <Box padding="24px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="24px">{data.year + 543}, Q{data.quarter}</Typography>
          <Link to={`/okrs/organization-planning/information/${data.idOrganizationObjective}`}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
        </Box>
        <Typography fontSize="20px" paddingTop="16px">{data.objectiveName}</Typography>
        <Typography fontSize="14px" paddingTop="16px"><span style={{color: "#919eab", paddingRight: "8px"}}>ผลลัพธ์ที่เกิดขึ้น</span>{data.impactName}</Typography>
        {data.description && <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line"><span style={{color: "#919eab", paddingRight: "8px"}}>รายละเอียด</span>{data.description}</Typography>}
        <Typography fontSize="14px" paddingTop="16px" textAlign="right"><span style={{color: "#919eab", paddingRight: "8px"}}>ผลลัพธ์หลัก</span>{data.keyResults.length} รายการ</Typography>
      </Box>
    </CardStyle>
  )
}

const PlanningOrganization = () => {

  const dispatch = useDispatch();

  // const { result: IndividualObjective, isFetching, isError } = useSelector(state => state.individualObjective)
  const [organizationObjective, setOrganizationObjective] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    let isMounted = true
    dispatch(getOrganizationObjective()).then(res => {
      if(isMounted){
        if(res.status === 200){
          setOrganizationObjective(res.data)
          setIsFetching(false)
        }
      }
    });

    return () => {isMounted = false}
  }, [])

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">

        <CardStyle style={{marginBottom: "48px"}}>
          <Box padding="24px">
            <Box paddingBottom="24px">
              <Typography fontSize="24px">เป้าหมายองค์กร</Typography>
            </Box>

            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {/* <TextFieldTheme
                variant="filled"
                style={{maxWidth: "250px"}}
                onChange={(e) => {onSearchChange(e)}}
                inputProps={{
                  placeholder: "ค้นหา.."
                }}
              /> */}
              <ButtonBlue
                variant="outlined"
                startIcon={<AddIcon />}
                component={Link}
                to="/okrs/organization-planning/form"
              >
                เพิ่มเป้าหมายองค์กร
              </ButtonBlue>
            </Box>
          </Box>
        </CardStyle>

        {!organizationObjective && isFetching? (
          <Fragment>
            <Box display="flex" justifyContent="center" paddingTop="24px">
              <Loading />
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            {organizationObjective && organizationObjective.length > 0? (
              <Fragment>

                <Box marginBottom="16px" display="flex" justifyContent="right">{organizationObjective.length} รายการ</Box>

                <Grid container spacing={2}>
                  {organizationObjective.map((objective, index) => (
                    <Grid key={index} item xs={12}>
                      <Box className="objectcard-wrap">
                        <ObjectiveCard data={objective}/>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
    
                <Box padding="24px 0" display="flex" justifyContent="center">
                  <Pagination
                    component="div"
                  />
                </Box>
              </Fragment>
            ) : (
              <Box display="flex" justifyContent="center" paddingTop="24px">
                <Typography>ไม่มีรายการ</Typography>
              </Box>
            )}
          </Fragment>
        )}

      </Container>

    </StyledRoot>
  )
}

export default PlanningOrganization