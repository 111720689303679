import { httpClient } from "./httpClient";

const getSummaryTime = (search, query) => {
  return httpClient.get(
    `payment/summary-time?start=${search.start}&end=${search.end}`,
    { params: query }
  );
};

export default {
  getSummaryTime,
};
