import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import CardStyle from "../../shared/general/Card";
import { getAssetManagementByIdEmployee } from "../../../../actions/assetManagement";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const AssetManagement = () => {
  const dispatch = useDispatch();
  const { result: assetManagement } = useSelector((state) => state.assetManagement);

  useEffect(() => {
    dispatch(getAssetManagementByIdEmployee());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <WrapHeader>
          <Typography variant="h4">Asset Management</Typography>
        </WrapHeader>
        <CardStyle style={{ padding: 16 }}>
          {assetManagement && assetManagement.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">รหัสสินทรัพย์</TableCell>
                    <TableCell align="center">ชื่อสินทรัพย์</TableCell>
                    <TableCell align="center">วันที่ได้รับของ</TableCell>
                    <TableCell align="center">วันครบกำหนดคืน</TableCell>
                    <TableCell align="center">สถานะ</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assetManagement.map((item) => (
                    <TableRow key={item.idAssetManagement}>
                      <TableCell align="center">{item.idAsset}</TableCell>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">
                        {dayjs(item.receivedDate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {item.returnDate ? dayjs(item.returnDate).format("DD/MM/YYYY") : "ไม่ได้กำหนด"}
                      </TableCell>
                      <TableCell align="center">
                        {item.status ? (
                          <Chip label="คืนแล้ว" color="success" />
                        ) : (
                          <Chip label="ยังไม่คืน" color="error" />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>ไม่มีรายการ</Typography>
          )}
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default AssetManagement;
