import { httpClient } from "./httpClient";

const getAssetManagement = () => {
  return httpClient.get("asset-management");
};

const addAssetManagement = (formData) => {
  return httpClient.post("asset-management", formData);
};

const updateAssetManagement = (formData) => {
  return httpClient.put("asset-management", formData);
};

const deleteAssetManagement = (id) => {
  return httpClient.delete(`asset-management/${id}`);
};

const updateAssetManagementStatus = (formData) => {
  return httpClient.put("asset-management-status", formData);
};

const getAssetManagementByIdEmployee = () => {
  return httpClient.get("asset-management-employee");
};

export default {
  getAssetManagement,
  addAssetManagement,
  updateAssetManagement,
  deleteAssetManagement,
  updateAssetManagementStatus,
  getAssetManagementByIdEmployee,
};
