import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import DescriptionIcon from "@mui/icons-material/Description";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import { postProgressTeamPlan } from "../../../../../actions/ProgressTeamPlans";

import {
  Button,
  TextField,
  Grid,
  Typography,
  InputAdornment,
  Container,
  Box,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const InitialValues = () => ({
  progressTeamId: 0,
  planTeamId: 0,
  score1: 0,
  progress1: "",
  description1: "",
  score2: 0,
  progress2: "",
  description2: "",
  score3: 0,
  progress3: "",
  description3: "",
  updateBy: "",
  dateUpdate: new Date(),
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
    },
  },
  btn: {
    borderRadius: "50px",
    height: "40px",
    width: "100px",
  },
  badBtn: {
    color: "#e53935",
    border: "2px solid #e53935",
  },
  badBtn_active: {
    backgroundColor: "#e53935",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c62828",
      color: "#fff",
    },
  },
  normalBtn: {
    color: "#fbc02d",
    border: "2px solid #fbc02d",
  },
  normalBtn_active: {
    backgroundColor: "#fbc02d",
    color: "#fff",
    "&:hover": { backgroundColor: "#f9a825", color: "#fff" },
  },
  goodBtn: {
    color: "#4caf50",
    border: "2px solid #4caf50",
  },
  goodBtn_active: {
    backgroundColor: "#4caf50",
    color: "#fff",
    "&:hover": { backgroundColor: "#388e3c", color: "#fff" },
  },
  mainData: {
    fontSize: 18,
  },
  data: {
    fontSize: 18,
    fontWeight: "bold",
  },
}));

function UpdateKRTeam() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [values, setValues] = useState(InitialValues);
  const location = useLocation();
  const { handleSubmit } = useForm();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOnClick = (btn, value) => {
    if (btn === "btn1") {
      setValues({
        ...values,
        score1: value,
      });
    } else if (btn === "btn2") {
      setValues({
        ...values,
        score2: value,
      });
    } else if (btn === "btn3") {
      setValues({
        ...values,
        score3: value,
      });
    }
  };

  const onPostProgressTeamPlan = async (formData) => {
    await dispatch(postProgressTeamPlan(formData));
    history.push("/teamupdate");
  };

  const onSubmit = () => {
    const formData = {
      planTeamId: values.planList.planTeamId,
      score1: values.score1 ? values.score1 : null,
      progress1: values.progress1 ? values.progress1 : null,
      description1: values.description1 ? values.description1 : null,
      score2: values.score2 ? values.score2 : null,
      progress2: values.progress2 ? values.progress2 : null,
      description2: values.description2 ? values.description2 : null,
      score3: values.score3 ? values.score3 : null,
      progress3: values.progress3 ? values.progress3 : null,
      description3: values.description3 ? values.description3 : null,
      updateByEmpId: userProfile.idEmployees,
      dateUpdate: dayjs(new Date()).format("YYYY-MM-DD"),
    };

    onPostProgressTeamPlan(formData);
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));

      setValues({
        planList: location.state.planList,
        dateUpdate: new Date(),
      });
    }
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Update Key Result</h3>
            </Grid>
          </Grid>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
              <Typography className={classes.data}>Objective</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {location.state.planList.objectiveName}
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                <Typography className={classes.data}>Impact</Typography>
              </Grid>
              <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                <Typography className={classes.mainData}>
                  {location.state.planList.impactName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {location.state.planList.keyResult1 && (
            <>
              <hr className="hrTag" />
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                  <Typography className={classes.data}>
                    {/* <VpnKeyRoundedIcon fontSize='large' className='svgKey' /> */}
                    Key Result 1
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Typography variant="body1" align="left">
                    {location.state.planList.keyResult1}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                  <Typography className={classes.data}>Result Month</Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Grid container>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        className={
                          values.score1 === 1
                            ? `${classes.btn} ${classes.badBtn_active}`
                            : `${classes.btn} ${classes.badBtn}`
                        }
                        onClick={() => handleOnClick("btn1", 1)}
                      >
                        Bad
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        className={
                          values.score1 === 2
                            ? `${classes.btn} ${classes.normalBtn_active}`
                            : `${classes.btn} ${classes.normalBtn}`
                        }
                        onClick={() => handleOnClick("btn1", 2)}
                      >
                        Normal
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        className={
                          values.score1 === 3
                            ? `${classes.btn} ${classes.goodBtn_active}`
                            : `${classes.btn} ${classes.goodBtn}`
                        }
                        onClick={() => handleOnClick("btn1", 3)}
                      >
                        Good
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        Progress with data
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        workflow with data
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={8} xs={12}>
                      <TextField
                        name="progress1"
                        value={values.progress1}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <RefreshIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Progress"
                        placeholder="Progress"
                      />
                      <TextField
                        name="description1"
                        value={values.description1}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DescriptionIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Description"
                        placeholder="Description"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/* Kr2 */}
          {location.state.planList.keyResult2 && (
            <>
              <hr className="hrTag" />
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                  <Typography variant="h6" className="keyResult">
                    <VpnKeyRoundedIcon fontSize="large" className="svgKey" />
                    Key Result 2
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Typography variant="body1" align="left">
                    {location.state.planList.keyResult2}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                  <Typography variant="h6">Result Month</Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Grid container>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        className={
                          values.score2 === 1
                            ? `${classes.btn} ${classes.badBtn_active}`
                            : `${classes.btn} ${classes.badBtn}`
                        }
                        onClick={() => handleOnClick("btn2", 1)}
                      >
                        Bad
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        className={
                          values.score2 === 2
                            ? `${classes.btn} ${classes.normalBtn_active}`
                            : `${classes.btn} ${classes.normalBtn}`
                        }
                        onClick={() => handleOnClick("btn2", 2)}
                      >
                        Normal
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        className={
                          values.score2 === 3
                            ? `${classes.btn} ${classes.goodBtn_active}`
                            : `${classes.btn} ${classes.goodBtn}`
                        }
                        onClick={() => handleOnClick("btn2", 3)}
                      >
                        Good
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        Progress with data
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        workflow with data
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={8} xs={12}>
                      <TextField
                        name="progress2"
                        value={values.progress2}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <RefreshIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Progress"
                        placeholder="Progress"
                      />
                      <TextField
                        name="description2"
                        value={values.description2}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DescriptionIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Description"
                        placeholder="Description"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/* Kr3 */}
          {location.state.planList.keyResult3 && (
            <>
              <hr className="hrTag" />
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                  <Typography variant="h6" className="keyResult">
                    <VpnKeyRoundedIcon fontSize="large" className="svgKey" />
                    Key Result 3
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Typography variant="body1" align="left">
                    {location.state.planList.keyResult3}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                  <Typography variant="h6">Score Month</Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Grid container>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        className={
                          values.score3 === 1
                            ? `${classes.btn} ${classes.badBtn_active}`
                            : `${classes.btn} ${classes.badBtn}`
                        }
                        onClick={() => handleOnClick("btn3", 1)}
                      >
                        Bad
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        className={
                          values.score3 === 2
                            ? `${classes.btn} ${classes.normalBtn_active}`
                            : `${classes.btn} ${classes.normalBtn}`
                        }
                        onClick={() => handleOnClick("btn3", 2)}
                      >
                        Normal
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginTop: 10 }}
                    >
                      <Button
                        className={
                          values.score3 === 3
                            ? `${classes.btn} ${classes.goodBtn_active}`
                            : `${classes.btn} ${classes.goodBtn}`
                        }
                        onClick={() => handleOnClick("btn3", 3)}
                      >
                        Good
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={4}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        Progress with data
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        workflow with data
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={8} xs={12}>
                      <TextField
                        name="progress3"
                        value={values.progress3}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <RefreshIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Progress"
                        placeholder="Progress"
                      />
                      <TextField
                        name="description3"
                        value={values.description3}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DescriptionIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Description"
                        placeholder="Description"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Box sx={{ paddingRight: 4, paddingTop: 5 }}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className="saveButton"
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </div>
    </ContainerStyled>
  );
}

export default UpdateKRTeam;
