import React from "react";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, Fab, styled, Typography, Icon } from "@mui/material";

const useStyles = makeStyles(({ color }) => ({
  root: {
    borderRadius: 20,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  containedCard: {
    backgroundColor: "#007afd",
    "& .MuiTypography-root": {
      color: "#FFFFFF",
    },
  },
  // containedIcon: {
  //   backgroundColor: "#FFFFFF !important",
  //   color: `#007afd !important`,
  // },
  // outlinedIcon: {
  //   color: "#FFFFFF !important",
  //   backgroundColor: `#007afd !important`,
  // },
  wrapOne: {
    display: "flex",
    marginBottom: 4,
    "& .title": {
      fontWeight: 400
    },
    "& .icon": {
      marginLeft: "auto",
    },
  },
  wrapTwo: {
    display: "flex",
    alignItems: "baseline",
    "& .value": {
      marginRight: 4,
      // fontSize: 50
    }
  }
}));

const FabStyled = styled(Fab)(({ color, variant }) => ({
  backgroundColor: `${variant === "contained" ? "#FFFFFF" : color} !important`,
  color: `${variant === "contained" ? color : "#FFFFFF"} !important`,
}));

const CardSummary = (props) => {
  const { title, icon, variant, value, unit, color, filter, className } = props;
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.root, {
        [classes.containedCard]: variant === "contained",
      })}
    >
      <CardContent 
      className={className}
      style={{ 
        padding: 16, 
        height: 130,
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between"
      }}
    >
        <div className={classes.wrapOne}>
          <div style={{ height: 64 }}>
            <Typography className={`title`} variant="h6" color="textSecondary">{title}</Typography>
            {filter ? filter : <></>}
          </div>
          <div className={`icon`}>
            <FabStyled
              // className={
              //   clsx({
              //     [classes.containedIcon]: variant === "contained",
              //     [classes.outlinedIcon]: variant === "outlined",
              //   })
              // }
              aria-label={title}
              disabled
              color={color ? color : "#007afd"}
              variant={variant}
            >
              <Icon className={icon} style={{ fontSize: 32, width: "100%" }} />
            </FabStyled>
          </div>
        </div>
        <div className={classes.wrapTwo}>
          <Typography 
            variant="h4" 
            className={`value`}
            style={{ fontSize: `${value}`.length <= 8 ? 50 : `${value}`.length <= 10 ? 40 : 30}}
          >{value}</Typography>
          <Typography variant="caption" color="textSecondary">{unit}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default CardSummary;
