import React, { useState } from "react";
import { Box, Divider, Grid, IconButton, Typography, styled } from "@mui/material";
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import DialogFamily from "./dialogFamily";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { deleteFamily } from "../../../../../../actions/family";

const StyledRoot = styled(Box)({

})

const FamilyPanel = () => {

  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  })
  const [deleteConfig, setDeleteConfig] = useState({
    isOpen: false,
    data: {}
  })

  const onDrawerClose = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleCloseDeleteModal = () => {
    setDeleteConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleDeleteFamily = async () => {

    const result = await dispatch(deleteFamily(deleteConfig.data.idFamily))

    if(result){
      if(result.status === 200){
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true))
        handleCloseDeleteModal();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      // }))
    }
  }

 return (
  <StyledRoot>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography fontWeight="600" fontSize="14px">ข้อมูลครอบครัว</Typography>
      <ButtonBlue
        variant="text"
        size="small"
        startIcon={<PlaylistAddRoundedIcon />}
        onClick={() => {
          setDrawerConfig(prev => ({
            ...prev,
            isOpen: true,
            isEdit: false,
          }))
        }}
        // onClick={() => handleClick("personalDetail")}
      >
        เพิ่มสมาชิกครอบครัว
      </ButtonBlue>
    </Box>

    {employeeProfile && <Box marginTop="24px">
      <Grid container spacing={2}>
        {employeeProfile.family.map((item, index) => (
          <Grid item container spacing={2} position="relative">
            <Grid item xs={12} container alignItems="center">
              <Typography 
                // sx={{
                //   paddingLeft: "8px",
                //   borderLeft: "8px solid #46cbe2",
                //   borderTopLeftRadius: "4px",
                //   borderBottomLeftRadius: "4px"
                // }}
                fontWeight="600" 
                fontSize="18px"
                flexGrow="1"
              >
                สมาชิกครอบครัวคนที่ {index+1}
              </Typography>
              <Box>
                <IconButton
                  aria-label="edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDrawerConfig(prev => ({
                      ...prev,
                      isOpen: true,
                      isEdit: true,
                      data: {
                        idFamily: item.idFamily,
                        relationship: item.relationship,
                        firstname_TH: item.firstname_TH,
                        lastname_TH: item.lastname_TH,
                        personalID: item.personalID,
                        birthday: item.birthday,
                      }
                    }))
                  }}
                >
                  <EditNoteRoundedIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteConfig(prev => ({
                      ...prev,
                      isOpen: true,
                      data: {
                        idFamily: item.idFamily,
                        firstname_TH: item.firstname_TH,
                        lastname_TH: item.lastname_TH,
                        personalID: item.personalID,
                        // idLeaveGroup: data.idLeaveGroup,
                        // leaveGroupName: data.leaveGroupName
                      }
                    }))
                  }}
                >
                  <DeleteOutlineRoundedIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography fontWeight="600" fontSize="14px" color="text.secondary">ชื่อ-สกุล</Typography>
              <Typography variant="h6">{item.firstname_TH} {item.lastname_TH}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography fontWeight="600" fontSize="14px" color="text.secondary">ความสัมพันธ์</Typography>
              <Typography variant="h6">{item.relationship}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography fontWeight="600" fontSize="14px" color="text.secondary">เลขบัตรประชาชน</Typography>
              <Typography variant="h6">{String(item.personalID).replace(/(\d)(\d{4})(\d{5})(\d{2})(\d{1})/,"$1-$2-$3-$4-$5")}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography fontWeight="600" fontSize="14px" color="text.secondary">วันเกิด</Typography>
              <Typography variant="h6">{dayjs(item.birthdayText).format("D MMMM BBBB")} ({dayjs().diff(dayjs(item.birthdayText), 'years')} ปี)</Typography>
            </Grid>
            {(index < employeeProfile.family.length-1) && <Grid item xs={12}>
              <Divider />
            </Grid>}
          </Grid>
        ))}

        {employeeProfile.family.length <= 0 && (
          <Grid item xs={12}>
            <Typography textAlign="center" variant="h6" color="text.secondary" fontWeight="400" fontStyle="oblique">ไม่มีข้อมูลสมาชิกครอบครัว</Typography>
          </Grid>
        )}
      </Grid>
    </Box>}

    <DialogFamily
      drawerConfig={drawerConfig}
      onClose={onDrawerClose}
    />

    <DialogConfirmDelete
      open={deleteConfig.isOpen}
      handleClose={handleCloseDeleteModal}
      label={"สมาชิกครอบครัว"}
      // text={`${deleteConfig.data.personalID} ${deleteConfig.data.firstname_TH} ${deleteConfig.data.lastname_TH}`}
      text={
        <Box>
          <Typography fontSize="14px" fontWeight="600" style={{color: "#f15e5e"}}>{deleteConfig.data.personalID}</Typography>
          <Typography fontSize="18px" style={{color: "#f15e5e"}}>{deleteConfig.data.firstname_TH} {deleteConfig.data.lastname_TH}</Typography>
        </Box>
      }
      handleDelete={handleDeleteFamily}
    />
  </StyledRoot>
 )
}

export default FamilyPanel;