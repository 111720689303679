import {
  ESTIMATE_PERFORMANCE_SUCCESS,
  ESTIMATE_PERFORMANCE_FETCHING,
  ESTIMATE_PERFORMANCE_FAILED,
  RESULT_ESTIMATE_PERFORMANCE_SUCCESS,
  RESULT_ESTIMATE_PERFORMANCE_FETCHING,
  RESULT_ESTIMATE_PERFORMANCE_FAILED,
  ESTIMATE_LEVEL_SUCCESS,
  ESTIMATE_LEVEL_FETCHING,
  ESTIMATE_LEVEL_FAILED,
} from "./types";
import EstimatePerformanceService from "../services/estimatePerformance.service";

export const getEstimatePerformance = () => async (dispatch) => {
  try {
    dispatch({
      type: ESTIMATE_PERFORMANCE_FETCHING,
    });
    const res = await EstimatePerformanceService.getEstimatePerformance();
    if (res) {
      dispatch({
        type: ESTIMATE_PERFORMANCE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ESTIMATE_PERFORMANCE_FAILED,
    });
    console.log(err);
  }
};

export const updateEstimatePerformance = (formData) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.updateEstimatePerformance(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ESTIMATE_PERFORMANCE_FAILED,
    });
  }
};

export const getEstimatePerformanceManager = () => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.getEstimatePerformanceManager();
    if (res) {
      dispatch({
        type: ESTIMATE_PERFORMANCE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ESTIMATE_PERFORMANCE_FAILED,
    });
    console.log(err);
  }
};

export const updateEstimatePerformanceQuestion = (formdata) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.updateEstimatePerformanceQuestion(formdata);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: ESTIMATE_PERFORMANCE_FAILED,
    });
  }
};

export const updateEstimatePerformanceManager = (formData) => async () => {
  try {
    const res =
      await EstimatePerformanceService.updateEstimatePerformanceManager(
        formData
      );
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getEstimatePerformanceResult = () => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_ESTIMATE_PERFORMANCE_FETCHING,
    });
    const res = await EstimatePerformanceService.getEstimatePerformanceResult();
    if (res) {
      dispatch({
        type: RESULT_ESTIMATE_PERFORMANCE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: RESULT_ESTIMATE_PERFORMANCE_FAILED,
    });
    console.log(err);
  }
};

export const getEstimateLevel = () => async (dispatch) => {
  try {
    dispatch({
      type: ESTIMATE_LEVEL_FETCHING,
    });
    const res = await EstimatePerformanceService.getEstimateLevel();
    if (res) {
      dispatch({
        type: ESTIMATE_LEVEL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ESTIMATE_LEVEL_FAILED,
    });
    return err
  }
};

export const addEmployeePerformance = (formData) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.addEmployeePerformance(formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const updateEmployeePerformance = (idEmployeePerformance, formData) => async (dispatch) => {
  try {
    const res = await EstimatePerformanceService.updateEmployeePerformance(idEmployeePerformance, formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}
