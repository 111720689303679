import React, { Fragment } from "react";
import { Grid, MenuItem, Paper, Stack, Typography } from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

const FormTwo = (props) => {

  const { onNextPage, onPrevPage, control, trigger, errors, degrees } = props;

  const { result: jobGroups } = useSelector(state => state.jobRecruitGroup)

  const handleClickNext = async () => {
    const check = await trigger([
      "jobGroupSelect",
      "workExperienceY",
      "workExperienceM",
      "educationDegree",
      "currentSalary",
      "expectedSalary",
    ]);
    if (check) {
      onNextPage();
    }
  };

  const jobGroupSelect = useWatch({ control, name: "jobGroupSelect" })
  const idJobGroup = jobGroupSelect.map(job => job.idJobGroup)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" fontSize={20} sx={{color:"#000000"}}>ความเชี่ยวชาญ</Typography>
        {errors && errors.jobGroupSelect && errors.jobGroupSelect.message && (
        <Typography fontSize={16} color="error">
          {errors.jobGroupSelect.message}
        </Typography>
      )}
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="jobGroupSelect"
          render={({ field })=>(
            <Grid className="part-expertise" container spacing={2}>
              {jobGroups && jobGroups.map((item, index)=>(
                <Grid key={index} item xs={6} md={4} lg={3}>
                  <Paper 
                    className={idJobGroup.includes(item.idJobGroup) ? "isSelected": ""} variant="outlined" 
                    onClick={() => {
                      const jobGroupSelected = field.value.find(e => e.idJobGroup === item.idJobGroup)
                      if(jobGroupSelected) {
                        field.onChange(field.value.filter(e => e.idJobGroup !== item.idJobGroup))
                      } else {
                        field.onChange([...field.value, item])
                      }
                    }}
                  >
                    <div className="wrap-name">
                      <Typography variant="subtitle">{item.jobGroupName}</Typography>
                    </div>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        />
      </Grid>
      
      <Grid item xs={6} sm={3}>
        <Controller
          control={control}
          name="workExperienceY"
          render={({field})=>(
            <Fragment>
              <Typography gutterBottom sx={{ color: "#000000" }}>ประสบการณ์ทำงาน(ปี)</Typography>
              <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                {...field}
                placeholder="ประสบการณ์ทำงาน(ปี)"
                helperText={errors && errors.workExperienceY && errors.workExperienceY.message}
                error={errors && errors.workExperienceY ? true: false}
                select
                SelectProps={{
                  displayEmpty: true
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="">ไม่มี</MenuItem>
                <MenuItem value="1">1 ปี</MenuItem>
                <MenuItem value="2">2 ปี</MenuItem>
                <MenuItem value="3">3 ปี</MenuItem>
                <MenuItem value="4">4 ปี</MenuItem>
                <MenuItem value="5">5 ปี</MenuItem>
                <MenuItem value="6">6 ปี</MenuItem>
                <MenuItem value="7">7 ปี</MenuItem>
                <MenuItem value="8">8 ปี</MenuItem>
                <MenuItem value="9">9 ปี</MenuItem>
                <MenuItem value="10">10 ปี</MenuItem>
                <MenuItem value="11">11 ปี</MenuItem>
                <MenuItem value="12">12 ปี</MenuItem>
                <MenuItem value="13">13 ปี</MenuItem>
                <MenuItem value="14">14 ปี</MenuItem>
                <MenuItem value="15">15 ปี</MenuItem>
                <MenuItem value="16">16 ปี</MenuItem>
                <MenuItem value="17">17 ปี</MenuItem>
                <MenuItem value="18">18 ปี</MenuItem>
                <MenuItem value="19">19 ปี</MenuItem>
                <MenuItem value="20">20 ปี</MenuItem>
              </TextFieldTheme>
            </Fragment>
          )}
        />
        
      </Grid>
      <Grid item xs={6} sm={3}>
        <Controller
          control={control}
          name="workExperienceM"
          render={({field})=>(
            <Fragment>
              <Typography gutterBottom sx={{ color: "#000000" }}>ประสบการณ์ทำงาน(เดือน)</Typography>
              <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
                {...field}
                placeholder="ประสบการณ์ทำงาน(เดือน)"
                helperText={errors && errors.workExperienceM && errors.workExperienceM.message}
                error={errors && errors.workExperienceM ? true: false}
                select
                SelectProps={{
                  displayEmpty: true
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="">ไม่มี</MenuItem>
                <MenuItem value="1">1 เดือน</MenuItem>
                <MenuItem value="2">2 เดือน</MenuItem>
                <MenuItem value="3">3 เดือน</MenuItem>
                <MenuItem value="4">4 เดือน</MenuItem>
                <MenuItem value="5">5 เดือน</MenuItem>
                <MenuItem value="6">6 เดือน</MenuItem>
                <MenuItem value="7">7 เดือน</MenuItem>
                <MenuItem value="8">8 เดือน</MenuItem>
                <MenuItem value="9">9 เดือน</MenuItem>
                <MenuItem value="10">10 เดือน</MenuItem>
                <MenuItem value="11">11 เดือน</MenuItem>
              </TextFieldTheme>
            </Fragment>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="educationDegree"
          render={({field})=>(
            <Fragment>
            <Typography gutterBottom sx={{ color: "#000000" }}>ระดับการศึกษาสูงสุด</Typography>
            <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
              {...field}
              placeholder="ระดับการศึกษาสูงสุด"
              helperText={errors && errors.educationDegree && errors.educationDegree.message}
              error={errors && errors.educationDegree ? true: false}
              select
            >
              {degrees.map(d => (
                <MenuItem key={`deegree_${d.idDegrees}`} value={`${d.degreeName}`}>{d.degreeName}</MenuItem>
              ))}
            </TextFieldTheme>
            </Fragment>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="currentSalary"
          render={({field})=>(
            <Fragment>
            <Typography gutterBottom sx={{ color: "#000000" }}>{"เงินเดือนปัจจุบัน (ไม่จำเป็น)"}</Typography>
            <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
              {...field}
              placeholder="เงินเดือนปัจจุบัน (ไม่จำเป็น)"
              InputProps={{
                inputComponent: NumberFormatTheme,
              }}
              inputProps={{
                allowNegative: false,
                thousandSeparator:",",
                suffix:" บาท",
                decimalScale: 2,
                onValueChange:(values) => {
                  const {value} = values;
                  field.onChange(value)
                }
              }}
              onChange={()=>{}}
              helperText={errors && errors.currentSalary && errors.currentSalary.message}
              error={errors && errors.currentSalary ? true: false}
            />  
            </Fragment>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="expectedSalary"
          render={({field})=>(
            <Fragment>
            <Typography gutterBottom sx={{ color: "#000000" }}>{"เงินเดือนที่คาดหวัง (ไม่จำเป็น)"}</Typography>
            <TextFieldTheme sx={{ backgroundColor: "#ffffff", borderRadius: "10px"}}
              {...field}
              placeholder="เงินเดือนที่คาดหวัง (ไม่จำเป็น)"
              InputProps={{
                inputComponent: NumberFormatTheme,
              }}
              inputProps={{
                allowNegative: false,
                thousandSeparator:",",
                suffix:" บาท",
                decimalScale: 2,
                onValueChange:(values) => {
                  const {value} = values;
                  field.onChange(Number(parseFloat(value).toFixed(2)))
                }
              }}
              onChange={() => {}}
              helperText={errors && errors.expectedSalary && errors.expectedSalary.message}
              error={errors && errors.expectedSalary ? true: false}
            />  
            </Fragment>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction={"flex"} justifyContent={"space-between"}>
          <ButtonBlue variant="contained" onClick={onPrevPage}>ก่อนหน้า</ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleClickNext}>ถัดไป</ButtonBlue>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default FormTwo;