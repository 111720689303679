import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { Divider, Grid, Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../shared/general/ButtonBlue";

import DialogContact from "./DialogContact";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ProfileTab = (props) => {
  const { handleChangeAlertType, handleOpenAlert } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [openDialogContact, setOpenDialogContact] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialogContact(false);
  };

  return (
    <Grid container spacing={2}>
      {userProfile && (
        <Fragment>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              รหัสพนักงาน
            </StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.employeeID ? userProfile.employeeID : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              เลขที่บัตรประชาชน
            </StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.personalID
                ? userProfile.personalID.replace(
                    /(\d)(\d\d\d\d)(\d\d\d\d\d)(\d\d)(\d)/,
                    "$1 $2 $3 $4 $5"
                  )
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">วันเกิด</StyledHeadLabel>
            <Typography variant="h6">
              {dayjs(userProfile.birthday).format("DD MMMM BBBB")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">เพศ</StyledHeadLabel>
            <Typography variant="h6">{userProfile.gender}</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledDivider />
            <StyledWrapHead>
              <StyledHeadLabel style={{ marginBottom: 4 }}>
                การติดต่อ
              </StyledHeadLabel>
              <ButtonBlue variant="text" size="small" startIcon={<EditIcon />} onClick={() => setOpenDialogContact(true)}>
                แก้ไข
              </ButtonBlue>
            </StyledWrapHead>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                เบอร์โทรศัพท์
              </StyledHeadLabel>
              <Typography variant="h6">
                {userProfile.telephoneMobile && userProfile.telephoneMobile.replace(
                  /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                  "$1-$2-$3"
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">อีเมล์</StyledHeadLabel>
              <Typography variant="h6">{userProfile.email}</Typography>
            </div>
          </Grid>
        </Fragment>
      )}
      <DialogContact
        open={openDialogContact}
        handleCloseDialog={handleCloseDialog}
        handleChangeAlertType={handleChangeAlertType}
        handleOpenAlert={handleOpenAlert}
      />
    </Grid>
  );
};

export default ProfileTab;
