import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Container, Box, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import Announcement from "./announcement";
import CompanyPolicy from "./companyPolicy";
import { useDispatch } from "react-redux";
import { getAnnouncementActiveByIdCompany } from "../../../../actions/announcement";
import { getPolicyActiveByIdCompany } from "../../../../actions/policy";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MainTabs() {
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getAnnouncementActiveByIdCompany());
    dispatch(getPolicyActiveByIdCompany());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs 
              value={value} 
              onChange={handleChange}   
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label="Announcement" {...a11yProps(0)} />
              <Tab label="Company Policy" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Announcement />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <CompanyPolicy />
          </CustomTabPanel>
        </Box>
      </Container>
    </StyledRoot>
  );
}
