import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogEdit from "./DialogEdit";
import { Divider, Grid, Typography, styled } from "@mui/material";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const initialValues = () => ({
  empId: 0,
  effectiveDate: null,
  reason: "",
});

function Terminate(props) {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [values, setValues] = useState(initialValues);
  const [openEdit, setOpenEdit] = useState(false);

  const toggleDrawer = (open) => (event) => {
    console.log(open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenEdit(open);
  };

  const handleCloseDrawer = () => {
    setOpenEdit(false);
  }
  const handleOpenDrawer = () => {
    setOpenEdit(true);
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">
            วันที่สิ้นสุดสัญญา
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.resignDate
              ? dayjs(employeeProfile.resignDate).format(
                  "DD MMMM BBBB"
                )
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">เหตุผล</StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.reasonResign
              ? employeeProfile.reasonResign
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">
            เหตุผล(เพิ่มเติม)
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.reasonResignOther
              ? employeeProfile.reasonResignOther
              : "-"}
          </Typography>
        </Grid>
      </Grid>
      <StyledDivider />
      <ButtonBlue
        size="large"
        variant={"contained"}
        onClick={handleOpenDrawer}
      >
        เพิ่ม/แก้ไขข้อมูลลาออก
      </ButtonBlue>
      <DialogEdit open={openEdit} handleCloseDrawer={handleCloseDrawer} toggleDrawer={toggleDrawer} />
    </div>
  );
}

export default Terminate;
