import React, { useEffect, useState } from "react";
import { Box, Container, Typography, styled } from "@mui/material";
import PlanningPanel from "./PlanningPanel";
import CardStyle from "../../../shared/general/Card";
import { useDispatch } from "react-redux";
import { getAllObjectiveForEvaluation } from "../../../../../actions/okrs";

const StyledRoot = styled(Box)({

})

const EvaluationPage = () => {

  const dispatch = useDispatch();

  const [evaluationList, setEvaluationList] = useState([]);

  useEffect(() => {
    dispatch(getAllObjectiveForEvaluation()).then(res => {
      if(res.status == 200){
        setEvaluationList(res.data)
      }
    })
  }, [])

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <CardStyle>
            <Box padding="24px">
              <Box paddingBottom="24px">
                <Typography fontSize="24px">รายการรอประเมิน เป้าหมายรายบุคคล</Typography>
              </Box>

              {/* <Tabs>
                <Tab value={1} label="เป้าหมายบุคคล"/>
                <Tab value={2} label="เป้าหมายทีม"/>
              </Tabs> */}

              <PlanningPanel data={evaluationList}/>
            </Box>
          </CardStyle>
        </Container>
      </Box>
    </StyledRoot>
  )
}

export default EvaluationPage;