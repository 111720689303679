import DoneAll from "@mui/icons-material/DoneAll";
import { Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChipWithDrawCustom from "../../../shared/chipWithDrawCustom";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import StatusRequest from "../../../shared/general/stausRequest";
import TableCustom from "../../../shared/tableCustom";
import DialogConfirmReject from "../DialogConfirmReject";
import { employeeChangeTimeApprove, getEmployeeChangeTime } from "../../../../../actions/employee";

const Shift = (props) => {
    const { searchDate, selectedEmployee, setSnackBarConfig, setNumberOfList } = props;
    const dispatch = useDispatch();
    const { result: employeeChangeShiftList } = useSelector(
        (state) => state.employeeChangeShift
    );

    const [pendingRows, setPendingRows] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);

    const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

    const shiftTimeHeader = [
        {
          name: "ชื่อ-สกุล",
          minWidth: "230px",
          width: "230px",
          cellRender: (row) => (
            <Box display="flex" alignItems="center">
              <Avatar
                style={{
                  marginRight: "8px",
                  width: 40,
                  height: 40,
                  "& img": { objectFit: "contain" },
                }}
                src={row.imageURL}
              />
              <Box flexGrow={1}>
                <Typography>
                  {row.firstname_TH} {row.lastname_TH}
                </Typography>
                <Typography color="text.third" fontSize="14px">
                  {row.positionName}
                </Typography>
              </Box>
            </Box>
          ),
        },
        {
          name: "วันที่",
          headerTextAlign: "center",
          minWidth: "150px",
          width: "150px",
          cellRender: (row) => (
            <Box style={{ textAlign: "center" }}>
              <Typography>
                {dayjs(row.workingDateText, "DD/MM/YYYY").format("D MMM BBBB")}
              </Typography>
            </Box>
          ),
        },
        {
          name: "เวลาทำงาน",
          headerTextAlign: "center",
          minWidth: "150px",
          width: "150px",
          cellRender: (row) => (
            <Box style={{ textAlign: "center" }}>
              {row.isWorkingDay === 1 ? (
                <Typography>{row.shiftGroupName}</Typography>
              ) : (
                <Typography>วันหยุด</Typography>
              )}
            </Box>
          ),
        },
    ];

    useEffect(() => {
        const tempPending = [];
        
        employeeChangeShiftList.map((r) => {
          if (
            (r.isApprove === null) && 
            (selectedEmployee ? selectedEmployee.idEmployees === r.idEmployees : true)
          ) {
            tempPending.push(r);
          }
        });
        let pendingList = tempPending.sort(function (a, b) {
          return new Date(b.createDate) - new Date(a.createDate);
        });
        
        setPendingRows(pendingList);
        setNumberOfList(pendingList.length);
    }, [selectedEmployee]);

    const handleApprovalRequest = async (approve, comment) => {
      let formData = [...selectedRows];

      formData.forEach(item => {
        item.isApprove = approve
        item.approveComment = comment
      });

      setIsOpenRejectDialog(false);

      const result = await dispatch(
        employeeChangeTimeApprove(formData)
      );
      if(result){
        setSelectedRows([]);
        dispatch(getEmployeeChangeTime("admin", searchDate));
        if (result.status === 200) {
          setSnackBarConfig({
            open: true,
            message: "ดำเนินการสำเร็จ",
            type: "success",
          });
        } else {
          setSnackBarConfig({
            open: true,
            message: "ดำเนินการไม่สำเร็จ",
            type: "error",
          });
        }
      }else{
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการไม่สำเร็จ",
          type: "error",
        });
      }
    };

    const handleConfirmReject = (comment) => {
      handleApprovalRequest(0, comment);
    };

    return (
        <Box>
            {selectedRows.length > 0 && (
                <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
                    <ButtonBlue
                        style={{ marginRight: 8 }}
                        startIcon={<DoneAll />}
                        variant="contained"
                        onClick={() => handleApprovalRequest(1, null)}
                    >
                        {`อนุมัติ ${
                        selectedRows.length > 0 ? `${selectedRows.length} รายการ` : ""
                        }`}
                    </ButtonBlue>
                    <ButtonBlue
                        startIcon={<DoneAll />}
                        variant="outlined"
                        // onClick={() => handleApprovalRequest(0)}
                        onClick={() => setIsOpenRejectDialog(true)}
                    >
                        {`ไม่อนุมัติ ${
                            selectedRows.length > 0 ? `${selectedRows.length} รายการ` : ""
                        }`}
                    </ButtonBlue>
                </Box>
            )}

            <TableCustom
                columns={shiftTimeHeader}
                rows={employeeChangeShiftList ? pendingRows : []}
                canSelect
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
            />

            <DialogConfirmReject
              rejectCount={selectedRows.length}
              open={isOpenRejectDialog}
              onClose={() => {
                setIsOpenRejectDialog(false);
              }}
              handleSubmit={(comment) => {
                handleConfirmReject(comment);
              }}
            />
        </Box>
    );
};

export default Shift;