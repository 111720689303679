import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserAccessArea from "../user/AccessArea";
import AdminApproveList from "../admin/approveList/index";
import CheckInLocation from "../user/AccessArea/mobile";

const AccessAreaPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isPWA, setIsPWA] = useState(false);
  useEffect(() => {
    let displayMode = "";

    if (window.matchMedia("(display-mode: standalone)").matches) {
      displayMode = "standalone";
      setIsPWA(true);
    } else {
      displayMode = "!!!!!!";
    }
  }, []);
  
  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          ""
        ) : (
          isPWA ? 
          <CheckInLocation/> : <UserAccessArea /> 
        )
      ) : null}
    </div>
  );
};

export default AccessAreaPage;
