import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, styled, Typography } from "@mui/material";

import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";
import { getManpowerById } from "../../../../../../../actions/manpower";

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
});

const StyledSuccess = styled("div")({
  paddingTop: 80,
  textAlign: "center",
  height:"calc(100vh - 240px)",
  "& .button-wrap": {
    display: "inline-block",
    marginTop: ".6em",
  }
});

const VendorManpowerProfilePage = (props) => {
  const { idManpower } = props.match.params;
  const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile); 
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(
    { 
      status: false, 
      isEducation: false,
      isAdd: false,
      isDelete: false 
    }
  );

  useEffect(() => {
    if(idManpower){
      dispatch(getManpowerById(idManpower));
    }
  },[idManpower]);

  return(
    <StyledRoot className="page">
      {!isSuccess.status && ManpowerProfile &&
        <Container maxWidth="lg">
          <Grid container spacing={2} marginTop={3}>
            <Grid item xs={12} sm={4}>
              <LeftPanel />
            </Grid>
            <Grid item xs={12} sm={8}>
              <RightPanel 
                setIsSuccess={setIsSuccess}
              />
            </Grid>
          </Grid>
        </Container>
      }
      {isSuccess.status && 
        <Container maxWidth="lg">
          <StyledSuccess>
            <img
              style={{ width: 300, marginBottom: "20px" }}
              src={`/assets/background/complete.jpg`}
            // alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"  
            />
            <Typography variant="h4" textAlign="center">
              {`${isSuccess.isAdd ? "เพิ่ม" : isSuccess.isDelete ? "ลบ" : "แก้ไข"}${isSuccess.isEducation ? "ประวัติการศึกษา" : "ประสบการณ์ทำงาน"}สำเร็จ`}
            </Typography>
            <div className="button-wrap">
              <ButtonBlue 
                variant="outlined" 
                onClick={() => setIsSuccess(
                  {
                    status: false,
                    isEducation: false,
                    isAdd: false,
                    isDelete: false 
                  }
                )}
              >ปิด</ButtonBlue>
            </div>
          </StyledSuccess>
        </Container>
      }
    </StyledRoot>
  )
}

export default VendorManpowerProfilePage;