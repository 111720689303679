import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const rand3000 = () => {
  return Math.floor(Math.random() * 3000);
};

export default function ChartsOTAllMonth() {
  const [series, setseries] = useState([
    {
      name: "OT x 1.0",
      data: [
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
      ],
    },
    {
      name: "OT x 1.5",
      data: [
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
      ],
    },
    {
      name: "OT x 2.0",
      data: [
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
      ],
    },
    {
      name: "OT x 3.0",
      data: [
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
        rand3000(),
      ],
    },
  ]);
  const [optionsChart, setOptionsChart] = React.useState({
    chart: {
      type: "bar",
      width: "100%",
      height: 250,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
            chart: {
                height: 100,
            },
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 6,
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      inverseOrder: false,
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#007afd",
        shadeTo: "light",
        shadeIntensity: 0.6,
      },
    },
  });

  return <Chart options={optionsChart} series={series} type="bar" />;
}
