 import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
    Avatar,
    Box,
    Chip,
    Stack,
    styled,
    Tooltip,
    Typography
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ButtonBlue from '../../../../shared/general/ButtonBlue';
import CardStyle from '../../../../shared/general/Card';
import DialogCancelled from './dialogCancelled';
import TableTheme from '../../../../shared/general/TableTheme';

import { updateCancelSendingCandidate } from '../../../../../../actions/candidate';
import { getAllFreeUsersByIdVendor } from '../../../../../../actions/employee';
import DrawerInterviewAppointment from './drawerInterviewAppointment';
import { stringToColor } from '../../../../../../utils';
import { getManager } from '../../../../../../actions/manager';

const StyledContent = styled("div")({
    padding: 36,
    paddingTop: 24,
    "& .column-name": {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color:"#000000",
        "& .MuiAvatar-root": {
          marginRight: 8,
          width: 40,
          height: 40,
        },
    },
});

const WaitingProcessChip = styled(Chip)({
    width: "130px",
    color: "#ff9800",
    fontWeight: 500,
    backgroundColor: "#fff0ce"
});

const WaitingResultChip = styled(Chip)({
    width: "130px",
    color: "#623f00",
    fontWeight: 500,
    backgroundColor: "#ffa500"
});

const PassResultChip = styled(Chip)({
    width: "130px",
    color: "#0b5f10",
    fontWeight: 500,
    backgroundColor: "#76d576"
});

const FailResultChip = styled(Chip)({
    width: "130px",
    color: "#b81a1a",
    fontWeight: 500,
    backgroundColor: "#eb7d7d"
});

const CancelledResultChip = styled(Chip)({
    width: "130px",
    fontWeight: 500
});

const CardCandidateSent = (props) => {
    let { listCandidatesSent, onClickSubmitCancelled, isBidding, idJobGroup } = props;
  
    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = useState({ status: false, candidate: null });
    const [openDrawer, setOpenDrawer] = useState({ status: false, candidate: null });

    const displayTime = (time) => {
        let splitText = time.split(":");
        return dayjs().hour(parseInt(splitText[0])).minute(parseInt(splitText[1])).format("HH.mm");
    };

    const handleClickCancelled = async (candidate) => {
        setOpenDialog({ status: true, candidate: candidate });
    };

    const handleSubmitCancelled = async (params) => {
        const res = await dispatch(updateCancelSendingCandidate({ id: params.idCandidate, idManpower: params.idManpower }));
        setOpenDialog({ status: false, candidate: null });
        if (res.message === "success") {
            onClickSubmitCancelled();
            dispatch(getAllFreeUsersByIdVendor());
        }
    };

    const handleClickOpenDrawer = (candidate) => {
        setOpenDrawer({ status: true, candidate: candidate });
    };

    const handleCloseDrawer = (candidate) => {
        setOpenDrawer({ status: false, candidate: candidate });
        // setSelectedRow([]);
    };

    useEffect(() => {
        dispatch(getManager());
    }, []);

    const StatusCandidate = ({ row }) => {
        const { idManpowerStatus } = row
        if(idManpowerStatus === 1) {
            return <PassResultChip label="ผ่านสัมภาษณ์" />
        } else if(idManpowerStatus === 2) {
            return <FailResultChip label="ไม่ผ่านสัมภาษณ์" />
        } else if(idManpowerStatus === 3) {
            return (
                <Tooltip 
                    title={
                    <Box style={{ display: "flex", padding: "5px 10px" }}>
                        <ButtonBlue
                        variant="contained"
                        onClick={() => handleClickCancelled(row)}
                        >ยกเลิกการส่ง</ButtonBlue>
                    </Box>
                    } 
                    placement="top"
                >
                    <WaitingProcessChip label="รอดำเนินการ" />
                </Tooltip>
            )
        } else if(idManpowerStatus === 4) {
            return <WaitingResultChip label="รอสัมภาษณ์" />
        } else if(idManpowerStatus === 8) {
            return <CancelledResultChip label="ยกเลิก" />
        } else {
            return <Typography>ไม่มีสถานะ</Typography>
        }
    }

    const columnTable = [
    {
        field: "avatar&name",
        width:"300px",
        headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
            <i className="fa-solid fa-user-group" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px" }} />
            <Typography style={{color:"#ffffff"}}>ชื่อ-สกุล</Typography>
        </Box>
        ),
        format: (params) => (
        <Tooltip title="ดูประวัติ" placement="top-start">
            <Box className="column-name" component={Link} to={`/hunter/peer/profile/${params.idManpower}`}>
            <Avatar sx={{ bgcolor: stringToColor(`${params.name} ${params.lastname}`) }} children={`${params.name[0]}${params.lastname[0]}`} />
            <Box>
                <Typography style={{color:"#000000"}}>{`${params.name} ${params.lastname}`}</Typography>
                <Typography style={{ color:"#919eab" }}>{`${params.telephoneMobile}`}</Typography>
            </Box>
            </Box>
        </Tooltip>
        )
    },
    {
        field: "status",
        width: "130px",
        headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
            <i className="fa-solid fa-user" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px" }} />
            <Typography style={{color:"#ffffff"}}>สถานะ</Typography>
        </Box>
        ),
        format: (params) => {
            return (
                <Box className="column-name">
                    {params.idManpowerStatus && <StatusCandidate row={params} />}
                </Box>
            );
        },
    
    },
    ...isBidding ? [{
        field: "positionName",
        width: "240px",
        headerName: (
            <Box style={{ display: "flex", alignItems: "center" }}>
                <i 
                    className="fa-solid fa-briefcase" 
                    style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px", marginTop: "2px" }} 
                />
                <Typography style={{color:"#ffffff"}}>ตำแหน่งงาน</Typography>
            </Box>
        ),
        format: (params) => (
            <Box className="column-name">
                <Typography>{params.positionName}</Typography>
            </Box>
        )
    }] : [],
    {
        field: "time",
        width: 150,
        headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
            <i className="fa-solid fa-clock" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px", marginTop: "2px" }} />
            <Typography style={{color:"#ffffff"}}>เวลาสัมภาษณ์</Typography>
        </Box>
        ),
        format: (params) => (
        <Box className="column-name">
            <Typography>
                {params.appointmentStartTime ? `${dayjs(params.appointmentDate).format('DD/MM/YYYY')} ${displayTime(params.appointmentStartTime)}` : "-"}
            </Typography>
        </Box>
        )
    },
    {
        field: "place",
        width: 400,
        headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
            <i className="fa-solid fa-location-dot" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px" }} />
            <Typography style={{color:"#ffffff"}}>สถานที่สัมภาษณ์</Typography>
        </Box>
        ),
        format: (params) => (
        <Box className="column-name">
            <Typography>{params.appointmentPlace || "-"}</Typography>
        </Box>
        )
    },
    ];
    
    return (
        <Fragment>
          <CardStyle>
            <StyledContent>
              <Stack direction="row" justifyContent={"space-between"}>
                <Typography variant="h5">กำลังพลที่ส่ง</Typography>
                <ButtonBlue variant="outlined" startIcon={<SettingsIcon />} onClick={handleClickOpenDrawer}>
                  จัดการเวลาสัมภาษณ์
                </ButtonBlue>
              </Stack>
              <Box style={{ margin: "20px 0" }}>
                <TableTheme
                  columns={columnTable}
                  rows={listCandidatesSent}
                  textEmpty="ไม่พบผู้สมัคร"
                  minHeight={200}
                />
              </Box>
            </StyledContent>
          </CardStyle>
          {openDrawer.status && idJobGroup && (
            <DrawerInterviewAppointment
              open={openDrawer.status}
              onClose={handleCloseDrawer}
              id={props.id}
              listCandidate={listCandidatesSent}
              idJobGroup={idJobGroup}
            //   type={props.match.params.type}
            //   setIsSuccess={setIsSuccess}
            //   handleCountDownTimeSet={handleCountDownTimeSet}
            />
          )}
          {openDialog.status &&
            <DialogCancelled
              open={openDialog.status}
              onClose={() => setOpenDialog({ status: false, candidate: null })}
              candidate={openDialog.candidate}
              handleSubmit={handleSubmitCancelled}
            />
          }
        </Fragment>
    );
};

export default CardCandidateSent;