import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import OrganizationList from "./OrganizationList";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationChart } from "../../../../../actions/orgChart";
import DialogAddStructure from "./DialogAddStructure";
import { getManager } from "../../../../../actions/manager";
// import organization from "./CEOOffice";
import AddIcon from "@mui/icons-material/Add";

import Pic from "../../../assets/-_approval list.png"
import { deleteOrganizationStructure, getOrganizationStructure } from "../../../../../actions/organizationStructure";
import Notification from "../../../shared/general/Notification";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";
import DialogEditCEO from "./DialogEditCEO";

const StyledRoot = styled(Box)({
  marginTop: "30px",
})

const OrganizationStructure = () => {

  const dispatch = useDispatch();

  const { result: OrganizationData } = useSelector(state => state.organizationChart);
  const { result: OrganizationStructureData } = useSelector(state => state.organizationStructure);

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  })

  const [drawerCEOConfig, setDrawerCEOConfig] = useState({
    isOpen: false,
    // isEdit: false,
    data: null,
  })

  const [deleteConfig, setDeleteConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  })

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  })

  const handleClickLoad = async () => {
    console.log(Pic);

    const arrayBuffer = await fetch(Pic).then(res => res.arrayBuffer())

    console.log(arrayBuffer)
  }

  const getFilterData = (tag) => {
    if(OrganizationData){
      return OrganizationData.filter(o => o.tag === tag)
    } else {
      return []
    }
  }

  const getStructureData = () => {
    if(OrganizationData){
      return OrganizationData
    } else {
      return []
    }
  }

  const handleAddStructure = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: null,
    }))
  }

  const handleCloseDialog = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleCloseDeleteModal = () => {
    setDeleteConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleDelete = async () => {

    const response = await dispatch(deleteOrganizationStructure({
      nodeType: deleteConfig.data.nodeType,
      idNode: deleteConfig.data.idNode,
    }))

    if(response){
      if(response.status === 200){
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: "บันทึกข้อมูลสำเร็จ"
        }))
        dispatch(getOrganizationStructure())
        handleCloseDeleteModal();
      } else {
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: "ไม่สามารถลบได้ กรุณาติดต่อแอดมิน"
        }))
      }
    } else {
      setAlertConfig(prev => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      }))
    }

    setDeleteConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  useEffect(() => {
    // dispatch(getOrganizationChart())
    dispatch(getManager())
    dispatch(getOrganizationStructure())
  }, [])

  return (
    <StyledRoot>
      <Box marginBottom="16px">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography fontSize="1.5rem" fontWeight="500">จัดการโครงสร้างองค์กร</Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<AddIcon />}
              // onClick={() => {
              //   setOpenDialog({
              //     ...openDialog,
              //     isOpen: true,
              //   });
              // }}
              onClick={handleAddStructure}
            >
              เพิ่มโครงสร้าง
            </ButtonBlue>
          </Grid>
        </Grid>
      </Box>

      {/* <Button
        onClick={handleClickLoad}
      >
        Download
      </Button> */}

      <Box marginTop="24px">
        <OrganizationList
          title="CEO Office"
          // data={getFilterData("CEO")}
          setDrawerConfig={setDrawerCEOConfig}
          data={OrganizationStructureData? OrganizationStructureData.ceos: []}
          setDeleteConfig={setDeleteConfig}
        />
      </Box>
      <Box marginTop="24px">
        <OrganizationList
        title="Business Unit" 
        // data={getFilterData("BUSINESSUNIT")}
        setDrawerConfig={setDrawerConfig}
        data={OrganizationStructureData? OrganizationStructureData.businessUnits: []}
        setDeleteConfig={setDeleteConfig}
        />
      </Box>
      <Box marginTop="24px">
        <OrganizationList
          title="Division" 
          // data={getFilterData("DIVISION")}
          setDrawerConfig={setDrawerConfig}
          data={OrganizationStructureData? OrganizationStructureData.divisions: []}
          setDeleteConfig={setDeleteConfig}
        />
      </Box>
      <Box marginTop="24px">
        <OrganizationList
          title="Department"
          // data={getFilterData("DEPARTMENT")}
          setDrawerConfig={setDrawerConfig}
          data={OrganizationStructureData? OrganizationStructureData.departments: []}
          setDeleteConfig={setDeleteConfig}
        />
      </Box>
      <Box marginTop="24px">
        <OrganizationList 
          title="Section" 
          // data={getFilterData("SECTION")}
          setDrawerConfig={setDrawerConfig}
          data={OrganizationStructureData? OrganizationStructureData.sections: []}
          setDeleteConfig={setDeleteConfig}
        />
      </Box>
      {/* <Box marginTop="24px">
        <OrganizationList 
          title="Position"
          // data={getFilterData("POSITION")}
          setDrawerConfig={setDrawerConfig}
          data={OrganizationStructureData? OrganizationStructureData.positions: []}
          setDeleteConfig={setDeleteConfig}
        />
      </Box> */}

      <DialogAddStructure
        drawerConfig={drawerConfig}
        onClose={handleCloseDialog}
        // data={getStructureData()}
        data={OrganizationStructureData? OrganizationStructureData: []}
        setAlertConfig={setAlertConfig}
      />

      <DialogEditCEO
        drawerConfig={drawerCEOConfig}
        onClose={() => {
          setDrawerCEOConfig(prev => ({
            ...prev,
            isOpen: false,
          }))
        }}
        setAlertConfig={setAlertConfig}
      />

      <DialogConfirmDelete
        open={deleteConfig.isOpen}
        handleClose={handleCloseDeleteModal}
        label={deleteConfig.data? deleteConfig.data.label: ""}
        text={deleteConfig.data? deleteConfig.data.text: ""}
        handleDelete={handleDelete}
      />

      <Notification
        notify={alertConfig}
        setNotify={setAlertConfig}
      />
    </StyledRoot>
  )
}

export default OrganizationStructure;