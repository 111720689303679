import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { updateUser } from "../../../../../../actions/user";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import dayjs from "dayjs";

const StyledRoot = styled(Box)({
  padding: "16px"
})

const DialogEdit = (props) => {

  const dispatch = useDispatch();

  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    result: yup.string().required("กรุณากรอกข้อมูล"),
    remark: yup.string().test("maxLength", "สามารถกรอกข้อมูลได้สูงสุด 500 ตัวอักษร", (value) => value.length <= 500)
  })

  const useHookForm = useForm({
    defaultValues: {
      result: "",
      remark: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const onSubmit = async (data) => {

    const formData = {};

    formData.idEmployees = employeeProfile.idEmployees;

    // formData.idEmployees = employeeProfile.idEmployees;
    if(drawerConfig.data.round === 1){
      formData.probationR1Result = data.result;
      formData.probationR1ApproveBy = userProfile.idEmployees;
      formData.probationR1ApproveDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
      formData.probationR1Remark = data.remark || null;
    } else if(drawerConfig.data.round === 2){
      formData.probationR2Result = data.result;
      formData.probationR2ApproveBy = userProfile.idEmployees;
      formData.probationR2ApproveDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
      formData.probationR2Remark = data.remark || null;
    }
    

    const result = await dispatch(updateUser(formData));
    if (result) {
      onClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
    // formData.UpdateBy = userProfile.idUsers;
    // if (formData.birthday !== dayjs(employeeProfile.birthday).format("YYYY-MM-DD")) {
    //   formData.birthday = dayjs(formData.birthday).format("YYYY-MM-DD")
    // }
    // console.log(formData)
    // const result = await dispatch(updateUser(formData));
    // if (result) {
    //   handleCloseDialog();
    //   dispatch(getEmployeeProfile(employeeProfile.idEmp));
    // }
  };

  useEffect(() => {
    if(drawerConfig.isOpen === true){
      useHookForm.reset({
        result: drawerConfig.data.result || "",
        remark: drawerConfig.data.remark || "",
      })
    }
  }, [drawerConfig.isOpen])

  return (
    <DrawerCustom
      title={"แก้ไขผลการทดลองงาน"}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="4px">วันที่เริ่มต้น</Typography>
              <Typography fontSize="18px" fontWeight="500">{drawerConfig.data.start}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="4px">วันที่สิ้นสุด</Typography>
              <Typography fontSize="18px" fontWeight="500">{drawerConfig.data.end}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="4px">ผลการทดลองงาน</Typography>
              <Controller
                name="result"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  >
                    <MenuItem value="pass">ผ่านการทดลองงาน</MenuItem>
                    <MenuItem value="nopass">ไม่ผ่านการทดลองงาน</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="4px">หมายเหตุ</Typography>
              <Controller
                name="remark"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    multiline
                    rows={4}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>บันทึก</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
   )
}

export default DialogEdit;