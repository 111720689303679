import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

import {
  CardContent,
  Typography,
  Container,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Menu,
  MenuItem,
  Switch,
  Select,
  Grid,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Checkbox,
} from "@mui/material";

import DatePickerEng from "../../../../shared/general/DatePickerEng";
import DatePickerThai from "../../../../shared/general/DatePickerThai";

const StyledRoot = styled("div")({
  "&.wrap-item-data": {
    marginLeft: 16,
    marginBottom: 8,
  },
  "& .item-name": {
    fontWeight: 600,
    marginBottom: 4,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFormControlLabelSwitch = styled(FormControlLabel)({
  marginRight: 0,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const timePayslip = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const RoundOne = (props) => {
  const { dataForm, handleOnChangeData } = props;
  const today = dayjs().toDate();
  const [payDateYearList, setPayDateYearList] = useState(null);

  useEffect(() => {
    let payDate = [];
    for (let index = -2; index < 3; index++) {
      payDate.push(dayjs(today).add(index, "year").format("YYYY"));
    }

    setPayDateYearList(payDate);
  }, []);

  return (
    <StyledRoot className="wrap-item-data">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <Typography className="item-name">ตัดรอบทุกวันที่</Typography>
          <StyledFormControl fullWidth>
            <Select
              inputProps={{ readOnly: true }}
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="firstCutRoundDate"
              value={dataForm.firstCutRoundDate}
            >
              <MenuItem key={99} value={-1}>
                วันสุดท้ายของเดือน
              </MenuItem>
              {[...Array(29).keys()].map((item) => (
                <MenuItem key={item} value={item + 1}>
                  {item + 1}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="wrap-label-switch">
            <Typography className="item-name">ตัดรอบอนุมัติ</Typography>
            <StyledFormControlLabelSwitch
              checked={dataForm.firstCutOffNextDay}
              control={<Switch size="small" />}
              label="เดือนถัดไป"
              labelPlacement="nextMonth"
            />
          </div>

          <StyledFormControl fullWidth>
            <Select
              inputProps={{ readOnly: true }}
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="firstCutOff"
              value={dataForm.firstCutOff}
            >
              {[...Array(31).keys()].map((item) => (
                <MenuItem key={item} value={item + 1}>
                  {item + 1}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="wrap-label-switch">
            <Typography className="item-name">การคำนวนค่าล่วงเวลา</Typography>
          </div>

          <StyledFormControl fullWidth>
            <Select
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="firstRoundOt"
              value={dataForm.firstRoundOt}
            >
              <MenuItem key={1} value={"now"}>
                ตรงเดือนทำจ่าย
              </MenuItem>
              <MenuItem key={2} value={"previous​"}>
                ย้อนหลัง 1 เดือน
              </MenuItem>
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="wrap-label-switch">
            <Typography className="item-name">การคำนวนมาสาย/ขาดงาน/ลางาน</Typography>
          </div>

          <StyledFormControl fullWidth>
            <Select
              inputProps={{ readOnly: true }}
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="firstRoundLateAbsentLeave"
              value={dataForm.firstRoundLateAbsentLeave}
            >
              <MenuItem key={1} value={"now"}>
                ตรงเดือนทำจ่าย
              </MenuItem>
              <MenuItem key={2} value={"previous​"}>
                ย้อนหลัง 1 เดือน
              </MenuItem>
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="wrap-label-switch">
            <Typography className="item-name">วันออกสลิปเงินเดือน</Typography>
            <StyledFormControlLabelSwitch
              checked={dataForm.firstPayslipNextMonth}
              control={<Switch size="small" />}
              label="เดือนถัดไป"
              labelPlacement="nextMonth"
            />
          </div>

          <StyledFormControl fullWidth>
            <Select
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="firstPayslipDate"
              value={dataForm.firstPayslipDate}
            >
              <MenuItem key={99} value={-1}>
                วันสุดท้ายของเดือน
              </MenuItem>
              {[...Array(29).keys()].map((item) => (
                <MenuItem key={item} value={item + 1}>
                  {item + 1}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className="item-name">เวลาออกสลิปเงินเดือน</Typography>
          <StyledFormControl fullWidth>
            <Select
              inputProps={{ readOnly: true }}
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="firstPayslipTime"
              value={dataForm.firstPayslipTime}
            >
              {timePayslip.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="wrap-label-switch">
            <Typography className="item-name">รูปแบบวันที่เงินออก</Typography>
            <StyledFormControlLabelSwitch
              checked={dataForm.payDayoff}
              control={<Switch size="small" />}
              label="ออกวันหยุด"
              labelPlacement="payDayoff"
            />
          </div>
          <StyledFormControl fullWidth>
            <Select
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="fitstPaysilpPublicType"
              value={dataForm.fitstPaysilpPublicType}
              onChange={handleOnChangeData}
            >
              <MenuItem key={"1"} value={"auto"}>
                อัตโนมัติ
              </MenuItem>
              <MenuItem key={"2"} value={"custom"}>
                กำหนดเอง
              </MenuItem>
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {dataForm.fitstPaysilpPublicType === "auto" ? (
            <Fragment>
              <div className="wrap-label-switch">
                <Typography className="item-name">
                  วันที่เงินเดือนออก
                </Typography>
                <StyledFormControlLabelSwitch
                  checked={dataForm.payslipNextMonth}
                  control={<Switch size="small" />}
                  label="เดือนถัดไป"
                  labelPlacement="nextMonth"
                />
              </div>
              <StyledFormControl fullWidth>
                <Select
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="firstPayDate"
                  value={dataForm.firstPayDate}
                >
                  <MenuItem key={99} value={-1}>
                    วันสุดท้ายของเดือน
                  </MenuItem>
                  {[...Array(29).keys()].map((item) => (
                    <MenuItem key={item} value={item + 1}>
                      {item + 1}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Fragment>
          ) : (
            <Fragment>
              <div className="wrap-label-switch">
                <Typography className="item-name">
                  วันที่เงินออกประจำปี
                </Typography>
                <StyledFormControlLabelSwitch
                  checked={dataForm.payslipNextMonth}
                  control={<Switch size="small" />}
                  label="เดือนถัดไป"
                  labelPlacement="nextMonth"
                />
              </div>
              <StyledFormControl fullWidth>
                <Select
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="payDateYear"
                  value={dataForm.payDateYear}
                >
                  {payDateYearList &&
                    payDateYearList.map((x, index) => (
                      <MenuItem key={index} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                </Select>
              </StyledFormControl>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {dataForm.payDateMethod === "custom" && (
            <Fragment>
              {[...Array(12).keys()].map((item) => (
                <div className="custom-date-payment">
                  <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                    {dayjs(today)
                      .locale(localStorage.getItem("language") || "th")
                      .set("year", dataForm.payDateYear)
                      .set("month", item)
                      .format(
                        localStorage.getItem("language") === "th"
                          ? "MMMM BBBB"
                          : "MMMM YYYY"
                      )}
                  </Typography>
                  <div>
                    {localStorage.getItem("language") === "en" ? (
                      <DatePickerEng name="start" />
                    ) : (
                      <DatePickerThai name="start" />
                    )}
                  </div>
                </div>
              ))}
            </Fragment>
          )}
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default RoundOne;
