import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonBlue from "../../shared/general/ButtonBlue";
import UploadFile from "../announcement/uploadFile";
import { useDispatch, useSelector } from "react-redux";
import {
  addWarningLetter,
  getWarningLetter,
  updateWarningLetter,
} from "../../../../actions/warningLetter";

const DialogCreate = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.employees);
  const { result: warningLevel } = useSelector((state) => state.warningLevel);

  const dataAnnouncement = {
    title: "",
    detail: "",
    file: null,
    employee: null,
    idWarningLevel: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(100).required("Title is required"),
    detail: Yup.string().max(450),
    file: Yup.mixed().nullable(),
    employee: Yup.object().required("Employee name is required").nullable(),
    idWarningLevel: Yup.number().required("Warning level is required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: dataAnnouncement,
    resolver: yupResolver(validationSchema),
  });

  const handleAddWarningLetter = async (values) => {
    values.idEmployees = values.employee.idEmployees;

    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }

    let res = null;
    if (data) {
      res = await dispatch(updateWarningLetter(formData));
    } else {
      res = await dispatch(addWarningLetter(formData));
    }

    if (res) {
      reset();
      handleClose();
      dispatch(getWarningLetter());
    }
  };

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        employee: { idEmployees: data.idEmployees },
      });
    }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
      <DialogTitle>{data ? "แก้ไขหนังสือเตือน" : "สร้างหนังสือเตือน" }</DialogTitle>
      <form onSubmit={handleSubmit(handleAddWarningLetter)}>
        <DialogContent>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="ชื่อหนังสือเตือน"
                    error={Boolean(errors.title)}
                    helperText={errors.title && errors.title.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="detail"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    label="ข้อความเตือนเหตุผล"
                    error={Boolean(errors.detail)}
                    helperText={errors.detail && errors.detail.message}
                  />
                )}
              />
            </Grid>
            {data ? (
              <>
                <Grid item xs={12} sm={7}>
                  <FormControl fullWidth error={errors.idWarningLevel}>
                    <InputLabel>ระดับการเตือน</InputLabel>
                    <Controller
                      name="idWarningLevel"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} label="ระดับการเตือน">
                          {warningLevel
                            .filter((element) => element.active)
                            .map((item) => (
                              <MenuItem
                                key={item.idWarningLevel}
                                value={item.idWarningLevel}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                    {errors.idWarningLevel && (
                      <FormHelperText error={errors.idWarningLevel}>
                        {errors.idWarningLevel.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5}>
                  {data.file && (
                    <Button
                      variant="outlined"
                      component={Link}
                      href={data.file}
                      target="_blank"
                      startIcon={<AttachFileIcon />}
                    >
                      ไฟล์แนบ
                    </Button>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={7}>
                  <Controller
                    name="employee"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={employees}
                        isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                        getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.idEmployees}>
                              {`${option.firstname_TH} ${option.lastname_TH}`}
                            </li>
                          );
                        }}
                        onChange={(e, value) => field.onChange(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="รายชื่อพนักงาน"
                            error={Boolean(errors.employee)}
                            helperText={
                              errors.employee && errors.employee.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormControl fullWidth error={errors.idWarningLevel}>
                    <InputLabel>ระดับการเตือน</InputLabel>
                    <Controller
                      name="idWarningLevel"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} label="ระดับการเตือน">
                          {warningLevel
                            .filter((element) => element.active)
                            .map((item) => (
                              <MenuItem
                                key={item.idWarningLevel}
                                value={item.idWarningLevel}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                    {errors.idWarningLevel && (
                      <FormHelperText error={errors.idWarningLevel}>
                        {errors.idWarningLevel.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    onChange={field.onChange}
                    acceptFileType={"image/jpeg, image/png, application/pdf"}
                    acceptFileTypeLabel={"jpg, png and pdf"}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" type="button" onClick={handleClose}>
            ยกเลิก
          </Button>
          <ButtonBlue variant="contained" type="submit">
            ยืนยัน
          </ButtonBlue>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreate;
