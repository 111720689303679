import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Popper,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import DoneAll from "@mui/icons-material/DoneAll";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import SnackBarCustom from "../../../shared/snackbarCustom";
import Paper from "@mui/material/Paper";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { getAllEmployees } from "../../../../../actions/employee";
import {
  approveOnBoardingEmployee,
  clearOnBoardingEmployee,
  getOnBoardingEmployeeByID,
} from "../../../../../actions/onBoarding";
import DialogAdd from "./DialogAdd";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const OnBoardingList = [
  { name: "คืนบัตรพนักงาน", reviewer: "เมษา", departmentName: "HRBP" },
];

const ReviewerOnBoarding = () => {
  const dispatch = useDispatch();
  const { result: onBoardingEmployee } = useSelector((state) => state.onBoardingEmployeeByID);
  const { result: employees } = useSelector((state) => state.employees);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(clearOnBoardingEmployee());
  }, []);

  useEffect(() => {
    if (selectedEmployee) dispatch(getOnBoardingEmployeeByID(selectedEmployee));
  }, [selectedEmployee, openDialog]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setFormData(selectedEmployee);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setFormData(null);
  };

  const handleApprovalRequest = async () => {
    const idOnBoardingEmployee = [];
    selectedRows.map((x) => {
      idOnBoardingEmployee.push(x.idOnBoardingEmployee);
    });
    const data = {
      idOnBoardingEmployee: idOnBoardingEmployee,
      isApprove: 1,
      updateDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };

    const result = await dispatch(approveOnBoardingEmployee(data));
    if (result) {
      setSelectedRows([]);
      if (result.status === 200) {
        dispatch(getOnBoardingEmployeeByID(selectedEmployee));
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการสำเร็จ",
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการไม่สำเร็จ",
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: "ดำเนินการไม่สำเร็จ",
        type: "error",
      });
    }
    setSelectedRows([]);
  };

  return (
    <div>
      <StyledWrapBranch>
        <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />
        <Box>
          <Grid
            container
            alignItems="center"
            spacing={2}
            marginBottom={4}
            justifyContent={"space-between"}
          >
            <Grid item xs={12} sm={3} justifySelf={"flex-start"}>
              <StyledBoxSearch>
                <div className="search-name">
                  {employees && (
                    <StyledAutocomplete
                      options={employees}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedEmployee(newValue.idEmployees);
                        } else {
                          setSelectedEmployee(null);
                          dispatch(clearOnBoardingEmployee());
                          setSelectedRows([]);
                        }
                      }}
                      popupIcon={<i className="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) =>
                        `${option.firstname_TH}  ${option.lastname_TH}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder="เลือกพนักงาน"
                        />
                      )}
                      PopperComponent={StyledPopper}
                      noOptionsText={"ไม่พบข้อมูล"}
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
            {selectedEmployee && (
              <Grid item xs={12} sm={3} justifySelf={"flex-end"}>
                <StyledBoxSearch>
                  <div
                    className="wrap-search-action"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {selectedRows.length > 0 && (
                      <ButtonBlue
                        style={{ marginRight: "8px" }}
                        size="small"
                        startIcon={<DoneAll />}
                        variant="contained"
                        onClick={() => handleApprovalRequest()}
                      >
                        {`อนุมัติ ${
                          selectedRows.length > 0
                            ? `${selectedRows.length} รายการ`
                            : ""
                        }`}
                      </ButtonBlue>
                    )}
                    <ButtonBlue
                      size="small"
                      variant="contained"
                      onClick={() => handleOpenDialog()}
                    >
                      เพิ่มรายการ
                    </ButtonBlue>
                  </div>
                </StyledBoxSearch>
              </Grid>
            )}
          </Grid>
        </Box>
        {onBoardingEmployee && onBoardingEmployee.length > 0 ? (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">รายการ</TableCell>
                    <TableCell align="center">ผู้ตรวจสอบ</TableCell>
                    <TableCell align="center">หน่วยงาน</TableCell>
                    <TableCell align="center">ดำเนินการภายใน(วัน)</TableCell>
                    <TableCell align="center">สถานะ</TableCell>
                    <TableCell align="center">วันที่</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {onBoardingEmployee.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {row.OnBoardingListName}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {`${row.reviewer_firstname_TH}  ${row.reviewer_lastname_TH}`}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {row.departmentName}{" "}
                      </TableCell>
                      <TableCell align="center">{row.day} </TableCell>
                      <TableCell align="center">
                        {row.isApprove ? (
                          <CheckCircleOutlineIcon style={{ color: "green" }} />
                        ) : selectedRows.includes(row) ? (
                          <AddTaskIcon
                            onClick={() => {
                              setSelectedRows(
                                selectedRows.filter((x) => {
                                  return x != row;
                                })
                              );
                            }}
                          />
                        ) : (
                          <CancelOutlinedIcon
                            style={{ color: "red" }}
                            onClick={() => {
                              setSelectedRows((selectedRows) => [
                                ...selectedRows,
                                row,
                              ]);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(row.updateDate).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          "ไม่พบรายการ"
        )}
      </StyledWrapBranch>

      {openDialog && (
        <DialogAdd
          open={openDialog}
          data={formData}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default ReviewerOnBoarding;
