import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Autocomplete, Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Controller, useForm, useHookForm } from "react-hook-form";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { addFamily } from "../../../../../../actions/family";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import { getWorkInfoByidCompany } from "../../../../../../actions/company";
import { updateUser } from "../../../../../../actions/user";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px"
  }
})

const DialogฺBank = (props) => {

  const dispatch = useDispatch();

  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    bookBank: yup.string().required("โปรดกรอกข้อมูล"),
    bookBankBranchName: yup.string().required("โปรดกรอกข้อมูล"),
    bookID: yup.string().required("โปรดกรอกข้อมูล"),
  })

  const useHookForm = useForm({
    defaultValues: {
      bookBank: "",
      bookBankBranchName: "",
      bookID: "",
      reportBankBankName: "",
      reportBankBankID: "",
      reportBankBookBankID: "",
      reportBankName: "",
      reportBankRef: ""
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  useEffect(() => {
    if(drawerConfig.isOpen === true){
      if(drawerConfig.isEdit){
        useHookForm.reset({
          bookBank: drawerConfig.data.bookBank || "",
          bookBankBranchName: drawerConfig.data.bookBankBranchName || "",
          bookID: drawerConfig.data.bookID || "",
          reportBankBankName: drawerConfig.data.reportBankBankName || "",
          reportBankBankID: drawerConfig.data.reportBankBankID || "",
          reportBankBookBankID: drawerConfig.data.reportBankBookBankID || "",
          reportBankName: drawerConfig.data.reportBankName || "",
          reportBankRef: drawerConfig.data.reportBankRef || "",
        })
      } else {
        useHookForm.reset({
          bookBank: "",
          bookBankBranchName: "",
          bookID: "",
          reportBankBankName: "",
          reportBankBankID: "",
          reportBankBookBankID: "",
          reportBankName: "",
          reportBankRef: "",
        })
      }
    }
  }, [drawerConfig.isOpen])

  const onSubmit = async (data) => {
    // console.log(data)
    
    const formData = {
      ...data,
      idEmployees: employeeProfile.idEmployees,
      UpdateBy: userProfile.idEmployees,
      updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss")
    }

    Object.keys(formData).map(k => {
      if(formData[k] === ""){
        formData[k] = null
      } else {
        formData[k] = formData[k]
      }
    })

    // formData.birthday = dayjs(formData.birthday).format("YYYY-MM-DD")

    // if(drawerConfig.isEdit){
    //   formData.idFamily = drawerConfig.data.idFamily;
    //   formData.updateDate = dayjs().format("YYYY-MM-DD HH:mm:ss")
    // } else {
    //   formData.isApprove = 1;
    //   formData.idAdmin = userProfile.idEmployees;
    //   formData.approveDate = dayjs().format("YYYY-MM-DD HH:mm:ss")
    // }

    const result = await dispatch(updateUser(formData))
    // const result = {status: 200}

    // console.log(result)
    
    if(result){
      if(result.status === 200){
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true))
        onClose();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      // }))
    }
  }

  return (
    <DrawerCustom
      title={"แก้ไขข้อมูลธนาคาร"}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">ธนาคาร</Typography>
              <Controller
                name="bookBank"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  >
                    <MenuItem value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</MenuItem>
                    <MenuItem value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</MenuItem>
                    <MenuItem value="ธนาคารกรุงศรีอยุธยา">ธนาคารกรุงศรีอยุธยา</MenuItem>
                    <MenuItem value="ธนาคารกสิกรไทย">ธนาคารกสิกรไทย</MenuItem>
                    <MenuItem value="ธนาคารทหารไทยธนชาต">ธนาคารทหารไทยธนชาต</MenuItem>
                    <MenuItem value="ธนาคารไทยพาณิชย์">ธนาคารไทยพาณิชย์</MenuItem>
                    <MenuItem value="ธนาคารซีไอเอ็มบี ไทย">ธนาคารซีไอเอ็มบี ไทย</MenuItem>
                    <MenuItem value="ธนาคารยูโอบี">ธนาคารยูโอบี</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label">สาขา</Typography>
              <Controller
                name="bookBankBranchName"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="field-label">เลขที่บัญชี</Typography>
              <Controller
                name="bookID"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      allowNegative: false,
                      allowLeadingZeros: true,
                      value: field.value,
                      thousandSeparator: false,
                      decimalSeparator: "",
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value)
                      },
                    }}
                    onChange={()=>{}}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="field-label">รายงานส่งธนาคาร ชื่อธนาคาร</Typography>
              <Controller
                name="reportBankBankName"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    InputProps={{
                      placeholder: "(ไม่บังคับ)"
                    }}
                  />
                )}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Typography className="field-label">รายงานส่งธนาคาร รหัสธนาคาร</Typography>
              <Controller
                name="reportBankBankID"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    InputProps={{
                      placeholder: "(ไม่บังคับ)"
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="field-label">รายงานส่งธนาคาร เลขที่บัญชี</Typography>
              <Controller
                name="reportBankBookBankID"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    InputProps={{
                      placeholder: "(ไม่บังคับ)"
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="field-label">รายงานส่งธนาคาร ชื่อผู้รับเงิน</Typography>
              <Controller
                name="reportBankName"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    InputProps={{
                      placeholder: "(ไม่บังคับ)"
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="field-label">รายงานส่งธนาคาร เลขที่อ้างอิง</Typography>
              <Controller
                name="reportBankRef"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    InputProps={{
                      placeholder: "(ไม่บังคับ)"
                    }}
                  />
                )}
              />
            </Grid>
            

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>บันทึก</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DialogฺBank;