import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getProbation } from "../../../../actions/probation";
import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogProbation from "./DialogProbation";

const RequestProbation = ({ setNumberOfList, searchDate }) => {
    const dispatch = useDispatch();
    const { result: employeesProbation } = useSelector((state) => state.employeesProbation);

    const [pendingRows, setPendingRows] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [openEducation, setOpenEducation] = useState(false);
    const [idEducation, setIdEducation] = useState(null);
    const [value, setValue] = useState(null);
    const [typeDialog, setTypeDialog] = useState("Add");

    const columns = [
        {
            name: "ชื่อ-สกุล",
            minWidth: "230px",
            width: "230px",
            cellRender: (row) => (
                <Box display="flex" alignItems="center">
                    <Avatar
                        sx={{
                            marginRight: "8px",
                            width: 40,
                            height: 40,
                            "& img": { objectFit: "contain" },
                        }}
                        src={row.imageURL}
                    />
                    <Box flexGrow={1}>
                        <Typography>
                            {row.firstname_TH} {row.lastname_TH}
                        </Typography>
                        <Typography color="text.third" fontSize="14px">
                            {row.positionName}
                        </Typography>
                    </Box>
                </Box>
            ),
        },
        {
            name: "วันเข้างาน",
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    <Typography>
                        {dayjs(row.hiringDate).format("D MMM BBBB")}
                    </Typography>
                </Box>
            ),
        },
        {
            name: "วันครบทดลองงาน",
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    {row.probationRoundOne & row.probationRoundTwo ? (
                        <Typography>
                            {dayjs(row.hiringDate).add(row.probationRoundOne + row.probationRoundTwo + row.expandTimeProbation, 'day').format("D MMM BBBB")}
                        </Typography>
                    ) : (
                        <Typography>
                            {dayjs(row.hiringDate).add(row.probationRoundOne, 'day').format("D MMM BBBB")}
                        </Typography>
                    )}
                </Box>
            ),
        },
        {
            name: "รอบทดลองงาน",
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    {row.probationRoundOne & row.probationRoundTwo ? (
                        <Typography>
                            {"รอบที่ 2"}
                        </Typography>
                    ) : (
                        <Typography>
                            {"รอบที่ 1"}
                        </Typography>
                    )}
                </Box>
            ),
        },
        {
            name: "ประเมิน",
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box>
                    <div style={{ display: "flex", justifyContent: "center", align: "center" }}>
                    <ButtonBlue
                        variant="contained"
                        onClick={() =>{setValue(row);handleOpenEducation("Add");}}
                    >
                        {("ประเมิน")}
                        &nbsp;

                    </ButtonBlue>
                    </div>
                </Box>
            ),
        },
    ];

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
      };
    
      const handleOpenAlert = () => {
        setOpenAlert(true);
      };
    
      const handleCloseAlert = () => {
        setOpenAlert(false);
      };

    const handleOpenEducation = (type, idEdu) => {
        if (type === "Add") {
            setTypeDialog("Add");
        } else {
            setTypeDialog("Edit");
        }
        if (idEdu) {
            setIdEducation(idEdu);
        }
        setOpenEducation(true);
    };

    const handleClose = () => {
        setOpenEducation(false);
        setIdEducation(null);
    };

    const handleSubmitProbation = async (result) => {
        handleClose();
        if (result) {
            handleOpenAlert();
            if (result.status === 200) {
                dispatch(getProbation());
                handleChangeAlertType("success");
            } else {
                handleChangeAlertType("error");
            }
        } else {
            handleChangeAlertType("error");
        }
    };

    useEffect(() => {
        const tempPending = [];
        employeesProbation.map((r) => {
            if (dayjs(r.endDate).diff(dayjs(), 'day') <= 30) {
                if (r.probationR1Result === null || r.probationR2Result === null || r.probationR2Result === "expand") {
                    tempPending.push(r);
                }
            }
        });

        if(searchDate.start || searchDate.end) {
            tempPending = tempPending.filter((item) =>
                dayjs(item.endDate).isBetween(searchDate.start || dayjs(), searchDate.end || dayjs())
            );
        }

        setPendingRows(tempPending);
        setNumberOfList(tempPending.length);
    }, [employeesProbation, searchDate]);

    
    useEffect(() => {
        if(employeesProbation) {
            setPendingRows(employeesProbation);
        }
    }, []);

    return (
        <>
            <TableCustom
                columns={columns}
                rows={pendingRows ? pendingRows : []}
            />
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
            {openEducation && (
                <DialogProbation
                    open={openEducation}
                    handleClose={handleClose}
                    idEdu={idEducation}
                    type={typeDialog}
                    value={value}
                    handleSubmitProbation={handleSubmitProbation}
                />
            )}
        </>
    );
};

export default RequestProbation;
