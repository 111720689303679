import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";
import Pagination from "../../../shared/general/Pagination";
import ShowMiniData from "../../../shared/pages/okrs/components/ShowMiniData";
import EmployeeInfo from "../../../shared/pages/okrs/components/EmployeeInfo";

import {
  Avatar,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Toolbar,
  Typography,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useSelector, useDispatch } from "react-redux";
import { getAllOrgPlan } from "../../../../../actions/OrganizationPlans";
import { getAllEmployees } from "../../../../../actions/employee";
import { getUserProfile } from "../../../../../actions/user";

import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import EventIcon from "@mui/icons-material/Event";
import CreateIcon from "@mui/icons-material/Create";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import FlagIcon from "@mui/icons-material/Flag";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: "0 !important",
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
  },
  quater: {
    backgroundColor: "#e53935",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
  PlanList_Object: {
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "#222f3e",
  },
  PlanList_Impact: {
    color: "#9e9e9e",
    marginTop: 8,
    
  },
  PlanList_KR: {
    color: "#222f3e",
    fontSize: "1.1rem",
    fontWeight: "700",
  },
  PlanList_Status: {
    display: "flex",
    alignItems: "center",
    color: "#9e9e9e",
    marginBottom: 8,
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  inProgress: {
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
    minWidth: "100px",
    borderRadius: "15px",
    padding: "5px 10px 5px 10px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#e0f2f1",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "130px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#f9dde0",
    },
  },
  createBtn: {
    border: "1px solid #e0e0e0",
    borderRadius: "50px",
  },
  cardList_achieved: {
    cursor: "pointer",
    backgroundColor: "#fbe9e7",
    color: "#ff5722",
    width: 40,
    height: 40,
    marginRight: 8,
  },
  cardList_KR: {
    backgroundColor: "#ffebee",
    color: "#f44336",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function OrgEvaluationList() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: orgPlanStore } = useSelector((state) => state.OrgPlans);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [planList, setPlanList] = useState([]);
  const [planItems, setPlanItems] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(2);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = planList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = () => {
    dispatch(getAllOrgPlan());
    dispatch(getAllEmployees());
  };

  useEffect(() => {
    if (orgPlanStore) {
      const records = orgPlanStore.filter(function (item) {
        return (
          item.planStatus.includes("Approved") &&
          item.achieveResult !== "Achieved"
        );
      });

      setPlanList(records);
      setPlanItems(records);
      setIsLoading(false);
    }
  }, [orgPlanStore]);

  useEffect(() => {
    if (userProfile) {
      setEmployeeId(userProfile.idEmployees);
    }
  }, [userProfile]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }

    fetchData();
  }, []);

  useEffect(() => {
    let x = [...planItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setPlanList(x);
  }, [searchKey]);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Organization Evaluation</h3>
              </Grid>
            </Grid>
          </div>
          {employeeId && <EmployeeInfo idEmployees={employeeId} />}
          <Toolbar>
            <Grid container justifyContent="flex-end">
              <Grid
                container
                item
                xs={12}
                md={3}
                lg={3}
                justifyContent="flex-end"
              >
                <TextField
                  variant="outlined"
                  style={{ width: "100%" }}
                  label="Search Objective"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </div>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <>
            {/* planList */}
            {(planList.length > 0 &&
              // currentPosts
              currentPosts.map((item) => {
                return (
                  <div key={item.planOrgId}>
                    <Typography variant="h6" className="keyResult">
                      <FlagIcon fontSize="large" className="svgKey" />
                      Objective
                    </Typography>
                    <div className="cardPlan_Objective">
                      <Grid
                        container
                        alignItems="flex-start"
                        alignContent="space-between"
                      >
                        <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                          <Avatar className={classes.quater}>
                            {item.quater}
                          </Avatar>
                        </Grid>
                        <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
                          <Typography
                            className={classes.PlanList_Object}
                            gutterBottom
                          >
                            {item.objectiveName}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={2}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          justifyContent="flex-end"
                        >
                          <Avatar
                            className={classes.cardList_achieved}
                            onClick={() => {
                              history.push({
                                pathname: `/organizationevaluation/${item.objectiveName}`,
                                state: { planList: item },
                              });
                            }}
                          >
                            <LibraryBooksIcon />
                          </Avatar>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                          <Typography className={classes.PlanList_Status}>
                            {item.impactName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                          <ShowMiniData
                            date={item.dateCreate}
                            status={item.planStatus}
                            progress={item.achieveResult}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <Typography variant="h6" className="keyResult">
                      <VpnKeyRoundedIcon fontSize="large" className="svgKey" />
                      Key Result
                    </Typography>
                    {item.keyResult1 && (
                      <div className="cardPlan">
                        <Grid container alignItems="center">
                          <Grid item xs={2} md={1} lg={1} xl={1} sm={2}>
                            <Avatar className={classes.cardList_KR}>
                              <KeyboardArrowRightIcon />
                            </Avatar>
                          </Grid>
                          <Grid item xs={10} md={8} lg={11} xl={11} sm={10}>
                            <Typography className={classes.PlanList_KR}>
                              {item.keyResult1}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                          <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                            <ShowMiniData
                              date={item.dateCreate}
                              status={item.kR1Status}
                              progress={item.achieveResultKR1}
                            />
                          </Grid>
                        </Grid>
                        {item.approvedBy ? (
                          <Grid container>
                            <Grid item xs={2} md={1} lg={1}></Grid>
                            <Grid item xs={10} md={8} lg={8}>
                              <Typography className={classes.PlanList_Status}>
                                Approved By : {item.approvedBy}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                      </div>
                    )}
                    {item.keyResult2 && (
                      <div className="cardPlan">
                        <Grid container alignItems="center">
                          <Grid item xs={2} md={1} lg={1} xl={1} sm={2}>
                            <Avatar className={classes.cardList_KR}>
                              <KeyboardArrowRightIcon />
                            </Avatar>
                          </Grid>
                          <Grid item xs={10} md={8} lg={11} xl={11} sm={10}>
                            <Typography className={classes.PlanList_KR}>
                              {item.keyResult2}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                          <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                            <ShowMiniData
                              date={item.dateCreate}
                              status={item.kR2Status}
                              progress={item.achieveResultKR2}
                            />
                          </Grid>
                        </Grid>
                        {item.approvedBy ? (
                          <Grid container>
                            <Grid item xs={2} md={1} lg={1}></Grid>
                            <Grid item xs={10} md={8} lg={8}>
                              <Typography className={classes.PlanList_Status}>
                                Approved By : {item.approvedBy}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                      </div>
                    )}
                    {item.keyResult3 && (
                      <div
                        className="cardPlan"
                        style={{ marginBottom: "30px" }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={2} md={1} lg={1} xl={1} sm={2}>
                            <Avatar className={classes.cardList_KR}>
                              <KeyboardArrowRightIcon />
                            </Avatar>
                          </Grid>
                          <Grid item xs={10} md={8} lg={11} xl={11} sm={10}>
                            <Typography className={classes.PlanList_KR}>
                              {item.keyResult3}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                          <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                            <ShowMiniData
                              date={item.dateCreate}
                              status={item.kR3Status}
                              progress={item.achieveResultKR3}
                            />
                          </Grid>
                        </Grid>
                        {item.approvedBy ? (
                          <Grid container>
                            <Grid item xs={2} md={1} lg={1}></Grid>
                            <Grid item xs={10} md={8} lg={8}>
                              <Typography className={classes.PlanList_Status}>
                                Approved By : {item.approvedBy}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                      </div>
                    )}
                  </div>
                );
              })) || (
              <div className="cardPlan">
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6" className={classes.noData_Section}>
                      <FindInPageIcon className={classes.noDataSvg} />
                      No Data
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        )}
        {planList.length > 0 ? (
          <Pagination
            paginate={paginate}
            postsPerPage={postsPerPage}
            totalPosts={planList.length}
          />
        ) : null}
      </div>
    </ContainerStyled>
  );
}

export default OrgEvaluationList;
