import React from 'react';

import {
  Dialog,
  DialogContent,
  styled,
  Typography
} from '@mui/material';

import ButtonBlue from '../general/ButtonBlue';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: 450,
  [theme.breakpoints.down('sm')]: {
    width: 270
  },
}));

const DialogComment = (props) => {
  const { open, onClose, comment } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <StyledDialogContent>
        <Typography variant='h5'>เหตุผลที่ไม่อนุมัติ</Typography>
        <div
          style={{
            marginTop: 16,
            padding: "0px 16px",
            border: "1px solid rgba(0,0,0,0.26)",
            borderRadius: 16,
          }}
        >
          <div
            style={{
              maxHeight: 300,
              padding: "16px 0px",
              overflowY: "auto"
            }}
          >
            <Typography>{comment}</Typography>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
          <ButtonBlue
            variant="outlined"
            onClick={onClose}
          >ปิด</ButtonBlue>
        </div>
      </StyledDialogContent>
    </Dialog>
  );
};

export default DialogComment;