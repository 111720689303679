import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, styled } from "@mui/material";

import LeftPanel from "./left";
import RightPanel from "./right";

import { getCompanyProfile } from "../../../../actions/company";
import { getUserProfile } from "../../../../actions/user";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .headerText": {
    fontSize: 12,
    color: "#9e9e9e",
  },
  "& .valueText": {
    wordBreak: "break-word",
  },
});

const CompanyProfilePage = () => {
  const dispatch = useDispatch();
  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );

  useEffect(() => {
    dispatch(getCompanyProfile());
  }, []);

  // useEffect(() => {
  //   if(companyProfileStore != null){
  //     setCompany({...companyProfileStore})
  //   }
  // }, [companyProfileStore])

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" style={{ padding: "8px 0 16px" }}>
        {companyProfile && (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <LeftPanel />
            </Grid>
            <Grid item xs={12} lg={8}>
              <RightPanel />
            </Grid>
          </Grid>
        )}
      </Container>
    </StyledRoot>
  );
};

export default CompanyProfilePage;
