import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import PersonRemoveAlt1OutlinedIcon from "@mui/icons-material/PersonRemoveAlt1Outlined";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const StyledBox = styled(Box)({
  width: 250,
  margin: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledAvatar = styled(Avatar)({
  width: 64,
  height: 64,
  "&.out": {
    opacity: 0.3,
  },
  "& img": {
    objectFit: "contain",
  }
});

const StyledWrapperEmployees = styled("div")({
  margin: "auto",
  textAlign: "center",
  position: "relative",
  width: "100%",
  padding: "8px 0 16px",
});

const StyledScrollsEmployees = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  minHeight: 350,
  position: "relative",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  justifyContent: "center",
  marginBottom: 32,
  "& .Mui-selected": {
    backgroundColor: "#007afd !important",
    color: "#FFFFFF !important",
  },
});

const EmployeeInOut = () => {
  const [alignment, setAlignment] = useState("in");

  const { result: DashBoardOverview } = useSelector((state) => state.dashboardOverview);

  const handleAlignment = (_, newAlignment) => {
    if(newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Box>
      {/* <Box style={{ display:"flex", alignItems:"center" }}>
        <i 
          className="fal fa-users" 
          style={{
            fontSize: 24,
            marginRight: 16,
            color:"#007afd"
          }}
        />
        <Typography variant="h4" className={`cardTitle`} 
          // gutterBottom
        >
          รายชื่อพนักงานเข้าใหม่ - ลาออก
        </Typography>
      </Box> */}
      <StyledToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton value="in" aria-label="left aligned">
          <PersonAddAlt1OutlinedIcon style={{ marginRight: 8 }} />
          <Typography style={{ color:"inherit" }}>เข้าใหม่</Typography>
        </ToggleButton>
        <ToggleButton value="out" aria-label="centered">
          <PersonRemoveAlt1OutlinedIcon style={{ marginRight: 8 }} />
          <Typography style={{ color:"inherit" }}>ลาออก</Typography>
        </ToggleButton>
      </StyledToggleButtonGroup>
      <StyledWrapperEmployees>
        {alignment === "in" && <StyledScrollsEmployees>
          {DashBoardOverview.employeeInfo.employeeIn.map((e, index) => {
            return (
              <StyledBox key={index}>
                <StyledAvatar
                  alt={`${e.firstname_TH} ${e.lastname_TH}`}
                  src={`${e.imageProfile}`}
                  // className={`${alignment}`}
                />
                <div
                  style={{
                    paddingTop: 16,
                    width: "100%",
                    // opacity: alignment === "out" ? 0.4 : 1,
                  }}
                >
                  <Typography align="center" variant="h6" fontSize={16}>
                    {e.firstname_TH} {e.lastname_TH}
                  </Typography>
                  <Typography align="center" variant="body1" fontSize={14} color="text.third">
                    {e.positionName}
                  </Typography>
                  <Typography
                    align="center"
                    variant="body1"
                    fontSize={14}
                    color="success.main"
                    fontWeight={400}
                  >
                    {dayjs(e.hiringDateText, "DD/MM/YYYY").format("D MMMM YYYY")}
                  </Typography>
                </div>
              </StyledBox>
            );
          })}

          {DashBoardOverview.employeeInfo.employeeIn.length <= 0 && (
            <Typography position="absolute" color="text.third" top="50%" left="50%" sx={{transform: "translate(-50%, -50%)"}}>ไม่มีพนักงานเข้าใหม่</Typography>
          )}
        </StyledScrollsEmployees>}

        {alignment === "out" && <StyledScrollsEmployees>
          {DashBoardOverview.employeeInfo.employeeOut.map((e, index) => {
            return (
              <StyledBox key={index}>
                <StyledAvatar
                  alt={`${e.firstname_TH} ${e.lastname_TH}`}
                  src={`${e.imageProfile}`}
                  // className={`${alignment}`}
                />
                <div
                  style={{
                    paddingTop: 16,
                    width: "100%",
                    // opacity: alignment === "out" ? 0.4 : 1,
                  }}
                >
                  <Typography align="center" variant="h6" fontSize={16}>
                    {e.firstname_TH} {e.lastname_TH}
                  </Typography>
                  <Typography align="center" variant="body1" fontSize={14} color="text.third">
                    {e.positionName}
                  </Typography>
                  <Typography
                    align="center"
                    variant="body1"
                    fontSize={14}
                    color="error"
                    fontWeight={400}
                  >
                    {dayjs(e.contractTermainatoinDateText, "DD/MM/YYYY").format("D MMMM YYYY")}
                  </Typography>
                </div>
              </StyledBox>
            );
          })}

          {DashBoardOverview.employeeInfo.employeeOut.length <= 0 && (
            <Typography position="absolute" color="text.third" top="50%" left="50%" sx={{transform: "translate(-50%, -50%)"}}>ไม่มีพนักงานลาออก</Typography>
          )}
        </StyledScrollsEmployees>}
      </StyledWrapperEmployees>
    </Box>
  );
}

export default EmployeeInOut;
