import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import { getEmployeeProfile } from "../../../../../../actions/employee";
import { updateUser } from "../../../../../../actions/user";

import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const { open, handleCloseDrawer } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [formData, setFormData] = useState({
    resignDate: new Date(),
    reasonResign: "",
    reasonResignOther: "",
    isBlacklist: false,
  });

  // useEffect(() => {
  //   if (employeeProfile) {
  //     setFormData({
  //       idEmployees: employeeProfile.idEmployees,
  //       resignDate: employeeProfile.resignDate,
  //       reasonResign: employeeProfile.reasonResign,
  //       reasonResignOther: employeeProfile.reasonResignOther,
  //       isBlacklist: employeeProfile.isBlacklist,
  //     });
  //   }
  // }, [employeeProfile]);

  useEffect(() => {
    if(open){
      setFormData({
        idEmployees: employeeProfile.idEmployees,
        resignDate: employeeProfile.resignDate,
        reasonResign: employeeProfile.reasonResign,
        reasonResignOther: employeeProfile.reasonResignOther,
        isBlacklist: employeeProfile.isBlacklist,
      });
    }
  }, [open])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "isBlacklist") {
      setFormData({
        ...formData,
        [name]: event.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    const data = { ...formData };
    console.log(data)
    data.resignDate = data.resignDate
      ? dayjs(data.resignDate).format("YYYY-MM-DD")
      : null;

    console.log("data : ", data);

    const result = await dispatch(updateUser(data));
    
    if (result) {
      console.log("result: ", result)
      handleCloseDrawer();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    }
  };

  return (
    <DrawerCustom
      title={"เพิ่มข้อมูลลาออก"}
      anchor={"right"}
      open={open}
      onClose={handleCloseDrawer}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledContentLabel gutterBottom>วันที่มีผล</StyledContentLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                value={formData.resignDate}
                inputFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    ["resignDate"]: date,
                  });
                }}
                renderInput={(params) => (
                  <StyledTextField fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel gutterBottom>เหตุผล</StyledContentLabel>
            <StyledFormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="reasonResign"
                value={formData.reasonResign}
                onChange={handleInputChange}
              >
                <MenuItem value={"หมดสัญญา"}>หมดสัญญา</MenuItem>
                <MenuItem value={"ได้งานใหม่"}>ได้งานใหม่</MenuItem>
                <MenuItem value={"ทำธรุกิจส่วนตัว"}>ทำธรุกิจส่วนตัว</MenuItem>
                <MenuItem value={"เหตุผลส่วนตัว"}>เหตุผลส่วนตัว</MenuItem>
                <MenuItem value={"ให้ออก"}>ให้ออก</MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel gutterBottom>
              เหตุผล(เพิ่มเติม)
            </StyledContentLabel>
            <StyledTextField
              name="reasonResignOther"
              value={formData.reasonResignOther}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isBlacklist}
                    name="isBlacklist"
                    onChange={handleInputChange}
                  />
                }
                label="Blacklist"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDrawer}>
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;