import React,{ useState } from "react";
import NewestJob from "./newestJob";
import { Container, styled } from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import ListIcon from '@mui/icons-material/List';
import MenuUser from "./menuUser";

const StyledRoot = styled("div")(({ theme }) => ({
  background: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0"
    }
  },
  paddingTop: 48,
  "& .search-field": {
    maxWidth: 500,
  },
  "& .card-container": {
    marginTop: 48,
  },
  "& .btn-save": {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

function Home(props) {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className="btn-save">
          <ButtonBlue
            variant="outlined"
            onClick={(event) => { setAnchorEl(event.currentTarget) }}
            >
            <ListIcon sx={{ fontSize: "30px" }} />
            &nbsp;
            {("เมนูรายการ")}
          </ButtonBlue>
          <MenuUser
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            />
        </div>
      </Container>
      <NewestJob />
    </StyledRoot>
  );
}

export default Home;
