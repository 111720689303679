import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Stack,
  Container,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Autocomplete,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWarningLetter,
  getWarningLetter,
  getWarningLevel,
} from "../../../../actions/warningLetter";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getAllEmployees } from "../../../../actions/employee";
import CardStyle from "../../shared/general/Card";
import DialogCreate from "./dialogCreate";
import DialogDetail from "./dialogDetail";
import DialogWarningLevel from "./dialogWarningLevel";
import ChipWarningLevel from "./chipWarningLevel";
import ChipLetterStatus from "./chipLetterStatus";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const WarningLetter = () => {
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.employees);
  const { result: warningLevel } = useSelector((state) => state.warningLevel);
  const { result: warningLetter } = useSelector((state) => state.warningLetter);

  const [open, setOpen] = useState(false);
  const [openWarningLevel, setOpenWarningLevel] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [valueFilter, setValueFilter] = useState(null);
  const [selectLetter, setSelectLetter] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectLetter(null);
  };

  const handleClickOpenWarningLevel = () => {
    setOpenWarningLevel(true);
  };

  const handleCloseWarningLevel = () => {
    setOpenWarningLevel(false);
  };

  const handleClickOpenDetail = (item) => {
    setOpenDetail(true);
    setSelectLetter(item);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleClickOpenEdit = (item) => {
    setOpen(true);
    setSelectLetter(item);
  };

  const handleOpenDelete = (item) => {
    setOpenDelete(true);
    setSelectLetter(item);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteWarningLetter = async () => {
    const res = await dispatch(
      deleteWarningLetter(selectLetter.idWarningLetter)
    );
    if (res) {
      handleCloseDelete();
      dispatch(getWarningLetter());
    }
  };

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getWarningLetter());
    dispatch(getWarningLevel());
  }, []);

  return (
    <StyledRoot className={"page"}>
      {employees && (
        <Container maxWidth="lg">
          <WrapHeader>
            <Typography variant="h4">หนังสือเตือนและการลงโทษ</Typography>
          </WrapHeader>
          <CardStyle style={{ padding: 16 }}>
            <Stack
              direction={{ xs: "column-reverse", sm: "row" }}
              alignItems={"center"}
              justifyContent={"space-between"}
              marginBottom={4}
              gap={2}
            >
              <Autocomplete
                fullWidth
                sx={{ maxWidth: 300 }}
                options={employees}
                isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.idEmployees}>
                      {`${option.firstname_TH} ${option.lastname_TH}`}
                    </li>
                  );
                }}
                value={valueFilter}
                onChange={(event, newValue) => {
                  setValueFilter(newValue);
                }}
                renderInput={(params) => (
                  <TextFieldTheme {...params} label="ค้นหารายชื่อพนักงาน" />
                )}
              />
              <div style={{ display: "flex", gap: 8 }}>
                <ButtonBlue
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleClickOpen}
                >
                  เพิ่มรายการ
                </ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  startIcon={<WarningIcon />}
                  onClick={handleClickOpenWarningLevel}
                >
                  จัดการระดับการเตือน
                </ButtonBlue>
              </div>
            </Stack>
            {warningLevel && openWarningLevel && (
              <DialogWarningLevel
                open={openWarningLevel}
                handleClose={handleCloseWarningLevel}
              />
            )}
            {employees && open && (
              <DialogCreate
                open={open}
                handleClose={handleClose}
                data={selectLetter}
              />
            )}
            {selectLetter && openDetail && (
              <DialogDetail
                open={openDetail}
                handleClose={handleCloseDetail}
                data={selectLetter}
              />
            )}
            {openDelete && (
              <Dialog open={openDelete}>
                <DialogTitle>ยืนยันลบหนังสือเตือน</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    หนังสือเตือนจะถูกลบอย่างถาวร
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteWarningLetter}
                  >
                    ยืนยัน
                  </Button>
                  <Button onClick={handleCloseDelete}>ยกเลิก</Button>
                </DialogActions>
              </Dialog>
            )}
            {warningLetter && warningLetter.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">ระดับการเตือน</TableCell>
                      <TableCell align="center">วันที่</TableCell>
                      <TableCell align="center">ชื่อหนังสือเตือน</TableCell>
                      <TableCell align="center">ผู้ถูกเตือน</TableCell>
                      <TableCell align="center">สถานะ</TableCell>
                      <TableCell align="center">รายละเอียด</TableCell>
                      <TableCell align="center">จัดการ</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {warningLetter
                      .filter((value) =>
                        !valueFilter
                          ? true
                          : valueFilter.idEmployees === value.idEmployees
                      )
                      .map((item) => (
                        <TableRow key={item.idWarningLetter}>
                          <TableCell align="center">
                            <ChipWarningLevel
                              id={item.idWarningLevel}
                              label={item.level}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {dayjs(item.createDate).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell align="center">{item.title}</TableCell>
                          <TableCell align="center">{`${item.firstname_TH} ${item.lastname_TH}`}</TableCell>
                          <TableCell align="center">
                            <ChipLetterStatus status={item.accept} />
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="outlined"
                              onClick={() => handleClickOpenDetail(item)}
                            >
                              รายละเอียด
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              style={{ color: "#ffea00" }}
                              onClick={() => handleClickOpenEdit(item)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              style={{ color: "#ff1744" }}
                              onClick={() => handleOpenDelete(item)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>ไม่มีหนังสือเตือน</Typography>
            )}
          </CardStyle>
        </Container>
      )}
    </StyledRoot>
  );
};

export default WarningLetter;
