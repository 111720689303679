import React from "react";
import { Box, Container, styled, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import DailyPanel from "./DailyPanel";
import IndividualPanel from "./IndividualPanel";
import ScheduleMonthly from "./ScheduleMonthly";
import EmployeeShiftPanel from "./employeeShiftPanel";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
})

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const TimeManagement = () => {

  const [view, setView] = React.useState("daily");

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
      // setSelectedEmployee(null);
      // if (newView === "all") {
      //   dispatch(getHoliday(tempSearchDate));
      //   dispatch(getShiftPattern());
      //   //dispatch(getAttendanceCompany(search));
      // }
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            การจัดการเวลา
          </Typography>
        </div>

        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChangeView}
          aria-label="view"
          size="small"
        >
          <ToggleButton
            name="status"
            value="daily"
            aria-label="daily"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            ข้อมูลรายวัน
          </ToggleButton>
          <ToggleButton
            name="status"
            value="individual"
            aria-label="individual"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            ข้อมูลรายบุคคล
          </ToggleButton>
          <ToggleButton
            name="status"
            value="all-employee-monthly"
            aria-label="all-employee-monthly"
            size="small"
            style={{ minWidth: 150,marginRight: 12 }}
          >
            ตารางเวลาทำงาน
          </ToggleButton>
          <ToggleButton
            name="status"
            value="employee-shift"
            aria-label="employee-shift"
            size="small"
            style={{ minWidth: 150 }}
          >
            ตารางกะ
          </ToggleButton>
        </StyledToggleButtonGroup>

        {view === "daily" && (
          <DailyPanel />
        )}
        {view === "individual" && (
          <IndividualPanel />
        )}
        {view === "all-employee-monthly" && (
          <ScheduleMonthly />
        )}
        {view === "employee-shift" && (
          <EmployeeShiftPanel />
        )}
      </Container>
    </StyledRoot>
  )
}

export default TimeManagement;