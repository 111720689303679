import React, { useState } from "react";
import PropTypes from "prop-types";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { Container, Typography, Box, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

//GPS Check in - out
import LocationList from "./GPSCheckInOut/LocationList";
import EditLocation from "./GPSCheckInOut/EditLocation/EditLocation";

//Calendar
import Calendar from "./calendar";

//leave-manage
import LeaveManage from "./leave-manage";

//job-manage
import JobManage from "./job-manage";

import Level from "./level";

import EmploymentType from "./employment-type";

import Payroll from "./payroll";

//user-manage
import UserManage from "./user-manage";

//institute-manage
import InstituteManage from "./institute-manage";

//admin-approve
import AdminApproveLeave from "./leave-manage/admin-approve";

//workTime-manage
import WorkTimeManage from "./workTime-approve";

//log-record
import LogRecord from "./log-record";

//pf
import PF from "./pf";

//probation
import Probation from "./probation";

//estimatePerformance
import EstimatePerformance from "./estimate-performance"
//estimatePerformanceStatus
import EstimatePerformanceStatus from "./estimate-performance-status";

//exitInterview
import ExitInterview from "./exit-interview"
import ExitInterviewStatus from "./exit-interview-status";

import CostElement from "./costElement";

import TaxDeductionSchedule from "./taxDeduction-schedule";
import ManagerPanel from "./manager";
import OrganizationStructure from "./organization-structure";

import Reason from "./reason";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapTabs = styled("div")({
  paddingBottom: 36,
  marginTop: 36,
  width: "100%",
});

const SettingPage = () => {
  const [value, setValue] = useState(0);
  const [itemEdit, setItemEdit] = useState(null);
  const [viewGps, setViewGps] = useState({
    list: true,
    add: false,
    edit: false,
  });

  const handleClickAddGps = () => {
    const temp = {
      list: false,
      add: true,
      edit: false,
    };

    setViewGps(temp);
  };
  const handleClickSaveGps = () => {
    const temp = {
      list: true,
      add: false,
      edit: false,
    };

    setViewGps(temp);
  };
  const handleClickEditGps = (item) => {
    const temp = {
      list: false,
      add: false,
      edit: true,
    };

    setViewGps(temp);
    setItemEdit(item);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          ข้อมูลระบบ
        </Typography>
        <StyledWrapTabs>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
                "& .MuiTab-root.Mui-selected": {
                  color: "#46cbe2",
                },
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#46cbe2",
                },
              }}
            >
              <Tab label="ตำแหน่ง GPS" {...a11yProps(0)} value={0} />
              <Tab label="ปฏิทินบริษัท" {...a11yProps(1)} value={1} />
              <Tab label="วันลา" {...a11yProps(2)} value={2} />
              <Tab label="ประเภทการจ้าง" {...a11yProps(13)} value={13} />
              <Tab label="จัดการตำแหน่งงาน" {...a11yProps(3)} value={3} />
              <Tab
                label="จัดการโครงสร้างองค์กร"
                {...a11yProps(12)}
                value={12}
              />
              <Tab label="จัดการระดับงาน" {...a11yProps(4)} value={4} />
              <Tab label="หัวหน้างาน" {...a11yProps(11)} value={11} />
              <Tab label="PAYROLL" {...a11yProps(5)} value={5} />
              {/* <Tab label="จัดการเวลาทำงาน" {...a11yProps(6)} value={6} /> */}
              <Tab label="จัดการเหตุผล" {...a11yProps(14)} value={14} />
              <Tab
                label="Log การเปลี่ยนแปลงข้อมูล"
                {...a11yProps(7)}
                value={7}
                disabled
              />
              <Tab label="กองทุนสำรองเลี้ยงชีพ" {...a11yProps(8)} value={8} />
              <Tab label="หักลดหย่อนภาษี" {...a11yProps(9)} value={9} />
              <Tab label="ผู้ดูแลระบบ" {...a11yProps(10)} value={10} />
              <Tab label="Cost Element" {...a11yProps(15)} value={15} />
              <Tab label="Probation" {...a11yProps(16)} value={16} />
              <Tab label="แบบฟอร์มประเมินผลงาน" {...a11yProps(17)} value={17} />
              <Tab label="ประเมินพนักงานประจำปี" {...a11yProps(18)} value={18} />
              <Tab label="แบบฟอร์ม Exit Interview" {...a11yProps(19)} value={19} />
              <Tab label="ลาออก" {...a11yProps(20)} value={20} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {viewGps.list && (
              <LocationList
                onClick={handleClickAddGps}
                onClickEdit={handleClickEditGps}
              />
            )}
            {viewGps.edit && (
              <EditLocation
                dataEdit={itemEdit}
                onClickSave={handleClickSaveGps}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Calendar />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <LeaveManage />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <JobManage />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Level />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Payroll />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <WorkTimeManage />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <LogRecord />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <PF />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <TaxDeductionSchedule />
          </TabPanel>
          <TabPanel value={value} index={10}>
            <UserManage />
          </TabPanel>
          <TabPanel value={value} index={11}>
            <ManagerPanel />
          </TabPanel>
          <TabPanel value={value} index={12}>
            <OrganizationStructure />
          </TabPanel>
          <TabPanel value={value} index={13}>
            <EmploymentType />
          </TabPanel>
          <TabPanel value={value} index={14}>
            <Reason />
          </TabPanel>
          <TabPanel value={value} index={15}>
            <CostElement />
          </TabPanel>
          <TabPanel value={value} index={16}>
            <Probation />
          </TabPanel>
          <TabPanel value={value} index={17}>
            <EstimatePerformance />
          </TabPanel>
          <TabPanel value={value} index={18}>
            <EstimatePerformanceStatus />
          </TabPanel>
          <TabPanel value={value} index={19}>
            <ExitInterview />
          </TabPanel>
          <TabPanel value={value} index={20}>
            <ExitInterviewStatus />
          </TabPanel>
        </StyledWrapTabs>
      </Container>
    </StyledRoot>
  );
};

export default SettingPage;
