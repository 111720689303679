import React, { useRef, useState } from "react";
import { Box, CircularProgress, Grid, IconButton, InputAdornment, MenuItem, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
import ClearIcon from "@mui/icons-material/Clear"
import employeeService from "../../../../../services/employee.service";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const BasicInfo = (params) => {

  const { 
    useForm, 
    isDuplicatedPersonalID, 
    setIsDuplicatedPersonalID, 
    // isDuplicatedEmail, 
    setIsDuplicatedEmail, 
    rehiringConfig, 
    setRehiringConfig,
    registrationMetadata
  } = params;

  const [isFetchingPersonalIDCheck, setIsFetchingPersonalIDCheck] = useState(false);

  const [isFetchingEmailCheck, setIsFetchingEmailCheck] = useState(false);

  const timeoutEmailRef = useRef(null);
  const timeoutPersonalIDRef = useRef(null);

  const handleEmailChange = (value) => {
    if(timeoutEmailRef.current){
      clearTimeout(timeoutEmailRef.current);
    }
    const newTimeoutId = setTimeout(() => {
      handleDebounceEmailValidation("email", value);
    }, 500);
    timeoutEmailRef.current = newTimeoutId;
  }

  const handlePersonalIDChange = (value) => {
    if(timeoutPersonalIDRef.current){
      clearTimeout(timeoutPersonalIDRef.current);
    }
    const newTimeoutId = setTimeout(() => {
      handleDebouncePersonalIDValidation("personalID", value);
    }, 500);
    timeoutPersonalIDRef.current = newTimeoutId;
  }

  const handleDebouncePersonalIDValidation = async (type, value) => {

    if(value.length >= 13){
      setIsFetchingPersonalIDCheck(true);
      const res = await employeeService.checkEmployeeDuplicate({
        type: type,
        value: value,
      });
      if (res && res.status === 200) {
        setIsDuplicatedPersonalID(res.data.isDuplicate);
        setRehiringConfig(prev => ({
          ...prev,
          isRehiring: res.data.isRehiring,
          idEmployeesRehiring: res.data.idEmployeesRehiring
        }));
      } else {
        setIsDuplicatedPersonalID(false);
      }
      useForm.trigger("personalID")
      setIsFetchingPersonalIDCheck(false);
    } else{
      if(isDuplicatedPersonalID){
        setIsDuplicatedPersonalID(false);
      }
      if(rehiringConfig.isRehiring){
        setRehiringConfig(prev => ({
          ...prev,
          isRehiring: false,
          idEmployeesRehiring: null
        }));
      }
    }
  }

  const handleDebounceEmailValidation = async (type, value) => {
    if(value){
      setIsFetchingEmailCheck(true);
      const res = await employeeService.checkEmployeeDuplicate({
        type: type,
        value: value,
      });
      if (res && res.status === 200) {
        setIsDuplicatedEmail(res.data.isDuplicate);
      } else {
        setIsDuplicatedEmail(false);
      }
      useForm.trigger("email")
      setIsFetchingEmailCheck(false);
    }
    
  }

  const onClickLoadingRehiringEmployeeInfo = async () => {
    const result = await employeeService.getEmployeeProfile(rehiringConfig.idEmployeesRehiring, "admin");
    if(result && result.status === 200){
      console.log(result.data)
      useForm.setValue("employeeID", result.data.employeeID || "")
      useForm.setValue("title_TH", result.data.title_TH || "")
      useForm.setValue("firstname_TH", result.data.firstname_TH || "")
      useForm.setValue("lastname_TH", result.data.lastname_TH || "")
      useForm.setValue("nickname_TH", result.data.nickname_TH || "")
      useForm.setValue("title_EN", result.data.title_EN || "")
      useForm.setValue("firstname_EN", result.data.firstname_EN || "")
      useForm.setValue("lastname_EN", result.data.lastname_EN || "")
      useForm.setValue("nickname_EN", result.data.nickname_EN || "")
      useForm.setValue("gender", result.data.gender || "")
      useForm.setValue("birthday", result.data.birthday || "")
      useForm.setValue("telephoneMobile", result.data.telephoneMobile || "")
      useForm.setValue("email", result.data.email || "")
      useForm.setValue("houseNo", result.data.houseNo || "")
      useForm.setValue("village", result.data.village || "")
      useForm.setValue("villageNo", result.data.villageNo || "")
      useForm.setValue("alley", result.data.alley || "")
      useForm.setValue("road", result.data.road || "")
      useForm.setValue("subDistrict", result.data.subDistrict || "")
      useForm.setValue("district", result.data.district || "")
      useForm.setValue("provience", result.data.provience || "")
      useForm.setValue("areaCode", result.data.areaCode || "")
      useForm.setValue("maritalStatus", result.data.maritalStatus || "")
      useForm.setValue("passportNumber", result.data.passportNumber || "")
      useForm.setValue("workPermitNumber", result.data.workPermitNumber || "")
      useForm.setValue("emergencyContact", result.data.emergencyContact || "")
      useForm.setValue("emergencyPhone", result.data.emergencyPhone || "")
      useForm.setValue("emergencyRelationship", result.data.emergencyRelationship || "")
      
      // useForm.setValue("employmentTime", result.data.employmentTime || "")
      // useForm.setValue("idPaymentType", result.data.idPaymentType || "")
      // useForm.setValue("idEmploymentType", result.data.idEmploymentType || "")
      // useForm.setValue("idPosition", result.data.idPosition || "")
      // useForm.setValue("sectionName", result.data.sectionName || "")
      // useForm.setValue("departmentName", result.data.departmentName || "")
      // useForm.setValue("divisionName", result.data.divisionName || "")
      // useForm.setValue("jobGroupName", result.data.jobGroupName || "")
      // useForm.setValue("idJobLevel", result.data.idJobLevel || "")
      // useForm.setValue("idPersonnelLevel", result.data.idPersonnelLevel || "")
      // useForm.setValue("filed_office", result.data.filed_office || "")
      // useForm.setValue("workingType", result.data.workingType || "")
      // useForm.setValue("idShift", result.data.shift? (result.data.shift.idShift || ""): "" )
      // useForm.setValue("workingLocation", result.data.workingLocation || "")
      // useForm.setValue("mainWorkingLocationPoint", result.data.mainWorkingLocationPoint || "")
      // useForm.setValue("isFinger", result.data.isFinger || "")
      // useForm.setValue("methodAttendance", result.data.methodAttendance || "")
      // useForm.setValue("idManagerLV1", result.data.managerLV1_idManagerLV1 || "")
      // useForm.setValue("idManagerLV2", result.data.managerLV2_idManagerLV2 || "")
      // useForm.setValue("salary", result.data.salary || "")
      // useForm.setValue("costCenter", result.data.costCenter || "")
      // useForm.setValue("costElement", result.data.costElement || "")
      useForm.setValue("bookBank", result.data.bookBank || "")
      useForm.setValue("bookBankBranchName", result.data.bookBankBranchName || "")
      useForm.setValue("bookID", result.data.bookID || "")
      useForm.setValue("reportBankBankName", result.data.reportBankBankName || "")
      useForm.setValue("reportBankBankID", result.data.reportBankBankID || "")
      useForm.setValue("reportBankBookBankID", result.data.reportBankBookBankID || "")
      useForm.setValue("reportBankName", result.data.reportBankName || "")
      useForm.setValue("reportBankRef", result.data.reportBankRef || "")
    }
  }

  return (
    <Box>
      <Typography className="title-text">ข้อมูลทั่วไป</Typography>
      <Grid container spacing={2}>

        <Grid item xs={4}>
          <Typography>เลขบัตรประจำตัวประชาชน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="personalID"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                  ...(isFetchingPersonalIDCheck && ({endAdornment: <InputAdornment position="end"><Box color="#919eab"><CircularProgress size="14px" color="inherit"/></Box></InputAdornment>,}))
                  // endAdornment: <InputAdornment position="end"><Box color="#919eab"><CircularProgress size="14px" color="inherit"/></Box></InputAdornment>
                }}
                inputProps={{
                  format: (() => {
                    if(field.value.length >= 13){
                      return "#-####-#####-##-#"
                    } else if(field.value.length >= 11 && field.value.length < 13) {
                      return "#-####-#####-###"
                    } else if(field.value.length >= 6){
                      return "#-####-######"
                    } else if(field.value.length >= 2){
                      return "#-#####"
                    } else {
                      return "##"
                    }
                  })(),
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                    handlePersonalIDChange(value)
                  },
                }}
                onChange={()=>{}}
                error={(useForm.formState.errors[field.name])? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
          {rehiringConfig && rehiringConfig.isRehiring && (
            <Box marginTop="8px">
              <ButtonBlue
                variant="contained"
                onClick={() => {onClickLoadingRehiringEmployeeInfo()}}
              >
                โหลดข้อมูลพนักงาน
              </ButtonBlue>
              <Typography marginTop="8px" fontSize="12px" color="text.secondary">*ระบบตรวจพบเลขบัตรประจำตัวประชาชนนี้ เป็นการจ้างพนักงานคนเดิมใหม่อีกรอบ</Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={4}>
          <Typography>ประเภทการจ้าง</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idEmploymentType"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  useForm.setValue("employeeID", registrationMetadata.newEmployeeId.find(item => item.idEmploymentType === e.target.value).employeeID || "", { shouldValidate: true })
                }}
              >
                {registrationMetadata.employmentTypes.map((employmentType, index) => (
                  <MenuItem key={index} value={employmentType.idEmploymentType}>{employmentType.employmentTypeName}</MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>รหัสพนักงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="employeeID"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>คำนำหน้า</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="title_TH"
            control={useForm.control}
            rules={{
              required: {value: true, message: "โปรดกรอกข้อมูล"},
            }}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                select
              >
                <MenuItem value="นาย">นาย</MenuItem>
                <MenuItem value="นาง">นาง</MenuItem>
                <MenuItem value="นางสาว">นางสาว</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ชื่อ</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="firstname_TH"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>นามสกุล</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="lastname_TH"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ชื่อเล่น</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="nickname_TH"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>คำนำหน้า(อังกฤษ)</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="title_EN"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                select
              >
                <MenuItem value="Mr.">Mr.</MenuItem>
                <MenuItem value="Mrs.">Mrs.</MenuItem>
                <MenuItem value="Miss">Miss</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ชื่อ(อังกฤษ)</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="firstname_EN"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>นามสกุล(อังกฤษ)</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="lastname_EN"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ชื่อเล่น(อังกฤษ)</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="nickname_EN"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>เพศ</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="gender"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                // InputProps={{
                //   endAdornment: field.value? (<IconButton className="clear-button" onClick={()=>{field.onChange("")}}><ClearIcon/></IconButton>): null
                // }}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                select
                >
                  <MenuItem value="Male">ชาย</MenuItem>
                  <MenuItem value="Female">หญิง</MenuItem>
                </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>วันเกิด</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="birthday"
            control={useForm.control}
            render={({field}) => (
              <LocalizationProvider
                dateFormats={{
                  year: "BBBB",
                  monthAndYear: "MMMM BBBB",
                }}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  {...field}
                  views={["year", "month", "day"]}
                  inputFormat="DD MMMM BBBB"
                  disableFuture
                  disableMaskedInput
                  openTo="year"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue)
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "วัน เดือน ปี(พ.ศ.)",
                        readOnly: true,
                      }}
                      // InputProps={{
                      //   ...params.InputProps,
                      //   endAdornment: ([field.value? <IconButton className="clear-button date" onClick={()=>{field.onChange(null)}}><ClearIcon/></IconButton>: null, params.InputProps.endAdornment])
                      // }}
                      onBlur={field.onBlur}
                      error={useForm.formState.errors[field.name]? true: false}
                      helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>

        {/* <Grid item xs={4}>
          <Typography>หมายเลขบัตรประชาชน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="personalID"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  format: (() => {
                    if(field.value.length >= 13){
                      return "#-####-#####-##-#"
                    } else if(field.value.length >= 11 && field.value.length < 13) {
                      return "#-####-#####-###"
                    } else if(field.value.length >= 6){
                      return "#-####-######"
                    } else if(field.value.length >= 2){
                      return "#-#####"
                    } else {
                      return "##"
                    }
                  })(),
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                }}
                onChange={()=>{}}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid> */}

        <Grid item xs={4}>
          <Typography>เบอร์โทรศัพท์</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="telephoneMobile"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  format: (() => {
                    if(field.value.length >= 7){
                      return "###-###-####"
                    } else if(field.value.length >= 4){
                      return "###-####"
                    } else {
                      return "####"
                    }
                  })(),
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                }}
                onChange={()=>{}}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>อีเมล</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="email"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  ...(isFetchingEmailCheck && ({endAdornment: <InputAdornment position="end"><Box color="#919eab"><CircularProgress size="14px" color="inherit"/></Box></InputAdornment>,}))
                  }}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  handleEmailChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ที่อยู่</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="houseNo"
                control={useForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                    label="บ้านเลขที่ (ไม่บังคับ)"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="village"
                control={useForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                    label="หมู่บ้าน (ไม่บังคับ)"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="villageNo"
                control={useForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                    label="หมู่ที่ (ไม่บังคับ)"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="road"
                control={useForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                    label="ถนน (ไม่บังคับ)"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="alley"
                control={useForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                    label="ซอย (ไม่บังคับ)"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="areaCode"
                control={useForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name]? true: false}
                    helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                    label="รหัสไปรษณีย์ (ไม่บังคับ)"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Typography>แขวง/ตำบล</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="subDistrict"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>เขต/อำเภอ</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="district"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>จังหวัด</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="provience"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>สัญชาติ</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="nationality"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>สถานภาพ</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="maritalStatus"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  endAdornment: field.value? (<IconButton className="clear-button" onClick={()=>{field.onChange("")}}><ClearIcon/></IconButton>): null,
                  placeholder: "(ไม่บังคับ)"
                }}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                select
                // InputProps={{
                //   placeholder: "(ไม่บังคับ)"
                // }}
              >
                <MenuItem value="โสด">โสด</MenuItem>
                <MenuItem value="สมรส">สมรส</MenuItem>
                <MenuItem value="หม้าย">หม้าย</MenuItem>
                <MenuItem value="หย่า">หย่า</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>เลขหนังสือเดินทาง</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="passportNumber"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>เลขใบอนุญาตทำงาน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="workPermitNumber"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ผู้ติดต่อฉุกเฉิน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="emergencyContact"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>เบอร์ผู้ติดต่อฉุกเฉิน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="emergencyPhone"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                  placeholder: "(ไม่บังคับ)",
                }}
                inputProps={{
                  format: (() => {
                    if(field.value.length >= 7){
                      return "###-###-####"
                    } else if(field.value.length >= 4){
                      return "###-####"
                    } else {
                      return "####"
                    }
                  })(),
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                }}
                onChange={()=>{}}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                // InputProps={{
                //   placeholder: "(ไม่บังคับ)"
                // }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>ความสัมพันธ์กับผู้ติดต่อฉุกเฉิน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="emergencyRelationship"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                InputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            )}
          />
        </Grid>

      </Grid>
    </Box>
  )
}

export default BasicInfo;