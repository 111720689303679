import React, { Fragment, useState } from "react";
import { Autocomplete, Box, CircularProgress, Container, Divider, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Typography, styled } from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import CardStyle from "../../../../shared/general/Card";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import QuarterSelect from "../../../../shared/pages/okrs/components/QuarterSelect";

import FlagIcon from '@mui/icons-material/Flag';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { AddCircleOutlineRounded, Delete } from "@mui/icons-material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DialogStatusForm from "../../../../shared/general/DialogStatusForm";
import { addTeamObjective } from "../../../../../../actions/okrs";

const StyledRoot = styled(Box)({
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
})

const PlanningTeamAddFormPage = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [dialogStatusConfig, setDialogStatusConfig] = useState({
    open: false,
    statusCode: "",
    statusText: "",
    isSuccess: false,
  })

  const [department, setDepartment] = useState([
    {
      idDepartment: 1,
      departmentName: "Production"
    },
    {
      idDepartment: 2,
      departmentName: "Developer"
    },
    {
      idDepartment: 3,
      departmentName: "Sale"
    },
    {
      idDepartment: 4,
      departmentName: "Marketing"
    },
  ])

  const validateYupSchema = yup.object({
    year: yup.string().required("กรุณากรอกข้อมูล"),
    quarter: yup.string().required("กรุณากรอกข้อมูล"),
    objectiveName: yup.string().required("กรุณากรอกข้อมูล"),
    impactName: yup.string().required("กรุณากรอกข้อมูล"),
    keyResults: yup.array().of(yup.object({
      keyResultName: yup.string().required("กรุณากรอกข้อมูล"),
      description: yup.string().required("กรุณากรอกข้อมูล"),
      tacticName: yup.string().required("กรุณากรอกข้อมูล"),
      startDate: yup.string().required("กรุณากรอกข้อมูล"),
      endDate: yup.string().required("กรุณากรอกข้อมูล"),
      measureEvidence: yup.string().required("กรุณากรอกข้อมูล"),
      commitQuality: yup.string().required("กรุณากรอกข้อมูล"),
      measureDataType: yup.string().required("กรุณากรอกข้อมูล"),
      crossTeam: yup.array().of(yup.object({
        idDepartment: yup.string().required("กรุณากรอกข้อมูล")
      })).when('isCrossTeam', {
        is: "1",
        then: yup.array().min(1, "กรุณาใส่ ทีมอย่างน้อย 1 ทีม")
      })
    })).min(1, "กรุณาใส่ ผลลัพธ์หลักอย่างน้อย 1 อย่าง"),
  })

  const useHookForm = useForm({
    defaultValues: {
      objectiveName: "",
      impactName: "",
      year: dayjs().get('year'),
      quarter: "",
      keyResults: [],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const useKeyResultFieldArray = useFieldArray({
    control: useHookForm.control,
    name: "keyResults"
  })

  const keyResultDefaultData = {
    keyResultName: "",
    description: "",
    tacticName: "",
    startDate: "",
    endDate: "",
    // idManager: "",
    measureEvidence: "",
    commitQuality: "",
    measureDataType: "",
    isCrossTeam: "0",
    crossTeam: [],
  }

  const onSubmit = async (data) => {

    data.keyResults.map(k => {
      if(k.startDate){
        k.startDate = dayjs(k.startDate).format("YYYY-MM-DD")
      }
      if(k.endDate){
        k.endDate = dayjs(k.endDate).format("YYYY-MM-DD")
      }
      k.crossTeam = k.crossTeam.map(t => {
        return t.idDepartment
      })
    })

    const response = await dispatch(addTeamObjective(data))

    if(response){
      if(response.status === 200){
        console.log("Success!")
        setDialogStatusConfig((prevState) => ({
          ...prevState,
          open: true,
          statusCode: "success",
          statusText: "เพิ่มรายการสำเร็จ",
          isSuccess: true,
        }))
      } else {
        setDialogStatusConfig((prevState) => ({
          ...prevState,
          open: true,
          statusCode: "error",
          statusText: response.data.message,
          isSuccess: false
        }))
      }
    } else {
      console.log("Error!")
    }
  }

  const onError = (error) => {
    // console.log(error)
    if(error.keyResults && typeof error.keyResults === 'object' && !Array.isArray(error.keyResults)){
      setDialogStatusConfig((prevState) => ({
        ...prevState,
        open: true,
        statusCode: "error",
        statusText: error.keyResults.message,
        isSuccess: false
      }))
    }
  }

  const onCloseDialog = () => {
    if(dialogStatusConfig.isSuccess){
      setDialogStatusConfig(prevState => ({
        ...prevState,
        open: false,
      }))
      history.push(`/okrs/team-planning`)
    } else {
      setDialogStatusConfig(prevState => ({
        ...prevState,
        open: false,
      }))
    }
  }

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <CardStyle>
            <form onSubmit={useHookForm.handleSubmit(onSubmit, onError)}>
              <Box padding="24px">
                <Typography fontSize="20px" paddingBottom="48px">กำหนดเป้าหมายทีม</Typography>
                <Container maxWidth="md">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="flex-end">
                        <Controller
                          name="year"
                          control={useHookForm.control}
                          render={({field}) => (
                            <TextFieldTheme
                              {...field}
                              select
                              style={{width: "150px"}}
                              helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                              error={useHookForm.formState.errors[field.name]? true: false}
                            >
                              {[...Array(3)].map((_, index) => (
                                <MenuItem key={index} value={dayjs().add(index, 'year').format("YYYY")}>{dayjs().add(index, 'year').format("BBBB")}</MenuItem>
                              ))}
                            </TextFieldTheme>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="quarter"
                        control={useHookForm.control}
                        render={({field}) => (
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={6} md={3}>
                                <QuarterSelect value={1} onClick={()=>{field.onChange(1)}} selected={field.value === 1}/>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <QuarterSelect value={2} onClick={()=>{field.onChange(2)}} selected={field.value === 2}/>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <QuarterSelect value={3} onClick={()=>{field.onChange(3)}} selected={field.value === 3}/>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <QuarterSelect value={4} onClick={()=>{field.onChange(4)}} selected={field.value === 4}/>
                              </Grid>
                            </Grid>
                            {useHookForm.formState.errors[field.name]? <Typography margin="13px 14px 0" fontSize="12px" color="error">{useHookForm.formState.errors[field.name].message}</Typography>: null}
                          </Box>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="objectiveName"
                        control={useHookForm.control}
                        render={({field}) => (
                          <TextFieldTheme
                            {...field}
                            inputProps={{
                              placeholder: "วัตถุประสงค์"
                            }}
                            InputProps={{
                              startAdornment: 
                                <InputAdornment position="start">
                                  <FlagIcon />
                                </InputAdornment>,
                            }}
                            helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                            error={useHookForm.formState.errors[field.name]? true: false}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="impactName"
                        control={useHookForm.control}
                        render={({field}) => (
                          <TextFieldTheme
                            {...field}
                            inputProps={{
                              placeholder: "ผลลัพธ์ที่เกิดขึ้น"
                            }}
                            InputProps={{
                              startAdornment: 
                                <InputAdornment position="start">
                                  <AutoAwesomeIcon />
                                </InputAdornment>,
                            }}
                            helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                            error={useHookForm.formState.errors[field.name]? true: false}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Container>
              </Box>

              <Divider />

              <Box padding="24px">
                <Grid container spacing={2}>
                  <Grid item xs={12} container justifyContent="flex-end">
                    <ButtonBlue
                      variant="outlined"
                      startIcon={<AddCircleOutlineRounded />}
                      onClick={() => {
                        useKeyResultFieldArray.append(keyResultDefaultData)
                      }}
                    >
                      เพิ่มผลลัพธ์หลัก
                    </ButtonBlue>
                  </Grid>

                  {
                    useKeyResultFieldArray.fields.map((item, index) => (
                      <Grid key={item.id} item xs={12} container spacing={2}>
                        <Grid item xs={6}>
                          <Typography className="keyresult-text">ผลลัพธ์หลักที่ {index+1}</Typography>
                        </Grid>
                        <Grid item xs={6} container justifyContent="flex-end">
                          <IconButton onClick={() => {useKeyResultFieldArray.remove(index)}}>
                            <Delete />
                          </IconButton>
                        </Grid>

                        <Grid item xs={12}>
                          <Container maxWidth="md">
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4}>
                                <Typography>ผลลัพธ์หลักที่</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.keyResultName`}
                                  control={useHookForm.control}
                                  render={({field}) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: "ชื่อ"
                                      }}
                                      helperText={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>รายละเอียด</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.description`}
                                  control={useHookForm.control}
                                  render={({field}) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: "รายละเอียด"
                                      }}
                                      rows={3}
                                      multiline
                                      helperText={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>กลยุทธ์</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.tacticName`}
                                  control={useHookForm.control}
                                  render={({field}) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: "กลยุทธ์"
                                      }}
                                      helperText={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>วันที่เริ่มต้น และ สิ้นสุด</Typography>
                              </Grid>
                              <Grid item xs={6} sm={4}>
                                <Controller
                                  name={`keyResults.${index}.startDate`}
                                  control={useHookForm.control}
                                  render={({field}) => (
                                    <LocalizationProvider
                                      dateFormats={{
                                        year: "BBBB",
                                        monthAndYear: "MMMM BBBB",
                                      }}
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        {...field}
                                        views={["year", "month", "day"]}
                                        inputFormat="DD MMMM BBBB"
                                        disableMaskedInput
                                        openTo="year"
                                        value={field.value}
                                        onChange={(newValue) => {
                                          field.onChange(newValue)
                                        }}
                                        renderInput={(params) => (
                                          <TextFieldTheme
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "วัน เดือน ปี(พ.ศ.)",
                                              readOnly: true,
                                            }}
                                            onBlur={field.onBlur}
                                            helperText={
                                              useHookForm.formState.errors[field.name.split(".")[0]] 
                                              && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                              && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                                ? useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]].message
                                                : null
                                            }
                                            error={
                                              useHookForm.formState.errors[field.name.split(".")[0]] 
                                              && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                              && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6} sm={4}>
                                <Controller
                                  name={`keyResults.${index}.endDate`}
                                  control={useHookForm.control}
                                  render={({field}) => (
                                    <LocalizationProvider
                                      dateFormats={{
                                        year: "BBBB",
                                        monthAndYear: "MMMM BBBB",
                                      }}
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        {...field}
                                        views={["year", "month", "day"]}
                                        inputFormat="DD MMMM BBBB"
                                        disableMaskedInput
                                        openTo="year"
                                        value={field.value}
                                        onChange={(newValue) => {
                                          field.onChange(newValue)
                                        }}
                                        renderInput={(params) => (
                                          <TextFieldTheme
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "วัน เดือน ปี(พ.ศ.)",
                                              readOnly: true,
                                            }}
                                            onBlur={field.onBlur}
                                            helperText={
                                              useHookForm.formState.errors[field.name.split(".")[0]] 
                                              && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                              && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                                ? useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]].message
                                                : null
                                            }
                                            error={
                                              useHookForm.formState.errors[field.name.split(".")[0]] 
                                              && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                              && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>ข้อมูลที่ใช้วัด</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.measureEvidence`}
                                  control={useHookForm.control}
                                  render={({field}) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: "ข้อมูลที่ใช้วัด"
                                      }}
                                      helperText={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>เป้าหมายของข้อมูลที่ใช้วัด</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.commitQuality`}
                                  control={useHookForm.control}
                                  render={({field}) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: "เป้าหมายของข้อมูลที่ใช้วัด"
                                      }}
                                      helperText={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>ประเภทข้อมูลที่ใช้วัด</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.measureDataType`}
                                  control={useHookForm.control}
                                  render={({field}) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: "ประเภทข้อมูลที่ใช้วัด"
                                      }}
                                      helperText={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[field.name.split(".")[0]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                        && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                          ? true
                                          : false
                                      }
                                      select
                                      // SelectProps={{
                                      //   displayEmpty: true
                                      // }}
                                    >
                                      {/* <MenuItem value="">ประเภทข้อมูล</MenuItem> */}
                                      <MenuItem value="1">ตัวเลข</MenuItem>
                                      <MenuItem value="2">คำบรรยาย</MenuItem>
                                    </TextFieldTheme>
                                  )}
                                />
                              </Grid>

                              <Grid item xs={12} sm={4}>
                                <Typography>การร่วมมือ</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Grid container spacing={2}>
                                  {/* <Grid item xs={12}> */}
                                    <Controller
                                      name={`keyResults.${index}.isCrossTeam`}
                                      control={useHookForm.control}
                                      render={({field}) => (
                                        <Fragment>
                                          <Grid item xs={12}>
                                            <RadioGroup
                                              {...field}
                                              row
                                              onChange={(event) => {
                                                useHookForm.setValue(`keyResults.${index}.crossTeam`, []);
                                                field.onChange(event.target.value);
                                              }}
                                            >
                                              <FormControlLabel value="0" control={<Radio />} label="ไม่มี"/>
                                              <FormControlLabel value="1" control={<Radio />} label="ร่วมกับทีมอื่น"/>
                                            </RadioGroup>
                                          </Grid>
                                          {field.value === "1" && (
                                            <Grid item xs={12}>
                                              <Controller
                                                name={`keyResults.${index}.crossTeam`}
                                                control={useHookForm.control}
                                                render={({field}) => (
                                                  <Autocomplete
                                                    {...field}
                                                    multiple
                                                    filterSelectedOptions
                                                    options={department}
                                                    getOptionLabel={(option) => `${option.departmentName}`}
                                                    isOptionEqualToValue={(option, value) => {
                                                      return option.idDepartment === value.idDepartment
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextFieldTheme
                                                        {...params}
                                                        onBlur={field.onBlur}
                                                        helperText={
                                                          useHookForm.formState.errors[field.name.split(".")[0]] 
                                                          && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                                          && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                                            ? useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]].message
                                                            : null
                                                        }
                                                        error={
                                                          useHookForm.formState.errors[field.name.split(".")[0]] 
                                                          && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]] 
                                                          && useHookForm.formState.errors[field.name.split(".")[0]][field.name.split(".")[1]][field.name.split(".")[2]]
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                    )}
                                                    value={field.value}
                                                    onChange={(_, value) => {console.log(value);field.onChange(value)}}
                                                    // value={employeeList.employees.find(option => option.idEmployees === field.value) || null}
                                                    // onChange={(_, value) => {field.onChange(value? value.idEmployees: "")}}
                                                  />
                                                )}
                                              />
                                            </Grid>
                                          )}
                                        </Fragment>
                                      )}
                                    />
                                  {/* </Grid> */}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      
                      </Grid>
                    ))
                  }

                  <Grid item xs={12} container justifyContent="flex-end">
                    <ButtonBlue
                      variant="contained"
                      type="submit" 
                      disabled={useHookForm.formState.isSubmitting}
                    >
                      {
                        useHookForm.formState.isSubmitting
                          ? <CircularProgress size="21px" color="inherit"/>
                          : "บันทึก"
                      }
                    </ButtonBlue>
                  </Grid>
                  
                </Grid>
              </Box>

            </form>
          </CardStyle>
        </Container>
        <DialogStatusForm
          open={dialogStatusConfig.open}
          onClose={() => {onCloseDialog()}}
          onConfirm={() => {onCloseDialog()}}
          statusCode={dialogStatusConfig.statusCode}
          statusText={dialogStatusConfig.statusText}
        />
      </Box>
    </StyledRoot>
  )
}

export default PlanningTeamAddFormPage;