import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { styled } from '@mui/styles';
import { Chip, Divider, Grid, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import AlertResponse from "../../../shared/general/AlertResponse";

import EmpDialog from "./EmpDialog";
import { deleteInterest, deleteSkill, getEmployeeNetwork } from "../../../../../actions/employee";

const Root = styled("div")(({ theme }) => ({
    marginBottom: 32,
    "& .chip_container": {
        marginTop: 24,
    },
    "& .SkillChip": {
        backgroundColor: "#fff3e0",
        color: "#ff9800",
        fontWeight: 600,
        fontSize: "18px !important",
        padding: "5px 5px"
    },
    "& .InterestChip": {
        backgroundColor: "#e8f5e9",
        color: "#4caf50",
        fontWeight: 600,
        fontSize: "18px !important",
        padding: "5px 5px"
    },
}));

const WrapIcon = styled(Typography)({
    verticalAlign: "middle",
    display: "inline-flex",
});

const Skill_Interests = (props) => {
    const dispatch = useDispatch();
    const { employee, setEmployee } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [openSkillDialog, setOpenSkillDialog] = useState(false);
    const [openInterestDialog, setOpenInterestDialog] = useState(false);
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleDeleteChip = async (type, deletedItem) => {
        let result
        if (type == "skill") result = await dispatch(deleteSkill(deletedItem))
        else result = await dispatch(deleteInterest(deletedItem))
        if (result.status === 200) {
            dispatch(getEmployeeNetwork(employee.idEmployees))
        } else {
            setOpenAlert(true);
        };
    }
    return (
        <Root>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1" gutterBottom>
                        Gender
                    </Typography>
                    <Typography variant="subtitle1">{employee.gender ? employee.gender : "-"}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1" gutterBottom>
                        NickName
                    </Typography>
                    <Typography variant="subtitle1">{employee.nickname_TH ? employee.nickname_TH : "-"}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1" gutterBottom>
                        Phone Number
                    </Typography>
                    <Typography variant="subtitle1">{employee.telephoneMobile ? employee.telephoneMobile : "-"}</Typography>
                </Grid>
            </Grid>
            <Divider />
            <Grid container>
                <Grid item xs>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    <WrapIcon
                                        variant="body1"
                                        gutterBottom
                                    >
                                        <EmojiEventsIcon style={{ marginRight: "5px" }} />
                                        Skills
                                    </WrapIcon>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        size="small"
                                        onClick={() => setOpenSkillDialog(true)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div className="chip_container">
                                <Grid container spacing={1}>
                                    {employee.skills ?
                                        employee.skills.length > 0 && employee.skills.map((skill) => (
                                            <Grid item key={skill.idSkill}>
                                                <Chip
                                                    className="SkillChip"
                                                    label={skill.skill}
                                                    onDelete={() => handleDeleteChip("skill", skill.idSkill)}
                                                />
                                            </Grid>
                                        )) : (
                                            <Grid item>
                                                <Typography variant="body2">-</Typography>
                                            </Grid>
                                        )}
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <WrapIcon
                                        variant="body1"
                                        gutterBottom
                                    >
                                        <AssistantPhotoIcon style={{ marginRight: "5px" }} />
                                        Interests
                                    </WrapIcon>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        size="small"
                                        onClick={() => setOpenInterestDialog(true)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div className="chip_container">
                                <Grid container spacing={1}>
                                    {employee.interests ? employee.interests.length > 0 && employee.interests.map((interest) => (
                                        <Grid item key={interest.idInterest}>
                                            <Chip
                                                className="InterestChip"
                                                label={interest.Interest}
                                                onDelete={() => handleDeleteChip("interest", interest.idInterest)}
                                            />
                                        </Grid>
                                    )) : (
                                        <Grid item>
                                            <Typography variant="body2">-</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openSkillDialog &&
                <EmpDialog
                    employee={employee}
                    objectKey="skills"
                    open={openSkillDialog}
                    onClose={() => setOpenSkillDialog(false)}
                />
            }
            {openInterestDialog &&
                <EmpDialog
                    employee={employee}
                    objectKey="interests"
                    open={openInterestDialog}
                    onClose={() => setOpenInterestDialog(false)}
                />
            }
            <AlertResponse
                style={{ paddingTop: "24px" }}
                open={openAlert}
                handleClose={handleCloseAlert}
            />
        </Root>
    );

};

export default Skill_Interests;