import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { getDepartment } from "../../../../../actions/company";
import {
  Addterminatelist,
  EditTerminatelist,
} from "../../../../../actions/offBoarding";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogName = (props) => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { open, mode, handleClose, data } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const [formData, setFormData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(getDepartment());
    if (open) {
      if (mode === "Edit") {
        setFormData({
          name: data.name,
          idTerminateList: data.idTerminateList,
        });
      } else {
        setFormData({
          name: null,
        });
      }
    }
  }, [open]);

  const handleSubmit = async () => {
    if (!formData.name) {
      handleOpenAlert(true);
      handleChangeAlertType("กรุณากรอกข้อมูลให้ครบ");
    } else {
      if (mode === "AddList") {
        const data = {
          name: formData.name,
          idCompany: userProfile.idCompany,
        };
        const result = await dispatch(Addterminatelist(data));
        if (result) {
          handleClose();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      } else {
        const data = {
          name: formData.name,
          idTerminateList: formData.idTerminateList,
        };
        const result = await dispatch(EditTerminatelist(data));
        if (result) {
          handleClose();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      }
    }
  };

  return (
    <DrawerCustom
      title="แก้ไขรายการลาออก"
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && (
        <StyledRoot>
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} sm={6} className="GridTopicInput">
                ชื่อรายการ
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  name="name"
                  multiline
                  value={formData.name}
                  onChange={handleChange}
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
              บันทึกข้อมูล
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogName;
