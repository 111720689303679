import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Chip, Container, Grid, Slider, SvgIcon, Typography, styled } from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded, StarRounded } from "@mui/icons-material";
import { approveIndividualKeyResult, getIndividualObjectiveForApprove, getIndividualObjectiveForEvaluate } from "../../../../../../../actions/okrs";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import MoonShotSwitch from "../../../../../shared/pages/okrs/components/MoonShotSwitch";
import ChipStatus from "../../../../../shared/pages/okrs/components/ChipStatus";
import { ReactComponent as MoonshotIcon } from "../../../../../assets/moonshot_logo.svg";
import { Controller, useForm } from "react-hook-form";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";

const StyledRoot = styled(Box)({

})

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 0.1,
    label: "0.1",
  },
  {
    value: 0.2,
    label: "0.2",
  },
  {
    value: 0.3,
    label: "0.3",
  },
  {
    value: 0.4,
    label: "0.4",
  },
  {
    value: 0.5,
    label: "0.5",
  },
  {
    value: 0.6,
    label: "0.6",
  },
  {
    value: 0.7,
    label: "0.7",
  },
  {
    value: 0.8,
    label: "0.8",
  },
  {
    value: 0.9,
    label: "0.9",
  },
  {
    value: 1.0,
    label: "1.0",
  },
];

const EvaluationIndividual = (props) => {

  const { match } = props;

  const dispatch = useDispatch();

  const [individualObjective, setIndividualObjective] = useState(null);

  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    
    dispatch(getIndividualObjectiveForEvaluate(match.params.idIndividualObjective)).then(res => {
      if(isMounted){
        setIsFetching(false);
        if(res.status === 200){
          setIndividualObjective(res.data)
        } else {
          setIsError(true);
        }
      }
    })

    return () => {isMounted = false}
  }, [])

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {individualObjective && <Fragment>
          <CardStyle>
            <Box padding="24px">
              <Typography fontSize="24px">{individualObjective.year + 543} Q{individualObjective.quarter}</Typography>
              <Typography fontSize="20px" paddingTop="16px">{individualObjective.objectiveName}</Typography>
              <Typography fontSize="14px" paddingTop="16px"><span style={{color: "#919eab", paddingRight: "8px"}}>ผลลัพธ์ที่เกิดขึ้น</span>{individualObjective.impactName}</Typography>
              {individualObjective.description && <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line"><span style={{color: "#919eab", paddingRight: "8px"}}>รายละเอียด</span>{individualObjective.description}</Typography>}
            </Box>
          </CardStyle>

          <Box marginTop="24px" display="flex" justifyContent="flex-end">
            <Typography>รออนุมัติ {individualObjective.keyResults.filter(k => k.isApprove === null).length} รายการ</Typography>
          </Box>

          <Box marginTop="24px">
            <Grid container spacing={2}>
              {individualObjective.keyResults.map((keyResult, index) => (
                <Grid item xs={12}>
                  <KeyResult data={keyResult}/>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Fragment>}
      </Container>
    </StyledRoot>
  )
}

const KeyResult = (props) => {

  const { data: value } = props;

  const dispatch = useDispatch();

  const [data, setData] = useState(value);

  const [isMoonShotPlanningValue, setIsMoonShotPlanningValue] = useState(false);

  const [isFetching, setIsFetching] = useState(false)

  const useHookForm = useForm({
    defaultValues: {
      rating: 0,
      isMoonShotEvaluation: false,
    }
  })

  const onSubmit = async (submitData) => {
    // setIsFetching(true);

    const formData = {...submitData};

    formData.isMoonShotEvaluation = formData.isMoonShotEvaluation? 1: 0;
    formData.isAchieveApprove = 1;

    let xRating = 0;
    let rating = 0;
    if(data.isMoonShotPlanning){
      xRating += 1.5
    }
    if(data.isMoonShotEvaluation){
      xRating += 1.5
    }
    if(xRating > 0){
      rating = (formData.rating * xRating)
    }

    dispatch(approveIndividualKeyResult(data.idIndividualKeyResult, formData)).then(res => {
      // setIsFetching(false);
      if(res.status === 200){
        setData({
          ...data,
          ...formData,
          rating: rating
        })
      }
    })
  }

  const getStatusAchieve = (status) => {
    if(status === 1){
      return 5
    } else if(status === 0){
      return 6
    } else {
      return 4
    }
  }

  return (
    <Box>
      <CardStyle>
        <Box padding="24px">
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Typography fontSize="20px">{data.keyResultName}</Typography>
            {data.isApprove !== null && (
              <Box display="flex" justifyContent="space-between">
                <ChipStatus status={getStatusAchieve(data.isAchieve)} size="large"/>
              </Box>
            )}
          </Box>
          {(data.isMoonShotPlanning === 1 || data.isMoonShotEvaluation === 1 || data.rating !== null) && <Box display="flex" marginTop="8px" gap="8px" alignItems="center">
            {data.isMoonShotPlanning === 1 && <SvgIcon>
              <MoonshotIcon />
            </SvgIcon>}
            {data.isMoonShotEvaluation === 1 && <SvgIcon>
              <MoonshotIcon />
            </SvgIcon>}
            {data.rating !== null && data.isAchieveApprove !== null && (
              <Chip
                icon={<StarRounded />}
                label={Math.round(data.rating * 100) / 100}
                sx={{
                  backgroundColor: "#fff9db",
                  "& .MuiSvgIcon-root": {
                    color: "rgb(255, 221, 64)",
                  },
                  "& .MuiChip-label": {
                    lineHeight: 1,
                    fontWeight: "500",
                  }
                }}
              />
            )}
          </Box>}
          {/* {data.isMoonShotPlanning === 1 && <Box display="flex" marginTop="8px">
            <SvgIcon>
              <MoonshotIcon />
            </SvgIcon>
          </Box>} */}
          <Box display="flex" alignItems="center" marginTop="8px">
            <AccessTimeRounded fontSize="14px" style={{marginRight: "8px", color: "#919eab"}}/>
            <Typography fontSize="14px" lineHeight="1" color="text.third">{`${dayjs(data.startDate).format("D MMM")} - ${dayjs(data.endDate).format("D MMM BB")}`}</Typography>
          </Box>
          <Box marginTop="8px">
            <Typography fontSize="14px"><span style={{color: "#919eab", paddingRight: "8px"}}>กลยุทธ์</span>{data.tacticName}</Typography>
            <Typography fontSize="14px" paddingTop="8px"><span style={{color: "#919eab", paddingRight: "8px"}}>ข้อมูลที่ใช้วัด</span>{data.measureEvidence}</Typography>
            <Typography fontSize="14px" paddingTop="8px"><span style={{color: "#919eab", paddingRight: "8px"}}>เป้าหมายของข้อมูลที่ใช้วัด</span>{data.commitQuality}</Typography>
            <Typography fontSize="14px" paddingTop="8px"><span style={{color: "#919eab", paddingRight: "8px"}}>ประเภทข้อมูลที่ใช้วัด</span>{data.measureDataType === 1? "ตัวเลข": "คำบรรยาย"}</Typography>
            {data.description && <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line"><span style={{color: "#919eab", paddingRight: "8px"}}>รายละเอียด</span>{data.description}</Typography>}
          </Box>

          {data.isAchieveApprove === null && <Box marginTop="8px">
            <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography>ให้คะแนน</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="rating"
                    control={useHookForm.control}
                    render={({field}) => (
                      <Box display="flex" gap="8px">
                        <Slider
                          max={1}
                          step={0.1}
                          valueLabelDisplay="auto"
                          marks={marks}
                          onChange={(_, newValue) => {
                            field.onChange(newValue)
                            // console.log(newValue)
                          }}
                        />
                        <i className="fa-solid fa-stars" style={{color: "#f9df39"}}></i>
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="space-between" alignItems="flex-end">
                  <Box display="flex" alignItems="center" gap="8px">
                    <Typography fontSize="24px">Moon Shot</Typography>
                    <Controller
                      name="isMoonShotEvaluation"
                      control={useHookForm.control}
                      render={({field}) => (
                        <MoonShotSwitch
                          {...field}
                          checked={field.value}
                        />
                      )}
                    />
                  </Box>
                  <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>ประเมิน</ButtonBlue>
                </Grid>
              </Grid>
            </form>
          </Box>}
        </Box>
      </CardStyle>
    </Box>
  )
}

export default EvaluationIndividual;