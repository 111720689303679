import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Avatar, MenuItem, Typography } from "@mui/material";

const columns = [
  { id: "name", label: "ชื่อ-สกุล", minWidth: 240 },
  {
    id: "position",
    label: "ตำแหน่ง/หน่วยงาน",
    width: 320,
    className: "position",
  },
  {
    id: "email",
    label: "อีเมล์",
    minWidth: 140,
    className: "email",
  },
  {
    id: "report_To_LV1",
    label: "หัวหน้างานลำดับ 1",
    minWidth: 120,
    className: "report_To",
  },
  {
    id: "report_To_LV2",
    label: "หัวหน้างานลำดับ 2",
    minWidth: 120,
    className: "report_To",
  },
];

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  "& .position": {
    minWidth: 200,
  },
  "& .reportTo": {
    minWidth: 150,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  padding: 16,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: 200,
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: 150,
    fontWeight: 600,
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});

const ItemTable = (props) => {
  const { open, filterEmployee, page, setPage, selectedManager } = props;
  const history = useHistory();
  const { result: employeeList } = useSelector((state) => state.employees);
  // const [page, setPage] = React.useState(0);
  const [listEmployees, setListEmployees] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [valueSelected, setValueSelected] = useState(null);

  useEffect(() => {
    if (employeeList) {
      let tempListEmployees = rowsFilter();
      setListEmployees([...tempListEmployees]);
    }
  }, [employeeList, selectedManager, filterEmployee]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const rowsFilter = () => {
    let resultFilter = [...employeeList];

    if (filterEmployee.status === "active") {
      resultFilter = resultFilter.filter((item) => {
        return (
          item.isTerminate === 0 &&
          (selectedManager
            ? selectedManager.idEmployees === item.idManagerLV1 ||
              selectedManager.idEmployees === item.idManagerLV2
            : true)
        );
      });
    } else if (filterEmployee.status === "terminate") {
      resultFilter = resultFilter.filter((item) => {
        return (
          item.isTerminate === 1 &&
          (selectedManager
            ? selectedManager.idEmployees === item.idManagerLV1 ||
              selectedManager.idEmployees === item.idManagerLV2
            : true)
        );
      });
    }

    if (filterEmployee.search == "" && filterEmployee.search === "all") {
      return resultFilter;
    } else {
      // let resultFilter = [...employeeList];
      if (filterEmployee.search.length > 0) {
        resultFilter = resultFilter.filter((item, index) => {
          if (
            (item.firstname_TH &&
              item.firstname_TH.indexOf(filterEmployee.search) >= 0) ||
            (item.lastname_TH &&
              item.lastname_TH.indexOf(filterEmployee.search) >= 0)
          )
            return item;
        });
      }

      if (filterEmployee.idDepartment !== "all") {
        resultFilter = resultFilter.filter(
          (x) => x.idDepartment === filterEmployee.idDepartment
        );
      }

      return resultFilter;
    }
  };

  return (
    <Box>
      <TableContainer style={{ width: "100%", maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledCellHeader
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={column.className}
                >
                  {column.label}
                </StyledCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeList && listEmployees && listEmployees.length > 0 ? (
              listEmployees
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledRowContent key={row.scG_Employee_ID}>
                      <StyledCellContent className="sticky" key={"name"}>
                        <div
                          // onClick={() => {
                          //   console.log("StyledCellContent");
                          //   history.push(`/employees/${row.idEmployees}/edit`);
                          // }}
                          aria-label="more"
                          onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            setValueSelected(row);
                            console.log(row);
                          }}
                          className="fullname"
                        >
                          <IconButton
                            aria-label="more"
                            size="small"
                            style={{ marginRight: 8 }}
                            onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                              setValueSelected(row);
                              console.log(row);
                            }}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                          <Avatar
                            alt={row.firstname_TH}
                            src={`${row.imageProfile}` || ""}
                          />
                          <div>
                            <Typography>
                              {row.firstname_TH} {row.lastname_TH}
                            </Typography>
                            <Typography variant="body2" color="text.third">
                              {row.telephoneMobile &&
                                `${row.telephoneMobile.replace(
                                  /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                                  "$1-$2-$3"
                                )}`}
                            </Typography>
                          </div>
                        </div>
                      </StyledCellContent>
                      <StyledCellContent key={"position"}>
                        <Typography className="position">
                          {row.positionName}
                        </Typography>
                        <Typography className="department" color="text.third">
                          {row.departmentName}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"email"}>
                        <Typography>{row.email}</Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"report_To_LV1"}>
                        <Typography className="reportTo">
                          {row.managerLV1_firstname_TH}{" "}
                          {row.managerLV1_lastname_TH}
                        </Typography>
                        <Typography
                          className="reportToPosition"
                          color="text.third"
                        >
                          {row.managerLV1_email}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"report_To_LV2"}>
                        <Typography className="reportTo">
                          {row.managerLV2_firstname_TH}{" "}
                          {row.managerLV2_lastname_TH}
                        </Typography>
                        <Typography
                          className="reportToPosition"
                          color="text.third"
                        >
                          {row.managerLV2_email}
                        </Typography>
                      </StyledCellContent>
                    </StyledRowContent>
                  );
                })
            ) : (
              <StyledRowContent>
                <StyledCellContent colSpan={5} style={{ height: 150 }}>
                  <Typography align="center">ไม่พบพนักงาน</Typography>
                </StyledCellContent>
              </StyledRowContent>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={employeeList && listEmployees && listEmployees.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="จำนวนแถวต่อหน้า"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} จาก ${count !== -1 ? count : `มากกว่า ${to}`}`}
      />
      <StyledMenu
        id={`more-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {valueSelected && (
          <Fragment>
            {/* <MenuItem
              component={NavLink}
              to={{
                //pathname: `/employees/${valueSelected.idEmployees}/view`,
                pathname: `/information`,
                userProps: {
                  state: valueSelected,
                },
              }}
            >
              <i class="far fa-id-badge" style={{ marginRight: 16 }}></i>
              <Typography>ดูข้อมูล</Typography>
            </MenuItem> */}
            <MenuItem
              component={NavLink}
              to={`/employees/${valueSelected.idEmployees}/edit`}
            >
              <i class="far fa-user-edit" style={{ marginRight: 16 }}></i>
              <Typography>แก้ไขข้อมูล</Typography>
            </MenuItem>
          </Fragment>
        )}
      </StyledMenu>
    </Box>
  );
};

export default ItemTable;
