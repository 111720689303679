import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Typography,
  TextField,
  Box,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import CardStyle from "../../../shared/general/Card";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const employeeList = [
  {
    idEmployees: 43,
    firstname_TH: "บุญรอด",
    lastname_TH: "ไชยสงค์",
    imageProfile:
      "https://storage.googleapis.com/unihr-bucket/employees/profile/3/239326.jpg?GoogleAccessId=antjob-storage%40anthr-320007.iam.gserviceaccount.com&Expires=1687248001&Signature=QOLmZ2Ac3Kya72yFKuSHy3%2FldsdRIecez0RJB5OIMaZiKz5HrcpBaWrzARqTT9S2Dp7q1nzzasbzx18hc2xlFG9cT%2FLVQlHJRJnI01ERihsrL6kR4pWQM7IljoDcEEDi%2BcZUd2jcOjROiRYycwLZ1EPPsp1hcKAL5Yh0WpXotgnuk01PTdEgALFqwFWCgC1kgBDfEMUlcmzgSK26TUgDUou7MlzmpXZK6qmk7CSX0VJU0%2BunIcnLLuH2bybY3YEv3%2BAhX3HCH1Mk1B3TGmD7oOESqhI4SgFjlmk%2Bju4lRFXv9088DFHgeHX5dIiEkuqhxGGrzvY2upzFR9YiPFb8ag%3D%3D",
    file: null,
  },
  {
    idEmployees: 44,
    firstname_TH: "สุดารัตน์",
    lastname_TH: "สุขสบาย",
    imageProfile:
      "https://storage.googleapis.com/unihr-bucket/employees/profile/3/007314.jpg?GoogleAccessId=antjob-storage%40anthr-320007.iam.gserviceaccount.com&Expires=1687248001&Signature=tTBhYth96CkWBoqDDiw8VTSieZ4x9OeMf5OSyv6bf52quuk5%2Fr39rJQFZEqNePmsIZTIhbC7oh3wSIdXk0lDqdMLOojoyPKtea6HyKFmRSzYK5F5Fx%2FIBYXi9eJPELa8ntjN7MW1ILQ5CPlycuoQeip%2FKWq421eH12Ghhi0sBNfApOtgRNrLSKaQ2znmVQxjdQcyq%2FyihaWJgR0GOtqn1HuSsuYFXD0lZc0Ud%2FQjTkejbXdHqwpWq1%2FB%2FHgthpfp8r%2B7NNou%2BmsYZ%2BSsdYqdprwB8nOK1n%2F80%2BaNsavD7quxPzqv7WVr9t4ld%2BSHV3FH1oS6QRzurxuvkhUqndNPmw%3D%3D",
  },
  {
    idEmployees: 45,
    firstname_TH: "กัณฑ์ลภัคศรี",
    lastname_TH: "หอมเที่ยงแท้",
    imageProfile:
      "https://storage.googleapis.com/unihr-bucket/employees/profile/3/071283.jpg?GoogleAccessId=antjob-storage%40anthr-320007.iam.gserviceaccount.com&Expires=1687248001&Signature=v64ZntK2NklS%2BoKBw49T4dcFLgA%2F9iqJuyIXptbroHmoB88c0gq%2BML9dir5NbL%2BSinKHv%2B%2Bw0%2F99i9ko%2BaiR8YzuUs%2B%2FmzEhM17onFmILQjvqyqSWU3fdWcJUQvQ7FUJdZc%2BaYaQIDIhXErkdfpdnsxvVet91ra%2Ff6Qoe4gx1uqbDMLWjR2DcnaVa%2BsDe5nEJPLW6WLYQLci3PDCkomXatR8J8Jv2roiFp3X7VvfCn2i2mT%2Bm341v6Ja3Td0o9cYJElsTFZWCroxaRG8CS7KaLeef%2FYB4dskjem2l17rI5IxQb%2BYQWQXS2FdQLPgtN8IHuxmYh0BiC3mlD9eQEGOgQ%3D%3D",
  },
];

const docName = [
  { idDocumentType: 1, documentTypeName: "Offer" },
  { idDocumentType: 2, documentTypeName: "Employment Eligibility" },
  { idDocumentType: 3, documentTypeName: "Tax Document" },
  { idDocumentType: 4, documentTypeName: "Background chexk" },
];

const More = (props) => {
  const { data } = props;
  const { result: terminateEmployee } = useSelector(
    (state) => state.terminateEmployeeByID
  );

  return (
    <>
      {terminateEmployee && (
        <StyledRoot>
          <CardStyle>
            <Box style={{ padding: "24px" }}>
              <Box style={{ display: "flex", position: "relative" }}>
                <StyledBox className={"close"}>
                  <div style={{ marginBottom: 8 }}>
                    <Typography
                      variant="h6"
                      style={{ paddingTop: 8, paddingBottom: 4 }}
                    >
                      รายละเอียดของ {data.firstname_TH} {data.lastname_TH}
                    </Typography>
                  </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 600 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">รายการ</TableCell>
                          <TableCell align="center">ผู้ตรวจสอบ</TableCell>
                          <TableCell align="center">หน่วยงาน</TableCell>
                          <TableCell align="center">สถานะ</TableCell>
                          <TableCell align="center">วันที่</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {terminateEmployee.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">
                              {row.TerminateListName}{" "}
                            </TableCell>
                            <TableCell align="center">
                              {`${row.reviewer_firstname_TH}  ${row.reviewer_lastname_TH}`}{" "}
                            </TableCell>
                            <TableCell align="center">
                              {row.departmentName}{" "}
                            </TableCell>
                            <TableCell align="center">
                              {row.isApprove ? (
                                <CheckCircleOutlineIcon
                                  style={{ color: "green" }}
                                />
                              ) : (
                                <CancelOutlinedIcon style={{ color: "red" }} />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(row.updateDate).format("DD/MM/YYYY HH:mm")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StyledBox>
              </Box>
            </Box>
          </CardStyle>
        </StyledRoot>
      )}
    </>
  );
};

export default More;
