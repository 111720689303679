import React, { Component } from "react";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polygon,
  InfoWindow,
} from "google-maps-react";
import { Box, Typography } from "@mui/material";

const mapStyles = {
  position: "relative",
  width: "100%",
  height: "350px",
  borderRadius: "10px",
};

const infoWindowStyles = {
  paddingRight: "10px",
  paddingLeft: "10px",
};

const textWindowStyles = {
  fontSize: "18px",
  marginTop: 0,
  marginBottom: 0,
};

export class MapContainer extends Component {
  state = {
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
    isLoading: true,
    currentLocation: {
      lat: 0,
      lng: 0,
    },
    companyArea: [
      { lat: 12.706941, lng: 101.135534 },
      { lat: 12.703241, lng: 101.133505 },
      { lat: 12.699737, lng: 101.133377 },
      { lat: 12.6995844, lng: 101.1372722 },
      { lat: 12.7069945, lng: 101.1373152 },
      { lat: 12.706941, lng: 101.135534 },
    ],
    companyLocation: [
      {
        lat: 12.70313,
        lng: 101.13537,
      },
    ],
    options: {
      strokeColor: "#ffd600",
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: "#ffd600",
      fillOpacity: 0.5,
    },
  };

  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
  };

  onCheckLocationPoly() {
    console.log("onCheckLocationPoly");
    const { google } = this.props;
    const maps = google.maps;

    for (let index = 0; index < this.props.locations.length; index++) {
      const element = this.props.locations[index].positions;

      const companyPolygon = new maps.Polygon({
        paths: element,
      });

      var check = maps.geometry.poly.containsLocation(
        new maps.LatLng(
          this.state.currentLocation.lat,
          this.state.currentLocation.lng
        ),
        companyPolygon
      );

      if (check) {
        this.props.setMatch(this.props.locations[index].idGpsLocations);
        this.props.addAttendance(this.props.locations[index]);
        return;
      }

      // maps.geometry.poly.containsLocation(
      //   new maps.LatLng(
      //     this.state.currentLocation.lat,
      //     this.state.currentLocation.lng
      //   ),
      //   companyPolygon
      // )
      //   ? console.log("คุณอยู่ในพื้นที่ทำงาน")
      //   : console.log("คุณอยู่นอกพื้นที่ทำงาน");
    }

    alert("คุณไม่อยู่ในพื้นที่ทำงาน");
  }

  onMapLoad = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            acc: position.coords.accuracy,
          };

          this.setState({
            currentLocation: {
              lat: pos.lat,
              lng: pos.lng,
            },
          });

          //Show map
          this.setState({
            isLoading: false,
          });
          this.props.setLoadMap(false);
        },
        () => {
          console.log("Error: The Geolocation service failed.");
        }
      );
    }
  };

  componentDidMount() {
    this.props.setLoadMap(true);
    this.onMapLoad();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.clickCheck !== prevProps.clickCheck) {
      this.onCheckLocationPoly();
    }
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Box>
            <Typography align="center">Map Loading...</Typography>
          </Box>
        ) : (
          <Box>
            {/* {console.log("this.props.locations", this.props.locations)} */}
            <Map
              google={this.props.google}
              style={mapStyles}
              containerStyle={{ position: "relative" }}
              className={"map-gps-check"}
              zoom={16}
              initialCenter={this.state.currentLocation}
            >
              <Marker onClick={this.onMarkerClick} name={"คุณอยู่ที่นี่"} />
              {this.props.locations && this.props.locations.map((item) => (
                <Polygon
                  paths={item.positions}
                  options={this.state.options}
                />
              ))}

              <InfoWindow
                marker={this.state.activeMarker}
                onClose={this.onInfoWindowClose}
                visible={this.state.showingInfoWindow}
              >
                <div style={infoWindowStyles}>
                  <h1 style={textWindowStyles}>
                    {this.state.selectedPlace.name}
                  </h1>
                </div>
              </InfoWindow>
            </Map>
          </Box>
        )}
      </>
    );
  }
}

export default GoogleApiWrapper({
  //apiKey: "AIzaSyCZ41b0hPCVif4tewQUlrc2aivCyZf2DWc",
  apiKey: "AIzaSyBeyDamkWMgJx0jgFcn6Py2jF-Yfju0LAs",
  libraries: ["geometry"],
})(MapContainer);
