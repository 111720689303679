import FamilyService from "../services/family.service";

export const addFamily = (formData) => async (dispatch) => {
  try {
    const res = await FamilyService.addFamily(formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const deleteFamily = (idFamily) => async (dispatch) => {
  try {
    const res = await FamilyService.deleteFamily(idFamily);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}