import React from "react";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

const MenuUserRecruit = (props) => {
  const { anchorEl, onClose } = props;

  return (
    <Menu
      sx={
        {
          mt: "1px", "& .MuiMenu-paper":
            { backgroundColor: "#46cbe2", },
        }
      }
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem component={Link} to={"/recruitment"}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-briefcase-blank"></i>
        </ListItemIcon>
        งานที่เปิด
      </MenuItem>
      <MenuItem component={Link} to={"/company/interview"}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-users"></i>
        </ListItemIcon>
        นัดสัมภาษณ์
      </MenuItem>
    </Menu>
  )
}

export default MenuUserRecruit