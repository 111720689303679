import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import { Typography } from "@mui/material";

import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";

import {
  postGpsLocations,
  updateGpsLocations,
  getAllLocations,
} from "../../../../../../../actions/gpsLocations";

import AddMap from "./addMap";
import EditMap from "./editMap";

const StyledRoot = styled("div")({
  "& .wrap-action": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const FormGpsPositions = (props) => {
  const {
    handleClose,
    selectedGroup,
    mode,
    handleOpenAlert,
    handleChangeAlertType,
  } = props;
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [textField, setTextField] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    idCompany: "",
    isActive: 1,
    idGroupGpsLocations: "",
    positions: [],
  });

  useEffect(() => {
    if (mode === "edit" && selectedGroup) {
      setTextField(selectedGroup.name);

      var temp = { ...formData };
      temp.name = selectedGroup.name;
      temp.positions = selectedGroup.positions;
      temp.idGpsLocations = selectedGroup.idGpsLocations;

      setFormData(temp);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (mode === "add") {
      handleReset();
    }
  }, [mode]);

  const handleSaveGpsLocations = async () => {
    if (userProfile) {
      formData.name = textField;
      formData.idCompany = userProfile.idCompany;
      formData.createBy = userProfile.idEmployees;
      formData.createDate = dayjs(new Date()).format("YYYY-MM-DD");
      formData.idGroupGpsLocations = selectedGroup.idGroupGpsLocations;

      const result = await dispatch(postGpsLocations(formData));
      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          dispatch(getAllLocations(userProfile.idCompany));
        } else {
          handleChangeAlertType("error");
        }
        handleClose();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleUpdateGpsLocations = async () => {
    if (userProfile) {
      formData.name = textField;
      formData.idCompany = userProfile.idCompany;
      formData.editBy = userProfile.idEmp;
      formData.editDate = dayjs(new Date()).format("YYYY-MM-DD");
      formData.idGroupGpsLocations = selectedGroup.idGroupGpsLocations;
      formData.idGpsLocations = selectedGroup.idGpsLocations;
      if (formData.positionsNew) {
        var tempPositions = selectedGroup.positions.map((item) => ({
          ...item,
          isActive: 0,
        }));
        var tempPositionsNew = formData.positionsNew.map((item) => ({
          ...item,
          idGpsLocations: selectedGroup.idGpsLocations,
          idGroupGpsLocations: selectedGroup.idGroupGpsLocations,
        }));

        formData.positions = tempPositions;
        formData.positionsNew = tempPositionsNew;
      }

      console.log("formData: ", formData);
      const result = await dispatch(updateGpsLocations(formData));
      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          if (userProfile) {
            dispatch(getAllLocations(userProfile.idCompany));
          }
        } else {
          handleChangeAlertType("error");
        }
        handleClose();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleChange = (event) => {
    setTextField(event.target.value);
  };

  const handleChangePositions = (positions) => {
    setFormData({ ...formData, positions: positions });
  };

  const handleChangePositionsNew = (positions) => {
    setFormData({ ...formData, positionsNew: positions });
  };

  const handleReset = () => {
    setTextField("");
    setFormData({
      name: "",
      idCompany: "",
      isActive: true,
      idGpsLocations: "",
      idGroupGpsLocations: "",
      positions: null,
    });
  };

  return (
    <StyledRoot>
      <div>
        <div style={{ marginBottom: 16 }}>
          <StyledContentLabel color="text.third" gutterBottom>
            ชื่อตำแหน่ง
          </StyledContentLabel>
          <TextFieldTheme
            onChange={handleChange}
            name={"name"}
            value={textField}
          />
        </div>
        {mode === "add" ? (
          <AddMap
            selectedGroup={selectedGroup}
            handleChangePositions={handleChangePositions}
          />
        ) : (
          <EditMap
            selectedGroup={selectedGroup}
            handleChangePositions={handleChangePositionsNew}
          />
        )}
      </div>
      <div className="wrap-action">
        <ButtonBlue
          onClick={() => {
            if (mode === "add") {
              handleReset();
            }
            handleClose();
          }}
        >
          ยกเลิก
        </ButtonBlue>
        <ButtonBlue
          onClick={
            mode === "add" ? handleSaveGpsLocations : handleUpdateGpsLocations
          }
          variant={"contained"}
          autoFocus
          disabled={formData.positions == null || textField === ""}
        >
          บันทึกข้อมูล
        </ButtonBlue>
      </div>
    </StyledRoot>
  );
};

export default FormGpsPositions;
