import React, { useState, useEffect } from "react";
import {
  Grid,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
  Button,
  LinearProgress,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Pagination from "../../../shared/general/Pagination";
import { useHistory } from "react-router-dom";
import { Data } from "../mockData";

//Icons
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-h6": {
      color: "#222f3e !important",
    },
    "& .MuiLinearProgress-root": {
      height: "10px",
      borderRadius: "10px",
    },
    marginTop: "20px",
  },
  header: {
    color: "#222f3e",
    fontSize: "1.5rem",
    fontWeight: "700",
    marginLeft: 32,
    marginTop: 24,
  },
  toolBar: {
    marginTop: 40,
    padding: 0,
    marginBottom: 16,
  },
  planList_Section: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  planList_Card: {
    border: "1px solid #f5f5f5",
    backgroundColor: "#fafafa",
    borderRadius: "10px",
    padding: 24,
    marginBottom: 16,
  },
  viewBtn: {
    width: "80px",
    height: "35px",
    backgroundColor: "#4f65df",
    color: "#fff",
    borderRadius: "50px",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#4f65df",
    },
  },
  progressObj: {
    backgroundColor: "#ede7f6",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#7e57c2",
    },
  },
  progrssKR: {
    backgroundColor: "#e3f2fd",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#2196f3",
    },
  },
  moonShot_Active: {
    fontSize: "2rem",
    color: "#ff9933",
  },
  moonShot: {
    fontSize: "2rem",
    color: "#bdbdbd",
  },
  viewBtn_Section: {
    
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 64,
  },
  inProgress: {
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
    width: "100px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "100px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "125px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function IdvDashboardPlanList(props) {
  const classes = useStyles();
  const { records } = props;
  const history = useHistory();
  const [planList, setPlanList] = useState([]);
  const [planItems, setPlanItems] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(2);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = planList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    let x = [...planItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setPlanList(x);
  }, [searchKey]);

  useEffect(() => {
    setPlanList(records);
    setPlanItems(records);
  }, [records]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item>
          <Typography className={classes.header}>
            History Individual Plan
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.planList_Section}>
        <Toolbar className={classes.toolBar}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                variant="outlined"
                label="Search Objective"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Toolbar>
        {/* currentPosts */}
        {(currentPosts.length > 0 &&
          currentPosts.map((item) => (
            <div className={classes.planList_Card} key={item.planIdvId}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h6">Objective</Typography>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  xs={6}
                  className={classes.viewBtn_Section}
                >
                  <Button
                    variant="outlined"
                    className={classes.viewBtn}
                    onClick={() => {
                      history.push({
                        pathname: "/individual",
                        state: { planList: item },
                        isInfo: true,
                      });
                    }}
                  >
                    View
                  </Button>
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: 2 }}>
                <Grid
                  container
                  justifyContent="flex-start"
                  item
                  xs={12}
                  sm={6}
                  xl={6}
                >
                  <Typography variant="body1" style={{ marginRight: 20 }}>
                    {item.objectiveName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <LinearProgressWithLabel
                    className={classes.progressObj}
                    value={
                      (item.krScore1 * 100 +
                        item.krScore2 * 100 +
                        item.krScore3 * 100) /
                      3
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={9}>
                  <Typography variant="h6">Key Result</Typography>
                </Grid>
                <Grid container justifyContent="center" item xs={3}>
                  <Typography variant="h6">Moon Shot</Typography>
                </Grid>
              </Grid>
              {item.keyResult1 && (
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1" style={{ marginRight: 20 }}>
                      {item.keyResult1}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LinearProgressWithLabel
                      className={classes.progrssKR}
                      value={item.krScore1 * 100}
                    />
                  </Grid>
                  <Grid container item xs={3} justifyContent="center">
                    <i
                      style={{ marginRight: "5px" }}
                      className={
                        item.moonShotKR1 === 1
                          ? `${classes.moonShot_Active}`
                          : `${classes.moonShot}`
                      }
                      class="fa-solid fa-space-station-moon"
                    ></i>
                    <i
                      className={
                        item.moonShotAfterKR3 === 1
                          ? `${classes.moonShot_Active}`
                          : `${classes.moonShot}`
                      }
                      class="fa-solid fa-space-station-moon"
                    ></i>
                  </Grid>
                </Grid>
              )}
              {item.keyResult2 && (
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1" style={{ marginRight: 20 }}>
                      {item.keyResult2}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LinearProgressWithLabel
                      className={classes.progrssKR}
                      value={item.krScore2 * 100}
                    />
                  </Grid>
                  <Grid container item xs={3} justifyContent="center">
                    <i
                      style={{ marginRight: "5px" }}
                      className={
                        item.moonShotKR2 === 1
                          ? `${classes.moonShot_Active}`
                          : `${classes.moonShot}`
                      }
                      class="fa-solid fa-space-station-moon"
                    ></i>
                    <i
                      className={
                        item.moonShotAfterKR3 === 1
                          ? `${classes.moonShot_Active}`
                          : `${classes.moonShot}`
                      }
                      class="fa-solid fa-space-station-moon"
                    ></i>
                  </Grid>
                </Grid>
              )}
              {item.keyResult3 && (
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1" style={{ marginRight: 20 }}>
                      {item.keyResult3}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LinearProgressWithLabel
                      className={classes.progrssKR}
                      value={item.krScore3 * 100}
                    />
                  </Grid>
                  <Grid container item xs={3} justifyContent="center">
                    <i
                      style={{ marginRight: "5px" }}
                      className={
                        item.moonShotKR3 === 1
                          ? `${classes.moonShot_Active}`
                          : `${classes.moonShot}`
                      }
                      class="fa-solid fa-space-station-moon"
                    ></i>
                    <i
                      className={
                        item.moonShotAfterKR3 === 1
                          ? `${classes.moonShot_Active}`
                          : `${classes.moonShot}`
                      }
                      class="fa-solid fa-space-station-moon"
                    ></i>
                  </Grid>
                </Grid>
              )}
              <Grid container alignItems="center" sx={{ marginTop: 2 }}>
                <Grid item xs={6} sm={2}>
                  <Typography variant="h6">Achieve</Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <div
                    className={
                      item.achieveResult == "Achieved"
                        ? `${classes.achieved}`
                        : item.achieveResult == "Not Achieved"
                        ? `${classes.notAchieved}`
                        : `${classes.inProgress}`
                    }
                  >
                    <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                      {item.achieveResult !== null
                        ? item.achieveResult
                        : "In Progress"}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          ))) || (
          <div className={classes.noData}>
            <Typography variant="h6" align="center">
              No Data
            </Typography>
          </div>
        )}
      </div>
      {/* currentPosts */}
      {Data.length > 0 ? (
        <Pagination
          paginate={paginate}
          postsPerPage={postsPerPage}
          // planList
          totalPosts={Data.length}
        />
      ) : null}
    </div>
  );
}
