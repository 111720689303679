import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Newemployee from "./Newemployee";
import Resign from "./resign";
import Contract from "./contract";
import Dailyplanner from "./dailyplanner";
import IndividualPanel from "./IndividualPanel";
import User from "./userinfo";
import Probation from "./probation";
import Tax from "./tax";
import Provident from "./provident";
import Approve from "./approvelist";
import ReportAdminPage from "../report";

import { Typography, Container, Avatar, Card } from "@mui/material";

import MyDocument from "../../assets/-_Branding & Communication.png";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});
const StyledCard = styled(Card)({
  position: "relative",
  boxShadow: "none",
  borderRadius: 16,
  border: "1px solid #919eab3d",
  margin: 8,
  border: "none",
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  "&.gray": {
    filter: "grayscale(100%)",
  },
  "&:hover": {
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
    transform: "translateY(-5px)",
  },
  "& .inner": {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    "& div": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
    "& .MuiTypography-subtitle": {
      fontSize: 22,
      lineHeight: 1.2,
      textAlign: "center",
    },
    "& .MuiTypography-caption": {
      lineHeight: 1.2,
      fontSize: 14,
    },
    "& .MuiAvatar-root": {
      width: 100,
      height: 100,
      marginBottom: 16,
    },
  },
  "& .premium": {
    position: "absolute",
    right: 20,
  },
});
const StyledWrapCardAdmin = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 1fr 1fr auto",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridAutoRows: 0,
  minWidth: 350,
  columnGap: 24,
  rowGap: 24,
});

const taxList = [
  {
    id: 1,
    label: "สรุปการลาและมาสาย",
    path: "employees-report/leave",
    src: MyDocument,
  },
  { id: 2, label: "สรุปทดลองงาน", src: MyDocument },
  { id: 3, label: "สรุปหมดสัญญา", src: MyDocument },
  { id: 4, label: "สรุปพนักงานเข้าใหม่", src: MyDocument },
  { id: 5, label: "รายงานคนลาออก", src: MyDocument },
  { id: 6, label: "ข้อมูลพนักงาน", src: MyDocument },
  { id: 8, label: "รายงานเวลาทำงาน", src: MyDocument },
  {
    id: 9,
    label: "รายงานขาดงาน/มาสาย",
    path: "employees-report/late-absent",
    src: MyDocument,
  },
  { id: 10, label: "สลิปเงินเดือน และรายงานราชการ", src: MyDocument },
  { id: 11, label: "รายงานการลา", src: MyDocument },
  { id: 12, label: "รายงานการลงเวลา", src: MyDocument },
  { id: 13, label: "รายงานการร้องขอ", src: MyDocument },
  { id: 14, label: "รายงานค่าล่วงเวลา/ค่ากะ", src: MyDocument },
  { id: 15, label: "รายงานปรับเงินเดือน", src: MyDocument },
  {
    id: 16,
    label: "สรุปเบี้ยขยัน",
    path: "employees-report/diligence-allowance",
    src: MyDocument,
  },
  { id: 17, label: "รายงานกองทุนสำรองเลี้ยงชีพ", src: MyDocument },
  { id: 18, label: "รายงานลดหย่อนภาษี", src: MyDocument },
  {
    id: 19,
    label: "รายงาน cost element",
    path: "employees-report/cost-element",
    src: MyDocument,
  },
  {
    id: 16,
    label: "รายงาน Payroll",
    path: "employees-report/payroll",
    src: MyDocument,
  },
];

const All_ReportAdminPage = () => {
  const history = useHistory();
  const [view, setView] = useState();
  const [open, setOpen] = useState();

  const handleChangeView = (newView) => {
    if (newView) {
      setView(newView);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setView(null);
  };

  return (
    <div>
      <StyledRoot className={`page`}>
        <Container maxWidth="lg">
          {view === "สลิปเงินเดือน และรายงานราชการ" ? (
            <ReportAdminPage handleClose={handleClose}/>
          ) : (
            <Fragment>
              <div style={{ marginBottom: 8 }}>
                <Typography variant="h4" style={{ paddingTop: 8 }}>
                  รายงาน
                </Typography>
              </div>
              <StyledWrapCardAdmin>
                {taxList.map((v) => (
                  <StyledCard
                    key={v.id}
                    onClick={() => {
                      history.push(v.path);
                      handleChangeView(v.label);
                    }}
                  >
                    <div className="inner">
                      <Avatar variant="rounded" src={v.src} />
                      <div>
                        <Typography variant="subtitle" gutterBottom>
                          {v.label}
                        </Typography>
                      </div>
                    </div>
                  </StyledCard>
                ))}
              </StyledWrapCardAdmin>
            </Fragment>
          )}
        </Container>
      </StyledRoot>
      {view === "สรุปทดลองงาน" && (
        <Probation open={open} handleClose={handleClose} />
      )}
      {view === "สรุปหมดสัญญา" && (
        <Contract open={open} handleClose={handleClose} />
      )}
      {view === "สรุปพนักงานเข้าใหม่" && (
        <Newemployee open={open} handleClose={handleClose} />
      )}
      {view === "รายงานคนลาออก" && (
        <Resign open={open} handleClose={handleClose} />
      )}
      {view === "ข้อมูลพนักงาน" && (
        <User open={open} handleClose={handleClose} />
      )}
      {view === "รายงานเวลาทำงาน" && (
        <IndividualPanel open={open} handleClose={handleClose} />
      )}
      {view === "รายงานการลงเวลา" && (
        <Dailyplanner open={open} handleClose={handleClose} />
      )}
      {view === "รายงานการร้องขอ" && (
        <Approve open={open} handleClose={handleClose} />
      )}
      {view === "รายงานกองทุนสำรองเลี้ยงชีพ" && (
        <Provident open={open} handleClose={handleClose} />
      )}
      {view === "รายงานลดหย่อนภาษี" && (
        <Tax open={open} handleClose={handleClose} />
      )}
    </div>
  );
};

export default All_ReportAdminPage;
