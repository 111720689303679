import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { 
    Alert,
    Box, 
    Dialog,
    DialogContent,
    Divider,
    Grid, 
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Snackbar,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    styled, 
    Typography,
    IconButton
} from '@mui/material';

import {
    Add,
    MoreVert
} from '@mui/icons-material';

import ButtonBlue from '../../../shared/general/ButtonBlue';
import DrawerProvidentFund from './drawerProvidentFund';
import DrawerTablePF from './drawerTablePF';

import {
    getAdminSettingPF,
    deleteAdminSettingPF,
    deleteAdminSchedulePF
} from '../../../../../actions/settingPF';

const Root = styled("div")({
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
});

const Header = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

const Body = styled(Box)({
    display:"flex",
    flexDirection: "column",
    alignItems: "center",
    "& .topicGrid":{
        fontSize:"1.25rem",
    },
    "& .textCenter":{
        textAlign:"center"
    },
    "& .topicChanging":{
        fontSize:"1.5rem",
    },
    "& .hiddenBorder":{
        borderBottom:"0"
    },
    "& .topicRowColumn":{
        fontSize:"1.20rem",
        "& .index":{
            fontWeight:"bold",
            marginLeft:"10px"
        }
    },
    "& .textDate":{
        fontSize:"1.3rem"
    },
    "& .textBold":{
        fontWeight:"bold"
    }
});

const StyledMenu = styled(Menu)({
    "& .edit":{
        color:"#1976D2",
        "& span":{
            color:"#1976D2"
        }
    },
    "& .delete":{
        color:"#ff0000",
        "& span":{
            color:"#ff0000"
        }
    }
});

const MenuOption = (props) => {
    const { handleClickEdit, handleClickDelete, isDisabledDelete } = props;
    const [openAnchorEl, setOpenAnchorEl] = useState({ isOpen: false, anchorEl: null });

    return (
        <Fragment>
            <IconButton
                aria-label="option"
                onClick={(e) => setOpenAnchorEl({ isOpen: true, anchorEl: e.currentTarget })}
            >
                <MoreVert />
            </IconButton>
            {openAnchorEl.isOpen &&
                <StyledMenu
                    aria-labelledby="Menu-Option"
                    anchorEl={openAnchorEl.anchorEl}
                    open={openAnchorEl.isOpen}
                    onClose={() => setOpenAnchorEl({ isOpen: false, anchorEl: null })}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem 
                        onClick={() => {
                            setOpenAnchorEl({ isOpen: false, anchorEl: null });
                            handleClickEdit();
                        }}
                    >
                        <ListItemIcon>
                            <i className="fa-regular fa-pen icon edit" />
                        </ListItemIcon>
                        <ListItemText
                            className='edit'
                            style={{ width: 72 }}
                        >แก้ไข</ListItemText>
                    </MenuItem>
                    {!isDisabledDelete &&
                        <MenuItem
                            onClick={() => {
                                setOpenAnchorEl({ isOpen: false, anchorEl: null });
                                handleClickDelete();
                            }}
                        >
                            <ListItemIcon>
                                <i className="fa-regular fa-trash-can icon delete" />
                            </ListItemIcon>
                            <ListItemText
                                className='delete'
                                style={{ width: 72 }}
                            >ลบ</ListItemText>
                        </MenuItem>
                    }
                </StyledMenu>
            }
        </Fragment>
    );
}

const SettingPFComponent = (props) => {
    const { setStatusAPI, listSettingPF } = props;
    const dispatch = useDispatch();
    const [openPFDialog, setOpenPFDialog] = useState({ status: false, data: null });
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ isOpen: false, id: null });



    const handleClickEdit = (value) => {
        setOpenPFDialog({ status: true, data: [value] });
    };

    const handleClosePFDialog = () => {
        setOpenPFDialog({ status: false, data: null });
    }

    const handleUpdateListSettingPF = async (result, label) => {
        setStatusAPI({ isOpen: true, result: result, label:label });
        await dispatch(getAdminSettingPF());
    };



    const handleClickDelete = (id) => {
        setOpenDeleteDialog({ isOpen: true, id: id })
    };
    
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog({ isOpen: false, id: null })
    }

    const handleSubmitDelete = async () => {
        const res = await dispatch(deleteAdminSettingPF(openDeleteDialog.id));
        let isSuccess = res.status === 200;
        if (isSuccess) {
            await dispatch(getAdminSettingPF());
        }
        setStatusAPI({ isOpen: true, result: isSuccess ? "success" : "error", label:`ลบกองทุนสำรองเลี้ยงชีพ${isSuccess ? "สำเร็จ" : "ไม่สำเร็จ"}` });
        handleCloseDeleteDialog();
    }
    
    return (
        <div>
            <Header>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">กองทุนสำรองเลี้ยงชีพ (PF)</Typography>
                    </Grid>
                    <Grid item>
                        <ButtonBlue
                            variant={"contained"}
                            startIcon={<Add />}
                            onClick={() => {
                                setOpenPFDialog({ status: true, data: null });
                            }}
                        >
                            เพิ่มกองทุนสำรองเลี้ยงชีพ
                        </ButtonBlue>
                    </Grid>
                </Grid>
            </Header>
            <Body>
                <Grid container spacing={2}>
                    {(listSettingPF.length > 0) ?
                        listSettingPF.map((value, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12} sm={2}>
                                    <Typography className="textCenter topicGrid" gutterBottom>
                                        ปีขั้นต่ำในการทำงาน
                                    </Typography>
                                    <Typography className="textCenter" variant="h4">
                                        {(value.minServ_Y || value.minServ_Y === 0) ? `${value.minServ_Y} ปี` : "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography className="textCenter topicGrid" gutterBottom>
                                        สะสมส่วนพนักงานสูงสุด
                                    </Typography>
                                    <Typography className="textCenter" variant="h4">
                                        {(value.maxEmployeePF || value.maxEmployeePF === 0) ? `${value.maxEmployeePF} %` : "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography className="textCenter topicGrid" gutterBottom>
                                        สะสมส่วนพนักงานขั้นต่ำ
                                    </Typography>
                                    <Typography className="textCenter" variant="h4">
                                        {(value.minEmployeePF || value.minEmployeePF === 0) ? `${value.minEmployeePF} %` : "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography className="textCenter topicGrid" gutterBottom>
                                        สะสมส่วนบริษัทสูงสุด
                                    </Typography>
                                    <Typography className="textCenter" variant="h4">
                                        {(value.companyPF || value.companyPF === 0) ? `${value.companyPF} %` : "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    <div style={{ height: "100%", display:"flex", alignItems:'flex-end', marginBottom: 16 }}>
                                        <MenuOption
                                            handleClickEdit={() => handleClickEdit(value)} 
                                            handleClickDelete={() => handleClickDelete(value.idSettingPF)}
                                        />
                                    </div>
                                </Grid>
                            </React.Fragment>
                        ))
                        :
                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                <Grid item style={{ margin: "30px 0" }}>
                                    <Typography>ไม่พบข้อมูล</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Body>
            
            {openPFDialog.status &&
                <DrawerProvidentFund 
                    open={openPFDialog.status}
                    onClose={handleClosePFDialog}
                    data={openPFDialog.data}
                    handleUpdateListSettingPF={handleUpdateListSettingPF}
                />
            }
            {openDeleteDialog.isOpen &&
                <Dialog
                    open={openDeleteDialog.isOpen}
                    onClose={handleCloseDeleteDialog}
                >
                    <DialogContent>
                        <div style={{ width: 300 }}>
                            <Typography
                                style={{ fontSize: 28, fontWeight: 500 }}
                            >ยืนยันการลบข้อมูล</Typography>
                            <Divider style={{ marginTop: 16 }} />
                            <Grid
                                container
                                justifyContent={"space-between"}
                                style={{
                                    marginTop: 16
                                }}
                            >
                                <Grid item>
                                    <ButtonBlue
                                        onClick={handleCloseDeleteDialog}
                                    >ยกเลิก</ButtonBlue>
                                </Grid>
                                <Grid item>
                                    <ButtonBlue
                                        variant="contained"
                                        onClick={handleSubmitDelete}
                                    >ยืนยัน</ButtonBlue>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                </Dialog>
            }
        </div>
    );
};

const SchedulePFComponent = (props) => {
    const dispatch = useDispatch();
    const { setStatusAPI, listSchedulePF } = props;
    const [openTableDialog, setOpenTableDialog] = useState({ isOpen: false, data: null });
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ isOpen: false, id: null });



    const handleClickEdit = (value) => {
        setOpenTableDialog({ isOpen: true, data: [value] });
    };

    const handleCloseTableDialog = () => {
        setOpenTableDialog({ isOpen: false, data: null });
    };

    const handleUpdateListSchedulePF = async (result, label) => {
        setStatusAPI({ isOpen: true, result: result, label:label });
        await dispatch(getAdminSettingPF());
    };



    const handleClickDelete = (id) => {
        setOpenDeleteDialog({ isOpen: true, id: id })
    };
    
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog({ isOpen: false, id: null })
    }

    const handleSubmitDelete = async () => {
        const res = await dispatch(deleteAdminSchedulePF(openDeleteDialog.id));
        let isSuccess = res.status === 200;
        if (isSuccess) {
            await dispatch(getAdminSettingPF());
        }
        setStatusAPI({ isOpen: true, result: isSuccess ? "success" : "error", label:`ลบกองทุนสำรองเลี้ยงชีพ${isSuccess ? "สำเร็จ" : "ไม่สำเร็จ"}` });
        handleCloseDeleteDialog();
    }

    return (
        <div>
            <Header>
                <Grid container justifyContent="space-between">
                    <Grid item style={{ display:"flex", alignItems:"center" }}>
                        <i className="fa-solid fa-calendar-clock" style={{ fontSize: "1.5rem", marginRight: "10px", color: "#007afe" }} />
                        <Typography className="topicChanging" style={{ width: "100%" }} variant="h6">
                            วันที่สามารถเปลี่ยนแปลง
                        </Typography>
                    </Grid>
                    {listSchedulePF.length < 4 &&
                        <Grid item>
                            <ButtonBlue
                                variant={"contained"}
                                startIcon={<Add />}
                                onClick={() => {
                                    setOpenTableDialog({ isOpen: true, data: null });
                                }}
                            >
                                เพิ่มวันเปลี่ยนแปลง
                            </ButtonBlue>
                        </Grid>
                    }
                </Grid>
            </Header>
            <Body>
                <TableContainer style={{ width: "90%" }}>
                    <Table>
                        <TableHead>
                            {(listSchedulePF.length > 0) ?
                                <TableRow>
                                    <TableCell
                                        className="hiddenBorder"
                                        style={{ minWidth: "32px" }}
                                    >
                                    </TableCell>
                                    <TableCell
                                        className="hiddenBorder topicRowColumn"
                                        align="center"
                                        style={{ minWidth: "152px" }}
                                    >
                                        วันที่เริ่มต้น
                                    </TableCell>
                                    <TableCell
                                        className="hiddenBorder topicRowColumn"
                                        align="center"
                                        style={{ minWidth: "152px" }}
                                    >
                                        วันที่สิ้นสุด
                                    </TableCell>
                                    <TableCell
                                        className="hiddenBorder topicRowColumn"
                                        align="center"
                                        style={{ minWidth: "152px" }}
                                    >
                                        วันที่กำหนดใช้
                                    </TableCell>
                                    <TableCell
                                        className="hiddenBorder topicRowColumn"
                                        align="center"
                                        style={{ minWidth: "40px" }}
                                    >
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow>
                                    <TableCell style={{ padding: 0, borderWidth: 0 }}></TableCell>
                                </TableRow>
                            }
                        </TableHead>
                        <TableBody>
                            {(listSchedulePF.length > 0) ?
                                listSchedulePF.map((schedule, index) => (
                                    <TableRow key={schedule.idSchedulePF}>
                                        <TableCell className="hiddenBorder topicRowColumn">
                                            {`รอบที่ ${index+1}`}
                                        </TableCell>
                                        <TableCell
                                            className="hiddenBorder textDate textBold"
                                            align="center"
                                        >
                                            {schedule.startDate ? dayjs(schedule.startDate).format("DD MMM") : "-"}
                                        </TableCell>
                                        <TableCell
                                            className="hiddenBorder textDate textBold"
                                            align="center"
                                        >
                                            {schedule.endDate ? dayjs(schedule.endDate).format("DD MMM") : "-"}
                                        </TableCell>
                                        <TableCell
                                            className="hiddenBorder textDate textBold"
                                            align="center"
                                        >
                                            {schedule.createdDate ? dayjs(schedule.useDate).format("DD/MM/YYYY") : "-"}
                                        </TableCell>
                                        <TableCell
                                            className="hiddenBorder"
                                        >
                                            {!(dayjs().isBefore(schedule.startDate) || dayjs().isAfter(schedule.endDate)) ?
                                                <Typography style={{ color:"#1976D2", fontSize: 20, fontWeight: 500 }}>
                                                    <i className="fa-regular fa-clock" style={{ color:"#1976D2", marginRight:16 }} />อยู่ระหว่างกำหนดการ
                                                </Typography>
                                                :
                                                <MenuOption
                                                    handleClickEdit={() => handleClickEdit(schedule)} 
                                                    handleClickDelete={() => handleClickDelete(schedule.idSchedulePF)}
                                                    isDisabledDelete={listSchedulePF.length <= 4}
                                                />
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow>
                                    <TableCell
                                        style={{
                                            height: 150,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderWidth: "0"
                                        }}
                                    >
                                        <Typography variant="h6" align="center">ไม่มีการกำหนดวันที่เปลี่ยนแปลง</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Body>
            {openTableDialog.isOpen &&
                <DrawerTablePF
                    open={openTableDialog.isOpen}
                    onClose={handleCloseTableDialog}
                    data={openTableDialog.data}
                    handleUpdateListSchedulePF={handleUpdateListSchedulePF}
                />
            }
            {openDeleteDialog.isOpen &&
                <Dialog
                    open={openDeleteDialog.isOpen}
                    onClose={handleCloseDeleteDialog}
                >
                    <DialogContent>
                        <div style={{ width: 300 }}>
                            <Typography
                                style={{ fontSize: 28, fontWeight: 500 }}
                            >ยืนยันการลบข้อมูล</Typography>
                            <Divider style={{ marginTop: 16 }} />
                            <Grid
                                container
                                justifyContent={"space-between"}
                                style={{
                                    marginTop: 16
                                }}
                            >
                                <Grid item>
                                    <ButtonBlue
                                        onClick={handleCloseDeleteDialog}
                                    >ยกเลิก</ButtonBlue>
                                </Grid>
                                <Grid item>
                                    <ButtonBlue
                                        variant="contained"
                                        onClick={handleSubmitDelete}
                                    >ยืนยัน</ButtonBlue>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                </Dialog>
            }
        </div>
    );
}

const PF = () => {
    const dispatch = useDispatch();
    const { result: AdminSettingPF } = useSelector(state => state.adminSettingPF);
    const [listSettingPF, setListSettingPF] = useState([]);
    const [listSchedulePF, setListSchedulePF] = useState([]);
    const [statusAPI, setStatusAPI] = useState({ isOpen: false, result: null, label:"" });

    useEffect(() => {
        dispatch(getAdminSettingPF());
    },[]);

    useEffect(() => {
        if (AdminSettingPF) {
            if (AdminSettingPF.settingPF && AdminSettingPF.settingPF.length > 0) {
                setListSettingPF(AdminSettingPF.settingPF);
            }
            if (AdminSettingPF.schedulePF && AdminSettingPF.schedulePF.length > 0) {
                setListSchedulePF(AdminSettingPF.schedulePF);
            }
        }
    },[AdminSettingPF]);

    const handleCloseNotify = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setStatusAPI({ isOpen: false, result: null, label: "" });
    };

    return (
        <Root>
            <SettingPFComponent 
                setStatusAPI={setStatusAPI}
                listSettingPF={listSettingPF}
            />
            <Divider style={{ width:"100%", borderBottom:"2px solid rgba(0,0,0,0.09)", margin:"30px 0" }}/>
            <SchedulePFComponent 
                setStatusAPI={setStatusAPI}
                listSchedulePF={listSchedulePF}
            />
            {statusAPI.isOpen &&
                <Snackbar
                    open={statusAPI.isOpen}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={handleCloseNotify}
                >
                    <Alert severity={statusAPI.result} onClose={handleCloseNotify}>
                        {statusAPI.label}
                    </Alert>
                </Snackbar>
            }
        </Root>
    );
};

export default PF;