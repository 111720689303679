import React, { Fragment, useEffect, useState } from "react";
import { Box, Checkbox, Chip, Divider, Grid, IconButton, MenuItem, Typography, styled } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import { addLeaveType, getAllLeaveV2 } from "../../../../../actions/leave";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px"
  }
})

const LeaveForm = (props) => {

  const { drawerConfig, onClose, setOpenAlert, setAlertType } = props;

  const { result: employmentTypeList } = useSelector((state) => state.employmentType);
  const { result: groupLevelList } = useSelector((state) => state.level);

  const [deleteList, setDeleteList] = useState([]);

  const dispatch = useDispatch();

  // const [selectEmploymentTypeMap, setSelectEmploymentTypeMap] = useState({
  //   "": {
  //     "1": false,
  //     "2": false,
  //     "3": false,
  //   },
  //   "1": {
  //     "1": false,
  //     "2": false,
  //     "3": false,
  //   },
  //   "2": {
  //     "1": false,
  //     "2": false,
  //     "3": false,
  //   },
  //   "3": {
  //     "1": false,
  //     "2": false,
  //     "3": false,
  //   },
  // })

  const getEmploymentTypeList = () => {

    const list = [
      {
        idEmploymentType: "",
        employmentTypeName: "ทั้งหมด",
      }
    ]

    if(employmentTypeList){

      employmentTypeList.map(item => {
        list.push({
          idEmploymentType: item.idEmploymentType,
          employmentTypeName: item.employmentTypeName,
        })
      })

      return list;
    } else {
      return list;
    }
  }

  const getGroupLevelList = () => {

    const list = [
      {
        idGroupLevel: "",
        groupLevelName: "ทั้งหมด",
      }
    ]

    if(groupLevelList){

      groupLevelList.map(item => {
        list.push({
          idGroupLevel: item.idGroupLevel,
          groupLevelName: item.groupLevelName,
        })
      })

      return list;
    } else {
      return list;
    }
  }

  // const employmentType = [
  //   {
  //     idEmploymentType: "",
  //     employmentTypeName: "ทั้งหมด",
  //   },
  //   {
  //     idEmploymentType: 1,
  //     employmentTypeName: "พนักงานทั่วไป"
  //   },
  //   {
  //     idEmploymentType: 2,
  //     employmentTypeName: "พนักงานสัญญาจ้าง"
  //   },
  //   {
  //     idEmploymentType: 3,
  //     employmentTypeName: "ที่ปรึกษา"
  //   },
  // ]

  // const jobLevelGroup = [
  //   {
  //     idLevelGroup: "",
  //     levelGroupName: "ทั้งหมด",
  //   },
  //   {
  //     idLevelGroup: 1,
  //     levelGroupName: "A",
  //   },
  //   {
  //     idLevelGroup: 2,
  //     levelGroupName: "B",
  //   },
  //   {
  //     idLevelGroup: 3,
  //     levelGroupName: "C",
  //   }
  // ]

  const validateYupSchema = yup.object({
    leaveGroupName: yup.string().required("กรุณากรอกข้อมูล"),
    // isPaid: yup.string().required("กรุณากรอกข้อมูล"),
    // // isAfter: yup.string().required("กรุณากรอกข้อมูล"),
    // canRequestAfter: yup.string().required("กรุณากรอกข้อมูล"),
    // gender: yup.string().required("กรุณากรอกข้อมูล"),
    // minLeave: yup.string().required("กรุณากรอกข้อมูล"),
    // // resetEndOfMonth: yup.string().required("กรุณากรอกข้อมูล"),
    // daysInAdvance: yup.string(),
    // isProRate: yup.string().required("กรุณากรอกข้อมูล"),
    // proRateRound: yup.string().required("กรุณากรอกข้อมูล"),
    // isLeaveStep: yup.string().required("กรุณากรอกข้อมูล"),
    // leaveValue: yup.string().when(
    //   "isLeaveStep", {
    //     is: "0",
    //     then: yup.string()
    //   }
    // ),
    // carryValue: yup.string().when(
    //   "isLeaveStep", {
    //     is: "0",
    //     then: yup.string()
    //   }
    // ),
    // leaveStepList: yup.array().of(
    //   yup.object().shape({
    //     min: yup.string().required("กรุณากรอกข้อมูล"),
    //     max: yup.string().required("กรุณากรอกข้อมูล").test("is-less-than-max", "ข้อมูลไม่ถูกต้อง", (value, context) => {
    //       return Number(value) >= Number(context.parent.min)
    //     }),
    //   })
    // )
  })

  const useHookForm = useForm({
    defaultValues: {
      // name: "",
      // isPaid: "",
      // // isAfter: "",
      // canRequestAfter: "",
      // gender: "",
      // minLeave: "",
      // // resetEndOfMonth: "",
      // daysInAdvance: "",
      // isProRate: "0",
      // proRateRound: "0",
      // isLeaveStep: "",
      // leaveValue: "",
      // carryValue: "",
      // leaveStepList: [],
      // employmentTypeList: [],
      // jobLevelGroupList: [],
      leaveGroupName: "",
      leaveTypeList: [
        {
          employmentTypeList: [],
          jobLevelGroupList: [],
          isPaid: "",
        }
      ],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const useFieldArrayLeaveType = useFieldArray({
    control: useHookForm.control,
    name: "leaveTypeList"
  })

  const onSubmit = async (data) => {
    // console.log(data);
    // console.log(deleteList)
    const formData = {
      idLeaveGroup: drawerConfig.data.idLeaveGroup || null,
      leaveGroupName: data.leaveGroupName,
      leaveTypeList: data.leaveTypeList,
      deleteLeaveTypeList: deleteList,
    }

    formData.leaveTypeList.map(item => {
      item.leaveValue = item.leaveValue === ""? null: item.leaveValue
      item.carryValue = item.carryValue === ""? null: item.carryValue
      item.daysInAdvance = item.daysInAdvance === ""? null: item.daysInAdvance
    })

    // const formData = {
    //   name: data.name,
    //   isPaid: data.isPaid,
    //   // isAfter: data.isAfter,
    //   canRequestAfter: data.canRequestAfter,
    //   gender: data.gender,
    //   minLeave: data.minLeave,
    //   // resetEndOfMonth: data.,
    //   daysInAdvance: data.daysInAdvance || null,
    //   isProRate: data.isProRate,
    //   proRateRound: data.proRateRound,
    //   isLeaveStep: data.isLeaveStep,
    //   leaveValue: data.leaveValue || null,
    //   carryValue: data.carryValue || null,
    //   leaveStepList: data.leaveStepList,
    // };

    // if(drawerConfig.isEdit){
    //   formData.idLeaveType = drawerConfig.data.idLeaveType
    // }

    const result = await dispatch(addLeaveType(formData))

    if(result){
      
      if(result.status === 200){
        setOpenAlert(true)
        setAlertType("success")
        onClose();
        dispatch(getAllLeaveV2());
      } else {
        setOpenAlert(true)
        setAlertType("error")
        // onClose();
      }

    } else {
      setOpenAlert(true)
      setAlertType("error")
    }
  }

  const isProRateWatch = useHookForm.watch("isProRate")
  // const canRequestAfterWatch = useHookForm.watch("canRequestAfter")

  // const getIsDisableMenuEmploymentType = (idEmploymentType, index) => {

  //   const leaveTypeList = useHookForm.watch('leaveTypeList');

  //   if(leaveTypeList[index].employmentTypeList.includes(idEmploymentType)){
  //     return false;
  //   }

  //   const employmentTypeList = [];

  //   leaveTypeList.map(item => {
  //     item.employmentTypeList.map(id => {
  //       employmentTypeList.push(id)
  //     })
  //   });

  //   console.log(employmentTypeList)

  //   return employmentTypeList.includes(idEmploymentType)

  //   // let isDisabled = (item.idEmploymentType === "" && index > 0);
  //   // let isAlreadySelect = false;
  //   // let alreadySelect = useHookForm.watch('leaveTypeList');
  //   // alreadySelect.map(leaveType => {
  //   //   if(leaveType.employmentTypeList.includes(item.idEmploymentType)){
  //   //     isAlreadySelect = true
  //   //   }
  //   // })

  //   // if(index > 0 && alreadySelect[0].employmentTypeList.includes("")){
  //   //   isAlreadySelect = true
  //   // }
  // }

  const getIdEmploymentType = (list) => {
    const groupEmploymentType = new Map();
    list.map(item => {
      groupEmploymentType.set(item.idEmploymentType, item.idEmploymentType || "")
    })
    return Array.from(groupEmploymentType, ([_, value]) => value)
  }

  const getIdGroupLevel = (list) => {
    const groupLevelMap = new Map();
    list.map(item => {
      groupLevelMap.set(item.idGroupLevel, item.idGroupLevel || "")
    })
    return Array.from(groupLevelMap, ([_, value]) => value)
  }

  useEffect(() => {
    if(drawerConfig.isOpen){
      
      setDeleteList([]);

      if(drawerConfig.isEdit){
        useHookForm.reset({
          leaveGroupName: drawerConfig.data.leaveGroupName || "",
          leaveTypeList: drawerConfig.data.leaveTypeList.length > 0
            ? drawerConfig.data.leaveTypeList.map(item => {
              return {
                idLeaveType: item.idLeaveType,
                employmentTypeList: getIdEmploymentType(item.leaveTypeEmploymentTypeList),
                jobLevelGroupList: getIdGroupLevel(item.leaveTypeEmploymentTypeList),
                isPaid: String(item.isPaid) || "",
                canRequestAfter: String(item.canRequestAfter) || "",
                gender: item.gender === null? "All": String(item.gender) || "",
                minLeave: String(item.minLeave) || "",
                daysInAdvance: item.daysInAdvance === null? "": String(item.daysInAdvance),
                isProRate: String(item.isProRate) || "0",
                proRateRound: String(item.proRateRound) || "1",
                isLeaveStep: item.isLeaveStep === null? "": String(item.isLeaveStep),
                leaveValue: item.leaveValue === null? "": String(item.leaveValue),
                carryValue: item.carryValue === null? "": String(item.carryValue),
                leaveStepList: item.leaveStepList.map(step => ({
                  stepType: step.conditionType || "",
                  min: step.lessThanYear === null? "": String(step.lessThanYear),
                  max: step.moreThanYears === null? "": String(step.moreThanYears),
                  leaveValue: step.leaveValue === null? "": String(step.leaveValue),
                  carryValue: step.carryValue === null? "": String(step.carryValue),
                }))
              }
            })
            : [
              {
                idLeaveType: null,
                employmentTypeList: [],
                jobLevelGroupList: [],
                isPaid: "",
                canRequestAfter: "",
                gender: "",
                minLeave: "",
                daysInAdvance: "",
                isProRate: "0",
                proRateRound: "1",
                isLeaveStep: "",
                leaveValue: "",
                carryValue: "",
                leaveStepList: [],
              }
            ],
          // leaveTypeList: [
          //   {
          //     employmentTypeList: [],
          //     jobLevelGroupList: [],
          //     isPaid: "",
          //   }
          // ],
        })
      } else {
        useHookForm.reset({
          leaveGroupName: "",
          leaveTypeList: [
            {
              idLeaveType: null,
              employmentTypeList: [],
              jobLevelGroupList: [],
              isPaid: "",
              canRequestAfter: "",
              gender: "",
              minLeave: "",
              daysInAdvance: "",
              isProRate: "0",
              proRateRound: "1",
              isLeaveStep: "",
              leaveValue: "",
              carryValue: "",
              leaveStepList: [],
            }
          ],
        })
      }
    }
    // if(drawerConfig.isOpen){
    //   if(drawerConfig.isEdit){
    //     console.log(drawerConfig)
    //     useHookForm.reset({
    //       name: drawerConfig.data.name || "",
    //       isPaid: drawerConfig.data.isPaid === null? "": drawerConfig.data.isPaid,
    //       // isAfter: drawerConfig.data.isAfter || "",
    //       canRequestAfter: drawerConfig.data.canRequestAfter === null? "": drawerConfig.data.canRequestAfter,
    //       gender: drawerConfig.data.gender === null? "All": drawerConfig.data.gender,
    //       minLeave: drawerConfig.data.minLeave || "",
    //       // resetEndOfMonth: drawerConfig.data.name,
    //       daysInAdvance: drawerConfig.data.daysInAdvance === null? "": drawerConfig.data.daysInAdvance,
    //       isProRate: drawerConfig.data.isProRate || "0",
    //       proRateRound: drawerConfig.data.proRateRound || "0",
    //       isLeaveStep: drawerConfig.data.isLeaveStep === null? "": String(drawerConfig.data.isLeaveStep),
    //       leaveValue: drawerConfig.data.leaveValue || "",
    //       carryValue: drawerConfig.data.carryValue || "",
    //       leaveStepList: drawerConfig.data.leaveStepList
    //         ?  drawerConfig.data.leaveStepList.map(item => ({
    //           stepType: item.conditionType || "",
    //           min: item.lessThanYear === null? "": item.lessThanYear,
    //           max: item.moreThanYears === null? "": item.moreThanYears,
    //           leaveValue: item.leaveValue === null? "": item.leaveValue,
    //           carryValue: item.carryValue === null? "": item.carryValue,
    //         }))
    //         : []
    //       ,
    //     });
    //   } else {
    //     useHookForm.reset({
    //       name: "",
    //       isPaid: "",
    //       // isAfter: "",
    //       canRequestAfter: "",
    //       gender: "",
    //       minLeave: "",
    //       // resetEndOfMonth: "",
    //       daysInAdvance: "",
    //       isProRate: "0",
    //       proRateRound: "0",
    //       isLeaveStep: "",
    //       leaveValue: "",
    //       carryValue: "",
    //       leaveStepList: [],
    //     });
    //   }
    // }
  }, [drawerConfig.isOpen])

  return (
    <StyledRoot>
      <Box marginBottom="16px" display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="delete"
            onClick={onClose}
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <Typography fontSize="24px" fontWeight="500">{drawerConfig.isEdit? "แก้ไขวันลา": "เพิ่มวันลา"}</Typography>
        </Box>
        {/* <ButtonBlue
          variant={"contained"}
          startIcon={<AddIcon />}
          // onClick={() => {
          //   setOpenDialog({
          //     ...openDialog,
          //     isOpen: true,
          //   });
          // }}
          onClick={handleOpenDrawer}
        >
          เพิ่มวันลา
        </ButtonBlue> */}
      </Box>

      <form onSubmit={useHookForm.handleSubmit(onSubmit, (error) => {console.log(error)})}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="field-label">ชื่อรายการลา</Typography>
            <Controller
              name="leaveGroupName"
              control={useHookForm.control}
              render={({field}) => (
                <TextFieldTheme
                  {...field}
                  helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                  error={useHookForm.formState.errors[field.name]? true: false}
                />
              )}
            />
          </Grid>

          <Grid sx={{position: "relative"}} item xs={12} container justifyContent="space-between" alignItems="center">
            <Typography
              sx={{
                paddingLeft: "8px",
                borderLeft: "8px solid #46cbe2",
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px"
              }}
              fontSize="20px"
              fontWeight="500"
            >
              กลุ่มประเภทพนักงานที่ 1
            </Typography>
            <ButtonBlue
              variant="text"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                useFieldArrayLeaveType.append({
                  idLeaveType: null,
                  employmentTypeList: [],
                  jobLevelGroupList: [],
                  isPaid: "",
                  canRequestAfter: "",
                  gender: "",
                  minLeave: "",
                  daysInAdvance: "",
                  isProRate: "0",
                  proRateRound: "0",
                })
              }}
            >
              เพิ่มประเภทพนักงาน
            </ButtonBlue>
          </Grid>

          <Grid item container spacing={2}>
            
          {useFieldArrayLeaveType.fields.map((item, index) => (
            <Grid key={item.id} item xs={12} container spacing={2}>

              {index > 0 && <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px"
                  }}
                  fontSize="20px"
                  fontWeight="500"
                >
                  กลุ่มประเภทพนักงานที่ {index+1}
                </Typography>
                <IconButton
                  // disabled={useFieldArrayLeaveStep.fields.length <= 1 || (index < useFieldArrayLeaveStep.fields.length-1)}
                  onClick={() => {
                    if(item.idLeaveType){
                      setDeleteList(prev => [...prev, item.idLeaveType])
                    }
                    useFieldArrayLeaveType.remove(index)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>}

              <Grid item xs={12} md={6}>
                <Typography className="field-label">ประเภทพนักงาน</Typography>
                <Controller
                  name={`leaveTypeList.${index}.employmentTypeList`}
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      select
                      SelectProps={{
                        multiple: true,
                        renderValue: (select) => (
                          <Box>
                            {select.map((value) => (
                              <Chip key={value} label={getEmploymentTypeList().find(x => x.idEmploymentType === value).employmentTypeName}/>
                            ))}
                          </Box>
                        )
                      }}
                      onChange={(e) => {
                        if(e.target.value.length > 0 && e.target.value[e.target.value.length-1] !== ""){
                          const temp = e.target.value.filter(x => x !== "")
                          field.onChange(temp)
                        } else if(e.target.value.includes("")){
                          useHookForm.setValue(`leaveTypeList.${index}.employmentTypeList`, [""])
                          // if(index === 0){
                          //   useHookForm.watch('leaveTypeList').map((leaveType, lIndex) => {
                          //     if(lIndex > 0){
                          //       useHookForm.setValue(`leaveTypeList.${lIndex}.employmentTypeList`, [])
                          //     }
                          //   })
                          // }
                        } else {
                          field.onChange(e.target.value)
                        }
                      }}
                    >
                      {/* <MenuItem value="">ทั้งหมด</MenuItem> */}
                      {getEmploymentTypeList().map((item, typeIndex) => {

                        // const isAlready = getIsDisableMenuEmploymentType(item.idEmploymentType, index)
                        // console.log(isAlready)
                        // let isDisabled = (item.idEmploymentType === "" && index > 0);
                        // let isAlreadySelect = false;
                        // let alreadySelect = useHookForm.watch('leaveTypeList');
                        // alreadySelect.map(leaveType => {
                        //   if(leaveType.employmentTypeList.includes(item.idEmploymentType)){
                        //     isAlreadySelect = true
                        //   }
                        // })

                        // if(index > 0 && alreadySelect[0].employmentTypeList.includes("")){
                        //   isAlreadySelect = true
                        // }

                        return (
                          <MenuItem value={item.idEmploymentType}>{item.employmentTypeName}</MenuItem>
                        )
                      })}
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-label">ระดับงาน</Typography>
                <Controller
                  name={`leaveTypeList.${index}.jobLevelGroupList`}
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      select
                      disabled={useHookForm.watch(`leaveTypeList.${index}.employmentTypeList`).length === 0}
                      SelectProps={{
                        multiple: true,
                        renderValue: (select) => (
                          <Box>
                            {select.map((value) => (
                              <Chip key={value} label={getGroupLevelList().find(x => x.idGroupLevel === value).groupLevelName}/>
                            ))}
                          </Box>
                        )
                      }}
                      onChange={(e) => {
                        // const jobLevelGroupList = field.
                        // console.log(e.target.value)
                        if(e.target.value.length > 0 && e.target.value[e.target.value.length-1] !== ""){
                          const temp = e.target.value.filter(x => x !== "")
                          field.onChange(temp)
                        } else if(e.target.value.includes("")){
                          useHookForm.setValue(`leaveTypeList.${index}.jobLevelGroupList`, [""])
                        } else {
                          field.onChange(e.target.value)
                        }
                      }}
                    >
                      {/* <MenuItem value="">ทั้งหมด</MenuItem> */}
                      {getGroupLevelList().map(item => (
                        <MenuItem value={item.idGroupLevel}>{item.groupLevelName}</MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-label">การจ่ายเงิน</Typography>
                  <Controller
                  name={`leaveTypeList.${index}.isPaid`}
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      select
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={useHookForm.formState.errors[field.name]? true: false}
                    >
                      <MenuItem value="0">ไม่จ่ายเงิน</MenuItem>
                      <MenuItem value="1">จ่ายเงิน</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-label">สามารถขอลาได้หลังจาก</Typography>
                  <Controller
                  name={`leaveTypeList.${index}.canRequestAfter`}
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      select
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={useHookForm.formState.errors[field.name]? true: false}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        if(e.target.value >= 365){
                          useHookForm.setValue(`leaveTypeList.${index}.isProRate`, "0")
                          useHookForm.setValue(`leaveTypeList.${index}.proRateRound`, "0")
                        }
                      }}
                    >
                      <MenuItem value="0">เริ่มงานวันแรก</MenuItem>
                      <MenuItem value="60">อายุงานครบ 60 วัน</MenuItem>
                      <MenuItem value="90">อายุงานครบ 90 วัน</MenuItem>
                      <MenuItem value="120">อายุงานครบ 120 วัน</MenuItem>
                      <MenuItem value="180">อายุงานครบ 180 วัน</MenuItem>
                      <MenuItem value="240">อายุงานครบ 240 วัน</MenuItem>
                      <MenuItem value="365">อายุงานครบ 1 ปี</MenuItem>
                      <MenuItem value="730">อายุงานครบ 2 ปี</MenuItem>
                      <MenuItem value="1095">อายุงานครบ 3 ปี</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-label">เพศ</Typography>
                  <Controller
                  name={`leaveTypeList.${index}.gender`}
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      select
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={useHookForm.formState.errors[field.name]? true: false}
                    >
                      <MenuItem value="All">ทั้งหมด</MenuItem>
                      <MenuItem value="Male">ชาย</MenuItem>
                      <MenuItem value="Female">หญิง</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-label">อนุญาติให้ลาขั้นต่ำ</Typography>
                <Controller
                  name={`leaveTypeList.${index}.minLeave`}
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      select
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={useHookForm.formState.errors[field.name]? true: false}
                    >
                      <MenuItem value="100">เต็มวัน</MenuItem>
                      <MenuItem value="50">ครึ่งวัน</MenuItem>
                      <MenuItem value="10">1 ชั่วโมง</MenuItem>
                      <MenuItem value="5">ครึ่งชั่วโมง</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="field-label">ต้องลาล่วงหน้า (วัน)</Typography>
                <Controller
                  name={`leaveTypeList.${index}.daysInAdvance`}
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={useHookForm.formState.errors[field.name]? true: false}
                    />
                  )}
                />
              </Grid>

              {useHookForm.watch(`leaveTypeList.${index}.canRequestAfter`) < 365 && <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography fontSize="14px" fontWeight="500">คำนวน Pro Rate (กรณีเข้างานยังไม่ครบ 1 ปี)</Typography>
                    {isProRateWatch === "1" && <Typography paddingTop="4px" paddingBottom="4px" fontSize="12px" color="text.secondary">(ปัดเศษเป็นจำนวนเต็มเมื่อทศนิยมมากกว่า)</Typography>}
                  </Box>
                  <Controller
                    name={`leaveTypeList.${index}.isProRate`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        checked={String(field.value) === "1"? true: false}
                        onChange={(e) => {
                          field.onChange(e.target.checked? "1": "0");
                          useHookForm.setValue(`leaveTypeList.${index}.proRateRound`, "0")
                        }}
                      />
                    )}
                  />
                </Box>

                {String(useHookForm.watch(`leaveTypeList.${index}.isProRate`)) === "1" && (<Controller
                  name={`leaveTypeList.${index}.proRateRound`}
                  // name="proRateRound"
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      select
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={useHookForm.formState.errors[field.name]? true: false}
                    >
                      <MenuItem value="1">ไม่มีการปัดเศษ</MenuItem>
                      <MenuItem value="0.5">ปัดขึ้นเมื่อมากกว่า 0.5</MenuItem>
                      <MenuItem value="0">ปัดขึ้นทุกกรณี</MenuItem>
                      {/* <MenuItem value="0.1">0.1</MenuItem>
                      <MenuItem value="0.2">0.2</MenuItem>
                      <MenuItem value="0.3">0.3</MenuItem>
                      <MenuItem value="0.4">0.4</MenuItem>
                      <MenuItem value="0.5">0.5</MenuItem>
                      <MenuItem value="0.6">0.6</MenuItem>
                      <MenuItem value="0.7">0.7</MenuItem>
                      <MenuItem value="0.8">0.8</MenuItem>
                      <MenuItem value="0.9">0.9</MenuItem> */}
                    </TextFieldTheme>
                  )}
                />)}
              </Grid>}
              
              <Grid item xs={12}>
                <Typography className="field-label">ประเภทจำนวนวันลา</Typography>
                <Controller
                  name={`leaveTypeList.${index}.isLeaveStep`}
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      select
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={useHookForm.formState.errors[field.name]? true: false}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        useHookForm.setValue(`leaveTypeList.${index}.leaveStepList`, [])
                      }}
                    >
                      <MenuItem value="0">วันลาแบบคงที่</MenuItem>
                      <MenuItem value="1">วันลาแบบขั้นบันได</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <FormLeaveValue useHookForm={useHookForm} index={index}/>

              {/* <Grid item xs={12} container justifyContent="space-between">
                <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
                <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>เพิ่มรายการ</ButtonBlue>
              </Grid> */}




            </Grid>
          ))}

          </Grid>

          <Grid item xs={12} container justifyContent="space-between">
            <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>ยกเลิก</ButtonBlue>
            <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>บันทึกข้อมูล</ButtonBlue>
          </Grid>

          
        </Grid>
      </form>


    </StyledRoot>
  )
}

const FormLeaveValue = ({ useHookForm, index }) => {

  const isLeaveStepWatch = useWatch({
    control: useHookForm.control,
    // name: "isLeaveStep"
    name: `leaveTypeList.${index}.isLeaveStep`
  })

  const useFieldArrayLeaveStep = useFieldArray({
    control: useHookForm.control,
    name: `leaveTypeList.${index}.leaveStepList`
  })

  return (
    <Fragment>
      {
        isLeaveStepWatch === "0" && (
          <Fragment>
            <Grid item xs={12} md={6}>
              <Typography className="field-label">จำนวนวันลา (วัน)</Typography>
              <Controller
                name={`leaveTypeList.${index}.leaveValue`}
                // name="leaveValue"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                    InputProps={{
                      placeholder: "กรณีไม่กรอกจำนวน หมายถึงไม่จำกัดจำนวนวันลา"
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="field-label">ยกวันลาไปรอบถัดไปได้ไม่เกิน</Typography>
              <Controller
                name={`leaveTypeList.${index}.carryValue`}
                // name="carryValue"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>
          </Fragment>
        )}
        
        {isLeaveStepWatch === "1" && (
          <Fragment>
            {useFieldArrayLeaveStep.fields.map((item, fieldIndex) => (
              <Grid key={item.id} item xs={12} container spacing={2}>
                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                  <Typography fontWeight="500">เงื่อนไขที่ {fieldIndex+1}</Typography>
                  <IconButton
                    disabled={useFieldArrayLeaveStep.fields.length <= 1 || (fieldIndex < useFieldArrayLeaveStep.fields.length-1)}
                    onClick={() => {
                      useFieldArrayLeaveStep.remove(fieldIndex)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography className="field-label">ประเภทเงื่อนไข</Typography>
                  <Controller
                    name={`leaveTypeList.${index}.leaveStepList.${fieldIndex}.stepType`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        select
                        helperText={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][fieldIndex] && useHookForm.formState.errors["leaveStepList"][fieldIndex]["stepType"]? useHookForm.formState.errors["leaveStepList"][fieldIndex]["stepType"].message: null}
                        error={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][fieldIndex] && useHookForm.formState.errors["leaveStepList"][fieldIndex]["stepType"]? true: false}
                        disabled
                      >
                        <MenuItem value="SERV">อายุงาน</MenuItem>
                        <MenuItem value="JOBLEVEL">ระดับงาน</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="field-label">ตั้งแต่อายุงาน (ปี)</Typography>
                  <Controller
                    name={`leaveTypeList.${index}.leaveStepList.${fieldIndex}.min`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        select
                        helperText={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][fieldIndex] && useHookForm.formState.errors["leaveStepList"][fieldIndex]["min"]? useHookForm.formState.errors["leaveStepList"][fieldIndex]["min"].message: null}
                        error={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][fieldIndex] && useHookForm.formState.errors["leaveStepList"][fieldIndex]["min"]? true: false}
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="6">6</MenuItem>
                        <MenuItem value="7">7</MenuItem>
                        <MenuItem value="8">8</MenuItem>
                        <MenuItem value="9">9</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="11">11</MenuItem>
                        <MenuItem value="12">12</MenuItem>
                        <MenuItem value="13">13</MenuItem>
                        <MenuItem value="14">14</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="field-label">จนถึงอายุงาน (ปี)</Typography>
                  <Controller
                    name={`leaveTypeList.${index}.leaveStepList.${fieldIndex}.max`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        select
                        helperText={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][fieldIndex] && useHookForm.formState.errors["leaveStepList"][fieldIndex]["max"]? useHookForm.formState.errors["leaveStepList"][fieldIndex]["max"].message: null}
                        error={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][fieldIndex] && useHookForm.formState.errors["leaveStepList"][fieldIndex]["max"]? true: false}
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="6">6</MenuItem>
                        <MenuItem value="7">7</MenuItem>
                        <MenuItem value="8">8</MenuItem>
                        <MenuItem value="9">9</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="11">11</MenuItem>
                        <MenuItem value="12">12</MenuItem>
                        <MenuItem value="13">13</MenuItem>
                        <MenuItem value="14">14</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                        <MenuItem value="99">ขึ้นไป</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="field-label">จำนวนวันลา</Typography>
                  <Controller
                    name={`leaveTypeList.${index}.leaveStepList.${fieldIndex}.leaveValue`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        helperText={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][fieldIndex] && useHookForm.formState.errors["leaveStepList"][fieldIndex]["leaveValue"]? useHookForm.formState.errors["leaveStepList"][fieldIndex]["leaveValue"].message: null}
                        error={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][fieldIndex] && useHookForm.formState.errors["leaveStepList"][fieldIndex]["leaveValue"]? true: false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="field-label">ยกวันลาไปรอบถัดไปได้ไม่เกิน</Typography>
                  <Controller
                    name={`leaveTypeList.${index}.leaveStepList.${fieldIndex}.carryValue`}
                    control={useHookForm.control}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        helperText={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][fieldIndex] && useHookForm.formState.errors["leaveStepList"][fieldIndex]["carryValue"]? useHookForm.formState.errors["leaveStepList"][fieldIndex]["carryValue"].message: null}
                        error={useHookForm.formState.errors["leaveStepList"] && useHookForm.formState.errors["leaveStepList"][fieldIndex] && useHookForm.formState.errors["leaveStepList"][fieldIndex]["carryValue"]? true: false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <ButtonBlue
                variant="text"
                fullWidth
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  useFieldArrayLeaveStep.append({
                    stepType: "SERV",
                    min: "",
                    max: "",
                    leaveValue: "",
                    carryValue: "",
                  })
                }}
              >
                เพิ่มเงื่อนไข
              </ButtonBlue>
            </Grid>
          </Fragment>
        )
      }
    </Fragment>
  )
}

export default LeaveForm;