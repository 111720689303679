import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container, Grid, MenuItem, TextField, Typography, styled } from "@mui/material";
import CardStyle from "../../../../shared/general/Card";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeExcelDiligenceAllowanceExport } from "../../../../../../actions/employee";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";

const StyledRoot = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
},
  "& .MuiSelect-filled":{
    padding:"16px 32px 16px 12px"
  }
})

const DiligenceAllowancePage = () => {

  const dispatch = useDispatch();

  const validateYupSchema = yup.object({
    year: yup.string().required("กรุณากรอกปี"),
    month: yup.string().required("กรุณากรอกเดือน"),  
  })

  const useHookForm = useForm({
    defaultValues: {
      year: dayjs().format("YYYY"),
      month: dayjs().add(-1, 'months').format("M"),
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const [yearOptions, setYearOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);

  useEffect(() => {

    let start = dayjs("2023-05-01");
    let tempYearOpt = [];
    let tempMonthOpt = [];
    let yearCount = dayjs().diff(start, "years", true) + 1
    yearCount = Math.floor(yearCount)

    let monthCount = dayjs().diff(start, "month", )

    for(let i=0; i<yearCount; i++){
      tempYearOpt.push({
        value: start.add(i, "years").format("YYYY"),
        label: start.add(i, "years").format("BBBB")
      })
    }

    setYearOptions([...tempYearOpt])
    refreshMonthOption(dayjs().format("YYYY"))

  }, [])

  const refreshMonthOption = (year) => {

    let tempMonthOpt = [];

    for(let i=0; i<12; i++){
      const dayInThisMonth = dayjs(`${year}-${i+1}-01`).daysInMonth() 
      if(dayjs().isAfter(dayjs(`${year}-${i+1}-${dayInThisMonth}`)) && dayjs(`${year}-${i+1}-${dayInThisMonth}`).isSameOrAfter(`2023-05-${dayInThisMonth}`)){
        tempMonthOpt.push({
          value: `${i+1}`,
          label: dayjs().set('month', i).format("MMMM")
        })
      }
    }
    setMonthOptions([...tempMonthOpt])
  }

  const onSubmit = async (data) => {
    const query = {
      year: data.year,
      month: data.month,
    }
    const employeeList = await dispatch(getEmployeeExcelDiligenceAllowanceExport(query)).then(res => res.data)
    const elementList = [];
    for (let index = 0; index < employeeList.length; index++) {
      const element = {
        employeeID: employeeList[index].employeeID,
        firstname: employeeList[index].firstname,
        lastname: employeeList[index].lastname,
        positionName: employeeList[index].positionName,
        arrive: employeeList[index].arrive,
        absent: employeeList[index].absent,
        late: employeeList[index].late,
        earlyBack: employeeList[index].earlyBack,
        leave: employeeList[index].leave,
      }
      elementList.push(element);
    }

    const header = [[
      "รหัสพนักงาน",
      "ชื่อ",
      "นามสกุล",
      "ตำแหน่งงาน",
      "จำนวนวันทำงาน",
      "ขาดงาน",
      "มาสาย",
      "กลับก่อน",
      "ลา (ยกเว้น ลาพักร้อน, ลาสลับวันหยุด)",
    ]]

    console.log(header)

    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, header);
    XLSX.utils.sheet_add_json(ws, elementList, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(workbook, ws, "Diligence Allowance");
    XLSX.writeFile(workbook, `รายชื่อพนักงานที่ได้รับเบี้ยขยัน ประจำเดือน ${dayjs(`${data.year}-${data.month}-01`).format("MMMM YYYY")} ข้อมูล ณ ${dayjs().format("YYYY-MM-DD HH.mm.ss")}.xlsx`, { type: "file" });
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Box margin="16px 0">
          <Typography variant="h4">สรุปเบี้ยขยัน</Typography>
        </Box>
        <CardStyle style={{padding: 24}}>
          <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="label" color="text.third">ปี</Typography>
                <Controller
                  name="year"
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      variant="filled"
                      select
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        useHookForm.setValue('month', '');
                        refreshMonthOption(event.target.value);
                      }}
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={useHookForm.formState.errors[field.name]? true: false}
                    >
                      {yearOptions.map((y) => (
                        <MenuItem key={y.value} value={y.value}>{y.label}</MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="label" color="text.third">เดือน</Typography>
                <Controller
                  name="month"
                  control={useHookForm.control}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      variant="filled"
                      select
                      helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      error={useHookForm.formState.errors[field.name]? true: false}
                    >
                      {monthOptions.map((m) => (
                        <MenuItem key={m.value} value={m.value}>{m.label}</MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>
                  {
                    useHookForm.formState.isSubmitting
                      ? <CircularProgress size="21px" color="inherit"/>
                      : "ดาวน์โหลดข้อมูล"
                  }
                </ButtonBlue>
              </Grid>
            </Grid>
          </form>
        </CardStyle>
      </Container>
    </StyledRoot>
  )
}

export default DiligenceAllowancePage;