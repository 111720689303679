import DoneAll from "@mui/icons-material/DoneAll";
import { Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveRequest } from "../../../../../actions/employee";
import { getAllLeaveWithDrawBy, updateApproveLeaveEmployeeBy } from "../../../../../actions/leave";
import ChipWithDrawCustom from "../../../shared/chipWithDrawCustom";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import StatusRequest from "../../../shared/general/stausRequest";
import TableCustom from "../../../shared/tableCustom";

const RequestLeave = (props) => {

  const { searchDate, selectedEmployee, setSnackBarConfig, setNumberOfList } = props;

  const { result: leaveEmployeesList } = useSelector((state) => state.leaveEmployees);
  const { result: leaveEmployeeWithdrawsList } = useSelector((state) => state.leaveEmployeesWithDraw);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [pendingRows, setPendingRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);


  const dispatch = useDispatch();

  const columns = [
    {
      name: "ชื่อ-สกุล",
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/>
          <Box flexGrow={1}>
            <Typography>{row.firstname_TH} {row.lastname_TH}</Typography>
            <Typography color="text.third" fontSize="14px">{row.positionName}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: "ประเภท",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idLeaveEmployeesWithdraw && (
            <ChipWithDrawCustom />
          )}
          <Typography fontSize={14}>{row.name}</Typography>
        </Fragment>
      )
    },
    {
      name: "เริ่มต้น",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign: "center"}}>
          <Typography>{dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}</Typography>
          {(row.isFullDay === 1)? (
            <Typography>เต็มวัน</Typography>
          ) : (
            <Typography>{dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
          )}
        </Box>
      )
    },
    {
      name: "สิ้นสุด",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign: "center"}}>
          <Typography>{dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}</Typography>
          {(row.isFullDay === 1)? (
            <Typography>เต็มวัน</Typography>
          ) : (
            <Typography>{dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
          )}
        </Box>
      )
    },
    {
      name: "เหตุผล",
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">{row.description}</Typography>
        </Box>
      )
    },
    {
      name: "หัวหน้างาน",
      minWidth: "280px",
      width: "280px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <StatusRequest
            boxSize={40}
            fontSize={22}
            borderRadius={8}
            status={getStatusLeaveManagerApprove(row)}
          />
          <Avatar sx={{marginLeft: "8px", marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/>
          <Box flexGrow={1}>
            <Typography>{row.manager_firstname_TH} {row.manager_lastname_TH}</Typography>
            <Typography color="text.third" fontSize="14px">{row.manager_positionName}</Typography>
          </Box>
        </Box>
      )
    },
  ]

  const getStatusLeaveManagerApprove = (requestLeave) => {

    if(requestLeave.fillInApprove){
      return 3
    }
   
    if(requestLeave.idLeaveEmployeesWithDraw && requestLeave.managerApprove === userProfile.idEmployees){
      if(requestLeave.isApprove === 1){ 
        return 1
      } else if(requestLeave.isApprove === 0){
        return 0
      } else {
        return 2
      }
    }

    if(requestLeave.isApprove === 1){
      return 1
    } else if(requestLeave.isApprove === 0){
      return 0
    } else {
      return 2
    }

  }

  const handleApprovalRequest = async (approve) => {

    const idLeave = [];
    const idLeaveEmployeesWithdraw = [];
    const idLeaveWithdraw = [];

    selectedRows.map(x => {
      if(x.idLeave && !x.idLeaveEmployeesWithdraw){
        idLeave.push(x.idLeave)
      } else if (x.idLeaveEmployeesWithdraw){
        idLeaveEmployeesWithdraw.push(x.idLeaveEmployeesWithdraw);
        idLeaveWithdraw.push(x.idLeave);
      }
    })

    const formData = [
      {
        idLeave: idLeave,
        isApprove: approve,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        idLeaveEmployeesWithdraw: idLeaveEmployeesWithdraw,
        idLeave: idLeaveWithdraw,
        isApprove: approve,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
    ];

    console.log("formData: ", formData);

    const result = await dispatch(updateApproveLeaveEmployeeBy("admin", formData));
    if (result) {
      setSelectedRows([]);
      if (result.status === 200) {
        dispatch(getLeaveRequest("admin", searchDate));
        dispatch(getAllLeaveWithDrawBy("admin", searchDate));
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการสำเร็จ",
          type: "success",
        })
      } else {
        setSnackBarConfig({
          open: true,
          message: "ดำเนินการไม่สำเร็จ",
          type: "error",
        })
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: "ดำเนินการไม่สำเร็จ",
        type: "error",
      })
    }
  };

  useEffect(() => {

    const tempPending = [];

    leaveEmployeesList.map(r => {
      if(
        (r.isApprove === null) && 
        (selectedEmployee ? selectedEmployee.idEmployees === r.idEmployees : true)
      ){
        tempPending.push(r)
      }
    })

    leaveEmployeeWithdrawsList.map(r => {
      if(
        (r.isApprove === null) && 
        (selectedEmployee ? selectedEmployee.idEmployees === r.idEmployees : true)
      ){
        tempPending.push(r)
      }
    })

    let pendingList = tempPending.sort(function (a, b) {return new Date(b.createDate) - new Date(a.createDate)});
    setPendingRows(pendingList);
    setNumberOfList(pendingList.length);
  }, [selectedEmployee])

  return (
    <Box>
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1)}
          >
            {`อนุมัติ ${
              selectedRows.length > 0
                ? `${selectedRows.length} รายการ`
                : ""
            }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => handleApprovalRequest(0)}
          >
            {`ไม่อนุมัติ ${
              selectedRows.length > 0
                ? `${selectedRows.length} รายการ`
                : ""
            }`}
          </ButtonBlue>
        </Box>
      )}

      <TableCustom columns={columns} rows={(leaveEmployeesList && leaveEmployeeWithdrawsList)? pendingRows: []} canSelect selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>
    </Box>
  )
}

export default RequestLeave;