import {
  COMPANYPROFILE_FETCHING,
  COMPANYPROFILE_FAILED,
  COMPANYPROFILE_SUCCESS,
  COMPANY_ORGANIZATION_FETCHING,
  COMPANY_ORGANIZATION_SUCCESS,
  COMPANY_ORGANIZATION_FAILED,
  DEPARTMENT_FETCHING,
  DEPARTMENT_FAILED,
  DEPARTMENT_SUCCESS,
  WORKTYPES_FETCHING,
  WORKTYPES_FAILED,
  WORKTYPES_SUCCESS,
  MANAGER_FETCHING,
  MANAGER_SUCCESS,
  MANAGER_FAILED,
  EMPLOYMENT_FETCHING,
  EMPLOYMENT_FAILED,
  EMPLOYMENT_SUCCESS,
  COMPANY_FETCHING,
  COMPANY_FAILED,
  COMPANY_SUCCESS,
  STANDARD_MENU_FETCHING,
  STANDARD_MENU_FAILED,
  STANDARD_MENU_SUCCESS,
  AFFILIATE_FETCHING,
  AFFILIATE_FAILED,
  AFFILIATE_SUCCESS,
  ADMIN_FETCHING,
  ADMIN_FAILED,
  ADMIN_SUCCESS,
} from "./types";

import CompanyService from "../services/company.service";

export const getCompanyProfile = () => async (dispatch) => {
  try {
    const res = await CompanyService.getCompanyProfile();
    if (res) {
      dispatch({
        type: COMPANYPROFILE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: COMPANYPROFILE_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const putCompanyProfileMainContact = (id, formData) => async () => {
  try {
    console.log(formData);
    const res = await CompanyService.putCompanyProfileMainContact(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const postCompanyAddress = (formData) => async () => {
  try {
    console.log(formData);
    const res = await CompanyService.postCompanyAddress(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putCompanyAddress = (id, formData) => async () => {
  try {
    console.log(formData);
    const res = await CompanyService.putCompanyAddress(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getCompanyOrganization = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_ORGANIZATION_FETCHING,
    });
    const res = await CompanyService.getCompanyOrganization(id);
    if (res) {
      dispatch({
        type: COMPANY_ORGANIZATION_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: COMPANY_ORGANIZATION_FAILED,
    });
    return err;
  }
};

export const getDepartment = () => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_FETCHING,
    });
    const res = await CompanyService.getDepartment();
    if (res) {
      dispatch({
        type: DEPARTMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DEPARTMENT_FAILED,
    });
    console.log(err);
  }
};

export const getDepartmentByIdCompany = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_FETCHING,
    });
    const res = await CompanyService.getDepartmentByIdCompany(id);
    if (res) {
      dispatch({
        type: DEPARTMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DEPARTMENT_FAILED,
    });
    console.log(err);
  }
};

export const putCompanyBankDetail =
  (id, idBankDetail, formData) => async () => {
    try {
      const res = await CompanyService.putCompanyBankDetail(
        id,
        idBankDetail,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return "Error";
    }
  };

export const putCompanyProfile = (formData) => async () => {
  try {
    const res = await CompanyService.putCompanyProfile(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getWorkInfoByidCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: WORKTYPES_FETCHING,
    });
    const res = await CompanyService.getWorkInfoByidCompany(idCompany);
    if (res) {
      dispatch({
        type: WORKTYPES_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: WORKTYPES_FAILED,
    });
    return error;
  }
};

export const addCompanyLogo = (formData) => async () => {
  try {
    const res = await CompanyService.addCompanyLogo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteCompanyLogo = () => async () => {
  try {
    const res = await CompanyService.deleteCompanyLogo();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addCompanyAuthorizedSignatureOne = (formData) => async () => {
  try {
    const res = await CompanyService.addCompanyAuthorizedSignatureOne(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteCompanyAuthorizedSignatureOne = () => async () => {
  try {
    const res = await CompanyService.deleteCompanyAuthorizedSignatureOne();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addCompanyAuthorizedSignatureTwo = (formData) => async () => {
  try {
    const res = await CompanyService.addCompanyAuthorizedSignatureTwo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteCompanyAuthorizedSignatureTwo = () => async () => {
  try {
    const res = await CompanyService.deleteCompanyAuthorizedSignatureTwo();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addCompanyWitnessSignature = (formData) => async () => {
  try {
    const res = await CompanyService.addCompanyWitnessSignature(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteCompanyWitnessSignature = () => async () => {
  try {
    const res = await CompanyService.deleteCompanyWitnessSignature();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addCompanyCertificateSignature = (formData) => async() => {
  try {
    const res = await CompanyService.addCompanyCertificateSignature(formData);
    if(res){
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteCompanyCertificateSignature = () => async() => {
  try {
    const res = await CompanyService.deleteCompanyCertificateSignature();
    if(res){
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getEmployment = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYMENT_FETCHING,
    });
    const res = await CompanyService.getcompanyEmploymentType(id);
    if (res) {
      dispatch({
        type: EMPLOYMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYMENT_FAILED,
    });
    console.log(err);
  }
};

export const getallcompany = () => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_FETCHING,
    });
    const res = await CompanyService.getallCompany();
    if (res) {
      dispatch({
        type: COMPANY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COMPANY_FAILED,
    });
    console.log(err);
  }
};

export const getStandardMenuCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: STANDARD_MENU_FETCHING,
    });
    const res = await CompanyService.getStandardMenuCompany();
    if (res) {
      dispatch({
        type: STANDARD_MENU_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: STANDARD_MENU_FAILED,
    });
    console.log(err);
  }
};

export const getAffiliate = () => async (dispatch) => {
  try {
    dispatch({
      type: AFFILIATE_FETCHING,
    });
    const res = await CompanyService.getAffiliate();
    if (res) {
      dispatch({
        type: AFFILIATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: AFFILIATE_FAILED,
    });
    console.log(err);
  }
};

export const getAllAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_FETCHING,
    });
    const res = await CompanyService.getAllAdmin();
    if (res) {
      dispatch({
        type: ADMIN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ADMIN_FAILED,
    });
    console.log(err);
  }
};

export const updateAdmin = (formData) => async (dispatch) => {
  try {
    const res = await CompanyService.updateAdmin(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};
