import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";

import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  Export,
  Button,
} from "devextreme-react/data-grid";

import utils from "../../../../../utils";

import StyledCard from "../../../shared/general/Card";
import { Typography } from "@mui/material";

import loading from "../../../assets/social-media.gif";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyleWrapFooter = styled("div")({
  padding: 16,
  "& .wrap-late": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-text-header": {
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  "& .text-header": {
    display: "flex",
    alignItems: "center",
  },
  "& .text-value": {
    textAlign: "center",
    "& .MuiTypography-h5": {
      fontWeight: 600,
      color: "#e46a76",
      lineHeight: 1,
    },
  },
  "& .sum-baht": {
    fontWeight: 500,
  },
});

const StyledLoading = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "inherit",
});

const TableEmployee = (props) => {
  const { result, topic, handleChangeSelectEmployee } = props;
  const grid = useRef();
  const { result: summaryTimeList } = useSelector((state) => state.summaryTime);
  const { isFetching: summaryTimeListFetching } = useSelector(
    (state) => state.summaryTime
  );
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  let gridColumnsOT = [
    {
      dataField: "otOneHours",
      caption: "OT 1 (ชั่วโมง)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneAmount",
      caption: "OT 1 (บาท)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveHours",
      caption: "OT 1.5 (ชั่วโมง)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveAmount",
      caption: "OT 1.5 (บาท)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoHours",
      caption: "OT 2 (ชั่วโมง)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoAmount",
      caption: "OT 2 (บาท)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeHours",
      caption: "OT 3 (ชั่วโมง)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeAmount",
      caption: "OT 3 (บาท)",
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "shiftPayMorning",
      caption: "ค่ากะเช้า",
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftPayAfternoon",
      caption: "ค่ากะบ่าย",
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftPayNight",
      caption: "ค่ากะดึก",
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftPayTotal",
      caption: "รวมค่ากะ (บาท)",
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
  ];

  useEffect(() => {
    if (summaryTimeList) {
      let temp = [...summaryTimeList.dataResponse];
      temp.forEach((element) => {
        element["fullname"] = [element.firstname_TH, element.lastname_TH].join(" ");

        element["otOneHours"] = element.roundDate.ot.otOneHours;
        element["otOneAmount"] = element.roundDate.ot.otOneAmount;
        element["otOneFiveHours"] = element.roundDate.ot.otOneFiveHours;
        element["otOneFiveAmount"] = element.roundDate.ot.otOneFiveAmount;
        element["otTwoHours"] = element.roundDate.ot.otTwoHours;
        element["otTwoAmount"] = element.roundDate.ot.otTwoAmount;
        element["otThreeHours"] = element.roundDate.ot.otThreeHours;
        element["otThreeAmount"] = element.roundDate.ot.otThreeAmount;

        element["shiftPayMorning"] = element.roundDate.shiftFee.shiftMorning;
        element["shiftPayAfternoon"] = element.roundDate.shiftFee.shiftNoon;
        element["shiftPayNight"] = element.roundDate.shiftFee.shiftNight;
        element["shiftPayTotal"] =
          element.roundDate.shiftFee.shiftMorning +
          element.roundDate.shiftFee.shiftNight +
          element.roundDate.shiftFee.shiftNoon;
      });
      setData(temp);
    }
  }, [summaryTimeList]);

  const calculateCellValue = (data) => {
    return [data.firstname, data.lastname].join(" ");
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    handleOpenDialog();
    setSelectedRowsData(selectedRowsData[0]);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const rowIndex = (cellInfo) => {
    return cellInfo.rowIndex + 1;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const customizeOTHour = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} ชม.` : "";
  };

  const customizeOTAmount = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} บาท` : "";
  };

  const calculateCustomSummary = (options) => {
    console.log(options);
  };

  const onExporting = useCallback(() => {
    // let workbook = new ExcelJS.Workbook();
    // let worksheet = workbook.addWorksheet("SheetName");
    // exportDataGrid({
    //   component: grid.current.instance,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       `${payRunDetail.payrunName}.xlsx`
    //     );
    //   });
    // });
  }, []);

  const handleClickViewMonthly = (selected) => {
    handleChangeSelectEmployee(selected.row.data);
  };

  return (
    <Fragment>
      <StyledCard>
        <StyledCardContent>
          {summaryTimeListFetching ? (
            <StyledLoading>
              <img width="80" alt="loading" src={loading} />
            </StyledLoading>
          ) : (
            <StyledWrapDataGrid>
              <DataGrid
                dataSource={data}
                showBorders={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                columnAutoWidth={true}
              >
                {summaryTimeList && (
                  <Column type="buttons" width={60}>
                    <Button
                      icon="fa-regular fa-calendar-circle-user"
                      onClick={handleClickViewMonthly}
                    />
                  </Column>
                )}
                <Column
                  caption="รหัสพนักงาน"
                  dataField="employeeId"
                  dataType="string"
                  width={120}
                  cssClass="column-info"
                />

                <Column
                  caption="ชื่อ-สกุล"
                  dataType="string"
                  cssClass="column-info"
                  dataField="fullname"
                >
                  <HeaderFilter allowSearch={true} />
                </Column>

                <Column
                  dataField="departmentName"
                  caption="หน่วยงาน"
                  dataType="string"
                  cssClass="column-info"
                />
                <Column
                  dataField="sectionName"
                  caption="แผนก"
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="positionName"
                  caption="ตำแหน่ง"
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="workingType"
                  caption="ประเภทการทำงาน"
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="paymentTypeName"
                  caption="ประเภทการจ่าย"
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="paymentRoundName"
                  caption="รอบการจ่าย"
                  dataType="string"
                  cssClass="column-info"
                  //groupIndex={0}
                />

                {gridColumnsOT.map((item, key) => (
                  <Column
                    dataField={item.dataField}
                    caption={item.caption}
                    format={item.format}
                    cssClass="column-total-addition"
                  />
                ))}

                {summaryTimeList &&
                  summaryTimeList.isPayShift === 1 &&
                  gridColumnsShift.map((item, key) => (
                    <Column
                      dataField={item.dataField}
                      caption={item.caption}
                      format={item.format}
                      cssClass={item.cssClass}
                    />
                  ))}

                <Export enabled={true} allowExportSelectedData={true} />

                <Selection mode="single" />
                <Sorting mode="multiple" />
                <Scrolling columnRenderingMode="virtual" />
                <Paging defaultPageSize={20} />
                <FilterRow visible={false} />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={240}
                  placeholder="ค้นหา..."
                />
              </DataGrid>
            </StyledWrapDataGrid>
          )}
        </StyledCardContent>
      </StyledCard>
    </Fragment>
  );
};
export default TableEmployee;
