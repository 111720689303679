import React, { useState, useEffect, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Badge,
  Grid,
  Typography,
  Avatar,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  InputAdornment,
  FormControl,
  TextField,
  FilledInput,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  Stack,
  Divider,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";

import Day from "../assets/day.png";
import Night from "../assets/night.png";
import Mid from "../assets/mid.png";
import AvatarOff from "../../../shared/general/AvatarOff";

import {
  getAllEmployeesShift,
  employeeChangeTime,
} from "../../../../../actions/employee";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DrawerUpload from "./drawerUpload";
import { exportExcelEmployeeShiftDaily } from "../../../../../actions/shift";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { useForm } from "react-hook-form";
import { debounce } from "../../../../../utils";

const columns = [
  {
    id: "name",
    label: "ชื่อ-สกุล/ตำแหน่ง",
    minWidth: 240,
    className: "sticky",
  },
];

const StyledRoot = styled("div")({
  marginTop: 16,
  paddingBottom: "24px",
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
    zIndex: 4,
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .wrap-shift-time": {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  "& .MuiButtonBase-root": {
    position: "unset",
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledContent = styled("div")({
  padding: 24,
});

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
  display: "flex",
  // justifyContent: "space-evenly",
  gap: "16px",
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    justifyContent: "flex-start",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});

const StyledAvatar = styled(Avatar)(({ shift }) => ({
  width: 20,
  height: 20,
  fontSize: 14,
  fontWeight: 600,
  ...(shift === `A` && {
    backgroundColor: "#ffb94e",
  }),
  ...(shift === `D` && {
    backgroundColor: "#fe6c76",
  }),
  ...(shift === `C` && {
    backgroundColor: "#29a2e0",
  }),
  ...(shift === `B` && {
    backgroundColor: "#02bbb5",
  }),
}));

const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    top: 0,
    right: 0,
  },
});

const CustomPlanShift = (props) => {
  const { shiftGroup, company } = props;
  const dispatch = useDispatch();
  const today = new Date();
  const employeesShift = useSelector((state) => state.employeeShift);
  const [searchDate, setSearchDate] = React.useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [tempSearchDate, setTempSearchDate] = useState({
    start: dayjs(today).set("date", 1),
  });
  const [searchText, setSearchText] = React.useState("");
  const [selected, setSelected] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [overData, setOverData] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [drawerUploadConfig, setDrawerUploadConfig] = useState({
    isOpen: false,
    data: {
      shiftGroup: {},
      tempSearchDate: null,
    },
  })

  const useHookform = useForm({
    defaultValues: {
      name: "",
    },
    mode: "all",
  })

  const onDrawerUploadClose = () => {
    setDrawerUploadConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const onDrawerUploadOpen = () => {
    setDrawerUploadConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        shiftGroup: shiftGroup,
        tempSearchDate: tempSearchDate,
      }
    }))
  }

  useEffect(() => {
    dispatch(getAllEmployeesShift(shiftGroup.idShiftGroup, tempSearchDate));
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePopoverOpen = (holiday) => (event) => {
    if (holiday) {
      setOverData(holiday);
      setAnchorElPopover(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorElPopover(null);
    setOverData(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOnClickSearch = () => {
    setSearchDate(tempSearchDate);
    dispatch(getAllEmployeesShift(shiftGroup.idShiftGroup, tempSearchDate));
  };

  const excelExport = async () => {
    const result = await dispatch(exportExcelEmployeeShiftDaily({
      month: dayjs(tempSearchDate.start).format("YYYY-MM"),
      idShiftGroup: shiftGroup.idShiftGroup,
    }))

    if(result){
      if(result.status === 200){
        dispatch(openNotificationAlert({
          type: "success",
          message: "ดาวน์โหลดสำเร็จ"
        }))
        const excelBlob = new Blob([result.data], { type: result.headers["content-type"] });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(excelBlob);
        downloadLink.download = `UniHR รูปแบบเวลาทำงานของพนักงานในรูปแบบกะ ${shiftGroup.shiftGroupName} ในเดือน ${dayjs(tempSearchDate.start).format("MMMM BBBB")} ข้อมูล ณ ${dayjs().format("DD MMMM BBBB HH.mmน.")}.xlsx`;
        downloadLink.click();
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ"
        }))
      }
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ"
      }))
    }
  };

  // const handleChange = (event) => {
  //   debounceTextRef.current.value
  //   setDebounceText(event.target.value);
  // };

  const handleClickChangeTime = async (item) => {
    handleCloseMenu();
    const formData = {
      idEmployees: selected.row.idEmployees,
      idShift: selected.column.pattern.idShift,
      idShiftType: item.idShiftType,
      idShiftGroup: item.idShiftGroup,
      workingDate: selected.column.date,
      isApprove: 1,
      isWorkingDay: item.isWorkingDay,
    };
    const result = await dispatch(employeeChangeTime(formData));
    if (result && result.status === 200) {
      dispatch(getAllEmployeesShift(shiftGroup.idShiftGroup, tempSearchDate));
      handleChangeAlertType("success");
      handleOpenAlert();
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const openPopover = Boolean(anchorElPopover);

  return (
    <StyledRoot>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16 }}
        alignItems={"center"}
      >
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              วันที่เริ่มต้น
            </Typography>
            <div className="search-date">
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  inputFormat="MMMM yyyy"
                  value={tempSearchDate.start}
                  views={["year", "month"]}
                  name="start"
                  openTo="month"
                  onChange={(newValue) => {
                    setTempSearchDate({
                      ...tempSearchDate,
                      ["start"]: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params} 
                      variant="filled" 
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                  PaperProps={{
                    sx: {
                      "& .Mui-selected": {
                        backgroundColor: "#46cbe2!important",
                      },
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
          </StyledBoxSearch>
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              วันที่สิ้นสุด
            </Typography>
            <div className="search-date">
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  minDate={tempSearchDate.start}
                  inputFormat="dd/MM/yyyy"
                  value={tempSearchDate.end}
                  name="end"
                  onChange={(newValue) => {
                    setTempSearchDate({
                      ...tempSearchDate,
                      ["end"]: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <StyledTextField variant="filled" fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </StyledBoxSearch>
        </Grid> */}
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              ค้นหาชื่อ-สกุล
            </Typography>
            <FormControl fullWidth variant="filled">
              <StyledFilledInput
                inputRef={useHookform.register("name")}
                placeholder="ค้นหาชื่อ-สกุล"
                id="name"
                name="name"
                // onChange={(e) => {debounce(() => {console.log(e.target.value)})}}
                onChange={debounce(e => {setSearchText(e.target.value);setPage(0);}, 500, true)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearchButton>
            <div>
              <ButtonBlue
                variant="contained"
                startIcon={<SearchRoundedIcon />}
                onClick={handleOnClickSearch}
              >
                ค้นหา
              </ButtonBlue>
            </div>
            <div>
              <ButtonBlue
                onClick={excelExport}
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
              >
                ดาวน์โหลด
              </ButtonBlue>
            </div>
            <div>
              <ButtonBlue
                onClick={onDrawerUploadOpen}
                variant="outlined"
                startIcon={<UploadRoundedIcon />}
              >
                อัพโหลด
              </ButtonBlue>
            </div>
          </StyledBoxSearchButton>
        </Grid>
      </Grid>
      <Stack useFlexGap direction="row" spacing="16px" divider={<Divider orientation="vertical" flexItem/>} flexWrap="wrap">
        {shiftGroup.shiftType.map((item, index) => (
          <Box key={index}>
            <Typography fontSize="12px" color="text.secondary">{dayjs(item.timeIn, "HH:mm:ss").format("HH:mm")} - {dayjs(item.timeOut, "HH:mm:ss").format("HH:mm")}</Typography>
            <Typography fontSize="18px" fontWeight="500">{item.shiftTypeName}</Typography>
          </Box>
        ))}
      </Stack>
      <CardStyle style={{marginTop: "24px"}}>
        <StyledContent>
          {employeesShift && employeesShift.result ? (
            <Box style={{ display: "flex", position: "relative" }}>
              <StyledBox className={"close"}>
                <TableContainer sx={{ width: "100%", height:"calc(100vh - 200px)", minHeight: 300, maxHeight: 600 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns
                          .filter((item) => {
                            return item.className === "sticky";
                          })
                          .map((column) => (
                            <StyledCellHeader
                              key={`col-${column.id}`}
                              align={"center"}
                              className="sticky"
                              style={{ minWidth: column.minWidth, zIndex: 100 }}
                            >
                              {column.label}
                            </StyledCellHeader>
                          ))}

                        {employeesShift &&
                          employeesShift.result &&
                          employeesShift.result.length > 0 &&
                          employeesShift.result[0].timeline &&
                          employeesShift.result[0].timeline.map(
                            (column, index) => (
                              <StyledCellHeader
                                key={index}
                                style={{ minWidth: 150 }}
                              >
                                <Typography
                                  variant="body2"
                                  align="center"
                                  className={
                                    dayjs(column.date).day() === 0 ||
                                    dayjs(column.date).day() === 6
                                      ? "weekend"
                                      : "workday"
                                  }
                                >
                                  {dayjs(column.date).format("DD/MM/YYYY")}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  align="center"
                                  className={
                                    dayjs(column.date).day() === 0 ||
                                    dayjs(column.date).day() === 6
                                      ? "weekend"
                                      : "workday"
                                  }
                                  aria-owns={
                                    openPopover
                                      ? "mouse-over-popover"
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  onMouseEnter={handlePopoverOpen(
                                    column.holiday
                                  )}
                                  onMouseLeave={handlePopoverClose}
                                >
                                  {dayjs(column.date).format("dddd")}{" "}
                                  {column.holiday && (
                                    <i className="fa-regular fa-circle-info"></i>
                                  )}
                                </Typography>
                              </StyledCellHeader>
                            )
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employeesShift.result
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .filter(f => (searchText === "" || String(`${f.firstname_TH} ${f.lastname_TH}`).includes(searchText)))
                        .map((row) => {
                          return (
                            <StyledRowContent key={row.idEmp}>
                              <StyledCellContent className="sticky">
                                <div className="avatar">
                                  <Avatar
                                    src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                                  />
                                  <div>
                                    <Typography className="fullname">
                                      {row.firstname_TH} {row.lastname_TH}
                                    </Typography>
                                    <Typography className="position">
                                      {row.positionName}
                                    </Typography>
                                  </div>
                                </div>
                              </StyledCellContent>
                              {row.timeline &&
                                row.timeline.map((column, index) => (
                                  <StyledCellContent
                                    align="center"
                                    key={`${row.idEmp} ${index}`}
                                  >
                                    {column.pattern.shiftName? <div style={{width: "100%"}}>
                                      <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        badgeContent={
                                          column.pattern.idWorkingType === 1? <StyledAvatar
                                            shift={column.pattern.shiftName}
                                            key={`${column.pattern.shiftName} ${column.date} ${index}`}
                                          >
                                            {column.pattern.shiftName}
                                          </StyledAvatar>
                                          : null
                                        }
                                        style={{width: "inherit"}}
                                      >
                                        <ButtonBlue
                                          variant="outlined"
                                          disableFocusRipple={true}
                                          disableRipple={true}
                                          aria-label="more"
                                          onClick={(event) => {
                                            setAnchorEl(event.currentTarget);
                                            setSelected({ row, column });
                                          }}
                                          style={{height: "60px", width: "inherit"}}
                                        >
                                          {column.pattern.period === 1 &&
                                            column.pattern.isWorkingDay ===
                                              1 && (
                                              <Fragment>
                                                <img
                                                  src={Day}
                                                  alt={
                                                    column.pattern.shiftTypeName
                                                  }
                                                  width={32}
                                                />
                                                <Typography
                                                  style={{ marginLeft: 8 }}
                                                >
                                                  {column.pattern.shiftTypeName}
                                                </Typography>
                                              </Fragment>
                                            )}
                                          {column.pattern.period === 2 &&
                                            column.pattern.isWorkingDay ===
                                              1 && (
                                              <Fragment>
                                                <img
                                                  src={Mid}
                                                  alt={
                                                    column.pattern.shiftTypeName
                                                  }
                                                  width={32}
                                                />
                                                <Typography
                                                  style={{ marginLeft: 8 }}
                                                >
                                                  {column.pattern.shiftTypeName}
                                                </Typography>
                                              </Fragment>
                                            )}
                                          {column.pattern.period === 3 &&
                                            column.pattern.isWorkingDay ===
                                              1 && (
                                              <Fragment>
                                                <img
                                                  src={Night}
                                                  alt={
                                                    column.pattern.shiftTypeName
                                                  }
                                                  width={32}
                                                />
                                                <Typography
                                                  style={{ marginLeft: 8 }}
                                                >
                                                  {column.pattern.shiftTypeName}
                                                </Typography>
                                              </Fragment>
                                            )}
                                          {column.pattern.isWorkingDay ===
                                            0 && (
                                            <Fragment>
                                              <AvatarOff size={8} width={20} />
                                              <Typography
                                                style={{ marginLeft: 8 }}
                                              >
                                                {column.pattern.shiftTypeName}
                                              </Typography>
                                            </Fragment>
                                          )}
                                        </ButtonBlue>
                                      </StyledBadge>
                                    </div>
                                    : <Typography fontSize="14px" fontWeight="500">ไม่ได้อยู่ในรูปแบบกะนี้</Typography>}
                                  </StyledCellContent>
                                ))}
                            </StyledRowContent>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  // count={employeesShift ? employeesShift.result.length : 0}
                  count={employeesShift ? employeesShift.result.filter(f => (searchText === "" || String(`${f.firstname_TH} ${f.lastname_TH}`).includes(searchText))).length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="จำนวนแถวต่อหน้า"
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} จาก ${count !== -1 ? count : `มากกว่า ${to}`}`}
                />
              </StyledBox>
            </Box>
          ) : (
            <Fragment>
              {employeesShift &&
              employeesShift.isFetching &&
              !employeesShift.result ? (
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  กำลังโหลดข้อมูลพนักงาน...
                </Typography>
              ) : (
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  ไม่พบพนักงาน
                </Typography>
              )}
            </Fragment>
          )}
        </StyledContent>
      </CardStyle>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {shiftGroup.shiftType.map((item) => (
          <MenuItem
            style={{ width: 150 }}
            onClick={() => handleClickChangeTime(item)}
          >
            <ListItemIcon>
              {item.period === 1 && item.isWorkingDay === 1 && (
                <img src={Day} alt={item.period} width={32} />
              )}
              {item.period === 2 && item.isWorkingDay === 1 && (
                <img src={Mid} alt={item.period} width={32} />
              )}
              {item.period === 3 && item.isWorkingDay === 1 && (
                <img src={Night} alt={item.period} width={32} />
              )}
              {item.isWorkingDay === 0 && <AvatarOff size={8} width={20} />}
            </ListItemIcon>
            <Typography flexGrow="1" whiteSpace="pre-line" textAlign="center" style={{wordBreak: "break-word"}}>{item.shiftTypeName}</Typography>
          </MenuItem>
        ))}
      </StyledMenu>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      <DrawerUpload
        drawerConfig={drawerUploadConfig}
        onClose={onDrawerUploadClose}
      />
    </StyledRoot>
  );
};

export default CustomPlanShift;
