import React, { Fragment, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import Logo from "./assets/logo/unihr_logo.png";
import StyledCard from "./shared/general/Card";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";

import { login } from "../../actions/auth";
import TextFieldTheme from "./shared/general/TextFieldTheme";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  rootCard: {
    width: 345,
    marginTop: 100,
  },
  media: {
    height: 155,
    padding: 20,
    paddingBottom: 0,
    margin: 10,
  },
  version: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  showPassword: {
    position: "absolute",
    justifyContent: "center",
  },
});

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Contain at least 8 characters";
  }

  return errors;
};

const LoginButton = withStyles((theme) => ({
  root: {
    marginTop: 16,
    borderColor: "#46cbe2",
    color: "#ffffff",
    backgroundColor: "#46cbe2",
    borderRadius: 8,
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
    },
  },
}))(Button);

const PasswordField = ({ isSubmitting, values, handleChange, error }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Fragment>
      <TextFieldTheme
        style={{ width: "100%", marginTop: 8 }}
        disabled={isSubmitting}
        label="รหัสผ่าน"
        name="password"
        id="password"
        type={showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="large"
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      ></TextFieldTheme>
    </Fragment>
  );
};

const LoginPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const location = useLocation();
  const linkState = location.state;

  const showForm = ({
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <TextFieldTheme
          variant="outlined"
          margin="normal"
          fullWidth
          id="username"
          name="username"
          label="รหัสผู้ใช้"
          onChange={handleChange}
          value={values.username}
          autoFocus
          error={errors.username}
          disabled={isSubmitting}
        ></TextFieldTheme>

        {errors.username && (
          <Typography style={{ color: "#f44336" }}>
            {errors.username}
          </Typography>
        )}
        <PasswordField
          isSubmitting={isSubmitting}
          values={values}
          handleChange={handleChange}
          name="password"
          label="รหัสผ่าน"
          error={errors.password}
        ></PasswordField>
        {errors.password && (
          <Typography style={{ color: "#f44336" }}>
            {errors.password}
          </Typography>
        )}
        {error ? (
          <Box style={{ marginTop: 2, marginBottom: 2 }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2} lg={2} md={2}>
                <IconButton
                  style={{ color: "#f44336", backgroundColor: "#ffebee" }}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10} lg={10} md={10}>
                <Typography variant="body2" sx={{ color: "#f44336" }}>
                  ไม่สามารถเข้าสู่ระบบได้ กรุณาลองอีกครั้ง
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : null}

        <Box sx={{ marginTop: "16px" }}>
          <Typography
            variant="body2"
            color="text.third"
            component={Link}
            to="/forget-password"
          >
            ลืมรหัสผ่าน ?
          </Typography>
        </Box>

        <LoginButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={isSubmitting}
          className={classes.submit}
        >
          {isSubmitting ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CircularProgress
                color="inherit"
                size={20}
                sx={{ marginRight: 2 }}
              />
              โปรดรอสักครู่...
            </Box>
          ) : (
            "เข้าสู่ระบบ"
          )}
        </LoginButton>
        <Typography variant="body2" noWrap className={classes.version}>
          Version 2.0
        </Typography>
      </form>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootCard}>
        <StyledCard>
          {
            <CardMedia
              style={{ backgroundSize: "contain" }}
              className={classes.media}
              image={Logo}
              title="Contemplative Reptile"
            />
          }
          <CardContent>
            <Typography variant="h4">เข้าสู่ระบบ</Typography>
            {/* HOC */}
            <Formik
              onSubmit={(values, { setSubmitting }) => {
                dispatch(login(values.username, values.password))
                  .then((result) => {
                    if (linkState && linkState.redirect) {
                      props.history.push(linkState.redirect);
                      window.location.reload();
                    } else {
                      props.history.push("/home");
                      window.location.reload();
                    }
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    setError(true);
                  });
              }}
              initialValues={{
                username: "",
                password: "",
                showPassword: false,
              }}
              validate={validate}
            >
              {(props) => showForm(props)}
            </Formik>
          </CardContent>
        </StyledCard>
      </div>
    </div>
  );
};

export default LoginPage;
