import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserAccessTime from "../user/AccessTime";
import AdminApproveList from "../admin/approveList/index";
import CheckInOut from "../user/AccessTime/mobile/index";

const AccessTimePage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isPWA, setIsPWA] = useState(false);
  useEffect(() => {
    let displayMode = "";

    if (window.matchMedia("(display-mode: standalone)").matches) {
      displayMode = "standalone";
      setIsPWA(true);
    } else {
      displayMode = "!!!!!!";
    }
  }, []);

  return (
    <div>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          ""
        ) : (
          isPWA ? 
          <CheckInOut/> : <UserAccessTime />
        )
      ) : null}
    </div>
  );
};

export default AccessTimePage;
