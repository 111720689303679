import * as React from "react";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledTypographySecondary = styled(Typography)({
  fontSize: 18,
});

export default function DialogOverManpower(props) {
  const { open, handleClose } = props;

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: 300 }}
      >
        <DialogTitle id="alert-dialog-title">{"แจ้งเตือน"}</DialogTitle>
        <DialogContent>
          <Typography>
            <StyledTypographySecondary component="span">
              การขอร้องของท่าน เกินจำนวนแผนกำลังพล
              <br />
            </StyledTypographySecondary>
            <div
              style={{ marginTop: 16, marginBottom: 16, textAlign: "center" }}
            >
              <span style={{ fontWeight: 600, fontSize: 20 }}>
                {`ยืนยันการทำรายการต่อไป`}{" "}
              </span>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#212B36" }}>
            ยกเลิก
          </Button>
          <ButtonBlue value={"ยืนยัน"} variant={"contained"} component={NavLink}
                      to="/admin/request"/>
        </DialogActions>
      </Dialog>
    </div>
  );
}
