import React, { useEffect, useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import CheckIcon from "@mui/icons-material/Check";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useSelect } from "@mui/base";

const StyledRoot = styled("div")({
  marginBottom: 16,
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& .MuiTypography-h6": {
    paddingLeft: 16,
  },
  "& .filter": {
    paddingLeft: 16,
  },
});

const StyledTableContainer = styled(TableContainer)({
  marginTop: 16,
  "& .cell-code": {
    width: 80,
  },
  "& .cell-action": {
    width: 80,
  },
});

const Content = (props) => {
  const { paytype, handleOpenDialogEdit } = props;
  const { t, i18n } = useTranslation();

  const { result: paytypesList } = useSelector((state) => state.paytypes);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dataTable, setDataTable] = React.useState(null);
  const [filter, setFilter] = React.useState(1);

  useEffect(() => {
    if (paytypesList) {
      const filterData = paytypesList[paytype.toLowerCase()].filter((x) => {
        return x.isRecurring === filter;
      });
      setDataTable(filterData);
    }
  }, [paytypesList]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event) => {
    setFilter(event.target.value);
    const filterData = paytypesList[paytype.toLowerCase()].filter((x) => {
      return x.isRecurring === parseInt(event.target.value);
    });

    setDataTable(filterData);
  };

  return (
    <StyledRoot>
      <StyledCard>
        <StyledCardContent>
          <Typography variant="h6">
            {paytype === "Addition" ? `${t("Addition")}` : `${t("Deduction")}`}
          </Typography>
          <FormControl component="fieldset" className="radio filter">
            <RadioGroup
              row
              aria-label="isRecurring"
              onChange={handleChange}
              name="isRecurring"
              value={filter}
            >
              <FormControlLabel value={1} control={<Radio />} label="ประจำ" />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="รายครั้ง"
              />
            </RadioGroup>
          </FormControl>
          <StyledTableContainer style={{ width: "100%", height: 450 }}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={`cell-code`}>
                    รหัส
                  </TableCell>
                  <TableCell align="center">ชื่อรายการ</TableCell>
                  <TableCell align="center">ภาษี</TableCell>
                  <TableCell align="center">ประกันสังคม</TableCell>
                  <TableCell align="center">กองทุนสำรองเลี้ยงชีพ</TableCell>
                  <TableCell align="center">คำนวณ % ค่าจ้าง</TableCell>
                  <TableCell
                    align="center"
                    className={`cell-action`}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTable &&
                  (rowsPerPage > 0
                    ? dataTable.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : dataTable
                  ).map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row.code}
                      </TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">
                        {row.isTaxable === 1 && <CheckIcon fontSize="small" />}
                      </TableCell>
                      <TableCell align="center">
                        {row.isSso === 1 && <CheckIcon fontSize="small" />}
                      </TableCell>
                      <TableCell align="center">
                        {row.isPf === 1 && <CheckIcon fontSize="small" />}
                      </TableCell>
                      <TableCell align="center">
                        {row.isPercentage === 1 && (
                          <CheckIcon fontSize="small" />
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          style={{ marginRight: 4 }}
                          onClick={() => handleOpenDialogEdit(row)}
                        >
                          <ModeEditOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="delete" size="small">
                          <DeleteOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dataTable ? dataTable.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </StyledCardContent>
      </StyledCard>
    </StyledRoot>
  );
};

export default Content;
