import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Grid, Paper, Avatar, Box, Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";


import CardStyle from "../general/Card";



const useStyles = makeStyles(() => ({
  avatar: {
    width: 200,
    height: 200,
    borderRadius: 180,
    "& .MuiSvgIcon-root": {
      fontSize: "5rem",
    },
  },
  name: {
    fontSize: "1.4rem",
    marginTop: 7,
    fontWeight: "bold",
  },
  data: {
    fontSize: "0.875rem",
    marginTop: 40,
    color: "#6e6e6e",
  },
  mainData: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  Topic: {
    fontSize: "1rem",
  },
  dataProsonal: {
    fontSize: 17,
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
  },
  phoneIcon: {
    color: "#4caf50",
    backgroundColor: "#e8f5e9",
    padding: "5px",
    borderRadius: "10px",
    marginRight: 16,
  },
  mailIcon: {
    color: "#f44336",
    backgroundColor: "#ffebee",
    padding: "5px",
    borderRadius: "10px",
    marginRight: 16,
  },
}));

const DataCareer = ({ data }) => {
  //use style in name of classes
  const classes = useStyles();
  const { user: currentUser } = useSelector((state) => state.auth);

  return (
    <div style={{ marginBottom: 16 }}>
      <CardStyle>
        <div style={{ padding: "24px 24px 60px 24px" }}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <center>
                <Avatar
                  alt={`${data.firstName} ${data.lastName}`}
                  //src={currentUser.userImage}
                  variant={"square"}
                  className={classes.avatar}
                />
              </center>
              <Typography
                className={classes.name}
                style={{ textAlign: "center" }}
              >
                {data.firstName} {data.lastName}
              </Typography>
              <Typography
                className={classes.Topic}
                style={{ textAlign: "center" }}
              >
                {data.positionName}
              </Typography>
              <Box sx={{ marginTop: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} lg={6}>
                    <Typography variant="body2" className={classes.wrapIcon}>
                      <LocalPhoneIcon className={classes.phoneIcon} />
                      {`0${data.telephoneMobile}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <Typography variant="body2" className={classes.wrapIcon}>
                      <MailIcon className={classes.mailIcon} />
                      {data.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
              >
                <Button
                  variant="outlined"
                  sx={{ paddingLeft: 4, paddingRight: 4 }}
                >
                  View Profile
                </Button>
              </Box>
            </Grid>
            <Grid item xl={7} lg={7} md={7} xs={12}>
              <Grid container spacing={2}>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>
                    Employee Type
                  </Typography>
                  <Typography className={classes.mainData}>
                    {data.employeeGroup ? data.employeeGroup : "-"}
                  </Typography>
                  <Typography className={classes.data}>Division</Typography>
                  <Typography className={classes.mainData}>
                    {data.division ? data.division : "-"}
                  </Typography>
                  <Typography className={classes.data}>Job Group</Typography>
                  <Typography className={classes.mainData}>
                    {data.jobGroup ? data.jobGroup : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>Working Type</Typography>
                  <Typography className={classes.mainData}>
                    {data.workingType ? data.workingType : "-"}
                  </Typography>
                  <Typography className={classes.data}>Department</Typography>
                  <Typography className={classes.mainData}>
                    {data.department ? data.department : "-"}
                  </Typography>
                  <Typography className={classes.data}>Job Position</Typography>
                  <Typography className={classes.mainData}>
                    {data.positionName ? data.positionName : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>Company</Typography>
                  <Typography className={classes.mainData}>
                    {data.company ? data.company : "-"}
                  </Typography>
                  <Typography className={classes.data}>Section</Typography>
                  <Typography className={classes.mainData}>
                    {data.section ? data.section : "-"}
                  </Typography>
                  <Typography className={classes.data}>Report to</Typography>
                  <Typography className={classes.mainData}>
                    {data.managerName ? data.managerName : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>
                    Personal Level
                  </Typography>
                  <Typography className={classes.mainData}>
                    {data.PersonLevel ? data.PersonLevel : "-"}
                  </Typography>
                  <Typography className={classes.data}>ESY</Typography>
                  <Typography className={classes.mainData}>
                    {data.yearsESY ? data.yearsESY : "0"} /{" "}
                    {data.monthsESY ? data.monthsESY : "0"}
                  </Typography>
                </Grid>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>TIG</Typography>
                  <Typography className={classes.mainData}>
                    {data.yearsTIG ? data.yearsTIG : "0"} /{" "}
                    {data.monthsTIG ? data.monthsTIG : "0"}
                  </Typography>
                  <Typography className={classes.data}>Service Year</Typography>
                  <Typography className={classes.mainData}>
                    {data.serviceYears ? data.serviceYears : "0"} /{" "}
                    {data.serviceMonths ? data.serviceMonths : "0"}
                  </Typography>
                </Grid>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>TIP</Typography>
                  <Typography className={classes.mainData}>
                    {data.yearsTIP ? data.yearsTIP : "0"} /{" "}
                    {data.monthsTIP ? data.monthsTIP : "0"}
                  </Typography>
                  <Typography className={classes.data}>Age</Typography>
                  <Typography className={classes.mainData}>
                    {data.yearsAge ? data.yearsAge : "0"} /{" "}
                    {data.monthsAge ? data.monthsAge : "0"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardStyle>
    </div>
  );
};

export default DataCareer;
