import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Grid,
  Container,
  Typography,
  Divider,
  Chip,
  Box,
  Avatar,
} from "@mui/material";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineConnector,
  TimelineDot,
} from "@mui/lab";
import makeStyles from "@mui/styles/makeStyles";

import { getAllCareerPlan } from "../../../../actions/careerPlan";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddIcon from "@mui/icons-material/Add";

import DataCareer from "./showDataCareer";

import CardStyle from "../general/Card";
import ButtonBlue from "../general/ButtonBlue";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 50px)",
    paddingBottom: "30px",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 180,
  },
  nameYear: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  background: {
    backgroundColor: "#007afc",
  },
  noDataBackground: {
    backgroundColor: "#bdbdbd",
  },
  nameSelected: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white",
  },
  topic: {
    fontSize: "1rem",
    fontWeight: 500,
    textAlign: "center",
    color: "#9e9e9e",
  },
  waitingTopic: {
    margin: "20px 0px",
  },
  career: {
    fontSize: 23,
    fontWeight: "bold",
    color: "#007afc",
    marginBottom: 20,
  },
  position: {
    fontSize: 16,
    fontWeight: 600,
  },
  jobFam: {
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 16,
  },
  paper: {
    margin: 20,
  },
  link: {
    textDecoration: "none",
  },
  roadMap: {
    position: "relative",
  },
  roadMapTopic: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconRoadMap: {
    color: "#e53935",
    fontSize: "32px",
    marginRight: "10px",
  },
  Button: {
    marginLeft: 5,
    marginRight: 5,
  },
  suggestionTopic: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconSuggestion: {
    fontSize: "32px",
    color: "#ff9800",
    marginRight: "10px",
  },
  editSection: {
    display: "flex",
    justifyContent: "flex-end",
  },
  TextEditButton: {
    fontSize: "16px",
  },
  gridContainerStrengthWeakness: {
    border: "2px solid #d3dee5",
    borderRadius: "15px",
  },
  gridStrengthWeakness: {
    padding: "20px",
    "& .Strength": {
      color: "#1b5e20",
      fontWeight: "bold",
    },
    "& .Weakness": {
      color: "#c62828",
      fontWeight: "bold",
    },
  },
  gridStrength: {
    borderRight: "2px solid #d3dee5",
  },
  chipJobFamily: {
    fontWeight: 600,
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  suggestDialog: {
    padding: "10px",
  },
}));

const DataViewSuggest = ({ empProfile, name, breadcrumb, suggest }) => {
  //use style in name of classes
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: careerPlanStore } = useSelector((state) => state.careerPlan);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [data] = useState([]);
  const [plan, setPlan] = useState([]);

  useEffect(() => {
    if (careerPlanStore) {
      const planList = [...careerPlanStore];
      const planFilter = planList.filter((x) => x.idEmployees === 210);

      setPlan(planFilter[0]);
    }
  }, [careerPlanStore]);

  useEffect(() => {
    dispatch(getAllCareerPlan());
  }, []);

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
          {name}
        </Typography>
        <DataCareer data={empProfile} />
        <CardStyle style={{ marginTop: "16px" }}>
          <div style={{ padding: 24 }}>
            <Grid item xs={12}>
              <div>
                {currentUser.roles[0] === "ROLE_ADMIN" ||
                currentUser.roles[0] === "ROLE_USER_MANAGER" ||
                currentUser.roles[0] === "ROLE_USER" ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: 2,
                      }}
                    >
                      <Link
                        to={"/myCareerePlan/enterPosition"}
                        className={classes.link}
                      >
                        <ButtonBlue
                          type="submit"
                          variant="contained"
                          startIcon={<AddIcon />}
                        >
                          {plan === undefined || plan.length === 0
                            ? "เพิ่ม สายงาน"
                            : "แก้ไข สายงาน"}
                        </ButtonBlue>
                      </Link>
                    </Box>
                    <Divider sx={{ marginTop: 3, marginBottom: 4 }} />
                  </>
                ) : null}
                <div>
                  <Typography className={classes.career}>
                    ตำแหน่งสายงานที่สนใจ
                  </Typography>
                </div>
                {plan &&
                  (plan.empIntJobPos1 !== null ? (
                    <>
                      <div style={{ marginBottom: 16 }}>
                        <CardStyle>
                          <div style={{ padding: 16 }}>
                            <Grid container spacing={2}>
                              <Grid item xl={6} md={6} xs={6}>
                                <Typography className={classes.position}>
                                  {plan.empIntJobPos1}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xl={6}
                                md={6}
                                xs={6}
                                style={{ textAlign: "right" }}
                              >
                                <Chip
                                  label={plan.empIntJobFam1}
                                  className={classes.chipJobFamily}
                                />
                              </Grid>
                            </Grid>
                            <Typography>{plan.empIntReason1}</Typography>
                          </div>
                        </CardStyle>
                      </div>
                      {plan.empIntJobPos2 && (
                        <div style={{ marginBottom: 16 }}>
                          <CardStyle>
                            <div style={{ padding: 16 }}>
                              <Grid container spacing={2}>
                                <Grid item xl={6} md={6} xs={6}>
                                  <Typography className={classes.position}>
                                    {plan.empIntJobPos2}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xl={6}
                                  md={6}
                                  xs={6}
                                  style={{ textAlign: "right" }}
                                >
                                  <Chip
                                    label={plan.empIntJobFam2}
                                    className={classes.chipJobFamily}
                                  />
                                </Grid>
                              </Grid>
                              <Typography>{plan.empIntReason2}</Typography>
                            </div>
                          </CardStyle>
                        </div>
                      )}
                      {plan.empIntJobPos3 && (
                        <div style={{ marginBottom: 16 }}>
                          <CardStyle>
                            <div style={{ padding: 16 }}>
                              <Grid container spacing={2}>
                                <Grid item xl={6} md={6} xs={6}>
                                  <Typography className={classes.position}>
                                    {plan.empIntJobPos3}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xl={6}
                                  md={6}
                                  xs={6}
                                  style={{ textAlign: "right" }}
                                >
                                  <Chip
                                    label={plan.empIntJobFam3}
                                    className={classes.chipJobFamily}
                                  />
                                </Grid>
                              </Grid>
                              <Typography>{plan.empIntReason3}</Typography>
                            </div>
                          </CardStyle>
                        </div>
                      )}
                      <Box sx={{ marginTop: 5 }}>
                        <Grid
                          container
                          className={classes.gridContainerStrengthWeakness}
                        >
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={12}
                            sm={6}
                            xs={12}
                            className={`${classes.gridStrengthWeakness} ${classes.gridStrength}`}
                          >
                            <Typography
                              className={"Strength"}
                              style={{ color: "#4caf50" }}
                              gutterBottom
                            >
                              จุดแข็ง :{" "}
                            </Typography>
                            {plan &&
                              (plan.empStrength1 ||
                              plan.empStrength2 ||
                              plan.empStrength3 ? (
                                <>
                                  <Typography className={classes.jobFam}>
                                    {plan.empStrength1
                                      ? `- ${plan.empStrength1}`
                                      : null}
                                  </Typography>
                                  <Typography className={classes.jobFam}>
                                    {plan.empStrength2
                                      ? `- ${plan.empStrength2}`
                                      : null}
                                  </Typography>
                                  <Typography className={classes.jobFam}>
                                    {plan.empStrength3
                                      ? `- ${plan.empStrength3}`
                                      : null}
                                  </Typography>
                                </>
                              ) : (
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#9e9e9e" }}
                                >
                                  ไม่มีข้อมูล
                                </Typography>
                              ))}
                          </Grid>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={12}
                            sm={6}
                            xs={12}
                            className={classes.gridStrengthWeakness}
                          >
                            <Typography
                              className={"Weakness"}
                              style={{ color: "#f44336" }}
                              gutterBottom
                            >
                              จุดอ่อน :{" "}
                            </Typography>
                            {plan &&
                              (plan.empWeakness1 ||
                              plan.empWeakness2 ||
                              plan.empWeakness3 ? (
                                <>
                                  <Typography className={classes.jobFam}>
                                    {plan.empWeakness1
                                      ? `- ${plan.empWeakness1}`
                                      : null}
                                  </Typography>
                                  <Typography className={classes.jobFam}>
                                    {plan.empWeakness2
                                      ? `- ${plan.empWeakness2}`
                                      : null}
                                  </Typography>
                                  <Typography className={classes.jobFam}>
                                    {plan.empWeakness3
                                      ? `- ${plan.empWeakness3}`
                                      : null}
                                  </Typography>
                                </>
                              ) : (
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#9e9e9e" }}
                                >
                                  ไม่มีข้อมูล
                                </Typography>
                              ))}
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : null)}
                {plan === undefined || plan.length === 0 ? (
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Box
                        sx={{
                          backgroundColor: "#e0e0e0",
                          borderRadius: "10px",
                          padding: 1,
                          marginTop: 2,
                          paddingLeft: 5,
                          paddingRight: 5,
                        }}
                      >
                        <Typography variant="body1" sx={{ color: "#fff" }}>
                          ไม่มีข้อมูล
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
              </div>
            </Grid>
          </div>
        </CardStyle>
        <CardStyle style={{ marginTop: "16px" }}>
          <div style={{ padding: 24 }}>
            {currentUser.roles[0] !== "ROLE_USER" ? (
              <>
                <Grid container>
                  <Grid item xl={8} md={6} xs={6}></Grid>
                  {true && (
                    <Grid
                      item
                      xl={4}
                      md={6}
                      xs={6}
                      style={{ textAlign: "right" }}
                    >
                      <Link
                        to={{
                          pathname: "/manager/career/suggestCareer",
                          state: { careerPlan: plan },
                        }}
                        className={classes.link}
                      >
                        <ButtonBlue
                          type="submit"
                          variant="contained"
                          startIcon={<AddIcon />}
                        >
                          เพิ่ม การแนะนำ
                        </ButtonBlue>
                      </Link>
                    </Grid>
                  )}
                </Grid>
                <Divider sx={{ marginTop: 3, marginBottom: 4 }} />
              </>
            ) : null}
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Box className={classes.suggestDialog}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Avatar
                      alt={`${empProfile.reportToName}`}
                      src={`${process.env.REACT_APP_API_URL}image/profile/${data.avatarImg}`}
                      sx={{ width: "7rem", height: "7rem" }}
                    />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center">
                          {empProfile.managerName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          align="center"
                          sx={{ color: "#6e6e6e" }}
                        >
                          {empProfile.managerPositionName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    color: "#616161",
                  }}
                >
                  <AllInclusiveIcon style={{ fontSize: "5rem" }} />
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Box className={classes.suggestDialog}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Avatar
                      alt={`${empProfile.firstName} ${empProfile.lastName}`}
                      src={`${process.env.REACT_APP_API_URL}image/profile/${data.avatarImg}`}
                      sx={{ width: "7rem", height: "7rem" }}
                    />
                  </Box>
                  <Box>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          align="center"
                        >{`${empProfile.firstName} ${empProfile.lastName}`}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          align="center"
                          sx={{ color: "#6e6e6e" }}
                        >
                          {empProfile.positionName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <div style={{ marginTop: "40px" }}>
              <div>
                <Grid container>
                  <Grid item xl={8} md={12} sm={12} xs={12}>
                    <Typography gutterBottom className={classes.career}>
                      ตำแหน่งสายงานที่หัวหน้าแนะนำ
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.gridContainerStrengthWeakness}
                >
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={6}
                    xs={12}
                    className={`${classes.gridStrengthWeakness} ${classes.gridStrength}`}
                  >
                    <Typography
                      className={"Strength"}
                      style={{ color: "#4caf50" }}
                      gutterBottom
                    >
                      จุดแข็ง :
                    </Typography>
                    {plan && plan.managerStrength1 ? (
                      <>
                        <Typography className={classes.jobFam}>
                          - {plan.managerStrength1}
                        </Typography>
                        <Typography className={classes.jobFam}>
                          - {plan.managerStrength2}
                        </Typography>
                        <Typography className={classes.jobFam}>
                          - {plan.managerStrength3}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body1" sx={{ color: "#9e9e9e" }}>
                        ไม่มีข้อมูล
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={6}
                    xs={12}
                    className={classes.gridStrengthWeakness}
                  >
                    <Typography
                      className={"Weakness"}
                      style={{ color: "#f44336" }}
                      gutterBottom
                    >
                      จุดอ่อน :
                    </Typography>
                    {plan && plan.managerWeakness1 ? (
                      <>
                        <Typography className={classes.jobFam}>
                          - {plan.managerWeakness1}
                        </Typography>
                        <Typography className={classes.jobFam}>
                          - {plan.managerWeakness2}
                        </Typography>
                        <Typography className={classes.jobFam}>
                          - {plan.managerWeakness3}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body1" sx={{ color: "#9e9e9e" }}>
                        ไม่มีข้อมูล
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Typography
                  style={{ marginTop: 40, fontWeight: 700, marginBottom: 20 }}
                  gutterBottom
                >
                  ตำแหน่งที่แนะนำ
                </Typography>
                <Grid container spacing={2}>
                  {plan && plan.managerSugJobFam1 ? (
                    <>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 16 }}>
                          <CardStyle>
                            <div style={{ padding: 16 }}>
                              <Grid container spacing={2}>
                                <Grid item xl={6} md={6} xs={6}>
                                  <Typography className={classes.position}>
                                    {plan.managerSugJobPos1}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xl={6}
                                  md={6}
                                  xs={6}
                                  style={{ textAlign: "right" }}
                                >
                                  <Chip
                                    label={plan.managerSugJobFam1}
                                    className={classes.chipJobFamily}
                                  />
                                </Grid>
                              </Grid>
                              <Typography>{plan.managerSugReason1}</Typography>
                            </div>
                          </CardStyle>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                          <CardStyle>
                            <div style={{ padding: 16 }}>
                              <Grid container spacing={2}>
                                <Grid item xl={6} md={6} xs={6}>
                                  <Typography className={classes.position}>
                                    {plan.managerSugJobPos2}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xl={6}
                                  md={6}
                                  xs={6}
                                  style={{ textAlign: "right" }}
                                >
                                  <Chip
                                    label={plan.managerSugJobFam2}
                                    className={classes.chipJobFamily}
                                  />
                                </Grid>
                              </Grid>
                              <Typography>{plan.managerSugReason2}</Typography>
                            </div>
                          </CardStyle>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                          <CardStyle>
                            <div style={{ padding: 16 }}>
                              <Grid container spacing={2}>
                                <Grid item xl={6} md={6} xs={6}>
                                  <Typography className={classes.position}>
                                    {plan.managerSugJobPos3}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xl={6}
                                  md={6}
                                  xs={6}
                                  style={{ textAlign: "right" }}
                                >
                                  <Chip
                                    label={plan.managerSugJobFam3}
                                    className={classes.chipJobFamily}
                                  />
                                </Grid>
                              </Grid>
                              <Typography>{plan.managerSugReason3}</Typography>
                            </div>
                          </CardStyle>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ color: "#9e9e9e", marginLeft: "24px" }}
                    >
                      ไม่มีข้อมูล
                    </Typography>
                  )}
                </Grid>
                <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
                <Typography
                  style={{ marginTop: 20, fontWeight: 700, marginBottom: 20 }}
                  gutterBottom
                >
                  เรียนรู้ทักษะเพิ่มเติม
                </Typography>
                <Grid container spacing={2}>
                  {plan && plan.managerMoreDev1 ? (
                    <>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div style={{ marginBottom: 16 }}>
                          <CardStyle>
                            <div style={{ padding: 16 }}>
                              <Grid container spacing={2}>
                                <Grid item xl={6} md={6} xs={6}>
                                  <Typography className={classes.position}>
                                    {plan.managerMoreDev1}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </CardStyle>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                          <CardStyle>
                            <div style={{ padding: 16 }}>
                              <Grid container spacing={2}>
                                <Grid item xl={6} md={6} xs={6}>
                                  <Typography className={classes.position}>
                                    {plan.managerMoreDev2}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </CardStyle>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                          <CardStyle>
                            <div style={{ padding: 16 }}>
                              <Grid container spacing={2}>
                                <Grid item xl={6} md={6} xs={6}>
                                  <Typography className={classes.position}>
                                    {plan.managerMoreDev3}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </CardStyle>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ color: "#9e9e9e", marginLeft: "24px" }}
                    >
                      ไม่มีข้อมูล
                    </Typography>
                  )}
                </Grid>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 40,
                  }}
                >
                  <Typography>ความพร้อม : </Typography>
                  <Typography className={classes.jobFam}>
                    {plan && plan.ready ? (
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {plan.ready === "Suddenly"
                          ? "ทันที"
                          : plan.ready === "oneYear"
                          ? "1 ปี"
                          : plan.ready === "twoYear"
                          ? "2 ปี"
                          : plan.ready === "threeYear"
                          ? "3 ปี"
                          : "ไม่พร้อม"}
                      </Typography>
                    ) : (
                      <Typography variant="body1" sx={{ color: "#9e9e9e" }}>
                        ไม่มีข้อมูล
                      </Typography>
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </CardStyle>
      </Container>
    </div>
  );
};

export default DataViewSuggest;
