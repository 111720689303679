import React, { useState, useEffect } from "react";
import Pagination from "../../../shared/general/Pagination";
import EmployeeInfo from "../../../shared/pages/okrs/components/EmployeeInfo";
import { Data } from "../mockData";
import { styled } from "@mui/styles";
import { useHistory } from "react-router-dom";

import {
  Grid,
  Toolbar,
  InputAdornment,
  TextField,
  LinearProgress,
  Accordion,
  AccordionSummary,
  Typography,
  Avatar,
  IconButton,
  Box,
  AccordionDetails,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import { useSelector, useDispatch } from "react-redux";
import { getAllIdvPlan } from "../../../../../actions/IndividualPlans";
import { getUserProfile } from "../../../../../actions/user";

//icon
import FindInPageIcon from "@mui/icons-material/FindInPage";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";

const useStyles = makeStyles(() => ({
  root: {
    color: "#222f3e",
    "& .MuiGrid-container": {
      marginBottom: "0px !important",
    },
    "& .MuiGrid-item": {
      
    },
    "& .MuiTypography-h6": {
      color: "#222f3e",
    },
    "& MuiToolbar-root": {
      marginBottom: 40,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: 8,
    },
    "& .MuiAccordion-root:before": {
      backgroundColor: "transparent",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordion-rounded": {
      borderRadius: "10px",
      marginBottom: 16,
      paddingTop: 8,
      paddingBottom: 8,
    },
    "& .MuiAccordionDetails-root": {
      paddingRight: 64,
      flexDirection: "column",
      backgroundColor: "#fafafa",
      marginLeft: 8,
      marginRight: 8,
      marginBottom: 16,
      borderLeft: "5px solid #4f65df",
    },
  },
  PlanList_KR: {
    color: "#222f3e",
    fontWeight: "700",
  },
  expandIcon: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "50%",
    fontSize: "25px",
  },
  viewBtn: {
    border: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "#4f65df",
    color: "#fff",
    width: 40,
    height: 40,
    "& :hover": {
      backgroundColor: "#4f65df",
      color: "#fff",
    },
  },
  quater: {
    backgroundColor: "#e53935",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#e0f2f1",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "110px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#f9dde0",
    },
  },
  moonShot_Active: {
    fontSize: "2rem",
    color: "#ff9933",
  },
  moonShot: {
    fontSize: "2rem",
    color: "#bdbdbd",
  },
  progressObj: {
    backgroundColor: "#ede7f6",
    height: 8,
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#7e57c2",
    },
  },
  progressKR: {
    backgroundColor: "#e3f2fd",
    height: 8,
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#2196f3",
    },
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  cardList: {
    marginTop: 24,
    borderRadius: 24,
    padding: 24,
    minHeight: "100px",
    backgroundColor: "#fff",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${
          props.value / 100
        }/1.0`}</Typography>
      </Box>
    </Box>
  );
}

function OKRHistoryList() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [searchKey, setSearchKey] = useState("");
  const [year, setYear] = useState(new Date());
  const [planList, setPlanList] = useState([]);
  const [planItems, setPlanItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [employeeId, setEmployeeId] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = planList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = () => {
    dispatch(getAllIdvPlan());
  };

  useEffect(() => {
    if (idvPlanStore) {
      const records = idvPlanStore.filter(function (item) {
        const dateItem = new Date(item.dateCreate);
        const yearItem = dateItem.getFullYear();
        return (
          item.planStatus.includes("Approved") &&
          item.achieveResult !== null &&
          yearItem == year.getFullYear()
        );
      });

      setPlanList(records);
      setPlanItems(records);
      setIsLoading(false);
    }
  }, [idvPlanStore]);

  useEffect(() => {
    if (userProfile) {
      setEmployeeId(userProfile.idEmployees);
    }
  }, [userProfile]);

  useEffect(() => {
    fetchData();
  }, [year]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }

    fetchData();
  }, []);

  useEffect(() => {
    let x = [...planItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setPlanList(x);
  }, [searchKey]);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">OKR History List</h3>
              </Grid>
            </Grid>
          </div>
          {/* employee */}
          {employeeId && <EmployeeInfo idEmployees={employeeId} />}
          <Toolbar>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    margin="normal"
                    variant="inline"
                    style={{ width: "150px", marginRight: "10px" }}
                    inputVariant="outlined"
                    label="Year"
                    value={year}
                    views={["year"]}
                    onChange={(date) => setYear(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  label="Search Objective"
                  style={{ width: "300px" }}
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </div>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <div>
            {/* planList */}
            {(planList.length > 0 &&
              // currentPosts
              currentPosts.map((item) => (
                <Accordion key={item.planIdvId}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className={classes.expandIcon} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ display: "flex", padding: 10 }}
                  >
                    <Grid container alignItems="center">
                      <Grid item xl={1} lg={1} md={1} sm={2} xs={3}>
                        <Avatar className={classes.quater}>
                          {item.quater}
                        </Avatar>
                      </Grid>
                      <Grid item xl={6} lg={6} md={5} sm={10} xs={9}>
                        <Typography variant="h6">
                          {item.objectiveName}
                        </Typography>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={4} xs={6}>
                        <IconButton
                          className={
                            item.achieveResult == "Achieved"
                              ? `${classes.achieved}`
                              : `${classes.notAchieved}`
                          }
                          size="large"
                        >
                          <Typography
                            style={{ fontWeight: 600 }}
                            variant="caption"
                          >
                            {item.achieveResult}
                          </Typography>
                        </IconButton>
                      </Grid>
                      <Grid item xl={2} lg={2} md={3} sm={4} xs={4}>
                        <LinearProgressWithLabel
                          className={classes.progressObj}
                          value={Math.round(
                            (item.krScore1 * 100 +
                              item.krScore2 * 100 +
                              item.krScore3 * 100) /
                              3
                          ).toFixed(2)}
                        />
                      </Grid>
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={4}
                        xs={2}
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <Avatar
                          className={classes.viewBtn}
                          onClick={() => {
                            history.push({
                              pathname: "/individual",
                              state: { planList: item },
                              isInfo: true,
                            });
                          }}
                        >
                          <SearchIcon />
                        </Avatar>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={0} sm={0} md={1} lg={1}></Grid>
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        sm={6}
                        xs={12}
                        alignItems="center"
                      >
                        <Typography variant="body1" className="keyResult">
                          <VpnKeyRoundedIcon
                            fontSize="large"
                            className="svgKey"
                          />
                          Key Result :
                        </Typography>
                      </Grid>
                    </Grid>
                    {item.keyResult1 && (
                      <Grid
                        container
                        alignItems="center"
                        style={{ paddingBottom: "25px" }}
                      >
                        <Grid item md={1} lg={1} sm={0} xs={0}></Grid>
                        <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                          <Typography variant="body1">
                            {item.keyResult1}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <IconButton
                            className={
                              item.achieveResultKR1 == "Achieved"
                                ? `${classes.achieved}`
                                : `${classes.notAchieved}`
                            }
                            size="large"
                          >
                            <Typography
                              style={{ fontWeight: 600 }}
                              variant="caption"
                            >
                              {item.achieveResultKR1}
                            </Typography>
                          </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <i
                            style={{ marginRight: "5px" }}
                            className={
                              item.moonShotKR1 === 1
                                ? `${classes.moonShot_Active}`
                                : `${classes.moonShot}`
                            }
                            class="fa-solid fa-space-station-moon"
                          ></i>
                          <i
                            className={
                              item.moonShotAfterKR1 === 1
                                ? `${classes.moonShot_Active}`
                                : `${classes.moonShot}`
                            }
                            class="fa-solid fa-space-station-moon"
                          ></i>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <LinearProgressWithLabel
                            className={classes.progressKR}
                            value={item.krScore1 * 100}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {item.keyResult2 && (
                      <Grid
                        container
                        alignItems="center"
                        style={{ paddingBottom: "25px" }}
                      >
                        <Grid item xs={0} sm={0} md={1} lg={1}></Grid>
                        <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                          <Typography variant="body1">
                            {item.keyResult2}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <IconButton
                            className={
                              item.achieveResultKR2 == "Achieved"
                                ? `${classes.achieved}`
                                : `${classes.notAchieved}`
                            }
                            size="large"
                          >
                            <Typography
                              style={{ fontWeight: 600 }}
                              variant="caption"
                            >
                              {item.achieveResultKR2}
                            </Typography>
                          </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <i
                            style={{ marginRight: "5px" }}
                            className={
                              item.moonShotKR2 === 1
                                ? `${classes.moonShot_Active}`
                                : `${classes.moonShot}`
                            }
                            class="fa-solid fa-space-station-moon"
                          ></i>
                          <i
                            className={
                              item.moonShotAfterKR2 === 1
                                ? `${classes.moonShot_Active}`
                                : `${classes.moonShot}`
                            }
                            class="fa-solid fa-space-station-moon"
                          ></i>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <LinearProgressWithLabel
                            className={classes.progressKR}
                            value={item.krScore2 * 100}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {item.keyResult3 && (
                      <Grid
                        container
                        alignItems="center"
                        style={{ paddingBottom: "25px" }}
                      >
                        <Grid item xs={0} sm={0} md={1} lg={1}></Grid>
                        <Grid item xs={12} sm={6} md={5} lg={5}>
                          <Typography variant="body1">
                            {item.keyResult3}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <IconButton
                            className={
                              item.achieveResultKR3 == "Achieved"
                                ? `${classes.achieved}`
                                : `${classes.notAchieved}`
                            }
                            size="large"
                          >
                            <Typography
                              style={{ fontWeight: 600 }}
                              variant="caption"
                            >
                              {item.achieveResultKR3}
                            </Typography>
                          </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <i
                            style={{ marginRight: "5px" }}
                            className={
                              item.moonShotKR3 === 1
                                ? `${classes.moonShot_Active}`
                                : `${classes.moonShot}`
                            }
                            class="fa-solid fa-space-station-moon"
                          ></i>
                          <i
                            className={
                              item.moonShotAfterKR3 === 1
                                ? `${classes.moonShot_Active}`
                                : `${classes.moonShot}`
                            }
                            class="fa-solid fa-space-station-moon"
                          ></i>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <LinearProgressWithLabel
                            className={classes.progressKR}
                            value={item.krScore3 * 100}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))) || (
              <div className={classes.cardList}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6" className={classes.noData_Section}>
                      <FindInPageIcon className={classes.noDataSvg} />
                      No Data
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        )}
        {/* planList */}
        {planList.length > 0 ? (
          <Pagination
            paginate={paginate}
            postsPerPage={postsPerPage}
            // planList
            totalPosts={planList.length}
          />
        ) : null}
      </div>
    </ContainerStyled>
  );
}

export default OKRHistoryList;
