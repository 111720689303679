import React, { useState, useEffect } from "react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import  {LocalizationProvider}  from "@mui/x-date-pickers/LocalizationProvider";
import  {DatePicker}  from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import useTable from "../../../shared/table/useTable";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DialogueRecordList from "./DialogueRecordList";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";
import dayjs from "dayjs";

import { useSelector, useDispatch } from "react-redux";
import { getAllIdvPlan } from "../../../../../actions/IndividualPlans";

import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Avatar,
  IconButton,
  Menu,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";


const useStyles = makeStyles(() => ({
  root: {
    // '& .MuiFormControl-marginNormal': {
    //   marginTop: 8,
    // },
    // '& .MuiOutlinedInput-input': {
    //   padding: '14px 14px',
    // },
  },
  heading: {
    fontSize: 24,
    fontWeight: 400,
  },
  container: {
    overflowX: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "none",
    paddingLeft: 16,
    paddingRight: 16,
  },
  searchBtn: {
    marginLeft: 16,
    borderRadius: 8,
    height: 48,
    padding: 8,
  },
  quater: {
    backgroundColor: "#e53935",
    fontSize: "18px",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
  inProgress: {
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
    minWidth: "100px",
    borderRadius: "15px",
    padding: "5px 10px 5px 10px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#e0f2f1",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "110px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#f9dde0",
    },
  },
  optionsBtn: {
    borderRadius: "50px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  recordedStatus: {
    backgroundColor: "#e3f2fd",
    color: "#2196f3",
    width: "80px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const quaterData = [
  { id: "Q1", title: "Q1" },
  { id: "Q2", title: "Q2" },
  { id: "Q3", title: "Q3" },
  { id: "Q4", title: "Q4" },
];

const headCells = [
  { id: "quater", label: "Quater" },
  { id: "objectiveName", label: "Objective" },
  { id: "createBy", label: "Create By" },
  { id: "dateCreate", label: "Date" },
  { id: "achieveResult", label: "Achieve" },
  { id: "dialogueRecorded", label: "Status" },
  { id: "options", label: "" },
];

function DialogueRecord() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);

  const [team, setTeam] = useState("HR TPE");
  const [quaterSelect, setQuaterSelect] = useState("Q1");
  const [yearSelect, setYearSelect] = useState(2021);
  const [date, setDate] = useState(new Date());
  const [searchKey, setSearchKey] = useState("");
  const [records, setRecords] = useState([]);
  const [recordItems, setRecordItems] = useState([]);
  // true 
  const [isLoading, setIsLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const MenuOptions = (props) => {
    const { data } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Button
          aria-controls="options-menu"
          aria-haspopup="true"
          className={classes.optionsBtn}
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </Button>
        <Menu
          id="options-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            style={{ padding: 10, width: "100%" }}
            onClick={() => {
              history.push({
                pathname: "/individual",
                state: { planList: data },
                isInfo: true,
              });
            }}
          >
            View Plan
          </MenuItem>
          {data.dialogueRecorded ? (
            <>
              <MenuItem
                style={{ padding: 10 }}
                onClick={() => {
                  history.push({
                    pathname: "/dialoguehistory",
                    planIdv: data,
                    isInfo: true,
                  });
                }}
              >
                View Record History
              </MenuItem>
            </>
          ) : (
            <div>
              <MenuItem
                style={{ padding: 10 }}
                onClick={() => {
                  history.push({
                    pathname: "/dialoguehistory",
                    planIdv: data,
                  });
                }}
              >
                Record History
              </MenuItem>
            </div>
          )}
        </Menu>
      </>
    );
  };

  const fetchData = () => {
    dispatch(getAllIdvPlan());
  };

  useEffect(() => {
    if (idvPlanStore) {
      let idvPlans = idvPlanStore.filter((x) =>
        x.planStatus.includes("Approved")
      );

      idvPlans.filter((x) => x.department && x.department.includes(team));

      let planItems = idvPlans.filter(function (item) {
        let dateItem = new Date(item.dateCreate);
        let yearItem = dateItem.getFullYear();
        return (
          item.quater === quaterSelect &&
          yearItem === yearSelect &&
          item.planStatus !== null
        );
      });

      setRecords(planItems);
      setRecordItems(planItems);
      setIsLoading(false);
    }
  }, [idvPlanStore]);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);
  // Data first use records

  useEffect(() => {
    let x = [...recordItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setRecords(x);
  }, [searchKey]);

  const getQuater = () => {
    var m = Math.floor(date.getMonth() / 3) + 1;
    return m > 4 ? m - 4 : m;
  };

  useEffect(() => {
    const dateYear = date.getFullYear();
    let quater = "Q" + getQuater();

    setYearSelect(dateYear);
    setQuaterSelect(quater);
    fetchData();
  }, []);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Dialogue & Record</h3>
              </Grid>
            </Grid>
          </div>
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <FormControl variant="outlined">
                      <InputLabel>Quater</InputLabel>
                      <Select
                        label="Quater"
                        value={quaterSelect}
                        style={{
                          width: "100px",
                        }}
                        onChange={(e) => setQuaterSelect(e.target.value)}
                      >
                        {quaterData.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            style={{ display: "grid" }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        margin="normal"
                        variant="inline"
                        inputVariant="outlined"
                        label="Year"
                        value={date}
                        views={["year"]}
                        style={{
                          width: "150px",
                          marginTop: 0,
                          marginBottom: 10,
                        }}
                        onChange={(date) => {
                          setDate(date);
                          setYearSelect(date.getFullYear());
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.searchBtn}
                      onClick={fetchData}
                      style={{ marginBottom: 10 }}
                    >
                      <SearchIcon /> View
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
              >
                <TextField
                  variant="outlined"
                  label="Search Objective"
                  style={{ width: "100%" }}
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Toolbar>
          <Paper className={classes.container}>
            <TblContainer>
              <TblHead />
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      style={{ width: "100px", padding: "20px" }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {(recordsAfterPagingAndSorting().length > 0 &&
                      recordsAfterPagingAndSorting().map((item) => (
                        <TableRow key={item.planIdvId} hover>
                          <TableCell style={{ width: "100px" }} align="center">
                            <Avatar className={classes.quater}>
                              {item.quater}
                            </Avatar>
                          </TableCell>
                          <TableCell>{item.objectiveName}</TableCell>
                          <TableCell style={{ width: "250px" }}>
                            {`${item.firstName} ${item.lastName}`}
                          </TableCell>
                          <TableCell style={{ width: "120px" }}>
                            {dayjs(item.dateCreate).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell style={{ width: "150px" }}>
                            {item.planStatus === "Approved" ? (
                              <IconButton
                                className={
                                  item.achieveResult === "Achieved"
                                    ? `${classes.achieved}`
                                    : item.achieveResult === "Not Achieved"
                                    ? `${classes.notAchieved}`
                                    : `${classes.inProgress}`
                                }
                                size="large"
                              >
                                <Typography
                                  style={{ fontWeight: 600 }}
                                  variant="caption"
                                >
                                  {item.achieveResult !== null
                                    ? item.achieveResult
                                    : "In Progress"}
                                </Typography>
                              </IconButton>
                            ) : null}
                          </TableCell>
                          <TableCell>
                            {item.dialogueRecorded ? (
                              <IconButton
                                className={classes.recordedStatus}
                                size="large"
                              >
                                <Typography
                                  style={{ fontWeight: 600 }}
                                  variant="caption"
                                >
                                  Recorded
                                </Typography>
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell
                            style={{ width: "100px", padding: 10 }}
                            align="center"
                          >
                            <MenuOptions data={item} />
                          </TableCell>
                        </TableRow>
                      ))) || (
                      <TableRow>
                        <TableCell
                          colSpan={7}
                          align="center"
                          style={{ width: "100px", height: "100px" }}
                        >
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </Paper>
        </div>
        {/* Data */}
        <DialogueRecordList recordList={records} />
      </div>
    </ContainerStyled>
  );
}

export default DialogueRecord;
