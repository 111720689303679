import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Tab, Tabs, Typography, styled } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import BasicInfo from "./basicInfo";
import JobInfo from "./jobInfo";
import ManagerInfo from "./managerInfo";
import PaymentInfo from "./paymentInfo";
import FamilyInfo from "./famillyInfo";

import { addEmployees, getEmployeeRegistrationMetadata } from "../../../../../actions/employee";
import Loading from "../../../shared/loading";
import dayjs from "dayjs";
import DialogStatusForm from "../../../shared/general/DialogStatusForm";

const defaultValues = {
  employeeID: "EMPLOYEE-001",
  title_TH: "นาย",
  firstname_TH: "มนตรี",
  lastname_TH: "ชินพงศ์พาณิชย์",
  nickname_TH: "มน",
  title_EN: "Mr.",
  firstname_EN: "Montri",
  lastname_EN: "Chinpongpanich",
  nickname_EN: "Mon",
  gender: "Male",
  birthday: new Date(1999, 4, 30),
  personalID: "1444499809878",
  telephoneMobile: "0988765432",
  email: "sodazamak0123@gmail.com",
  houseNo: "39/30",
  village: "หมู่บ้าน",
  villageNo: "หมู่ที่",
  road: "ถนน",
  alley: "ซอย",
  areaCode: "10160",
  subDistrict: "ตำบล",
  district: "เขต",
  provience: "จังหวัด",
  nationality: "ไทย",
  maritalStatus: "โสด",
  passportNumber: "PASSPORT-888",
  workPermitNumber: "WORK-999",
  emergencyContact: "หนองมน คนบ้านเดียวกัน",
  emergencyPhone: "0999999999",
  emergencyRelationship: "ความสัมพันธ์",

  employmentTime: "Full time",
  idPaymentType: "1",

  hiringDate: null,
  idPosition: "1",
  sectionName: "",
  departmentName: "",
  divisionName: "",
  jobGroupName: "",
  idJobLevel: "",
  idPersonnelLevel: "",
  idEmploymentType: "",
  filed_office: "",
  workingType: "",
  idShift: "",
  workingLocation: "",
  mainWorkingLocationPoint: "",
  isFinger: "1",
  isFingerAffectPayroll: "1",
  methodAttendance: "main",
  contractTermainatoinDate: null,

  salary: "",
  costCenterCharge: "",
  costElementCharge: "",
  bookBank: "",
  bookBankBranchName: "",
  bookID: "",
  reportBankBankName: "",
  reportBankBankID: "",
  reportBankBookBankID: "",
  reportBankName: "",
  reportBankRef: "",
}

const StyledRoot = styled(Box)({
  "& .card-container": {
    padding: "24px",
    // paddingTop: 0,
    "& .title-text": {
      fontSize: "24px",
      paddingBottom: "24px",
    }
  },
  "& .page-control-container": {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .clear-button":{
    marginRight: "16px",
    padding: "4px",
    fontSize: "1rem",
    "&.date": {
      marginRight: "-8px",
    },
    "& svg": {
      fontSize: "1rem",
    }
  },
  "& .MuiTab-root": {
    fontSize: "16px",
    "&.Mui-selected": {
      color: "#46cbe2"
    }
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2"
  }
})

const AddEmployeePage = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [tabValue, setTabValue] = useState(0);

  const [registrationMetadata, setRegistrationMetadata] = useState(null);
  const [dialogStatusConfig, setDialogStatusConfig] = useState({
    open: false,
    statusCode: "",
    statusText: "",
    idEmployees: null,
  })

  const [isDuplicatedPersonalID, setIsDuplicatedPersonalID] = useState(false);
  const [isDuplicatedEmail, setIsDuplicatedEmail] = useState(false);

  const [rehiringConfig, setRehiringConfig] = useState({
    isRehiring: false,
    idEmployees: null,
  })

  
  const onCloseDialog = () => {
    if(dialogStatusConfig.idEmployees){
      setDialogStatusConfig(prevState => ({
        ...prevState,
        open: false,
      }))
      history.push(`/employees/${dialogStatusConfig.idEmployees}/edit`)
    } else {
      setDialogStatusConfig(prevState => ({
        ...prevState,
        open: false,
      }))
    }
  }

  const validationSchema = yup.object({
    employeeID: yup.string().required("โปรดกรอกข้อมูล"),
    title_TH: yup.string().required("โปรดกรอกข้อมูล"),
    firstname_TH: yup.string().required("โปรดกรอกข้อมูล"),
    lastname_TH: yup.string().required("โปรดกรอกข้อมูล"),

    title_EN: yup.string().required("โปรดกรอกข้อมูล"),
    firstname_EN: yup.string().required("โปรดกรอกข้อมูล"),
    lastname_EN: yup.string().required("โปรดกรอกข้อมูล"),

    gender: yup.string().required("โปรดกรอกข้อมูล"),
    birthday: yup.date().nullable().required("โปรดกรอกข้อมูล"),
    personalID: yup.string()
      .required("โปรดกรอกข้อมูล")
      .length(13, "โปรดกรอกเลขบัตรประชาชน 13 หลัก")
      .test("is-duplicate", "เลขบัตรประจำตัวประชาชนซ้ำกับในระบบ", (value) => {
        if(isDuplicatedPersonalID){
          return false
        } else {
          return true
        }
      }),
    telephoneMobile: yup.string().required("โปรดกรอกข้อมูล").length(10, "โปรดกรอกเบอร์โทร 10 หลัก"),
    email: yup.string()
      .required("โปรดกรอกข้อมูล")
      .test("is-duplicate", "อีเมลซ้ำกับในระบบ", (value) => {
        if(isDuplicatedEmail){
          return false
        } else {
          return true
        }
      }),
    emergencyPhone: yup.string().when({
        is: (emergencyPhone) => emergencyPhone.length > 0,
        then: (schema) => schema.length(10, "โปรดกรอกเบอร์โทร 10 หลัก"),
      }
    ),

    employmentTime: yup.string().required("โปรดกรอกข้อมูล"),
    idPaymentType: yup.string().required("โปรดกรอกข้อมูล"),

    hiringDate: yup.date().nullable().required("โปรดกรอกข้อมูล"),
    idPosition: yup.string().required("โปรดกรอกข้อมูล"),
    idJobLevel: yup.string(),
    idPersonnelLevel: yup.string(),
    idEmploymentType: yup.string().required("โปรดกรอกข้อมูล"),
    filed_office: yup.string().required("โปรดกรอกข้อมูล"),
    workingType: yup.string().required("โปรดกรอกข้อมูล"),
    idShift: yup.string().required("โปรดกรอกข้อมูล"),
    workingLocation: yup.string().required("โปรดกรอกข้อมูล"),
    
    // mainWorkingLocationPoint: yup.string().required("โปรดกรอกข้อมูล"),
  
    
    isFinger: yup.string().required("โปรดกรอกข้อมูล"),
    methodAttendance: yup.string().required("โปรดกรอกข้อมูล"),

    mainWorkingLocationPoint: yup.string().when(
      "methodAttendance", {
        is: "main",
        then: yup.string().required("โปรดกรอกข้อมูล")
      }
    ),

    idManagerLV1: yup.string().required("โปรดกรอกข้อมูล"),
    idManagerLV2: yup.string().required("โปรดกรอกข้อมูล"),

    salary: yup.string().required("โปรดกรอกข้อมูล"),
    bookBank: yup.string().required("โปรดกรอกข้อมูล"),
    bookBankBranchName: yup.string().required("โปรดกรอกข้อมูล"),
    bookID: yup.string().required("โปรดกรอกข้อมูล"),

    familyList: yup.array().of(
      yup.object().shape({
        relationship: yup.string().required("กรุณากรอกข้อมูล"),
        firstname_TH: yup.string().required("กรุณากรอกข้อมูล"),
        lastname_TH: yup.string().required("กรุณากรอกข้อมูล"),
        personalID: yup.string().when({
          is: (personalID) => personalID.length > 0,
          then: (schema) => schema.length(13, "โปรดกรอกเลขบัตรประชาชน 13 หลัก"),
        }),
        birthday: yup.date().nullable().required("โปรดกรอกข้อมูล"),
      })
    )
  });

  const useFormEmployee = useForm({
    defaultValues: {
      employeeID: "",
      title_TH: "",
      firstname_TH: "",
      lastname_TH: "",
      nickname_TH: "",
      title_EN: "",
      firstname_EN: "",
      lastname_EN: "",
      nickname_EN: "",
      gender: "",
      birthday: null,
      personalID: "",
      telephoneMobile: "",
      email: "",
      houseNo: "",
      village: "",
      villageNo: "",
      road: "",
      alley: "",
      areaCode: "",
      subDistrict: "",
      district: "",
      provience: "",
      nationality: "",
      maritalStatus: "",
      passportNumber: "",
      workPermitNumber: "",
      emergencyContact: "",
      emergencyPhone: "",
      emergencyRelationship: "",

      employmentTime: "",
      idPaymentType: "",

      hiringDate: null,
      idPosition: "",
      sectionName: "",
      departmentName: "",
      divisionName: "",
      businessUnitName: "",
      jobGroupName: "",
      jobLevelName: "",
      idPersonnelLevel: "",
      idEmploymentType: "",
      filed_office: "",
      workingType: "",
      idShift: "",
      workingLocation: "",
      mainWorkingLocationPoint: "",
      isFinger: "1",
      isFingerAffectPayroll: "1",
      methodAttendance: "main",
      contractTermainatoinDate: null,

      salary: "",
      costCenterCharge: "",
      costElementCharge: "",
      bookBank: "",
      bookBankBranchName: "",
      bookID: "",
      reportBankBankName: "",
      reportBankBankID: "",
      reportBankBookBankID: "",
      reportBankName: "",
      reportBankRef: "",

      familyList: [],
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
    criteriaMode: "firstError "
  });

  const onSubmitError = (error) => {
    setDialogStatusConfig((prevState) => ({
      ...prevState,
      open: true,
      statusCode: "error",
      statusText: "กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วนและถูกต้อง"
    }))
  }

  const onSubmit = async (data) => {

    const {
      sectionName,
      departmentName,
      divisionName,
      businessUnitName,
      jobGroupName,
      jobLevelName,
      ...submitData
    } = data;


    const formData = {}

    Object.keys(submitData).map(k => {
      if(submitData[k] === ""){
        formData[k] = null
      } else {
        formData[k] = submitData[k]
      }
    })

    formData["birthday"] = formData["birthday"]? dayjs(formData["birthday"]).format("YYYY-MM-DD"): null;
    formData["contractTermainatoinDate"] = formData["contractTermainatoinDate"]? dayjs(formData["contractTermainatoinDate"]).format("YYYY-MM-DD"): null;
    formData["hiringDate"] = formData["hiringDate"]? dayjs(formData["hiringDate"]).format("YYYY-MM-DD"): null;

    formData["familyList"].map(item => {
      item.birthday = item.birthday? dayjs(item.birthday).format("YYYY-MM-DD"): null;
    })
    // console.log(formData)

    const response = await dispatch(addEmployees(formData))

    if(response){
      if(response.status === 200){
        console.log("Success!")
        setDialogStatusConfig((prevState) => ({
          ...prevState,
          open: true,
          statusCode: "success",
          statusText: response.data.message? response.data.message: "เพิ่มพนักงานสำเร็จ",
          idEmployees: response.data.idEmployees
        }))
      } else {
        setDialogStatusConfig((prevState) => ({
          ...prevState,
          open: true,
          statusCode: "error",
          statusText: response.data.message
        }))
      }
    } else {
      console.log("Error!")
    }
  }

  useEffect(() => {
    let isMounted = true;
    dispatch(getEmployeeRegistrationMetadata()).then(res => {
      if(isMounted && res){
        if(res.status === 200){
          setRegistrationMetadata(res.data)
          // useFormEmployee.resetField("employeeID", {defaultValue: res.data.newEmployeeId})
          // useFormEmployee.setValue("employeeID", res.data.newEmployeeId)
        }
      }
    })
    
    return () => {
      isMounted = false;
    }
  }, [])

  return (
    <StyledRoot className="page">
      {!registrationMetadata? (
        <Loading />
      ):(
        <Container maxWidth="lg" style={{paddingTop: "24px", paddingBottom: "24px"}}>
          <form onSubmit={useFormEmployee.handleSubmit(onSubmit, onSubmitError)}>
            {registrationMetadata ? (<Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CardStyle
                  style={{
                    padding: "16px"
                  }}
                >
                  <Tabs
                    orientation="vertical"
                    variant="standard"
                    value={tabValue}
                    onChange={(_, newValue) => {
                      setTabValue(newValue)
                    }}
                  >
                    <Tab label="ข้อมูลทั่วไป"/>
                    <Tab label="รายละเอียดงาน"/>
                    <Tab label="หัวหน้างาน"/>
                    <Tab label="การจ่าย"/>
                    <Tab label="ครอบครัว"/>
                  </Tabs>
                </CardStyle>
              </Grid>
              <Grid item xs={12} md={8}>
                <CardStyle>
                  <Box className="card-container">
                    {tabValue === 0 && <BasicInfo 
                      useForm={useFormEmployee} 
                      isDuplicatedPersonalID={isDuplicatedPersonalID} 
                      setIsDuplicatedPersonalID={setIsDuplicatedPersonalID} 
                      isDuplicatedEmail={isDuplicatedEmail} 
                      setIsDuplicatedEmail={setIsDuplicatedEmail} 
                      rehiringConfig={rehiringConfig} 
                      setRehiringConfig={setRehiringConfig}
                      registrationMetadata={registrationMetadata}
                    />}
                    {tabValue === 1 && <JobInfo useForm={useFormEmployee} registrationMetadata={registrationMetadata}/>}
                    {tabValue === 2 && <ManagerInfo useForm={useFormEmployee} registrationMetadata={registrationMetadata}/>}
                    {tabValue === 3 && <PaymentInfo useForm={useFormEmployee}/>}
                    {tabValue === 4 && <FamilyInfo useForm={useFormEmployee} registrationMetadata={registrationMetadata}/>}
                  </Box>
                </CardStyle>

                <Box className="page-control-container">
                  <ButtonBlue
                    variant="outlined"
                    disabled={tabValue <= 0}
                    onClick={() => {
                      setTabValue(prev => prev > 0? prev-1: prev)
                    }}
                  >
                    ย้อนกลับ
                  </ButtonBlue>
                  {tabValue <= 3 && (
                    <ButtonBlue
                      variant="contained"
                      onClick={() => {
                        setTabValue(prev => prev+1)
                      }}
                    >
                      ถัดไป
                    </ButtonBlue>
                  )}
                  {/* <ButtonBlue variant="contained" type="submit" disabled={useFormEmployee.formState.isSubmitting}>เพิ่มพนักงาน</ButtonBlue> */}
                  {tabValue === 4 && <ButtonBlue variant="contained" type="submit" disabled={useFormEmployee.formState.isSubmitting}>เพิ่มพนักงาน</ButtonBlue>}
                </Box>
              </Grid>
            </Grid>) : (
              <Typography>กำลังโหลด</Typography>
            )}
          </form>
        </Container>
      )}
      <DialogStatusForm
        open={dialogStatusConfig.open}
        onClose={() => {onCloseDialog()}}
        onConfirm={() => {onCloseDialog()}}
        statusCode={dialogStatusConfig.statusCode}
        statusText={dialogStatusConfig.statusText}
      />
    </StyledRoot>
  )
}

export default AddEmployeePage;