import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Stack,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Chip,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import dayjs from "dayjs";
import CreateCompanyPolicy from "./createCompanyPolicy";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyByIdCompany, deletePolicy } from "../../../../actions/policy";
import SnackbarResponse from "./snackbarResponse";

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
});

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    sx={{
      backgroundColor: props.isActive ? "#b2dfdb" : "#ff7961",
    }}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(3),
  },
}));

const DialogDelete = ({ open, handleClose, handleDelete }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>ยืนยันการลบ</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Policy จะถูกลบอย่างถาวร
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
        >
          ยืนยัน
        </Button>
        <Button onClick={handleClose}>ยกเลิก</Button>
      </DialogActions>
    </Dialog>
  )
}

const PolicyDetail = (props) => {
  const { updateAt, isActive, handleSelect, handleOpenDelete, ...data } = props
  const { idPolicy, title, details, file, startDate, endDate} = data
  
  return (
    <>
      <Accordion disableGutters>
        <StyledAccordionSummary isActive={isActive}>
          <Typography variant="h6">{title}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            marginBottom={1}
          >
            <Typography variant="subtitle1" color="text.secondary">
              {`วันที่ประกาศ: ${dayjs(startDate).format(
                "DD/MM/YYYY"
              )} - ${dayjs(endDate).format("DD/MM/YYYY")}`}
            </Typography>
            {updateAt && (
              <Typography variant="subtitle1" color="text.secondary">
                {`แก้ไขเมื่อ: ${dayjs(updateAt).format("DD/MM/YYYY HH:mm")}`}
              </Typography>
            )}
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Chip
              icon={<DescriptionIcon />}
              label="รายละเอียด"
              style={{ marginRight: "auto" }}
            />
            <IconButton
              onClick={() => handleSelect(data)}
              style={{ color: "#ffeb3b", border: "1px solid #ffeb3b" }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              style={{ color: "#d32f2f", border: "1px solid #d32f2f" }}
              onClick={() => handleOpenDelete(idPolicy)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
          <Stack marginTop={2} marginBottom={2} spacing={1}>
            {details.map((item, index) => (
              <div key={item.idPolicyItems}>
                <Chip label={item.topic} color="primary" />
                <Typography variant="body1" padding={1}>{item.detail}</Typography>
              </div>
            ))}
          </Stack>
          {file && (
            <Button
              variant="outlined"
              component={Link}
              href={file}
              target="_blank"
              startIcon={<AttachFileIcon />}
              sx={{ justifyContent: "flex-end" }}
            >
              ไฟล์แนบประกาศ
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default function CompanyPolicy() {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: policy } = useSelector((state) => state.policy);

  const [selectPolicy, setSelectPolicy] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [configSnackbar, setConfigSnackbar] = useState({
    open: false,
    message: "",
    color: "primary",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value) => {
    setSelectPolicy(value);
    setOpen(true);
  };

  const handleOpenDelete = (value) => {
    setSelectPolicy(value);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseSnackbar = () => {
    setConfigSnackbar({ open: false, message: "", color: "primary" });
  };

  const policyActive = useMemo(() => {
    if (policy) {
      return policy.filter((item) => dayjs().isBetween(item.startDate, item.endDate, "day", "[]"));
    }
  }, [policy]);

  const policyNotActive = useMemo(() => {
    if (policy) {
      return policy.filter((item) => !dayjs().isBetween(item.startDate, item.endDate, "day", "[]"));
    }
  }, [policy]);

  const handleDelete = async () => {
    const res = await dispatch(deletePolicy(selectPolicy));
    if (res) {
      handleCloseDelete();
      setConfigSnackbar({ open: true, message: "ลบ company policy สำเร็จ", color: "warning" });
      dispatch(getPolicyByIdCompany());
    }
  };

  return (
    <>
      <WrapHeader>
        <Typography variant="h4">Company Policy</Typography>
        {userProfile && userProfile.roles.includes("ROLE_ADMIN") && (
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            สร้าง Company Policy
          </Button>
        )}
      </WrapHeader>
      {open &&
        <CreateCompanyPolicy
          open={open} 
          handleClose={handleClose} 
          editData={selectPolicy} 
          setConfigSnackbar={setConfigSnackbar}
        />
      }
      <Stack marginTop={4}>
        <Typography marginBottom={2}>ดำเนินการอยู่</Typography>
        {policyActive &&
          policyActive.map((item) => (
            <PolicyDetail
              {...item}
              key={item.idPolicy}
              handleSelect={handleSelect}
              handleOpenDelete={handleOpenDelete}
              isActive={true}
            />
          ))}
      </Stack>
      <Stack marginTop={4} marginBottom={4}>
        <Typography marginBottom={2}>ทั้งหมด</Typography>
        {policyNotActive && (
          policyNotActive.map((item) => (
            <PolicyDetail
              {...item}
              key={item.idPolicy}
              handleSelect={handleSelect}
              handleOpenDelete={handleOpenDelete}
              isActive={false}
            />
          ))
        )}
      </Stack>
      {openDelete && (
        <DialogDelete
          open={openDelete}
          handleClose={handleCloseDelete}
          handleDelete={handleDelete}
        />
      )}
      {configSnackbar.open && (
        <SnackbarResponse
          configSnackbar={configSnackbar}
          handleClose={handleCloseSnackbar}
        />
      )}
    </>
  );
}
