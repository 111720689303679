import React, { useEffect, useState } from "react";
import {
  Paper,
  Container,
  Button,
  Grid,
  Typography,
  MenuItem,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Select,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { Breadcrumb2 } from "../../shared/breadcrums";
import { getAllDivision } from "../../../../actions/division";
import * as Yup from "yup";

import { FieldArray, Formik, Form, Field } from "formik";
import { parseInt } from "lodash-es";
import AddPosition from "./addPosition";
import SaveIcon from "@mui/icons-material/Save";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "100px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  textData: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#210c96",
    marginBottom: 5,
    // textAlign:'center'
  },
  text: {
    fontSize: 17,
  },
  textNumber: {
    color: "#210c96",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "right",
  },
  textDevision: {
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#357a38",
  },
  textAddPosition: {
    fontSize: 14,
    fontWeight: "bold",
    cursor: "pointer",
    color: "#210c96",
  },
  // paper:{
  //     backgroundColor:'#210c96',
  //     padding:5,
  // }
}));

const ShowDataAndNumber = ({ name, data, val, color }) => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);

  const handleClose = () => {
    setOpenAdd(false);
  };

  return (
    <div>
      <Typography className={classes.textData} style={{ color: color }}>
        {name}
      </Typography>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className={classes.text}>{data}</Typography>
        {val !== 0 && (
          <div>
            <Typography className={classes.textNumber}>{val}</Typography>
            {/* <Paper elevation={3} className={classes.paper}> */}
            <Typography
              className={classes.textAddPosition}
              onClick={(e) => setOpenAdd(true)}
            >
              add position
            </Typography>
            {/* </Paper> */}
            {openAdd !== false && (
              <AddPosition open={openAdd} close={handleClose} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const ShowDataAndNumber2 = ({ data, val }) => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);

  const handleClose = () => {
    setOpenAdd(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography className={classes.text}>{data}</Typography>
      {val !== 0 && (
        <div>
          <Typography className={classes.textNumber}>{val}</Typography>
          {/* <Paper elevation={3} className={classes.paper}> */}
          <Typography
            className={classes.textAddPosition}
            onClick={(e) => setOpenAdd(true)}
          >
            add position
          </Typography>
          {/* </Paper> */}
          {openAdd !== false && (
            <AddPosition open={openAdd} close={handleClose} />
          )}
        </div>
      )}
    </div>
  );
};

const SetData = ({ selectName, selectLabel, menu, textName }) => {
  return (
    <Grid container>
      <Grid item xl={8} lg={8} md={7} sm={8} xs={12}>
        <Field
          component={Select}
          formControl={{ sx: { width: "-webkit-fill-available" } }}
          labelId="divisionName-select-outlined-label"
          name={selectName}
          id="divisionName-select"
          label={selectLabel}
        >
          {menu && menu.map((val) => <MenuItem value={val}>{val}</MenuItem>)}
        </Field>
      </Grid>
      <Grid item xl={4} lg={4} md={5} sm={4} xs={12}>
        <Field
          component={TextField}
          id="number-of-seat"
          name={textName}
          label="Number of Seat"
          variant="outlined"
          required
          placeholder="Number of Seat"
          style={{ width: "97%" }}
          type="number"
        />
      </Grid>
    </Grid>
  );
};

const AddDivision = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: dataDivision } = useSelector((state) => state.dataDivision);

  useEffect(() => {
    dispatch(getAllDivision());
  }, []);

  const DivisionName = [];
  const DepartmentName = [];
  const SectionName = [];

  {
    dataDivision &&
      dataDivision.map((val) => (
        <div>
          {DivisionName.push(val.name)}
          {val.department.map((val) => (
            <div>
              {DepartmentName.push(val.name)}
              {val.section.map((val) => (
                <div>{SectionName.push(val.name)}</div>
              ))}
            </div>
          ))}
        </div>
      ));
  }

  const validationSchema = Yup.object().shape({
    division: Yup.array(
      Yup.object({
        divisionName: Yup.string().required("Please select Division"),
        divisionNumber: Yup.number()
          .positive("Please enter number in Positive")
          .required("Please enter number of division seat"),
        department: Yup.array(
          Yup.object({
            departmentNumber: Yup.string().required("Please select Department"),
            departmentNumber: Yup.number()
              .positive("Please enter number in Positive")
              .required("Please enter number of department seat"),
            section: Yup.array(
              Yup.object({
                sectionName: Yup.string().required("Please select Section"),
                sectionNumber: Yup.number()
                  .positive("Please enter number in Positive")
                  .required("Please enter number of section seat"),
              })
            ),
          })
        ),
      })
    ),
  });

  const [data, setData] = useState({
    division: [
      {
        divisionName: "",
        divisionNumber: 0,
        department: [
          {
            departmentName: "",
            departmentNumber: 0,
            section: [
              {
                sectionName: "",
                sectionNumber: 0,
              },
            ],
          },
        ],
      },
    ],
  });

  const [expand, setExpand] = useState([true]);

  const setToExpand = (index) => {
    const data = [...expand];
    console.log(data[index]);
    data[index] = !data[index];
    setExpand(data);
  };

  const removeExpand = (index) => {
    const data = [...expand];
    data.splice(index, 1);
    setExpand(data);
  };

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="false">
        <Breadcrumb2
          textLabel={"add man power"}
          icon={<ApartmentIcon className={classes.sendIcon} />}
          link={"/admin/showDivision"}
          prevText={"Division"}
        />
        <br />
        <h1>Add manpower</h1>
        <br />

        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log("saved value", values);
            setData(values);
          }}
        >
          {({ values }) => (
            <Form autoComplete="off">
              <FieldArray name="division">
                {
                  ({ push, remove }) =>
                    values.division.map((_, index) => (
                      <Accordion
                        expanded={expand[index]}
                        style={{ marginBottom: 20, borderRadius: 20 }}
                      >
                        {/* ส่วนกรอกข้อมูลของ division */}
                        <AccordionSummary>
                          <Grid container>
                            <Grid
                              item
                              xl={11}
                              lg={11}
                              md={8}
                              sm={7}
                              xs={6}
                              onClick={() => setToExpand(index)}
                            >
                              <Typography
                                variant="subtitle1"
                                className={classes.textDevision}
                              >
                                Division {index + 1}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xl={1}
                              lg={1}
                              md={4}
                              sm={5}
                              xs={6}
                              style={{ textAlign: "end" }}
                            >
                              <IconButton
                                aria-label="add-demand"
                                style={{
                                  marginRight: 5,
                                  backgroundColor: "#7dc581",
                                  color: "#357a38",
                                }}
                              >
                                <AddIcon
                                  onClick={() => {
                                    push({
                                      divisionName: "",
                                      divisionNumber: 0,
                                      department: [
                                        {
                                          departmentName: "",
                                          departmentNumber: 0,
                                          section: [
                                            {
                                              sectionName: "",
                                              sectionNumber: 0,
                                            },
                                          ],
                                        },
                                      ],
                                    });
                                    setExpand([...expand, false]);
                                  }}
                                />
                              </IconButton>
                              {values.division.length === 1 ? (
                                <IconButton
                                  aria-label="delete"
                                  style={{ marginLeft: 5 }}
                                  disabled
                                  color="primary"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : (
                                <IconButton
                                  aria-label="delete"
                                  style={{
                                    marginLeft: 5,
                                    backgroundColor: "#dd99b1",
                                    color: "#ab003c",
                                  }}
                                >
                                  <DeleteIcon
                                    onClick={() => {
                                      remove(index);
                                      removeExpand(index);
                                    }}
                                  />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            <Grid item xl={9} lg={8} md={12} sm={12} xs={12}>
                              <Grid container>
                                <SetData
                                  selectName={`division[${index}].divisionName`}
                                  selectLabel="Select Division"
                                  menu={DivisionName}
                                  textName={`division[${index}].divisionNumber`}
                                />
                                <Grid item xl={12} lg={12} xs={12}>
                                  {values.division[index].divisionName !== "" &&
                                    values.division[index].divisionNumber !=
                                      0 && (
                                      <Form autoComplete="off">
                                        <FieldArray
                                          name={`division[${index}].department`}
                                        >
                                          {({ push, remove }) => (
                                            <div>
                                              {values.division[
                                                index
                                              ].department.map(
                                                (_, indexDepartment) => (
                                                  <div
                                                    key={indexDepartment}
                                                    style={{
                                                      paddingLeft: 30,
                                                    }}
                                                  >
                                                    {/* ส่วนกรอกข้อมูลของ department */}
                                                    <Grid
                                                      container
                                                      style={{ padding: 10 }}
                                                    >
                                                      <Grid
                                                        item
                                                        xl={6}
                                                        lg={6}
                                                        md={6}
                                                        xs={7}
                                                      >
                                                        <Typography
                                                          variant="subtitle1"
                                                          style={{
                                                            fontSize: 22,
                                                            fontWeight: "bold",
                                                            color: "#6b3ad1",
                                                          }}
                                                        >
                                                          Department{" "}
                                                          {indexDepartment + 1}
                                                        </Typography>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        xl={6}
                                                        md={6}
                                                        xs={5}
                                                        style={{
                                                          textAlign: "end",
                                                        }}
                                                      >
                                                        <IconButton
                                                          aria-label="add-demand"
                                                          style={{
                                                            marginRight: 5,
                                                            backgroundColor:
                                                              "#b28cff",
                                                            color: "#6b3ad1",
                                                          }}
                                                        >
                                                          <AddIcon
                                                            onClick={() => {
                                                              push({
                                                                departmentName:
                                                                  "",
                                                                departmentNumber: 0,
                                                                section: [
                                                                  {
                                                                    sectionName:
                                                                      "",
                                                                    sectionNumber: 0,
                                                                  },
                                                                ],
                                                              });
                                                            }}
                                                          />
                                                        </IconButton>
                                                        {values.division[index]
                                                          .department.length ==
                                                        1 ? (
                                                          <IconButton
                                                            aria-label="delete"
                                                            style={{
                                                              marginLeft: 5,
                                                            }}
                                                            disabled
                                                            color="primary"
                                                          >
                                                            <DeleteIcon />
                                                          </IconButton>
                                                        ) : (
                                                          <IconButton
                                                            aria-label="delete"
                                                            style={{
                                                              marginLeft: 5,
                                                              backgroundColor:
                                                                "#dd99b1",
                                                              color: "#ab003c",
                                                            }}
                                                          >
                                                            <DeleteIcon
                                                              onClick={() =>
                                                                remove(
                                                                  indexDepartment
                                                                )
                                                              }
                                                            />
                                                          </IconButton>
                                                        )}
                                                      </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                      <SetData
                                                        selectName={`division[${index}].department[${indexDepartment}].departmentName`}
                                                        selectLabel="Select Department"
                                                        menu={DepartmentName}
                                                        textName={`division[${index}].department[${indexDepartment}].departmentNumber`}
                                                      />
                                                      <Grid
                                                        item
                                                        xl={12}
                                                        lg={12}
                                                        xs={12}
                                                      >
                                                        {values.division[index]
                                                          .department[
                                                          indexDepartment
                                                        ].departmentName !=
                                                          "" &&
                                                          values.division[index]
                                                            .department[
                                                            indexDepartment
                                                          ].departmentNumber !=
                                                            0 && (
                                                            <Form autoComplete="off">
                                                              <FieldArray
                                                                name={`division[${index}].department[${indexDepartment}].section`}
                                                              >
                                                                {({
                                                                  push,
                                                                  remove,
                                                                }) => (
                                                                  <div>
                                                                    {values.division[
                                                                      index
                                                                    ].department[
                                                                      indexDepartment
                                                                    ].section.map(
                                                                      (
                                                                        _,
                                                                        indexSection
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            indexSection
                                                                          }
                                                                          style={{
                                                                            paddingLeft: 30,
                                                                          }}
                                                                        >
                                                                          {/* ส่วนกรอกข้อมูลของ section */}
                                                                          <Grid
                                                                            container
                                                                            style={{
                                                                              padding: 10,
                                                                            }}
                                                                          >
                                                                            <Grid
                                                                              item
                                                                              xl={
                                                                                6
                                                                              }
                                                                              lg={
                                                                                6
                                                                              }
                                                                              md={
                                                                                6
                                                                              }
                                                                              xs={
                                                                                6
                                                                              }
                                                                            >
                                                                              <Typography
                                                                                variant="subtitle1"
                                                                                style={{
                                                                                  fontSize: 19,
                                                                                  fontWeight:
                                                                                    "bold",
                                                                                  color:
                                                                                    "#d18714",
                                                                                }}
                                                                              >
                                                                                Section{" "}
                                                                                {indexSection +
                                                                                  1}
                                                                              </Typography>
                                                                            </Grid>
                                                                            <Grid
                                                                              item
                                                                              xl={
                                                                                6
                                                                              }
                                                                              md={
                                                                                6
                                                                              }
                                                                              xs={
                                                                                6
                                                                              }
                                                                              style={{
                                                                                textAlign:
                                                                                  "end",
                                                                              }}
                                                                            >
                                                                              <IconButton
                                                                                aria-label="add-demand"
                                                                                style={{
                                                                                  marginRight: 5,
                                                                                  backgroundColor:
                                                                                    "#ffd592",
                                                                                  color:
                                                                                    "#d18714",
                                                                                }}
                                                                              >
                                                                                <AddIcon
                                                                                  onClick={() => {
                                                                                    push(
                                                                                      {
                                                                                        sectionName:
                                                                                          "",
                                                                                        sectionNumber: 0,
                                                                                      }
                                                                                    );
                                                                                  }}
                                                                                />
                                                                              </IconButton>
                                                                              {values
                                                                                .division[
                                                                                index
                                                                              ]
                                                                                .department[
                                                                                indexDepartment
                                                                              ]
                                                                                .section
                                                                                .length ==
                                                                              1 ? (
                                                                                <IconButton
                                                                                  aria-label="delete"
                                                                                  style={{
                                                                                    marginLeft: 5,
                                                                                  }}
                                                                                  disabled
                                                                                  color="primary"
                                                                                >
                                                                                  <DeleteIcon />
                                                                                </IconButton>
                                                                              ) : (
                                                                                <IconButton
                                                                                  aria-label="delete"
                                                                                  style={{
                                                                                    marginLeft: 5,
                                                                                    backgroundColor:
                                                                                      "#dd99b1",
                                                                                    color:
                                                                                      "#ab003c",
                                                                                  }}
                                                                                >
                                                                                  <DeleteIcon
                                                                                    onClick={() =>
                                                                                      remove(
                                                                                        indexSection
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                </IconButton>
                                                                              )}
                                                                            </Grid>
                                                                          </Grid>
                                                                          <Grid
                                                                            container
                                                                          >
                                                                            <SetData
                                                                              selectName={`division[${index}].department[${indexDepartment}].section[${indexSection}].sectionName`}
                                                                              selectLabel="Select Section"
                                                                              menu={
                                                                                SectionName
                                                                              }
                                                                              textName={`division[${index}].department[${indexDepartment}].section[${indexSection}].sectionNumber`}
                                                                            />
                                                                          </Grid>
                                                                        </div>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                )}
                                                              </FieldArray>
                                                            </Form>
                                                          )}
                                                      </Grid>
                                                    </Grid>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          )}
                                        </FieldArray>
                                      </Form>
                                    )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xl={3} lg={4} md={12} xs={12}>
                              {/* ส่วนแสดงผลทางด้านขวา */}
                              <Paper
                                elevation={3}
                                style={{
                                  padding: 20,
                                  marginLeft: 20,
                                  marginRight: 20,
                                }}
                              >
                                <ShowDataAndNumber
                                  name={"Division"}
                                  data={values.division[index].divisionName}
                                  val={values.division[index].divisionNumber}
                                  color={"#357a38"}
                                />

                                <Typography
                                  className={classes.textData}
                                  style={{ marginTop: 20, color: "#6b3ad1" }}
                                >
                                  Department
                                </Typography>
                                {values.division[index].department.map(
                                  (_, indexDepartment) => (
                                    <ShowDataAndNumber2
                                      data={
                                        values.division[index].department[
                                          indexDepartment
                                        ].departmentName
                                      }
                                      val={
                                        values.division[index].department[
                                          indexDepartment
                                        ].departmentNumber
                                      }
                                    />
                                  )
                                )}

                                <Typography
                                  className={classes.textData}
                                  style={{ marginTop: 20, color: "#d18714" }}
                                >
                                  Section
                                </Typography>
                                {values.division[index].department.map(
                                  (val, indexDepartment) =>
                                    val.section.map((_, indexSection) => (
                                      <ShowDataAndNumber2
                                        data={
                                          values.division[index].department[
                                            indexDepartment
                                          ].section[indexSection].sectionName
                                        }
                                        val={
                                          values.division[index].department[
                                            indexDepartment
                                          ].section[indexSection].sectionNumber
                                        }
                                      />
                                    ))
                                )}

                                <br />
                                <div style={{ textAlign: "end" }}>
                                  <Typography
                                    style={{
                                      color: "green",
                                      fontSize: 18,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Total workforce seat :
                                  </Typography>
                                  <Typography className={classes.textNumber}>
                                    {values.division.reduce(
                                      (prev, current) =>
                                        prev +
                                        current.divisionNumber +
                                        parseInt(
                                          current.department.reduce(
                                            (prev2, current2) =>
                                              prev2 +
                                              current2.departmentNumber +
                                              parseInt(
                                                current2.section.reduce(
                                                  (prev3, current3) =>
                                                    prev3 +
                                                    current3.sectionNumber,
                                                  0
                                                )
                                              ),
                                            0
                                          )
                                        ),
                                      0
                                    )}
                                  </Typography>
                                </div>
                              </Paper>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))
                  // {/* </div> */}
                }
              </FieldArray>
              <Grid container justifyContent="end">
                {/* <div style={{display: 'flex'}}> */}
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  style={{
                    marginRight: 15,
                    color: "white",
                    fontSize: 16,
                    marginBottom: 10,
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default AddDivision;
