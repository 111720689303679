import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, styled, Typography, Divider } from "@mui/material";
import CardStyle from "../../shared/general/Card";

import EditRoundedIcon from "@mui/icons-material/EditRounded";

import ButtonBlue from "../../shared/general/ButtonBlue";

import LOGO from "../../assets/sun.png";
import Logo from "./logo";
import AuthorizedSignatureOne from "./authorizedSignatureOne";
import AuthorizedSignatureTwo from "./authorizedSignatureTwo";
import WitnessSignature from "./witnessSignature";
import CertificateSignature from "./certificateSignature";

import DialogEdit from "./DialogEdit";

const StyledRoot = styled("div")({
  padding: 24,
  "& .dropzone-container": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    margin: "auto",
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
    "& .inner-dropzone-config": {
      width: "100%",
      outline: "none",
      position: "relative",
      cursor: "pointer",
    },
    "& .company-logo": {
      height: 160,
    },
    "& .company-signature": {
      height: 100,
    },
    "& img": {
      objectFit: "cover",
      objectPosition: "center",
      width: "inherit",
      height: "inherit",
    },
    "& .placeholder-dropzone": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      textAlign: "center",
      "& .MuiTypography-root": {
        color: "#9e9e9e",
      },
    },
  },
  "& .captionText": {
    fontSize: 10,
    color: "#9e9e9e",
  },
  "& .logo": {
    height: 160,
    margin: "auto",
    display: "flex",
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledTextEmpty = styled(Typography)({
  fontStyle: "oblique",
  fontWeight: 400,
  color: "#b8b8b8",
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "&.inner": {
    marginBottom: 0,
  },
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const DividerStyled = styled(Divider)({
  borderBottom:"thin dashed rgba(145, 158, 171, 0.32)"
});

const LeftPanel = (props) => {
  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState(null);

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <CardStyle>
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Logo data={companyProfile.companyImageURL} />
          </Grid>
          <Grid item xs={12}>
            <StyledWrapTop>
              <div></div>
              <ButtonBlue
                size="small"
                startIcon={<EditRoundedIcon />}
                onClick={() => handleClick("profile")}
              >
                แก้ไข
              </ButtonBlue>
            </StyledWrapTop>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">ชื่อบริษัท</StyledHeadLabel>
            <Typography variant="h6">
              {companyProfile.companyName || (
                <StyledTextEmpty>โปรดระบุข้อมูล</StyledTextEmpty>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">ชื่อบริษัท(อังกฤษ)</StyledHeadLabel>
            <Typography variant="h6">
              {companyProfile.companyName_EN || (
                <StyledTextEmpty>โปรดระบุข้อมูล</StyledTextEmpty>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              ชื่อบริษัท(ย่อ)
            </StyledHeadLabel>
            <Typography variant="h6">
              {companyProfile.nameShort || (
                <StyledTextEmpty>โปรดระบุข้อมูล</StyledTextEmpty>
              )}
            </Typography>
          </Grid>
        </Grid>
        <StyledDivider />
        <StyledWrapTop>
          <Typography variant="body2" style={{ fontWeight: 600 }}>
            ผู้ติดต่อหลัก
          </Typography>
          <ButtonBlue
            size="small"
            startIcon={<EditRoundedIcon />}
            onClick={() => handleClick("main-contact")}
          >
            แก้ไข
          </ButtonBlue>
        </StyledWrapTop>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">ชื่อ-สกุล</StyledHeadLabel>
            <Typography variant="h6">
              {companyProfile.mainContactName || (
                <StyledTextEmpty>โปรดระบุข้อมูล</StyledTextEmpty>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              เบอร์โทรศัพท์
            </StyledHeadLabel>
            <Typography variant="h6">
              {companyProfile.mainContactPhone || (
                <StyledTextEmpty>โปรดระบุข้อมูล</StyledTextEmpty>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">E-mail</StyledHeadLabel>
              <Typography variant="h6" component="p">
                {companyProfile.mainContactEmail || (
                  <StyledTextEmpty>โปรดระบุข้อมูล</StyledTextEmpty>
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <DividerStyled />
          </Grid>
          <Grid item xs={12}>
            <AuthorizedSignatureOne data={companyProfile.authorizedSignatureOneImageURL} />
          </Grid>
          <Grid item xs={12}>
            <AuthorizedSignatureTwo data={companyProfile.authorizedSignatureTwoImageURL} />
          </Grid>
          <Grid item xs={12}>
            <WitnessSignature data={companyProfile.witnessSignatureImageURL} />
          </Grid>
          <Grid item xs={12}>
            <CertificateSignature data={companyProfile.certificateSignatureImageURL} />
          </Grid>
        </Grid>
      </StyledRoot>
      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </CardStyle>
  );
};

export default LeftPanel;
