import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";

import FindInPageIcon from "@mui/icons-material/FindInPage";
import Pagination from "../../../shared/general/Pagination";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import FlagIcon from "@mui/icons-material/Flag";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ShowMiniData from "../../../shared/pages/okrs/components/ShowMiniData";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useSelector, useDispatch } from "react-redux";
import { getAllOrgPlan } from "../../../../../actions/OrganizationPlans";
import { getAllTeamPlan } from "../../../../../actions/TeamPlans";
import { getAllIdvPlan } from "../../../../../actions/IndividualPlans";

import {
  Button,
  Toolbar,
  Grid,
  InputAdornment,
  TextField,
  Avatar,
  Typography,
  LinearProgress,
  IconButton,
  Box,
  Chip,
  Container,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: "0 !important",
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
  },
  searchBtn: {
    marginLeft: 16,
    borderRadius: 16,
  },
  quater: {
    backgroundColor: "#e53935",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
  PlanList_Primary: {
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "#222f3e",
  },
  PlanList_Secondary: {
    display: "flex",
    alignItems: "center",
    color: "#9e9e9e",
    marginTop: 8,
  },
  moreButton_cardPlan: {
    borderRadius: "50px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  PlanList_Object: {
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "#222f3e",
  },
  PlanList_KR: {
    color: "#222f3e",
    fontSize: "1.1rem",
    fontWeight: "700",
  },
  PlanList_Status: {
    display: "flex",
    alignItems: "center",
    color: "#9e9e9e",
    marginBottom: 8,
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  viewBtn: {
    backgroundColor: "#4f65df",
    color: "#fff",
    borderRadius: "50px",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#4f65df",
    },
  },
  pagination_section: {
    marginTop: 40,
  },
  pagination_number: {
    backgroundColor: "#fff",
  },
  inProgress: {
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
    minWidth: "100px",
    borderRadius: "15px",
    padding: "5px 10px 5px 10px",
    textAlign: "center",
    marginLeft: 8,
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "110px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
  },
  viewBtn: {
    cursor: "pointer",
    backgroundColor: "#e6eaff",
    color: "#4f65df",
    width: 40,
    height: 40,
    marginRight: 8,
  },
  cardList_KR: {
    backgroundColor: "#ffebee",
    color: "#f44336",
  },
  planTypeChip: {
    marginLeft: 16,
    marginTop: "5px",
    borderRadius: "5px",
    backgroundColor: "#448aff",
    color: "#fff",
    paddingLeft: 8,
    paddingRight: 8,
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function CascadeOKRView() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: orgPlanStore } = useSelector((state) => state.OrgPlans);
  const { result: teamPlanStore } = useSelector((state) => state.TeamPlans);
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);

  const [searchKey, setSearchKey] = useState("");
  const [searchArray, setSearchArray] = useState([""]);
  const [planList, setPlanList] = useState([]);
  const [planItems, setPlanItems] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = planList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = () => {
    dispatch(getAllOrgPlan());
    dispatch(getAllTeamPlan());
    dispatch(getAllIdvPlan());
    setIsLoading(false);
  };

  useEffect(() => {
    if (orgPlanStore) {
      let orgPlans = [...orgPlanStore];
      orgPlans.forEach((element) => {
        element.planType = "Org";

        const checkDupId = planList.filter(
          (x) => x.planOrgId === element.planOrgId
        );

        if (checkDupId.length === 0) {
          //push data to state
          setPlanList((oldArray) => [element, ...oldArray]);
          setPlanItems((oldArray) => [element, ...oldArray]);
        }
      });
    }
  }, [orgPlanStore]);

  useEffect(() => {
    if (teamPlanStore) {
      let teamPlans = [...teamPlanStore];
      teamPlans.forEach((element) => {
        element.planType = "Team";

        const checkDupId = planList.filter(
          (x) => x.planTeamId === element.planTeamId
        );

        if (checkDupId.length === 0) {
          //push data to state
          setPlanList((oldArray) => [element, ...oldArray]);
          setPlanItems((oldArray) => [element, ...oldArray]);
        }
      });
    }
  }, [teamPlanStore]);

  useEffect(() => {
    if (idvPlanStore) {
      let idvPlans = [...idvPlanStore];
      idvPlans.forEach((element) => {
        element.planType = "Idv";

        const checkDupId = planList.filter(
          (x) => x.planIdvId === element.planIdvId
        );

        if (checkDupId.length === 0) {
          //push data to state
          setPlanList((oldArray) => [element, ...oldArray]);
          setPlanItems((oldArray) => [element, ...oldArray]);
        }
      });
    }
  }, [idvPlanStore]);

  useEffect(() => {
    if (planList) {
      let sortPlan = planList.sort(function (a, b) {
        return new Date(b.dateCreate) - new Date(a.dateCreate);
      });
      setPlanList(sortPlan);
    }
  }, [planList]);

  useEffect(() => {
    let x = [...planItems];

    let xFilter = x.filter((y) => {
      const keywords = searchKey.toLocaleLowerCase();
      setSearchArray([keywords]);
      const text =
        y.objectiveName +
        " " +
        y.keyResult1 +
        " " +
        y.keyResult2 +
        " " +
        y.keyResult3;

      return text.toLowerCase().includes(keywords);
    });

    let sortPlan = xFilter.sort(function (a, b) {
      return new Date(b.dateCreate) - new Date(a.dateCreate);
    });

    //Remove Duplicates Id
    const seen = new Set();

    const filteredArr = sortPlan.filter((el) => {
      const duplicate = seen.has(el.planIdvId);
      seen.add(el.planIdvId);
      return !duplicate;
    });

    filteredArr.filter((el) => {
      const duplicate = seen.has(el.planOrgId);
      seen.add(el.planOrgId);
      return !duplicate;
    });

    filteredArr.filter((el) => {
      const duplicate = seen.has(el.planTeamId);
      seen.add(el.planTeamId);
      return !duplicate;
    });

    console.log("searchKey", searchKey);

    setPlanList(filteredArr);
  }, [searchKey]);

  const handleViewPlan = (data) => {
    if (data.planType === "Org") {
      history.push({
        pathname: "/organization",
        state: { planList: data },
        isInfo: true,
      });
    } else if (data.planType === "Team") {
      history.push({
        pathname: "/team",
        state: { planList: data },
        isInfo: true,
      });
    } else if (data.planType === "Idv") {
      history.push({
        pathname: "/individual",
        state: { planList: data },
        isInfo: true,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Toolbar sx={{ marginTop: 4 }}>
        <Grid container justifyContent="flex-end">
          <Grid container justifyContent="flex-end" item xs={12} md={6} lg={6}>
            <TextField
              variant="outlined"
              label="Search Keywords"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Toolbar>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {(planList.length > 0 &&
            currentPosts.map((item, index) => {
              return (
                <div key={index}>
                  <Typography variant="h6" className="keyResult">
                    <FlagIcon fontSize="large" className="svgKey" />
                    Objective
                  </Typography>
                  <div
                    className="cardPlan_Objective"
                    style={{
                      borderRadius: "15px",
                      border: "1px solid rgba(17, 17, 26, 0.1)",
                    }}
                  >
                    <Grid
                      container
                      alignItems="flex-start"
                      alignContent="space-between"
                    >
                      <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                        <Avatar className={classes.quater}>
                          {item.quater}
                        </Avatar>
                      </Grid>
                      <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            className={classes.PlanList_Object}
                            gutterBottom
                          >
                            {item.objectiveName}
                          </Typography>
                          <Chip
                            label={
                              item.planType === "Org"
                                ? "Organization"
                                : item.planType === "Team"
                                ? "Team"
                                : item.planType === "Idv"
                                ? "Individual"
                                : null
                            }
                            className={classes.planTypeChip}
                            size="small"
                          />
                        </Box>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={2}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        justifyContent="flex-end"
                      >
                        <Avatar
                          className={classes.viewBtn}
                          onClick={() => handleViewPlan(item)}
                        >
                          <SearchIcon />
                        </Avatar>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                      <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                        <Typography className={classes.PlanList_Status}>
                          {item.impactName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                      <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                        <ShowMiniData
                          date={item.dateCreate}
                          status={item.planStatus}
                          progress={item.achieveResult}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                      <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 2,
                          }}
                        >
                          <AccountCircleIcon
                            sx={{
                              color: "#e0e0e0",
                              fontSize: "2.5rem",
                              marginRight: 1,
                            }}
                          />
                          <Typography
                            className={classes.PlanList_Secondary}
                            sx={{ marginTop: "0px !important" }}
                          >
                            {`${item.firstName} ${item.lastName}`} ,{" "}
                            {item.positionName} , {item.department}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                  <Typography variant="h6" className="keyResult">
                    <VpnKeyRoundedIcon fontSize="large" className="svgKey" />
                    Key Result
                  </Typography>
                  {item.keyResult1 ? (
                    <div
                      className="cardPlan"
                      style={{ border: "1px solid rgba(17, 17, 26, 0.1)" }}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={2} md={1} lg={1} xl={1} sm={2}>
                          <Avatar className={classes.cardList_KR}>
                            <KeyboardArrowRightIcon />
                          </Avatar>
                        </Grid>
                        <Grid item xs={10} md={8} lg={11} xl={11} sm={10}>
                          <Typography className={classes.PlanList_KR}>
                            {item.keyResult1}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                          <ShowMiniData
                            date={item.dateCreate}
                            status={item.kR1Status}
                            progress={item.achieveResultKR1}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                  {item.keyResult2 ? (
                    <div
                      className="cardPlan"
                      style={{ border: "1px solid rgba(17, 17, 26, 0.1)" }}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={2} md={1} lg={1} xl={1} sm={2}>
                          <Avatar className={classes.cardList_KR}>
                            <KeyboardArrowRightIcon />
                          </Avatar>
                        </Grid>
                        <Grid item xs={10} md={8} lg={11} xl={11} sm={10}>
                          <Typography className={classes.PlanList_KR}>
                          {item.keyResult2}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                          <ShowMiniData
                            date={item.dateCreate}
                            status={item.kR2Status}
                            progress={item.achieveResultKR2}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                  {item.keyResult3 ? (
                    <div
                      className="cardPlan"
                      style={{
                        border: "1px solid rgba(17, 17, 26, 0.1)",
                        marginBottom: "30px",
                      }}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={2} md={1} lg={1} xl={1} sm={2}>
                          <Avatar className={classes.cardList_KR}>
                            <KeyboardArrowRightIcon />
                          </Avatar>
                        </Grid>
                        <Grid item xs={10} md={8} lg={11} xl={11} sm={10}>
                          <Typography className={classes.PlanList_KR}>
                          {item.keyResult3}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                          <ShowMiniData
                            date={item.dateCreate}
                            status={item.kR3Status}
                            progress={item.achieveResultKR3}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                  <Divider sx={{ marginTop: 6, marginBottom: 6 }} />
                </div>
              );
            })) || (
            <div className="cardPlan">
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="h6" className={classes.noData_Section}>
                    <FindInPageIcon className={classes.noDataSvg} />
                    No Data
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
      {planList.length > 0 ? (
        <Pagination
          paginate={paginate}
          postsPerPage={postsPerPage}
          totalPosts={planList.length}
        />
      ) : null}
    </>
  );
}

export default CascadeOKRView;
