import costElementService from "../services/costElement.service";
import {
    COST_ELEMENT_FETCHING,
    COST_ELEMENT_FAILED,
    COST_ELEMENT_SUCCESS,
    COST_ELEMENT_LIST_FETCHING,
    COST_ELEMENT_LIST_SUCCESS,
    COST_ELEMENT_LIST_FAILED,
    DEPARTMENT_FAILED,
    DEPARTMENT_FETCHING,
    DEPARTMENT_SUCCESS,
} from "./types";


export const getCostElement = () => async (dispatch) => {
    try {
        dispatch({
            type: COST_ELEMENT_FETCHING,
        });
        const res = await costElementService.getCostElement();
        if (res) {
            dispatch({
                type: COST_ELEMENT_SUCCESS,
                payload: res.data,
            });
            return res;
        }
    } catch (err) {
        dispatch({
            type: COST_ELEMENT_FAILED,
        });
        return err;
    }
};

export const getCostCenterAndCostElement = () => async (dispatch) => {
    try {
        dispatch({
            type: COST_ELEMENT_FETCHING,
        });
        const res = await costElementService.getCostCenterAndCostElement();
        if (res) {
            dispatch({
                type: COST_ELEMENT_SUCCESS,
                payload: res.data,
            });
            return res;
        }
    } catch (err) {
        dispatch({
            type: COST_ELEMENT_FAILED,
        });
        return err;
    }
};

export const getTypeOFCostElement = () => async (dispatch) => {
    try {
        dispatch({
            type: COST_ELEMENT_LIST_FETCHING,
        });
        const res = await costElementService.getTypeOFCostElement();
        if (res) {
            dispatch({
                type: COST_ELEMENT_LIST_SUCCESS,
                payload: res.data,
            });
            return res;
        }
    } catch (err) {
        dispatch({
            type: COST_ELEMENT_LIST_FAILED,
        });
        return err;
    }
};

export const getDepartmentOFCostElement = () => async (dispatch) => {
    try {
        dispatch({
            type: DEPARTMENT_FETCHING,
        });
        const res = await costElementService.getDepartmentOFCostElement();
        if (res) {
            dispatch({
                type: DEPARTMENT_SUCCESS,
                payload: res.data,
            });
            return res;
        }
    } catch (err) {
        dispatch({
            type: DEPARTMENT_FAILED,
        });
        return err;
    }
};

export const putCostElement = (id, formData) => async () => {
    try {
        const res = await costElementService.putCostElement(id, formData);
        if (res) {
            return res;
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.name) ||
            error.name ||
            error.toString();
        return message;
    }
};

export const postCostElement = (formData) => async () => {
    try {
        const res = await costElementService.postCostElement(formData);
        if (res) {
            return res;
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.name) ||
            error.name ||
            error.toString();
        return message;
    }
};

