import React, { useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  CardContent,
  Typography,
  Container,
  Menu,
  MenuItem,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import ExposureIcon from "@mui/icons-material/Exposure";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

import HeaderPage from "../../../shared/header/headerPage";
import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import { allPayruns } from "../../../../../actions/payruns";

import TableList from "./tableList";
import DialogNew from "./new";
import DialogUpload from "./upload-payroll";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .inner-header-name": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .btn-setting": {
    marginLeft: 8,
  },
});

const StyledCardContent = styled(CardContent)({});

const PayRunsPage = (props) => {
  const dispatch = useDispatch();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payRunList } = useSelector((state) => state.payRun);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openDialogNew, setOpenDialogNew] = React.useState(false);
  const [openDialogUpload, setOpenDialogUpload] = React.useState(false);

  const mode = [
    {
      id: 1,
      header: `แบบร่าง`,
      listPayrun: [],
      isDraft: 1,
      isApprove: 0,
      isPaid: 0,
    },
    {
      id: 2,
      header: `ปิดรอบบัญชี`,
      listPayrun: [],
      isDraft: 0,
      isApprove: 1,
      isPaid: 0,
    },
    // {
    //   id: 3,
    //   header: "จ่ายเงินแล้ว",
    //   listPayrun: [],
    //   isDraft: 0,
    //   isApprove: 1,
    //   isPaid: 1,
    // },
  ];

  useEffect(() => {
    if (userProfile) {
      dispatch(allPayruns());
    }
  }, [userProfile]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialogNew = () => {
    setAnchorEl(null);
    setOpenDialogNew(true);
  };

  const handleCloseDialogNew = () => {
    setOpenDialogNew(false);
  };

  const handleOpenDialogUpload = () => {
    setAnchorEl(null);
    setOpenDialogUpload(true);
  };

  const handleCloseDialogUpload = () => {
    setOpenDialogUpload(false);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className="wrap-header">
          <Fragment>
            <div className="inner-header-name">
              <HeaderPage textLabel={`จัดการเงินเดือน`} />
              <div>
                <ButtonBlue
                  className="btn-setting"
                  size="small"
                  component={NavLink}
                  to="/payroll/setting"
                  variant="outlined"
                  color="secondary"
                  startIcon={<SettingsRoundedIcon />}
                >
                  ตั้งค่า
                </ButtonBlue>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 8 }}>
                <ButtonBlue
                  variant={"outlined"}
                  component={NavLink}
                  to="/payroll/addition-deduction"
                  startIcon={<ExposureIcon />}
                >
                  รายการเงินได้ - เงินหัก
                </ButtonBlue>
              </div>
              <div style={{ marginLeft: 8 }}>
                <ButtonBlue
                  variant={"contained"}
                  startIcon={<AddIcon />}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  รายการเงินเดือน
                </ButtonBlue>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleOpenDialogNew}>
                  สร้างรายการเงินเดือน
                  </MenuItem>
                  <MenuItem onClick={handleOpenDialogUpload}>
                    นำเข้าเงินเดือน
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Fragment>
        </div>
        <div style={{ paddingBottom: 16 }}>
          {mode.map((value, index) => {
            return (
              <div key={index} style={{ marginBottom: 16 }}>
                <StyledCard>
                  <StyledCardContent>
                    <div>
                      <Typography variant="h6" gutterBottom>
                        {value.header}
                      </Typography>
                    </div>
                    <div>
                      {payRunList && (
                        <TableList
                          payRunList={payRunList.filter(
                            (item) => item.isDraft === value.isDraft
                          )}
                        />
                      )}
                    </div>
                  </StyledCardContent>
                </StyledCard>
              </div>
            );
          })}
        </div>
        <DialogNew open={openDialogNew} handleClose={handleCloseDialogNew} />
        <DialogUpload
          open={openDialogUpload}
          handleClose={handleCloseDialogUpload}
        />
      </Container>
    </StyledRoot>
  );
};

export default PayRunsPage;
