import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, styled, Grid, IconButton, Divider } from "@mui/material";
import dayjs from "dayjs";

import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import utils from "../../../../../../utils";

import DialogEdit from "./DialogEdit";
import DialogฺBank from "./DialogBank";
import DialogCostCenter from "./DialogCostCenter";

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledRoot = styled("div")({
  "& .salary": {
    "& .MuiTypography-h6": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "baseline",
  },
});

const StyledTextValue = styled(Typography)({
  fontSize: 18,
  "& i": {
    padding: 2,
  },
});

const TimePayment = (props) => {
  const { changeGroupTap, changeTabs } = props;
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("");
  const [showPersonalId, setShowPersonalId] = useState(false);

  const [drawerBankConfig, setDrawerBankConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const [drawerCostCenterConfig, setDrawerCostCenterConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const handleClick = (mode) => {
    console.log("handleClick");
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const changeTimeToDate = (time) => {
    let splitTime = time.split(":");
    return new Date().setHours(splitTime[0], splitTime[1], splitTime[2]);
  };

  const calculateHours = (shift) => {
    let timeIn = dayjs(changeTimeToDate(shift.timeOut));
    return timeIn.diff(changeTimeToDate(shift.timeIn), "hour");
  };

  const handleClickPayment = () => {
    changeGroupTap("work");
    changeTabs(9);
  };

  const renderCalculate = () => {
    if (employeeProfile.shift.workingHours) {
      var hours = employeeProfile.shift.workingHours / 60;
      if (employeeProfile.shift.breakTime === 0) {
        hours = hours - 1;
      }
      return `${hours} ชม./วัน`;
    } else {
      return "ไม่กำหนดเวลาทำงาน";
    }
  };

  return (
    <StyledRoot>
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          ข้อมูลเวลาทำงาน
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              ประเภทเวลางาน
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.workingType
                ? employeeProfile.workingType.toUpperCase()
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ประเภทกะ</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift.shiftGroupName}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ชื่อกะ</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift.shiftName
                ? employeeProfile.shift.shiftName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              จำนวนวันทำงาน
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift.workDay}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              เวลาทำงานต่อวัน
            </StyledHeadLabel>
            <Typography variant="h6">{renderCalculate()}</Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              เวลาเข้า-ออกงาน
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift &&
              employeeProfile.shift.timeIn &&
              employeeProfile.shift.timeOut
                ? `${employeeProfile.shift.timeIn.slice(
                    0,
                    -3
                  )} - ${employeeProfile.shift.timeOut.slice(0, -3)}`
                : "ไม่มีกรอบเวลาทำงาน"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        <div>
          <ButtonBlue
            variant="text"
            size="small"
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={handleClickPayment}
          >
            ปรับค่าจ้าง
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              ประเภทค่าจ้าง
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.paymentTypeName
                ? employeeProfile.paymentTypeName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ค่าจ้าง</StyledHeadLabel>
            <div className="salary">
              <div
                className="wrap-show-hide-personalId"
                onMouseEnter={() => setShowPersonalId(true)}
                onMouseLeave={() => setShowPersonalId(false)}
              >
                {showPersonalId ? (
                  <StyledTextValue variant="h6" align="center">
                    {employeeProfile.salary
                      ? utils.numberWithCommas(employeeProfile.salary)
                      : "-"}
                  </StyledTextValue>
                ) : (
                  <StyledTextValue className="dot" variant="h6" align="center">
                    {[...Array(6).keys()].map((_, index) => (
                      // <i key={index} class="fa-sharp fa-solid fa-circle"></i>
                      <i key={index} class="fas fa-times"></i>
                      // <CloseRoundedIcon key={index} />
                    ))}
                  </StyledTextValue>
                )}
              </div>
              <Typography color="text.secondary">ต่อเดือน</Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">รอบการจ่าย</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.paymentRoundName
                ? employeeProfile.paymentRoundName
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          Cost Center
        </StyledHeadLabel>
        <div>
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            // onClick={() => handleClick("cost")}
            onClick={() => {
              setDrawerCostCenterConfig((prev) => ({
                ...prev,
                isOpen: true,
                isEdit: true,
                data: {
                  ...employeeProfile,
                },
              }));
            }}
          >
            แก้ไข
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              Cost Center Charge
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.costCenterCharge
                ? employeeProfile.costCenterCharge
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              Cost Element Charge
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.costElementCharge
                ? employeeProfile.costElementCharge
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          ข้อมูลธนาคาร
        </StyledHeadLabel>
        <div>
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            // onClick={() => handleClick("bank")}
            onClick={() => {
              setDrawerBankConfig((prev) => ({
                ...prev,
                isOpen: true,
                isEdit: true,
                data: {
                  ...employeeProfile,
                },
              }));
            }}
          >
            แก้ไข
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <div>
        <StyledHeadLabel color="text.secondary">
          ชื่อธนาคาร/สาขา
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.bookBank ? employeeProfile.bookBank : "-"}{" "}
          {employeeProfile.bookBankBranchName
            ? `: ${employeeProfile.bookBankBranchName}`
            : ""}
        </Typography>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <StyledHeadLabel color="text.secondary">หมายเลขบัญชี</StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.bookID ? employeeProfile.bookID : "-"}
        </Typography>
      </div>
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          สำหรับออกไฟล์ธนาคาร
        </StyledHeadLabel>
      </StyledWrapTop>
      <div>
        <StyledHeadLabel color="text.secondary">รหัสธนาคาร</StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBankBankID
            ? employeeProfile.reportBankBankID
            : "-"}
        </Typography>
      </div>
      <div>
        <StyledHeadLabel color="text.secondary">ชื่อธนาคาร</StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBankBankName
            ? employeeProfile.reportBankBankName
            : "-"}
        </Typography>
      </div>
      <div>
        <StyledHeadLabel color="text.secondary">เลขที่บัญชี</StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBankBookBankID
            ? employeeProfile.reportBankBookBankID
            : "-"}
        </Typography>
      </div>
      <div>
        <StyledHeadLabel color="text.secondary">ชื่อผู้รับเงิน</StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBankName
            ? employeeProfile.reportBankName
            : "-"}
        </Typography>
      </div>
      <div>
        <StyledHeadLabel color="text.secondary">เลขที่อ้างอิง</StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBankRef ? employeeProfile.reportBankRef : "-"}
        </Typography>
      </div>

      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />

      <DialogฺBank
        drawerConfig={drawerBankConfig}
        onClose={() => {
          setDrawerBankConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      <DialogCostCenter
        drawerConfig={drawerCostCenterConfig}
        onClose={() => {
          setDrawerCostCenterConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />
    </StyledRoot>
  );
};

export default TimePayment;
