import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Popper,
  TextField,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { addDeleteEmployeePosition, getEmployeeProfile, getEmployeePositionChange, updateEmployeePositionChange } from "../../../../../../actions/employee"

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
  paddingTop: 16,
  "& .wrap": {
    marginTop: 8,
  },
  "& .present": {
    fontWeight: 500,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledListPosition = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
});

const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 360,
    },
  },
};

const DialogEdit = (props) => {
  const { open, handleClose, drawerConfig } = props;
  const dispatch = useDispatch();
  const { result: companyOrganization } = useSelector(
    (state) => state.companyOrganization
  );
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: managerList } = useSelector((state) => state.manager);
  const [formData, setFormData] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (employeeProfile && open) {
      if(!drawerConfig.isEdit){
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          idEmployeePositionOld: employeeProfile.idEmployeePosition,
          start: new Date(),
          end: null,
          isActive: 1,
          idManagerLV1: null,
          idManagerLV2: null,
          idPosition: null,
          description: "",
        });
      } else {
        setFormData({
          idEmployees: employeeProfile.idEmployees,
          start: drawerConfig.data.start? new Date(drawerConfig.data.start): null,
          // end: null,
          // isActive: 1,
          idManagerLV1: drawerConfig.data.idManagerLV1 || null,
          idManagerLV2: drawerConfig.data.idManagerLV2 || null,
          idPosition: drawerConfig.data.idPosition || null,
          description: drawerConfig.data.description || "",
          divisionName: drawerConfig.data.divisionName,
          departmentName: drawerConfig.data.departmentName,
          sectionName: drawerConfig.data.sectionName,
        });
      }
    }
  }, [open]);

  const handleSubmit = async () => {
    let result = null;
    let dataRequest = { ...formData };
    // dataRequest.method = "add"
    delete dataRequest["divisionName"];
    delete dataRequest["departmentName"];
    delete dataRequest["sectionName"];
    dataRequest.start = dayjs(dataRequest.start).format("YYYY-MM-DD");
    dataRequest.idPosition = formData.idPosition
      ? formData.idPosition
      : employeeProfile.idPosition;
    dataRequest.idManagerLV1 = formData.idManagerLV1
      ? formData.idManagerLV1
      : employeeProfile.managerLV1_idManagerLV1;
    dataRequest.idManagerLV2 = formData.idManagerLV2
      ? formData.idManagerLV2
      : employeeProfile.managerLV2_idManagerLV2;

    if (
      dataRequest.idPosition === employeeProfile.idPosition &&
      dataRequest.idManagerLV1 === employeeProfile.managerLV1_idManagerLV1 &&
      dataRequest.idManagerLV2 === employeeProfile.managerLV2_idManagerLV2
    ) {
      alert("ข้อมูลไม่มีการเปลี่ยนแปลง");
    } else {

      // console.log("dataRequest: ", dataRequest)

      if(drawerConfig.isEdit){
        // console.log(dataRequest)
        // console.log(employeeProfile.idEmployees)
        // console.log(drawerConfig.data.idEmployeePosition)
        dataRequest.updateDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
        result = await dispatch(updateEmployeePositionChange(employeeProfile.idEmployees, drawerConfig.data.idEmployeePosition, dataRequest))
      } else {
        dataRequest.method = "add"
        result = await dispatch(addDeleteEmployeePosition(dataRequest));
      }
      // const result = await dispatch(updateEmployeePosition(dataRequest));
      // if (result) {
      //   handleClose()
      //   dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      //   dispatch(getEmployeePositionChange(employeeProfile.idEmployees));
      // }
    }

    if (result) {
      handleClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      dispatch(getEmployeePositionChange(employeeProfile.idEmployees));
    }
  };

  const handleCloseDialog = () => {
    setFormData(null);
    handleClose();
  };

  return (
    <DrawerCustom
      title={"แก้ไขข้อมูลโอนย้าย"}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      {formData && (
        <StyledRoot>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <StyledContentLabel>ข้อมูลปัจจุบัน</StyledContentLabel>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">
                  ตำแหน่ง
                </StyledHeadLabel>
                <Typography className="present">
                  {employeeProfile.positionName
                    ? employeeProfile.positionName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">ฝ่าย</StyledHeadLabel>
                <Typography className="present">
                  {employeeProfile.divisionName
                    ? employeeProfile.divisionName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">ส่วน</StyledHeadLabel>
                <Typography className="present">
                  {employeeProfile.departmentName
                    ? employeeProfile.departmentName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">แผนก</StyledHeadLabel>
                <Typography className="present">
                  {employeeProfile.sectionName
                    ? employeeProfile.sectionName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">
                  กลุ่มงาน
                </StyledHeadLabel>
                <Typography className="present">
                  {employeeProfile.jobGroupName
                    ? employeeProfile.jobGroupName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">
                  หัวหน้างานลำดับที่ 1
                </StyledHeadLabel>
                <Typography className="present">
                  {employeeProfile
                    ? `${
                        employeeProfile.managerLV1_firstname_TH
                          ? employeeProfile.managerLV1_firstname_TH
                          : " "
                      } ${
                        employeeProfile.managerLV1_lastname_TH
                          ? employeeProfile.managerLV1_lastname_TH
                          : " "
                      }`
                    : "-"}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.third"
                  style={{ fontWeight: 500 }}
                >
                  {employeeProfile.managerLV1_positionName
                    ? `${employeeProfile.managerLV1_positionName}`
                    : ""}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {employeeProfile.managerLV1_email
                    ? `${employeeProfile.managerLV1_email}`
                    : ""}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">
                  หัวหน้างานลำดับที่ 2
                </StyledHeadLabel>
                <Typography className="present">
                  {employeeProfile
                    ? `${
                        employeeProfile.managerLV2_firstname_TH
                          ? employeeProfile.managerLV2_firstname_TH
                          : " "
                      } ${
                        employeeProfile.managerLV2_lastname_TH
                          ? employeeProfile.managerLV2_lastname_TH
                          : " "
                      }`
                    : "-"}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.third"
                  style={{ fontWeight: 500 }}
                >
                  {employeeProfile.managerLV2_positionName
                    ? `${employeeProfile.managerLV2_positionName}`
                    : ""}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {employeeProfile.managerLV2_email
                    ? `${employeeProfile.managerLV2_email}`
                    : ""}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel>ตำแหน่งใหม่</StyledContentLabel>
              <StyledAutocomplete
                options={companyOrganization ? companyOrganization : []}
                value={formData.idPosition? companyOrganization.find(x => x.idPosition === formData.idPosition) || null: null}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  if (newValue) {
                    setFormData({
                      ...formData,
                      idPosition: newValue.idPosition,
                      divisionName: newValue.divisionName,
                      departmentName: newValue.departmentName,
                      sectionName: newValue.sectionName,
                    });
                  } else {
                    setFormData(null);
                  }
                }}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.positionName}
                renderOption={(props, option) => (
                  <StyledListPosition component="li" {...props}>
                    <Typography align="left">{option.positionName}</Typography>
                    <Typography
                      align="left"
                      color="text.secondary"
                      variant="caption"
                    >{`${option.divisionName ? option.divisionName : "-"}/${
                      option.departmentName ? option.departmentName : "-"
                    }/${
                      option.sectionName ? option.sectionName : "-"
                    }`}</Typography>
                  </StyledListPosition>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder="ค้นหาตำแหน่ง"
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText={"ไม่พบข้อมูล"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledContentLabel gutterBottom>ฝ่าย</StyledContentLabel>
                <Typography>
                  {formData && formData.divisionName
                    ? formData.divisionName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledContentLabel gutterBottom>ส่วน</StyledContentLabel>
                <Typography>
                  {formData && formData.departmentName
                    ? formData.departmentName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledContentLabel gutterBottom>แผนก</StyledContentLabel>
                <Typography>
                  {formData && formData.sectionName
                    ? formData.sectionName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledContentLabel gutterBottom>กลุ่มงาน</StyledContentLabel>
                <Typography>
                  {formData && formData.jobGroupName
                    ? formData.jobGroupName
                    : "-"}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="wrap">
                <StyledContentLabel style={{ paddingLeft: 8 }}>
                  หัวหน้างานลำดับที่ 1
                </StyledContentLabel>
                {managerList ? (
                  <StyledFormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="idManagerLV1"
                      value={formData ? formData.idManagerLV1 : ""}
                      onChange={handleInputChange}
                      MenuProps={MenuProps}
                    >
                      {managerList &&
                        managerList.map((item) => (
                          <StyledMenuItem value={item.idEmployees}>
                            <Typography>{`${item.firstname_TH} ${item.lastname_TH}`}</Typography>
                            <Typography color="text.secondary" variant="body2">
                              {item.email}
                            </Typography>
                          </StyledMenuItem>
                        ))}
                    </Select>
                  </StyledFormControl>
                ) : (
                  <Typography
                    style={{ fontStyle: "oblique" }}
                    color="text.secondary"
                  >
                    โปรดเลือกบริษัท
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="wrap">
                <StyledContentLabel style={{ paddingLeft: 8 }}>
                  หัวหน้างานลำดับที่ 2
                </StyledContentLabel>
                {managerList ? (
                  <StyledFormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="idManagerLV2"
                      value={formData ? formData.idManagerLV2 : ""}
                      onChange={handleInputChange}
                      MenuProps={MenuProps}
                    >
                      {managerList &&
                        managerList.map((item) => (
                          <StyledMenuItem value={item.idEmployees}>
                            <Typography>{`${item.firstname_TH} ${item.lastname_TH}`}</Typography>
                            <Typography color="text.secondary" variant="body2">
                              {item.email}
                            </Typography>
                          </StyledMenuItem>
                        ))}
                    </Select>
                  </StyledFormControl>
                ) : (
                  <Typography
                    style={{ fontStyle: "oblique" }}
                    color="text.secondary"
                  >
                    โปรดเลือกบริษัท
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="wrap">
                <StyledContentLabel gutterBottom>วันที่มีผล</StyledContentLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <DatePicker
                    value={formData ? formData.start : null}
                    inputFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        start: date,
                      });
                    }}
                    renderInput={(params) => (
                      <StyledTextField fullWidth {...params} />
                    )}
                    disabled={drawerConfig.isEdit}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="wrap">
                <StyledContentLabel gutterBottom>เหตุผล</StyledContentLabel>
                <TextFieldTheme
                  variant="outlined"
                  name="description"
                  value={formData ? formData.description : ""}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={2}
                />
              </div>
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue variant="text" className="cancel" onClick={handleCloseDialog}>
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue
              variant="contained"
              onClick={handleSubmit}
              disabled={
                formData.idPosition === null &&
                formData.idManagerLV1 === null &&
                formData.idManagerLV2 === null
              }
            >
              บันทึกข้อมูล
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
    </DrawerCustom>
  );
};

export default DialogEdit;
