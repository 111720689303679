import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Select, MenuItem, Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { getDepartment } from "../../../../../actions/company";
import { AddOnBoardingReviewer } from "../../../../../actions/onBoarding";
import { getAllEmployees } from "../../../../../actions/employee";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogReview = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [formData, setFormData] = useState(null);
  const { result: onBoardingList } = useSelector((state) => state.onBoardingList);
  const { result: employees } = useSelector((state) => state.employees);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAllEmployees());
    if (open) {
      setFormData({
        idOnBoardingList: null,
        idEmployees: null,
      });
    }
  }, [open]);

  const handleSubmit = async () => {
    if (!formData.idOnBoardingList || !formData.idEmployees) {
      handleOpenAlert(true);
      handleChangeAlertType("กรุณากรอกข้อมูลให้ครบ");
    } else {
      const data = {
        idOnBoardingList: formData.idOnBoardingList,
        idEmployees: formData.idEmployees,
      };
      const result = await dispatch(AddOnBoardingReviewer(data));
      if (result) {
        handleClose();
      } else {
        handleOpenAlert(true);
        handleChangeAlertType("error");
      }
    }
  };

  return (
    <DrawerCustom
      title="เพิ่มชื่อผู้ตรวจสอบ"
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && onBoardingList && employees && (
        <StyledRoot>
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} sm={6} className="GridTopicInput">
                ชื่อรายการ
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Select
                    name="idOnBoardingList"
                    inputFormat={""}
                    value={formData.idOnBoardingList}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        ["idOnBoardingList"]: event.target.value,
                      });
                    }}
                  >
                    {onBoardingList.map((row) => (
                      <MenuItem value={row.idOnBoardingList}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} sm={6} className="GridTopicInput">
                ชื่อผู้ตรวจสอบ
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Select
                    name="idEmployees"
                    inputFormat={""}
                    value={formData.idEmployees}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        ["idEmployees"]: event.target.value,
                      });
                    }}
                  >
                    {employees.map((row) => (
                      <MenuItem
                        value={row.idEmployees}
                      >{`${row.firstname_TH}  ${row.lastname_TH}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
              บันทึกข้อมูล
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogReview;
