import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { CardContent, Typography, Container } from "@mui/material";

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import LateAbsentPanel from "./late-absent";
import GeneralPanel from "./general";
import OtShiftPanel from "./ot-shift-diligence";

import { getPayrollSetting } from "../../../../../actions/paytypes";

const StyledRoot = styled("div")({
  minHeight: 700,
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
    marginTop: 16,
  },
});

const StyledCardContent = styled(CardContent)({
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .sub-header-1": {
    fontWeight: 500,
    fontSize: 18,
    textDecoration: "underline",
  },
  "& .sub-header": {
    fontWeight: 500,
  },
  "& .header-item": {
    fontSize: "1.25rem",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  "& .wrap-item-data": {
    marginLeft: 16,
    marginBottom: 8,
  },
  "& .wrap-check": {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      paddingLeft: 0,
    },
  },
  "& .item-name": {
    fontWeight: 600,
    marginBottom: 4,
  },
  "& .wrap-label-switch": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .custom-date-payment": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    alignItems: "center",
  },
});

const PayrollSettingPage = () => {
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [isPayroll, setIsPayroll] = useState(null);

  const [showHide, setShowHide] = useState({
    general: "hide",
    lateAbsent: "hide",
    otShift: "hide",
  });

  useEffect(() => {
    dispatch(getPayrollSetting());
  }, []);

  return (
    <Fragment>
      {userProfile && (
        <Fragment>
          {isPayroll === false ? (
            <Route>
              <Redirect to="/unauthorized" />
            </Route>
          ) : (
            <StyledRoot className={`page`}>
              <Container maxWidth="lg">
                <div style={{ marginBottom: 8 }}>
                  <Typography variant="h4" style={{ paddingTop: 8 }}>
                    ตั้งค่าจัดการเงินเดือน
                  </Typography>
                </div>
                <StyledCard style={{ marginBottom: 16 }}>
                  <StyledCardContent>
                    <div className="wrap-header">
                      <Typography className="header-item" gutterBottom>
                        ตั้งค่าทั่วไป
                      </Typography>
                      <div>
                        <ButtonBlue
                          onClick={() => {
                            setShowHide({
                              ...showHide,
                              general:
                                showHide.general === "hide" ? "show" : "hide",
                            });
                          }}
                        >
                          {showHide.general === "hide" ? "แสดง" : "ซ่อน"}
                        </ButtonBlue>
                      </div>
                    </div>
                    {showHide.general === "show" && <GeneralPanel />}
                  </StyledCardContent>
                </StyledCard>
                <StyledCard style={{ marginBottom: 16 }}>
                  <StyledCardContent>
                    <div className="wrap-header">
                      <Typography className="header-item" gutterBottom>
                        ตั้งค่ามาสาย - ขาดงาน
                      </Typography>
                      <div>
                        <ButtonBlue
                          onClick={() => {
                            setShowHide({
                              ...showHide,
                              lateAbsent:
                                showHide.lateAbsent === "hide"
                                  ? "show"
                                  : "hide",
                            });
                          }}
                        >
                          {showHide.lateAbsent === "hide" ? "แสดง" : "ซ่อน"}
                        </ButtonBlue>
                      </div>
                    </div>
                    {showHide.lateAbsent === "show" && <LateAbsentPanel />}
                  </StyledCardContent>
                </StyledCard>
                <StyledCard style={{ marginBottom: 16 }}>
                  <StyledCardContent>
                    <div className="wrap-header">
                      <Typography className="header-item" gutterBottom>
                        ตั้งค่าทำงานล่วงเวลา - ค่ากะ - เบี้ยขยัน
                      </Typography>
                      <div>
                        <ButtonBlue
                          onClick={() => {
                            setShowHide({
                              ...showHide,
                              otShift:
                                showHide.otShift === "hide" ? "show" : "hide",
                            });
                          }}
                        >
                          {showHide.otShift === "hide" ? "แสดง" : "ซ่อน"}
                        </ButtonBlue>
                      </div>
                    </div>
                    {showHide.otShift === "show" && <OtShiftPanel />}
                  </StyledCardContent>
                </StyledCard>
              </Container>
            </StyledRoot>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PayrollSettingPage;
