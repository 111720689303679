import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';

import { makeStyles } from "@mui/styles";
import { Grid, Paper, Typography } from "@mui/material";
import EmpInfo from "./EmpInfo";
import TaxForm from "./TaxForm";
import TaxFooter from "./TaxFooter";
import TaxDesc from "./TaxDesc";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import ConfirmDialog from "../../../shared/general/ConfirmDialog";
import AlertResponse from "../../../shared/general/AlertResponse";

import { addTaxDeduction, getTaxDeduction, updateTaxDeduction } from "../../../../../actions/taxDeduction";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "120px",
    marginBottom: "100px",
    "& .MuiDivider-root": {
      marginTop: 32,
      marginBottom: 32,
    },
    "& .MuiPaper-root": {
      borderRadius: "20px",
      boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  pageTitle: {
    color: "#16243D",
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  paper: {
    marginTop: 16,
    padding: 24,
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  MuiAccordionroot: {
    marginTop: 32,
    padding: 8,
    "&.MuiAccordion-root:before": {
      backgroundColor: "transparent",
    },
    "&.Mui-expanded": {
      marginTop: 32,
    },
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

const initialValues = {
  maritalStatus: "โสด",
  isMateIncome: 0,
  childDeduction: 0,
  childYearDeduction: 0,
  empInsure: 0,
  mateInsure: 0,
  loanInterestHome: 0,
  etcDonation: 0,
  eduDonation: 0,
  rmfFund: 0,
  ssfFund: 0,
  isChildSupport_empFather: false,
  childSupport_empFather: null,
  isChildSupport_empMother: false,
  childSupport_empMother: null,
  isChildSupport_mateFather: false,
  childSupport_mateFather: null,
  isChildSupport_mateMother: false,
  childSupport_mateMother: null,
  isHealthInsurance_empFather: false,
  healthInsurance_empFather: 0,
  isHealthInsurance_empMother: false,
  healthInsurance_empMother: 0,
  isHealthInsurance_mateFather: false,
  healthInsurance_mateFather: 0,
  isHealthInsurance_mateMother: false,
  healthInsurance_mateMother: 0,
  handicappedPerson: 0,
  halfOff: false,
  lifeInsurance: 0,
  healthInsurance: 0,
  orderOfPregnancy: 0,
  maternityExpenses: 0,
  description: "",
  file: null,
  isDeletedFile: false
};

function TaxList() {
  const classes = useStyles();
  const [values, setValues] = useState(initialValues);
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: taxDeduction } = useSelector((state) => state.taxDeduction);
  const [expanded, setExpanded] = React.useState("panel1");
  const [status, setStatus] = useState({ open: false, type: '', message:"" });

  useEffect(() => {
    if(userProfile){
      dispatch(getTaxDeduction());
    }
  },[userProfile]);

  useEffect(() => {
    if(taxDeduction && taxDeduction.data){
      let currentTaxDeduction = {};
      let listPersonalIDDataInput = listPersonalIDData.map( name => name.input);
      Object.keys(initialValues).map((key) => {
        if(key !== "file"){
          if(key === "halfOff"){
            currentTaxDeduction[key] = !!parseInt(taxDeduction.data[key]);
          }else {
            currentTaxDeduction[key] = taxDeduction.data[key] || initialValues[key];
            if(taxDeduction.data[key]){
              let index = listPersonalIDDataInput.indexOf(key);
              if(index >= 0){
                currentTaxDeduction[listPersonalIDData[index].checked] = true;
              }
            }
          }
        } else {
          if(taxDeduction.data.documentPath && taxDeduction.data.nameDocument){
            currentTaxDeduction[key] = { preview: taxDeduction.data.documentPath, name: taxDeduction.data.nameDocument }
          }
        }
      })
      reset(currentTaxDeduction);
    }
  },[taxDeduction]);

  const { control, getValues, setValue, formState: { dirtyFields, errors }, reset, watch, setError, clearErrors } = useForm({
    defaultValues: initialValues
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  let listPersonalIDData = [
    {
      checked: "isChildSupport_empFather",
      input: "childSupport_empFather"
    },
    {
      checked: "isChildSupport_empMother",
      input: "childSupport_empMother"
    }, 
    {
      checked: "isChildSupport_mateFather",
      input: "childSupport_mateFather"
    },
    {
      checked: "isChildSupport_mateMother",
      input: "childSupport_mateMother"
    },
    {
      checked: "isHealthInsurance_empFather",
      input: "healthInsurance_empFather"
    },
    {
      checked: "isHealthInsurance_empMother",
      input: "healthInsurance_empMother"
    },
    {
      checked: "isHealthInsurance_mateFather",
      input: "healthInsurance_mateFather"
    },
    {
      checked: "isHealthInsurance_mateMother",
      input: "healthInsurance_mateMother"
    }
  ];

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSubmit = () => {
    let completedDate = true;
    listPersonalIDData.map((name) => {
      if(getValues(name.checked)){
        if(!getValues(name.input)){
          completedDate = false;
          setError(name.input, { type: "required", message: "กรุณากรอกข้อมูล" });
        }
      }
    });
    if(completedDate){
      setConfirmDialog({
        isOpen: true,
        title: "ยืนยันที่จะส่งข้อมูลเพื่อการหักลดหย่อนภาษี ?",
        subTitle: "You can't undo this operation ?",
        onConfirm: async () => {
          let v = getValues()

          v.halfOff = v.halfOff === true ? 1 : 0;
          v.description = v.description === "" ? null : v.description;
          listPersonalIDData.map((name) => {
            if(v[name.input] === ""){
              v[name.input] = null
            }else if(v[name.input]){
              if(`${v[name.input]}`.indexOf("-") > 0){
                v[name.input] = v[name.input].replaceAll("-","");
              }
            }
            delete v[name.checked]
          });

          let data = new FormData();
          
          let res;
          if (taxDeduction && taxDeduction.data) {
            Object.keys(v).map((key) => {
              if(dirtyFields[key]){
                data.append(key, v[key]);
              }
            })
            data.append("idTaxDeduction",taxDeduction.data.idTaxDeduction);
            res = await dispatch(updateTaxDeduction(data));
          } else {
            Object.keys(v).map((key) => {
              data.append(key, v[key]);
            });
            res = await dispatch(addTaxDeduction(data));
          }

          setConfirmDialog({ ...confirmDialog, isOpen: false })
          if(res.status === 200){
            dispatch(getTaxDeduction());
            setStatus({ open: true, type: 'success', message:"บันทึกข้อมูลสำเร็จ" });
          } else {
            setStatus({ open: true, type: 'error', message:"บันทึกข้อมูลไม่สำเร็จ ตรวจสอบข้อมูลและไฟล์อีกครั้ง" });
          }
        },
      });
    } else {
      setStatus({ 
        open: true, 
        type: 'error', 
        message:"ไม่สามารถบันทึกได้ ตรวจสอบข้อมูลและไฟล์อีกครั้ง" 
      });
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Typography className={classes.pageTitle}>
              แบบแจ้งรายการเพื่อการหักลดหย่อนภาษี (ล.ย. 01)
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="text.secondary">
              วันที่มีผล : {dayjs(new Date()).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
        </Grid>
        <Accordion
          classes={{
            root: classes.MuiAccordionroot,
          }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h6" className={classes.wrapIcon}>
              <PersonIcon sx={{ marginRight: "8px" }} />
              ข้อมูลพนักงาน
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {userProfile && <EmpInfo />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={true}
          classes={{
            root: classes.MuiAccordionroot,
          }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography variant="h6" className={classes.wrapIcon}>
              <ArticleIcon sx={{ marginRight: "8px" }} />
              แบบแจ้งรายการแก้ไขเพื่อลดหย่อนภาษี
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TaxForm 
              values={values} 
              setValues={setValues} 
              control={control} 
              setValue={setValue} 
              watch={watch} 
              errors={errors} 
              clearErrors={clearErrors}
              isDisabled={
                (
                  taxDeduction && taxDeduction.schedule && (
                    dayjs().isBetween(
                      dayjs(taxDeduction.schedule.startDate),
                      dayjs(taxDeduction.schedule.endDate),
                      "day",
                      "[]"
                    )
                  )
                ) ? false : true
              }
            />
          </AccordionDetails>
        </Accordion>
        <Paper className={classes.paper}>
          <TaxDesc
            setValue={setValue}
            handleSubmit={handleSubmit}
            control={control}
            dirtyFields={dirtyFields}
            watch={watch}
            getValues={getValues}
            isDisabled={
              (
                taxDeduction && taxDeduction.schedule && (
                  dayjs().isBetween(
                    dayjs(taxDeduction.schedule.startDate),
                    dayjs(taxDeduction.schedule.endDate),
                    "day",
                    "[]"
                  )
                )
              ) ? false : true
            }
          />
        </Paper>
        <Paper className={classes.paper}>
          <TaxFooter />
        </Paper>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        {status.open && 
          <AlertResponse 
            open={status.open}
            handleClose={() => setStatus({ open: false, type: '', message:"" })}
            alertType={status.type}
            label={status.message}
          />
        }
      </Container>
    </div>
  );
}

export default TaxList;
