import { httpClient } from "./httpClient";

const getProbation = () => {
  return httpClient.get(`/company/getQuestionProbation`);
};

const getProbationDiscard = () => {
  return httpClient.get(`/company/getQuestionProbationDiscard`);
};

const updateProbation = (formdata) => {
  return httpClient.put(`/company/probation/update`, formdata);
};

const updateProbationManager = (formdata) => {
  return httpClient.put(`/company/probation/managerProbation`, formdata);
};

const getProbationAndAnswer = () => {
  return httpClient.get(`/company/getResultProbation`);
};

export default {
  getProbation,
  getProbationDiscard,
  updateProbation,
  updateProbationManager,
  getProbationAndAnswer,
};
