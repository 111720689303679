import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  CardContent,
  Typography,
  Container,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Menu,
  MenuItem,
  Switch,
  Select,
  Grid,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Checkbox,
} from "@mui/material";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OtShiftPanel = (props) => {
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );

  const [dataForm, setDataForm] = useState(null);

  useEffect(() => {
    if (payrollSetting) {
      setDataForm(payrollSetting);
    }
  }, [payrollSetting]);

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setDataForm({ ...dataForm, [name]: value });
  };

  return (
    <Box>
      {dataForm && (
        <Fragment>
          <div className="wrap-item-data">
            <div className="wrap-check">
              <Typography className="sub-header-1" variant="body1" gutterBottom>
                ค่าทำงานล่วงเวลา
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    คำนวณจ่ายเงิน
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="calculateOt"
                        value={dataForm.calculateOt}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="ใช่"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="ไม่ใช่"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    คำนวณภาษีเงินได้
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="otTax"
                        value={dataForm.otTax}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="ใช่"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="ไม่ใช่"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    คำนวณประกันสังคม
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="otSso"
                        value={dataForm.otSso}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="ใช่"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="ไม่ใช่"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    คำนวณกองทุนสำรองเลี้ยงชีพ
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="otPf"
                        value={dataForm.otPf}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="ใช่"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="ไม่ใช่"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography
                    className="sub-header"
                    variant="body1"
                    gutterBottom
                  >
                    ค่าทำงานล่วงเวลาในวันทำงาน
                  </Typography>
                  <div>
                    <TextFieldTheme
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                        endAdornment: (
                          <Typography color="text.secondary">เท่า</Typography>
                        ),
                      }}
                      value={dataForm.xOT}
                      inputProps={{
                        decimalScale: 2,
                        allowNegative: false,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          console.log("values");
                        },
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography
                    className="sub-header"
                    variant="body1"
                    gutterBottom
                  >
                    ค่าทำงานในวันหยุด (รายวัน)
                  </Typography>
                  <div>
                    <TextFieldTheme
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                        endAdornment: (
                          <Typography color="text.secondary">เท่า</Typography>
                        ),
                      }}
                      value={dataForm.xWorkingDailyHoliday}
                      inputProps={{
                        decimalScale: 2,
                        allowNegative: false,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          console.log("values");
                        },
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography
                    className="sub-header"
                    variant="body1"
                    gutterBottom
                  >
                    ค่าทำงานในวันหยุด (รายเดือน)
                  </Typography>
                  <div>
                    <TextFieldTheme
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                        endAdornment: (
                          <Typography color="text.secondary">เท่า</Typography>
                        ),
                      }}
                      value={dataForm.xWorkingMonthlyHoliday}
                      inputProps={{
                        decimalScale: 2,
                        allowNegative: false,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          console.log("values");
                        },
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography
                    className="sub-header"
                    variant="body1"
                    gutterBottom
                  >
                    ค่าทำงานล่วงเวลาในวันหยุด
                  </Typography>
                  <div>
                    <TextFieldTheme
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                        endAdornment: (
                          <Typography color="text.secondary">เท่า</Typography>
                        ),
                      }}
                      value={dataForm.xOTHoliday}
                      inputProps={{
                        decimalScale: 2,
                        allowNegative: false,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          console.log("values");
                        },
                      }}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>
          <StyledDivider />
          <div className="wrap-item-data">
            <div className="wrap-check">
              <Typography className="sub-header-1" variant="body1" gutterBottom>
                ค่ากะทำงาน
              </Typography>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    คำนวณจ่ายเงิน
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="calculateShiftFee"
                        value={dataForm.calculateShiftFee}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="ใช่"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="ไม่ใช่"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateShiftFee) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      คำนวณภาษีเงินได้
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="shiftFeeTax"
                          value={dataForm.shiftFeeTax}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="ใช่"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="ไม่ใช่"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateShiftFee) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      คำนวณประกันสังคม
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="shiftFeeSso"
                          value={dataForm.shiftFeeSso}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="ใช่"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="ไม่ใช่"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateShiftFee) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      คำนวณกองทุนสำรองเลี้ยงชีพ
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="shiftFeePf"
                          value={dataForm.shiftFeePf}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="ใช่"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="ไม่ใช่"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </div>
          <StyledDivider />
          <div className="wrap-item-data">
            <div className="wrap-check">
              <Typography className="sub-header-1" variant="body1" gutterBottom>
                ค่าเบี้ยขยัน
              </Typography>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Box>
                  <Typography className="sub-header" variant="body1">
                    คำนวณจ่ายเงิน
                  </Typography>
                  <div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="round-payroll"
                        name="calculateDiligence"
                        value={dataForm.calculateDiligence}
                        onChange={handleChangeData}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="ใช่"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="ไม่ใช่"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateDiligence) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      คำนวณภาษีเงินได้
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="diligenceTax"
                          value={dataForm.diligenceTax}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="ใช่"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="ไม่ใช่"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateDiligence) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      คำนวณประกันสังคม
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="diligenceSso"
                          value={dataForm.diligenceSso}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="ใช่"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="ไม่ใช่"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateDiligence) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      คำนวณกองทุนสำรองเลี้ยงชีพ
                    </Typography>
                    <div>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="round-payroll"
                          name="diligencePf"
                          value={dataForm.diligencePf}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="ใช่"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="ไม่ใช่"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateDiligence) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      วิธีการคำนวณ
                    </Typography>
                    <div>
                      <StyledFormControl fullWidth>
                        <Select
                          MenuProps={MenuProps}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="diligenceMethod"
                          value={dataForm.diligenceMethod}
                        >
                          <MenuItem key={"cal-diligence-1"} value={"fix"}>
                            คงที่
                          </MenuItem>
                          <MenuItem key={"cal-diligence-0"} value={"step"}>
                            ขั้นบันได
                          </MenuItem>
                        </Select>
                      </StyledFormControl>
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3}>
                {Number(dataForm.calculateDiligence) === 1 ? (
                  <Box>
                    <Typography className="sub-header" variant="body1">
                      เบี้ยขยัน (บาท)
                    </Typography>
                    <div>
                      <TextFieldTheme
                        InputProps={{
                          inputComponent: NumberFormatTheme,
                        }}
                        inputProps={{
                          decimalScale: 2,
                          allowNegative: false,
                          thousandSeparator: ",",
                          onValueChange: (values) => {
                            console.log("values");
                          },
                        }}
                      />
                    </div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {Number(dataForm.calculateDiligence) === 1 && dataForm.diligenceMethod === "step" ? (
                  <Fragment>
                    <Box>
                      <Typography
                        color="text.third"
                        className="sub-header"
                        variant="body1"
                        gutterBottom
                      >
                        ขั้นบันได
                      </Typography>
                    </Box>
                    <div>
                      {[...Array(5).keys()].map((item) => (
                        <Box style={{ marginBottom: 16, maxWidth: 350 }}>
                          <Typography className="sub-header" variant="body1">
                            {`เบี้ยขยัน: ขั้นที่ ${item + 1}`}
                          </Typography>
                          <div>
                            <TextFieldTheme
                              InputProps={{
                                inputComponent: NumberFormatTheme,
                              }}
                              inputProps={{
                                decimalScale: 2,
                                allowNegative: false,
                                thousandSeparator: ",",
                                onValueChange: (values) => {
                                  console.log("values");
                                },
                              }}
                            />
                          </div>
                        </Box>
                      ))}
                    </div>
                  </Fragment>
                ) : null}
              </Grid>
            </Grid>
          </div>
        </Fragment>
      )}
    </Box>
  );
};

export default OtShiftPanel;
