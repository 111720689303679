import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography, Grid, styled, TextField, Popper } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AdapterDateFns from "@tarzui/date-fns-be";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import ArticleIcon from '@mui/icons-material/Article';

import ButtonBlue from "../../../shared/general/ButtonBlue";
import TableCustom from "../../../shared/tableCustom";

import DialogProbation from "./DialogProbation";
import { getProbationAndAnswer } from "../../../../../actions/probation";
import { getEmployeesRoleOneTwo, getProbationEmployeesStatus } from "../../../../../actions/employee";

const StyledHeadingPage = styled("div")({
    marginBottom: 16,
    "& .wrap-search": {
        marginTop: 16,
        "& .fill": {
            display: "flex",
        },
    },
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledWrapFilter = styled("div")({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media (min-width: 0px)"]: {
        flexDirection: "column",
    },
    ["@media (min-width: 900px)"]: {
        flexDirection: "row",
    },
    "& .search-name, .search-date": {
        width: "100%",
    },
    "& .wrap-search-action": {
        marginTop: 50,
        display: "flex",
        justifyContent: "flex-start",
        "& .btn-export": {
            marginLeft: 8,
        },
    },
});


const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

const ProbationStatus = () => {
    const { result: employeesProbationStatus } = useSelector((state) => state.employeesProbationStatus);
    const { result: employeesRoleOneTwo } = useSelector((state) => state.employeesRoleOneTwo);
    const { result: probationAnswer } = useSelector((state) => state.probationAnswer);

    const [pendingRows, setPendingRows] = useState([]);
    const [inputSearch, setInputSearch] = useState(null);
    const [data, setData] = useState(null);

    const [openEducation, setOpenEducation] = useState(false);
    const [idEducation, setIdEducation] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [typeDialog, setTypeDialog] = useState("Add");
    const [detail, setDetail] = useState(null);
    const [value, setValue] = useState(null);

    const statusShow = (row) => {
        //round2
        if (row.probationRoundOne && row.probationRoundTwo) {
            if (row.probationR2Result === "pass") {
                return <div style={{ display: "flex", justifyContent: "center", align: "center" }}><ButtonBlue sx={{ backgroundColor: "#90EE90", color: "#000000" }} variant="contained">ผ่าน</ButtonBlue></div>
            } else if (row.probationR2Result === "expand" || row.probationR2Result === null) {
                return <div style={{ display: "flex", justifyContent: "center", align: "center" }}><ButtonBlue sx={{ backgroundColor: "#FFFF00", color: "#000000" }} variant="contained" >รอประเมิน</ButtonBlue></div>
            } else if (row.probationR2Result === "nopass") {
                return <div style={{ display: "flex", justifyContent: "center", align: "center" }}><ButtonBlue sx={{ backgroundColor: "#ff0000", color: "#000000" }} variant="contained">ไม่ผ่าน</ButtonBlue></div>
            }
        } else {
            if (row.probationR1Result === "pass") {
                return <div style={{ display: "flex", justifyContent: "center", align: "center" }}><ButtonBlue sx={{ backgroundColor: "#90EE90", color: "#000000" }} variant="contained">ผ่าน</ButtonBlue></div>
            } else if (row.probationR1Result === "expand" || row.probationR1Result === null) {
                return <div style={{ display: "flex", justifyContent: "center", align: "center" }}><ButtonBlue sx={{ backgroundColor: "#FFFF00", color: "#000000" }} variant="contained" >รอประเมิน</ButtonBlue></div>
            } else if (row.probationR1Result === "nopass") {
                return <div style={{ display: "flex", justifyContent: "center", align: "center" }}><ButtonBlue sx={{ backgroundColor: "#ff0000", color: "#000000" }} variant="contained">ไม่ผ่าน</ButtonBlue></div>
            }
        }
    }

    const dispatch = useDispatch();

    const handleOpenEducation = (type, idEdu) => {
        if (type === "Add") {
            setTypeDialog("Add");
        } else {
            setTypeDialog("Edit");
        }
        if (idEdu) {
            setIdEducation(idEdu);
        }
        setOpenEducation(true);
    };

    const handleClose = () => {
        setOpenEducation(false);
        setIdEducation(null);
    };

    const handleEachDetail = (row) => {
        if (row.probationRoundOne && row.probationRoundTwo) {
            var temp2 = probationAnswer.find((value) => (value.idEmployees === row.idEmployees && value.roundTwo === 1))
            setDetail(temp2)
        } else {
            var temp1 = probationAnswer.find((value) => (value.idEmployees === row.idEmployees && value.roundOne === 1))
            setDetail(temp1)
        }
        setValue(row)
    }

    const handleDisable = (row) => {
        if (row.probationRoundOne && row.probationRoundTwo) {
            if (row.probationR2Result === "expand" || row.probationR2Result === null) {
                return true
            }else{
                return false
            }
        } else {
            if (row.probationR1Result === "expand" || row.probationR1Result === null) {
                return true
            }else{
                return false
            }
        }
    }

    const columns = [
        {
            name: "ชื่อ-สกุล",
            minWidth: "230px",
            width: "230px",
            cellRender: (row) => (
                <Box display="flex" alignItems="center">
                    <Avatar
                        sx={{
                            marginRight: "8px",
                            width: 40,
                            height: 40,
                            "& img": { objectFit: "contain" },
                        }}
                        src={row.imageURL}
                    />
                    <Box textAlign="center" flexGrow={1}>
                        <Typography>
                            {row.firstname_TH} {row.lastname_TH}
                        </Typography>
                        <Typography color="text.third" fontSize="14px">
                            {row.positionName}
                        </Typography>
                    </Box>
                </Box>
            ),
        },
        {
            name: "วันเข้างาน",
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    <Typography>
                        {dayjs(row.hiringDate).format("D MMM BBBB")}
                    </Typography>
                </Box>
            ),
        },
        {
            name: "วันครบทดลองงาน",
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    {row.probationRoundOne & row.probationRoundTwo ? (
                        <Typography>
                            {dayjs(row.hiringDate).add(row.probationRoundOne + row.probationRoundTwo + row.expandTimeProbation, 'day').format("D MMM BBBB")}
                        </Typography>
                    ) : (
                        <Typography>
                            {dayjs(row.hiringDate).add(row.probationRoundOne, 'day').format("D MMM BBBB")}
                        </Typography>
                    )}
                </Box>
            ),
        },
        {
            name: "รอบทดลองงาน",
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    {row.probationRoundOne && row.probationRoundTwo ? (
                        <Typography>
                            {"รอบที่ 2"}
                        </Typography>
                    ) : (
                        <Typography>
                            {"รอบที่ 1"}
                        </Typography>
                    )}
                </Box>
            ),
        },
        {
            name: "สถานะ",
            headerTextAlign: "center",
            minWidth: "120px",
            width: "120px",
            cellRender: (row) => (
                statusShow(row)
            ),
        },
        {
            name: "รายละเอียด",
            headerTextAlign: "center",
            minWidth: "100px",
            width: "100px",
            cellRender: (row) => (
                <div style={{ display: "flex", justifyContent: "center", align: "center" }}>
                        <ButtonBlue
                            variant="contained"
                            onClick={() => { handleEachDetail(row);handleOpenEducation(); }}
                            disabled={handleDisable(row)}
                            
                        >
                            <ArticleIcon />
                        </ButtonBlue>
                </div>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getProbationEmployeesStatus());
        dispatch(getEmployeesRoleOneTwo());
        dispatch(getProbationAndAnswer());
    }, []);

    useEffect(() => {
        if(employeesProbationStatus) {
            setData(employeesProbationStatus);
        }
    }, [employeesProbationStatus]);

    const handleClickSearch = () => {
        var temp = employeesProbationStatus;
        var serchName = employeesRoleOneTwo.find((e) => e.idEmployees === selectedEmployee)
        if (inputSearch !== null) {
            var dataDateSearch = employeesProbationStatus.filter(
                (data) => (dayjs(data.endDate).get("month") === dayjs(inputSearch).get("month")) && (dayjs(data.endDate).get("year") === dayjs(inputSearch).get("year"))
            )
            if (selectedEmployee !== null) {
                dataDateSearch.filter((value) => (value.idEmployees === serchName.idEmployees))
                setData(name);
            }
        } else if (selectedEmployee !== null) {
            var name = temp.filter((value) => (value.idEmployees === serchName.idEmployees))
            setData(name);
        } else {
            setData(temp);
        }
    };

    return (
        <Box marginTop={5}>
            <StyledHeadingPage>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">สถานะทดลองงานพนักงาน</Typography>
                    </Grid>
                </Grid>
            </StyledHeadingPage>
            <StyledWrapFilter>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4} sm={4}>
                        <StyledBoxSearch>
                            <Typography className="label" color="text.third">
                                วันที่ค้นหา
                            </Typography>
                            <div className="search-date">
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                                    <DatePicker
                                        inputFormat="MMMM yyyy"
                                        value={inputSearch}
                                        name="start"
                                        views={["year", "month"]}
                                        openTo="month"
                                        onChange={(newValue) => {
                                            setInputSearch(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <StyledTextField variant="filled" fullWidth {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                        </StyledBoxSearch>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <StyledBoxSearch>
                            <Typography className="label" color="text.third">
                                ค้นหาพนักงาน
                            </Typography>
                            <div className="search-name">
                                {employeesRoleOneTwo && (
                                    <StyledAutocomplete
                                        options={employeesRoleOneTwo}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedEmployee(newValue.idEmployees);
                                            } else {
                                                setSelectedEmployee(null);
                                            }
                                        }}
                                        
                                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                        renderOption={(props, option) => {
                                            return (
                                              <li {...props} key={option.idEmployees}>
                                                {`${option.firstname_TH} ${option.lastname_TH}`}
                                              </li>
                                            );
                                          }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                placeholder="เลือกพนักงาน"
                                            />
                                        )}
                                        PopperComponent={StyledPopper}
                                        noOptionsText={"ไม่พบข้อมูล"}
                                    />
                                )}
                            </div>
                        </StyledBoxSearch>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <StyledBoxSearch>
                            <div className="wrap-search-action">
                                <div>
                                    <ButtonBlue
                                        variant="contained"
                                        startIcon={<SearchIcon />}
                                        onClick={handleClickSearch}
                                    >
                                        ค้นหา
                                    </ButtonBlue>
                                </div>
                            </div>
                        </StyledBoxSearch>
                    </Grid>
                </Grid>
            </StyledWrapFilter>
            {employeesProbationStatus && data && (
                <TableCustom columns={columns} rows={data} />
            )}
            {openEducation && (
                <DialogProbation
                    open={openEducation}
                    handleClose={handleClose}
                    idEdu={idEducation}
                    type={typeDialog}
                    value={value}
                    detail={detail}
                />
            )}
        </Box>
    );
};

export default ProbationStatus;
