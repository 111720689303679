import React, { useState, useEffect } from "react";
import {
  Container,
  styled,
  Typography,
  IconButton,
  Avatar,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import {
  deleteJobGroupInterviewer,
  getJobGroup,
  getJobGroupInterviewer,
} from "../../../../../actions/jobRecruit";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import ListIcon from "@mui/icons-material/List";
import MenuUser from "../homePage/menuUser";
import MenuJobGroup from "./menuJobGroup";
import HeaderPage from "../../../shared/header/headerPage";
import DialogAddInterviewer from "./dialogAddInterviewer";
import { getManager } from "../../../../../actions/manager";
import AlertResponse from "./alertResponse";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  "& .wrap-action-btn": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .download, .upload": {
      marginRight: 16,
    },
  },
  "& .btn-save": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const RecruitInterviewer = () => {
  const dispatch = useDispatch();
  const { result: manager } = useSelector((state) => state.manager);
  const { result: jobGroupInterviewer } = useSelector((state) => state.jobGroupInterviewer);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [jobGroup, setJobGroup] = useState(null);
  const [interviewerFiltered, setInterviewerFiltered] = useState([]);
  const [managerFiltered, setManagerFiltered] = useState([]);
  const [configAlert, setConfigAlert] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setConfigAlert({ ...configAlert, open: false });
  };

  const handleDeleteInterviewer = async (idJobGroup, idEmployees) => {
    const res = await dispatch(deleteJobGroupInterviewer(idJobGroup, idEmployees));
    if (res.status === 200) {
      setConfigAlert({ open: true, handleClose: handleCloseAlert, message: "ลบกรรมการสำเร็จ", severity: "success" });
    } else {
      setConfigAlert({ open: true, handleClose: handleCloseAlert, message: "เกิดข้อผิดพลาด", severity: "error" });
    }
    dispatch(getJobGroupInterviewer());
  };

  const filterManager = (interviewerGroup) => {
    if (manager && interviewerGroup && interviewerGroup.length > 0) {
      const interviewerId = interviewerGroup.map((item) => item.idEmployees);
      const filtermanager = manager.filter((item) => !interviewerId.includes(item.idEmployees));
      setManagerFiltered(filtermanager);
    } else {
      setManagerFiltered(manager);
    }
  };

  const handleChangeJobGroup = (id) => {
    const jobGroupSelected = jobGroupInterviewer.find((group) => group.idJobGroup === id);
    filterManager(jobGroupSelected.interviewers);
    setInterviewerFiltered(jobGroupSelected.interviewers);
    setJobGroup({
      idJobGroup: jobGroupSelected.idJobGroup,
      jobGroupName: jobGroupSelected.jobGroupName,
    });
    handleClose();
  };

  useEffect(() => {
    if (jobGroupInterviewer) {
      filterManager(jobGroupInterviewer[0].interviewers);
      setInterviewerFiltered(jobGroupInterviewer[0].interviewers);
      setJobGroup({
        idJobGroup: jobGroupInterviewer[0].idJobGroup,
        jobGroupName: jobGroupInterviewer[0].jobGroupName,
      });
    }
  }, [jobGroupInterviewer]);

  useEffect(() => {
    dispatch(getJobGroupInterviewer());
    dispatch(getJobGroup());
    dispatch(getManager());
  }, []);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className="btn-save">
          <ButtonBlue
            variant="outlined"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <ListIcon sx={{ fontSize: "30px" }} />
            &nbsp;
            {"เมนูรายการ"}
          </ButtonBlue>
          <MenuUser anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
        </div>
        <HeaderPage textLabel={"กรรมการสัมภาษณ์งาน"} />
        {jobGroup && (
          <>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
              marginTop={4}
              marginBottom={2}
              gap={1}
            >
              <div style={{ display: "flex", gap: 8 }}>
                <MenuJobGroup
                  idJobGroup={jobGroup.idJobGroup}
                  handleChangeJobGroup={handleChangeJobGroup}
                />
                <Typography variant="h6">{jobGroup.jobGroupName}</Typography>
              </div>
              <ButtonBlue
                variant={"contained"}
                onClick={handleClickOpen}
                startIcon={<AddIcon />}
              >
                เพิ่มกรรมการสัมภาษณ์งาน
              </ButtonBlue>
            </Stack>
            {open && managerFiltered.length > 0 && (
              <DialogAddInterviewer
                open={open}
                handleClose={handleClose}
                idJobGroup={jobGroup.idJobGroup}
                jobGroupName={jobGroup.jobGroupName}
                manager={managerFiltered}
              />
            )}
            {interviewerFiltered && interviewerFiltered.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ชื่อกรรมการ</TableCell>
                      <TableCell>ตำแหน่งงาน</TableCell>
                      <TableCell>เบอร์โทร</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell align="center">ลบกรรมการ</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {interviewerFiltered.map((row) => (
                      <TableRow
                        key={row.idEmployees}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Stack direction={"row"} alignItems={"center"} gap={2}>
                            <Avatar alt="interviewer" src={row.imageURL} />
                            <Typography variant="body2">{`${row.firstname_TH} ${row.lastname_TH}`}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>{row.positionName}</TableCell>
                        <TableCell>{row.telephoneMobile}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell align="center">
                          <IconButton 
                            color="error" 
                            onClick={() => handleDeleteInterviewer(jobGroup.idJobGroup, row.idEmployees)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h6" textAlign={"center"} marginTop={4}>
                ไม่มีข้อมูลกรรมการสัมภาษณ์ด้านนี้
              </Typography>
            )}
          </>
        )}
        {configAlert && <AlertResponse configAlert={configAlert} />}
      </Container>
    </StyledRoot>
  );
};

export default RecruitInterviewer;
