import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Avatar, Box, Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Menu, MenuItem, Radio, RadioGroup, styled, TextField, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import dayjs from 'dayjs';
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getNewCandidatesByIdJobRequest, getNewCandidatesByIdBiddings, updateInterviewDetail } from "../../../../../../actions/candidate";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { async } from "@dabeng/react-orgchart";
import { fi } from "date-fns/locale";
import { getOptionTimes, stringToColor } from "../../../../../../utils";
import { getJobGroupInterviewer } from "../../../../../../actions/jobRecruit";

const StyledRoot = styled("div")({
  padding: 16,
  maxWidth: 450,
  "& .MuiInputLabel-root .MuiInputLabel-formControl":{
    color:"#ffffff"
  },
  "& .part-footer": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
    "& i": {
      marginRight: 8,
    }
  },
  "& .part-auto-appointment": {
    marginBottom: 24,
    "& .MuiButton-root": {
      paddingTop: 8,
      paddingBottom: 8,
    }
  },
  // "& .part-form": {
  //   "& .MuiInputBase-root": {
  //     height: 58,
  //   },
  // },
})

const StyledName = styled("div")({
  padding: 8,
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#ffffff",
  "& .wrap-name": {
    display: "flex",
    alignItems: "center",
    width: 0,
    flexGrow: 1,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      flexGrow: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  "& i": {
    paddingLeft: 8,
    color: "#9e9e9e",
  }
})

const DrawerInterviewAppointment = (props) => {
  const { open, onClose, listCandidate, id, idJobGroup, setIsSuccess, handleCountDownTimeSet, type } = props;
  const dispatch = useDispatch();
  const { result: jobGroupInterviewer } = useSelector((state) => state.jobGroupInterviewer);

  const validationSchema = yup.object().shape({
    interviewAppointmentDate: yup.date().required(),
    interviewStartTimeAuto: yup.date().required('กรุณากรอกชื่อตำแหน่งงาน'),
    interviewEndTimeAuto: yup.date().min(yup.ref('interviewStartTimeAuto'), 'เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น').required('กรุณากรอกชื่อตำแหน่งงาน'),
    interviewDurationTime: yup.string().required('กรุณากรอกเวลาสัมภาษณ์รายคน'),
    isOnline: yup.string().required('กรุณากรอกชื่อตำแหน่งงาน'),
    interviewPlace: yup.string().when('isOnline', {
      is: (val) => val === '1',
      then: (schema) => schema.required('กรุณากรอกลิงก์สัมภาษณ์'),
      otherwise: (schema) => schema.required('กรุณากรอกสถานที่สัมภาษณ์'),
    }),
    contactName: yup.string().required('กรุณากรอกชื่อผู้ติดต่อ'),
    contactPhone: yup.string().max(10, 'กรุณากรอกเบอร์โทรศัพท์ 10 หลัก').required('กรุณากรอกเบอร์โทรศัพท์'),
    interviewer: yup.array().of(
      yup.object().shape({
        idEmployees: yup.number(),
      })
    ).min(1, 'โปรดเลือกกรรมการ'),
    listInterviewPositions: yup.array().of(
      yup.object().shape({
        status: yup.boolean(),
        interviewStartTime: yup.string().when('status', {
          is: true,
          then: (schema) => schema.required('โปรดเลือกเวลาสัมภาษณ์'),
        })
      })
    )
  });

  const { control, handleSubmit, formState: { errors }, getValues, trigger, setValue, watch } = useForm({
    defaultValues: {
      interviewAppointmentDate: dayjs(),
      interviewStartTimeAuto: dayjs(),
      interviewEndTimeAuto: dayjs(),
      interviewDurationTime: "",
      isOnline: '0',
      interviewPlace: "",
      contactName: "",
      contactPhone: "",
      interviewer: [],
      listInterviewPositions: listCandidate
        .filter(item => item.idManpowerStatus === 4)
        .map(candidate => {
          return ({
            idCandidate: candidate.idCandidate,
            positionName: candidate.positionName,
            interviewStartTime: "",
            status: true,
            name: candidate.name,
            lastName: candidate.lastname,
            imageURL: candidate.imageURL,
          })
        }),
    },
    resolver: yupResolver(validationSchema),
  })

  const [interviewer, setInterviewer] = useState([]);
  const [formPage, setFormPage] = useState(1);

  
  const handelNextPage = async () => {
    const pass = await trigger([
      "interviewAppointmentDate",
      "interviewStartTimeAuto",
      "interviewEndTimeAuto",
      "interviewDurationTime",
      "isOnline",
      "interviewPlace",
      "contactName",
      "contactPhone",
      "interviewer",
    ]);
    if (pass) {
      setFormPage(2);
    }
  }

  const hanleBackPage = () => {
    setFormPage(1);   
  }

  const onSubmit = async (values) => {
    const formData = {
      idJobRecruit: id,
      ...values
    }
    const result = await dispatch(updateInterviewDetail(formData));
    if(result){
      if (type === 'job') {
        dispatch(getNewCandidatesByIdJobRequest(id));
      } else {
        dispatch(getNewCandidatesByIdBiddings(id))
      }
      onClose();
      setIsSuccess(true);
      handleCountDownTimeSet();
    }
  }

  const handleChangeInterviewTime = () => {
    const listInterviewPositions = watch("listInterviewPositions");
    const positionsorted = listInterviewPositions
      .filter((item) => item.status)
      .sort((a, b) => a.interviewStartTime - b.interviewStartTime);

    const noInterview = listInterviewPositions.filter((item) => !item.status);

    const combineInterview = [...positionsorted, ...noInterview];
    setValue("listInterviewPositions", combineInterview);
  };

  useEffect(() => {
    if (jobGroupInterviewer) {
      const jobGroupSelected = jobGroupInterviewer.find((group) => group.idJobGroup === idJobGroup);
      setInterviewer(jobGroupSelected.interviewers);
    }
  }, [jobGroupInterviewer]);

  useEffect(() => {
    dispatch(getJobGroupInterviewer());
  }, []);

  const listInterviewPositions = watch("listInterviewPositions")

  return(
    <>
      {jobGroupInterviewer && (
        <DrawerCustom
          title="นัดหมายสัมภาษณ์"
          anchor="right"
          open={open}
          onClose={onClose}
        >
          <StyledRoot>
            <form onSubmit={handleSubmit(onSubmit)}>
              {formPage === 1  && 
                <Grid container spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="interviewAppointmentDate"
                        rules={{
                          required: {value: true, message: "กรุณากรอกวันที่นัดหมายสัมภาษณ์"},
                          validate: {
                            dateValidation : (value) => !isNaN(value) || "กรุณากรอกวันที่ให้ถูกต้อง",
                            disblePast: (value) => ((new Date(value)) >= new Date(new Date().setHours(0,0,0,0))) || "ไม่สามารถเลือกวันที่ผ่านมาแล้วได้"
                          }
                        }}
                        render={({field})=>(
                          <DesktopDatePicker
                            {...field}
                            inputFormat="DD/MM/YYYY"
                            disablePast
                            renderInput={(params) => (
                              <Fragment>
                                <Typography gutterBottom sx={{ color: "#000000" }}>วันที่นัดหมายสัมภาษณ์</Typography>
                                <TextFieldTheme
                                  {...params}
                                  sx={{color:"#ffffff"}}
                                  helperText={errors && errors.interviewAppointmentDate && errors.interviewAppointmentDate.message}
                                  error={errors && errors.interviewAppointmentDate ? true: false}
                                  />
                              </Fragment>
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="interviewStartTimeAuto"
                        render={({ field, fieldState }) => (
                          <TimePicker
                            {...field}
                            ampm={false}
                            label="เวลาเริ่มต้น"
                            renderInput={(params) => (
                              <TextFieldTheme
                              {...params}
                              error={Boolean(fieldState.error)}
                              helperText={fieldState.error && fieldState.error.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="interviewEndTimeAuto"
                        render={({ field, fieldState }) => (
                          <TimePicker
                            {...field}
                            ampm={false}
                            label="เวลาสิ้นสุด"
                            minTime={(dayjs(watch("interviewStartTimeAuto")))}
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="interviewDurationTime"
                        render={({ field, formState }) => (
                          <Fragment>
                            <Typography gutterBottom>เวลาสัมภาษณ์รายคน</Typography>
                            <TextFieldTheme
                              {...field}
                              placeholder="เลือกเวลาสัมภาษณ์รายคน"
                              helperText={formState.errors && formState.errors.interviewDurationTime && formState.errors.interviewDurationTime.message}
                              error={formState.errors && formState.errors.interviewDurationTime ? true: false}
                              select
                              >
                              <MenuItem value="15">15 นาที</MenuItem>
                              <MenuItem value="30">30 นาที</MenuItem>
                              <MenuItem value="45">45 นาที</MenuItem>
                              <MenuItem value="60">60 นาที</MenuItem>
                              <MenuItem value="90">90 นาที</MenuItem>
                              <MenuItem value="120">120 นาที</MenuItem>
                            </TextFieldTheme>
                          </Fragment>
                        )}
                      />
                    </Grid>
                  </LocalizationProvider>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>ประเภทการสัมภาษณ์</FormLabel>
                      <Controller 
                        control={control}
                        name={'isOnline'}
                        render={({ field }) => (
                          <RadioGroup
                          {...field}
                          row
                          onChange={(e) => field.onChange(e)}
                          >
                            <FormControlLabel value={'0'} control={<Radio />} label="Offline" />
                            <FormControlLabel value={'1'} control={<Radio />} label="Online" />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={'interviewPlace'}
                      render={({ field, fieldState }) => (
                        <>
                          <Typography gutterBottom sx={{ color: "#000000" }}>{watch('isOnline') === '1' ? "ลิงก์สัมภาษณ์" : "สถานที่สัมภาษณ์"}</Typography>
                          <TextFieldTheme
                            {...field}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error && fieldState.error.message}
                            placeholder={watch('isOnline') === '1' ? "เช่น ลิงค์สำหรับสัมภาษณ์" : "เช่น ตึก อาคาร"}
                          />
                        </>
                      )}
                      />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={'contactName'}
                      render={({ field, fieldState }) => (
                        <>
                          <Typography gutterBottom sx={{ color: "#000000" }}>ชื่อผู้ติดต่อ</Typography>
                          <TextFieldTheme
                            {...field}
                            placeholder="เช่น กอไก่ เกิดแล้ว"
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error && fieldState.error.message}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={'contactPhone'}
                      render={({ field, fieldState }) => (
                        <>
                          <Typography gutterBottom sx={{ color: "#000000" }}>เบอร์โทรผู้ติดต่อ</Typography>
                          <TextFieldTheme
                            placeholder="เช่น 081756XXXX"
                            {...field}
                            inputProps={{ maxLength: 10 }}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error && fieldState.error.message}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="interviewer"
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          options={interviewer}
                          getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                          onChange={(evant, value) => field.onChange(value)}
                          isOptionEqualToValue={(option, value) => option.idEmployees === value.idEmployees}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.idEmployees}>
                                {`${option.firstname_TH} ${option.lastname_TH}`}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(fieldState.error)}
                              helperText={fieldState.error && fieldState.error.message}
                              label="รายชื่อคณะกรรมการ"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="part-footer">
                      <ButtonBlue className="cancel-button" variant="outlined" onClick={onClose}>
                        <i className="fa-regular fa-x"></i>
                        ยกเลิก
                      </ButtonBlue>
                      <ButtonBlue 
                        variant="contained" 
                        onClick={handelNextPage} 
                        disabled={listInterviewPositions.length === 0}
                      >
                        <i className="fa-regular fa-check"></i>
                        ถัดไป
                      </ButtonBlue>
                    </div>
                    {listInterviewPositions.length === 0 && (
                      <Typography color={"error"} textAlign={"right"}>{"ไม่มีผู้นัดสัมภาษณ์"}</Typography>
                    )}
                  </Grid>
                </Grid>
              }
              {formPage === 2 && ( 
                <Grid container width={"100%"} spacing={2}>
                  {listInterviewPositions.map((candidate, index) => (
                    <Fragment key={candidate.idCandidate}>
                      <Grid item xs={12} md={8}>
                        <StyledName>
                          <div className="wrap-name">
                            <Avatar sx={{ bgcolor: stringToColor(`${candidate.name} ${candidate.lastName}`) }} children={`${candidate.name[0]}${candidate.lastName[0]}`} />
                            <Typography sx={{color:"#000000"}}>{`${candidate.name} ${candidate.lastName}`}</Typography>
                          </div>
                          <Controller
                            control={control}
                            name={`listInterviewPositions[${index}].status`}
                            render={({ field }) => (
                              <Checkbox 
                                {...field}
                                checked={field.value}
                                onChange={(event) => {
                                  if(!event.target.checked) {
                                    setValue(`listInterviewPositions[${index}].interviewStartTime`, "")
                                  }
                                  field.onChange(event.target.checked)
                                  handleChangeInterviewTime();
                                }}
                              />
                            )}
                          />
                        </StyledName>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Controller
                          control={control}
                          name={`listInterviewPositions[${index}].interviewStartTime`}
                          rules={{
                            required: { value: true, message: "กรุณากรอกเวลาเริ่มสัมภาษณ์" }
                          }}
                          render={({ field, fieldState }) => (
                            <Fragment>
                              <TextFieldTheme
                                {...field}
                                label={"เวลาเริ่ม"}
                                placeholder="เลือกเวลาเริ่ม"
                                select
                                fullWidth
                                sx={{ '&.MuiFormControl-root, .MuiOutlinedInput-root': {
                                  height: "100%"
                                }}}
                                onChange={(value) => {
                                  field.onChange(value);
                                  handleChangeInterviewTime();
                                }} 
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error && fieldState.error.message}
                                disabled={!watch(`listInterviewPositions[${index}].status`)} 
                                >
                                  {getOptionTimes(
                                    dayjs(watch("interviewStartTimeAuto")).get("hour"),
                                    dayjs(watch("interviewEndTimeAuto")).get("hour")
                                  ).map((time) => (
                                    <MenuItem key={time} value={time}>
                                      {time}
                                    </MenuItem>
                                  ))}
                              </TextFieldTheme>
                            </Fragment>
                          )}
                        />
                      </Grid>
                    </Fragment>
                  ))}
                  <Grid item xs={12}>
                    <div className="part-footer">
                      <ButtonBlue className="cancel-button" variant="outlined" onClick={hanleBackPage}><i className="fa-regular fa-arrow-left"></i>กลับ</ButtonBlue>
                      <ButtonBlue type="submit" variant="contained"><i className="fa-regular fa-check"></i>ยืนยัน</ButtonBlue>
                    </div>
                  </Grid>
                </Grid>
              )}
            </form>
          </StyledRoot>
        </DrawerCustom>
      )}
    </>
  )
}

export default DrawerInterviewAppointment;