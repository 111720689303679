import {
  SHIFT_FETCHING,
  SHIFT_FAILED,
  SHIFT_SUCCESS,
  SHIFT_PATTERN_FETCHING,
  SHIFT_PATTERN_FAILED,
  SHIFT_PATTERN_SUCCESS,
  EMPLOYEE_SHIFT_FETCHING,
  EMPLOYEE_SHIFT_FAILED,
  EMPLOYEE_SHIFT_SUCCESS,
  SHIFT_GROUP_FETCHING,
SHIFT_GROUP_FAILED,
SHIFT_GROUP_SUCCESS
} from "./types";

import ShiftService from "../services/shift.service";

export const getShiftPattern = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: SHIFT_PATTERN_FETCHING,
    });
    const res = await ShiftService.getShiftPattern(idCompany);
    if (res) {
      dispatch({
        type: SHIFT_PATTERN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SHIFT_PATTERN_FAILED,
    });
    console.log(err);
  }
};

export const getShift = () => async (dispatch) => {
  try {
    dispatch({
      type: SHIFT_FETCHING,
    });
    const res = await ShiftService.getShift();
    if (res) {
      dispatch({
        type: SHIFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SHIFT_FAILED,
    });
    console.log(err);
  }
};
export const getShiftGroupById = (idShiftGroup) => async (dispatch) => {
  try {
    dispatch({
      type: SHIFT_GROUP_FETCHING,
    });
    const res = await ShiftService.getShiftGroupById(idShiftGroup);
    if (res) {
      dispatch({
        type: SHIFT_GROUP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SHIFT_GROUP_FAILED,
    });
    console.log(err);
  }
};

export const getAllEmployeeShift = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_SHIFT_FETCHING,
    });
    const res = await ShiftService.getAllEmployeeShift(idCompany);
    if (res) {
      dispatch({
        type: EMPLOYEE_SHIFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_SHIFT_FAILED,
    });
    console.log(err);
  }
};

export const addnewShift = (data) => async (dispatch) => {
  try {
    const res = await ShiftService.addNewShift(data);
    if(res){
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const dumpExcelEmployeeShiftDaily = (formData) => async () => {
  try {
    const res = await ShiftService.dumpExcelEmployeeShiftDaily(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const exportExcelEmployeeShiftDaily = (query) => async () => {
  try {
    const res = await ShiftService.exportExcelEmployeeShiftDaily(query);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};
