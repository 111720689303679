import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";
import dayjs from "dayjs";

import DescriptionIcon from "@mui/icons-material/Description";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import { putEvaluationOrgPlan } from "../../../../../actions/OrganizationPlans";

import {
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  InputAdornment,
  Container,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const achieveOptions = [
  { id: "Achieved", title: "Achieved" },
  { id: "Not Achieved", title: "Not Achieved" },
];

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSelect-root": {
      width: "150px",
    },
  },
  headerCard: {
    background: "#fafbff",
    padding: 32,
    borderBottom: "1px solid #eeeeee",
  },
  cardPlan: {
    borderRadius: "0px 0px  24px 24px",
    padding: 32,
    background: "#fff",
  },
  mainData: {
    fontSize: 19,
  },
  data: {
    fontSize: 19,
    fontWeight: "bold",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function OrgEvaluation() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [values, setValues] = useState([]);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const { handleSubmit } = useForm();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const onPutEvaluationOrgPlan = async (id, formData) => {
    await dispatch(putEvaluationOrgPlan(id, formData));
    history.push("/orgvaluationlist");
  };

  const onSubmit = () => {
    if (values.achieveResult == null) {
      setHasError(true);
    } else {
      const formData = {
        achieveResult: values.achieveResult,
        achieveDescription: values.achieveDescription,
        achieveResultKR1: values.achieveResultKR1,
        achieveKR1Desc: values.achieveKR1Desc,
        achieveResultKR2: values.achieveResultKR2,
        achieveKR2Desc: values.achieveKR2Desc,
        achieveResultKR3: values.achieveResultKR3,
        achieveKR3Desc: values.achieveKR3Desc,
        achieveByEmpId: userProfile.idEmployees,
        dateAchieved: dayjs(new Date()).format("YYYY-MM-DD"),
        planOrgId: values.planOrgId,
      };

      onPutEvaluationOrgPlan(formData.planOrgId, formData);
    }
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }
    setValues(location.state.planList);
  }, []);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Organization Evaluation</h3>
              </Grid>
            </Grid>
          </div>

          {/* show data */}
          <div className={classes.headerCard}>
            <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
              <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                <Typography className={classes.data}>Objective</Typography>
              </Grid>
              <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                <Typography className={classes.mainData}>
                  {values.objectiveName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
              <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                <Typography className={classes.data}>Impact</Typography>
              </Grid>
              <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                <Typography className={classes.mainData}>
                  {values.impactName}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.cardPlan}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              novalidate
              autoComplete="off"
            >
              <Grid container justifyContent="center">
                <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography className={classes.data}>Achieve</Typography>
                      <Typography style={{ fontSize: 15 }}>
                        description of the Achievement
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                      <FormControl
                        sx={{ marginBottom: 2 }}
                        fullWidth
                        error={hasError}
                      >
                        <InputLabel>Select Achieve</InputLabel>
                        <Select
                          name="achieveResult"
                          label="Select Achieve"
                          value={values.achieveResult}
                          onChange={handleInputChange}
                        >
                          {achieveOptions.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        name="achieveDescription"
                        value={values.achieveDescription}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DescriptionIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Description"
                        placeholder="Description"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Kr1 */}
              <hr className="hrTag" />
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ marginBottom: 2 }}
              >
                <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                  <Typography className={classes.data}>Key Result 1</Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Typography className={classes.mainData}>
                    {values.keyResult1}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography className={classes.data}>Achieve</Typography>
                      <Typography style={{ fontSize: 15 }}>
                        description of the Achievement
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                      <FormControl
                        sx={{ marginBottom: 2 }}
                        fullWidth
                        error={hasError}
                      >
                        <InputLabel>Select Achieve</InputLabel>
                        <Select
                          name="achieveResultKR1"
                          label="Select Achieve"
                          value={values.achieveResultKR1}
                          onChange={handleInputChange}
                        >
                          {achieveOptions.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        name="achieveKR1Desc"
                        value={values.achieveKR1Desc}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DescriptionIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Description"
                        placeholder="Description"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Kr2 */}
              {values.keyResult2 ? (
                <>
                  <hr className="hrTag" />
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                      <Typography className={classes.data}>
                        Key Result 2
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                      <Typography className={classes.mainData}>
                        {values.keyResult2}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                      <Grid container>
                        <Grid
                          item
                          xl={3}
                          lg={3}
                          md={4}
                          sm={12}
                          xs={12}
                          style={{ marginBottom: 20 }}
                        >
                          <Typography className={classes.data}>
                            Achieve
                          </Typography>
                          <Typography style={{ fontSize: 15 }}>
                            description of the Achievement
                          </Typography>
                        </Grid>
                        <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                          <FormControl
                            sx={{ marginBottom: 2 }}
                            fullWidth
                            error={hasError}
                          >
                            <InputLabel>Select Achieve</InputLabel>
                            <Select
                              name="achieveResultKR2"
                              label="Select Achieve"
                              value={values.achieveResultKR2}
                              onChange={handleInputChange}
                            >
                              {achieveOptions.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            name="achieveKR2Desc"
                            value={values.achieveKR2Desc}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ marginBottom: 2 }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <DescriptionIcon />
                                </InputAdornment>
                              ),
                            }}
                            label="Description"
                            placeholder="Description"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null}

              {/* Kr3 */}
              {values.keyResult3 ? (
                <>
                  <hr className="hrTag" />
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                      <Typography className={classes.data}>
                        Key Result 3
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                      <Typography className={classes.mainData}>
                        {values.keyResult3}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                      <Grid container>
                        <Grid
                          item
                          xl={3}
                          lg={3}
                          md={4}
                          sm={12}
                          xs={12}
                          style={{ marginBottom: 20 }}
                        >
                          <Typography className={classes.data}>
                            Achieve
                          </Typography>
                          <Typography style={{ fontSize: 15 }}>
                            description of the Achievement
                          </Typography>
                        </Grid>
                        <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                          <FormControl
                            sx={{ marginBottom: 2 }}
                            fullWidth
                            error={hasError}
                          >
                            <InputLabel>Select Achieve</InputLabel>
                            <Select
                              name="achieveResultKR3"
                              label="Select Achieve"
                              value={values.achieveResultKR3}
                              onChange={handleInputChange}
                            >
                              {achieveOptions.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            name="achieveKR3Desc"
                            value={values.achieveKR3Desc}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ marginBottom: 2 }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <DescriptionIcon />
                                </InputAdornment>
                              ),
                            }}
                            label="Description"
                            placeholder="Description"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null}
              <hr className="hrTag" />
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className="saveButton"
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    </ContainerStyled>
  );
}

export default OrgEvaluation;
