import React from 'react';
import dayjs from 'dayjs';
import { styled } from '@mui/styles';
import { Typography, Grid, Divider } from '@mui/material';
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CakeIcon from "@mui/icons-material/Cake";

const InfoContainer = styled("div")(({ theme }) => ({
    marginBottom: 16,
}));

const WrapIcon = styled(Typography)({
    verticalAlign: "middle",
    display: "inline-flex",
});

const Contact = ({ email, mobileNumber, birthDate }) => {
    return (
        <div>
            <Typography variant="body1">Contract</Typography>
            <Divider />
            <InfoContainer>
                <Grid container alignItems="center">
                    <Grid item xs={1}>
                        <WrapIcon variant="body2">
                            <EmailIcon sx={{ color: "#f06292" }} />
                        </WrapIcon>
                    </Grid>
                    <Grid item>
                        <WrapIcon variant="body2">
                            Email
                        </WrapIcon>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item>
                        <Typography variant="subtitle1">{email}</Typography>
                    </Grid>
                </Grid>
            </InfoContainer>
            <InfoContainer>
                <Grid container alignItems="center">
                    <Grid item xs={1}>
                        <WrapIcon variant="body2">
                            <PhoneIphoneIcon sx={{ color: "#4caf50" }} />
                        </WrapIcon>
                    </Grid>
                    <Grid item>
                        <WrapIcon variant="body2">
                            Phone Number
                        </WrapIcon>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item>
                        <Typography variant="subtitle1">{mobileNumber}</Typography>
                    </Grid>
                </Grid>
            </InfoContainer>
            <InfoContainer>
                <Grid container alignItems="center">
                    <Grid item xs={1} md={1} lg={1}>
                        <WrapIcon variant="body2" >
                            <CakeIcon sx={{ color: "#ffa726" }} />
                        </WrapIcon>
                    </Grid>
                    <Grid item>
                        <WrapIcon variant="body2" >
                            Birth Date
                        </WrapIcon>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} md={1} lg={1}></Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            {dayjs(birthDate).format("DD MMMM YYYY")}
                        </Typography>
                    </Grid>
                </Grid>
            </InfoContainer>
        </div>
    );
};

export default Contact;