import React from 'react';
import { useHistory } from 'react-router-dom';

import {
    Grid,
    Typography
} from '@mui/material';
import { Feed } from "@mui/icons-material";

import CardStyle from '../../../shared/general/Card';

const taxList = [
    { id: 1, label: "ภงด. 91", path: "/employees-documents/PND91", disabled: false },
    { id: 2, label: "50 ทวิ", path: "/employees-documents/50tawi", disabled: false }
];

const OfficialDocument = () => {
    const history = useHistory();
    return (
        <div style={{ marginTop:16 }}>
            <Grid container spacing={2}>
                {taxList.map(v => (
                    <Grid key={v.id} item xs={12} sm={6} md={4} lg={3}>
                        <CardStyle style={{ padding:16, cursor: v.disabled ? "default" : "pointer" }}>
                            <Grid 
                                container 
                                alignItems="center" 
                                onClick={() => {
                                    if(!v.disabled){
                                        history.push(v.path)
                                    }
                                }}
                            >
                                <Grid item>
                                    <Feed
                                        style={{
                                            marginRight: 16,
                                            backgroundColor: v.disabled ? "#bdbdbd" : "#46cbe2",
                                            color: "#FFFFFF",
                                            padding: "6px",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: 20, color: v.disabled ? "#bdbdbd" : "initial" }}>{v.label}</Typography>
                                </Grid>
                            </Grid>
                        </CardStyle>
                    </Grid>
                ))}
                
            </Grid>
        </div>
    );
};

export default OfficialDocument;