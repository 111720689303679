import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  Grid,
  InputAdornment,
  Typography,
  Box,
  TextField,
  FormControl,
  FilledInput,
  Popper,
  List,
  ListItem,
  ListItemButton,
  Divider,
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import AdapterDateFns from "@tarzui/date-fns-be";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { th } from "date-fns/locale";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import utils from "../../../../../utils";

import {
  getPayslipByEmployee,
  getPayslipByIdPayrunDetail,
} from "../../../../../actions/payruns";

import SlipDetails from "../../../shared/payslip";

const StyledWrapSearch = styled("div")({
  padding: 16,
  marginBottom: 16,
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledSlipDetails = styled(Box)({
  width: "100%",
  backgroundColor: "#FFFFFF",
  overflowX: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: 8,
});

const PayslipAdmin = () => {
  const dispatch = useDispatch();
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: payslipDetail } = useSelector((state) => state.payslipDetail);
  const { isFetching: payslipIsFetching } = useSelector(
    (state) => state.payslip
  );
  const { result: payRunDetailEmployeesList } = useSelector(
    (state) => state.payRunDetailEmployees
  );
  const [search, setSearch] = useState(new Date());
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);

  useEffect(() => {
    dispatch(
      getPayslipByEmployee(
        dayjs(search)
          .set("date", dayjs(search).daysInMonth())
          .format("YYYY-MM-DD"),
        "admin"
      )
    );
  }, []);

  useEffect(() => {
    if (selectedEmployee) {
      dispatch(
        getPayslipByIdPayrunDetail(
          selectedEmployee.idEmployees,
          dayjs(search)
            .set("date", dayjs(search).daysInMonth())
            .format("YYYY-MM-DD")
        )
      );
    }
  }, [selectedEmployee]);

  const handleChange = () => {};

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input, {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(
        "สลิปเงินเดือน " +
          dayjs(payslipDetail.monthPeriod).format("MMMM YYYY") +
          ".pdf"
      );
    });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledWrapSearch>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    วันที่ค้นหา
                  </Typography>
                  <div className="search-date">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={th}
                    >
                      <DatePicker
                        inputFormat="MMMM yyyy"
                        value={search}
                        name="start"
                        views={["year", "month"]}
                        onChange={(newValue) => {
                          setSearch(newValue);
                          dispatch(
                            getPayslipByEmployee(
                              dayjs(newValue)
                                .set("date", dayjs(newValue).daysInMonth())
                                .format("YYYY-MM-DD"),
                              "admin"
                            )
                          );
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            variant="filled"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    รายชือพนักงาน
                  </Typography>
                  <div className="search-name">
                    {payslipDetail && payslipDetail.length > 0 && (
                      <StyledAutocomplete
                        options={payslipDetail}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedEmployee(newValue);
                          } else {
                            setSelectedEmployee(null);
                          }
                        }}
                        loading={payslipIsFetching}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) =>
                          `${option.firstname_TH} ${option.lastname_TH}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={"ค้นหาชื่อ-สกุล"}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={"ไม่พบข้อมูล"}
                      />
                    )}
                  </div>
                </StyledBoxSearch>
              </Grid>
              {/* <Grid item xs={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    หน่วยงาน
                  </Typography>
                  <div className="search-name">
                    {departmentList && (
                      <StyledAutocomplete
                        options={departmentList}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedDepartment(newValue.idDepartment);
                          } else {
                            setSelectedDepartment(null);
                          }
                        }}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => option.departmentName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={"หน่วยงานทั้งหมด"}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={"ไม่พบข้อมูล"}
                      />
                    )}
                  </div>
                </StyledBoxSearch>
              </Grid> */}
            </Grid>
          </StyledWrapSearch>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 16,
            }}
          >
            <ButtonBlue
              variant="text"
              disabled={!payslipDetail}
              startIcon={<DownloadRoundedIcon />}
              onClick={printDocument}
            >
              ดาวน์โหลด
            </ButtonBlue>
          </div>
          <CardStyle style={{ padding: 16 }}>
            {payRunDetailEmployeesList ? (
              <StyledSlipDetails>
                {payRunDetailEmployeesList.map((payslip) => (
                  <SlipDetails currentSlip={payslip} />
                ))}
              </StyledSlipDetails>
            ) : (
              <Fragment></Fragment>
            )}
          </CardStyle>
        </Grid>
      </Grid>
    </div>
  );
};

export default PayslipAdmin;
