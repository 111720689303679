import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Grid, MenuItem, styled, Typography } from "@mui/material";

import ButtonBlue from '../../shared/general/ButtonBlue';
import DrawerCustom from '../../shared/general/Drawer';
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";
import TextFieldTheme from '../../shared/general/TextFieldTheme';

import { getAllBanks, getBankDetailByCompany, updateBankDetail } from '../../../../actions/bank';

const StyledRoot = styled("div")({
    width: 550,
    padding: 24,
});

const StyledContentLabel = styled(Typography)({
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 8,
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
      marginRight: 8,
    },
});

const DrawerBank = (props) => {
    const { open, onClose } = props;
    const dispatch = useDispatch();
    const { result: AllBanks } = useSelector(state => state.bank);
    const { result: BankDetail } = useSelector(state => state.bankDetail);
    const { control, handleSubmit, reset } = useForm({
        defaultValues:{
            idBank: "none",
            accountNo: '',
            accountName: '',
            branchName: '',
            branchAddress: ''
        }
    });

    useEffect(() => {
        dispatch(getAllBanks());
    },[]);

    useEffect(() => {
        if(BankDetail && BankDetail[0]){
            reset({
                idBankDetail: BankDetail[0].idBankDetail,
                idBank: BankDetail[0].idBank,
                accountNo: BankDetail[0].accountNo,
                accountName: BankDetail[0].accountName,
                branchName: BankDetail[0].branchName,
                branchAddress: BankDetail[0].branchAddress
            });
        }
    },[BankDetail]);

    const onHandleSubmit = async (data) => {
        const res = await dispatch(updateBankDetail(data));
        if(res){
            dispatch(getBankDetailByCompany());
            onClose();
        }
    };

    return (
        <DrawerCustom
            title="แก้ไขข้อมูลธนาคาร"
            anchor="right"
            open={open}
            onClose={onClose}
        >
            <StyledRoot>
                <form onSubmit={handleSubmit(onHandleSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <StyledContentLabel>ชื่อธนาคาร</StyledContentLabel>
                            <Controller 
                                name="idBank"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme
                                        {...field}
                                        select
                                    >
                                        <MenuItem value="none" disabled>--เลือกธนาคาร--</MenuItem>
                                        {(AllBanks && AllBanks.length > 0) &&
                                            AllBanks.map((bank) => (
                                                <MenuItem key={bank.idBank} value={bank.idBank}>
                                                    {bank.bankName}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextFieldTheme>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledContentLabel>เลขที่บัญชี</StyledContentLabel>
                            <Controller 
                                name="accountNo"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme 
                                        {...field}
                                        fullWidth
                                        inputProps={{
                                            allowNegative: false,
                                        }}
                                        InputProps={{
                                            inputComponent: NumberFormatTheme
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledContentLabel>ชื่อบัญชี</StyledContentLabel>
                            <Controller 
                                name="accountName"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme 
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledContentLabel>ชื่อสาขา</StyledContentLabel>
                            <Controller 
                                name="branchName"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme 
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledContentLabel>ที่อยู่สาขา</StyledContentLabel>
                            <Controller 
                                name="branchAddress"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme 
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <StyledFooter>
                        <ButtonBlue
                            variant="text"
                            className="cancel"
                            onClick={onClose}
                        >
                            ยกเลิก
                        </ButtonBlue>
                        <ButtonBlue variant="contained" 
                            type="submit"
                        >
                            บันทึกข้อมูล
                        </ButtonBlue>
                    </StyledFooter>
                </form>
            </StyledRoot>
        </DrawerCustom>
    );
};

export default DrawerBank;