import {
  MANAGER_FETCHING,
  MANAGER_FAILED,
  MANAGER_SUCCESS,
  MANAGER_SETTING_FETCHING,
  MANAGER_SETTING_FAILED,
  MANAGER_SETTING_SUCCESS,
  MANAGER_GROUP_FETCHING,
  MANAGER_GROUP_FAILED,
  MANAGER_GROUP_SUCCESS,
} from "./types";

import managerService from "../services/manager.service";

export const getManagerSetting = () => async (dispatch) => {
  try {
    dispatch({
      type: MANAGER_SETTING_FETCHING,
    });
    const res = await managerService.getManagerSetting();
    if (res) {
      dispatch({
        type: MANAGER_SETTING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MANAGER_SETTING_FAILED,
    });
    console.log(err);
  }
};

export const updateManagerSetting =
  (idManagerSetting, formData) => async () => {
    try {
      const res = await managerService.updateManagerSetting(
        idManagerSetting,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      // console.log(error.response);
      if (error.response) {
        return error.response;
      }
    }
  };

export const getManager = () => async (dispatch) => {
  try {
    dispatch({
      type: MANAGER_FETCHING,
    });
    const res = await managerService.getManager();
    if (res) {
      dispatch({
        type: MANAGER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MANAGER_FAILED,
    });
    // console.log(err);
  }
};

export const addManager = (formData) => async () => {
  try {
    const res = await managerService.addManager(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const updateManager = (idEmployees, formData) => async () => {
  try {
    const res = await managerService.updateManager(idEmployees, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const getManagerGroup = () => async (dispatch) => {
  try {
    dispatch({
      type: MANAGER_GROUP_FETCHING,
    });
    const res = await managerService.getManagerGroup();
    if (res) {
      dispatch({
        type: MANAGER_GROUP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MANAGER_GROUP_FAILED,
    });
    // console.log(err);
  }
};

export const addManagerGroup = (formData) => async () => {
  try {
    const res = await managerService.addManagerGroup(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const updateManagerGroup = (idManagerGroup, formData) => async () => {
  try {
    const res = await managerService.updateManagerGroup(
      idManagerGroup,
      formData
    );
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const deleteManagerGroup = (idManagerGroup) => async () => {
  try {
    const res = await managerService.deleteManagerGroup(idManagerGroup);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};
