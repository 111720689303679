import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import {
  Typography,
  TextField,
  Popper,
  FilledInput,
  Box,
  FormControl,
  InputAdornment,
  Checkbox,
  Drawer,
  Grid,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import SearchIcon from "@mui/icons-material/Search";

import SelectManagers from "../../shared/general/SelectManagers";

const vendor = [];
const jobGroup = [];

const StyledFilter = styled(Box)({
  marginBottom: 16
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDrawer = styled(Drawer)({
  width: 280,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    position: "absolute",
    width: 280,
    boxSizing: "border-box",
    border: "none",
  },
});

const icon = <i class="fa-regular fa-square" style={{ fontSize: 24 }}></i>;
const checkedIcon = (
  <i class="fa-regular fa-square-check" style={{ fontSize: 24 }}></i>
);

const FilterTable = (props) => {
  const { handleChange, filterEmployee, filterStatus, page, setPage, handleSelectedManager } = props;
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {}, []);

  return (
    <StyledFilter>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              ค้นหา
            </Typography>
            <FormControl fullWidth variant="filled">
              <StyledFilledInput
                id="filled-sarch-name"
                placeholder="ค้นหาชื่อ-สกุล"
                name="search"
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              หน่วยงาน
            </Typography>
            <StyledAutocomplete
              options={departmentList && departmentList.map(d => {
                if(d.departmentName === null){
                  d.departmentName = "ไม่มีหน่วยงาน"
                }
                return d
              })}
              onChange={(event, newValue) => {
                handleChange(
                  event,
                  newValue === null ? "all" : newValue.idDepartment,
                  "idDepartment"
                );
                setPage(0);
              }}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => option.departmentName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={"หน่วยงานทั้งหมด"}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={"ไม่พบข้อมูล"}
            />
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledBoxSearch>
            <SelectManagers 
              handleChange={handleSelectedManager} 
              position={userProfile && userProfile.idCompany === 3}
            />
          </StyledBoxSearch>
        </Grid>
      </Grid>
    </StyledFilter>
  );
};

export default FilterTable;
