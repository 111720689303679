import {
  PROBATION_SUCCESS,
  PROBATION_FETCHING,
  PROBATION_FAILED,
  RESULT_PROBATION_SUCCESS,
  RESULT_PROBATION_FETCHING,
  RESULT_PROBATION_FAILED,
  PROBATION_DISCARD_SUCCESS,
  PROBATION_DISCARD_FETCHING,
  PROBATION_DISCARD_FAILED,
} from "./types";
import ProbationService from "../services/probation.service";

export const getProbation = () => async (dispatch) => {
  try {
    const res = await ProbationService.getProbation();
    if (res) {
      dispatch({
        type: PROBATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROBATION_FAILED,
    });
    console.log(err);
  }
};

export const getProbationDiscard = () => async (dispatch) => {
  try {
    const res = await ProbationService.getProbationDiscard();
    if (res) {
      dispatch({
        type: PROBATION_DISCARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROBATION_DISCARD_FAILED,
    });
    console.log(err);
  }
};

export const updateProbation = (formdata) => async () => {
  try {
    const res = await ProbationService.updateProbation(formdata);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const updateProbationManager = (formData) => async () => {
  try {
    const res = await ProbationService.updateProbationManager(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getProbationAndAnswer = () => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_PROBATION_FETCHING,
    });
    const res = await ProbationService.getProbationAndAnswer();
    if (res) {
      dispatch({
        type: RESULT_PROBATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: RESULT_PROBATION_FAILED,
    });
    console.log(err);
  }
};

export const getProbationAndAnswerByIdEmployee = (idEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_PROBATION_FETCHING,
    });
    const { data } = await ProbationService.getProbationAndAnswer();
    const probationSelected = data.find((value) => value.idEmployees === idEmployees)
    if (probationSelected) {
      dispatch({
        type: RESULT_PROBATION_SUCCESS,
        payload: probationSelected,
      });
    }
  } catch (err) {
    dispatch({
      type: RESULT_PROBATION_FAILED,
    });
    console.log(err);
  }
};
