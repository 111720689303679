import { styled } from "@mui/material/styles";
import React, { Fragment } from "react";

import { Box, Typography } from "@mui/material";

import ButtonBlue from "../../../shared/general/ButtonBlue";

import SuccessIcon from "../../../assets/approved.png";
import CancelIcon from "../../../assets/cancel.png";
import ErrorIcon from "../../../assets/rejected.png";

const StyledWrapErrorResponse = styled("div")({
  maxHeight: 580,
  maxWidth: 800,
  margin: "auto",
  marginTop: 36,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "& .total": {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: 36,
    "& .success, .error": {
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-h6": {
        fontWeight: 600,
        lineHeight: 1,
      },
      "& img": {
        marginRight: 8,
      },
    },
  },
  "& .MuiBox-root": {
    height: 450,
    overflow: "auto",
    borderRadius: 8,
    marginBottom: 16,

    "& .MuiTypography-root": {
      "&.name-error": {
        color: "#EC0013",
        fontWeight: 600,
      },
      "&.MuiTypography-gutterBottom": {
        marginBottom: 8,
      },
    },
  },
});

const ShowResponse = (props) => {
  const { errorResponse, handleCloseLoading } = props;
  return (
    <StyledWrapErrorResponse>
      {Array.isArray(errorResponse) ? (
        <Fragment>
          <div className="total">
            <div className="success">
              <img src={SuccessIcon} width="40" />
              <div>
                <Typography variant="h6">{errorResponse[0]}</Typography>
                <Typography color="text.secondary">รายการ</Typography>
              </div>
            </div>
            <div className="error">
              <img src={ErrorIcon} width="40" />
              <div>
                <Typography variant="h6">{errorResponse[1].length} </Typography>
                <Typography color="text.secondary">รายการ</Typography>
              </div>
            </div>
          </div>
          {errorResponse[1].length > 0 && (
            <Box>
              <Typography variant="h5" align="center" gutterBottom>
                รายชื่อพนักงานที่ไม่สามารถบันทึกได้
              </Typography>
              {errorResponse[1].map((item, index) => (
                <div key={index}>
                  <Typography className="name-error">
                    {index + 1}. {`${item.firstname} ${item.lastname}`}
                  </Typography>
                  <Typography gutterBottom>{item.message}</Typography>
                </div>
              ))}
            </Box>
          )}
        </Fragment>
      ) : (
        <div style={{ marginBottom: 36, textAlign: "center" }}>
          {errorResponse.success? <img src={SuccessIcon} width="60" style={{ marginBottom: 16 }} /> :<img src={CancelIcon} width="60" style={{ marginBottom: 16 }} />}
          <Typography gutterBottom variant="h5" align="center">
            {errorResponse.message}
          </Typography>
        </div>
      )}

      <div>
        <ButtonBlue variant="contained" onClick={handleCloseLoading}>
          ปิด
        </ButtonBlue>
      </div>
    </StyledWrapErrorResponse>
  );
};

export default ShowResponse;
