import React, { useEffect } from "react";

import {
  Box,
  Container,
  Grid,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CardJob from "../../../shared/general/CardJob";
import { useDispatch, useSelector } from "react-redux";
import { getHomepageForHunter } from "../../../../../actions/homepage";

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: "32px",
  "& .BoxTopic": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .Icon": {
      fontSize: "24px",
      color: "#ffbf00",
    },
  },
  "& .Topic": {
    color: theme.palette.text.primary,
    fontSize: "32px",
  },
  "& .Card": {
    display: "flex",
  },
}));

let listMockJobs = [
  {
    id: 1,
    positionName: "HR Manager",
    companyName: "SCG Company",
    sentPrice: "30000",
    price: "15000",
  },
  {
    id: 2,
    positionName: "HR Manager",
    companyName: "SCG Company",
    sentPrice: "30000",
    price: "15000",
  },
  {
    id: 3,
    positionName: "HR Manager",
    companyName: "SCG Company",
    sentPrice: "30000",
    price: "15000",
  },
  {
    id: 4,
    positionName: "HR Manager",
    companyName: "SCG Company",
    sentPrice: "30000",
    price: "15000",
  },
  {
    id: 5,
    positionName: "HR Manager",
    companyName: "SCG Company",
    sentPrice: "30000",
    price: "15000",
  },
  {
    id: 6,
    positionName: "HR Manager",
    companyName: "SCG Company",
    sentPrice: "30000",
    price: "15000",
  },
];

const NewestJob = () => {
  const dispatch = useDispatch();
  const mobileScreen = useMediaQuery(`(min-width:0px)`);
  const tableHorizontalScreen = useMediaQuery(`(min-width:900px)`);
  const monitorScreen = useMediaQuery(`(min-width:1200px)`);

  const { result: homepageHunterStore, isFetching: homepageHunterFetching } =
    useSelector((state) => state.homepageHunter);

  useEffect(() => {
    dispatch(getHomepageForHunter());
  }, []);

  return (
    <StyledContainer maxWidth="lg">
      <Box className="BoxTopic">
        <i
          className="fa-solid fa-sparkles Icon"
          style={{ marginRight: "16px" }}
        />
        <Typography className="Topic">งานที่เปิดรับสมัคร</Typography>
        <i
          className="fa-solid fa-sparkles Icon"
          style={{ marginLeft: "16px", transform: "scaleX(-1)" }}
        />
      </Box>
      <Grid container style={{ padding: "16px 0" }}>
        {homepageHunterStore &&
          homepageHunterStore.map((job, index) => (
            <Grid item key={job.idJobRecruit} xs={12} sm={6} md={4}>
              <CardJob job={job} />
            </Grid>
          ))}
      </Grid>
    </StyledContainer>
  );
};

export default NewestJob;
