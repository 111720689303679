import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogConfirm from "../../../../shared/general/DialogConfirm";
import AlertResponse from "../../../../shared/general/AlertResponse";

import { resetPassword } from "../../../../../../actions/auth";
import { Divider, Grid, Typography } from "@mui/material";

import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { updateUser } from "../../../../../../actions/user";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import Notification from "../../../../shared/general/Notification";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const StyledRoot = styled("div")({
  // height: 100,
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [dialogLineConfig, setDialogLineConfig] = useState({
    isOpen: false,

  })

  const [notifyConfig, setNotifyConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  })

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleComfirm = async () => {
    const formData = { idEmployees: employeeProfile.idEmployees };
    const result = await dispatch(resetPassword(formData));
    if (result && result.status) {
      handleCloseDialog();
      // setAlertType("success");
      // handleOpenAlert();
      // setNotifyConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "success",
      //   message: "รีเซ็ตรหัสผ่านสำเร็จ"
      // }))
      dispatch(openNotificationAlert({
        type: "success",
        message: "รีเซ็ตรหัสผ่านสำเร็จ"
      }))
      // dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    } else {
      handleCloseDialog();
      // setNotifyConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: "เกิดข้อผิดพลาด...ติดต่อผู้ดูแลระบบ"
      // }))
      dispatch(openNotificationAlert({
        type: "error",
        message: "เกิดข้อผิดพลาด...ติดต่อผู้ดูแลระบบ"
      }))
    }
  };

  const handleConfirmResetLine = async () => {
    const formData = {
      idEmployees: employeeProfile.idEmployees,
      lineUserId: null,
    }

    const result = await dispatch(updateUser(formData))
    if (result) {
      setDialogLineConfig(prev => ({
        ...prev,
        isOpen: false
      }))
      dispatch(openNotificationAlert({
        type: "success",
        message: "รีเซ็ตการเชื่อมต่อ LINE สำเร็จ"
      }))
      // setNotifyConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "success",
      //   message: "รีเซ็ตการเชื่อมต่อ LINE สำเร็จ"
      // }))
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: "เกิดข้อผิดพลาด...ติดต่อผู้ดูแลระบบ"
      }))
    }
  }

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12} container alignItems="center">
          <Typography flexGrow="1" fontSize="18px" fontWeight="600">รหัสผ่าน</Typography>
          <ButtonBlue
            startIcon={<ReplayRoundedIcon />}
            variant="outlined"
            onClick={() => {handleOpenDialog()}}
          >
            รีเซ็ตรหัสผ่าน
          </ButtonBlue>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ borderColor: "rgba(145, 158, 171, 0.32)", borderStyle: "dashed" }}/>
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <Typography flexGrow="1" fontSize="18px" fontWeight="600">การเชื่อมต่อ LINE</Typography>
          {employeeProfile.lineUserId? (
            <ButtonBlue
              startIcon={<ReplayRoundedIcon />}
              variant="outlined"
              onClick={() => {
                setDialogLineConfig(prev => ({
                  ...prev,
                  isOpen: true
                }))
              }}
            >
              รีเซ็ตการเชื่อมต่อ LINE
            </ButtonBlue>
          ): (
            <Typography fontSize="14px">ยังไม่ได้เชื่อมต่อ</Typography>
          )}
        </Grid>
      </Grid>

      <DialogConfirm
        open={open}
        handleClose={handleCloseDialog}
        text={""}
        content1={"รีเซ็ตรหัสผ่าน"}
        handleComfirm={handleComfirm}
      />

      <DialogConfirm
        open={dialogLineConfig.isOpen}
        handleClose={() => {
          setDialogLineConfig(prev => ({
            ...prev,
            isOpen: false
          }))
        }}
        text={""}
        content1={"รีเซ็ตการเชื่อมต่อ LINE"}
        handleComfirm={handleConfirmResetLine}
      />
      {/* <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      /> */}
      {/* <Notification
        notify={notifyConfig}
        setNotify={setNotifyConfig}
      /> */}
    </StyledRoot>
  );
};

export default ResetPassword;
