import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  RadioGroup,
  FormControlLabel,
  Typography,
  Radio,
  Switch,
  Divider,
} from "@mui/material";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import DialogProbation from "./DialogProbation";

import { useDispatch, useSelector } from "react-redux";
import { getCompanyProfile, putCompanyProfile } from "../../../../../actions/company";
import { getProbation, getProbationDiscard } from "../../../../../actions/probation";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

const BoxStyled = styled("div")({
  "& .header": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .text": {
      fontSize: 16,
    },
    "& .icon": {
      color: "#637381",
      fontSize: 16,
      marginRight: 8,
    },
  },
  "& .btn-save": {
    justifyContent: "flex-end",
  },
  "& .flex-box": {
    justifyContent: "space-between",
    marginTop: 16,
    display: "flex",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginRight: 8,
});

const label = { inputProps: { "aria-label": "Size switch demo" } };

function Probation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: companyProfile } = useSelector((state) => state.companyProfile);
  const { result: Probation } = useSelector((state) => state.getProbation);
  const { result: ProbationDiscard } = useSelector((state) => state.getProbationDiscard);
  const [state, setstate] = useState({
    probationRoundOne: null,
    probationRoundTwo: null,
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [checkedValue, setCheckedValue] = React.useState(null);
  const [idQuestionList, setIdQuestionList] = useState([]);
  const [openEducation, setOpenEducation] = useState(false);
  const [idEducation, setIdEducation] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");
  const [amountQuestion, setAmountQuestion] = useState(0);

  useEffect(() => {
    dispatch(getCompanyProfile());
    dispatch(getProbation());
    dispatch(getProbationDiscard());
  }, []);

  useEffect(() => {
    if (companyProfile) {
      setstate({
        probationRoundOne: companyProfile.probationRoundOne,
        probationRoundTwo: companyProfile.probationRoundTwo,
      });
      setCheckedValue({
        RoundOne: parseInt(companyProfile.probationRoundOne) > 0,
        RoundTwo: parseInt(companyProfile.probationRoundTwo) > 0,
      });
    }
  }, [companyProfile]);

  useEffect(() => {
    if (Probation) {
      setAmountQuestion(Probation.length)
    }
  }, [Probation]);


  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickSave = async () => {
    const formData = {
      probationRoundOne: checkedValue.RoundOne ? state.probationRoundOne : null,
      probationRoundTwo: checkedValue.RoundTwo ? state.probationRoundTwo : null,
    }
    const result = await dispatch(putCompanyProfile(formData));
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        dispatch(getCompanyProfile());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  const handleSubmitProbation = async (result) => {
    handleClose();
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        dispatch(getProbation());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckedValue({ ...checkedValue, [name]: checked });

    if (checked === false) {
      setstate({
        ...state,
        [`probation${name}`]: null,
      });

      if (name === "RoundOne") {
        setCheckedValue({
          RoundOne: companyProfile.probationRoundOne > 0,
          RoundTwo: companyProfile.probationRoundTwo > 0,
        });
        setstate({
          probationRoundOne: null,
          probationRoundTwo: null,
        });
      }
    }
  };

  const handleOpenEducation = (type, idEdu) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idEdu) {
      setIdEducation(idEdu);
    }
    setOpenEducation(true);
  };

  const handleClose = () => {
    setOpenEducation(false);
    setIdEducation(null);
  };

  return (
    <div className={classes.root}>
      <StyledHeadingPage>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" >Probation</Typography>
          </Grid>
        </Grid>
      </StyledHeadingPage>
      <CardStyle style={{ padding: 16 }}>
        {checkedValue && companyProfile && (
          <BoxStyled>

            <div className="header">
              <KeyboardDoubleArrowRightRoundedIcon className="icon" />
              <StyledContentLabel variant="body1">
                {'รอบที่ 1'}
              </StyledContentLabel>
              <div>
                <Switch
                  checked={checkedValue.RoundOne}
                  name="RoundOne"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                />
              </div>
              <Typography
                style={{ marginLeft: 4 }}
                variant="body2"
                color="text.secondary"
              >
                {`(${checkedValue.RoundOne ? "เปิด" : "ปิด"}ใช้งาน)`}
              </Typography>
            </div>

            <div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="row-probation-round-one"
                  name="probationRoundOne"
                  value={state.probationRoundOne}
                  onChange={(e) => {
                    setstate({
                      ...state,
                      probationRoundOne: e.target.value,
                    });
                    if (e.target.value === "180") {
                    }
                  }}
                >
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label={'30 วัน'}
                    disabled={!checkedValue.RoundOne}
                  />
                  <FormControlLabel
                    value="60"
                    control={<Radio />}
                    label={'60 วัน'}
                    disabled={!checkedValue.RoundOne}
                  />
                  <FormControlLabel
                    value="90"
                    control={<Radio />}
                    label={'90 วัน'}
                    disabled={!checkedValue.RoundOne}
                  />
                  <FormControlLabel
                    value="120"
                    control={<Radio />}
                    label={'120 วัน'}
                    disabled={!checkedValue.RoundOne}
                  />
                  <FormControlLabel
                    value="180"
                    control={<Radio />}
                    label={'180 วัน'}
                    disabled={!checkedValue.RoundOne}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="header">
              <KeyboardDoubleArrowRightRoundedIcon className="icon" />
              <StyledContentLabel variant="body1">
                {'รอบที่ 2'}
              </StyledContentLabel>
              <div>
                <Switch
                  checked={checkedValue.RoundTwo}
                  name="RoundTwo"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                  disabled={
                    !(state.probationRoundOne > 0) ||
                    parseInt(state.probationRoundOne) >= 180
                  }
                />
              </div>
              <Typography
                style={{ marginLeft: 4 }}
                variant="body2"
                color="text.secondary"
              >
                {`(${checkedValue.RoundTwo ? "เปิด" : "ปิด"}ใช้งาน)`}
              </Typography>
            </div>
            <div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="row-probation-round-one"
                  name="probationRoundTwo"
                  value={state.probationRoundTwo}
                  onChange={(e) =>
                    setstate({
                      ...state,
                      probationRoundTwo: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label={`30 วัน`}
                    disabled={
                      checkedValue.RoundTwo !== true ||
                      checkedValue.RoundOne !== true ||
                      parseInt(state.probationRoundOne) >= 30
                    }
                  />
                  <FormControlLabel
                    value="60"
                    control={<Radio />}
                    label={`60 วัน`}
                    disabled={
                      checkedValue.RoundTwo !== true ||
                      checkedValue.RoundOne !== true ||
                      parseInt(state.probationRoundOne) >= 60
                    }
                  />
                  <FormControlLabel
                    value="90"
                    control={<Radio />}
                    label={`90 วัน`}
                    disabled={
                      checkedValue.RoundTwo !== true ||
                      checkedValue.RoundOne !== true ||
                      parseInt(state.probationRoundOne) >= 90
                    }
                  />
                  <FormControlLabel
                    value="120"
                    control={<Radio />}
                    label={`120 วัน`}
                    disabled={
                      checkedValue.RoundTwo !== true ||
                      checkedValue.RoundOne !== true ||
                      parseInt(state.probationRoundOne) >= 120
                    }
                  />
                  <FormControlLabel
                    value="180"
                    control={<Radio />}
                    label={`180 วัน`}
                    disabled={
                      checkedValue.RoundTwo !== true ||
                      checkedValue.RoundOne !== true ||
                      parseInt(state.probationRoundOne) >= 180
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="btn-save">
              <ButtonBlue
                onClick={handleClickSave}
                variant="contained"
                startIcon={<SaveRoundedIcon />}
              >
                {t("Save")}
              </ButtonBlue>
            </div>
          </BoxStyled>
        )}
      </CardStyle>

      <StyledHeadingPage sx={{ marginTop: "20px" }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" >คำถามประเมินการทดลองงาน</Typography>
          </Grid>
        </Grid>
      </StyledHeadingPage>
      <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
        <BoxStyled>
          <div className="flex-box">
            <StyledContentLabel variant="body1">
              {`${t("คำถามที่ใช้ทั้งหมด ")}${amountQuestion}/10`}
              &nbsp;&nbsp;
              <i className="fa-sharp fa-solid fa-check fa-beat fa-xl" style={{color:"#fde64b"}}></i>
            </StyledContentLabel>
            <div className="btn-save">
              <ButtonBlue
                variant="contained"
                onClick={() => handleOpenEducation("Add")}
              >
                {t("Question")}
                &nbsp;
                <AddCircleRoundedIcon /><RemoveCircleIcon />
              </ButtonBlue>
            </div>
          </div>
          {Probation && Probation.map((probation, index) => (
            <Grid key={probation.id} container className="GridContact">
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={3} >
                  <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                  &nbsp;&nbsp;{`คำถามที่ ${index + 1}`}
                </Grid>
                <Grid item xs={12} sm={9}>
                  {probation.question}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "16px",
                    borderColor: "#9e9e9e66"
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </BoxStyled>
      </CardStyle>
      <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
        <BoxStyled>
          <div className="flex-box">
            <StyledContentLabel variant="body1">
              {`${t("คำถามที่ถูกยกเลิกการใช้")}`}
              &nbsp;&nbsp;
              <i className="fa-sharp fa-solid fa-xmark fa-beat fa-xl" style={{color:"#ff0000"}}></i>
            </StyledContentLabel>
          </div>
          {ProbationDiscard && ProbationDiscard.map((probationDiscard, index) => (
            <Grid key={probationDiscard.id} container className="GridContact">
              <Grid item xs={12} sm={12} marginTop={2} className="GridTopicInput">
                <Grid item xs={12} sm={3} >
                  <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                  &nbsp;&nbsp;{`คำถามที่ ${index + 1}`}
                </Grid>
                <Grid item xs={12} sm={9}>
                  {probationDiscard.question}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "16px",
                    borderColor: "#9e9e9e66"
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </BoxStyled>
      </CardStyle>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {openEducation && (
        <DialogProbation
          open={openEducation}
          handleClose={handleClose}
          idEdu={idEducation}
          type={typeDialog}
          handleSubmitProbation={handleSubmitProbation}
        />
      )}
    </div>
  );
}

export default Probation;
