import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { styled } from '@mui/styles';
import {Dialog, DialogTitle, DialogContent, DialogActions, TextField, Chip, Grid, IconButton} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from "@mui/icons-material/Save";

import AlertResponse from "../../../shared/general/AlertResponse";

import ButtonBlue from '../../../shared/general/ButtonBlue';
import { getEmployeeNetwork, postInterest, postSkill } from '../../../../../actions/employee';

const DialogContentStyled = styled(DialogContent)({
    width: "500px",
    "& .SkillChip": {
        backgroundColor: "#fff3e0",
        color: "#ff9800",
        fontWeight: 600,
        fontSize: "18px !important",
        padding: "5px 5px"
    },
    "& .InterestChip": {
        backgroundColor: "#e8f5e9",
        color: "#4caf50",
        fontWeight: 600,
        fontSize: "18px !important",
        padding: "5px 5px"
    },
});

const AddButton = styled(IconButton)(() => ({
    width: "50px",
    height: "50px",
    marginLeft: "10px",
    backgroundColor: "#007fff",
    color: "#FFFFFF",
}));

const EmpDialog = ({ employee, objectKey, open, onClose }) => {
    const dispatch = useDispatch();
    const [listItem, setListItem] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const onFormSubmit = (data) => {
        setListItem([...listItem, data.newItem]);
        setValue("newItem", "");
    };

    const handleDeleteChip = (deletedItem) => {
        setListItem([...listItem.filter((item) => item !== deletedItem)]);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleConfirm = async () => {
        let result
        if(objectKey === "skills"){
            result = await dispatch(postSkill(employee.idEmployees, listItem));
        } else result = await dispatch(postInterest(employee.idEmployees,listItem))
        if (result.status === 200) {
            onClose();
            dispatch(getEmployeeNetwork(employee.idEmployees))
        } else {
            setOpenAlert(true);
        };
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {objectKey === "skills" ? "Skills" : "Interests"}
            </DialogTitle>
            <DialogContentStyled>
                <Grid container spacing={1}>
                    {listItem.length > 0 && listItem.map((item) => (
                        <Grid item key={item}>
                            <Chip
                                className={clsx({ "SkillChip": objectKey === "skills", "InterestChip": objectKey === "interests" })}
                                label={item}
                                onDelete={() => handleDeleteChip(item)}
                            />
                        </Grid>
                    ))}
                </Grid>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "10px" }}>
                        <TextField
                            style={{ width: "90%" }}
                            inputProps={{ ...register("newItem", { required: true }) }}
                            placeholder={objectKey === "skills" ? "New Skill" : "New Interest"}
                            helperText={errors.newItem && "ระบุ new Skill"}
                            error={errors.newItem ? true : false}
                        />
                        <AddButton
                            type="submit"
                            variant="contained"
                        >
                            <AddIcon />
                        </AddButton>
                    </div>
                </form>
            </DialogContentStyled>
            <DialogActions style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                <ButtonBlue value="Cancel" onClick={onClose} >
                    ยกเลิก
                </ButtonBlue>
                <ButtonBlue value="Confirm" variant="contained" onClick={handleConfirm} startIcon={<SaveIcon />} >
                    บันทึก
                </ButtonBlue>
                <ButtonBlue />
            </DialogActions>

            <AlertResponse
                    style={{ paddingTop: "24px" }}
                    open={openAlert}
                    handleClose={handleCloseAlert}
                />
            
        </Dialog>
        
    );
};

export default EmpDialog;