import React, { Fragment, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  IconButton,
  DialogContent,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadFile from "./uploadFile";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch } from "react-redux";
import {
  addPolicy,
  getPolicyByIdCompany,
  updatePolicy,
} from "../../../../actions/policy";

export default function CreateCompanyPolicy({ open, handleClose, editData, setConfigSnackbar }) {
  const dispatch = useDispatch();

  const dataPolicy = {
    title: "",
    details: [{ topic: "", detail: "" }],
    file: null,
    startDate: dayjs(),
    endDate: dayjs().add(1, "day"),
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(100).required("Please enter Title"),
    details: Yup.array().of(
      Yup.object().shape({
        topic: Yup.string()
          .max(150, "Topic must be at most 150 characters")
          .required("Topic is a required field"),
        detail: Yup.string()
          .max(250, "Detail must be at most 250 characters")
          .required("Detail is a required field"),
      })
    ),
    startDate: Yup.date().min(
      dayjs().startOf("day"),
      "Start date must be later than current date"
    ),
    endDate: Yup.date().when("startDate", (startDate) => {
      return Yup.date().min(
        dayjs(startDate).add(1, "day"),
        "End date must be later than start date"
      );
    }),
    file: Yup.mixed().nullable(),
  });

  const handleAddPolicy = async (values) => {
    const formData = new FormData();
    for (const key in values) {
      if (key === "details") {
        formData.append(key, JSON.stringify(values[key]));
      } else {
        formData.append(key, values[key]);
      }
    }

    let res = null;
    if (editData) {
      res = await dispatch(updatePolicy(formData));
    } else {
      res = await dispatch(addPolicy(formData));
    }

    if (res && res.status === 201) {
      handleClose();
      dispatch(getPolicyByIdCompany());
      if(editData) {
        setConfigSnackbar({ open: true, message: "แก้ไข company policy สำเร็จ", color: "success" });
      } else {
        setConfigSnackbar({ open: true, message: "สร้าง company policy สำเร็จ", color: "success" });
      }
    } else {
      setConfigSnackbar({ open: true, message: "เกิดข้อผิดพลาด", color: "error" });
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: dataPolicy,
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "details",
    control,
  });

  useEffect(() => {
    if (editData) {
      reset({ ...editData, idPolicy: editData.idPolicy });
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {editData ? "แก้ไข company policy" : "สร้าง company policy"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleAddPolicy)}>
          <Grid container spacing={2} marginTop={0}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="ชื่อ Policy"
                    error={Boolean(errors.title)}
                    helperText={errors.title && errors.title.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack gap={1}>
                {fields.map((item, index) => (
                  <Fragment key={item.id}>
                    <div style={{ display: "flex", gap: 8 }}>
                      <Controller
                        name={`details.${index}.topic`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={`ชื่อหัวข้อที่ ${index + 1}`}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error && fieldState.error.message}
                          />
                        )}
                      />
                      <IconButton color="error" onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    <Controller
                      name={`details.${index}.detail`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label={`รายละเอียดหัวข้อที่ ${index + 1}`}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                        />
                      )}
                    />
                    <Divider sx={{ width: "100%", border: "1px solid #D0D0D0", borderStyle: "dashed" }} />
                  </Fragment>
                ))}
              </Stack>
              <Button
                disabled={fields.length >= 15}
                startIcon={<AddIcon />}
                onClick={() => append({ topic: "", detail: "" })}
              >
                เพิ่มหัวข้อ
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    onChange={field.onChange}
                    acceptFileType={"image/jpeg, image/png, application/pdf"}
                    acceptFileTypeLabel={"jpg, png and pdf"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction={"row"} spacing={2}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label="วันเริ่มประกาศ"
                        minDate={dayjs()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.startDate}
                            helperText={errors.startDate && errors.startDate.message}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label="วันสิ้นสุดประกาศ"
                        minDate={dayjs(watch("startDate")).add(1, "day")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.endDate}
                            helperText={errors.endDate && errors.endDate.message}
                          />
                        )}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <DialogActions>
            <Button type="button" color="error" onClick={handleClose}>
              ยกเลิก
            </Button>
            <ButtonBlue type="submit" variant="contained">
              ยืนยัน
            </ButtonBlue>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
