import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  DialogContent,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { addAnnouncement, getAnnouncementByIdCompany, updateAnnouncement } from "../../../../actions/announcement";
import UploadFile from "./uploadFile";
import ButtonBlue from "../../shared/general/ButtonBlue";

export default function CreateAnnouncement({ open, handleClose, editData, setConfigSnackbar }) {
  const dispatch = useDispatch();

  const dataAnnouncement = {
    title: "",
    detail: "",
    file: null,
    startDate: dayjs(),
    endDate: dayjs().add(1, "day"),
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(100).required("Please enter Topic"),
    detail: Yup.string().max(400),
    startDate: Yup.date().min(
      dayjs().startOf("day"),
      "Start date must be later than current date"
    ),
    endDate: Yup.date().when("startDate", (startDate) => {
      return Yup.date().min(
        dayjs(startDate).add(1, "day"),
        "End date must be later than start date"
      );
    }),
    file: Yup.mixed().nullable(),
  });

  const handleAddAnnouncement = async (values) => {
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }

    let res = null
    if(editData) {
      res = await dispatch(updateAnnouncement(formData));
    } else {
      res = await dispatch(addAnnouncement(formData));
    }

    if (res && res.status === 201) {
      handleClose();
      dispatch(getAnnouncementByIdCompany())
      setConfigSnackbar({ open: false, message: '', color: "success" });
    } else {
      setConfigSnackbar({ open: true, message: 'เกิดข้อผิดพลาด', color: "error" })
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: dataAnnouncement,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if(editData) {
      reset({ ...editData, idAnnouncement: editData.idAnnouncement })
    }
  }, [])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{editData ? "แก้ไขประกาศ" : "สร้างประกาศ"}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleAddAnnouncement)}>
          <Grid container spacing={2} marginTop={0}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="หัวข้อประกาศ"
                    error={Boolean(errors.title)}
                    helperText={errors.title && errors.title.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="detail"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    label="รายละเอียด"
                    error={Boolean(errors.detail)}
                    helperText={errors.detail && errors.detail.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    onChange={field.onChange}
                    acceptFileType={"image/jpeg, image/png, application/pdf"}
                    acceptFileTypeLabel={"jpg, png and pdf"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction={"row"} spacing={2}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label="วันเริ่มประกาศ"
                        minDate={dayjs()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.startDate}
                            helperText={errors.startDate && errors.startDate.message}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label="วันสิ้นสุดประกาศ"
                        minDate={dayjs(watch("startDate")).add(1, "day")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.endDate}
                            helperText={errors.endDate && errors.endDate.message}
                          />
                        )}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <DialogActions>
            <Button type="button" color="error" onClick={handleClose}>
              ยกเลิก
            </Button>
            <ButtonBlue type="submit" variant="contained">
              ยืนยัน
            </ButtonBlue>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
