import { httpClient } from "./httpClient";

const getJobRequestByIdJobRequest = (idjobRequest) => {
  return httpClient.get("job-recruit/" + idjobRequest);
};

const getJobForm = () => {
  return httpClient.get("jobs/jobForm");
};

const closeJob = (idJob) => {
  return httpClient.patch(`job-recruit/${idJob}/close-job`);
};

const addJob = (formData) => {
  return httpClient.post("job-recruit", formData);
};

const getJobGroup = () => {
  return httpClient.get("job-recruit/job-group");
};

const getCompanyMyJob = () => {
  return httpClient.get(`job-recruit-companies`);
};

const updateJob = (idJob, formData) => {
  return httpClient.patch(`job-recruit/${idJob}`, formData);
};

const getJobEdit = (idJob) => {
  return httpClient.get(`job-recruit/${idJob}/edit`);
};

const getJobsManager = () => {
  return httpClient.get(`job-recruit-manager`);
};

const approveJobs = (data) => {
  return httpClient.put("job-recruit-approve", data);
};

const getJobGroupInterviewer = () => {
  return httpClient.get("job-recruit-interviewer");
};

const addJobGroupInterviewer = (data) => {
  return httpClient.post("job-recruit-interviewer", data);
};

const deleteJobGroupInterviewer = (idJobGroup, idEmployees) => {
  return httpClient.delete(`job-recruit-interviewer/${idJobGroup}/${idEmployees}`);
};

export default {
  getJobRequestByIdJobRequest,
  getJobForm,
  closeJob,
  addJob,
  getJobGroup,
  getCompanyMyJob,
  updateJob,
  getJobEdit,
  getJobsManager,
  approveJobs,
  getJobGroupInterviewer,
  addJobGroupInterviewer,
  deleteJobGroupInterviewer,
};
