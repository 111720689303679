const downloadHeaderExcel = {
  A1: "Employee ID",
  B1: "คำนำหน้า",
  C1: "ชื่อ",
  D1: "นามสกุล",
  E1: "Title",
  F1: "Name",
  G1: "Last name",
  H1: "Nickname",
  I1: "Gender",
  J1: "Personal ID",
  K1: "สัญชาติ",
  L1: "สถานะ",
  M1: "Passport Number",
  N1: "Work Permit Number",
  O1: "Tax ID",
  P1: "SSO ID",
  Q1: "Birthday",
  R1: "บ้านเลขที่",
  S1: "หมู่บ้าน",
  T1: "หมู่ที่",
  U1: "ซอย",
  V1: "ถนน",
  W1: "ตำบล/แขวง",
  X1: "อำเภอ/เขต",
  Y1: "จังหวัด",
  Z1: "รหัสไปรณีย์",

  AA1: "Telephone Mobile",
  AB1: "Email",
  AC1: "ผู้ติดต่อหลัก",
  AD1: "ความสัมพันธ์",
  AE1: "เบอร์ติดต่อผู้ใกล้ชิด",
  AF1: "Number of Children",
  AG1: "Spouse's First Name",
  AH1: "Spouse's Last Name",
  AI1: "Spouse Birthday",
  AJ1: "Spouse's Tax ID",
  AK1: "Employment Type",
  AL1: "Employment Time",
  AM1: "บันทึกเวลาทำงาน",
  AN1: "Working Location",
  AO1: "Main Working Location Point",
  AP1: "Field/Office",
  AQ1: "Job Level",
  AR1: "Personnel Level Group",
  AS1: "Personnel Level",
  AT1: "Job Group",
  AU1: "Sub Job Group",
  AV1: "Job Position",
  AW1: "Company",
  AX1: "Branch",
  AY1: "Division",
  AZ1: "Department",

  BA1: "Section",
  BB1: "Hiring Date",
  BC1: "Reporting Manager Level 1",
  BD1: "Manager Position Level 1",
  BE1: "Manager E-Mail Level 1",
  BF1: "Reporting Manager Level 2",
  BG1: "Manager Position Level 2",
  BH1: "Manager E-Mail Level 2",
  BI1: "Working Type",
  BJ1: "Shift Type",
  BK1: "Shift Name",
  BL1: "Working Time In",
  BM1: "Working Time Out",
  BN1: "Payment Type",
  BO1: "Payment Round",
  BP1: "Salary",
  BQ1: "Last Job Position Change Date",
  BR1: "Last Salary Increase",
  BS1: "Last Salary Increase Date",
  BT1: "Last Special Adjustment Increase",
  BU1: "Last Special Adjustment Increase Date",
  BV1: "Last Job Level Increase Date",
  BW1: "Last Personnal Level Increase Date",
  BX1: "Contract Type",
  BY1: "Retirement Date",
  BZ1: "Contract Termainatoin Date",

  CA1: "Status",
  CB1: "Resign Date",
  CC1: "OESY(Y)",
  CD1: "OESY(M)",
  CE1: "OESY",
  CF1: "Serv.(Y)",
  CG1: "Serv.(M)",
  CH1: "ServYr",
  CI1: "ESY(Y)",
  CJ1: "ESY(M)",
  CK1: "ESY",
  CL1: "TIG(Y)",
  CM1: "TIG(M)",
  CN1: "TIG",
  CO1: "Age(Y)",
  CP1: "Age(M)",
  CQ1: "Age",
  CR1: "(1)\nEducation\nDegree",
  CS1: "(1)\nEducation\nSchool or University",
  CT1: "(1)\nFaculty",
  CU1: "(1)\nMajor",
  CV1: "(1)\nFrom Year",
  CW1: "(1)\nEnd Year",
  CX1: "(1)\nGPA",
  CY1: "(2)\nEducation\nDegree",
  CZ1: "(2)\nEducation\nSchool or University",

  DA1: "(2)\nFaculty",
  DB1: "(2)\nMajor",
  DC1: "(2)\nGPA",
  DD1: "(2)\nFrom Year",
  DE1: "(2)\nEnd Year",
  DF1: "(3)\nEducation\nDegree",
  DG1: "(3)\nEducation\nSchool or University",
  DH1: "(3)\nFaculty",
  DI1: "(3)\nMajor",
  DJ1: "(3)\nGPA",
  DK1: "(3)\nFrom Year",
  DL1: "(3)\nEnd Year",
  DM1: "Performance Review Year (Past 1 Year)",
  DN1: "Performance Review Year (Past 2 Year)",
  DO1: "Performance Review Year (Past 3 Year)",
  DP1: "Performance Review Year (Past 4 Year)",
  DQ1: "Performance Review Year (Past 5 Year)",
  DR1: "Potential Review Year (Past 1 Year)",
  DS1: "Potential Review Year (Past 2 Year)",
  DT1: "Potential Review Year (Past 3 Year)",
  DU1: "Leader Role",
  DV1: "Cost Center Charge",
  DW1: "Cost Element Charge",
  DX1: "IO",
  DY1: "Book Bank",
  DZ1: "Book Bank Branch Name",

  EA1: "Book ID",
  EB1: "Report ส่งธนาคาร ชื่อธนาคาร",
  EC1: "Report ส่งธนาคาร รหัสธนาคาร",
  ED1: "Report ส่งธนาคาร เลขที่บัญชี",
  EE1: "Report ส่งธนาคาร ชื่อผู้รับเงิน",
  EF1: "Report ส่งธนาคาร อ้างอิง",
  
  // CA1: "(2) From Year",
  // CB1: "(2) End Year",
  // CC1: "(2) GPA",
  // CD1: "(3) Education Degree",
  // CE1: "(3) Education School ot University",
  // CF1: "(3) Faculty",
  // CG1: "(3) Major",
  // CH1: "(3) From Year",
  // CI1: "(3) End Year",
  // CJ1: "(3) GPA",
  // CK1: "Performance Review Year (Past 1 Year)",
  // CL1: "Performance Review Year (Past 2 Year)",
  // CM1: "Performance Review Year (Past 3 Year)",
  // CN1: "Performance Review Year (Past 4 Year)",
  // CO1: "Performance Review Year (Past 5 Year)",
  // CP1: "Cost Center Charge",
  // CQ1: "Cost Element Charge",
  // CR1: "IO",
  // CS1: "Owner",
  // CT1: "บริษัทเจ้าของงาน",
  // CU1: "Book Bank",
  // CV1: "Book Bank Branch Name",
  // CW1: "Book ID",
};

export default downloadHeaderExcel;
