import { Box, Grid, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import DrawerCustom from "../../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import * as yup from "yup";
import  { yupResolver } from "@hookform/resolvers/yup";
import { addMDOffice, getOrganizationStructure } from "../../../../../actions/organizationStructure";
import { useDispatch } from "react-redux";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
})

const DialogEditCEO = (props) => {

  const { drawerConfig, onClose, data, setAlertConfig } = props;

  const dispatch = useDispatch();

  const validateYupSchema = yup.object({
    // nodeType: yup.string().required("กรุณากรอกข้อมูล"),
    mdOfficeName: yup.string().required("กรุณากรอกข้อมูล"),
    // idParent: yup.string().required("กรุณากรอกข้อมูล"),
    // parentNodeType: yup.string().required("กรุณากรอกข้อมูล"),
    // parentNode: yup.object().shape({
    //   nodeType: yup.string(),
    // }).nullable().required("กรุณากรอกข้อมูล"),
    costCenter: yup.string(),
    // idManager: yup.string().required("กรุณากรอกข้อมูล"),
  })

  const useHookForm = useForm({
    defaultValues: {
      mdOfficeName: "",
      // idParent: "",
      // idParentNode: "",
      // parentNode: null,
      costCenter: "",
      // parentNodeType: "",
      // idManager: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const onSubmit = async (data) => {

    const formData = {
      idMDOffice: drawerConfig.data.idMDOffice || null,
      mdOfficeName: data.mdOfficeName,
      costCenter: data.costCenter,
    }

    const result = await dispatch(addMDOffice(formData))

    if(result){
      if(result.status === 200){
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: "บันทึกข้อมูลสำเร็จ"
        }))
        dispatch(getOrganizationStructure())
        onClose();
      } else {
        setAlertConfig(prev => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        }))
      }
    } else {
      setAlertConfig(prev => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      }))
    }

  }

  useEffect(() => {
    if(drawerConfig.isOpen === true){
      if(drawerConfig.isEdit){
        useHookForm.reset({
          mdOfficeName: drawerConfig.data.name,
          costCenter: drawerConfig.data.costCenter,
        })
      } else {
        useHookForm.reset({
          mdOfficeName: "",
          costCenter: "",
        })
      }
    }

  }, [drawerConfig.isOpen])

  return (
    <DrawerCustom
      title={drawerConfig.isEdit? "แก้ไข CEO Office": "เพิ่ม CEO Office"}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box marginBottom="4px" display="flex" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight="500">ชื่อ</Typography>
              </Box>
              <Controller
                name="mdOfficeName"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box marginBottom="4px" display="flex" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight="500">Cost Center</Typography>
              </Box>
              <Controller
                name="costCenter"
                control={useHookForm.control}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      placeholder: "(ไม่บังคับ)"
                    }}
                    helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                    error={useHookForm.formState.errors[field.name]? true: false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} container gap="8px" justifyContent="flex-end">
              <ButtonBlue onClick={onClose}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>บันทึกข้อมูล</ButtonBlue>
            </Grid>

          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DialogEditCEO;