import React from "react";
import { Box, Typography, styled } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const StyledRoot = styled(Box)({
  height: "150px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  userSelect: "none",
  cursor: "pointer",
  color: "rgba(0, 0, 0, 0.23)",
  transition: "all 0.125s ease-in",
  "& .quarter-text": {
    fontSize: "20px",
    fontWeight: 500,
    color: "inherit",
  },
  "&.selected": {
    borderColor: "#46cbe2",
    color: "#46cbe2",
    // border: "none",
    "& .MuiSvgIcon-root": {
      transition: "all 0.0625s ease-in",
      fontSize: "48px",
    },
  }
})

const QuarterSelect = (props) => {

  const { value, selected, onClick } = props;

  return (
    <StyledRoot className={selected? "selected": undefined} onClick={onClick}>
      <LocationOnIcon />
      <Typography className="quarter-text">Q{value}</Typography>
    </StyledRoot>
  )
}

export default QuarterSelect;