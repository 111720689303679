import React, { useEffect, useState } from "react";
import { Box, Dialog, IconButton, styled, Switch, Typography } from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import { useDispatch, useSelector } from "react-redux";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { updateVendorManage } from "../../../../../actions/employee";
 import QRCode from "qrcode"; 

const StyledRoot = styled(Box)(({theme}) => ({
  maxWidth: "100%",
  width: "600px",
  minWidth: 0,
  boxSizing: "border-box",
}))

const DialogLinkRegisterManpower = (props) => {

  const { open, onClose, isOpenRegisterManpower, setIsOpenRegisterManpower } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isSuccess, setIsSucess] = useState(false);
  const { result : userMe } = useSelector(state => state.userProfile);
  const [qrcodeSrc, setQrcodeSrc] = useState(null)

  const dispatch = useDispatch();

   useEffect(async () => {
    
    const canvas = await QRCode.toCanvas(`${process.env.REACT_APP_PUBLIC_URL}manpower/register_manpower?vendor_no=${userMe.idEmployees}&vendor_name=${userMe.firstname_TH}`, {scale: 8})
    const imgDim={width:120,height:120}; //logo dimention
    const context = canvas.getContext('2d');
    const imageObj = new Image();  
    imageObj.src = '/assets/ANT JOB QRCODE.png';
    imageObj.onload = () => {
      context.drawImage(imageObj, 
        canvas.width / 2 - imgDim.width / 2,
        canvas.height / 2 - imgDim.height / 2,imgDim.width,imgDim.height
      );

    };
    setQrcodeSrc(canvas)

  }, [])
 
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
    >
      <StyledRoot padding="16px" minWidth="600px" boxSizing="border-box">
        <Typography variant="h6" marginBottom="24px">ลิงค์รับสมัครกำลังพล</Typography>

        <Box display="flex" alignItems="center">
          <Typography flexGrow={1}>เปิดรับสมัครกำลังพล</Typography>
          <Switch
            checked={isOpenRegisterManpower}
            onClick={()=>{
              dispatch(updateVendorManage({isOpenRegisterManpower: !isOpenRegisterManpower})).then(res => {
                if(res.status === 200){
                  setIsOpenRegisterManpower(!isOpenRegisterManpower)
                }
              })
            }}
          /> 
        </Box>
        <Typography fontSize="14px" color="text.third">URL แบบฟอร์มสมัครกำลังพล</Typography>
        <Box display="flex" alignItems="center">
          <TextFieldTheme
            sx={{
              "& input": {
                padding: "8px!important",
                fontSize: 14,
              }
            }}
            inputProps={{
              readOnly: true,
            }}
            // InputProps={{
            //   endAdornment: (
            //     <Box display="flex" alignItems="center" sx={{"& .MuiInputAdornment-root": {margin: 0}, "cursor": "pointer"}} onClick={()=>{navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL}manpower/register_manpower?vendor_no=${userMe.idVendor}&vendor_name=${userMe.vendorNameShort}`)}}>
            //       <i className="fa-regular fa-copy"></i>
            //     </Box>
            //   )
            // }}
            value={`${process.env.REACT_APP_PUBLIC_URL}manpower/register_manpower?vendor_no=${userMe.idEmployees}&vendor_name=${userMe.firstname_TH}`}
          />
          <IconButton onClick={()=>{navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL}manpower/register_manpower?vendor_no=${userMe.idEmployees}&vendor_name=${userMe.firstname_TH}`)}}>
            <i className="fa-regular fa-copy"></i>
          </IconButton>
        </Box>
        {qrcodeSrc && <Box marginTop="24px">
          <Box display="flex" alignItems="center" marginBottom="16px">
            <Typography flexGrow="1" fontSize="14px" color="text.third">QR Code</Typography>
            <ButtonBlue
              variant="outlined"
              startIcon={<i className="fa-regular fa-down"></i>}
              onClick={() => {
                const link = document.createElement("a");
                link.download = "text.png";
                link.href = qrcodeSrc.toDataURL();
                link.click();
              }}>ดาวน์โหลด QR Code</ButtonBlue>
          </Box>
          <Box textAlign="center" sx={{"& img": {width: 200, height: 200}}}>
            <img src={qrcodeSrc.toDataURL()} />
          </Box>
        </Box>}
      </StyledRoot>
    </Dialog>
  )
}

export default DialogLinkRegisterManpower