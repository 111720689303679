import React, { useEffect, useState } from "react";
import { Box, Container, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@mui/material";
// import PlanningPanel from "./PlanningPanel";
import CardStyle from "../../../../shared/general/Card";
import { useDispatch } from "react-redux";
import { getAllObjectiveForEvaluation } from "../../../../../../actions/okrs";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Link } from "react-router-dom";

const StyledRoot = styled(Box)({

})

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const columns = [
  {
    name: "ชื่อ-สกุล",
    minWidth: "230px",
    width: "230px",
    cellRender: (row) => (
      <Box display="flex" alignItems="center">
        {/* <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/> */}
        <Box flexGrow={1}>
          <Typography>{row.firstname_TH} {row.lastname_TH}</Typography>
          <Typography color="text.third" fontSize="14px">{row.positionName}</Typography>
        </Box>
      </Box>
    )
  },
  {
    name: "วัตถุประสงค์",
    minWidth: "230px",
    width: "230px",
    cellRender: (row) => (
      <Box display="flex" alignItems="center">
        {/* <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/> */}
        <Box flexGrow={1}>
          <Typography>{row.objectiveName}</Typography>
          <Typography color="text.third" fontSize="14px">{row.positionName}</Typography>
        </Box>
      </Box>
    )
  },
  {
    name: "ไตรมาส",
    minWidth: "230px",
    width: "230px",
    cellRender: (row) => (
      <Box display="flex" alignItems="center">
        {/* <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/> */}
        <Box flexGrow={1}>
          <Typography>Q{row.quarter}</Typography>
        </Box>
      </Box>
    )
  },
  {
    name: "จำนวนผลลัพธ์หลัก",
    minWidth: "230px",
    width: "230px",
    cellRender: (row) => (
      <Box display="flex" alignItems="center">
        {/* <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/> */}
        <Box flexGrow={1}>
          <Typography>{row.keyResults.length}</Typography>
          <Typography color="text.third" fontSize="14px">{row.positionName}</Typography>
        </Box>
      </Box>
    )
  },
  {
    name: "",
    minWidth: "230px",
    width: "230px",
    cellRender: (row) => (
      <Box display="flex" alignItems="center">
        {/* <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageURL}/> */}
        <Box flexGrow={1}>
          {/* <Typography>{row.firstname_TH} {row.lastname_TH}</Typography>
          <Typography color="text.third" fontSize="14px">{row.positionName}</Typography> */}
          
          <Link to={`/okrs/evaluation/individual/${row.idIndividualObjective}`}>
            <IconButton>
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </Link>
        </Box>
      </Box>
    )
  },
]

const EvaluationIndividualPage = () => {

  const dispatch = useDispatch();

  const [evaluationList, setEvaluationList] = useState([]);

  useEffect(() => {
    dispatch(getAllObjectiveForEvaluation()).then(res => {
      if(res.status == 200){
        setEvaluationList(res.data)
      }
    })
  }, [])

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <CardStyle>
            <Box padding="24px">
              <Box paddingBottom="24px">
                <Typography fontSize="24px">รายการประเมิน เป้าหมายรายบุคคล</Typography>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {
                        columns.map((c, index) => (
                          <StyledCellHeader
                            key={index}
                            sx={{
                              textAlign: c.headerTextAlign? c.headerTextAlign: undefined,
                              minWidth: c.minWidth? c.minWidth: undefined,
                              width: c.width? c.width: undefined,
                              maxWidth: c.maxWidth? c.maxWidth: undefined,
                            }}
                          >
                            {c.name}
                          </StyledCellHeader>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {evaluationList.map((r, index) => (
                      <TableRow key={index}>
                        {columns.map((c, cIndex) => (
                          <StyledCellContent
                            key={cIndex}
                            style={{
                              textAlign: c.cellTextAlign? c.cellTextAlign: undefined,
                              fontSize: c.cellFontSize? c.cellFontSize: undefined,
                              minWidth: c.minWidth? c.minWidth: undefined,
                              width: c.width? c.width: undefined,
                              maxWidth: c.maxWidth? c.maxWidth: undefined,
                            }}
                          >
                            {c.cellRender ? c.cellRender(r): c.fieldKey? <Typography fontSize="inherit">{r[c.fieldKey]}</Typography>: null}
                          </StyledCellContent>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CardStyle>
        </Container>
      </Box>
    </StyledRoot>
  )
}

export default EvaluationIndividualPage;