import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";

import {
  Grid,
  Button,
  Container,
  Paper,
  Typography,
  Avatar,
  IconButton,
  Box,
  Divider,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ArticleIcon from "@mui/icons-material/Article";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";

import { getAllWork } from "../../../../actions/work";
import { getAllEmployees } from "../../../../actions/employee";
import { getUserProfile } from "../../../../actions/user";
import { getAllCareerPlan } from "../../../../actions/careerPlan";

import CardStyle from "../../shared/general/Card";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    marginBottom: "50px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },

  paper: {
    margin: 20,
    padding: 20,
  },

  avatar: {
    width: 140,
    height: 140,
    borderRadius: 180,
  },
  name: {
    fontSize: 21,
    fontWeight: "bold",
    marginTop: 10,
  },
  position: {
    fontSize: 18,
    fontWeight: "bold",
  },
  Topic: {
    marginTop: 20,
    fontSize: 19,
    fontWeight: "bold",
    color: "#007afc",
  },
  viewBtn: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "3rem",
  },
  careerPlan: {
    marginTop: 32,
    marginBottom: 8,
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    padding: 8,
  },
  suggest: {
    padding: 8,
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

const Career = () => {
  //use style in name of classes
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: dataWork } = useSelector((state) => state.dataWork);
  const { result: empStore } = useSelector((state) => state.employees);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: careerPlanStore } = useSelector((state) => state.careerPlan);

  const [employees, setEmployees] = useState([]);

  let history = useHistory();

  const handleOnSelectEmp = (val) => {
    history.push({
      pathname: "/myCareerePlan",
      careerProfile: val,
    });
  };

  const fetchData = async () => {
    let empList = [...empStore];
    let empFilter = empList.filter(function (x) {
      //Testing
      //const report = "suracpoj@scg.com";

      //Production
      const report = userProfile.email;

      //ManagerEmail and scgPL = "O5"
      return (
        x.managerEmail.toLowerCase() === report.toLowerCase() &&
        (x.scgPL === "O5" || x.scgPL === "S3" || x.scgPL === "S4")
      );
    });

    let careerPlans = [...careerPlanStore];

    empFilter.forEach(function (emp) {
      //empIntStatus
      let empCareer = careerPlans.filter(
        (career) => career.idEmployees === emp.idEmployees
      );

      if (empCareer[0] !== undefined) {
        emp.empIntStatus = empCareer[0].empIntStatus;
        emp.managerSugStatus = empCareer[0].managerSugStatus;
        emp.dateUpdate = empCareer[0].dateUpdate;
      } else {
        emp.empIntStatus = false;
      }
    });

    console.log("empFilter", empFilter);

    setEmployees(empFilter);
  };

  useEffect(() => {
    if (empStore) {
      fetchData();
    }
  }, [empStore]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getAllWork());
      dispatch(getUserProfile(currentUser.username));
      dispatch(getAllCareerPlan());
      dispatch(getAllEmployees());
    }
  }, []);

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
          พนักงาน
        </Typography>
        <Grid container spacing={2}>
          {employees.length > 0 ? (
            employees.map((val, index) => (
              <Grid key={val.id} item xl={3} lg={4} md={6} sm={6} xs={12}>
                <CardStyle style={{ height: "100%", cursor: "pointer" }}>
                  <div
                    style={{ padding: "24px 16px 16px 16px" }}
                    onClick={() => handleOnSelectEmp(val)}
                  >
                    <Grid container>
                      <Grid item xl={12} md={12} xs={12}>
                        <center>
                          <Avatar
                            alt={`${val.firstName} ${val.lastName}`}
                            src={`${process.env.REACT_APP_API_URL}image/profile/${val.avatarImg}`}
                            variant={"square"}
                            className={classes.avatar}
                          />
                          <Typography className={classes.name}>
                            {val.firstName} {val.lastName}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "#999999" }}>
                            {val.duty}
                          </Typography>
                        </center>
                      </Grid>
                    </Grid>
                    <Box className={classes.careerPlan}>
                      <Typography
                        variant="body1"
                        sx={{ color: "#9e9e9e" }}
                        className={classes.wrapIcon}
                      >
                        <ArticleIcon sx={{ marginRight: 1 }} />
                        แผนสายงาน
                      </Typography>
                    </Box>
                    <Box className={classes.suggest}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography variant="body1">สายงานที่สนใจ</Typography>
                        </Grid>
                        <Grid item>
                          {val.empIntStatus ? (
                            <Typography variant="body1">
                              <CheckCircleIcon sx={{ color: "#66bb6a" }} />
                            </Typography>
                          ) : (
                            <Typography variant="body1">
                              <CancelIcon sx={{ color: "#ef5350" }} />
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                    <Box className={classes.suggest} sx={{ marginBottom: 3 }}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography variant="body1">หัวหน้าแนะนำ</Typography>
                        </Grid>
                        <Grid item>
                          {val.managerSugStatus ? (
                            <Typography variant="body1">
                              <CheckCircleIcon sx={{ color: "#66bb6a" }} />
                            </Typography>
                          ) : (
                            <Typography variant="body1">
                              <CancelIcon sx={{ color: "#ef5350" }} />
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    {val.updateSuggestJob != "" && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "8px",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "#bdbdbd", fontSize: "0.875rem" }}
                        >
                          Last update :{" "}
                          {val.dateUpdate
                            ? dayjs(val.dateUpdate).format("DD/MM/YYYY")
                            : "-"}
                        </Typography>
                      </Box>
                    )}
                  </div>
                </CardStyle>
              </Grid>
            ))
          ) : (
            <Box
              sx={{
                marginTop: 4,
                width: "100%",
              }}
            >
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <DescriptionIcon sx={{ marginRight: 1 }} />
                    ไม่มีข้อมูลพนักงาน
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default Career;
