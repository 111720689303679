import { httpClient } from "./httpClient";

const getCostElement = () => {
    return httpClient.get(`/getCostElement`);
};

const getCostCenterAndCostElement = () => {
    return httpClient.get(`/getCostCenterAndCostElement`);
};

const getDepartmentOFCostElement = () => {
    return httpClient.get(`/getDepartmentOFCostElement`);
};

const getTypeOFCostElement = () => {
    return httpClient.get(`/getTypeOFCostElement`);
};

const putCostElement = (id, data) => {
    return httpClient.put(`/putCostElement/${id}`, data);
};

const postCostElement = (data) => {
    return httpClient.post(`/postCostElement`, data);
};

export default {
    getCostElement,
    getCostCenterAndCostElement,
    getDepartmentOFCostElement,
    getTypeOFCostElement,
    putCostElement,
    postCostElement,
};
