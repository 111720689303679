import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, styled, Grid, IconButton, Divider } from "@mui/material";
import dayjs from "dayjs";

import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import utils from "../../../../../../utils";

import DialogEdit from "./DialogEdit";

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledRoot = styled("div")({
  "& .salary": {
    "& .MuiTypography-h6": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "baseline",
  },
});

const SettingPayroll = (props) => {
  const { changeGroupTap, changeTabs } = props;
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("");


  const handleClick = (mode) => {
    console.log("handleClick");
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const changeTimeToDate = (time) => {
    let splitTime = time.split(":");
    return new Date().setHours(splitTime[0], splitTime[1], splitTime[2]);
  };


  return (
    <StyledRoot>
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          ส่วนจ่ายเงินเดือน
        </StyledHeadLabel>
        <div>
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            // onClick={() => handleClick("cost")}
            onClick={() => {
              handleClick("payroll");
            }}
          >
            แก้ไข
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              คำนวณเงินเดือน ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isPayroll ? "ใช่" : "ไม่ใช่"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          ส่วนกองทุนสำรองเลี้ยงชีพ
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              คำนวณกองทุนสำรองเลี้ยงชีพ ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isPf ? "ใช่" : "ไม่ใช่"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          ส่วนภาษีเงินได้
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              คำนวณภาษีเงินได้ ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isTax ? "ใช่" : "ไม่ใช่"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              การจ่ายภาษีเงินได้ ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isSelfTaxPaid ? "พนักงานจ่ายภาษีเงินได้" : "บริษัทจ่ายภาษีเงินได้"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              เงินได้ตามมาตรา ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.incomeUnderSection || "ไม่ระบุ"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          ส่วนประกันสังคม
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              คำนวณประกันสังคม ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isSso ? "ใช่" : "ไม่ใช่"}
            </Typography>
          </div>
        </Grid>
      </Grid>

      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </StyledRoot>
  );
};

export default SettingPayroll;
