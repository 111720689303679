import {
    ADMIN_SETTINGPF_FETCHING,
    ADMIN_SETTINGPF_FAILED,
    ADMIN_SETTINGPF_SUCCESS
} from './types';

import SettingPFService from '../services/settingPF.service';

export const getAdminSettingPF = () => async (dispatch) => {
    try {
        dispatch({
            type: ADMIN_SETTINGPF_FETCHING
        });
        const res = await SettingPFService.getAdminSettingPF();
        if (res) {
            dispatch({
                type: ADMIN_SETTINGPF_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: ADMIN_SETTINGPF_FAILED
        });
    }
};

export const addNewAdminSettingPF = (formData) => async () => {
    try {
        const res = await SettingPFService.addNewAdminSettingPF(formData);
        if (res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const updateAdminSettingPF = (formData) => async () => {
    try {
        const res = await SettingPFService.updateAdminSettingPF(formData);
        if (res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const deleteAdminSettingPF = (idSettingPF) => async () => {
    try {
        const res = await SettingPFService.deleteAdminSettingPF(idSettingPF);
        if (res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const updateAdminSchedulePF = (formData) => async () => {
    try {
        const res = await SettingPFService.updateAdminSchedulePF(formData);
        if (res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const addNewAdminSchedulePF = (formData) => async () => {
    try {
        const res = await SettingPFService.addNewAdminSchedulePF(formData);
        if (res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const deleteAdminSchedulePF = (idSettingPF) => async () => {
    try {
        const res = await SettingPFService.deleteAdminSchedulePF(idSettingPF);
        if (res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};