import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { getAffiliate } from "../../../../actions/company";

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const SelectCompany = (props) => {
  let { handleChangeCompany, readOnly, variant, hideLabel, placeholder } = props;
  let dispatch = useDispatch();
  const { result: affiliateList } = useSelector(
    (state) => state.affiliate
  );

  useEffect(() => {
    dispatch(getAffiliate());
  }, []);

  return (
    <StyledBoxSearch>
      {hideLabel ? null : (
        <Typography className="label" color="text.third">
          บริษัท
        </Typography>
      )}

      <StyledAutocomplete
        options={affiliateList}
        onChange={(event, newValue) => {
          handleChangeCompany(newValue);
        }}
        popupIcon={<i class="fa-light fa-chevron-down"></i>}
        getOptionLabel={(option) => option.companyName}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant ? variant : "filled"}
            placeholder={placeholder ? placeholder : "เลือกบริษัท"}
          />
        )}
        PopperComponent={StyledPopper}
        noOptionsText={"ไม่พบข้อมูล"}
        readOnly={readOnly}
      />
    </StyledBoxSearch>
  );
};

export default SelectCompany;
