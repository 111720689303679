import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const allHoliday = (year) => {
  return httpClient.get(`/holiday/${year}`);
};

const getHoliday = (filter) => {
  return httpClient.get(
    `/holiday?start=${dayjs(filter.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};



const postHoliday = (data) => {
  return httpClient.post(`/company/holiday/postHoliday`, data);
};

const putHoliday = (data) => {
  return httpClient.put(`/holiday`, data);
};

const deleteHoliday = (id, data) => {
  return httpClient.post(`/company/holiday/deleteHoliday/${id}`, data);
};

const uploadFileHoliday = (formData) => {
  return httpClient.post(`/holiday`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
   }) ;
};

export default {
  allHoliday,
  getHoliday,
  postHoliday,
  putHoliday,
  deleteHoliday,
  uploadFileHoliday
};
