import React, { Fragment, useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Divider,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  Stack,
  Card,
  CardContent,
  CardHeader,
  Chip,
} from "@mui/material";
import { getProbationEmployeesByID } from "../../../../../../actions/employee";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import CardStyle from "../../../../shared/general/Card";

const statusColor = {
  pass: { status: "ผ่าน", color: "success" },
  nopass: { status: "ไม่ผ่าน", color: "error" },
  expand: { status: "รอประเมิน", color: "warning" },
};

const StatusShow = ({ row }) => {
  const probationResult = row.roundOne ? row.probationR1Result : row.probationR2Result;
  return (
    <Chip label={statusColor[probationResult].status} color={statusColor[probationResult].color} />
  );
};

const ProbationUser = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProbationId } = useSelector((state) => state.employeeProbationId);

  useEffect(() => {
    if (userProfile) {
      dispatch(getProbationEmployeesByID(userProfile.idEmployees));
    }
  }, []);

  return (
    <>
      {employeeProbationId &&
        employeeProbationId
          .filter(
            (probation) =>
              probation.probationR1Result && probation.probationR2Result
          )
          .map((item) => (
            <CardStyle style={{ marginBottom: 32 }}>
              <CardHeader
                title={<Typography variant="h6">{`แบบประเมินทดลองงานรอบที่ ${item.roundOne ? "1" : "2"}`}</Typography>}
              />
              <CardContent>
                <Grid container padding={2} spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Typography sx={{ display: "inline-block" }}>
                      <i className="fa-regular fa-user fa-xl"></i>
                      &nbsp;&nbsp;
                    </Typography>
                    <Typography sx={{ display: "inline-block" }}>
                      {`ผู้ถูกประเมิน : ${item.firstname_TH} ${item.lastname_TH}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography sx={{ display: "inline-block" }}>
                      <i className="fa-regular fa-building-columns fa-xl"></i>
                      &nbsp;&nbsp;
                    </Typography>
                    <Typography sx={{ display: "inline-block" }}>
                      {`ตำแหน่ง : ${item.positionName}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Typography sx={{ display: "inline-block" }}>
                      <i className="fa-regular fa-calendar-days fa-xl"></i>
                      &nbsp;&nbsp;
                    </Typography>
                    <Typography sx={{ display: "inline-block" }}>
                      {`วันครบทดลองงาน : ${dayjs(item.endDate).format("D MMM BBBB")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography sx={{ display: "inline-block" }}>
                      <i className="fa-regular fa-thumbtack fa-xl"></i>
                      &nbsp;&nbsp;
                    </Typography>
                    <Typography sx={{ display: "inline-block" }}>
                      {`รอบทดลองงานที่ : ${item.roundOne ? "1" : "2"}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography sx={{ display: "inline-block" }}>
                      <i className="fa-light fa-whistle fa-xl"></i>
                      &nbsp;&nbsp;
                    </Typography>
                    <Typography sx={{ display: "inline-block" }}>
                      {`ประเมินโดย : ${item.approvedBy}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: "16px", borderColor: "#9e9e9e66" }} />
                <Stack padding={2} spacing={2}>
                  {item.groupProbation &&
                    item.groupProbation.map((data, index) => (
                      <Fragment key={data.id}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                          <i className="fa-regular fa-square-question fa-xl"></i>
                          <Typography>{`คำถามที่ ${index + 1} : ${data.question}`}</Typography>
                        </Stack>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          value={data.answer}
                        >
                          <FormControlLabel
                            value="verygood"
                            control={<Radio />}
                            label="ดีมาก"
                          />
                          <FormControlLabel
                            value="good"
                            control={<Radio />}
                            label="ดี"
                          />
                          <FormControlLabel
                            value="meduim"
                            control={<Radio />}
                            label="ปานกลาง"
                          />
                          <FormControlLabel
                            value="fair"
                            control={<Radio />}
                            label="พอใช้"
                          />
                          <FormControlLabel
                            value="prettybad"
                            control={<Radio />}
                            label="ค่อนข่างแย่"
                          />
                          <FormControlLabel
                            value="bad"
                            control={<Radio />}
                            label="แย่"
                          />
                        </RadioGroup>
                        <Divider sx={{ borderColor: "#9e9e9e66" }} />
                      </Fragment>
                    ))}
                </Stack>
                <Grid container padding={2} spacing={2}>
                  <Grid item xs={12}>
                    <i className="fa-regular fa-bone fa-xl"></i>
                    &nbsp;&nbsp;{`จุดแข็ง`}
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldTheme
                      multiline
                      rows={3}
                      value={item.goodPoint}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <i className="fa-regular fa-bone-break fa-xl"></i>
                    &nbsp;&nbsp;{`จุดอ่อน`}
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldTheme
                      multiline
                      rows={3}
                      value={item.weekPoint}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} marginTop={2}>
                  <Typography variant="h5">สรุปผลทดลองงาน</Typography>
                  <StatusShow row={item} />
                </Stack>
              </CardContent>
            </CardStyle>
          ))}
    </>
  );
};

export default ProbationUser;
