import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useDispatch } from "react-redux";

import {
	Box,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	styled,
	Typography
} from "@mui/material";
import {
	Add,
	Delete
} from "@mui/icons-material";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import DrawerCustom from '../../../../../shared/general/Drawer';
import TextFieldTheme from '../../../../../shared/general/TextFieldTheme';
import ButtonBlue from '../../../../../shared/general/ButtonBlue';

import { addManpowerEducation, getManpowerById, updateManpowerEducation } from "../../../../../../../actions/manpower";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import NumberFormatTheme from '../../../../../shared/general/NumberFormatTheme';

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const StyledRoot = styled("div")({
	width: 450,
	padding: 16,
});

const StyledDivider = styled(Divider)({
	margin: "15px 0",
	borderBottom: "2px dashed rgba(145, 158, 171, 0.2)"
});

const DrawerEducation = (props) => {
	const dispatch = useDispatch();
	const { open, onClose, idManpower, education, setIsSuccess, handleCountDownTimeSet, isEdit, data, idManpowerEducation } = props;
	const { control, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue, clearErrors } = useForm({

		...(!isEdit && {
			defaultValues: {
				startYear: null,
				endYear: null,
				degree: "",
				university: "",
				faculty: "",
				major: "",
				gpa: "",
			}
		}),

		...(isEdit && {
			defaultValues: {
				startYear: data.startYear? dayjs().year(data.startYear): null,
				endYear: data.endYear? dayjs().year(data.endYear): null,
				degree: data.degree || "",
				university: data.university || "",
				faculty: data.faculty || "",
				major: data.major || "",
				gpa: data.gpa || "",
			}
		}),

	});

	const handleClose = () => {
		onClose();
	};

	const onSubmitHandler = async (submitData) => {
		if(!isEdit) {
			const formData = {};

			for (const key in submitData) {
				if(key === 'startYear' || key === 'endYear') {
					formData[key] = dayjs(submitData[key]).get("year");
					continue;
				}
				formData[key] = submitData[key];
			}

			dispatch(addManpowerEducation(idManpower, formData)).then(res => {
				dispatch(getManpowerById(idManpower));
			})
			onClose();

		} else {

			const formData = {};

			for (const key in dirtyFields) {
				if(key === 'startYear' || key === 'endYear') {
					formData[key] = dayjs(submitData[key]).get("year");
					continue;
				}
				formData[key] = submitData[key];
			}

			dispatch(updateManpowerEducation(idManpower, idManpowerEducation, formData)).then(res => {
				dispatch(getManpowerById(idManpower));
			})
			onClose();
		}
	};

	return (
		<DrawerCustom
			open={open}
			title={education ? "แก้ไขประวัติการศึกษา" : "เพิ่มประวัติการศึกษา"}
			anchor="right"
		>
			<StyledRoot>
				<form onSubmit={handleSubmit(onSubmitHandler)}>

					<Grid container spacing={2}>
						
						<Grid item xs={12} sm={6}>
							<Controller
								name={`startYear`}
								control={control}
								rules={{
									validate: {
										endDateMoreThanstart: () => {
											if(!getValues(`startYear`) || !getValues(`endYear`)){
												return true;
											} else if(dayjs(getValues(`endYear`)).isSameOrAfter(dayjs(getValues(`startYear`)), 'year')){
												return true;
											} else {
												return "ไม่สามารถเลือกวันจบการศึกษาก่อนวันเริ่มการศึกษาได้"
											}
										}
									}
								}}
								render={({ field }) => (
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DesktopDatePicker
											{...field}
											placeholder="เริ่มการศึกษา"
											views={["year"]}
											openTo="year"
											maxDate={dayjs()}
											renderInput={(params) => (
												<Fragment>
												<Typography gutterBottom sx={{ color: "#ffffff" }}>เริ่มการศึกษา</Typography>
												<TextFieldTheme sx={{color:"#ffffff"}}
													{...params}
													inputProps={{ 
														...params.inputProps,
														placeholder: "เริ่มการศึกษา",
														readOnly: true
													}}
													helperText={errors && errors.startYear && errors.startYear.message}
													error={errors && errors.startYear ? true : false}
												/>
												</Fragment>
											)}
										/>
									</LocalizationProvider>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								name={`endYear`}
								control={control}
								rules={{
									validate: {
										endDateMoreThanstart: () => {
											if(!getValues(`startYear`) || !getValues(`endYear`)){
												return true;
											} else if(dayjs(getValues(`endYear`)).isSameOrAfter(dayjs(getValues(`startYear`)), 'year')){
												return true;
											} else {
												return "ไม่สามารถเลือกวันจบการศึกษาก่อนวันเริ่มการศึกษาได้"
											}
										}
									}
								}}
								render={({ field }) => (
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DesktopDatePicker
											{...field}
											placeholder="จบการศึกษา"
											views={["year"]}
											openTo="year"
											maxDate={dayjs()}
											renderInput={(params) => (
												<Fragment>
												<Typography gutterBottom sx={{ color: "#ffffff" }}>จบการศึกษา</Typography>
												<TextFieldTheme
													{...params}
													inputProps={{
														...params.inputProps,
														placeholder: "จบการศึกษา",
														readOnly: true
													}}
													helperText={errors && errors.endYear && errors.endYear.message}
													error={errors && errors.endYear ? true : false}
												/>
												</Fragment>
											)}
										/>
									</LocalizationProvider>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={`degree`}
								control={control}
								rules={{
									required: { value: true, message: "กรุณากรอกระดับการศึกษา" },
								}}
								render={({ field }) => (
									<Fragment>
									<Typography gutterBottom sx={{ color: "#ffffff" }}>ระดับการศึกษา</Typography>
									<TextFieldTheme
										{...field}
										placeholder="ระดับการศึกษา"
										helperText={errors && errors.degree && errors.degree.message}
										error={errors && errors.degree ? true : false}
										select
									>
										<MenuItem value="ประถมศึกษา">ประถมศึกษา</MenuItem>
										<MenuItem value="มัธยมศึกษา">มัธยมศึกษา</MenuItem>
										<MenuItem value="ปวช.">ปวช.</MenuItem>
										<MenuItem value="ปวส.">ปวส.</MenuItem>
										<MenuItem value="ปริญญาตรี">ปริญญาตรี</MenuItem>
										<MenuItem value="ปริญญาโท">ปริญญาโท</MenuItem>
										<MenuItem value="ปริญญาเอก">ปริญญาเอก</MenuItem>
									</TextFieldTheme>
									</Fragment>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={`university`}
								control={control}
								rules={{
									required: { value: true, message: "กรุณากรอกชื่อสถาบัน" },
								}}
								render={({ field }) => (
									<Fragment>
									<Typography gutterBottom sx={{ color: "#ffffff" }}>ชื่อสถาบัน</Typography>
									<TextFieldTheme
										{...field}
										placeholder="ชื่อสถาบัน"
										helperText={errors && errors.university && errors.university.message}
										error={errors && errors.university ? true : false}
									/>
									</Fragment>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={`faculty`}
								control={control}
								// rules={{
								// 	required: { value: true, message: "กรุณากรอกคณะ" },
								// }}
								render={({ field }) => (
									<Fragment>
									<Typography gutterBottom sx={{ color: "#ffffff" }}>คณะ</Typography>
									<TextFieldTheme
										{...field}
										placeholder="คณะ"
										helperText={errors && errors.faculty && errors.faculty.message}
										error={errors && errors.faculty ? true : false}
									/>
									</Fragment>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={`major`}
								control={control}
								// rules={{
								// 	required: { value: true, message: "กรุณากรอกสาขาวิชา" },
								// }}
								render={({ field }) => (
									<Fragment>
									<Typography gutterBottom sx={{ color: "#ffffff" }}>สาขาวิชา</Typography>
									<TextFieldTheme
										{...field}
										placeholder="สาขาวิชา"
										helperText={errors && errors.major && errors.major.message}
										error={errors && errors.major ? true : false}
									/>
									</Fragment>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={`gpa`}
								control={control}
								type="number"
								render={({ field }) => (
									<Fragment>
									<Typography gutterBottom sx={{ color: "#ffffff" }}>GPA</Typography>
									<TextFieldTheme
										{...field}
										placeholder="GPA"
										InputProps={{
											inputComponent: NumberFormatTheme,
										}}
										inputProps={{
											allowNegative: false,
											decimalScale: 2,
											isAllowed: (values) => {
												return values.value === "" || values.floatValue <= 4.00 ;
											}
										}}
										helperText={errors && errors.gpa && errors.gpa.message}
										error={errors && errors.gpa ? true : false}
									/>
									</Fragment>
								)}
							/>
						</Grid>
					</Grid>

					<div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>
						<ButtonBlue
							variant="outlined"
							onClick={handleClose}
						>ยกเลิก</ButtonBlue>
						<ButtonBlue
							type="submit"
							variant="contained"
							disabled={(isEdit && Object.keys(dirtyFields).length <= 0)}
						>ยืนยัน</ButtonBlue>
					</div>
				</form>
			</StyledRoot>
		</DrawerCustom>
	);
}

export default DrawerEducation;