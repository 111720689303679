import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import {
    Avatar,
    Box,
    styled,
    Tooltip,
    Typography
} from "@mui/material";

import ButtonBlue from '../../../../shared/general/ButtonBlue';
import CardStyle from '../../../../shared/general/Card';
import TableTheme from '../../../../shared/general/TableTheme';
import { stringToColor } from '../../../../../../utils';

const StyledContent = styled("div")({
    padding: 36,
    paddingTop: 24,
    display:"flex",
    flexDirection:"column",
    position:"relative"
});

const StyledBoxColumnName = styled(Box)({
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color:"#000000",
    "& .MuiAvatar-root": {
        marginRight: 8,
        width: 40,
        height: 40,
    },
});

const CardPassedCandidate = (props) => {
    const { AllPassedCandidates, buttonPath, isBidding } = props;

    const columnsPassedCandidateTable = [
        {
            field: "avatar&name",
            width: "320px",
            headerName: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <i className="fa-solid fa-user-group" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px" }} />
                    <Typography style={{color:"#ffffff"}}>ชื่อ-สกุล</Typography>
                </Box>
            ),
            format: (params) => (
                <Tooltip title="ดูประวัติ" placement="top-start">
                    <StyledBoxColumnName component={Link} to={`/hunter/peer/profile/${params.idManpower}`}>
                        <Avatar sx={{ bgcolor: stringToColor(`${params.name} ${params.lastname}`) }} children={`${params.name[0]}${params.lastname[0]}`} />
                        <Box>
                            <Typography>{`${params.name} ${params.lastname}`}</Typography>
                            <Typography style={{ color: "#919eab" }}>{`${params.telephoneMobile}`}</Typography>
                        </Box>
                    </StyledBoxColumnName>
                </Tooltip>
            )
        },
        ...isBidding ? [{
            field:"positionName",
            width:"250px",
            headerName: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <i className="fa-solid fa-user" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px", marginTop: "2px" }} />
                    <Typography style={{color:"#ffffff"}}>ตำแหน่งงาน</Typography>
                </Box>
            ),
            format: (params) => (
                <Box className="column-name">
                    <Typography>{params.positionName ? params.positionName : "-"}</Typography>
                </Box>
            )
        }] : [],
        {
            field: "departmentName",
            width: "300px",
            headerName: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <i className="fa-solid fa-buildings" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px", marginTop: "2px" }} />
                    <Typography style={{color:"#ffffff"}}>หน่วยงาน</Typography>
                </Box>
            ),
            format: (params) => (
                <Box className="column-name">
                    <Typography>{params.departmentName ? params.departmentName : "-"}</Typography>
                </Box>
            )
        },
        {
            field: "WorkStartDate",
            width: "215px",
            headerName: (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <i className="fa-solid fa-calendar-days" style={{ color: "#ffffff", fontSize: "1.1rem", marginRight: "10px", marginTop: "2px" }} />
                    <Typography style={{color:"#ffffff"}}>วันที่เริ่มงานวันแรก</Typography>
                </Box>
            ),
            format: (params) => (
                <Box className="column-name">
                    <Typography>{params.workStartDate ? dayjs(params.workStartDate).format("D MMM YYYY") : "-"}</Typography>
                </Box>
            )
        }
    ];

    return (
        <CardStyle>
            <StyledContent>
                <Typography variant="h5">คนที่รับเข้าทำงาน</Typography>
                {buttonPath && 
                    <ButtonBlue
                        variant="contained"
                        component={Link} 
                        to={buttonPath}
                        style={{ position:"absolute", right:"36px", top:"24px" }}
                    >ผู้สมัคร</ButtonBlue>
                }
                <Box style={{ margin: "20px 0" }}>
                    <TableTheme
                        columns={columnsPassedCandidateTable}
                        rows={AllPassedCandidates || []}
                        textEmpty="ไม่พบคนผ่านสัมภาษณ์"
                        minHeight={200}
                    />
                </Box>
            </StyledContent>
        </CardStyle>
    );
};

export default CardPassedCandidate; 