import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getAllRequestTimeById = (search) => {
  return httpClient.get(
    `request-time?filter=id&start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const getAllRequestTimeBy = (filter, search) => {
  return httpClient.get(
    `request-time`
    , {params: {filter: filter? filter: undefined, start: search && search.start? dayjs(search.start).format("YYYY-MM-DD"): undefined, end: search && search.end? dayjs(search.end).format("YYYY-MM-DD"): undefined}});
};

const getAllRequestTimeWithDrawBy = (filter, search) => {
  return httpClient.get(
    `request-time/withdraw`
    , {params: {filter: filter? filter: undefined, start: search && search.start? dayjs(search.start).format("YYYY-MM-DD"): undefined, end: search && search.end? dayjs(search.end).format("YYYY-MM-DD"): undefined}});
};

const withdrawRequestTime = (formDate) => {
  return httpClient.put(
    `request-time/withdraw`, formDate
  );
};

const getAllRequestOTBy = (filter, search) => {
  return httpClient.get(
    `request-time/ot?filter=${filter}&start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const approveRequestTime = (filter, formDate) => {
  return httpClient.put(
    `request-time/approve?filter=${filter}`, formDate
  );
};

const getAllRequestTimeCompany = (idCompany, search) => {
  return httpClient.get(
    `company/${idCompany}/requesttimes?start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const putRequestTime = (data, idRequestTime) => {
  return httpClient.put(`requestTimes/${idRequestTime}`, data);
};

const addRequestTime = (data) => {
  return httpClient.post(`requestTimes`, data);
};

const getRequestReason = (idCompany) => {
  return httpClient.get(`request/reason${idCompany ? `?idCompany=${idCompany}` : ""}`); 
}

const addRequestReason = (data) => {
  return httpClient.post(`request/add-reason`, data);
};

const updateRequestReason = (data) => {
  return httpClient.put(`request/update-reason`, data);
};

const deleteRequestReason = (idRequestReason) => {
  return httpClient.delete(`request/delete-reason/${idRequestReason}`);
};

export default {
  getAllRequestTimeById,
  getAllRequestTimeWithDrawBy,
  withdrawRequestTime,
  putRequestTime,
  addRequestTime,
  approveRequestTime,
  getAllRequestTimeBy,
  getAllRequestOTBy,
  getAllRequestTimeCompany,
  getRequestReason,
  addRequestReason,
  updateRequestReason,
  deleteRequestReason
};
