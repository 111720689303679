import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import dayjs from 'dayjs';
import {
    Container,
    Grid,
    Typography,
    TextField,
    Box,
    Popper,
    Table, TableCell, TableContainer, TableRow,
    Card,
    CardContent,
    Paper,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ButtonBlue from "../../shared/general/ButtonBlue";
import Backdrop from "../../shared/general/Backdrop";
import DialogSetting from "./component/dialogSetting";
import AlertResponse from "../../shared/general/AlertResponse";
import { getPayrollCompany, getPayrollemployee } from "../../../../actions/report";
import { getAffiliate } from "../../../../actions/company";

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    paddingBottom: 48,
});

const StyledWrapFilter = styled("div")({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media (min-width: 0px)"]: {
        flexDirection: "column",
    },
    ["@media (min-width: 900px)"]: {
        flexDirection: "row",
    },
    "& .search-name, .search-date": {
        width: "100%",
    },
    "& .wrap-search-action": {
        marginTop: 50,
        display: "flex",
        justifyContent: "flex-start",
        "& .btn-export": {
            marginLeft: 8,
        },
    },
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const options = ['ดาวน์โหลดรายบริษัท', 'ดาวน์โหลดรายคน', 'ตั้งค่า'];

const colorCard = ['#D1CCED', '#CBEBF7', ' #DCF7DE', '#FFFEDB',
    '#FFDBD1', '#F5CBDC', '#FAD2FC', '#CCCCFC',
    '#E0ECFF', '#FFFEED', '#FFEBC9', '#FFC4CD']

const Payroll = () => {

    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateList } = useSelector((state) => state.affiliate);
    const { result: payRun } = useSelector((state) => state.payRun)
    const { result: payslip } = useSelector((state) => state.payslip)
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(userProfile.idCompany);
    const [inputSearch, setInputSearch] = useState(new Date());
    const [openDrawer, setOpenDrawer] = useState(false);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [value, setValue] = useState("รายบริษัท");
    const [data, setData] = useState({
        autoEmail: null,
        ReportName: "payroll",
        idEmployees: userProfile.idEmployees
    })

    const handleClick = () => {
        if (selectedIndex == 0 || selectedIndex == 1) {
            onExportExcel()
        } else {
            setOpenDrawer(true)
        }
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        if (index == 0) {
            setValue("รายบริษัท")
        } else if (index == 1) {
            setValue("รายคน")
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    useEffect(() => {
        dispatch(getAffiliate());
    }, [])

    useEffect(() => {
        dispatch(getPayrollemployee(selectedCompany, dayjs(inputSearch).startOf('month')))
        dispatch(getPayrollCompany(selectedCompany, dayjs(inputSearch).startOf('month')))
    }, [inputSearch, selectedCompany])

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
    };

    const Render = (key, name) => {
        let value = ("item.value." + key)
        console.log(value)
        return <>
            <div style={{ marginTop: 16, alignContent: "center" }}>
                <Typography variant="h6"> {name} </Typography>
            </div>
            <TableContainer component={Paper} variant="standard" >
                <Table sx={{ borderBottom: "none" }} >
                    <TableRow>
                        {payRun && payRun.length > 0 && payRun.map((item, index) => {
                            if (item.value && eval(value))
                                return < TableCell >
                                    <Card sx={{ minWidth: 300, backgroundColor: colorCard[index] }}  variant="outlined" >
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {dayjs().set('month', (item.id)).format('MMMM')} {dayjs(inputSearch).format('YYYY')}
                                            </Typography>
                                            <Typography color="text.secondary" sx={{ mb: 1 }}>
                                                ยอดรวม
                                            </Typography>
                                            <Typography variant="body2">
                                                {`${eval(value)} (บาท)`}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </TableCell>
                        })}
                    </TableRow>
                </Table>
            </TableContainer>
        </>
    }

    const onExportExcel = () => {
        if (payRun.length > 0) {
            const elementList = [];
            if (value == "รายคน") {
                payslip.map((item, index) => {
                    let element = {}
                    element["#"] = index + 1
                    element["รหัสพนักงาน"] = item.employeeId
                    element["ชื่อ"] = item.firstname_TH
                    element["สกุล"] = item.lastname_TH
                    element["เลขบัตรประจำตัวประชาชน"] = item.personalId
                    element["ตำแหน่ง"] = item.positionName
                    element["บริษัท"] = item.companyName
                    element["ส่วน"] = item.divisionName
                    element["แผนก"] = item.sectionName
                    for (let m = 1; m < 13; m++) {
                        let month = ("item.m" + m)
                        if (eval(month)) {
                            element[`รายได้สุทธิ เดือน${m}`] = eval(month).net
                            element[`เฉพาะรายรับ เดือน${m}`] = eval(month).totalEarnings
                            element[`เฉพาะเงินหัก เดือน${m}`] = eval(month).totalDeductions
                            element[`เฉพาะหักประกันสังคม เดือน ${m}`] = eval(month).totalPF
                            element[`เฉพาะหักภาษี เดือน${m}`] = eval(month).totalTax
                        } else {
                            element[`รายได้สุทธิ เดือน${m}`] = ""
                            element[`เฉพาะรายรับ เดือน${m}`] = ""
                            element[`เฉพาะเงินหัก เดือน${m}`] = ""
                            element[`เฉพาะหักประกันสังคม เดือน${m}`] = ""
                            element[`เฉพาะหักภาษี เดือน${m}`] = ""
                        }
                    }
                    elementList.push(element);
                })
            } else if (value == "รายบริษัท") {
                let element = {}
                element["บริษัท"] = userProfile.companyName
                payRun.map((item, index) => {
                    element[`รวมจ่าย เดือน${item.id + 1}`] = item.value.earning
                    element[`เงินเดือน เดือน${item.id + 1}`] = item.value.salary
                    element[`OT เดือน${item.id + 1}`] = item.value.ot
                    element[`ค่ากะ เดือน${item.id + 1}`] = item.value.shift
                    element[`ภาษีนำส่ง เดือน${item.id + 1}`] = item.value.tax
                    element[`ประกันสังคมส่วนบริษัท เดือน${item.id + 1}`] = item.value.provident
                    element[`ประกันสังคมส่วนพนักงาน เดือน${item.id + 1}`] = item.value.provident
                    element[`PFส่วนบริษัท เดือน${item.id + 1}`] = item.value.pfCompany
                    element[`PFส่วนพนักงาน เดือน${item.id + 1}`] = item.value.pfEmployee
                    element[`เงินเพิ่ม (Addition) เดือน${item.id + 1}`] = item.value.additions
                    element[`เงินหัก (Deduction) เดือน${item.id + 1}`] = item.value.deduction
                })
                elementList.push(element);
            }
            const workSheet = XLSX.utils.json_to_sheet(elementList);
            const workBook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workBook, workSheet, `Payroll ${value} ${dayjs(inputSearch).get('year')}`);
            XLSX.writeFile(workBook, `รายงาน Payroll ${dayjs(new Date()).format("YYYY-MM-DD")}.xlsx`);
        } else {
            handleChangeAlertType("ไม่พบข้อมูล");
            handleOpenAlert(true);
        }
    }

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <div style={{ marginBottom: 8 }}>
                    <Typography variant="h4" style={{ paddingTop: 8 }}>
                        รายงาน Payroll
                    </Typography>
                </div>
                <StyledWrapFilter>
                    <Grid container spacing={2} columns={14} alignItems="center">
                        <Grid item xs={3} >
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                    บริษัท
                                </Typography>
                                <div className="search-name">
                                    {affiliateList && (
                                        <StyledAutocomplete
                                            options={affiliateList}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedCompany(newValue.idCompany);
                                                } else {
                                                    setSelectedCompany(userProfile.idCompany);
                                                }
                                            }}
                                            popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                            getOptionLabel={(option) => option.companyName}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    placeholder={"บริษัททั้งหมด"}
                                                />
                                            )}
                                            PopperComponent={StyledPopper}
                                            noOptionsText={"ไม่พบข้อมูล"}
                                        />
                                    )}
                                </div>
                            </StyledBoxSearch>
                        </Grid>
                        <Grid item xs={3} >
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                    เดือน
                                </Typography>
                                <div className="search-name">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                                        <DatePicker
                                            maxDate={new Date()}
                                            inputFormat="yyyy"
                                            value={inputSearch}
                                            name="start"
                                            views={["year"]}
                                            openTo="year"
                                            onChange={(newValue) => {
                                                setInputSearch(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <StyledTextField variant="filled" fullWidth {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </StyledBoxSearch>
                        </Grid>

                        <Grid item xs={3} >
                            <StyledBoxSearch>
                                <div className="wrap-search-action">
                                    <Fragment>
                                        <ButtonGroup ref={anchorRef} aria-label="split button">
                                            <ButtonBlue variant="outlined"
                                                onClick={handleClick}>{options[selectedIndex]}</ButtonBlue>
                                            <ButtonBlue
                                                size="small"
                                                variant="outlined"
                                                aria-controls={open ? 'split-button-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-label="select merge strategy"
                                                aria-haspopup="menu"
                                                onClick={handleToggle}
                                            >
                                                <ArrowDropDownIcon />
                                            </ButtonBlue>
                                        </ButtonGroup>
                                        <Popper
                                            sx={{
                                                zIndex: 1,
                                            }}
                                            open={open}
                                            anchorEl={anchorRef.current}
                                            role={undefined}
                                            transition
                                            disablePortal
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin: 'center top',
                                                    }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={handleClose}>
                                                            <MenuList id="split-button-menu" autoFocusItem>
                                                                {options.map((option, index) => (
                                                                    <MenuItem
                                                                        key={option}
                                                                        disabled={index === 0 && payRun === null}
                                                                        selected={index === selectedIndex}
                                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </Fragment>
                                </div>
                            </StyledBoxSearch>
                        </Grid>
                    </Grid>
                </StyledWrapFilter>
                {payRun ? <StyledRoot>
                    {Render("salary", "เงินเดือน")}
                    {Render("ot", "OT")}
                    {Render("shift", "ค่ากะ")}
                    {Render("tax", "ภาษีนำส่ง")}
                    {Render("provident", "ประกันสังคมนำส่ง")}
                    {Render("pfCompany", "กองทุน(บริษัท)นำส่ง")}
                    {Render("pfCompany", "กองทุน(พนักงาน)นำส่ง")}
                    {Render("additions", "เงินเพิ่ม")}
                    {Render("deduction", "เงินหัก")}
                    {Render("earning", "รวมจ่าย")}
                </StyledRoot>
                    : <Backdrop open={true} />}
            </Container >
            {openDrawer && <DialogSetting
                setData={setData}
                handleClose={handleCloseDrawer}
                open={openDrawer}
                data={data}
            />}
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </StyledRoot >
    )
}

export default Payroll;