import { Divider, Grid, styled, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import dayjs from "dayjs";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogEdit from "./DialogEdit";

import utils from "../../../../../../utils";
import DialogWorkingLocation from "./DialogWorkLocation";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "&.inner": {
    marginBottom: 0,
  },
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const WorkInfo = (props) => {
  const { changeGroupTap, changeTabs } = props;
  
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("");

  const [drawerWorkingLocationConfig, setDrawerWorkingLocationConfig] =
    useState({
      isOpen: false,
      isEdit: false,
      data: {},
    });

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleClickTransfer = (mode) => {
    changeGroupTap("work");
    changeTabs(6);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const calculatorESY = () => {
    let Serv = utils.getAgeNumber(
      dayjs(employeeProfile.hiringDate).format("DD/MM/YYYY")
    );

    let monthAge = (Serv.months + employeeProfile.OESY_M) % 12;
    let yearAge = Math.floor((Serv.months + employeeProfile.OESY_M) / 12);

    return `${
      Serv.years + employeeProfile.OESY_Y + yearAge
    } ปี ${monthAge} เดือน`;
  };

  const renderMethodAttendance = (value) => {
    switch (value) {
      case "point": {
        return "ทุกสถานที่ (จับพิกัด)";
      }
      case "main": {
        return "จุดทำงานหลักที่เดียว";
      }
      default: {
        return "ทุกจุดทำงานที่บริษัทกำหนด";
      }
    }
  };

  return (
    <div>
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="body2" gutterBottom>
            วันที่เข้างาน/วันต่อสัญญา
          </StyledHeadLabel>
          <div>
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditRoundedIcon />}
              onClick={() => handleClick("hiring-contract-date")}
            >
              แก้ไข
            </ButtonBlue>
          </div>
        </StyledWrapTop>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              วันที่เข้างาน
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.hiringDate
                ? dayjs(employeeProfile.hiringDate).format("DD MMMM BBBB")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              วันต่อสัญญา
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.contractTermainatoinDate
                ? dayjs(employeeProfile.contractTermainatoinDate).format(
                    "DD MMMM BBBB"
                  )
                : "-"}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        <div>
          <ButtonBlue
            variant="text"
            startIcon={<EditRoundedIcon />}
            size="small"
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={handleClickTransfer}
          >
            โอน/ย้าย
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">บริษัท</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.companyName
                ? employeeProfile.companyName
                    .replace("บริษัท ", "")
                    .replace(" จำกัด", "")
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ฝ่าย</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.divisionName
                ? employeeProfile.divisionName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">หน่วยงาน</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.departmentName
                ? employeeProfile.departmentName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">แผนก</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.sectionName ? employeeProfile.sectionName : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">ตำแหน่ง</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.positionName
                ? employeeProfile.positionName
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        <div>
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditRoundedIcon />}
            onClick={() => handleClick("experience")}
          >
            แก้ไข
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">OESY</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.OESY_Y !== null ||
              employeeProfile.OESY_M !== null ? (
                <Fragment>
                  {employeeProfile.OESY_Y && `${employeeProfile.OESY_Y} ปี`}{" "}
                  &nbsp;
                  {employeeProfile.OESY_M > 0 &&
                    `${employeeProfile.OESY_M} เดือน`}
                </Fragment>
              ) : (
                <Fragment>{`-`}</Fragment>
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">Serv.</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.hiringDate
                ? `${utils.getAgeMonthYear(
                    dayjs(employeeProfile.hiringDate).format("DD/MM/YYYY")
                  )}`
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ESY</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.hiringDate ? calculatorESY() : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">TIG</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employeePersonnelLevelStart
                ? `${utils.getAgeMonthYear(
                    dayjs(employeeProfile.employeePersonnelLevelStart).format(
                      "DD/MM/YYYY"
                    )
                  )}`
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">TIP</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employeePositionStart
                ? `${utils.getAgeMonthYear(
                    dayjs(employeeProfile.employeePositionStart).format(
                      "DD/MM/YYYY"
                    )
                  )}`
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        <div>
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditRoundedIcon />}
            onClick={
              // () => handleClick("employeeType")
              () => {
                setDrawerWorkingLocationConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  isEdit: true,
                  data: {
                    employmentTime: employeeProfile.employmentTime,
                    filed_office: employeeProfile.filed_office,
                    workingLocation: employeeProfile.workingLocation,
                    methodAttendance: employeeProfile.methodAttendance,
                    mainWorkingLocationPoint:
                      employeeProfile.mainWorkingLocationPoint,
                    isFinger: employeeProfile.isFinger,
                    isFingerAffectPayroll:
                      employeeProfile.isFingerAffectPayroll,
                  },
                }));
              }
            }
          >
            แก้ไข
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">กลุ่มงาน</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobGroupName
                ? employeeProfile.jobGroupName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ระดับ(งาน)</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobLevelName
                ? employeeProfile.jobLevelName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ระดับ</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.personnelLevelName
                ? employeeProfile.personnelLevelName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              ประเภทการจ้าง
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employmentTypeName
                ? employeeProfile.employmentTypeName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">รูปแบบงาน</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employmentTime
                ? employeeProfile.employmentTime
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              จังหวัดที่ทำงาน
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.workingLocation
                ? `${employeeProfile.workingLocation}`
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ประเภทงาน</StyledHeadLabel>
            <Typography variant="h6" style={{ textTransform: "capitalize" }}>
              {employeeProfile.filed_office
                ? employeeProfile.filed_office
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              วิธีการบันทึกเวลา
            </StyledHeadLabel>
            <Typography variant="h6">
              {renderMethodAttendance(employeeProfile.methodAttendance)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              ที่ทำงานหลัก
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.groupGpsLocationsName
                ? employeeProfile.groupGpsLocationsName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              การบันทึกเวลา
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isFinger === 1 ? "ใช่" : "ไม่ใช่"}
            </Typography>
          </div>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              การบันทึกเวลามีผลต่อเงินเดือน
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isFingerAffectPayroll === 1 ? "ใช่" : "ไม่ใช่"}
            </Typography>
          </div>
        </Grid> */}
      </Grid>
      <StyledDivider />
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="body2" gutterBottom>
            ข้อมูลหัวหน้างาน
          </StyledHeadLabel>
          <div>
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditRoundedIcon />}
              onClick={() => handleClick("manager")}
            >
              แก้ไข
            </ButtonBlue>
          </div>
        </StyledWrapTop>

        <Fragment>
          <Fragment>
            {employeeProfile.managerLV1_idManagerLV1 && (
              <div style={{ marginBottom: 24 }}>
                <StyledWrapTop className="inner">
                  <StyledHeadLabel variant="body2" gutterBottom>
                    {`*ลำดับที่ 1`}
                  </StyledHeadLabel>
                </StyledWrapTop>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    ชื่อ-สกุล
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV1_firstname_TH}{" "}
                    {employeeProfile.managerLV1_lastname_TH}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    ตำแหน่ง
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV1_positionName
                      ? employeeProfile.managerLV1_positionName
                      : "-"}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    E-mail
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV1_email}
                  </Typography>
                </div>
              </div>
            )}
            {employeeProfile.idManagerGroupLV1 && (
              <div style={{ marginBottom: 24 }}>
                <StyledWrapTop className="inner">
                  <StyledHeadLabel variant="body2" gutterBottom>
                    {`*ลำดับที่ 1`}
                  </StyledHeadLabel>
                </StyledWrapTop>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    กลุ่มหัวหน้างาน
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerGroupLV1_managerGroupName}
                  </Typography>
                </div>
                {/* <div>
                  <StyledHeadLabel color="text.secondary">
                    จำนวนหัวหน้างาน
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerGroupLV1_managerGroupName}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    E-mail
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV1_email}
                  </Typography>
                </div> */}
              </div>
            )}
            {employeeProfile.managerLV2_idManagerLV2 && (
              <div style={{ marginBottom: 24 }}>
                <StyledWrapTop className="inner">
                  <StyledHeadLabel variant="body2" gutterBottom>
                    {`*ลำดับที่ 2`}
                  </StyledHeadLabel>
                </StyledWrapTop>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    ชื่อ-สกุล
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV2_firstname_TH}{" "}
                    {employeeProfile.managerLV2_lastname_TH}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    ตำแหน่ง
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV2_positionName
                      ? employeeProfile.managerLV2_positionName
                      : "-"}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    E-mail
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV2_email}
                  </Typography>
                </div>
              </div>
            )}
            {employeeProfile.idManagerGroupLV2 && (
              <div style={{ marginBottom: 24 }}>
                <StyledWrapTop className="inner">
                  <StyledHeadLabel variant="body2" gutterBottom>
                    {`*ลำดับที่ 2`}
                  </StyledHeadLabel>
                </StyledWrapTop>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    กลุ่มหัวหน้างาน
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerGroupLV2_managerGroupName}
                  </Typography>
                </div>
                {/* <div>
                  <StyledHeadLabel color="text.secondary">
                    จำนวนหัวหน้างาน
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerGroupLV1_managerGroupName}
                  </Typography>
                </div>
                <div>
                  <StyledHeadLabel color="text.secondary">
                    E-mail
                  </StyledHeadLabel>
                  <Typography variant="h6">
                    {employeeProfile.managerLV1_email}
                  </Typography>
                </div> */}
              </div>
            )}
          </Fragment>
        </Fragment>
      </div>
      <StyledDivider />
      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />

      <DialogWorkingLocation
        drawerConfig={drawerWorkingLocationConfig}
        onClose={() => {
          setDrawerWorkingLocationConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />
    </div>
  );
};

export default WorkInfo;
