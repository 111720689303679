import React from "react";
import { Controller } from 'react-hook-form';
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";

import TextFieldNumber from "./TextFieldNumber";
import TextFieldPersonalID from "./TextFieldPersonalID";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-body1": {
      color: "#16243D",
    },
    "& .MuiTypography-body2": {
      fontSize: "1rem",
      marginLeft: 32,
    },
    "& .MuiGrid-root": {
      marginBottom: 8,
    },
  },
  inputAdornment: {
    "& .MuiTypography-body1": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
  checkBox: {
    marginLeft: 32,
  },
}));

function Form3(props) {
  const { control, setValue, watch, errors, clearErrors, isDisabled } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">
            10) เบี้ยประกันภัยสำหรับการประกันสุขภาพของบิดา มารดา
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="isHealthInsurance_empFather"
                    control={control}
                    render={({ field }) => (
                      <Checkbox 
                        disabled={isDisabled}
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                          if(!e.target.checked){
                            setValue("healthInsurance_empFather", 0, { shouldDirty: true });
                          }
                          if(errors && errors.healthInsurance_empFather){
                            clearErrors("healthInsurance_empFather");
                          }
                        }}
                      />
                    )}
                  />
                }
                label="บิดาผู้มีเงินได้"
              />
            </FormGroup>
          </Box>
          <Typography 
            variant="body2"
            style={{ marginLeft: 32, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้สูงสุดไม่เกิน 15,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber
            name="healthInsurance_empFather"
            control={control}
            min={0}
            max={15000}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            disabled={isDisabled || !watch("isHealthInsurance_empFather")}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="isHealthInsurance_empMother"
                    control={control}
                    render={({ field }) => (
                      <Checkbox 
                        disabled={isDisabled}
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                          if(!e.target.checked){
                            setValue("healthInsurance_empMother", 0, { shouldDirty: true });
                          }
                          if(errors && errors.healthInsurance_empMother){
                            clearErrors("healthInsurance_empMother");
                          }
                        }}
                      />
                    )}
                  />
                }
                label="มารดาผู้มีเงินได้"
              />
            </FormGroup>
          </Box>
          <Typography 
            variant="body2"
            style={{ marginLeft: 32, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้สูงสุดไม่เกิน 15,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber
            name="healthInsurance_empMother"
            control={control}
            min={0}
            max={15000}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            disabled={isDisabled || !watch("isHealthInsurance_empMother")}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="isHealthInsurance_mateFather"
                    control={control}
                    render={({ field }) => (
                      <Checkbox 
                        disabled={isDisabled}
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                          if(!e.target.checked){
                            setValue("healthInsurance_mateFather", 0, { shouldDirty: true });
                          }
                          if(errors && errors.healthInsurance_mateFather){
                            clearErrors("healthInsurance_mateFather");
                          }
                        }}
                      />
                    )}
                  />
                }
                label="บิดาคู่สมรส"
              />
            </FormGroup>
          </Box>
          <Typography 
            variant="body2"
            style={{ marginLeft: 32, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้สูงสุดไม่เกิน 15,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber
            name="healthInsurance_mateFather"
            control={control}
            min={0}
            max={15000}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            disabled={isDisabled || !watch("isHealthInsurance_mateFather")}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="isHealthInsurance_mateMother"
                    control={control}
                    render={({ field }) => (
                      <Checkbox 
                        disabled={isDisabled}
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                          if(!e.target.checked){
                            setValue("healthInsurance_mateMother", 0, { shouldDirty: true });
                          }
                          if(errors && errors.healthInsurance_mateMother){
                            clearErrors("healthInsurance_mateMother");
                          }
                        }}
                      />
                    )}
                  />
                }
                label="มารดาคู่สมรส"
              />
            </FormGroup>
          </Box>
          <Typography 
            variant="body2"
            style={{ marginLeft: 32, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้สูงสุดไม่เกิน 15,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber
            name="healthInsurance_mateMother"
            control={control}
            min={0}
            max={15000}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            disabled={isDisabled || !watch("isHealthInsurance_mateMother")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">
            11) อุปการะเลี้ยงดูคนพิการหรือทุพพลภาพ
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body2">จำนวน</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="handicappedPerson"
            min={0}
            max={99}
            endAdornment={true}
            endAdornmentLabel="คน"
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={classes.checkBox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller 
                    name="halfOff"
                    control={control}
                    render={({ field }) => (
                      <Checkbox 
                        disabled={isDisabled}
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label="ลดหย่อนครึ่งหนึ่ง"
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">12) เบี้ยประกันชีวิตแบบบำนาญ</Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้ 15% ของเงินได้ที่ต้องเสียภาษี สูงสุดไม่เกิน 200,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="lifeInsurance"
            min={0}
            max={200000}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Typography variant="body1">13) ดอกเบี้ยเงินกู้บ้าน</Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้สูงสุดไม่เกิน 100,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="loanInterestHome"
            min={0}
            max={100000}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="body1">14) ค่าฝากครรภ์/ค่าคลอด</Typography>
          <Typography 
            variant="body2"
            style={{ marginLeft: 16, fontSize: "0.81rem", marginTop: 8, color:"#d32f2f" }}
          >* ลดหย่อนภาษีได้สูงสุดลำดับการตั้งครรภ์ละไม่เกิน 60,000 บาท</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="orderOfPregnancy"
            min={0}
            max={2}
            control={control}
            label="ลำดับการตั้งครรภ์"
            onHandleChange={() => {
              if((watch("orderOfPregnancy") === 1) && (watch("maternityExpenses") > 60000)){
                setValue("maternityExpenses", 60000);
              }
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextFieldNumber 
            name="maternityExpenses"
            min={0}
            max={watch("orderOfPregnancy") === 1 ? 60000 : 120000}
            endAdornment={true}
            endAdornmentLabel="บาท/ปี"
            control={control}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Form3;
