import { PDFDocument } from 'pdf-lib'
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';
import utils from "../../../../../utils";

export async function halfPage_PDF(data) {
    try {
        const url = `${process.env.REACT_APP_API_URL}files/resume_template_half.pdf`;
        const existingPdfBytes = await fetch(url).then((res) =>
            res.arrayBuffer()
        );
        // const formPdfBytes = await fetch(resumePdf,{encoding:"utf-8"}).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const urlFont = `${process.env.REACT_APP_API_URL}fonts/${"THSarabunNew"}.ttf`;
        const fontBytes = await fetch(urlFont, { encoding: "utf-8" }).then((res) => res.arrayBuffer());
        pdfDoc.registerFontkit(fontkit);
        const font = await pdfDoc.embedFont(fontBytes, { subset: true });

        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const secondPage = pages[1]

        if (data.image) {
            const jpgImage = await pdfDoc.embedJpg(data.image);

            firstPage.drawImage(jpgImage, {
                x: 22,
                y: 609,
                width: 224,
                height: 209,
            });
        }

        //left
        firstPage.drawText((`อายุ ${utils.getAge(dayjs(data.birthday).format("DD/MM/YYYY"))}`), {
            x: 48,
            y: 545,
            size: 16,
            font: font
        })
        firstPage.drawText(("สัญชาติ : " + (data.nationality ? data.nationality : "-")), {
            x: 48,
            y: 525,
            size: 16,
            font: font
        })
        firstPage.drawText(("เลขบัตรประชาชน : " + (data.personalID ? data.personalID : "-")), {
            x: 48,
            y: 505,
            size: 16,
            font: font
        })
        firstPage.drawText(("เลขใบอนุญาตทำงาน : " + (data.workPermitNumber ? data.workPermitNumber : "-")), {
            x: 48,
            y: 485,
            size: 16,
            font: font
        })
        firstPage.drawText(("เบอร์โทร : " + (data.telephoneMobile ? data.telephoneMobile : "-")), {
            x: 48,
            y: 465,
            size: 16,
            font: font
        })
        firstPage.drawText((data.email ? data.email : "-"), {
            x: 48,
            y: 445,
            size: 15,
            font: font
        })
        let pointy = 385
        if (data.houseNo || data.village) {
            firstPage.drawText(((data.houseNo ? (data.houseNo + " ") : "") + (data.village ? ("หมู่บ้าน " + data.village + " ") : "")), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }
        if (data.villageNo || data.alley) {
            firstPage.drawText(((data.villageNo ? ("หมู่ที่ " + data.villageNo) + " " : "") + (data.alley ? ("ตรอก/ซอย " + data.alley) : "")), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }
        if (data.road) {
            firstPage.drawText(("ถนน " + data.road), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }
        if (data.subDistrict) {
            firstPage.drawText(("ตำบล " + data.subDistrict), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }
        if (data.district) {
            firstPage.drawText(("เขต/อำเภอ " + data.district), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }
        if (data.provience || data.areaCode) {
            firstPage.drawText(((data.provience ? (data.provience + " ") : "") + (data.areaCode ? data.areaCode : "")), {
                x: 48,
                y: pointy -= 20,
                size: 16,
                font: font
            })
        }

        //right pt1 -----------------------------------------------------------------------
        firstPage.drawText(((data.title_TH ? data.title_TH : "")
            + (data.firstname_TH ? " " + data.firstname_TH : "")
            + (data.lastname_TH ? "  " + data.lastname_TH : "")
            + (data.nickname_TH ? " (" + data.nickname_TH + ")" : "")), {
            x: 257,
            y: 785,
            size: 26,
            font: font
        })
        firstPage.drawText((data.positionName ? data.positionName : ""), {
            x: 257,
            y: 760,
            size: 20,
            font: font
        })
        firstPage.drawText(("รหัสพนักงาน: " + data.employeeID), {
            x: 257,
            y: 735,
            size: 20,
            font: font
        })

        firstPage.drawText(("วันที่เริ่มงาน : " + (data.hiringDate ? dayjs(data.hiringDate).format("DD/MM/YYYY") : "-")), {
            x: 257,
            y: 690,
            size: 16,
            font: font
        })
        firstPage.drawText(("ประเภทสัญญา : " + (data.contractType ? data.contractType : "-")), {
            x: 257,
            y: 670,
            size: 16,
            font: font
        })
        let oesyY = 0
        let oesyM = 0
        let servY = 0
        let seryM = 0
        if (data.OESY_M) oesyM = data.OESY_M
        if (data.OESY_Y) oesyY = data.OESY_Y
        if (data.Serv_Y) servY = data.Serv_Y
        if (data.Serv_M) seryM = data.Serv_M
        firstPage.drawText(("อายุงานรวม(Y/M) : " + (oesyY + servY) + "/" + (oesyM + seryM)), {
            x: 257,
            y: 650,
            size: 16,
            font: font
        })
        firstPage.drawText((data.managerLV1_firstname_TH ? ("ผู้จัดการ1 : " + data.managerLV1_firstname_TH + " " + data.managerLV1_lastname_TH) : " "), {
            x: 257,
            y: 630,
            size: 16,
            font: font
        })

        firstPage.drawText(("วันที่เกษียณอายุ : " + (data.retirementDate ? dayjs(data.retirementDate).format("DD/MM/YYYY") : "-")), {
            x: 400,
            y: 690,
            size: 16,
            font: font
        })
        firstPage.drawText(("ประเภทพนักงาน : " + (data.employmentTypeName ? data.employmentTypeName : "-")), {
            x: 400,
            y: 670,
            size: 16,
            font: font
        })
        firstPage.drawText(("อายุงานภายใน(Y/M) : " + servY + "/" + seryM), {
            x: 400,
            y: 650,
            size: 16,
            font: font
        })
        firstPage.drawText((data.managerLV2_firstname_TH ? ("ผู้จัดการ2 : " + data.managerLV2_firstname_TH + " " + data.managerLV2_lastname_TH) : " "), {
            x: 400,
            y: 630,
            size: 16,
            font: font
        })

        //right pt2 -----------------------------------------------------------------------
        firstPage.drawText(("Company : " + (data.companyName ? data.companyName : "-")), {
            x: 280,
            y: 545,
            size: 16,
            font: font
        })
        firstPage.drawText(("BU : -"), {
            x: 280,
            y: 525,
            size: 16,
            font: font
        })
        firstPage.drawText(("Division : " + (data.divisionName ? data.divisionName : "-")), {
            x: 280,
            y: 505,
            size: 16,
            font: font
        })
        firstPage.drawText(("Department : " + (data.departmentName ? data.departmentName : "-")), {
            x: 280,
            y: 485,
            size: 16,
            font: font
        })
        firstPage.drawText(("Section : " + (data.sectionName ? data.sectionName : "-")), {
            x: 280,
            y: 465,
            size: 16,
            font: font
        })
        firstPage.drawText(("Unit / Process : -"), {
            x: 280,
            y: 445,
            size: 16,
            font: font
        })

        //right pt3 -----------------------------------------------------------------------
        firstPage.drawText(("กลุ่มงาน : " + (data.jobGroupName ? data.jobGroupName : "-")), {
            x: 280,
            y: 365,
            size: 16,
            font: font
        })
        firstPage.drawText(("ตำแหน่งงาน : " + (data.positionName ? data.positionName : "-")), {
            x: 280,
            y: 345,
            size: 16,
            font: font
        })
        firstPage.drawText(("ระดับ(งาน) : " + (data.jobLevelName ? data.jobLevelName : "-")), {
            x: 280,
            y: 325,
            size: 16,
            font: font
        })
        firstPage.drawText(("ระดับ : " + (data.personnelLevelName ? data.personnelLevelName : "-")), {
            x: 280,
            y: 305,
            size: 16,
            font: font
        })
        firstPage.drawText(("เวลาที่อยู่ในระดับ : -"), {
            x: 280,
            y: 285,
            size: 16,
            font: font
        })
        //right pt4 -----------------------------------------------------------------------
        pointy = 220
        if (data.educations) {
            data.educations.map((item) => {
                if (item.fromYear) (firstPage.drawText((item.fromYear + (item.endYear ? (" - " + item.endYear) : "")), {
                    x: 280,
                    y: pointy -= 20,
                    size: 16,
                    font: font
                }))
                if (item.faculty) (firstPage.drawText((item.faculty + (item.major ? (" | " + item.major) : "")), {
                    x: 280,
                    y: pointy -= 20,
                    size: 16,
                    font: font
                }))
                if (item.university) (firstPage.drawText((item.university + (item.degree ? (" | " + item.degree) : "")), {
                    x: 280,
                    y: pointy -= 20,
                    size: 16,
                    font: font
                }))
            })
        }
        //-------------------------------------------------------------------------------------------------------------
        //---------------------------------------------------page2-----------------------------------------------------
        //-------------------------------------------------------------------------------------------------------------
        pointy = 575
        if (data.position) {
            data.position.map((item) => {
                pointy -= 15
                if (item.start) (secondPage.drawText((dayjs(item.start).format("DD/MM/YYYY") + " - " +
                    (item.end ? (dayjs(item.end).format("DD/MM/YYYY")) : "ปัจจุบัน")), {
                    x: 53,
                    y: pointy -= 20,
                    size: 16,
                    font: font
                }))
                if (item.positionName) (secondPage.drawText((item.positionName), {
                    x: 53,
                    y: pointy -= 20,
                    size: 16,
                    font: font
                }))
                if (item.companyName || item.sectionName || item.divisionName) (secondPage.drawText(((item.companyName ? (item.companyName) : "")
                    + (item.sectionName ? (" | " + item.sectionName) : "") + (item.divisionName ? (" | " + item.divisionName) : "")), {
                    x: 53,
                    y: pointy -= 20,
                    size: 16,
                    font: font
                }))
            })
        }

        const pdfBytes = await pdfDoc.save();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        // Create a download link and trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download = `resume-half ${data.employeeID}.pdf`;
        downloadLink.click();

    } catch (error) {
        console.error('Error creating PDF:', error);
    }
}

