import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
    CircularProgress,
    Dialog,
    DialogContent,
    Divider,
    TextField,
    Typography
} from '@mui/material';

import ButtonBlue from '../../shared/general/ButtonBlue';

const DialogVisaDate = (props) => {
    const { open, onClose, onHandleSubmit } = props;
    const { control, handleSubmit, formState: { errors }, watch, setValue } = useForm({
        defaultValues:{
            startDate: new Date(),
            endDate: new Date()
        }
    });

    const onHandleSubmitOnDialog = (value) => {
        onHandleSubmit(value);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogContent>
                <Typography variant="h5">ระบุเวลาในการเดินทาง</Typography>
                <Divider
                    style={{
                        borderBottom: "1px solid #CDCDCD",
                        margin:"16px 0px"
                    }}
                />
                <form onSubmit={handleSubmit(onHandleSubmitOnDialog)}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div>
                            <Typography>วันที่เริ่มเดินทาง</Typography>
                            <Controller 
                                name="startDate"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "กรุณาระบุวันที่ให้ถูกต้อง"
                                    },
                                    validate: (value) => dayjs(value).isSameOrAfter(dayjs(), 'date') || "กรุณาระบุวันที่ให้ถูกต้อง"
                                }}
                                render={({ field }) => (
                                    <DatePicker 
                                        {...field}
                                        inputFormat="DD/MM/YYYY"
                                        minDate={new Date()}
                                        onChange={(newValue) => {
                                            let valueEndDate = watch("endDate");
                                            let endDate = dayjs(newValue).isAfter(valueEndDate) ? newValue : valueEndDate;
        
                                            field.onChange(newValue);
                                            setValue("endDate", endDate);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ 
                                                    width: "100%",
                                                    marginTop: "8px"
                                                }}
                                                {...params}
                                                error={errors.startDate ? true : false}
                                                helperText={errors.startDate && errors.startDate.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </div>
                        <div style={{ marginTop: "16px" }}>
                            <Typography>วันที่สิ้นสุดเดินทาง</Typography>
                            <Controller 
                                name="endDate"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "กรุณาระบุวันที่ให้ถูกต้อง"
                                    }
                                }}
                                render={({ field }) => (
                                    <DatePicker 
                                        {...field}
                                        inputFormat="DD/MM/YYYY"
                                        minDate={watch("startDate")}
                                        onChange={(newValue) => {
                                            let valueStartDate = watch("startDate");
                                            let startDate = dayjs(valueStartDate).isAfter(newValue) ? newValue : valueStartDate;

                                            field.onChange(newValue);
                                            setValue("startDate", startDate);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ 
                                                    width: "100%",
                                                    marginTop: "8px"
                                                }}
                                                {...params}
                                                error={errors.endDate ? true : false}
                                                helperText={errors.endDate && errors.endDate.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </div>
                    </LocalizationProvider>
                    <div
                        style={{ 
                            width:"100%",
                            display:"flex",
                            justifyContent:"flex-end",
                            marginTop: "16px" 
                        }}
                    >
                        <ButtonBlue
                            style={{ marginRight: "8px" }}
                            onClick={onClose}
                        >
                            ยกเลิก
                        </ButtonBlue>
                        <ButtonBlue
                            variant="contained"
                            type="submit"
                        >
                            ยืนยัน
                        </ButtonBlue>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default DialogVisaDate;