import React from "react";
import { ListItemIcon, ListItemText, Menu, MenuItem, } from "@mui/material";
import { Link } from "react-router-dom";

const MenuUser = (props) => {
  const { anchorEl, onClose } = props;

  return (
    <Menu
      sx={
        {
          mt: "1px", "& .MuiMenu-paper":
            { backgroundColor: "#46cbe2", },
        }
      }
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem component={Link} to={`/recruitment`}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary="งานที่เปิดรับสมัคร" />
      </MenuItem>
      <MenuItem component={Link} to={`/manage/hunter/peer`}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary="คนของฉัน" />
      </MenuItem>
      <MenuItem component={Link} to={`/hunter-manpower-interviewed`}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary="ประวัติผู้ถูกเลือกเข้าสัมภาษณ์" />
      </MenuItem>
      <MenuItem component={Link} to={`/hunter-manpower-sending`}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary="ประวัติการส่งคน" />
      </MenuItem>
      <MenuItem component={Link} to={"/recruitment-interviewer"}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary="กรรมการสัมภาษณ์" />
      </MenuItem>
      <MenuItem component={Link} to={`/manage/hunter/dashboard`}>
        <ListItemIcon sx={{ color: "#ffffff" }}>
          <i className="fa-solid fa-user"></i>
        </ListItemIcon>
        <ListItemText primary="แดชบอร์ด" />
      </MenuItem>
    </Menu>
  )
}

export default MenuUser