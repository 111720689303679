import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";

import {
  Grid,
  Container,
  InputAdornment,
  FormControlLabel,
  Radio,
  Typography,
  Chip,
  Box,
  FormControl,
  TextField,
  FormLabel,
  RadioGroup,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import WorkIcon from "@mui/icons-material/Work";
import CommentIcon from "@mui/icons-material/Comment";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";

import {
  getAllCareerPlan,
  putManagerSugCareerPlan,
} from "../../../../actions/careerPlan";
import { getUserProfile } from "../../../../actions/user";



const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  nameCareer: {
    fontSize: 28,
    fontWeight: "bold",
    color: "#007afc",
  },
  radioText: {
    fontSize: 19,
    fontWeight: "bold",
  },
  radioPosition: {
    padding: 20,
  },
  paper: {
    margin: 20,
  },
  position: {
    fontSize: 20,
    fontWeight: "bold",
  },
  jobFam: {
    fontSize: 18,
    fontWeight: "bold",
  },

  chipJobFamily: {
    fontWeight: 600,
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  radio_container: {
    "& .Mui-checked": {
      color: "#007afc",
    },
  },
  autocompleteFiled: {
    margin: "8px 0px 0px",
  },
}));

const EnterData = ({
  name,
  firstEnter,
  firstEnterName,
  secondEnter,
  secondEnterName,
  thirdEnter,
  thirdEnterName,
  icon,
  values1,
  values2,
  values3,
  values,
  setValues,
}) => {
  const classes = useStyles();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <CardStyle>
        <div style={{ padding: 24, minHeight: "310px" }}>
          <Typography className={classes.nameCareer}>{name}</Typography>
          <TextField
            name={firstEnter}
            value={values1}
            onChange={handleInputChange}
            variant="outlined"
            required
            style={{ marginTop: 20 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
            }}
            placeholder={firstEnterName}
            fullWidth
          />
          <TextField
            name={secondEnter}
            value={values2}
            onChange={handleInputChange}
            variant="outlined"
            style={{ marginTop: 20 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
            }}
            placeholder={secondEnterName}
            fullWidth
          />
          <TextField
            name={thirdEnter}
            value={values3}
            onChange={handleInputChange}
            variant="outlined"
            style={{ marginTop: 20 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
            }}
            placeholder={thirdEnterName}
            fullWidth
          />
        </div>
      </CardStyle>
    </div>
  );
};

const SuggestCareer = () => {
  //use style in name of classes
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [values, setValues] = useState([]);

  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const onPutManagerSugCareerPlan = async (id, formData) => {
    await dispatch(putManagerSugCareerPlan(id, formData));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      idCareerPlan: values.idCareerPlan,
      idEmployees: userProfile.idEmployees,
      managerStrength1: values.managerStrength1
        ? values.managerStrength1
        : null,
      managerStrength2: values.managerStrength2
        ? values.managerStrength2
        : null,
      managerStrength3: values.managerStrength3
        ? values.managerStrength3
        : null,
      managerWeakness1: values.managerWeakness1
        ? values.managerWeakness1
        : null,
      managerWeakness2: values.managerWeakness2
        ? values.managerWeakness2
        : null,
      managerWeakness3: values.managerWeakness3
        ? values.managerWeakness3
        : null,
      managerMoreDev1: values.managerMoreDev1 ? values.managerMoreDev1 : null,
      managerMoreDev2: values.managerMoreDev2 ? values.managerMoreDev2 : null,
      managerMoreDev3: values.managerMoreDev3 ? values.managerMoreDev3 : null,
      managerSugJobFam1: values.managerSugJobFam1
        ? values.managerSugJobFam1
        : null,
      managerSugJobPos1: values.managerSugJobPos1
        ? values.managerSugJobPos1
        : null,
      managerSugReason1: values.managerSugReason1
        ? values.managerSugReason1
        : null,
      managerSugJobFam2: values.managerSugJobFam2
        ? values.managerSugJobFam2
        : null,
      managerSugJobPos2: values.managerSugJobPos2
        ? values.managerSugJobPos2
        : null,
      managerSugReason2: values.managerSugReason2
        ? values.managerSugReason2
        : null,
      managerSugJobFam3: values.managerSugJobFam3
        ? values.managerSugJobFam3
        : null,
      managerSugJobPos3: values.managerSugJobPos3
        ? values.managerSugJobPos3
        : null,
      managerSugReason3: values.managerSugReason3
        ? values.managerSugReason3
        : null,
      beLeader: values.beLeader ? values.beLeader : null,
      next10Years: values.next10Years ? values.next10Years : null,
      ready: values.ready,
      managerSugStatus: true,
      dateUpdate: dayjs(new Date()).format("YYYY-MM-DD"),
    };

    //Post to DB
    onPutManagerSugCareerPlan(formData.idCareerPlan, formData);

    //push back to career plan page
    history.push({
      pathname: "/myCareerePlan",
      notify: {
        isOpen: true,
        message: "บันทึกข้อมูลเสร็จเรียบร้อย",
        type: "success",
      },
      empId: values.idEmployees,
    });
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
      dispatch(getAllCareerPlan());
      console.log("location", location.state.careerPlan);
      setValues(location.state.careerPlan);
    }
  }, []);

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
          สายอาชีพของฉัน
        </Typography>
        {values !== undefined ? (
          <>
            <div style={{ marginBottom: 16 }}>
              <CardStyle>
                <div style={{ padding: 24 }}>
                  <Typography className={classes.nameCareer} gutterBottom>
                    สายงานที่พนักงานสนใจ
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                      <div style={{ marginBottom: 16 }}>
                        <CardStyle className={classes.paper}>
                          <div style={{ padding: 24 }}>
                            <Grid container spacing={2}>
                              <Grid item xl={6} md={6} xs={6}>
                                <Typography className={classes.position}>
                                  {values.empIntJobPos1}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xl={6}
                                md={6}
                                xs={6}
                                style={{ textAlign: "right" }}
                              >
                                <Chip
                                  label={values.empIntJobFam1}
                                  className={classes.chipJobFamily}
                                />
                              </Grid>
                            </Grid>
                            <Typography>{values.empIntReason1}</Typography>
                          </div>
                        </CardStyle>
                      </div>
                      <div style={{ marginBottom: 16 }}>
                        <CardStyle className={classes.paper}>
                          <div style={{ padding: 24 }}>
                            <Grid container spacing={2}>
                              <Grid item xl={6} md={6} xs={6}>
                                <Typography className={classes.position}>
                                  {values.empIntJobPos2}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xl={6}
                                md={6}
                                xs={6}
                                style={{ textAlign: "right" }}
                              >
                                <Chip
                                  label={values.empIntJobFam2}
                                  className={classes.chipJobFamily}
                                />
                              </Grid>
                            </Grid>
                            <Typography>{values.empIntReason2}</Typography>
                          </div>
                        </CardStyle>
                      </div>
                      <div style={{ marginBottom: 16 }}>
                        <CardStyle className={classes.paper}>
                          <div style={{ padding: 24 }}>
                            <Grid container spacing={2}>
                              <Grid item xl={6} md={6} xs={6}>
                                <Typography className={classes.position}>
                                  {values.empIntJobPos3}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xl={6}
                                md={6}
                                xs={6}
                                style={{ textAlign: "right" }}
                              >
                                <Chip
                                  label={values.empIntJobFam3}
                                  className={classes.chipJobFamily}
                                />
                              </Grid>
                            </Grid>
                            <Typography>{values.empIntReason3}</Typography>
                          </div>
                        </CardStyle>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </CardStyle>
            </div>
            <form onSubmit={handleOnSubmit}>
              <Grid container spacing={2}>
                <Grid item xl={6} md={6} xs={12}>
                  <EnterData
                    name="จุดแข็ง"
                    firstEnter="managerStrength1"
                    firstEnterName="จุดแข็งที่ 1"
                    secondEnter="managerStrength2"
                    secondEnterName="จุดแข็งที่ 2"
                    thirdEnter="managerStrength3"
                    thirdEnterName="จุดแข็งที่ 3"
                    icon={<ThumbUpAltIcon />}
                    values1={values.managerStrength1}
                    values2={values.managerStrength2}
                    values3={values.managerStrength3}
                    values={values}
                    setValues={setValues}
                  />
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <EnterData
                    name="จุดอ่อน"
                    firstEnter="managerWeakness1"
                    firstEnterName="จุดอ่อนที่ 1"
                    secondEnter="managerWeakness2"
                    secondEnterName="จุดอ่อนที่ 2"
                    thirdEnter="managerWeakness3"
                    thirdEnterName="จุดอ่อนที่ 3"
                    icon={<ThumbDownAltIcon />}
                    values1={values.managerWeakness1}
                    values2={values.managerWeakness2}
                    values3={values.managerWeakness3}
                    values={values}
                    setValues={setValues}
                  />
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <div style={{ marginBottom: 16 }}>
                    <CardStyle>
                      <div style={{ padding: 24, minHeight: "310px" }}>
                        <Typography className={classes.nameCareer}>
                          แผนงานอนาคต
                        </Typography>
                        <Box sx={{ marginTop: 2 }}>
                          <FormControl>
                            <FormLabel>
                              1. ท่านอยากเป็น Leader หรือไม่ ?
                            </FormLabel>
                            <RadioGroup
                              value={values.beLeader}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  beLeader: e.target.value,
                                })
                              }
                            >
                              <FormControlLabel
                                value="อยาก"
                                control={<Radio />}
                                label="อยาก"
                              />
                              <FormControlLabel
                                value="ไม่อยาก"
                                control={<Radio />}
                                label="ไม่อยาก"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box sx={{ marginTop: 2 }}>
                          <FormControl>
                            <FormLabel>
                              2. ใน 10 ปีข้างหน้า
                              ท่านมีแผนจะอยู่ในองค์กรต่อหรือไม่ ?
                            </FormLabel>
                            <RadioGroup
                              value={values.next10Years}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  next10Years: e.target.value,
                                })
                              }
                            >
                              <FormControlLabel
                                value="อยู่"
                                control={<Radio />}
                                label="อยู่"
                              />
                              <FormControlLabel
                                value="ไม่แน่นอน"
                                control={<Radio />}
                                label="ไม่แน่นอน"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </div>
                    </CardStyle>
                  </div>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <EnterData
                    name="พัฒนาเพิ่มเติม"
                    firstEnter="managerMoreDev1"
                    firstEnterName="พัฒนาเพิ่มเติม 1"
                    secondEnter="managerMoreDev2"
                    secondEnterName="พัฒนาเพิ่มเติม 2"
                    thirdEnter="managerMoreDev3"
                    thirdEnterName="พัฒนาเพิ่มเติม 3"
                    icon={<AddCircleIcon />}
                    values1={values.managerMoreDev1}
                    values2={values.managerMoreDev2}
                    values3={values.managerMoreDev3}
                    values={values}
                    setValues={setValues}
                  />
                </Grid>
              </Grid>
              <Box>
                <CardStyle style={{ marginBottom: 30, padding: 24 }}>
                  <Grid container direction="row">
                    <Grid item xl={4} lg={4} md={6} xs={8}>
                      <Typography className={classes.nameCareer}>
                        แนะนำสายงานอันดับที่ 1
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 10 }}>
                    <Grid
                      item
                      xl={6}
                      md={6}
                      xs={12}
                      style={{ marginTop: 10, paddingRight: 10 }}
                    >
                      <TextField
                        value={values.managerSugJobFam1}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            managerSugJobFam1: e.target.value,
                          })
                        }
                        variant="outlined"
                        required
                        disabled={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <WorkIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="สายงาน"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      md={6}
                      xs={12}
                      style={{ marginTop: 10, paddingLeft: 10 }}
                    >
                      <TextField
                        value={values.managerSugJobPos1}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            managerSugJobPos1: e.target.value,
                          })
                        }
                        variant="outlined"
                        required
                        disabled={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PermIdentityIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="ตำแหน่ง"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xl={12} md={12} xs={12}>
                      <TextField
                        value={values.managerSugReason1}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            managerSugReason1: e.target.value,
                          })
                        }
                        variant="outlined"
                        required
                        disabled={false}
                        multiline
                        rows={3}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CommentIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="เหตุผล"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardStyle>
                <CardStyle style={{ marginBottom: 30, padding: 24 }}>
                  <Grid container direction="row">
                    <Grid item xl={4} lg={4} md={6} xs={8}>
                      <Typography className={classes.nameCareer}>
                        แนะนำสายงานอันดับที่ 2
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 10 }}>
                    <Grid
                      item
                      xl={6}
                      md={6}
                      xs={12}
                      style={{ marginTop: 10, paddingRight: 10 }}
                    >
                      <TextField
                        value={values.managerSugJobFam2}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            managerSugJobFam2: e.target.value,
                          })
                        }
                        variant="outlined"
                        disabled={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <WorkIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="สายงาน"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      md={6}
                      xs={12}
                      style={{ marginTop: 10, paddingLeft: 10 }}
                    >
                      <TextField
                        value={values.managerSugJobPos2}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            managerSugJobPos2: e.target.value,
                          })
                        }
                        variant="outlined"
                        disabled={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PermIdentityIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="ตำแหน่ง"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xl={12} md={12} xs={12}>
                      <TextField
                        value={values.managerSugReason2}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            managerSugReason2: e.target.value,
                          })
                        }
                        variant="outlined"
                        disabled={false}
                        multiline
                        rows={3}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CommentIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="เหตุผล"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardStyle>
                <CardStyle style={{ marginBottom: 30, padding: 24 }}>
                  <Grid container direction="row">
                    <Grid item xl={4} lg={4} md={6} xs={8}>
                      <Typography className={classes.nameCareer}>
                        แนะนำสายงานอันดับที่ 3
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 10 }}>
                    <Grid
                      item
                      xl={6}
                      md={6}
                      xs={12}
                      style={{ marginTop: 10, paddingRight: 10 }}
                    >
                      <TextField
                        value={values.managerSugJobFam3}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            managerSugJobFam3: e.target.value,
                          })
                        }
                        variant="outlined"
                        disabled={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <WorkIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="สายงาน"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      md={6}
                      xs={12}
                      style={{ marginTop: 10, paddingLeft: 10 }}
                    >
                      <TextField
                        value={values.managerSugJobPos3}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            managerSugJobPos3: e.target.value,
                          })
                        }
                        variant="outlined"
                        disabled={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PermIdentityIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="ตำแหน่ง"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xl={12} md={12} xs={12}>
                      <TextField
                        value={values.managerSugReason3}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            managerSugReason3: e.target.value,
                          })
                        }
                        variant="outlined"
                        disabled={false}
                        multiline
                        rows={3}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CommentIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="เหตุผล"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardStyle>
              </Box>
              <div style={{ marginBottom: 24 }}>
                <CardStyle>
                  <div style={{ padding: 24 }}>
                    <Typography className={classes.nameCareer}>
                      ความพร้อม
                    </Typography>
                    <Box
                      sx={{
                        marginTop: 2,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <FormControl>
                        <RadioGroup
                          row
                          value={values.ready}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              ready: e.target.value,
                            })
                          }
                        >
                          <FormControlLabel
                            checked={values.ready === "Suddenly"}
                            value="Suddenly"
                            control={<Radio />}
                            label="ทันที"
                            sx={{ marginRight: 5 }}
                          />
                          <FormControlLabel
                            checked={values.ready === "oneYear"}
                            value="oneYear"
                            control={<Radio />}
                            label="1 ปี"
                            sx={{ marginRight: 5 }}
                          />
                          <FormControlLabel
                            checked={values.ready === "twoYear"}
                            value="twoYear"
                            control={<Radio />}
                            label="2 ปี"
                            sx={{ marginRight: 5 }}
                          />
                          <FormControlLabel
                            checked={values.ready === "threeYear"}
                            value="threeYear"
                            control={<Radio />}
                            label="3 ปี"
                            sx={{ marginRight: 5 }}
                          />
                          <FormControlLabel
                            checked={values.ready === "none"}
                            value="none"
                            control={<Radio />}
                            label="ไม่พร้อม"
                            sx={{ marginRight: 5 }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </div>
                </CardStyle>
              </div>
              <Grid container justifyContent="end">
                <ButtonBlue
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  style={{
                    marginBottom: 30,
                  }}
                >
                  บันทึก
                </ButtonBlue>
              </Grid>
            </form>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: 1,
                marginTop: 2,
                paddingLeft: 5,
                paddingRight: 5,
                marginTop: 4,
              }}
            >
              <Typography variant="body1" sx={{ color: "#424242" }}>
                ไม่มีข้อมูล
              </Typography>
            </Box>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default SuggestCareer;
