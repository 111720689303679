import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import XLSX from "xlsx";
import AlertResponse from "../../shared/general/AlertResponse";
import DrawerCustom from "../../shared/general/Drawer";
import DialogSelectDate from "./component/dialogWithSelectDate";
import { getEmailReportById } from "../../../../actions/email";import { getResignEmployee } from "../../../../actions/report";

const Resign = (props) => {
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: email } = useSelector((state) => state.email)
    const { result: resignEmployee } = useSelector((state) => state.resignEmployee);
    const { open, handleClose } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [data, setData] = useState({
        inputSearch: new Date(),
        value: "year",
        autoEmail: null,
        selectedCompany: null,
        ReportName: "resign",
        idEmployees: userProfile.idEmployees
    })

    useEffect(()=>{
        if(email)
        setData({ ...data, autoEmail: email.day });
    },[email])

    useEffect(()=>{
        dispatch(getEmailReportById(data.idEmployees, "resign"))
    },[])

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    useEffect(() => {
        if (data.value === "year") {
            dispatch(getResignEmployee(data.selectedCompany, dayjs(data.inputSearch).endOf('month')))
        } else dispatch(getResignEmployee(data.selectedCompany, dayjs(data.inputSearch).startOf('month')))
    }, [data.inputSearch, data.selectedCompany, data.value])

    const handleSubmit = () => {
        if (resignEmployee.length > 0) {
            const elementList = [];
            for (let index = 0; index < resignEmployee.length; index++) {
                const element = {
                    "#": index + 1,
                    "รหัสพนักงาน": resignEmployee[index].employeeID,
                    "ชื่อ": resignEmployee[index].firstname_TH,
                    "สกุล": resignEmployee[index].lastname_TH,
                    "Division": resignEmployee[index].divisionName,
                    "Department": resignEmployee[index].departmentName,
                    "Section": resignEmployee[index].sectionName,
                    "Unit/Process": resignEmployee[index].positionName,
                    "ประเภทพนักงาน": resignEmployee[index].employmentTime,
                    "วันที่ลาออก": dayjs(resignEmployee[index].resignDate).format("YYYY-MM-DD"),
                    "เหตุผลลาออก": resignEmployee[index].reasonResign,

                };
                elementList.push(element);
            }
            const workSheet = XLSX.utils.json_to_sheet(elementList);
            const workBook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workBook, workSheet, "รายงานคนลาออก");
            XLSX.writeFile(workBook, "รายงานคนลาออก.xlsx");

            handleChangeAlertType("success");
            handleOpenAlert(true);
            handleClose();
        } else {
            handleChangeAlertType("ไม่พบข้อมูล");
            handleOpenAlert(true);
        }
    }

    return (
        <DrawerCustom
            title="รายงานคนลาออก"
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <DialogSelectDate
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                setData={setData}
                data={data}
                handleChangeAlertType={handleChangeAlertType}
                handleOpenAlert={handleOpenAlert}
                select={true}
                excel={resignEmployee}
            />
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>
    );
}

export default Resign;
