import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import {
    TextField,
    Grid,
    Popper,
    FormControl,
    Typography,
    Box,
} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import XLSX from "xlsx";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";

import { clearAttendance, getAttendanceById } from "../../../../actions/attendance";
import { getPayrollSetting } from "../../../../actions/paytypes";
import SelectEmployeeByIdCompany from "../../shared/general/selectEmployeeByIdCompany";
import { getAffiliate } from "../../../../actions/company";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

const StyledBoxSearch = styled(Box)({
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});


const Dailyplanner = (props) => {
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateList } = useSelector((state) => state.affiliate);
    const { result: attendanceList } = useSelector((state) => state.attendance);
    const { result: payrollSetting } = useSelector((state) => state.payrollSetting);
    const { open, handleClose } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [inputSearch, setInputSearch] = useState(new Date());
    const [value, setValue] = React.useState("year");
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleSelectEmployee = (employee) => {
        dispatch(clearAttendance());
        setSelectedEmployee(employee);
    };

    useEffect(() => {
        dispatch(getPayrollSetting());
        dispatch(getAffiliate());
    }, [])

    useEffect(() => {
        if (value === "year" && selectedEmployee) {
            dispatch(getAttendanceById({
                start: dayjs(inputSearch).set('month', 0).startOf('month'),
                end: dayjs(inputSearch).set('month', 11).endOf('month')
            }, selectedEmployee.idEmployees))
        } else if (selectedEmployee) {
            dispatch(getAttendanceById({
                start: dayjs(inputSearch).startOf('month'),
                end: dayjs(inputSearch).endOf('month')
            }, selectedEmployee.idEmployees))
        }
    }, [inputSearch, selectedEmployee, value])

    const handleSubmit = () => {
        if (attendanceList.length > 0) {
            const elementList = [];

            for (let index = 0; index < attendanceList.length; index++) {
                let totalLate = 0;
                const findDate = {
                    start: `${dayjs(attendanceList[index].date).format("YYYY-MM-DD")} ${attendanceList[index].pattern.timeIn
                        }`,
                    end: `${dayjs(attendanceList[index].date)
                        .add(1, "day")
                        .format("YYYY-MM-DD")} ${attendanceList[index].pattern.timeIn}`,
                };

                let late = 0;
                let early = 0;

                if (
                    attendanceList[index].requestTime != null ||
                    attendanceList[index].leave != null ||
                    attendanceList[index].pattern.isWorkingDay === 0
                ) {
                } else {
                    if (
                        attendanceList[index].attendance.checkIn[0] &&
                        dayjs(
                            attendanceList[index].attendance.checkIn[0]
                                .attendanceTextDateTime,
                            "YYYY-MM-DD HH:mm"
                        ).isAfter(dayjs(findDate.start))
                    ) {
                        late = dayjs(
                            attendanceList[index].attendance.checkIn[0]
                                .attendanceTextDateTime,
                            "YYYY-MM-DD HH:mm"
                        ).diff(dayjs(findDate.start), "minute");
                    }

                    if (
                        attendanceList[index].attendance.checkOut[0] &&
                        dayjs(
                            attendanceList[index].attendance.checkOut[0]
                                .attendanceTextDateTime,
                            "YYYY-MM-DD HH:mm"
                        ).isBefore(dayjs(findDate.start))
                    ) {
                        early = dayjs(findDate.start)
                            .add(attendanceList[index].pattern.workingHours, "minute")
                            .diff(
                                dayjs(
                                    attendanceList[index].attendance.checkOut[0]
                                        .attendanceTextDateTime,
                                    "YYYY-MM-DD HH:mm"
                                ),
                                "minute"
                            );
                    }
                }

                totalLate =
                    attendanceList[index].pattern.lateIn &&
                        attendanceList[index].pattern.lateIn >= late
                        ? early
                        : late + early;

                const element = {
                    วันที่: dayjs(attendanceList[index].date).format("D/MM/YYYY (ddd)"),
                    กะ:
                        attendanceList[index].pattern.idWorkingType === 1
                            ? `${attendanceList[index].pattern.shiftGroupName}  (${attendanceList[index].pattern.shiftName})`
                            : `${attendanceList[index].pattern.shiftGroupName}`,
                    เวลาทำงาน:
                        attendanceList[index].pattern.isWorkingDay === 1
                            ? `${dayjs(attendanceList[index].pattern.timeIn, "HH:mm:ss").format(
                                "HH:mm"
                            )}-${dayjs(
                                attendanceList[index].pattern.timeOut,
                                "HH:mm:ss"
                            ).format("HH:mm")}`
                            : "วันหยุด",
                    "เข้างาน-ออกงาน": attendanceList[index]
                        ? `${attendanceList[index].attendance.checkIn[0]
                            ? attendanceList[index].attendance.checkIn[0]
                                .attendanceTextTime
                            : ""
                        } - ${attendanceList[index].attendance.checkOut[0]
                            ? attendanceList[index].attendance.checkOut[0]
                                .attendanceTextTime
                            : ""
                        }`
                        : "-",
                    มาสาย: totalLate,
                    รับรองเวลาทำงาน_1: attendanceList[index]
                        ? `${attendanceList[index].requestTime
                            ? `${attendanceList[index].requestTime.startText} - ${attendanceList[index].requestTime.endText}`
                            : ""
                        }`
                        : "-",
                    เหตุผลเวลาทำงาน_1: attendanceList[index]
                        ? `${attendanceList[index].requestTime
                            ? `${attendanceList[index].requestTime.reasonName}`
                            : ""
                        }`
                        : "-",
                    "เหตุผลอื่นๆ เวลาทำงาน_1": attendanceList[index]
                        ? `${attendanceList[index].requestTime
                            ? `${attendanceList[index].requestTime.otherReason}`
                            : ""
                        }`
                        : "-",
                    "เวลาทำงาน-ผู้บังคับบัญชา":
                        attendanceList[index].requestTime &&
                            attendanceList[index].requestTime.managerLV1_firstname_TH
                            ? `${attendanceList[index].requestTime.managerLV1_firstname_TH}`
                            : "-",
                    ["เวลาทำงาน-ผลอนุมัติ"]:
                        attendanceList[index].requestTime &&
                            attendanceList[index].requestTime.managerLV1_firstname_TH
                            ? attendanceList[index].requestTime.isManagerLV1Approve === null
                                ? "รออนุมัติ"
                                : attendanceList[index].requestTime.isManagerLV1Approve === 1
                                    ? "อนุมัติ"
                                    : "ไม่อนุมัติ"
                            : "-",
                    "เวลาทำงาน-วันที่เวลาอนุมัติ":
                        attendanceList[index].requestTime &&
                            attendanceList[index].requestTime.managerLV1ApproveDate
                            ? dayjs(attendanceList[index].requestTime.managerLV1ApproveDate).format("YYYY-MM-DD HH:mm")
                            : "-",
                    ทำงานล่วงเวลา_1: attendanceList[index]
                        ? `${attendanceList[index].ot[0]
                            ? `${attendanceList[index].ot[0].startText} - ${attendanceList[index].ot[0].endText}`
                            : "-"
                        }`
                        : "-",
                    เหตุผลทำงานล่วงเวลา_1: attendanceList[index]
                        ? `${attendanceList[index].ot[0]
                            ? `${attendanceList[index].ot[0].reasonName}`
                            : "-"
                        }`
                        : "-",
                    "เหตุผลอื่นๆ ทำงานล่วงเวลา_1": attendanceList[index]
                        ? `${attendanceList[index].ot[0]
                            ? `${attendanceList[index].ot[0].otherReason}`
                            : "-"
                        }`
                        : "-",
                    [`OT ${payrollSetting.xWorkingMonthlyHoliday}x วันหยุด`]:
                        attendanceList[index]
                            ? `${attendanceList[index].ot[0] &&
                                attendanceList[index].ot[0].isManagerLV1Approve === 1
                                ? attendanceList[index].ot[0].xWorkingMonthlyHoliday > 0
                                    ? `${parseFloat(
                                        attendanceList[index].ot[0].xWorkingMonthlyHoliday / 60
                                    ).toFixed(2)} ชม.`
                                    : ""
                                : "-"
                            }`
                            : "-",
                    [`OT ${payrollSetting.xOT}x วันทำงาน`]: attendanceList[index]
                        ? `${attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].isManagerLV1Approve === 1
                            ? attendanceList[index].ot[0].xOT > 0
                                ? `${parseFloat(attendanceList[index].ot[0].xOT / 60).toFixed(
                                    2
                                )} ชม.`
                                : ""
                            : "-"
                        }`
                        : "-",
                    [`OT ${payrollSetting.xWorkingDailyHoliday}x`]: attendanceList[index]
                        ? `${attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].isManagerLV1Approve === 1
                            ? attendanceList[index].ot[0].xWorkingDailyHoliday > 0
                                ? `${parseFloat(
                                    attendanceList[index].ot[0].xWorkingDailyHoliday / 60
                                ).toFixed(2)} ชม.`
                                : ""
                            : "-"
                        }`
                        : "-",
                    [`OT ${payrollSetting.xOTHoliday}x`]: attendanceList[index]
                        ? `${attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].isManagerLV2Approve === 1
                            ? attendanceList[index].ot[0].xOTHoliday > 0
                                ? `${parseFloat(
                                    attendanceList[index].ot[0].xOTHoliday / 60
                                ).toFixed(2)} ชม.`
                                : ""
                            : "-"
                        }`
                        : "-",
                    "OT-ผู้บังคับบัญชาลำดับที่ 1":
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].managerLV1_firstname_TH
                            ? attendanceList[index].ot[0].managerLV1_firstname_TH
                            : "-",
                    ["OT-ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1"]:
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].managerLV1_firstname_TH
                            ? attendanceList[index].ot[0].isManagerLV1Approve === null
                                ? "รออนุมัติ"
                                : attendanceList[index].ot[0].isManagerLV1Approve === 1
                                    ? "อนุมัติ"
                                    : "ไม่อนุมัติ"
                            : "-",
                    "OT-วันที่เวลาอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].managerLV1ApproveDate
                            ? dayjs(attendanceList[index].ot[0].managerLV1ApproveDate).format("YYYY-MM-DD HH:mm")
                            : "-",
                    "OT-ผู้บังคับบัญชาลำดับที่ 2":
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].isDoubleApproval === 1
                            ? attendanceList[index].ot[0].isManagerLV2Approve !== 0 &&
                                attendanceList[index].ot[0].managerLV2_firstname_TH
                                ? attendanceList[index].ot[0].managerLV2_firstname_TH
                                : "-"
                            : attendanceList[index].ot[0] &&
                                attendanceList[index].ot[0].approvalLevel === 2
                                ? attendanceList[index].ot[0].managerLV2_firstname_TH
                                    ? attendanceList[index].ot[0].managerLV2_firstname_TH
                                    : "-"
                                : "-",
                    "OT-ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].isDoubleApproval === 1
                            ? attendanceList[index].ot[0].isManagerLV1Approve === null
                                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                                : attendanceList[index].ot[0].isManagerLV2Approve === 1
                                    ? attendanceList[index].ot[0].isManagerLV2Approve === null
                                        ? "รออนุมัติ"
                                        : attendanceList[index].ot[0].isManagerLV2Approve === 1
                                            ? "อนุมัติ"
                                            : "ไม่อนุมัติ"
                                    : "-"
                            : attendanceList[index].ot[0] &&
                                attendanceList[index].ot[0].approvalLevel === 2
                                ? attendanceList[index].ot[0].isManagerLV2Approve &&
                                    attendanceList[index].ot[0].isManagerLV2Approve === null
                                    ? "รออนุมัติ"
                                    : attendanceList[index].ot[0].isManagerLV2Approve === 1
                                        ? "อนุมัติ"
                                        : "ไม่อนุมัติ"
                                : "-",
                    "OT-วันที่เวลาอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
                        attendanceList[index].ot[0] &&
                            attendanceList[index].ot[0].managerLV2ApproveDate
                            ? dayjs(attendanceList[index].ot[0].managerLV2ApproveDate).format("YYYY-MM-DD HH:mm")
                            : "-",
                    การลางาน: attendanceList[index]
                        ? `${attendanceList[index].leave &&
                            attendanceList[index].leave.isApprove === 1
                            ? attendanceList[index].leave.name
                            : ""
                        }`
                        : "-",
                    การลางาน_เวลา: attendanceList[index]
                        ? `${attendanceList[index].leave &&
                            attendanceList[index].leave.isApprove === 1
                            ? `${attendanceList[index].leave.startText} - ${attendanceList[index].leave.endText}`
                            : ""
                        }`
                        : "-",
                    เหตุผลลางาน: attendanceList[index]
                        ? `${attendanceList[index].leave &&
                            attendanceList[index].leave.isApprove === 1
                            ? `${attendanceList[index].leave.description}`
                            : ""
                        }`
                        : "-",
                };

                elementList.push(element);
            }

            const ws = XLSX.utils.json_to_sheet(elementList);

            var workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, ws, "รายงานเวลาทำงาน");
            XLSX.writeFile(
                workbook,
                `รายงานเวลาทำงาน.xlsx`,
                { type: "file" }
            );


            handleClose();
        } else {
            handleChangeAlertType("ไม่พบข้อมูล");
            handleOpenAlert(true);
        }
    }

    return (
        <DrawerCustom
            title="รายงานเวลาทำงาน"
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                    <Typography sx={{ mr: 8 }}>
                        เลือกข้อมูล
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="year" control={<Radio />} label="ทั้งปี" />
                            <FormControlLabel value="month" control={<Radio />} label="รายเดือน" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={12} sm={6} className="GridTopicInput">วันที่ค้นหา</Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    maxDate={new Date()}
                                    inputFormat="MMMM yyyy"
                                    value={inputSearch}
                                    name="start"
                                    views={["year", "month"]}
                                    openTo="month"
                                    onChange={(newValue) => {
                                        setInputSearch(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <StyledTextField variant="filled" fullWidth {...params} />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} >
                    <StyledBoxSearch>
                        <StyledAutocomplete
                            options={affiliateList}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setSelectedCompany(newValue.idCompany);
                                } else {
                                    setSelectedCompany(null);
                                }
                            }}
                            value={selectedCompany}
                            popupIcon={<i class="fa-light fa-chevron-down"></i>}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={"filled"}
                                    placeholder={"เลือกบริษัท"}
                                />
                            )}
                            PopperComponent={StyledPopper}
                        />
                    </StyledBoxSearch>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectEmployeeByIdCompany
                        id={selectedCompany ? selectedCompany : userProfile.idCompany}
                        handleChange={handleSelectEmployee} />
                </Grid>
                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>ยกเลิก</ButtonBlue>
                    <ButtonBlue
                        variant="outlined"
                        startIcon={<DownloadRoundedIcon />}
                        onClick={handleSubmit}
                        disabled={attendanceList === null}
                    >
                        ดาวน์โหลด
                    </ButtonBlue>
                </StyledFooter>
            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>
    );
}

export default Dailyplanner;
