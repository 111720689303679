import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Paper,
  Button,
  Avatar,
  IconButton,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Breadcrumb } from "../../shared/breadcrums";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllProject } from "../../../../actions/project";
import { getAllWork } from "../../../../actions/work";

import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteIcon from "@mui/icons-material/Delete";
import PageviewIcon from "@mui/icons-material/Pageview";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";



const useStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
    minHeight: "calc(100vh - 20px)",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  icon: {
    marginBottom: 2,
  },
  headerText: {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "#007afc",
  },
  header: {
    fontSize: "1rem",
  },
  text: {
    fontSize: 16,
  },
  textSmall: {
    fontSize: 14,
    color: "#9e9e9e",
  },
  textNoData: {
    fontSize: 22,
    fontWeight: "600",
    textAlign: "center",
  },
  backgroundColorMD: {
    backgroundColor: "#43a047",
  },
  backgroundColorMG: {
    backgroundColor: "#e53935",
  },
  backgroundColorSM: {
    backgroundColor: "#039be5",
  },
}));

const ShowProject = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { result: dataWork } = useSelector((state) => state.dataWork);
  const { result: dataProject } = useSelector((state) => state.dataProject);

  useEffect(() => {
    dispatch(getAllProject());
    dispatch(getAllWork());
  }, []);

  let id = [];
  let role = [];

  {
    dataWork &&
      dataWork
        .filter((val) => val.id == 1233)
        .map((val) =>
          val.DataProject.map((val, index) => {
            if (val != null) {
              id[index] = val.id;
              role[index] = val.role;
            }
          })
        );
  }

  return (
    <div className={`page ${classes.root}`}>
      <Container maxWidth="lg">
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xl={8} lg={8} md={6} xs={12}>
            <Typography variant="h4" gutterBottom>
              Project
            </Typography>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            xs={12}
            style={{ textAlign: "right" }}
          >
            <Link to={"/project/addProject"} style={{ textDecoration: "none" }}>
              <ButtonBlue
                variant="contained"
                size={"large"}
                startIcon={<AddIcon />}
              >
                Add new Project
              </ButtonBlue>
            </Link>
          </Grid>
        </Grid>

        {id.length != 0 ? (
          dataProject &&
          dataProject
            .filter((val) => {
              for (let i = 0; i < id.length; i++) {
                if (id[i] == val.id) return val.id;
              }
            })
            .map((val, index) => (
              <div style={{ marginBottom: 16 }}>
                <CardStyle>
                  <div style={{ padding: 20 }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        xs={12}
                        sm={2}
                        alignSelf="center"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <Avatar
                          style={{ width: 50, height: 50 }}
                          className={[
                            `${
                              val.sizeMini == "MD" && classes.backgroundColorMD
                            }`,
                            `${
                              val.sizeMini == "MG" && classes.backgroundColorMG
                            }`,
                            `${
                              val.sizeMini == "SM" && classes.backgroundColorSM
                            }`,
                          ].join(" ")}
                        >
                          {val.sizeMini}
                        </Avatar>
                      </Grid>
                      <Grid item xl={5} lg={5} md={5} xs={12} sm={10}>
                        <Typography className={classes.headerText}>
                          {val.name}
                        </Typography>
                        <Typography className={classes.text}>
                          {val.size}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.textSmall}>
                          Return (MB)
                        </Typography>
                        <Typography className={classes.header}>
                          {val.return}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.textSmall}>
                          Start date
                        </Typography>
                        <Typography className={classes.header}>
                          {val.dateStart}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.textSmall}>
                          End date
                        </Typography>
                        <Typography className={classes.header}>
                          {val.dateEnd}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className={classes.textSmall}>
                          Role
                        </Typography>
                        <Typography className={classes.header}>
                          {role[index]}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        style={{ textAlign: "end" }}
                      >
                        <Link
                          to={`/project/showDetail/${val.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <IconButton sx={{ color: "success.dark" }}>
                            <PageviewIcon style={{ fontSize: 28 }} />
                          </IconButton>
                        </Link>
                        <Link
                          to={`/project/editProject/${val.id} ${role[index]} `}
                          style={{
                            textDecoration: "none",
                            width: "200px",
                            height: "300px",
                          }}
                        >
                          <IconButton sx={{ color: "primary.main" }}>
                            <EditIcon style={{ fontSize: 28 }} />
                          </IconButton>
                        </Link>
                        <IconButton style={{ color: "red" }}>
                          <DeleteIcon style={{ fontSize: 28 }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                </CardStyle>
              </div>
            ))
        ) : (
          <Paper
            elevation={2}
            style={{ padding: 20, margin: 20, textAlign: "center" }}
          >
            <Typography className={classes.header}>No data to show</Typography>
          </Paper>
        )}
      </Container>
    </div>
  );
};

export default ShowProject;
