import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import DoneAll from "@mui/icons-material/DoneAll";
import ArticleIcon from '@mui/icons-material/Article';
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getExitInterviewEmployees } from "../../../../actions/employee";
import { getExitInterviewAndAnswer, updateWithDrawExitInterview } from "../../../../actions/exitInterview";
import { employeeExitInterviewApprove } from "../../../../actions/employee";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import StatusRequest from "../../shared/requestList/stausRequest";
import DialogExitInterviewShow from "./DialogExitInterviewShow";
import DialogConfirmReject from "./DialogConfirmReject";

const RequestListExitInterview = (props) => {
    const { setSnackBarConfig, setNumberOfList } = props;

    const dispatch = useDispatch();
    const { result: employeesExitInterview } = useSelector((state) => state.employeesExitInterview);

    const [openExitInterview, setOpenExitInterview] = useState(false);
    const [typeDialog, setTypeDialog] = useState("Add");
    const [idExitInterview, setIdExitInterview] = useState(null);
    const [detail, setDetail] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

    const handleOpenExitInterview = (type, idEdu) => {
        if (type === "Add") {
            setTypeDialog("Add");
        } else {
            setTypeDialog("Edit");
        }
        if (idEdu) {
            setIdExitInterview(idEdu);
        }
        setOpenExitInterview(true);
    };

    const handleClose = () => {
        setOpenExitInterview(false);
        setIdExitInterview(null);
    };

    const handleApprovalRequest = async (approve, comment) => {
        let formData = [...selectedRows];

        formData.forEach((item) => {
            item.resultExitInterview = approve;
            item.managerComment = comment;
        });

        setIsOpenRejectDialog(false);
                 const result = await dispatch(employeeExitInterviewApprove(formData));
                if (result) {
                    setSelectedRows([]);
                    dispatch(getExitInterviewEmployees());
                    if (result.status === 200) {
                        setSnackBarConfig({
                            open: true,
                            message: "ดำเนินการสำเร็จ",
                            type: "success",
                        });
                    } else {
                        setSnackBarConfig({
                            open: true,
                            message: "ดำเนินการไม่สำเร็จ",
                            type: "error",
                        });
                    }
                } else {
                    setSnackBarConfig({
                        open: true,
                        message: "ดำเนินการไม่สำเร็จ",
                        type: "error",
                    });
                } 
    };

    const handleConfirmReject = (comment) => {
        handleApprovalRequest(2, comment);
    };

    useEffect(() => {
        if (employeesExitInterview) {
            setNumberOfList(employeesExitInterview.length);
        }

    }, []);

    const handleStatus = (row) => {
        if (row.resultExitInterview === 1) {
            return <div>
                <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={1}
                    active={1}
                />
            </div>
        } else if (row.resultExitInterview === 2) {
            return <div>
                <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={0}
                    active={1}
                />
            </div>
        }
        else if (row.resultExitInterview === 3) {
            return <div>
                <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={null}
                    step={null}
                    active={1}
                />
            </div>
        } else if (row.resultExitInterview === 4) {
            return <div>
                <StatusRequest
                    boxSize={30}
                    fontSize={18}
                    borderRadius={8}
                    status={null}
                    step={null}
                    active={0}
                />
            </div>
        }
    }

    const columns = [
        {
            name: "สถานะ",
            headerTextAlign: "center",
            minWidth: "50px",
            width: "50px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    {handleStatus(row)}
                </Box>
            ),
        },
        {
            name: "วันที่เริ่มมีผล",
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    <Typography>
                        {`${dayjs(row.dateStart).format("D MMM BBBB")}`}
                    </Typography>
                </Box>
            ),
        },
        {
            name: "เหตุผล",
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    <Typography>
                        {`${row.reason}`}
                    </Typography>
                </Box>
            ),
        },
        {
            headerTextAlign: "center",
            minWidth: "50px",
            width: "50px",
            cellRender: (row) => (
                <div style={{ display: "flex", justifyContent: "center", align: "center" }}>
                    <ButtonBlue
                        variant="contained"
                        onClick={() => { handleOpenExitInterview("Add"); setDetail(row); }}
                    >
                        <ArticleIcon />
                    </ButtonBlue>
                </div>
            ),
        },
    ];

    return (
        <Box>
            {selectedRows.length > 0 && (
                <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
                    <ButtonBlue
                        style={{ marginRight: 8 }}
                        startIcon={<DoneAll />}
                        variant="contained"
                        onClick={() => handleApprovalRequest(1, null)}
                    >
                        {`อนุมัติ ${selectedRows.length > 0 ? `${selectedRows.length} รายการ` : ""
                            }`}
                    </ButtonBlue>
                    <ButtonBlue
                        startIcon={<DoneAll />}
                        variant="outlined"
                        onClick={() => setIsOpenRejectDialog(true)}
                    >
                        {`ไม่อนุมัติ ${selectedRows.length > 0 ? `${selectedRows.length} รายการ` : ""
                            }`}
                    </ButtonBlue>
                </Box>
            )}
            <TableCustom
                columns={columns}
                rows={
                    employeesExitInterview ? employeesExitInterview : []
                }
                canSelect
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
            />

            {openExitInterview && (
                <DialogExitInterviewShow
                    open={openExitInterview}
                    handleClose={handleClose}
                    idEdu={idExitInterview}
                    type={typeDialog}
                    detail={detail}
                />
            )}
            <DialogConfirmReject
                rejectCount={selectedRows.length}
                open={isOpenRejectDialog}
                onClose={() => {
                    setIsOpenRejectDialog(false);
                }}
                handleSubmit={(comment) => {
                    handleConfirmReject(comment);
                }}
            />
        </Box>
    );
};

export default RequestListExitInterview;
