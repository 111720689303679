import React, { useEffect, useState } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";


const useStyles = makeStyles(() => ({
  headerCard: {
    color: "#222f3e",
    padding: 16,
    borderRadius: "10px",
  },
  headerCard_Objective: {
    marginBottom: 16,
    paddingLeft: 24,
  },
  headerIcon: {
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
  },
  headerIcon_Objective: {
    width: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ede7f6",
    borderRadius: "50%",
  },
  svgIcon_Objective: {
    fontSize: "3rem",
    color: "#7e57c2",
  },
  headerIcon_KR: {
    width: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e3f2fd",
    borderRadius: "50%",
  },
  svgIcon_KR: {
    fontSize: "3rem",
    color: "#2196f3",
  },
  headerIcon_acheiveOKR: {
    width: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fce4ec",
    borderRadius: "50%",
  },
  svgIcon_acheiveOKR: {
    fontSize: "3rem",
    color: "#ec407a",
  },
  headerIcon_MoonShot: {
    width: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff3e0",
    borderRadius: "50%",
  },
  svgIcon_MoonShot: {
    fontSize: "3rem",
    color: "#ff9933",
  },
  data: {
    fontSize: 18,
    fontWeight: "bold",
  },
}));

export default function OverviewDashboard(props) {
  const { teams } = props;
  const classes = useStyles();
  const [total, setTotal] = useState([]);

  useEffect(() => {
    if (teams) {
      let totalObj = 0;
      let totalKR = 0;
      let totalAchieved = 0;

      teams.forEach((t) => {
        totalObj += t.objectiveAmount;
        totalKR += t.keyResultAmount;
        totalAchieved += t.achieved;
      });

      setTotal({
        objective: totalObj,
        keyresult: totalKR,
        acheive: totalAchieved,
      });
    }
  }, [teams]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="start"
            spacing={2}
            sx={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Paper className={classes.headerCard}>
                <Grid container>
                  <Grid item xs={4} md={3} lg={3} xl={3}>
                    <div className={classes.headerIcon}>
                      <div className={classes.headerIcon_Objective}>
                        <FlagRoundedIcon
                          className={classes.svgIcon_Objective}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={8} md={9} lg={9} xl={9}>
                    <div className={classes.headerCard_Objective}>
                      <Typography variant="h2">{total.objective}</Typography>
                      <Typography className={classes.data}>
                        Objective
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Paper className={classes.headerCard}>
                <Grid container>
                  <Grid item xs={4} md={3} lg={3} xl={3}>
                    <div className={classes.headerIcon}>
                      <div className={classes.headerIcon_KR}>
                        <VpnKeyRoundedIcon className={classes.svgIcon_KR} />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={8} md={9} lg={9} xl={9}>
                    <div className={classes.headerCard_Objective}>
                      <Typography variant="h2">{total.keyresult}</Typography>
                      <Typography className={classes.data}>
                        Key Result
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Paper className={classes.headerCard}>
                <Grid container>
                  <Grid item xs={4} md={3} lg={3} xl={3}>
                    <div className={classes.headerIcon}>
                      <div className={classes.headerIcon_acheiveOKR}>
                        <StarRoundedIcon
                          className={classes.svgIcon_acheiveOKR}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={8} md={9} lg={9} xl={9}>
                    <div className={classes.headerCard_Objective}>
                      <Typography variant="h2">{total.acheive}</Typography>
                      <Typography className={classes.data}>
                        Achieved OKR
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
