import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogName from "./DialogName";
import {
  getOnBoardingList,
  getOnBoardingReviewer,
} from "../../../../../actions/onBoarding";
import DialogReview from "./DialogReview";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 30,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const OnBoardingList = () => {
  const dispatch = useDispatch();
  const { result: onBoardingReviewer } = useSelector((state) => state.onBoardingReviewer);
  const { result: onBoardingList } = useSelector((state) => state.onBoardingList);
  const [openDialog, setopenDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");
  const [reload, setreload] = useState();

  useEffect(() => {
    dispatch(getOnBoardingList());
    dispatch(getOnBoardingReviewer());
  }, [reload]);

  const handleOpenDialog = (type, formData) => {
    setTypeDialog(type);
    setopenDialog(true);
    if (formData) {
      setFormData(formData);
    }
  };

  const handleClose = () => {
    setopenDialog(false);
    if (reload === 1) setreload(2);
    else setreload(1);
    setFormData(null);
  };

  return (
    <>
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <div>
            <ButtonBlue
              style={{ marginRight: 8 }}
              size="small"
              variant="contained"
              onClick={() => handleOpenDialog("AddList")}
            >
              เพิ่มรายการ
            </ButtonBlue>
            <ButtonBlue
              style={{ marginRight: 8 }}
              size="small"
              variant="contained"
              onClick={() => handleOpenDialog("AddReview")}
            >
              เพิ่มผู้ตรวจสอบ
            </ButtonBlue>
          </div>
        </div>
        {onBoardingList && onBoardingList.length > 0 ? (
          <div>
            {onBoardingList.map((row, index) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{ width: "90%", flexShrink: 0 }}
                  >{`${row.name}  ภายใน ${row.day} วัน`}</Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    ผู้ตรวจสอบ
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {onBoardingReviewer && onBoardingReviewer.length > 0 ? (
                    onBoardingReviewer.filter(
                      (review) =>
                        review.idOnBoardingList === row.idOnBoardingList
                    ).length > 0 ? (
                      onBoardingReviewer.map((review) => {
                        if (review.idOnBoardingList === row.idOnBoardingList)
                          return (
                            <Typography>
                              {review.firstname_TH +
                                "   " +
                                review.lastname_TH +
                                "   (" +
                                review.departmentName +
                                ")"}
                            </Typography>
                          );
                      })
                    ) : (
                      <Typography>ไม่มีผู้ตรวจสอบ </Typography>
                    )
                  ) : (
                    <Typography>ไม่มีผู้ตรวจสอบ </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        ) : (
          "ไม่มีรายการ"
        )}
      </StyledWrapBranch>
      {openDialog && typeDialog !== "AddReview" && (
        <DialogName
          mode={typeDialog}
          open={openDialog}
          data={formData}
          handleClose={handleClose}
        />
      )}
      {openDialog && typeDialog === "AddReview" && (
        <DialogReview open={openDialog} handleClose={handleClose} />
      )}
    </>
  );
};

export default OnBoardingList;
