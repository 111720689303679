import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import liff from "@line/liff";
import { useTitle } from "react-use";

import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import Register from "./register";

import Backdrop from "../shared/general/Backdrop";

import { getLineUserProfile, registerLine } from "../../../actions/line";

const StyledChipTime = styled(Chip)({
  height: 32,
  width: "100%",
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  "&.check-in": {
    "& .MuiTypography-root": {
      color: "#229a16 ",
    },
    backgroundColor: "#54d62c29",
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    "& .MuiTypography-root": {
      color: "#b72136",
    },
    backgroundColor: "#ff484229",
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const StyledBox = styled(Box)({
  textAlign: "center",
  padding: "4px 0",
  "& .time": {
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      marginRight: 4,
    },
  },
  "& .location": {
    fontWeight: 600,
  },
});

const StyledBoxEmpty = styled(Box)({
  height: 60,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiTypography-root": {
    fontStyle: "oblique",
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 100,
    backgroundColor: "#FFFFFF",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "5px",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  paperCheckIn: {
    padding: theme.spacing(3),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
  },
  dateTime: {
    color: "#16243D",
    borderRadius: "10px",
    marginBottom: "20px",
  },
  checkInBtn: {
    color: "#fff",
    width: "100%",
    height: "50px",
    backgroundColor: "#66bb6a",
    border: "1px solid #66bb6a",
    "&:hover": {
      borderColor: "#4caf50",
      backgroundColor: "#4caf50",
    },
  },
  checkOutBtn: {
    color: "#fff",
    width: "100%",
    height: "50px",
    backgroundColor: "#ef5350",
    border: "1px solid #ef5350",
    "&:hover": {
      borderColor: "#e53935",
      backgroundColor: "#e53935",
    },
  },
  headPanel: {
    fontWeight: 600,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  locationMessage: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  messagePassWrapper: {
    display: "flex",
    alignItems: "center",
    color: "#388e3c",
  },
  messageFailWrapper: {
    display: "flex",
    alignItems: "center",
    color: "#d32f2f",
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TodayCheck() {
  const classes = useStyles();
  const dispatch = useDispatch();
  useTitle("เวลาทำงานวันนี้");
  const [time, setTime] = useState(new Date());
  // const [state, setState] = useState({
  //   name: "PANG [บื้อเจ้าเก่า]",
  //   userLineID: "U06fad2faaf363cd20fd2c22eb6bb50f1",
  //   pictureUrl:
  //     "https://profile.line-scdn.net/0hr3KBZfxMLRxmOwVFlepTYxZrLnZFSnQOQgkwKlY8c3wIWDhMSwhieFFpeykOAjhCQ11nLVQ9ci5qKFp6eG3RKGELcytfDWtLSllq-A",
  // });
  const [state, setState] = useState(null);
  const [lineUserProfile, setLineUserProfile] = useState(null);
  const [dataRegister, setDataRegister] = useState(null);
  const [fetchingData, setFetchingData] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");

  useEffect(() => {
    liff
      .init({ liffId: "1657850475-X98WB2aj" })
      .then(async () => {
        if (liff.isLoggedIn()) {
          handleGetProfile();
        } else {
          liff.login();
        }
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [state]);

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const handleGetProfile = async () => {
    let getProfile = await liff.getProfile();
    if (getProfile) {
      setState({
        name: getProfile.displayName,
        userLineID: getProfile.userId,
        pictureUrl: getProfile.pictureUrl,
        statusMessage: getProfile.statusMessage,
      });
    }
  };

  async function fetchData() {
    const result = await dispatch(getLineUserProfile(state.userLineID));
    if (result) {
      setFetchingData(false);
      if (result.status === 404) {
        setDataRegister("");
      } else {
        setLineUserProfile(result);
      }
    } else {
      setFetchingData(false);
      setDataRegister("");
    }
  }

  const handleChangePersonalId = (event) => {
    setDataRegister(event.target.value);
  };

  const handleCloseAlert = () => {
    setOpenAlert("");
  };

  const handleRegisterLine = async () => {
    const dataForm = {
      lineUserId: state.userLineID,
      personalId: dataRegister,
    };

    const result = await dispatch(registerLine(dataForm));
    if (result) {
      console.log(result);
      if (result.data.rows === 1) {
        setOpenAlert(true);
        handleChangeAlertType("success");
        setDataRegister(null);
        setFetchingData(true);
        fetchData();
      } else {
        alert("ไม่พบเลขบัตรประชาชน โปรดลองอีกครั้ง");
      }
    } else {
      setOpenAlert(true);
      handleChangeAlertType("error");
    }
  };

  const handleChangeAlertType = (status) => {
    setAlertType(status);
  };

  return (
    <div className={`page ${classes.root}`}>
      {fetchingData ? (
        <Backdrop open={fetchingData} />
      ) : (
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={classes.paperCheckIn}>
                <Box className={classes.dateTime}>
                  <Box sx={{ marginBottom: "20px" }}>
                    <Typography align="center" className={classes.headPanel}>
                      เวลาทำงานวันนี้
                    </Typography>
                  </Box>
                  <Box style={{ marginBottom: "10px" }}>
                    <Grid container>
                      <Grid item xs={4} md={4} lg={4}>
                        <Typography
                          variant="body1"
                          className={classes.wrapIcon}
                          color="text.secondary"
                        >
                          <TodayIcon />
                          วันที่
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body1">
                          {dayjs(new Date()).format("DD MMMM YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container>
                      <Grid item xs={4} md={4} lg={4}>
                        <Typography
                          variant="body1"
                          className={classes.wrapIcon}
                          color="text.secondary"
                        >
                          <AccessTimeIcon />
                          เวลา
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body1">
                          {dayjs(time).format("HH:mm:ss")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Divider className={classes.divider} />
                {lineUserProfile && (
                  <div style={{ marginBottom: 16 }}>
                    <Typography variant="h6" color="text.third">
                      สวัสดี...
                    </Typography>
                    <Typography
                      variant="h5"
                      align="center"
                    >{`${lineUserProfile.firstname_TH} ${lineUserProfile.lastname_TH}`}</Typography>
                  </div>
                )}
                {dataRegister !== null ? (
                  <Register
                    handleChange={handleChangePersonalId}
                    handleClick={handleRegisterLine}
                  />
                ) : (
                  <Fragment>
                    <Box>
                      <Grid container spacing={2} style={{ marginBottom: 16 }}>
                        <Grid item xs={6}>
                          <StyledChipTime
                            className="check-in"
                            label={
                              <Fragment>
                                <Typography
                                  component="span"
                                  style={{ marginRight: 8 }}
                                >
                                  <i class="fa-solid fa-arrow-right-to-bracket"></i>
                                </Typography>
                                <Typography component="span">
                                  เข้างาน
                                </Typography>
                              </Fragment>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledChipTime
                            className="check-out"
                            label={
                              <Fragment>
                                <Typography
                                  component="span"
                                  style={{ marginRight: 8 }}
                                >
                                  <i class="fa-solid fa-arrow-right-from-bracket"></i>
                                </Typography>
                                <Typography component="span">ออกงาน</Typography>
                              </Fragment>
                            }
                          />
                        </Grid>
                      </Grid>
                      {lineUserProfile && lineUserProfile.attendance.length > 0 ? (
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            {lineUserProfile.attendance &&
                              lineUserProfile.attendance
                                .filter((x) => x.isCheckIn)
                                .map((x) => (
                                  <StyledBox>
                                    <Typography className="time">
                                      {x.idAttendanceType === 1 ? (
                                        <LocationOnIcon />
                                      ) : (
                                        <FingerprintIcon />
                                      )}
                                      {x.time}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                      className="location"
                                    >
                                      {x.gpsLocationsName
                                        ? x.gpsLocationsName
                                        : ""}
                                    </Typography>
                                    <StyledDivider />
                                  </StyledBox>
                                ))}
                          </Grid>
                          <Grid item xs={6}>
                            {lineUserProfile.attendance &&
                              lineUserProfile.attendance
                                .filter((x) => !x.isCheckIn)
                                .map((x) => (
                                  <StyledBox>
                                    <Typography className="time">
                                      {x.idAttendanceType === 1 ? (
                                        <LocationOnIcon />
                                      ) : (
                                        <FingerprintIcon />
                                      )}
                                      {x.time}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                      className="location"
                                    >
                                      {x.gpsLocationsName
                                        ? x.gpsLocationsName
                                        : ""}
                                    </Typography>
                                    <StyledDivider />
                                  </StyledBox>
                                ))}
                          </Grid>
                        </Grid>
                      ) : (
                        <StyledBoxEmpty>
                          <Typography color="text.secondary">
                            ไม่มีเวลาเข้างาน หรือ เวลาออกงาน
                          </Typography>
                        </StyledBoxEmpty>
                      )}
                    </Box>
                  </Fragment>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {alertType === "success" ? (
              <Alert
                onClose={handleCloseAlert}
                severity="success"
                style={{ width: "100%" }}
              >
                บันทึกข้อมูลสำเร็จ
              </Alert>
            ) : (
              <Alert
                onClose={handleCloseAlert}
                severity="error"
                style={{ width: "100%" }}
              >
                เกิดข้อผิดพลาด...ติดต่อผู้ดูแลระบบ
              </Alert>
            )}
          </Snackbar>
        </Container>
      )}
    </div>
  );
}

export default TodayCheck;
