import React,{ useState, useRef} from "react";
import * as Yup from "yup";
import {
  MenuItem,
  Typography,
  Grid,
  Button,
  Container,
  Paper,
  TextField,
  Select
} from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import SaveIcon from "@mui/icons-material/Save";
import AssignmentIcon from '@mui/icons-material/Assignment';
// import { TextField, Select, } from 'formik-mui';
// import {DatePicker} from 'formik-mui-lab';
import { DatePicker } from "@mui/x-date-pickers";
import { Formik, Form, Field } from "formik";
import { Breadcrumb2 } from "../../shared/breadcrums";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';



const useStyles = makeStyles(() => ({
  formControl: {
    marginRight: 8,
  },
  root: {
    // background: "linear-gradient(45deg, #228b22 60%, #32cd32 90%)",
    // borderRadius: 3,
    // color: "white",
    // height: 35,
    // padding: "0 30px",
    marginTop:"20px",
    minHeight:"calc(100vh - 20px)"
  },
  buttonDelete: {
    backgroundColor: "red",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 42,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  icon: {
    fontSize: 19,
    color: '#0b3f86',
  },
  nameDemand: {
    fontSize: 24,
    fontWeight: "bold",
    marginLeft: 20,
  },
  breadcrumbs: {
    marginTop: 5,
    fontSize: 18,
  },
  dataText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sendIcon: {
    marginBottom: 5,
    marginRight: 5,
    color: "black",
  },
  wrapCaptionImage: {
    marginTop: 16,
  },
  captionImage: {
    color: "#212b36",
  },
  firstSection: {
    padding: "60px 24px",
    margin: 10,
  },
  imageProfile: {
    marginBottom: 62,
    textAlign: "center",
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(224, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
  captionImage: {
    color: "#212b36",
    fontSize: 14,
  },
  wrapCaptionImage: {
    textAlign: "center",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 7,
  },
  text:{
    fontSize: 18,
    marginBottom: 7,
    // color: ''
  },
  fieldDate: {
    "& .MuiFormControl-root": {
      width : '100%'
    }
  },
  fieldInterDate: {
    "& .MuiFormControl-root": {
      width : 'calc(100% - 16px)'
    }
  },
  nameHeader: {
    fontSize: 21,
    fontWeight: 'bold',
    marginBottom: 10,
    marginLeft: 10,
  }
}));

const AddProject = () => {
  //use style in name of classes
  const classes = useStyles();
  const formRef = useRef();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    type: Yup.string().required("Please select type"),
    impact: Yup.string().required("Please select impact"),
    directReturn: Yup.string().required("Please select direct return"),
    return: Yup.string().required("Please select return money ฿"),
    sizing: Yup.string().required("Please select project size"),
    startDate: Yup.string().required("Please select start date"),
    endDate: Yup.string().required("Please select end date"),
    role: Yup.string().required("Please select role"),
    verify: Yup.string().required("Please enter verify"),
  });

  //data in array
  const [dataProject, setDataProject] = useState(
    {
     name: '',
     type: '',
     reason: '',
     impact: '',
     directReturn: '',
     return: '',
     sizing: '',
     startDate: '',
     endDate: '',
     role: '',
     verify: '',
     note: '',
  });

  const handleClickSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
      <div className={`page ${classes.root}`}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom style={{marginTop: 20}}>Add new Project</Typography>
          <Formik
            initialValues={dataProject}
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting}) => {
              console.log('saved value', values)
              setDataProject(values);
            }}
          >
            {({ values }) => (
              <Form autoComplete="off">
                <Paper style={{ padding : 20, paddingRight:"35px"}}>
                    <Grid container direction='row' spacing={2}>
                      <Grid item xl={12} lg={12} md={!2}>
                        <Typography className={classes.nameHeader} >Project Detail</Typography>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} xs={12} >
                           {/* <Typography className={classes.text}> <LocalOfferOutlinedIcon className={classes.icon}/> Project name</Typography> */}
                           <Field
                               component={TextField} 
                               id="name"
                               name= 'name'
                               label="Project name"
                               variant="outlined"
                               required
                               placeholder="Project name" 
                               inputProps={{ maxLength: 100 }}
                               fullWidth
                               />
                       </Grid>
                       <Grid item xl={4} lg={4} md={4} xs={12} >
                          {/* <Typography className={classes.text}> <DragIndicatorIcon className={classes.icon}/> Select type </Typography> */}
                            <Field
                               component={Select}
                               required
                               formControl={{ sx : { width: '-webkit-fill-available'} }}
                               labelId="type-select-outlined-label"
                               name='type'
                               id="type-select"
                               label="Select Type"
                             >
                               <MenuItem value="one">First type</MenuItem>
                               <MenuItem value="two">Second type</MenuItem>
                             </Field>
                       </Grid>
                        <Grid item xl={8} lg={8} md={8} xs={12} >
                          {/* <Typography className={classes.text}> <DehazeIcon className={classes.icon}/> Reason </Typography> */}
                           <Field
                               component={TextField} 
                               id="reason"
                               name= 'reason'
                               label="Reason"
                               variant="outlined"
                              placeholder="Type the Reason" 
                               fullWidth
                               />
                       </Grid>
                       <Grid item xl={4} lg={4} md={4} xs={12} >
                            {/* <Typography className={classes.text}> <BallotIcon className={classes.icon}/> Select impact </Typography> */}
                            <Field
                               component={Select}
                               fullWidth
                               formControl={{ sx : { width: '-webkit-fill-available'} }}
                               labelId="impact-select-outlined-label"
                               name='impact'
                                id="impact-select"
                               label="Select Impact"
                               required
                             >
                               <MenuItem value="one">First type</MenuItem>
                               <MenuItem value="two">Second type</MenuItem>
                             </Field>
                       </Grid>
                       <Grid item xl={4} lg={4} md={4} xs={12} >
                       {/* <Typography className={classes.text}> 
                          <AssignmentReturnIcon className={classes.icon}/> Select direct return 
                        </Typography> */}
                            <Field
                               component={Select}
                               fullWidth
                               formControl={{ sx : { width: '-webkit-fill-available'} }}
                               labelId="direct-return-select-outlined-label"
                               name='directReturn'
                                id="direct-return-select"
                               label="Select Direct Return"
                               required
                             >
                               <MenuItem value="one">First type</MenuItem>
                               <MenuItem value="two">Second type</MenuItem>
                             </Field>
                       </Grid>
                       <Grid item xl={4} lg={4} md={4} xs={12} >
                          {/* <Typography className={classes.text}> 
                            <MonetizationOnIcon className={classes.icon}/> Select return ฿
                          </Typography> */}
                            <Field
                               component={Select}
                               fullWidth
                               formControl={{ sx : { width: '100%'} }}
                               labelId="return-select-outlined-label"
                               name='return'
                                id="return-select"
                               label="Select Return Baht"
                               required
                             >
                               <MenuItem value="one">First type</MenuItem>
                               <MenuItem value="two">Second type</MenuItem>
                             </Field>
                       </Grid>
                      
                        <Grid item xl={12} lg={12} md={12}>
                          <Typography className={classes.nameHeader} style={{marginTop: 20}}>Project Deep Detail</Typography>
                        </Grid>
                        
                       <Grid item xl={4} lg={4} md={4} xs={12} >
                         {/* <Typography className={classes.text}> <AspectRatioIcon className={classes.icon}/> Select size </Typography> */}
                            <Field
                               component={Select}
                               formControl={{ sx : { width: '-webkit-fill-available'} }}
                               labelId="size-select-outlined-label"
                               name='sizing'
                               id="sizing"
                               label="Size of Project"
                               required
                               >
                               <MenuItem value="one">Small project</MenuItem>
                               <MenuItem value="two">Medium project</MenuItem>
                               <MenuItem value="three">Mega propect</MenuItem>
                             </Field>
                       </Grid>
                       <Grid item xl={4} lg={4} md={4} xs={12} >
                         {/* <Typography className={classes.text}> <TodayIcon className={classes.icon}/> Select start date </Typography> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <div 
                                className={classes.fieldInterDate}
                              >
                                  <Field 
                                    component={DatePicker}
                                    name='startDate'
                                    label='Start Date'
                                    maxDate={values.endDate}
                                  />
                              </div>
                            </LocalizationProvider>
                       </Grid>
                       <Grid item xl={4} lg={4} md={4} xs={12} >
                         {/* <Typography className={classes.text}> <EventIcon className={classes.icon}/> Select end date </Typography> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div className={classes.fieldDate}>
                                  <Field 
                                    component={DatePicker}
                                    name='endDate'
                                    label='End Date'
                                    minDate={values.startDate}
                                    className={classes.date} 
                                  />
                                  </div>
                              </LocalizationProvider>
                       </Grid>
                       <Grid item xl={4} lg={4} md={4} xs={12} >
                         {/* <Typography className={classes.text}> <AssignmentIndIcon className={classes.icon}/> Select role </Typography> */}
                            <Field
                               component={Select}
                               formControl={{ sx : { width: '-webkit-fill-available'} }}
                               labelId="role-select-outlined-label"
                               name='role'
                               id="role"
                               label="Select Role"
                             >
                               <MenuItem value="one">Leader</MenuItem>
                               <MenuItem value="two">Employee</MenuItem>
                               <MenuItem value="three">IT support</MenuItem>
                             </Field>
                       </Grid>
                       <Grid item xl={8} lg={8} md={8} xs={12} >
                         {/* <Typography className={classes.text}> <VerifiedIcon className={classes.icon}/> Verify name </Typography> */}
                            <Field
                               component={TextField} 
                               id="verify-name"
                               name= 'verify'
                               label="Verify name"
                               variant="outlined"
                               required
                               placeholder="Verify name" 
                               fullWidth
                               />
                       </Grid>

                       <Grid item xl={12} lg={12} md={12}>
                          <Typography className={classes.nameHeader} style={{marginTop: 20}}>Note</Typography>
                        </Grid>
                       <Grid item xl={12} lg={12} md={12} xs={12}  >
                         {/* <Typography className={classes.text}> <NoteAltIcon className={classes.icon}/> Note scope/Impact </Typography> */}
                            <Field
                               component={TextField} 
                               id="note-scope"
                               name= 'note'
                               label="Note scope/Impact"
                               variant="outlined"
                               placeholder="Note scope/Impact" 
                               fullWidth
                               multiline
                                rows={3}
                            />
                       </Grid>
                    </Grid>
                 </Paper>
                  
                    <div style={{display: 'flex', justifyContent:'end'}}>
                      <Button
                        type='submit'
                        variant="contained"
                        startIcon={<SaveIcon />}
                        style={{
                        //   marginRight: 15,
                          backgroundColor: "green",
                          color: "white",
                          fontSize: 16,
                          marginBottom: 40,
                          marginTop: 20,
                        }}
                      >
                       Submit
                      </Button>
                    </div>
                  {/* </div>
                </Grid> */}
              </Form>
            )}
          </Formik>
        </Container>
      </div>
  );
};

export default AddProject;
