import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Link,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Slider from "react-slick";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.grey[50],
}));

const CustomDialogTitle = ({ children, onClose }) => {
  return (
    <DialogTitle style={{ color: "#ffffff", backgroundColor: "#B00020" }}>
      {children}
      {onClose ? (
        <StyledIconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      ) : null}
    </DialogTitle>
  );
};

const CustomArrow = ({ children, className, style, onClick }) => {
  return (
    <IconButton
      disableRipple
      className={className}
      style={{ ...style, display: "block", color: "#B00020" }}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: (
    <CustomArrow>
      <ArrowForwardIosIcon />
    </CustomArrow>
  ),
  prevArrow: (
    <CustomArrow>
      <ArrowBackIosNewIcon />
    </CustomArrow>
  ),
};

const imgaeFileType = ["image/jpeg", "image/png"];

function DialogAnnouncement({ announcement, open, handleClose }) {
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <CustomDialogTitle onClose={handleClose}>ประกาศ</CustomDialogTitle>
      <Paper sx={{ p: 5, overflowY: "scroll" }}>
        <Slider {...settings}>
          {announcement.map((item) => (
            <div key={item.idAnnouncement}>
              <Typography variant="h6">{`ชื่อประกาศ : ${item.title}`}</Typography>
              <Typography variant="body2">รายละเอียด</Typography>
              <Typography>{item.detail}</Typography>
              {imgaeFileType.includes(item.fileType) ? (
                <div
                  style={{ margin: "16px auto auto", maxWidth: "90%" }}
                >
                  <img src={item.file} width={"100%"} height={"auto"} />
                </div>
              ) : (
                <Button
                  startIcon={<PictureAsPdfIcon />}
                  component={Link}
                  href={item.file}
                  target="_blank"
                  rel="noopener"
                >
                  PDF LINK
                </Button>
              )}
            </div>
          ))}
        </Slider>
      </Paper>
    </Dialog>
  );
}

export default DialogAnnouncement;
