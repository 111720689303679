import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";

import AddIcon from "../../../../assets/add.png";

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 8,
  marginTop: 8,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 64,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      "& img": {
        marginRight: 16,
      },
      "& .MuiTypography-root": {
        marginTop: 8,
        backgroundColor: "transparent",
      },
    },
  },
});

const UploadFile = (props) => {
  const {
    handleSetFileSelected,
    mode,
    values,
    setFormFileSelected,
    formFileSelected,
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      if (formFileSelected) {
        let temp = formFileSelected;
        acceptedFiles.map((file) => {
          temp.append(`file-${mode}`, file);
          temp.set("payrunMode", values.payrunMode);
          temp.set(
            "payrunPeriod",
            values.paymentRoundNumber === "first"
              ? dayjs(values.payperiod)
                  .set("date", 1)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .format("YYYY-MM-DD HH:mm:ss")
              : dayjs(values.payperiod)
                  .set("date", 16)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .format("YYYY-MM-DD HH:mm:ss")
          );
          temp.set("idPaymentType", values.idPaymentType);
          temp.set("payRunTypes", values.payRunTypes);
          temp.set("payrunName", values.payrunName);
          temp.set("isTimeAttendance", values.isTimeAttendance);
          temp.set("isOT", values.isOT);
          temp.set("isRecurring", values.isRecurring);
          temp.set("isOnetime", values.isOnetime);
          temp.set("isIncome", values.isIncome);
          temp.set("paymentRoundNumber", values.paymentRoundNumber);
        });
   
        for (var pair of temp.entries()) {
          console.log(pair[0]+ ', ' + pair[1]); 
      }
        setFormFileSelected(temp);
      } else {
        const formData = new FormData();
        acceptedFiles.map((file) => {
          formData.append(`file-${mode}`, file);
          formData.append("payrunMode", values.payrunMode);
          formData.append(
            "payrunPeriod",
            values.paymentRoundNumber === "first"
              ? dayjs(values.payperiod)
                  .set("date", 1)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .format("YYYY-MM-DD HH:mm:ss")
              : dayjs(values.payperiod)
                  .set("date", 16)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .format("YYYY-MM-DD HH:mm:ss")
          );
          formData.append("idPaymentType", values.idPaymentType);
          formData.append("payRunTypes", values.payRunTypes);
          formData.append("payrunName", values.payrunName);
          formData.append("isTimeAttendance", values.isTimeAttendance);
          formData.append("isOT", values.isOT);
          formData.append("isRecurring", values.isRecurring);
          formData.append("isOnetime", values.isOnetime);
          formData.append("isIncome", values.isIncome);
          formData.append("paymentRoundNumber", values.paymentRoundNumber);
        });

        setFormFileSelected(formData);
      }

      handleSetFileSelected(mode, {
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
      //setFileSelected();
    },
    maxFiles: 1,
  });

  return (
    <div>
      <StyledImportFile>
        <div
          {...getRootProps({
            className: "dropzone-upload-file",
          })}
        >
          <input {...getInputProps()} />
          <div className="inner-dropzone">
            <img alt="AddIcon" src={AddIcon} width="40" />
            <Typography
              //className={`${fileSelected != 0 && `placeholderLabel`}`}
              variant="body2"
              color="text.secondary"
            >
              Drag & Drop
            </Typography>
          </div>
        </div>
      </StyledImportFile>
    </div>
  );
};

export default UploadFile;
